import { InputConfiguration } from './../../../../common/inputs/Inputs';
import { Page } from './../../../../common/entities/Page';
import { CustomPageModule } from './../../../../common/entities/pagemodules/CustomPageModule';
import { EventVersion } from './../../../../common/entities/EventVersion';
import { Component, Input, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';

@Component({
  selector: 'app-custom-page-module-edit',
  templateUrl: './custom-page-module-edit.component.html',
  styleUrls: ['./custom-page-module-edit.component.scss'],
})
export class CustomPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: CustomPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  inputJsonpathes: string[] = [];

  constructor(
    private configurationService: ConfigurationService,
  ) {}

  async ngOnInit() {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }

    const type = await this.configurationService.customPageModuleType(this.pageModule.plugin, this.pageModule.customPageModuleType)

    if (type) {
      this.inputJsonpathes = type.fields.map(f => `$.configuration.${f.name}`)
    }
  }
}
