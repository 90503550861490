<p-dialog
  [header]="(showPublish ? 'COMPONENT_EVENT_VERSION_SELECT_VERSIONS_PUBLISHING' : 'COMPONENT_EVENT_VERSION_SELECT_VERSIONS') | translate"
  [(visible)]="show"
  (visibleChange)="showChange.emit($event)"
  [style]="{ margin: '80px', overflow: 'none' }"
>
  <div *ngIf="showContent">
    <div class="panel">
      <div class="panel-header">
        {{ 'COMPONENT_EVENT_VERSION_SELECT_VERSIONS' | translate }}
        <div class="actions">
          <c-table-options #toc></c-table-options>
        </div>
      </div>

      <div class="header-options"></div>
      <c-table mode="query" [options]="eventVersionTableOptions" [tableOptionsComponent]="toc" [singleTemplate]="true" (query)="queryEventVersions($event)">
        <ng-template let-v>
          <tr>
            <td class="actions">
              <div class="buttons">
                <p-button label="{{ v.version || v._id }}{{ v.dirty ? '*' : '' }}" (click)="diffVersion(v._id)"></p-button>
              </div>
            </td>
            <td>{{ (getCreatedAt(v) | date : 'medium') || '-' }}</td>
            <td>{{ (v?.forkedBy || page?.createdBy || event?.createdBy | adminUser | async)?.displayName || '-' }}</td>
            <td>{{ v?.finalizedAt ? (v.finalizedAt | date : 'medium') : '-' }}</td>
            <td>{{ (v?.finalizedBy | adminUser | async)?.displayName || '-' }}</td>
            <td>{{ v?.publishedAt ? (v.publishedAt | date : 'medium') : '-' }}</td>
            <td>{{ (v?.publishedBy | adminUser | async)?.displayName || '-' }}</td>
            <td class="actions">
              <div class="buttons" *ngIf="type === 'event'">
                <p-button
                  *ngIf="showVersionSelect"
                  [disabled]="working || eventVersion._id === v._id"
                  [label]="eventVersion._id === v._id ? 'Selected' : 'Select'"
                  (click)="eventVersion = v; versionChange.emit(v)"
                ></p-button>
                <p-button
                  *ngIf="showPublish"
                  [disabled]="working || (event.currentEventVersion?._id === v._id && v.publishedAt) || !v.finalizedAt"
                  [label]="!v.finalizedAt ? 'Work in Progress' : event.currentEventVersion?._id === v._id && v.publishedAt ? 'Published' : 'Publish'"
                  (click)="publish.emit(v)"
                ></p-button>
              </div>

              <div class="buttons" *ngIf="type === 'page'">
                <p-button
                  *ngIf="showVersionSelect"
                  [disabled]="working || pageVersion._id === v._id"
                  [label]="pageVersion._id === v._id ? 'Selected' : 'Select'"
                  (click)="pageVersion = v; versionChange.emit(v)"
                ></p-button>
                <p-button
                  *ngIf="showPublish"
                  [disabled]="working || (page.pageVersion === v._id && v.publishedAt) || !v.finalizedAt"
                  [label]="!v.finalizedAt ? 'Work in Progress' : page.pageVersion == v._id && v.publishedAt ? 'Published' : 'Publish'"
                  (click)="publish.emit(v)"
                ></p-button>
              </div>

              <div class="buttons" *ngIf="type === 'map'">
                <p-button
                  *ngIf="showVersionSelect"
                  [disabled]="working || mapVersion._id === v._id"
                  [label]="mapVersion._id === v._id ? 'Selected' : 'Select'"
                  (click)="mapVersion = v; versionChange.emit(v)"
                ></p-button>
                <p-button
                  *ngIf="showPublish"
                  [disabled]="working || (map.mapVersion === v._id && v.publishedAt) || !v.finalizedAt"
                  [label]="!v.finalizedAt ? 'Work in Progress' : map.mapVersion === v._id && v.publishedAt ? 'Published' : 'Publish'"
                  (click)="publish.emit(v)"
                ></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </c-table>
    </div>
  </div>
</p-dialog>
<app-version-diff [version]="versionId" [type]="type" [mapId]="map?._id" [pageId]="page?._id" [eventId]="event?._id" [(show)]="showDiff"></app-version-diff>
