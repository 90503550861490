import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

import { Language } from 'src/common/entities/Language';
import { GetLanguageQuery, GetLanguageResponse } from 'src/common/api/v1/configuration/languages/GetLanguage';
import { GetLanguagesQuery, GetLanguagesResponse } from 'src/common/api/v1/configuration/languages/GetLanguages';
import { PostLanguageBody, PostLanguageQuery, PostLanguageResponse } from 'src/common/api/v1/configuration/languages/PostLanguage';
import { PostLanguagesBody, PostLanguagesQuery, PostLanguagesResponse } from 'src/common/api/v1/configuration/languages/PostLanguages';

import { ApiSocketService } from '../api-socket/api-socket.service';
import { Observable } from 'rxjs';
import { CacheService } from '../cache/cache.service';
import { CacheContainer } from '../cache/cache-container';

@Injectable({
  providedIn: 'root',
})
export class LanguagesService {
  // private _languages: {[language: string] : Promise<Language>} = {};
  // private _languages: BehaviorSubject<Language[]> = new BehaviorSubject<Language[]>(null);

  private _cache: CacheContainer<Language>;

  constructor(private apiService: ApiService, private apiSocketService: ApiSocketService, private cacheService: CacheService) {
    this._cache = this.cacheService.create<Language>({
      id: (value) => value._id,
      fill: async () => (await this.apiService.get<GetLanguagesQuery, GetLanguagesResponse>('/api/v1/configuration/languages').toPromise()).items,
      get: async (language) => await this.apiService.get<GetLanguageQuery, GetLanguageResponse>(`/api/v1/configuration/languages/${language}`).toPromise(),
      groups: [
        {
          name: 'active',
          filter: (value) => value.active,
        },
      ],
      socketEvents: ['language:update'],
    });
  }

  asObservable(language: string): Observable<Language> {
    return this._cache.asObservable(language);
  }

  asPromise(language: string): Promise<Language> {
    return this._cache.asPromise(language);
  }

  async getLanguages(query?: GetLanguagesQuery): Promise<GetLanguagesResponse> {
    if (this._cache.length() === 0) {
      const result = await this.apiService
        .get<GetLanguagesQuery, GetLanguagesResponse>('/api/v1/configuration/languages', {
          ...query,
        })
        .toPromise();

      this._cache.fill(result.items);
    }

    const languages = await this._cache.allAsPromise();

    return {
      items: languages,
      totalCount: languages.length,
    };
  }

  getActiveLanguages(): Observable<Language[]> {
    return this._cache.group('active');
  }

  async getActiveLanguagesAsPromise(): Promise<Language[]> {
    return (await this.getLanguages()).items.filter((l) => l.active);
  }

  async createLanguage(language: Language): Promise<Language> {
    return this._cache.post<PostLanguagesQuery, PostLanguagesBody, PostLanguagesResponse>('/api/v1/configuration/languages', language);
  }

  async updateLanguage(language: Language): Promise<Language> {
    return this._cache.post<PostLanguageQuery, PostLanguageBody, PostLanguageResponse>(`/api/v1/configuration/languages/${language._id}`, language);
  }
}
