import { Pipe, PipeTransform } from '@angular/core';
import { Event, eventPhase } from 'src/common/entities/Event';

@Pipe({
  name: 'eventPhase',
})
export class EventPhasePipe implements PipeTransform {
  transform(event: Event, ...args: [string, Date]): string {
    return eventPhase(event, args[0] || undefined, args[1]);
  }
}
