import { Component, Input, OnInit } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { MultiEventStagePageModule } from 'src/common/entities/pagemodules/MultiEventStagePageModule';

import { InputConfiguration } from 'src/common/inputs/Inputs';

@Component({
  selector: 'c-multieventstage-page-module-edit',
  templateUrl: './multieventstage-page-module-edit.component.html',
  styleUrls: ['./multieventstage-page-module-edit.component.scss'],
})
export class MultiEventStagePageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: MultiEventStagePageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathes: string[] = [
    '$.local.$language.title',
    '$.local.$language.subtitle',
    '$.local.$language.preHeadline',
    '$.local.$language.background',
    '$.ctaButton',
    '$.local.$language.subtext',
    '$.discoverMore',
    '$.hideEventName',
    '$.hideEventDate',
    '$.hideEventTags',
    '$.anchor',
  ];

  designJsonpathes: string[] = [
    '$.backgroundTheme',
    '$.stageHeight.desktop',
    '$.stageHeight.desktopLarge',
    '$.stageHeight.desktopWidescreen',
    '$.stageHeight.tablet',
    '$.stageHeight.tabletLarge',
    '$.stageHeight.mobile',
    '$.stageHeight.mobileLarge',
    '$.buttonSpacingTopBreakpoint.desktop',
    '$.buttonSpacingTopBreakpoint.desktopLarge',
    '$.buttonSpacingTopBreakpoint.desktopWidescreen',
    '$.buttonSpacingTopBreakpoint.tablet',
    '$.buttonSpacingTopBreakpoint.tabletLarge',
    '$.buttonSpacingTopBreakpoint.mobile',
    '$.buttonSpacingTopBreakpoint.mobileLarge',
  ];

  initialized: boolean = false;
  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  constructor() {}

  async ngOnInit(): Promise<void> {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
    this.initialized = true;
  }
}
