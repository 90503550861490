import { TextAttributes } from './../Attributes';
import { PageModule, TitlePositions } from './PageModule';
import { CTAButton } from '../CTAButton';

export interface ListImagePageModule extends PageModule {
  pageModuleType: 'ListImage';
  titlePosition?: TitlePositions | 'content';
  local: { [language: string]: ListImagePageModuleLocal };

  imageSize: 'large' | 'medium';
  imagePosition: 'left' | 'right';
  imagePositionWithinImageArea: 'left' | 'right' | 'center';
  ctaButton?: CTAButton;
  imagePositionMobile?: 'top' | 'bottom';
}

export type ListImagePageModuleAttributes = TextAttributes & {};

export interface ListImagePageModuleLocal {
  title: string;
  title_attr?: ListImagePageModuleAttributes;
  items: string[];
  itemIcon?: string;
  image: string;
}

export function isListImagePageModule(object: any): object is ListImagePageModule {
  return object?.pageModuleType === 'ListImage';
}
