import { AdminUser, ServiceAdminUser } from '../entities/AdminUser';
import { AbstractFactory, FactoryOptions, Values } from './AbstractFactory';

export class AdminUserFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createAdminUser(values?: Values<AdminUser>) {
    return {
      ...values,
      _id: await this.id(values?._id),
      adminUserType: values?.adminUserType || 'LocalAdminUser'
    } as AdminUser;
  }

  async createServiceAdminUser(values?: Values<ServiceAdminUser>) {
    return {
      ...values,
      _id: await this.id(values?._id),
      adminUserType: 'ServiceAdminUser',
      internalName: values?.internalName || ''
    } as ServiceAdminUser;
  }
}
