import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageConfigurationComponent } from './page-configuration.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { TableModule } from 'primeng/table';
import { ComponentsModule } from 'src/app/components/components.module';
import { PageModule as _PageModule } from '../../page/page.module';
import { CardModule } from 'primeng/card';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MenuModule } from 'primeng/menu';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { ListboxModule } from 'primeng/listbox';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { LayoutEditorModule } from 'src/app/layout-editor/layout-editor.module';
import { SidebarModule } from 'primeng/sidebar';
import { AccordionModule } from 'primeng/accordion';

const routes: Routes = [{ path: '', component: PageConfigurationComponent }];

@NgModule({
  declarations: [PageConfigurationComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    DialogModule,
    CardModule,
    TableModule,
    MenuModule,
    FormsModule,
    ListboxModule,
    ConfirmPopupModule,
    PipesModule,
    DirectivesModule,
    _PageModule,
    DividerModule,
    ButtonModule,
    ComponentsModule,
    LayoutEditorModule,
    SidebarModule,
    AccordionModule,
  ],
  exports: [RouterModule],
})
export class PageConfigurationModule {}
