import { ActivatedRoute } from '@angular/router';
import { TabsService } from './../../tabs/services/tabs.service';
import { Tab } from './../../tabs/classes/tab';
import { TableOptions, TableQuery } from './../../components/table/table.interfaces';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { KeytopicsService } from 'src/app/services/keytopics/keytopics.service';
import { Keytopic } from 'src/common/entities/Keytopic';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TableComponent } from '../../components/table/table.component';

@Component({
  selector: 'app-keytopics',
  templateUrl: './keytopics.component.html',
  styleUrls: ['./keytopics.component.scss'],
})
export class KeytopicsComponent implements OnInit, OnDestroy {
  @ViewChildren(TableComponent) private tables: QueryList<TableComponent<any>>;
  tab: Tab;
  tableOptions: TableOptions<Keytopic>;

  searchText: string;
  searchTextSubject: Subject<string> = new Subject<string>();
  subscriptions: Subscription[] = [];

  constructor(private keytopicsService: KeytopicsService, private tabsService: TabsService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.subscriptions.push(this.searchTextSubject.pipe(debounceTime(500)).subscribe(() => this.refresh()));

    this.tab = this.tabsService.register({
      category: 'data',
      route: this.activatedRoute.snapshot,
      title: 'Adminportal Users',
    });

    this.tableOptions = {
      size: 50,
      columns: [
        { header: 'PAGE_KEYTOPIC_GLOBAL_PROPERTIES_INTERNAL_NAME', sort: 'internalName' },
        { header: 'GENERAL_LANGUAGES' },
        { header: 'PAGE_KEYTOPIC_LOCAL_PROPERTIES_TITLE', sort: 'local.en.title' },
        { header: 'PAGE_KEYTOPIC_LOCAL_PROPERTIES_SHORT_DESCIPTION', sort: 'local.en.shortDescription' },
      ],
      filters: [
        { header: 'PAGE_KEYTOPIC_GLOBAL_PROPERTIES_INTERNAL_NAME', path: 'internalName' },
        { header: 'PAGE_KEYTOPIC_LOCAL_PROPERTIES_TITLE', path: 'local.en.title' },
        { header: 'PAGE_KEYTOPIC_LOCAL_PROPERTIES_SHORT_DESCIPTION', path: 'local.en.shortDescription' },
      ],
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async searchTextKeyUp() {
    this.searchTextSubject.next();
  }

  query(query: TableQuery<Keytopic>) {
    if (this.searchText) {
      query.query.filter.internalName = {
        matchMode: 'contains',
        caseInsensitive: true,
        value: this.searchText,
      };
    }
    query.result = this.keytopicsService.getKeytopics(query.query);
  }

  async refresh() {
    const tables = this.tables.toArray();
    await Promise.all(tables.map((t) => t.refresh()));
  }
}
