<div *skeleton="{ show: tab.loading }">
  <div class="tab-header">
    <h1>
      <span class="pre-title">{{ page?.pageType !== 'EmbeddedPage' ? ('GENERAL_GLOBAL_PAGE' | translate) : ('GENERAL_EMBEDDED_PAGE' | translate) }}</span>
      <span>{{ page?.internalName || 'GENERAL_UNNAMED' | translate }}</span>
      <a *ngIf="pageAsPageVersion() as pv" (click)="pageURL()" target="_blank">
        <i *ngIf="pv.publishedAt && pv.version" class="pi pi-external-link"></i>
      </a>
    </h1>
    <div class="info">
      <div *hasRight="'pages.edit'">
        <span *ngIf="pageVersion?.dirty">{{ 'GENERAL_CHANGES_WILL_BE_SAVED_AUTOMATICALLY' | translate }}</span>
      </div>
      <div *ngIf="!isNonVersionedPage" class="info-box">
        <div class="value">{{ pageVersion?.version || '-' }}</div>
        <div class="title">Current<br />Version</div>
      </div>
      <div class="info-box">
        <div class="value" *ngIf="pageAsPageVersion() as pv">{{ (pv.publishedAt && pv.version) || '-' }}</div>
        <div class="title">Published<br />Version</div>
      </div>
    </div>
  </div>

  <div class="tab-menu">
    <p-button *hasRight="'pages.edit'" styleClass="p-button-text" icon="pi pi-cog" (onClick)="showConfiguration = true" [label]="'GENERAL_CONFIGURATION' | translate" [disabled]="working"></p-button>
    <c-language-button
      *ngIf="pageVersion && !isLinkedPage"
      [manageLanguages]="hasEditRights && !isNonVersionedPage"
      [(currentLanguage)]="currentLanguage"
      [(languages)]="pageVersion.languages"
      (languagesChange)="onActiveLanguageChange($event)"
      [disabled]="working || !pageVersion.dirty"
    ></c-language-button>
    <c-language-button
      *ngIf="linkedPageVersion && isLinkedPage"
      [manageLanguages]="false"
      [(currentLanguage)]="currentLanguage"
      [(languages)]="linkedPageVersion.languages"
      (languagesChange)="onActiveLanguageChange($event)"
      [disabled]="working"
    ></c-language-button>
    <p-button
      *ngIf="!isNonVersionedPage && hasEditRights"
      [disabled]="pageVersion?.dirty || working"
      icon="pi pi-pencil"
      styleClass="p-button-text"
      (onClick)="forkPageVersion(pageVersion?._id)"
      label="Edit"
    ></p-button>
    <p-button
      *ngIf="!isNonVersionedPage && hasEditRights"
      [disabled]="!pageVersion?.dirty || working"
      styleClass="p-button-text"
      (onClick)="savePageVersion(pageVersion?._id)"
      label="Finalize Version"
    ></p-button>
    <p-button styleClass="p-button-text" (onClick)="showVersions = true" label="Versions & Publishing" [disabled]="working"></p-button>
    <div class="right">
      <p-button styleClass="p-button-text" icon="pi pi-eye" (onClick)="showPreview = true" [label]="'GENERAL_PREVIEW' | translate"></p-button>
    </div>
  </div>

  <c-layout-editor
    *ngIf="pageVersion && page! && !isLinkedPage"
    [context]="{ page: page, pageVersion: pageVersion }"
    [enableProperties]="!isNonVersionedPage"
    (contextChange)="contextChanged($event)"
    pageJsonpath="$"
    [language]="currentLanguage"
  ></c-layout-editor>
  <c-layout-editor
    *ngIf="pageVersion && page! && linkedPageVersion && linkedPage && isLinkedPage"
    [context]="{ page: linkedPage, pageVersion: linkedPageVersion }"
    [message]="{ severity: 'info', text: 'You are currently viewing a preview of the LinkedPage \'' + linkedPage.internalName + '\'' }"
    [enableProperties]="false"
    (contextChange)="contextChanged($event)"
    [language]="currentLanguage"
  ></c-layout-editor>
</div>

<p-dialog *ngIf="showConfiguration" [(visible)]="showConfiguration" [modal]="true" [draggable]="false" [closeOnEscape]="true" styleClass="form-dialog" [header]="'GENERAL_CONFIGURATION' | translate">
  <div class="form-container">
    <c-collaboration-patch-input *ngFor="let inputJsonpath of inputJsonpathesConfiguration" [object]="page" [jsonpath]="[inputJsonpath]" [inputs]="inputsConfiguration"></c-collaboration-patch-input>
  </div>

  <div *ngIf="page && page.pageType === 'AssetPage'" class="form-container">
    <p-accordion>
      <p-accordionTab header="VIDEO GRID">
        <ng-container *ngFor="let inputJsonpath of inputJsonpathesAssetPageConfiguration">
          <c-collaboration-patch-input
            *ngIf="inputJsonpath !== '$.local.$language.asset' || page['assetScope'] === 'SelectAsset'"
            [object]="page"
            [jsonpath]="[inputJsonpath]"
            [jsonpathParams]="{ language: currentLanguage }"
            [inputs]="inputsConfiguration"
          ></c-collaboration-patch-input>
        </ng-container>
      </p-accordionTab>
      <p-accordionTab header="RECOMMENDED VIDEOS">
        <c-collaboration-patch-input
          *ngFor="let inputJsonpath of inputJsonpathesRecommendedVideosConfiguration"
          [object]="page"
          [jsonpath]="[inputJsonpath]"
          [jsonpathParams]="{ language: currentLanguage }"
          [inputs]="inputsConfiguration"
        ></c-collaboration-patch-input>
        <ng-container *ngIf="asAssetPage(page)?.recommendationsSettings?.navigationType === 'pagination'">
          <c-collaboration-patch-input
            *ngFor="let inputJsonpath of inputJsonPathesPagination"
            [jsonpath]="[inputJsonpath]"
            [object]="page"
            [inputs]="inputsConfiguration"
          ></c-collaboration-patch-input>
        </ng-container>
        <ng-container *ngIf="asAssetPage(page)?.recommendationsSettings?.navigationType === 'loadMore'">
          <c-collaboration-patch-input
            *ngFor="let inputJsonpath of inputJsonPathesLoadMore"
            [jsonpath]="[inputJsonpath]"
            [jsonpathParams]="{ language: currentLanguage }"
            [object]="page"
            [inputs]="inputsConfiguration"
          ></c-collaboration-patch-input>
        </ng-container>
        <c-collaboration-patch-input
          *ngFor="let inputJsonpath of inputJsonpathesAssetPageRecommendedVideosConfiguration"
          [jsonpath]="[inputJsonpath]"
          [object]="page"
          [inputs]="inputsConfiguration"
        ></c-collaboration-patch-input>
      </p-accordionTab>
    </p-accordion>
  </div>

  <ng-template pTemplate="footer">
    <!-- footer must exist to have rounded border at bottom, too & bottom-padding like at the top -->
    <p-button (onClick)="savePageConfiguration()" [disabled]="working" [label]="'GENERAL_SAVE' | translate"></p-button>
  </ng-template>
</p-dialog>

<c-version-select
  [(show)]="showVersions"
  [working]="working"
  [page]="page"
  [pageVersion]="pageVersion"
  (versionChange)="loadPageVersion($event._id); showVersions = false"
  [showPublish]="hasPublishRights"
  [showVersionSelect]="!isNonVersionedPage"
  (publish)="publishPageVersion($event._id); showVersions = false"
  type="page"
></c-version-select>

<p-sidebar [(visible)]="showPreview" styleClass="p-sidebar-st" position="right" [modal]="true" [appendTo]="'body'" [showCloseIcon]="false">
  <c-sidebar-header [headerTitle]="'Preview Links'" (onClose)="showPreview = false"></c-sidebar-header>

  <c-preview *ngIf="showPreview" [page]="page" [pageVersion]="pageVersion" type="page"></c-preview>
</p-sidebar>
