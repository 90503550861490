import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, using } from 'rxjs';
import { GetPageQuery, GetPageResponse } from 'src/common/api/v1/configuration/pages/GetPage';
import { GetPagePageVersionQuery, GetPagePageVersionResponse } from 'src/common/api/v1/configuration/pages/GetPagePageVersion';
import { GetPagePageVersionsQuery, GetPagePageVersionsResponse } from 'src/common/api/v1/configuration/pages/GetPagePageVersions';
import { GetPagesQuery, GetPagesResponse } from 'src/common/api/v1/configuration/pages/GetPages';
import { PostPageBody, PostPageQuery, PostPageResponse } from 'src/common/api/v1/configuration/pages/PostPage';
import { PostPagePageVersionPatchBody, PostPagePageVersionPatchQuery, PostPagePageVersionPatchResponse } from 'src/common/api/v1/configuration/pages/PostPagePageVersionPatch';
import { PostPagePageVersionPublishBody, PostPagePageVersionPublishQuery, PostPagePageVersionPublishResponse } from 'src/common/api/v1/configuration/pages/PostPagePageVersionPublish';
import { PostEmbeddedPageBody, PostEmbeddedPageResponse, PostPagesBody, PostPagesQuery, PostPagesResponse } from 'src/common/api/v1/configuration/pages/PostPages';
import { GetPagePreviewsQuery, GetPagePreviewsResponse } from 'src/common/api/v1/configuration/pages/GetPagePreviews';
import { PostPagePreviewsBody, PostPagePreviewsQuery, PostPagePreviewsResponse } from 'src/common/api/v1/configuration/pages/PostPagePreviews';
import { EmbeddedPage, Page, PageVersion, pageVersionDateFields } from 'src/common/entities/Page';
import { Patch } from 'src/common/patch/Patch';
import { PatchExecutor } from 'src/common/patch/PatchExecutor';
import { ApiSocketService } from '../api-socket/api-socket.service';
import { ApiService } from '../api/api.service';
import * as uuid from 'uuid';
import * as jp from 'jsonpath';
import { PageVersionPatch } from 'src/common/api/v1/websocket/PageVersionPatch';
import { PostPageDeleteBody, PostPageDeleteQuery, PostPageDeleteResponse } from 'src/common/api/v1/configuration/pages/PostPageDelete';
import { map } from 'rxjs/operators';
import { GetDiffQuery, GetDiffResponse } from 'src/common/api/v1/diff/GetDiff';

@Injectable({
  providedIn: 'root',
})
export class PagesService {
  private exceptions = ['api', 'search'];

  private _pages: {
    [pageId: string]: {
      subject: ReplaySubject<Page>;
      observable: Observable<Page>;
    };
  } = {};

  private _pageVersions: { [pageVersionId: string]: BehaviorSubject<PageVersion> } = {};
  private _lastPageVersionPatch: { [pageVersionId: string]: ReplaySubject<PageVersionPatch> } = {};

  private _patches: {
    [id: string]: {
      resolve: (result: boolean) => void;
      reject: (err: any) => void;
    };
  } = {};

  constructor(private apiService: ApiService, private apiSocketService: ApiSocketService) {
    this.apiSocketService.on('connect', () => {
      [...new Set(Object.keys(this._pages).concat(Object.values(this._pageVersions).map((v) => v.getValue().page)))].forEach((pageId) => {
        this.apiSocketService.emit('page:register', { page: pageId });
      });
    });

    this.apiSocketService.on<Page>('page:update', (page) => {
      if (this._pages[page._id]) {
        this._pages[page._id].subject.next(page);
      }
    });

    this.apiSocketService.on<PageVersionPatch>('pageversion:patch', async (pageVersionPatch) => {
      if (this._pageVersions[pageVersionPatch.pageVersion]) {
        const pageVersion = this._pageVersions[pageVersionPatch.pageVersion].getValue();

        try {
          if ((pageVersion.change || 0) === pageVersionPatch.change - 1) {
            if (pageVersionPatch.patch.command === 'set' && pageVersionDateFields.includes(pageVersionPatch.patch.jsonpath) && pageVersionPatch.patch.value) {
              pageVersionPatch.patch.value = new Date(pageVersionPatch.patch.value);
            }

            for (const patch of pageVersionPatch.previousPatches || []) {
              PatchExecutor.patch(pageVersion, patch);
            }

            PatchExecutor.patch(pageVersion, pageVersionPatch.patch);

            for (const patch of pageVersionPatch.subsequentPatches || []) {
              PatchExecutor.patch(pageVersion, patch);
            }

            this.convertPageVersionDates(pageVersion);
            pageVersion.change = pageVersionPatch.change;
            this._pageVersions[pageVersionPatch.pageVersion].next(pageVersion);

            if (!this._lastPageVersionPatch[pageVersionPatch.pageVersion]) {
              this._lastPageVersionPatch[pageVersionPatch.pageVersion] = new ReplaySubject(1);
            }
            this._lastPageVersionPatch[pageVersionPatch.pageVersion].next(pageVersionPatch);

            if (this._patches[pageVersionPatch.patch.id]) {
              this._patches[pageVersionPatch.patch.id].resolve(true);
              delete this._patches[pageVersionPatch.patch.id];
              return;
            }
          } else {
            await this.getPageVersion(pageVersionPatch.page, pageVersionPatch.pageVersion);
          }
        } catch (err) {
          console.error(err);
          await this.getPageVersion(pageVersionPatch.page, pageVersionPatch.pageVersion);
        }
      }

      if (this._patches[pageVersionPatch.patch.id]) {
        this._patches[pageVersionPatch.patch.id].resolve(false);
        delete this._patches[pageVersionPatch.patch.id];
      }
    });
  }

  convertPageDates(page: Page) {
    this.convertPageVersionDates(page as PageVersion);
    return page;
  }

  convertPageVersionDates(pageVersion: PageVersion) {
    if (pageVersion) {
      pageVersionDateFields.forEach((d) => {
        const paths = jp.paths(pageVersion, d);

        for (const path of paths) {
          jp.apply(pageVersion, jp.stringify(path), (v) => (v ? new Date(v) : v));
        }
      });
    }
    return pageVersion;
  }

  async getPage(pageId: string): Promise<Page> {
    return this.convertPageDates(await this.apiService.get<GetPageQuery, GetPageResponse>(`/api/v1/configuration/pages/${pageId}`).toPromise());
  }

  getDiff(firstVersion: string, secondVersion: string): Promise<GetDiffResponse> {
    return this.apiService.get<GetDiffQuery, GetDiffResponse>(`/api/v1/configuration/pageversions/${firstVersion}/diff`, { compareVersion: secondVersion }).toPromise();
  }

  async pathExists(pagePath: string, domainCollection: string): Promise<boolean> {
    const response = await this.apiService
      .get<GetPagesQuery, GetPagesResponse>(`/api/v1/configuration/pages`, {
        path: pagePath,
        domainCollection: domainCollection,
      })
      .toPromise();
    return response.totalCount > 0;
  }

  private ensurePageVersion(pageVersion: PageVersion): PageVersion {
    this.convertPageVersionDates(pageVersion);

    if (!this._pageVersions[pageVersion._id]) {
      this._pageVersions[pageVersion._id] = new BehaviorSubject<PageVersion>(pageVersion);
    } else {
      this._pageVersions[pageVersion._id].next(pageVersion);
    }

    return pageVersion;
  }

  async getPageVersion(pageId: string, pageVersionId: string): Promise<PageVersion> {
    return this.ensurePageVersion(await this.apiService.get<GetPagePageVersionQuery, GetPagePageVersionResponse>(`/api/v1/configuration/pages/${pageId}/pageversions/${pageVersionId}`).toPromise());
  }

  getPages(domainCollection: string, query?: GetPagesQuery): Promise<GetPagesResponse> {
    return this.apiService.get<GetPagesQuery, GetPagesResponse>('/api/v1/configuration/pages', { ...query, domainCollection, limit: 0 }).toPromise();
  }

  getPageVersions(pageId: string, query?: GetPagePageVersionsQuery): Promise<GetPagePageVersionsResponse> {
    return this.apiService.get<GetPagePageVersionsQuery, GetPagePageVersionsResponse>(`/api/v1/configuration/pages/${pageId}/pageversions`, query).toPromise();
  }

  async publishPageVersion(pageId: string, pageVersionId: string): Promise<PageVersion> {
    return this.ensurePageVersion(
      await this.apiService
        .post<PostPagePageVersionPublishQuery, PostPagePageVersionPublishBody, PostPagePageVersionPublishResponse>(`/api/v1/configuration/pages/${pageId}/pageversions/${pageVersionId}/publish`, {})
        .toPromise()
    );
  }

  async savePageVersion(pageId: string, pageVersionId: string): Promise<PageVersion> {
    return this.ensurePageVersion(
      await this.apiService
        .post<PostPagePageVersionPublishQuery, PostPagePageVersionPublishBody, PostPagePageVersionPublishResponse>(`/api/v1/configuration/pages/${pageId}/pageversions/${pageVersionId}/save`, {})
        .toPromise()
    );
  }

  async forkPageVersion(pageId: string, pageVersionId: string): Promise<PageVersion> {
    return this.ensurePageVersion(
      await this.apiService
        .post<PostPagePageVersionPublishQuery, PostPagePageVersionPublishBody, PostPagePageVersionPublishResponse>(`/api/v1/configuration/pages/${pageId}/pageversions/${pageVersionId}/fork`, {})
        .toPromise()
    );
  }

  async createEmbeddedPage(embeddedPage: PostEmbeddedPageBody): Promise<EmbeddedPage> {
    return this.apiService
      .post<PostPagesQuery, PostEmbeddedPageBody, PostEmbeddedPageResponse>('/api/v1/configuration/pages', embeddedPage)
      .pipe(map((embeddedPage) => embeddedPage as EmbeddedPage))
      .toPromise();
  }

  async createPage(page: PostPagesBody): Promise<Page> {
    return this.convertPageDates(await this.apiService.post<PostPagesQuery, PostPagesBody, PostPagesResponse>('/api/v1/configuration/pages', page).toPromise());
  }

  async updatePage(page: Page): Promise<Page> {
    return this.convertPageDates(await this.apiService.post<PostPageQuery, PostPageBody, PostPageResponse>(`/api/v1/configuration/pages/${page._id}`, page).toPromise());
  }

  async deletePage(pageId: string, body?: PostPageDeleteBody): Promise<PostPageDeleteResponse> {
    return await this.apiService.post<PostPageDeleteQuery, PostPageDeleteBody, PostPageDeleteResponse>(`/api/v1/configuration/pages/${pageId}/delete`, body).toPromise();
  }

  subscribePage(pageId: string): Observable<Page> {
    if (!this._pages[pageId]) {
      const subject = new ReplaySubject<Page>(1);
      const observable = using(
        () => {
          return {
            unsubscribe: () => {
              if (subject.observers.length === 0) {
                delete this._pages[pageId];
                subject.complete();
                this.apiSocketService.emit('page:unregister', { page: pageId });
              }
            },
          };
        },
        () => {
          return subject;
        }
      );

      this._pages[pageId] = {
        subject: subject,
        observable: observable,
      };

      this.apiSocketService.emit('page:register', { page: pageId });

      this.getPage(pageId).then((page) => {
        subject.next(page);
      });
    }

    return this._pages[pageId].observable;
  }

  public lastPageVersionPatch(pageVersion: string): Observable<PageVersionPatch> {
    if (!this._lastPageVersionPatch[pageVersion]) {
      this._lastPageVersionPatch[pageVersion] = new ReplaySubject(1);
    }
    return this._lastPageVersionPatch[pageVersion].asObservable();
  }

  async patch(pageVersion: PageVersion, patch: Patch): Promise<boolean> {
    return new Promise<boolean>(async (resolve: (result: boolean) => void, reject: (err: any) => void) => {
      patch.id = uuid.v4();
      this._patches[patch.id] = { resolve, reject };

      const result = await this.apiService
        .post<PostPagePageVersionPatchQuery, PostPagePageVersionPatchBody, PostPagePageVersionPatchResponse>(
          `/api/v1/configuration/pages/${pageVersion.page}/pageversions/${pageVersion._id}/patch`,
          patch
        )
        .toPromise();

      if (!result.successful) {
        delete this._patches[patch.id];
        resolve(false);
      } else {
        setTimeout(() => {
          if (this._patches[patch.id]) {
            delete this._patches[patch.id];
            reject(new Error('Timeout'));
          }
        }, 10000);
      }
    });
  }

  public async getPreviews(page: string, query?: GetPagePreviewsQuery): Promise<GetPagePreviewsResponse> {
    return this.apiService.get<GetPagePreviewsQuery, GetPagePreviewsResponse>(`/api/v1/configuration/pages/${page}/previews`, query).toPromise();
  }
  public async createPreview(page: string, preview: PostPagePreviewsBody): Promise<PostPagePreviewsResponse> {
    return this.apiService.post<PostPagePreviewsQuery, PostPagePreviewsBody, PostPagePreviewsResponse>(`/api/v1/configuration/pages/${page}/previews`, preview).toPromise();
  }
}
