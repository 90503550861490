import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'c-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss'],
})
export class DatetimeComponent implements OnInit {
  _start: Date | string;
  @Input()
  set start(inputStart: Date | string) {
    if (inputStart) {
      this._start = moment(inputStart).toDate();
      this.startDate = moment(inputStart).toDate();
      this.startTime = moment(inputStart).get('hours').toString().padStart(2, '0') + ':' + moment(inputStart).get('minutes').toString().padStart(2, '0');
    } else {
      this._start = null;
      this.startDate = null;
      this.startTime = null;
    }
  }

  @Output()
  startChange = new EventEmitter<Date | string>();

  _end: Date | string;
  @Input()
  set end(inputEnd: Date | string) {
    if (inputEnd) {
      this._end = moment(inputEnd).toDate();
      this.endDate = moment(inputEnd).toDate();
      this.endTime = moment(inputEnd).get('hours').toString().padStart(2, '0') + ':' + moment(inputEnd).get('minutes').toString().padStart(2, '0');
    } else {
      this._end = null;
      this.endDate = null;
      this.endTime = null;
    }
  }

  @Input()
  defaultTimespanInMinutes: number = 45;

  @Output()
  endChange = new EventEmitter<Date | string>();

  @HostBinding('class.error')
  get hasError(): boolean {
    return typeof this.error === 'string' && this.error.length > 0;
  }

  @Input()
  error: string | null = null;

  startDate: Date;
  startTime: string;
  endDate: Date;
  endTime: string;
  currentTimespanInMinutes: number;

  constructor() {}

  ngOnInit(): void {
    if (this._start && this._end) this.setCurrentTimespan();
  }

  changedStart() {
    if (!this.isStartValid()) {
      this.startTime = null;
      this.currentTimespanInMinutes = null;
      return;
    }

    this._start = moment(this.startDate).startOf('day').add(this.startTime.split(':')[0], 'hours').add(this.startTime.split(':')[1], 'minutes').toDate();

    if (this._start > this._end || !this._end) {
      if (this.currentTimespanInMinutes) {
        this.end = moment(this._start).add(this.currentTimespanInMinutes, 'minutes').toDate();
      } else {
        this.end = moment(this._start).add(this.defaultTimespanInMinutes, 'minutes').toDate();
      }

      this.endChange.emit(this._end);
    }

    this.setCurrentTimespan();
    this.startChange.emit(this._start);
  }

  changedEnd() {
    if (this.isEndValid()) {
      this._end = moment(this.endDate).startOf('day').add(this.endTime.split(':')[0], 'hours').add(this.endTime.split(':')[1], 'minutes').toDate();
      if (this._start > this._end || !this._start) {
        if (this.currentTimespanInMinutes) {
          this.start = moment(this._end).add(-this.currentTimespanInMinutes, 'minutes').toDate();
        } else {
          this.start = moment(this._end).add(-this.defaultTimespanInMinutes, 'minutes').toDate();
        }

        this.startChange.emit(this._start);
      }

      this.setCurrentTimespan();

      this.endChange.emit(this._end);
    } else {
      this._end = null;
      this.endTime = null;
      this.currentTimespanInMinutes = null;
    }
  }

  isStartValid(): boolean {
    if (!this.startTime || !this.startDate) return false;

    if (isNaN(Number(this.startTime.split(':')[0])) || Number(this.startTime.split(':')[0]) > 23) return false;

    if (isNaN(Number(this.startTime.split(':')[1])) || Number(this.startTime.split(':')[1]) > 59) return false;

    return true;
  }

  isEndValid(): boolean {
    if (!this.endTime || !this.endDate) return false;

    if (isNaN(Number(this.endTime.split(':')[0])) || Number(this.endTime.split(':')[0]) > 23) return false;

    if (isNaN(Number(this.endTime.split(':')[1])) || Number(this.endTime.split(':')[1]) > 59) return false;

    return true;
  }

  setCurrentTimespan() {
    var start = moment(this._start);
    var end = moment(this._end);
    var duration = moment.duration(end.diff(start));
    this.currentTimespanInMinutes = duration.asMinutes();
  }
}
