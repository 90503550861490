import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';
import { EventsService } from 'src/app/services/events/events.service';
import { Event, EventPhase } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page, PageVersion } from 'src/common/entities/Page';
import { Factory } from 'src/common/factories/Factory';
import { PageFactory } from 'src/common/factories/PageFactory';
import { InputConfiguration } from 'src/common/inputs/Inputs';
import { PagesService } from '../../services/pages/pages.service';

@Component({
  selector: 'c-page-edit',
  templateUrl: './page-edit.component.html',
  styleUrls: ['./page-edit.component.scss'],
})
export class PageEditComponent implements OnInit {
  @Input()
  event?: Event;

  @Input()
  eventVersion?: EventVersion;

  @Input()
  eventPhase?: EventPhase;

  @Input()
  page: Page | PageVersion;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Output()
  onClose = new EventEmitter<boolean>();

  @Output()
  pageDeleted = new EventEmitter<boolean>();

  loading = false;
  disableDelete = false;

  tab = 'general';

  readAccessInputConfiguration: InputConfiguration;
  inputPropertyLinkTitle: InputConfiguration = {};
  inputPropertyLinkDescription: InputConfiguration = {};

  pages: Page[] = [];

  deleteChildren = false;

  currentLanguage: string;

  constructor(
    private collaborationService: CollaborationService,
    private eventsService: EventsService,
    private pageService: PagesService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.currentLanguage = this.activatedRoute.snapshot.queryParams.language || 'en';

    if (this.eventVersion) {
      this.readAccessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }

    if (this.page && this.page.default) {
      this.readAccessInputConfiguration = {
        type: 'accesspolicy',
        disabled: true,
      };
    }

    const local = (this.eventVersion || this.page)?.local ? (this.eventVersion || this.page)?.local[this.currentLanguage] : { linkTitle: '', linkDescription: '' };

    this.inputPropertyLinkTitle = {
      header: 'PAGE_LINK_PREVIEW_LOCAL_LINKTITLE',
      description: local?.linkTitle && local?.linkTitle.trim().length > 0 ? 'PAGE_LINK_PREVIEW_LOCAL_LINKTITLE_DESCRIPTION' : 'PAGE_LINK_PREVIEW_LOCAL_LINKTITLE_PLACEHOLDER_DESCRIPTION',
      onChange: (newValue) => {
        if (newValue.trim().length > 0) {
          this.inputPropertyLinkTitle = { ...this.inputPropertyLinkTitle, description: 'PAGE_LINK_PREVIEW_LOCAL_LINKTITLE_DESCRIPTION' };
        } else {
          this.inputPropertyLinkTitle = { ...this.inputPropertyLinkTitle, description: 'PAGE_LINK_PREVIEW_LOCAL_LINKTITLE_PLACEHOLDER_DESCRIPTION' };
        }
      },
    };

    this.inputPropertyLinkDescription = {
      header: 'PAGE_LINK_PREVIEW_LOCAL_LINKDESCRIPTION',
      description:
        local?.linkDescription && local?.linkDescription.trim().length > 0 ? 'PAGE_LINK_PREVIEW_LOCAL_LINKDESCRIPTION_DESCRIPTION' : 'PAGE_LINK_PREVIEW_LOCAL_LINKDESCRIPTION_PLACEHOLDER_DESCRIPTION',
      onChange: (newValue) => {
        if (newValue.trim().length > 0) {
          this.inputPropertyLinkDescription = { ...this.inputPropertyLinkDescription, description: 'PAGE_LINK_PREVIEW_LOCAL_LINKDESCRIPTION_DESCRIPTION' };
        } else {
          this.inputPropertyLinkDescription = { ...this.inputPropertyLinkDescription, description: 'PAGE_LINK_PREVIEW_LOCAL_LINKDESCRIPTION_PLACEHOLDER_DESCRIPTION' };
        }
      },
    };
  }

  async pageTypeChange() {
    this.loading = true;
    const newPage = await Factory.get(PageFactory, { newIds: false }).page(this.page);
    const collaborationKey = this.collaborationService.collaborationKey(this.eventVersion || this.page);

    this.collaborationService.patch(collaborationKey, this.eventVersion || this.page, {
      command: 'set',
      jsonpath: this.jsonpath,
      jsonpathParams: this.jsonpathParams,
      value: newPage,
    });
    this.loading = false;
  }

  async deletePage(): Promise<void> {
    if (this.eventVersion) {
      this.eventsService
        .patch(this.eventVersion, {
          command: 'delete',
          jsonpath: `$.phase.$eventPhase.pages[?(@._id=="$pageId")]`,
          jsonpathParams: {
            eventPhase: this.eventPhase,
            pageId: this.page._id,
          },
        })
        .then(() => this.onClose.emit(true));
    } else {
      const pageId = (this.page as PageVersion)?.page || (this.page as Page)?._id;
      this.pageService.deletePage(pageId, { deleteChildren: this.deleteChildren }).then((value) => {
        if (value.deletedPages.includes(pageId)) {
          this.pageDeleted.emit(true);
          this.onClose.emit(true);
        }
      });
    }
  }

  hasChildren() {
    const pageId = (this.page as PageVersion)?.page || (this.page as Page)?._id;
    return this.pages.some((p) => p.parentPage === pageId);
  }

  // arrow function to keep `this` reference
  showDeleteConfirmation = async (event) => {
    if (!this.eventVersion) {
      const domainCollectionId = this.page.domainCollection || this.activatedRoute.snapshot.queryParams.domainCollectionId;
      this.disableDelete = true;
      await this.pageService.getPages(domainCollectionId).then((result) => (this.pages = result.items));
      this.disableDelete = false;
    }

    this.confirmationService.confirm({
      key: 'pageDeleteConfirm',
      target: event.target,
      message: this.translate.instant('GENERAL_CONFIRM_DELETE'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deletePage();
      },
      reject: () => {
        // Nothing to do
      },
    });
  };
}
