import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OperatingRegionsService } from 'src/app/services/operating-regions/operating-regions.service';
import { OperatingRegion } from 'src/common/entities/OperatingRegion';
@Component({
  selector: 'c-operating-region-select',
  templateUrl: './operating-region-select.component.html',
  styleUrls: ['./operating-region-select.component.scss'],
})
export class OperatingRegionSelectComponent implements OnInit {
  @Input()
  id: string;

  @Input()
  isEventPage = false;

  @Input()
  disabled = false;

  @Output()
  idChange: EventEmitter<string> = new EventEmitter<string>();

  operatingRegion: OperatingRegion;

  showSearch = false;
  filterText = '';

  constructor(private operatingRegionsService: OperatingRegionsService) {}

  async ngOnInit(): Promise<void> {
    this.id;
    this.operatingRegion = await this.operatingRegionsService.getOperatingRegion(this.id);
  }

  async select(operatingRegion: OperatingRegion): Promise<void> {
    this.showSearch = false;
    this.operatingRegion = operatingRegion;
    this.id = this.operatingRegion._id;
    this.idChange.emit(this.id);
  }

  openSearch() {
    this.showSearch = true;
  }

  remove(): void {
    this.id = null;
    this.idChange.emit(this.id);
  }
}
