import { Page } from './../../../../common/entities/Page';
import { DocumentCollectionPageModule } from './../../../../common/entities/pagemodules/DocumentCollectionPageModule';
import { EventVersion } from './../../../../common/entities/EventVersion';
import { InputConfiguration } from './../../../../common/inputs/Inputs';
import { Inputs } from 'src/common/inputs/Inputs';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-documentcollection-page-module-edit',
  templateUrl: './documentcollection-page-module-edit.component.html',
  styleUrls: ['./documentcollection-page-module-edit.component.scss'],
})
export class DocumentcollectionPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: DocumentCollectionPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathes: string[] = ['$.local.$language.title', '$.titlePosition', '$.ctaButton', '$.displayAs', '$.pagination', '$.anchor'];

  inputJsonpathesDesign: string[] = ['$.backgroundTheme'];

  tab = 'general';
  inputs: Inputs = {};
  accessInputConfiguration: InputConfiguration;

  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
  }
}
