<div class="sidebar-tabs">
  <button [class.active]="tab === 'general'" (click)="tab = 'general'">
    {{ 'GENERAL_GENERAL' | translate }}
  </button>
  <button [class.active]="tab === 'design'" (click)="tab = 'design'">
    {{ 'GENERAL_DESIGN' | translate }}
  </button>
  <button [class.active]="tab === 'conditions'" (click)="tab = 'conditions'">
    {{ 'GENERAL_CONDITIONS' | translate }}
  </button>
</div>

<div *ngIf="tab == 'general'" class="sidebar-body">
  <c-page-patch-input *ngFor="let inputJsonpath of inputJsonPathes" [jsonpath]="[jsonpath, inputJsonpath]" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'design'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.backgroundTheme']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingTop']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingBottom']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingLeft']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingRight']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'conditions'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.accessPolicy']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page" [inputConfiguration]="accessInputConfiguration"></c-page-patch-input>
</div>
