import { Component, OnInit } from '@angular/core';
import { LookupsService } from 'src/app/services/lookups/lookups.service';
import { Lookup } from 'src/common/entities/Lookup';
import { Tab } from 'src/app/tabs/classes/tab';
import { TableOptions, TableQuery } from 'src/app/components/table/table.interfaces';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LookupFactory } from 'src/common/factories/LookupFactory';

@Component({
  selector: 'app-lookups',
  templateUrl: './lookups.component.html',
  styleUrls: ['./lookups.component.scss'],
})
export class LookupsComponent implements OnInit {
  tab: Tab;
  tableOptions: TableOptions<Lookup>;

  constructor(private lookupsService: LookupsService, private tabsService: TabsService, private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.tab = this.tabsService.register({
      category: 'configuration',
      route: this.activatedRoute.snapshot,
      title: 'Lookups',
    });

    this.tableOptions = {
      size: 50,
      columns: [
        { header: 'Internal Name', sort: 'internalName', visible: 'fixed' },
        {
          header: 'Description',
          sort: 'description',
        },
        { header: 'Type' },
        { header: '' },
      ],
      filters: [
        { header: 'Internal Name', path: 'internalName' },
        { header: 'Description', path: 'description' },
      ],
    };
  }

  async createLookup() {
    let lookup: Lookup;
    lookup = await new LookupFactory({ newIds: true }).createLookup({});
    lookup = await this.lookupsService.createLookup(lookup);
    this.router.navigate(['/configuration/lookups', lookup._id], {
      replaceUrl: true,
    });
  }

  query(query: TableQuery<Lookup>) {
    query.result = this.lookupsService.getLookups(query.query);
  }
}
