import { Component, Input, OnInit } from '@angular/core';
import { Asset } from 'src/common/entities/Asset';

@Component({
  selector: 'c-speaker-item',
  templateUrl: './speaker-item.component.html',
  styleUrls: ['./speaker-item.component.scss']
})
export class SpeakerItemComponent {
  @Input()
  displayName: string;

  @Input()
  title: string;

  @Input()
  asset: Asset;
}
