import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { EventSlot, EventTimeSlot, EventTimeSlotLocal } from '../entities/EventSlot';
import { CombinedEventSlot } from '../entities/EventRegistration';

export class SlotFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createCombinedEventSlot(values?: any) {
    const eventSlotId = typeof values?.eventSlot === 'string' ? values?.eventSlot : values?.eventSlot?._id;
    return {
      eventSlot: eventSlotId ?? '',
      eventTimeSlots: await Promise.all(
        (values?.eventTimeSlots || []).map((slot: any) => {
          if (typeof slot === 'string') {
            return slot;
          }
          return this.createTimeSlot(slot);
        })
      ),
      eventTickets: values?.eventTickets || [],
    } as CombinedEventSlot;
  }

  async createSlot(values?: any) {
    return {
      _id: await this.id(values?._id),
      event: values?.event || '',
      startAt: values?.startAt || null,
      endAt: values?.endAt || null,
      capacity: values?.capacity || null,
      deletedAt: values?.deletedAt || null,
      warningCapacity: values?.warningCapacity || null,
      eventTimeSlots: await Promise.all((values?.eventTimeSlots || []).map((slot: any) => this.createTimeSlot(slot))),
      eventTickets: values?.eventTickets || [],
    } as EventSlot;
  }

  async createTimeSlot(values?: any) {
    return {
      _id: await this.id(values?._id),
      startAt: values?.startAt || null,
      endAt: values?.endAt || null,
      capacity: values?.capacity || null,
      warningCapacity: values?.warningCapacity || null,
      local: this.localKeys(values?.local).reduce((a, b) => ({ ...a, [b]: this.createTimeSlotLocal(values.local[b]) }), {}),
    } as EventTimeSlot;
  }

  createTimeSlotLocal(values?: any) {
    return {
      title: values?.title || null,
    } as EventTimeSlotLocal;
  }
}
