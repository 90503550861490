import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { titlePositions } from '../../entities/PageModule';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';
import { LoginPageModuleFactory } from '../../factories/pagemodules/LoginPageModuleFactory';

export const loginPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('Login'),

  "$.pageModules[?(@.pageModuleType=='Login' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new LoginPageModuleFactory().createLoginPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='Login' && @._id=='$pageModuleId')].local.$language.title": {
    header: 'Title',
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='Login' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [...titlePositions].map((item) => ({
      label: item,
      value: item,
    })),
  },
};
