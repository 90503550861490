export const notificationMethodTypes = ['Email', 'MicrosoftTeams', 'Jira', 'MicrosoftDevOps'] as const;
export type NotificationMethodType = (typeof notificationMethodTypes)[number];

export const notificationEvents = ['Contact'] as const;
export type NotificationEvent = (typeof notificationEvents)[number];

export type NotificationMethod = {
  notificationMethodType: NotificationMethodType;
  event: NotificationEvent;
} & {
  notificationMethodType: 'Email';
  targetEmailAddress: string;
  template: string;
};

export interface NotificationConfiguration {
  notificationMethods: NotificationMethod[];
}
