import { MenuItemFactory } from '../../factories/MenuItemFactory';
import { Inputs } from '../Inputs';
import { pageInputs } from '../page/PageInputs';

export const eventVersionPhaseInputs: Inputs = {
  '$.phase.$eventPhase.showMeetYourExpertInMenu': {
    header: 'Show Meet Your Expert in Menu',
    type: 'switch'
  },

  ...Object.keys(pageInputs).reduce(
    (a, b) => ({
      ...a,
      [b.replace('$', `$.phase.$eventPhase.pages[?(@._id=='$pageId')]`)]: pageInputs[b]
    }),
    {}
  ),
  ...['mobileMenuItems', 'appMenuItems', 'webMenuItems', 'appNavigationMenuItems'].reduce(
    (a, b) => ({
      ...a,
      ['$.phase.$eventPhase.' + b + "[?(@._id=='$menuItemId')].local.$language"]: {
        factory: async () => new MenuItemFactory().menuItemLocal({})
      },
      ['$.phase.$eventPhase.' + b + "[?(@._id=='$menuItemId')].local.$language.title"]: {},
      ['$.phase.$eventPhase.' + b + "[?(@._id=='$menuItemId')].local.$language.link"]: {},
      ['$.phase.$eventPhase.' + b + "[?(@._id=='$menuItemId')].menuItemType"]: {
        disabled: true
      },
      ['$.phase.$eventPhase.' + b + "[?(@._id=='$menuItemId')].dialog"]: {
        type: 'dropdown'
      },
      ['$.phase.$eventPhase.' + b + "[?(@._id=='$menuItemId')].page"]: {},
      ['$.phase.$eventPhase.' + b + "[?(@._id=='$menuItemId')].form"]: {
        type: 'form'
      },
      ['$.phase.$eventPhase.' + b + "[?(@._id=='$menuItemId')].icon"]: {
        type: 'imageasset',
        mimeTypes: ['image/svg+xml']
      },
      ['$.phase.$eventPhase.' + b + "[?(@._id=='$menuItemId')].hoverIcon"]: {
        type: 'imageasset',
        mimeTypes: ['image/svg+xml']
      },
      ['$.phase.$eventPhase.' + b + "[?(@._id=='$menuItemId')].activeIcon"]: {
        type: 'imageasset',
        mimeTypes: ['image/svg+xml']
      },
      ['$.phase.$eventPhase.' + b + "[?(@._id=='$menuItemId')].readAccess"]: {
        type: 'accesspolicy'
      },
      ['$.phase.$eventPhase.' + b + "[?(@._id=='$menuItemId')].executeAccess"]: {
        type: 'accesspolicy'
      }
    }),
    {}
  )
};
