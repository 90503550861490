<div class="sidebar-tabs">
  <button [class.active]="tab === 'general'" (click)="tab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
  <button [class.active]="tab === 'events'" (click)="tab = 'events'">{{ 'GENERAL_EVENTS' | translate }}</button>
  <button [class.active]="tab === 'ctalist'" (click)="tab = 'ctalist'">{{ 'GENERAL_CTA_LIST' | translate }}</button>
  <button [class.active]="tab === 'ctalistInteraction'" (click)="tab = 'ctalistInteraction'">{{ 'GENERAL_CTA_LIST_INTERACTION' | translate }}</button>
  <button [class.active]="tab === 'design'" (click)="tab = 'design'">{{ 'GENERAL_DESIGN' | translate }}</button>
  <button [class.active]="tab === 'accesspolicy'" (click)="tab = 'accesspolicy'">{{ 'GENERAL_CONDITIONS' | translate }}</button>
</div>

<div *ngIf="tab == 'general'" class="sidebar-body">
  <c-page-patch-input *ngFor="let inputJsonpath of inputJsonpathes" [jsonpath]="[jsonpath, inputJsonpath]" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'events'" class="sidebar-body">
  <c-page-patch-input
    [jsonpath]="[jsonpath, '$.local.$language.events']"
    [jsonpathParams]="jsonpathParams"
    [object]="eventVersion || page"
    [inputConfiguration]="inputConfigurationEvent"
  ></c-page-patch-input>
</div>

<div *ngIf="tab == 'design'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.backgroundTheme']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <p-accordion>
    <p-accordionTab header="Top Area">
      <c-page-patch-input *ngFor="let inputJsonpath of topAreaJsonpathes" [jsonpath]="[jsonpath, inputJsonpath]" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    </p-accordionTab>
    <p-accordionTab header="Content Area">
      <c-page-patch-input
        *ngFor="let inputJsonpath of contentAreaJsonpathes"
        [jsonpath]="[jsonpath, inputJsonpath]"
        [jsonpathParams]="jsonpathParams"
        [object]="eventVersion || page"
      ></c-page-patch-input>
    </p-accordionTab>
    <p-accordionTab header="CTA Area">
      <c-page-patch-input *ngFor="let inputJsonpath of ctaAreaJsonpathes" [jsonpath]="[jsonpath, inputJsonpath]" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    </p-accordionTab>
  </p-accordion>
</div>

<div *ngIf="tab == 'ctalist'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.ctalist']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'ctalistInteraction'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.ctalistInteraction']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'accesspolicy'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.accessPolicy']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page" [inputConfiguration]="accessInputConfiguration"></c-page-patch-input>
</div>
