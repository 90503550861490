<div class="quick-filters">
  <i class="pi icon-ic-filter"></i>
  <span class="title">{{ title || ('GENERAL_QUICK_FILTERS' | translate) }}:</span>
  <div class="filter-group">
    <div *ngIf="active">
      <p-button
        *ngFor="let filter of quickFilters"
        [class.active]="filter.active"
        [icon]="filter.active ? 'pi pi-check' : 'pi pi-times'"
        [label]="filter.name"
        (click)="toggleQuickFilters(filter)"
      ></p-button>
    </div>
    <div *ngIf="!active">
      <p-button *ngFor="let filter of quickFilters" [class.active]="filter.active" [label]="filter.name" (click)="toggleQuickFilters(filter)"></p-button>
    </div>
  </div>
</div>
