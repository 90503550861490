import { Injectable } from '@angular/core';
import { GetShowroomConfigurationsQuery, GetShowroomConfigurationsResponse } from 'src/common/api/v1/showrooms/GetShowroomConfigurations';
import { PostGenerateBody, PostGenerateQuery, PostGenerateResponse } from 'src/common/api/v1/showrooms/PostGenerate';
import { Showroom } from 'src/common/entities/Showroom';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ShowroomsService {
  constructor(private apiService: ApiService) {}

  async generateToken(request: PostGenerateBody): Promise<Showroom> {
    return this.apiService.post<PostGenerateQuery, PostGenerateBody, PostGenerateResponse>('/api/v1/showrooms/generate', request).toPromise();
  }

  async getShowroomConfigurations(query?: GetShowroomConfigurationsQuery): Promise<GetShowroomConfigurationsResponse> {
    return this.apiService.get<GetShowroomConfigurationsQuery, GetShowroomConfigurationsResponse>('/api/v1/showrooms/configurations', { limit: 50, skip: 0, ...query }).toPromise();
  }
}
