import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppointmentsService } from 'src/app/services/appointments/appointments.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { I18nService } from 'src/app/services/i18n/i18n.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { IFilterableListQuery } from 'src/common/api/interfaces';
import { AdminUser } from 'src/common/entities/AdminUser';
import { Appointment } from 'src/common/entities/Appointment';

interface ActionItem {
  label: string;
  action: () => void;
  menuItems: MenuItem[];
}

@Component({
  selector: 'app-appointments-table',
  templateUrl: './appointments-table.component.html',
  styleUrls: ['./appointments-table.component.scss'],
})
export class AppointmentsTableComponent implements OnInit {
  @Input()
  group: string;

  loading = true;

  appointmentsSubject: BehaviorSubject<Appointment[]>;
  appointments: Observable<Appointment[]>;
  watchAppointments: Observable<Appointment[]>;
  totalRecords: number = 0;

  isTracking = true;
  displayAppointmentDetail = false;

  appointment: Appointment;

  search: {
    user: string;
    host: string;
    organizer: string;
    assigned: { name: string; code: boolean } | null;
    assignedDropdown: { name: string; code: boolean }[];
    origin: { name: string; code: boolean } | null;
    originDropdown: { name: string; code: string }[];
  } = {
    user: null,
    host: null,
    organizer: null,
    assigned: null,
    assignedDropdown: [
      { name: 'Yes', code: true },
      { name: 'No', code: false },
    ],
    origin: null,
    originDropdown: [
      { name: 'Customer', code: 'Customer' },
      { name: 'Host', code: 'Host' },
      { name: 'Organizer', code: 'Organizer' },
    ],
  };

  get currentAdminUser(): AdminUser {
    return this.authService.currentAdminUser();
  }

  get currentAdminUserId(): string {
    return this.currentAdminUser._id;
  }

  get organizerGroup(): string {
    return this.configurationService.configuration()['appointment']['organizerGroup'];
  }

  get hasOrganizerGroup(): boolean {
    return this.currentAdminUser.admin || this.currentAdminUser.groups.includes(this.organizerGroup);
  }

  constructor(
    private appointmentsService: AppointmentsService,
    private translate: TranslateService,
    private authService: AuthService,
    private configurationService: ConfigurationService,
    private router: Router,
    private utilsService: UtilsService,
    public i18nService: I18nService
  ) {}

  ngOnInit(): void {
    // this.load()

    if (this.group !== 'open') {
      this.appointmentsSubject = new BehaviorSubject<Appointment[]>([]);
      this.appointments = this.appointmentsSubject.asObservable();
      this.watchAppointments = this.appointmentsService.appointmentsByGroup(this.group);
    }
  }

  async load(loadEvent?: LazyLoadEvent) {
    this.loading = true;

    try {
      const query: IFilterableListQuery = {
        skip: 0,
        limit: 30,
        ...(loadEvent ? this.utilsService.loadEventToQuery(loadEvent) : {}),
        filter: {
          ...(this.search.host ? { 'participants.host': { value: this.search.host, matchMode: 'equalsObjectId' } } : {}),
          ...(this.search.user ? { 'participants.users': { value: this.search.user, matchMode: 'equalsObjectId' } } : {}),
          ...(this.search.organizer ? { organizer: { value: this.search.organizer, matchMode: 'equalsObjectId' } } : {}),
          ...(this.search.assigned ? { organizer: { value: null, matchMode: this.search.assigned.code === true ? 'notEquals' : 'equals' } } : {}),
          ...(this.search.origin ? { origin: { value: this.search.origin.code, matchMode: 'equals' } } : {}),
        },
      };

      if (this.group === 'open') {
        this.appointments = this.appointmentsService.appointmentsByGroup(this.group, query);
        this.totalRecords = 0;
      } else {
        const appointments = await this.appointmentsService.closedAppointments(query);
        this.appointmentsSubject.next(appointments.items);
        this.totalRecords = appointments.totalCount;
      }
    } catch (err) {
      console.error(err);
    }

    this.loading = false;
  }

  public getActionItem = (appointment: Appointment): ActionItem => {
    const viewButton = {
      label: this.translate.instant('PAGE_APPOINTMENTS_GENERAL_BUTTON_VIEW_APPOINTMENT'),
      command: () => {
        this.view(appointment);
      },
    };
    const setMeetingDoneButton = {
      label: this.translate.instant('PAGE_APPOINTMENTS_GENERAL_BUTTON_SET_MEETING_STATUS_DONE'),
      command: () => {
        this.stopAppointment(appointment);
      },
    };

    if (appointment.participants.host === this.currentAdminUserId || appointment.participants.adminUsers.includes(this.currentAdminUserId)) {
      if (appointment.status === 'Confirmed') {
        return {
          label: 'Start Meeting', // this.translate.instant('PAGE_APPOINTMENTS_GENERAL_BUTTON_EXECUTE_APPOINTMENT'),
          action: () => {
            this.startMeeting(appointment, true);
          },
          menuItems: [...(appointment.participants.host === this.currentAdminUserId ? [viewButton, setMeetingDoneButton] : [viewButton])],
        };
      }
    }

    if (appointment.origin !== 'Host' && this.hasOrganizerGroup) {
      if (appointment.organizer !== this.currentAdminUserId) {
        return {
          label: this.translate.instant('GENERAL_ASSIGN_AND_EDIT'),
          action: () => {
            this.assignAndEdit(appointment);
          },
          menuItems: [viewButton],
        };
      }
    }

    return {
      label: this.translate.instant('PAGE_APPOINTMENTS_GENERAL_BUTTON_VIEW_APPOINTMENT'),
      action: () => {
        this.view(appointment);
      },
      menuItems: [],
    };
  };

  view(appointment: Appointment) {
    this.router.navigate(['/appointments', appointment._id]);
  }

  startMeeting(appointment: Appointment, isTracking: boolean) {
    this.isTracking = isTracking;
    this.appointment = appointment;
    this.displayAppointmentDetail = true;
  }

  async assign(appointment: Appointment) {
    await this.appointmentsService.assignAppointment(appointment, this.currentAdminUserId);
  }

  async assignAndEdit(appointment: Appointment) {
    await this.assign(appointment);
    this.view(appointment);
  }

  showDialogMaximized(event, dialog: Dialog) {
    dialog.maximized = true;
  }

  getUserId(): string {
    return this.authService.currentAdminUser()._id;
  }

  isAllowedToEditAppointment(): boolean {
    return this.authService.hasGlobalRight('appointments.edit');
  }

  async stopAppointment(appointment: Appointment) {
    await this.appointmentsService.stopAppointment(appointment);
  }
}
