import { TableOptions, TableQuery } from 'src/app/components/table/table.interfaces';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from '../../services/utils/utils.service';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { OperatingCountriesService } from 'src/app/services/operating-countries/operating-countries.service';
import { OperatingCountry } from 'src/common/entities/OperatingCountry';
import { OperatingCountryFactory } from 'src/common/factories/OperatingCountryFactory';
import { TableComponent } from 'src/app/components/table/table.component';
import { InputConfiguration, Inputs } from 'src/common/inputs/Inputs';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';
import { Language } from '../../../common/entities/Language';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-operating-countries',
  templateUrl: './operating-countries.component.html',
  styleUrls: ['./operating-countries.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OperatingCountriesComponent implements OnInit, OnDestroy {
  tab: Tab;
  tableOptions: TableOptions<OperatingCountry>;
  showSidebar: boolean = false;
  editMode: boolean = false;
  operatingCountry: OperatingCountry;
  languages: Language[];
  languageTags: string[];
  currentLanguage: string;
  hasDeleteRight: boolean = false;

  sidebarTab = 'general';

  @ViewChild('operatingCountriesTable') operatingCountriesTable: TableComponent<any>;

  constructor(
    private authService: AuthService,
    private operatingCountriesService: OperatingCountriesService,
    public utilsService: UtilsService,
    private tabsService: TabsService,
    private activatedRoute: ActivatedRoute,
    private languagesService: LanguagesService,
    private collaborationService: CollaborationService
  ) {
    this.tableOptions = {
      size: 50,
      columns: [
        { header: 'GENERAL_INTERNAL_NAME', sort: 'internalName' },
        { header: 'PAGE_OPERATING_COUNTRIES_BELONGS_TO_REGION', sort: 'belongsToRegion' },
        { header: 'PAGE_OPERATING_COUNTRIES_IS_DEFAULT_OPERATING_COUNTRY', sort: 'isDefaultOperatingCountry' },
      ],
      filters: [{ header: 'GENERAL_INTERNAL_NAME', path: 'internalName' }],
    };
  }

  inputConfig: InputConfiguration;

  inputs: Inputs;
  get jsonPathes(): string[] {
    return Object.keys(this.inputs);
  }

  async ngOnInit(): Promise<void> {
    this.hasDeleteRight = await this.authService.hasRight('configuration.delete');
    this.tab = this.tabsService.register({
      category: 'configuration',
      route: this.activatedRoute.snapshot,
    });
    this.languages = (await this.languagesService.getLanguages())?.items.filter((i) => i.selectable);
    this.languageTags = this.languages.map((i) => i.language);
    const language = this.activatedRoute.snapshot.queryParams.language || 'en';
    this.currentLanguage = this.languageTags.includes(language) ? language : this.languageTags[0];

    this.inputConfig = { languages: this.languageTags };
    this.inputs = {
      '$.internalName': {
        type: 'text',
      },
      '$.isoCode': {},
      '$.local': {
        factory: async () => ({}),
      },
      '$.local.$language': {
        factory: async () => {
          return new OperatingCountryFactory().createOperatingCountryLocal({});
        },
      },
      '$.local.$language.title': {
        type: 'text',
      },
      '$.flag': {
        type: 'imageasset',
      },
      '$.belongsToRegion': {
        header: 'Belongs To Region',
        type: 'operatingregion',
      },
      '$.tags': {
        type: 'tags',
        multiselect: true,
      },
      '$.isDefaultOperatingCountry': {
        type: 'switch',
      },
      '$.mappedIsoCodes': {
        header: 'Mapped ISO Codes',
        factory: async () => [],
        list: true,
        childFactory: async () => '',
      },
      '$.mappedIsoCodes[$index]': {
        type: 'text',
        header: 'ISO Code',
      },
      '$.favoriteLanguages': {
        header: 'Favorite Languages',
        factory: async () => [],
        list: true,
        childFactory: async () => '',
      },
      '$.favoriteLanguages[$index]': {
        type: 'dropdown',
        dropdownOptions: this.languages.map(({ language, languageName }) => ({ label: languageName, value: language })),
        header: 'Language',
      },
    };
  }

  ngOnDestroy(): void {
    this.collaborationService.unregisterLocal(`operatingCountry:${this.operatingCountry._id}`);
  }

  query(query: TableQuery<OperatingCountry>) {
    query.result = this.operatingCountriesService.getOperatingCountries(query.query);
  }

  async create(): Promise<void> {
    this.editMode = false;
    this.operatingCountry = null;
    this.operatingCountry = await new OperatingCountryFactory({ ensureLocals: [this.currentLanguage] }).createOperatingCountry();
    this.collaborationService.registerLocal(`operatingCountry:${this.operatingCountry._id}`, this.operatingCountry, true);
    this.showSidebar = true;
  }

  async edit(operatingCountry: OperatingCountry): Promise<void> {
    this.editMode = true;
    this.sidebarTab = 'general';
    this.operatingCountry = await new OperatingCountryFactory({ ensureLocals: [this.currentLanguage] }).createOperatingCountry(operatingCountry);
    this.collaborationService.registerLocal(`operatingCountry:${this.operatingCountry._id}`, this.operatingCountry, true);
    this.showSidebar = true;
  }

  async save(): Promise<void> {
    if (!this.editMode) {
      await this.operatingCountriesService.createOperatingCountry(this.operatingCountry);
    } else {
      await this.operatingCountriesService.updateOperatingCountry(this.operatingCountry);
    }
    await this.operatingCountriesTable.refresh(true);
  }

  async delete(): Promise<void> {
    await this.operatingCountriesService.deleteOperatingCountry(this.operatingCountry._id);
    await this.operatingCountriesTable.refresh(true);
  }

  async ensureLanguages(languages: string[]) {
    for (const language of languages) {
      if (!this.operatingCountry.local[language]) {
        this.operatingCountry.local[language] = await new OperatingCountryFactory().createOperatingCountryLocal({});
      }
    }
  }

  handleSidebarClose() {
    this.showSidebar = false;
    this.editMode = false;
    this.collaborationService.unregisterLocal(`operatingCountry:${this.operatingCountry._id}`);
  }
}
