import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Reaction } from '../../../common/entities/Reaction';
import { PostReactionsBody, PostReactionsQuery, PostReactionsResponse } from '../../../common/api/v1/reactions/PostReactions';
import { PostReactionBody, PostReactionQuery, PostReactionResponse } from '../../../common/api/v1/reactions/PostReaction';
import { GetReactionQuery, GetReactionResponse } from '../../../common/api/v1/reactions/GetReaction';
import { GetReactionsQuery, GetReactionsResponse } from '../../../common/api/v1/reactions/GetReactions';

@Injectable({
  providedIn: 'root',
})
export class ReactionsService {
  constructor(private apiService: ApiService) {}

  getReaction(reactionId: string): Promise<Reaction> {
    return this.apiService.get<GetReactionQuery, GetReactionResponse>(`/api/v1/events/reactions/${reactionId}`).toPromise();
  }

  getReactions(query?: GetReactionsQuery): Promise<GetReactionsResponse> {
    return this.apiService
      .get<GetReactionsQuery, GetReactionsResponse>('/api/v1/events/reactions', {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();
  }

  createReaction(reaction: Reaction): Promise<Reaction> {
    return this.apiService.post<PostReactionsQuery, PostReactionsBody, PostReactionsResponse>('/api/v1/events/reactions', reaction).toPromise();
  }

  updateReaction(reaction: Reaction): Promise<Reaction> {
    return this.apiService.post<PostReactionQuery, PostReactionBody, PostReactionResponse>(`/api/v1/events/reactions/${reaction._id}`, reaction).toPromise();
  }
}
