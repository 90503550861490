import { CRMEvent, CRMEventData } from '../entities/CRMEvent';
import { Event } from '../entities/Event';
import { AbstractFactory, FactoryOptions, Values } from './AbstractFactory';
import { EventVersionFactory } from './EventVersionFactory';

export class EventFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createEvent<T extends Event>(values: any): Promise<T> {
    const eventId = await this.id(values?._id);
    return {
      ...values,
      _id: eventId,
      announcedAt: values?.announcedAt || null,
      internalName: values?.internalName || '',
      eventType: values?.eventType || null,
      currentEventVersion: await AbstractFactory.get(EventVersionFactory, this.options).createEventVersion({ ...values?.currentEventVersion, event: eventId }),
      deletedAt: values?.deletedAt || null,
      deletedBy: values?.deletedBy || null,
      public: values?.public || false,
      hideLoginButton: values?.hideLoginButton || false,
      hideLanguageSelector: values?.hideLanguageSelector || false,
      showBackButton: typeof values?.showBackButton === 'boolean' ? values?.showBackButton : false,
      releasedAt: values?.releasedAt || null,
      sessions: values?.sessions || [],
      shortId: values?.shortId || '',
      totalEndAt: values?.totalEndAt || null,
      totalStartAt: values?.totalStartAt || null,
      totalVersions: values?.totalVersions || 0,
      promoted: values?.promoted || false,
      country: values?.country || null,
      eventSerie: values?.eventSerie || null,
      externalEvent: values?.externalEvent || null
    } as T;
  }

  createCRMEventData(values: Values<CRMEventData>): CRMEventData {
    return {
      id: values?.id || '',
      subIds: (values?.subIds || []).map(s => ({
        id: s?.id || '',
        type: ['digital', 'onSite'].includes(s?.type || '') ? s.type : undefined,
        eventTicket: s?.eventTicket,
        plain: s?.plain || null
      })),
      plain: values?.plain || null
    };
  }

  async createCRMEvent(values: Values<CRMEvent>): Promise<CRMEvent> {
    return {
      ...values,
      _id: await this.id(values._id),
      date: values?.date || null,
      event: values?.event || null,
      plugin: values?.plugin || null,
      data: this.createCRMEventData(values?.data || {})
    } as CRMEvent;
  }
}
