<div class="filter" *ngIf="type === 'string'">
  <input type="text" [(ngModel)]="value" (ngModelChange)="valueChanged()" />
  <i class="pi pi-times delete-input" (click)="clear()" *ngIf="value"></i>
</div>

<div class="filter" *ngIf="type === 'date'">
  <input type="date" [(ngModel)]="value" (ngModelChange)="valueChanged()" />
  <!-- <i class="pi pi-times delete-input" (click)="clear()" *ngIf="value"></i> -->
</div>

<div class="filter" *ngIf="type === 'boolean'">
  <select [(ngModel)]="value" (ngModelChange)="valueChanged()">
    <option *ngIf="nullOption" [ngValue]="null" selected></option>
    <option [ngValue]="true">{{ 'GENERAL_YES' | translate }}</option>
    <option [ngValue]="false">{{ 'GENERAL_NO' | translate }}</option>
  </select>
</div>

<div class="filter p-d-flex p-ai-center" *ngIf="type === 'boolean-radio'">
  <input type="radio" [value]="true" [(ngModel)]="value" (ngModelChange)="valueChanged()" class="boolean-radio p-mr-1" />
  <i class="pi pi-check p-mr-1 success"></i>

  <input type="radio" [value]="false" [(ngModel)]="value" (ngModelChange)="valueChanged()" class="boolean-radio p-mr-1" />
  <i class="pi pi-times p-mr-1 danger"></i>

  <input *ngIf="nullOption" type="radio" [value]="null" [(ngModel)]="value" (ngModelChange)="valueChanged()" class="boolean-radio p-mr-1" />
  <div *ngIf="nullOption">{{ 'GENERAL_ALL' | translate }}</div>
</div>

<div class="filter" *ngIf="type === 'select'">
  <select [(ngModel)]="value" (ngModelChange)="valueChanged()">
    <option *ngIf="nullOption" [ngValue]="null" selected></option>
    <div *ngIf="optionValue; then valueBlock; else optionBlock"></div>
    <ng-template #valueBlock>
      <option *ngFor="let option of options" [ngValue]="option[optionValue]">{{ option[optionLabel] }}</option>
    </ng-template>
    <ng-template #optionBlock>
      <option *ngFor="let option of options" [ngValue]="option">{{ option[optionLabel] }}</option>
    </ng-template>
  </select>
</div>

<div class="filter" *ngIf="!type">
  <input type="text" disabled="disabled" />
</div>
