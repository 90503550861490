import {
  AppFunctionMenuItem,
  AppMenuItem,
  AppNavigationMenuItem,
  DialogMenuItem,
  ExploreMenuItem,
  FormMenuItem,
  LinkMenuItem,
  MenuItem,
  MenuItemLocal,
  MenuItemType,
  MobileQuickAccessMenuItem,
  PageMenuItem,
  WebMenuItem
} from '../entities/MenuItem';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { AppFunctionFactory } from './AppFunctionFactory';

export class MenuItemFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async menuItemLocal(values: any) {
    return {
      ...values,
      title: values?.title || '',
      topTitle: values?.topTitle || ''
    } as MenuItemLocal;
  }

  private async basicMenuItem(values: any): Promise<MenuItem> {
    let local: { [language: string]: MenuItemLocal } = {};

    for (const language of this.localKeys(values?.local)) {
      local[language] = await this.menuItemLocal(values.local[language]);
    }

    return {
      _id: await this.id(values._id),
      local: local,
      menuItemType: values.menuItemType,
      readAccess: values?.readAccess || [],
      executeAccess: values?.executeAccess || [],
      icon: values?.icon || null,
      hoverIcon: values?.hoverIcon || null,
      activeIcon: values?.activeIcon || null
    };
  }

  public async pageMenuItem(values: any) {
    return {
      ...(await this.basicMenuItem(values)),
      page: values?.page || null
    } as PageMenuItem;
  }

  public async linkMenuItem(values: any) {
    const lmi = {
      ...(await this.basicMenuItem(values))
    } as LinkMenuItem;

    for (const language of Object.keys(lmi.local || {})) {
      lmi.local[language].link = lmi.local[language].link || '';
    }

    return lmi;
  }

  public async dialogMenuItem(values: any) {
    return {
      ...(await this.basicMenuItem(values)),
      dialog: values?.dialog || 'login'
    } as DialogMenuItem;
  }

  public async formMenuItem(values: any) {
    return {
      ...(await this.basicMenuItem(values)),
      form: values?.form || null
    } as FormMenuItem;
  }

  public async appFunctionMenuItem(values: any) {
    return {
      ...(await this.basicMenuItem(values)),
      appFunction: new AppFunctionFactory(this.options).createAppFunction(values?.appFunction),
      menuItemType: 'AppFunction'
    } as AppFunctionMenuItem;
  }

  public async exploreMenuItem(values: any) {
    return {
      ...(await this.basicMenuItem(values)),
      menuItemType: 'Explore'
    } as ExploreMenuItem;
  }

  public async mobileQuickAccessMenuItem(values: any) {
    return {
      ...values,
      showAsCircleButton: values?.showAsCircleButton || false
    } as MobileQuickAccessMenuItem;
  }

  public async appNavigationMenuItem(values: any) {
    return {
      ...values,
      showAsCircleButton: values?.showAsCircleButton || false
    } as AppNavigationMenuItem;
  }

  public async appWebMenuItem(values: any) {
    return {
      ...values
    } as WebMenuItem;
  }

  public async appMenuItem(values: any) {
    return {
      ...values
    } as AppMenuItem;
  }

  public async menuItem(values: { menuItemType: MenuItemType }): Promise<MenuItem> {
    switch (values.menuItemType) {
      case 'Page':
        return { ...values, ...(await this.pageMenuItem(values)) };
      case 'Link':
        return { ...values, ...(await this.linkMenuItem(values)) };
      case 'Dialog':
        return { ...values, ...(await this.dialogMenuItem(values)) };
      case 'Form':
        return { ...values, ...(await this.formMenuItem(values)) };
      case 'AppFunction':
        return { ...values, ...(await this.appFunctionMenuItem(values)) };
      case 'Explore':
        return { ...values, ...(await this.exploreMenuItem(values)) };
    }
  }

  public async asMobileQuickAccessMenuItem(values: { menuItemType: MenuItemType }): Promise<MobileQuickAccessMenuItem> {
    return { ...(await this.menuItem(values)), ...(await this.mobileQuickAccessMenuItem(values)) };
  }

  public async asAppNavigationMenuItem(values: { menuItemType: MenuItemType }): Promise<MobileQuickAccessMenuItem> {
    return { ...(await this.menuItem(values)), ...(await this.appNavigationMenuItem(values)) };
  }

  public async asWebMenuItem(values: { menuItemType: MenuItemType }): Promise<WebMenuItem> {
    return { ...(await this.menuItem(values)), ...(await this.appWebMenuItem(values)) };
  }

  public async asAppMenuItem(values: { menuItemType: MenuItemType }): Promise<AppMenuItem> {
    return { ...(await this.menuItem(values)), ...(await this.appMenuItem(values)) };
  }
}
