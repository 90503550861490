import { titlePositions } from './../../entities/pagemodules/PageModule';
import { ListVideoPageModuleFactory } from './../../factories/pagemodules/ListVideoPageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';
import { textAttributesInputs } from '../attributes/TextAttributesInputs';

export const listVideoPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('ListVideo'),
  "$.pageModules[?(@.pageModuleType=='ListVideo' && @._id=='$pageModuleId')].ctaButton": {},

  "$.pageModules[?(@.pageModuleType=='ListVideo' && @._id=='$pageModuleId')].local.$language.itemIcon": {
    type: 'imageasset',
  },
  "$.pageModules[?(@.pageModuleType=='ListVideo' && @._id=='$pageModuleId')].local.$language.upperText": {
    header: 'Upper Text',
    type: 'textarea',
  },
  "$.pageModules[?(@.pageModuleType=='ListVideo' && @._id=='$pageModuleId')].local.$language.lowerText": {
    header: 'Lower Text',
    type: 'textarea',
  },

  "$.pageModules[?(@.pageModuleType=='ListVideo' && @._id=='$pageModuleId')].videoSize": {
    type: 'number',
    header: 'Width %',
  },
  "$.pageModules[?(@.pageModuleType=='ListVideo' && @._id=='$pageModuleId')].videoPosition": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Left', value: 'left' },
      { label: 'Right', value: 'right' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='ListVideo' && @._id=='$pageModuleId')].videoPositionMobile": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Bottom', value: 'bottom' },
      { label: 'Top', value: 'top' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='ListVideo' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new ListVideoPageModuleFactory().listVideoPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='ListVideo' && @._id=='$pageModuleId')].local.$language.video": {
    type: 'videoasset',
  },
  "$.pageModules[?(@.pageModuleType=='ListVideo' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='ListVideo' && @._id=='$pageModuleId')].local.$language.items": {
    factory: async () => [],
    list: true,
    childFactory: async () => '',
  },
  "$.pageModules[?(@.pageModuleType=='ListVideo' && @._id=='$pageModuleId')].local.$language.items[$index]": {
    header: 'Item',
  },
  "$.pageModules[?(@.pageModuleType=='ListVideo' && @._id=='$pageModuleId')].videoAppearanceMobile": {
    header: 'Video Appearance Mobile',
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Content Area ', value: 'contentArea ' },
      { label: 'Full Width With Margin', value: 'fullWidthMargin' },
      { label: 'Full width with margin', value: 'fullWidth' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='ListVideo' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [{ label: 'content', value: 'content' }].concat(
      [...titlePositions].map((item) => ({
        label: item,
        value: item,
      }))
    ),
  },
};
