<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'PAGE_EXPORTS_HEADER' | translate }}</h1>
  </div>
  <div class="tab-menu"></div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_EXPORTS_HEADER' | translate }}
    </div>
    <c-table mode="query" [options]="tableOptions" (query)="query($event)">
      <ng-template let-export>{{ export.type }}</ng-template>
      <ng-template let-export>{{ export.createdAt }}</ng-template>
      <ng-template let-export>{{ export.finishedAt }}</ng-template>
      <ng-template let-export>{{ export.successful ? 'successful' : 'error' }}</ng-template>
      <ng-template let-export>{{ export.params | json }}</ng-template>
      <ng-template let-export>
        <div *hasRight="'exports.read'">
          <div *ngIf="export.results">
            <div *ngIf="export.results.length == 0">
              <i>No results</i>
            </div>
            <div *ngIf="export.results.length == 1">
              <a [routerLink]="[]" (click)="download(export, export.results[0])">{{ export.results[0].fileName }}</a>
            </div>
            <div *ngIf="export.results.length > 1">
              <i
                ><a [routerLink]="[]" (click)="showResults[export._id] = !showResults[export._id]">{{ showResults[export._id] ? '... Hide Results' : '... Show Results' }}</a
                >&nbsp;({{ export.results.length }})</i
              >
              <div *ngIf="showResults[export._id]">
                <div>&nbsp;</div>
                <div *ngFor="let result of export.results">
                  <a [routerLink]="[]" (click)="download(export, result)">{{ result.fileName }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </c-table>
  </div>
</div>
