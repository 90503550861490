import { PollOption } from '../../entities/Poll';
import { PollFactory } from '../../factories/PollFactory';
import { Inputs } from '../Inputs';

export const pollInputs: Inputs = {
  '$.internalName': {
    header: 'GENERAL_INTERNAL_NAME',
    type: 'text'
  },
  '$.minSelections': {
    header: 'PAGE_EVENT_SECTION_POLLS_MINSELECTIONS',
    type: 'number'
  },
  '$.maxSelections': {
    header: 'PAGE_EVENT_SECTION_POLLS_MAXSELECTIONS',
    type: 'number'
  },

  '$.local.$language': {
    factory: async () => new PollFactory().createPollLocal()
  },
  '$.local.$language.title': {
    header: 'GENERAL_TITLE',
    type: 'text'
  },
  '$.local.$language.description': {
    header: 'GENERAL_DESCRIPTION',
    type: 'textarea'
  },
  '$.options': {
    header: 'Options',
    listKey: '_id',
    listValue: 'optionId',
    factory: async () => [],
    list: true,
    childFactory: async () => new PollFactory().createPollOption({}),
    childLabel: (item: PollOption, index: number) => item?.internalName as string,
    childDescription: (item: PollOption, index: number, lang?: string) => item?.local[lang ? lang : 'en']?.description as string
  },
  "$.options[?(@._id=='$optionId')].internalName": {
    header: 'GENERAL_INTERNAL_NAME',
    type: 'text'
  },
  "$.options[?(@._id=='$optionId')].local.$language": {
    factory: async () => {
      return new PollFactory().createPollOptionLocal({});
    }
  },
  "$.options[?(@._id=='$optionId')].local.$language.label": {
    header: 'GENERAL_LABEL',
    type: 'text'
  },
  "$.options[?(@._id=='$optionId')].local.$language.description": {
    header: 'GENERAL_DESCRIPTION',
    type: 'textarea'
  },
  '$.theme.background': {
    header: 'Background',
    type: 'imageasset'
  },

  '$.theme.font': {
    header: 'Font',
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Netto web pro', value: 'NettoWebPro' },
      { label: 'Roboto', value: 'Roboto' }
    ]
  },

  '$.theme.fontcolor': {
    header: 'Font Color',
    type: 'text'
  },

  '$.theme.leadingBarBackgroundColor': {
    header: 'Leading Bar Background Color',
    type: 'text'
  },

  '$.theme.leadingBarFontColor': {
    header: 'Leading Bar Font Color',
    type: 'text'
  },

  '$.theme.barBackgroundColor': {
    header: 'Bar Background Color',
    type: 'text'
  },

  '$.theme.barFontColor': {
    header: 'Bar Font Color',
    type: 'text'
  },

  '$.theme.showVotesInBar': {
    header: 'Show Votes in Bar',
    type: 'switch'
  },

  '$.theme.showVotesTotal': {
    header: 'Show Votes Total',
    type: 'switch'
  }
};
