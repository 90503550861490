import { Notification, NotificationLocal } from '../entities/Notification';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';

export class NotificationFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createNotification(values?: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      createdBy: values?.createdBy || '',
      createdAt: values?.createdAt ? new Date(values?.createdAt) : new Date(),
      data: this.createNotificationData(values?.data) || null,
      local: this.localKeys(values?.local).reduce((a, b) => ({ ...a, [b]: this.createNotificationLocal(values?.local?.[b]) }), {})
    } as Notification;
  }

  createNotificationData(values?: any) {
    switch (values?.type) {
      case 'OpenEventPage':
        return { ...values, type: 'OpenEventPage', event: values?.event, page: values?.page };
      case 'OpenApp':
        return { ...values, type: 'OpenApp' };
    }
  }

  createNotificationLocal(values?: any) {
    return {
      ...values,
      title: values?.title || '',
      text: values?.text || ''
    } as NotificationLocal;
  }
}
