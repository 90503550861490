import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';

import { CacheContainer } from '../cache/cache-container';
import { CacheService } from '../cache/cache.service';
import { ChatMessageTemplate } from 'src/common/entities/ChatMessageTemplate';
import { GetChatMessageTemplatesQuery, GetChatMessageTemplatesResponse } from 'src/common/api/v1/configuration/chatMessageTemplates/GetChatMessageTemplates';
import { PostChatMessageTemplatesBody, PostChatMessageTemplatesQuery, PostChatMessageTemplatesResponse } from 'src/common/api/v1/configuration/chatMessageTemplates/PostChatMessageTemplates';
import { PostChatMessageTemplateBody, PostChatMessageTemplateQuery, PostChatMessageTemplateResponse } from 'src/common/api/v1/configuration/chatMessageTemplates/PostChatMessageTemplate';

@Injectable({
  providedIn: 'root',
})
export class ChatMessageTemplatesService {
  private _cache: CacheContainer<ChatMessageTemplate>;

  constructor(private apiService: ApiService, private cacheService: CacheService) {
    this._cache = this.cacheService.create<ChatMessageTemplate>({
      id: (value) => value._id,
      fill: async () => (await this.apiService.get<GetChatMessageTemplatesQuery, GetChatMessageTemplatesResponse>('/api/v1/configuration/chatmessagetemplates').toPromise()).items,
      get: async (user) => null,
      groups: [],
      socketEvents: [],
    });
  }

  async getChatMessageTemplates(query?: GetChatMessageTemplatesQuery): Promise<GetChatMessageTemplatesResponse> {
    const result = await this.apiService
      .get<GetChatMessageTemplatesQuery, GetChatMessageTemplatesResponse>('/api/v1/configuration/chatmessagetemplates', {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();

    this._cache.fill(result.items);

    return result;
  }

  async createChatMessageTemplate(chatMessageTemplate: ChatMessageTemplate): Promise<ChatMessageTemplate> {
    return this._cache.post<PostChatMessageTemplatesQuery, PostChatMessageTemplatesBody, PostChatMessageTemplatesResponse>('/api/v1/configuration/chatmessagetemplates', chatMessageTemplate);
  }

  async updateChatMessageTemplate(chatMessageTemplate: ChatMessageTemplate): Promise<ChatMessageTemplate> {
    return this._cache.post<PostChatMessageTemplateQuery, PostChatMessageTemplateBody, PostChatMessageTemplateResponse>(
      `/api/v1/configuration/chatmessagetemplates/${chatMessageTemplate._id}`,
      chatMessageTemplate
    );
  }
}
