import { Speaker } from 'src/common/entities/Speaker';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { SpeakersService } from 'src/app/services/speakers/speakers.service';
import { debounceTime } from 'rxjs/operators';
import { IFilterList } from 'src/common/api/interfaces';
import { AssetsService } from 'src/app/services/assets/assets.service';
import { Asset } from 'src/common/entities/Asset';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'c-speaker-select',
  templateUrl: './speaker-select.component.html',
  styleUrls: ['./speaker-select.component.scss']
})
export class SpeakerSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  onBlur?: Function;

  @Input()
  onFocus?: Function;

  @Input()
  disabled = false;

  @Input()
  value: string;

  @Input()
  hideRemoveLink: boolean;

  @Input()
  refreshManually: boolean;

  @Input()
  templateSearch: boolean;

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  showSearch: boolean = false;
  searchText: string;
  searchTextSubject: Subject<string> = new Subject<string>();

  currentLanguage: string;
  selectedSpeaker: Speaker;
  allSpeakerTypes: { [id: string]: string } = {};
  allTeaserAssets: { [id: string]: Asset } = {};
  searchResult: Speaker[] = [];
  currentPage: number = 0;
  assetsPerPage = 20;
  isLoading = false;
  private subscriptions: Subscription[] = [];

  constructor(private speakersService: SpeakersService, private assetsService: AssetsService, private activatedRoute: ActivatedRoute) {}
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.value && changes.value.currentValue !== changes.value.previousValue) {
      this.setCurrentSpeaker();
    } else {
      if ((!changes.value || !changes.value.currentValue) && this.hideRemoveLink && (!changes?.disabled || !changes?.disabled.previousValue)) {
        this.openSearch();
      }
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.currentLanguage = this.activatedRoute.snapshot.queryParams.language || 'en';

    this.subscriptions.push(this.searchTextSubject.pipe(debounceTime(500)).subscribe(() => this.executeSearch(true)));

    this.executeSearch();
  }

  searchTextKeyUp() {
    this.searchTextSubject.next(this.searchText);
  }

  setCurrentSpeaker() {
    if (this.value) {
      this.speakersService.getSpeaker(this.value).then(se => {
        this.selectedSpeaker = se;

        if (se.picture) {
          this.subscriptions.push(this.assetsService.getAsset(se.picture).subscribe(ti => (this.allTeaserAssets[se._id] = ti)));
        }
      });
    } else {
      this.selectedSpeaker = null;
    }
  }

  async executeSearch(clear = false) {
    if (this.isLoading) return;

    this.isLoading = true;

    setTimeout(() => {
      let filter: IFilterList | null = null;

      if (this.searchText) {
        if (clear) {
          this.searchResult = [];
          this.currentPage = 0;
        }
        filter = {
          displayName: {
            matchMode: 'contains',
            value: this.searchText,
            caseInsensitive: true
          }
        };
      }

      this.speakersService
        .getSpeakers({
          limit: this.assetsPerPage,
          skip: this.assetsPerPage * this.currentPage++,
          ...(filter ? { filter } : {})
        })
        .then(r => {
          r.items.forEach(e => {
            this.searchResult.push(e);
            if (e._id && e.picture && !this.allTeaserAssets[e._id]) {
              this.subscriptions.push(this.assetsService.getAsset(e.picture).subscribe(ti => (this.allTeaserAssets[e._id] = ti)));
            }
          });
        })
        .finally(() => (this.isLoading = false));
    }, 150);
  }

  selectSpeaker(speaker: Speaker) {
    this.showSearch = false;
    this.value = speaker._id;
    this.valueChange.emit(this.value);

    if (this.refreshManually) {
      this.setCurrentSpeaker();
    }
  }

  removeSpeaker() {
    this.value = null;
    this.valueChange.emit(this.value);

    if (this.refreshManually) {
      this.setCurrentSpeaker();
    }
  }

  openSearch() {
    if (!this.disabled) {
      this.showSearch = true;
    }
  }
}
