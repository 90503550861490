<div *skeleton="{ show: loading }">
  <div class="tab-header">
    <h1>
      <span>{{ 'GENERAL_STREAMS' | translate }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <p-button *hasRight="'streams.create'" icon="pi icon-ic-add-item" [label]="'PAGE_STREAMS_NEW' | translate" (onClick)="createStream()"></p-button>
  </div>

  <div class="panel">
    <div *ngIf="streams" class="panel-body">
      <div class="stream" [ngClass]="{ read: hasReadRights, edit: hasEditRights }" *ngFor="let stream of streams; trackBy: trackById" (click)="openStream(stream._id)">
        <div class="preview">
          <div *ngIf="stream.previewUrls?.length > 0">
            <c-amp [src]="stream.previewUrls[0].url" [autoplay]="true" [muted]="true"></c-amp>
          </div>
        </div>
        <div class="details">
          <h1>{{ stream.internalName }}</h1>
          <c-stream-status [status]="stream.status"></c-stream-status>
        </div>
        <div class="sessions" *ngIf="relatedSessions(stream) as rs">
          <table>
            <thead>
              <tr>
                <th>Related Session</th>
                <th>In Event</th>
                <th>Start At</th>
                <th>End At</th>
                <th>Actual Start At</th>
                <th>Actual End At</th>
                <th>Language</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let relatedSession of rs">
                <td>{{ relatedSession.sessionTitle }}</td>
                <td>{{ relatedSession.eventTitle }}</td>
                <td>{{ relatedSession.startAt | date : 'short' }}</td>
                <td>{{ relatedSession.endAt | date : 'short' }}</td>
                <td>{{ relatedSession.actualStartAt | date : 'short' }}</td>
                <td>{{ relatedSession.actualEndAt | date : 'short' }}</td>
                <td>{{ (relatedSession.language | language | async)?.languageName }}</td>
              </tr>
              <tr *ngIf="rs.length === 0" class="no-sessions">
                <td colspan="7">No sessions found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog [showHeader]="false" [(visible)]="showStream" [header]="stream?.internalName" [showHeader]="true" [modal]="true" [closable]="true">
  <c-stream-details [stream]="stream" [executeStreamOperation]="executeStreamOperation"></c-stream-details>
</p-dialog>
