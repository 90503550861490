import { HeadlineType } from './../../entities/Attributes';
import {
  VideoTeaserCollectionPageModule,
  VideoTeaserCollectionPageModuleAttributes,
  VideoTeaserCollectionSortingFieldType,
  VideoTeaserCollectionSortingOption,
} from './../../entities/pagemodules/VideoTeaserCollectionPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';
import { SortDirection } from '../../entities/Sorting';

export class VideoTeaserCollectionPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async createVideoTeaserCollectionPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.videoTeaserCollectionPageModuleAttributes(values?.title_attr),
      videos: values?.videos || [],
      discoverMoreTitle: values?.discoverMoreTitle || '',
      loadMoreButtonLabel: values?.loadMoreButtonLabel || '',
    };
  }

  public videoTeaserCollectionPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): VideoTeaserCollectionPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async videoTeaserCollectionPageModule(values: any): Promise<VideoTeaserCollectionPageModule> {
    // migrate deprecated `keytopic` to `keytopics`
    let keytopic = values?.keytopic || null;
    const keytopics = values?.keytopics || [];
    if (keytopic) {
      keytopics.push(keytopic);
      keytopic = null;
    }

    const defaultSortingField: VideoTeaserCollectionSortingFieldType = values?.defaultSortingField || 'uploadDate';
    const defaultSortingDirection: SortDirection = values?.defaultSortingDirection || '-1';

    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'VideoTeaserCollection',
      titlePosition: values?.titlePosition || 'left',
      VideoTeaserCollectionType: !values?.VideoTeaserCollectionType && values.showHighlightSessions ? 'Highlights' : values?.VideoTeaserCollectionType || 'Default',
      teaserAlignment: values?.teaserAlignment || 'center',
      showProgressBar: typeof values?.showProgressBar === 'boolean' ? values?.showProgressBar : false,
      showVideoStatusFlag: typeof values?.showVideoStatusFlag === 'boolean' ? values?.showVideoStatusFlag : false,
      local: await this.local(values, (val) => this.createVideoTeaserCollectionPageModuleLocal(val)),
      teaserLayoutVariation: values?.teaserLayoutVariation || 1,
      ctaButton: values?.ctaButton || null,
      discoverMoreCta: values?.discoverMoreCta || null,
      showAssetTags: typeof values?.showAssetTags === 'boolean' ? values?.showAssetTags : true,
      showLanguageTags: typeof values?.showLanguageTags === 'boolean' ? values?.showLanguageTags : true,
      showInProgressVideos: typeof values?.showInProgressVideos === 'boolean' ? values?.showInProgressVideos : false,
      showViewedVideos: typeof values?.showViewedVideos === 'boolean' ? values?.showViewedVideos : false,
      showNewVideos: typeof values?.showNewVideos === 'boolean' ? values?.showNewVideos : false,
      languages: values?.languages || [],
      keytopic,
      keytopics,
      showVideoTitle: typeof values?.showVideoTitle === 'boolean' ? values?.showVideoTitle : true,
      showEventtypeTags: typeof values?.showEventtypeTags === 'boolean' ? values?.showEventtypeTags : true,
      showDate: typeof values?.showDate === 'boolean' ? values?.showDate : true,
      showDiscoverMoreLast: typeof values?.showDiscoverMoreLast === 'boolean' ? values?.showDiscoverMoreLast : false,
      showPlayButton: typeof values?.showPlayButton === 'boolean' ? values?.showPlayButton : false,
      showSliderPagination: typeof values?.showPlayButton === 'boolean' ? values?.showPlayButton : true,
      showResultLabel: typeof values?.showResultLabel === 'boolean' ? values?.showResultLabel : true,
      displayAs: values?.displayAs || 'slider',
      pagination: values?.pagination || 10,
      navigationType: values?.navigationType || 'pagination',
      loadMoreAmount: values?.loadMoreAmount || 6,
      loadMoreInitialAmount: values?.loadMoreInitialAmount || 9,
      defaultSortingField,
      defaultSortingDirection,
      sortingOptions: await Promise.all((values?.sortingOptions || []).map((e: any) => this.videoTeaserCollectionSortingOption(e, defaultSortingDirection, defaultSortingField))),
      query: values?.query || null,
    };
  }

  public async videoTeaserCollectionSortingOption(values: any, defaultSortingField = 'uploadDate', defaultSortingDirection = '-1'): Promise<VideoTeaserCollectionSortingOption> {
    return {
      _id: await this.id(values?._id),
      sortingField: values?.sortingField || defaultSortingField,
      sortingDirection: values?.sortingDirection || defaultSortingDirection,
      local: await this.local(values, (val) => this.videoTeaserCollectionSortingOptionLocal(val)),
    };
  }

  public videoTeaserCollectionSortingOptionLocal(values: any) {
    return {
      title: values?.title || '',
    };
  }
}
