import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';

import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';

import { ComponentsModule } from 'src/app/components/components.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

import { LookupsComponent } from './lookups.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { TagModule } from 'primeng/tag';

const routes: Routes = [{ path: '', component: LookupsComponent }];

@NgModule({
  declarations: [LookupsComponent],
  imports: [ButtonModule, CommonModule, RouterModule.forChild(routes), FormsModule, TranslateModule, TableModule, DirectivesModule, ComponentsModule, PipesModule, TagModule],
  exports: [RouterModule],
})
export class LookupsModule {}
