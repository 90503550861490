<div class="selected-menu-item" *ngIf="selectedMenuItem">
  <c-field header="Title" *ngIf="selectedMenuItem.local[language]">
    <input pInputText [(ngModel)]="selectedMenuItem.local[language].title" />
  </c-field>

  <c-field header="Menu Item Type">
    <p-dropdown [options]="menuItemTypeOptions" [(ngModel)]="selectedMenuItem.menuItemType"></p-dropdown>
  </c-field>

  <div *ngIf="asLinkMenuItem(selectedMenuItem) as externalMenuItem">
    <c-field header="Link">
      <input pInputText [(ngModel)]="externalMenuItem.local[language].link" placeholder="https://... or /events/eventId" />
    </c-field>
  </div>

  <div *ngIf="asPageMenuItem(selectedMenuItem) as pageMenuItem">
    <c-field header="Page">
      <p-dropdown [options]="pageOptions" [(ngModel)]="pageMenuItem.page"></p-dropdown>
    </c-field>
  </div>

  <div *ngIf="asDialogMenuItem(selectedMenuItem) as dialogMenuItem">
    <c-field header="Dialog">
      <input pInputText [(ngModel)]="dialogMenuItem.dialog" placeholder="Name of the dialog" />
    </c-field>
  </div>

  <div *ngIf="asAppFunctionMenuItem(selectedMenuItem) as appFunctionMenuItem">
    <c-field header="Action">
      <c-basic-patch-input jsonpath="$.action" [object]="appFunction" [inputs]="appFunctionInputs" [showHeader]="false"></c-basic-patch-input>
    </c-field>

    <c-field header="Action data">
      <c-basic-patch-input jsonpath="$.local.$language.actionData" [object]="appFunction" [inputs]="appFunctionInputs" [jsonpathParams]="jsonPathParams" [showHeader]="false"></c-basic-patch-input>
    </c-field>
  </div>

  <c-field header="Icon">
    <c-asset-select [(id)]="selectedMenuItem.icon" [mimeType]="'image/svg+xml'"></c-asset-select>
  </c-field>
  <c-field header="Hover Icon">
    <c-asset-select [(id)]="selectedMenuItem.hoverIcon" [mimeType]="'image/svg+xml'"></c-asset-select>
  </c-field>
  <c-field header="Active Icon">
    <c-asset-select [(id)]="selectedMenuItem.activeIcon" [mimeType]="'image/svg+xml'"></c-asset-select>
  </c-field>
</div>
