<div class="panel">
  <div class="panel-header">
    {{ 'GENERAL_KEYTOPICS' | translate }}
    <div class="actions">
      <button *hasRight="'events.editContent'" pButton type="button" class="p-button" [label]="'GENERAL_ADD' | translate" icon="pi icon-ic-add-item" (click)="showKeytopicSelect = true"></button>
      <c-table-options #to></c-table-options>
    </div>
  </div>

  <c-table #table [data]="eventVersion.eventKeytopics" [options]="keytopicsTableOptions" [tableOptionsComponent]="to">
    <ng-template let-eventKeytopic>
      <a [routerLink]="[]" queryParamsHandling="merge" (click)="editKeytopic(eventKeytopic)">{{ (eventKeytopic.keytopic | keytopic | async)?.internalName }}</a>
    </ng-template>
    <ng-template let-eventKeytopic>{{ eventKeytopic.override ? eventKeytopic.override.local[language]?.title : (eventKeytopic.keytopic | keytopic | async)?.local[language]?.title }}</ng-template>
    <ng-template let-eventKeytopic>{{
      eventKeytopic.override ? eventKeytopic.override.local[language]?.shortDescription : (eventKeytopic.keytopic | keytopic | async)?.local[language]?.shortDescription
    }}</ng-template>
    <ng-template let-eventKeytopic>
      <i *ngIf="eventKeytopic.override" class="pi pi-check"></i>
      <i *ngIf="!eventKeytopic.override" class="pi pi-times"></i>
    </ng-template>
    <ng-template let-eventKeytopic let-first="first" let-last="last">
      <div *hasRight="'events.editContent'" style="cursor: pointer">
        <i *ngIf="!first" class="pi pi-sort-up p-mr-2" [pTooltip]="'GENERAL_MOVE_UP' | translate" tooltipPosition="left" (click)="keytopicUp(eventKeytopic); $event.stopPropagation()"></i>
        <i
          *ngIf="!last"
          class="pi pi-sort-down"
          [ngClass]="{ 'down-padding-left': first }"
          [pTooltip]="'GENERAL_MOVE_DOWN' | translate"
          tooltipPosition="left"
          (click)="keytopicDown(eventKeytopic); $event.stopPropagation()"
        ></i>
      </div>
    </ng-template>
  </c-table>
</div>

<!-- Keytopic Edit Dialog -->
<p-sidebar *ngIf="showKeytopicEdit" [(visible)]="showKeytopicEdit" styleClass="p-sidebar-st" position="right" [modal]="true" [appendTo]="'body'" [showCloseIcon]="false">
  <c-sidebar-header
    [headerTitle]="'PAGE_EVENT_SECTION_KEYTOPICS_EDIT' | translate"
    [headerSubTitle]="(editEventKeytopic.keytopic | keytopic | async)?.internalName"
    (onClose)="showKeytopicEdit = false"
  ></c-sidebar-header>

  <div class="sidebar-tabs">
    <button [class.active]="keytopicEditTab === 'general'" (click)="keytopicEditTab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
    <button [class.active]="keytopicEditTab === 'assets'" (click)="keytopicEditTab = 'assets'">{{ 'GENERAL_LOCAL_ASSETS' | translate }}</button>
  </div>
  <div class="sidebar-body">
    <div *ngIf="keytopicEditTab == 'general'">
      <c-collaboration-patch-input
        [object]="keytopicEditHelper"
        jsonpath="$.override"
        [inputConfiguration]="{
          type: 'switch',
          header: 'PAGE_EVENT_SECTION_KEYTOPICS_OVERRIDE',
          description: 'PAGE_EVENT_SECTION_KEYTOPICS_OVERRIDE_DESCRIPTION'
        }"
        [disabled]="this.eventVersion | isDisabled"
        (onValueChanged)="overrideKeytopic($event, editEventKeytopic)"
      ></c-collaboration-patch-input>

      <div *ngIf="editEventKeytopic.override != null">
        <c-event-patch-input
          *ngFor="let jsonPath of generalJsonPathes"
          [eventVersion]="eventVersion"
          [jsonpath]="jsonPath"
          [jsonpathParams]="{ keytopicId: editEventKeytopic.keytopic }"
        ></c-event-patch-input>
      </div>
    </div>

    <div *ngIf="keytopicEditTab == 'assets'">
      <c-event-patch-input
        [eventVersion]="eventVersion"
        jsonpath="$.eventKeytopics[?(@.keytopic == '$keytopicId')].local.$language.assets"
        [jsonpathParams]="{ keytopicId: editEventKeytopic.keytopic }"
      ></c-event-patch-input>
    </div>
  </div>

  <c-sidebar-footer
    *hasRight="'events.editContent'"
    [autoSaveInfo]="true"
    (onClose)="showKeytopicEdit = false"
    [showDeleteButton]="true"
    (onDelete)="keytopicDelete(editEventKeytopic); showKeytopicEdit = false"
  ></c-sidebar-footer>
</p-sidebar>

<!-- Add Keytopic Selection Dialog -->
<p-sidebar *ngIf="showKeytopicSelect" [(visible)]="showKeytopicSelect" styleClass="p-sidebar-st" position="right" [modal]="true" [appendTo]="'body'" [showCloseIcon]="false">
  <c-sidebar-header [headerTitle]="'PAGE_EVENT_SECTION_KEYTOPICS_ADD' | translate" (onClose)="showKeytopicSelect = false"></c-sidebar-header>

  <div class="sidebar-body">
    <input type="text" [(ngModel)]="filterKeytopicsText" placeholder="Search ..." />
    <div class="">
      <div class="items-list" *ngFor="let keytopic of filteredKeytopics" (click)="addKeytopic(keytopic)">
        <div class="label">{{ keytopic.internalName }}</div>
      </div>
    </div>
  </div>
</p-sidebar>
