import { SpeakerListPageModuleFactory } from './../../factories/pagemodules/SpeakerListPageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';
import { SpeakerListPageModuleElement } from './../../entities/pagemodules/SpeakerListPageModule';

export const speakerListPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('SpeakerList'),

  "$.pageModules[?(@.pageModuleType=='SpeakerList' && @._id=='$pageModuleId')].linkable": {
    header: 'Link',
    type: 'switch',
  },
  "$.pageModules[?(@.pageModuleType=='SpeakerList' && @._id=='$pageModuleId')].displayAs": {
    header: 'Display As',
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Card Slider', value: 'slider' },
      { label: 'List', value: 'list' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='SpeakerList' && @._id=='$pageModuleId')].personDetailPage": {
    header: 'Display As',
    type: 'dropdown',
    dropdownOptions: [
      { label: 'User Page in this Event', value: 'userPage' },
      { label: 'External User Account', value: 'externalUserAccount' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='SpeakerList' && @._id=='$pageModuleId')].defaultSortingField": {
    header: 'Default Sorting Field',
    type: 'dropdown',
    dropdownOptions: [
      { label: 'First Name', value: 'firstName' },
      { label: 'Last Name', value: 'lastName' },
      { label: 'Company', value: 'company' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='SpeakerList' && @._id=='$pageModuleId')].defaultSortingDirection": {
    header: 'Default Sorting Direction',
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Ascending', value: '1' },
      { label: 'Descending', value: '-1' },
    ],
  },

  "$.pageModules[?(@.pageModuleType=='SpeakerList' && @._id=='$pageModuleId')].speakers": {
    header: 'speakers',
    factory: async () => [],
    list: true,
    childFactory: async () => null,
  },

  "$.pageModules[?(@.pageModuleType=='SpeakerList' && @._id=='$pageModuleId')].speakers[$index]": {
    type: 'speaker',
    header: 'Speaker',
    hideRemoveLink: true,
  },

  "$.pageModules[?(@.pageModuleType=='SpeakerList' && @._id=='$pageModuleId')].elements": {
    factory: async () => [],
    header: 'GUI Sorting Elements',
    list: true,
    listKey: '_id',
    listValue: 'elementId',
    childFactory: async () => new SpeakerListPageModuleFactory().speakerListPageModuleElement({}),
    childLabel: (item: SpeakerListPageModuleElement, index: number, lang?: string) => item?.local[lang ? lang : 'en']?.title as string,
  },

  "$.pageModules[?(@.pageModuleType=='SpeakerList' && @._id=='$pageModuleId')].elements[?(@._id=='$elementId')].local.$language": {
    factory: async () => new SpeakerListPageModuleFactory().speakerListPageModuleElementLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='SpeakerList' && @._id=='$pageModuleId')].elements[?(@._id=='$elementId')].local.$language.title": {},

  "$.pageModules[?(@.pageModuleType=='SpeakerList' && @._id=='$pageModuleId')].elements[?(@._id=='$elementId')].sortingField": {
    header: 'Sorting Field',
    type: 'dropdown',
    dropdownOptions: [
      { label: 'First Name', value: 'firstName' },
      { label: 'Last Name', value: 'lastName' },
      { label: 'Company', value: 'company' },
    ],
  },

  "$.pageModules[?(@.pageModuleType=='SpeakerList' && @._id=='$pageModuleId')].elements[?(@._id=='$elementId')].sortingDirection": {
    header: 'Sorting Direction',
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Ascending', value: '1' },
      { label: 'Descending', value: '-1' },
    ],
  },
};
