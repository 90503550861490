import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Onboarding } from 'src/common/entities/Onboarding';

@Component({
  selector: 'c-onboarding-item',
  templateUrl: './onboarding-item.component.html',
  styleUrls: ['./onboarding-item.component.scss'],
})
export class OnboardingItemComponent implements OnInit, OnChanges {
  @Input()
  onboarding: Onboarding;

  constructor() {}

  ngOnInit(): void {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.onboarding?.currentValue && changes.onboarding?.currentValue !== changes.onboarding?.previousValue) {
    }
  }
}
