import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SidebarService } from 'src/app/services/sidebar/sidebar';
import { SidebarElement } from '../sidebar-element/sidebar-element.component';

@Component({
  selector: 'c-sidebar-body',
  templateUrl: './sidebar-body.component.html',
  styleUrls: ['./sidebar-body.component.scss'],
  animations: [
    // trigger('element', [
    //   state('*', style({transform: 'translateX(100%)'})),
    //   state('hide', style({transform: 'translateX(100%)'})),
    //   state('show', style({transform: 'translateX(0%)'})),
    //   transition('* => show', [ animate(300) ]),
    //   transition('show => hide', [ animate(300) ]),
    // ])
  ],
})
export class SidebarBodyComponent implements OnInit, OnDestroy {
  sidebarElements: SidebarElement[] = [];
  currentIndex = -1;
  mode: 'add' | 'remove' = 'add';

  @Input()
  position = 'right';

  @Input()
  noScroll = false;

  constructor(private sidebarService: SidebarService) {}

  ngOnInit(): void {
    this.sidebarService.setSidebarBody(this);
  }

  ngOnDestroy() {
    for (const sidebarElement of this.sidebarElements) {
      if (sidebarElement.destroyed) {
        sidebarElement.destroyed();
      }
    }
    this.sidebarService.setSidebarBody(null);
  }

  trackByIndex(index: number, sidebarElement: SidebarElement) {
    return sidebarElement.id;
  }

  add(element: SidebarElement) {
    this.mode = 'add';
    this.currentIndex++;
    this.sidebarElements.push(element);
  }

  remove(sideMenuElement?: SidebarElement) {
    const index = this.sidebarElements.indexOf(sideMenuElement);
    this.mode = 'remove';

    if (index < 0) return;

    do {
      this.currentIndex--;

      setTimeout(() => {
        const element = this.sidebarElements.pop();

        if (element?.destroyed) {
          element.destroyed();
        }
      }, 300);
    } while (this.currentIndex >= index);
  }
}
