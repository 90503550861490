import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Message, MessageService } from 'primeng/api';

import { LanguagesService } from 'src/app/services/languages/languages.service';
import { Language } from 'src/common/entities/Language';

import { StaticContentConfiguration } from 'src/common/entities/StaticContentConfiguration';
import { StaticContentConfigurationsService } from 'src/app/services/static-content-configurations/static-content-configurations.service';
import { StaticContent } from 'src/common/entities/StaticContent';
import { StaticContentsService } from 'src/app/services/static-contents/static-contents.service';
import { StaticContentFactory } from 'src/common/factories/StaticContentFactory';
import { Factory } from 'src/common/factories/Factory';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';

@Component({
  selector: 'app-static-content',
  templateUrl: './static-content.component.html',
  styleUrls: ['./static-content.component.scss'],
  providers: [MessageService],
})
export class StaticContentComponent implements OnDestroy, OnInit {
  selectedEditor = { name: 'Editor', code: 'editor' };
  editor = [this.selectedEditor, { name: 'HTML', code: 'html' }];

  languages: Language[] = [];
  languageEdit: string = 'en'; // Editable language
  languageCompare: string = this.languageEdit; // Language to compare with

  shortId: string; // Of static content to edit
  staticContentConfiguration: StaticContentConfiguration; // Holds setting with all content parts and types

  staticContents: StaticContent = null; // Holds static contents in all languages

  loading: boolean = false;
  saving: boolean = false;
  messages: Message[] = [];
  tab: Tab;

  domainCollectionId: string = null;

  private _ngUnsubscribe: Subject<any> = new Subject<any>();

  get selectedLanguages(): string[] {
    return this.languages ? this.languages.map((language) => language.language) : [];
  }

  set selectedLanguages(languages: string[]) {
    this.languageEdit = languages[0];
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private languagesService: LanguagesService,
    private staticContentConfigurationsService: StaticContentConfigurationsService,
    private staticContentsService: StaticContentsService,
    private tabsService: TabsService
  ) {
    this.tab = this.tabsService.register({
      category: 'configuration',
      loading: true,
      route: this.activatedRoute.snapshot,
    });
  }

  async ngOnInit() {
    this.tab.loading = true;
    this.activatedRoute.params.pipe(takeUntil(this._ngUnsubscribe)).subscribe(async (params) => {
      this.shortId = params.shortId;
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe subscriptions
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  // async ngOnInit(): Promise<void> {

  // }

  async load(domainCollectionId: string) {
    try {
      const result = await new StaticContentFactory().createStaticContent(await this.staticContentsService.getStaticContent(this.shortId, domainCollectionId));
      this.staticContents = result;
    } catch (err) {
      if (err.error && err.error.errorTag === 'STATIC_CONTENT_NOT_FOUND') {
        this.staticContents = await new StaticContentFactory().createStaticContent({});
      }
    }
  }

  async getLanguages() {
    await Promise.allSettled(
      Object.keys(this.staticContents.local).map(
        async (l) =>
          await this.languagesService.asPromise(l).then(
            // Add language if active and not already in languages
            (v) => {
              if (!this.languages.includes(v) && v.active == true) this.languages.push(v);
            },
            (e) => {}
          )
      )
    );
  }

  async save() {
    this.saving = true;

    try {
      if (this.staticContents) {
        if (this.staticContents._id === 'new') {
          this.staticContents = await this.staticContentsService.createStaticContent(this.staticContents);
        } else {
          this.staticContents = await this.staticContentsService.updateStaticContent(this.staticContents);
        }
      }
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: "Can't save static content " + this.staticContents.shortId,
      });
      throw err;
    }

    this.saving = false;
  }

  addListItem(part: string) {
    if (!this.staticContents.local[this.languageEdit].parts[part]) {
      this.staticContents.local[this.languageEdit].parts[part] = [];
    }
    (this.staticContents.local[this.languageEdit].parts[part] as string[]).push('');
  }

  deleteListItem(part: string, index: number) {
    this.staticContents.local[this.languageEdit].parts[part].splice(index, 1);
  }

  listTrackBy(index, item) {
    return index;
  }

  changeCompareLanguage(event: any) {
    if (event.value) this.languageCompare = event.value;
  }

  invalidLanguages(): string[] {
    return [];
  }

  async onLanguagesChange(languages: string[]) {
    this.loading = true;

    await this.addLanguage(languages).then(async () => {
      this.deleteLanguage(languages);
      this.changeEditCompare();
      this.loading = false;
    });
  }

  async addLanguage(languages: string[]) {
    return new Promise((resolve) => {
      const filtered = languages.filter((lang) => this.languages.findIndex((l) => l.language === lang) === -1);

      if (filtered.length === 0) {
        resolve(true);
      }

      filtered.forEach(async (item) => {
        await this.languagesService.asPromise(item).then(async (l) => {
          this.languages.push(l);
          this.staticContents.local[item] = await new StaticContentFactory().createStaticContentLocalFromConfiguration(this.staticContentConfiguration);
          resolve(true);
        });
      });
    });
  }

  deleteLanguage(languages: string[]) {
    // Delete deactivated languages
    this.languages
      .filter((lang) => !languages.includes(lang.language))
      .forEach((item) => {
        const index = this.languages.findIndex((lang) => lang.language === item.language);
        this.languages.splice(index, 1);
        delete this.staticContents.local[item.language];
      });
  }

  changeEditCompare() {
    // Change languageEdit & languageCompare if they do not exist
    if (this.languages.findIndex((l) => l.language === this.languageEdit) === -1) {
      this.languageEdit = this.languages[0].language;
    }
    if (this.languages.findIndex((l) => l.language === this.languageCompare) === -1) {
      this.languageCompare = this.languages[0].language;
    }
  }

  async loadDialog(domainCollectionId: string) {
    this.domainCollectionId = domainCollectionId;
    if (this.shortId && this.shortId != '') {
      this.staticContentConfiguration = await this.staticContentConfigurationsService.getStaticContentConfiguration(this.shortId);
      await this.load(domainCollectionId);
      await this.getLanguages();

      this.tab.loading = false;
    }
  }
}
