import { Inputs } from 'src/common/inputs/Inputs';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetsService, AssetUpload } from 'src/app/services/assets/assets.service';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { AccessPolicyType } from 'src/common/entities/AccessPolicy';
import {
  Asset,
  DocumentAsset,
  ImageAsset,
  isDocumentAsset,
  isImageAsset,
  isMediaServicesVideoAssetDistributor,
  isVideoAsset,
  MediaServicesVideoAssetDistributor,
  VideoAsset,
  VideoAssetDistributor,
} from 'src/common/entities/Asset';
import { Language } from 'src/common/entities/Language';
import { Environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'c-asset-item',
  templateUrl: './asset-item.component.html',
  styleUrls: ['./asset-item.component.scss'],
})
export class AssetItemComponent implements OnInit, OnDestroy {
  @Input()
  asset: Asset;

  @Input()
  assetUpload: AssetUpload;

  @Input()
  disableDetails = false;

  @Input()
  openEdit = true;

  @ViewChild('canvas')
  canvas: ElementRef;

  showDetails = false;
  currentLanguage = 'en';
  working = false;
  currentPosition = 0;
  pageNumber = 1;
  thumbnailAsset: Observable<Asset>;
  isPdf = false;

  inputs: Inputs = {
    '$.local.$lang.title': {
      type: 'text',
      header: 'Title',
    },
    '$.local.$lang.description': {
      header: 'Description',
      type: 'html',
    },
    '$.local.$lang.alt': {
      header: 'Alternative Text (Alt Tag)',
      type: 'text',
    },
    '$.statusNewUntil': {
      header: 'COMPONENT_ASSET_ITEM_STATUS_NEW_UNTIL',
      type: 'date',
    },
    '$.recordedAt': {
      header: 'COMPONENT_ASSET_ITEM_RECORDED_AT',
      type: 'datetime',
    },
  };

  get selectedLanguages(): string[] {
    return this.asset ? Object.keys(this.asset.local) : [];
  }

  set selectedLanguages(languages: string[]) {
    if (this.asset) {
      for (const language of languages) {
        if (!this.asset.local[language]) {
          this.asset.local[language] = {
            title: '',
            description: '',
          };
        }
      }
    }
  }

  get internalAsset(): Asset {
    if (this.asset && !this.asset.local) {
      this.asset.local = {};
    }
    return this.asset || this.assetUpload?.status?.response;
  }

  // tmp solution
  get executeAccess(): AccessPolicyType {
    // since empty access array is considered `Public` access in frontend, we should show it as default here too
    return this.internalAsset?.executeAccess?.length > 0 ? this.internalAsset?.executeAccess[0].accessPolicyType : 'Public';
  }
  set executeAccess(type: AccessPolicyType) {
    this.internalAsset.executeAccess = [
      {
        _id: '',
        accessPolicyType: type,
      },
    ];
  }

  url: string;

  constructor(
    private environment: Environment,
    private languagesService: LanguagesService,
    private assetsService: AssetsService,
    private collaborationService: CollaborationService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.collaborationService.unregisterLocal(`assetconfiguration:${this.asset._id}`);
  }

  ngOnInit(): void {
    if (isVideoAsset(this.internalAsset) || isDocumentAsset(this.internalAsset)) {
      if (this.internalAsset.thumbnail) {
        this.thumbnailAsset = this.assetsService.getAsset(this.internalAsset.thumbnail);
      }
    }
    if (this.internalAsset?.mimeType) {
      this.isPdf = this.internalAsset.mimeType === 'application/pdf';
    }
    if (this.asset?._id) {
      this.collaborationService.registerLocal(`assetconfiguration:${this.asset._id}`, this.asset, true);
    }
  }

  asVideoAsset(): VideoAsset {
    return isVideoAsset(this.internalAsset) ? this.internalAsset : null;
  }

  asImageAsset(): ImageAsset {
    return isImageAsset(this.internalAsset) ? this.internalAsset : null;
  }

  asDocumentAsset(): DocumentAsset {
    return isDocumentAsset(this.internalAsset) ? this.internalAsset : null;
  }

  asAssetWithThumbnail(): { thumbnail?: string } {
    return isVideoAsset(this.internalAsset) || isDocumentAsset(this.internalAsset) ? this.internalAsset : null;
  }

  asMediaServiceVideoDistributor(distributor: VideoAssetDistributor): MediaServicesVideoAssetDistributor {
    return isMediaServicesVideoAssetDistributor(distributor) ? distributor : null;
  }

  imageUrl(): string {
    if (this.asset && isImageAsset(this.asset) && this.asset.paths) {
      if (this.asset.mimeType === 'image/svg+xml') {
        return `url("${this.asset.paths.dataUri}")`;
      } else {
        return `url(${this.environment.cdn.images}/${this.asset.paths.large})`;
      }
    }

    return 'none';
  }

  videoUrl(): string {
    return this.asVideoAsset()?.streamingUrls.find((s) => s.endsWith('/manifest'));
  }

  documentUrl(): string {
    return `${this.environment.cdn.documents}/${this.asDocumentAsset()?.path}`;
  }

  imageStyle(): any {
    return { 'background-image': this.url, 'background-size': `contain` };
  }

  openDetails(): void {
    if (this.openEdit) {
      this.router.navigate(['/assets', this.asset._id]);
    }
  }

  onShow(): void {
    this.collaborationService.registerLocal(`assetconfiguration:${this.asset._id}`, this.asset, true);
    if (isImageAsset(this.internalAsset)) {
      this.url = this.imageUrl();
    } else if (isVideoAsset(this.internalAsset)) {
      this.url = this.videoUrl();
    } else if (isDocumentAsset(this.internalAsset)) {
      this.url = this.documentUrl();
    }
    this.cd.detectChanges();
  }

  onHide(): void {
    this.url = null;
  }

  deleteLanguage(language: string): void {
    if (language === 'en') {
      return;
    }

    delete this.asset?.local[language];

    if (language === this.currentLanguage && this.asset) {
      this.currentLanguage = Object.keys(this.asset.local)[0];
    }
  }

  getActiveLanguages(): Observable<Language[]> {
    return this.languagesService.getActiveLanguages();
  }

  async save(): Promise<void> {
    this.working = true;
    try {
      await this.assetsService.updateAsset(this.asset);
      this.showDetails = false;
    } catch (err) {
      console.error(err);
    }
    this.collaborationService.registerLocal(`assetconfiguration:${this.asset._id}`, this.asset, true);
    this.working = false;
  }

  async generateThumbnail(): Promise<void> {
    this.working = true;

    try {
      if (isVideoAsset(this.asset) || isDocumentAsset(this.asset)) {
        const imageAsset = await this.assetsService.generateThumbnail(this.asset, !isDocumentAsset(this.asset) ? this.currentPosition : this.pageNumber).toPromise();

        this.asset.thumbnail = imageAsset._id;
      }
    } catch (err) {
      console.error(err);
    }

    this.working = false;
  }

  contentLanguages(): string | null {
    const { contentLanguages } = this.internalAsset;
    if (Array.isArray(contentLanguages) && contentLanguages.length > 0) return contentLanguages.join(', ');
    return null;
  }
}
