import { GridPageModule } from '../../entities/pagemodules/GridPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class GridPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async gridPageModule(values: any): Promise<GridPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'Grid',
      gridType: values?.gridType || '3grid',
      viewType: values?.viewType || 'tabs',
      speakerDisplayType: values?.speakerDisplayType || 'list',
      showFilterOptions: values?.showFilterOptions || false,
      showAgendaCalendarButton: values?.showAgendaCalendarButton || false,
      showAgendaShareButton: values?.showAgendaShareButton || false,
      showCurrentTime: values?.showCurrentTime || false,
      showBookmarkButton: values?.showBookmarkButton || false,
      showDescription: values?.showDescription || false,
      showTime: values?.showTime || false,
      showCalendarButton: values?.showCalendarButton || false,
      showShareButton: values?.showShareButton || false,
      showRelatedDocuments: values?.showRelatedDocuments || false,
      event: values?.event || values?.eventId,
      showReactions: values?.showReactions || false,
      parallelReactions: values?.parallelQuickReactions || 100,
      sticky: values.sticky || false,
      chatWithoutLogin: values?.chatWithoutLogin || false,
      showPolls: typeof values?.showPolls === 'boolean' ? values.showPolls : true,
      showCountdown: typeof values?.showCountdown === 'boolean' ? values.showCountdown : true,
      initGrid: values?.initGrid || 'middle',
      showStickyPlayerOnMobile: values?.showStickyPlayerOnMobile || false,
      showMapButton: values?.showMapButton || false,
    };
  }
}
