import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { Tab } from '../../tabs/classes/tab';
import { TableOptions } from '../../components/table/table.interfaces';
import { GetPagesQuery, PageViewType, PageWithChildPages } from '../../../common/api/v1/configuration/pages/GetPages';
import { EmbeddedPage, PageType, PageTypes } from '../../../common/entities/Page';
import { IFilterList } from '../../../common/api/interfaces';
import { ConfigurationService } from '../../services/configuration/configuration.service';
import { PagesService } from '../../services/pages/pages.service';
import { LanguagesService } from '../../services/languages/languages.service';
import { UtilsService } from '../../services/utils/utils.service';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TabsService } from '../../tabs/services/tabs.service';
import { MessageService } from 'primeng/api';
import { InputConfiguration, Inputs } from '../../../common/inputs/Inputs';
import { delay, distinctUntilChanged, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import { PageFactory } from '../../../common/factories/PageFactory';
import { areArraysEqualByTableOptions, areObjectEquals, sortByInternalName } from '../../utils';

@Component({
  selector: 'app-embedded-pages',
  templateUrl: './embedded-pages.component.html',
  styleUrls: ['./embedded-pages.component.scss'],
})
export class EmbeddedPagesComponent implements OnInit, OnDestroy {
  @ViewChild('modalCreateEmbeddedPagesSideBar') private modalCreatePageSideBar: SidebarComponent;

  $searchText = new BehaviorSubject<string | null>(null);
  $domainCollectionId = new BehaviorSubject<string | null>(null);

  tab: Tab;
  tableOptions: TableOptions<PageWithChildPages>;

  language = 'en';

  newEmbeddedPage: EmbeddedPage;
  pageType: PageType = 'EmbeddedPage';
  get showNewEmbeddedPageModal(): boolean {
    return !!this.newEmbeddedPage;
  }
  set showNewEmbeddedPageModal(val: boolean) {
    if (!val) {
      this.newEmbeddedPage = null;
    }
  }

  expanded: { [pageId: string]: boolean } = {};

  // We need this subject to refresh the list of pages whenever any page is updated
  $refresh = new BehaviorSubject<unknown>(true);
  $filter = new BehaviorSubject<IFilterList>({});
  $pages: Observable<PageWithChildPages[]>;

  subscriptions: Subscription[] = [];

  constructor(
    private configurationService: ConfigurationService,
    private pagesService: PagesService,
    private languagesService: LanguagesService,
    private utilsService: UtilsService,
    private router: Router,
    private tabsService: TabsService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService
  ) {
    this.language = this.utilsService.defaultLanguage;
  }

  inputConfig: InputConfiguration;
  inputs: Inputs = {};

  async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      this.$searchText
        .pipe(
          delay(300),
          distinctUntilChanged(),
          withLatestFrom(this.$filter),
          tap(([value, filter]) => {
            if (value !== filter.internalName?.value) {
              filter.internalName = {
                matchMode: 'contains',
                caseInsensitive: true,
                value,
              };
              this.$filter.next(filter);
            }
          })
        )
        .subscribe()
    );

    this.$pages = combineLatest([
      this.$domainCollectionId.pipe(
        filter((data) => !!data),
        distinctUntilChanged()
      ),
      this.$filter.pipe(
        map((filter) => {
          if (!filter.internalName?.value) {
            delete filter.internalName;
          }
          return filter;
        }),
        map(this.getFilterForEmbeddedPages),
        distinctUntilChanged((x, y) => areObjectEquals(x, y))
      ),
      this.$refresh,
    ]).pipe(
      switchMap(([domainCollectionId, filter = {}]) => {
        const query: GetPagesQuery = { filter, view: PageViewType.LIST };
        return fromPromise(this.pagesService.getPages(domainCollectionId, query)).pipe(map(({ items }) => items));
      }),
      distinctUntilChanged((itemsA, itemsB) => areArraysEqualByTableOptions(itemsA, itemsB, this.tableOptions))
    );

    this.tab = this.tabsService.register({
      category: 'configuration',
      route: this.activatedRoute.snapshot,
    });

    this.tableOptions = {
      size: 50,
      columns: [
        {
          header: 'GENERAL_TITLE',
          sort: sortByInternalName,
        },
      ],
      filters: [{ header: 'GENERAL_TITLE', path: 'internalName' }],
    };

    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        withLatestFrom(this.$domainCollectionId)
      )
      .subscribe(([navEnd, domainCollectionId]) => {
        if (!this.tab.loading && navEnd.url === '/configuration/embedded-pages') {
          this.$refresh.next(true);
          this.loadDialog(domainCollectionId);
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async loadDialog(domainCollectionId: string): Promise<void> {
    this.$domainCollectionId.next(domainCollectionId);

    this.inputs = {
      '$.parentPage': {
        header: 'Set Parent',
        type: 'page',
        domainCollectionId,
      },
    };
  }

  async filterChange(filterChange: IFilterList): Promise<void> {
    this.$filter.next(filterChange);
    const value = filterChange.internalName?.value ? filterChange.internalName?.value.toString() : '';
    this.$searchText.next(value);
  }

  async createPage(): Promise<void> {
    if (this.newEmbeddedPage.internalName) {
      try {
        const embeddedPage = await this.pagesService.createEmbeddedPage(this.newEmbeddedPage);
        this.newEmbeddedPage = null;
        this.showNewEmbeddedPageModal = false;
        this.$refresh.next(true);
        setTimeout(() => {
          this.router.navigate(['/configuration/pages', embeddedPage._id], {
            replaceUrl: true,
            queryParams: { domainCollectionId: this.$domainCollectionId.getValue() },
          });
        }, 500);
      } catch (error) {
        console.error(error);
        const summary = typeof error.error?.message === 'string' ? error.error.message : 'Something went wrong';
        this.messageService.add({ severity: 'error', summary });
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'Please fill empty forms.' });
    }
  }

  async openNewEmbeddedPageModal(): Promise<void> {
    this.newEmbeddedPage = await new PageFactory({ newIds: true }).page({
      pageType: PageTypes.EmbeddedPage,
      domainCollection: this.$domainCollectionId.getValue(),
    });
  }

  handleInput($event: Event) {
    const { value } = $event.target as HTMLInputElement;
    this.$searchText.next(value);
  }

  private getFilterForEmbeddedPages(filter: IFilterList): IFilterList {
    return {
      ...filter,
      pageType: {
        matchMode: 'equals',
        value: PageTypes.EmbeddedPage,
      },
    };
  }
}
