import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Tab } from '../../classes/tab';
import { TabsService } from '../../services/tabs.service';
import { stickyTabs } from '../../sticky-tabs';
import { routes } from 'src/app/app-routing.module';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'c-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  category: string;
  tabs: Tab[];
  rightsCheckCompleted = false;
  instanceAlias?: string;
  instanceAliasColor?: string;

  rights: { [category: string]: string[] } = {};
  // rights: {[category: string]: string[]} = {
  //   'events': [
  //     '*$events.create',
  //     '*$events.edit',
  //     '*$events.publish',
  //     '*$events.list',
  //     '*$eventregistrations.list',
  //     '*$eventregistrations.read',
  //     '*$eventregistrations.edit',
  //     '*$eventregistrations.create',
  //     '*$eventregistrations.delete',
  //     '*$eventticketslots.list',
  //     '*$eventticketslots.read',
  //     '*$eventticketslots.edit',
  //     '*$live.read',
  //     '*$live.manage',
  //     '*$templates.edit',
  //     '*$templates.list',
  //     '*$templates.read',
  //   ],
  //   'users': [
  //     '*$users.list',
  //     '*$users.create',
  //     '*$users.edit',
  //   ],
  //   'appointments': [
  //     '*$appointments.list',
  //     '*$appointments.assign',
  //     '*$appointments.edit',
  //     '*$appointments.create',
  //     '*$appointments.listown',
  //     '*$appointments.start',
  //     '*$appointments.stop',
  //     '*$appointments.delete',
  //   ],
  //   'data': [
  //     '*$keytopics.list',
  //     '*$keytopics.create',
  //     '*$keytopics.edit',
  //     '*$keytopics.read',
  //     '*$speakers.list',
  //     '*$speakers.create',
  //     '*$speakers.edit',
  //     '*$speakers.read',
  //     '*$assets.import',
  //     '*$assets.list',
  //     '*$assets.read',
  //     '*$assets.edit',
  //   ],
  //   'configuration': [
  //     '*$configuration.list',
  //     '*$configuration.read',
  //     '*$configuration.edit',
  //     '*$configuration.create',
  //     '*$configuration.delete',
  //   ],
  // }

  constructor(private tabsService: TabsService, private authService: AuthService, private router: Router, private environment: Environment) {
    for (const stickyTab of stickyTabs) {
      if (!this.rights[stickyTab.category]) {
        this.rights[stickyTab.category] = [];
      }

      stickyTab.hasRight = routes.find((r) => r.path === stickyTab.url?.slice(1))?.data.hasRight || stickyTab.hasRight;

      if (stickyTab.hasRight) {
        this.rights[stickyTab.category] = [...new Set(this.rights[stickyTab.category].concat(Array.isArray(stickyTab.hasRight) ? stickyTab.hasRight : [stickyTab.hasRight]))];
      }
    }
  }

  ngOnInit(): void {
    combineLatest([this.tabsService.category(), this.tabsService.tabs()]).subscribe(([category, tabs]) => {
      this.category = category;
      this.tabs = tabs.filter((t) => t.configuration.category === category && !t.configuration.parent);
    });
    this.authService.adminUser().subscribe((adminUser) => {
      if (adminUser) {
        this.initRights();
      }
    });
    this.instanceAlias = this.environment.instanceAlias;
    this.instanceAliasColor = this.environment.instanceAliasColor;
  }

  openCategory(category: string) {
    this.tabsService.openCategory(category);
  }

  closeTab(tab: Tab) {
    this.tabsService.close(tab);
  }

  tabClick(tab: Tab) {
    if (tab.configuration.collapsible) {
      tab.collapsed = !tab.collapsed;
    } else {
      this.router.navigateByUrl(tab.completedUrl);
    }
  }

  async initRights() {
    await Promise.all(
      Object.values(this.rights)
        .reduce((a, b) => a.concat(b), [])
        .map((r) => this.authService.hasRight(r))
    );
    this.rightsCheckCompleted = true;
  }

  logout() {
    this.authService.logout();
    window.location.reload();
  }
}
