import { Pipe, PipeTransform } from '@angular/core';
import { defer, from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SelectMenuItem } from 'src/app/components/select-menu/select-menu.component';
import { RegionsService } from 'src/app/services/regions/regions.service';

@Pipe({
  name: 'regionSelectMenuItems',
})
export class RegionSelectMenuItemsPipe implements PipeTransform {
  constructor(private regionsService: RegionsService) {}

  transform(regions: string[], ...args: unknown[]): Observable<SelectMenuItem[]> {
    return from([regions || []])
      .pipe(
        switchMap((regions) => {
          return defer(async () => {
            return Promise.all(regions.map((r) => this.regionsService.getRegion(r)));
          });
        })
      )
      .pipe(
        map((regions) => {
          return regions.map((r) => {
            return {
              id: r.region,
              title: r.regionName,
            };
          });
        })
      );
  }
}
