import { LiveSessionMedia, ReLiveSessionMedia, SessionLanguage, SessionRegion, VodSessionMedia } from '../../entities/Session';
import { AbstractFactory, FactoryOptions } from '../AbstractFactory';

export class BaseSessionFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public createSessionLocal(values: any) {
    return {
      title: values?.title || '',
      description: values?.description || '',
      highlightTeaser: values?.highlightTeaser || '',
      onDemandOnly: values?.onDemandOnly || false,
      chat: values?.chat || null,

      relatedContent: values?.relatedContent || [],
    } as SessionLanguage;
  }

  /**
   * @deprecated
   */
  public createSessionRegion(values?: SessionRegion): SessionRegion {
    return {
      title: '',
      description: '',
      highlightTeaser: '',
      relatedContent: [],
      onDemandOnly: false,
    };
  }

  public createVodSessionMedia(values?: VodSessionMedia): VodSessionMedia {
    return {
      asset: values?.asset || null,
      sessionMediaType: 'VodSessionMedia',
    } as unknown as VodSessionMedia;
  }

  public createLiveSessionMedia(values?: LiveSessionMedia): LiveSessionMedia {
    return {
      asset: values?.asset || null,
      stream: values?.stream || null,
      sessionMediaType: 'LiveSessionMedia',
    } as unknown as LiveSessionMedia;
  }

  public createReLiveSessionMedia(values?: ReLiveSessionMedia): ReLiveSessionMedia {
    return {
      asset: values?.asset || null,
      sessionMediaType: 'ReLiveSessionMedia',
    } as unknown as ReLiveSessionMedia;
  }
}
