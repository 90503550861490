import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { GetCSPReportsQuery, GetCSPReportsResponse } from 'src/common/api/v1/csp-reports/GetCSPReports';
import { DeleteCSPReportsBody, DeleteCSPReportsQuery, DeleteCSPReportsResponse } from 'src/common/api/v1/csp-reports/DeleteCSPReports';

@Injectable({
  providedIn: 'root',
})
export class CSPReportsService {
  constructor(private apiService: ApiService) {}

  async getReports(query?: GetCSPReportsQuery): Promise<GetCSPReportsResponse> {
    return await this.apiService
      .get<GetCSPReportsQuery, GetCSPReportsResponse>('/api/v1/configuration/csp-reports', {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();
  }
  async deleteSelectedReports(ids?: string[]): Promise<DeleteCSPReportsResponse> {
    return await this.apiService
      .post<DeleteCSPReportsQuery, DeleteCSPReportsBody, DeleteCSPReportsResponse>('/api/v1/configuration/csp-reports/delete', {
        ids,
      })
      .toPromise();
  }
}
