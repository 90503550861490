import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as jp from 'jsonpath';

import { UtilsService } from 'src/app/services/utils/utils.service';
import { InputConfiguration, Inputs } from 'src/common/inputs/Inputs';

export interface Jsonpath {
  originalPath: string;
  resolvedPath: string;
  params: { [key: string]: any };
}

@Component({
  selector: 'c-basic-patch-input',
  templateUrl: './basic-patch-input.component.html',
  styleUrls: ['./basic-patch-input.component.scss'],
})
export class BasicPatchInputComponent implements OnChanges {
  @Input()
  jsonpath: string | string[];

  @Input()
  jsonpathParams: { [key: string]: any } = {};

  @Input()
  object: any;

  @Input()
  inputs: Inputs = {};

  @Input()
  disabled: boolean;

  @Input()
  showHeader: boolean = true;

  @Input()
  showDescription: boolean = true;

  @Input()
  header: string;

  @Input()
  description: string;

  calculatedInputConfiguration: InputConfiguration;

  initialized: boolean = false;

  resolvedJsonpath: string;

  private _combinedJsonpath: string | null = null;
  get combinedJsonpath(): string {
    if (!this._combinedJsonpath) {
      this._combinedJsonpath = this.utilsService.combineJsonpath(this.jsonpath);
    }
    return this._combinedJsonpath;
  }

  constructor(private utilsService: UtilsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.jsonpath?.currentValue !== changes.jsonpath?.previousValue ||
      changes.object?.currentValue !== changes.object?.previousValue ||
      changes.jsonpathParams?.currentValue !== changes.jsonpathParams?.previousValue ||
      changes.inputs?.currentValue !== changes.inputs?.previousValue
    ) {
      this._combinedJsonpath = null;
      this.init();
    }
  }

  init() {
    this.initialized = false;
    if (this.object && this.jsonpath && this.inputs) {
      this.resolvedJsonpath = this.utilsService.resolveJsonpath(this.jsonpath, this.jsonpathParams);

      this.calculatedInputConfiguration = this.inputs[this.combinedJsonpath];

      this.header = this.header || this.inputs[this.combinedJsonpath]?.header || this.calculateHeader();
      this.description = this.inputs[this.combinedJsonpath]?.description || this.description || '';

      this.initialized = true;
    }
  }

  show(): boolean {
    if (this.calculatedInputConfiguration?.condition) {
      const parts = jp.parse(this.utilsService.resolveJsonpath(this.combinedJsonpath, this.jsonpathParams));
      const parentParts = parts.slice(0, parts.length - 1);
      const parentJsonpath = jp.stringify(parentParts);
      const parentValue = jp.value(this.object, parentJsonpath);

      return this.calculatedInputConfiguration.condition(this.object, this.combinedJsonpath, this.jsonpathParams, parentValue);
    } else {
      return true;
    }
  }

  calculateHeader(): string {
    const resolvedJsonpath = this.utilsService.resolveJsonpath(this.combinedJsonpath, this.jsonpathParams);
    const parts = jp.parse(resolvedJsonpath);
    const lastPart = parts[parts.length - 1]?.expression?.value;

    if (typeof lastPart === 'string') {
      return lastPart.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
      });
    }

    return '';
  }

  async valueChanged(value: any) {
    const parts = jp.parse(this.resolvedJsonpath);
    const parentPath = jp.stringify(parts.slice(0, parts.length - 1));
    const parent = jp.value(this.object, parentPath);

    if (parts?.length > 0 && parent) {
      const key = parts[parts.length - 1].expression.value;
      parent[key] = value;
    }
  }
}
