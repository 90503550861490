import { BaseUser } from './../../../common/entities/User';
import { ActivatedRoute } from '@angular/router';
import { TabsService } from './../../tabs/services/tabs.service';
import { TableOptions, TableQuery } from './../../components/table/table.interfaces';
import { Tab } from './../../tabs/classes/tab';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { UsersService } from 'src/app/services/users/users.service';
import { TableComponent } from '../../components/table/table.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  @ViewChildren(TableComponent) private tables: QueryList<TableComponent<any>>;
  tab: Tab;
  tableOptions: TableOptions<BaseUser>;

  searchText: string;
  searchTextSubject: Subject<string> = new Subject<string>();
  subscriptions: Subscription[] = [];

  constructor(private usersService: UsersService, private tabsService: TabsService, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.subscriptions.push(this.searchTextSubject.pipe(debounceTime(500)).subscribe(() => this.refresh()));

    this.tab = this.tabsService.register({
      category: 'data',
      route: this.activatedRoute.snapshot,
      title: 'Adminportal Users',
    });

    this.tableOptions = {
      size: 50,
      columns: [
        { header: 'PAGE_USER_LASTNAME', sort: 'lastName' },
        { header: 'PAGE_USER_FIRSTNAME', sort: 'firstName' },
        { header: 'PAGE_USER_EMAIL', sort: 'email' },
        { header: 'PAGE_USER_ORGANIZATION', sort: 'organization' },
        { header: 'PAGE_USER_COUNTRY', sort: 'country' },
        { header: 'GENERAL_ACTIVE', sort: 'active' },
        { header: 'PAGE_USER_USERTYPE', sort: 'userType' },
        { header: 'PAGE_USER_REGISTEREDAT', sort: 'registeredAt' },
      ],
      filters: [
        { header: 'PAGE_USER_LASTNAME', path: 'lastName' },
        { header: 'PAGE_USER_FIRSTNAME', path: 'firstName' },
        { header: 'PAGE_USER_EMAIL', path: 'email' },
        { header: 'PAGE_USER_ORGANIZATION', path: 'organization' },
        { header: 'PAGE_USER_COUNTRY', path: 'country' },
        { header: 'GENERAL_ACTIVE', path: 'active', type: 'boolean' },
        {
          header: 'PAGE_USER_USERTYPE',
          path: 'userType',
          type: 'string',
          matchModes: ['equals'],
          values: [
            { value: 'LocalUser', label: 'Local User' },
            { value: 'AzureAdUser', label: 'Azure User' },
          ],
        },
        { header: 'PAGE_USER_REGISTEREDAT', path: 'registeredAt', type: 'date' },
      ],
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async searchTextKeyUp() {
    this.searchTextSubject.next();
  }

  query(query: TableQuery<BaseUser>) {
    delete query.query.filter.userDetails;
    if (this.searchText) {
      query.query.filter.userDetails = {
        operator: 'or',
        value: {
          firstName: {
            matchMode: 'contains',
            value: this.searchText,
            caseInsensitive: true,
          },
          lastName: {
            matchMode: 'contains',
            value: this.searchText,
            caseInsensitive: true,
          },
          email: {
            matchMode: 'contains',
            value: this.searchText,
            caseInsensitive: true,
          },
        },
      };
    }
    query.result = this.usersService.getCustomers(query.query);
  }

  async refresh() {
    const tables = this.tables.toArray();
    await Promise.all(tables.map((t) => t.refresh()));
  }

  linkClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
