import { Component, HostBinding, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'c-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnChanges {
  @Input()
  loading: boolean = false;

  @Input()
  working: boolean = false;

  @Input()
  saving: boolean = false;

  @Input()
  hideTitle: boolean = false;

  get show(): boolean {
    return this.loading || this.working || this.saving;
  }

  @HostBinding('class.hide')
  get hide(): boolean {
    return !this.show;
  }

  @Input()
  title: string | null = null;

  initialized: boolean = false;

  lastText: string = '';

  constructor() {}

  ngOnInit(): void {
    this.initialized = this.loading || this.working || this.saving;
  }

  ngOnChanges() {
    this.initialized = this.loading || this.working || this.saving || this.initialized;
  }

  text() {
    if (this.title) this.lastText = this.title;
    else if (this.loading) this.lastText = 'GENERAL_LOADING';
    else if (this.working) this.lastText = 'GENERAL_WORKING';
    else if (this.saving) this.lastText = 'GENERAL_SAVING';
    return this.lastText;
  }
}
