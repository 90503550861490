<div class="event-item">
  <div class="event-preview">
    <c-asset-preview [asset]="asset"></c-asset-preview>
  </div>
  <div class="event-info">
    <h1>{{ internalName }}</h1>
    <div class="details">
      <div>Short-ID: {{ shortId }}</div>
      <div>Type: {{ eventTypeName }}</div>
    </div>
  </div>
</div>
