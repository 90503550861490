import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { QuickFilter } from 'src/common/entities/QuickFilter';

@Component({
  selector: 'c-quick-filters',
  templateUrl: './quick-filters.component.html',
  styleUrls: ['./quick-filters.component.scss'],
})
export class QuickFiltersComponent implements OnInit, OnChanges {
  @Input()
  active: boolean = true;

  @Input()
  quickFilters: QuickFilter[] = [];

  @Input()
  title?: string = null;

  @Output()
  valueChange: EventEmitter<QuickFilter[]> = new EventEmitter<QuickFilter[]>();

  _active = true;

  constructor() {}

  ngOnInit(): void {}

  toggleQuickFilters(quickFilter) {
    quickFilter.active = !quickFilter.active;
    this.valueChange.emit(this.quickFilters);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.quickFilters?.currentValue && changes.quickFilters.currentValue !== changes.quickFilters.previousValue) {
    }
  }
}
