<div *skeleton="{ show: loading }">
  <div class="tab-header">
    <h1>
      <span class="pre-title">{{ 'GENERAL_I18N' | translate }}</span>
      <span>{{ (language | language | async)?.languageName }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <p-button *hasRight="'configuration.edit'" icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" [disabled]="loading || saving" (onClick)="save()"></p-button>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_LOCAL_PROPERTIES' | translate }}
    </div>
    <div *ngIf="language" class="panel-body">
      <p-card class="tiered-menu">
        <c-select-menu
          *ngIf="i18nModulesEn && !refreshing"
          [(currentItem)]="currentModule"
          (currentItemChange)="moduleChanged()"
          [items]="moduleSelectMenuItems"
          [selectedItems]="moduleSelectedMenuItems"
        ></c-select-menu>

        <div *ngIf="i18nModules" class="tiered-menu-content">
          <h2>{{ currentModule }}</h2>

          <c-field>
            <div class="p-grid gridinput">
              <div class="p-col"></div>
              <div class="p-col">
                {{ 'GENERAL_REFERENCE_LANGUAGE' | translate }}
                <p-dropdown [options]="languages" optionLabel="name" [(ngModel)]="compareLanguage" (onChange)="retrieveCompareLanguage($event)"></p-dropdown>
              </div>
            </div>
          </c-field>

          <c-field *ngFor="let key of currentModuleSortedKeys; trackBy: trackBy" [header]="key" [warning]="i18nModules[currentModule].data[key] ? '' : 'Please enter translation'">
            <div class="p-grid gridinput">
              <div class="p-col">
                <textarea pInputTextarea [(ngModel)]="i18nModules[currentModule].data[key]"></textarea>
              </div>
              <div class="p-col">
                <textarea pInputTextarea [(ngModel)]="i18nModulesCompare[currentModule].data[key]" readonly="true"></textarea>
              </div>
            </div>
          </c-field>
        </div>
      </p-card>
    </div>
  </div>
</div>
