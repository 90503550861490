import { Component, Input } from '@angular/core';
import { StreamStatus } from 'src/common/entities/Stream';

@Component({
  selector: 'c-stream-status',
  templateUrl: './stream-status.component.html',
  styleUrls: ['./stream-status.component.scss'],
})
export class StreamStatusComponent {
  @Input()
  status: StreamStatus;
}
