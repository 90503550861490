import { Component, Input } from '@angular/core';
import { Asset, isImageAsset } from 'src/common/entities/Asset';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'c-asset-preview',
  templateUrl: './asset-preview.component.html',
  styleUrls: ['./asset-preview.component.scss'],
})
export class AssetPreviewComponent {
  @Input()
  asset: Asset;

  @Input()
  wrapperStyle: string;

  get url(): string {
    if (this.asset && isImageAsset(this.asset) && this.asset.paths) {
      if (this.asset.mimeType === 'image/svg+xml') {
        return `url("${this.asset.paths.dataUri}")`;
      } else {
        return `url(${this.environment.cdn.images}/${this.asset.paths.thumbnail})`;
      }
    }

    return 'none';
  }

  get style(): any {
    return { 'background-image': this.url };
  }

  constructor(private environment: Environment) {}
}
