import { EventVersion } from '../../entities/EventVersion';
import { Patch } from '../../patch/Patch';
import { Inputs } from '../Inputs';

export const eventVersionChatsInputs: Inputs = {
  '$.chats[?(@.chat=="$chatId")]': {
    beforePatching: async (obj: EventVersion, patch: Patch) => {
      if (patch.command === 'delete') {
        const patches: Patch[] = [];

        for (const session of obj.sessions) {
          for (const language of Object.keys(session.local)) {
            if (session.local[language].chat === patch.jsonpathParams?.chatId) {
              patches.push({
                command: 'set',
                value: null,
                jsonpath: "$.sessions[?(@._id=='$sessionId')].local.$language.chat",
                jsonpathParams: {
                  sessionId: session._id,
                  language: language
                }
              });
            }
          }
        }

        return patches;
      }
      return [];
    }
  },
  "$.chats[?(@.chat=='$chatId')].internalName": {
    header: 'Internal Name'
  },
  "$.chats[?(@.chat=='$chatId')].autoConfirm": {
    header: 'Auto Confirm',
    type: 'switch'
  },
  "$.chats[?(@.chat=='$chatId')].chatReply": {
    header: 'Chat Reply',
    type: 'switch'
  }
};
