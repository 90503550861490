import { ButtonSpacingTopDefaultBreakpointValue, StageHeightDefaultBreakpointValue } from './DefaultBreakponitValue';
import { BreakpointValue } from './../../entities/Breakpoint';
import { MultiEventStagePageModule } from '../../entities/pagemodules/MultiEventStagePageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class MultiEventStagePageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public multiEventStagePageModuleLocal(values?: any) {
    return {
      title: values?.title || '',
      subtitle: values?.subtitle || '',
      preHeadline: values?.preHeadline || '',
      subtext: values?.subtext || '',
      events: values?.events || [],
      background: values?.background || null
    };
  }

  public async multiEventStagePageModuleButton(values?: any) {
    return {
      _id: await this.id(values?._id),
      ctaButton: values?.ctaButton || null
    };
  }

  public multiEventStagePageModuleStageHeight(defaultValues: BreakpointValue<number>, values?: any) {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge
    };
  }

  public multiEventStagePageModuleButtonSpacingTopBreakpoint(defaultValues: BreakpointValue<number>, values?: any) {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge
    };
  }

  public async multiEventStagePageModule(values: any): Promise<MultiEventStagePageModule> {
    if (values?.background) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.background = item.background || values.background;
      });
    }

    const pageModule = {
      ...(await this.baseValues(values)),
      pageModuleType: 'MultiEventStage',
      background: undefined,
      events: values?.events || null,
      local: await this.local(values, val => this.multiEventStagePageModuleLocal(val)),
      ctaButton: values?.ctaButton || null,
      ctalist: values?.ctalist || [],
      ctalistInteraction: values?.ctalistInteraction || [],
      discoverMore: values?.discoverMore || false,
      stageHeight: values?.stageHeight ? values?.stageHeight : this.multiEventStagePageModuleStageHeight(StageHeightDefaultBreakpointValue, values?.stageHeight),
      buttonSpacingTopBreakpoint: values?.buttonSpacingTopBreakpoint
        ? values?.buttonSpacingTopBreakpoint
        : this.multiEventStagePageModuleButtonSpacingTopBreakpoint(ButtonSpacingTopDefaultBreakpointValue, values?.buttonSpacingTopBreakpoint),
      hideEventName: values?.hideEventName || false,
      hideEventDate: values?.hideEventDate || false,
      hideEventTags: values?.hideEventTags || false
    } as MultiEventStagePageModule;

    // `events` migration `local..events`
    // `en` is used as default fallback
    if (pageModule.events && pageModule?.events?.length > 0) {
      if (!pageModule.local.en) {
        pageModule.local['en'] = this.multiEventStagePageModuleLocal();
      }

      pageModule.local.en.events = pageModule.events;
      pageModule.events = null;
    }

    return { ...pageModule, pageModuleType: 'MultiEventStage' };
  }
}
