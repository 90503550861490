import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventComponent } from './event.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ComponentsModule } from 'src/app/components/components.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { DataViewModule } from 'primeng/dataview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PickListModule } from 'primeng/picklist';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { SidebarModule } from 'primeng/sidebar';
import { TieredMenuModule } from 'primeng/tieredmenu';

import { EventBasicInformationComponent } from './event-basic-information/event-basic-information.component';
import { EventKeytopicsComponent } from './event-keytopics/event-keytopics.component';
import { EventProductsComponent } from './event-products/event-products.component';
import { EventSessionsComponent } from './event-sessions/event-sessions.component';
import { EventTicketsComponent } from './event-tickets/event-tickets.component';
import { EventSlotsComponent } from './event-slots/event-slots.component';
import { EventCustomFieldsComponent } from './event-custom-fields/event-custom-fields.component';
import { EventPreviewsComponent } from './event-previews/event-previews.component';
import { EventLayoutComponent } from './event-layout/event-layout.component';
import { EventStagesModule } from 'src/app/event-stages/event-stages.module';
import { PageModulesModule } from 'src/app/page-modules/page-modules.module';
import { PageModule } from 'src/app/page/page.module';
import { EventChatsComponent } from './event-chats/event-chats.component';
import { MenuModule } from 'primeng/menu';
import { EventStagesComponent } from './event-stages/event-stages.component';
import { LayoutEditorModule } from 'src/app/layout-editor/layout-editor.module';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { EventStatusComponent } from './event-status/event-status.component';
import { MessageModule } from 'primeng/message';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EventMapsComponent } from './event-maps/event-maps.component';

const routes: Routes = [{ path: '', component: EventComponent }];

@NgModule({
  declarations: [
    EventComponent,
    EventBasicInformationComponent,
    EventKeytopicsComponent,
    EventProductsComponent,
    EventSessionsComponent,
    EventTicketsComponent,
    EventLayoutComponent,
    EventSlotsComponent,
    EventCustomFieldsComponent,
    EventPreviewsComponent,
    EventChatsComponent,
    EventStagesComponent,
    EventStatusComponent,
    EventMapsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FormsModule,
    ButtonModule,
    CardModule,
    TableModule,
    InputTextModule,
    InputNumberModule,
    InputTextareaModule,
    ComponentsModule,
    PipesModule,
    MenuModule,
    MessageModule,
    DirectivesModule,
    TabViewModule,
    CalendarModule,
    DropdownModule,
    TieredMenuModule,
    InputSwitchModule,
    DividerModule,
    DialogModule,
    ConfirmPopupModule,
    AccordionModule,
    ChipModule,
    ChipsModule,
    CheckboxModule,
    SidebarModule,
    ListboxModule,
    DataViewModule,
    AutoCompleteModule,
    PickListModule,
    SplitButtonModule,
    PageModule,
    ConfirmDialogModule,
    MessagesModule,
    LayoutEditorModule,
    EventStagesModule,
    PageModulesModule,
    ChipModule,
    TooltipModule,
    InfiniteScrollModule,
  ],
})
export class EventModule {}
