import { ActivatedRoute, Router } from '@angular/router';
import { TabsService } from './../../tabs/services/tabs.service';
import { TableOptions, TableQuery } from './../../components/table/table.interfaces';
import { Tab } from './../../tabs/classes/tab';
import { Component, OnInit } from '@angular/core';
import { CustomFieldsService } from 'src/app/services/customFields/custom-fields.service';
import { CustomField } from 'src/common/entities/CustomField';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss'],
})
export class CustomFieldsComponent implements OnInit {
  tab: Tab;
  tableOptions: TableOptions<CustomField>;

  constructor(private customFieldsService: CustomFieldsService, private tabsService: TabsService, private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.tab = this.tabsService.register({
      category: 'data',
      route: this.activatedRoute.snapshot,
      title: 'Custom Fields',
    });

    this.tableOptions = {
      size: 50,
      columns: [
        { header: 'PAGE_CUSTOM_FIELD_INTERNAL_NAME', sort: 'internalName' },
        { header: 'PAGE_CUSTOM_FIELD_TYPE', sort: 'customFieldType' },
        { header: 'PAGE_CUSTOM_FIELD_TITLE', sort: 'local.en.title' },
      ],
      filters: [
        { header: 'PAGE_CUSTOM_FIELD_INTERNAL_NAME', path: 'firstName' },
        { header: 'PAGE_CUSTOM_FIELD_TYPE', path: 'customFieldType' },
        { header: 'PAGE_CUSTOM_FIELD_TITLE', path: 'local.en.title' },
      ],
    };
  }

  query(query: TableQuery<CustomField>): void {
    query.result = this.customFieldsService.getCustomFields(query.query);
  }

  async newCustomField(): Promise<void> {
    await this.router.navigate(['/configuration/custom-fields/new']);
  }

  getTitle(customField: CustomField): string {
    return customField.local.en?.title || customField.local[Object.keys(customField.local)[0]].title;
  }
}
