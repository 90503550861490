import { Component, OnDestroy, OnInit } from '@angular/core';

import { UtilsService } from 'src/app/services/utils/utils.service';

import { CustomFieldsService } from 'src/app/services/customFields/custom-fields.service';
import {
  CustomField,
  CustomFieldSelect,
  CustomFieldSelectOption,
  CustomFieldTicketAttendees,
  CustomFieldTicketAttendeesOption,
  customFieldTypes,
  isCustomFieldCheckbox,
  isCustomFieldSelect,
  isCustomFieldTicketAttendees,
  isCustomFieldTicketSelfVisit,
} from 'src/common/entities/CustomField';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { customFieldValidator } from 'src/common/api/v1/configuration/customFields/validators/CustomFieldValidator';
import { DropdownOption, Inputs, InputType } from '../../../common/inputs/Inputs';
import { CollaborationService } from '../../services/collaboration/collaboration.service';
import { LanguagesService } from '../../services/languages/languages.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomFieldFactory } from '../../../common/factories/CustomFieldFactory';
import { AuthService } from '../../services/auth/auth.service';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { Tab } from 'src/app/tabs/classes/tab';

@Component({
  selector: 'app-custom-field',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.scss'],
})
export class CustomFieldComponent implements OnInit, OnDestroy {
  languages = ['en'];
  currentLanguage = 'en';
  tab: Tab;

  customFieldOptions: DropdownOption[] = [];
  ticketAttendeesFieldOptions = [
    { value: 'user.firstName', label: 'First Name' },
    { value: 'user.lastName', label: 'Last Name' },
    { value: 'user.email', label: 'Email' },
    { value: 'user.text', label: 'Text' },
  ];

  customField: CustomField = null;
  customFieldId: string = null;

  customFieldTypes: { value: string; label: string }[] = [];

  loading = false;
  saving = false;

  titleInput: Inputs = {
    '$.local.$language.title': {
      header: 'PAGE_CUSTOM_FIELD_TITLE',
      description: 'PAGE_CUSTOM_FIELD_TITLE_DESCRIPTION',
      type: 'text',
    },
  };

  defaultInputField: Inputs = this.generateNewDefaultInput();

  fieldsLocalTitleInput: Inputs = {
    '$.fields[$index].local.$language.title': {
      header: 'PAGE_CUSTOM_FIELD_SELECT_OPTION_FIELD_TITLE',
      description: 'PAGE_CUSTOM_FIELD_SELECT_OPTION_FIELD_TITLE_DESCRIPTION',
      type: 'text',
    },
  };

  fieldsInternalNameInput: Inputs = {
    '$.fields[$index].internalName': {
      header: 'PAGE_CUSTOM_FIELD_SELECT_OPTION_FIELD_INTERNAL_NAME',
      type: 'text',
    },
  };

  fieldsMaxCharactersInput: Inputs = {
    '$.fields[$index].maxCharacters': {
      header: 'PAGE_CUSTOM_FIELD_SELECT_OPTION_FIELD_MAX_CHARACTERS',
      type: 'number',
    },
  };

  optionLocalInput: Inputs = {
    '$.options[$index].local.$language': {
      header: 'PAGE_CUSTOM_FIELD_TYPE_LOCAL_PROPERTY_OPTION',
      description: 'PAGE_CUSTOM_FIELD_TYPE_LOCAL_PROPERTY_OPTION_DESCRIPTION',
      type: 'text',
    },
  };

  descriptionInput: Inputs = {
    '$.local.$language.description': {
      header: 'PAGE_CUSTOM_FIELD_TITLE_DESCRIPTION_F',
      description: 'PAGE_CUSTOM_FIELD_TITLE_DESCRIPTION_F_DESCRIPTION',
      type: 'html',
    },
  };

  checkboxDescriptionInput: Inputs = {
    '$.local.$language.checkboxDescription': {
      header: 'PAGE_CUSTOM_FIELD_CHECKBOX_DESCRIPTION_F',
      description: 'PAGE_CUSTOM_FIELD_CHECKBOX_DESCRIPTION_F_DESCRIPTION',
      type: 'html',
    },
  };

  private subscriptions: Subject<any> = new Subject<any>();

  fieldTypeLookup(): InputType {
    switch (this.customField?.customFieldType) {
      case 'checkbox':
      case 'ticketSelfVisit':
        return 'switch';
      case 'textarea':
        return 'html';
      case 'select':
        return 'dropdown';
      default:
        return 'text';
    }
  }

  fieldPathLookup(): string {
    switch (this.customField?.customFieldType) {
      case 'checkbox':
      case 'ticketSelfVisit':
        return '$.default';
      default:
        return '$.local.$language.default';
    }
  }

  generateNewDefaultInput(): Inputs {
    return {
      [this.fieldPathLookup()]: {
        header: 'PAGE_CUSTOM_FIELD_DEFAULT',
        description: 'PAGE_CUSTOM_FIELD_DEFAULT_DESCRIPTION',
        dropdownOptions: this.customFieldOptions,
        type: this.fieldTypeLookup(),
      },
    };
  }

  get showDefaultFieldInput(): boolean {
    return !(this.customField.customFieldType === 'ticketSlotSelection' || this.customField.customFieldType === 'ticketAttendees');
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private validatorService: ValidatorService,
    private customFieldsService: CustomFieldsService,
    public utilsService: UtilsService,
    private router: Router,
    private collaborationService: CollaborationService,
    private languagesService: LanguagesService,
    private translateService: TranslateService,
    private authService: AuthService,
    private tabsService: TabsService
  ) {
    this.tab = this.tabsService.register({
      category: 'configuration',
      parent: '/configuration/custom-fields',
      loading: true,
      route: this.activatedRoute.snapshot,
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe subscriptions
    this.subscriptions.next();
    this.subscriptions.complete();
    this.collaborationService.unregisterLocal(`customField:${this.customFieldId}`);
  }

  async ngOnInit(): Promise<void> {
    for (const fieldType of customFieldTypes) {
      this.customFieldTypes.push({
        value: fieldType,
        label: await this.utilsService.translate('PAGE_CUSTOM_FIELD_TYPE_' + fieldType.toUpperCase()),
      });
    }

    this.activatedRoute.params.pipe(takeUntil(this.subscriptions)).subscribe(async (params) => {
      this.customFieldId = params.id;
      if (this.customFieldId) {
        this.loading = true;
        if (this.customFieldId === 'new') {
          this.initCurrentLanguage(true);
          // Create custom field
          this.customField = {
            _id: 'new',
            internalName: '',
            customFieldType: 'text',
            local: {
              [this.currentLanguage]: {
                title: '',
                description: '',
                checkboxDescription: '',
              },
            },
            default: this.getNewUnlocalizedDefaultValue(),
          };
        } else {
          const field = await this.customFieldsService.getCustomField(this.customFieldId);
          this.customField = await new CustomFieldFactory().createCustomField(field);
          this.convertBoolean();
          this.initCurrentLanguage();
        }
        this.refreshDefaultInput();
        this.loading = false;
      }

      this.collaborationService.registerLocal(`customField:${this.customFieldId}`, this.customField);
    });
  }

  convertBoolean(): void {
    if (this.isDefaultValueCheckbox() && this.customField.local[this.currentLanguage].default) {
      delete this.customField.local[this.currentLanguage].default;
    }
  }

  getNewUnlocalizedDefaultValue(): boolean | undefined {
    return isCustomFieldTicketSelfVisit(this.customField) ? true : isCustomFieldCheckbox(this.customField) ? undefined : false;
  }

  isDefaultValueCheckbox(): boolean {
    return ['checkbox', 'ticketSelfVisit'].includes(this.customField.customFieldType);
  }

  initCurrentLanguage(isNew = false): void {
    const currentLang = this.activatedRoute.snapshot.queryParams.language || 'en';
    if (isNew) {
      this.currentLanguage = currentLang;
      this.languages = [this.currentLanguage];
    } else {
      this.languages = Object.keys(this.customField.local);
      if (!this.customField.local[currentLang]) {
        this.currentLanguage = this.languages[0];
      } else {
        this.currentLanguage = currentLang;
      }
      this.customField.languages = this.languages;
    }
  }

  customFieldTypeChange($type): void {
    if ($type === 'select') {
      this.asCustomFieldSelect(this.customField).options = [];
    } else if ($type === 'ticketAttendees') {
      this.asCustomFieldTicketAttendee(this.customField).fields = [];
      this.customFieldOptions = [{ value: '', label: '-' }];
      this.optionKeyChange();
    } else {
      this.customField.local[this.currentLanguage].default = null;
      if (Object.keys(this.customField).find((item) => item === 'options')) {
        delete (this.customField as CustomFieldSelect).options;
      } else if (Object.keys(this.customField).find((item) => item === 'fields')) {
        delete (this.customField as CustomFieldTicketAttendees).fields;
      }

      Object.keys(this.customField.local).forEach((lg) => (this.customField.local[lg].checkboxDescription = ''));
    }

    this.refreshDefaultInput();
  }

  refreshDefaultInput(): void {
    if (isCustomFieldSelect(this.customField)) {
      let custFieldSelect = this.asCustomFieldSelect(this.customField);
      this.customFieldOptions = [{ value: '', label: '-' }].concat(custFieldSelect?.options.map((item) => ({ value: item.key, label: this.translateService.instant(item.key) })) || []);
    }
    this.defaultInputField = this.generateNewDefaultInput();
  }

  async onAddOption(customFieldSelect: CustomFieldSelect | CustomFieldTicketAttendees): Promise<void> {
    if (isCustomFieldTicketAttendees(customFieldSelect)) {
      if (!customFieldSelect.fields) {
        customFieldSelect.fields = new Array<CustomFieldTicketAttendeesOption>();
      }

      customFieldSelect.fields.splice(
        customFieldSelect.fields.filter((f) => !f.deletedBy).length,
        0,
        await new CustomFieldFactory({ ensureLocals: this.languages }).createCustomFieldAttendeeOptionField({
          _id: 'new',
          fieldType: this.ticketAttendeesFieldOptions[0].value,
          local: {},
        })
      );
    } else {
      if (!customFieldSelect.options) {
        customFieldSelect.options = new Array<CustomFieldSelectOption>();
      }

      customFieldSelect.options.push({
        key: 'New option key',
        local: { [this.currentLanguage]: '' },
      });
      this.optionKeyChange();
    }
  }

  optionKeyChange(): void {
    this.customField.local[this.currentLanguage].default = '';
    this.refreshDefaultInput();
  }

  onDeleteOption(customFieldSelect: CustomFieldSelect | CustomFieldTicketAttendees, atIndex: number): void {
    if (isCustomFieldTicketAttendees(customFieldSelect)) {
      if (customFieldSelect.fields[atIndex]._id !== 'new') {
        customFieldSelect.fields[atIndex].deletedAt = new Date().toISOString();
        customFieldSelect.fields[atIndex].deletedBy = this.authService.currentAdminUser()._id;

        // move to bottom for correct field shows index
        const element = customFieldSelect.fields[atIndex];
        customFieldSelect.fields.splice(atIndex, 1);
        customFieldSelect.fields.splice(customFieldSelect.fields.length, 0, element);
      } else {
        customFieldSelect.fields.splice(atIndex, 1);
      }
    } else {
      customFieldSelect.options.splice(atIndex, 1);
    }
    this.optionKeyChange();
  }

  onUpOption(customFieldSelect: CustomFieldSelect | CustomFieldTicketAttendees, atIndex: number): void {
    if (isCustomFieldTicketAttendees(customFieldSelect)) {
      if (atIndex > 0 && atIndex <= customFieldSelect.fields.length - 1) {
        const element = customFieldSelect.fields[atIndex];
        customFieldSelect.fields.splice(atIndex, 1);
        customFieldSelect.fields.splice(atIndex - 1, 0, element);
        this.optionKeyChange();
      }
    } else {
      if (atIndex > 0 && atIndex <= customFieldSelect.options.length - 1) {
        const element = customFieldSelect.options[atIndex];
        customFieldSelect.options.splice(atIndex, 1);
        customFieldSelect.options.splice(atIndex - 1, 0, element);
        this.optionKeyChange();
      }
    }
  }

  onDownOption(customFieldSelect: CustomFieldSelect | CustomFieldTicketAttendees, atIndex: number): void {
    if (isCustomFieldTicketAttendees(customFieldSelect)) {
      if (atIndex >= 0 && atIndex <= customFieldSelect.fields.length - 2) {
        const element = customFieldSelect.fields[atIndex];
        customFieldSelect.fields.splice(atIndex, 1);
        customFieldSelect.fields.splice(atIndex + 1, 0, element);
        this.optionKeyChange();
      }
    } else {
      if (atIndex >= 0 && atIndex <= customFieldSelect.options.length - 2) {
        const element = customFieldSelect.options[atIndex];
        customFieldSelect.options.splice(atIndex, 1);
        customFieldSelect.options.splice(atIndex + 1, 0, element);
        this.optionKeyChange();
      }
    }
  }

  async onSave(): Promise<void> {
    this.saving = true;
    try {
      if (this.customFieldId === 'new') {
        this.customField = await this.customFieldsService.createCustomField(this.customField);
        await this.router.navigate(['/configuration/custom-fields', this.customField._id], { replaceUrl: true });
        this.collaborationService.registerLocal(`customField:${this.customFieldId}`, this.customField);
        this.tabsService.close(this.tab);
      } else {
        this.customField = await this.customFieldsService.updateCustomField(this.customField);
        this.collaborationService.registerLocal(`customField:${this.customFieldId}`, this.customField, true);
      }
      this.convertBoolean();
    } catch (err) {
      console.error(err);
    }
    this.saving = false;
  }

  isValidCustomField(): boolean {
    return this.validatorService.isValid(customFieldValidator, this.customField);
  }

  errorAtCustomField(path: string): string | null {
    return this.validatorService.errorAt(customFieldValidator, this.customField, path);
  }

  asCustomFieldSelectOrTicketAttendee(customField: any): CustomFieldSelect | CustomFieldTicketAttendees {
    return isCustomFieldSelect(customField) || isCustomFieldTicketAttendees(customField) ? customField : null;
  }

  asCustomFieldSelect(customField: any): CustomFieldSelect {
    return isCustomFieldSelect(customField) ? customField : null;
  }

  asCustomFieldTicketAttendee(customField: any): CustomFieldTicketAttendees {
    return isCustomFieldTicketAttendees(customField) ? customField : null;
  }

  onCurrentLanguageChange(newCurrentLanguages: string): void {
    this.currentLanguage = newCurrentLanguages;
  }

  onActiveLanguageChange(languages: string[]): void {
    this.languages = languages;
    this.customField.languages = this.languages;
    if (this.customField) {
      for (const language of Object.keys(this.customField.local)) {
        if (!this.languages.includes(language)) {
          delete this.customField.local[language];
        }
      }
      for (const language of languages) {
        if (!this.customField.local[language]) {
          this.customField.local[language] = {
            title: '',
            description: '',
            default: null,
            checkboxDescription: '',
          };
        }
        if (this.asCustomFieldTicketAttendee(this.customField)) {
          this.asCustomFieldTicketAttendee(this.customField).fields.forEach((item, index) => {
            if (!item.local[language]) {
              this.asCustomFieldTicketAttendee(this.customField).fields[index].local[language] = { title: '' };
            }
          });
        }
        if (this.asCustomFieldSelect(this.customField)) {
          this.asCustomFieldSelect(this.customField).options.forEach((item, index) => {
            if (!item.local[language]) {
              this.asCustomFieldSelect(this.customField).options[index].local[language] = '';
            }
          });
        }
      }
    }
  }

  getFieldsOrOptions(customField: CustomFieldSelect | CustomFieldTicketAttendees): Iterable<CustomFieldSelectOption | CustomFieldTicketAttendeesOption> {
    return isCustomFieldSelect(customField) ? customField.options : customField.fields;
  }

  isNotDeleted(customField: CustomField, optionField: any): boolean {
    return !this.asCustomFieldAttendeeOption(customField, optionField)?.deletedBy;
  }

  isLastOptionField(customField: CustomFieldSelect | CustomFieldTicketAttendees, currentIndex: number): boolean {
    return isCustomFieldSelect(customField) ? currentIndex === customField.options.length - 1 : currentIndex === customField.fields?.filter((f) => !f.deletedBy).length - 1;
  }

  asCustomFieldSelectOption(customField: CustomField, optionField: any): CustomFieldSelectOption {
    return isCustomFieldSelect(customField) ? (optionField as CustomFieldSelectOption) : null;
  }

  asCustomFieldAttendeeOption(customField: CustomField, optionField: any): CustomFieldTicketAttendeesOption {
    return isCustomFieldTicketAttendees(customField) ? (optionField as CustomFieldTicketAttendeesOption) : null;
  }
}
