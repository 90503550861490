import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CustomDataService } from 'src/app/services/custom-data/custom-data.service';
import { IFilterList } from 'src/common/api/interfaces';
import { CustomData } from 'src/common/entities/CustomData';

@Component({
  selector: 'c-customdata-select',
  templateUrl: './customdata-select.component.html',
  styleUrls: ['./customdata-select.component.scss'],
})
export class CustomdataSelectComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input()
  id: string;

  @Output()
  idChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  disabled = false;

  @ViewChild('searchInput')
  searchInput: ElementRef<HTMLInputElement>;

  customData: CustomData;

  showSearch = false;
  searchText: string;
  searchTextSubject: Subject<string> = new Subject<string>();
  searchTextSubscription: Subscription;
  searchResult: CustomData[] = [];

  constructor(private customDataService: CustomDataService) {}

  ngOnInit(): void {
    this.searchTextSubscription = this.searchTextSubject.pipe(debounceTime(500)).subscribe(() => this.executeSearch());
    this.executeSearch(false);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.id && changes.id.currentValue !== changes.id.previousValue && this.id) {
      this.customData = await this.customDataService.getCustomData(this.id);
    }
  }

  ngOnDestroy() {
    this.searchTextSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.searchInput?.nativeElement?.focus();
    }, 100);
  }

  searchTextKeyUp() {
    this.searchTextSubject.next(this.searchText);
  }

  async executeSearch(append: boolean = false) {
    // this.loading = true

    const filter: IFilterList = {
      status: {
        matchMode: 'equals',
        value: 'FINISHED',
      },
    };

    if (this.searchText) {
      filter.internalName = {
        matchMode: 'contains',
        value: this.searchText,
        caseInsensitive: true,
      };
    }

    if (!append) {
      this.searchResult = [];
    }

    const result = await this.customDataService.getCustomData({
      limit: 10,
      skip: 0,
      filter,
    });

    this.searchResult = this.searchResult.concat(result.items);

    // this.loading = false
  }

  selectCustomData(customData: CustomData) {
    this.showSearch = false;
    this.customData = customData;
    this.id = this.customData._id;
    this.idChange.emit(this.id);
  }

  remove() {
    this.showSearch = false;
    this.customData = null;
    this.id = null;
    this.idChange.emit(this.id);
  }
}
