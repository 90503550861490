<h2 class="table-header">
  <div class="table-params">
    <div class="input-group compact">
      <div class="input">
        <p-dropdown [options]="search.originDropdown" [(ngModel)]="search.origin" placeholder="All" optionLabel="name" [showClear]="true" (onChange)="load()"></p-dropdown>
      </div>
      <div class="title">{{ 'APPOINTMENTS_TABLE_INITIATED_BY' | translate }}</div>
    </div>
    <div class="input-group compact">
      <div class="input">
        <p-dropdown [options]="search.assignedDropdown" [(ngModel)]="search.assigned" placeholder="All" optionLabel="name" [showClear]="true" (onChange)="load()"></p-dropdown>
      </div>
      <div class="title">{{ 'APPOINTMENTS_TABLE_ASSIGNED' | translate }}</div>
    </div>
    <div class="input-group compact">
      <div class="input">
        <c-user-picker [(user)]="search.organizer" [multi]="false" [group]="organizerGroup" userType="ADMIN_USER" (userChange)="load()"></c-user-picker>
      </div>
      <div class="title">{{ 'APPOINTMENTS_TABLE_ORGANIZER' | translate }}</div>
    </div>
    <div class="input-group compact">
      <div class="input">
        <c-user-picker [(user)]="search.host" [multi]="false" userType="ADMIN_USER" (userChange)="load()"></c-user-picker>
      </div>
      <div class="title">{{ 'APPOINTMENTS_TABLE_HOST' | translate }}</div>
    </div>
    <div class="input-group compact">
      <div class="input">
        <c-user-picker [(user)]="search.user" [multi]="false" userType="USER" (userChange)="load()"></c-user-picker>
      </div>
      <div class="title">{{ 'APPOINTMENTS_TABLE_CUSTOMER' | translate }}</div>
    </div>
  </div>
</h2>

<div class="new-appointments" *ngIf="(watchAppointments | async)?.length > 0">New items in list: <button class="p-button p-button-link" (click)="load()">Reload</button></div>

<p-table
  *ngIf="!displayAppointmentDetail"
  #appointmentTable
  [value]="appointments | async"
  [paginator]="group !== 'open'"
  [loading]="loading"
  (onLazyLoad)="load($event)"
  [lazy]="true"
  [rows]="group !== 'open' ? 30 : 99999"
  [totalRecords]="totalRecords"
  styleClass="p-datatable-sm"
>
  <!-- <ng-template pTemplate="caption"></ng-template> -->
  <ng-template pTemplate="header">
    <tr>
      <th>{{ 'PAGE_APPOINTMENTS_HEADER_MEETING_STATUS' | translate }}</th>
      <th>
        <div>{{ 'PAGE_APPOINTMENTS_HEADER_COMPANY' | translate }}</div>
        <div>{{ 'PAGE_APPOINTMENTS_HEADER_CONTACT' | translate }}</div>
      </th>
      <!-- <th>{{'PAGE_APPOINTMENTS_HEADER_EXISTING_CUSTOMER' | translate}}</th> -->
      <th>
        <!-- <div>{{'PAGE_APPOINTMENTS_HEADER_REGION' | translate}}</div> -->
        <div>{{ 'PAGE_APPOINTMENTS_HEADER_COUNTRY' | translate }}</div>
      </th>
      <th>Host</th>
      <th>{{ 'PAGE_APPOINTMENTS_HEADER_ORGANIZER' | translate }}</th>
      <th pSortableColumn="dateFrom">{{ 'PAGE_APPOINTMENTS_HEADER_START' | translate }} <p-sortIcon field="dateFrom"></p-sortIcon></th>
      <th pSortableColumn="createdAt">{{ 'PAGE_APPOINTMENTS_HEADER_CREATEDAT' | translate }} <p-sortIcon field="createdAt"></p-sortIcon></th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-appointment>
    <tr
      [ngClass]="{
        deletedAppointments: appointment.deletedAt,
        confirmedAppointments: appointment.status?.toUpperCase() == 'CONFIRMED' && !appointment.deletedAt,
        unconfirmedAppointments: appointment.status?.toUpperCase() != 'CONFIRMED' && appointment.status?.toUpperCase() != 'DONE' && !appointment.deletedAt,
        completedAppointments: (appointment.status?.toUpperCase() == 'DONE' || appointment.status?.toUpperCase() == 'REJECTED') && !appointment.deletedAt
      }"
    >
      <td>
        <span *ngIf="!appointment.deletedAt">
          <span *ngIf="appointment.status === 'Confirmed' && appointment.actualDateFrom && !appointment.actualDateTo"> Running&nbsp;... </span>
          <span *ngIf="!(appointment.status === 'Confirmed' && appointment.actualDateFrom && !appointment.actualDateTo)">
            {{ 'GENERAL_APPOINTMENT_STATUSTYPE_' + appointment.status.toUpperCase() | translate }}
          </span>
        </span>
        <span *ngIf="appointment.deletedAt">{{ 'GENERAL_DELETED' | translate }}</span>
      </td>
      <td>
        <div class="p-text-nowrap p-text-truncate" style="font-weight: bold">{{ (appointment.participants.users[0] | user | async)?.organization }}</div>
        <div>{{ (appointment.participants.users[0] | user | async)?.displayName }}</div>
      </td>
      <td>
        <!-- <div>{{appointment.region ? (appointment.region | region | async)?.regionName : "-" }}</div> -->
        <div>{{ i18nService.translateCountry((appointment?.participants.users[0] | user | async)?.country) }}</div>
      </td>
      <td>
        {{ (appointment.participants.host | adminUser | async)?.displayName }}
      </td>
      <td>
        <i *ngIf="appointment.origin === 'Host'">Self hosted</i>
        <span *ngIf="appointment.origin !== 'Host' && appointment.organizer">{{ (appointment.organizer | adminUser | async)?.displayName }}</span>
        <span *ngIf="appointment.origin !== 'Host' && !appointment.organizer" style="font-weight: bold">{{ 'PAGE_APPOINTMENTS_GENERAL_UNASSIGNED_ORGANIZER' | translate }}</span>
      </td>
      <td>{{ appointment.dateFrom | date : 'short' }}</td>
      <td>{{ appointment.createdAt | date : 'short' }}</td>
      <td *hasRight="['appointments.read', 'appointements.edit']" class="p-text-center appointmentActions">
        <div *ngIf="appointment | fn : getActionItem : appointment as actionItem">
          <p-button *ngIf="actionItem.menuItems.length === 0" styleClass="p-button-sm appointment-button" (onClick)="actionItem.action()" [label]="actionItem.label"></p-button>
          <p-splitButton
            *ngIf="actionItem.menuItems.length > 0"
            styleClass="p-button-sm appointment-button"
            [label]="actionItem.label"
            (onClick)="actionItem.action()"
            [model]="actionItem.menuItems"
          ></p-splitButton>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">{{ 'GENERAL_NO_RECORDS_FOUND' | translate }}</td>
    </tr>
  </ng-template>
</p-table>

<p-dialog [showHeader]="false" [(visible)]="displayAppointmentDetail" (onShow)="showDialogMaximized($event, appointmentDetail)" #appointmentDetail>
  <app-appointment-detail *ngIf="appointment" [adhocAppointment]="false" [appointment]="appointment" [dialog]="appointmentDetail"></app-appointment-detail>
</p-dialog>
