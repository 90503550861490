import { PostFormsBody, PostFormsQuery, PostFormsResponse } from './../../../common/api/v1/configuration/forms/PostForms';
import { Form } from './../../../common/entities/Form';
import { GetFormsQuery, GetFormsResponse } from './../../../common/api/v1/configuration/forms/GetForms';
import { ApiService } from 'src/app/services/api/api.service';
import { Injectable } from '@angular/core';
import { PostFormBody, PostFormQuery, PostFormResponse } from './../../../common/api/v1/configuration/forms/PostForm';
import { GetFormQuery, GetFormResponse } from 'src/common/api/v1/configuration/forms/GetForm';
import { GetFormEntryQuery, GetFormEntryResponse } from 'src/common/api/v1/configuration/forms/GetFormEntry';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  constructor(private apiService: ApiService) {}

  getForm(id: string): Promise<Form> {
    return this.apiService.get<GetFormQuery, GetFormResponse>(`/api/v1/configuration/forms/${id}`).toPromise();
  }

  async getForms(query?: GetFormsQuery): Promise<GetFormsResponse> {
    return await this.apiService
      .get<GetFormsQuery, GetFormsResponse>('/api/v1/configuration/forms', {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();
  }

  async getFormEntry(id?: string): Promise<GetFormEntryResponse> {
    return await this.apiService.get<GetFormEntryQuery, GetFormEntryResponse>(`/api/v1/configuration/form-entry/${id}`).toPromise();
  }

  async getFormEntries(form: Form, query?: GetFormsQuery): Promise<GetFormsResponse> {
    return await this.apiService
      .get<GetFormsQuery, GetFormsResponse>(`/api/v1/configuration/forms/${form._id}/entries`, {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();
  }

  downloadFormEntries(formId: string, query?: GetFormsQuery) {
    this.apiService.download(`/api/v1/configuration/forms/${formId}/entries`, 'form_entries', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', {
      ...query,
      format: 'xlsx',
    });
  }

  createForm(form: Form): Promise<Form> {
    return this.apiService.post<PostFormsQuery, PostFormsBody, PostFormsResponse>(`/api/v1/configuration/forms`, form).toPromise();
  }

  updateForm(form: Form): Promise<Form> {
    return this.apiService.post<PostFormQuery, PostFormBody, PostFormResponse>(`/api/v1/configuration/forms/${form._id}`, form).toPromise();
  }
}
