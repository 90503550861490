import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AssetsService } from 'src/app/services/assets/assets.service';
import { IFilterList } from 'src/common/api/interfaces';
import { Asset } from 'src/common/entities/Asset';
import { TableOptions, TableQuery } from '../../table/table.interfaces';
import { GetAssetsQuery } from '../../../../common/api/v1/assets/GetAssets';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';

@Component({
  selector: 'c-asset-select-search',
  templateUrl: './asset-select-search.component.html',
  styleUrls: ['./asset-select-search.component.scss'],
})
export class AssetSelectSearchComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() minWidth: number;
  @Input() minHeight: number;
  @Input() width: number;
  @Input() height: number;
  @Input() mimeTypes: string[];

  @Output()
  assetSelected: EventEmitter<Asset> = new EventEmitter<Asset>();

  @ViewChild('searchInput')
  searchInput: ElementRef<HTMLInputElement>;

  $searchText = new BehaviorSubject<string | null>(null);
  $refresh = new BehaviorSubject<boolean>(true);

  $searchQuery = this.$searchText.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    map(this.getQuery),
    tap((query) => {
      this.query = query;
      this.$refresh.next(true);
    })
  );

  imageFilterChecked = false;
  videoFilterChecked = false;
  documentFilterChecked = false;

  image: string = 'image/*';
  video: string = 'video/*';
  document: string = 'application/*';
  filters = {
    image: { checked: () => this.imageFilterChecked, value: 'image/' },
    video: { checked: () => this.videoFilterChecked, value: 'video/' },
    document: { checked: () => this.documentFilterChecked, value: 'application/' },
  };

  tableOptions: TableOptions<Asset> = {
    size: 20,
    columns: [{ header: '' }],
    defaultSortDirection: 'desc',
  };
  private query: GetAssetsQuery = { filter: {} };
  subscriptions: Subscription[] = [];

  constructor(private assetsService: AssetsService) {}
  ngOnInit(): void {
    if (this.mimeTypes?.length > 0) {
      if (this.mimeTypes.includes(this.image)) {
        this.imageFilterChecked = true;
      } else if (this.mimeTypes.includes(this.video)) {
        this.videoFilterChecked = true;
      } else if (this.mimeTypes.includes(this.document)) {
        this.documentFilterChecked = true;
      }
    }
    this.$refresh.next(true);
  }

  ngAfterViewInit() {
    this.subscriptions.push(this.$searchQuery.subscribe());
    setTimeout(() => {
      this.searchInput?.nativeElement?.focus();
    }, 100);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  selectAsset(asset: Asset) {
    this.assetSelected.emit(asset);
  }

  private getQuery(searchText: string): GetAssetsQuery {
    const filter: IFilterList = {
      status: {
        matchMode: 'equals',
        value: 'FINISHED',
      },
    };

    if (searchText) {
      filter.internalName = {
        matchMode: 'contains',
        value: searchText,
        caseInsensitive: true,
      };
    }

    if (this.mimeTypes?.length > 0) {
      filter.mimeType = {
        matchMode: 'startsWith',
        value: this.mimeTypes.map((mimeType) => {
          return mimeType.endsWith('*') ? mimeType.replace('*', '') : mimeType;
        }),
      };
    }

    if (this.width || this.minWidth) {
      filter.width = {
        matchMode: this.width ? 'equals' : 'gte',
        value: this.width || this.minWidth,
      };
    }

    if (this.height || this.minHeight) {
      filter.height = {
        matchMode: this.height ? 'equals' : 'gte',
        value: this.height || this.minHeight,
      };
    }

    return { filter };
  }

  handleFilterChange() {
    this.$refresh.next(true);
  }

  includeChip(): boolean {
    return !this.mimeTypes.some((mimeType) => mimeType.includes('*'));
  }

  handleInput($event: Event) {
    this.$searchText.next(($event.target as HTMLInputElement).value);
  }

  setQuery(tableQuery: TableQuery<Asset>) {
    if (this.mimeTypes) {
      for (const filterType in this.filters) {
        if (this.filters[filterType].checked() && !this.mimeTypes.includes(this.filters[filterType].value)) {
          this.mimeTypes.unshift(this.filters[filterType].value);
        } else if (!this.filters[filterType].checked() && this.mimeTypes.includes(this.filters[filterType].value)) {
          this.mimeTypes = this.mimeTypes.filter((mime) => mime !== this.filters[filterType].value);
        }
        this.query.filter = {
          ...this.query.filter,
          mimeType: {
            matchMode: 'contains',
            value: this.mimeTypes.length === 0 ? this.mimeTypes && '' : this.mimeTypes,
          },
        };
      }
    }

    tableQuery.query = { ...tableQuery.query, ...this.query };
    tableQuery.result = this.assetsService.getAssets(tableQuery.query);
  }
}
