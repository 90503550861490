<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'PAGE_CUSTOM_FIELDS' | translate }}</h1>
  </div>
  <div class="tab-menu"></div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_CUSTOM_FIELDS' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button *hasRight="'configuration.create'" [label]="'PAGE_CUSTOM_FIELDS_ADD' | translate" icon="pi icon-ic-add-item" (onClick)="newCustomField()"></p-button>
      </div>
    </div>
    <c-table mode="query" [options]="tableOptions" [tableOptionsComponent]="toc" (query)="query($event)" [baseRoute]="'/configuration/custom-fields'" [routePathProperty]="'_id'">
      <ng-template let-customField>
        <a *hasRight="['configuration.read', 'configuration.edit']">{{ customField.internalName }}</a>
        <span *hasNoRight="['configuration.read', 'configuration.edit']">{{ customField.internalName }}</span>
      </ng-template>
      <ng-template let-customField>
        {{ 'PAGE_CUSTOM_FIELD_TYPE_' + customField.customFieldType.toUpperCase() | translate }}
      </ng-template>
      <ng-template let-customField>{{ getTitle(customField) }}</ng-template>
    </c-table>
  </div>
</div>

<!--
<div class="content">

  <div class="page-header">
    <h1>
      <span class="header">
        {{ "PAGE_CUSTOM_FIELDS" | translate }}
        <span class="subheader" *ngIf="loading">{{ "GENERAL_LOADING" | translate }} ...</span>
      </span>
    </h1>
    <p-divider align="right">
      <div class="divider-menu">
        <p-button styleClass="p-button-text" [routerLink]="['./new']" [label]="('PAGE_CUSTOM_FIELDS_ADD' | translate)"></p-button>
      </div>
    </p-divider>
  </div>


  <div>
    <p-table [value]="customFields" [lazy]="true" (onLazyLoad)="retrieveData($event)" [paginator]="true" [rows]="15" [totalRecords]="totalRecords" styleClass="p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ "PAGE_CUSTOM_FIELD_INTERNAL_NAME" | translate }}</th>
          <th>{{ "PAGE_CUSTOM_FIELD_TYPE" | translate }}</th>
          <th>{{ "PAGE_CUSTOM_FIELD_TITLE" | translate }}</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-customField>
        <tr>
          <td>{{ customField.internalName }}</td>
          <td>{{ ("PAGE_CUSTOM_FIELD_TYPE_" + customField.customFieldType.toUpperCase()) | translate }}</td>
          <td>{{ customField.local[utilsService.currentLanguage]?.title }}</td>
          <td class="text-right"><a [routerLink]="[customField._id]">{{ "GENERAL_DETAILS" | translate }}</a></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div> -->
