import { BaseSessionFactory } from '../../factories/sessions/BaseSessionFactory';
import { BreakoutSessionFactory } from '../../factories/sessions/BreakoutSessionFactory';
import { isBreakoutSession, isLiveSessionMedia, isSessionMediaWithAsset, sessionMediaTypes } from '../../entities/Session';
import { Inputs } from '../Inputs';

export const actualStartAt: any = {
  type: 'datetime',
  datetimeOptions: {
    showSeconds: true,
  },
};

export const actualEndAt: any = {
  type: 'datetime',
  datetimeOptions: {
    showSeconds: true,
  },
};

export const eventVersionSessionsInputs: Inputs = {
  "$.sessions[?(@._id=='$sessionId')].startAt": {},
  "$.sessions[?(@._id=='$sessionId')].endAt": {},
  "$.sessions[?(@._id=='$sessionId')].actualStartAt": actualStartAt,
  "$.sessions[?(@._id=='$sessionId')].actualEndAt": actualEndAt,
  "$.sessions[?(@._id=='$sessionId')].highlight": { type: 'switch' },
  "$.sessions[?(@._id=='$sessionId')].hidden": { type: 'switch' },
  "$.sessions[?(@._id=='$sessionId')].skippable": { type: 'switch' },
  "$.sessions[?(@._id=='$sessionId')].maxChatMessages": { type: 'number' },
  "$.sessions[?(@._id=='$sessionId')].images.main": {
    type: 'imageasset',
  },

  "$.sessions[?(@._id=='$sessionId')].local.$language": {
    factory: async () => new BaseSessionFactory().createSessionLocal({}),
  },
  "$.sessions[?(@._id=='$sessionId')].local.$language.title": {},

  "$.sessions[?(@._id=='$sessionId')].sessionMedia": {
    factory: async (obj, jsonpathParams) => (obj.sessions.find((s: any) => s._id === jsonpathParams.sessionId).sessionType === 'BreakoutSession' ? { sessionMediaType: 'VodSessionMedia' } : {}),
    // condition: (obj, jsonpath, jsonpathParams, parent) => isSessionWithSessionMedia(parent)
  },
  "$.sessions[?(@._id=='$sessionId')].sessionMedia.$language": {
    factory: async () => ({}),
    // condition: (obj, jsonpath, jsonpathParams, parent) => isSessionWithSessionMedia(parent)
  },
  "$.sessions[?(@._id=='$sessionId')].sessionMedia.$language.sessionMediaType": {
    // condition: (obj, jsonpath, jsonpathParams, parent) => isSessionWithSessionMedia(parent),
    type: 'dropdown',
    dropdownOptions: [{ label: 'None', value: null }].concat(sessionMediaTypes.map((t: any) => ({ label: t, value: t }))),
  },
  "$.sessions[?(@._id=='$sessionId')].sessionMedia.$language.stream": {
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return isLiveSessionMedia(parent);
    },
  },
  "$.sessions[?(@._id=='$sessionId')].sessionMedia.$language.asset": {
    type: 'videoasset',
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return isSessionMediaWithAsset(parent) || isBreakoutSession(obj.sessions.find((s: any) => s._id === jsonpathParams.sessionId));
    },
  },

  "$.sessions[?(@._id=='$sessionId')].breakouts": {
    factory: async () => [],
  },
  "$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')].link": {},
  "$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')].asset": {
    type: 'videoasset',
  },
  "$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')].local": {
    factory: async () => ({}),
  },
  "$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')].local.$language": {
    factory: async () => new BreakoutSessionFactory().createBreakoutLocal({}),
  },
  "$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')].local.$language.title": {},
  "$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')].local.$language.shortTitle": {},
  "$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')].local.$language.description": {},
  "$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')].speakers": {
    type: 'autocomplete',
  },
};
