import { TextAttributes } from './../Attributes';
import { BreakpointValue } from './../Breakpoint';
import { CTAButton } from '../CTAButton';
import { PageModule } from './PageModule';

export const stage2TeaserItemTypes = ['Highlight', 'Link', 'CTA'] as const;
export type Stage2TeaserItemType = (typeof stage2TeaserItemTypes)[number];

export interface Stage2TeaserItem {
  _id: string;
  teaserItemType: Stage2TeaserItemType;
}

export interface Stage2HighlightTeaserItem extends Stage2TeaserItem {
  teaserItemType: 'Highlight';
  session: string;
}

export interface Stage2LinkTeaserItem extends Stage2TeaserItem {
  teaserItemType: 'Link';
  image: string;
  title: string;
  link: string;
}

export interface Stage2CTATeaserItem extends Stage2TeaserItem {
  teaserItemType: 'CTA';
  image: string;
  title: string;
  cta: CTAButton;
}

export interface Stage2TeaserEventStagePageModuleLocal {
  smallHeadline: string;
  smallHeadline_attr?: Stage2TeaserEventStagePageModuleAttributes;
  title: string;
  title_attr?: Stage2TeaserEventStagePageModuleAttributes;
  subtitle: string;
  subtitle_attr?: Stage2TeaserEventStagePageModuleAttributes;
  items: Stage2TeaserItem[];
  subtext: string;
  background?: string;
}

export type Stage2TeaserEventStagePageModuleAttributes = TextAttributes & {};

export interface Stage2TeaserEventStagePageModule extends PageModule {
  pageModuleType: 'TeaserEventStage2';
  local: { [language: string]: Stage2TeaserEventStagePageModuleLocal };
  ctalist?: Stage2TeaserEventStageButton[];
  ctalistInteraction?: Stage2TeaserEventStageButton[];
  showCountdown: boolean;
  discoverMore?: boolean;
  ctaAreaHeight?: BreakpointValue<number>;
  contentAreaHeight?: BreakpointValue<number>;
  topAreaHeight?: BreakpointValue<number>;
}
export interface Stage2TeaserEventStageButton {
  _id: string;
  ctaButton: CTAButton;
}

export function isHighlightTeaserItemStage2(object: any): object is Stage2HighlightTeaserItem {
  return object?.teaserItemType === 'Highlight';
}

export function isLinkTeaserItemStage2(object: any): object is Stage2LinkTeaserItem {
  return object?.teaserItemType === 'Link';
}

export function isCTATeaserItemStage2(object: any): object is Stage2CTATeaserItem {
  return object?.teaserItemType === 'CTA';
}

export function isTeaserEventStage2PageModule(object: any): object is Stage2TeaserEventStagePageModule {
  return object?.pageModuleType === 'TeaserEventStage2';
}
