export interface Region {
  _id: string;
  region: string;
  regionName: string;
  language: string;
  timeZone: string;
  active: boolean;
  sort: number;
  icon: string;
}

export function createRegion(): Region {
  return {
    _id: '',
    region: '',
    regionName: '',
    language: '',
    timeZone: '',
    active: true,
    sort: 0,
    icon: '',
  };
}
