import { HeadlineType } from './../../entities/Attributes';
import { ButtonSpacingTopDefaultBreakpointValue, StageHeightDefaultBreakpointValue } from './DefaultBreakponitValue';
import { BreakpointValue } from './../../entities/Breakpoint';
import { LinkTeaserItemLocal, TeaserEventStagePageModuleAttributes } from './../../entities/pagemodules/TeaserEventStagePageModule';
import { createCTAButton } from '../../entities/CTAButton';
import { CTATeaserItem, HighlightTeaserItem, LinkTeaserItem, TeaserEventStagePageModule, TeaserEventStagePageModuleLocal } from '../../entities/PageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class TeaserEventStagePageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async highlightTeaserItem(values: any): Promise<HighlightTeaserItem> {
    return {
      _id: await this.id(values?._id),
      teaserItemType: 'Highlight',
      session: values?.session || null,
    };
  }

  public async linkTeaserItem(values: any): Promise<LinkTeaserItem> {
    return {
      _id: await this.id(values?._id),
      teaserItemType: 'Link',
      //local: values?.local || null,
      image: values?.image || null,
      title: values?.title || null,
      link: values?.link || null,
    };
  }

  public async linkTeaserItemLocal(values: any): Promise<LinkTeaserItemLocal> {
    return {
      image: values?.image || null,
    };
  }

  public async ctaTeaserItem(values: any): Promise<CTATeaserItem> {
    return {
      _id: await this.id(values?._id),
      teaserItemType: 'CTA',
      image: values?.image || null,
      title: values?.title || null,
      cta: createCTAButton(values?.cta),
    };
  }

  public async teaserItem(values: any) {
    switch (values.teaserItemType) {
      case 'Highlight':
        return this.highlightTeaserItem(values);
      case 'Link':
        return this.linkTeaserItem(values);
      case 'CTA':
        return this.ctaTeaserItem(values);
    }
    return [];
  }

  public teaserEventStagePageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): TeaserEventStagePageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async teaserEventStagePageModuleLocal(values?: any): Promise<TeaserEventStagePageModuleLocal> {
    return {
      smallHeadline: values?.smallHeadline || '',
      smallHeadline_attr: this.teaserEventStagePageModuleAttributes(values?.smallHeadline_attr, 'no headline'),
      title: values?.title || '',
      title_attr: this.teaserEventStagePageModuleAttributes(values?.title_attr),
      subtitle: values?.subtitle || '',
      subtitle_attr: this.teaserEventStagePageModuleAttributes(values?.subtitle_attr, 'no headline'),
      items: await Promise.all((values?.items || []).map((i: any) => this.teaserItem(i))),
      subtext: values?.subtext || '',
      background: values?.background || null,
    };
  }

  public async teaserEventStagePageModuleButton(values?: any) {
    return {
      _id: await this.id(values?._id),
      ctaButton: values?.ctaButton || null,
    };
  }

  public teaserEventStagePageModuleStageHeight(defaultValues: BreakpointValue<number>, values?: any) {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge,
    };
  }

  public teaserEventStagePageModuleButtonSpacingTopBreakpoint(defaultValues: BreakpointValue<number>, values?: any) {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge,
    };
  }

  public async teaserEventStagePageModule(values?: any): Promise<TeaserEventStagePageModule> {
    if (values?.background) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.background = item.background || values.background;
      });
    }
    const pageModule = {
      pageModuleType: 'TeaserEventStage',
      ...(await this.baseValues(values)),
      ctaButton: values?.ctaButton || null,
      background: undefined,
      local: await this.local(values || {}, (val) => this.teaserEventStagePageModuleLocal(val)),
      ctalist: values?.ctalist || [],
      ctalistInteraction: values?.ctalistInteraction || [],
      showCountdown: values?.showCountdown || false,
      discoverMore: values?.discoverMore || false,
      stageHeight: values?.stageHeight ? values?.stageHeight : this.teaserEventStagePageModuleStageHeight(StageHeightDefaultBreakpointValue, values?.stageHeight),
      buttonSpacingTopBreakpoint: values?.buttonSpacingTopBreakpoint
        ? values?.buttonSpacingTopBreakpoint
        : this.teaserEventStagePageModuleButtonSpacingTopBreakpoint(ButtonSpacingTopDefaultBreakpointValue, values?.buttonSpacingTopBreakpoint),
    };

    return { ...pageModule, pageModuleType: 'TeaserEventStage' };
  }
}
