import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { titlePositions } from './../../entities/pagemodules/PageModule';
import { ImagePageModuleFactory } from '../../factories/pagemodules/ImagePageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const imagePageModulesInputs: Inputs = {
  ...commonPageModuleInputs('Image'),
  "$.pageModules[?(@.pageModuleType=='Image' && @._id=='$pageModuleId')].ctaButton": {},

  "$.pageModules[?(@.pageModuleType=='Image' && @._id=='$pageModuleId')].local.$language.image": {
    type: 'imageasset',
    header: 'Image',
  },

  "$.pageModules[?(@.pageModuleType=='Image' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new ImagePageModuleFactory().imagePageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='Image' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },

  "$.pageModules[?(@.pageModuleType=='Image' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [...titlePositions].map((item) => ({
      label: item,
      value: item,
    })),
  },
};
