import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutContext } from 'src/app/layout-editor/layout-editor';
import { Event, eventPhase, EventPhase } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';

@Component({
  selector: 'c-event-layout',
  templateUrl: './event-layout.component.html',
  styleUrls: ['./event-layout.component.scss'],
})
export class EventLayoutComponent implements OnInit, OnChanges {
  @Input()
  event: Event;

  @Input()
  language: string;

  @Input()
  eventVersion: EventVersion;

  context: LayoutContext;

  showPages = false;
  pageJsonpath = "$.phase.$eventPhase.pages[?(@._id=='$pageId')]";

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  async ngOnInit() {

    if (this.eventVersion) {
      this.context = {
        event: this.event,
        eventVersion: this.eventVersion,
      };
      // this.context.event = this.event
      // this.context.eventVersion = this.eventVersion

      const loadedContext = this.loadContext();
      const currentPhase = eventPhase(this.event);

      if (this.activatedRoute.snapshot.queryParams.page) {
        for (const phase of Object.keys(this.eventVersion.phase)) {
          const page = this.eventVersion.phase[phase].pages.find((p) => p._id === this.activatedRoute.snapshot.queryParams.page);

          if (page) {
            this.context = {
              ...this.context,
              page: page,
              eventPhase: phase as EventPhase,
            };
            this.saveContext();
            return;
          }
        }
      }

      if (loadedContext?.page && loadedContext.eventPhase === currentPhase) {
        const page = this.eventVersion.phase[loadedContext.eventPhase].pages.find((p) => p._id === loadedContext.page);

        if (page) {
          this.context = {
            ...this.context,
            page: page,
            eventPhase: loadedContext.eventPhase,
          };
          return;
        }
      }

      // find phase with pages
      // search in current phase first
      for (const phase of [currentPhase as string].concat(Object.keys(this.eventVersion?.phase || {}))) {
        const page = this.eventVersion.phase[phase]?.pages.find((p) => !p.path) || this.eventVersion.phase[phase]?.pages[0];

        if (page) {
          this.context = {
            ...this.context,
            page: page,
            eventPhase: phase as EventPhase,
          };
          this.saveContext();
          return;
        }
      }
    }
  }

  async saveContext() {
    sessionStorage.setItem(
      `context:${this.event._id}`,
      JSON.stringify({
        event: this.context?.event?._id,
        eventVersion: this.context?.eventVersion?._id,
        page: this.context?.page?._id,
        eventPhase: this.context?.eventPhase,
      })
    );

  }

  loadContext(): { event: string; eventVersion: string; page: string; eventPhase: string } {
    return JSON.parse(sessionStorage.getItem(`context:${this.event._id}`) || '{}');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eventVersion?.currentValue !== changes.eventVersion?.previousValue) {
      this.context = null;
      setTimeout(() => {
        this.ngOnInit();
      }, 10);
    }
  }


}
