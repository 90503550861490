export const contactMethods = ['Email', 'Form'] as const;
export type ContactMethodType = (typeof contactMethods)[number];

export interface ContactMethod {
  contactMethodType: ContactMethodType;
  enabled: boolean;
}

export interface EmailContactMethod extends ContactMethod {
  contactMethodType: 'Email';
  contactEnabled?: boolean;
}

export interface FormContactMethod extends ContactMethod {
  contactMethodType: 'Form';
  form: string;
  contactEnabled?: boolean;
  local: { [language: string]: FormContactMethodLocal };
}

export interface FormContactMethodLocal {
  label: string;
}

export interface ContactConfiguration {
  _id: string;
  contactMethods: ContactMethod[];
  contactPublic: boolean;
}

export function isFormContactMethod(obj: any): obj is FormContactMethod {
  return obj?.contactMethodType === 'Form';
}

export function isEmailContactMethod(obj: any): obj is EmailContactMethod {
  return obj?.contactMethodType === 'Email';
}
