import { TextAttributes } from './../Attributes';
import { CTAButtonType } from './../CTAButton';
import { PageModule, TitlePositions } from './PageModule';
import { CTAButton } from '../CTAButton';

export const productImageSize = ['default', 'large', 'extra_large'] as const;
export type ProductImageSize = (typeof productImageSize)[number];
export interface ProductListPageModule extends PageModule {
  pageModuleType: 'ProductList';
  showHeadline: boolean;
  local: {
    [language: string]: {
      title: string;
      title_attr?: ProductListPageModuleAttributes;
    };
  };
  titlePosition?: TitlePositions;
  ctaButton?: CTAButton;
  groups?: ProductListPageModuleGroup[];
  productImageSize?: ProductImageSize;
}

export type ProductListPageModuleAttributes = TextAttributes & {};

export interface ProductListPageModuleGroup {
  _id: string;
  local: {
    [language: string]: {
      title?: string;
    };
  };
  series: ProductListPageModuleSeries[];
}

export interface ProductListPageModuleSeries {
  _id: string;
  local: {
    [language: string]: {
      title?: string;
    };
  };
  products: ProductListPageModuleProduct[];
}

export interface ProductListPageModuleProduct {
  _id: string;
  local: {
    [language: string]: {
      title: string;
      image: string;
      linkType: CTAButtonType;
      link?: string;
    };
  };
}

export function isProductListPageModule(object: any): object is ProductListPageModule {
  return object?.pageModuleType === 'ProductList';
}
