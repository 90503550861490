import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguagesService } from 'src/app/services/languages/languages.service';

import { Keytopic } from 'src/common/entities/Keytopic';
import { KeytopicsService } from 'src/app/services/keytopics/keytopics.service';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { Inputs } from '../../../common/inputs/Inputs';
import { SpeakerFactory } from '../../../common/factories/SpeakerFactory';
import { MessageService } from 'primeng/api';
import { UtilsService } from '../../services/utils/utils.service';
import { CollaborationService } from '../../services/collaboration/collaboration.service';
import { ValidatorService } from '../../services/validator/validator.service';
import { keytopicLocalValidator, keytopicValidator } from '../../../common/api/v1/keytopics/validators/KeytopicValidator';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-keytopic',
  templateUrl: './keytopic.component.html',
  styleUrls: ['./keytopic.component.scss'],
})
export class KeytopicComponent implements OnInit, OnDestroy {
  languages: string[];
  currentLanguage = 'en';

  keytopicId: string = 'undefined';
  keytopic: Keytopic | null = null;

  hasEditRights: boolean = false;
  hasCreateRights: boolean = false;

  loading = true;
  saving = false;
  tab: Tab;

  mainInputs: Inputs = {
    '$.local.$language.title': {
      header: 'PAGE_KEYTOPIC_LOCAL_PROPERTIES_TITLE',
      description: 'PAGE_KEYTOPIC_LOCAL_PROPERTIES_TITLE_DESCRIPTION',
    },
    '$.local.$language.shortDescription': {
      header: 'PAGE_KEYTOPIC_LOCAL_PROPERTIES_SHORT_DESCIPTION',
    },
    '$.local.$language.longDescription': {
      header: 'PAGE_KEYTOPIC_LOCAL_PROPERTIES_LONG_DESCIPTION',
    },
  };

  mainJsonPathes = Object.keys(this.mainInputs);

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private languagesService: LanguagesService,
    private keytopicsService: KeytopicsService,
    private messageService: MessageService,
    private tabsService: TabsService,
    private utilsService: UtilsService,
    private collaborationService: CollaborationService,
    private validatorService: ValidatorService
  ) {
    this.tab = this.tabsService.register({
      category: 'data',
      parent: '/keytopics',
      loading: true,
      route: this.activatedRoute.snapshot,
    });
  }

  async ngOnInit(): Promise<void> {
    this.languages = (await this.languagesService.getLanguages())?.items.filter((i) => i.selectable).map((i) => i.language);
    this.keytopicId = this.activatedRoute.snapshot.params.keytopicId;
    this.hasEditRights = await this.authService.hasRight('keytopics:' + this.keytopicId + '$' + 'keytopics.edit');
    this.hasCreateRights = await this.authService.hasRight('keytopics.create');
    if (this.keytopicId === 'new') {
      this.keytopic = {
        languages: [this.currentLanguage],
        internalName: '',
        icon: null,
        header: null,
        local: {
          [this.currentLanguage]: {
            title: '',
            shortDescription: '',
            longDescription: '',
          },
        },
      };

      this.tab.title = 'PAGE_KEYTOPIC_NEW';
      if (this.hasEditRights) {
        this.collaborationService.registerLocal(`keytopic:${this.keytopicId}`, this.keytopic);
      }
      this.tab.loading = false;
    } else if (this.keytopicId) {
      await this.retrieveData();
    }
  }

  ngOnDestroy(): void {
    this.collaborationService.unregisterLocal(`keytopic:${this.keytopicId}`);
  }

  async languagesChange(languages: string[]): Promise<void> {
    this.languages = languages;

    for (const language of languages) {
      if (!this.keytopic.local[language]) {
        this.keytopic.local[language] = await new SpeakerFactory().createSpeakerLocal({});
      }
    }
  }

  changeLanguage(language: string): void {
    if (!this.keytopic.local[language]) {
      this.keytopic.languages = [...this.keytopic.languages, language];
      this.keytopic.local[language] = {
        title: '',
        shortDescription: '',
        longDescription: '',
      };
    }
  }

  async retrieveData(): Promise<void> {
    this.tab.loading = true;
    try {
      this.keytopic = await this.keytopicsService.getKeytopic(this.keytopicId);
      const translations = Object.keys(this.keytopic.local);
      if (this.keytopic && translations.length > 0) {
        this.keytopic.languages = translations;

        if (!this.currentLanguage) {
          this.currentLanguage = translations[0];
        }
      }
      this.tab.title = this.keytopic.internalName;
    } catch (err) {}
    this.collaborationService.registerLocal(`keytopic:${this.keytopicId}`, this.keytopic);
    this.tab.loading = false;
  }

  async save(): Promise<void> {
    this.tab.loading = true;
    try {
      if (this.keytopic) {
        if (this.keytopicId === 'new') {
          this.keytopic = await this.keytopicsService.createKeytopic(this.keytopic);
          await this.router.navigate(['/keytopics', this.keytopic._id], {
            replaceUrl: true,
          });
          if (this.keytopic && this.keytopic._id) this.keytopicId = this.keytopic._id;

          this.collaborationService.registerLocal(`keytopic:${this.keytopicId}`, this.keytopic);
        } else {
          this.keytopic = await this.keytopicsService.updateKeytopic(this.keytopic);
          this.collaborationService.registerLocal(`keytopic:${this.keytopicId}`, this.keytopic, true);
        }
        this.keytopic.languages = Object.keys(this.keytopic.local);
      }
    } catch (err) {
      const summary = typeof err.error.message === 'string' ? err.error.message : 'Something went wrong';
      this.messageService.add({ severity: 'error', summary });
    }
    this.tab.loading = false;
  }

  async delete(): Promise<void> {
    this.tab.loading = true;
    this.collaborationService.disable(`keytopic:${this.keytopicId}`);
    try {
      if (this.keytopic) {
        if (this.keytopicId !== 'new') {
          this.keytopic = await this.keytopicsService.deleteKeytopic(this.keytopic);
          await this.router.navigate(['/keytopics'], {
            replaceUrl: true,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
    this.tab.loading = false;
    this.collaborationService.enable(`keytopic:${this.keytopicId}`);
  }

  isValid(): boolean {
    return this.validatorService.isValid(keytopicValidator, this.keytopic) && this.invalidLanguages().length === 0;
  }

  invalidLanguages(): string[] {
    return this.keytopic ? Object.keys(this.keytopic.local).filter((l) => !this.localIsValid(l)) : [];
  }

  localIsValid(language: string): boolean {
    return this.validatorService.isValid(keytopicLocalValidator, this.keytopic?.local[language]);
  }
}
