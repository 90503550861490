<p-card>
  <ng-template pTemplate="header">
    <div class="card-header">
      Previews
      <div class="card-header-right">
        <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="addPreview()">Add Preview</a>
      </div>
    </div>
  </ng-template>
  <table class="table">
    <thead>
      <tr>
        <th>Start Time</th>
        <th>Current Time</th>
        <th>Event Phase</th>
        <th>Created At</th>
        <th>Created By</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let preview of previews">
        <td>{{ preview.startDate }}</td>
        <td>{{ currentDate(preview) }}</td>
        <td>{{ currentEventPhase(preview) }}</td>
        <td>{{ preview.createdAt }}</td>
        <td>{{ (preview.createdBy | adminUser | async)?.displayName }}</td>
        <td>
          <a target="_blank" [href]="url(preview)">Preview</a>
        </td>
      </tr>
    </tbody>
  </table>
</p-card>

<p-dialog [visible]="newPreview">
  <ng-template pTemplate="header"> Add Preview </ng-template>

  <div *ngIf="newPreview">
    <p-calendar name="regionEndDate" [inline]="true" [(ngModel)]="newPreview.startDate" [showTime]="true" hourFormat="24"></p-calendar>
  </div>

  <ng-template pTemplate="footer">
    <button class="p-button" (click)="createPreview()">Create</button>
  </ng-template>
</p-dialog>
