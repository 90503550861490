<p-sidebar
  *ngIf="_mode === 'self'"
  #NavSideBar
  (onHide)="NavSideBar.destroyModal()"
  [visible]="_selfSidebarVisible"
  [appendTo]="'body'"
  styleClass="p-sidebar-st"
  [position]="position"
  [modal]="true"
  [showCloseIcon]="false"
  (onHide)="selfSidebarHidden()"
>
  <c-sidebar-header [headerTitle]="header" [headerSubTitle]="subheader" (onClose)="selfSidebarHidden()"></c-sidebar-header>
  <div class="sidebar-tabs" *ngIf="tabs">
    <button *ngFor="let tab of tabs; trackBy: trackByIndex" [class.active]="activeTab === tab.key" (click)="setActiveTab(tab.key)">{{ tab.label | translate }}</button>
  </div>
  <c-sidebar-body [noScroll]="noScroll" #sidebarBody [position]="position">
    <div class="sidebar-body">
      <div #sidebarBodyContainer></div>
    </div>
  </c-sidebar-body>
</p-sidebar>

<div #container [class.hide]="_hideContainer">
  <ng-content></ng-content>
</div>
