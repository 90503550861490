import { Environment } from './../../../environments/environment';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { PollsService } from 'src/app/services/polls/polls.service';
import { PollAnswers } from 'src/common/api/v1/polls/GetPollAnswers';
import { Poll, PollOption } from 'src/common/entities/Poll';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'c-poll-viewer',
  templateUrl: './poll-viewer.component.html',
  styleUrls: ['./poll-viewer.component.scss'],
})
export class PollViewerComponent implements OnInit {
  @Input()
  pollId: string;

  @Input()
  data: {
    poll: Poll;
    answers?: PollAnswers[];
    users?: number;
  };

  @Input()
  @HostBinding('class.bcp')
  bcp: boolean = true;

  @HostBinding('style.--image-var')
  imageBackground;

  @HostBinding('style.--font-var')
  font;

  @HostBinding('style.--font-color-var')
  fontColor;

  @HostBinding('style.--bar-leading-color-var')
  barfontleadingColor;

  @HostBinding('style.--bar-leading-background-var')
  barbackgroundleadingColor;

  @HostBinding('style.--bar-background-var')
  barbackgroundColor;

  @HostBinding('style.--bar-color-var')
  barColor;

  currentLanguage: string;

  poll: Poll = null;
  pollResults: {
    option: PollOption;
    count: number;
    percentage: number;
    width: string;
  }[];

  totalCount = 0;
  maxCount = 0;
  users = 0;
  valueWidth: string;
  loading = true;

  constructor(private environment: Environment, private sanitizer: DomSanitizer, private pollsService: PollsService) {}

  async ngOnInit() {
    this.loading = true;

    if (this.data) {
      this.poll = this.data.poll;
    } else {
      this.poll = await this.pollsService.getPoll(this.pollId);
    }

    if (this.poll.theme) {
      if (this.poll.theme?.background?.paths?.large) {
        this.imageBackground = this.sanitizer.bypassSecurityTrustStyle(`url(${this.environment.cdn.images}/${this.poll.theme?.background.paths.large})`);
      }

      this.font = this.poll.theme.font;
      this.fontColor = this.poll.theme.fontcolor;
      this.barfontleadingColor = this.poll.theme.leadingBarFontColor;
      this.barbackgroundleadingColor = this.poll.theme.leadingBarBackgroundColor;
      this.barbackgroundColor = this.poll.theme.barBackgroundColor;
      this.barColor = this.poll.theme.barFontColor;
    }

    this.currentLanguage = Object.keys(this.poll.local)[0];

    await this.update();

    // if (!this.poll.closedAt) {
    // }
    this.refresh();

    this.loading = false;
  }

  async update() {
    let answers: PollAnswers[];
    let open: boolean;

    if (this.data) {
      this.users = this.data.users || 0;
      answers = this.data.answers || [];
      open = !this.data.poll.closedAt;
    } else {
      const result = await this.pollsService.answers(this.pollId);
      this.users = result.users;
      answers = result.answers;
      open = result.open;
    }

    for (const option of this.poll.options) {
      if (!answers.find((a) => a.option === option._id)) {
        answers.push({
          option: option._id,
          count: 0,
        });
      }
    }

    this.totalCount = answers.reduce((a, b) => a + b.count, 0);
    this.maxCount = answers.reduce((a, b) => Math.max(a, b.count), 0);

    this.pollResults = answers
      .sort((a, b) => (a.count > b.count ? -1 : 1))
      .map((a) => ({
        option: this.poll.options.find((o) => o._id === a.option),
        count: a.count,
        percentage: a.count && a.count > 0 ? Math.round((a.count / this.users) * 100) : 0,
        width: (a.count && a.count > 0 ? (a.count / this.users) * 100 : 0) + '%',
      }));

    this.valueWidth = Math.max((this.maxCount + '').length, 5) * 1 + 'rem';
    return open;
  }

  refresh() {
    setTimeout(async () => {
      const open = await this.update();
      this.refresh();
      // if (open) {
      // }
    }, 5000);
  }

  trackByOption(index: number, option: { option: string }) {
    return option.option;
  }
}
