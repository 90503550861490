export const relatedContentTypes = ['AssetRelatedContent', 'LinkRelatedContent'] as const;
export type RelatedContentType = (typeof relatedContentTypes)[number];

export interface RelatedContent {
  relatedContentType: RelatedContentType;
}

export interface AssetRelatedContent extends RelatedContent {
  asset: string;
}

export interface LinkRelatedContent extends RelatedContent {
  title: string;
  link: string;
  target: '_blank';
}

export function isAssetRelatedContent(object: any): object is AssetRelatedContent {
  return object.relatedContentType === 'AssetRelatedContent';
}

export function isLinkRelatedContent(object: any): object is LinkRelatedContent {
  return object.relatedContentType === 'LinkRelatedContent';
}
