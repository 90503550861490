import { Pipe, PipeTransform } from '@angular/core';
import * as jp from 'jsonpath';

export enum GroupingMode {
  DAY = 'DAY',
  TIME = 'TIME',
}

@Pipe({
  name: 'byDate',
})
export class ByDatePipe implements PipeTransform {
  transform<T>(values: T[], dateField = '$.date', groupingMode = GroupingMode.TIME): { [date: string]: T[] } {
    const dateStringLength = groupingMode === GroupingMode.DAY ? 10 : 19;
    const result: { [date: string]: T[] } = {};

    const sortedValues = values.sort((a, b) => {
      const dateA = jp.value(a, dateField);
      const dateB = jp.value(b, dateField);

      if (!dateA && !dateB) return 0;
      if (!dateA) return -1;
      if (!dateB) return 1;

      return new Date(dateA).getTime() - new Date(dateB).getTime();
    });

    for (const value of sortedValues) {
      const date = jp.value(value, dateField);
      const dateString = date ? new Date(date).toISOString().slice(0, dateStringLength) : '';

      if (!result[dateString]) {
        result[dateString] = [];
      }

      result[dateString].push(value);
    }

    return result;
  }
}
