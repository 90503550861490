<div>
  <div class="phase" [class.active]="phaseActive('DRAFT')" [class.completed]="phaseCompleted('DRAFT')">
    <div class="phase-index">0</div>
    <div class="phase-details">
      <div class="phase-name">
        Draft
        <div class="phase-active">Active</div>
        <div class="phase-completed">Completed</div>
      </div>
      <div class="phase-status">Status: completed</div>
      <div class="phase-description">The event is not public available in the draft phase. You should set up everything in this phase, before the event will be visible to the customer.</div>
    </div>
    <div class="phase-action">
      <button *hasRight="'events.edit'" pButton [label]="'Set Offstage'" class="p-button-outlined event-phase-button" (click)="confirmSetOffstage()"></button>
    </div>
  </div>

  <div class="phase" [class.active]="phaseActive('ANNOUNCED')" [class.completed]="phaseCompleted('ANNOUNCED')">
    <div class="phase-index">1</div>
    <div class="phase-details">
      <div class="phase-name">
        Announced
        <div class="phase-active">Active</div>
        <div class="phase-completed">Completed</div>
      </div>
      <div class="phase-status">
        <span *ngIf="event.announcedAt">Status: Announced date set for {{ event.announcedAt | timeZoneByUrl | async | date : 'short' }}</span>
        <span *ngIf="!event.announcedAt" class="warning">Status: No date set</span>
      </div>
      <div class="phase-description">
        The event is now public available. There should be a first landing page with initial information of the event for the customer. If the ticket configuration is already finished, you can enable
        the registration process for the event in this phase. The visitor of the event sees now the pages that are configured at
        <span class="layout-path">Layout &raquo; Pages &raquo; Phase Announced</span>.
      </div>
    </div>
    <div class="phase-action">
      <button *hasRight="'events.edit'" pButton [label]="'Plan Announcement'" class="event-phase-button" (click)="openDialogSetAnnouncedAt()"></button>
    </div>
  </div>

  <div class="phase" [class.active]="phaseActive('RELEASED')" [class.completed]="phaseCompleted('RELEASED')">
    <div class="phase-index">2</div>
    <div class="phase-details">
      <div class="phase-name">
        Released
        <div class="phase-active">Active</div>
        <div class="phase-completed">Completed</div>
      </div>
      <div class="phase-status">
        <span *ngIf="event.releasedAt">Status: {{ event.releasedAt | timeZoneByUrl | async | date : 'short' }}</span>
        <span *ngIf="!event.releasedAt" class="warning">Status: No date set</span>
      </div>
      <div class="phase-description">
        In the release phase, there should be more detailed information of the event like an agenda and location details for on-side events. If a registration for the event is required, you should
        enable the user to do it in this phase at the latest. The visitor of the event sees now the pages that are configured at
        <span class="layout-path">Layout &raquo; Pages &raquo; Phase Released</span>.
      </div>
    </div>
    <div class="phase-action">
      <button *hasRight="'events.edit'" pButton [label]="'Plan Release'" class="event-phase-button" (click)="openDialogSetReleasedAt()"></button>
    </div>
  </div>

  <div class="phase" [class.active]="phaseActive('LIVE')" [class.completed]="phaseCompleted('LIVE')">
    <div class="phase-index">3</div>
    <div class="phase-details">
      <div class="phase-name">
        Live
        <div class="phase-active">Active</div>
        <div class="phase-completed">Completed</div>
      </div>
      <div class="phase-status">
        <span *ngIf="event.totalStartAt">Status: The event will be live at {{ event.totalStartAt | timeZoneByUrl | async | date : 'short' }}</span>
        <span *ngIf="!event.totalStartAt" class="warning">Status: No date set</span>
      </div>
      <div class="phase-description">
        In the announced phase, there should be more detailed information of the event for the customer. The visitor of the event sees now the pages that are configured at
        <span class="layout-path">Layout &raquo; Pages &raquo; Phase Live</span>.
      </div>
    </div>
  </div>

  <div class="phase" [class.active]="phaseActive('ARCHIVE')" [class.completed]="phaseCompleted('ARCHIVE')">
    <div class="phase-index">4</div>
    <div class="phase-details">
      <div class="phase-name">
        Archive
        <div class="phase-active">Active</div>
        <div class="phase-completed">Completed</div>
      </div>
      <div class="phase-status">
        <span *ngIf="event.totalEndAt">Status: The event will be archived at {{ event.totalEndAt | timeZoneByUrl | async | date : 'short' }}</span>
        <span *ngIf="!event.totalEndAt" class="warning">Status: No date set</span>
      </div>
      <div class="phase-description">
        The event will be in the archive phase when the event is over. You should have on-demand content prepared for this stage. The visitor of the event sees now the pages that are configured at
        <span class="layout-path">Layout &raquo; Pages &raquo; Phase Archive</span>.
      </div>
    </div>
  </div>

  <p-confirmDialog></p-confirmDialog>
</div>

<!-- Dialog Set Announcement -->
<p-dialog [header]="'PAGE_EVENT_DIALOG_PLAN_ANNOUNCEMENT_TITLE' | translate" [(visible)]="dialogPlanAnnouncmentVisible" [modal]="true">
  <div>
    <c-basic-patch-input jsonpath="$.announcedAt" [object]="edit" [inputs]="inputs"></c-basic-patch-input>
  </div>
  <p-footer>
    <p-button
      *hasRight="'events.edit'"
      [label]="'PAGE_EVENT_DIALOG_PLAN_ANNOUNCEMENT_SET_ANNOUNCED' | translate"
      icon="pi pi-check"
      styleClass="p-button-sm"
      (onClick)="announce()"
      [disabled]="!edit.announcedAt"
    ></p-button>
  </p-footer>
</p-dialog>

<!-- Dialog Set Released -->
<p-dialog [header]="'PAGE_EVENT_DIALOG_PLAN_RELEASE_TITLE' | translate" [(visible)]="dialogPlanReleaseVisible" [modal]="true">
  <div>
    <c-basic-patch-input jsonpath="$.releasedAt" [object]="edit" [inputs]="inputs"></c-basic-patch-input>
  </div>
  <p-footer>
    <p-button
      *hasRight="'events.edit'"
      [label]="'PAGE_EVENT_DIALOG_PLAN_RELEASE_SET_RELEASED' | translate"
      icon="pi pi-check"
      styleClass="p-button-sm"
      (onClick)="release()"
      [disabled]="!edit.releasedAt"
    ></p-button>
  </p-footer>
</p-dialog>
