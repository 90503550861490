import { TextImagePageModuleAttributes } from './../../entities/pagemodules/TextImagePageModule';
import { HeadlineType } from './../../entities/Attributes';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';
import { TextImagePageModule, TextImagePageModuleLocal } from '../../entities/PageModule';

export class TextImagePageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async textImagePageModuleLocal(values?: any): Promise<TextImagePageModuleLocal> {
    return {
      title: values?.title || '',
      title_attr: this.textImagePageModuleAttributes(values?.title_attr),
      text: values?.text || '',
      image: values?.image || '',
    };
  }

  public textImagePageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): TextImagePageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async textImagePageModule(values: any): Promise<TextImagePageModule> {
    if (values?.image) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.image = item.image || values.image;
      });
    }

    const pageModule = {
      ...(await this.baseValues(values)),
      pageModuleType: 'TextImage',
      titlePosition: values?.titlePosition || 'content',
      local: await this.local(values, (val) => this.textImagePageModuleLocal(val)),
      image: undefined,
      imageSize: values?.imageSize || 'large',
      imagePosition: values?.imagePosition || 'left',
      imagePositionWithinImageArea: values?.imagePositionWithinImageArea || 'left',
      ctaButton: values?.ctaButton || null,
      imagePositionMobile: values?.imagePositionMobile || 'bottom',
    };

    return { ...pageModule, pageModuleType: 'TextImage' };
  }
}
