import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PagesService } from 'src/app/services/pages/pages.service';
import {
  AppFunctionMenuItem,
  DialogMenuItem,
  ExploreMenuItem,
  isAppFunctionMenuItem,
  isDialogMenuItem,
  isExploreMenuItem,
  isLinkMenuItem,
  isPageMenuItem,
  LinkMenuItem,
  MenuItem,
  menuItemTypes,
  PageMenuItem,
} from 'src/common/entities/MenuItem';
import { Factory } from 'src/common/factories/Factory';
import { Observable } from 'rxjs';
import { Asset } from '../../../common/entities/Asset';
import { AssetsService } from '../../services/assets/assets.service';

@Component({
  selector: 'c-menu-items-edit',
  templateUrl: './menu-items-edit.component.html',
  styleUrls: ['./menu-items-edit.component.scss'],
})
export class MenuItemsEditComponent implements OnInit, OnChanges {
  @Input()
  domainCollectionId: string = null;

  @Input()
  menuItems: MenuItem[];

  @Input()
  menuStyle: 'header' | 'footer' = 'header';

  @Input()
  language: string;

  loading = true;
  selectedMenuItem: MenuItem;
  menuItemTypeOptions: { label: string; value: string }[] = [];

  constructor(private pagesService: PagesService, private assetsService: AssetsService) {}

  async ngOnInit() {
    try {
      this.menuItemTypeOptions = [...menuItemTypes].map((m) => ({ label: m, value: m }));
    } catch (err) {
      console.error(err);
    }

    this.loading = false;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.language && changes.language.currentValue !== changes.language.previousValue) {
      for (const menuItem of this.menuItems) {
        if (!menuItem.local[this.language]) menuItem.local[this.language] = await Factory.menuItem().menuItemLocal({});
      }
    }
  }

  asLinkMenuItem = (menuItem: MenuItem): LinkMenuItem => {
    return isLinkMenuItem(menuItem) ? menuItem : null;
  };

  asPageMenuItem = (menuItem: MenuItem): PageMenuItem => {
    return isPageMenuItem(menuItem) ? menuItem : null;
  };

  asDialogMenuItem = (menuItem: MenuItem): DialogMenuItem => {
    return isDialogMenuItem(menuItem) ? menuItem : null;
  };

  asAppFunctionMenuItem = (menuItem: MenuItem): AppFunctionMenuItem => {
    return isAppFunctionMenuItem(menuItem) ? menuItem : null;
  };

  asExploreMenuItem = (menuItem: MenuItem): ExploreMenuItem => {
    return isExploreMenuItem(menuItem) ? menuItem : null;
  };

  async addMenuItem() {
    const menuItem = await Factory.menuItem().menuItem({ menuItemType: 'Page' });
    this.menuItems.push(menuItem);
    this.selectMenuItem(menuItem);
  }

  removeMenuItem(index: number) {
    this.menuItems.splice(index, 1);
  }

  moveMenuItemLeft(index: number) {
    if (index > 0) {
      const menuItem = this.menuItems[index];
      this.menuItems.splice(index, 1);
      this.menuItems.splice(index - 1, 0, menuItem);
    }
  }

  moveMenuItemRight(index: number) {
    if (index < this.menuItems.length - 1) {
      const menuItem = this.menuItems[index];
      this.menuItems.splice(index, 1);
      this.menuItems.splice(index + 1, 0, menuItem);
    }
  }

  async selectMenuItem(menuItem: MenuItem) {
    if (!menuItem.local[this.language]) menuItem.local[this.language] = await Factory.menuItem().menuItemLocal({});
    this.selectedMenuItem = menuItem;
  }

  streamAsset(icon: string): Observable<Asset> {
    return this.assetsService.getAsset(icon);
  }
}
