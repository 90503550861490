import { UserProfileStagePageModuleLocal } from './../../entities/pagemodules/UserProfileStagePageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';
import { UserProfileStagePageModule } from '../../entities/PageModule';

export class UserProfileStagePageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async userProfileStagePageModuleLocal(values?: any): Promise<UserProfileStagePageModuleLocal> {
    return {
      background: values?.background || null,
    };
  }

  public async userProfileStagePageModule(values: any): Promise<UserProfileStagePageModule> {
    if (values?.background) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.background = item.background || values.background;
      });
    }

    const pageModule = {
      ...(await this.baseValues(values)),
      pageModuleType: 'UserProfileStage',
      background: undefined,
      local: await this.local(values, (val) => this.userProfileStagePageModuleLocal(val)),
      userProfileType: values?.userProfileType || 'CurrentUser',
    };
    return { ...pageModule, pageModuleType: 'UserProfileStage' };
  }
}
