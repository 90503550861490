import { ConversionStagePageModuleFactory } from '../../factories/pagemodules/ConversionStagePageModuleFactory';
import { textAttributesInputs } from '../attributes/TextAttributesInputs';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

const CONVERSION_STAGE_MODULE_LOCAL_TITLE = {
  type: 'text',
  attributes: {
    inputs: textAttributesInputs,
    label: (obj: any) => {
      return obj;
    }
  }
} as const;

export const conversionStagePageModuleInputs: Inputs = {
  ...commonPageModuleInputs('ConversionStage'),
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].stageHeight": {
    factory: async () => new ConversionStagePageModuleFactory().conversionStagePageModuleStageHeight({})
  },
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].enablePageSelector": {
    type: 'switch'
  },
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].selectorPages": {
    factory: async () => []
  },
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new ConversionStagePageModuleFactory().conversionStagePageModuleLocal({})
  },
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].local.$language.subtext": {},
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].local.$language.devices": {
    factory: async () => new ConversionStagePageModuleFactory().getDevicesInfo({})
  },
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].local.$language.devices.mobile.subtext": { header: 'Mobile Subtext', type: 'textarea' },
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].local.$language.devices.tablet.subtext": { header: 'Tablet Subtext', type: 'textarea' },
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].local.$language.devices.desktop.subtext": { header: 'Desktop Subtext', type: 'textarea' },
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].local.$language.background": {},
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].local.$language.pageSelectorPrefix": {},
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].local.$language.title": CONVERSION_STAGE_MODULE_LOCAL_TITLE,
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].local.$language.devices.mobile.title": { ...CONVERSION_STAGE_MODULE_LOCAL_TITLE, header: 'Mobile Title' },
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].local.$language.devices.tablet.title": { ...CONVERSION_STAGE_MODULE_LOCAL_TITLE, header: 'Tablet Title' },
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].local.$language.devices.desktop.title": { ...CONVERSION_STAGE_MODULE_LOCAL_TITLE, header: 'Desktop Title' },
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].local.$language.items": {
    factory: async () => []
  },
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].local.$language.items[?(@.teaserItemType=='Link' && ?(@._id=='$itemId')]": {
    factory: async () => new ConversionStagePageModuleFactory().linkTeaserItem({})
  },
  "$.pageModules[?(@.pageModuleType=='ConversionStage' && @._id=='$pageModuleId')].local.$language.items[?(@.teaserItemType=='CTA' && ?(@._id=='$itemId')]": {
    factory: async () => new ConversionStagePageModuleFactory().ctaTeaserItem({})
  }
};
