<div *ngIf="assetSerie" class="asset-item">
  <div class="asset-preview">
    <c-asset-preview [asset]="thumbnailAsset"></c-asset-preview>
  </div>
  <div class="asset-info">
    <h1>{{ assetSerie.internalName }}</h1>
    <div class="details">
      <div>Items: {{ assetSerie.assets.length }}</div>
      <div>System: {{ assetSerie.source.system }}</div>
    </div>
  </div>
</div>
