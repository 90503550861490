import { Injectable } from '@angular/core';
import { GetExportDownloadQuery, GetExportDownloadResponse } from 'src/common/api/v1/exports/GetExportDownload';
import { GetExportsQuery, GetExportsResponse } from 'src/common/api/v1/exports/GetExports';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ExportsService {
  constructor(private apiService: ApiService) {}

  async getExports(query?: GetExportsQuery): Promise<GetExportsResponse> {
    return await this.apiService
      .get<GetExportsQuery, GetExportsResponse>('/api/v1/exports', {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();
  }

  async downloadUrl(exportId: string, resultId: string) {
    const result = await this.apiService.get<GetExportDownloadQuery, GetExportDownloadResponse>(`/api/v1/exports/${exportId}/download/${resultId}`).toPromise();
    return result.url;
  }
}
