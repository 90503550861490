<div>
  <div class="tab-header">
    <h1>{{ 'PAGE_ASSET_CONFIGURATION' | translate }}</h1>
  </div>
  <div class="tab-menu">
    <p-button *hasRight="'configuration.edit'" icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" [disabled]="tab.loading" (onClick)="save()"></p-button>
    <c-domain-button *hasRight="'configuration.edit'" [configKey]="'asset'" [domainCollectionId]="domainCollectionId" (onDomainCollectionIdChange)="onDomainChange($event)"></c-domain-button>
    <c-domain-button
      *hasNoRight="'configuration.edit'"
      [enableUnification]="false"
      [configKey]="'asset'"
      [domainCollectionId]="domainCollectionId"
      (onDomainCollectionIdChange)="onDomainChange($event)"
    ></c-domain-button>
  </div>

  <div class="panel" *skeleton="{ show: tab.loading }">
    <div class="panel-header">
      {{ 'GENERAL_PROPERTIES' | translate }}
    </div>
    <div *ngIf="assetConfiguration" class="panel-body">
      <div class="input-group">
        <div class="input">
          <input pInputText type="number" [(ngModel)]="assetConfiguration.statusNewDurationInDays" [disabled]="tab.loading" />
        </div>
        <div class="title">{{ 'PAGE_ASSET_CONFIGURATION_STATUS_NEW_DURATION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <p-dropdown [options]="videoPlayerTypes" [(ngModel)]="assetConfiguration.videoPlayerType" [appendTo]="'body'"></p-dropdown>
        </div>
        <div class="title">{{ 'PAGE_ASSET_CONFIGURATION_VIDEO_PLAYER_TYPE' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <p-dropdown [options]="videoTargetTypes" [(ngModel)]="assetConfiguration.videoTarget" [appendTo]="'body'"></p-dropdown>
        </div>
        <div class="title">{{ 'PAGE_ASSET_CONFIGURATION_VIDEO_TARGET_TYPE' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_ASSET_CONFIGURATION_VIDEO_ASSET_PAGE_PROPERTIES' | translate }}
    </div>
    <div *ngIf="assetConfiguration" class="panel-body">
      <div class="input-group">
        <div class="input">
          <p-dropdown [options]="assetPages" [(ngModel)]="assetConfiguration.videoAssetPage" [appendTo]="'body'"></p-dropdown>
        </div>
        <div class="title">{{ 'PAGE_ASSET_CONFIGURATION_VIDEO_TARGET_PAGE' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_ASSET_CONFIGURATION_VIDEO_OVERLAY_PROPERTIES' | translate }}
    </div>
    <div *ngIf="assetConfiguration" class="panel-body">
      <div class="input-group switch">
        <div class="input">
          <p-checkbox [disabled]="tab.loading" [(ngModel)]="assetConfiguration.showVideoTitle" [binary]="true"></p-checkbox>
        </div>
        <div class="title">{{ 'PAGE_ASSET_CONFIGURATION_SHOW_VIDEO_TITLE' | translate }}</div>
      </div>
      <div class="input-group switch">
        <div class="input">
          <p-checkbox [disabled]="tab.loading" [(ngModel)]="assetConfiguration.showVideoDescription" [binary]="true"></p-checkbox>
        </div>
        <div class="title">{{ 'PAGE_ASSET_CONFIGURATION_SHOW_VIDEO_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group switch">
        <div class="input">
          <p-checkbox [disabled]="tab.loading" [(ngModel)]="assetConfiguration.showLanguageTags" [binary]="true"></p-checkbox>
        </div>
        <div class="title">{{ 'PAGE_ASSET_CONFIGURATION_SHOW_LANGUAGE_TAGS' | translate }}</div>
      </div>
      <div class="input-group switch">
        <div class="input">
          <p-checkbox [disabled]="tab.loading" [(ngModel)]="assetConfiguration.showKeytopicTags" [binary]="true"></p-checkbox>
        </div>
        <div class="title">{{ 'PAGE_ASSET_CONFIGURATION_SHOW_KEYTOPIC_TAGS' | translate }}</div>
      </div>
      <div class="input-group switch">
        <div class="input">
          <p-checkbox [disabled]="tab.loading" [(ngModel)]="assetConfiguration.showEventTypeTags" [binary]="true"></p-checkbox>
        </div>
        <div class="title">{{ 'PAGE_ASSET_CONFIGURATION_SHOW_EVENT_TYPE_TAGS' | translate }}</div>
      </div>
      <div class="input-group switch">
        <div class="input">
          <p-checkbox [disabled]="tab.loading" [(ngModel)]="assetConfiguration.showEventTitle" [binary]="true"></p-checkbox>
        </div>
        <div class="title">{{ 'PAGE_ASSET_CONFIGURATION_SHOW_EVENT_TITLE' | translate }}</div>
      </div>
      <div class="input-group switch">
        <div class="input">
          <p-checkbox [disabled]="tab.loading" [(ngModel)]="assetConfiguration.showDate" [binary]="true"></p-checkbox>
        </div>
        <div class="title">{{ 'PAGE_ASSET_CONFIGURATION_SHOW_DATE' | translate }}</div>
      </div>
    </div>
  </div>
</div>
