import { TextAttributes } from './../Attributes';
import { BreakpointValue } from './../Breakpoint';
import { CTAButton } from '../CTAButton';
import { PageModule } from './PageModule';

export interface Stage2StreamEventStagePageModuleLocal {
  smallHeadline: string;
  smallHeadline_attr?: Stage2StreamEventStagePageModuleAttributes;
  title: string;
  title_attr?: Stage2StreamEventStagePageModuleAttributes;
  subtext: string;
  background: string;
}

export type Stage2StreamEventStagePageModuleAttributes = TextAttributes & {};

export interface Stage2StreamEventStagePageModule extends PageModule {
  pageModuleType: 'StreamEventStage2';
  local: { [language: string]: Stage2StreamEventStagePageModuleLocal };
  ctalist?: Stage2StreamEventStageButton[];
  ctalistInteraction?: Stage2StreamEventStageButton[];
  discoverMore?: boolean;
  ctaAreaHeight?: BreakpointValue<number>;
  contentAreaHeight?: BreakpointValue<number>;
  topAreaHeight?: BreakpointValue<number>;
}
export interface Stage2StreamEventStageButton {
  _id: string;
  ctaButton: CTAButton;
  background: string;
}

export function isStreamEventStage2PageModule(object: any): object is Stage2StreamEventStagePageModule {
  return object?.pageModuleType === 'StreamEventStage2';
}
