import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Params, QueryParamsHandling, Router, RouteReuseStrategy, RouterEvent } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ITabConfiguration, Tab } from '../classes/tab';
import { TabsReuseStrategy } from '../classes/tabs-reuse-strategy';

@Injectable({
  providedIn: 'root',
})
export class TabsService {
  // _currentCategory: string | null = null;
  // _currentTab: Tab | null = null;
  _tabs: { [path: string]: Tab } = {};
  _stack: string[] = [];
  _activeUrl: string;
  _tabsSubject: BehaviorSubject<Tab[]> = new BehaviorSubject<Tab[]>([]);
  _categorySubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  _currentTabSubject: BehaviorSubject<Tab> = new BehaviorSubject<Tab>(null);

  constructor(private routeReuse: RouteReuseStrategy, private router: Router) {
    // const tabConfigurations = JSON.parse(localStorage.getItem('tabs') || '[]') as ITabConfiguration[];

    // for (const tabConfiguration of tabConfigurations) {
    //   this.register(tabConfiguration)
    // }

    router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        this._stack.push(e.urlAfterRedirects);
        this._activeUrl = e.urlAfterRedirects;
        this._stack = this._stack.filter((s) => this._tabsSubject.getValue().findIndex((t) => t.resolvedUrl === s || s.indexOf(`${t.resolvedUrl}/`) === 0) >= 0);

        this.checkViewState();
      }
    });
  }

  public tabs() {
    return this._tabsSubject.asObservable();
  }

  public category() {
    return this._categorySubject.asObservable();
  }

  public async openCategory(category: string) {
    this._categorySubject.next(category);

    const categoryTabsFromStack = this._stack.filter(
      (s) => this._tabsSubject.getValue().findIndex((t) => t.configuration.category === category && (t.resolvedUrl === s || s.indexOf(`${t.resolvedUrl}/`) === 0)) >= 0
    );

    if (categoryTabsFromStack.length > 0) {
      await this.router.navigateByUrl(categoryTabsFromStack[categoryTabsFromStack.length - 1]);
      return true;
    }

    const categoryTabs = this._tabsSubject.getValue().filter((t) => t.configuration.category === category && t.resolvedUrl);

    if (categoryTabs.length > 0) {
      await this.router.navigateByUrl(categoryTabs[0].resolvedUrl);
      return true;
    }

    return false;
  }

  public register(tabConfiguration: ITabConfiguration) {
    const tab = new Tab(tabConfiguration, {
      remove: this.close,
      isActive: this.isActive,
      navigate: this.navigate,
    });

    if (!this._tabs[tab.identifier]) {
      this._tabs[tab.identifier] = tab;
    }

    // localStorage.setItem('tabs', JSON.stringify(Object.values(this._tabs).map(t => t.configuration)));

    if (tabConfiguration.parent) {
      const parent = typeof tabConfiguration.parent === 'object' ? tabConfiguration.parent : this._tabsSubject.getValue().find((t) => this.sameURL(tabConfiguration.parent as string, t.resolvedUrl));

      if (parent) {
        parent.addChild(tab);
      }
    }

    // for (const child of tabConfiguration.children || []) {
    //   const childTab = this.register(child)
    //   tab.addChild(childTab)
    // }

    this._tabsSubject.next(Object.values(this._tabs));

    this.checkViewState();

    return tab;
  }

  checkViewState() {
    const currentTab = this._tabsSubject.getValue().find((t) => t.isActive());

    if (currentTab) {
      this._currentTabSubject.next(currentTab);
    } else {
      this._currentTabSubject.next(null);
      return;
    }

    if (currentTab?.configuration.category !== this._categorySubject.getValue()) {
      this._categorySubject.next(currentTab.configuration.category);
    }

    const parent = this._tabsSubject.getValue().find((p) => !!p.children.find((c) => c === currentTab));

    if (parent && parent.configuration.collapsible && parent.collapsed) {
      parent.collapsed = false;
    }

    const lastActivatedRoute = this.lastActivatedRoute();

    if (Tab.getResolvedUrl(lastActivatedRoute) === currentTab.resolvedUrl) {
      if (Tab.getQueryParams(lastActivatedRoute) !== currentTab.queryParams) {
        currentTab.configuration.route = lastActivatedRoute;
      }
    }
  }

  public sameURL = (urlA: string, urlB: string) => {
    if (!urlA || !urlB) return false;
    urlA = urlA.split('?')[0].trim().replace(/\/\//g, '/').replace(/\/$/g, '');
    urlB = urlB.split('?')[0].trim().replace(/\/\//g, '/').replace(/\/$/g, '');
    return urlA === urlB;
  };

  public lastActivatedRoute(): ActivatedRouteSnapshot {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot;
  }

  public isActive = (tab: Tab): boolean => {
    if (!this._activeUrl) return false;
    return this.sameURL(this._activeUrl, tab.resolvedUrl);
    // const resolvedUrl = tab.resolvedUrl.split('?')[0].replace('//', '/');
    // const activeUrl = this._activeUrl.split('?')[0] + '/';
    // return resolvedUrl === activeUrl || activeUrl.startsWith(resolvedUrl);
  };

  private navigate = async (tab: Tab, queryParams: Params, queryParamsHandling: QueryParamsHandling): Promise<boolean> => {
    const navigated = await this.router.navigate([], {
      queryParams,
      queryParamsHandling,
    });

    if (navigated) {
      const newActivatedRoute = this.lastActivatedRoute();
      const previousResolvedUrl = Tab.getResolvedUrl(tab.configuration.route);
      const nextResolvedUrl = Tab.getResolvedUrl(newActivatedRoute);

      if (previousResolvedUrl === nextResolvedUrl) {
        tab.configuration.route = newActivatedRoute;
      }
    }

    return navigated;
  };

  // public isChildActive = (tab: Tab, child: ITabChildConfiguration) : boolean => {
  //   const resolvedUrl = tab.resolvedUrl.split('?')[0].replace('//', '/') + child.routerLink + '/';
  //   const activeUrl = this._activeUrl.split('?')[0] + '/';
  //   return resolvedUrl === activeUrl || activeUrl.startsWith(resolvedUrl);
  // }

  public close = async (tab: Tab) => {
    if (tab.configuration.sticky) {
      return;
    }

    if (tab.configuration.parent) {
      const parent =
        typeof tab.configuration.parent === 'object' ? tab.configuration.parent : this._tabsSubject.getValue().find((t) => this.sameURL(tab.configuration.parent as string, t.resolvedUrl));

      if (parent) {
        parent.removeChild(tab);
      }
    }

    delete this._tabs[tab.resolvedUrl];
    this._stack = this._stack.filter((s) => !this.sameURL(s, tab.resolvedUrl));

    if (this._stack.length > 0) {
      await this.router.navigateByUrl(this._stack[this._stack.length - 1]);
    } else if (!(await this.openCategory(this._categorySubject.getValue()))) {
      await this.router.navigateByUrl('/');
    }

    (<TabsReuseStrategy>this.routeReuse).remove(tab.configuration.route);

    // localStorage.setItem('tabs', JSON.stringify(Object.values(this._tabs).map(t => t.configuration)));
  };

  currentTab(): Observable<Tab | null> {
    return this._currentTabSubject.asObservable();
  }
}
