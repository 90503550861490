import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';
import { SlideshowStagePageModuleFactory } from './../../../common/factories/pagemodules/SlideshowStagePageModuleFactory';
import { CTASlideshowStageItem, LinkSlideshowStageItem, VideoSlideshowStageItem, isCTATeaserItem, isLinkTeaserItem, isVideoTeaserItem } from '../../entities/pagemodules/SlideshowStagePageModule';

export const slideshowStagePageModuleInputs: Inputs = {
  ...commonPageModuleInputs('SlideshowStage'),

  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].local.$language.background": {},
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new SlideshowStagePageModuleFactory().slideshowStagePageModuleLocal({})
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: obj => {
        return obj;
      }
    }
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].switchTimerEnabled": {
    type: 'switch',
    header: 'Timer Enabled'
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].switchTime": {
    type: 'number',
    header: 'Switch Time'
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].local.$language.items": {
    factory: async () => [],
    childFactory: async () => new SlideshowStagePageModuleFactory().slideshowItem({}),
    childImage: (item: LinkSlideshowStageItem | CTASlideshowStageItem | VideoSlideshowStageItem, index: number) => {
      if (isVideoTeaserItem(item)) return item?.video as string;
      if (isCTATeaserItem(item) || isLinkTeaserItem(item)) return item?.image as string;
      return '';
    },
    childLabel: (item: LinkSlideshowStageItem | CTASlideshowStageItem | VideoSlideshowStageItem, index: number) => {
      if ((isLinkTeaserItem(item) || isCTATeaserItem(item)) && item?.title) {
        return `Item #${index + 1} - ${item?.teaserItemType as string} - ${item?.title}`;
      }
      return `Item #${index + 1} - ${item?.teaserItemType as string}`;
    },
    list: true,
    header: 'Teaser Items'
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].local.$language.items[$index].teaserItemType": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Link', value: 'Link' },
      { label: 'CTA', value: 'CTA' },
      { label: 'Video', value: 'Video' }
    ]
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].local.$language.items[$index].image": {
    type: 'imageasset',
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return parent?.teaserItemType === 'Link' || parent?.teaserItemType === 'CTA';
    }
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].local.$language.items[$index].title": {
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return parent?.teaserItemType === 'Link' || parent?.teaserItemType === 'CTA';
    }
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].local.$language.items[$index].link": {
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return parent?.teaserItemType === 'Link';
    }
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].local.$language.items[$index].video": {
    type: 'videoasset',
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return parent?.teaserItemType === 'Video';
    }
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].local.$language.items[$index].targetVideo": {
    type: 'videoasset',
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return parent?.teaserItemType === 'Video';
    }
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].local.$language.items[$index].cta": {
    type: 'ctabutton',
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return parent?.teaserItemType === 'CTA';
    }
  },

  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].$.stageHeight.desktopWidescreen": {
    header: 'Top Area Height Desktop Widescreen'
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].$.stageHeight.desktop": {
    header: 'Top Area Height Desktop'
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].$.stageHeight.desktopLarge": {
    header: 'Top Area Height Desktop large'
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].$.stageHeight.mobile": {
    header: 'Top Area Height mobile'
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].$.stageHeight.mobileLarge": {
    header: 'Top Area Height mobile large'
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].$.stageHeight.tablet": {
    header: 'Top Area Height tablet '
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].$.stageHeight.tabletLarge": {
    header: 'Top Area Height tablet large'
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint": {
    factory: async () => new SlideshowStagePageModuleFactory().slideshowStagePageModuleButtonSpacingTopBreakpoint({})
  },

  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].$.buttonSpacingTopBreakpoint.desktopWidescreen": {
    header: 'Top Area Height Desktop Widescreen'
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].$.buttonSpacingTopBreakpoint.desktop": {
    header: 'Top Area Height Desktop'
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].$.buttonSpacingTopBreakpoint.desktopLarge": {
    header: 'Top Area Height Desktop large'
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].$.buttonSpacingTopBreakpoint.mobile": {
    header: 'Top Area Height mobile'
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].$.buttonSpacingTopBreakpoint.mobileLarge": {
    header: 'Top Area Height mobile large'
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].$.buttonSpacingTopBreakpoint.tablet": {
    header: 'Top Area Height tablet '
  },
  "$.pageModules[?(@.pageModuleType=='SlideshowStage' && @._id=='$pageModuleId')].$.buttonSpacingTopBreakpoint.tabletLarge": {
    header: 'Top Area Height tablet large'
  }
};
