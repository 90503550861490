import { EventProduct } from 'src/common/entities/EventVersion';
import { Injectable } from '@angular/core';
import { GetProductQuery, GetProductResponse } from 'src/common/api/v1/products/GetProduct';
import { GetProductsQuery, GetProductsResponse } from 'src/common/api/v1/products/GetProducts';
import { PostProductBody, PostProductQuery, PostProductResponse } from 'src/common/api/v1/products/PostProduct';
import { PostProductsBody, PostProductsQuery, PostProductsResponse } from 'src/common/api/v1/products/PostProducts';
import { Product } from 'src/common/entities/Product';
import { ApiService } from '../api/api.service';
import { CacheContainer } from '../cache/cache-container';
import { CacheService } from '../cache/cache.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private _cache: CacheContainer<Product>;

  constructor(private apiService: ApiService, private cacheService: CacheService) {
    this._cache = this.cacheService.create<Product>({
      get: async (productId) => await this.apiService.get<GetProductQuery, GetProductResponse>(`/api/v1/configuration/products/${productId}`).toPromise(),
      socketEvents: ['product:update'],
    });
  }

  getProduct(productId: string): Promise<Product> {
    return this._cache.asPromise(productId);
  }

  async getProducts(query?: GetProductsQuery): Promise<GetProductsResponse> {
    let products = await this.apiService
      .get<GetProductsQuery, GetProductsResponse>('/api/v1/configuration/products', {
        ...query,
      })
      .toPromise();
    this._cache.fill(products.items);
    return products;
  }

  createProduct(product: Product): Promise<Product> {
    return this._cache.post<PostProductsQuery, PostProductsBody, PostProductsResponse>('/api/v1/configuration/products', product);
  }

  updateProduct(product: Product): Promise<Product> {
    return this._cache.post<PostProductQuery, PostProductBody, PostProductResponse>(`/api/v1/configuration/products/${product._id}`, product);
  }

  deleteProduct(product: Product): Promise<Product> {
    return this._cache.post<PostProductQuery, PostProductBody, PostProductResponse>(`/api/v1/configuration/products/${product._id}/delete`, product);
  }
}
