import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { AccordionModule } from 'primeng/accordion';

import { ComponentsModule } from 'src/app/components/components.module';

import { EventStageEditComponent } from './event-stage-edit.component';
import { TextEventStageEditComponent } from './text-event-stage-edit/text-event-stage-edit.component';
import { TitleEventStageEditComponent } from './title-event-stage-edit/title-event-stage-edit.component';
import { StreamEventStageEditComponent } from './stream-event-stage-edit/stream-event-stage-edit.component';
import { TeaserEventStageEditComponent } from './teaser-event-stage-edit/teaser-event-stage-edit.component';
import { ColorPickerModule } from 'primeng/colorpicker';
import { CardModule } from 'primeng/card';

@NgModule({
  declarations: [EventStageEditComponent, TextEventStageEditComponent, TitleEventStageEditComponent, StreamEventStageEditComponent, TeaserEventStageEditComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    TranslateModule,
    ButtonModule,
    TabViewModule,
    DividerModule,
    DropdownModule,
    InputTextModule,
    InputNumberModule,
    InputSwitchModule,
    AccordionModule,
    ColorPickerModule,
    CardModule,
  ],
  exports: [EventStageEditComponent],
})
export class EventStageEditModule {}
