<div *skeleton="{ show: tab.loading }">
  <div class="tab-header">
    <h1>
      <span class="pre-title"
        >{{ (pageMode === 'CREATE' ? 'PAGE_APPOINTMENTS_NEW_APPOINTMENT' : 'GENERAL_APPOINTMENT') | translate }} {{ 'PAGE_APPOINTMENT_GENERAL_STATUS' | translate }}
        {{ 'GENERAL_APPOINTMENT_STATUSTYPE_' + this.appointment.status?.toUpperCase() | translate }}</span
      >
      <span *ngIf="appointment?.user">{{ (appointment?.user | user | async)?.organization }}</span>
      <span *ngIf="appointment?.user">{{ (appointment?.user | user | async)?.organization }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <span *ngIf="appointment.origin !== 'Host'" class="p-mr-2" style="margin-left: 8px"
      >{{ 'PAGE_APPOINTMENT_ASSIGNED_TO' | translate }} <span *ngIf="!hasOrganizerGroup">{{ (appointment.organizer | adminUser | async)?.displayName }}</span></span
    >
    <c-user-picker
      *ngIf="appointment.origin !== 'Host' && hasOrganizerGroup"
      [disabled]="loading || saving"
      [multi]="false"
      userType="ADMIN_USER"
      [user]="appointment.organizer"
      [group]="organizerGroup"
      (userChange)="assign($event)"
    ></c-user-picker>
    <p-button
      *ngIf="appointment.origin !== 'Host' && hasOrganizerGroup"
      [disabled]="loading || saving || appointment.organizer === currentUserId"
      [label]="'PAGE_APPOINTMENTS_GENERAL_ASSIGN_TO_ME' | translate"
      icon="pi pi-user"
      (onClick)="assignToMe()"
    ></p-button>
    <p-button
      *ngIf="showActionButtons && appointment.status !== 'Confirmed'"
      [disabled]="loading || saving || pageMode === 'READ' || !dirty"
      icon="pi pi-save"
      [label]="'GENERAL_SAVE' | translate"
      (onClick)="save()"
    ></p-button>
    <p-button
      *ngIf="showActionButtons && pageMode === 'CREATE' && appointment.status !== 'Confirmed'"
      [disabled]="loading || saving || !isValidForSendInvitations()"
      [label]="'Confirm & Send Invitation'"
      icon="pi pi-send"
      (onClick)="sendInvitations()"
    ></p-button>
    <p-splitButton
      *ngIf="showActionButtons && pageMode !== 'CREATE' && appointment.status !== 'Confirmed'"
      [disabled]="loading || saving || pageMode === 'READ' || !isValidForSendInvitations()"
      label="Confirm & Send Invitation"
      icon="pi pi-send"
      (onClick)="sendInvitations()"
      [model]="menu[appointment.status]"
    ></p-splitButton>
    <p-splitButton
      *ngIf="showActionButtons && appointment.status === 'Confirmed'"
      [disabled]="loading || saving || !isValidForSendInvitations() || pageMode !== 'EDIT'"
      label="Update Invitation"
      icon="pi pi-send"
      (onClick)="save()"
      [model]="menu[appointment.status]"
    ></p-splitButton>

    <p-button
      *ngIf="showActionButtons && pageMode !== 'CREATE'"
      [disabled]="loading || saving || !allowedToDelete"
      [label]="'GENERAL_DELETE' | translate"
      icon="pi pi-trash"
      (onClick)="delete()"
    ></p-button>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_GLOBAL_PROPERTIES' | translate }}
    </div>
    <div *ngIf="appointment" class="panel-body">
      <c-field header="PAGE_APPOINTMENT_GENERAL_INITIATED_BY" description="PAGE_APPOINTMENT_GENERAL_INITIATED_BY_DESCRIPTION">
        <div *ngIf="appointment.origin == 'Customer'">
          <div *ngIf="appointment.initiatedBy | user | async as user">
            <div *hasRight="'users.edit'">
              <a [routerLink]="['/users', user._id]">{{ user.displayName }}</a> (Customer)
            </div>
            <div *hasNoRight="'users.edit'">{{ user.displayName }} (Customer)</div>
            <div>{{ user.organization }}</div>
            <div *ngIf="user.street || user.zipCode || user.city">
              <div>&nbsp;</div>
              <div>{{ user.street }}</div>
              <div>{{ user.zipCode }} {{ user.city }}</div>
            </div>
            <div>
              <div>&nbsp;</div>
              <div>Country: {{ i18nService.translateCountry(user.country) }}</div>
            </div>
            <div *ngIf="user.phone || user.email">
              <div>&nbsp;</div>
              <div>
                Phone: <a [href]="'tel:' + user.phone">{{ user.phone }}</a>
              </div>
              <div>
                Email: <a [href]="'mailto:' + user.email">{{ user.email }}</a>
              </div>
            </div>
            <div>&nbsp;</div>
            <div>
              <i>Preferred Language: {{ (user.language | language | async)?.languageName }}</i>
            </div>
          </div>
        </div>
        <div *ngIf="appointment.origin !== 'Customer'">
          {{ (appointment.initiatedBy | adminUser | async)?.displayName }} ({{ 'GENERAL_APPOINTMENT_ORIGIN_' + appointment.origin.toUpperCase() | translate }})
        </div>
      </c-field>

      <c-field *ngIf="appointment.origin === 'Customer'" [error]="errorAt('company')" header="PAGE_APPOINTMENT_PROPOSALS" description="PAGE_APPOINTMENT_PROPOSALS_DESCRIPTION">
        <div *ngIf="appointment.alternative1DateFrom">
          {{ appointment.alternative1DateFrom | date : 'short' }} {{ 'GENERAL_DATES_UNTIL' | translate }} {{ appointment.alternative1DateTo | date : 'short' }}
        </div>
        <div *ngIf="appointment.alternative2DateFrom">
          {{ appointment.alternative2DateFrom | date : 'short' }} {{ 'GENERAL_DATES_UNTIL' | translate }} {{ appointment.alternative2DateTo | date : 'short' }}
        </div>
      </c-field>

      <c-field [error]="errorAt('date')" header="PAGE_APPOINTMENT_GENERAL_PLANNED_APPOINTMENT" description="PAGE_APPOINTMENT_GENERAL_PLANNED_APPOINTMENT_DESCRIPTION">
        <div *ngIf="pageMode == 'READ'">
          {{ appointment.dateFrom | date : 'short' }}
          {{ 'GENERAL_DATES_UNTIL' | translate }}
          {{ appointment.dateTo | date : 'short' }}
        </div>
        <div *ngIf="pageMode != 'READ'">
          <c-datetime [(start)]="appointment.dateFrom" [(end)]="appointment.dateTo" [defaultTimespanInMinutes]="45"></c-datetime>
        </div>
      </c-field>

      <c-field *ngIf="appointment.actualDateFrom" header="PAGE_APPOINTMENT_GENERAL_ACTUAL_APPOINTMENT" description="PAGE_APPOINTMENT_GENERAL_ACTUAL_APPOINTMENT_DESCRIPTION">
        <div>
          {{ appointment.actualDateFrom | date : 'short' }}
          {{ 'GENERAL_DATES_UNTIL' | translate }}
          {{ appointment.actualDateTo | date : 'short' }}
        </div>
      </c-field>

      <c-field [error]="errorAt('description')" header="PAGE_APPOINTMENT_GENERAL_DESCRIPTION" description="PAGE_APPOINTMENT_GENERAL_DESCRIPTION_DESCRIPTION">
        <div *ngIf="pageMode == 'READ'">
          {{ appointment.description }}
        </div>
        <textarea *ngIf="pageMode != 'READ'" name="description" pInputTextarea [(ngModel)]="appointment.description"></textarea>
      </c-field>
      <c-field [error]="errorAt('topic')" header="PAGE_APPOINTMENT_MAIN_INTEREST" description="PAGE_APPOINTMENT_MAIN_INTEREST_DESCRIPTION">
        <p-chip *ngIf="pageMode == 'READ' && topics" [label]="topics[appointment.topic]"></p-chip>
        <p-dropdown *ngIf="pageMode !== 'READ'" [options]="topicsDropdown" [(ngModel)]="appointment.topic" optionLabel="name" optionValue="code"></p-dropdown>
      </c-field>
      <c-field [error]="errorAt('otherTopics')" header="PAGE_APPOINTMENT_SUBJECTS" description="PAGE_APPOINTMENT_SUBJECTS_DESCRIPTION">
        <p-autoComplete *ngIf="pageMode !== 'READ'" [(ngModel)]="appointment.otherTopics" [suggestions]="topicsFiltered" (completeMethod)="filterTopics($event)" [dropdown]="true" [multiple]="true">
          <ng-template let-topic pTemplate="selectedItem">
            {{ topics ? topics[topic] : '' }}
          </ng-template>
          <ng-template let-topic pTemplate="item">
            {{ topics ? topics[topic] : '' }}
          </ng-template>
        </p-autoComplete>
        <div *ngIf="pageMode == 'READ' && topics">
          <p-chip *ngFor="let topic of appointment.otherTopics" [label]="topics[topic]"></p-chip>
        </div>
      </c-field>
    </div>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_APPOINTMENT_APPOINTMENT_STATUS_HEADER' | translate }}
    </div>
    <div *ngIf="appointment" class="panel-body">
      <c-field header="PAGE_APPOINTMENT_GENERAL_STATUS" description="PAGE_APPOINTMENT_GENERAL_STATUS_DESCRIPTION">
        <p-message *ngIf="!appointment.externalAppointment" severity="warn" text="No invitations sent"></p-message>
        <div *ngIf="appointment.externalAppointment">
          <p-message *ngIf="appointment.externalAppointment.error" severity="error" text="An error occured while sending the appointment"></p-message>
          <p-message *ngIf="appointment.externalAppointment.link" severity="success" text="Invitation has been sent"></p-message>

          <div class="p-mt-2" *ngIf="appointment.externalAppointment.link">
            <button class="p-button join-external-appointment-button" (click)="joinExternalAppointment()">{{ 'PAGE_APPOINTMENT_GENERAL_JOIN_EXTERNAL_APPOINTMENT' | translate }}</button>
          </div>
        </div>
      </c-field>

      <c-field header="Required Fields" description="PAGE_APPOINTMENT_GENERAL_STATUS_DESCRIPTION">
        <div class="check" [class.success]="appointment.dateFrom">Please provide a <strong>Planned Appointment start date</strong> <i *ngIf="appointment.dateFrom" class="pi pi-check"></i></div>
        <div class="check" [class.success]="appointment.dateTo">Please provide a <strong>Planned Appointment end date</strong> <i *ngIf="appointment.dateTo" class="pi pi-check"></i></div>
        <div class="check" [class.success]="appointment.participants.host" *ngIf="appointment.origin !== 'Host'">
          Please add a <strong>Host</strong> <i *ngIf="appointment.participants.host" class="pi pi-check"></i>
        </div>
        <div class="check" [class.success]="appointment.participants.users.length > 0">
          Please add one or more <strong>Participants</strong> <i *ngIf="appointment.participants.users.length > 0" class="pi pi-check"></i>
        </div>
      </c-field>
    </div>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_APPOINTMENT_CUSTOMER_ADDITIONAL_PARTICIPANTS_HEADER' | translate }}
    </div>
    <div *ngIf="appointment" class="panel-body">
      <c-field [error]="errorAt('host')" header="PAGE_APPOINTMENT_GENERAL_HOST" description="PAGE_APPOINTMENT_GENERAL_HOST_DESCRIPTION">
        <c-user-picker [readonly]="pageMode == 'READ' || appointment.origin === 'Host'" [multi]="false" userType="REMOTE_ADMIN_USER" [(user)]="appointment.participants.host"></c-user-picker>
      </c-field>

      <c-field header="PAGE_APPOINTMENT_CUSTOMER_PARTICIPANTS" description="PAGE_APPOINTMENT_CUSTOMER_PARTICIPANTS_DESCRIPTION">
        <c-user-picker [readonly]="pageMode == 'READ'" [userType]="'USER'" [(users)]="appointment.participants.users" [removeBlacklist]="[appointment.user]"></c-user-picker>
      </c-field>

      <c-field header="PAGE_APPOINTMENT_CUSTOMER_ADDITIONAL_PARTICIPANTS_COMPANY" description="PAGE_APPOINTMENT_CUSTOMER_ADDITIONAL_PARTICIPANTS_COMPANY_DESCRIPTION">
        <div *ngIf="pageMode == 'READ'">
          <p-chip *ngFor="let user of appointment.participants.adminUsers" [label]="(user | adminUser | async)?.displayName"></p-chip>
        </div>
        <c-user-picker *ngIf="pageMode != 'READ'" [userType]="'REMOTE_ADMIN_USER'" [(users)]="appointment.participants.adminUsers" [removeBlacklist]="[appointment.participants.host]"></c-user-picker>
      </c-field>
    </div>
  </div>
</div>

<p-dialog [header]="'PAGE_APPOINTMENT_GENERAL_ERROR_HEADER' | translate" [(visible)]="showError" [modal]="true" [draggable]="false" [closable]="true" [closeOnEscape]="true">
  <p-messages severity="error">
    <ng-template pTemplate>
      {{ 'PAGE_APPOINTMENT_GENERAL_ERROR_DESCRIPTION' | translate }}
    </ng-template>
  </p-messages>
</p-dialog>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
