import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { TextListPageModuleFactory } from './../../factories/pagemodules/TextListPageModuleFactory';
import { titlePositions } from './../../entities/pagemodules/PageModule';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const textListPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('TextList'),
  "$.pageModules[?(@.pageModuleType=='TextList' && @._id=='$pageModuleId')].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='TextList' && @._id=='$pageModuleId')].local.$language.itemIcon": {
    type: 'imageasset',
  },
  "$.pageModules[?(@.pageModuleType=='TextList' && @._id=='$pageModuleId')].listPosition": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Left', value: 'left' },
      { label: 'Right', value: 'right' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='TextList' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new TextListPageModuleFactory().createTextListPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='TextList' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='TextList' && @._id=='$pageModuleId')].local.$language.text": {},
  "$.pageModules[?(@.pageModuleType=='TextList' && @._id=='$pageModuleId')].local.$language.items": {
    factory: async () => [],
    list: true,
    childFactory: async () => '',
  },
  "$.pageModules[?(@.pageModuleType=='TextList' && @._id=='$pageModuleId')].local.$language.items[$index]": {
    header: 'Item',
  },
  "$.pageModules[?(@.pageModuleType=='TextList' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [{ label: 'content', value: 'content' }].concat(
      [...titlePositions].map((item) => ({
        label: item,
        value: item,
      }))
    ),
  },
};
