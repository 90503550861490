import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MenuItem, PrimeIcons } from 'primeng/api';

export interface SelectMenuItem {
  id: string;
  title: string;
}

@Component({
  selector: 'c-select-menu',
  templateUrl: './select-menu.component.html',
  styleUrls: ['./select-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectMenuComponent implements OnChanges {
  @Input()
  currentItem: string | null = null;

  @Input()
  disabledActions: boolean = false;

  @Output()
  currentItemChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  invalidItems: string[] = [];

  @Input()
  selectedItems: string[];

  get _selectedItems(): string[] {
    return this.selectedItems || (this._items || []).map((i) => i.id);
  }

  _labelAddButton: string = '';
  @Input() set labelAddButton(value: string) {
    if (this._labelAddButton != value) {
      this._labelAddButton = value;
      this.refresh();
    }
  }

  _items: SelectMenuItem[] = [];
  @Input() set items(value: SelectMenuItem[]) {
    if (JSON.stringify(this._items) != JSON.stringify(value)) {
      this._items = value;
      this.refresh();
    }
  }

  @Output()
  selectedItemsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  menuItems: MenuItem[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      // Not nice, but should be ok for now
      (changes['invalidItems'] && JSON.stringify(changes['invalidItems'].currentValue) != JSON.stringify(changes['invalidItems'].previousValue)) ||
      (changes['selectedItems'] && JSON.stringify(changes['selectedItems'].currentValue) != JSON.stringify(changes['selectedItems'].previousValue)) ||
      (changes['currentItem'] && changes['currentItem'].currentValue != changes['currentItem'].previousValue) ||
      (changes['disabledActions'] && changes['disabledActions'].currentValue != changes['disabledActions'].previousValue)
    ) {
      this.refresh();
    }
  }

  styleClass(id: string) {
    let clazz = '';
    if (id === this.currentItem) clazz += ' active';
    if (this.invalidItems.includes(id)) clazz += ' invalid';
    return clazz;
  }

  refresh() {
    let unselectedItemsCnt: number = (this._items == null ? 0 : (this._items || []).length) - this._selectedItems.length;
    this.menuItems = this._selectedItems.map((id: string) => {
      return {
        label: (this._items || []).find((l) => l.id === id)?.title,
        icon: PrimeIcons.ANGLE_RIGHT,
        styleClass: this.styleClass(id),
        command: (event) => {
          this.selectItem(id);
        },
      };
    });

    if (unselectedItemsCnt != 0 || this._selectedItems.length == 0) {
      if (!this.disabledActions) {
        this.menuItems.push({
          label: this._labelAddButton,
          icon: PrimeIcons.PLUS,
          items: (this._items || [])
            .filter((l) => !this._selectedItems.includes(l.id))
            .map((item: SelectMenuItem) => {
              return {
                label: (this._items || []).find((l) => l.id === item.id)?.title,
                icon: '',
                command: (event) => {
                  this.selectItem(item.id);
                },
              };
            }),
        });
      }
    }

    // if ((!this.currentItem || !(this._items || []).find(i => i.id === this.currentItem)) && this._selectedItems.length > 0) {
    //   this.selectItem(this._selectedItems[0]);
    // }
  }

  selectItem(id: string) {
    if (this.selectedItems && !this.selectedItems.includes(id)) {
      this.selectedItems.push(id);
      this.selectedItemsChange.emit(this.selectedItems);
    }

    this.currentItem = id;
    this.currentItemChange.emit(this.currentItem);
  }
}
