<div *hasRight="['assets.read', 'assets.edit']">
  <div *ngIf="previewOnly">
    <c-asset-item *ngIf="id && !assetInvalid" [asset]="asset" [disableDetails]="previewOnly"></c-asset-item>
  </div>
  <div *ngIf="!previewOnly">
    <ngx-file-drop contentClassName="file-drop-content" (onFileDrop)="dropped($event)" [multiple]="false" dropZoneClassName="drop-zone">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <c-asset-item *ngIf="id && !assetInvalid" [asset]="asset" [disableDetails]="previewOnly"></c-asset-item>
        <div *ngIf="assetInvalid" class="p-error p-mb-2">{{ 'COMPONENT_ASSET_SELECT_REPLACE_INVALID_ID' | translate }} {{ id }}!</div>

        <div class="actions as-layer" *ngIf="id && !assetInvalid && !disabled">
          <span *ngIf="showDeleteButton">
            <a *hasRight="['assets.list']" [routerLink]="[]" [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openSearch()">{{ 'GENERAL_SEARCH' | translate }}</a
            >&nbsp;/&nbsp; <a *hasRight="['assets.create']" [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openFileSelector()">{{ 'GENERAL_UPLOAD' | translate }}</a
            >&nbsp;/&nbsp; <a *hasRight="['assets.remove']" [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="removeAsset()">Remove</a>&nbsp;
          </span>
          <span *ngIf="!showDeleteButton">
            <a *hasRight="['assets.list']" [routerLink]="[]" [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openSearch()">{{ 'GENERAL_SEARCH' | translate }}</a
            >&nbsp;/&nbsp; <a *hasRight="['assets.create']" [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openFileSelector()">{{ 'GENERAL_UPLOAD' | translate }}</a>
          </span>
        </div>

        <div class="actions" *ngIf="!id || assetInvalid">
          <span *ngIf="!disabled">
            <a *hasRight="['assets.list']" [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openSearch()">{{ 'GENERAL_SEARCH' | translate }}</a
            >,&nbsp; <a *hasRight="['assets.create']" [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openFileSelector()">{{ 'GENERAL_UPLOAD' | translate }}</a
            >&nbsp;
            {{ 'COMPONENT_ASSET_SELECT_DROP_A_FILE' | translate }}
            <span *hasNoRight="['assets.list', 'assets.create']">{{ 'COMPONENT_ASSET_SELECT_NO_PERMISSION' | translate }}</span>
          </span>
        </div>
      </ng-template>
    </ngx-file-drop>

    <p-dialog [header]="'COMPONENT_ASSET_SELECT_ERROR' | translate" [(visible)]="showError" [draggable]="false" [modal]="true" [closable]="true" [closeOnEscape]="true" [appendTo]="'body'">
      <p-messages severity="error">
        <ng-template pTemplate>
          {{ 'COMPONENT_ASSET_SELECT_INVALID_FORMAT' | translate }}
        </ng-template>
      </p-messages>
      <h3>{{ 'COMPONENT_ASSET_SELECT_REQUIREMENTS' | translate }}</h3>
      <ul>
        <li *ngIf="mimeTypes.length > 0">
          {{ 'COMPONENT_ASSET_SELECT_REQUIREMENT_MIME_TYPES' | translate }} <span *ngFor="let mimeType of mimeTypes; let last = last">{{ mimeType }}<span *ngIf="!last">, </span></span>
        </li>
        <li *ngIf="width" [innerHTML]="'COMPONENT_ASSET_SELECT_REQUIREMENT_WIDTH' | translate : { width: width }"></li>
        <li *ngIf="minWidth" [innerHTML]="'COMPONENT_ASSET_SELECT_REQUIREMENT_MIN_WIDTH' | translate : { minWidth: minWidth }"></li>
        <li *ngIf="height" [innerHTML]="'COMPONENT_ASSET_SELECT_REQUIREMENT_HEIGHT' | translate : { height: height }"></li>
        <li *ngIf="minHeight" [innerHTML]="'COMPONENT_ASSET_SELECT_REQUIREMENT_MIN_HEIGHT' | translate : { minHeight: minHeight }"></li>
      </ul>
    </p-dialog>

    <p-sidebar *hasRight="['assets.list']" [(visible)]="showSearch" styleClass="p-sidebar-st" [position]="position || 'right'" [modal]="true" [showCloseIcon]="false" [appendTo]="'body'">
      <c-sidebar-header [headerTitle]="'Select Asset'" (onClose)="showSearch = false"></c-sidebar-header>
      <div class="sidebar-body">
        <c-asset-select-search
          *ngIf="showSearch"
          [mimeTypes]="mimeTypes"
          [width]="width"
          [minWidth]="minWidth"
          [height]="height"
          [minHeight]="minHeight"
          (assetSelected)="selectAsset($event)"
        ></c-asset-select-search>
      </div>
      <c-sidebar-footer [showDeleteButton]="false"></c-sidebar-footer>
    </p-sidebar>
  </div>
</div>
<div *hasNoRight="['assets.read', 'assets.edit']" class="p-error p-mb-2">{{ 'COMPONENT_ASSET_SELECT_NO_PERMISSION' | translate }}</div>
