import { MessageModule } from 'primeng/message';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TooltipModule } from 'primeng/tooltip';

import { ComponentsModule } from 'src/app/components/components.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

import { EmailTemplateComponent } from './email-template.component';

const routes: Routes = [{ path: '', component: EmailTemplateComponent }];

@NgModule({
  declarations: [EmailTemplateComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FormsModule,
    ButtonModule,
    SelectButtonModule,
    CardModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    TooltipModule,
    CheckboxModule,
    ComponentsModule,
    EditorModule,
    PipesModule,
    DirectivesModule,
    MessageModule,
  ],
  exports: [RouterModule],
})
export class EmailTemplateModule {}
