import { HeadlineType } from './../../entities/Attributes';
import { BreakpointValue } from './../../entities/Breakpoint';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';
import { createCTAButton } from '../../entities/CTAButton';
import {
  CTASlideshowStageItem,
  LinkSlideshowStageItem,
  SlideshowStageItem,
  SlideshowStagePageModule,
  SlideshowStagePageModuleAttributes,
  SlideshowStagePageModuleLocal,
  VideoSlideshowStageItem
} from './../../entities/pagemodules/SlideshowStagePageModule';
import { ButtonSpacingTopDefaultBreakpointValue, StageHeightDefaultBreakpointValue } from './DefaultBreakponitValue';

export class SlideshowStagePageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async slideshowItem(values: any): Promise<CTASlideshowStageItem | VideoSlideshowStageItem | LinkSlideshowStageItem> {
    return {
      _id: await this.id(values?._id),
      teaserItemType: values?.teaserItemType || 'Link',
      image: values?.image || null,
      title: values?.title || null,
      subtext: values?.subtext || null,
      link: values?.link || null,
      cta: values?.teaserItemType === 'CTA' ? createCTAButton(values?.cta) : null,
      video: values?.video || null,
      targetVideo: values?.targetVideo || null
    };
  }

  public async slideshowStagePageModuleLocal(values?: any): Promise<SlideshowStagePageModuleLocal> {
    return {
      title: values?.title || '',
      background: values?.background || null,
      items: await Promise.all((values?.items || []).map((i: any) => this.slideshowItem(i)))
    };
  }

  public slideshowStagePageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): SlideshowStagePageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2'
    };
  }

  public slideshowStagePageModuleStageHeight(defaultValues: BreakpointValue<number>, values?: any) {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge
    };
  }

  public slideshowStagePageModuleButtonSpacingTopBreakpoint(defaultValues: BreakpointValue<number>, values?: any) {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge
    };
  }

  public async slideshowStagePageModule(values?: any): Promise<SlideshowStagePageModule> {
    if (values?.background) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.background = item.background || values.background;
      });
    }
    return {
      pageModuleType: 'SlideshowStage',
      ...(await this.baseValues(values)),
      local: await this.local(values, val => this.slideshowStagePageModuleLocal(val)),
      stageHeight: values?.stageHeight ? values?.stageHeight : this.slideshowStagePageModuleStageHeight(StageHeightDefaultBreakpointValue, values?.stageHeight),
      buttonSpacingTopBreakpoint: values?.buttonSpacingTopBreakpoint
        ? values?.buttonSpacingTopBreakpoint
        : this.slideshowStagePageModuleButtonSpacingTopBreakpoint(ButtonSpacingTopDefaultBreakpointValue, values?.buttonSpacingTopBreakpoint),
      switchTimerEnabled: values?.switchTimerEnabled || true,
      switchTime: values?.switchTime || 8
    };
  }
}
