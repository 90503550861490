import { IFilterList } from './../../../../common/api/interfaces';
import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'c-table-status',
  templateUrl: './table-status.component.html',
  styleUrls: ['./table-status.component.scss'],
})
export class TableStatusComponent {
  listTotalRecords: number;
  listInitRecords: number;
  listTotalParticipants?: number;
  listInitParticipants?: number;
  showParticipants: boolean = false;
  filteredRecords: boolean;

  @HostBinding('style.--font-color-var')
  fontColor: string;

  async init(filterList: IFilterList, totalRecords: number, initRecords: number, totalParticipants?: number | undefined, initParticipants?: number | undefined) {
    this.listTotalRecords = totalRecords;
    this.listInitRecords = initRecords;
    this.listTotalParticipants = totalParticipants;
    this.listInitParticipants = initParticipants;

    if (initParticipants >= 0 && totalParticipants >= 0) {
      this.showParticipants = true;
    } else {
      this.showParticipants = false;
    }

    this.filteredRecords = false;
    this.fontColor = '#027DB4';
    if (Object.keys(filterList).length > 0) {
      this.fontColor = '#b34800';
      this.filteredRecords = true;
    }
  }
}
