export const conditionTypes = ['And', 'Or', 'Equal', 'NotEqual', 'EqualOrChildOf', 'EndsWith', 'StartsWith', 'Contains'] as const;
export type ConditionType = (typeof conditionTypes)[number];

export const fieldTypes = ['Tag', 'Country', 'Email'] as const;
export type FieldType = (typeof fieldTypes)[number];

// do NOT add And or Or condition!
export const fieldConditionMapping: { [type in FieldType]: ConditionType[] } = {
  Tag: ['Equal', 'NotEqual', 'EqualOrChildOf'],
  Country: ['Equal', 'NotEqual'],
  Email: ['Equal', 'NotEqual', 'EndsWith', 'StartsWith', 'Contains'],
};

export interface Condition {
  type: ConditionType;
}

export interface AndCondition extends Condition {
  type: 'And';
  conditions: Condition[];
}

export interface OrCondition extends Condition {
  type: 'Or';
  conditions: Condition[];
}

export interface FieldCondition extends Condition {
  field: FieldType;
  value: any;
}

export interface EqualCondition extends FieldCondition {
  type: 'Equal';
}

export interface NotEqualCondition extends FieldCondition {
  type: 'NotEqual';
}

export interface EndsWithCondition extends FieldCondition {
  type: 'EndsWith';
}

export interface StartsWithCondition extends FieldCondition {
  type: 'StartsWith';
}

export interface ContainsCondition extends FieldCondition {
  type: 'Contains';
}

export interface EqualOrChildOfCondition extends FieldCondition {
  type: 'EqualOrChildOf';
}

export function isAndCondition(condition: Condition): condition is AndCondition {
  return condition.type === 'And';
}

export function isOrCondition(condition: Condition): condition is OrCondition {
  return condition.type === 'Or';
}

export function isEqualCondition(condition: Condition): condition is EqualCondition {
  return condition.type === 'Equal';
}

export function isNotEqualCondition(condition: Condition): condition is NotEqualCondition {
  return condition.type === 'NotEqual';
}

export function isEqualOrChildOfCondition(condition: Condition): condition is EqualOrChildOfCondition {
  return condition.type === 'EqualOrChildOf';
}

export function isEndsWithCondition(condition: Condition): condition is EndsWithCondition {
  return condition.type === 'EndsWith';
}

export function isStartsWithCondition(condition: Condition): condition is StartsWithCondition {
  return condition.type === 'StartsWith';
}

export function isContainsCondition(condition: Condition): condition is ContainsCondition {
  return condition.type === 'Contains';
}
