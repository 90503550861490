import { HeadlineType } from './../../entities/Attributes';
import {
  ProductListPageModule,
  ProductListPageModuleAttributes,
  ProductListPageModuleGroup,
  ProductListPageModuleProduct,
  ProductListPageModuleSeries,
} from './../../entities/pagemodules/ProductListPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class ProductListPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public productListPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
    };
  }

  public productListPageModuleLocalWithAttributes(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.productListPageModuleAttributes(values?.title_attr),
    };
  }

  public productListPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): ProductListPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public productListPageModuleProductLocal(values: any) {
    return {
      title: values?.title || '',
      image: values?.image || null,
      linkType: values?.linkType || null,
      link: values?.link || '',
    };
  }

  public async productListPageModuleGroup(values: any): Promise<ProductListPageModuleGroup> {
    return {
      _id: await this.id(values?._id),
      local: await this.local(values, (val) => this.productListPageModuleLocal(val)),
      series: await Promise.all((values?.series || []).map((e: any) => this.productListPageModuleSeries(e))),
    };
  }

  public async productListPageModuleSeries(values: any): Promise<ProductListPageModuleSeries> {
    return {
      _id: await this.id(values?._id),
      local: await this.local(values, (val) => this.productListPageModuleLocal(val)),
      products: await Promise.all((values?.products || []).map((e: any) => this.productListPageModuleProducts(e))),
    } as ProductListPageModuleSeries;
  }

  public async productListPageModuleProducts(values: any): Promise<ProductListPageModuleProduct> {
    return {
      _id: await this.id(values?._id),
      local: await this.local(values, (val) => this.productListPageModuleProductLocal(val)),
    } as ProductListPageModuleProduct;
  }

  public async productListPageModule(values?: any): Promise<ProductListPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'ProductList',
      showHeadline: values?.showHeadline || false,
      local: await this.local(values, (val) => this.productListPageModuleLocalWithAttributes(val)),
      titlePosition: values?.titlePosition || 'left',
      ctaButton: values?.ctaButton || null,
      groups: await Promise.all((values?.groups || []).map((e: any) => this.productListPageModuleGroup(e))),
      productImageSize: values?.productImageSize || 'default',
    };
  }
}
