import { SpeakerSession } from '../../entities/Session';
import { FactoryOptions } from '../AbstractFactory';
import { BaseSessionFactory } from './BaseSessionFactory';

export class SpeakerSessionFactory extends BaseSessionFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async createSpeakerSession(values: any): Promise<SpeakerSession> {
    return {
      ...values,
      _id: await this.id(values?._id),
      sessionType: 'SpeakerSession',
      endAt: values?.endAt || null,
      highlight: values?.highlight || false,
      mainStageHighlight: values?.mainStageHighlight || false,
      startAt: values?.startAt || null,
      tags: values?.tags || {},
      keytopics: values?.keytopics || [],
      actualEndAt: values?.actualEndAt || null,
      actualStartAt: values?.actualStartAt || null,
      regional: values?.regional || {},
      images: {
        main: values?.images?.main || null,
      },
      local: Object.keys(values?.local || {}).reduce((a, b) => ({ ...a, [b]: this.createSessionLocal(values.local[b]) }), {}),
      event: values?.event || null,
      hidden: values?.hidden || false,
      skippable: values?.skippable || false,
      speakers: values?.speakers || [],
      sessionMedia: values?.sessionMedia || null,
      stages: values?.stages || [],
      maxChatMessages: values?.maxChatMessages || 100,
    } as SpeakerSession;
  }
}
