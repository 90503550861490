import { Pipe, PipeTransform } from '@angular/core';
import { OperatingRegionsService } from 'src/app/services/operating-regions/operating-regions.service';
import { OperatingRegion } from 'src/common/entities/OperatingRegion';

@Pipe({
  name: 'operatingRegion',
})
export class OperatingRegionPipe implements PipeTransform {
  constructor(private operatingRegionsService: OperatingRegionsService) {}

  transform(operatingRegion: string, ...args: unknown[]): Promise<OperatingRegion> {
    return this.operatingRegionsService.getOperatingRegion(operatingRegion);
  }
}
