import { NotificationsService } from './../../../services/notifications/notifications.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { TableOptions, TableQuery } from 'src/app/components/table/table.interfaces';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { GetNotificationsResponse } from 'src/common/api/v1/notifications/GetNotifications';
import { Event, eventPhase } from 'src/common/entities/Event';
import { isAppNotificationData, Notification } from 'src/common/entities/Notification';
import { NotificationFactory } from 'src/common/factories/NotificationFactory';
import { InputConfiguration, Inputs } from 'src/common/inputs/Inputs';

@Component({
  selector: 'app-live-notifications',
  templateUrl: './live-notifications.component.html',
  styleUrls: ['./live-notifications.component.scss'],
})
export class LiveNotificationsComponent implements OnInit, OnDestroy {
  @Input()
  event: Event = null;

  @Input()
  languages: string[] = [];

  @Input()
  currentLanguage: string = '';

  notification: Notification;
  editMode: boolean = false;
  showSidebar: boolean = false;
  showSendSidebar: boolean = false;
  loading: boolean = false;
  saving: boolean = false;
  mode;

  eventPages = [{ value: null, label: '-' }];

  isWorking = false;

  inputConfig: InputConfiguration;
  typeInputConfig: InputConfiguration;

  confirm: { users?: string[]; mode: 'all' | 'user' } = { users: null, mode: 'all' };
  optionalButton = async () => {
    return await this.confirmSend();
  };

  confirmInputs: Inputs = {
    '$.mode': {
      header: 'Mode',
      type: 'dropdown',
      dropdownOptions: [
        { value: 'all', label: 'Send to all Users' },
        { value: 'user', label: 'Send to specific Users' },
      ],
    },
    '$.users': {
      header: 'GENERAL_USERS',
      type: 'user',
      condition: () => {
        return this.confirm.mode === 'user';
      },
    },
  };

  jsonPathes;

  inputs: Inputs = {
    '$.internalName': {},
    '$.local.$language.title': {},
    '$.local.$language.text': {},
    '$.data.type': {
      type: 'dropdown',
      dropdownOptions: [
        { label: 'Open App', value: 'OpenApp' },
        { label: 'Open Event Page', value: 'OpenEventPage' },
      ],
      onChange: (a, b) => {
        a === 'OpenApp' ? (this.typeInputConfig = { hide: true }) : (this.typeInputConfig = { hide: false });
        this.eventPageDropdown();
      },
    },
    '$.data.page': {
      header: 'Target Page',
      type: 'dropdown',
      dropdownOptions: [{ value: null, label: '-' }],
    },
  };

  notificationsTableOptions: TableOptions<Notification> = {
    columns: [{ header: 'PAGE_EVENT_SECTION_NOTIFICATION_INTERNAL_NAME', visible: 'fixed' }, { header: 'Message Title' }, { header: 'Message Body' }, { header: '' }, { header: '' }],
  };

  constructor(
    private notificationsService: NotificationsService,
    private confirmationService: ConfirmationService,
    private collaborationService: CollaborationService,
    private configurationService: ConfigurationService
  ) {}
  ngOnDestroy(): void {
    if (this.notification) {
      this.collaborationService.unregisterLocal(`notification:${this.notification._id}`);
    }
  }
  ngOnInit(): void {
    this.inputConfig = { languages: this.languages };
    this.jsonPathes = Object.keys(this.confirmInputs);
  }
  refresh = new BehaviorSubject<unknown>(null);

  notificationQuery(query: TableQuery<Notification>) {
    query.result = this.notificationsService.getNotifications(this.event._id, query.query) as unknown as GetNotificationsResponse;
  }

  async create(): Promise<void> {
    this.editMode = false;
    this.notification = await new NotificationFactory().createNotification({ data: { type: 'OpenApp', event: this.event._id } });
    this.collaborationService.registerLocal(`notification:${this.notification._id}`, this.notification, true);
    this.typeInputConfig = { hide: true };
    this.eventPageDropdown();
    this.ensureLanguages(this.languages);
    this.showSidebar = true;
  }

  async edit(notification: Notification): Promise<void> {
    this.editMode = true;
    this.notification = await new NotificationFactory().createNotification({ ...notification, data: { ...notification.data, event: this.event._id } });
    this.typeInputConfig = isAppNotificationData(notification) ? { hide: true } : { hide: false };
    isAppNotificationData(notification.data) ? (this.typeInputConfig = { hide: true }) : (this.typeInputConfig = { hide: false });
    this.eventPageDropdown();
    this.collaborationService.registerLocal(`notification:${this.notification._id}`, this.notification, true);
    this.ensureLanguages(this.languages);
    this.showSidebar = true;
  }

  async save(): Promise<void> {
    if (this.editMode) {
      await this.notificationsService.updateNotification(this.notification);
    } else {
      await this.notificationsService.createNotification(this.notification);
    }
    this.showSidebar = false;
    this.refresh.next(true);
  }

  eventPageDropdown() {
    const phase = eventPhase(this.event, null, this.configurationService.serverTime());
    this.eventPages = [{ value: null, label: '-' }].concat(
      this.event.currentEventVersion.phase[phase].pages.map((item) => {
        return { label: `${item.local?.en?.navigationTitle || 'unknown'} - ${item.path || ''}`, value: item._id };
      })
    );
    this.typeInputConfig = { ...this.typeInputConfig, type: 'dropdown', dropdownOptions: this.eventPages };
  }

  async delete(): Promise<void> {}

  async confirmSend() {
    this.closeNotificationSendDialog();
    const selectedMode = this.confirm;
    const notification = this.notification;
    this.confirmationService.confirm({
      header: 'Confirmation',
      message: `Are you sure you want to send the push notification: "${this.notification.internalName}" ?`,
      icon: 'pi pi-exclamation-triangle',
      accept: async (_) => {
        const users = selectedMode?.users && this.confirm.mode === 'user' ? selectedMode.users : null;
        await this.notificationsService.sendNotification(notification, users);
      },
      reject: () => {
        // Nothing to do
      },
    });
  }

  async send(notification?: Notification): Promise<void> {
    this.confirm = { users: null, mode: 'all' };
    this.notification = notification;
    this.showSendSidebar = true;
  }

  ensureLanguages(languages: string[]) {
    for (const language of languages) {
      if (!this.notification.local[language]) {
        this.notification.local[language] = new NotificationFactory().createNotificationLocal({});
      }
    }
  }

  closeNotificationDialog() {
    this.showSidebar = false;
  }

  closeNotificationSendDialog() {
    this.showSendSidebar = false;
  }
}
