import { CustomPageModule } from '../../entities/pagemodules/CustomPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class CustomPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async customPageModule(values?: any): Promise<CustomPageModule> {
    return {
      _id: await this.id(values?._id),
      pageModuleType: 'Custom',
      customPageModuleType: values?.customPageModuleType,
      plugin: values?.plugin,
      accessPolicy: values?.accessPolicy || [],
      configuration: values?.configuration || {},
      pageModules: values?.pageModules || [],
    } as CustomPageModule
  }
}
