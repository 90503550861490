<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'GENERAL_WEBSITE_LANGUAGES' | translate }}</h1>
  </div>
  <div class="tab-menu"></div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_WEBSITE_LANGUAGES' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button *hasRight="'configuration.create'" [label]="('GENERAL_NEW' | translate) + ' ' + ('GENERAL_LANGUAGE' | translate)" icon="pi icon-ic-add-item" (onClick)="createLanguage()"></p-button>
      </div>
    </div>
    <c-table mode="query" [options]="tableOptions" [tableOptionsComponent]="toc" (query)="query($event)" [baseRoute]="'/configuration/languages'" [routePathProperty]="'_id'">
      <ng-template let-language>
        <div *hasRight="['configuration.read', 'configuration.edit']">{{ language.language }}</div>
        <span *hasNoRight="['configuration.read', 'configuration.edit']">{{ language.language }}</span>
      </ng-template>
      <ng-template let-language>{{ language.languageName }}</ng-template>
      <ng-template let-language>
        <p-checkbox [(ngModel)]="language.active" [binary]="true" [disabled]="true" (click)="linkClick($event)"></p-checkbox>
      </ng-template>
      <ng-template let-language>
        <p-checkbox [(ngModel)]="language.selectable" [binary]="true" [disabled]="true" (click)="linkClick($event)"></p-checkbox>
      </ng-template>
      <ng-template let-language>
        <a *hasRight="['configuration.read', 'configuration.edit']" [routerLink]="['/configuration/i18n/' + language.language]" (click)="linkClick($event)">{{ 'GENERAL_I18N' | translate }}</a>
      </ng-template>
    </c-table>
  </div>
</div>
