<div class="content">
  <div class="navBar p-d-flex p-jc-between">
    <div class="p-d-flex p-ai-center">
      <i class="back pi pi-angle-left" (click)="op.toggle($event)"></i>
      <!-- <img class="logo" src="/assets/images/dmg-mori-meet-expert.svg" />
      <img class="logo small" src="/assets/images/dmg-mori-logo.svg" /> -->
    </div>
    <div class="p-text-right">
      <!-- <button *ngIf="!appointment || (isHeaderMinimized && isShowroomMinimized && appointment?.actualDateFrom)" pButton pRipple type="button" icon="pi pi-desktop" class="p-button p-button-outlined p-ml-2 joinButton" [label]=" 'PAGE_APPOINTMENT_DETAIL_ACTION_JOIN_DIGITAL_SHOWROOM' | translate" (click)="joinDigitalShowroom()"></button> -->
      <!-- <button *ngIf="appointment && !showShowroom && !appointment?.actualDateFrom" pButton pRipple type="button" icon="pi pi-clock" class="p-button p-button-outlined p-ml-2 join-external-appointment-button" [label]=" 'PAGE_APPOINTMENT_DETAIL_ACTION_START_APPOINTMENT' | translate" (click)="startAppointment()"></button> -->
      <button
        *ngIf="appointment && showShowroom && appointment?.actualDateFrom && !appointment?.actualDateTo"
        [disabled]="saving"
        pButton
        pRipple
        type="button"
        icon="pi pi-users"
        class="p-button p-ml-2 join-external-appointment-button"
        [label]="'PAGE_APPOINTMENT_DETAIL_ACTION_JOIN_MS_TEAMS' | translate"
        (click)="joinExternalAppointment()"
      ></button>
      <button
        *ngIf="appointment && showShowroom && appointment?.actualDateFrom && !appointment?.actualDateTo"
        [disabled]="saving"
        pButton
        pRipple
        type="button"
        icon="pi pi-clock"
        class="p-button p-ml-2 stop-button"
        [label]="'PAGE_APPOINTMENT_DETAIL_ACTION_STOP_APPOINTMENT' | translate"
        (click)="stopAppointment()"
      ></button>

      <!-- <button *ngIf="appointment && showroomStarted && !showShowroom" pButton pRipple type="button" icon="pi pi-angle-double-up" class="p-button p-button-outlined p-ml-2" (click)="showShowroom = true"></button> -->

      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-bookmark"
        class="p-button p-button-outlined p-ml-2"
        (click)="showPositionDialog('right')"
        [label]="'PAGE_APPOINTMENT_DETAIL_RELATED_CONTENT' | translate"
      ></button>
    </div>
  </div>

  <div class="inner-container">
    <div class="appointment">
      <div class="inner-appointment">
        <h1>{{ 'PAGE_APPOINTMENT_DETAIL_HEADER_WELCOME' | translate }}</h1>
        <div *ngIf="!adhocAppointment && appointment" class="appointment-details">
          <div class="p-grid">
            <div class="p-col-12 p-md-6">
              <div class="p-col-12 p-md-12 detail-box">
                <div class="p-grid p-ai-stretch">
                  <div class="p-col-12 p-md-6 title">
                    <p>{{ 'PAGE_APPOINTMENT_DETAIL_HEADER_DESCRIPTION' | translate }}</p>
                  </div>
                  <div class="p-col-12 p-md-6 value" style="min-height: 115px">
                    <p>{{ appointment?.description }}</p>
                  </div>
                </div>
              </div>
              <div class="p-col-12 p-md-12 detail-box">
                <div class="p-grid p-ai-stretch">
                  <div class="p-col-12 p-md-6 title">
                    <p>{{ 'PAGE_APPOINTMENT_DETAIL_HEADER_MAIN_SUBJECT' | translate }}</p>
                  </div>
                  <div class="p-col-12 p-md-6 value">
                    <p *ngIf="appointment?.topic" class="subject">{{ topics[appointment?.topic] }}</p>
                  </div>
                </div>
              </div>
              <div class="p-col-12 p-md-12 detail-box">
                <div class="p-grid p-ai-stretch">
                  <div class="p-col-12 p-md-6 title">
                    <p>{{ 'PAGE_APPOINTMENT_DETAIL_HEADER_FURTHER_SUBJECTS' | translate }}</p>
                  </div>
                  <div class="p-col-12 p-md-6 value">
                    <p *ngFor="let subject of appointment?.otherTopics" class="subject">{{ topics[subject] }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-col-12 p-md-6">
              <div class="p-col-12 p-md-12 detail-box">
                <div class="p-grid p-ai-stretch">
                  <div class="p-col-12 p-md-4 title">
                    <p>{{ 'PAGE_APPOINTMENT_DETAIL_HEADER_YOUR_HOST' | translate }}</p>
                  </div>
                  <div class="p-col-12 p-md-8 value">
                    <p>
                      <strong>{{ (appointment?.participants.host | adminUser | async)?.displayName }}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div class="p-col-12 p-md-12 detail-box">
                <div class="p-grid p-ai-stretch">
                  <div class="p-col-12 p-md-4 title">
                    <p>{{ 'PAGE_APPOINTMENT_DETAIL_HEADER_YOUR_COMPANY_PARTICIPANTS' | translate }}</p>
                  </div>
                  <div class="p-col-12 p-md-8 value">
                    <p *ngFor="let expert of appointment?.participants.adminUsers">
                      <strong>{{ (expert | adminUser | async)?.displayName }}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div class="p-col-12 p-md-12 detail-box">
                <div class="p-grid p-ai-stretch">
                  <div class="p-col-12 p-md-4 title">
                    <p>{{ 'PAGE_APPOINTMENT_DETAIL_HEADER_PARTICIPANTS' | translate }}</p>
                  </div>
                  <div class="p-col-12 p-md-8 value">
                    <p *ngFor="let user of appointment?.participants.users">
                      <strong>{{ (user | user | async)?.fullName }}</strong> <br />
                      {{ (user | user | async)?.organization }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-col-12 p-md-6 detail-box">
              <div class="p-grid p-ai-stretch">
                <div class="p-col-12 p-md-6 title">
                  <p>{{ 'PAGE_APPOINTMENT_DETAIL_PLANNED_APPOINTMENT_START' | translate }}</p>
                  <p>{{ 'PAGE_APPOINTMENT_DETAIL_PLANNED_APPOINTMENT_END' | translate }}</p>
                </div>
                <div class="p-col-12 p-md-6 value">
                  <p>{{ appointment?.dateFrom | date : 'short' }}</p>
                  <p>{{ appointment?.dateTo | date : 'short' }}</p>
                </div>
              </div>
            </div>
            <div class="p-col-12 p-md-6 detail-box">
              <div class="p-grid p-ai-stretch">
                <div class="p-col-12 p-md-4 title">
                  <p>{{ 'PAGE_APPOINTMENT_DETAIL_ACTUAL_APPOINTMENT_START' | translate }}</p>
                  <p>{{ 'PAGE_APPOINTMENT_DETAIL_ACTUAL_APPOINTMENT_END' | translate }}</p>
                </div>
                <div class="p-col-12 p-md-8 value">
                  <p *ngIf="appointment?.actualDateFrom">{{ appointment?.actualDateFrom | date : 'short' }}</p>
                  <p *ngIf="!appointment?.actualDateFrom">{{ 'PAGE_APPOINTMENT_DETAIL_NOT_STARTED_YET' | translate }}</p>
                  <p *ngIf="appointment?.actualDateTo">{{ appointment?.actualDateTo | date : 'short' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="adhocAppointment" class="appointment-details">
          <p>{{ 'PAGE_APPOINTMENT_DETAIL_HEADER_YOUR_HOST' | translate }}: {{ (currentAdminUserId | adminUser | async)?.displayName }}</p>
        </div>
        <div class="appointment-actions">
          <div class="p-col-12 p-text-center">
            <button
              *ngIf="!adhocAppointment"
              pButton
              type="button"
              [disabled]="saving"
              icon="pi pi-users"
              class="p-button-lg join-external-appointment-button"
              [label]="'PAGE_APPOINTMENT_GENERAL_JOIN_EXTERNAL_APPOINTMENT' | translate"
              (click)="joinExternalAppointment()"
            ></button>
            <button
              pButton
              type="button"
              [disabled]="saving"
              class="p-button-lg join-showroom-button"
              icon="pi pi-desktop"
              [label]="'PAGE_APPOINTMENT_DETAIL_ACTION_JOIN_DIGITAL_SHOWROOM' | translate"
              (click)="joinDigitalShowroom()"
            ></button>
            <button
              *ngIf="!adhocAppointment && appointment?.actualDateFrom && !appointment?.actualDateTo"
              [disabled]="saving"
              pButton
              type="button"
              icon="pi pi-clock"
              class="p-button-lg stop-button"
              [label]="'PAGE_APPOINTMENT_DETAIL_ACTION_STOP_APPOINTMENT' | translate"
              (click)="stopAppointment()"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <div class="showroom-container" [class.show]="showShowroom">
      <div class="showroom-hide">
        <button pRipple type="button" (click)="showShowroom = false" [class.hide]="!showShowroom">
          <i class="pi pi-angle-double-down"></i>
        </button>
      </div>
      <div *ngIf="showroomStarted" id="furioos_container"></div>
    </div>
  </div>
</div>

<p-overlayPanel #op>
  <ng-template pTemplate>
    <div><i class="pi pi-exclamation-triangle p-mb-4"></i>{{ 'PAGE_APPOINTMENT_DETAIL_CONFIRM_BACK_TO_DASHBOARD' | translate }}</div>
    <button type="button" pButton class="p-mr-2" icon="pi pi-times" label="Cancel" (click)="op.hide()"></button>
    <button type="button" pButton class="p-mr-2" icon="pi pi-check" label="Proceed" (click)="closeDialog($event, false)"></button>
    <button *ngIf="!adhocAppointment && !appointment.actualDateTo" type="button" pButton icon="pi pi-check" label="Stop Appointment and proceed" (click)="closeDialog($event, true)"></button>
  </ng-template>
</p-overlayPanel>

<p-dialog
  [header]="'PAGE_APPOINTMENT_DETAIL_RELATED_CONTENT' | translate"
  [(visible)]="displayPosition"
  [position]="position"
  [modal]="true"
  [style]="{ width: '20vw', height: '100vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <div class="external-links">
    <h2 *ngIf="eventKeytopics.length > 0">Event Keytopics</h2>
    <p *ngFor="let ek of eventKeytopics">
      <i class="pi pi-external-link externalLink"
        >&nbsp;<a [href]="ek.href" target="_blank">{{ ek.title }}</a></i
      >
    </p>

    <h2 *ngIf="helpfulLinks.length > 0">Helpful Links</h2>
    <p *ngFor="let hl of helpfulLinks">
      <i class="pi pi-external-link externalLink"
        >&nbsp;<a [href]="hl.href" target="_blank">{{ hl.title }}</a></i
      >
    </p>
  </div>
</p-dialog>
