import { Injectable } from '@angular/core';
import { SidebarElement } from 'src/app/components/sidebar-element/sidebar-element.component';
import { SidebarBodyComponent } from 'src/app/components/sidebar-body/sidebar-body.component';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private currentSidebarBody: SidebarBodyComponent | null = null;
  elements: SidebarElement[] = [];

  constructor() {}

  setSidebarBody(body: SidebarBodyComponent | null) {
    if (!this.currentSidebarBody && body) {
      for (const element of this.elements) {
        body.add(element);
      }
      this.elements = [];
    }

    this.currentSidebarBody = body;
  }

  hasSidebarBody() {
    return !!this.currentSidebarBody;
  }

  add(sideMenuElement: SidebarElement) {
    if (this.currentSidebarBody) {
      this.currentSidebarBody.add(sideMenuElement);
    } else {
      this.elements.push(sideMenuElement);
    }
  }

  remove(sideMenuElement?: SidebarElement) {
    const index = this.elements.indexOf(sideMenuElement);

    if (index >= 0) {
      this.elements.splice(index, 1);
    }

    this.currentSidebarBody?.remove(sideMenuElement);
  }
}
