import { PageModule, PageModuleType } from './../../entities/PageModule';
import { BookmarksPageModuleFactory } from './BookmarksPageModuleFactory';
import { TextListPageModuleFactory } from './TextListPageModuleFactory';
import { VideoSliderPageModuleFactory } from './VideoSliderPageModuleFactory';
import { FactoryOptions } from '../AbstractFactory';
import { AgendaPageModuleFactory } from './AgendaPageModuleFactory';
import { BannerPageModuleFactory } from './BannerPageModuleFactory';
import { AccordionPageModuleFactory } from './AccordionPageModuleFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';
import { DocumentCollectionPageModuleFactory } from './DocumentCollectionPageModuleFactory';
import { FormPageModuleFactory } from './FormPageModuleFactory';
import { ImageBannerPageModuleFactory } from './ImageBannerPageModuleFactory';
import { ImagePageModuleFactory } from './ImagePageModuleFactory';
import { ListImagePageModuleFactory } from './ListImagePageModuleFactory';
import { ListPageModuleFactory } from './ListPageModuleFactory';
import { ListVideoPageModuleFactory } from './ListVideoPageModuleFactory';
import { MultiEventStagePageModuleFactory } from './MultiEventStagePageModuleFactory';
import { ProductListPageModuleFactory } from './ProductListPageModuleFactory';
import { SpeakerListPageModuleFactory } from './SpeakerListPageModuleFactory';
import { Stage2MultiEventStagePageModuleFactory } from './Stage2MultiEventStagePageModuleFactory';
import { Stage2StreamEventStagePageModuleFactory } from './Stage2StreamEventStagePageModuleFactory';
import { Stage2TeaserEventStagePageModuleFactory } from './Stage2TeaserEventStagePageModuleFactory';
import { Stage2TextEventStagePageModuleFactory } from './Stage2TextEventStagePageModuleFactory';
import { Stage2TitleEventStagePageModuleFactory } from './Stage2TitleEventStagePageModuleFactory';
import { StreamEventStagePageModuleFactory } from './StreamEventStagePageModuleFactory';
import { TeaserEventStagePageModuleFactory } from './TeaserEventStagePageModuleFactory';
import { TextEventStagePageModuleFactory } from './TextEventStagePageModuleFactory';
import { TextImagePageModuleFactory } from './TextImagePageModuleFactory';
import { TilesPageModuleFactory } from './TilesPageModuleFactory';
import { TitleEventStagePageModuleFactory } from './TitleEventStagePageModuleFactory';
import { UserProfileStagePageModuleFactory } from './UserProfileStagePageModuleFactory';
import { VideoTeaserCollectionPageModuleFactory } from './VideoTeaserCollectionPageModuleFactory';
import { TextPageModuleFactory } from './TextPageModuleFactory';
import { Text3DImagePageModuleFactory } from './Text3DImagePageModuleFactory';
import { ShowroomPageModuleFactory } from './ShowroomPageModuleFactory';
import { ImageTagTextLinkPageModuleFactory } from './ImageTagTextLinkPageModuleFactory';
import { IconGroupPageModuleFactory } from './IconGroupPageModuleFactory';
import { GridPageModuleFactory } from './GridPageModuleFactory';
import { FilterPageModuleFactory } from './FilterPageModuleFactory';
import { EventsListPageModuleFactory } from './EventsListPageModuleFactory';
import { EventKeytopicsIconGroupPageModuleFactory } from './EventKeytopicsIconGroupPageModuleFactory';
import { ConversionStagePageModuleFactory } from './ConversionStagePageModuleFactory';
import { SlideshowStagePageModuleFactory } from './SlideshowStagePageModuleFactory';
import { TrendingTopicsPageModuleFactory } from './TrendingTopicsPageModuleFactory';
import { SpacerPageModuleFactory } from './SpacerPageModuleFactory';
import { ProductList2PageModuleFactory } from './ProductList2PageModuleFactory';
import { LoginPageModuleFactory } from './LoginPageModuleFactory';
import { ExploreStagePageModuleFactory } from './ExploreStagePageModuleFactory';
import { CustomPageModuleFactory } from './CustomPageModuleFactory';
import { NewsfeedEntryPageModuleFactory } from './NewsfeedEntryPageModuleFactory';

export class PageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async createPageModule(pageModule: { _id?: string; pageModuleType: PageModuleType; eventId?: string }): Promise<PageModule> {
    switch (pageModule.pageModuleType) {
      case 'Accordion':
        return { ...pageModule, ...(await new AccordionPageModuleFactory(this.options).accordionPageModule(pageModule)) }; //
      case 'Agenda':
        return { ...pageModule, ...(await new AgendaPageModuleFactory(this.options).agendaPageModule(pageModule)) }; //
      case 'Banner':
        return { ...pageModule, ...(await new BannerPageModuleFactory(this.options).bannerPageModule(pageModule)) }; //
      case 'Bookmarks':
        return { ...pageModule, ...(await new BookmarksPageModuleFactory(this.options).bookmarksPageModule(pageModule)) }; //
      case 'DocumentCollection':
        return { ...pageModule, ...(await new DocumentCollectionPageModuleFactory(this.options).documentCollectionPageModule(pageModule)) }; //
      case 'EventKeytopicsIconGroup':
        return { ...pageModule, ...(await new EventKeytopicsIconGroupPageModuleFactory(this.options).eventKeytopicsIconGroupPageModule(pageModule)) }; //
      case 'EventsList':
        return { ...pageModule, ...(await new EventsListPageModuleFactory(this.options).eventsListPageModule(pageModule)) }; //
      case 'ExploreStage':
        return { ...pageModule, ...(await new ExploreStagePageModuleFactory(this.options).exploreStagePageModule(pageModule)) }; //
      case 'Filter':
        return { ...pageModule, ...(await new FilterPageModuleFactory(this.options).filterPageModule(pageModule)) }; //
      case 'Form':
        return { ...pageModule, ...(await new FormPageModuleFactory(this.options).formPageModule(pageModule)) }; //
      case 'Grid':
        return { ...pageModule, ...(await new GridPageModuleFactory(this.options).gridPageModule(pageModule)) }; //
      case 'IconGroup':
        return { ...pageModule, ...(await new IconGroupPageModuleFactory(this.options).iconGroupPageModule(pageModule)) }; //
      case 'ListImage':
        return { ...pageModule, ...(await new ListImagePageModuleFactory(this.options).listImagePageModule(pageModule)) }; //
      case 'ListVideo':
        return { ...pageModule, ...(await new ListVideoPageModuleFactory(this.options).listVideoPageModule(pageModule)) }; //
      case 'ImageBanner':
        return { ...pageModule, ...(await new ImageBannerPageModuleFactory(this.options).imageBannerPageModule(pageModule)) }; //
      case 'ImageTagTextLink':
        return { ...pageModule, ...(await new ImageTagTextLinkPageModuleFactory(this.options).imageTagTextLinkPageModule(pageModule)) }; //
      case 'Image':
        return { ...pageModule, ...(await new ImagePageModuleFactory(this.options).imagePageModule(pageModule)) }; //
      case 'List':
        return { ...pageModule, ...(await new ListPageModuleFactory(this.options).listPageModule(pageModule)) }; //
      case 'Showroom':
        return { ...pageModule, ...(await new ShowroomPageModuleFactory(this.options).showroomPageModule(pageModule)) }; //
      case 'SpeakerList':
        return { ...pageModule, ...(await new SpeakerListPageModuleFactory(this.options).speakerListPageModule(pageModule)) }; //
      case 'Text3DImage':
        return { ...pageModule, ...(await new Text3DImagePageModuleFactory(this.options).text3DImagePageModule(pageModule)) };
      case 'TextList':
        return { ...pageModule, ...(await new TextListPageModuleFactory(this.options).textListPageModule(pageModule)) }; //
      case 'TextImage':
        return { ...pageModule, ...(await new TextImagePageModuleFactory(this.options).textImagePageModule(pageModule)) }; //
      case 'Text':
        return { ...pageModule, ...(await new TextPageModuleFactory(this.options).textPageModule(pageModule)) }; //
      case 'Login':
        return { ...pageModule, ...(await new LoginPageModuleFactory(this.options).loginPageModule(pageModule)) }; //
      case 'Tiles':
        return { ...pageModule, ...(await new TilesPageModuleFactory(this.options).tilesPageModule(pageModule)) }; //
      case 'VideoSlider':
        return { ...pageModule, ...(await new VideoSliderPageModuleFactory(this.options).videoSliderPageModule(pageModule)) }; //
      case 'VideoTeaserCollection':
        return { ...pageModule, ...(await new VideoTeaserCollectionPageModuleFactory(this.options).videoTeaserCollectionPageModule(pageModule)) }; //
      case 'ProductList':
        return { ...pageModule, ...(await new ProductListPageModuleFactory(this.options).productListPageModule(pageModule)) }; //
      case 'ProductList2':
        return { ...pageModule, ...(await new ProductList2PageModuleFactory(this.options).productList2PageModule(pageModule)) }; //
      case 'Custom':
        return { ...pageModule, ...(await new CustomPageModuleFactory(this.options).customPageModule(pageModule)) }; //
      case 'NewsfeedEntry':
        return { ...pageModule, ...(await new NewsfeedEntryPageModuleFactory(this.options).newsfeedEntryPageModule(pageModule)) }; //

      case 'MultiEventStage':
        return { ...pageModule, ...(await new MultiEventStagePageModuleFactory(this.options).multiEventStagePageModule(pageModule)) }; //
      case 'StreamEventStage':
        return { ...pageModule, ...(await new StreamEventStagePageModuleFactory(this.options).streamEventStagePageModule(pageModule)) }; //
      case 'TeaserEventStage':
        return { ...pageModule, ...(await new TeaserEventStagePageModuleFactory(this.options).teaserEventStagePageModule(pageModule)) }; //
      case 'TextEventStage':
        return { ...pageModule, ...(await new TextEventStagePageModuleFactory(this.options).textEventStagePageModule(pageModule)) }; //
      case 'TitleEventStage':
        return { ...pageModule, ...(await new TitleEventStagePageModuleFactory(this.options).titleEventStagePageModule(pageModule)) }; //
      case 'UserProfileStage':
        return { ...pageModule, ...(await new UserProfileStagePageModuleFactory(this.options).userProfileStagePageModule(pageModule)) }; //
      case 'ConversionStage':
        return { ...pageModule, ...(await new ConversionStagePageModuleFactory(this.options).conversionStagePageModule(pageModule)) }; //
      case 'SlideshowStage':
        return { ...pageModule, ...(await new SlideshowStagePageModuleFactory(this.options).slideshowStagePageModule(pageModule)) }; //
      case 'TrendingTopics':
        return { ...pageModule, ...(await new TrendingTopicsPageModuleFactory(this.options).trendingTopicsPageModule(pageModule)) }; //
      case 'Spacer':
        return { ...pageModule, ...(await new SpacerPageModuleFactory(this.options).spacerPageModule(pageModule)) }; //

      case 'TextEventStage2':
        return { ...pageModule, ...(await new Stage2TextEventStagePageModuleFactory(this.options).textEventStage2PageModule(pageModule)) }; //
      case 'StreamEventStage2':
        return { ...pageModule, ...(await new Stage2StreamEventStagePageModuleFactory(this.options).streamEventStage2PageModule(pageModule)) }; //
      case 'TeaserEventStage2':
        return { ...pageModule, ...(await new Stage2TeaserEventStagePageModuleFactory(this.options).teaserEventStage2PageModule(pageModule)) }; //
      case 'TitleEventStage2':
        return { ...pageModule, ...(await new Stage2TitleEventStagePageModuleFactory(this.options).titleEventStage2PageModule(pageModule)) }; //
      case 'MultiEventStage2':
        return { ...pageModule, ...(await new Stage2MultiEventStagePageModuleFactory(this.options).multiEventStage2PageModule(pageModule)) }; //
    }
  }
}
