import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from 'src/app/services/i18n/i18n.service';

@Pipe({
  name: 'country',
})
export class CountryPipe implements PipeTransform {
  constructor(private i18nService: I18nService) {}

  transform(countryTag: string, ...args: unknown[]): string {
    if (!countryTag) return null;

    const country = this.i18nService.translateCountry(countryTag);
    if (country) {
      return country;
    } else {
      return countryTag;
    }
  }
}
