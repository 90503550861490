import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { VideoTeaserCollectionPageModuleFactory } from './../../factories/pagemodules/VideoTeaserCollectionPageModuleFactory';
import { EventVersion } from '../../entities/EventVersion';
import { isPage, isPageWithPageModules, Page } from '../../entities/Page';
import { isVideoTeaserCollectionPageModule, titlePositions, VideoTeaserCollectionDisplayTypes, VideoTeaserCollectionPageModule, VideoTeaserCollectionTypes } from '../../entities/PageModule';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const VideoTeaserCollectionPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('VideoTeaserCollection'),
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].ctaButton": {
    type: 'ctabutton',
    options: {
      additionalTypes: [{ label: 'Query Link', value: 'querylink' }],
    },
  },

  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].discoverMoreBackgroundImage": {
    type: 'imageasset',
    mimeTypes: ['image/png', 'image/jpg'],
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].discoverMoreIcon": {
    type: 'imageasset',
    mimeTypes: ['image/svg'],
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].discoverMoreCta": {
    type: 'ctabutton',
    options: {
      additionalTypes: [{ label: 'Query Link', value: 'querylink' }],
    },
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].showProgressBar": {
    type: 'switch',
    header: 'Show Watch Progress Bar',
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].showVideoStatusFlag": {
    type: 'switch',
    header: 'Show Video Status Flag',
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].showAssetTags": {
    type: 'switch',
    header: 'Show Asset Tags',
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].showLanguageTags": {
    type: 'switch',
    header: 'Show Language Tags',
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].VideoTeaserCollectionType": {
    type: 'dropdown',
    dropdownOptions: VideoTeaserCollectionTypes.map((type) => {
      return { value: type, label: type };
    }),
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].event": {
    type: 'event',
    header: 'Event for keytopic sessions (optional)',
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return parent?.VideoTeaserCollectionType === 'Keytopic';
    },
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].maxCount": {
    type: 'number',
    header: 'Video max count',
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return parent?.VideoTeaserCollectionType !== 'Default';
    },
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new VideoTeaserCollectionPageModuleFactory().createVideoTeaserCollectionPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].local.$language.videos": {
    factory: async () => [],
    list: true,
    childFactory: async () => null,
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      let page: Page | null | undefined = null;

      if (isPage(obj)) {
        page = obj;
      } else {
        const eventVersion = obj as EventVersion;
        page = eventVersion.phase[jsonpathParams.eventPhase].pages.find((p) => p._id === jsonpathParams.pageId);
      }

      if (isPage(page)) {
        if (isPageWithPageModules(page)) {
          const pageModule = page.pageModules.find((p) => p._id === jsonpathParams.pageModuleId);

          if (isVideoTeaserCollectionPageModule(pageModule)) {
            return pageModule.VideoTeaserCollectionType === 'Default';
          }
        }
      }

      return false;
    },
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].local.$language.videos[$index]": {
    type: 'videoasset',
    header: 'Video',
  },

  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].local.$language.discoverMoreTitle": {
    type: 'text',
    header: 'Discover More Title',
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].local.$language.loadMoreButtonLabel": {
    type: 'text',
    header: 'Load More Button Label',
  },

  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].showInProgressVideos": {
    type: 'switch',
    header: 'Show in progress videos',
  },

  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].showViewedVideos": {
    type: 'switch',
    header: 'Show viewed videos',
  },

  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].showNewVideos": {
    type: 'switch',
    header: 'Show new videos',
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].showPlayButton": {
    type: 'switch',
    header: 'Show Play Button',
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].showSliderPagination": {
    type: 'switch',
    header: 'Show Slider Pagination',
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return parent?.displayAs === 'slider';
    },
  },

  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].languages": {
    type: 'multiselect',
    header: 'Languages Filter',
    description: 'If none is selected, the module will display all possible languages.',
    placeholder: 'All Languages',
    showToggleAll: true,
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].keytopics": {
    type: 'multiselect',
    header: 'Keytopics',
    placeholder: 'None',
    showToggleAll: true,
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return parent?.VideoTeaserCollectionType === 'Keytopic' || parent?.VideoTeaserCollectionType === 'KeytopicAllEvents';
    },
  },

  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].showResultLabel": {
    type: 'switch',
    header: 'Show Result Label',
  },

  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].teaserAlignment": {
    type: 'dropdown',
    header: 'Teaser Alignment',
    dropdownOptions: [
      { label: 'Centered', value: 'center' },
      { label: 'Left', value: 'left' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [...titlePositions].map((item) => ({
      label: item,
      value: item,
    })),
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].showVideoTitle": {
    type: 'switch',
    header: 'Show Video Title',
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].showEventtypeTags": {
    type: 'switch',
    header: 'Show Eventtype Tags ',
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].showDate": {
    type: 'switch',
    header: 'Show Date',
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].displayAs": {
    type: 'dropdown',
    dropdownOptions: [...VideoTeaserCollectionDisplayTypes].map((a) => ({ label: a, value: a })),
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].teaserLayoutVariation": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Layout 1 - Tags at top', value: 1 },
      { label: 'Layout 2 - Tags at bottom', value: 2 },
      { label: 'Layout 3 - Tags at top with title below teaser', value: 3 },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].pagination": {
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].showDiscoverMoreLast": {
    type: 'switch',
    header: 'Show Discover More as last item',
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].defaultSortingField": {
    header: 'Default Sorting Field',
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Upload Date', value: 'uploadDate' },
      { label: 'Publish Date', value: 'publishDate' },
      { label: 'View Amount', value: 'viewAmount' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].defaultSortingDirection": {
    header: 'Default Sorting Direction',
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Descending', value: '-1' },
      { label: 'Ascending', value: '1' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].sortingOptions[?(@._id=='$elementId')].local.$language.title": {
    header: 'Title',
    type: 'text',
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].sortingOptions[?(@._id=='$elementId')].sortingField": {
    header: 'Sorting Field',
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Upload Date', value: 'uploadDate' },
      { label: 'Publish Date', value: 'publishDate' },
      { label: 'View Amount', value: 'viewAmount' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].sortingOptions[?(@._id=='$elementId')].sortingDirection": {
    header: 'Sorting Direction',
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Descending', value: '-1' },
      { label: 'Ascending', value: '1' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].navigationType": {
    header: 'Navigation',
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Pagination', value: 'pagination' },
      { label: 'Load more', value: 'loadMore' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].loadMoreInitialAmount": {
    type: 'number',
    header: 'Initial Amount',
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].loadMoreAmount": {
    type: 'number',
    header: 'Load More Amount',
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].sortingOptions": {
    factory: async () => [],
    header: 'GUI Sorting Elements',
    list: true,
    listKey: '_id',
    listValue: 'elementId',
    childFactory: async (obj: VideoTeaserCollectionPageModule) => new VideoTeaserCollectionPageModuleFactory({ ensureLocals: obj.languages }).videoTeaserCollectionSortingOption({}),
  },
  "$.pageModules[?(@.pageModuleType=='VideoTeaserCollection' && @._id=='$pageModuleId')].query": {
    type: 'query',
    useTags: true,
    fieldTypes: ['Tag'],
    header: 'Query',
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return parent?.VideoTeaserCollectionType === 'Query';
    },
  },
};
