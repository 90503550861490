<div class="sidebar-footer">
  <div class="actions-left">
    <button
      *ngIf="showOptionalButton"
      pButton
      type="button"
      class="p-button-sm p-button-danger p-mr-2"
      [label]="optionalButtonLabel | translate"
      (click)="confirmOptional($event)"
      [disabled]="disableOptionalButton"
    ></button>
    <button
      *ngIf="showDeleteButton"
      pButton
      type="button"
      class="p-button-sm p-button-danger p-mr-2"
      [label]="'GENERAL_DELETE' | translate"
      icon="pi pi-trash"
      (click)="confirmDelete($event)"
      [disabled]="disableDeleteButton"
    ></button>

    <button
      *ngIf="showSaveButton"
      pButton
      type="button"
      class="p-button-sm"
      [label]="'GENERAL_SAVE' | translate"
      icon="pi pi-check"
      [disabled]="disableSaveButton"
      (click)="onSave.emit(true)"
    ></button>
  </div>

  <div class="info p-mr-2">
    <span *ngIf="footerInfo">{{ footerInfo | translate }}</span>
    <span *ngIf="autoSaveInfo" class="info">{{ 'GENERAL_CHANGES_WILL_BE_SAVED_AUTOMATICALLY' | translate }}</span>
  </div>

  <div class="actions-right">
    <button
      *ngIf="showCancelButton"
      pButton
      type="button"
      class="p-button-sm p-button-secondary p-mr-2"
      [label]="'GENERAL_CANCEL' | translate"
      icon="pi pi-times"
      (click)="onCancel.emit(true)"
    ></button>

    <button *ngIf="showCloseButton" pButton type="button" class="p-button-sm" [label]="'GENERAL_CLOSE' | translate" icon="pi pi-times" (click)="onClose.emit(true)"></button>
  </div>
  <p-confirmPopup [key]="generatedConfirmPopupKey"></p-confirmPopup>
</div>
