import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { titlePositions } from './../../entities/pagemodules/PageModule';
import { TextImagePageModuleFactory } from '../../factories/pagemodules/TextImagePageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const textImagePageModulesInputs: Inputs = {
  ...commonPageModuleInputs('TextImage'),
  "$.pageModules[?(@.pageModuleType=='TextImage' && @._id=='$pageModuleId')].ctaButton": {},

  "$.pageModules[?(@.pageModuleType=='TextImage' && @._id=='$pageModuleId')].imageSize": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Large', value: 'large' },
      { label: 'Medium', value: 'medium' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='TextImage' && @._id=='$pageModuleId')].imagePosition": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Left', value: 'left' },
      { label: 'Right', value: 'right' },
    ],
  },

  "$.pageModules[?(@.pageModuleType=='TextImage' && @._id=='$pageModuleId')].imagePositionWithinImageArea": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Left', value: 'left' },
      { label: 'Right', value: 'right' },
      { label: 'Center', value: 'center' },
    ],
  },

  "$.pageModules[?(@.pageModuleType=='TextImage' && @._id=='$pageModuleId')].imagePositionMobile": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Bottom', value: 'bottom' },
      { label: 'Top', value: 'top' },
    ],
  },

  "$.pageModules[?(@.pageModuleType=='TextImage' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new TextImagePageModuleFactory().textImagePageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='TextImage' && @._id=='$pageModuleId')].local.$language.image": {
    type: 'imageasset',
  },
  "$.pageModules[?(@.pageModuleType=='TextImage' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='TextImage' && @._id=='$pageModuleId')].local.$language.text": {},
  "$.pageModules[?(@.pageModuleType=='TextImage' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [{ label: 'content', value: 'content' }].concat(
      [...titlePositions].map((item) => ({
        label: item,
        value: item,
      }))
    ),
  },
};
