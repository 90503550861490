import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';

import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { Page, PageType, PageTypes } from 'src/common/entities/Page';
import { PagesService } from 'src/app/services/pages/pages.service';
import { TableOptions } from '../table/table.interfaces';
import { GetPagesQuery, PageViewType, PageWithChildPages } from 'src/common/api/v1/configuration/pages/GetPages';
import { DomainCollection } from 'src/common/entities/configuration/DomainConfiguration';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { TranslateService } from '@ngx-translate/core';

type PageStructure = {
  page: Page;
  children: PageStructure[];
};

@Component({
  selector: 'c-page-select',
  templateUrl: './page-select.component.html',
  styleUrls: ['./page-select.component.scss'],
})
export class PageSelectComponent implements OnInit, OnChanges {
  @Input()
  onBlur?: Function;

  @Input()
  onFocus?: Function;

  @Input()
  disabled = false;

  @Input()
  value: string;

  @Input()
  domainCollectionId: string;

  @Input()
  hideRemoveLink: boolean;

  @Input()
  pageType?: PageType;

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  showSidebar: boolean = false;
  searchQuery: { text: string; domainCollectionId: string } = { text: '', domainCollectionId: '' };
  $searchQuery: BehaviorSubject<{ text: string; domainCollectionId: string } | null> = new BehaviorSubject<{ text: string; domainCollectionId: string }>({ text: '', domainCollectionId: '' });

  tableOptions: TableOptions<Page | PageStructure>;

  $pageTreeStructure: Observable<PageWithChildPages[]>;

  selectedPage: Page;

  expanded: { [pageId: string]: boolean } = {};

  domainCollections: DomainCollection[] = [];

  domainCollectionOptions: { label: string; value: string }[] = [];

  selectedDomainCollection: string = null;

  constructor(private pagesService: PagesService, private configurationService: ConfigurationService, private translateService: TranslateService) {}

  ngOnInit() {
    this.domainCollections = this.configurationService.configuration().domain?.domainCollections;
    this.domainCollectionOptions = [{ internalName: this.translateService.instant('GENERAL_ALL'), _id: null }, ...this.domainCollections]
      .map((d) => ({
        label: d.internalName || this.translateService.instant('GENERAL_UNNAMED'),
        value: d._id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    this.tableOptions = {
      size: 50,
      columns: [{ header: '' }],
    };

    this.$pageTreeStructure = this.$searchQuery.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map((searchQuery) => this.getGetPagesQuery(searchQuery.text, this.pageType)),
      switchMap((query) => from(this.pagesService.getPages(this.domainCollectionId ? this.domainCollectionId : this.selectedDomainCollection, query))),
      map(({ items }) => items)
    );
  }

  getChildContentClass(level = 0) {
    if (level === 0) return '';
    return `level-${(level - 1) % 2}`;
  }

  getRowPaddingLeft(pageStructure: PageWithChildPages, level = 0) {
    const defaultPadding = 16;
    if (level === 0) return `${defaultPadding}px`;

    let padding = level * 8;
    if (pageStructure.childPages.length === 0) {
      padding += 26; // to fill empty arrow position
    }

    return `${padding + defaultPadding}px`;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.value && changes.value.currentValue !== changes.value.previousValue) {
      this.setSelectedPage();
    }
  }

  async setSelectedPage() {
    if (this.value) {
      this.selectedPage = await this.pagesService.getPage(this.value);
      this.clearSearch();
    } else {
      this.removePage();
    }
  }

  searchTextKeyUp() {
    this.$searchQuery.next({ ...this.searchQuery });
  }

  clearSearch() {
    this.searchQuery.text = '';
    this.searchTextKeyUp();
  }

  openSidebar() {
    if (!this.disabled) {
      this.showSidebar = true;
    }
  }

  selectPage(pageId) {
    this.valueChange.emit(pageId);
    this.showSidebar = false;
  }

  removePage() {
    this.selectedPage = null;
    this.valueChange.emit(null);
  }

  selectDomainCollection(e) {
    this.clearSearch();
    this.$searchQuery.next({ ...this.searchQuery });
  }

  getGetPagesQuery(search: string, pageType: PageType): GetPagesQuery {
    return search === null
      ? <GetPagesQuery>{
          filter: pageType
            ? {
                pageType: {
                  matchMode: 'equals',
                  value: pageType,
                },
              }
            : {},
          view: PageViewType.TREE,
        }
      : <GetPagesQuery>{
          filter: {
            internalName: {
              caseInsensitive: true,
              matchMode: 'contains',
              value: search,
            },
            pageType: pageType
              ? {
                  matchMode: 'equals',
                  value: pageType,
                }
              : {},
          },
          view: PageViewType.TREE,
        };
  }

  tableItemSelect(data: any) {
    this.selectPage(data);
  }
}
