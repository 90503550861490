import { Session, SessionType } from '../../entities/Session';
import { AbstractFactory, FactoryOptions } from '../AbstractFactory';
import { BreakoutSessionFactory } from './BreakoutSessionFactory';
import { SpeakerSessionFactory } from './SpeakerSessionFactory';

export class SessionFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createSession(values: { sessionType: SessionType; event: string }): Promise<Session> {
    switch (values.sessionType) {
      case 'SpeakerSession':
        return new SpeakerSessionFactory(this.options).createSpeakerSession(values);
      case 'BreakoutSession':
        return new BreakoutSessionFactory(this.options).createBreakoutSession(values);
    }
  }
}
