import { Factory } from '../../factories/Factory';
import { EventTicketFactory } from '../../factories/EventTicketFactory';
import { Inputs } from '../Inputs';

export const eventVersionTicketsInputs: Inputs = {
  '$.eventTicketConfiguration.maxEventTickets': {
    header: 'PAGE_EVENT_SECTION_TICKETS_CONFIGURATION_MAXEVENTTICKETS',
    description: 'PAGE_EVENT_SECTION_TICKETS_CONFIGURATION_MAXEVENTTICKETS_DESCRIPTION',
    type: 'number'
  },
  '$.eventTicketConfiguration.customConfirmationDialog': {
    type: 'switch'
  },
  '$.eventTicketConfiguration.customChangedConfirmationDialog': {
    type: 'switch'
  },
  '$.eventTicketConfiguration.sendTicketConfirmationMail': {
    type: 'switch'
  },
  '$.eventTicketConfiguration.sendCustomTicketConfirmationMail': {
    type: 'switch'
  },
  '$.eventTicketConfiguration.local.$language.customEmailTemplateParts': {
    factory: async () => ({})
  },
  '$.eventTicketConfiguration.local.$language.customEmailTemplateParts.$parts': {},
  '$.eventTicketConfiguration.emailTemplate': {
    header: 'Email Templates',
    type: 'dropdown'
  },

  '$.eventTicketConfiguration.sendChangedTicketConfirmationMail': {
    type: 'switch'
  },
  '$.eventTicketConfiguration.local.$language.customChangedEmailTemplateParts': {
    factory: async () => ({})
  },
  '$.eventTicketConfiguration.local.$language.customChangedEmailTemplateParts.$parts': {},
  '$.eventTicketConfiguration.emailChangedTemplate': {
    header: 'Changed Email Templates',
    type: 'dropdown'
  },

  '$.eventTicketConfiguration.local': {
    factory: async () => ({})
  },
  '$.eventTicketConfiguration.local.$language': {
    factory: async () => new EventTicketFactory().createEventTicketConfigurationLocal({})
  },
  '$.eventTicketConfiguration.local.$language.customConfirmationDialogHeadline': {
    type: 'text'
  },
  '$.eventTicketConfiguration.local.$language.customConfirmationDialogText': {
    type: 'textarea'
  },
  '$.eventTicketConfiguration.local.$language.customChangedConfirmationDialogHeadline': {
    type: 'text'
  },
  '$.eventTicketConfiguration.local.$language.customChangedConfirmationDialogText': {
    type: 'textarea'
  },
  "$.eventTickets[?(@._id=='$ticketId')].internalName": {
    header: 'PAGE_EVENT_SECTION_TICKETS_INTERNALNAME',
    description: 'PAGE_EVENT_SECTION_TICKETS_INTERNALNAME_DESCRIPTION',
    type: 'text'
  },
  "$.eventTickets[?(@._id=='$ticketId')].validFrom": {
    header: 'PAGE_EVENT_SECTION_TICKETS_VALID_FROM',
    type: 'datetime'
  },
  "$.eventTickets[?(@._id=='$ticketId')].validUntil": {
    header: 'PAGE_EVENT_SECTION_TICKETS_VALID_UNTIL',
    type: 'datetime'
  },
  "$.eventTickets[?(@._id=='$ticketId')].digital": {
    header: 'PAGE_EVENT_SECTION_TICKETS_DIGITAL',
    description: 'PAGE_EVENT_SECTION_TICKETS_DIGITAL_DESCRIPTION',
    type: 'switch'
  },
  "$.eventTickets[?(@._id=='$ticketId')].onSite": {
    header: 'PAGE_EVENT_SECTION_TICKETS_ONSITE',
    description: 'PAGE_EVENT_SECTION_TICKETS_ONSITE_DESCRIPTION',
    type: 'switch'
  },
  "$.eventTickets[?(@._id=='$ticketId')].sendRegistrationToExternalSystem": {
    header: 'Send registration to external system',
    type: 'switch'
  },
  "$.eventTickets[?(@._id=='$ticketId')].showInEventRegistrationProcess": {
    header: 'Show ticket in event registration process',
    description: 'If checked, users can select this ticket in the ticket registration process in the frontend.',
    type: 'switch'
  },
  "$.eventTickets[?(@._id=='$ticketId')].showQRCode": {
    header: 'Show QR Code',
    description: 'A QR Code is displayed containing data to identify the user. If a QR Code is displayed, no Description is displayed.',
    type: 'switch'
  },
  "$.eventTickets[?(@._id=='$ticketId')].showUserInfo": {
    header: 'Show User Info',
    description: 'Full name and organization of the user are displayed.',
    type: 'switch'
  },
  "$.eventTickets[?(@._id=='$ticketId')].showDates": {
    header: 'Show Dates',
    description: 'Show Dates in Wallet.',
    type: 'switch'
  },
  "$.eventTickets[?(@._id=='$ticketId')].logo": {
    type: 'imageasset'
  },
  "$.eventTickets[?(@._id=='$ticketId')].maxEventSlots": {
    header: 'Max Ticket Slots',
    description: 'How many days can a user select at an event registration'
  },
  "$.eventTickets[?(@._id=='$ticketId')].maxAttendees": {
    header: 'Max Attendees'
  },
  "$.eventTickets[?(@._id=='$ticketId')].icon": {
    header: 'PAGE_EVENT_SECTION_TICKETS_ICON',
    description: 'PAGE_EVENT_SECTION_TICKETS_ICON_DESCRIPTION',
    type: 'imageasset'
  },
  "$.eventTickets[?(@._id=='$ticketId')].useWalletAdvantages": {
    header: 'PAGE_EVENT_SECTION_TICKETS_USE_WALLET_ADVANTAGES',
    description: 'PAGE_EVENT_SECTION_TICKETS_USE_WALLET_ADVANTAGES_DESCRIPTION',
    type: 'switch'
  },

  "$.eventTickets[?(@._id=='$ticketId')].local.$language": {
    factory: async () => Factory.eventTicket().createEventTicketLocal({})
  },
  "$.eventTickets[?(@._id=='$ticketId')].local.$language.title": {
    header: 'PAGE_EVENT_SECTION_TICKETS_TITLE',
    description: 'PAGE_EVENT_SECTION_TICKETS_TITLE_DESCRIPTION',
    type: 'text'
  },
  "$.eventTickets[?(@._id=='$ticketId')].local.$language.walletTitle": {
    header: 'PAGE_EVENT_SECTION_TICKETS_WALLET_TITLE',
    description: 'PAGE_EVENT_SECTION_TICKETS_WALLET_TITLE_DESCRIPTION',
    type: 'text'
  },
  "$.eventTickets[?(@._id=='$ticketId')].local.$language.description": {
    header: 'PAGE_EVENT_SECTION_TICKETS_DESCRIPTION',
    description: 'PAGE_EVENT_SECTION_TICKETS_DESCRIPTION_DESCRIPTION',
    type: 'textarea'
  },
  "$.eventTickets[?(@._id=='$ticketId')].local.$language.advantages": {
    factory: async () => [],
    list: true,
    childFactory: async () => ''
  },
  "$.eventTickets[?(@._id=='$ticketId')].local.$language.advantages[$index]": {
    header: 'PAGE_EVENT_SECTION_TICKETS_WALLET_ADVANTAGE',
    description: 'PAGE_EVENT_SECTION_TICKETS_ADVANTAGE_DESCRIPTION',
    type: 'text'
  },
  "$.eventTickets[?(@._id=='$ticketId')].local.$language.walletAdvantages": {
    factory: async () => [],
    list: true,
    childFactory: async () => ''
  },
  "$.eventTickets[?(@._id=='$ticketId')].local.$language.walletAdvantages[$index]": {
    header: 'PAGE_EVENT_SECTION_TICKETS__WALLET_ADVANTAGE',
    description: 'PAGE_EVENT_SECTION_TICKETS_WALLET_ADVANTAGE_DESCRIPTION',
    type: 'text'
  },
  "$.eventTickets[?(@._id=='$ticketId')].deletedAt": {
    factory: async () => ''
  },

  "$.eventTickets[?(@._id=='$ticketId')].readAccess": {
    header: 'Access',
    type: 'accesspolicy',
    allowedAccessPolicyTypes: ['Public', 'VIPTicket']
  },

  "$.eventTickets[?(@._id=='$ticketId')].codes": {
    factory: async () => [],
    list: true,
    listKey: '_id',
    listValue: 'codeId',
    childFactory: async () => Factory.eventTicket().createEventTicketCode()
  },
  "$.eventTickets[?(@._id=='$ticketId')].codes[?(@._id=='$codeId')].code": {
    type: 'text'
  },
  "$.eventTickets[?(@._id=='$ticketId')].codes[?(@._id=='$codeId')].startAt": {
    type: 'datetime'
  },
  "$.eventTickets[?(@._id=='$ticketId')].codes[?(@._id=='$codeId')].endAt": {
    type: 'datetime'
  },
  "$.eventTickets[?(@._id=='$ticketId')].codes[?(@._id=='$codeId')].enabled": {
    type: 'switch'
  }
};
