import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilsService } from './utils.service';

@Injectable({ providedIn: 'root' })
export class ListeningQueryParamService {
  constructor(private activatedRoute: ActivatedRoute, private utilsService: UtilsService) {}

  listeningToQueryParam(queryParamName: string, translationKey?: string, paramGenerate?: (queryParamValue) => any, activatedRoute = this.activatedRoute): Observable<string> {
    const that = this;
    return new Observable((subscriber) => {
      activatedRoute.queryParams.subscribe({
        async next(params): Promise<void> {
          if (params[queryParamName]) {
            if (translationKey) {
              subscriber.next(await that.utilsService.translate(translationKey, paramGenerate(params[queryParamName])));
            } else {
              subscriber.next(params[queryParamName]);
            }
          } else {
            subscriber.next('');
          }
        },
        error(error): void {
          subscriber.error(error);
        },
        complete(): void {
          subscriber.complete();
        },
      });
    });
  }
}
