import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { PageType, PageTypes } from 'src/common/entities/Page';

interface PageItem {
  type: PageType;
  label: string;
  image: string;
  description: string;
}

@Component({
  selector: 'c-page-type-select',
  templateUrl: './page-type-select.component.html',
  styleUrls: ['./page-type-select.component.scss'],
})
export class PageTypeSelectComponent implements OnInit {
  @Output()
  selected: EventEmitter<PageType> = new EventEmitter<PageType>();

  @Output()
  onClose = new EventEmitter<boolean>();

  pages: PageItem[] = [];
  filteredPages: PageItem[] = [];
  filterText = '';

  constructor(private utilsService: UtilsService) {}

  async label(pageType: PageType) {
    const key = `GENERAL_PAGETYPE_${pageType.toUpperCase()}`;
    const translation = await this.utilsService.translate(key);
    return key === translation ? pageType : translation;
  }

  async description(pageType: PageType) {
    const key = `GENERAL_PAGETYPE_${pageType.toUpperCase()}_DESCRIPTION`;
    const translation = await this.utilsService.translate(key);
    return key === translation ? '' : translation;
  }

  async ngOnInit() {
    // TODO Product Backlog Item 77038: Adminportal - Hide 'Standard Page' and Page module 'Video Slider'
    const labels = await Promise.all(
      Object.values(PageTypes)
        .filter((p) => p !== 'StandardPage')
        .map((p) => this.label(p))
    );
    const descriptions = await Promise.all(
      Object.values(PageTypes)
        .filter((p) => p !== 'StandardPage')
        .map((p) => this.description(p))
    );

    this.pages = Object.values(PageTypes)
      .filter((p) => p !== 'StandardPage')
      .map((p, i) => ({
        type: p,
        label: labels[i],
        description: descriptions[i],
        image: `/assets/images/pagemodules/${p.toLowerCase()}_icon.svg`,
      }));

    this.filteredPages = this.pages.filter((p) => true);
  }

  filter() {
    this.filteredPages = this.pages.filter((p) => {
      return p.label.toLowerCase().includes(this.filterText.toLowerCase()) || p.description.toLowerCase().includes(this.filterText.toLowerCase());
    });
  }

  async select(pageType: PageType) {
    this.selected.emit(pageType);
  }
}
