<div>
  <div class="tab-header">
    <h1>{{ 'GENERAL_EMBEDDED_PAGES' | translate }}</h1>
  </div>
  <div class="tab-menu">
    <c-domain-button
      *hasRight="'configuration.edit'"
      [configKey]="'pages'"
      [domainCollectionId]="($domainCollectionId | async) ?? ''"
      (onDomainCollectionIdChange)="loadDialog($event)"
    ></c-domain-button>
    <c-domain-button
      *hasNoRight="'configuration.edit'"
      [enableUnification]="false"
      [configKey]="'pages'"
      [domainCollectionId]="($domainCollectionId | async) ?? ''"
      (onDomainCollectionIdChange)="loadDialog($event)"
    ></c-domain-button>
  </div>

  <div class="panel" *skeleton="{ show: tab.loading, type: 'table' }">
    <div class="panel-header">
      {{ 'GENERAL_EMBEDDED_PAGES' | translate }}
      <div class="actions">
        <c-table-options (filterListChange)="filterChange($event)" #toc></c-table-options>
        <p-button *hasRight="'pages.create'" [label]="'GENERAL_EMBEDDED_PAGES_CREATE' | translate" icon="pi icon-ic-add-item" (onClick)="openNewEmbeddedPageModal()"></p-button>
      </div>
    </div>
    <div class="search">
      <input [value]="$searchText | async" placeholder="Search content ..." pInputText (input)="handleInput($event)" />
    </div>
    <ng-container *ngIf="$pages | async as pages">
      <c-table mode="static" [singleTemplate]="true" [options]="tableOptions" [tableOptionsComponent]="toc" (clearFilterList)="filterChange($event)" [data]="pages">
        <ng-template let-page let-level="level" #row>
          <tr>
            <td [routerLink]="[page._id]" [queryParams]="{ domainCollectionId: ($domainCollectionId | async) ?? '' }" class="active-link">
              <div *hasRight="['pages.read', 'pages.edit']">{{ page.internalName || 'GENERAL_UNNAMED' | translate }}</div>
              <span *hasNoRight="['pages.read', 'pages.edit']">{{ page.internalName || 'GENERAL_UNNAMED' | translate }}</span>
            </td>
          </tr>
        </ng-template>
      </c-table>
    </ng-container>
  </div>
</div>

<p-sidebar #modalCreateEmbeddedPagesSideBar class="new-page-modal" [(visible)]="showNewEmbeddedPageModal" styleClass="p-sidebar-st" position="right" [modal]="true" [showCloseIcon]="false">
  <c-sidebar-header [headerTitle]="'GENERAL_EMBEDDED_PAGES_NEW'" (onClose)="newEmbeddedPage = null"></c-sidebar-header>

  <c-sidebar-body>
    <div *ngIf="showNewEmbeddedPageModal">
      <div *ngIf="newEmbeddedPage" class="input-group compact">
        <div class="input">
          <input type="text" pInputText name="internalName" [(ngModel)]="newEmbeddedPage.internalName" />
        </div>
        <div class="title">{{ 'GENERAL_INTERNAL_NAME' | translate }}</div>
        <div class="description">{{ 'PAGE_EMBEDDED_PAGES_INTERNAL_NAME_DESCRIPTION' | translate }}</div>
      </div>

      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button class="p-button" (click)="createPage()">Create</button>
      </div>
    </div>
  </c-sidebar-body>

  <c-sidebar-footer> </c-sidebar-footer>
</p-sidebar>
