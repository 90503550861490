<div class="container">
  <div class="error-message">
    <i class="pi pi-exclamation-circle"></i>
    <div class="title">{{ 'GENERAL_ERROR_NO_PERMISSION_TITLE' | translate }}</div>
    <div class="content">
      {{ 'GENERAL_ERROR_NO_PERMISSION' | translate }}
    </div>
    <div class="actions">
      <a [href]="['/']" class="p-element p-button p-component">{{ 'GENERAL_GO_HOME' | translate }}</a>
    </div>
  </div>
</div>
