import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Pipe({
  name: 'jsonpath',
})
export class JsonpathPipe implements PipeTransform {
  constructor(private utilsService: UtilsService, private activatedRoute: ActivatedRoute) {}

  transform(value: string, ...args: any[]): Observable<string> {
    return this.activatedRoute.queryParams.pipe(
      map((q) => {
        return this.utilsService.resolveJsonpath(value, {
          language: q.language || 'en',
          ...(args[0] || {}),
        });
      })
    );
  }
}
