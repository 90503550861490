<div *ngIf="stream" class="stream-details">
  <div class="left-right">
    <div *hasRight="'streams.operate'" class="left">
      <div class="action">
        <div class="action-button">
          <button
            pRipple
            type="button"
            class="p-button-outlined"
            label="Activate"
            pButton
            (click)="activateStream()"
            [disabled]="loading || !streamStatusChangeAllowed(stream.status, 'ACTIVATED')"
          ></button>
        </div>
        <div class="action-description">Creates a new live event in the connected streaming service (e.g. Azure MediaServices)</div>
      </div>
      <div *ngIf="stream.streamDistributor?.streamDistributorType === 'MediaServices'" class="action">
        <div class="action-button">
          <button
            pRipple
            type="button"
            class="p-button-outlined"
            label="Standby"
            pButton
            (click)="standbyStream()"
            [disabled]="loading || !streamStatusChangeAllowed(stream.status, 'STANDBY')"
          ></button>
        </div>
        <div class="action-description">Sets the live event in the connected streaming service into a standby state.</div>
      </div>
      <div class="action">
        <div class="action-button">
          <button pRipple type="button" class="p-button-outlined" label="Start" pButton (click)="startStream()" [disabled]="loading || !streamStatusChangeAllowed(stream.status, 'STARTED')"></button>
        </div>
        <div class="action-description">
          Starts the live event in the connected streaming service.
          <div class="warning">Warning: This might create additional cost!</div>
        </div>
      </div>
      <div class="action">
        <div class="action-button">
          <button
            pRipple
            type="button"
            class="p-button-outlined"
            label="Stop"
            pButton
            (click)="stopStream($event)"
            [disabled]="loading || !streamStatusChangeAllowed(stream.status, 'STOPPED')"
          ></button>
        </div>
        <div class="action-description">
          Stops the live event in the connected streaming service.
          <div class="warning">Warning: This might create new streaming URLs if you want to start the stream later again. Consider using Standby instead!</div>
        </div>
      </div>
      <div class="action">
        <div class="action-button">
          <button
            pRipple
            type="button"
            class="p-button-outlined"
            label="Deactivate"
            pButton
            (click)="deactivateStream()"
            [disabled]="loading || !streamStatusChangeAllowed(stream.status, 'DEACTIVATED')"
          ></button>
        </div>
        <div class="action-description">Deletes the live event in the connected streaming service.</div>
      </div>
      <div *hasRight="'streams.delete'" class="action">
        <div class="action-button">
          <button pRipple type="button" class="p-button-outlined" label="Delete" pButton (click)="deleteStream()" [disabled]="loading || !streamStatusChangeAllowed(stream.status, 'DELETED')"></button>
        </div>
        <div class="action-description">Deletes the stream. This should be done after each event. Do NOT reuse existing streams!</div>
      </div>
    </div>
    <div class="right">
      <c-field header="Internal Name" layout="vertical">
        <input pInputText class="internal-name" [(ngModel)]="stream.internalName" (blur)="internalNameBlur()" [disabled]="loading" />
      </c-field>

      <c-stream-status [status]="stream.status"></c-stream-status>

      <div class="distributor">Connected streaming Service: {{ stream.streamDistributor?.streamDistributorType }}</div>

      <div class="urls">
        <h2>Ingest URLs</h2>
        <div *ngIf="!stream.ingestUrls || stream.ingestUrls.length === 0">No URL found</div>
        <div *ngFor="let ingestUrl of stream.ingestUrls">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">{{ ingestUrl.protocol }}</span>
            <input type="text" pInputText [value]="ingestUrl.url" />
            <button type="button" pButton pRipple icon="pi pi-copy" (click)="copyToClipboard(ingestUrl.url)"></button>
          </div>
          <div *ngIf="ingestUrl.streamKey" class="p-inputgroup">
            <span class="p-inputgroup-addon">Stream key</span>
            <input type="text" pInputText [value]="ingestUrl.streamKey" />
            <button type="button" pButton pRipple icon="pi pi-copy" (click)="copyToClipboard(ingestUrl.streamKey)"></button>
          </div>
        </div>
      </div>
      <div class="urls">
        <h2>Streaming URLs</h2>
        <div *ngIf="!stream.streamingUrls || stream.streamingUrls.length === 0">No URL found</div>
        <div *ngFor="let streamingUrl of stream.streamingUrls">
          <div class="p-inputgroup">
            <input type="text" pInputText [value]="streamingUrl" />
            <button type="button" pButton pRipple icon="pi pi-copy" (click)="copyToClipboard(streamingUrl)"></button>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button *hasRight="'streams.edit'" pButton label="Refresh" (click)="refreshStream()" [disabled]="loading"></button>
      </div>
    </div>
  </div>
</div>

<p-confirmPopup></p-confirmPopup>
