import { AppFunction, AppFunctionLocal } from '../entities/AppFunction';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';

export class AppFunctionFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  createAppFunction(values?: any) {
    return {
      ...values,
      action: values?.action || 'openQRScan',
      local: this.localKeys(values?.local).reduce((a, b) => ({ ...a, [b]: this.createAppFunctionLocal(values?.local?.[b]) }), {}),
    } as AppFunction;
  }

  createAppFunctionLocal(values?: any) {
    return {
      ...values,
      actionData: values?.actionData || '',
    } as AppFunctionLocal;
  }
}
