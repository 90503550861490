import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuModule } from 'primeng/menu';

import { ConfigurationComponent } from './configuration.component';
import { HasRightGuard } from 'src/app/guards/has-right.guard';

const routes: Routes = [
  {
    path: '',
    component: ConfigurationComponent,
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$configuration.list', '*$configuration.read', '*$pages.list'],
    },
    children: [
      //TODO: Delete Pages later
      {
        path: 'pages',
        data: {
          hasRight: ['*$pages.list'],
        },
        redirectTo: '/pages',
      },
      {
        path: 'embedded-pages',
        data: {
          hasRight: ['*$pages.list'],
        },
        redirectTo: '/embedded-pages',
      },
      {
        path: 'variables/:variableId',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$configuration.read', '*$configuration.edit'],
        },
        loadChildren: () => import('../variable/variable.module').then((m) => m.VariableModule),
      },
      {
        path: 'types/:eventTypeId',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$configuration.read', '*$configuration.edit'],
        },
        loadChildren: () => import('../event-type-configuration/event-type-configuration.module').then((m) => m.EventTypeConfigurationModule),
      },
      {
        path: 'blacklistentries/:blacklistEntryId',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$configuration.read', '*$configuration.edit'],
        },
        loadChildren: () => import('../blacklist-entry/blacklist-entry.module').then((m) => m.BlacklistEntryModule),
      },
      {
        path: 'languages/:language',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$configuration.read', '*$configuration.edit'],
        },
        loadChildren: () => import('../language/language.module').then((m) => m.LanguageModule),
      },
      {
        path: 'static-contents/:shortId',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$configuration.read', '*$configuration.edit'],
        },
        loadChildren: () => import('../static-content/static-content.module').then((m) => m.StaticContentModule),
      },
      {
        path: 'i18n/:language',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$configuration.read', '*$configuration.edit'],
        },
        loadChildren: () => import('../i18n/i18n.module').then((m) => m.I18nModule),
      },
      {
        path: 'email-templates/:emailTemplateConfigurationId',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$configuration.read', '*$configuration.edit'],
        },
        loadChildren: () => import('../email-template/email-template.module').then((m) => m.EmailTemplateModule),
      },
      {
        path: 'custom-data',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$configuration.list'],
        },
        loadChildren: () => import('../custom-data/custom-data.module').then((m) => m.CustomDataModule),
      },
      {
        path: 'service-hooks/:serviceHookId',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$configuration.read', '*$configuration.edit'],
        },
        loadChildren: () => import('../service-hook/service-hook.module').then((m) => m.ServiceHookModule),
      },
      {
        path: 'service-hooks',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$configuration.list'],
        },
        loadChildren: () => import('../service-hooks/service-hooks.module').then((m) => m.ServiceHooksModule),
      },
      {
        path: 'admin-users',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$admin'],
        },
        loadChildren: () => import('../admin-users/admin-users.module').then((m) => m.AdminUsersModule),
      },
      {
        path: 'admin-users/:adminUserId',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$admin'],
        },
        loadChildren: () => import('../admin-user/admin-user.module').then((m) => m.AdminUserModule),
      },
      {
        path: 'service-admin-users',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$admin'],
        },
        loadChildren: () => import('../service-admin-users/service-admin-users.module').then((m) => m.ServiceAdminUsersModule),
      },
      {
        path: 'service-admin-users/:adminUserId',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$admin'],
        },
        loadChildren: () => import('../service-admin-user/service-admin-user.module').then((m) => m.ServiceAdminUserModule),
      },
      {
        path: 'groups',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$admin'],
        },
        loadChildren: () => import('../groups/groups.module').then((m) => m.GroupsModule),
      },
      {
        path: 'groups/:groupId',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$admin'],
        },
        loadChildren: () => import('../group/group.module').then((m) => m.GroupModule),
      },
      {
        path: 'roles',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$admin'],
        },
        loadChildren: () => import('../roles/roles.module').then((m) => m.RolesModule),
      },
      {
        path: 'roles/:roleId',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['*$admin'],
        },
        loadChildren: () => import('../role/role.module').then((m) => m.RoleModule),
      },
      { path: 'domains', loadChildren: () => import('../domains-configuration/domains-configuration.module').then((m) => m.DomainsConfigurationModule) },
      { path: 'platform', loadChildren: () => import('../platform-configuration/platform-configuration.module').then((m) => m.PlatformConfigurationModule) },
      { path: 'login', loadChildren: () => import('../login-configuration/login-configuration.module').then((m) => m.LoginConfigurationModule) },
      { path: 'asset', loadChildren: () => import('../asset-configuration/asset-configuration.module').then((m) => m.AssetConfigurationModule) },

      { path: 'themes', loadChildren: () => import('../themes/themes.module').then((m) => m.ThemesModule) },
      { path: 'themes/:configurationId', loadChildren: () => import('../theme/theme.module').then((m) => m.ThemeModule) },
      { path: 'plugins', loadChildren: () => import('../frontend-plugins-configuration/frontend-plugins-configuration.module').then((m) => m.FrontendPluginsConfigurationModule) },
      { path: 'system-plugins', loadChildren: () => import('../system-plugins/system-plugins.module').then((m) => m.SystemPluginsModule) },
      { path: 'variables', loadChildren: () => import('../global-variables/global-variables.module').then((m) => m.GlobalVariablesModule) },
      { path: 'types', loadChildren: () => import('../event-types-configuration/event-types-configuration.module').then((m) => m.EventTypesConfigurationModule) },
      { path: 'templates', loadChildren: () => import('../templates-configuration/templates-configuration.module').then((m) => m.TemplatesConfigurationModule) },

      { path: 'languages', loadChildren: () => import('../languages/languages.module').then((m) => m.LanguagesModule) },
      { path: 'operating-regions', loadChildren: () => import('../operating-regions/operating-regions.module').then((m) => m.OperatingRegionsModule) },
      { path: 'operating-countries', loadChildren: () => import('../operating-countries/operating-countries.module').then((m) => m.OperatingCountriesModule) },
      { path: 'regions', loadChildren: () => import('../regions/regions.module').then((m) => m.RegionsModule) },
      { path: 'regions/:region', loadChildren: () => import('../region/region.module').then((m) => m.RegionModule) },
      { path: 'static-contents', loadChildren: () => import('../static-contents/static-contents.module').then((m) => m.StaticContentsModule) },
      { path: 'email-templates', loadChildren: () => import('../email-templates/email-templates.module').then((m) => m.EmailTemplatesModule) },
      { path: 'blacklistentries', loadChildren: () => import('../blacklist-entries/blacklist-entries.module').then((m) => m.BlacklistEntriesModule) },
      { path: 'contact-configuration', loadChildren: () => import('../contact-configuration/contact-configuration.module').then((m) => m.ContactConfigurationModule) },
      { path: 'lookups', loadChildren: () => import('../lookups/lookups.module').then((m) => m.LookupsModule) },
      { path: 'lookups/:id', loadChildren: () => import('../lookup/lookup.module').then((m) => m.LookupModule) },
      { path: 'custom-fields', loadChildren: () => import('../custom-fields/custom-fields.module').then((m) => m.CustomFieldsModule) },
      { path: 'custom-fields/:id', loadChildren: () => import('../custom-field/custom-field.module').then((m) => m.CustomFieldModule) },
      { path: 'interaction', loadChildren: () => import('../interaction/interaction.module').then((m) => m.InteractionModule) },
    ],
  },
];

@NgModule({
  declarations: [ConfigurationComponent],
  imports: [CommonModule, RouterModule.forChild(routes), MenuModule],
  exports: [RouterModule],
})
export class ConfigurationModule {}
