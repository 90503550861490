import { InputConfiguration, Inputs } from 'src/common/inputs/Inputs';
import { ListVideoPageModule } from './../../../../common/entities/pagemodules/ListVideoPageModule';
import { Page } from 'src/common/entities/Page';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-listvideo-page-module-edit',
  templateUrl: './listvideo-page-module-edit.component.html',
  styleUrls: ['./listvideo-page-module-edit.component.scss'],
})
export class ListvideoPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: ListVideoPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathes: string[] = [
    '$.local.$language.title',
    '$.titlePosition',
    '$.local.$language.itemIcon',
    '$.local.$language.upperText',
    '$.local.$language.lowerText',
    '$.local.$language.video',
    '$.videoSize',
    '$.videoPosition',
    '$.videoPositionMobile',
    '$.ctaButton',
    '$.anchor',
  ];

  inputJsonpathesDesign: string[] = ['$.backgroundTheme', '$.videoAppearanceMobile'];

  tab = 'general';
  inputs: Inputs = {};
  accessInputConfiguration: InputConfiguration;

  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
  }
}
