import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { EventKeytopicsIconGroupPageModuleFactory } from './../../factories/pagemodules/EventKeytopicsIconGroupPageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const eventKeytopicsIconGroupPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('EventKeytopicsIconGroup'),
  "$.pageModules[?(@.pageModuleType=='EventKeytopicsIconGroup' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new EventKeytopicsIconGroupPageModuleFactory().createEventKeytopicsIconGroupPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='EventKeytopicsIconGroup' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
};
