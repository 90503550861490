import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';
import { userProfileTypes } from '../../entities/PageModule';

export const userProfileStagePageModulesInputs: Inputs = {
  ...commonPageModuleInputs('UserProfileStage'),

  "$.pageModules[?(@.pageModuleType=='UserProfileStage' && @._id=='$pageModuleId')].userProfileType": {
    type: 'dropdown',
    dropdownOptions: [...userProfileTypes].map((a) => ({ label: a, value: a })),
  },

  "$.pageModules[?(@.pageModuleType=='UserProfileStage' && @._id=='$pageModuleId')].local.$language.background": {},
};
