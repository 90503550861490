import { EventVersion } from './EventVersion';

export interface Event {
  _id: string;
  internalName: string;
  eventType: string;
  shortId: string;
  public: boolean;
  hideLoginButton?: boolean;
  hideLanguageSelector?: boolean;
  hideBeaconIcon?: boolean;
  showBackButton?: boolean;

  /**
   * @deprecated
   */
  country?: string;

  contactmail?: string[];

  announcedAt?: string | Date | null;
  releasedAt?: string | Date | null;

  promoted: boolean;

  createdAt?: string;
  createdBy?: string;

  updatedAt: string;
  updatedBy: string;
  deletedAt: string;
  deletedBy: string;

  sessions: {
    session: string;
    actualStartAt: string;
    actualEndAt: string;
  }[];

  // calculated fields
  totalStartAt?: string | Date | null; // earliest regional Agenda start date if set, else earliest regional event start date
  totalEndAt?: string | Date | null; // latest regional Agenda start date if set, else latest regional event start date

  sort?: {
    [language: string]: EventSortLocal;
  };

  totalVersions: number;
  currentEventVersion: EventVersion;
  lastFinalizedAt: string | Date;
  lastFinalizedBy: string;
  lastPublishedAt: string | Date;
  lastPublishedBy: string;

  /**
   * @deprecated
   */
  chats: { [region: string]: string };

  /**
   * Is this event a template event
   */
  template?: boolean;

  /**
   * Was this event created from a template event
   */
  fromTemplate?: {
    event: string;
    eventVersion: string;
  };

  /**
   * Event is integrated with external system
   */
  plain?: {
    webinar: any;
  };
  externalEvent?: {
    externalEventId: string;
    url: string;
    updatedAt: string | Date;
    eventSerieType: string;
  };

  eventSerie?: string;
}

export interface EventSortLocal {
  title: string;
  startAt: string | Date;
}

export const eventPhases = ['DRAFT', 'ANNOUNCED', 'RELEASED', 'LIVE', 'ARCHIVE'] as const;
export type EventPhase = (typeof eventPhases)[number];

function calculateLocalDate(timeZone: string, date: Date): string {
  return Intl.DateTimeFormat('de', { timeZone })
    .format(date)
    .split('.')
    .reverse()
    .map(d => d.padStart(2, '0'))
    .join('-');
}

/**
 *
 * @param event The event
 * @param regions All regions that are available (not only for the event)
 * @param forRegion If you want to have the phase for a specific region
 * @param date current server date
 */
export function eventPhase(event: Event, forLanguage?: string, date?: Date): EventPhase {
  date = date || new Date();
  if (event.promoted) {
    return 'ARCHIVE';
  }
  if (!event.currentEventVersion) {
    return 'DRAFT';
  }

  const timeZone = event.currentEventVersion.eventTimeZone || 'Europe/Berlin';
  const languages = event.currentEventVersion.languages || Object.keys(event.currentEventVersion.local || {});

  for (const language of forLanguage ? [forLanguage] : languages) {
    try {
      const localDate = calculateLocalDate(timeZone, date);

      if (event.currentEventVersion.local[language]?.startAt && event.currentEventVersion.local[language]?.endAt) {
        const endAt = new Date(event.currentEventVersion.local[language].endAt);
        const localEndAt = calculateLocalDate(timeZone, endAt);
        const startAt = new Date(event.currentEventVersion.local[language].startAt);
        const localStartAt = calculateLocalDate(timeZone, startAt);

        if (localDate >= localStartAt && localDate <= localEndAt) {
          return 'LIVE';
        }
      }
    } catch (err) {
      console.warn(err);
    }
  }

  for (const language of forLanguage ? [forLanguage] : languages) {
    try {
      const localDate = calculateLocalDate(timeZone, date);

      if (event.currentEventVersion.local[language]?.endAt) {
        const endAt = new Date(event.currentEventVersion.local[language].endAt);
        const localEndAt = calculateLocalDate(timeZone, endAt);

        if (localDate > localEndAt) {
          return 'ARCHIVE';
        }
      }
    } catch (err) {
      console.warn(err);
    }
  }

  if (event.releasedAt && new Date(event.releasedAt) < date) {
    return 'RELEASED';
  }
  if (event.announcedAt && new Date(event.announcedAt) < date) {
    return 'ANNOUNCED';
  }

  return 'DRAFT';
}
