import { Pipe, PipeTransform } from '@angular/core';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { CollaborationService } from '../../services/collaboration/collaboration.service';

@Pipe({
  name: 'isDisabled',
})
export class IsDisabledPipe implements PipeTransform {
  constructor(private collaborationService: CollaborationService) {}

  transform(value: string | EventVersion | Page, ...args: unknown[]): boolean {
    return this.collaborationService.isDisabled(value);
  }
}
