import { Stage2TitleEventStagePageModuleEditComponent } from './stage2-titleeventstage-page-module-edit/stage2-titleeventstage-page-module-edit.component';
import { ProductListPageModuleEditComponent } from './product-list-page-module-edit/product-list-page-module-edit.component';
import { TilesPageModuleEditComponent } from './tiles-page-module-edit/tiles-page-module-edit.component';
import { DocumentcollectionPageModuleEditComponent } from './documentcollection-page-module-edit/documentcollection-page-module-edit.component';
import { SpeakerListPageModuleEditComponent } from './speaker-list-page-module-edit/speaker-list-page-module-edit.component';
import { FormPageModuleEditComponent } from './form-page-module-edit/form-page-module-edit.component';
import { ImageBannerPageModuleEditComponent } from './image-banner-page-module-edit/image-banner-page-module-edit.component';

import { ListvideoPageModuleEditComponent } from './listvideo-page-module-edit/listvideo-page-module-edit.component';
import { GridPageModuleEditComponent } from './grid-page-module-edit/grid-page-module-edit.component';
import { AfterViewInit, Component, ComponentFactoryResolver, EventEmitter, Input, Output, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { PageModule } from 'src/common/entities/PageModule';
import { AccordionPageModuleEditComponent } from './accordion-page-module-edit/accordion-page-module-edit.component';
import { AgendaPageModuleEditComponent } from './agenda-page-module-edit/agenda-page-module-edit.component';
import { BannerPageModuleEditComponent } from './banner-page-module-edit/banner-page-module-edit.component';
import { EventkeytopicsicongroupPageModuleEditComponent } from './eventkeytopicsicongroup-page-module-edit/eventkeytopicsicongroup-page-module-edit.component';
import { EventsListPageModuleEditComponent } from './eventslist-page-module-edit/eventslist-page-module-edit.component';
import { IcongroupPageModuleEditComponent } from './icongroup-page-module-edit/icongroup-page-module-edit.component';
import { ImagetagtextlinkPageModuleEditComponent } from './imagetagtextlink-page-module-edit/imagetagtextlink-page-module-edit.component';
import { MultiEventStagePageModuleEditComponent } from './multieventstage-page-module-edit/multieventstage-page-module-edit.component';
import { ShowroomPageModuleEditComponent } from './showroom-page-module-edit/showroom-page-module-edit.component';
import { TextPageModuleEditComponent } from './text-page-module-edit/text-page-module-edit.component';
import { Text3dimagePageModuleEditComponent } from './text3dimage-page-module-edit/text3dimage-page-module-edit.component';
import { TextlistPageModuleEditComponent } from './textlist-page-module-edit/textlist-page-module-edit.component';
import { VideosliderPageModuleEditComponent } from './videoslider-page-module-edit/videoslider-page-module-edit.component';
import { VideoTeaserCollectionPageModuleEditComponent } from './videoteasercollection-page-module-edit/videoteasercollection-page-module-edit.component';
import { FilterPageModuleEditComponent } from './filter-page-module-edit/filter-page-module-edit.component';
import { StreamEventStagePageModuleEditComponent } from './streameventstage-page-module-edit/streameventstage-page-module-edit.component';
import { TeaserEventStagePageModuleEditComponent } from './teasereventstage-page-module-edit/teasereventstage-page-module-edit.component';
import { TextEventStagePageModuleEditComponent } from './texteventstage-page-module-edit/texteventstage-page-module-edit.component';
import { TitleEventStagePageModuleEditComponent } from './titleeventstage-page-module-edit/titleeventstage-page-module-edit.component';
import { ListPageModuleEditComponent } from './list-page-module-edit/list-page-module-edit.component';
import { ListImagePageModuleEditComponent } from './listimage-page-module-edit/listimage-page-module-edit.component';
import { ImagePageModuleEditComponent } from './image-page-module-edit/image-page-module-edit.component';
import { TextImagePageModuleEditComponent } from './textimage-page-module-edit/textimage-page-module-edit.component';
import { UserProfileStagePageModuleEditComponent } from './user-profile-stage-page-module-edit/user-profile-stage-page-module-edit.component';
// Stage 2.0
import { Stage2TextEventStagePageModuleEditComponent } from './stage2-texteventstage-page-module-edit/stage2-texteventstage-page-module-edit.component';
import { Stage2StreamEventStagePageModuleEditComponent } from './stage2-streameventstage-page-module-edit/stage2-streameventstage-page-module-edit.component';
import { Stage2TeaserEventStagePageModuleEditComponent } from './stage2-teasereventstage-page-module-edit/stage2-teasereventstage-page-module-edit.component';
import { Stage2MultiEventStagePageModuleEditComponent } from './stage2-multieventstage-page-module-edit/stage2-multieventstage-page-module-edit.component';
import { ConversionStagePageModuleEditComponent } from './conversion-stage-page-module-edit/conversion-stage-page-module-edit.component';
import { SlideshowPageModuleEditComponent } from './slideshow-page-module-edit/slideshow-page-module-edit.component';
import { TrendingtopicsPageModuleEditComponent } from './trendingtopics-page-module-edit/trendingtopics-page-module-edit.component';
import { SpacerPageModuleEditComponent } from './spacer-page-module-edit/spacer-page-module-edit.component';
import { ProductList2PageModuleEditComponent } from './product-list2-page-module-edit/product-list2-page-module-edit.component';
import { LoginPageModuleEditComponent } from './login-page-module-edit/login-page-module-edit.component';
import { ExploreStagePageModuleEditComponent } from './explore-stage-page-module-edit/explore-stage-page-module-edit.component';
import { BookmarksPageModuleEditComponent } from './bookmarks-page-module-edit/bookmarks-page-module-edit.component';
import { CustomPageModuleEditComponent } from './custom-page-module-edit/custom-page-module-edit.component';
import { NewsfeedEntryPageModuleEditComponent } from './newsfeedentry-page-module-edit/newsfeedentry-page-module-edit.component';


const pageModuleComponents = {
  Accordion: AccordionPageModuleEditComponent,
  Agenda: AgendaPageModuleEditComponent,
  Banner: BannerPageModuleEditComponent,
  Bookmarks: BookmarksPageModuleEditComponent,
  DocumentCollection: DocumentcollectionPageModuleEditComponent,
  EventKeytopicsIconGroup: EventkeytopicsicongroupPageModuleEditComponent,
  ExploreStage: ExploreStagePageModuleEditComponent,
  IconGroup: IcongroupPageModuleEditComponent,
  ImageTagTextLink: ImagetagtextlinkPageModuleEditComponent,
  Image: ImagePageModuleEditComponent,
  Form: FormPageModuleEditComponent,
  Grid: GridPageModuleEditComponent,
  ListVideo: ListvideoPageModuleEditComponent,
  List: ListPageModuleEditComponent,
  Login: LoginPageModuleEditComponent,
  MultiEventStage: MultiEventStagePageModuleEditComponent,
  SpeakerList: SpeakerListPageModuleEditComponent,
  StreamEventStage: StreamEventStagePageModuleEditComponent,
  TeaserEventStage: TeaserEventStagePageModuleEditComponent,
  TextEventStage: TextEventStagePageModuleEditComponent,
  TitleEventStage: TitleEventStagePageModuleEditComponent,
  Tiles: TilesPageModuleEditComponent,
  Showroom: ShowroomPageModuleEditComponent,
  Text: TextPageModuleEditComponent,
  Text3DImage: Text3dimagePageModuleEditComponent,
  TextList: TextlistPageModuleEditComponent,
  TextImage: TextImagePageModuleEditComponent,
  VideoSlider: VideosliderPageModuleEditComponent,
  VideoTeaserCollection: VideoTeaserCollectionPageModuleEditComponent,
  EventsList: EventsListPageModuleEditComponent,
  Filter: FilterPageModuleEditComponent,
  UserProfileStage: UserProfileStagePageModuleEditComponent,
  ImageBanner: ImageBannerPageModuleEditComponent,
  ListImage: ListImagePageModuleEditComponent,
  TextEventStage2: Stage2TextEventStagePageModuleEditComponent,
  TitleEventStage2: Stage2TitleEventStagePageModuleEditComponent,
  StreamEventStage2: Stage2StreamEventStagePageModuleEditComponent,
  TeaserEventStage2: Stage2TeaserEventStagePageModuleEditComponent,
  MultiEventStage2: Stage2MultiEventStagePageModuleEditComponent,
  ProductList: ProductListPageModuleEditComponent,
  ProductList2: ProductList2PageModuleEditComponent,
  ConversionStage: ConversionStagePageModuleEditComponent,
  SlideshowStage: SlideshowPageModuleEditComponent,
  TrendingTopics: TrendingtopicsPageModuleEditComponent,
  Spacer: SpacerPageModuleEditComponent,
  Custom: CustomPageModuleEditComponent,
  NewsfeedEntry: NewsfeedEntryPageModuleEditComponent,
}

@Component({
  selector: 'c-page-module-edit',
  templateUrl: './page-module-edit.component.html',
  styleUrls: ['./page-module-edit.component.scss'],
})
export class PageModuleEditComponent implements AfterViewInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  jsonpath: string | string[];

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  pageModule: PageModule;

  @Input()
  collaborationKey: string;

  @Output()
  onClose = new EventEmitter<boolean>();

  @ViewChild('child', { read: ViewContainerRef })
  child: ViewContainerRef;

  noEditComponent = false;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private renderer: Renderer2, private utilsService: UtilsService) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const component = pageModuleComponents[this.pageModule.pageModuleType];

      if (component) {
        const editComponentFactory = this.componentFactoryResolver.resolveComponentFactory<any>(component);
        const ref = this.child.createComponent(editComponentFactory);
        const instance = ref.instance as any;

        instance.event = this.event;
        instance.eventVersion = this.eventVersion;
        instance.page = this.page;
        instance.pageModule = this.pageModule;
        instance.collaborationKey = this.collaborationKey;
        instance.jsonpath = this.utilsService.combineJsonpath(this.jsonpath);
        instance.jsonpathParams = this.jsonpathParams;

        this.noEditComponent = false;
      } else {
        this.noEditComponent = true;
      }
    }, 0);
  }
}
