import { ImageBannerPageModule, ImageBannerPageModuleLocal } from './../../entities/pagemodules/ImageBannerPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class ImageBannerPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public imageBannerPageModuleBreakpoint(values?: any) {
    return {
      desktop: values?.desktop || null,
      desktopLarge: values?.desktopLarge || null,
      desktopWidescreen: values?.desktopWidescreen || null,
      tablet: values?.tablet || null,
      tabletLarge: values?.tabletLarge || null,
      mobile: values?.mobile || null,
      mobileLarge: values?.mobileLarge || null,
    };
  }

  public async imageBannerPageModuleLocal(values?: any): Promise<ImageBannerPageModuleLocal> {
    return {
      imageAssetBreakpoint: values?.imageAssetBreakpoint ? values?.imageAssetBreakpoint : this.imageBannerPageModuleBreakpoint(values?.imageAssetBreakpoint),
    };
  }

  public async imageBannerPageModule(values: any): Promise<ImageBannerPageModule> {
    if (values?.imageAssetBreakpoint) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.imageAssetBreakpoint = item.imageAssetBreakpoint || values.imageAssetBreakpoint;
      });
    }

    const pageModule = {
      ...(await this.baseValues(values)),
      pageModuleType: 'ImageBanner',
      link: values?.link || null,
      width: values?.width || 'content-area',
      imageAssetBreakpoint: undefined,
      local: await this.local(values, (val) => this.imageBannerPageModuleLocal(val)),
    };

    return { ...pageModule, pageModuleType: 'ImageBanner' };
  }
}
