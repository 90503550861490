import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { CTAButton } from '../entities/CTAButton';
import { AppFunctionFactory } from './AppFunctionFactory';

export class CTAButtonFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  createCTAButtonLocal(values: any) {
    return {
      ...values,
      label: values?.label || null,
      link: values?.link || null,
      video: values?.video || '',
    };
  }

  createCTAButton(values: any) {
    return {
      ...values,
      ctaButtonType: values?.ctaButtonType || 'meetyourexpert',
      readAccess: values?.readAccess,
      executeAccess: values?.executeAccess,
      disabled: values?.disabled || false,
      appFunction: values?.ctaButtonType === 'appfunction' ? new AppFunctionFactory(this.options).createAppFunction(values?.appFunction) : null,
      shareCtaTargets: values?.shareCtaTargets,
      local: Object.keys(values?.local || {}).reduce((a, b) => ({ ...a, [b]: this.createCTAButtonLocal(values.local[b]) }), {}),
      event: values?.event,
      page: values?.page
    } as CTAButton;
  }
}
