import { TextAttributes } from '../Attributes';
import { BreakpointValue } from '../Breakpoint';
import { CTAButton } from '../CTAButton';
import { PageModule } from './PageModule';

export const slideshowStageItemTypes = ['Video', 'Link', 'CTA'] as const;
export type SlideshowStageItemType = (typeof slideshowStageItemTypes)[number];

export interface SlideshowStageItem {
  _id: string;
  teaserItemType: SlideshowStageItemType;
}

export interface LinkSlideshowStageItem extends SlideshowStageItem {
  teaserItemType: 'Link';
  image: string;
  title: string;
  subtext: string;
  link: string;
}

export interface CTASlideshowStageItem extends SlideshowStageItem {
  teaserItemType: 'CTA';
  image: string;
  title: string;
  subtext: string;
  cta: CTAButton | null;
}

export interface VideoSlideshowStageItem extends SlideshowStageItem {
  teaserItemType: 'Video';
  video?: string;
  targetVideo?: string;
}

export interface SlideshowStagePageModuleLocal {
  title: string;
  title_attr?: SlideshowStagePageModuleAttributes;
  background: string;
  items: SlideshowStageItem[];
}

export type SlideshowStagePageModuleAttributes = TextAttributes & {};

export interface SlideshowStagePageModule extends PageModule {
  pageModuleType: 'SlideshowStage';
  local: { [language: string]: SlideshowStagePageModuleLocal };
  stageHeight: BreakpointValue<number>;
  buttonSpacingTopBreakpoint: BreakpointValue<number>;
  switchTimerEnabled: boolean;
  switchTime: number;
}

export function isVideoTeaserItem(object: any): object is VideoSlideshowStageItem {
  return object?.teaserItemType === 'Video';
}

export function isLinkTeaserItem(object: any): object is LinkSlideshowStageItem {
  return object?.teaserItemType === 'Link';
}

export function isCTATeaserItem(object: any): object is CTASlideshowStageItem {
  return object?.teaserItemType === 'CTA';
}

export function isSlideshowStage(object: any): object is SlideshowStagePageModule {
  return object?.pageModuleType === 'SlideshowStage';
}
