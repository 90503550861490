<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'GENERAL_ADMINUSERS' | translate }}</h1>
  </div>
  <div class="tab-menu"></div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_ADMINUSERS' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button [label]="'GENERAL_ADMINUSER' | translate" icon="pi icon-ic-add-item" (onClick)="newAdminUser()"></p-button>
      </div>
    </div>
    <c-table mode="query" [options]="tableOptions" [tableOptionsComponent]="toc" (query)="query($event)" [baseRoute]="'/configuration/admin-users'" [routePathProperty]="'_id'">
      <ng-template let-user>
        {{ user.firstName }}
      </ng-template>
      <ng-template let-user>
        {{ user.lastName }}
      </ng-template>
      <ng-template let-user>{{ user.email }}</ng-template>
      <ng-template let-user>{{ user.organization }}</ng-template>
      <ng-template let-user>
        <p-checkbox [ngModel]="user.admin" [binary]="true" [disabled]="true" (click)="linkClick($event)"></p-checkbox>
      </ng-template>
      <ng-template let-user>{{ user.lastActivity | date : 'short' }}</ng-template>
      <ng-template let-user
        ><a *ngIf="user.user" [routerLink]="['/users/' + user.user]">{{ 'GENERAL_PLATFORM_USER' | translate }} {{ 'GENERAL_DETAILS' | translate }}</a></ng-template
      >
    </c-table>
  </div>
</div>
