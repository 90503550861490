import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { ChipsModule } from 'primeng/chips';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DividerModule } from 'primeng/divider';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { ComponentsModule } from 'src/app/components/components.module';
import { DirectivesModule } from './../../directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

import { GroupComponent } from './group.component';

const routes: Routes = [{ path: '', component: GroupComponent }];

@NgModule({
  declarations: [GroupComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ComponentsModule,
    DirectivesModule,
    PipesModule,
    TableModule,
    TranslateModule,
    ButtonModule,
    CardModule,
    FormsModule,
    InputTextModule,
    ChipsModule,
    AutoCompleteModule,
    SplitButtonModule,
    DividerModule,
    ConfirmDialogModule,
  ],
  exports: [RouterModule],
})
export class GroupModule {}
