import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';
import { SpacerPageModule } from '../../entities/pagemodules/SpacerPageModule';
import { BreakpointValue } from '../../entities/Breakpoint';

export class SpacerPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async spacerPageModule(values: any): Promise<SpacerPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'Spacer',
      heightBreakpoint: values?.heightBreakpoint ? values?.heightBreakpoint : this.spacerPageModuleHeight(values),
    };
  }

  public spacerPageModuleHeight(values?: any): BreakpointValue<number> {
    return {
      desktop: values?.desktop || 0,
      desktopLarge: values?.desktopLarge || 0,
      desktopWidescreen: values?.desktopWidescreen || 0,
      tablet: values?.tablet || 0,
      tabletLarge: values?.tabletLarge || 0,
      mobile: values?.mobile || 0,
      mobileLarge: values?.mobileLarge || 0,
    };
  }
}
