import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AssetPipe } from './asset/asset.pipe';
import { DropdownOptionsPipe } from './dropdown-options/dropdown-options.pipe';
import { FileSizePipe } from './file-size/file-size.pipe';
import { KeysPipe } from './keys/keys.pipe';
import { KeytopicPipe } from './keytopic/keytopic.pipe';
import { ProductPipe } from './product/product.pipe';
import { LanguagePipe } from './language/language.pipe';
import { CountryPipe } from './country/country.pipe';
import { SelectMenuItemsPipe } from './pipes/select-menu-items.pipe';
import { RegionPipe } from './region/region.pipe';
import { OperatingRegionPipe } from './operating-region/operating-region.pipe';
import { SpeakerPipe } from './speaker/speaker.pipe';
import { UserPipe } from './user/user.pipe';
import { RegionSelectMenuItemsPipe } from './region-select-menu-items/region-select-menu-items.pipe';
import { AdminUserPipe } from './adminUser/adminUser.pipe';
import { CustomFieldsPipe } from './custom-fields/custom-fields.pipe';
import { JsonpathPipe } from './jsonpath/jsonpath.pipe';
import { ByDatePipe } from './by-date/by-date.pipe';
import { IsDisabledPipe } from './is-disabled/is-disabled.pipe';
import { EventTypePipe } from './event-type/event-type.pipe';
import { IncludesPipe } from './includes/includes.pipe';
import { RbacDisplayPipe } from './rbac-display/rbac-display.pipe';
import { ServerTimePipe } from './server-time/server-time.pipe';
import { EventPhasePipe } from './event-phase/event-phase.pipe';
import { LocalizePipe } from './localize/localize.pipe';
import { ExtractPipe } from './extract/extract.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { LocaledatePipe } from './localedate/localedate.pipe';
import { TimeZoneByUrlPipe } from './time-zone-by-url/time-zone-by-url.pipe';
import { TimeZoneByNamePipe } from './time-zone-by-name/time-zone-by-namel.pipe';
import { NumberAsTimePipe } from './number-as-time/number-as-time.pipe';
import { ShortenPipe } from './shorten/shorten.pipe';
import { FnPipe } from './fnpipe/fn.pipe';
import { MapsPipe } from './maps/maps.pipe';
import { TagPipe } from './tag/tag.pipe';
import { MaybeJsonPipe } from './maybeJson/maybeJson.pipe';

const pipes = [
  KeysPipe,
  LanguagePipe,
  CountryPipe,
  RegionPipe,
  FileSizePipe,
  KeytopicPipe,
  ProductPipe,
  SpeakerPipe,
  AssetPipe,
  DropdownOptionsPipe,
  SelectMenuItemsPipe,
  UserPipe,
  FnPipe,
  RegionSelectMenuItemsPipe,
  AdminUserPipe,
  CustomFieldsPipe,
  JsonpathPipe,
  ByDatePipe,
  IsDisabledPipe,
  EventTypePipe,
  IncludesPipe,
  RbacDisplayPipe,
  ServerTimePipe,
  EventPhasePipe,
  LocalizePipe,
  ExtractPipe,
  SafeHtmlPipe,
  LocaledatePipe,
  TimeZoneByUrlPipe,
  TimeZoneByNamePipe,
  NumberAsTimePipe,
  ShortenPipe,
  MapsPipe,
  TagPipe,
  MaybeJsonPipe,
  OperatingRegionPipe,
];

@NgModule({
  declarations: [...pipes, ShortenPipe],
  imports: [CommonModule],
  exports: [...pipes],
})
export class PipesModule {}
