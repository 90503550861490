import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { AppointmentsService } from 'src/app/services/appointments/appointments.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { I18nService } from 'src/app/services/i18n/i18n.service';
import { LookupsService } from 'src/app/services/lookups/lookups.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { AdminUser } from 'src/common/entities/AdminUser';
import { Appointment, appointmentStatus } from 'src/common/entities/Appointment';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss'],
  providers: [ConfirmationService],
})
export class AppointmentComponent implements OnInit {
  tab: Tab;
  loading: boolean = false;
  saving: boolean = false;
  showError: boolean = false;

  originalAppointment: string;
  appointment: Appointment;
  appointmentId: string;

  topics: { [key: string]: string };
  topicsDropdown: { name: string; code: string }[] = [];
  topicsFiltered: string[] = [];

  statusTypes = [...appointmentStatus];

  menu: { [status: string]: MenuItem[] } = {};

  get currentUser(): AdminUser {
    return this.authService.currentAdminUser();
  }

  get currentUserId(): string {
    return this.currentUser._id;
  }

  get organizerGroup(): string {
    return this.configurationService.configuration()['appointment']['organizerGroup'];
  }

  get hasOrganizerGroup(): boolean {
    return this.currentUser.admin || this.currentUser.groups.includes(this.organizerGroup) || this.authService.hasGlobalRight('appointments.edit');
  }

  get allowedToEdit(): boolean {
    if (this.authService.hasGlobalRight('appointments.edit')) return true;

    if (this.appointment.origin === 'Host' && (this.appointment.initiatedBy === this.currentUserId || this.hasOrganizerGroup)) return true;

    if (this.appointment.origin === 'Customer' || this.appointment.origin === 'Organizer') {
      if (this.appointment.participants.host === this.currentUserId) return true;
      if (this.appointment.organizer === this.currentUserId && this.hasOrganizerGroup) return true;
    }

    return false;
  }

  get allowedToDelete(): boolean {
    return this.allowedToEdit && this.authService.hasGlobalRight('appointments.delete');
  }

  get pageMode(): 'CREATE' | 'EDIT' | 'READ' {
    if (this.appointmentId.toUpperCase() == 'NEW') return 'CREATE';
    if (!this.appointment || this.completed) return 'READ';

    return this.allowedToEdit ? 'EDIT' : 'READ';
  }

  get dirty(): boolean {
    return JSON.stringify(this.appointment) !== this.originalAppointment;
  }

  get showActionButtons(): boolean {
    return this.appointment.participants.host === this.currentUserId || this.hasOrganizerGroup;
  }

  get completed(): boolean {
    return ['Done', 'Rejected'].includes(this.appointment.status) || !!this.appointment.deletedAt;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private appointmentsService: AppointmentsService,
    private authService: AuthService,
    private configurationService: ConfigurationService,
    private lookupsService: LookupsService,
    public i18nService: I18nService,
    private utilsService: UtilsService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private tabsService: TabsService
  ) {
    this.appointmentId = this.activatedRoute.snapshot.params.appointmentId;
    this.tab = this.tabsService.register({
      category: 'data',
      parent: '/appointments',
      loading: false,
      route: this.activatedRoute.snapshot,
    });
  }

  async ngOnInit(): Promise<void> {
    this.loadData();
  }

  async loadData() {
    this.loading = true;

    if (this.pageMode == 'CREATE') {
      this.appointment = {
        _id: 'NEW',
        origin: this.hasOrganizerGroup ? 'Organizer' : 'Host',
        initiatedBy: this.currentUserId,
        createdAt: new Date().toISOString(),
        organizer: this.hasOrganizerGroup ? this.currentUserId : null,
        participants: {
          users: [],
          adminUsers: [],
          host: this.hasOrganizerGroup ? null : this.currentUserId,
        },
      };
      this.tab.title = 'PAGE_APPOINTMENTS_NEW_APPOINTMENT';
    } else {
      this.appointment = await this.appointmentsService.getAppointment(this.appointmentId);
      this.tab.title = new Date(this.appointment.createdAt).toLocaleDateString() + ', ' + new Date(this.appointment.createdAt).toLocaleTimeString();
    }

    this.originalAppointment = JSON.stringify(this.appointment);

    this.topics = (await this.lookupsService.getLookup('topic'))?.local['en'] || {};
    this.topicsDropdown = [{ name: '', code: '' }].concat(Object.keys(this.topics).map((t) => ({ name: this.topics[t], code: t })));

    await this.buildMenu();

    this.loading = false;
  }

  async buildMenu() {
    this.menu = {
      Inquired: [
        { label: 'Reject', command: () => this.reject() },
        // {label: 'Delete', command: () => this.delete()},
      ],
      Confirmed: [
        { label: 'Done', command: () => this.done() },
        { label: 'Reject', command: () => this.reject() },
        // {label: 'Delete', command: () => this.delete()},
      ],
      // 'Rejected': [
      //   {label: 'Delete', command: () => this.delete()},
      // ],
      // 'Done': [
      //   {label: 'Delete', command: () => this.delete()},
      // ],
    };
  }

  isValid(): boolean {
    return this.isValidForSendInvitations();
  }

  async save() {
    if (this.pageMode === 'CREATE') {
      await this.execute(this.appointmentsService.createAppointment(this.appointment), true);
    } else {
      await this.execute(this.appointmentsService.updateAppointment(this.appointment), true);
    }
  }

  async execute(promise: Promise<Appointment> | Promise<Appointment>[] | (() => Promise<Appointment>)[], goBack: boolean = false) {
    this.saving = true;

    try {
      for (const p of Array.isArray(promise) ? promise : [promise]) {
        if (typeof p === 'function') {
          this.appointment = await p();
        } else {
          this.appointment = await p;
        }
      }

      this.originalAppointment = JSON.stringify(this.appointment);

      if (goBack) {
        this.back();
        return;
      }
    } catch (err) {
      console.error(err);
    }

    this.saving = false;
  }

  errorAt(field: string): string {
    return '';
  }

  async assignToMe() {
    if (this.pageMode !== 'CREATE') {
      await this.execute(this.appointmentsService.assignAppointment(this.appointment, this.currentUserId));
    }
  }

  async assign(adminUser: string) {
    if (this.pageMode !== 'CREATE') {
      await this.execute(this.appointmentsService.assignAppointment(this.appointment, adminUser));
    }
  }

  async sendInvitations() {
    await this.execute(
      [
        async () => (this.pageMode === 'CREATE' ? this.appointmentsService.createAppointment(this.appointment) : this.appointmentsService.updateAppointment(this.appointment)),
        async () => this.appointmentsService.sendInvitations(this.appointment),
      ],
      true
    );
  }

  isValidForSendInvitations(): boolean {
    return (
      this.appointment &&
      this.appointment.participants.host &&
      this.appointment.participants.users &&
      this.appointment.participants.users.length > 0 &&
      this.appointment.dateFrom != null &&
      this.appointment.dateTo != null
    );
  }

  back() {
    this.router.navigate(['/appointments'], {
      replaceUrl: true,
    });
  }

  joinExternalAppointment() {
    if (this.appointment.externalAppointment) {
      window.open(this.appointment.externalAppointment.link, '_blank');
    }
  }

  filterTopics(event) {
    if (!event.query) {
      this.topicsFiltered = Object.keys(this.topics);
    } else {
      this.topicsFiltered = Object.keys(this.topics).filter((t) => t.toLowerCase().startsWith(event.query.toLowerCase()));
    }
  }

  done() {
    this.execute(this.appointmentsService.stopAppointment(this.appointment), true);
  }

  reject() {
    this.execute(this.appointmentsService.rejectAppointment(this.appointment), true);
  }

  async delete() {
    this.confirmationService.confirm({
      message: await this.utilsService.translate('PAGE_APPOINTMENT_DELETE_CONFIRM_MESSAGE'),
      accept: () => {
        this.execute(this.appointmentsService.deleteAppointment(this.appointment), true);
      },
      reject: () => {
        // Nothing to do
      },
    });
  }
}
