export interface FactoryOptions {
  newIds?: boolean;
  ensureLocals?: string[];
}

export type NewIdFunction = () => Promise<string>;

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type Values<T> = Omit<T, keyof T> & Partial<Pick<T, keyof T>>;

export abstract class AbstractFactory {
  private static _newIdFunction: NewIdFunction;

  protected constructor(protected options?: FactoryOptions) {}

  protected id(previousId?: string): Promise<string> {
    if (this.options?.newIds || !previousId) {
      return AbstractFactory._newIdFunction();
    } else {
      return Promise.resolve(previousId);
    }
  }

  protected async local(values: { local: { [language: string]: any } }, localFunction: (values: any) => any | Promise<any>) {
    const local: any = {};

    for (const language of this.localKeys(values?.local || {})) {
      const value = localFunction((values?.local && values?.local[language]) || {});

      if (typeof value.then === 'function') {
        local[language] = await value;
      } else {
        local[language] = value;
      }
    }

    return local;
  }

  protected localKeys(obj: { [language: string]: any }): string[] {
    return Array.from(new Set([Object.keys(obj || {}).concat(this.options?.ensureLocals || [])].flat()));
  }

  public static get<T extends AbstractFactory>(type: { new (options?: FactoryOptions): T }, options?: FactoryOptions): T {
    return new type(options);
  }

  public static setNewIdFunction(newIdFunction: NewIdFunction) {
    AbstractFactory._newIdFunction = newIdFunction;
  }
}
