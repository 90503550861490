import { ComponentRef, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

function routeToUrl(route: ActivatedRouteSnapshot): string {
  if (route.url) {
    if (route.url.length) {
      return route.url.join('/');
    } else {
      if (typeof route.component === 'function') {
        return `[${route.component.name}]`;
      } else if (typeof route.component === 'string') {
        return `[${route.component}]`;
      } else {
        return `[null]`;
      }
    }
  } else {
    return '(null)';
  }
}

function calcKey(route: ActivatedRouteSnapshot) {
  let url = route.pathFromRoot.map((it) => routeToUrl(it)).join('/'); // + '*';

  if (url.startsWith('[null]')) {
    url = url.replace(/^\[null\]/, '[AppComponent]');
  }

  return url;
}

function removeComponents(key: string) {
  return key
    .replace(/\/\[[a-z]+\]/gi, '/')
    .replace(/^\[[a-z]+\]/gi, '')
    .replace('*', '');
}

@Injectable()
export class TabsReuseStrategy implements RouteReuseStrategy {
  handlers: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return false;
    } else {
      return true;
    }
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const key = calcKey(route);
    this.handlers[key] = handle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const key = calcKey(route);
    return !!route.routeConfig && !!this.handlers[key];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return null;
    }
    const key = calcKey(route);
    return this.handlers[key];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const furtureKey = calcKey(future);
    const currentKey = calcKey(curr);
    return furtureKey === currentKey;
  }

  remove(route: ActivatedRouteSnapshot) {
    const componentlessKey = removeComponents(calcKey(route));

    for (let handlerKey of Object.keys(this.handlers)) {
      const componentlessHanlerKey = removeComponents(handlerKey);

      if (componentlessHanlerKey.indexOf(componentlessKey) === 0) {
        const componentRef: ComponentRef<any> = this.handlers[handlerKey]['componentRef'];

        if (componentRef) {
          componentRef.destroy();
        }

        delete this.handlers[handlerKey];
      }
    }
  }
}
