import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { FilterPageModuleFactory } from './../../factories/pagemodules/FilterPageModuleFactory';
import { filterOptions } from './../../entities/pagemodules/FilterPageModule';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const FilterPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('Filter'),
  "$.pageModules[?(@.pageModuleType=='Filter' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new FilterPageModuleFactory().createFilterPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='IconGroup' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='Filter' && @._id=='$pageModuleId')].eventType": {
    header: 'Event Types',
    type: 'switch',
  },
  "$.pageModules[?(@.pageModuleType=='Filter' && @._id=='$pageModuleId')].keyTopics": {
    header: 'Keytopics',
    type: 'switch',
  },
  "$.pageModules[?(@.pageModuleType=='Filter' && @._id=='$pageModuleId')].language": {
    header: 'Language',
    type: 'switch',
  },
  "$.pageModules[?(@.pageModuleType=='Filter' && @._id=='$pageModuleId')].country": {
    header: 'Country',
    type: 'switch',
  },
  "$.pageModules[?(@.pageModuleType=='Filter' && @._id=='$pageModuleId')].eventTypeLabel": {
    header: 'Event Types Label',
  },
  "$.pageModules[?(@.pageModuleType=='Filter' && @._id=='$pageModuleId')].keyTopicsLabel": {
    header: 'Keytopics Label',
  },
  "$.pageModules[?(@.pageModuleType=='Filter' && @._id=='$pageModuleId')].languageLabel": {
    header: 'Language Label',
  },
  "$.pageModules[?(@.pageModuleType=='Filter' && @._id=='$pageModuleId')].countryLabel": {
    header: 'Country Label',
  },
  "$.pageModules[?(@.pageModuleType=='Filter' && @._id=='$pageModuleId')].filterOption": {
    header: 'Filter Option ',
    type: 'dropdown',
    dropdownOptions: [...filterOptions].map((a) => ({ label: a, value: a })),
  },
};
