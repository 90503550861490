import { Patch } from '../patch/Patch';
import * as jp from 'jsonpath';
import { EventVersion } from '../entities/EventVersion';
import { AccessPolicyType } from '../entities/AccessPolicy';
import { CTAButtonType } from '../entities/CTAButton';
import { FieldType } from '../entities/Condition';

export type InputType =
  | 'text'
  | 'textarea'
  | 'html'
  | 'number'
  | 'date'
  | 'datetime'
  | 'time'
  | 'switch'
  | 'videoasset'
  | 'imageasset'
  | 'asset'
  | 'dropdown'
  | 'multiselect'
  | 'accesspolicy'
  | 'ctabutton'
  | 'color'
  | 'autocomplete'
  | 'event'
  | 'speaker'
  | 'tags'
  | 'page'
  | 'product'
  | 'onboarding'
  | 'operatingregion'
  | 'user';

export type InputFactory = (obj: any, jsonpathParams: { [key: string]: any }) => Promise<any>;
export type ChildLabel = (obj: any, index: number, lang?: string) => string;
export type ChildDescription = (obj: any, index: number, lang?: string) => string;
export type ChildImage = (obj: any, index: number) => string;
export type Condition = (obj: any, jsonpath: string, jsonpathParams: { [key: string]: any }, parent: any) => boolean;
export type NumberModeType = 'currency' | 'decimal';

export type BasicInputConfiguration = {
  header?: string;
  description?: string;
  disabled?: boolean;
};

export type PatchInputConfiguration = BasicInputConfiguration & {
  hide?: boolean;
  validator?: any;
  beforePatching?: (obj: any, patch: Patch) => Promise<Patch[]>;
  afterPatching?: (obj: any, patch: Patch) => Promise<Patch[]>;
  factory?: InputFactory;
  indexName?: string;
  condition?: Condition;
  list?: boolean;
  childFactory?: InputFactory;
  childImage?: ChildImage;
  childLabel?: ChildLabel;
  childDescription?: ChildDescription;
  listKey?: string;
  listValue?: string;
  onChange?: (newValue: any, previousValue: any) => void;
  onFocus?: () => void;
  languages?: string[];
};

export type CTAButtonInputOptions = {
  types?: CTAButtonType[];
  additionalTypes?: { label: string; value: CTAButtonType }[];
};

export interface DropdownOption {
  label: string;
  value: string | number | null;
}

export type InputTypeConfiguration =
  | {
      type?: 'html' | 'date' | 'switch' | 'videoasset' | 'asset' | 'product' | 'assetseries' | 'color' | 'customdata' | 'form' | 'onboarding' | 'operatingregion' | 'user';
    }
  | {
      type: 'imageasset';
      mimeTypes?: string[];
      position?: 'left' | 'right';
    }
  | {
      type: 'documentasset';
      mimeTypes?: string[];
    }
  | {
      type: 'tags';
      multiselect?: boolean;
    }
  | {
      type: 'textarea';
      placeholder?: string;
    }
  | {
      type: 'text';
      attributes?: { label: string | ((obj: string) => string); inputs: Inputs };
      placeholder?: string;
    }
  | {
      type: 'number';
      maxFractionDigits?: number;
      minFractionDigits?: number;
      mode?: NumberModeType;
    }
  | {
      type: 'dropdown';
      dropdownOptions?: DropdownOption[];
    }
  | {
      type: 'datetime';
      datetimeOptions?: {
        showSeconds?: boolean;
      };
    }
  | {
      type: 'time';
      datetimeOptions?: {
        showSeconds?: boolean;
        dateSelection?: boolean;
      };
    }
  | {
      type: 'autocomplete';
      autocompleteOptions?: {
        multiple?: boolean;
        dropdown?: boolean;
        sort?: boolean;
        query: (value: string) => Promise<any[]>;
        displayField?: string | ((obj: any) => Promise<string>);
      };
    }
  | {
      type: 'multiselect';
      multiselectOptions?: { label: string; value: string | object | null }[];
      showToggleAll?: boolean;
      placeholder?: string;
      showManualItemSort?: boolean;
    }
  | {
      type: 'accesspolicy';
      /**
       * If not existing, all types are allowed
       */
      allowedAccessPolicyTypes?: AccessPolicyType[];
      /**
       * event version, where a ticket can be selected from
       */
      eventVersion?: EventVersion;
      inverted?: boolean;
    }
  | {
      type: 'ctabutton';
      options?: CTAButtonInputOptions;
    }
  | {
      type: 'event';
      hideRemoveLink?: boolean;
    }
  | {
      type: 'speaker';
      hideRemoveLink?: boolean;
    }
  | {
      type: 'query';
      useTags?: boolean;
      fieldTypes: FieldType[];
    }
  | {
      type: 'page';
      domainCollectionId?: string;
      hideRemoveLink?: boolean;
    }
  | { type: 'embeddedpage'; domainCollectionId?: string };

export type SimpleInputConfiguration = BasicInputConfiguration & InputTypeConfiguration;
export type InputConfiguration = PatchInputConfiguration & InputTypeConfiguration;

export type ListInputParentConfiguration = {
  isObject: boolean;
  indexName?: string;
  children: string[];
  childJsonpath: string;
  childFactory: InputFactory;
  element: any;
};

export interface Inputs {
  [jsonpath: string]: InputConfiguration;
}

export function guessInputType(jsonpath: string): InputType {
  const parts = jp.parse(jsonpath);
  // const lastPart = parts[parts.length - 1]?.expression?.value?.toLowerCase() // ORIGINAL !!!
  let lastPart = parts[parts.length - 1]?.expression?.value;

  if (typeof lastPart === 'string') {
    lastPart = lastPart.toLowerCase();
    if (lastPart.includes('text') || lastPart.includes('description')) {
      return 'textarea';
    }
    if (lastPart.includes('startat') || lastPart.includes('endat')) {
      return 'datetime';
    }
    if (lastPart.includes('color')) {
      return 'color';
    }
    if (lastPart.includes('ctabutton')) {
      return 'ctabutton';
    }
    if (lastPart.includes('image') || lastPart.includes('background') || lastPart.includes('icon')) {
      return 'imageasset';
    }
    if (lastPart.startsWith('max') || lastPart.startsWith('min')) {
      return 'number';
    }
    if (lastPart.startsWith('show') || lastPart.startsWith('indicate')) {
      return 'switch';
    }
  }

  return 'text';
}
