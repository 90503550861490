<div *skeleton="{ show: tab.loading, type: 'table', menu: true, tabs: true }">
  <div class="tab-header">
    <h1>{{ 'GENERAL_LIVE_EVENT' | translate }} {{ event?.internalName }}</h1>

    <div class="info">
      <div class="info-box">
        <div class="value">{{ loggedInViewers }}</div>
        <div class="title">Logged In<br />Visitors</div>
      </div>
      <div class="info-box">
        <div class="value">{{ loggedOutViewers }}</div>
        <div class="title">Logged Out<br />Visitors</div>
      </div>
      <div *hasRight="['events.read', 'events.edit', 'events.editContent']" class="info-box">
        <div class="value">{{ eventPhase() }}</div>
        <div class="title">Event<br />Phase</div>
      </div>
      <div *hasRight="['events.read', 'events.edit', 'events.editContent']" class="info-box">
        <div class="value">{{ event.currentEventVersion?.version || '-' }}</div>
        <div class="title">Published<br />Version</div>
      </div>
    </div>
  </div>
  <div class="tab-menu">
    <c-language-button *hasRight="['notification.edit', 'notification.create']" [languages]="languages" [(currentLanguage)]="currentLanguage"></c-language-button>
    <c-language-button *hasNoRight="['notification.edit', 'notification.create']" [manageLanguages]="false" [languages]="languages" [(currentLanguage)]="currentLanguage"></c-language-button>
    <p-button
      *hasRight="{ resource: 'events:' + event._id, rights: 'eventsessions.sendupdate' }"
      styleClass="p-button-text"
      [label]="'PAGE_LIVE_OVERVIEW_SEND_SESSIONS_UPDATE' | translate"
      [disabled]="working"
      (click)="sendUpdateSessions()"
    ></p-button>
    <p-button
      *hasRight="{ resource: 'events:' + event._id, rights: 'events.sendupdate' }"
      styleClass="p-button-text"
      [label]="'PAGE_LIVE_OVERVIEW_SEND_EVENT_UPDATE' | translate"
      [disabled]="working"
      (click)="sendUpdateEvent()"
    ></p-button>
  </div>
  <div class="tab-subtabs">
    <div class="tabs">
      <a
        *hasRight="{
          resource: 'event:' + eventId,
          rights: ['eventsessions.list', 'eventsessions.create', 'eventsessions.read', 'eventsessions.edit']
        }"
        [class.active]="currentTab === 'overview'"
        [routerLink]="[]"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ tab: 'overview' }"
      >
        {{ 'PAGE_LIVE_OVERVIEW_HEADER' | translate }}
      </a>
      <a
        *hasRight="{
          resource: 'event:' + eventId,
          rights: ['eventregistrations.list', 'eventregistrations.create', 'eventregistrations.read', 'eventregistrations.edit']
        }"
        [class.active]="currentTab === 'eventregistrations'"
        [routerLink]="[]"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ tab: 'eventregistrations' }"
      >
        {{ 'PAGE_LIVE_VISITOR_MANAGEMENT_HEADER' | translate }}
      </a>
      <a
        *hasRight="{ resource: 'event:' + eventId, rights: ['eventticketslots.list', 'eventticketslots.create', 'eventticketslots.read', 'eventticketslots.edit'] }"
        [class.active]="currentTab === 'eventticketslots'"
        [routerLink]="[]"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ tab: 'eventticketslots' }"
      >
        {{ 'PAGE_LIVE_TICKET_SLOT_MANAGEMENT' | translate }}
      </a>

      <a
        *hasRight="{
          resource: 'event:' + eventId,
          rights: ['eventsessions.list', 'eventsessions.create', 'eventsessions.read', 'eventsessions.edit']
        }"
        [class.active]="currentTab === 'notifications'"
        [routerLink]="[]"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ tab: 'notifications' }"
      >
        {{ 'PAGE_LIVE_NOTIFICATIONS_HEADER' | translate }}
      </a>
    </div>
  </div>
</div>

<div *hasRight="{ resource: 'event:' + eventId, rights: ['eventsessions.list', 'eventsessions.create', 'eventsessions.read', 'eventsessions.edit'] }">
  <app-live-sessions *ngIf="currentTab === 'overview' && event" [event]="event" [viewers]="viewers"></app-live-sessions>
</div>
<div *hasRight="{ resource: 'event:' + eventId, rights: ['eventregistrations.list', 'eventregistrations.create', 'eventregistrations.read', 'eventregistrations.edit'] }">
  <app-live-users *ngIf="currentTab === 'eventregistrations' && event" [event]="event"></app-live-users>
</div>
<div *hasRight="{ resource: 'event:' + eventId, rights: ['eventticketslots.list', 'eventticketslots.create', 'eventticketslots.read', 'eventticketslots.edit'] }">
  <app-live-ticketslots *ngIf="currentTab === 'eventticketslots' && event" [event]="event"></app-live-ticketslots>
</div>
<div *hasRight="{ resource: 'event:' + eventId, rights: ['eventsessions.list', 'eventsessions.create', 'eventsessions.read', 'eventsessions.edit'] }">
  <app-live-notifications *ngIf="currentTab === 'notifications' && event" [event]="event" [languages]="languages" [currentLanguage]="currentLanguage"></app-live-notifications>
</div>
