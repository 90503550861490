import { Stage2TeaserEventStagePageModuleAttributes } from './../../entities/pagemodules/Stage2TeaserEventStagePageModule';
import { HeadlineType } from './../../entities/Attributes';
import { ContentAreaHeightDefaultBreakpointValue, CTAAreaHeightDefaultBreakpointValue, TopAreaHeightDefaultBreakpointValue } from './DefaultBreakponitValue';
import { BreakpointValue } from './../../entities/Breakpoint';
import { Stage2CTATeaserItem, Stage2HighlightTeaserItem, Stage2LinkTeaserItem, Stage2TeaserEventStagePageModule, Stage2TeaserEventStagePageModuleLocal } from './../../entities/PageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';
import { createCTAButton } from '../../entities/CTAButton';

export class Stage2TeaserEventStagePageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async highlightTeaserItem(values: any): Promise<Stage2HighlightTeaserItem> {
    return {
      _id: await this.id(values?._id),
      teaserItemType: 'Highlight',
      session: values?.session || null,
    };
  }

  public async linkTeaserItem(values: any): Promise<Stage2LinkTeaserItem> {
    return {
      _id: await this.id(values?._id),
      teaserItemType: 'Link',
      image: values?.image || null,
      title: values?.title || null,
      link: values?.link || null,
    };
  }

  public async ctaTeaserItem(values: any): Promise<Stage2CTATeaserItem> {
    return {
      _id: await this.id(values?._id),
      teaserItemType: 'CTA',
      image: values?.image || null,
      title: values?.title || null,
      cta: createCTAButton(values?.cta),
    };
  }

  public async teaserItem(values: any) {
    switch (values.teaserItemType) {
      case 'Highlight':
        return this.highlightTeaserItem(values);
      case 'Link':
        return this.linkTeaserItem(values);
      case 'CTA':
        return this.ctaTeaserItem(values);
    }
    return null;
  }

  public async teaserEventStage2PageModuleLocal(values?: any): Promise<Stage2TeaserEventStagePageModuleLocal> {
    return {
      smallHeadline: values?.smallHeadline || '',
      smallHeadline_attr: this.stage2TeaserEventStagePageModuleAttributes(values?.smallHeadline_attr, 'no headline'),
      title: values?.title || '',
      title_attr: this.stage2TeaserEventStagePageModuleAttributes(values?.title_attr),
      subtitle: values?.subtitle || '',
      subtitle_attr: this.stage2TeaserEventStagePageModuleAttributes(values?.subtitle_attr, 'no headline'),
      items: await Promise.all((values?.items || []).map((i: any) => this.teaserItem(i))),
      subtext: values?.subtext || '',
      background: values?.background || null,
    };
  }

  public stage2TeaserEventStagePageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): Stage2TeaserEventStagePageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async teaserEventStage2PageModuleButton(values?: any) {
    return {
      _id: await this.id(values?._id),
      ctaButton: values?.ctaButton || null,
    };
  }

  public teaserEventStage2PageModuleAreaHeight(defaultValues: BreakpointValue<number>, values?: any) {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge,
    };
  }

  public async teaserEventStage2PageModule(values?: any): Promise<Stage2TeaserEventStagePageModule> {
    if (values?.background) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.background = item.background || values.background;
      });
    }
    return {
      pageModuleType: 'TeaserEventStage2',
      ...(await this.baseValues(values)),
      local: await this.local(values, (val) => this.teaserEventStage2PageModuleLocal(val)),
      ctalist: values?.ctalist || [],
      ctalistInteraction: values?.ctalistInteraction || [],
      showCountdown: values?.showCountdown || false,
      discoverMore: values?.discoverMore || false,
      ctaAreaHeight: values?.ctaAreaHeight ? values?.ctaAreaHeight : this.teaserEventStage2PageModuleAreaHeight(CTAAreaHeightDefaultBreakpointValue, values?.ctaAreaHeight),
      contentAreaHeight: values?.contentAreaHeight ? values?.contentAreaHeight : this.teaserEventStage2PageModuleAreaHeight(ContentAreaHeightDefaultBreakpointValue, values?.contentAreaHeight),
      topAreaHeight: values?.topAreaHeight ? values?.topAreaHeight : this.teaserEventStage2PageModuleAreaHeight(TopAreaHeightDefaultBreakpointValue, values?.topAreaHeight),
    };
  }
}
