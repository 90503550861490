import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { AgendaPageModuleFactory } from './../../factories/pagemodules/AgendaPageModuleFactory';
import { titlePositions } from './../../entities/pagemodules/PageModule';
import { shareCtaTargets } from '../../entities/CTAButton';
import { speakerDisplayTypes, viewTypes } from '../../entities/pagemodules/AgendaPageModule';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const agendaPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('Agenda'),
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].event": {
    header: 'Event',
    type: 'event',
  },

  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new AgendaPageModuleFactory().createAgendaPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].local.$language.preCtaText": {},

  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].viewType": {
    type: 'dropdown',
    dropdownOptions: [...viewTypes].map((a) => ({ label: a, value: a })),
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].speakerDisplayType": {
    type: 'dropdown',
    dropdownOptions: [...speakerDisplayTypes].map((a) => ({ label: a, value: a })),
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].showTime": {
    header: 'Show Time',
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].showThumbnail": {
    header: 'Show Thumbnail',
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].showCalendarButton": {
    header: 'Show Calendar Button',
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].showShareButton": {
    header: 'Show Share Button',
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].shareButtonTargets": {
    type: 'multiselect',
    header: 'Share targets',
    description: 'If none is selected, the module will display all possible targets.',
    placeholder: 'All targets',
    showToggleAll: true,
    multiselectOptions: [...shareCtaTargets].map((a) => ({ label: a, value: a })),
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].indicateLiveSessions": {
    header: 'Indicate Live Sessions',
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].indicatePastSessions": {
    header: 'Indicate Past Sessions',
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].maxSessionCount": {},

  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].ctaButton": {},

  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].highlightSessions": {
    factory: async () => [],
    list: true,
    childFactory: async () => '',
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].highlightSessions[$index]": {
    header: 'Highlight Session',
    type: 'dropdown',
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].showFilterOptionsButton": {
    header: 'Show Filter Options',
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].showAgendaCalendarButton": {
    header: 'Show Agenda Calendar Button',
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].showAgendaShareButton": {
    header: 'Show Agenda Share Button',
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].agendaShareButtonTargets": {
    type: 'multiselect',
    header: 'Agenda share targets',
    description: 'If none is selected, the module will display all possible targets.',
    placeholder: 'All targets',
    showToggleAll: true,
    multiselectOptions: [...shareCtaTargets].map((a) => ({ label: a, value: a })),
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].showCurrentTime": {
    header: 'Show Current Time',
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].showBookmarkButton": {
    header: 'Show Bookmark Button',
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].showDescription": {
    header: 'Show Description',
    type: 'switch',
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [...titlePositions].map((item) => ({
      label: item,
      value: item,
    })),
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].showMapButton": {
    type: 'switch',
  },
  "$.pageModules[?(@.pageModuleType=='Agenda' && @._id=='$pageModuleId')].indicateCurrentSession": {
    type: 'switch',
  },
};
