import { Injectable } from '@angular/core';
import { CacheContainer } from '../cache/cache-container';
import { ApiService } from '../api/api.service';
import { CacheService } from '../cache/cache.service';
import { EventRegistrationAutoAssignment } from '../../../common/entities/AutoAssignment';
import { GetEventRegistrationAutoAssignmentQuery, GetEventRegistrationAutoAssignmentResponse } from '../../../common/api/v1/events/GetEventRegistrationAutoAssignment';
import { GetEventRegistrationAutoAssignmentsQuery, GetEventRegistrationAutoAssignmentsResponse } from '../../../common/api/v1/events/GetEventRegistrationAutoAssignments';
import {
  PostEventRegistrationAutoAssignmentsBody,
  PostEventRegistrationAutoAssignmentsQuery,
  PostEventRegistrationAutoAssignmentsResponse,
} from '../../../common/api/v1/events/PostEventRegistrationAutoAssignments';
import {
  PostEventRegistrationAutoAssignmentBody,
  PostEventRegistrationAutoAssignmentQuery,
  PostEventRegistrationAutoAssignmentResponse,
} from '../../../common/api/v1/events/PostEventRegistrationAutoAssignment';
import {
  PostEventRegistrationAutoAssignmentDeleteBody,
  PostEventRegistrationAutoAssignmentDeleteQuery,
  PostEventRegistrationAutoAssignmentDeleteResponse,
} from '../../../common/api/v1/events/PostEventRegistrationAutoAssignmentDelete';
import { AbstractAutoAssignmentsService } from './auto-assignments.service';

@Injectable()
export class EventRegistrationAutoAssignmentsService extends AbstractAutoAssignmentsService {
  private _cache: CacheContainer<EventRegistrationAutoAssignment>;

  private readonly _separator = ', ';

  constructor(private apiService: ApiService, private cacheService: CacheService) {
    super();
    this._cache = this.cacheService.create<EventRegistrationAutoAssignment>({
      get: async (combinedId: string) => {
        const [event, id] = combinedId.split(this._separator);
        return await this.apiService
          .get<GetEventRegistrationAutoAssignmentQuery, GetEventRegistrationAutoAssignmentResponse | EventRegistrationAutoAssignment>(`/api/v1/events/${event}/autoassignments${id}`)
          .toPromise();
      },
      id: (value: EventRegistrationAutoAssignment) => `${value.event}${this._separator}${value._id}`,
      socketEvents: ['autoassignment:update'],
    });
  }

  getAutoAssignment(event: string, id: string): Promise<EventRegistrationAutoAssignment> {
    return this._cache.asPromise(`${event}${this._separator}${id}`);
  }

  async getAutoAssignments(query?: GetEventRegistrationAutoAssignmentsQuery, event?: string): Promise<GetEventRegistrationAutoAssignmentsResponse> {
    const autoAssignments = await this.apiService
      .get<GetEventRegistrationAutoAssignmentsQuery, GetEventRegistrationAutoAssignmentsResponse>(`/api/v1/events/${event}/autoassignments`, {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();

    this._cache.fill(autoAssignments.items);

    return autoAssignments;
  }

  createAutoAssignment(event: string, autoAssignment: EventRegistrationAutoAssignment): Promise<EventRegistrationAutoAssignment> {
    return this._cache.post<PostEventRegistrationAutoAssignmentsQuery, PostEventRegistrationAutoAssignmentsBody, PostEventRegistrationAutoAssignmentsResponse>(
      `/api/v1/events/${event}/autoassignments`,
      autoAssignment
    );
  }

  updateAutoAssignment(event: string, autoAssignment: EventRegistrationAutoAssignment): Promise<EventRegistrationAutoAssignment> {
    return this._cache.post<PostEventRegistrationAutoAssignmentQuery, PostEventRegistrationAutoAssignmentBody, PostEventRegistrationAutoAssignmentResponse>(
      `/api/v1/events/${event}/autoassignments/${autoAssignment?._id}`,
      autoAssignment
    );
  }

  deleteAutoAssignment(event: string, autoAssignment: EventRegistrationAutoAssignment): Promise<EventRegistrationAutoAssignment> {
    return this._cache.post<PostEventRegistrationAutoAssignmentDeleteQuery, PostEventRegistrationAutoAssignmentDeleteBody, PostEventRegistrationAutoAssignmentDeleteResponse>(
      `/api/v1/events/${event}/autoassignments/${autoAssignment?._id}/delete`,
      autoAssignment
    );
  }
}
