import { HeadlineType } from './../../entities/Attributes';
import { TextListPageModuleAttributes } from './../../entities/pagemodules/TextListPageModule';
import { TextListPageModule } from '../../entities/pagemodules/TextListPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class TextListPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async createTextListPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.textListPageModuleAttributes(values?.title_attr),
      text: values?.text || '',
      items: values?.items || [],
    };
  }

  title_attr?: TextListPageModuleAttributes;
  public textListPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): TextListPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async textListPageModule(values: any): Promise<TextListPageModule> {
    if (values?.itemIcon) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.itemIcon = item.itemIcon || values.itemIcon;
      });
    }

    const r = {
      ...(await this.baseValues(values)),
      pageModuleType: 'TextList',
      titlePosition: values?.titlePosition || 'content',
      itemIcon: undefined,
      listPosition: values?.listPosition || 'left',
      local: await this.local(values, (val) => this.createTextListPageModuleLocal(val)),
      ctaButton: values?.ctaButton || null,
    };

    return { ...r, pageModuleType: 'TextList' };
  }
}
