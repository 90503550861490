<div>
  <div class="tab-header">
    <h1>
      <span class="pre-title">{{ 'GENERAL_STATIC_CONTENT' | translate }}</span>
      <span>{{ staticContentConfiguration?.internalName }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <p-button icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" [disabled]="tab.loading || saving" (onClick)="save()"></p-button>
    <c-language-button
      *ngIf="!tab.loading"
      [(currentLanguage)]="languageEdit"
      [languages]="selectedLanguages"
      (languagesChange)="onLanguagesChange($event)"
      [disabled]="tab.loading || saving"
    ></c-language-button>
    <c-domain-button [configKey]="'static_contents'" [shortId]="shortId" [domainCollectionId]="domainCollectionId" (onDomainCollectionIdChange)="loadDialog($event)"></c-domain-button>
    <c-domain-button
      *hasNoRight="'configuration.edit'"
      [enableUnification]="false"
      [shortId]="shortId"
      [configKey]="'static_contents'"
      [domainCollectionId]="domainCollectionId"
      (onDomainCollectionIdChange)="loadDialog($event)"
    ></c-domain-button>
  </div>

  <div class="panel" *skeleton="{ show: tab.loading, type: 'table' }">
    <div class="panel-header">
      {{ 'GENERAL_LOCAL_PROPERTIES' | translate }}
    </div>
    <div *ngIf="staticContents" class="panel-body">
      <p-messages [value]="messages"></p-messages>

      <div *ngIf="staticContents" class="tiered-menu-content">
        <div class="p-fluid">
          <div class="p-field">
            <p-selectButton [options]="editor" [(ngModel)]="selectedEditor" optionLabel="name"></p-selectButton>
          </div>
        </div>

        <div class="p-grid gridinput" *ngFor="let part of staticContentConfiguration.parts | keys">
          <div class="input-group">
            <div class="input">
              <div *ngIf="staticContentConfiguration.parts[part] === 'string'">
                <textarea pInputTextarea [(ngModel)]="staticContents.local[languageEdit].parts[part]"></textarea>
              </div>

              <div *ngIf="staticContentConfiguration.parts[part] === 'html'">
                <div *ngIf="selectedEditor.code === 'editor'">
                  <p-editor [(ngModel)]="staticContents.local[languageEdit].parts[part]">
                    <p-header>
                      <span class="ql-formats">
                        <select class="ql-size">
                          <option value="small"></option>
                          <option selected></option>
                          <option value="large"></option>
                          <option value="huge"></option>
                        </select>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                        <button class="ql-strike"></button>
                      </span>
                      <span class="ql-formats">
                        <select class="ql-color" defaultValue="rgb(0, 0, 0)"></select>
                        <span class="ql-format-separator"></span>
                        <select class="ql-background" defaultValue="rgb(255, 255, 255)"></select>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                        <select class="ql-align">
                          <option selected></option>
                          <option value="center" label="Center"></option>
                          <option value="right" label="Right"></option>
                          <option value="justify" label="Justify"></option>
                        </select>
                      </span>
                    </p-header>
                  </p-editor>
                </div>
                <div *ngIf="selectedEditor.code === 'html'">
                  <textarea pInputTextarea [(ngModel)]="staticContents.local[languageEdit].parts[part]"></textarea>
                </div>
              </div>

              <div *ngIf="staticContentConfiguration.parts[part] === 'list'">
                <div *ngFor="let item of staticContents.local[languageEdit].parts[part]; let index = index; trackBy: listTrackBy">
                  <div class="p-inputgroup">
                    <textarea pInputTextarea [(ngModel)]="staticContents.local[languageEdit].parts[part][index]"></textarea>
                    <button type="button" pButton icon="pi pi-times" class="p-button-danger" (click)="deleteListItem(part, index)"></button>
                  </div>
                </div>
                <button type="button" pButton [label]="' ' + ('PAGE_STATIC_CONTENT_PART_LIST_ADD_ITEM' | translate)" icon="pi pi-plus" class="p-button" (click)="addListItem(part)"></button>
              </div>

              <div *ngIf="staticContentConfiguration.parts[part] === 'asset'">
                <c-asset-select inputWidth="100%" [(id)]="staticContents.local[languageEdit].parts[part]"></c-asset-select>
              </div>
            </div>
            <div class="title">{{ 'PAGE_STATIC_CONTENT_PART_' + part.toUpperCase() | translate }}</div>
            <div class="description">{{ 'PAGE_STATIC_CONTENT_PART_' + staticContentConfiguration.parts[part].toUpperCase() + '_DESCRIPTION' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
