import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableOptions, TableQuery } from 'src/app/components/table/table.interfaces';
import { SpeakersService } from 'src/app/services/speakers/speakers.service';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { Speaker } from 'src/common/entities/Speaker';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TableComponent } from '../../components/table/table.component';

@Component({
  selector: 'app-speakers',
  templateUrl: './speakers.component.html',
  styleUrls: ['./speakers.component.scss'],
})
export class SpeakersComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) table: TableComponent<Speaker>;

  tab: Tab;
  tableOptions: TableOptions<Speaker>;

  searchTextSubject: Subject<string> = new Subject<string>();
  searchText: string;

  constructor(private speakersService: SpeakersService, private tabsService: TabsService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.tab = this.tabsService.register({
      category: 'data',
      route: this.activatedRoute.snapshot,
      title: 'Speakers',
    });

    this.tableOptions = {
      size: 50,
      columns: [
        { header: 'PAGE_SPEAKERS_TABLE_COLUMN_LASTNAME', sort: 'lastName', visible: 'fixed' },
        { header: 'PAGE_SPEAKERS_TABLE_COLUMN_FIRSTNAME', sort: 'firstName' },
        { header: 'PAGE_SPEAKERS_TABLE_COLUMN_COMPANY', sort: 'company' },
        { header: 'PAGE_SPEAKERS_TABLE_COLUMN_LANGUAGES' },
        { header: 'PAGE_SPEAKERS_TABLE_COLUMN_TITLE', sort: 'local.en.title' },
        { header: 'PAGE_SPEAKERS_TABLE_COLUMN_SHORTDESCRIPTION', sort: 'local.en.shortDescription' },
        { header: 'PAGE_SPEAKERS_TABLE_COLUMN_TYPE', sort: 'type', visible: 'fixed' },
      ],
      filters: [
        { header: 'PAGE_SPEAKERS_TABLE_COLUMN_LASTNAME', path: 'lastName' },
        { header: 'PAGE_SPEAKERS_TABLE_COLUMN_FIRSTNAME', path: 'firstName' },
        { header: 'PAGE_SPEAKERS_TABLE_COLUMN_DISPLAY_NAME', path: 'displayName' },
        { header: 'PAGE_SPEAKERS_TABLE_COLUMN_COMPANY', path: 'company' },
        { header: 'PAGE_SPEAKERS_TABLE_COLUMN_TITLE', path: 'local.en.title' },
        { header: 'PAGE_SPEAKERS_TABLE_COLUMN_SHORTDESCRIPTION', path: 'local.en.shortDescription' },
        { header: 'PAGE_SPEAKERS_TABLE_COLUMN_TYPE', path: 'type' },
      ],
    };
  }

  subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.subscriptions.push(this.searchTextSubject.pipe(debounceTime(500)).subscribe(() => this.refresh(!this.searchText)));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  query(query: TableQuery<Speaker>) {
    if (this.searchText) {
      query.query.filter.displayName = {
        matchMode: 'contains',
        caseInsensitive: true,
        value: this.searchText,
      };
    }
    query.result = this.speakersService.getSpeakers(query.query);
  }

  newSpeaker() {
    this.router.navigate(['/speakers/new']);
  }

  handleTextKeyUp() {
    this.searchTextSubject.next();
  }

  async refresh(clear = false): Promise<void> {
    if (clear) {
      await this.table.clearFilter();
    } else {
      await this.table.refresh();
    }
  }
}
