import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GetAuthQuery, GetAuthResponse } from 'src/common/api/v1/auth/GetAuth';
import { GetPluginOAuthQuery, GetPluginOAuthResponse } from 'src/common/api/v1/auth/GetPluginOAuth';
import { PostHasRightBody, PostHasRightQuery, PostHasRightResponse } from 'src/common/api/v1/auth/PostHasRight';
import { PostLoginAdBody, PostLoginAdQuery, PostLoginAdResponse } from 'src/common/api/v1/auth/PostLoginAd';
import { PostPluginOAuthBody, PostPluginOAuthQuery, PostPluginOAuthResponse } from 'src/common/api/v1/auth/PostPluginOAuth';
import { AdminUser } from 'src/common/entities/AdminUser';
import { ApiService } from '../api/api.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _currentAdminUser: BehaviorSubject<AdminUser> = new BehaviorSubject<AdminUser>(null);
  private _hasRight: { [right: string]: Promise<boolean> } = {};

  constructor(private apiService: ApiService, private utilsService: UtilsService) {}

  async loginAd(msalToken: string) {
    const login = await this.apiService
      .post<PostLoginAdQuery, PostLoginAdBody, PostLoginAdResponse>(
        '/api/v1/auth/login/ad',
        {},
        {},
        {
          Authorization: `Bearer ${msalToken}`,
        }
      )
      .toPromise();

    if (login.successful) {
      localStorage.setItem('token', login.token);
      return !!(await this.checkAccount());
    }

    return false;
  }

  async login(email: string, password) {
    const login = await this.apiService
      .post<PostLoginAdQuery, PostLoginAdBody, PostLoginAdResponse>(
        '/api/v1/auth/login',
        {
          email,
          password,
        },
        {}
      )
      .toPromise();

    if (login.successful) {
      localStorage.setItem('token', login.token);
      return !!(await this.checkAccount());
    }

    return false;
  }

  async getPluginOAuth(query: GetPluginOAuthQuery) {
    return await this.apiService.get<GetPluginOAuthQuery, GetPluginOAuthResponse>('/api/v1/auth/plugin/oauth', query).toPromise();
  }

  async postPluginOAuth(body: PostPluginOAuthBody) {
    return await this.apiService.post<PostPluginOAuthQuery, PostPluginOAuthBody, PostPluginOAuthResponse>('/api/v1/auth/plugin/oauth', body).toPromise();
  }

  currentAdminUser(): AdminUser {
    return this._currentAdminUser.getValue();
  }

  adminUser(): Observable<AdminUser> {
    return this._currentAdminUser.asObservable();
  }

  hasGlobalRight(right: string | string[]): boolean {
    return this.currentAdminUser()?.admin || (Array.isArray(right) ? right : [right]).findIndex((r) => this.currentAdminUser() && this.currentAdminUser()?.rights?.includes(r)) >= 0;
  }

  private _collectedHasRightExecution = async (inputs: (string | string[])[]): Promise<boolean[]> => {
    let result;
    try {
      result = await this.apiService
        .post<PostHasRightQuery, PostHasRightBody, PostHasRightResponse>('/api/v1/auth/hasright', {
          forEach: inputs,
        })
        .toPromise();
    } catch {
      result = null;
    }

    return result?.forEachHasRight || [];
  };

  /**
   * Returns true if the user has one of the global or resource rights
   * @param right a list of identifiers, e.g. events:123abc456def$events.edit, appointments.list
   */
  async hasRight(right: string | string[]): Promise<boolean> {
    const rights = Array.isArray(right) ? right : [right];

    if (this.hasGlobalRight(rights.map((r) => r.split('$').slice(-1)[0]))) return true;
    if ((await Promise.all(rights.map((r) => this._hasRight[r] || Promise.resolve(false)))).find((r) => r)) return true;

    for (const r of rights) {
      if (!this._hasRight[r]) {
        this._hasRight[r] = new Promise<boolean>(async (resolve: (result: boolean) => void, reject: (err: any) => void) => {
          try {
            resolve(await this.utilsService.collectedRequest('/api/v1/auth/hasright', r, this._collectedHasRightExecution));
          } catch (err) {
            console.error(err);
            resolve(false);
          }
        });
      }
    }

    return !!(await Promise.all(rights.map((r) => this._hasRight[r]))).find((r) => r);
  }

  async checkAccount(): Promise<AdminUser> {
    try {
      const adminUser = await this.apiService.get<GetAuthQuery, GetAuthResponse>('/api/v1/auth').toPromise();
      this._currentAdminUser.next(adminUser);
      return adminUser;
    } catch (err) {
      console.error(err);
    }

    return null;
  }

  logout() {
    localStorage.removeItem('token');
    this._currentAdminUser.next(null);
  }
}
