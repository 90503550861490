import { PostMapPreviewsBody, PostMapPreviewsQuery, PostMapPreviewsResponse } from './../../../common/api/v1/configuration/maps/PostMapPreviews';
import { GetMapPreviewsQuery, GetMapPreviewsResponse } from './../../../common/api/v1/configuration/maps/GetMapPreviews';
import { PostPagePageVersionPatchResponse } from 'src/common/api/v1/configuration/pages/PostPagePageVersionPatch';
import { PostMapMapVersionPatchBody, PostMapMapVersionPatchQuery } from './../../../common/api/v1/configuration/maps/PostMapMapVersionPatch';
import { Patch } from './../../../common/patch/Patch';
import { PostMapMapVersionPublishBody, PostMapMapVersionPublishQuery, PostMapMapVersionPublishResponse } from './../../../common/api/v1/configuration/maps/PostMapMapVersionPublish';
import { GetMapMapVersionsQuery, GetMapMapVersionsResponse } from './../../../common/api/v1/configuration/maps/GetMapMapVersions';
import { PatchExecutor } from 'src/common/patch/PatchExecutor';
import { ApiSocketService } from './../api-socket/api-socket.service';
import { MapVersionPatch } from './../../../common/api/v1/websocket/MapVersionPatch';
import { BehaviorSubject, Observable, ReplaySubject, using } from 'rxjs';
import { GetMapMapVersionQuery, GetMapMapVersionResponse } from './../../../common/api/v1/configuration/maps/GetMapMapVersion';
import { PostMapsBody, PostMapsQuery, PostMapsResponse } from './../../../common/api/v1/configuration/maps/PostMaps';
import { Map, MapVersion } from './../../../common/entities/Map';
import { GetMapsQuery, GetMapsResponse } from './../../../common/api/v1/configuration/maps/GetMaps';
import { ApiService } from 'src/app/services/api/api.service';
import { Injectable } from '@angular/core';
import { PostMapBody, PostMapQuery, PostMapResponse } from './../../../common/api/v1/configuration/maps/PostMap';
import { GetMapQuery, GetMapResponse } from 'src/common/api/v1/configuration/maps/GetMap';
import * as uuid from 'uuid';
import { GetMapMapVersionOverlaysQuery, GetMapMapVersionOverlaysResponse } from 'src/common/api/v1/configuration/maps/GetMapMapVersionOverlays';
import { GetMapMapVersionMarkerIconsQuery, GetMapMapVersionMarkerIconsResponse } from 'src/common/api/v1/configuration/maps/GetMapMapVersionMarkerIcons';
import { GetMapMapVersionBeaconGroupsQuery, GetMapMapVersionBeaconGroupsResponse } from 'src/common/api/v1/configuration/maps/GetMapMapVersionBeaconGroups';
import { GetMapMapVersionBeaconsQuery, GetMapMapVersionBeaconsResponse } from 'src/common/api/v1/configuration/maps/GetMapMapVersionBeacons';
import { GetMapMapVersionMarkerGroupsQuery, GetMapMapVersionMarkerGroupsResponse } from 'src/common/api/v1/configuration/maps/GetMapMapVersionMarkerGroups';
import { GetDiffQuery, GetDiffResponse } from 'src/common/api/v1/diff/GetDiff';

@Injectable({
  providedIn: 'root',
})
export class MapsService {
  private _maps: {
    [mapId: string]: {
      subject: ReplaySubject<Map>;
      observable: Observable<Map>;
    };
  } = {};

  private _mapVersions: {
    [mapVersionId: string]: BehaviorSubject<MapVersion>;
  } = {};
  private _lastMapVersionPatch: {
    [mapVersionId: string]: ReplaySubject<MapVersionPatch>;
  } = {};

  private _patches: {
    [id: string]: {
      resolve: (result: boolean) => void;
      reject: (err: any) => void;
    };
  } = {};

  constructor(private apiService: ApiService, private apiSocketService: ApiSocketService) {
    this.apiSocketService.on('connect', () => {
      [...new Set(Object.keys(this._maps).concat(Object.values(this._mapVersions).map((v) => v.getValue().map)))].forEach((mapId) => {
        this.apiSocketService.emit('map:register', { map: mapId });
      });
    });

    this.apiSocketService.on<Map>('map:update', (map) => {
      if (this._maps[map._id]) {
        this._maps[map._id].subject.next(map);
      }
    });

    this.apiSocketService.on<MapVersionPatch>('mapversion:patch', async (mapVersionPatch) => {
      if (this._mapVersions[mapVersionPatch.mapVersion]) {
        const mapVersion = this._mapVersions[mapVersionPatch.mapVersion].getValue();

        try {
          if ((mapVersion.change || 0) === mapVersionPatch.change - 1) {
            // if (mapVersionPatch.patch.command === 'set' && mapVersionDateFields.includes(pageVersionPatch.patch.jsonpath) && pageVersionPatch.patch.value) {
            //   pageVersionPatch.patch.value = new Date(pageVersionPatch.patch.value);
            // }

            for (const patch of mapVersionPatch.previousPatches || []) {
              PatchExecutor.patch(mapVersion, patch);
            }

            PatchExecutor.patch(mapVersion, mapVersionPatch.patch);

            for (const patch of mapVersionPatch.subsequentPatches || []) {
              PatchExecutor.patch(mapVersion, patch);
            }

            // this.convertPageVersionDates(pageVersion);
            mapVersion.change = mapVersionPatch.change;
            this._mapVersions[mapVersionPatch.mapVersion].next(mapVersion);

            if (!this._lastMapVersionPatch[mapVersionPatch.mapVersion]) {
              this._lastMapVersionPatch[mapVersionPatch.mapVersion] = new ReplaySubject(1);
            }
            this._lastMapVersionPatch[mapVersionPatch.mapVersion].next(mapVersionPatch);

            if (this._patches[mapVersionPatch.patch.id]) {
              this._patches[mapVersionPatch.patch.id].resolve(true);
              delete this._patches[mapVersionPatch.patch.id];
              return;
            }
          } else {
            await this.getMapVersion(mapVersionPatch.map, mapVersionPatch.mapVersion);
          }
        } catch (err) {
          console.error(err);
          await this.getMapVersion(mapVersionPatch.map, mapVersionPatch.mapVersion);
        }
      }

      if (this._patches[mapVersionPatch.patch.id]) {
        this._patches[mapVersionPatch.patch.id].resolve(false);
        delete this._patches[mapVersionPatch.patch.id];
      }
    });
  }

  getMap(id: string): Promise<Map> {
    return this.apiService.get<GetMapQuery, GetMapResponse>(`/api/v1/configuration/maps/${id}`).toPromise();
  }

  getDiff(firstVersion: string, secondVersion: string): Promise<GetDiffResponse> {
    return this.apiService.get<GetDiffQuery, GetDiffResponse>(`/api/v1/configuration/mapversions/${firstVersion}/diff`, { compareVersion: secondVersion }).toPromise();
  }

  private ensureMapVersion(mapVersion: MapVersion): MapVersion {
    // this.convertPageVersionDates(pageVersion);

    if (!this._mapVersions[mapVersion._id]) {
      this._mapVersions[mapVersion._id] = new BehaviorSubject<MapVersion>(mapVersion);
    } else {
      this._mapVersions[mapVersion._id].next(mapVersion);
    }

    return mapVersion;
  }

  async getMapVersion(mapId: string, mapVersionId: string): Promise<MapVersion> {
    return this.ensureMapVersion(await this.apiService.get<GetMapMapVersionQuery, GetMapMapVersionResponse>(`/api/v1/configuration/maps/${mapId}/mapversions/${mapVersionId}`).toPromise());
  }

  getMaps(query?: GetMapsQuery): Observable<GetMapsResponse> {
    return this.apiService.get<GetMapsQuery, GetMapsResponse>('/api/v1/configuration/maps', {
      limit: 50,
      skip: 0,
      ...query,
    });
  }

  getMapVersions(mapId: string, query?: GetMapMapVersionsQuery): Promise<GetMapMapVersionsResponse> {
    return this.apiService.get<GetMapMapVersionsQuery, GetMapMapVersionsResponse>(`/api/v1/configuration/maps/${mapId}/mapversions`, query).toPromise();
  }

  async publishMapVersion(mapId: string, mapVersionId: string): Promise<MapVersion> {
    return this.ensureMapVersion(
      await this.apiService
        .post<PostMapMapVersionPublishQuery, PostMapMapVersionPublishBody, PostMapMapVersionPublishResponse>(`/api/v1/configuration/maps/${mapId}/mapversions/${mapVersionId}/publish`, {})
        .toPromise()
    );
  }

  async saveMapVersion(mapId: string, mapVersionId: string): Promise<MapVersion> {
    return this.ensureMapVersion(
      await this.apiService
        .post<PostMapMapVersionPublishQuery, PostMapMapVersionPublishBody, PostMapMapVersionPublishResponse>(`/api/v1/configuration/maps/${mapId}/mapversions/${mapVersionId}/save`, {})
        .toPromise()
    );
  }

  async forkMapVersion(mapId: string, mapVersionId: string): Promise<MapVersion> {
    return this.ensureMapVersion(
      await this.apiService
        .post<PostMapMapVersionPublishQuery, PostMapMapVersionPublishBody, PostMapMapVersionPublishResponse>(`/api/v1/configuration/maps/${mapId}/mapversions/${mapVersionId}/fork`, {})
        .toPromise()
    );
  }

  createMap(map: Map): Promise<Map> {
    return this.apiService.post<PostMapsQuery, PostMapsBody, PostMapsResponse>(`/api/v1/configuration/maps`, map).toPromise();
  }

  updateMap(map: Map): Promise<Map> {
    return this.apiService.post<PostMapQuery, PostMapBody, PostMapResponse>(`/api/v1/configuration/maps/${map._id}`, map).toPromise();
  }

  async deleteMap(map: Map): Promise<Map> {
    return await this.apiService.post<PostMapQuery, PostMapBody, PostMapResponse>(`/api/v1/configuration/maps/${map._id}/delete`, map).toPromise();
  }

  subscribeMap(mapId: string): Observable<Map> {
    if (!this._maps[mapId]) {
      const subject = new ReplaySubject<Map>(1);
      const observable = using(
        () => {
          return {
            unsubscribe: () => {
              if (subject.observers.length === 0) {
                delete this._maps[mapId];
                subject.complete();
                this.apiSocketService.emit('map:unregister', { map: mapId });
              }
            },
          };
        },
        () => {
          return subject;
        }
      );

      this._maps[mapId] = {
        subject: subject,
        observable: observable,
      };

      this.apiSocketService.emit('map:register', { map: mapId });

      this.getMap(mapId).then((map) => {
        subject.next(map);
      });
    }

    return this._maps[mapId].observable;
  }

  public lastMapVersionPatch(mapVersion: string): Observable<MapVersionPatch> {
    if (!this._lastMapVersionPatch[mapVersion]) {
      this._lastMapVersionPatch[mapVersion] = new ReplaySubject(1);
    }
    return this._lastMapVersionPatch[mapVersion].asObservable();
  }

  async patch(mapVersion: MapVersion, patch: Patch): Promise<boolean> {
    return new Promise<boolean>(async (resolve: (result: boolean) => void, reject: (err: any) => void) => {
      patch.id = uuid.v4();
      this._patches[patch.id] = { resolve, reject };

      const result = await this.apiService
        .post<PostMapMapVersionPatchQuery, PostMapMapVersionPatchBody, PostPagePageVersionPatchResponse>(`/api/v1/configuration/maps/${mapVersion.map}/mapversions/${mapVersion._id}/patch`, patch)
        .toPromise();

      if (!result.successful) {
        delete this._patches[patch.id];
        resolve(false);
      } else {
        setTimeout(() => {
          if (this._patches[patch.id]) {
            delete this._patches[patch.id];
            reject(new Error('Timeout'));
          }
        }, 10000);
      }
    });
  }

  public async getPreviews(map: string, query?: GetMapPreviewsQuery): Promise<GetMapPreviewsResponse> {
    return this.apiService.get<GetMapPreviewsQuery, GetMapPreviewsResponse>(`/api/v1/configuration/maps/${map}/previews`, query).toPromise();
  }

  public async createPreview(map: string, preview: PostMapPreviewsBody): Promise<PostMapPreviewsResponse> {
    return this.apiService.post<PostMapPreviewsQuery, PostMapPreviewsBody, PostMapPreviewsResponse>(`/api/v1/configuration/maps/${map}/previews`, preview).toPromise();
  }
}
