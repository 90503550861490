import { Injectable } from '@angular/core';
import { GetKeytopicQuery, GetKeytopicResponse } from 'src/common/api/v1/keytopics/GetKeytopic';
import { GetKeytopicsQuery, GetKeytopicsResponse } from 'src/common/api/v1/keytopics/GetKeytopics';
import { PostKeytopicBody, PostKeytopicQuery, PostKeytopicResponse } from 'src/common/api/v1/keytopics/PostKeytopic';
import { PostKeytopicsBody, PostKeytopicsQuery, PostKeytopicsResponse } from 'src/common/api/v1/keytopics/PostKeytopics';
import { Keytopic } from 'src/common/entities/Keytopic';
import { ApiService } from '../api/api.service';
import { CacheContainer } from '../cache/cache-container';
import { CacheService } from '../cache/cache.service';

@Injectable({
  providedIn: 'root',
})
export class KeytopicsService {
  private _cache: CacheContainer<Keytopic>;

  constructor(private apiService: ApiService, private cacheService: CacheService) {
    this._cache = this.cacheService.create<Keytopic>({
      get: async (keytopicId) => await this.apiService.get<GetKeytopicQuery, GetKeytopicResponse>(`/api/v1/keytopics/${keytopicId}`).toPromise(),
      socketEvents: ['keytopic:update'],
    });
  }

  getKeytopic(keytopicId: string): Promise<Keytopic> {
    return this._cache.asPromise(keytopicId);
  }

  async getKeytopics(query?: GetKeytopicsQuery): Promise<GetKeytopicsResponse> {
    const keytopics = await this.apiService
      .get<GetKeytopicsQuery, GetKeytopicsResponse>('/api/v1/keytopics', {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();

    this._cache.fill(keytopics.items);

    return keytopics;
  }

  createKeytopic(keytopic: Keytopic): Promise<Keytopic> {
    return this._cache.post<PostKeytopicsQuery, PostKeytopicsBody, PostKeytopicsResponse>('/api/v1/keytopics', keytopic);
  }

  updateKeytopic(keytopic: Keytopic): Promise<Keytopic> {
    return this._cache.post<PostKeytopicQuery, PostKeytopicBody, PostKeytopicResponse>(`/api/v1/keytopics/${keytopic._id}`, keytopic);
  }

  deleteKeytopic(keytopic: Keytopic): Promise<Keytopic> {
    return this._cache.post<PostKeytopicQuery, PostKeytopicBody, PostKeytopicResponse>(`/api/v1/keytopics/${keytopic._id}/delete`, keytopic);
  }
}
