export const personTypes = ['Speaker', 'Attendee'];
export type PersonType = (typeof personTypes)[number];
export const externalAccountTypes = ['Custom', 'LinkedIn', 'Xing', 'Twitter'] as const;
export type ExternalAccountType = (typeof externalAccountTypes)[number];

export interface ExternalAccount {
  externalAccountType: ExternalAccountType;
  link: string;
}

export interface DefaultExternalAccount extends ExternalAccount {
  externalAccountType: 'LinkedIn' | 'Xing' | 'Twitter';
}

export interface CustomExternalAccount extends ExternalAccount {
  externalAccountType: 'Custom';
  icon: string;

  local: {
    [language: string]: {
      title: string;
    };
  };
}

export interface SpeakerContent {
  firstName: string;
  lastName: string;
  company: string;
  picture: string;
  email: string;
  externalProfileLink?: string;
  skills: string[];

  externalAccounts: ExternalAccount[];

  local: {
    [language: string]: {
      title: string;
      shortDescription: string;
      longDescription: string;
    };
  };

  // calculated
  displayName?: string;
}

export interface Speaker extends SpeakerContent {
  _id?: string;
  type: PersonType;
}
