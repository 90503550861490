<div class="content">
  <!-- Page Header with Add Button -->
  <div class="page-header">
    <h1>
      <span class="header">
        {{ 'PAGE_TEMPLATES_CONFIGURATION' | translate }}
        <span class="subheader" *ngIf="loading">{{ 'GENERAL_LOADING' | translate }} ...</span>
      </span>
    </h1>
    <p-divider align="right">
      <div class="divider-menu">
        <p-button styleClass="p-button-text" [routerLink]="['./new']" [label]="'PAGE_TEMPLATES_CONFIGURATION_ADD' | translate" [disabled]="true"></p-button>
      </div>
    </p-divider>
  </div>

  <!-- Templates Table -->
  <div>
    <p-table
      [value]="templatesConfigurations"
      [lazy]="true"
      (onLazyLoad)="retrieveData($event)"
      [paginator]="false"
      [rows]="50"
      [totalRecords]="totalRecords"
      [loading]="loading"
      styleClass="p-datatable-sm"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'PAGE_TEMPLATE_CONFIGURATION_INTERNALNAME' | translate }}</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-templateConfig>
        <tr>
          <td>{{ templateConfig.value.internalName }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
