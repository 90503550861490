import { TextAttributes } from './../Attributes';
import { PageModule, TitlePositions } from './PageModule';
import { CTAButton } from '../CTAButton';

export interface TextImagePageModule extends PageModule {
  pageModuleType: 'TextImage';
  titlePosition?: TitlePositions | 'content';
  local: { [language: string]: TextImagePageModuleLocal };
  imageSize: 'large' | 'medium';
  imagePosition: 'left' | 'right';
  imagePositionWithinImageArea: 'left' | 'right' | 'center';
  ctaButton?: CTAButton;
  imagePositionMobile?: 'top' | 'bottom';
}

export type TextImagePageModuleAttributes = TextAttributes & {};

export interface TextImagePageModuleLocal {
  title: string;
  title_attr?: TextImagePageModuleAttributes;
  text: string;
  image: string;
}

export function isTextImagePageModule(object: any): object is TextImagePageModule {
  return object?.pageModuleType === 'TextImage';
}
