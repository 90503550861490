import { Component } from '@angular/core';
import { Dialog } from 'primeng/dialog';
import { AppointmentsService } from 'src/app/services/appointments/appointments.service';
import { I18nService } from 'src/app/services/i18n/i18n.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent {
  displayAppointmentDetail: boolean = false;

  constructor(private appointmentsService: AppointmentsService, public utilsService: UtilsService, public i18nService: I18nService) {}

  startAdhocAppointment() {
    this.displayAppointmentDetail = true;
  }

  showDialogMaximized(event, dialog: Dialog) {
    dialog.maximized = true;
  }
}
