export const loginTypes = ['Local', 'AzureAD', 'AppAzureAD', 'OpenIdConnect'] as const;
export type LoginType = (typeof loginTypes)[number];

export interface LoginConfiguration {
  logins: Login[];
  defaults: { [type: string]: number | null };
}

export interface Login {
  loginType: LoginType;
  enabled: boolean;
}

export interface LocalLogin extends Login {
  loginType: 'Local';
}

export interface AzureADLogin extends Login {
  loginType: 'AzureAD';
  splitLogin: boolean;
  clientId: string;
  redirectURI: string;
  logoutRedirectURI: string;
  authority: string;
  authorityRegister: string;
  knownAuthorities: string[];
}

export interface AppAzureADLogin extends Login {
  loginType: 'AppAzureAD';
  splitLogin: boolean;
  clientId: string;
  redirectURI: string;
  logoutRedirectURI: string;
  authority: string;
  authorityRegister: string;
  knownAuthorities: string[];
}

export interface OpenIdConnectLogin extends Login {
  loginType: 'OpenIdConnect';
  clientId: string;
  redirectURI: string;
  authority: string;
  authorityRegister: string;
  issuer: string;
}

export function isLocalLogin(obj: any): obj is LocalLogin {
  return obj?.loginType === 'Local';
}

export function isAzureADLogin(obj: any): obj is AzureADLogin {
  return obj?.loginType === 'AzureAD';
}

export function isAppAzureADLogin(obj: any): obj is AppAzureADLogin {
  return obj?.loginType === 'AppAzureAD';
}

export function isOpenIdConnectLogin(obj: any): obj is OpenIdConnectLogin {
  return obj?.loginType === 'OpenIdConnect';
}
