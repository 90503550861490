<div class="element-header" [class.active]="active">
  <button (click)="close()" [disabled]="!active">
    <i class="pi pi-angle-double-left"></i>
  </button>
  {{ element.header }}
</div>
<div class="sidebar-body">
  <ng-template #comp></ng-template>
  <div #elementContainer></div>
</div>
