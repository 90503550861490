import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { CacheContainer } from '../cache/cache-container';
import { CacheService } from '../cache/cache.service';
import { GlobalTicket } from 'src/common/entities/GlobalTicket';
import { GetGlobalTicketQuery, GetGlobalTicketResponse } from 'src/common/api/v1/configuration/global-tickets/GetGlobalTicket';
import { GetGlobalTicketsQuery, GetGlobalTicketsResponse } from 'src/common/api/v1/configuration/global-tickets/GetGlobalTickets';
import { PostGlobalTicketsBody, PostGlobalTicketsQuery, PostGlobalTicketsResponse } from 'src/common/api/v1/configuration/global-tickets/PostGlobalTickets';
import { PostGlobalTicketBody, PostGlobalTicketQuery, PostGlobalTicketResponse } from 'src/common/api/v1/configuration/global-tickets/PostGlobalTicket';

@Injectable({
  providedIn: 'root',
})
export class GlobalTicketsService {
  private _cache: CacheContainer<GlobalTicket>;

  constructor(private apiService: ApiService, private cacheService: CacheService) {
    this._cache = this.cacheService.create<GlobalTicket>({
      get: async (globalticketId) => await this.apiService.get<GetGlobalTicketQuery, GetGlobalTicketResponse>(`/api/v1/configuration/global-tickets/${globalticketId}`).toPromise(),
      socketEvents: ['globalticket:update'],
    });
  }

  getGlobalTicket(globalticketId: string): Promise<GlobalTicket> {
    return this._cache.asPromise(globalticketId);
  }

  async getGlobalTickets(query?: GetGlobalTicketsQuery): Promise<GetGlobalTicketsResponse> {
    const globalTickets = await this.apiService
      .get<GetGlobalTicketsQuery, GetGlobalTicketsResponse>('/api/v1/configuration/global-tickets', {
        ...query,
      })
      .toPromise();
    this._cache.fill(globalTickets.items);
    return globalTickets;
  }

  createGlobalTicket(ticket: GlobalTicket): Promise<GlobalTicket> {
    return this._cache.post<PostGlobalTicketsQuery, PostGlobalTicketsBody, PostGlobalTicketsResponse>('/api/v1/configuration/global-tickets', ticket);
  }

  updateGlobalTicket(ticket: GlobalTicket): Promise<GlobalTicket> {
    return this._cache.post<PostGlobalTicketQuery, PostGlobalTicketBody, PostGlobalTicketResponse>(`/api/v1/configuration/global-tickets/${ticket._id}`, ticket);
  }

  deleteGlobalTicket(ticket: GlobalTicket): Promise<GlobalTicket> {
    return this._cache.post<PostGlobalTicketQuery, PostGlobalTicketBody, PostGlobalTicketResponse>(`/api/v1/configuration/global-tickets/${ticket._id}/delete`, ticket);
  }
}
