import { AfterViewInit, Component, ComponentFactoryResolver, ElementRef, HostBinding, Input, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { SidebarService } from 'src/app/services/sidebar/sidebar';

export type SidebarElement = {
  id: string;
  header?: string;
  destroyed?: () => void;
  destroyedCalled: boolean;
} & (
  | {
      component: any;
      params?: any;
    }
  | {
      elementRef: ElementRef;
    }
);

@Component({
  selector: 'c-sidebar-element',
  templateUrl: './sidebar-element.component.html',
  styleUrls: ['./sidebar-element.component.scss'],
})
export class SidebarElementComponent implements OnDestroy, AfterViewInit {
  @ViewChild('comp', { read: ViewContainerRef })
  child: ViewContainerRef;

  @ViewChild('elementContainer')
  elementContainer: ElementRef<HTMLDivElement>;

  @Input()
  element: SidebarElement;

  @Input()
  active: boolean = false;

  @Input()
  @HostBinding('class.first')
  first: boolean = false;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private elementRef: ElementRef, private sidebarService: SidebarService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.child.clear();

      if ('component' in this.element) {
        const factory = this.componentFactoryResolver.resolveComponentFactory(this.element.component);
        const ref = this.child.createComponent(factory);
        Object.assign(ref.instance, this.element.params || {});
      } else if ('elementRef' in this.element) {
        this.elementContainer?.nativeElement?.appendChild(this.element.elementRef.nativeElement);
      }
    });
  }

  ngOnDestroy() {
    this.sidebarService.remove(this.element);
  }

  close() {
    this.sidebarService.remove(this.element);
  }
}
