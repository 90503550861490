import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, using } from 'rxjs';
import { GetChatQuery, GetChatResponse } from 'src/common/api/v1/chats/GetChat';
import { GetChatMessagesQuery, GetChatMessagesResponse } from 'src/common/api/v1/chats/GetChatMessages';
import { GetChatsQuery, GetChatsResponse } from 'src/common/api/v1/chats/GetChats';
import { PostChatMessageConfirmBody, PostChatMessageConfirmQuery, PostChatMessageConfirmResponse } from 'src/common/api/v1/chats/PostChatMessageConfirm';
import { PostChatMessageDeleteBody, PostChatMessageDeleteQuery, PostChatMessageDeleteResponse } from 'src/common/api/v1/chats/PostChatMessageDelete';
import { PostChatMessageRejectBody, PostChatMessageRejectQuery, PostChatMessageRejectResponse } from 'src/common/api/v1/chats/PostChatMessageReject';
import { PollMessageBody, PostChatMessagesBody, PostChatMessagesQuery, PostChatMessagesResponse } from 'src/common/api/v1/chats/PostChatMessages';
import { Chat } from 'src/common/entities/Chat';
import { Message, PollMessage } from 'src/common/entities/Message';
import { ApiSocketService } from '../api-socket/api-socket.service';
import { ApiService } from '../api/api.service';
import { PollsService } from '../polls/polls.service';

export const AllMessages = 'ALL';
export const OpenMessage = 'OPEN';
export const RejectedMessage = 'REJECTED';
export const DeletedMessage = 'DELETED';
export const ConfirmedMessage = 'CONFIRMED';
export const PollMessages = 'POLL';

export class ChatContainer {
  private _chat: Chat;
  private _messages: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
  private _pollMessages: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
  private _openMessages: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
  private _rejectedMessages: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
  private _deletedMessages: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
  private _limit = 100;
  private _limitLocks: any[] = [];

  getFilteredMessages(status: string): Message[] {
    if (status == AllMessages) {
      return [...this._messages.getValue(), ...this._rejectedMessages.getValue(), ...this._deletedMessages.getValue(), ...this._openMessages.getValue()].sort((m1, m2) => m1.counter - m2.counter);
    } else if (status == OpenMessage) {
      return this._openMessages.getValue();
    } else if (status == RejectedMessage) {
      return this._rejectedMessages.getValue();
    } else if (status == DeletedMessage) {
      return this._deletedMessages.getValue();
    } else if (status == PollMessages) {
      return this._pollMessages.getValue();
    } else {
      return this._messages.getValue();
    }
  }

  get openMessages(): Message[] {
    return this._openMessages.getValue();
  }

  get rejectedMessages(): Message[] {
    return this._rejectedMessages.getValue();
  }

  get deletedMessages(): Message[] {
    return this._deletedMessages.getValue();
  }

  get messages(): Message[] {
    return this._messages.getValue();
  }

  get pollMessages(): Message[] {
    return this._pollMessages.getValue();
  }

  constructor(chat: Chat) {
    this._chat = chat;
  }

  public asObservable(type: 'open' | 'rejected' | 'deleted' | 'confirmed' | 'poll'): Observable<Message[]> {
    switch (type) {
      case 'open':
        return this._openMessages.asObservable();
      case 'rejected':
        return this._rejectedMessages.asObservable();
      case 'deleted':
        return this._deletedMessages.asObservable();
      case 'confirmed':
        return this._messages.asObservable();
      case 'poll':
        return this._pollMessages.asObservable();
    }
  }

  private _addMessage(message: Message, target: Message[]) {
    // if there isnt a message or if counter is greater than the counter of the last entry
    const current = target.find((m) => m._id === message._id);
    if (current) {
      Object.assign(current, message);
    } else if (target.length === 0 || message.date > target[target.length - 1].date) {
      target.push(message);
    } else if (message.date <= target[0].date) {
      target.unshift(message);
    } else {
      for (let i = target.length - 1; i >= 0; i--) {
        if (message.date < target[i].date) {
          target.splice(i, 0, message);
        }
      }
    }

    this._cutMessages(target);
  }

  private _cutMessages(target: Message[]) {
    if (this.lockLimit.length === 0) {
      while (target.length > this._limit) {
        target.unshift();
      }
    }

    if (target === this._messages.getValue()) {
      this._messages.next(this._messages.getValue());
    }
    if (target === this._pollMessages.getValue()) {
      this._pollMessages.next(this._pollMessages.getValue());
    }
    if (target === this._openMessages.getValue()) {
      this._openMessages.next(this._openMessages.getValue());
    }
    if (target === this._rejectedMessages.getValue()) {
      this._rejectedMessages.next(this._rejectedMessages.getValue());
    }
  }

  public addMessage(message: Message) {
    if (message.messageState === OpenMessage) {
      this._addMessage(message, this._openMessages.getValue());
    } else {
      this.removeOpenMessage(message);

      if (message.messageState === RejectedMessage) {
        this.removeRejectedMessage(message);
        this._addMessage(message, this._rejectedMessages.getValue());
      } else {
        if (message.messageState === DeletedMessage) {
          this._addMessage(message, this._deletedMessages.getValue());
        } else {
          if (message.messageType === 'PollMessage') {
            this._addMessage(message, this._pollMessages.getValue());
          }
          this._addMessage(message, this._messages.getValue());
        }
      }
    }
  }

  public addMessages(messages: Message[]) {
    for (const message of messages) {
      this.addMessage(message);
    }
  }

  public removeMessage(messageId: string) {
    let index = this._messages.getValue().findIndex((m) => m._id === messageId);
    if (index >= 0) this._messages.getValue().splice(index, 1);

    index = this._pollMessages.getValue().findIndex((m) => m._id === messageId);
    if (index >= 0) this._pollMessages.getValue().splice(index, 1);
  }

  public removeOpenMessage(message: Message) {
    const index = this._openMessages.getValue().findIndex((m) => m._id === message._id);
    if (index >= 0) this._openMessages.getValue().splice(index, 1);
  }

  public removeRejectedMessage(message: Message) {
    let index = this._messages.getValue().findIndex((m) => m._id === message._id);
    if (index >= 0) this._messages.getValue().splice(index, 1);

    index = this._pollMessages.getValue().findIndex((m) => m._id === message._id);
    if (index >= 0) this._pollMessages.getValue().splice(index, 1);

    index = this._rejectedMessages.getValue().findIndex((m) => m._id === message._id);
    if (index >= 0) this._rejectedMessages.getValue().splice(index, 1);
  }

  public lockLimit(lock: any) {
    if (!this._limitLocks.includes(lock)) {
      this._limitLocks.push(lock);
    }
  }

  public unlockLimit(lock: any) {
    this._limitLocks = this._limitLocks.filter((l) => l !== lock);
    this._cutMessages(this._messages.getValue());
  }
}

@Injectable({
  providedIn: 'root',
})
export class ChatsService {
  private _chats: {
    [chatId: string]: {
      chatContainer: ChatContainer | undefined;
      subject: ReplaySubject<ChatContainer>;
      observable: Observable<ChatContainer>;
    };
  } = {};

  constructor(private apiService: ApiService, private apiSocketService: ApiSocketService, private pollsService: PollsService) {
    this.apiSocketService.on<Message>('chat:message', (message) => {
      if (this._chats[message.chat]) {
        this._chats[message.chat].chatContainer.addMessage(message);
      }
    });

    this.apiSocketService.on('connect', () => {
      for (const chatId of Object.keys(this._chats)) {
        this.apiSocketService.emit('chat:register', { chat: chatId });
      }
    });
  }

  chatContainer(chatId: string): Observable<ChatContainer> {
    if (!this._chats[chatId]) {
      const subject = new ReplaySubject<ChatContainer>(1);
      const observable = using(
        () => {
          return {
            unsubscribe: () => {
              if (subject.observers.length === 0) {
                delete this._chats[chatId];
                subject.complete();
                this.apiSocketService.emit('chat:unregister', { chat: chatId });
              }
            },
          };
        },
        () => {
          return subject;
        }
      );

      this._chats[chatId] = {
        chatContainer: null,
        subject: subject,
        observable: observable,
      };

      this.apiSocketService.emit('chat:register', { chat: chatId });

      this.getChat(chatId).then(async (chat) => {
        const messages = (await this.getChatMessages(chatId).toPromise()).items;
        this._chats[chatId].chatContainer = new ChatContainer(chat);

        if (this._chats[chatId] && this._chats[chatId].chatContainer) {
          this._chats[chatId]?.chatContainer?.addMessages(messages);
          subject.next(this._chats[chatId].chatContainer);
        }
      });
    }

    return this._chats[chatId].observable;
  }

  getChat(chatId: string): Promise<Chat> {
    return this.apiService.get<GetChatQuery, GetChatResponse>(`/api/v1/chats/${chatId}`).toPromise();
  }

  getChats(query?: GetChatsQuery): Promise<GetChatsResponse> {
    return this.apiService.get<GetChatsQuery, GetChatsResponse>('/api/v1/chats', query).toPromise();
  }

  getChatsByEventAndRegion(event: string, region: string): Promise<GetChatsResponse> {
    return this.getChats({
      filter: {
        event: { matchMode: 'equalsObjectId', value: event },
        region: { matchMode: 'equals', value: region },
      },
      limit: 1,
    });
  }

  getChatMessages(chatId: string, counter?: number): Observable<GetChatMessagesResponse> {
    return this.apiService.get<GetChatMessagesQuery, GetChatMessagesResponse>(`/api/v1/chats/${chatId}/messages`, {
      limit: 30,
      filter: {},
    });
  }

  async confirmChatMessage(chatId: string, messageId: string): Promise<Message> {
    const message = await this.apiService
      .post<PostChatMessageConfirmQuery, PostChatMessageConfirmBody, PostChatMessageConfirmResponse>(`/api/v1/chats/${chatId}/messages/${messageId}/confirm`, {})
      .toPromise();

    if (this._chats[chatId].chatContainer) this._chats[chatId].chatContainer.addMessage(message);

    return message;
  }

  async rejectChatMessage(chatId: string, messageId: string, rejectionReason?: string): Promise<Message> {
    const message = await this.apiService
      .post<PostChatMessageRejectQuery, PostChatMessageRejectBody, PostChatMessageRejectResponse>(`/api/v1/chats/${chatId}/messages/${messageId}/reject`, {
        rejectionReason,
      })
      .toPromise();

    if (this._chats[chatId].chatContainer) this._chats[chatId].chatContainer.addMessage(message);

    return message;
  }

  async createChatMessage(chatId: string, newMessage: PostChatMessagesBody): Promise<Message> {
    const message = await this.apiService.post<PostChatMessagesQuery, PostChatMessagesBody, PostChatMessagesResponse>(`/api/v1/chats/${chatId}/messages`, newMessage).toPromise();
    if (this._chats[chatId]?.chatContainer) this._chats[chatId].chatContainer.addMessage(message);
    return message;
  }

  async deleteChatMessage(chatId: string, messageId: string): Promise<Message> {
    const message = await this.apiService
      .post<PostChatMessageDeleteQuery, PostChatMessageDeleteBody, PostChatMessageDeleteResponse>(`/api/v1/chats/${chatId}/messages/${messageId}/delete`, {})
      .toPromise();
    if (this._chats[chatId].chatContainer) this._chats[chatId].chatContainer.removeMessage(messageId);
    return message;
  }

  async createChatPollMessage(chatId: string, newMessage: PollMessageBody): Promise<Message> {
    const message = await this.apiService.post<PostChatMessagesQuery, PollMessageBody, PostChatMessagesResponse>(`/api/v1/chats/${chatId}/messages`, newMessage).toPromise();
    if (this._chats[chatId]?.chatContainer) this._chats[chatId].chatContainer.addMessage(message);
    return message;
  }

  async pollClose(pollMessage: PollMessage): Promise<PollMessage> {
    const poll = await this.pollsService.close(pollMessage.data.poll._id);
    const message = { ...pollMessage };
    message.data.poll = poll;
    this._chats[pollMessage.chat].chatContainer.addMessage(message);
    return message;
  }

  async pollPublishResult(pollMessage: PollMessage): Promise<PollMessage> {
    const poll = await this.pollsService.publishResult(pollMessage.data.poll._id);
    const message = { ...pollMessage };
    message.data.poll = poll;
    this._chats[pollMessage.chat].chatContainer.addMessage(message);
    return message;
  }
}
