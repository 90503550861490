<div *skeleton="{ show: loading }">
  <div class="tab-header">
    <h1>
      <span class="pre-title">{{ 'GENERAL_EMAIL_TEMPLATE' | translate }}</span>
      <span>{{ emailTemplateConfiguration?.internalName }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <p-button *hasRight="'configuration.edit'" icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" [disabled]="loading" (onClick)="save()"></p-button>
    <c-language-button
      *hasRight="'configuration.edit'"
      [(currentLanguage)]="currentLanguage"
      [(languages)]="languages"
      (languagesChange)="onActiveLanguageChange($event)"
      [disabled]="false"
    ></c-language-button>
    <c-language-button
      *hasNoRight="'configuration.edit'"
      [(currentLanguage)]="currentLanguage"
      [(languages)]="languages"
      (languagesChange)="onActiveLanguageChange($event)"
      [disabled]="false"
      [manageLanguages]="false"
    ></c-language-button>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_LOCAL_PROPERTIES' | translate }}
    </div>

    <div *ngIf="emailTemplate" class="panel-body">
      <div class="input-group">
        <div class="input">
          <p-dropdown
            [(ngModel)]="emailTemplate.emailTemplateConfigurationId"
            [options]="emailTemplateTypes"
            [disabled]="disableConfigSelection"
            (ngModelChange)="emailTemplateTypeChange()"
          ></p-dropdown>
        </div>
        <div class="title">{{ 'PAGE_EMAIL_TEMPLATE_EMAIL_TEMPLATE_TYPE' | translate }}</div>
        <div class="description">{{ 'PAGE_EMAIL_TEMPLATE_EMAIL_TEMPLATE_TYPE_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [(ngModel)]="emailTemplate.internalName" />
        </div>
        <div class="title">{{ 'PAGE_EMAIL_TEMPLATE_INTERNAL_NAME' | translate }}</div>
        <div class="description">{{ 'PAGE_EMAIL_TEMPLATE_INTERNAL_NAME_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group switch">
        <div class="input">
          <p-checkbox [(ngModel)]="emailTemplate.default" [binary]="true"></p-checkbox>
        </div>
        <div class="title">{{ 'PAGE_EMAIL_TEMPLATE_DEFAULT' | translate }}</div>
        <div class="description">{{ 'PAGE_EMAIL_TEMPLATE_DEFAULT_DESCRIPTION' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_EMAIL_TEMPLATE_PROPERTIES' | translate }}
    </div>
    <div *ngIf="emailTemplate && emailTemplateConfiguration" class="panel-body">
      <div class="input-group" *ngIf="emailTemplateConfiguration?.variables">
        <div class="input">
          <div class="variables">
            <button
              pButton
              *ngFor="let variable of emailTemplateConfiguration?.variables"
              class="p-button-outlined variable-button"
              type="button"
              [label]="'{' + variable + '}'"
              (click)="clipboard.copy('{' + variable + '}')"
              [pTooltip]="'PAGE_EMAIL_TEMPLATE_VARIABLES_TOOLTIP' | translate"
            ></button>
          </div>
        </div>
        <div class="title">Variables</div>
      </div>
      <div class="input-group" *ngFor="let part of emailTemplateConfiguration?.parts | keys">
        <div class="input" *ngIf="emailTemplateConfiguration?.parts[part] === 'string'">
          <textarea
            [disabled]="loading"
            pInputTextarea
            [rows]="countRows(emailTemplate?.local[currentLanguage]?.parts[part], 2)"
            [(ngModel)]="emailTemplate?.local[currentLanguage]?.parts[part]"
          ></textarea>
        </div>
        <div class="input" *ngIf="emailTemplateConfiguration?.parts[part] === 'html'">
          <div class="html-control">
            <div *ngIf="!activatedHTMLEditor">
              <div (click)="activatedHTMLEditor = true" class="html-preview" [innerHTML]="getPreviewHTML(emailTemplate.local[currentLanguage].parts[part]) | safeHtml"></div>
            </div>
            <div class="html-editor" *ngIf="activatedHTMLEditor">
              <p-editor [(ngModel)]="emailTemplate.local[currentLanguage].parts[part]" bounds="body" #pEditor>
                <p-header>
                  <span class="ql-formats">
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-strike"></button>
                  </span>
                  <span class="ql-formats">
                    <select class="ql-color" defaultValue="rgb(0, 0, 0)"></select>
                    <span class="ql-format-separator"></span>
                    <select class="ql-background" defaultValue="rgb(255, 255, 255)"></select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <select class="ql-align">
                      <option selected></option>
                      <option value="center" label="Center"></option>
                      <option value="right" label="Right"></option>
                      <option value="justify" label="Justify"></option>
                    </select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-link"></button>
                  </span>
                  <span class="ql-formats html-control-close-button">
                    <button (click)="this.activatedHTMLEditor = false">Close</button>
                  </span>
                </p-header>
              </p-editor>
            </div>
          </div>
        </div>
        <div class="input" *ngIf="emailTemplateConfiguration.parts[part] === 'asset'">
          <c-asset-select inputWidth="100%" [(id)]="emailTemplate.local[currentLanguage].parts[part]"></c-asset-select>
        </div>
        <div class="title">{{ 'PAGE_EMAIL_TEMPLATE_PART_' + part.toUpperCase() | translate }}</div>
        <div class="description">{{ 'PAGE_EMAIL_TEMPLATE_PART_' + emailTemplateConfiguration.parts[part].toUpperCase() + '_DESCRIPTION' | translate }}</div>
      </div>
    </div>
    <div *ngIf="!emailTemplateConfiguration" class="panel-body panel-info">
      <p-message severity="warn" text="No Email Template Type selected"></p-message>
    </div>
  </div>
</div>
