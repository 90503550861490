<c-loading [loading]="disabled || !initialized" [hideTitle]="true" [ngStyle]="{ zIndex: 2, top: !initialized ? '10px' : '150px' }"></c-loading>
<div *ngIf="initialized">
  <div [ngStyle]="{ paddingTop: '5px' }" *ngFor="let orCondition of asAndCondition(query); let orConditionIndex = index">
    <div *ngFor="let andCondition of asFieldConditions(orCondition); let andConditionIndex = index; trackBy: trackByIndex">
      <div class="input-group" [ngStyle]="{ padding: '2px 8px' }">
        <div class="input">
          <p-dropdown (onChange)="save()" [options]="fieldTypes" [(ngModel)]="andCondition.field"></p-dropdown>
          <p-dropdown [ngStyle]="{ width: '10vw' }" (onChange)="save()" [options]="fieldConditionMapping[andCondition.field]" [(ngModel)]="andCondition.type"></p-dropdown>
          <input
            *ngIf="andCondition.field === 'Tag'"
            [escape]="false"
            tooltipPosition="bottom"
            [pTooltip]="getTaxonomyToolTip(tags[andCondition.value])"
            [value]="tags[andCondition.value]?.label"
            (click)="disabled || openTagSelect(orConditionIndex, andConditionIndex)"
          />

          <input *ngIf="andCondition.field !== 'Tag'" [(ngModel)]="andCondition.value" (change)="save()" />

          <div class="actions input" [ngStyle]="{ width: 'auto', borderTop: 'none', borderRight: 'none', borderBottom: 'none', padding: '4px', paddingTop: 0 }">
            <i disabled="" class="pi pi-trash" [ngStyle]="{ marginTop: '8px' }" (click)="disabled || removeAndCondition(query, orConditionIndex, andConditionIndex); $event.stopPropagation()"></i>
          </div>
        </div>
      </div>
      <ng-container *ngIf="andConditionIndex === orCondition.conditions.length - 1">
        <div class="p-d-flex" [ngStyle]="{ marginTop: '15px' }">
          <button
            [disabled]="disabled"
            pButton
            type="button"
            class="p-button-sm p-button-outlined"
            [ngStyle]="{ width: 'auto', margin: 'auto' }"
            [label]="'Add And-Condition'"
            icon="pi icon-ic-add-item"
            (click)="disabled || addAndCondition(query, orConditionIndex)"
          ></button>
        </div>
        <p-divider align="right"></p-divider>
      </ng-container>
    </div>

    <ng-container *ngIf="orConditionIndex === asAndCondition(query).length - 1">
      <div class="p-d-flex">
        <button
          [disabled]="disabled"
          pButton
          type="button"
          class="p-button-sm p-button-outlined"
          [ngStyle]="{ width: 'auto', margin: 'auto', marginBottom: '20px' }"
          [label]="'Add Or-Condition'"
          icon="pi icon-ic-add-item"
          (click)="disabled || addOrCondition(query)"
        ></button>
      </div>
    </ng-container>
  </div>
</div>
<c-tags-select
  *ngIf="useTags"
  [value]="getAllTags()"
  [multiselect]="false"
  [renderBySelf]="true"
  [showTagsSidebar]="showTagsSidebar"
  (valueChange)="setQueryTag($event); showTagsSidebar = false"
  (valueChangeFullTag)="addTag($event)"
  (sideBarClosed)="showTagsSidebar = false; currentSelectedIndexes = null"
>
</c-tags-select>
