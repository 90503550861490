import { OperatingCountry, OperatingCountryLocal } from '../entities/OperatingCountry';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';

export class OperatingCountryFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createOperatingCountry(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      isoCode: values?.isoCode || '',
      local: await this.local(values, val => this.createOperatingCountryLocal(val)),
      flag: values?.flag || null,
      belongsToRegion: values?.belongsToRegion || null,
      tags: values?.tags || [],
      isDefaultOperatingCountry: values?.isDefaultOperatingCountry || false,
      mappedIsoCodes: values?.mappedIsoCodes || [],
      favoriteLanguages: values?.favoriteLanguages || []
    } as OperatingCountry;
  }

  async createOperatingCountryLocal(values?: any) {
    return {
      title: values?.title || ''
    } as OperatingCountryLocal;
  }
}
