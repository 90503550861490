<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>
      <span class="pre-title">{{ adminId === 'new' ? 'New ' + ('PAGE_ADMIN_USER' | translate) : ('PAGE_ADMIN_USER' | translate) }}</span>
      <span>{{ user?.displayName }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <p-button [disabled]="disabled" icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" [disabled]="tab.loading || userImport.identifier === ''" (onClick)="save()"></p-button>
  </div>
</div>
<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_ADMIN_USER' | translate }}
  </div>
  <div class="panel-body">
    <div class="input-group">
      <div class="input">
        <p-dropdown [options]="userTypes" [(ngModel)]="userImport.adminUserType" optionValue="value" optionLabel="title" [style]="{ width: '100%' }" [disabled]="adminId !== 'new'"></p-dropdown>
      </div>
      <div class="title">{{ 'PAGE_ADMIN_USER_TYPE' | translate }}</div>
      <div class="description">{{ 'PAGE_ADMIN_USER_TYPE_DESCRIPTION' | translate }}</div>
    </div>
    <div class="input-group" *ngIf="userImport.adminUserType === 'AzureADAdminUser' && adminId !== 'new'">
      <div class="input">
        <input type="text" pInputText [(ngModel)]="userImport.identifier" [disabled]="true" />
      </div>
      <div class="title">{{ 'PAGE_ADMIN_USER_IDENTIFIER' | translate }}</div>
      <div class="description">{{ 'PAGE_ADMIN_USER_IDENTIFIER_DESCRIPTION' | translate }}</div>
    </div>
    <div class="input-group">
      <div class="input">
        <input type="text" pInputText [(ngModel)]="userImport.email" [disabled]="adminId !== 'new'" />
      </div>
      <div class="title">{{ 'PAGE_ADMIN_USER_EMAIL' | translate }}</div>
      <div class="description">{{ 'PAGE_ADMIN_USER_EMAIL_DESCRIPTION' | translate }}</div>
    </div>
    <div class="input-group" *ngIf="userImport.adminUserType === 'LocalAdminUser' && adminId === 'new'">
      <div class="combined">
        <div class="right">
          <p-button [label]="'GENERAL_GENERATE' | translate" (onClick)="generatePassword()"></p-button>
        </div>
        <div class="input">
          <input type="text" pInputText [(ngModel)]="userImport.password" />
          <button pButton pRipple type="button" icon="pi pi-copy" class="p-button-text" (click)="copyText(userImport.password)"></button>
        </div>
      </div>
      <div class="title">{{ 'PAGE_ADMIN_USER_PASSWORD' | translate }}</div>
      <div class="description">{{ 'PAGE_ADMIN_USER_PASSWORD_DESCRIPTION' | translate }}</div>
    </div>
    <div class="input-group" *ngIf="!(userImport.adminUserType === 'AzureADAdminUser' && adminId === 'new')">
      <div class="input">
        <input type="text" pInputText [(ngModel)]="userImport.firstName" [disabled]="disabled" />
      </div>
      <div class="title">{{ 'PAGE_ADMIN_USER_FIRST_NAME' | translate }}</div>
      <div class="description">{{ 'PAGE_ADMIN_USER_FIRST_NAME_DESCRIPTION' | translate }}</div>
    </div>
    <div class="input-group" *ngIf="!(userImport.adminUserType === 'AzureADAdminUser' && adminId === 'new')">
      <div class="input">
        <input type="text" pInputText [(ngModel)]="userImport.lastName" [disabled]="disabled" />
      </div>
      <div class="title">{{ 'PAGE_ADMIN_USER_LAST_NAME' | translate }}</div>
      <div class="description">{{ 'PAGE_ADMIN_USER_LAST_NAME_DESCRIPTION' | translate }}</div>
    </div>
    <div class="input-group">
      <div class="input">
        <p-autoComplete
          [(ngModel)]="userImport.roles"
          [suggestions]="filteredRoles"
          (completeMethod)="filterRoles($event)"
          field="name"
          [forceSelection]="true"
          [multiple]="true"
          [dropdown]="true"
          [disabled]="disabled"
        >
        </p-autoComplete>
      </div>
      <div class="title">{{ 'PAGE_ADMIN_USER_ROLES' | translate }}</div>
      <div class="description">{{ 'PAGE_ADMIN_USER_ROLES_DESCRIPTION' | translate }}</div>
    </div>
    <div class="input-group">
      <div class="input">
        <p-autoComplete
          [disabled]="disabled"
          [(ngModel)]="userImport.groups"
          [suggestions]="filteredGroups"
          (completeMethod)="filterGroups($event)"
          field="name"
          [forceSelection]="true"
          [multiple]="true"
          [dropdown]="true"
        >
        </p-autoComplete>
      </div>
      <div class="title">{{ 'PAGE_ADMIN_USER_GROUPS' | translate }}</div>
      <div class="description">{{ 'PAGE_ADMIN_USER_GROUPS_DESCRIPTION' | translate }}</div>
    </div>
  </div>
</div>
