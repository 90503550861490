import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { Language } from '../entities/Language';

export class LanguageFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createLanguage(values?: any) {
    return {
      _id: await this.id(values?._id),
      language: values?.language || '**',
      languageName: values?.languageName || 'New Language',
      active: values?.active || false,
      selectable: values?.selectable || false,
    } as Language;
  }
}
