import { GetEmailTemplatesQuery, GetEmailTemplatesResponse } from './../../../common/api/v1/configuration/emailTemplates/GetEmailTemplates';
import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';

import { EmailTemplate } from 'src/common/entities/EmailTemplate';
import { GetEmailTemplateQuery, GetEmailTemplateResponse } from 'src/common/api/v1/configuration/emailTemplates/GetEmailTemplate';
import { PostEmailTemplateBody, PostEmailTemplateQuery, PostEmailTemplateResponse } from 'src/common/api/v1/configuration/emailTemplates/PostEmailTemplate';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplatesService {
  constructor(private apiService: ApiService) {}

  getEmailTemplate(emailTemplateId: string): Promise<EmailTemplate> {
    return this.apiService.get<GetEmailTemplateQuery, GetEmailTemplateResponse>(`/api/v1/configuration/email-templates/${emailTemplateId}`).toPromise();
  }

  getEmailTemplates(query?: GetEmailTemplatesQuery): Promise<GetEmailTemplatesResponse> {
    return this.apiService
      .get<GetEmailTemplatesQuery, GetEmailTemplatesResponse>(`/api/v1/configuration/email-templates`, {
        limit: 150,
        skip: 0,
        ...query,
      })
      .toPromise();
  }

  createEmailTemplate(emailTemplate: EmailTemplate): Promise<EmailTemplate> {
    return this.apiService.post<PostEmailTemplateQuery, PostEmailTemplateBody, PostEmailTemplateResponse>(`/api/v1/configuration/email-templates`, emailTemplate).toPromise();
  }

  updateEmailTemplate(emailTemplate: EmailTemplate): Promise<EmailTemplate> {
    return this.apiService.post<PostEmailTemplateQuery, PostEmailTemplateBody, PostEmailTemplateResponse>(`/api/v1/configuration/email-templates`, emailTemplate).toPromise();
  }
}
