import { ActivatedRouteSnapshot, Params, QueryParamsHandling } from '@angular/router';

export interface ITabChildConfiguration {
  title: string;
  routerLink: string | string[];
}

export interface ITabConfiguration {
  category: string;
  titlePrefix?: string;
  title?: string;
  route?: ActivatedRouteSnapshot;
  url?: string;
  collapsible?: boolean;
  sticky?: boolean;
  loading?: boolean;
  parent?: string | Tab;
  children?: ITabConfiguration[];
  hasRight?: string | string[];
  hasMoved?: boolean;
  init?: () => Promise<boolean>;
  skeleton?: {
    type: 'form' | 'table';
    menu?: boolean;
    tabs?: boolean;
  };
}

export interface ITabFunctions {
  remove: (tab: Tab) => void;
  isActive: (tab: Tab) => boolean;
  navigate: (tab: Tab, queryParams: Params, queryParamsHandling: QueryParamsHandling) => Promise<boolean>;
  // isChildActive: (tab: Tab, child: ITabChildConfiguration) => boolean;
}

export class Tab {
  private _tabConfiguration: ITabConfiguration;
  private _tabFunctions: ITabFunctions;
  private _children: Tab[] = [];

  private _id: string;

  public collapsed: boolean = false;

  public get configuration(): ITabConfiguration {
    return this._tabConfiguration;
  }

  public get identifier(): string {
    if (!this.resolvedUrl) {
      if (!this._id) {
        this._id = this.createId(32);
      }
      return this._id;
    }
    return this.resolvedUrl;
  }

  public get completedUrl(): string {
    return this.configuration.route ? Tab.getCompleteUrl(this.configuration.route) : this.configuration.url;
  }

  public get resolvedUrl(): string {
    return this.configuration.route ? Tab.getResolvedUrl(this.configuration.route) : this.configuration.url;
  }

  public get configuredUrl(): string {
    return this.configuration.route ? Tab.getConfiguredUrl(this.configuration.route) : this.configuration.url;
  }

  public get queryParams(): string {
    return this.configuration.route ? Tab.getQueryParams(this.configuration.route) : this.configuration.url.split('?')[1] || '';
  }

  public get loading() {
    return !!this.configuration.loading;
  }

  public set loading(val: boolean) {
    this.configuration.loading = val;
  }

  public get title() {
    return this.configuration.title;
  }

  public get titlePrefix() {
    return this.configuration.titlePrefix;
  }

  public set title(val: string) {
    this.configuration.title = val;
  }

  constructor(tabConfiguration: ITabConfiguration, tabFunctions: ITabFunctions) {
    this._tabConfiguration = tabConfiguration;
    this._tabFunctions = tabFunctions;
  }

  private createId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public remove() {
    this._tabFunctions.remove(this);
  }

  public isActive(): boolean {
    return this._tabFunctions.isActive(this);
  }

  // public isChildActive(child: ITabChildConfiguration) : boolean {
  //   return this._tabFunctions.isChildActive(this, child);
  // }

  public get children() {
    return this._children;
  }

  public addChild(tab: Tab) {
    const index = this._children.findIndex((c) => c.resolvedUrl === tab.resolvedUrl);
    if (index < 0) {
      this._children.push(tab);
    }
  }

  public removeChild(tab: Tab) {
    const index = this._children.findIndex((c) => c.resolvedUrl === tab.resolvedUrl);
    if (index >= 0) {
      this._children.splice(index, 1);
    }
  }

  public navigate(queryParams: Params, queryParamsHandling: QueryParamsHandling = 'merge') {
    this._tabFunctions.navigate(this, queryParams, queryParamsHandling);
  }

  public static getCompleteUrl(route: ActivatedRouteSnapshot): string {
    const queryParams = this.getQueryParams(route);
    return this.getResolvedUrl(route) + (queryParams ? '?' + queryParams : '');
  }

  public static getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route?.pathFromRoot
      .map((v) => v.url.map((segment) => segment.toString()).join('/'))
      .join('/')
      .replace(/\/\//g, '/')
      .replace(/\/$/, '');
  }

  public static getQueryParams(route: ActivatedRouteSnapshot): string {
    return Object.keys(route.queryParams)
      .reduce((a, b) => a.concat(`${b}=${encodeURIComponent(route.queryParams[b])}`), [])
      .join('&');
  }

  public static getConfiguredUrl(route: ActivatedRouteSnapshot): string {
    return route?.pathFromRoot
      .filter((v) => v.routeConfig)
      .map((v) => v.routeConfig!.path)
      .join('/');
  }
}
