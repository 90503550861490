<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_EVENT_SECTION_STAGES' | translate }}
    <div class="actions">
      <button
        *hasRight="'events.editContent'"
        pButton
        type="button"
        [label]="'PAGE_EVENT_SECTION_STAGES_ADD_STAGE' | translate"
        icon="pi icon-ic-add-item"
        class="p-button"
        (click)="addStage()"
      ></button>
    </div>
  </div>
  <c-table #table [options]="stagesTableOptions" [data]="eventVersion.stages">
    <ng-template let-stage>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openStage(stage)">
        {{ stage.internalName || ('GENERAL_UNNAMED' | translate) }}
      </a>
    </ng-template>
    <ng-template let-stage>
      <div *ngFor="let session of sessions[stage._id]">
        <div *ngFor="let keyval of session.local | keyvalue">{{ (keyval.key | language | async)?.languageName }}: {{ keyval.value.title }}</div>
      </div>
    </ng-template>
  </c-table>
</div>

<p-sidebar [(visible)]="showStage" styleClass="p-sidebar-st" position="right" [modal]="true" [appendTo]="'body'" [showCloseIcon]="false">
  <c-sidebar-header
    *ngIf="editableStage"
    [headerTitle]="'PAGE_EVENT_SECTION_STAGES_EDIT_STAGE' | translate"
    [headerSubTitle]="editableStage.internalName"
    (onClose)="showStage = false"
  ></c-sidebar-header>

  <div class="sidebar-body" *ngIf="editableStage">
    <c-event-patch-input jsonpath="$.stages[?(@._id=='$stageId')].internalName" [jsonpathParams]="{ stageId: editableStage._id }" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.stages[?(@._id=='$stageId')].local.$language.title" [jsonpathParams]="{ stageId: editableStage._id }" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.stages[?(@._id=='$stageId')].mapLink" [jsonpathParams]="{ stageId: editableStage._id }" [eventVersion]="eventVersion"></c-event-patch-input>
  </div>

  <c-sidebar-footer
    *hasRight="'events.editContent'"
    [autoSaveInfo]="true"
    (onClose)="showStage = false"
    [showDeleteButton]="true"
    (onDelete)="deleteStage(editableStage); showStage = false"
  ></c-sidebar-footer>
</p-sidebar>
