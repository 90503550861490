import { AssetSerie, AssetSerieLocal, AssetSerieSource } from '../entities/AssetSerie';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';

export class AssetSeriesFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createAssetSeries(values?: any) {
    return {
      _id: await this.id(values?._id),
      assets: values?.assets || [],
      local: await this.local(values, (val) => this.createAssetSeriesLocal(val)),
      internalName: values?.internalName || '',
      thumbnail: values?.thumbnail || null,
      readAccess: values?.readAccess || null,
      source: this.createSource(values?.source),
      createdAt: values?.createdAt ? new Date(values?.createdAt) : new Date(),
      contentLanguages: values?.contentLanguages || []
    } as AssetSerie;
  }

  public createSource(values: any) {
    return {
      system: values?.system || 'Internal',
      externalId: values?.externalId || '',
      data: values?.data || null,
    } as AssetSerieSource;
  }

  public createAssetSeriesLocal(values: any) {
    return {
      title: values?.title || '',
      description: values?.description || '',
      alt: values?.alt || '',
    } as AssetSerieLocal;
  }
}
