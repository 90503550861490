import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { TitleEventStagePageModuleFactory } from '../../factories/pagemodules/TitleEventStagePageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const titleEventStagePageModuleInputs: Inputs = {
  ...commonPageModuleInputs('TitleEventStage'),
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].local.$language.background": {},
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new TitleEventStagePageModuleFactory().titleEventStagePageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].local.$language.icon": {},
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].showLiveButton": {
    header: 'Show Live Button',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].ctalist": {
    factory: async () => [],
    childFactory: async () => new TitleEventStagePageModuleFactory().titleEventStagePageModuleButton({}),
    list: true,
    listKey: '_id',
    header: 'CTA Button',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].ctalist[$index].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].ctalistInteraction": {
    factory: async () => [],
    childFactory: async () => new TitleEventStagePageModuleFactory().titleEventStagePageModuleButton({}),
    list: true,
    listKey: '_id',
    header: 'CTA Button',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].ctalistInteraction[$index].ctaButton": {},

  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].topAreaHeight": {
    factory: async () => new TitleEventStagePageModuleFactory().titleEventStagePageModuleAreaHeight({}),
  },

  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.topAreaHeight.desktopWidescreen": {
    header: 'Top Area Height Desktop Widescreen',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.topAreaHeight.desktop": {
    header: 'Top Area Height Desktop',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.topAreaHeight.desktopLarge": {
    header: 'Top Area Height Desktop large',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.topAreaHeight.mobile": {
    header: 'Top Area Height mobile',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.topAreaHeight.mobileLarge": {
    header: 'Top Area Height mobile large',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.topAreaHeight.tablet": {
    header: 'Top Area Height tablet ',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.topAreaHeight.tabletLarge": {
    header: 'Top Area Height tablet large',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].contentAreaHeight": {
    factory: async () => new TitleEventStagePageModuleFactory().titleEventStagePageModuleAreaHeight({}),
  },

  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.contentAreaHeight.desktopWidescreen": {
    header: 'Top Area Height Desktop Widescreen',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.contentAreaHeight.desktop": {
    header: 'Top Area Height Desktop',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.contentAreaHeight.desktopLarge": {
    header: 'Top Area Height Desktop large',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.contentAreaHeight.mobile": {
    header: 'Top Area Height mobile',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.contentAreaHeight.mobileLarge": {
    header: 'Top Area Height mobile large',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.contentAreaHeight.tablet": {
    header: 'Top Area Height tablet ',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.contentAreaHeight.tabletLarge": {
    header: 'Top Area Height tablet large',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].ctaAreaHeight": {
    factory: async () => new TitleEventStagePageModuleFactory().titleEventStagePageModuleAreaHeight({}),
  },

  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.ctaAreaHeight.desktopWidescreen": {
    header: 'Top Area Height Desktop Widescreen',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.ctaAreaHeight.desktop": {
    header: 'Top Area Height Desktop',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.ctaAreaHeight.desktopLarge": {
    header: 'Top Area Height Desktop large',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.ctaAreaHeight.mobile": {
    header: 'Top Area Height mobile',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.ctaAreaHeight.mobileLarge": {
    header: 'Top Area Height mobile large',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.ctaAreaHeight.tablet": {
    header: 'Top Area Height tablet ',
  },
  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].$.ctaAreaHeight.tabletLarge": {
    header: 'Top Area Height tablet large',
  },

  "$.pageModules[?(@.pageModuleType=='TitleEventStage' && @._id=='$pageModuleId')].iconStyle": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Circle', value: 'circle' },
      { label: 'No Adjustment', value: 'no_adjustment' },
    ],
  },
};
