<div *ngIf="!renderBySelf" class="p-grid p-ai-center vertical-container chips-input">
  <p-chips
    class="p-col"
    [(ngModel)]="tags"
    [ngClass]="multiselect ? 'multiselect' : 'single'"
    [allowDuplicate]="false"
    [disabled]="disabled"
    (onFocus)="onFocus.emit()"
    (onBlur)="onBlur.emit()"
    (onRemove)="onRemove()"
  >
    <ng-template let-item pTemplate="item">
      <div [escape]="false" tooltipPosition="bottom" [pTooltip]="showTooltip ? getTaxonomyToolTip(item) : ''">
        <i class="pi pi-tag ml-2"></i>
        {{ item.label }}
      </div>
    </ng-template>
  </p-chips>
  <p-button (onClick)="showTagsSidebar = true" styleClass="p-button" [label]="multiselect ? ('GENERAL_ADD' | translate) : ('GENERAL_EDIT' | translate)" [disabled]="disabled"></p-button>
</div>

<c-sidebar
  [(visible)]="showTagsSidebar"
  [header]="multiselect ? ('GENERAL_TAGS_ADD' | translate) : renderBySelf ? 'Select Tag' : ('GENERAL_TAGS_EDIT' | translate)"
  position="right"
  (onHide)="sideBarClosed.emit()"
>
  <div class="search-input input-group compact">
    <div class="input">
      <input #searchInput placeholder="Search tags ..." pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
    </div>
  </div>
  <p-tree [filter]="false" [draggableNodes]="false" [droppableNodes]="false" (onNodeExpand)="nodeExpand($event)" (onNodeSelect)="onSelect($event)" [value]="tree" selectionMode="single"></p-tree>
</c-sidebar>
