import { Component, Input, OnInit } from '@angular/core';
import { CustomFieldsService } from 'src/app/services/customFields/custom-fields.service';
import { EventsService } from 'src/app/services/events/events.service';
import { PostEventRegistrationsResponse } from 'src/common/api/v1/events/PostEventRegistrations';
import { Event } from 'src/common/entities/Event';
import { EventRegistration, EventRegistrationData } from 'src/common/entities/EventRegistration';
import { isUser, User } from 'src/common/entities/User';
import { TableOptions } from '../table/table.interfaces';

@Component({
  selector: 'c-event-registration-dialog',
  templateUrl: './event-registration-dialog.component.html',
  styleUrls: ['./event-registration-dialog.component.scss'],
})
export class EventRegistrationDialogComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  disabled: boolean = false;

  showEventRegistrationDialog = false;
  working = false;
  users: string = '';
  tickets: { label: string; value: string }[] = [];
  eventRegistrationData: EventRegistrationData | null = null;
  sendEmail: boolean = true;
  eventRegistrationsResponse: PostEventRegistrationsResponse = [];
  customFieldSelectOptions: { [customFieldId: string]: { label: string; value: string }[] } = {};
  resultTableOptions: TableOptions<{ user?: string | User; eventRegistration?: EventRegistration; error?: any }>;

  constructor(private eventsService: EventsService, private customFieldsService: CustomFieldsService) {}

  ngOnInit(): void {
    this.resultTableOptions = {
      columns: [{ header: 'Email' }, { header: 'Name' }, { header: 'Status' }],
    };
  }

  openEventRegistrationDialog() {
    this.eventRegistrationData = {
      eventTicket: '',
      eventSlots: [],
      customFields: {},
    };
    this.users = '';
    this.eventRegistrationsResponse = [];

    this.showEventRegistrationDialog = true;
  }

  asUser(user: any): User {
    return isUser(user) ? user : null;
  }

  async save() {
    this.working = true;

    try {
      this.eventRegistrationsResponse = await this.eventsService.createEventRegistration(this.event._id, {
        users: this.users.split(','),
        registrationData: this.eventRegistrationData,
        sendEmail: this.sendEmail,
      });
    } catch (err) {
      console.error(err);
    }

    this.working = false;
  }
}
