import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { titlePositions } from './../../entities/pagemodules/PageModule';
import { documentCollectionDisplayTypeValues } from './../../entities/pagemodules/DocumentCollectionPageModule';
import { DocumentCollectionPageModuleFactory } from './../../factories/pagemodules/DocumentCollectionPageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const documentCollectionPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('DocumentCollection'),
  "$.pageModules[?(@.pageModuleType=='DocumentCollection' && @._id=='$pageModuleId')].ctaButton": {},

  "$.pageModules[?(@.pageModuleType=='DocumentCollection' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new DocumentCollectionPageModuleFactory().documentCollectionPageModuleLocal({})
  },

  "$.pageModules[?(@.pageModuleType=='DocumentCollection' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: obj => {
        return obj;
      }
    }
  },

  "$.pageModules[?(@.pageModuleType=='DocumentCollection' && @._id=='$pageModuleId')].displayAs": {
    type: 'dropdown',
    dropdownOptions: [...documentCollectionDisplayTypeValues].map(o => ({ label: o, value: o }))
  },

  "$.pageModules[?(@.pageModuleType=='DocumentCollection' && @._id=='$pageModuleId')].pagination": {
    header: 'Pagination',
    type: 'number'
  },

  "$.pageModules[?(@.pageModuleType=='DocumentCollection' && @._id=='$pageModuleId')].local.$language.assets": {
    factory: async () => [],
    list: true,
    childFactory: async () => null
  },

  "$.pageModules[?(@.pageModuleType=='DocumentCollection' && @._id=='$pageModuleId')].local.$language.assets[$index]": {
    type: 'documentasset',
    header: 'Asset',
    mimeTypes: ['application/*', 'video/*', 'image/*']
  },
  "$.pageModules[?(@.pageModuleType=='DocumentCollection' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [...titlePositions].map(item => ({
      label: item,
      value: item
    }))
  }
};
