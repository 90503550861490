import { TableOptions, TableQuery } from 'src/app/components/table/table.interfaces';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from '../../services/utils/utils.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { QRCodesService } from 'src/app/services/qr-codes/qr-codes.service';
import { QRCode } from 'src/common/entities/QRCode';
import { QRCodeFactory } from 'src/common/factories/QRCodeFactory';
import { TableComponent } from 'src/app/components/table/table.component';
import { Inputs } from 'src/common/inputs/Inputs';

@Component({
  selector: 'app-qr-codes',
  templateUrl: './qr-codes.component.html',
  styleUrls: ['./qr-codes.component.scss'],
})
export class QRCodesComponent implements OnInit {
  tab: Tab;
  queryList;
  qrCodePreviewImage;
  isImageLoading;
  showQRCodeSidebar: boolean = false;
  qrCodeType: 'png' | 'svg' = 'png';
  qrCodeTypeOptions = [
    { title: 'PNG', value: 'png' },
    { title: 'SVG', value: 'svg' },
  ];
  editTab: string = 'general';
  tableOptions: TableOptions<QRCode>;
  showSidebar: boolean = false;
  editMode: boolean = false;
  qrCode: QRCode;

  @ViewChild('qrCodesTable') qrCodesTable: TableComponent<any>;

  constructor(private qrCodesService: QRCodesService, public utilsService: UtilsService, private tabsService: TabsService, private activatedRoute: ActivatedRoute) {
    this.tableOptions = {
      size: 50,
      columns: [
        { header: 'PAGE_QR_CODES_INTERNAL_NAME', sort: 'internalName' },
        { header: 'PAGE_QR_CODES_CODE', sort: 'code' },
        { header: 'PAGE_QR_CODES_LINK', sort: 'link' },
        { header: '', actions: true },
      ],
      filters: [
        { header: 'PAGE_QR_CODES_INTERNAL_NAME', path: 'internalName' },
        { header: 'PAGE_QR_CODES_CODE', path: 'code' },
        { header: 'PAGE_QR_CODES_LINK', path: 'link' },
      ],
    };
  }

  inputs: Inputs = {
    '$.internalName': {},
    '$.code': {},
    '$.link': {},
  };

  ngOnInit(): void {
    this.tab = this.tabsService.register({
      category: 'configuration',
      route: this.activatedRoute.snapshot,
    });
  }

  query(query: TableQuery<QRCode>) {
    this.queryList = query.query;
    query.result = this.qrCodesService.getQRCodes(query.query);
  }

  async new(): Promise<void> {
    this.editMode = false;
    this.qrCode = null;
    this.qrCode = await new QRCodeFactory().createQRCode();
    this.showSidebar = true;
  }

  async edit(qrCode: QRCode): Promise<void> {
    this.editMode = true;
    this.qrCode = await new QRCodeFactory().createQRCode(qrCode);
    this.showSidebar = true;
  }

  async save(): Promise<void> {
    if (!this.editMode) {
      await this.qrCodesService.createQRCode(this.qrCode);
    } else {
      await this.qrCodesService.updateQRCode(this.qrCode);
    }
    await this.qrCodesTable.refresh(true);
  }

  async deleteQrCode(): Promise<void> {
    await this.qrCodesService.deleteQRCode(this.qrCode);
    await this.qrCodesTable.refresh(true);
  }

  async qrCodeSidebar(qrCode: QRCode) {
    if (qrCode.link) {
      this.isImageLoading = true;
      this.qrCodesService.getQRCodeImage(qrCode._id).subscribe((item) => {
        this.createImageFromBlob(item);
        this.isImageLoading = false;
      });
    }
    this.qrCode = await new QRCodeFactory().createQRCode(qrCode);
    this.qrCodeType = 'png';
    this.showQRCodeSidebar = true;
  }

  async downloadQrCodeArchive() {
    await this.qrCodesService.downloadQRCodes(this.queryList);
  }

  async downloadQRCode() {
    if (this.qrCode.code && this.qrCode.link && this.qrCode.internalName) {
      await this.qrCodesService.downloadQRCode(this.qrCode._id, `${this.qrCode.code}_${this.qrCode.internalName}`, this.qrCodeType);
    }
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.qrCodePreviewImage = reader.result;
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  linkClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
