import { FilterMatchMode, IFilterableListQuery, IListResponse } from 'src/common/api/interfaces';

export type SortPath = string;
export type FilterPath = string;

export type TableColumns<T> = {
  header: string;
  sort?:
    | SortPath
    | ((a: T, b: T) => number)
    | {
        property: string;
        type?: 'string' | 'number' | 'boolean' | 'date';
      };
  visible?: 'fixed' | boolean;
  actions?: boolean;
}[];

export type TableFilter = {
  header: string;
  path: FilterPath;
  type?: 'string' | 'number' | 'objectid' | 'boolean' | 'date';
  matchModes?: FilterMatchMode[];

  /**
   * e.g. Dropdown values
   */
  values?: { value: any; label: string }[] | Promise<{ value: any; label: string }[]>;

  value?: any;
};

export type TableFilters = TableFilter[];

export type TableColumnSettings = {
  visible: 'fixed' | boolean;
  actions: boolean;
}[];

export type TableOptions<T> = {
  size?: number;
  columns: TableColumns<T>;
  defaultSortDirection?: 'asc' | 'desc';
  filters?: TableFilters;
  selectable?: boolean;
  refreshTriggers?: {
    collaborationKey: string;
    jsonpath: string;
    jsonpathParams?: { [key: string]: any };
  }[];
};

export type TableQuery<T> = {
  query: IFilterableListQuery;
  result?: IListResponse<T> | Promise<IListResponse<T>>;
};

export const defaultMatchModes = {
  string: ['contains', 'notContains', 'startsWith', 'endsWith', 'equals', 'notEquals'],
  boolean: ['equals', 'notEquals'],
  objectid: ['equalsObjectId', 'notEqualsObjectId'],
  date: ['dateIs', 'dateIsNot', 'dateAfter', 'dateBefore'],
};
