import { FormPageModule } from './../../entities/pagemodules/FormPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class FormPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async formPageModule(values: any): Promise<FormPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'Form',
      titlePosition: values?.titlePosition || 'left',
      form: values?.form || '',
      buttonFullWidth: values?.buttonFullWidth || false,
    };
  }
}
