import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { titlePositions } from './../../entities/pagemodules/PageModule';
import { AccordionPageModuleFactory } from '../../factories/pagemodules/AccordionPageModuleFactory';
import { commonPageModuleInputs } from './common';
import { AccordionPageModuleChildElement, AccordionPageModuleElement } from './../../../common/entities/PageModule';
import { Inputs } from '../Inputs';

export const accordionPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('Accordion'),

  "$.pageModules[?(@.pageModuleType=='Accordion' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new AccordionPageModuleFactory().accordionPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='Accordion' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },

  "$.pageModules[?(@.pageModuleType=='Accordion' && @._id=='$pageModuleId')].elements": {
    factory: async () => [],
    list: true,
    listKey: '_id',
    listValue: 'elementId',
    childFactory: async () => new AccordionPageModuleFactory().accordionPageModuleElement({}),
    childLabel: (item: AccordionPageModuleElement, index: number, lang?: string) => item?.local[lang ? lang : 'en']?.title  as string,
    childDescription: (item: AccordionPageModuleElement, index: number) => `Expanded: ${item.isExpanded ? 'Yes' : 'No'}`,
  },
  "$.pageModules[?(@.pageModuleType=='Accordion' && @._id=='$pageModuleId')].elements[?(@._id=='$elementId')].isExpanded": {
    type: 'switch',
    header: 'Is Expanded',
  },
  "$.pageModules[?(@.pageModuleType=='Accordion' && @._id=='$pageModuleId')].elements[?(@._id=='$elementId')].local.$language": {
    factory: async () => new AccordionPageModuleFactory().accordionPageModuleElementLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='Accordion' && @._id=='$pageModuleId')].elements[?(@._id=='$elementId')].local.$language.title": {},

  "$.pageModules[?(@.pageModuleType=='Accordion' && @._id=='$pageModuleId')].elements[?(@._id=='$elementId')].childElements": {
    factory: async () => [],
    list: true,
    listKey: '_id',
    listValue: 'childElementId',
    childFactory: async () => new AccordionPageModuleFactory().accordionPageModuleChildElement({}),
    childLabel: (item: AccordionPageModuleChildElement, index: number, lang?: string) => item?.local[lang ? lang : 'en']?.title as string,
  },
  "$.pageModules[?(@.pageModuleType=='Accordion' && @._id=='$pageModuleId')].elements[?(@._id=='$elementId')].childElements[?(@._id=='$childElementId')].local.$language": {
    factory: async () => new AccordionPageModuleFactory().accordionPageModuleChildElementLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='Accordion' && @._id=='$pageModuleId')].elements[?(@._id=='$elementId')].childElements[?(@._id=='$childElementId')].local.$language.title": {},
  "$.pageModules[?(@.pageModuleType=='Accordion' && @._id=='$pageModuleId')].elements[?(@._id=='$elementId')].childElements[?(@._id=='$childElementId')].local.$language.description": {
    type: 'html',
  },
  "$.pageModules[?(@.pageModuleType=='Accordion' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [...titlePositions].map((item) => ({
      label: item,
      value: item,
    })),
  },
};
