import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class TimezoneSwitchService {
  constructor(private activatedRoute: ActivatedRoute) {}

  encodeByUrlTimeZone(from: Date | string, timeZone = this.activatedRoute.snapshot.queryParams.eTz): Date {
    return this.calculateByOffset(from, timeZone, false);
  }

  decodeByUrlTimeZone(from: Date | string, timeZone = this.activatedRoute.snapshot.queryParams.eTz): Date {
    return this.calculateByOffset(from, timeZone, true);
  }

  private calculateByOffset(date: Date | string, timeZone: string, removeOffset = false): Date {
    // inputDate new Date(..).getTime() is already plain without offset.
    // For example 9:00 o'Clock frontend value gives 08:00 value at Europe/Berlin wintertime ( utc+1 already removed)
    const inputDate = new Date(date);
    // console.log('change before ', removeOffset ? 'save' : 'show', ': ', date,
    //   !removeOffset ? 'true entity value is ' + inputDate.toUTCString() : '');

    let inputDateTimeStamp = inputDate.getTime();

    // it's a negative value (if utc is + and reverse for minus utc) in minutes,
    // so we need to multiply with 60 for getting milliseconds:
    // for example target timezone is Europe/Berlin wintertime -> -60 * 60.000 = -3.600.000
    const utcTargetTimeZoneOffset = moment.tz(inputDate, timeZone).utcOffset();
    // console.log('targetUTcOffset', utcTargetTimeZoneOffset / 60);

    // it's a positive value(if utc is + and reverse if utc-...):
    // for example system timezone is Europe/Berlin wintertime -> 60(minutes) * 60.000 = 3.600.000
    const inputDateSystemTimeZoneOffset = inputDate.getTimezoneOffset();
    // console.log('localUtcOffset', inputDateSystemTimeZoneOffset / 60);

    // for germany-date translate offset is everytime 0, cause system time is also german(if it is so)
    const offsetDifferenceFromTargetTimezone = inputDateSystemTimeZoneOffset + utcTargetTimeZoneOffset;
    // console.log('allOffset', offsetDifferenceFromTargetTimezone / 60);

    if (removeOffset) {
      // generally to save the to db
      inputDateTimeStamp -= offsetDifferenceFromTargetTimezone * 60 * 1000;

      // check after change, whether targetUTcOffset is changed
      const utcTargetTimeZoneOffsetChangeDiff = moment.tz(new Date(inputDateTimeStamp), timeZone).utcOffset() - utcTargetTimeZoneOffset;
      if (utcTargetTimeZoneOffsetChangeDiff !== 0) {
        inputDateTimeStamp -= utcTargetTimeZoneOffsetChangeDiff * 60 * 1000;
        // console.log('targetUTcOffset changed after removing allOffset. so correct them by ', utcTargetTimeZoneOffsetChangeDiff / 60);
      }
    } else {
      // for showing in ui
      inputDateTimeStamp += offsetDifferenceFromTargetTimezone * 60 * 1000;
    }
    // console.log(removeOffset ? 'saved' : 'show', ' as ', new Date(inputDateTimeStamp),
    //   removeOffset ? 'true entity value will be ' + new Date(inputDateTimeStamp).toUTCString() : '');
    // console.log('-');
    // console.log('-');
    // console.log('-');

    return new Date(inputDateTimeStamp);
  }
}
