import { Component, Input, OnInit } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { ShowroomPageModule } from 'src/common/entities/PageModule';

import { ShowroomsService } from 'src/app/services/showrooms/showrooms.service';

import { InputConfiguration } from 'src/common/inputs/Inputs';

@Component({
  selector: 'c-showroom-page-module-edit',
  templateUrl: './showroom-page-module-edit.component.html',
  styleUrls: ['./showroom-page-module-edit.component.scss'],
})
export class ShowroomPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: ShowroomPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputConfigurationShowroom: InputConfiguration = {};

  initialized: boolean = false;
  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  constructor(private showroomsService: ShowroomsService) {}

  async ngOnInit() {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
    this.inputConfigurationShowroom = {
      type: 'dropdown',
      dropdownOptions: [{ label: 'No Showroom selected', value: null }].concat(
        (await this.showroomsService.getShowroomConfigurations()).items.map((c) => ({
          label: `${c.instance} (${c.showroomType}, Default Room: ${c.defaultRoom})`,
          value: c._id,
        }))
      ),
    };
    this.initialized = true;
  }
}
