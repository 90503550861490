import { HeadlineType, TextAttributes } from './../../entities/Attributes';
import { StageHeightDefaultBreakpointValue } from './DefaultBreakponitValue';
import { BreakpointValue } from './../../entities/Breakpoint';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';
import {
  ConversionStageCTATeaserItem,
  ConversionStageLinkTeaserItem,
  ConversionStagePageModule,
  ConversionStagePageModuleInfo,
  ConversionStagePageModuleLocal,
  ConversionStageTeaserItem
} from '../../entities/pagemodules/ConversionStagePageModule';
import { createCTAButton } from '../../entities/CTAButton';

export class ConversionStagePageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async conversionStagePageModuleLocal(values?: any): Promise<ConversionStagePageModuleLocal> {
    return {
      title: values?.title || '',
      title_attr: this.conversionStagePageModuleAttributes(values?.title_attr),
      subtext: values?.subtext || '',
      background: values?.background || null,
      items: await Promise.all((values?.items || []).map((i: any) => this.teaserItem(i))),
      pageSelectorPrefix: values?.pageSelectorPrefix || '',
      devices: this.getDevicesInfo(values?.devices)
    };
  }

  public conversionStagePageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): TextAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2'
    };
  }

  public async linkTeaserItem(values: any): Promise<ConversionStageLinkTeaserItem> {
    return {
      _id: await this.id(values?._id),
      teaserItemType: 'Link',
      image: values?.image || null,
      title: values?.title || null,
      link: values?.link || null
    };
  }

  public async ctaTeaserItem(values: any): Promise<ConversionStageCTATeaserItem> {
    return {
      _id: await this.id(values?._id),
      teaserItemType: 'CTA',
      image: values?.image || null,
      title: values?.title || null,
      cta: createCTAButton(values?.cta)
    };
  }

  public async teaserItem(values: any): Promise<ConversionStageTeaserItem | null> {
    switch (values.teaserItemType) {
      case 'Link':
        return this.linkTeaserItem(values);
      case 'CTA':
        return this.ctaTeaserItem(values);
    }
    return null;
  }

  public conversionStagePageModuleStageHeight(defaultValues: BreakpointValue<number>, values?: any): BreakpointValue<number> {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge
    };
  }

  public async conversionStagePageModule(values?: any): Promise<ConversionStagePageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'ConversionStage',
      local: await this.local(values, val => this.conversionStagePageModuleLocal(val)),
      stageHeight: values?.stageHeight ? values.stageHeight : this.conversionStagePageModuleStageHeight(StageHeightDefaultBreakpointValue, values?.stageHeight),
      enablePageSelector: values?.enablePageSelector || false,
      ctaButton: values?.ctaButton || null,
      selectorPages: values?.selectorPages || []
    };
  }

  public getDevicesInfo(devices?: any) {
    const result = { mobile: { title: '', subtext: '' }, desktop: { title: '', subtext: '' }, tablet: { title: '', subtext: '' } };
    if (!devices) return result;
    for (const devicesKey in devices) {
      const { title = '', subtext = '' } = devices[devicesKey] as ConversionStagePageModuleInfo;
      (result as any)[devicesKey] = { title, subtext } as ConversionStagePageModuleInfo;
    }
    return result;
  }
}
