import { mapInputs } from './../../../../common/inputs/page/MapInputs';
import { Map, MapVersion } from './../../../../common/entities/Map';
import { InputConfiguration, Inputs } from './../../../../common/inputs/Inputs';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'c-map-patch-input',
  templateUrl: './map-patch-input.component.html',
  styleUrls: ['./map-patch-input.component.scss'],
})
export class MapPatchInputComponent implements OnChanges {
  @Input()
  jsonpath: string | string[];

  @Input()
  jsonpathParams: { [key: string]: any } = {};

  @Input()
  object: Map | MapVersion;

  @Input()
  inputConfiguration: InputConfiguration;

  @Input()
  disabled: boolean;

  @Input()
  showHeader: boolean = true;

  @Input()
  showDescription: boolean = true;

  @Input()
  header: string;

  @Input()
  description: string;

  @Output()
  onValueChanged = new EventEmitter<boolean>();

  @Input()
  inputs: Inputs | null = null;

  collaborationKey: string;

  mapInputs = mapInputs;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.object?.currentValue !== changes.object?.previousValue) {
      if (this.object) {
        this.collaborationKey = `mapversion:${this.object._id}`;
      }
      if (this.object && !this.inputs) {
        this.inputs = mapInputs;
      }
    }
  }

  emitValueChanged($event) {
    this.onValueChanged.emit($event);
  }
}
