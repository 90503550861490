import { Pipe, PipeTransform } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';

@Pipe({
  name: 'serverTime',
})
export class ServerTimePipe implements PipeTransform {
  constructor(private configurationService: ConfigurationService) {}

  transform(timeDifference: number): Observable<Date> {
    return interval(1000).pipe(
      map((i) => {
        return new Date(this.configurationService.serverTime().getTime() + (timeDifference || 0));
      })
    );
  }
}
