import { ActivatedRoute, Router } from '@angular/router';
import { TabsService } from './../../tabs/services/tabs.service';
import { TableOptions, TableQuery } from './../../components/table/table.interfaces';
import { Tab } from './../../tabs/classes/tab';
import { Component, OnInit } from '@angular/core';
import { AdminUsersService } from 'src/app/services/admin-users/admin-users.service';
import { AdminUser } from 'src/common/entities/AdminUser';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss'],
})
export class AdminUsersComponent implements OnInit {
  tab: Tab;
  tableOptions: TableOptions<AdminUser>;

  constructor(private adminUsersService: AdminUsersService, private tabsService: TabsService, private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.tab = this.tabsService.register({
      category: 'data',
      route: this.activatedRoute.snapshot,
      title: 'Adminportal Users',
    });

    this.tableOptions = {
      size: 50,
      columns: [
        { header: 'PAGE_USER_FIRSTNAME', sort: 'firstName' },
        { header: 'PAGE_USER_LASTNAME', sort: 'lastName' },
        { header: 'PAGE_USER_EMAIL', sort: 'email' },
        { header: 'PAGE_USER_ORGANIZATION', sort: 'organization' },
        { header: 'PAGE_USER_ADMIN', sort: 'admin' },
        { header: 'PAGE_USER_LASTACTIVITY', sort: 'lastActivity' },
        { header: 'PAGE_USER_TYPE', sort: 'user' },
      ],
      filters: [
        { header: 'PAGE_USER_FIRSTNAME', path: 'firstName' },
        { header: 'PAGE_USER_LASTNAME', path: 'lastName' },
        { header: 'PAGE_USER_EMAIL', path: 'email' },
        { header: 'PAGE_USER_ORGANIZATION', path: 'organization' },
        { header: 'AGE_USER_ADMIN', path: 'admin' },
        { header: 'PAGE_USER_LASTACTIVITY', path: 'lastActivity' },
        { header: 'PAGE_USER_TYPE', path: 'user' },
      ],
    };
  }

  query(query: TableQuery<AdminUser>) {
    query.query.filter.adminUserType = {
      matchMode: 'notEquals',
      value: 'ServiceAdminUser',
    };

    query.result = this.adminUsersService.getAdminUsers(query.query);
  }

  newAdminUser() {
    this.router.navigate(['/configuration/admin-users/new']);
  }

  linkClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
