import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TableComponent } from '../../../components/table/table.component';
import { EventVersion, RegistrationCustomField, RegistrationCustomFieldAutoFillType } from '../../../../common/entities/EventVersion';
import { Event } from '../../../../common/entities/Event';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { TableOptions } from '../../../components/table/table.interfaces';
import { EventsService } from '../../../services/events/events.service';
import { UtilsService } from '../../../services/utils/utils.service';
import { concatMap, debounceTime, map } from 'rxjs/operators';
import { IFilterList } from '../../../../common/api/interfaces';
import { Map } from '../../../../common/entities/Map';
import { MapsService } from '../../../services/maps/maps.service';

@Component({
  selector: 'c-event-maps',
  templateUrl: './event-maps.component.html',
  styleUrls: ['./event-maps.component.scss'],
})
export class EventMapsComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) table: TableComponent<RegistrationCustomField>;

  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  language: string;

  searchText: string;
  searchTextSubject: Subject<string> = new Subject<string>();
  filter$ = new BehaviorSubject<IFilterList | null>(null);
  searchResult$: Observable<Map[]> = new Observable<Map[]>();

  showMapSelect = false;

  customFieldsTableOptions: TableOptions<string> = {
    size: 100,
    columns: [{ header: 'GENERAL_INTERNAL_NAME' }, { header: '' }],
  };

  subscriptions: Subscription[] = [];

  constructor(private mapsService: MapsService, private eventsService: EventsService, private utilsService: UtilsService) {}

  async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      this.eventsService.lastEventVersionPatch(this.eventVersion._id).subscribe((patch) => {
        if (this.utilsService.startsWithJsonpath(patch.patch.jsonpath, '$.eventMaps')) {
          this.table.refresh();
        }
      })
    );

    this.subscriptions.push(this.searchTextSubject.pipe(debounceTime(500)).subscribe(() => this.executeSearch()));
    this.searchResult$ = this.filter$.pipe(
      concatMap((filter) => {
        return this.mapsService.getMaps({
          ...(filter ? { filter } : {}),
        });
      }),
      map(({ items }) => {
        const { eventMaps } = this.eventVersion;
        return items.filter(({ _id }) => !eventMaps.includes(_id));
      })
    );
    this.subscriptions.push(this.searchResult$.subscribe());

    this.executeSearch();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async addMap(map: Map): Promise<void> {
    await this.eventsService.patch(this.eventVersion, {
      command: 'push',
      jsonpath: `$.eventMaps`,
      value: map._id,
    });
    this.executeSearch();
    this.showMapSelect = false;
  }

  async deleteMap(index: number): Promise<void> {
    await this.eventsService.patch(this.eventVersion, {
      command: 'delete',
      jsonpath: `$.eventMaps[$index]`,
      jsonpathParams: { index },
    });
    this.executeSearch();
  }

  registrationCustomFieldAutoFillType(customField: RegistrationCustomField): RegistrationCustomFieldAutoFillType {
    return customField.autoFill?.registrationCustomFieldAutoFillType || null;
  }

  searchTextKeyUp(): void {
    this.searchTextSubject.next(this.searchText);
  }

  executeSearch(): void {
    let filter: IFilterList | null = null;

    if (this.searchText) {
      filter = {
        internalName: {
          matchMode: 'contains',
          value: this.searchText,
          caseInsensitive: true,
        },
        deletedBy: null,
      };
    }
    this.filter$.next(filter);
  }
}
