<div *skeleton="{ show: loading }">
  <div class="tab-header">
    <h1>
      <span class="pre-title">{{ 'PAGE_CUSTOM_FIELD' | translate }}</span>
      <span>{{ customField?.internalName }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <c-language-button
      *hasRight="'configuration.edit'"
      [(currentLanguage)]="currentLanguage"
      [languages]="languages"
      (currentLanguageChange)="onCurrentLanguageChange($event)"
      (languagesChange)="onActiveLanguageChange($event)"
      [disabled]="false"
    ></c-language-button>
    <c-language-button
      *hasNoRight="'configuration.edit'"
      [(currentLanguage)]="currentLanguage"
      [languages]="languages"
      (currentLanguageChange)="onCurrentLanguageChange($event)"
      (languagesChange)="onActiveLanguageChange($event)"
      [disabled]="false"
      [manageLanguages]="false"
    ></c-language-button>

    <p-button *hasRight="'configuration.edit'" icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" [disabled]="loading || saving || !isValidCustomField()" (onClick)="onSave()"></p-button>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_CUSTOM_FIELD_PROPERTIES' | translate }}
    </div>
    <div *ngIf="customField" class="panel-body">
      <!-- Internal Name Input -->
      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [(ngModel)]="customField.internalName" />
        </div>
        <div class="title">{{ 'PAGE_CUSTOM_FIELD_INTERNAL_NAME' | translate }}</div>
        <div class="description">{{ 'PAGE_CUSTOM_FIELD_INTERNAL_NAME_DESCRIPTION' | translate }}</div>
      </div>

      <!-- Custom Field Type Dropdown -->
      <div class="input-group">
        <div class="input">
          <p-dropdown
            [(ngModel)]="customField.customFieldType"
            [options]="customFieldTypes"
            optionValue="value"
            optionLabel="label"
            [disabled]="customFieldId !== 'new'"
            (onChange)="customFieldTypeChange(customField.customFieldType)"
          ></p-dropdown>
        </div>
        <div class="title">{{ 'PAGE_CUSTOM_FIELD_TYPE' | translate }}</div>
        <div class="description">{{ 'PAGE_CUSTOM_FIELD_TYPE_DESCRIPTION' | translate }}</div>
      </div>

      <div *ngIf="customField && currentLanguage">
        <c-collaboration-patch-input
          [object]="customField"
          [collaborationKey]="'customField:' + customFieldId"
          [jsonpath]="'$.local.$language.title'"
          [jsonpathParams]="{ language: currentLanguage }"
          [inputs]="titleInput"
        ></c-collaboration-patch-input>

        <c-collaboration-patch-input
          [object]="customField"
          [collaborationKey]="'customField:' + customFieldId"
          [jsonpath]="'$.local.$language.description'"
          [jsonpathParams]="{ language: currentLanguage }"
          [inputs]="descriptionInput"
        ></c-collaboration-patch-input>

        <c-collaboration-patch-input
          *ngIf="showDefaultFieldInput"
          [object]="customField"
          [collaborationKey]="'customField:' + customFieldId"
          [jsonpath]="fieldPathLookup()"
          [jsonpathParams]="{ language: currentLanguage }"
          [inputs]="defaultInputField"
        ></c-collaboration-patch-input>

        <c-collaboration-patch-input
          *ngIf="customField.customFieldType === 'ticketAttendees'"
          [object]="customField"
          [collaborationKey]="'customField:' + customFieldId"
          [jsonpath]="'$.local.$language.checkboxDescription'"
          [jsonpathParams]="{ language: currentLanguage }"
          [inputs]="checkboxDescriptionInput"
        ></c-collaboration-patch-input>
      </div>
    </div>
  </div>

  <div class="panel" *ngIf="currentLanguage && asCustomFieldSelectOrTicketAttendee(customField) as customField">
    <div class="panel-header">
      {{ 'PAGE_CUSTOM_FIELD_TYPE_PROPERTIES' | translate }}
    </div>
    <p-message
      class="tab-messages"
      *ngIf="errorAtCustomField(customField.customFieldType === 'ticketAttendees' ? 'fields' : 'options')"
      severity="error"
      [text]="errorAtCustomField(customField.customFieldType === 'ticketAttendees' ? 'fields' : 'options')"
    ></p-message>
    <div *ngIf="customField" class="panel-body">
      <!-- Option List -->
      <div *ngFor="let optionField of getFieldsOrOptions(customField); index as i">
        <ng-container *ngIf="isNotDeleted(customField, optionField)">
          <!-- Option Header with Delete Button -->
          <div>
            <b>{{ i + 1 }} . {{ (customField.customFieldType === 'ticketAttendees' ? 'PAGE_CUSTOM_FIELD_SELECT_OPTION_FIELD' : 'GENERAL_OPTION') | translate }}</b>
            <p-divider align="right">
              <p-button [label]="'GENERAL_UP' | translate" icon="pi pi-angle-up" styleClass="p-button-sm p-button-outlined" (click)="onUpOption(customField, i)" [disabled]="i === 0"> </p-button>
              <p-button
                [label]="'GENERAL_DOWN' | translate"
                icon="pi pi-angle-down"
                styleClass="p-button-sm p-button-outlined"
                (click)="onDownOption(customField, i)"
                [disabled]="isLastOptionField(customField, i)"
              >
              </p-button>
              <p-button [label]="'GENERAL_DELETE' | translate" icon="pi pi-trash" styleClass="p-button-sm p-button-danger p-button-outlined" (click)="onDeleteOption(customField, i)"> </p-button>
            </p-divider>
          </div>
          <div *ngIf="asCustomFieldSelectOption(customField, optionField) as optionField">
            <div class="input-group">
              <div class="input">
                <input type="text" pInputText (input)="optionKeyChange()" [(ngModel)]="optionField.key" />
              </div>
              <div class="title">{{ 'PAGE_CUSTOM_FIELD_SELECT_OPTION_KEY' | translate }}</div>
              <div class="description">{{ 'PAGE_CUSTOM_FIELD_SELECT_OPTION_KEY_DESCRIPTION' | translate }}</div>
            </div>
            <c-collaboration-patch-input
              [object]="customField"
              [collaborationKey]="'customField:' + customFieldId"
              [jsonpath]="'$.options[$index].local.$language'"
              [jsonpathParams]="{ language: currentLanguage, index: i }"
              [inputs]="optionLocalInput"
            ></c-collaboration-patch-input>
          </div>

          <div *ngIf="asCustomFieldAttendeeOption(customField, optionField) as optionField">
            <div class="input-group">
              <div class="input">
                <p-dropdown [(ngModel)]="optionField.fieldType" [options]="ticketAttendeesFieldOptions"></p-dropdown>
              </div>
              <div class="title">{{ 'PAGE_CUSTOM_FIELD_SELECT_OPTION_FIELD' | translate }}</div>
              <div class="description">{{ 'PAGE_CUSTOM_FIELD_SELECT_OPTION_FIELD_DESCRIPTION' | translate }}</div>
            </div>

            <c-collaboration-patch-input
              [object]="customField"
              [collaborationKey]="'customField:' + customFieldId"
              [jsonpath]="'$.fields[$index].internalName'"
              [jsonpathParams]="{ index: i }"
              [inputs]="fieldsInternalNameInput"
            ></c-collaboration-patch-input>
            <c-collaboration-patch-input
              [object]="customField"
              [collaborationKey]="'customField:' + customFieldId"
              [jsonpath]="'$.fields[$index].local.$language.title'"
              [jsonpathParams]="{ language: currentLanguage, index: i }"
              [inputs]="fieldsLocalTitleInput"
            ></c-collaboration-patch-input>

            <div class="input-group">
              <div class="input">
                <p-checkbox [(ngModel)]="optionField.mandatory" [binary]="true"></p-checkbox>
              </div>
              <div class="title">{{ 'PAGE_CUSTOM_FIELD_SELECT_OPTION_MANDATORY' | translate }}</div>
              <div class="description">{{ 'PAGE_CUSTOM_FIELD_SELECT_OPTION_MANDATORY_DESCRIPTION' | translate }}</div>
            </div>
            <c-collaboration-patch-input
              [object]="customField"
              [collaborationKey]="'customField:' + customFieldId"
              [jsonpath]="'$.fields[$index].maxCharacters'"
              [jsonpathParams]="{ index: i }"
              [inputs]="fieldsMaxCharactersInput"
            ></c-collaboration-patch-input>

            <div class="list-footer"></div>
          </div>

          <!-- Options Footer With Add Button -->
        </ng-container>
      </div>
      <p-button [label]="'PAGE_CUSTOM_FIELD_TYPE_ADD_OPTION' | translate" icon="pi icon-ic-add-item" styleClass="p-button-sm" (click)="onAddOption(customField)"> </p-button>
    </div>
  </div>
</div>
