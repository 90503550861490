import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { LazyLoadEvent, SelectItem } from 'primeng/api';

import { UsersService } from 'src/app/services/users/users.service';
import { MatchMode, UtilsService } from 'src/app/services/utils/utils.service';

import { SelectableTag, TagValues } from 'src/common/entities/Tag';
import { ArtificialUser, asUser, isArtificialUser, User } from 'src/common/entities/User';

interface Search {
  expert: boolean;
  firstName: string;
  lastName: string;
  active: boolean;
  tags: SelectableTag;
}

@Component({
  selector: 'c-chat-user-management',
  templateUrl: './chat-user-management.component.html',
  styleUrls: ['./chat-user-management.component.scss'],
})
export class ChatUserManagementComponent implements OnInit, OnDestroy {
  @Output() onUserCreated = new EventEmitter<User>();
  @Output() onUserUpdated = new EventEmitter<User>();

  isBusy: boolean = true;
  editing: boolean = false;

  searchSubject: Subject<Search> = new Subject<Search>();
  searchSubscription: Subscription;
  search: Search = {
    expert: null,
    firstName: '',
    lastName: '',
    active: true,
    tags: null,
  };
  searchMatchMode: MatchMode = {
    ['expert']: 'equals',
    ['firstName']: 'contains',
    ['lastName']: 'contains',
    ['active']: 'equals',
    ['tags']: 'equals',
  };

  lastLoadEvent: LazyLoadEvent;

  userTypes: SelectItem[];
  users: ArtificialUser[] = [];
  usersTotalRecords: number = 0;

  selectedUserTypeIsExpert: boolean = false;
  firstName: string;
  lastName: string;
  active: boolean = true;
  tagValues: TagValues = {};

  constructor(private userService: UsersService, public utilsService: UtilsService) {}

  async ngOnInit() {
    this.userTypes = [
      { title: await this.utilsService.translate('GENERAL_FAKE_USER'), value: false },
      { title: await this.utilsService.translate('GENERAL_FAKE_EXPERT_USER'), value: true },
    ];
    // Listen to search input controls changes
    this.searchSubscription = this.searchSubject.pipe(debounceTime(500)).subscribe((search) => {
      this.loadUsers();
    });
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

  onSearchChange() {
    this.searchSubject.next(this.search);
  }

  async loadUsers(loadEvent?: LazyLoadEvent) {
    this.isBusy = true;
    // Prepare paginator, sort and ...
    loadEvent = loadEvent || this.lastLoadEvent;
    this.lastLoadEvent = loadEvent;
    const query = loadEvent ? this.utilsService.loadEventToQuery(loadEvent) : {};
    // ... apply other table filter settings
    query.filter = { ...query.filter };
    for (const field of Object.keys(this.search)) {
      if ((this.search[field] && this.search[field] !== null) || this.search[field] === false) {
        if (field !== 'tags') {
          query.filter[field] = {
            matchMode: this.searchMatchMode[field],
            value: this.search[field],
            caseInsensitive: true,
          };
        }
      }
    }

    try {
      const result = await this.userService.getArtificialUsers(query);
      this.users = result.items as ArtificialUser[];
      this.usersTotalRecords = result.totalCount;
    } catch (err) {
      throw err;
    }
    this.isBusy = false;
  }

  async createUser() {
    this.isBusy = true;
    const newUser = await this.userService.createUser(<ArtificialUser>{
      userType: 'ArtificialUser',
      firstName: this.firstName,
      lastName: this.lastName,
      expert: this.selectedUserTypeIsExpert,
      active: this.active,
      tags: this.tagValues,
    });
    this.onUserCreated.emit(asUser(newUser));

    await this.loadUsers(this.lastLoadEvent);
    this.isBusy = false;

    this.firstName = '';
    this.lastName = '';
    this.active = true;
  }

  async onRowEditSave(user: User) {
    this.isBusy = true;
    const updatedUser = await this.userService.updateUser(user);
    this.isBusy = false;
    this.onUserUpdated.emit(asUser(updatedUser));
  }

  getUserType(expert: boolean): string {
    return expert ? this.userTypes[1].title : this.userTypes[0].title;
  }

  onRowEditInit(user: User) {
    if (user.active === null) user.active = false;
    this.editing = true;
  }

  onRowEditCancel(user: User, index: number) {
    this.loadUsers(this.lastLoadEvent);
  }

  asArtificialUser(user: any): ArtificialUser {
    return isArtificialUser(user) ? user : null;
  }
}
