import { Component, Input, OnInit } from '@angular/core';
import { Event } from '../../../../common/entities/Event';
import { EventVersion } from '../../../../common/entities/EventVersion';
import { Page } from '../../../../common/entities/Page';
import { TextPageModule } from '../../../../common/entities/pagemodules/TextPageModule';
import { InputConfiguration } from '../../../../common/inputs/Inputs';

@Component({
  selector: 'c-login-page-module-edit',
  templateUrl: './login-page-module-edit.component.html',
  styleUrls: ['./login-page-module-edit.component.scss'],
})
export class LoginPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: TextPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  inputJsonPathes: string[] = ['$.local.$language.title', '$.titlePosition'];

  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
  }
}
