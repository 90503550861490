import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { combineLatest, Subscription } from 'rxjs';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { Language } from 'src/common/entities/Language';

@Component({
  selector: 'c-language-button',
  templateUrl: './language-button.component.html',
  styleUrls: ['./language-button.component.scss'],
})
export class LanguageButtonComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  currentLanguage: string;

  @Output()
  currentLanguageChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  languages: string[];

  @Output()
  languagesChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input()
  allowedLanguages: string[];

  @Input()
  disabled = false;

  @Input()
  manageLanguages = true;

  subscription: Subscription;
  showManageLanguages = false;
  allLanguages: Language[];
  languageOptions: MenuItem[] = [];
  languagesEdit: string[] = [];
  working = false;

  constructor(private activatedRoute: ActivatedRoute, private languagesService: LanguagesService, private router: Router) {
    this.subscription = combineLatest([this.languagesService.getActiveLanguages(), this.activatedRoute.queryParams]).subscribe(([allLanguages, queryParams]) => {
      /* this.allLanguages = allLanguages;
      TODO: Use language ids consistently */

      this.allLanguages = allLanguages.filter(
        (value, index, arr) =>
          // Remove Objects with duplicate IDs
          index === arr.findIndex((i) => i._id === value._id)
      );

      if (queryParams.language && this.currentLanguage !== queryParams.language) {
        this.setCurrentLanguage(queryParams.language);
      }
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.currentLanguage && !this.activatedRoute.snapshot.queryParams.language) {
      await this.navigate(this.currentLanguage);
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.languages && changes.languages?.currentValue !== changes.languages?.previousValue) {
      if (this.languages.length === 0) {
        this.languages.push('en');
        this.emitLanguages();
      }

      if (!this.currentLanguage) {
        this.setDefault();
      }
    }

    if (
      this.currentLanguage &&
      changes.currentLanguage &&
      changes.currentLanguage?.currentValue !== changes.currentLanguage?.previousValue &&
      this.activatedRoute.snapshot.queryParams.language !== this.currentLanguage
    ) {
      await this.setCurrentLanguage(this.currentLanguage);
    }
  }

  setDefault(): void {
    if (this.languages.includes('en')) {
      this.setCurrentLanguage('en');
    } else if (this.languages.length > 0) {
      this.setCurrentLanguage(this.languages[0]);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async navigate(language?: string, eTz = this.activatedRoute.snapshot.queryParams.eTz || 'Europe/Berlin'): Promise<void> {
    await this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: {
        language,
        eTz,
      },
    });
  }

  async setCurrentLanguage(language: string): Promise<void> {
    if (language) {
      language = language.toLowerCase();

      if (this.allowedLanguages?.length > 0 && this.allowedLanguages.indexOf(language) < 0) {
        return;
      }

      if (this.allLanguages.findIndex((a) => a.language === language) < 0) {
        return;
      }

      this.currentLanguage = language;
      this.currentLanguageChange.emit(language);
      await this.navigate(this.currentLanguage);
    } else {
      this.currentLanguage = null;
      this.currentLanguageChange.emit(null);
      await this.navigate();
    }
  }

  async language(language: string): Promise<string> {
    const languages = (await this.languagesService.getLanguages()).items;
    const l = languages.find((lang) => lang._id === language || lang.language === language);
    return `${l?.language.toUpperCase()} | ${l?.languageName}`;
  }

  async fillLanguageOptions(): Promise<void> {
    this.languageOptions = (
      await Promise.all(
        this.languages.map(async (language) => ({
          label: await this.language(language),
          command: () => {
            setTimeout(() => {
              this.setCurrentLanguage(language);
            }, 0);
          },
        }))
      )
    ).sort((a, b) => a.label.localeCompare(b.label));

    this.languageOptions.push({ separator: true });

    if (this.manageLanguages) {
      this.languageOptions.push({
        label: 'Manage Languages...',
        command: async () => {
          this.languagesEdit = [...this.languages];
          this.working = false;
          this.showManageLanguages = true;
        },
      });
    }
  }

  emitLanguages(): void {
    this.working = true;
    this.showManageLanguages = false;

    setTimeout(() => {
      this.languages = [...this.languagesEdit];
      this.languagesChange.emit(this.languages);

      if (!this.languages.includes(this.currentLanguage)) {
        if (this.languages.length > 0) {
          this.setCurrentLanguage(this.languages[0]);
        } else {
          this.setCurrentLanguage(null);
        }
      }
    }, 100);
  }
}
