<div *skeleton="{ show: loading }">
  <div class="tab-header">
    <h1>
      <span class="pre-title">{{ 'GENERAL_LANGUAGE' | translate }}</span>
      <span>{{ language?.languageName }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <p-button *hasRight="'configuration.edit'" icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" [disabled]="loading || saving || !isValid() || !isLanguageChanged" (onClick)="save()"></p-button>
  </div>
  <div *hasRight="'configuration.edit'" class="panel">
    <div class="panel-header">
      {{ 'GENERAL_GLOBAL_PROPERTIES' | translate }}
    </div>
    <div *ngIf="language" class="panel-body">
      <c-collaboration-patch-input
        *ngFor="let jsonPath of jsonPathes"
        [object]="language"
        [collaborationKey]="'language:' + languageId"
        [jsonpath]="jsonPath"
        [inputs]="inputs"
        [error]="errorAt(jsonPath)"
      ></c-collaboration-patch-input>
    </div>
  </div>
  <div *hasNoRight="'configuration.edit'" class="panel">
    <div class="panel-header">
      {{ 'GENERAL_GLOBAL_PROPERTIES' | translate }}
    </div>
    <div *ngIf="language" class="panel-body">
      <c-collaboration-patch-input
        *ngFor="let jsonPath of jsonPathes"
        [disabled]="true"
        [object]="language"
        [collaborationKey]="'language:' + languageId"
        [jsonpath]="jsonPath"
        [inputs]="inputs"
        [error]="errorAt(jsonPath)"
      ></c-collaboration-patch-input>
    </div>
  </div>
</div>
