import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
})
export class IncludesPipe implements PipeTransform {
  transform(rights: string[], ...args: string[]): boolean {
    return rights.findIndex((r) => args.includes(r)) >= 0;
  }
}
