import { Pipe, PipeTransform } from '@angular/core';
import { KeytopicsService } from 'src/app/services/keytopics/keytopics.service';
import { Keytopic } from 'src/common/entities/Keytopic';

@Pipe({
  name: 'keytopic',
})
export class KeytopicPipe implements PipeTransform {
  constructor(private keytopicService: KeytopicsService) {}

  transform(keytopic: string, ...args: unknown[]): Promise<Keytopic> {
    return this.keytopicService.getKeytopic(keytopic);
  }
}
