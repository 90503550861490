import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { SelectItem } from 'primeng/api';

import { UtilsService } from 'src/app/services/utils/utils.service';

import { asPollMessage, Message, PollMessage, TextMessage } from 'src/common/entities/Message';
import { AllMessages, ChatContainer, ChatsService, ConfirmedMessage, DeletedMessage, OpenMessage, RejectedMessage } from 'src/app/services/chats/chats.service';

@Component({
  selector: 'c-chat-open-messages',
  templateUrl: './chat-open-messages.component.html',
  styleUrls: ['./chat-open-messages.component.scss'],
})
export class ChatOpenMessagesComponent implements OnInit {
  _chatId: string;
  @Input() set chatId(value: string) {
    if (value != this._chatId) {
      this._chatId = value;
      this.loadChat();
    }
  }

  @Output() onQuote = new EventEmitter<Message>();
  @Output() onReject = new EventEmitter<Message>();
  @Output() onOpenArtificialUser = new EventEmitter<string>();
  @Output() onConfirm = new EventEmitter<Message>();
  @Output() onReply = new EventEmitter<Message>();

  chatContainer: Observable<ChatContainer>;

  messageFilters: SelectItem[] = [];
  selectedMessageFilter: string = OpenMessage;

  constructor(private utilsService: UtilsService, private chatService: ChatsService) {}

  async ngOnInit() {
    this.messageFilters = [
      { title: await this.utilsService.translate('PAGE_LIVE_CHAT_ALL_MESSAGES'), value: AllMessages },
      { title: await this.utilsService.translate('PAGE_LIVE_CHAT_OPEN_MESSAGES'), value: OpenMessage },
      { title: await this.utilsService.translate('PAGE_LIVE_CHAT_REJECTED_MESSAGES'), value: RejectedMessage },
      { title: await this.utilsService.translate('PAGE_LIVE_CHAT_DELETED_MESSAGES'), value: DeletedMessage },
      { title: await this.utilsService.translate('PAGE_LIVE_CHAT_CONFIRMED_MESSAGES'), value: ConfirmedMessage },
    ];

    this.loadChat();
  }

  async loadChat() {
    if (this._chatId) {
      this.chatContainer = this.chatService.chatContainer(this._chatId);
    }
  }

  asPollMessage(message: Message): PollMessage | null {
    return asPollMessage(message);
  }

  confirm(message: Message) {
    this.chatService.confirmChatMessage(this._chatId, message._id);
    this.onConfirm.emit(message);
  }

  confirmAndQuote(message: Message) {
    this.confirm(message);
    this.onQuote.emit(message);
  }

  confirmAndReply(message: Message) {
    this.confirm(message);
    this.onReply.emit(message);
  }

  reject(message: Message) {
    this.chatService.rejectChatMessage(this._chatId, message._id);
  }

  rejectAndAnswerUser(message: Message) {
    this.onReject.emit(message);
  }

  getTextMessage(message: Message): TextMessage {
    return message as TextMessage;
  }

  openArtificialUser(user: string) {
    this.onOpenArtificialUser.emit(user);
  }
}
