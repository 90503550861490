import { EventKeytopicsIconGroupPageModuleAttributes } from './../../entities/pagemodules/EventKeytopicsIconGroupPageModule';
import { HeadlineType } from './../../entities/Attributes';
import { EventKeytopicsIconGroupPageModule } from '../../entities/pagemodules/EventKeytopicsIconGroupPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class EventKeytopicsIconGroupPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public createEventKeytopicsIconGroupPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.eventKeytopicsIconGroupPageModuleAttributes(values?.title_attr),
    };
  }

  public eventKeytopicsIconGroupPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): EventKeytopicsIconGroupPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async eventKeytopicsIconGroupPageModule(values: any): Promise<EventKeytopicsIconGroupPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'EventKeytopicsIconGroup',
      local: await this.local(values, (val) => this.createEventKeytopicsIconGroupPageModuleLocal(val)),
    };
  }
}
