import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';

import { ChatMessageTemplatesService } from 'src/app/services/chatmessagetemplates/chatmessagetemplate.service';
import { ChatsService } from 'src/app/services/chats/chats.service';
import { EventsService } from 'src/app/services/events/events.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ChatMessageTemplate } from 'src/common/entities/ChatMessageTemplate';
import { Message, MessageUser } from 'src/common/entities/Message';
import { ArtificialUser } from 'src/common/entities/User';
import { Event } from 'src/common/entities/Event';
import { SpeakerSession } from 'src/common/entities/Session';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { AdminUsersService } from 'src/app/services/admin-users/admin-users.service';
import { AdminUser, isAzureADAdminUser } from 'src/common/entities/AdminUser';
import { TagValues } from 'src/common/entities/Tag';
import { IFilterableListQuery } from 'src/common/api/interfaces';

const expertUser = 'expert';
const fakeUser = 'fake';
const fakeExpertUser = 'fakeExpert';

@Component({
  selector: 'app-live-region',
  templateUrl: './live-region.component.html',
  styleUrls: ['./live-region.component.scss'],
})
export class LiveRegionComponent implements OnInit {
  @Input() chatId: string;

  userFilters: SelectItem[];
  selectedUserFilter: string = fakeUser;

  filteredSendingUsers: AdminUser[] | ArtificialUser[] = [];
  currentSendingUser: AdminUser | ArtificialUser = null;

  displayUserManagement: boolean = false;
  displayChatMessageTemplateManagement: boolean = false;

  chatMessage = '';
  quotedMessage: Message;
  rejectedMessage: Message;
  chatMessageToAdd = '';

  _experts: AdminUser[] = [];
  _fakeUsers: ArtificialUser[] = [];
  _fakeExperts: ArtificialUser[] = [];

  tagValues: TagValues = {};

  _chatMessageTemplates: ChatMessageTemplate[] = [];

  region: string;
  eventId: string;
  event: Event;
  session: SpeakerSession;

  selectedChatMessageTemplate: ChatMessageTemplate;

  loading: boolean = false;

  constructor(
    private userServie: UsersService,
    private adminUsersService: AdminUsersService,
    private chatService: ChatsService,
    private chatMessageTemplateService: ChatMessageTemplatesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private eventService: EventsService,
    private utilsService: UtilsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.userFilters = [
      // {title: await this.utilsService.translate('GENERAL_EXPERT_USER'), value: expertUser},
      { title: await this.utilsService.translate('GENERAL_FAKE_USER'), value: fakeUser },
      { title: await this.utilsService.translate('GENERAL_FAKE_EXPERT_USER'), value: fakeExpertUser },
    ];

    this.eventId = this.activatedRoute.snapshot.parent.params['eventId'];
    this.region = this.activatedRoute.snapshot.params['region'];

    this.loading = true;
    this.loadEvent();
    this.loadChat();
    this.loadChatMessageTemplates();
    this.loadUsers();
    this.loading = false;
  }

  back() {
    this.router.navigate(['/live', this.event._id], {
      replaceUrl: true,
    });
  }

  async loadChatMessageTemplates() {
    this._chatMessageTemplates = (await this.chatMessageTemplateService.getChatMessageTemplates()).items.filter((item) => item.active);
  }

  async loadChat() {
    this.chatId = (await this.chatService.getChatsByEventAndRegion(this.eventId, this.region)).items[0]._id;
  }

  async loadEvent() {
    this.event = await this.eventService.getEvent(this.eventId);
    this.session = this.event.currentEventVersion.sessions[0] as SpeakerSession;
  }

  async loadUsers() {
    this._experts = (await this.adminUsersService.getExperts()).items;
    // Only use AdminUser experts with existing user
    this._experts = this._experts.filter((expert) => expert.user);

    const artificialUsers = (
      await this.userServie.getUsers({
        filter: {
          userType: {
            matchMode: 'equals',
            value: 'ArtificialUser',
          },
        },
      })
    ).items;
    this._fakeUsers = artificialUsers as ArtificialUser[];
    this._fakeExperts = this._fakeUsers.filter((user) => user.expert && user.expert == true);
    this._fakeUsers = this._fakeUsers.filter((user) => !user.expert || !user.expert);

    this.changedSelectedUserFilter({ value: this.selectedUserFilter });
  }

  changedSelectedUserFilter(event) {
    // Reset choosen user after changing user type
    this.currentSendingUser = null;
  }

  async changedSendingUser(choosenUser: ArtificialUser | AdminUser) {
    if (isAzureADAdminUser(choosenUser)) {
      const adminUser = await this.adminUsersService.getAdminUser(choosenUser.oid);
      this.currentSendingUser = adminUser;
    } else {
      this.currentSendingUser = choosenUser;
    }
  }

  async filterSendingUser(event) {
    if (this.selectedUserFilter === expertUser) {
      this.filterExperts(event);
    } else if (this.selectedUserFilter === fakeExpertUser || this.selectedUserFilter === fakeUser) {
      this.filterArtificialUsers(event);
    }
  }

  async filterExperts(event) {
    // NOT IN USE TILL YET
    this.filteredSendingUsers = await (
      await this.adminUsersService.getRemoteUsers({
        source: 'Remote',
        filter: {
          displayName: {
            matchMode: 'contains',
            value: event.query,
            caseInsensitive: false,
          },
        },
      })
    ).items;
  }

  async filterArtificialUsers(event) {
    var query: IFilterableListQuery = {};
    query.filter = { ...query.filter };
    // Apply artificial user filter setting
    query.filter['userType'] = {
      matchMode: 'equals',
      value: 'ArtificialUser',
    };
    // Apply users autocomplete filter setting
    if (event.query) {
      query.filter['displayName'] = {
        matchMode: 'equals',
        value: event.query,
        caseInsensitive: false,
      };
    }

    // Get & transform users
    const users = (await this.userServie.getUsers(query)).items;
    const artificialUsers = users as ArtificialUser[];

    // Apply expert filter
    if (this.selectedUserFilter == fakeUser) {
      this.filteredSendingUsers = artificialUsers.filter((user) => user.active && !user.expert);
    } else {
      this.filteredSendingUsers = artificialUsers.filter((user) => user.active && user.expert);
    }
  }

  async sendMessage(): Promise<void> {
    let messageUser: MessageUser = null;
    let expert: boolean = false;

    if (this.rejectedMessage) {
      await this.chatService.rejectChatMessage(this.chatId, this.rejectedMessage._id, this.chatMessage);
    } else {
      if (isAzureADAdminUser(this.currentSendingUser)) {
        messageUser = {
          _id: this.currentSendingUser?._id,
          firstName: this.currentSendingUser.firstName,
          lastName: this.currentSendingUser.lastName,
        };
      } else {
        messageUser = {
          _id: this.currentSendingUser._id,
          firstName: null,
          lastName: null,
        };
      }
      if (!messageUser) return;

      if (this.selectedUserFilter == expertUser || this.selectedUserFilter == fakeExpertUser) {
        expert = true;
      }

      await this.chatService.createChatMessage(this.chatId, {
        data: { text: this.chatMessage },
        messageType: 'TextMessage',
        relatedMessage: this.quotedMessage ? this.quotedMessage : null,
        user: messageUser,
        expert: expert,
      });
    }

    this.chatMessage = '';
    this.rejectedMessage = null;
    this.quotedMessage = null;
  }

  createQuote(message: Message) {
    this.rejectedMessage = null;
    this.quotedMessage = message;
  }

  createReject(message: Message) {
    this.quotedMessage = null;
    this.rejectedMessage = message;
  }

  showUserManagementDialog() {
    this.displayUserManagement = true;
  }

  showChatMessageTemplateDialog() {
    this.chatMessageToAdd = '';
    this.displayChatMessageTemplateManagement = true;
  }

  bookmarkMessageAsTemplate() {
    this.chatMessageToAdd = this.chatMessage;
    this.displayChatMessageTemplateManagement = true;
  }

  selectChatMessageTemplate(event) {
    this.selectedChatMessageTemplate = event.value;
  }

  addChatMessageTemplateToChatMessage() {
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach((textarea) => {
      if (textarea.id === 'message') {
        textarea.setRangeText(this.selectedChatMessageTemplate?.content, textarea.selectionStart, textarea.selectionEnd, 'end');
        this.chatMessage = textarea.value; // Otherwise changes not detected on textarea
      }
    });
  }
}
