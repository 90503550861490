import { PageModule, TitlePositions } from './PageModule';
import { CTAButton } from '../CTAButton';
import { TextAttributes } from '../Attributes';

export interface ListVideoPageModule extends PageModule {
  pageModuleType: 'ListVideo';
  titlePosition?: TitlePositions | 'content';
  local: { [language: string]: ListVideoPageModuleLocal };

  /** @deprecated Use `local.video`. */
  video?: string;
  videoSize: number;
  videoPosition: 'left' | 'right';
  ctaButton?: CTAButton;
  contentArea?: number;
  fullWidthMargin?: number;
  fullWidth?: number;
  videoAppearanceMobile?: 'contentArea' | 'fullWidthMargin' | 'fullWidth';
  videoPositionMobile?: 'top' | 'bottom';
}

export interface ListVideoPageModuleLocal {
  title: string;
  title_attr?: ListVideoPageModuleAttributes;
  items: string[];
  upperText?: string;
  lowerText?: string;
  video?: string;
  itemIcon?: string;
}

export type ListVideoPageModuleAttributes = TextAttributes & {};

export function isListVideoPageModule(object: any): object is ListVideoPageModule {
  return object?.pageModuleType === 'ListVideo';
}
