<div *ngIf="loading" class="loading full-height">
  <c-loading [loading]="true" [hideTitle]="true"></c-loading>
</div>

<div *ngIf="!loading">
  <div *ngIf="isPoll">
    <router-outlet></router-outlet>
  </div>

  <div *ngIf="!isPoll">
    <div *ngIf="loginRequired" class="login full-height">
      <div class="panel">
        <div class="panel-header">Login</div>
        <div class="panel-body">
          <div class="login-error" *ngIf="loginError">
            <p-message severity="error" text="Invalid credentials"></p-message>
          </div>
          <form (ngSubmit)="login()">
            <div class="input-group">
              <div class="input">
                <input type="text" [(ngModel)]="credentials.email" name="email" [disabled]="loginWorking" />
              </div>
              <div class="title">Email</div>
            </div>
            <div class="input-group">
              <div class="input">
                <input type="password" [(ngModel)]="credentials.password" name="password" [disabled]="loginWorking" />
              </div>
              <div class="title">Password</div>
            </div>
            <div class="input-group button">
              <div class="input">
                <button pButton type="submit" class="p-button" (onClick)="login()" label="Login" [disabled]="loginWorking || !credentials.email || !credentials.password"></button>
              </div>
              <div class="title"></div>
            </div>
          </form>
          <div class="or"><span>OR</span></div>
          <div>
            <p-button class="p-button-outlined p-button-secondary full-width" styleClass="full-width" (onClick)="loginAd()" label="AzureAD Login" [disabled]="loginWorking"></p-button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!loginRequired" class="tabs-container full-height">
      <c-tabs></c-tabs>
      <div class="route">
        <!-- <div *ngIf="(currentTab() | async) as tab">
          {{tab.configuration.title}}
        </div> -->
        <router-outlet></router-outlet>
      </div>
    </div>

    <p-toast></p-toast>
  </div>
</div>
