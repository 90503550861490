import { DomainCollection } from './../../../common/entities/configuration/DomainConfiguration';
import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';

import { StaticContentConfiguration } from 'src/common/entities/StaticContentConfiguration';
import { GetStaticContentConfigurationQuery, GetStaticContentConfigurationResponse } from 'src/common/api/v1/configuration/staticContentConfigurations/GetStaticContentConfiguration';
import { GetStaticContentConfigurationsQuery, GetStaticContentConfigurationsResponse } from 'src/common/api/v1/configuration/staticContentConfigurations/GetStaticContentConfigurations';

@Injectable({
  providedIn: 'root',
})
export class StaticContentConfigurationsService {
  constructor(private apiService: ApiService) {}

  getStaticContentConfiguration(shortId: string): Promise<StaticContentConfiguration> {
    return this.apiService.get<GetStaticContentConfigurationQuery, GetStaticContentConfigurationResponse>(`/api/v1/configuration/static-content-configurations/${shortId}`).toPromise();
  }

  async getStaticContentConfigurations(query?: GetStaticContentConfigurationsQuery): Promise<GetStaticContentConfigurationsResponse> {
    return await this.apiService
      .get<GetStaticContentConfigurationsQuery, GetStaticContentConfigurationsResponse>('/api/v1/configuration/static-content-configurations', {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();
  }
}
