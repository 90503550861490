import { TextAttributes } from './../Attributes';
import { CTAButton } from '../CTAButton';
import { PageModule } from './PageModule';

export const videoSliderTypes = ['Default', 'Keytopic', 'KeytopicAllEvents', 'Highlights'] as const;
export type VideoSliderType = (typeof videoSliderTypes)[number];

export interface VideoSliderPageModule extends PageModule {
  pageModuleType: 'VideoSlider';
  showHighlightSessions?: boolean;
  keytopic?: string;
  event?: string;
  videoSliderType: VideoSliderType;
  local: {
    [language: string]: {
      title: string;
      title_attr?: VideoSliderPageModuleModuleAttributes;
      videos: string[];
    };
  };
  ctaButton?: CTAButton;
  maxCount?: number;
}

export type VideoSliderPageModuleModuleAttributes = TextAttributes & {};

export function isVideoSliderPageModule(object: any): object is VideoSliderPageModule {
  return object?.pageModuleType === 'VideoSlider';
}
