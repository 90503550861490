import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localedate',
})
export class LocaledatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: string | Date, format?: 'date' | 'time' | 'date-time'): string {
    if (!value) return null;

    const date = new Date(value);

    if (format === 'date') {
      return this.datePipe.transform(date, 'mediumDate');
    }
    if (format === 'time') {
      return this.datePipe.transform(date, 'mediumTime');
    }
    return this.datePipe.transform(date, 'medium');
  }
}
