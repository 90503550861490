import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConfigurationModule } from './configuration/configuration.module';
import { LanguageModule } from './language/language.module';
import { LanguagesModule } from './languages/languages.module';
import { I18nModule } from './i18n/i18n.module';
import { RegionModule } from './region/region.module';
import { RegionsModule } from './regions/regions.module';
import { BlacklistEntryModule } from './blacklist-entry/blacklist-entry.module';
import { BlacklistEntriesModule } from './blacklist-entries/blacklist-entries.module';
import { StaticContentModule } from './static-content/static-content.module';
import { StaticContentsModule } from './static-contents/static-contents.module';
import { EmailTemplateModule } from './email-template/email-template.module';
import { EmailTemplatesModule } from './email-templates/email-templates.module';
import { LookupModule } from './lookup/lookup.module';
import { LookupsModule } from './lookups/lookups.module';
import { CustomFieldModule } from './custom-field/custom-field.module';
import { CustomFieldsModule } from './custom-fields/custom-fields.module';

import { DashboardModule } from './dashboard/dashboard.module';
import { EventModule } from './event/event.module';
import { EventsModule } from './events/events.module';
import { KeytopicModule } from './keytopic/keytopic.module';
import { KeytopicsModule } from './keytopics/keytopics.module';
import { SpeakersModule } from './speakers/speakers.module';
import { SpeakerModule } from './speaker/speaker.module';
import { TemplatesConfigurationModule } from './templates-configuration/templates-configuration.module';
import { LiveModule } from './live/live.module';
import { AssetsModule } from './assets/assets.module';
import { UsersModule } from './users/users.module';
import { UserModule } from './user/user.module';
import { AppointmentModule } from './appointment/appointment.module';
import { AppointmentsModule } from './appointments/appointments.module';
import { AdminUsersModule } from './admin-users/admin-users.module';
import { AdminUserModule } from './admin-user/admin-user.module';
import { GroupsModule } from './groups/groups.module';
import { GroupModule } from './group/group.module';
import { RolesModule } from './roles/roles.module';
import { RoleModule } from './role/role.module';
import { ExportsModule } from './exports/exports.module';
import { StreamsModule } from './streams/streams.module';
import { PageConfigurationModule } from './page-configuration/page-configuration.module';
import { PagesConfigurationModule } from './pages-configuration/pages-configuration.module';
import { PollModule } from './poll/poll.module';
import { EventTypesConfigurationModule } from './event-types-configuration/event-types-configuration.module';
import { EventTypeConfigurationModule } from './event-type-configuration/event-type-configuration.module';
import { AssetConfigurationModule } from './asset-configuration/asset-configuration.module';
import { AssetSeriesModule } from './asset-series/asset-series.module';
import { QRCodesModule } from './qr-codes/qr-codes.module';
import { OnboardingsModule } from './onboardings/onboardings.module';
import { EmbeddedPagesModule } from './embedded-pages/embedded-pages.module';
import { OperatingCountriesModule } from './operating-countries/operating-countries.module';
import { OperatingRegionsModule } from './operating-regions/operating-regions.module';
import { CSPReportsModule } from './csp-reports/csp-reports.module';
import { ErrorModule } from './error/error.module';
import { ErrorUnauthorizedModule } from './error-unauthorized/error-unauthorized.module';

const pages = [
  ConfigurationModule,
  ErrorModule,
  ErrorUnauthorizedModule,
  LanguageModule,
  LanguagesModule,
  OperatingCountriesModule,
  OperatingRegionsModule,
  I18nModule,
  RegionModule,
  RegionsModule,
  BlacklistEntryModule,
  BlacklistEntriesModule,
  StaticContentModule,
  StaticContentsModule,
  EmailTemplateModule,
  EmailTemplatesModule,
  EventTypesConfigurationModule,
  EventTypeConfigurationModule,
  LookupModule,
  LookupsModule,
  CustomFieldModule,
  CustomFieldsModule,
  DashboardModule,
  EventsModule,
  EventModule,
  KeytopicsModule,
  KeytopicModule,
  SpeakersModule,
  SpeakerModule,
  TemplatesConfigurationModule,
  PagesConfigurationModule,
  EmbeddedPagesModule,
  PageConfigurationModule,
  LiveModule,
  AssetsModule,
  AssetSeriesModule,
  UsersModule,
  UserModule,
  AppointmentModule,
  AppointmentsModule,
  AdminUsersModule,
  AdminUserModule,
  GroupsModule,
  GroupModule,
  RolesModule,
  RoleModule,
  ExportsModule,
  StreamsModule,
  PollModule,
  AssetConfigurationModule,
  AssetSeriesModule,
  QRCodesModule,
  OnboardingsModule,
  CSPReportsModule,
];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...pages],
})
export class PagesModule {}
