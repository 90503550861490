import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentsComponent } from './appointments.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DialogModule } from 'primeng/dialog';
import { AppointmentDetailModule } from '../appointments/appointment-detail/appointment-detail.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { AppointmentsTableComponent } from './appointments-table/appointments-table.component';
import { DividerModule } from 'primeng/divider';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ComponentsModule } from 'src/app/components/components.module';

const routes: Routes = [{ path: '', component: AppointmentsComponent }];
@NgModule({
  declarations: [AppointmentsComponent, AppointmentsTableComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    TableModule,
    PipesModule,
    SplitButtonModule,
    DividerModule,
    DialogModule,
    AppointmentDetailModule,
    DirectivesModule,
    ComponentsModule,
    InputTextModule,
    MultiSelectModule,
    FormsModule,
    MenuModule,
    CardModule,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    ChipModule,
    DividerModule,
    AutoCompleteModule,
    ChipsModule,
    DialogModule,
    ConfirmPopupModule,
    OverlayPanelModule,
  ],
})
export class AppointmentsModule {}
