import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'c-sidebar-header',
  templateUrl: './sidebar-header.component.html',
  styleUrls: ['./sidebar-header.component.scss'],
})
export class SidebarHeaderComponent {
  @Input()
  headerTitle: string;

  @Input()
  headerSubTitle: string;

  @Input()
  showCloseButton: boolean = true;
  @Output()
  onClose = new EventEmitter<boolean>();

  @Input()
  showSaveButton: boolean = false;
  @Output()
  onSave = new EventEmitter<boolean>();

  @Input()
  showBackButton: boolean = false;
  @Output()
  onBack = new EventEmitter<boolean>();
}
