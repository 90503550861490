import { PageModule, TitlePositions } from './PageModule';

export interface FormPageModule extends PageModule {
  pageModuleType: 'Form';
  titlePosition?: TitlePositions;
  buttonFullWidth: boolean;
  form: string;
}

export function isFormPageModule(object: any): object is FormPageModule {
  return object?.pageModuleType === 'Form';
}
