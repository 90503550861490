import { TextAttributes } from './../Attributes';
import { PageModule } from './PageModule';

export interface EventKeytopicsIconGroupPageModule extends PageModule {
  pageModuleType: 'EventKeytopicsIconGroup';
  local: {
    [language: string]: {
      title: string;
      title_attr?: EventKeytopicsIconGroupPageModuleAttributes;
    };
  };
}

export type EventKeytopicsIconGroupPageModuleAttributes = TextAttributes & {};

export function isEventKeytopicsIconGroupPageModule(object: any): object is EventKeytopicsIconGroupPageModule {
  return object?.pageModuleType === 'EventKeytopicsIconGroup';
}
