import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminUser } from 'src/common/entities/AdminUser';
import { AbstractFactory } from 'src/common/factories/AbstractFactory';
import { ApiSocketService } from './services/api-socket/api-socket.service';
import { AuthService } from './services/auth/auth.service';
import { ConfigurationService } from './services/configuration/configuration.service';
import { MsalService } from './services/msal/msal.service';
import { Tab } from './tabs/classes/tab';
import { TabsService } from './tabs/services/tabs.service';
import { stickyTabs } from './tabs/sticky-tabs';
import { routes } from './app-routing.module';
import { Environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loading: boolean = true;
  isPoll: boolean = false;

  loginRequired: boolean = false;
  loginError: boolean = false;
  loginWorking: boolean = false;

  credentials: {
    email: string;
    password: string;
  } = {
    email: '',
    password: '',
  };

  tabState: { [resolvedUrl: string]: 'loading' | 'denied' | 'show' } = {};

  constructor(
    private environment: Environment,
    private titleService: Title,
    private configurationService: ConfigurationService,
    private authService: AuthService,
    private msalService: MsalService,
    private router: Router,
    private apiSocket: ApiSocketService,
    private tabsService: TabsService
  ) {
    this.isPoll = document.URL.includes('/polls/');

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const pollUrl = /^\/polls\//;
        this.isPoll = pollUrl.test(event.url);
      }
    });

    AbstractFactory.setNewIdFunction(async () => {
      return this.configurationService.newObjectId();
    });

    // this.tabsService.currentTab().subscribe((tab) => {
    //   if (!this.tabState[tab.resolvedUrl]) {
    //     this.tabState[this.tabState[tab.resolvedUrl]]
    //   }
    // })
  }

  adminUser(): Observable<AdminUser> {
    return this.authService.adminUser();
  }

  async ngOnInit() {
    this.titleService.setTitle(`${this.environment.instanceAlias ? this.environment.instanceAlias + ' - ' : ''}Alive Admin Portal`);
    if (this.isPoll) {
      this.loading = false;
      return;
    }

    try {
      const msalToken = await this.msalService.ready();

      if (msalToken) {
        await this.authService.loginAd(msalToken);
      }

      if ((await this.authService.checkAccount()) && this.authService.currentAdminUser()) {
        await this.init();
        this.loading = false;
        this.loginRequired = false;
      } else {
        this.loading = false;
        this.loginRequired = true;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async login() {
    if (!this.credentials.email || !this.credentials.password) return;

    this.loginWorking = true;
    this.loginError = false;

    try {
      const successful = await this.authService.login(this.credentials.email, this.credentials.password);

      if (successful) {
        await this.init();
        this.loginRequired = false;
      } else {
        this.loginError = true;
      }
    } catch (err) {
      console.error(err);
      this.loginError = true;
    }

    this.loginWorking = false;
  }

  async loginAd() {
    this.loginWorking = true;

    try {
      await this.msalService.login();
    } catch (err) {
      console.error(err);
    }

    this.loginWorking = false;
  }

  logout() {
    this.authService.logout();
    window.location.reload();
  }

  async init() {
    await this.configurationService.ready();
    this.apiSocket.connect();

    if (!this.authService.hasGlobalRight('ADMIN') && ['', '/'].includes(this.router.url.split('#')[0])) {
      this.router.navigate(['/appointments'], { replaceUrl: true });
    }

    for (const stickyTab of stickyTabs) {
      const tab = this.tabsService.register({
        ...stickyTab,
        hasRight: routes.find((r) => r.path === stickyTab.url?.slice(1))?.data.hasRight,
        sticky: true,
      });

      if (tab.configuration.collapsible) {
        tab.collapsed = true;
      }

      for (const child of stickyTab.children || []) {
        this.tabsService.register({
          ...child,
          sticky: true,
          parent: tab,
        });
      }
    }
  }

  currentTab(): Observable<Tab | null> {
    return this.tabsService.currentTab();
  }
}
