<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'PAGE_EVENT_TYPES_CONFIGURATION' | translate }}</h1>
  </div>
  <div class="tab-menu"></div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_EVENT_TYPES_CONFIGURATION' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button *hasRight="'configuration.create'" [label]="'PAGE_EVENT_TYPES_CONFIGURATION_ADD' | translate" icon="pi icon-ic-add-item" (onClick)="newEventTyp()"></p-button>
      </div>
    </div>
    <c-table mode="query" [options]="tableOptions" [tableOptionsComponent]="toc" (query)="query($event)" [baseRoute]="'/configuration/types'" [routePathProperty]="'_id'">
      <ng-template let-eventType>
        <div *hasRight="['configuration.read', 'configuration.edit']">{{ eventType.internalName || ('GENERAL_EMPTY' | translate) }}</div>
        <span *hasNoRight="['configuration.read', 'configuration.edit']">{{ eventType.internalName || ('GENERAL_EMPTY' | translate) }}</span>
      </ng-template>
      <ng-template let-eventType>{{ eventType.local?.en?.title }}</ng-template>
      <ng-template let-eventType>{{ eventType.defaultTemplate }}</ng-template>
    </c-table>
  </div>
</div>
