<p-dialog
  [header]="'COMPONENT_EVENT_VERSION_DIFF_VERSIONS' | translate"
  [(visible)]="show"
  (visibleChange)="showChange.emit($event)"
  [style]="{ margin: '80px', overflow: 'none', width: '80vw', height: '100vw' }"
>
  <div class="inputs-wrapper">
    <div class="input-group compact item">
      <div class="input">
        <p-dropdown [options]="ev" [(ngModel)]="firstVersion" placeholder="1. Version" optionLabel="name" [showClear]="true"></p-dropdown>
      </div>
      <div class="title">{{ 'LEFT_VERSION' | translate }}</div>
    </div>

    <div class="input-group compact item">
      <div class="input">
        <p-dropdown [options]="ev" [(ngModel)]="secondVersion" placeholder="2. Version" optionLabel="name" [showClear]="true"></p-dropdown>
      </div>
      <div class="title">{{ 'RIGHT_VERSION' | translate }}</div>
    </div>
    <p-button [disabled]="!(secondVersion && firstVersion)" label="Compare" (click)="compare()"></p-button>
  </div>
  <div id="visualdiff">
    <div #questions class="jsondiffpatch-delta jsondiffpatch-node jsondiffpatch-child-node-type-object" [innerHTML]="innerDiv"></div>
  </div>
</p-dialog>
