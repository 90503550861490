import { BackgroundTheme, PageModule } from '../../entities/PageModule';
import { AbstractFactory, FactoryOptions } from '../AbstractFactory';

export abstract class BasePageModuleFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public static backgroundTheme(backgroundTheme?: BackgroundTheme): BackgroundTheme {
    return backgroundTheme || 'Light';
  }

  public async baseValues(values: any = {}): Promise<Omit<PageModule, 'pageModuleType'>> {
    return {
      _id: await this.id(values._id),
      backgroundTheme: BasePageModuleFactory.backgroundTheme(values.backgroundTheme),
      accessPolicy: values?.accessPolicy || [],
      anchor: values?.anchor || null,
      paddingTop: typeof values?.paddingTop === 'boolean' ? values.paddingTop : true,
      paddingBottom: typeof values?.paddingBottom === 'boolean' ? values.paddingBottom : true,
      paddingRight: typeof values?.paddingRight === 'boolean' ? values.paddingRight : true,
      paddingLeft: typeof values?.paddingLeft === 'boolean' ? values.paddingLeft : true
    };
  }
}
