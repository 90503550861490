import { NewsfeedEntryPageModule } from '../../entities/pagemodules/NewsfeedEntryPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class NewsfeedEntryPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async createNewsfeedEntryPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      text: values?.text || '',
      image: values?.image || '',
    };
  }

  public async newsfeedEntryPageModule(values: any): Promise<NewsfeedEntryPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'NewsfeedEntry',
      local: await this.local(values, (val) => this.createNewsfeedEntryPageModuleLocal(val)),
      tags: values?.tags || [],
      ctaButton: values?.ctaButton || null,
    };
  }
}
