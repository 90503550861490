import { Pipe, PipeTransform } from '@angular/core';
import * as jp from 'jsonpath';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Pipe({
  name: 'extract',
})
export class ExtractPipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}

  transform(value: any, jsonpath: string, jsonpathParams?: any): any {
    return jp.value(value, this.utilsService.resolveJsonpath(jsonpath, jsonpathParams));
  }
}
