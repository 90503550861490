import { EventsListPageModuleAttributes } from './../../entities/pagemodules/EventsListPageModule';
import { HeadlineType } from './../../entities/Attributes';
import { EventsListPageModule } from '../../entities/pagemodules/EventsListPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class EventsListPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public createEventsListPageModuleLocal(values: any): { title: string; title_attr: EventsListPageModuleAttributes } {
    return {
      title: values?.title || '',
      title_attr: this.eventsListPageModuleAttributes(values?.title_attr)
    };
  }

  public eventsListPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): EventsListPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2'
    };
  }

  public async eventsListPageModule(values: any): Promise<EventsListPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'EventsList',
      titlePosition: values?.titlePosition || 'centered',
      local: await this.local(values, val => this.createEventsListPageModuleLocal(val)),
      displayAs: values?.displayAs || 'list',
      pagination: values?.pagination || 10,
      paginationType: values?.paginationType || 'pagination',
      eventTypes: values?.eventTypes || [],
      keytopics: values?.keytopics || [],
      eventSeries: values?.eventSeries || [],
      dates: values?.dates || ['past', 'live', 'future'],
      languages: values?.languages || [],
      countries: values?.countries || [],
      sorting: values?.sorting || [],
      defaultSortingField: values?.defaultSortingField || '',
      defaultSortingDirection: values?.defaultSortingDirection || '1',
      myBookmarkedEvents: values?.myBookmarkedEvents || false,
      myBookedEvents: values?.myBookedEvents || false,

      showTicketInformation: values?.showTicketInformation || false,
      showTicketCancellationButton: values?.showTicketCancellationButton || false,
      showEventShareButton: values?.showEventShareButton || false,
      showEventCalendarButton: values?.showEventCalendarButton || false,
      showEventBookmarkButton: values?.showEventBookmarkButton || false,
      showEventRecommendationButton: values?.showEventRecommendationButton || false,
      showWithSessionVideosOnly: values?.showWithSessionVideosOnly || false,
      showSortButton: typeof values?.showSortButton === 'boolean' ? values?.showSortButton : true,
      ctaButton: values?.ctaButton || null
    };
  }
}
