import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OperatingRegion } from 'src/common/entities/OperatingRegion';

@Component({
  selector: 'c-operating-region-item',
  templateUrl: './operating-region-item.component.html',
  styleUrls: ['./operating-region-item.component.scss'],
})
export class OperatingRegionItemComponent implements OnInit, OnChanges {
  @Input()
  operatingRegion: OperatingRegion;

  constructor() {}

  ngOnInit(): void {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.operatingRegion?.currentValue && changes.operatingRegion?.currentValue !== changes.operatingRegion?.previousValue) {
    }
  }
}
