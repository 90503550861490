import { createCTAButton } from '../entities/CTAButton';
import {
  CTATeaserItem,
  EventStageType,
  HighlightTeaserItem,
  LinkTeaserItem,
  StreamEventStage,
  StreamEventStageLocal,
  TeaserEventStage,
  TeaserEventStageLocal,
  TextEventStage,
  TextEventStageLocal,
  TitleEventStage,
  TitleEventStageLocal,
} from '../entities/EventStage';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';

export class EventStageFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async titleEventStageLocal(values?: any): Promise<TitleEventStageLocal> {
    return {
      ...values,
      title: values?.title || '',
      icon: values?.icon || '',
    };
  }

  public async titleEventStage(values: any): Promise<TitleEventStage> {
    return {
      ...values,
      _id: await this.id(values?._id),
      eventStageType: 'Title',
      background: values?.background || null,
      showLiveButton: values?.showLiveButton || false,
      local: await this.local(values, (val) => this.titleEventStageLocal(val)),
    };
  }

  public async textEventStageLocal(values?: any): Promise<TextEventStageLocal> {
    return {
      ...values,
      smallHeadline: values?.smallHeadline || '',
      text: values?.text || '',
    };
  }

  public async textEventStage(values: any): Promise<TextEventStage> {
    return {
      ...values,
      _id: await this.id(values?._id),
      eventStageType: 'Text',
      layout: values?.layout || 'Default',
      background: values?.background || null,
      local: await this.local(values, (val) => this.textEventStageLocal(val)),
      ctaButton: values?.ctaButton || null,
      showShareButton: values.showShareButton || false,
      showCalendarButton: values.showCalendarButton || false,
      showCountdown: values.showCountdown || false,
    };
  }

  public async streamEventStageLocal(values?: any): Promise<StreamEventStageLocal> {
    return {
      ...values,
      smallHeadline: values?.smallHeadline || '',
      text: values?.text || '',
    };
  }

  public async streamEventStage(values: any): Promise<StreamEventStage> {
    return {
      ...values,
      _id: await this.id(values?._id),
      eventStageType: 'Stream',
      background: values?.background || null,
      local: await this.local(values, (val) => this.streamEventStageLocal(val)),
    };
  }

  public async highlightTeaserItem(values: any): Promise<HighlightTeaserItem> {
    return {
      _id: await this.id(values?._id),
      teaserItemType: 'Highlight',
      session: values?.session || null,
    };
  }

  public async linkTeaserItem(values: any): Promise<LinkTeaserItem> {
    return {
      _id: await this.id(values?._id),
      teaserItemType: 'Link',
      image: values?.image || null,
      title: values?.title || null,
      link: values?.link || null,
    };
  }

  public async ctaTeaserItem(values: any): Promise<CTATeaserItem> {
    return {
      _id: await this.id(values?._id),
      teaserItemType: 'CTA',
      image: values?.image || null,
      title: values?.title || null,
      cta: createCTAButton(values?.cta),
    };
  }

  public async teaserItem(values: any) {
    switch (values.teaserItemType) {
      case 'Highlight':
        return this.highlightTeaserItem(values);
      case 'Link':
        return this.linkTeaserItem(values);
      case 'CTA':
        return this.ctaTeaserItem(values);
    }
    return null;
  }

  public async teaserEventStageLocal(values?: any): Promise<TeaserEventStageLocal> {
    return {
      smallHeadline: values?.smallHeadline || '',
      title: values?.title || '',
      subtitle: values?.subtitle || '',
      items: await Promise.all((values?.items || []).map((i: any) => this.teaserItem(i))),
    };
  }

  public async teaserEventStage(values: any): Promise<TeaserEventStage> {
    let local: any = {};

    for (const l of Object.keys(values?.local || {})) {
      local[l] = await this.teaserEventStageLocal(values?.local[l]);
    }

    return {
      _id: await this.id(values?._id),
      eventStageType: 'Teaser',
      ctaButton: values?.ctaButton || null,
      background: values?.background || null,
      local: local,
      showCountdown: values.showCountdown || false,
    };
  }

  public async eventStage(values: { eventStageType: EventStageType }) {
    switch (values.eventStageType) {
      case 'Title':
        return { ...values, ...(await this.titleEventStage(values)) };
      case 'Teaser':
        return { ...values, ...(await this.teaserEventStage(values)) };
      case 'Text':
        return { ...values, ...(await this.textEventStage(values)) };
      case 'Stream':
        return { ...values, ...(await this.streamEventStage(values)) };
    }
  }
}
