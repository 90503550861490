import { FilterPageModuleAttributes } from './../../entities/pagemodules/FilterPageModule';
import { HeadlineType } from './../../entities/Attributes';
import { FilterPageModule } from '../../entities/pagemodules/FilterPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class FilterPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public filterPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): FilterPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public createFilterPageModuleLocal(values: any): { title: string; title_attr: FilterPageModuleAttributes } {
    return {
      title: values?.title || '',
      title_attr: this.filterPageModuleAttributes(values?.title_attr),
    };
  }

  public async filterPageModule(values: any): Promise<FilterPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'Filter',
      local: await this.local(values, (val) => this.createFilterPageModuleLocal(val)),
      eventType: typeof values?.eventType === 'boolean' ? values?.eventType : true,
      eventTypeLabel: values?.eventTypeLabel || null,
      keyTopics: typeof values?.keyTopics === 'boolean' ? values?.keyTopics : true,
      keyTopicsLabel: values?.keyTopicsLabel || null,
      language: typeof values?.language === 'boolean' ? values?.language : false,
      languageLabel: values?.languageLabel || null,
      country: typeof values?.country === 'boolean' ? values?.country : false,
      countryLabel: values?.countryLabel || null,
    };
  }
}
