import { stage2TitleEventStagePageModuleInputs } from './Stage2TitleEventStagePageModuleInputs';
import { speakerListPageModulesInputs } from './SpeakerListPageModuleInputs';
import { stage2TeaserEventStagePageModuleInputs } from './Stage2TeaserEventStagePageModuleInputs';
import { stage2MultiEventStagePageModulesInputs } from './Stage2MultiEventStagePageModuleInputs';
import { Inputs } from '../Inputs';
import { accordionPageModulesInputs } from './AccordionPageModulesInputs';
import { agendaPageModulesInputs } from './AgendaPageModulesInputs';
import { bannerPageModulesInputs } from './BannerPageModulesInputs';
import { bookmarksPageModulesInputs } from './BookmarksPageModulesInputs';
import { eventKeytopicsIconGroupPageModulesInputs } from './EventKeytopicsIconGroupPageModulesInputs';
import { gridPageModulesInputs } from './GridPageModulesInputs';
import { iconGroupPageModulesInputs } from './IconGroupPageModulesInputs';
import { imageTagTextLinkPageModulesInputs } from './ImageTagTextLinkPageModulesInputs';
import { multiEventStagePageModulesInputs } from './MultiEventStagePageModuleInputs';
import { showroomPageModulesInputs } from './ShowroomPageModuleInputs';
import { text3DImagePageModulesInputs } from './Text3DImagePageModulesInputs';
import { textListPageModulesInputs } from './TextListPageModulesInputs';
import { textPageModulesInputs } from './TextPageModulesInputs';
import { videoSliderPageModulesInputs } from './VideoSliderPageModuleInputs';
import { VideoTeaserCollectionPageModulesInputs } from './VideoTeaserCollectionPageModuleInputs';
import { EventsListPageModulesInputs } from './EventsListPageModuleInputs';
import { FilterPageModulesInputs } from './FilterPageModuleInputs';
import { streamEventStagePageModuleInputs } from './StreamEventStagePageModuleInputs';
import { teaserEventStagePageModuleInputs } from './TeaserEventStagePageModuleInputs';
import { textEventStagePageModuleInputs } from './TextEventStagePageModuleInputs';
import { titleEventStagePageModuleInputs } from './TitleEventStagePageModuleInputs';
import { listPageModulesInputs } from './ListPageModulesInputs';
import { listImagePageModulesInputs } from './ListImagePageModulesInputs';
import { listVideoPageModulesInputs } from './ListVideoPageModulesInputs';
import { documentCollectionPageModulesInputs } from './DocumentCollectionPageModulesInputs';
import { textImagePageModulesInputs } from './TextImagePageModulesInputs';
import { imagePageModulesInputs } from './ImagePageModulesInputs';
import { userProfileStagePageModulesInputs } from './UserProfileStagePageModulesInputs';
import { stage2StreamEventStagePageModuleInputs } from './Stage2StreamEventStagePageModuleInputs';
import { stage2TextEventStagePageModuleInputs } from './Stage2TextEventStagePageModuleInputs';
import { imageBannerPageModulesInputs } from './ImageBannerPageModulesInputs';
import { formPageModulesInputs } from './FormPageModulesInputs';
import { tilesPageModulesInputs } from './TilesPageModulesInputs';
import { productListPageModulesInputs } from './ProductListPageModulesInputs';
import { conversionStagePageModuleInputs } from './ConversionStagePageModuleInputs';
import { slideshowStagePageModuleInputs } from './SlideShowStagePageModuleInputs';
import { trendingTopicsPageModulesInputs } from './TrendingTopicsPageModulesInputs';
import { spacerPageModulesInputs } from './SpacerPageModulesInputs';
import { productList2PageModuleInputs } from './ProductList2PageModuleInputs';
import { loginPageModulesInputs } from './LoginModulesInputs';
import { exploreStagePageModuleInputs } from './ExploreStagePageModuleInputs';
import { newsfeedEntryPageModuleInputs } from './NewsfeedEntryPageModulesInputs';

export const pageModulesInputs: Inputs = {
  ...accordionPageModulesInputs,
  ...agendaPageModulesInputs,
  ...bannerPageModulesInputs,
  ...bookmarksPageModulesInputs,
  ...documentCollectionPageModulesInputs,
  ...eventKeytopicsIconGroupPageModulesInputs,
  ...exploreStagePageModuleInputs,
  ...gridPageModulesInputs,
  ...iconGroupPageModulesInputs,
  ...imageTagTextLinkPageModulesInputs,
  ...imagePageModulesInputs,
  ...listPageModulesInputs,
  ...listImagePageModulesInputs,
  ...listVideoPageModulesInputs,
  ...loginPageModulesInputs,
  ...multiEventStagePageModulesInputs,
  ...showroomPageModulesInputs,
  ...text3DImagePageModulesInputs,
  ...textImagePageModulesInputs,
  ...textListPageModulesInputs,
  ...textPageModulesInputs,
  ...videoSliderPageModulesInputs,
  ...VideoTeaserCollectionPageModulesInputs,
  ...EventsListPageModulesInputs,
  ...FilterPageModulesInputs,
  ...streamEventStagePageModuleInputs,
  ...teaserEventStagePageModuleInputs,
  ...userProfileStagePageModulesInputs,
  ...textEventStagePageModuleInputs,
  ...titleEventStagePageModuleInputs,
  ...imageBannerPageModulesInputs,
  ...formPageModulesInputs,
  ...speakerListPageModulesInputs,
  ...tilesPageModulesInputs,
  ...productListPageModulesInputs,
  ...conversionStagePageModuleInputs,
  ...slideshowStagePageModuleInputs,
  ...trendingTopicsPageModulesInputs,
  ...spacerPageModulesInputs,
  ...productList2PageModuleInputs,
  ...newsfeedEntryPageModuleInputs,

  //Stage 2.0
  ...stage2MultiEventStagePageModulesInputs,
  ...stage2StreamEventStagePageModuleInputs,
  ...stage2TeaserEventStagePageModuleInputs,
  ...stage2TextEventStagePageModuleInputs,
  ...stage2TitleEventStagePageModuleInputs,
};
