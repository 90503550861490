import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { TableOptions } from 'src/app/components/table/table.interfaces';
import { EventsService } from 'src/app/services/events/events.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Event } from 'src/common/entities/Event';
import { SessionWithSessionMedia } from 'src/common/entities/Session';
import { Inputs } from 'src/common/inputs/Inputs';
import { reactionInputs } from 'src/common/inputs/reaction/ReactionInputs';
import { Factory } from '../../../../common/factories/Factory';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { AnimationOptions } from 'ngx-lottie/lib/symbols';
import { Reaction } from '../../../../common/entities/Reaction';
import { ReactionsService } from '../../../services/reactions/reactions.service';
import { ReactionFactory } from '../../../../common/factories/ReactionFactory';
import { Stream } from '../../../../common/entities/Stream';
import { StreamsService } from '../../../services/streams/streams.service';
import { AssetsService } from '../../../services/assets/assets.service';
import { Asset } from '../../../../common/entities/Asset';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LiveStreamPostProductionComponent } from '../live-stream-post-production/live-stream-post-production.component';
import { DomainService } from '../../../services/domain/domain.service';

@Component({
  selector: 'app-live-reaction',
  templateUrl: './live-reaction.component.html',
  styleUrls: ['./live-reaction.component.scss'],
})
export class LiveReactionComponent implements OnInit {
  allQuickReactions: AnimationOptions[];
  reactions: Reaction[] = [];
  showReactionDialog = false;
  reactionsInputs: Inputs = reactionInputs;
  editableReaction: Reaction = null;
  undoChanges = true;
  language = 'en';
  newReaction = false;
  htmlOutputUrl: SafeResourceUrl;

  tabIndex = 0;
  @ViewChild('postProduction') postProduction: LiveStreamPostProductionComponent;

  get isReactionChanged(): boolean {
    if (this.newReaction) {
      return true;
    }
    if (this.editableReaction) {
      const original = this.reactions.find((p) => p._id === this.editableReaction._id);
      return JSON.stringify(original) !== JSON.stringify(this.editableReaction);
    }
    return false;
  }

  eventId: string;
  streamId: string;
  event: Event;
  stream: Stream;
  session: SessionWithSessionMedia;
  assets: { [assetId: string]: Observable<Asset> } = {};

  loading = false;

  quickReactionsTableOptions: TableOptions<Reaction> = {
    columns: [
      { header: 'GENERAL_INTERNAL_NAME', visible: 'fixed' },
      { header: 'GENERAL_BACKGROUND' },
      { header: 'GENERAL_BACKGROUND_COLOR' },
      { header: 'PAGE_EVENT_SECTION_REACTIONS_MAX_PARALLEL_CONNECTIONS' },
      { header: '' },
    ],
  };

  constructor(
    private confirmationService: ConfirmationService,
    private configurationService: ConfigurationService,
    private streamsService: StreamsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private assetsService: AssetsService,
    private eventService: EventsService,
    private reactionsService: ReactionsService,
    private utilsService: UtilsService,
    private domainService: DomainService
  ) {}

  async ngOnInit(): Promise<void> {
    this.eventId = this.activatedRoute.snapshot.parent.params.eventId;
    this.streamId = this.activatedRoute.snapshot.params.streamId;

    this.loading = true;
    await this.loadEvent();
    await this.loadReactions();

    await this.loadAllAvailableQuickReaction();
    this.loading = false;
  }

  async loadAllAvailableQuickReaction(): Promise<void> {
    const c = await this.configurationService.getConfigurationByKeyAndDomain('interaction', null);
    this.allQuickReactions = (await Factory.configuration().interactionConfiguration(c)).quickReactions.filter((qr) => qr.enabled).map((qr) => ({ animationData: JSON.parse(qr.animationJSON) }));
  }

  back(): void {
    this.router.navigate(['/live', this.event._id], {});
  }

  async refreshPostProduction() {
    this.postProduction.fetchData();
  }

  async loadReactions(): Promise<void> {
    this.reactions = (
      await this.reactionsService.getReactions({
        filter: {
          // event: {
          //   matchMode: 'equalsObjectId',
          //   value: this.eventId
          // }
          stream: {
            matchMode: 'equalsObjectId',
            value: this.streamId,
          },
        },
      })
    ).items;

    for (const reaction of this.reactions.filter((r) => r.background)) {
      this.assets[reaction.background] = await this.assetsService.getAsset(reaction.background);
    }
  }

  async loadEvent(): Promise<void> {
    this.event = await this.eventService.getEvent(this.eventId);
    this.session = this.event.currentEventVersion.sessions[0] as SessionWithSessionMedia;
    this.stream = await this.streamsService.getStream(this.streamId);
  }

  async openReactionDialog(reactionTemplate: Reaction): Promise<void> {
    let reaction = null;
    if (!this.newReaction) {
      // Get actual data before editing
      reaction = await await this.reactionsService.getReaction(reactionTemplate._id);
    } else {
      reaction = JSON.parse(JSON.stringify(reactionTemplate));
    }
    // To be able to undo changes, deep object copy is neccessary here
    this.undoChanges = true;
    this.editableReaction = await new ReactionFactory().createReaction(reaction);
    this.showReactionDialog = true;
  }

  async closeReactionDialog(): Promise<void> {
    if (!this.undoChanges) {
      // Get original data to compare with
      const original = this.reactions.find((r) => r._id === this.editableReaction._id);
      // Replace original data by edited work copy if there are changes
      if (this.newReaction || JSON.stringify(original) !== JSON.stringify(this.editableReaction)) {
        if (this.newReaction) {
          await this.reactionsService.createReaction(this.editableReaction);
        } else {
          await this.reactionsService.updateReaction(this.editableReaction);
        }
        // Refresh reaction tables data
        await this.loadReactions();
      }
    }
    this.showReactionDialog = false;
    this.newReaction = false;
    this.editableReaction = null;
  }

  async addReaction(cloneReaction?: Reaction): Promise<void> {
    if (!cloneReaction) {
      cloneReaction = null;
    }
    const reaction = await new ReactionFactory({ newIds: true }).createReaction(cloneReaction);
    reaction.event = this.eventId;
    reaction.stream = this.streamId;
    this.newReaction = true;
    await this.openReactionDialog(reaction);
  }

  async confirmDeleteReaction(event, reaction: Reaction): Promise<void> {
    this.confirmationService.confirm({
      target: event.target,
      message: await this.utilsService.translate('PAGE_EVENT_SECTION_REACTIONS_DELETE_CONFIRM_MESSAGE'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteReaction(reaction);
      },
      reject: () => {
        // Nothing to do
      },
    });
  }

  async deleteReaction(reaction: Reaction): Promise<void> {
    // !!! Don't implement without reaction in use validation !!!
  }

  isValidReaction(reaction: Reaction): boolean {
    return true;
  }

  showReactionResult(reaction: Reaction): void {
    const domain = this.domainService.findFirstFrontendDomain();
    const url = `${domain}/events/${this.eventId}/reactions/${reaction._id}`;
    // const url = `${this.environment.
    // frontend}/events/streaming-test/reactions`;
    window.open(url, '_blank');
    // this.htmlOutputUrl  = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getAsset(assetId: string): Observable<Asset> {
    return this.assets[assetId];
  }

  get frameStyle() {
    return {
      width: '100%', // add border on mobile
      height: '100vh', // add border on mobile
      // backgroundColor: transparent
    };
  }

  get deviceStyle() {
    return {
      transform: `none`, // 'none'
    };
  }
}
