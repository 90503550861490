<c-collaboration-patch-input
  [jsonpath]="jsonpath"
  [jsonpathParams]="jsonpathParams"
  [collaborationKey]="'mapversion:' + object._id"
  [object]="object"
  [inputConfiguration]="inputConfiguration"
  [disabled]="disabled"
  [showHeader]="showHeader"
  [showDescription]="showDescription"
  [header]="header"
  [description]="description"
  [inputs]="mapInputs"
  (onValueChanged)="emitValueChanged($event)"
></c-collaboration-patch-input>
