import { Inputs } from '../Inputs';

export const reactionInputs: Inputs = {
  '$.internalName': {
    header: 'GENERAL_INTERNAL_NAME',
    type: 'text',
  },
  '$.background': {
    header: 'GENERAL_BACKGROUND',
    type: 'asset',
  },
  '$.backgroundColor': {
    header: 'GENERAL_BACKGROUND_COLOR',
    type: 'text',
  },
  '$.maxParallelConnections': {
    header: 'PAGE_EVENT_SECTION_REACTIONS_MAX_PARALLEL_CONNECTIONS',
    type: 'number',
  },
};
