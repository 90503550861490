import { SpeakerListPageModule, SpeakerListPageModuleElement } from './../../entities/pagemodules/SpeakerListPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class SpeakerListPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public speakerListPageModuleElementLocal(values: any) {
    return {
      title: values?.title || ''
    };
  }

  public async speakerListPageModuleElement(values: any, defaultSortingField = 'firstName', defaultSortingDirection = '1') {
    return {
      _id: await this.id(values?._id),
      sortingField: values?.sortingField || defaultSortingField,
      sortingDirection: values?.sortingDirection || defaultSortingDirection,
      local: await this.local(values, val => this.speakerListPageModuleElementLocal(val))
    } as SpeakerListPageModuleElement;
  }

  public async speakerListPageModule(values: any): Promise<SpeakerListPageModule> {
    const defaultSortingField = values?.defaultSortingField || 'firstName';
    const defaultSortingDirection = values?.defaultSortingDirection || '1';

    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'SpeakerList',
      speakers: values?.speakers || [],
      linkable: values?.linkable,
      displayAs: values?.displayAs || 'slider',
      personDetailPage: values?.personDetailPage || 'userPage',
      defaultSortingField,
      defaultSortingDirection,
      elements: await Promise.all((values?.elements || []).map((e: any) => this.speakerListPageModuleElement(e, defaultSortingDirection, defaultSortingField)))
    };
  }
}
