import { InputConfiguration } from './../../../../common/inputs/Inputs';
import { ProductListPageModule } from './../../../../common/entities/pagemodules/ProductListPageModule';
import { Page } from './../../../../common/entities/Page';
import { EventVersion } from './../../../../common/entities/EventVersion';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-list-page-module-edit',
  templateUrl: './product-list-page-module-edit.component.html',
  styleUrls: ['./product-list-page-module-edit.component.scss'],
})
export class ProductListPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: ProductListPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  tab = 'general';

  inputJsonpathes: string[] = ['$.local.$language.title', '$.titlePosition', '$.showHeadline', '$.ctaButton', '$.anchor'];

  accessInputConfiguration: InputConfiguration;

  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
  }
}
