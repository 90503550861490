<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_EVENT_SECTION_AUTO_ASSIGNMENTS' | translate }}
    <div class="actions">
      <c-table-options #toc></c-table-options>
      <p-button
        *hasRight="'eventregistrations.edit'"
        styleClass="p-button-text"
        icon="pi icon-ic-add-item"
        [disabled]="isWorking"
        (onClick)="createNewAutoAssignment()"
        [label]="'PAGE_EVENT_SECTION_AUTO_ASSIGNMENTS_ADD' | translate"
      ></p-button>
    </div>
  </div>
  <c-table [$refresh]="refresh" #table mode="query" [singleTemplate]="true" [options]="autoAssignmentsTableOptions" (query)="retrieveAutoAssignments($event)">
    <ng-template let-autoAssignment>
      <tr>
        <td></td>
        <td>User</td>
        <td>
          <c-query [query]="autoAssignment.query"></c-query>
        </td>
        <td>{{ autoAssignment.eventTicket }}</td>
        <td>
          {{ autoAssignment.sendMail }}
        </td>
        <td class="actions">
          <div class="buttons">
            <p-button *hasRight="'eventregistrations.edit'" label="Edit" (onClick)="editAutoAssignment(autoAssignment)"></p-button>
          </div>
        </td>
      </tr>
    </ng-template>
  </c-table>
</div>
<p-sidebar *ngIf="showSidebar" [visible]="showSidebar" styleClass="p-sidebar-st" position="right" [modal]="true" [appendTo]="'body'" (onHide)="closeAutoAssignmentRegistrationDialog()">
  <c-sidebar-header [headerTitle]="'PAGE_EVENT_SECTION_AUTO_ASSIGNMENTS_EDIT' | translate" (onClose)="closeAutoAssignmentRegistrationDialog()"></c-sidebar-header>

  <div class="sidebar-body">
    <c-collaboration-patch-input [object]="editedAutoAssignment" [jsonpath]="'$.query'" [inputs]="inputs"></c-collaboration-patch-input>
    <c-app-event-ticket-selector [(ngModel)]="editedAutoAssignment" [event]="event" ngDefaultControl> </c-app-event-ticket-selector>
    <c-collaboration-patch-input [object]="editedAutoAssignment" [jsonpath]="'$.sendMail'" [inputs]="inputs"></c-collaboration-patch-input>
  </div>

  <c-sidebar-footer [showDeleteButton]="true" [showSaveButton]="true" [disableSaveButton]="false" (onSave)="saveAutoAssignment()" (onDelete)="deleteAutoAssignment()"></c-sidebar-footer>
</p-sidebar>
