import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { BookmarksPageModuleFactory } from './../../factories/pagemodules/BookmarksPageModuleFactory';
import { speakerDisplayTypes } from '../../entities/pagemodules/AgendaPageModule';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const bookmarksPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('Bookmarks'),
  "$.pageModules[?(@.pageModuleType=='Bookmarks' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new BookmarksPageModuleFactory().createBookmarksPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='Bookmarks' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='Bookmarks' && @._id=='$pageModuleId')].bookmarkType": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Session', value: 'sessionbookmark' },
      { label: 'Product', value: 'productbookmark' },
    ],
    description: 'Select the type which shall be displayed'
  },
  "$.pageModules[?(@.pageModuleType=='Bookmarks' && @._id=='$pageModuleId')].showTime": {},
  "$.pageModules[?(@.pageModuleType=='Bookmarks' && @._id=='$pageModuleId')].speakerDisplayType": {
    type: 'dropdown',
    dropdownOptions: [...speakerDisplayTypes].map((a) => ({ label: a, value: a })),
  },

};
