import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { CacheContainer } from '../cache/cache-container';
import { CacheService } from '../cache/cache.service';
import { OperatingCountry } from 'src/common/entities/OperatingCountry';
import { GetOperatingCountryQuery, GetOperatingCountryResponse } from 'src/common/api/v1/operating-countries/GetOperatingCountry';
import { GetOperatingCountriesQuery, GetOperatingCountriesResponse } from 'src/common/api/v1/operating-countries/GetOperatingCountries';
import { PostOperatingCountryBody, PostOperatingCountryQuery, PostOperatingCountryResponse } from 'src/common/api/v1/operating-countries/PostOperatingCountry';

@Injectable({
  providedIn: 'root',
})
export class OperatingCountriesService {
  private _cache: CacheContainer<OperatingCountry>;

  constructor(private apiService: ApiService, private cacheService: CacheService) {
    this._cache = this.cacheService.create<OperatingCountry>({
      get: async (operatingCountryId) =>
        await this.apiService.get<GetOperatingCountryQuery, GetOperatingCountryResponse>(`/api/v1/configuration/operating-countries/${operatingCountryId}`).toPromise(),
      socketEvents: ['operating-country:update'],
    });
  }

  async getOperatingCountries(query?: GetOperatingCountriesQuery): Promise<GetOperatingCountriesResponse> {
    const operatingCountries = await this.apiService
      .get<GetOperatingCountriesQuery, GetOperatingCountriesResponse>('/api/v1/configuration/operating-countries', {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();

    this._cache.fill(operatingCountries.items);

    return operatingCountries;
  }

  async getOperatingCountry(operatingCountryId: string): Promise<OperatingCountry> {
    return this._cache.asPromise(operatingCountryId);
  }

  async createOperatingCountry(operatingCountry: OperatingCountry): Promise<OperatingCountry> {
    return this._cache.post<PostOperatingCountryQuery, PostOperatingCountryBody, PostOperatingCountryResponse>('/api/v1/configuration/operating-countries', operatingCountry);
  }

  async updateOperatingCountry(operatingCountry: OperatingCountry): Promise<OperatingCountry> {
    return this._cache.post<PostOperatingCountryQuery, PostOperatingCountryBody, PostOperatingCountryResponse>(`/api/v1/configuration/operating-countries/${operatingCountry._id}`, operatingCountry);
  }

  deleteOperatingCountry(operatingCountryId: string): Promise<OperatingCountry> {
    return this._cache.post<PostOperatingCountryQuery, PostOperatingCountryBody, PostOperatingCountryResponse>(`/api/v1/configuration/operating-countries/${operatingCountryId}/delete`, {});
  }
}
