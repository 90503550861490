import { TextAttributes } from './../Attributes';
import { CTAButton } from '../CTAButton';
import { PageModule, TitlePositions } from './PageModule';

export interface TextListPageModule extends PageModule {
  pageModuleType: 'TextList';
  titlePosition?: TitlePositions | 'content';
  listPosition: 'left' | 'right';
  local: {
    [language: string]: {
      title: string;
      title_attr?: TextListPageModuleAttributes;
      text?: string;
      items: string[];
      itemIcon?: string;
    };
  };
  ctaButton?: CTAButton;
}

export type TextListPageModuleAttributes = TextAttributes & {};

export function isTextListPageModule(object: any): object is TextListPageModule {
  return object?.pageModuleType === 'TextList';
}
