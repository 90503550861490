import { ImagePageModuleAttributes } from './../../entities/pagemodules/ImagePageModule';
import { HeadlineType } from './../../entities/Attributes';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';
import { ImagePageModule, ImagePageModuleLocal } from '../../entities/PageModule';

export class ImagePageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public imagePageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): ImagePageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async imagePageModuleLocal(values?: any): Promise<ImagePageModuleLocal> {
    return {
      title: values?.title || '',
      title_attr: this.imagePageModuleAttributes(values?.title_attr),
      image: values?.image || '',
    };
  }

  public async imagePageModule(values: any): Promise<ImagePageModule> {
    if (values?.image) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.image = item.image || values.image;
      });
    }

    const pageModule = {
      ...(await this.baseValues(values)),
      titlePosition: values?.titlePosition || 'left',
      local: await this.local(values, (val) => this.imagePageModuleLocal(val)),
      image: undefined,
      ctaButton: values?.ctaButton || null,
    };

    return { ...pageModule, pageModuleType: 'Image' };
  }
}
