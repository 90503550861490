import { InputConfiguration } from 'src/common/inputs/Inputs';
import { Page } from './../../../../common/entities/Page';
import { Stage2TextEventStage } from './../../../../common/entities/eventstages/Stage2TextEventStage';
import { Component, Input, OnInit } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';

@Component({
  selector: 'c-stage2-texteventstage-page-module-edit',
  templateUrl: './stage2-texteventstage-page-module-edit.component.html',
  styleUrls: ['./stage2-texteventstage-page-module-edit.component.scss'],
})
export class Stage2TextEventStagePageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: Stage2TextEventStage;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathes: string[] = [
    '$.local.$language.smallHeadline',
    '$.local.$language.text',
    '$.local.$language.background',
    '$.showCountdown',
    '$.layout',
    '$.local.$language.subtext',
    '$.discoverMore',
    '$.anchor',
  ];

  topAreaJsonpathes: string[] = [
    '$.topAreaHeight.mobile',
    '$.topAreaHeight.mobileLarge',
    '$.topAreaHeight.tablet',
    '$.topAreaHeight.tabletLarge',
    '$.topAreaHeight.desktop',
    '$.topAreaHeight.desktopLarge',
    '$.topAreaHeight.desktopWidescreen',
  ];

  contentAreaJsonpathes: string[] = [
    '$.contentAreaHeight.mobile',
    '$.contentAreaHeight.mobileLarge',
    '$.contentAreaHeight.tablet',
    '$.contentAreaHeight.tabletLarge',
    '$.contentAreaHeight.desktop',
    '$.contentAreaHeight.desktopLarge',
    '$.contentAreaHeight.desktopWidescreen',
  ];

  ctaAreaJsonpathes: string[] = [
    '$.ctaAreaHeight.mobile',
    '$.ctaAreaHeight.mobileLarge',
    '$.ctaAreaHeight.tablet',
    '$.ctaAreaHeight.tabletLarge',
    '$.ctaAreaHeight.desktop',
    '$.ctaAreaHeight.desktopLarge',
    '$.ctaAreaHeight.desktopWidescreen',
  ];

  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
  }
}
