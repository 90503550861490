import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Asset, isImageAsset } from 'src/common/entities/Asset';
import { Environment } from 'src/environments/environment';

@Pipe({
  name: 'asset',
})
export class AssetPipe implements PipeTransform {
  constructor(private environment: Environment, private sanitizer: DomSanitizer) {}

  transform(asset: Asset, ...args: string[]): string | SafeUrl {
    if (isImageAsset(asset)) {
      if (asset.paths) {
        switch (args[0]) {
          case 'dataUri':
            return this.sanitizer.bypassSecurityTrustUrl(asset.paths.dataUri);
          case 'original':
            return this.environment.cdn['images'] + asset.paths.original;
          case 'thumbnail':
            return this.environment.cdn['images'] + asset.paths.thumbnail;
          case 'placeholder':
            return this.sanitizer.bypassSecurityTrustUrl(asset.placeholder);
        }
      }

      return null;
    }

    return asset[args[0]];
  }
}
