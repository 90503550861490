<div class="sidebar-tabs">
  <button [class.active]="tab === 'general'" (click)="tab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
  <button [class.active]="tab === 'filter'" (click)="tab = 'filter'">{{ 'GENERAL_FILTER' | translate }}</button>
  <button [class.active]="tab === 'design'" (click)="tab = 'design'">{{ 'GENERAL_DESIGN' | translate }}</button>
  <button [class.active]="tab === 'accesspolicy'" (click)="tab = 'accesspolicy'">{{ 'GENERAL_CONDITIONS' | translate }}</button>
</div>

<div *ngIf="initialized && tab == 'general'">
  <div class="sidebar-body">
    <c-page-patch-input *ngFor="let inputJsonpath of inputJsonpathes" [jsonpath]="[jsonpath, inputJsonpath]" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  </div>
  <p-accordion>
    <p-accordionTab [header]="'GENERAL_BUTTONS' | translate" [selected]="true">
      <div class="sidebar-body">
        <c-page-patch-input
          *ngFor="let inputJsonpath of inputJsonpathesButtons"
          [jsonpath]="[jsonpath, inputJsonpath]"
          [jsonpathParams]="jsonpathParams"
          [object]="eventVersion || page"
        ></c-page-patch-input>
      </div>
    </p-accordionTab>
  </p-accordion>
</div>

<div *ngIf="initialized && tab == 'filter'" class="sidebar-body">
  <c-page-patch-input
    *ngFor="let inputJsonpath of inputJsonpathesFilter"
    [jsonpath]="[jsonpath, inputJsonpath]"
    [jsonpathParams]="jsonpathParams"
    [object]="eventVersion || page"
    [inputConfiguration]="
      inputJsonpath === '$.keytopics'
        ? inputConfigurationKeytopics
        : inputJsonpath === '$.languages'
        ? inputConfigurationLanguages
        : inputJsonpath === '$.countries'
        ? inputConfigurationCountries
        : inputJsonpath === '$.eventTypes'
        ? inputConfigurationEventTypes
        : inputJsonpath === '$.eventSeries'
        ? inputConfigurationEventSeries
        : {}
    "
  ></c-page-patch-input>
</div>

<div *ngIf="initialized && tab == 'design'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.backgroundTheme']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paginationType']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.pagination']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingTop']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingBottom']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingLeft']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingRight']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>
<div *ngIf="tab == 'accesspolicy'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.accessPolicy']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page" [inputConfiguration]="accessInputConfiguration"></c-page-patch-input>
</div>
