<div *ngIf="parent && inputConfiguration.type">
  <div *ngIf="inputConfiguration.type === 'text'">
    <input
      pInputText
      type="text"
      class="p-inputtext-sm text-control"
      [placeholder]="inputConfiguration.placeholder || placeholder || ''"
      [ngModel]="parent[key]"
      [disabled]="inputConfiguration.disabled || disabled"
      (ngModelChange)="setValue($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
    />
  </div>

  <div *ngIf="inputConfiguration.type === 'color'">
    <c-color-picker [value]="parent[key]" (valueChange)="setValue($event); sendValue()" (onFocus)="onFocus()" (onBlur)="onBlur()"></c-color-picker>
  </div>
  <div *ngIf="inputConfiguration.type === 'number'">
    <p-inputNumber
      class="p-inputtext-sm text-control"
      [ngModel]="parent[key]"
      [disabled]="inputConfiguration.disabled || disabled"
      [maxFractionDigits]="inputConfiguration.maxFractionDigits"
      mode="decimal"
      [minFractionDigits]="inputConfiguration.minFractionDigits"
      (ngModelChange)="setValue($event)"
      (onFocus)="onFocus()"
      (onBlur)="onBlur()"
    ></p-inputNumber>
  </div>

  <div *ngIf="inputConfiguration.type === 'textarea'">
    <textarea
      pInputTextarea
      class="p-inputtext-sm text-control"
      [placeholder]="inputConfiguration.placeholder || placeholder || ''"
      [ngModel]="parent[key]"
      [disabled]="inputConfiguration.disabled || disabled"
      [rows]="countRows(parent[key], 3)"
      [cols]="30"
      (ngModelChange)="setValue($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
    ></textarea>
  </div>

  <div *ngIf="inputConfiguration.type === 'dropdown'">
    <p-dropdown
      [options]="inputConfiguration?.dropdownOptions"
      [filter]="inputConfiguration?.dropdownOptions?.length > 5 ? true : false"
      [disabled]="inputConfiguration.disabled || disabled"
      appendTo="body"
      [ngModel]="parent[key]"
      (ngModelChange)="setValue($event); sendValue()"
      (onFocus)="onFocus()"
      (onBlur)="onBlur()"
      [placeholder]="placeholder"
    ></p-dropdown>
  </div>

  <div class="multiselect" *ngIf="inputConfiguration.type === 'multiselect'">
    <p-multiSelect
      [options]="inputConfiguration.multiselectOptions"
      [placeholder]="inputConfiguration.placeholder || placeholder || ''"
      [disabled]="inputConfiguration.disabled || disabled"
      appendTo="body"
      [showToggleAll]="inputConfiguration.showToggleAll"
      [ngModel]="parent[key]"
      (ngModelChange)="setValue($event); sendValue()"
      (onFocus)="onFocus()"
      (onBlur)="onBlur()"
    ></p-multiSelect>
  </div>

  <div *ngIf="inputConfiguration.type === 'switch'">
    <p-checkbox
      [ngModel]="parent[key]"
      (ngModelChange)="setValue($event); sendValue()"
      [disabled]="inputConfiguration.disabled || disabled"
      [binary]="true"
      [label]="inputConfiguration.header || '' | translate"
    >
    </p-checkbox>
  </div>

  <div *ngIf="['datetime', 'date', 'time'].indexOf(inputConfiguration.type) >= 0">
    <p-calendar
      *ngIf="inputConfiguration.type === 'time' && !calendarShowDates"
      #timeOverlay
      [ngModel]="onlyViewValue"
      (ngModelChange)="setValue($event)"
      (onFocus)="onFocus()"
      (onBlur)="onBlur()"
      (onClose)="sendValue()"
      appendTo="body"
      [disabled]="inputConfiguration.disabled || disabled"
      [showOnFocus]="false"
      [showIcon]="true"
      icon="pi pi-clock"
      [timeOnly]="true"
      [showTime]="true"
      [showSeconds]="inputConfiguration.datetimeOptions?.showSeconds || false"
      hourFormat="24"
    >
      <ng-template pTemplate="footer">
        <div class="p-datepicker-buttonbar">
          <button pButton [label]="'GENERAL_NOW' | translate" (click)="setDateTime(); timeOverlay.toggle()" class="p-button-text"></button>
          <button *ngIf="inputConfiguration.datetimeOptions?.dateSelection" pButton icon="pi pi-calendar" (click)="timeOverlay.toggle(); calendarShowDates = true" class="p-button-text"></button>
          <button pButton [label]="'GENERAL_CLEAR' | translate" (click)="setDateTime(true); timeOverlay.toggle()" class="p-button-text"></button>
        </div>
      </ng-template>
    </p-calendar>

    <p-calendar
      *ngIf="inputConfiguration.type.includes('date') || (calendarShowDates && inputConfiguration.type === 'time')"
      styleClass="text-control"
      [ngModel]="onlyViewValue"
      (ngModelChange)="setValue($event)"
      (onFocus)="onFocus()"
      (onBlur)="onBlur()"
      (onClose)="sendValue()"
      appendTo="body"
      [disabled]="inputConfiguration.disabled || disabled"
      [showOnFocus]="false"
      [showIcon]="true"
      [showButtonBar]="true"
      [showTime]="inputConfiguration.type.includes('time')"
      [showSeconds]="
        inputConfiguration.type === 'date' ? false : inputConfiguration.type === 'datetime' || inputConfiguration.type === 'time' ? inputConfiguration.datetimeOptions?.showSeconds || false : false
      "
      hourFormat="24"
    ></p-calendar>
  </div>

  <div *ngIf="inputConfiguration.type === 'imageasset'">
    <c-asset-select
      [id]="parent[key]"
      [disabled]="inputConfiguration.disabled || disabled"
      [showDeleteButton]="showDeleteButton"
      (idChange)="setValue($event); sendValue()"
      [mimeType]="inputConfiguration.mimeTypes || 'image/*'"
      [position]="inputConfiguration.position"
    ></c-asset-select>
  </div>

  <div *ngIf="inputConfiguration.type === 'documentasset'">
    <c-asset-select
      [id]="parent[key]"
      [disabled]="inputConfiguration.disabled || disabled"
      [showDeleteButton]="showDeleteButton"
      (idChange)="setValue($event); sendValue()"
      [mimeType]="inputConfiguration.mimeTypes || documentMimeType"
    ></c-asset-select>
  </div>

  <div *ngIf="inputConfiguration.type === 'assetseries'">
    <c-asset-serie-select [id]="parent[key]" [disabled]="inputConfiguration.disabled || disabled" (idChange)="setValue($event); sendValue()"></c-asset-serie-select>
  </div>

  <div *ngIf="inputConfiguration.type === 'videoasset'">
    <c-asset-select
      [id]="parent[key]"
      [disabled]="inputConfiguration.disabled || disabled"
      [showDeleteButton]="showDeleteButton"
      (idChange)="setValue($event); sendValue()"
      mimeType="video/*"
    ></c-asset-select>
  </div>

  <div *ngIf="inputConfiguration.type === 'asset'">
    <c-asset-select [id]="parent[key]" [disabled]="inputConfiguration.disabled || disabled" [showDeleteButton]="showDeleteButton" (idChange)="setValue($event); sendValue()"></c-asset-select>
  </div>

  <div *ngIf="inputConfiguration.type === 'product'">
    <c-product-select [id]="parent[key]" [disabled]="inputConfiguration.disabled || disabled" (idChange)="setValue($event); sendValue()"></c-product-select>
  </div>

  <div *ngIf="inputConfiguration.type === 'onboarding'">
    <c-onboarding-select [id]="parent[key]" [disabled]="inputConfiguration.disabled || disabled" (idChange)="setValue($event); sendValue()"></c-onboarding-select>
  </div>

  <div *ngIf="inputConfiguration.type === 'operatingregion'">
    <c-operating-region-select [id]="parent[key]" [disabled]="inputConfiguration.disabled || disabled" (idChange)="setValue($event); sendValue()"></c-operating-region-select>
  </div>

  <div *ngIf="inputConfiguration.type === 'tags'">
    <c-tags-select
      [disabled]="inputConfiguration.disabled || disabled"
      [multiselect]="inputConfiguration.multiselect"
      [value]="parent[key]"
      (valueChange)="setValue($event); sendValue()"
    ></c-tags-select>
  </div>

  <div *ngIf="inputConfiguration.type === 'event'">
    <c-event-select
      [disabled]="inputConfiguration.disabled || disabled"
      [hideRemoveLink]="inputConfiguration.hideRemoveLink"
      [value]="parent[key]"
      (valueChange)="setValue($event); sendValue()"
    ></c-event-select>
  </div>

  <div *ngIf="inputConfiguration.type === 'speaker'">
    <c-speaker-select
      [disabled]="inputConfiguration.disabled || disabled"
      [hideRemoveLink]="inputConfiguration.hideRemoveLink"
      [value]="parent[key]"
      (valueChange)="setValue($event); sendValue()"
    ></c-speaker-select>
  </div>

  <div *ngIf="inputConfiguration.type === 'form'">
    <c-form-select [disabled]="inputConfiguration.disabled || disabled" [value]="parent[key]" (valueChange)="setValue($event); sendValue()" (focus)="onFocus()" (blur)="onBlur()"></c-form-select>
  </div>

  <div *ngIf="inputConfiguration.type === 'customdata'">
    <c-customdata-select [id]="parent[key]" [disabled]="inputConfiguration.disabled || disabled" (idChange)="setValue($event); sendValue()"></c-customdata-select>
  </div>

  <div *ngIf="inputConfiguration.type === 'user'">
    <c-user-picker [user]="parent[key]" [disabled]="inputConfiguration.disabled || disabled" (userChange)="setValue($event); sendValue()" (usersChange)="setValue($event); sendValue()"></c-user-picker>
  </div>

  <div *ngIf="inputConfiguration.type === 'accesspolicy'">
    <c-access-policy-edit
      [accessPolicies]="parent[key]"
      [disabled]="inputConfiguration.disabled || disabled"
      [eventVersion]="inputConfiguration.eventVersion"
      [inverted]="inputConfiguration.inverted"
      [allowedAccessPolicyTypes]="inputConfiguration.allowedAccessPolicyTypes"
      (accessPoliciesChange)="setValue($event); sendValue()"
      (onBlur)="onBlur()"
      (onFocus)="onFocus()"
    ></c-access-policy-edit>
  </div>

  <div *ngIf="inputConfiguration.type === 'ctabutton'">
    <c-cta-button-edit
      [ctaButton]="parent[key]"
      [disabled]="inputConfiguration.disabled || disabled"
      (ctaButtonChange)="setValue($event); sendValue()"
      (onBlur)="onBlur()"
      (onFocus)="onFocus()"
      [inputOptions]="inputConfiguration.options"
    ></c-cta-button-edit>
    <!-- <p-checkbox
      [ngModel]="ctaButtonActivated"
      (ngModelChange)="onChangeCTAButton($event); sendValue()"
      [binary]="true"
      [disabled]="inputConfiguration.disabled || disabled"
      [label]="inputConfiguration.header || '' | translate">
    </p-checkbox> -->
  </div>

  <div *ngIf="inputConfiguration.type === 'page'">
    <c-page-select
      [disabled]="inputConfiguration.disabled || disabled"
      [hideRemoveLink]="inputConfiguration.hideRemoveLink"
      [domainCollectionId]="inputConfiguration.domainCollectionId"
      [value]="parent[key]"
      (valueChange)="setValue($event); sendValue()"
    ></c-page-select>
  </div>

  <div *ngIf="inputConfiguration.type === 'embeddedpage'">
    <c-page-select
      [disabled]="inputConfiguration.disabled || disabled"
      [domainCollectionId]="inputConfiguration.domainCollectionId"
      [value]="parent[key]"
      (valueChange)="setValue($event); sendValue()"
      [pageType]="'EmbeddedPage'"
    ></c-page-select>
  </div>

  <div *ngIf="inputConfiguration.type === 'autocomplete'">
    <p-autoComplete
      [ngModel]="parent[key]"
      (ngModelChange)="setValue($event); sendValue()"
      (onBlur)="onBlur()"
      (onFocus)="onFocus()"
      (completeMethod)="autocompleteQuery($event)"
      [suggestions]="autocompleteResults"
      [multiple]="inputConfiguration.autocompleteOptions.multiple"
      [dropdown]="inputConfiguration.autocompleteOptions.dropdown"
      [disabled]="inputConfiguration.disabled || disabled"
    >
      <ng-template let-value pTemplate="selectedItem">
        {{ autocompleteDisplay(value) | async }}
        <i *ngIf="inputConfiguration.autocompleteOptions.sort !== false" (click)="autocompleteLeft(value)" class="pi pi-chevron-left autocomplete-chevron"></i>
        <i *ngIf="inputConfiguration.autocompleteOptions.sort !== false" (click)="autocompleteRight(value)" class="pi pi-chevron-right autocomplete-chevron"></i>
      </ng-template>
      <ng-template let-value pTemplate="item">
        {{ autocompleteDisplay(value) | async }}
      </ng-template>
    </p-autoComplete>
  </div>

  <div *ngIf="inputConfiguration.type === 'html'" class="html-control">
    <div *ngIf="!activatedHTMLEditor">
      <div (click)="onHTMLPreviewClick()" class="html-preview" [innerHTML]="getPreviewHTML() | safeHtml"></div>
    </div>
    <div *ngIf="activatedHTMLEditor">
      <div *ngIf="!showHtmlEditorCloseButton()" class="raw-html-controls">
        <div class="raw-html-switch">
          <p-inputSwitch [(ngModel)]="showRawHTMLInput"></p-inputSwitch>
          <span>HTML Mode</span>
        </div>
        <button pButton (click)="this.activatedHTMLEditor = false" label="Close"></button>
      </div>

      <c-rich-text-input
        *ngIf="!showRawHTMLInput"
        [value]="parent[key]"
        (valueChanged)="setValue($event)"
        (focus)="onFocus()"
        (blur)="onBlur()"
        (onCloseButton)="this.activatedHTMLEditor = false"
        [showCloseButton]="showHtmlEditorCloseButton()"
        [disabled]="inputConfiguration.disabled || disabled"
      >
      </c-rich-text-input>

      <div *ngIf="showRawHTMLInput">
        <textarea
          class="raw-html-input"
          rows="8"
          [ngModel]="parent[key]"
          (ngModelChange)="setValue($event)"
          (focus)="onFocus()"
          (blur)="onBlur()"
          [disabled]="inputConfiguration.disabled || disabled"
        ></textarea>
        <div>
          <div class="html-preview-tag">PREVIEW</div>
          <div class="html-preview" [innerHTML]="getPreviewHTML() | safeHtml"></div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="inputConfiguration.type === 'query'">
    <c-query-builder
      [disabled]="inputConfiguration.disabled || disabled"
      [useTags]="inputConfiguration.useTags"
      [query]="parent[key]"
      (queryChange)="setValue($event); sendValue()"
      [fieldTypes]="inputConfiguration.fieldTypes"
    ></c-query-builder>
  </div>
</div>
