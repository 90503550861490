import { Component, Input, OnInit } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { VideoSliderPageModule } from 'src/common/entities/PageModule';

import { InputConfiguration } from 'src/common/inputs/Inputs';

import { KeytopicsService } from '../../../services/keytopics/keytopics.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'c-videoslider-page-module-edit',
  templateUrl: './videoslider-page-module-edit.component.html',
  styleUrls: ['./videoslider-page-module-edit.component.scss'],
})
export class VideosliderPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: VideoSliderPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathesGeneral: string[] = ['$.local.$language.title', '$.ctaButton', '$.anchor'];

  inputJsonpathesVideos: string[] = ['$.videoSliderType', '$.maxCount', '$.keytopic', '$.event', '$.local.$language.videos'];

  inputConfigurationKeytopic: InputConfiguration = {};

  initialized: boolean = false;
  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  constructor(private keytopicsService: KeytopicsService) {}

  async ngOnInit(): Promise<void> {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
    const [keytopics] = await Promise.all([this.keytopicsService.getKeytopics()]);

    this.inputConfigurationKeytopic = {
      type: 'dropdown',
      dropdownOptions: [{ label: 'None', value: null }].concat(keytopics.items.map((e) => ({ label: e.internalName, value: e._id }))),
    };

    this.initialized = true;
  }
}
