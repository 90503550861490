import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './components/tabs/tabs.component';
import { RouterModule } from '@angular/router';
import { SkeletonModule } from 'primeng/skeleton';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../directives/directives.module';
import { InstanceAliasComponent } from '../components/instance-alias-component/instance-alias-component.component';

@NgModule({
  declarations: [TabsComponent, InstanceAliasComponent],
  imports: [CommonModule, RouterModule, DirectivesModule, TranslateModule, SkeletonModule],
  exports: [TabsComponent],
})
export class TabsModule {}
