<div class="sidebar-search">
  <div class="search-input input-group compact">
    <div class="input">
      <input #searchInput placeholder="Search product ..." pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
    </div>
  </div>
  <div infinite-scroll [scrollWindow]="false" (scrolled)="executeSearch()" class="search-results">
    <div class="search-result" *ngFor="let product of searchResult" (click)="selectProduct(product)">
      <c-product-item [product]="product" [disableDetails]="true"></c-product-item>
    </div>
  </div>
</div>
