import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmojiButton } from '@joeattardi/emoji-button';

@Component({
  selector: 'c-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss'],
})
export class EmojiPickerComponent implements OnInit {
  @Input()
  textareaId: string = null;

  @Output()
  text: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  emoji: EventEmitter<string> = new EventEmitter<string>();

  picker = new EmojiButton();

  constructor() {}

  ngOnInit(): void {
    this.picker.on('emoji', (selection) => {
      this.emoji.emit(selection.emoji);

      if (this.textareaId) {
        this.insertAtCaret(selection.emoji);
      }
    });
  }

  openEmojis() {
    var button = document.getElementById('emoji');
    this.picker.togglePicker(button);
  }

  insertAtCaret(text) {
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach((textarea) => {
      if (textarea.id === this.textareaId) {
        textarea.setRangeText(text, textarea.selectionStart, textarea.selectionEnd, 'end');
        this.text.emit(textarea.value); // Otherwise changes not detected on textarea
      }
    });
  }
}
