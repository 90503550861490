<c-collaboration-patch-input
  [jsonpath]="jsonpath"
  [jsonpathParams]="jsonpathParams"
  [collaborationKey]="'eventversion:' + eventVersion._id"
  [object]="eventVersion"
  [inputConfiguration]="inputConfiguration"
  [disabled]="disabled"
  [showHeader]="showHeader"
  [showDescription]="showDescription"
  [header]="header"
  [description]="description"
  [inputs]="inputs || eventVersionInputs"
  [placeholder]="placeholder"
  [placeholderDescription]="placeholderDescription"
  [showPlaceholderDescription]="showPlaceholderDescription"
  (onValueChanged)="emitValueChanged($event)"
></c-collaboration-patch-input>
