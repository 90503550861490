import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { roleValidator } from 'src/common/api/v1/configuration/roles/validators/RoleValidator';

import { Role } from 'src/common/entities/Role';
import { Right } from 'src/common/entities/Right';
import { Group } from 'src/common/entities/Group';
import { AdminUser } from 'src/common/entities/AdminUser';
import { AdminUsersService } from 'src/app/services/admin-users/admin-users.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { Tab } from 'src/app/tabs/classes/tab';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit, OnDestroy {
  roleId: string = 'undefined';
  role: Role | null = null;
  tab: Tab;

  allRights: Right[] = [];
  usedRights: Right[] = [];
  filteredRights: Right[] = [];

  roleGroups: Group[] = [];
  roleUsers: AdminUser[] = [];
  rightUsers: AdminUser[] = [];
  options = [{ value: 'custom', name: 'Custom' }];

  loading: boolean = true;
  saving: boolean = false;

  private _ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private validatorService: ValidatorService,
    public utilsService: UtilsService,
    private configurationService: ConfigurationService,
    private adminUsersService: AdminUsersService,
    private tabsService: TabsService
  ) {
    this.tab = this.tabsService.register({
      category: 'configuration',
      parent: '/configuration/roles',
      loading: true,
      route: this.activatedRoute.snapshot
    });
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.params.pipe(takeUntil(this._ngUnsubscribe)).subscribe(async params => {
      this.roleId = params.roleId;

      this.allRights = (await this.configurationService.getRights()).items;
      this.allRights.sort((a, b) => (a.name > b.name ? 1 : a.name === b.name ? 0 : -1));
      this.filteredRights = this.allRights;

      if (this.roleId === 'new') {
        this.role = {
          _id: '',
          name: '',
          type: 'custom',
          rights: []
        };
        this.options = [
          { value: 'custom', name: 'Custom' },
          { value: 'system', name: 'System' }
        ];
        this.loading = false;
      } else if (this.roleId) {
        await this.retrieveData();
      }
    });
  }

  ngOnDestroy() {
    // Unsubscribe subscriptions
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  async retrieveData() {
    this.loading = true;
    try {
      this.role = await this.configurationService.getRole(this.roleId);

      // Set up usedRights to get p-autoComplete component going
      this.usedRights = this.role.rights.map(rightInUse => this.allRights.find(right => right.identifier == rightInUse));

      // Set up filteredRights for use as p-autoComplete components suggestions list
      this.filterRights();

      // Get used groups
      this.roleGroups = (await this.configurationService.getGroups()).items;
      // !!! Filters not working at API endpoint => so filter here
      this.roleGroups = this.roleGroups.filter(group => group.roles.find(roleId => roleId === this.roleId));

      // Get users in groups
      this.roleUsers = [];
      this.roleGroups.forEach(async group => {
        const users = (
          await this.adminUsersService.getAdminUsers({
            source: 'Local',
            limit: 0,
            /* !!! This filter doesnt work
          filter: {
            groups: {
              matchMode: 'inObjectId',
              value: this.roleGroups.map(group => group._id)
            }
          } */
            filter: {
              groups: {
                matchMode: 'equalsObjectId',
                value: group._id
              }
            }
          })
        ).items;

        this.roleUsers = this.roleUsers.concat(users);
      });
    } catch (err) {}
    this.loading = false;
  }

  async onSave() {
    this.saving = true;
    try {
      if (this.role) {
        // Transfer usedRights to role.rights for saving
        this.role.rights = this.usedRights.map(rightInUse => rightInUse.identifier);

        if (this.roleId === 'new') {
          this.role = await this.configurationService.createRole(this.role);
          await this.router.navigate(['/configuration/roles', this.role._id], {
            replaceUrl: true
          });
          this.tabsService.close(this.tab);
        } else {
          this.role = await this.configurationService.updateRole(this.role);
          this.retrieveData();
        }
      }
    } catch (err) {}
    this.saving = false;
  }

  filterRights(event?) {
    // Only unused rights are listed in suggestions
    this.filteredRights = [];
    this.allRights.forEach(right => {
      if (!this.usedRights.find(rightInUse => rightInUse._id == right._id)) {
        this.filteredRights.push(right);
      }
    });

    // Apply filter expression to suggestions list
    if (event && event.query) {
      this.filteredRights = this.filteredRights.filter(right => right.identifier.toLowerCase().includes(event.query.toLowerCase()));
    }
  }

  isValid(): boolean {
    return this.validatorService.isValid(roleValidator, this.role);
  }

  isEditable(): boolean {
    if (this.activatedRoute.snapshot.params.roleId === 'new') {
      return false;
    } else if (this.role.type === 'system') {
      return true;
    }
    return false;
  }

  errorAt(path: string): string | null {
    return this.validatorService.errorAt(roleValidator, this.role, path);
  }
}
