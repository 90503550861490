<div #toolbar></div>

<div #htmlEditor class="c-rich-text-input"></div>

<div #toolbarTemplate class="toolbar-template">
  <div class="toolbar">
    <div class="formats">
      <span class="ql-formats">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-strike"></button>
      </span>
      <span class="ql-formats">
        <select class="ql-color"></select>
        <span class="ql-format-separator"></span>
        <select class="ql-background"></select>
      </span>
      <span class="ql-formats">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <select class="ql-align">
          <option selected></option>
          <option value="center" label="Center"></option>
          <option value="right" label="Right"></option>
          <option value="justify" label="Justify"></option>
        </select>
      </span>
      <span class="ql-formats">
        <button class="ql-link"></button>
      </span>
    </div>

    <div *ngIf="showCloseButton">
      <button class="close-button" (click)="onClose()">Close</button>
    </div>
  </div>
</div>
