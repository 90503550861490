import { EventSlotConfigurationFactory } from './../../factories/EventSlotConfigurationFactory';
import { Factory } from '../../factories/Factory';
import { Inputs } from '../Inputs';

export const eventVersionSlotsInputs: Inputs = {
  "$.eventSlots[?(@._id=='$eventSlot')].startAt": {
    header: 'PAGE_EVENT_SECTION_SLOTS_STARTAT',
    description: 'PAGE_EVENT_SECTION_SLOTS_STARTAT_DESCRIPTION',
    type: 'datetime',
  },
  "$.eventSlots[?(@._id=='$eventSlot')].endAt": {
    header: 'PAGE_EVENT_SECTION_SLOTS_ENDAT',
    description: 'PAGE_EVENT_SECTION_SLOTS_ENDAT_DESCRIPTION',
    type: 'datetime',
  },
  "$.eventSlots[?(@._id=='$eventSlot')].capacity": {
    header: 'PAGE_EVENT_SECTION_SLOTS_CAPACITY',
    description: 'PAGE_EVENT_SECTION_SLOTS_CAPACITY_DESCRIPTION',
    type: 'number',
  },
  "$.eventSlots[?(@._id=='$eventSlot')].warningCapacity": {
    header: 'PAGE_EVENT_SECTION_SLOTS_WARNINGCAPACITY',
    description: 'PAGE_EVENT_SECTION_SLOTS_WARNINGCAPACITY_DESCRIPTION',
    type: 'number',
  },
  "$.eventSlots[?(@._id=='$eventSlot')].eventTickets": {
    header: 'PAGE_EVENT_SECTION_SLOTS_TICKETS',
    description: 'PAGE_EVENT_SECTION_SLOTS_TICKETS_DESCRIPTION',
    type: 'multiselect',
  },

  "$.eventSlots[?(@._id=='$eventSlot')].eventTimeSlots[?(@._id=='$timeSlot')].startAt": {
    header: 'PAGE_EVENT_SECTION_SLOTS_TIME_SLOT_STARTAT',
    description: 'PAGE_EVENT_SECTION_SLOTS_TIME_SLOT_STARTAT_DESCRIPTION',
    type: 'datetime',
  },
  "$.eventSlots[?(@._id=='$eventSlot')].eventTimeSlots[?(@._id=='$timeSlot')].endAt": {
    header: 'PAGE_EVENT_SECTION_SLOTS_TIME_SLOT_ENDAT',
    description: 'PAGE_EVENT_SECTION_SLOTS_TIME_SLOT_ENDAT_DESCRIPTION',
    type: 'datetime',
  },

  "$.eventSlots[?(@._id=='$eventSlot')].eventTimeSlots[?(@._id=='$timeSlot')].local.$language.title": {
    header: 'PAGE_EVENT_SECTION_SLOTS_TIME_SLOT_TITLE',
    description: 'PAGE_EVENT_SECTION_SLOTS_TIME_SLOT_TITLE_DESCRIPTION',
  },
  "$.eventSlots[?(@._id=='$eventSlot')].eventTimeSlots[?(@._id=='$timeSlot')].local.$language": {
    factory: async () => Factory.slot().createTimeSlotLocal({}),
  },
  "$.eventSlots[?(@._id=='$eventSlot')].deletedAt": {
    factory: async () => '',
  },

  '$.eventSlotConfiguration.customTicketSlotHeader': {
    type: 'switch',
    header: 'Custom Ticket Slot Header',
  },

  '$.eventSlotConfiguration.local': {
    factory: async () => ({}),
  },

  '$.eventSlotConfiguration.local.$language': {
    factory: async () => new EventSlotConfigurationFactory().createEventSlotConfigurationLocal({}),
  },

  '$.eventSlotConfiguration.local.$language.customTitle': {
    type: 'text',
    header: 'Custom Title',
  },

  '$.eventSlotConfiguration.local.$language.customDescription': {
    type: 'html',
    header: 'Custom Description',
  },
};
