import { Injectable } from '@angular/core';
import { GetTagsQuery, GetTagsResponse } from 'src/common/api/v1/configuration/tags/GetTags';
import { PostTagsBody, PostTagsQuery, PostTagsResponse } from 'src/common/api/v1/configuration/tags/PostTags';

import { PostTagBody, PostTagQuery, PostTagResponse } from 'src/common/api/v1/configuration/tags/PostTag';
import { PostTagDeleteBody, PostTagDeleteQuery, PostTagDeleteResponse } from 'src/common/api/v1/configuration/tags/PostTagDelete';
import { PostTagPositionBody, PostTagPositionQuery, PostTagPositionResponse } from 'src/common/api/v1/configuration/tags/PostTagPosition';
import { Tag, TaxonomyNode } from 'src/common/entities/Tag';
import { ApiService } from '../api/api.service';
import { GetTagsTagAssignmentsQuery, GetTagsTagAssignmentsResponse } from 'src/common/api/v1/configuration/tags/GetTagsTagAssignments';
import { PostTagsTagAssignmentBody, PostTagsTagAssignmentQuery, PostTagsTagAssignmentResponse } from 'src/common/api/v1/configuration/tags/PostTagsTagAssignment';
import { TagAssignment } from 'src/common/entities/TagAssignment';
import { PostTagsTagAssignmentsBody, PostTagsTagAssignmentsQuery, PostTagsTagAssignmentsResponse } from 'src/common/api/v1/configuration/tags/PostTagsTagAssignments';
import { PostTagsTagAssignmentDeleteBody, PostTagsTagAssignmentDeleteQuery, PostTagsTagAssignmentDeleteResponse } from 'src/common/api/v1/configuration/tags/PostTagsTagAssignmentDelete';
import { CacheService } from '../cache/cache.service';
import { CacheContainer } from '../cache/cache-container';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  private _tagsCache: CacheContainer<GetTagsResponse>;

  constructor(private apiService: ApiService, private cacheService: CacheService) {
    this._tagsCache = this.cacheService.create<GetTagsResponse>({
      get: async (tag) => {
        const tempTag = await this.apiService.get<GetTagsQuery, GetTagsResponse>(`/api/v1/configuration/tags/${tag}`).toPromise();
        return tempTag;
      },
    });
  }

  getTags(search?: string): Promise<GetTagsResponse> {
    return this.apiService.get<GetTagsQuery, GetTagsResponse>(`/api/v1/configuration/tags`, { search }).toPromise();
  }

  getTagChildren(tagId: string): Promise<GetTagsResponse> {
    return this.apiService.get<GetTagsQuery, GetTagsResponse>(`/api/v1/configuration/tags/${tagId}/children`).toPromise();
  }

  getTag(tagId: string): Promise<GetTagsResponse> {
    return this._tagsCache.asPromise(tagId);
  }

  createTag(node: TaxonomyNode<Tag>, parentId: string) {
    return this.apiService.post<PostTagsQuery, PostTagsBody, PostTagsResponse>(`/api/v1/configuration/tags`, node).toPromise();
  }

  saveTag(node: TaxonomyNode<Tag>) {
    return this.apiService.post<PostTagQuery, PostTagBody, PostTagResponse>(`/api/v1/configuration/tags/${node.data._id}`, node).toPromise();
  }

  positionTag(index: number, dragNode: TaxonomyNode<Tag>, dropNode: TaxonomyNode<Tag>) {
    return this.apiService
      .post<PostTagPositionQuery, PostTagPositionBody, PostTagPositionResponse>(`/api/v1/configuration/tags/position/${index}/${dragNode.data._id}/${dropNode.data._id}`, {})
      .toPromise();
  }

  deleteTag(tagId: string) {
    return this.apiService.post<PostTagDeleteQuery, PostTagDeleteBody, PostTagDeleteResponse>(`/api/v1/configuration/tags/${tagId}/delete`, {}).toPromise();
  }

  moveTag(tagId: string) {
    return this.apiService.post<PostTagDeleteQuery, PostTagDeleteBody, PostTagDeleteResponse>(`/api/v1/configuration/tags/${tagId}/move`, {}).toPromise();
  }

  getTagAssignments(query: GetTagsTagAssignmentsQuery): Promise<GetTagsTagAssignmentsResponse> {
    return this.apiService.get<GetTagsTagAssignmentsQuery, GetTagsTagAssignmentsResponse>(`/api/v1/configuration/tags/tagassignments`, query).toPromise();
  }

  createTagAssignment(tagAssignment: TagAssignment): Promise<PostTagsTagAssignmentsResponse> {
    return this.apiService.post<PostTagsTagAssignmentsQuery, PostTagsTagAssignmentsBody, PostTagsTagAssignmentsResponse>(`/api/v1/configuration/tags/tagassignments`, tagAssignment).toPromise();
  }

  updateTagAssignment(id: string, tagAssignment: TagAssignment): Promise<PostTagsTagAssignmentResponse> {
    return this.apiService.post<PostTagsTagAssignmentQuery, PostTagsTagAssignmentBody, PostTagsTagAssignmentResponse>(`/api/v1/configuration/tags/tagassignments/${id}`, tagAssignment).toPromise();
  }

  deleteTagAssignment(id: string): Promise<PostTagsTagAssignmentDeleteResponse> {
    return this.apiService
      .post<PostTagsTagAssignmentDeleteQuery, PostTagsTagAssignmentDeleteBody, PostTagsTagAssignmentDeleteResponse>(`/api/v1/configuration/tags/tagassignments/${id}/delete`, {})
      .toPromise();
  }
}
