import { Component, Input } from '@angular/core';
import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';

@Component({
  selector: 'c-event-status',
  templateUrl: './event-status.component.html',
  styleUrls: ['./event-status.component.scss'],
})
export class EventStatusComponent {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  get isNewEvent(): boolean {
    return typeof this.event._id !== 'string';
  }

  constructor() {}
}
