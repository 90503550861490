import { AppFunctionFactory } from '../factories/AppFunctionFactory';
import { AccessPolicy } from './AccessPolicy';
import { AppFunction } from './AppFunction';

export const ctaButtonTypes = [
  'meetyourexpert',
  'registerplatform',
  'registerevent',
  'externallink',
  'reminder',
  'bookmark',
  'calendar',
  'share',
  'videoasset',
  'externalevent',
  'querylink',
  'appfunction',
  'exploreevent',
  'page'
] as const;
export type CTAButtonType = (typeof ctaButtonTypes)[number];

export const shareCtaTargets = ['email', 'facebook', 'linkedin', 'twitter', 'xing'];
export type ShareCTATarget = (typeof shareCtaTargets)[number];

export interface CTAButton {
  ctaButtonType: CTAButtonType;
  readAccess: AccessPolicy[];
  executeAccess: AccessPolicy[];
  disabled?: boolean;
  shareCtaTargets?: ShareCTATarget[];
  appFunction?: AppFunction;
  local: {
    [language: string]: {
      label: string;
      link?: string;
      video?: string;
    };
  };
  event?: string;
  page?: string;
}

export function createCTAButtonLocal(values: any) {
  return {
    label: values?.label || null,
    link: values?.link || null,
    video: values?.video || '',
  };
}

export function createCTAButton(values: any): CTAButton {
  return {
    ctaButtonType: values?.ctaButtonType || 'meetyourexpert',
    readAccess: values?.readAccess,
    executeAccess: values?.executeAccess,
    disabled: values?.disabled || false,
    appFunction: values?.ctaButtonType === 'appfunction' ? new AppFunctionFactory().createAppFunction(values?.appFunction) : null,
    local: Object.keys(values?.local || {}).reduce((a, b) => ({ ...a, [b]: createCTAButtonLocal(values.local[b]) }), {}),
    shareCtaTargets: values?.shareCtaTargets,
    page: values?.page
  } as CTAButton;
}
