import { Component, Input } from '@angular/core';

@Component({
  selector: 'c-instance-alias',
  templateUrl: './instance-alias-component.component.html',
  styleUrls: ['./instance-alias-component.component.scss'],
})
export class InstanceAliasComponent {
  constructor() {}

  @Input() alias?: string;
  @Input() color?: string;
}
