<div>
  <div class="tab-header">
    <h1>{{ 'GENERAL_GLOBAL_PAGES' | translate }}</h1>
  </div>
  <div class="tab-menu">
    <c-domain-button
      *hasRight="'configuration.edit'"
      [configKey]="'pages'"
      [domainCollectionId]="($domainCollectionId | async) ?? ''"
      (onDomainCollectionIdChange)="loadDialog($event)"
    ></c-domain-button>
    <c-domain-button
      *hasNoRight="'configuration.edit'"
      [enableUnification]="false"
      [configKey]="'pages'"
      [domainCollectionId]="($domainCollectionId | async) ?? ''"
      (onDomainCollectionIdChange)="loadDialog($event)"
    ></c-domain-button>
  </div>

  <div class="panel" *skeleton="{ show: tab.loading, type: 'table' }">
    <div class="panel-header">
      {{ 'GENERAL_GLOBAL_PAGES' | translate }}
      <div class="actions">
        <c-table-options (filterListChange)="filterChange($event)" #toc></c-table-options>
        <p-button *hasRight="['configuration.edit']" [label]="'GENERAL_MENU' | translate" icon="pi icon-ic-edit" (onClick)="editMenuConfiguration()"></p-button>
        <p-button *hasRight="'pages.create'" [label]="'GENERAL_GLOBAL_PAGES_CREATE' | translate" icon="pi icon-ic-add-item" (onClick)="openNewGlobalPageOrTemplateModal()"></p-button>
      </div>
    </div>
    <div class="search">
      <input [value]="$searchText | async" placeholder="Search global page ..." pInputText (input)="handleInput($event)" />
    </div>
    <ng-container *ngIf="$pages | async as pages">
      <c-table mode="static" [singleTemplate]="true" [options]="tableOptions" [tableOptionsComponent]="toc" (clearFilterList)="filterChange($event)" [data]="pages">
        <ng-template let-page let-level="level" #row>
          <tr [ngClass]="getChildContentClass(level) + ' active-link'" [routerLink]="[page._id]" [queryParams]="{ domainCollectionId: ($domainCollectionId | async) ?? '' }">
            <td [style.padding-left]="getRowPaddingLeft(page, level)">
              <i
                *ngIf="page.childPages.length > 0"
                [class]="expanded[page._id] ? 'pi pi-chevron-down small-chevron' : 'pi pi-chevron-right small-chevron'"
                (click)="linkClick($event); expanded[page._id] = !expanded[page._id]"
              ></i>
              <span
                *hasRight="{
                  resource: 'pages:' + page._id,
                  rights: ['pages.read', 'pages.edit']
                }"
              >
                {{ page.internalName || 'GENERAL_UNNAMED' | translate }}
              </span>
              <span
                *hasNoRight="{
                  resource: 'pages:' + page._id,
                  rights: ['pages.read', 'pages.edit']
                }"
                >{{ page.internalName || 'GENERAL_UNNAMED' | translate }}</span
              >
            </td>

            <td>
              {{ page.path }}
            </td>

            <td>
              {{ page.pageType }}
            </td>
            <td>
              <i *ngIf="page.default" class="pi pi-check success"></i>
            </td>

            <td *hasRight="['pages.edit']">
              <p-button styleClass="p-button-sm" (onClick)="linkClick($event); editStructure(page)" label="Edit structure"></p-button>
            </td>
          </tr>

          <ng-container *ngIf="expanded[page._id]">
            <ng-container *ngFor="let childStructure of page.childPages">
              <ng-container *ngTemplateOutlet="row; context: { $implicit: childStructure, level: (level || 0) + 1 }"> </ng-container>
            </ng-container>
          </ng-container>
        </ng-template>
      </c-table>
    </ng-container>
  </div>
</div>

<p-dialog header="Menu Items" [modal]="true" [(visible)]="showMenuConfiguration" [closable]="true">
  <div *ngIf="menuConfiguration" class="menu-configuration">
    <div class="header">
      <div class="logo">Logo</div>
      <div class="header-right">
        <p-dropdown [options]="languageOptions" [(ngModel)]="language"></p-dropdown>
        <c-user-context-menu-items [menuItems]="menuConfiguration.userContextMenuItems" (selectedMenuItemEvent)="selectUserContextMenuItems($event)" [language]="language"></c-user-context-menu-items>
      </div>
    </div>
    <c-user-context-menu-items-edit
      *ngIf="selectedUserContextMenuItem"
      [selectedMenuItem]="selectedUserContextMenuItem"
      [domainCollectionId]="($domainCollectionId | async) ?? ''"
      [language]="language"
    ></c-user-context-menu-items-edit>

    <c-menu-items-edit [menuItems]="menuConfiguration.headerMenuItems" [language]="language" [domainCollectionId]="($domainCollectionId | async) ?? ''" [menuStyle]="'header'"></c-menu-items-edit>
    <div class="page">Content</div>
    <c-menu-items-edit [menuItems]="menuConfiguration.footerMenuItems" [language]="language" [domainCollectionId]="($domainCollectionId | async) ?? ''" [menuStyle]="'footer'"></c-menu-items-edit>
  </div>

  <ng-template pTemplate="footer">
    <div class="text-right">
      <button class="p-button p-button-outlined" (click)="cancelMenuConfiguration()">Cancel</button>
      <button class="p-button" (click)="saveMenuConfiguration()">Save</button>
    </div>
  </ng-template>
</p-dialog>

<p-sidebar *ngIf="showEditStructureModal" [(visible)]="showEditStructureModal" styleClass="p-sidebar-st" position="right" [modal]="true" [showCloseIcon]="false">
  <c-sidebar-header [headerTitle]="'PAGE_GLOBAL_PAGES_EDIT_STRUCTURE'" (onClose)="pageEdit = null"></c-sidebar-header>

  <div class="sidebar-body">
    <div class="input-group">
      <c-collaboration-patch-input [object]="pageEdit" [jsonpath]="'$.parentPage'" [inputs]="inputs"></c-collaboration-patch-input>
    </div>
    <div class="input-group compact switch">
      <div class="message" *ngIf="pageEdit | fn : hasPageReadAccessConditions">
        <p-message severity="warn" text="The selected page has conditions. It cannot be changed to default page."></p-message>
      </div>
      <div class="input">
        <p-checkbox [disabled]="working || hasPageReadAccessConditions(pageEdit)" [(ngModel)]="pageEdit.default" label="Default" [binary]="true"></p-checkbox>
      </div>
      <div class="title">DEFAULT</div>
    </div>
  </div>

  <c-sidebar-footer [showSaveButton]="true" [disableSaveButton]="working" (onSave)="saveStructure()"></c-sidebar-footer>
</p-sidebar>

<p-sidebar *ngIf="showNewPageModal" class="new-page-modal" [(visible)]="showNewPageModal" styleClass="p-sidebar-st" position="right" [modal]="true" [showCloseIcon]="false">
  <c-sidebar-header [headerTitle]="'GENERAL_GLOBAL_PAGE_NEW'" (onClose)="newPage = null"></c-sidebar-header>

  <c-sidebar-body>
    <div *ngIf="showNewPageModal">
      <div class="input-group compact">
        <div class="input">
          <p-dropdown name="pageType" [options]="pageTypesOptions" [(ngModel)]="pageType" [disabled]="working" (onChange)="onPageTypeChange($event)"></p-dropdown>
        </div>
        <div class="title">{{ 'GENERAL_PAGETYPE' | translate }}</div>
        <div class="description">{{ 'PAGE_GLOBAL_NEW_PAGE_TYPE_DESCRIPTION' | translate }}</div>
      </div>

      <div *ngIf="newPage" class="input-group compact">
        <div class="input">
          <input type="text" pInputText name="internalName" [disabled]="working" [(ngModel)]="newPage.internalName" />
        </div>
        <div class="title">{{ 'GENERAL_INTERNAL_NAME' | translate }}</div>
        <div class="description">{{ 'PAGE_GLOBAL_NEW_PAGE_INTERNAL_NAME_DESCRIPTION' | translate }}</div>
      </div>

      <div *ngIf="newPage" class="input-group compact">
        <div class="input">
          <input type="text" pInputText name="path" [disabled]="working" [(ngModel)]="newPage.path" />
        </div>
        <div class="title">{{ 'GENERAL_PATH' | translate }}</div>
        <div class="description">{{ 'PAGE_GLOBAL_NEW_PAGE_PATH_DESCRIPTION' | translate }}</div>
        <div class="description">{{ 'GENERAL_DESCRIPTION_SEO' | translate }}</div>
      </div>

      <div *ngIf="newPage" class="input-group compact">
        <c-collaboration-patch-input [object]="newPage" [jsonpath]="'$.parentPage'" [inputs]="inputs"></c-collaboration-patch-input>
      </div>

      <div *ngIf="newPage && newPage.pageType === 'LinkedPage'" class="input-group compact">
        <c-collaboration-patch-input [object]="newPage" [jsonpath]="'$.linkedPage'" [inputs]="inputs"></c-collaboration-patch-input>
      </div>

      <div *ngIf="newPage.pageType !== 'LinkedPage'" class="input-group compact">
        <div class="description">{{ 'PAGE_GLOBAL_NEW_PAGE_CREATION_BASIS_INFO' | translate }}</div>
      </div>

      <div *ngIf="newPage.pageType !== 'LinkedPage'" class="p-field-radiobutton">
        <p-radioButton name="pageBaseType" value="none" [(ngModel)]="newPageBaseType" inputId="none"></p-radioButton>
        <label
          ><div class="title radio-label">{{ 'PAGE_GLOBAL_NEW_PAGE_NEW_FROM_SCRATCH_OPTION' | translate }}</div></label
        >
      </div>

      <div *ngIf="newPage.pageType !== 'LinkedPage'" class="p-field-radiobutton">
        <p-radioButton name="pageBaseType" value="existingPage" [(ngModel)]="newPageBaseType" inputId="existingPage"></p-radioButton>
        <label
          ><div class="title radio-label">{{ 'PAGE_GLOBAL_NEW_PAGE_FROM_EXISTING_PAGE_OPTION' | translate }}</div></label
        >
      </div>

      <div class="input-group compact" *ngIf="newPageBaseType === 'existingPage'">
        <div class="input">
          <c-page-select [(value)]="newPageFromTemplateId" [domainCollectionId]="($domainCollectionId | async) ?? ''"></c-page-select>
        </div>
        <div class="title">{{ 'GENERAL_TEMPLATE' | translate }}</div>
      </div>

      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button class="p-button" [disabled]="working" (click)="createPage()">Create</button>
      </div>
    </div>
  </c-sidebar-body>

  <c-sidebar-footer> </c-sidebar-footer>
</p-sidebar>
