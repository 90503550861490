import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { SidebarService } from 'src/app/services/sidebar/sidebar';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { SidebarBodyComponent } from '../sidebar-body/sidebar-body.component';
import { SidebarElement } from '../sidebar-element/sidebar-element.component';
import * as uuid from 'uuid';

@Component({
  selector: 'c-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild('container')
  container: ElementRef<HTMLDivElement>;

  @ViewChild('sidebarBodyContainer')
  sidebarBodyContainer: ElementRef<HTMLDivElement>;

  @ViewChild('sidebarBody')
  sidebarBody: SidebarBodyComponent;

  _visible = false;
  _sidebarElement: SidebarElement | null = null;
  _mode: 'self' | 'foreign' = 'foreign';
  _inProgress = false;
  _hideContainer = true;
  _selfSidebarVisible = false;

  @Input()
  set visible(val: boolean) {
    if (val) {
      this.show();
    } else {
      this.hide();
    }
  }

  @Output()
  visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onShow: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onHide: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  header: string;

  @Input()
  subheader: string;

  @Input()
  position = 'right';

  @Input()
  tabs: { key: string; label: string }[] | null = null;

  @Input()
  activeTab: string | null = null;

  @Input()
  noScroll = false;

  @Output()
  activeTabChange: EventEmitter<any> = new EventEmitter<any>();

  get visible(): boolean {
    return this._visible;
  }

  @HostBinding('style.display')
  get displayStyle(): 'none' | 'block' {
    return this.visible ? 'block' : 'none';
  }

  constructor(private sidebarService: SidebarService, private elementRef: ElementRef, private utilsService: UtilsService) {}

  ngOnInit(): void {
    if (this.tabs && Object.keys(this.tabs).length > 0 && !this.activeTab) {
      this.setActiveTab(Object.keys(this.tabs)[0]);
    }
  }

  ngOnDestroy() {
    if (this._mode === 'self') {
      if (this._visible) {
        this._visible = false;
        this.visibleChange.emit(this._visible);
        this.onHide.emit();
      }
    }
  }

  trackByIndex(index: number) {
    return index;
  }

  close() {
    if (this._selfSidebarVisible) {
    } else if (this._sidebarElement) {
      this.sidebarService.remove(this._sidebarElement);
    }
  }

  show() {
    if (!this._visible) {
      this._visible = true;
      this.visibleChange.emit(this._visible);
      this.onShow.emit();
      this.execute('show');
    }
  }

  hide() {
    if (this._visible) {
      this._visible = false;
      this.visibleChange.emit(this._visible);
      this.onHide.emit();
      this.execute('hide');
    }
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
    this.activeTabChange.emit(tab);
  }

  async execute(type: 'show' | 'hide') {
    if (this._inProgress) {
      return;
    }

    this._inProgress = true;

    try {
      if (type === 'show') {
        this._hideContainer = true;
        this._mode = this.sidebarService.hasSidebarBody() ? 'foreign' : 'self';

        await this.utilsService.wait(1);

        if (this._mode === 'foreign') {
          const sidebarElement: SidebarElement = {
            id: uuid.v4(),
            elementRef: this.container,
            header: this.header,
            destroyedCalled: false,
            destroyed: () => {
              if (!sidebarElement.destroyedCalled) {
                sidebarElement.destroyedCalled = true;
                this.hide();
              }
            },
          };
          this._sidebarElement = sidebarElement;
          this.sidebarService.add(this._sidebarElement);
        } else {
          this.sidebarBodyContainer?.nativeElement?.appendChild(this.container.nativeElement);
          this._selfSidebarVisible = true;
        }

        await this.utilsService.wait(10);

        this._hideContainer = false;
      } else {
        if (this._mode === 'foreign') {
          if (this._sidebarElement) {
            this._sidebarElement.destroyedCalled = true;
            this.sidebarService.remove(this._sidebarElement);

            await this.utilsService.wait(300);

            this.elementRef?.nativeElement?.appendChild(this.container.nativeElement);
            this._sidebarElement = null;
          }
        } else {
          this._selfSidebarVisible = false;

          await this.utilsService.wait(300);

          this._hideContainer = true;
          this.elementRef?.nativeElement?.appendChild(this.container.nativeElement);

          await this.utilsService.wait(1);

          this._mode = 'foreign';
        }
      }
    } catch (err) {}

    this._inProgress = false;

    if (type === 'hide' && this._visible) {
      this.execute('show');
    }
    if (type === 'show' && !this._visible) {
      this.execute('hide');
    }
  }

  selfSidebarHidden() {
    this.hide();
  }
}
