import { TextAttributes } from './../Attributes';
import { PageModule } from './PageModule';

export const filterOptions = ['single', 'multi'] as const;
export type FilterOption = (typeof filterOptions)[number];
export interface FilterPageModule extends PageModule {
  pageModuleType: 'Filter';
  local: {
    [language: string]: {
      title: string;
      title_attr?: FilterPageModuleAttributes;
    };
  };
  eventType: boolean;
  eventTypeLabel?: string;
  keyTopics: boolean;
  keyTopicsLabel?: string;
  language: boolean;
  languageLabel?: string;
  country: boolean;
  countryLabel?: string;
  filterOption?: FilterOption;
}

export type FilterPageModuleAttributes = TextAttributes & {};

export function isFilterPageModule(object: any): object is FilterPageModule {
  return object?.pageModuleType === 'Filter';
}
