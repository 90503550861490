import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { ChatMessageTemplatesService } from 'src/app/services/chatmessagetemplates/chatmessagetemplate.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ChatMessageTemplate } from 'src/common/entities/ChatMessageTemplate';

@Component({
  selector: 'c-chat-messagetemplate-management',
  templateUrl: './chat-messagetemplate-management.component.html',
  styleUrls: ['./chat-messagetemplate-management.component.scss'],
})
export class ChatMessageTemplateManagementComponent implements OnChanges {
  constructor(private chatMessageTemplateService: ChatMessageTemplatesService, private utilsService: UtilsService) {}

  @Input() messageToAdd: string = '';
  // @Input() languageCode: string = "de";

  @Output() onMessageTemplateCreated = new EventEmitter<ChatMessageTemplate>();
  @Output() onMessageTemplateUpdated = new EventEmitter<ChatMessageTemplate>();

  chatMessageTemplates: ChatMessageTemplate[] = [];
  chatMessageTemplatesLoading: boolean = true;
  chatMessageTemplateTotalRecords: number = 0;

  title: string = '';
  content: string = '';
  active: boolean = true;

  lastLoadEvent: LazyLoadEvent;

  editing: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (this.messageToAdd) {
      this.content = this.messageToAdd;
    }
  }

  async loadChatMessageTemplates(loadEvent: LazyLoadEvent) {
    this.chatMessageTemplatesLoading = true;
    this.lastLoadEvent = loadEvent;

    const result = await this.chatMessageTemplateService.getChatMessageTemplates(this.utilsService.loadEventToQuery(loadEvent));
    this.chatMessageTemplates = result.items;
    this.chatMessageTemplateTotalRecords = result.totalCount;
    this.chatMessageTemplatesLoading = false;
  }

  async createChatMessageTemplate() {
    const newChatMessageTemplate = await this.chatMessageTemplateService.createChatMessageTemplate(<ChatMessageTemplate>{
      title: this.title,
      content: this.content,
      active: this.active,
      language: 'de',
    });

    this.onMessageTemplateCreated.emit(newChatMessageTemplate);

    await this.loadChatMessageTemplates(this.lastLoadEvent);

    this.title = '';
    this.content = '';
    this.active = true;
  }

  onRowEditInit(chatMessageTemplate: ChatMessageTemplate) {
    this.editing = true;
  }

  async onRowEditSave(chatMessageTemplate: ChatMessageTemplate) {
    const updatedChatMessageTemplate = await this.chatMessageTemplateService.updateChatMessageTemplate(chatMessageTemplate);

    this.onMessageTemplateUpdated.emit(updatedChatMessageTemplate);
  }

  onRowEditCancel(chatMessageTemplate: ChatMessageTemplate, index: number) {
    this.loadChatMessageTemplates(this.lastLoadEvent);
  }
}
