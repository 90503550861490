import { shareCtaTargets } from '../../entities/CTAButton';
import { EventStage, isStreamEventStage, isTeaserEventStage, isTextEventStage, isTitleEventStage } from '../../entities/EventStage';
import { EventVersion } from '../../entities/EventVersion';
import { isPage, isPageWithEventStage, Page } from '../../entities/Page';
import { EventStageFactory } from '../../factories/EventStageFactory';
import { Inputs } from '../Inputs';

const extractEventStage = (obj: Page | EventVersion, jsonpathParams: { [key: string]: any }): EventStage | null => {
  let page: Page | null | undefined = null;

  if (isPage(obj)) {
    page = obj;
  } else {
    const eventVersion = obj as EventVersion;
    page = eventVersion.phase[jsonpathParams.eventPhase].pages.find((p) => p._id === jsonpathParams.pageId);
  }

  if (isPage(page)) {
    if (isPageWithEventStage(page)) {
      return page.eventStage;
    }
  }

  return null;
};

export const eventStageInputs: Inputs = {
  '$.eventStage.background': {},
  '$.eventStage.layout': {
    condition: (obj, jsonpath, jsonpathParams, parent) => isTextEventStage(parent),
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Default', value: 'Default' },
      { label: 'Background Focused', value: 'BackgroundFocused' },
    ],
  },
  '$.eventStage.showShareButton': {
    condition: (obj, jsonpath, jsonpathParams, parent) => isTextEventStage(parent),
  },
  '$.eventStage.shareButtonTargets': {
    condition: (obj, jsonpath, jsonpathParams, parent) => isTextEventStage(parent),
    type: 'multiselect',
    header: 'Share targets',
    description: 'If none is selected, the module will display all possible targets.',
    placeholder: 'All targets',
    showToggleAll: true,
    multiselectOptions: [...shareCtaTargets].map((a) => ({ label: a, value: a })),
  },
  '$.eventStage.showCalendarButton': {
    condition: (obj, jsonpath, jsonpathParams, parent) => isTextEventStage(parent),
  },
  '$.eventStage.showLiveButton': {
    condition: (obj, jsonpath, jsonpathParams, parent) => isTitleEventStage(parent),
    header: 'GENERAL_EVENTSTAGE_SHOWLIVEBUTTON',
  },

  '$.eventStage.local': {
    // condition: (obj, jsonpath, jsonpathParams, parent) => isTextEventStage(parent),
    factory: async () => ({}),
  },
  '$.eventStage.local.$language': {
    // condition: (obj, jsonpath, jsonpathParams, parent) => isTextEventStage(parent),
    factory: async (obj, jsonpathParams) => {
      switch (extractEventStage(obj, jsonpathParams)?.eventStageType) {
        case 'Text':
          return new EventStageFactory().textEventStageLocal();
        case 'Title':
          return new EventStageFactory().titleEventStageLocal();
        case 'Stream':
          return new EventStageFactory().streamEventStageLocal();
        case 'Teaser':
          return await new EventStageFactory().teaserEventStageLocal();
        default:
          return null;
      }
    },
  },
  '$.eventStage.local.$language.text': {
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      const eventStage = extractEventStage(obj, jsonpathParams);
      return isTextEventStage(eventStage);
    },
  },
  '$.eventStage.local.$language.smallHeadline': {
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      const eventStage = extractEventStage(obj, jsonpathParams);
      return isStreamEventStage(eventStage) || isTeaserEventStage(eventStage) || isTextEventStage(eventStage);
    },
  },
  '$.eventStage.local.$language.title': {
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      const eventStage = extractEventStage(obj, jsonpathParams);
      return isTitleEventStage(eventStage) || isStreamEventStage(eventStage) || isTeaserEventStage(eventStage);
    },
    header: 'GENERAL_EVENTSTAGE_TITLE',
    description: 'GENERAL_EVENTSTAGE_TITLE_DESCRIPTION',
  },
  '$.eventStage.local.$language.subtitle': {
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      const eventStage = extractEventStage(obj, jsonpathParams);
      return isTeaserEventStage(eventStage);
    },
  },
  '$.eventStage.local.$language.items': {
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      const eventStage = extractEventStage(obj, jsonpathParams);
      return isTeaserEventStage(eventStage);
    },
    factory: async () => [],
  },
  '$.eventStage.local.$language.icon': {
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      const eventStage = extractEventStage(obj, jsonpathParams);
      return isTitleEventStage(eventStage);
    },
  },
  '$.eventStage.ctaButton': {
    condition: (obj, jsonpath, jsonpathParams, parent) => isTextEventStage(parent) || isTeaserEventStage(parent),
  },
  '$.eventStage.showCountdown': {
    condition: (obj, jsonpath, jsonpathParams, parent) => isTextEventStage(parent) || isTeaserEventStage(parent),
  },
};
