import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fn',
})
export class FnPipe implements PipeTransform {
  public transform(templateValue: any, fnReference: Function, ...fnArguments: any[]): any {
    fnArguments.unshift(templateValue);
    return fnReference.apply(null, fnArguments);
  }
}
