import { ImageBannerPageModuleFactory } from './../../factories/pagemodules/ImageBannerPageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const imageBannerPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('ImageBanner'),

  "$.pageModules[?(@.pageModuleType=='ImageBanner' && @._id=='$pageModuleId')].width": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Content Area', value: 'content-area' },
      { label: 'Full Width', value: 'fullwidth' },
    ],
  },

  "$.pageModules[?(@.pageModuleType=='ImageBanner' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new ImageBannerPageModuleFactory().imageBannerPageModuleLocal({}),
  },

  "$.pageModules[?(@.pageModuleType=='ImageBanner' && @._id=='$pageModuleId')].local.$language.imageAssetBreakpoint": {
    factory: async () => new ImageBannerPageModuleFactory().imageBannerPageModuleBreakpoint({}),
  },
  "$.pageModules[?(@.pageModuleType=='ImageBanner' && @._id=='$pageModuleId')].local.$language.imageAssetBreakpoint.desktop": {
    header: 'Image Banner Desktop Size',
    type: 'imageasset',
  },
  "$.pageModules[?(@.pageModuleType=='ImageBanner' && @._id=='$pageModuleId')].local.$language.imageAssetBreakpoint.desktopLarge": {
    header: 'Image Banner Desktop Large Size',
    type: 'imageasset',
  },
  "$.pageModules[?(@.pageModuleType=='ImageBanner' && @._id=='$pageModuleId')].local.$language.imageAssetBreakpoint.desktopWidescreen": {
    header: 'Image Banner Desktop Widescreen Size',
    type: 'imageasset',
  },
  "$.pageModules[?(@.pageModuleType=='ImageBanner' && @._id=='$pageModuleId')].local.$language.imageAssetBreakpoint.tablet": {
    header: 'Image Banner Tablet Size',
    type: 'imageasset',
  },
  "$.pageModules[?(@.pageModuleType=='ImageBanner' && @._id=='$pageModuleId')].local.$language.imageAssetBreakpoint.tabletLarge": {
    header: 'Image Banner Tablet Large Size',
    type: 'imageasset',
  },
  "$.pageModules[?(@.pageModuleType=='ImageBanner' && @._id=='$pageModuleId')].local.$language.imageAssetBreakpoint.mobile": {
    header: 'Image Banner Mobile Size',
    type: 'imageasset',
  },
  "$.pageModules[?(@.pageModuleType=='ImageBanner' && @._id=='$pageModuleId')].local.$language.imageAssetBreakpoint.mobileLarge": {
    header: 'Image Banner Mobile Large Size',
    type: 'imageasset',
  },

  "$.pageModules[?(@.pageModuleType=='ImageBanner' && @._id=='$pageModuleId')].link": {
    type: 'ctabutton',
  },
};
