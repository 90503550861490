import { TextAttributes } from './../Attributes';
import { CTAButton, CTAButtonType } from '../CTAButton';
import { PageModule, TitlePositions } from './PageModule';

export type ImageTagTextLinkPageModuleAttributes = TextAttributes & {};

export interface ImageTagTextLinkPageModule extends PageModule {
  pageModuleType: 'ImageTagTextLink';
  titlePosition?: TitlePositions;
  local: {
    [language: string]: {
      title: string;
      title_attr?: ImageTagTextLinkPageModuleAttributes;
      items: ImageTagTextLinkItem[];
    };
  };
  ctaButton?: CTAButton;
}

export interface ImageTagTextLinkItem {
  image: string;
  title: string;
  tag: string;
  text: string;
  linkType: CTAButtonType;
  link?: string;
  width: 'full' | 'half';
}

export function isImageTagTextLinkPageModule(object: any): object is ImageTagTextLinkPageModule {
  return object?.pageModuleType === 'ImageTagTextLink';
}
