import { Component, Input } from '@angular/core';

export type SkeletonLine = 'text' | 'textarea' | 'table';

@Component({
  selector: 'c-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent {
  @Input()
  fields: SkeletonLine[] = [];

  @Input()
  menu: boolean = false;

  @Input()
  table: {
    columns: string[];
    rows: number;
  } = null;

  constructor() {}

  rows(): number[] {
    return Array.from({ length: this.table.rows }, (v, i) => i);
  }
}
