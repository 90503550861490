import { AccessPolicy } from './AccessPolicy';
import { AppFunction } from './AppFunction';

export const menuItemTypes = ['Page', 'Link', 'Dialog', 'Form', 'AppFunction', 'Explore'] as const;
export type MenuItemType = (typeof menuItemTypes)[number];

export interface MenuItem {
  _id: string;
  menuItemType: MenuItemType;
  local: { [language: string]: MenuItemLocal };
  readAccess: AccessPolicy[];
  executeAccess: AccessPolicy[];
  icon?: string;
  hoverIcon?: string;
  activeIcon?: string;
}

export interface MenuItemLocal {
  title: string;
  topTitle?: string;
}

export interface PageMenuItem extends MenuItem {
  menuItemType: 'Page';
  page: string;
}

export interface LinkMenuItem extends MenuItem {
  menuItemType: 'Link';
  local: {
    [language: string]: MenuItemLocal & {
      link: string;
    };
  };
}

export interface DialogMenuItem extends MenuItem {
  menuItemType: 'Dialog';
  dialog: string;
}

export interface FormMenuItem extends MenuItem {
  menuItemType: 'Form';
  form: string;
}

export interface AppFunctionMenuItem extends MenuItem {
  menuItemType: 'AppFunction';
  appFunction: AppFunction;
}

export interface ExploreMenuItem extends MenuItem {
  menuItemType: 'Explore';
}

export interface AppNavigationMenuItem extends MenuItem {
  showAsCircleButton?: boolean;
}

export interface MobileQuickAccessMenuItem extends MenuItem {
  showAsCircleButton?: boolean;
}

export interface WebMenuItem extends MenuItem {}

export interface AppMenuItem extends MenuItem {}

export function isPageMenuItem(object: any): object is PageMenuItem {
  return object.menuItemType === 'Page';
}

export function isLinkMenuItem(object: any): object is LinkMenuItem {
  return object.menuItemType === 'Link';
}

export function isDialogMenuItem(object: any): object is DialogMenuItem {
  return object.menuItemType === 'Dialog';
}

export function isFormMenuItem(object: any): object is FormMenuItem {
  return object.menuItemType === 'Form';
}

export function isAppFunctionMenuItem(object: any): object is AppFunctionMenuItem {
  return object.menuItemType === 'AppFunction';
}

export function isExploreMenuItem(object: any): object is ExploreMenuItem {
  return object.menuItemType === 'Explore';
}
