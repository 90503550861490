import { Injectable } from '@angular/core';
import { IFilterList } from 'src/common/api/interfaces';
import { GetAdminUserQuery, GetAdminUserResponse } from 'src/common/api/v1/adminusers/GetAdminUser';
import { GetAdminUserApiKeysQuery, GetAdminUserApiKeysResponse } from 'src/common/api/v1/adminusers/GetAdminUserApiKeys';
import { GetAdminUsersQuery, GetAdminUsersResponse } from 'src/common/api/v1/adminusers/GetAdminUsers';
import { PostAdminUserBody, PostAdminUserQuery, PostAdminUserResponse } from 'src/common/api/v1/adminusers/PostAdminUser';
import { PostAdminUserApiKeyDeleteBody, PostAdminUserApiKeyDeleteQuery, PostAdminUserApiKeyDeleteResponse } from 'src/common/api/v1/adminusers/PostAdminUserApiKeyDelete';
import { PostAdminUserApiKeysBody, PostAdminUserApiKeysQuery, PostAdminUserApiKeysResponse } from 'src/common/api/v1/adminusers/PostAdminUserApiKeys';
import { PostAdminUserBasicAuthBody, PostAdminUserBasicAuthQuery, PostAdminUserBasicAuthResponse } from 'src/common/api/v1/adminusers/PostAdminUserBasicAuth';
import { PostAdminUsersImportBody, PostAdminUsersImportQuery, PostAdminUsersImportResponse } from 'src/common/api/v1/adminusers/PostAdminUsersImport';
import { PostAdminUsersMembershipBody, PostAdminUsersMembershipQuery, PostAdminUsersMembershipResponse } from 'src/common/api/v1/adminusers/PostAdminUsersMembership';
import { AdminUser } from 'src/common/entities/AdminUser';
import { ApiService } from '../api/api.service';
import { CacheContainer } from '../cache/cache-container';
import { CacheService } from '../cache/cache.service';
import { ConfigurationService } from '../configuration/configuration.service';
import { PostAdminUsersBody, PostAdminUsersQuery, PostAdminUsersResponse } from './../../../common/api/v1/adminusers/PostAdminUsers';

@Injectable({
  providedIn: 'root',
})
export class AdminUsersService {
  private _adminUserCache: CacheContainer<AdminUser>;

  constructor(private apiService: ApiService, private cacheService: CacheService, private configurationService: ConfigurationService) {
    this._adminUserCache = this.cacheService.create<AdminUser>({
      get: async (user) => await this.apiService.get<GetAdminUserQuery, GetAdminUserResponse>(`/api/v1/adminusers/${user}`).toPromise(),
      socketEvents: ['adminUser:update'],
    });
  }

  getAdminUser(userId: string): Promise<AdminUser> {
    return this._adminUserCache.asPromise(userId);
  }

  createAdminUser(adminUser: AdminUser): Promise<AdminUser> {
    return this._adminUserCache.post<PostAdminUsersQuery, PostAdminUsersBody, PostAdminUsersResponse>('/api/v1/adminusers', adminUser);
  }

  updateAdminUser(adminUser: AdminUser): Promise<AdminUser> {
    return this._adminUserCache.post<PostAdminUserQuery, PostAdminUserBody, PostAdminUserResponse>(`/api/v1/adminusers/${adminUser._id}`, adminUser);
  }

  getServiceAdminUserApiKeys(adminUserId: string): Promise<GetAdminUserApiKeysResponse> {
    return this.apiService.get<GetAdminUserApiKeysQuery, GetAdminUserApiKeysResponse>(`/api/v1/adminusers/${adminUserId}/apikeys`).toPromise();
  }

  createServiceAdminUserApiKey(adminUserId: string, apiKey: PostAdminUserApiKeysBody): Promise<PostAdminUserApiKeysResponse> {
    return this.apiService.post<PostAdminUserApiKeysQuery, PostAdminUserApiKeysBody, PostAdminUserApiKeysResponse>(`/api/v1/adminusers/${adminUserId}/apikeys`, apiKey).toPromise();
  }

  deleteServiceAdminUserApiKey(adminUserId: string, apiKeyId: string): Promise<PostAdminUserApiKeyDeleteResponse> {
    return this.apiService
      .post<PostAdminUserApiKeyDeleteQuery, PostAdminUserApiKeyDeleteBody, PostAdminUserApiKeyDeleteResponse>(`/api/v1/adminusers/${adminUserId}/apikeys/${apiKeyId}/delete`, {})
      .toPromise();
  }

  async getAdminUsers(query?: GetAdminUsersQuery): Promise<GetAdminUsersResponse> {
    return this.apiService
      .get<GetAdminUsersQuery, GetAdminUsersResponse>('/api/v1/adminusers', {
        source: 'Local',
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();
  }

  public async getAdminUsersByGroup(groupId: string, query?: GetAdminUsersQuery): Promise<GetAdminUsersResponse> {
    const groupFilter = {
      groups: {
        matchMode: 'equalsObjectId',
        value: groupId,
      },
    } as IFilterList;

    return this.apiService
      .get<GetAdminUsersQuery, GetAdminUsersResponse>('/api/v1/adminusers', {
        source: 'Local',
        ...query,
        limit: query?.limit ? query.limit : 50,
        skip: query?.skip ? query.skip : 0,
        filter: query?.filter ? { ...query.filter, ...groupFilter } : groupFilter,
      })
      .toPromise();
  }

  async postAdminUserImport(identifier: string, groups: string[], roles: string[]) {
    return this.apiService
      .post<PostAdminUsersImportQuery, PostAdminUsersImportBody, PostAdminUsersImportResponse>('/api/v1/adminusers/import', {
        identifier: identifier,
        groups: groups,
        roles: roles,
      })
      .toPromise();
  }

  async getExperts(query?: GetAdminUsersQuery): Promise<GetAdminUsersResponse> {
    return this.getAdminUsersByGroup(this.configurationService.configuration()['expertGroup'], query);
  }

  async getRemoteUsers(query?: GetAdminUsersQuery): Promise<GetAdminUsersResponse> {
    return this.getAdminUsersByGroup(null, {
      ...query,
      source: 'Remote',
    });
  }

  membership(body: PostAdminUsersMembershipBody): Promise<PostAdminUsersMembershipResponse> {
    return this.apiService.post<PostAdminUsersMembershipQuery, PostAdminUsersMembershipBody, PostAdminUsersMembershipResponse>('/api/v1/adminusers/membership', body).toPromise();
  }

  postBasicAuth(adminUserId: string, body: PostAdminUserBasicAuthBody): Promise<PostAdminUserBasicAuthResponse> {
    return this.apiService.post<PostAdminUserBasicAuthQuery, PostAdminUserBasicAuthBody, PostAdminUserBasicAuthResponse>(`/api/v1/adminusers/${adminUserId}/basic-auth`, body).toPromise();
  }
}
