import { Stage2MultiEventStagePageModuleAttributes } from './../../entities/pagemodules/Stage2MultiEventStagePageModule';
import { HeadlineType } from './../../entities/Attributes';
import { ContentAreaHeightDefaultBreakpointValue, CTAAreaHeightDefaultBreakpointValue, TopAreaHeightDefaultBreakpointValue } from './DefaultBreakponitValue';
import { BreakpointValue } from './../../entities/Breakpoint';
import { Stage2MultiEventStagePageModule } from '../../entities/pagemodules/Stage2MultiEventStagePageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class Stage2MultiEventStagePageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public multiEventStage2PageModuleLocal(values?: any) {
    return {
      title: values?.title || '',
      title_attr: this.stage2MultiEventStagePageModuleAttributes(values?.title_attr),
      subtitle: values?.subtitle || '',
      subtitle_attr: this.stage2MultiEventStagePageModuleAttributes(values?.subtitle_attr, 'no headline'),
      preHeadline: values?.preHeadline || '',
      preHeadline_attr: this.stage2MultiEventStagePageModuleAttributes(values?.preHeadline_attr, 'no headline'),
      subtext: values?.subtext || '',
      events: values?.events || [],
      background: values?.background || null,
    };
  }

  public stage2MultiEventStagePageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): Stage2MultiEventStagePageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async multiEventStage2PageModuleButton(values?: any) {
    return {
      _id: await this.id(values?._id),
      ctaButton: values?.ctaButton || null,
    };
  }

  public multiEventStage2PageModuleAreaHeight(defaultValues: BreakpointValue<number>, values?: any) {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge,
    };
  }

  public async multiEventStage2PageModule(values: any): Promise<Stage2MultiEventStagePageModule> {
    if (values?.background) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.background = item.background || values.background;
      });
    }

    const pageModule = {
      ...(await this.baseValues(values)),
      pageModuleType: 'MultiEventStage2',
      background: undefined,
      events: values?.events || null,
      local: await this.local(values, (val) => this.multiEventStage2PageModuleLocal(val)),
      ctalist: values?.ctalist || [],
      ctalistInteraction: values?.ctalistInteraction || [],
      discoverMore: values?.discoverMore || false,
      ctaAreaHeight: values?.ctaAreaHeight ? values?.ctaAreaHeight : this.multiEventStage2PageModuleAreaHeight(CTAAreaHeightDefaultBreakpointValue, values?.ctaAreaHeight),
      contentAreaHeight: values?.contentAreaHeight ? values?.contentAreaHeight : this.multiEventStage2PageModuleAreaHeight(ContentAreaHeightDefaultBreakpointValue, values?.contentAreaHeight),
      topAreaHeight: values?.topAreaHeight ? values?.topAreaHeight : this.multiEventStage2PageModuleAreaHeight(TopAreaHeightDefaultBreakpointValue, values?.topAreaHeight),
    } as Stage2MultiEventStagePageModule;

    // `events` migration `local..events`
    // `en` is used as default fallback
    if (pageModule.events && pageModule.events.length > 0) {
      if (!pageModule.local.en) {
        pageModule.local.en = this.multiEventStage2PageModuleLocal();
      }

      pageModule.local.en.events = pageModule.events;
      pageModule.events = null;
    }

    return { ...pageModule, pageModuleType: 'MultiEventStage2' };
  }
}
