<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_EVENT_SECTION_CUSTOM_FIELDS_REGISTRATION' | translate }}
    <div class="actions">
      <button *hasRight="'events.editContent'" pButton type="button" class="p-button" [label]="'GENERAL_ADD' | translate" icon="pi icon-ic-add-item" (click)="showCustomFieldSelect = true"></button>
      <c-table-options #to></c-table-options>
    </div>
  </div>

  <c-table #table [data]="eventVersion.registrationCustomFields" [options]="customFieldsTableOptions" [tableOptionsComponent]="to">
    <ng-template let-registrationCF>
      <a *ngIf="hasEditContentRight" [routerLink]="[]" queryParamsHandling="merge" (click)="editCustomField(registrationCF)"> {{ (registrationCF.customField | customField | async)?.internalName }}</a>
      <span *ngIf="!hasEditContentRight">{{ (registrationCF.customField | customField | async)?.internalName }}</span>
    </ng-template>
    <ng-template let-registrationCF>
      {{ 'PAGE_CUSTOM_FIELD_TYPE_' + (registrationCF.customField | customField | async)?.customFieldType.toUpperCase() | translate }}
    </ng-template>
    <ng-template let-registrationCF>
      <i *ngIf="registrationCF.required" class="pi pi-check"></i>
      <i *ngIf="!registrationCF.required" class="pi pi-times"></i>
    </ng-template>
    <ng-template let-registrationCF>
      <i *ngIf="!registrationCF.eventTickets" class="pi pi-check"></i>
      <i *ngIf="registrationCF.eventTickets" class="pi pi-times"></i>
    </ng-template>
    <ng-template let-registrationCF let-first="first" let-last="last">
      <div *hasRight="'events.editContent'" style="cursor: pointer">
        <i *ngIf="!first" class="pi pi-sort-up p-mr-2" [pTooltip]="'GENERAL_MOVE_UP' | translate" tooltipPosition="left" (click)="registrationCFUp(registrationCF); $event.stopPropagation()"></i>
        <i
          *ngIf="!last"
          class="pi pi-sort-down"
          [ngClass]="{ 'down-padding-left': first }"
          [pTooltip]="'GENERAL_MOVE_DOWN' | translate"
          tooltipPosition="left"
          (click)="registrationCFDown(registrationCF); $event.stopPropagation()"
        ></i>
      </div>
    </ng-template>
  </c-table>
</div>

<!-- Registration Custom Field Edit Dialog -->
<p-sidebar *ngIf="showCustomFieldEdit" [(visible)]="showCustomFieldEdit" styleClass="p-sidebar-st" position="right" [modal]="true" [showCloseIcon]="false" [appendTo]="'body'">
  <c-sidebar-header
    [headerTitle]="'PAGE_EVENT_SECTION_CUSTOM_FIELDS_REGISTRATION_EDIT' | translate"
    [headerSubTitle]="(editRegistrationCustomField.customField | customField | async)?.internalName"
    (onClose)="showCustomFieldEdit = false"
  ></c-sidebar-header>

  <div class="sidebar-tabs">
    <button [class.active]="customFieldEditTab === 'general'" (click)="customFieldEditTab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
  </div>
  <div class="sidebar-body">
    <div *ngIf="customFieldEditTab == 'general'">
      <c-event-patch-input
        [eventVersion]="eventVersion"
        jsonpath="$.registrationCustomFields[?(@.customField=='$customField')].required"
        [jsonpathParams]="{ customField: editRegistrationCustomField.customField }"
        [inputConfiguration]="inputConfigurationRequired"
      ></c-event-patch-input>

      <c-collaboration-patch-input
        [object]="customFieldEditHelper"
        jsonpath="$.visibleOnAllTickets"
        [inputConfiguration]="{
          type: 'switch',
          header: 'PAGE_EVENT_SECTION_CUSTOM_FIELDS_REGISTRATION_ALL_TICKETS',
          description: 'PAGE_EVENT_SECTION_CUSTOM_FIELDS_REGISTRATION_ALL_TICKETS_DESCRIPTION'
        }"
        [disabled]="eventVersion | isDisabled"
        (onValueChanged)="setVisibleOnAllTickets($event, editRegistrationCustomField.customField)"
      ></c-collaboration-patch-input>

      <c-event-patch-input
        *ngIf="editRegistrationCustomField.eventTickets != null"
        [eventVersion]="eventVersion"
        jsonpath="$.registrationCustomFields[?(@.customField=='$customField')].eventTickets"
        [jsonpathParams]="{ customField: editRegistrationCustomField.customField }"
        [inputConfiguration]="inputConfigurationEventTickets"
      ></c-event-patch-input>

      <c-event-patch-input
        [eventVersion]="eventVersion"
        jsonpath="$.registrationCustomFields[?(@.customField=='$customField')].hidden"
        [jsonpathParams]="{ customField: editRegistrationCustomField.customField }"
      ></c-event-patch-input>

      <div class="input-group compact">
        <div class="input">
          <p-dropdown
            [options]="registrationCustomFieldAutoFillTypeOptions"
            [ngModel]="registrationCustomFieldAutoFillType(editRegistrationCustomField)"
            (ngModelChange)="registrationCustomFieldAutoFillTypeChange(editRegistrationCustomField, $event)"
          ></p-dropdown>
        </div>
        <div class="title">Auto Fill</div>
      </div>

      <div *ngIf="editRegistrationCustomField.autoFill?.registrationCustomFieldAutoFillType === 'CustomData'">
        <c-event-patch-input
          [eventVersion]="eventVersion"
          jsonpath="$.registrationCustomFields[?(@.customField=='$customField')].autoFill.customData"
          [inputConfiguration]="inputConfigurationCustomData"
          [jsonpathParams]="{ customField: editRegistrationCustomField.customField }"
          (onValueChanged)="autoFillCustomDataChanged($event)"
        ></c-event-patch-input>
        <c-event-patch-input
          [eventVersion]="eventVersion"
          jsonpath="$.registrationCustomFields[?(@.customField=='$customField')].autoFill.property"
          [inputConfiguration]="inputConfigurationCustomDataAutoFillProperty"
          [jsonpathParams]="{ customField: editRegistrationCustomField.customField }"
        ></c-event-patch-input>
        <c-event-patch-input
          [eventVersion]="eventVersion"
          jsonpath="$.registrationCustomFields[?(@.customField=='$customField')].autoFill.mode"
          [jsonpathParams]="{ customField: editRegistrationCustomField.customField }"
        ></c-event-patch-input>
      </div>
    </div>
  </div>

  <c-sidebar-footer
    *hasRight="'events.editContent'"
    [autoSaveInfo]="true"
    (onClose)="showCustomFieldEdit = false"
    [showDeleteButton]="true"
    (onDelete)="registrationCFDelete(editRegistrationCustomField); showCustomFieldEdit = false"
  ></c-sidebar-footer>
</p-sidebar>

<c-sidebar [noScroll]="true" [header]="'PAGE_EVENT_SECTION_CUSTOM_FIELDS_REGISTRATION_ADD' | translate" class="custom-fields-sidebar" [(visible)]="showCustomFieldSelect">
  <div *ngIf="searchResult" class="sidebar-search">
    <div class="search-input input-group compact">
      <div class="input">
        <input [placeholder]="'Search ...'" pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
      </div>
    </div>
    <div infinite-scroll [scrollWindow]="false" (scrolled)="executeSearch()" class="search-results">
      <div *ngFor="let customField of searchResult" (click)="addRegistrationCustomField(customField)">
        <div [title]="'PAGE_CUSTOM_FIELD_TYPE_' + customField.customFieldType.toUpperCase() | translate" class="customfield-item-wrapper">
          <div class="customfield-item">
            <div class="customfield-preview">
              <img [src]="'/assets/images/customfieldtypes/' + customField.customFieldType + '.svg'" height="24px" [alt]="customField.customFieldType" />
            </div>
            <div class="customfield-info">
              <h1>{{ customField.internalName }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</c-sidebar>
