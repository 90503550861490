import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguagesService } from 'src/app/services/languages/languages.service';
import { Language } from 'src/common/entities/Language';

import { Lookup } from 'src/common/entities/Lookup';
import { LookupsService } from 'src/app/services/lookups/lookups.service';

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss'],
})
export class LookupComponent implements OnInit, OnDestroy {
  _id: string; // Of lookup to edit
  lookup: Lookup = null; // Holds lookup to edit

  // Store newly added keys and values (in order to add them to all languages when saving)
  newKeysAndValues: {
    key: string;
    value: string;
  }[] = [];

  keysAndValuesByLanguage: {
    // Holds locale lookups editable keys and values by language
    language: { [language: string]: { key: string; value: string }[] };
  } = null;

  keysAndValues: {
    // Holds data lookups editable keys and values
    data: { key: string; value: string }[];
  } = null;

  languages: Language[] = [];
  languageBase: string = 'en'; // Base language, holding all lookup items
  languageEdit: string = this.languageBase; // Editable language

  loading: number = 0;
  saving: boolean = false;

  private _ngUnsubscribe: Subject<any> = new Subject<any>();

  get selectedLanguages(): string[] {
    return this.languages ? this.languages.map((language) => language.language) : [];
  }

  set selectedLanguages(languages: string[]) {
    this.languageEdit = languages[0];
  }

  constructor(private activatedRoute: ActivatedRoute, public clipboard: Clipboard, private lookupsService: LookupsService, private languagesService: LanguagesService) {}

  ngOnDestroy(): void {
    // Unsubscribe subscriptions
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  async ngOnInit(): Promise<void> {
    this.loading++;

    // Read requested lookup from route params
    this.activatedRoute.params.pipe(takeUntil(this._ngUnsubscribe)).subscribe(async (params) => {
      this._id = params.id;

      if (this._id && this._id != '') {
        try {
          // Get requested lookup
          this.lookup = await this.lookupsService.getLookup(this._id);
          // Prepare lookup for editing
          this.prepareLookup();
        } catch (err) {
          if (err.error && err.error.errorTag == 'LOOKUP_NOT_FOUND') {
          } else {
            throw err;
          }
        }
        this.loading--;
      }
    });
  }

  async onLanguagesChange(languages: string[]) {
    let _languages: Language[] = [];

    languages.forEach((language) => {
      this.languagesService.asPromise(language).then((l) => _languages.push(l));
      if (this.keysAndValuesByLanguage.language[language] == null) {
        // If new language has no values, copy values from languageBase
        this.keysAndValuesByLanguage.language[language] = [];
        this.keysAndValuesByLanguage.language[this.languageBase].forEach((item) => {
          // Save only items with valid key and value
          if (item.key) {
            this.keysAndValuesByLanguage.language[language].push({ key: item.key, value: '' });
          }
        });
      }
    });

    // Add updated languages to local language store
    this.languages = _languages;
  }

  onCurrentLanguageChange(language) {
    this.newKeysAndValues = [];
  }

  prepareLookup() {
    // Prepare lookup for editing
    if (this.lookup.local) {
      this.prepareLocalLookup();
    } else if (this.lookup.data) {
      this.prepareDataLookup();
    }
  }

  async prepareLocalLookup() {
    this.loading++;
    const _languageBase: Language = await this.languagesService.asPromise(this.languageBase);
    try {
      await Promise.allSettled(
        Object.keys(this.lookup.local).map(
          async (l) =>
            await this.languagesService.asPromise(l).then(
              // Add language if active and not already in languages
              (v) => {
                if (!this.languages.includes(v) && v.active == true) this.languages.push(v);
              },
              (e) => {}
            )
        )
      );

      if (this.languages.length > 0 && !this.languages.includes(_languageBase)) {
        this.languageBase = this.languages[0].language;
        this.languageEdit = this.languageBase;
      }
      if (this.languages.length === 0) {
        this.languages[0] = _languageBase;
      }

      // Check for each activated language ...
      this.languages.forEach((checkLanguage) => {
        // ... if lookup data for beeing checked language exists
        if (this.lookup.local[checkLanguage.language]) {
          // Lookup exists for beeing checked language: Check if all lookup items exists
          for (const item in this.lookup.local[this.languageBase]) {
            if (!this.lookup.local[checkLanguage.language][item]) {
              // If item not exists, add locale item (from 'en')
              this.lookup.local[checkLanguage.language][item] = null;
            }
          }
        } else {
          // Lookup does NOT exist for beeing checked language: Add locale items (from 'en') for being checked language
          let localCopy = Object.assign({}, this.lookup.local[this.languageBase]);
          this.lookup.local = { ...this.lookup.local, [checkLanguage.language]: localCopy };
          // Set copied item values to null
          for (const item in this.lookup.local[this.languageBase]) {
            this.lookup.local[checkLanguage.language][item] = null;
          }
        }

        // Prepare lookup data for editing in this.keysAndValuesByLanguage
        let tmpArray: { key: string; value: string }[] = [];
        for (const item in this.lookup.local[this.languageBase]) {
          tmpArray.push({ key: item, value: this.lookup.local[checkLanguage.language][item] });
        }
        if (!this.keysAndValuesByLanguage) {
          this.keysAndValuesByLanguage = Object.assign({}, { language: { [checkLanguage.language]: tmpArray } });
        } else {
          this.keysAndValuesByLanguage.language = {
            ...this.keysAndValuesByLanguage.language,
            [checkLanguage.language]: tmpArray,
          };
        }
      });
    } catch (err) {
      throw err;
    }

    this.loading--;
  }

  prepareDataLookup() {
    // Prepare lookup data for editing in this.keysAndValues
    let tmpArray: { key: string; value: string }[] = [];
    for (const item in this.lookup.data) {
      tmpArray.push({ key: item, value: this.lookup.data[item] });
    }
    this.keysAndValues = Object.assign({}, { data: tmpArray });
  }

  async save() {
    if (this.lookup.local) {
      // For each activated language
      this.languages.forEach((language) => {
        // Delete lookups retrieved locale data ...
        this.lookup.local[language.language] = {};
        // ... and fill lookups locale data with values from beeing edited this.keysAndValuesByLanguage
        this.keysAndValuesByLanguage.language[language.language].forEach((item) => {
          // Save only items with valid key and value
          if (item.key && item.value && this.selectedLanguages.includes(language.language)) {
            this.lookup.local[language.language][item.key] = item.value;
          }
        });
        // Add new keys and values
        this.newKeysAndValues.forEach((item) => {
          if (item.key && item.value && this.selectedLanguages.includes(language.language)) {
            // Add keys in every language
            this.lookup.local[language.language][item.key] = '';
          }
        });
      });
      // Remove deactivated languages
      Object.keys(this.lookup.local).forEach((local) => {
        if (this.languages.filter((i) => i.language === local).length === 0) {
          delete this.lookup.local[local];
        }
      });
      // Add values for current
      this.newKeysAndValues.forEach((item) => {
        if (item.key && item.value) {
          this.lookup.local[this.languageEdit][item.key] = item.value;
        }
      });
    } else if (this.lookup.data) {
      // Delete lookups retrieved data ...
      this.lookup.data = {};
      // ... and fill lookups data with values from beeing edited this.keysAndValues
      this.keysAndValues.data.forEach((item) => {
        // Save only items with valid key and value
        if (item.key && item.value) {
          this.lookup.data[item.key] = item.value;
        }
      });
    }

    this.saving = true;
    try {
      this.lookup = await this.lookupsService.updateLookup(this.lookup);
      // Clean-up
      this.newKeysAndValues = [];
    } catch (err) {
      this.lookup = null;
      throw err;
    }
    this.saving = false;

    // Prepare lookup returned from update for editing
    this.prepareLookup();
  }

  addEntry(local?: string) {
    if (this.lookup.local) {
      this.newKeysAndValues.push({ key: null, value: null });
    } else if (this.lookup.data) {
      this.keysAndValues.data.push({ key: null, value: null });
    }
  }

  onKeyEdit(e) {
    // Sync edited or removed keys for every language
    // Get index of modified key
    const _index = this.keysAndValuesByLanguage.language[this.languageEdit].findIndex((o) => o.key == e);
    if (_index != -1) {
      for (const language in this.keysAndValuesByLanguage.language) {
        if (this.keysAndValuesByLanguage.language[language][_index].key !== e) {
          // If it does not already correspond to value, sync for every language
          this.keysAndValuesByLanguage.language[language][_index].key = e;
        }
      }
    }
  }
}
