import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { GetAssetQuery, GetAssetResponse } from './../../../common/api/v1/assets/GetAsset';
import { GetAssetsQuery, GetAssetsResponse } from './../../../common/api/v1/assets/GetAssets';
import { PostAssetBody, PostAssetQuery, PostAssetResponse } from './../../../common/api/v1/assets/PostAsset';
import { PostAssetGenerateThumbnailBody, PostAssetGenerateThumbnailQuery, PostAssetGenerateThumbnailResponse } from 'src/common/api/v1/assets/PostAssetGenerateThumbnail';
import { PostAssetsQuery, PostAssetsResponse } from 'src/common/api/v1/assets/PostAssets';
import { PostAssetsImportBody, PostAssetsImportQuery, PostAssetsImportResponse } from 'src/common/api/v1/assets/PostAssetsImport';
import { Asset, DocumentAsset, ImageAsset, VideoAsset } from 'src/common/entities/Asset';
import { ApiService, Upload } from '../api/api.service';
import { CacheContainer } from '../cache/cache-container';
import { CacheService } from '../cache/cache.service';
import { AssetSerie } from 'src/common/entities/AssetSerie';
import { PostAssetSeriesBody, PostAssetSeriesQuery, PostAssetSeriesResponse } from 'src/common/api/v1/events/PostAssetSeries';
import { GetAssetSeriesQuery, GetAssetSeriesResponse } from 'src/common/api/v1/assets/GetAssetSeries';
import { PostAssetSerieBody, PostAssetSerieQuery, PostAssetSerieResponse } from 'src/common/api/v1/events/PostAssetSerie';
import { GetAssetSerieQuery, GetAssetSerieResponse } from 'src/common/api/v1/assets/GetAssetSerie';
import { PostAssetImportRetryBody, PostAssetImportRetryQuery, PostAssetImportRetryResponse } from 'src/common/api/v1/assets/PostAssetImportRetry';
import { GetAssetSasTokenQuery, GetAssetSasTokenResponse } from 'src/common/api/v1/assets/GetAssetSasToken';

export interface AssetUpload {
  file: File;
  internalName: string;
  status?: Upload<PostAssetsResponse>;
  error?: any;
}

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  private _currentAssetUploads: BehaviorSubject<AssetUpload[]> = new BehaviorSubject<AssetUpload[]>([]);
  private _cache: CacheContainer<Asset>;

  constructor(private apiService: ApiService, private messageService: MessageService, private cacheService: CacheService) {
    this._cache = this.cacheService.create({
      get: async (_id) => {
        try {
          return await this.apiService.get<GetAssetQuery, GetAssetResponse>(`/api/v1/assets/${_id}`).toPromise();
        } catch (err) {
          //throw err; => doesn't trigger error on subscription
          return null; // Return null instead to signal problem
        }
      },
      socketEvents: ['asset:update'],
      groups: [
        {
          name: 'imports',
          filter: (asset) => asset.status === 'DOWNLOADING',
          fill: async () => {
            const response = await this.getAssets({
              filter: {
                status: {
                  matchMode: 'equals',
                  value: 'DOWNLOADING',
                },
              },
              limit: 9999,
            });
            return response.items;
          },
        },
      ],
    });

    if (window.addEventListener) {
      window.addEventListener(
        'beforeunload',
        (e) => {
          if (!this._currentAssetUploads.getValue().every((a) => a.status?.response)) {
            const message = 'Asset upload in progress. Are you sure you want to close?';
            e.preventDefault();
            e.returnValue = message;
            return message;
          }
          return '';
        },
        true
      );
    }
  }

  currentAssetUploads(): Observable<AssetUpload[]> {
    return this._currentAssetUploads.asObservable();
  }

  currentAssetImports(): Observable<Asset[]> {
    return this._cache.group('imports');
  }

  uploadAsset(assetUpload: AssetUpload): Observable<Upload<PostAssetsResponse>> {
    this._currentAssetUploads.next(this._currentAssetUploads.getValue().concat([assetUpload]));

    const observable = this.apiService
      .upload<PostAssetsQuery, PostAssetsResponse>('/api/v1/assets', assetUpload.file, {
        internalName: assetUpload.internalName,
      })
      .pipe(share());

    observable.subscribe(
      (status) => {
        assetUpload.status = status;

        if (status.response) {
          this._cache.add(status.response);
          this.messageService.add({ severity: 'success', summary: 'Asset Upload', detail: `Uploaded ${assetUpload.file.name} successfully` });
          this._currentAssetUploads.next(this._currentAssetUploads.getValue().filter((u) => u !== assetUpload));
        }
      },
      (err) => {
        assetUpload.error = err;
        this.messageService.add({ severity: 'error', summary: 'Asset Upload', detail: `Error while uploading ${assetUpload.file.name}` });
      }
    );

    return observable;
  }

  generateThumbnail(asset: VideoAsset | DocumentAsset, position: number = 0): Observable<ImageAsset> {
    const result = this.apiService.post<PostAssetGenerateThumbnailQuery, PostAssetGenerateThumbnailBody, PostAssetGenerateThumbnailResponse>(`/api/v1/assets/${asset._id}/generatethumbnail`, {
      position: position,
    });
    result.subscribe(
      (status) => {
        if (status.status == 'FINISHED') {
          this.messageService.add({ severity: 'success', summary: 'Thumbnail generation', detail: `Successfully generated thumbnail.` });
        }
      },
      (err) => {
        let info = null;
        if (err.status == 400) {
          info = ' You might have entered an invalid page number.';
        }
        this.messageService.add({ severity: 'error', summary: 'Thumbnail generation', detail: `Error while trying to generate thumbnail.${info != null ? info : ''}` });
      }
    );
    return result;
  }

  getAsset(assetId: string, refresh: boolean = false): Observable<Asset> {
    return this._cache.asObservable(assetId, refresh);
  }

  getAssets(query?: GetAssetsQuery): Promise<GetAssetsResponse> {
    return this.apiService.get<GetAssetsQuery, GetAssetsResponse>('/api/v1/assets', query).toPromise();
  }

  async updateAsset(asset: Asset): Promise<PostAssetResponse> {
    const result = await this.apiService.post<PostAssetQuery, PostAssetBody, PostAssetResponse>(`/api/v1/assets/${asset._id}`, asset).toPromise();
    this._cache.add(asset);
    return result;
  }

  async publishAsset(asset: Asset): Promise<PostAssetResponse> {
    const result = await this.apiService.post<PostAssetQuery, PostAssetBody, PostAssetResponse>(`/api/v1/assets/${asset._id}/publish`, asset).toPromise();
    this._cache.add(asset);
    return result;
  }

  async retryImportAsset(asset: Asset): Promise<PostAssetImportRetryResponse> {
    const result = await this.apiService.post<PostAssetImportRetryQuery, PostAssetImportRetryBody, PostAssetImportRetryResponse>(`/api/v1/assets/${asset._id}/importretry`, {}).toPromise();
    return result;
  }

  async unpublishAsset(asset: Asset): Promise<PostAssetResponse> {
    const result = await this.apiService.post<PostAssetQuery, PostAssetBody, PostAssetResponse>(`/api/v1/assets/${asset._id}/unpublish`, asset).toPromise();
    this._cache.add(asset);
    return result;
  }

  async saveAssetSeries(assetSerie: AssetSerie): Promise<AssetSerie> {
    return await this.apiService.post<PostAssetSeriesQuery, PostAssetSeriesBody, PostAssetSeriesResponse>(`/api/v1/assets/assetseries`, assetSerie).toPromise();
  }

  async getAssetSeries(query?: GetAssetSeriesQuery): Promise<GetAssetSeriesResponse> {
    return this.apiService.get<GetAssetSeriesQuery, GetAssetSeriesResponse>(`/api/v1/assets/assetseries`, query).toPromise();
  }

  getAssetSerie(assetSerieId: String): Observable<GetAssetSerieResponse> {
    return this.apiService.get<GetAssetSerieQuery, GetAssetSerieResponse>(`/api/v1/assets/assetseries/${assetSerieId}`);
  }

  async downloadAssetViaSas(assetId: string): Promise<string> {
    const result = await this.apiService.get<GetAssetSasTokenQuery, GetAssetSasTokenResponse>(`/api/v1/assets/sas-token`, { assetId: assetId }).toPromise();
    return result.downloadUrl;
  }

  async updateAssetSerie(assetSerie: AssetSerie): Promise<AssetSerie> {
    return this.apiService.post<PostAssetSerieQuery, PostAssetSerieBody, PostAssetSerieResponse>(`/api/v1/assets/assetseries/${assetSerie._id}`, assetSerie).toPromise();
  }

  importAsset(importData: { url: string; internalName: string }): Promise<Asset> {
    return this._cache.post<PostAssetsImportQuery, PostAssetsImportBody, PostAssetsImportResponse>(`/api/v1/assets/import`, {
      ...importData,
    });
  }
}
