import { Map, MapVersion } from './../../../common/entities/Map';
import { MapsService } from 'src/app/services/maps/maps.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventsService } from 'src/app/services/events/events.service';
import { PagesService } from 'src/app/services/pages/pages.service';
import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page, PageVersion } from 'src/common/entities/Page';
import { TableOptions } from '../table/table.interfaces';
import HtmlFormatter, { format } from 'src/lib/diff-formater/htmlFormatter';

@Component({
  selector: 'c-version-select',
  templateUrl: './version-select.component.html',
  styleUrls: ['./version-select.component.scss'],
})
export class VersionSelectComponent implements OnInit {
  @Input()
  show: boolean = false;

  @Output()
  showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  eventVersion?: EventVersion = null;

  @Output()
  versionChange: EventEmitter<EventVersion> = new EventEmitter<EventVersion>();

  @Input()
  event?: Event = null;

  @Input()
  page?: Page = null;

  @Input()
  map?: Map = null;

  @Input()
  mapVersion?: MapVersion;

  @Input()
  pageVersion?: PageVersion;

  @Input()
  type: 'event' | 'page' | 'map' = 'event';

  @Input()
  showPublish: boolean = false;

  @Input()
  showVersionSelect: boolean = true;

  @Output()
  publish: EventEmitter<EventVersion> = new EventEmitter<EventVersion>();

  @Input()
  working = true;

  versionId: string;

  eventVersionTableOptions: TableOptions<EventVersion>;

  constructor(private eventsService: EventsService, private pagesService: PagesService, private mapsService: MapsService) {}

  ngOnInit(): void {
    this.eventVersionTableOptions = {
      size: 15,
      columns: [
        { header: 'Version' },
        { header: 'Created At' },
        { header: 'Created By' },
        { header: 'Finalized At' },
        { header: 'Finalized By' },
        { header: 'Published At' },
        { header: 'Published By' },
        { header: '' },
      ],
    };
  }

  getCreatedAt(v) {
    if (v?.forkedAt) return v.forkedAt;
    if (v?.modifiedAt) return v.modifiedAt;
    if (this.page?.createdAt) return this.page.createdAt;
    if (this.event?.createdAt) return this.event.createdAt;
    return null;
  }

  get showContent(): boolean {
    return !!((this.type === 'event' && this.eventVersion && this.event) || (this.type === 'page' && this.pageVersion && this.page) || (this.type === 'map' && this.mapVersion && this.map));
  }

  showDiff = false;
  diffVersion(id: string) {
    this.versionId = id;
    this.showDiff = true;
  }

  queryEventVersions(query): void {
    if (this.type === 'page') {
      query.result = this.pagesService.getPageVersions(this.page._id, {
        ...query.query,
        orderBy: 'version',
        orderDirection: -1,
      });
    } else if (this.type === 'map') {
      query.result = this.mapsService.getMapVersions(this.map._id, {
        ...query.query,
        orderBy: 'version',
        orderDirection: -1,
      });
    } else {
      query.result = this.eventsService.getEventVersions(this.event._id, {
        ...query.query,
        orderBy: 'version',
        orderDirection: -1,
      });
    }
  }
}
