import { DirectivesModule } from './../../directives/directives.module';
import { PipesModule } from './../../pipes/pipes.module';
import { SplitButtonModule } from 'primeng/splitbutton';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesConfigurationComponent } from './pages-configuration.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { TableModule } from 'primeng/table';
import { ComponentsModule } from 'src/app/components/components.module';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { SidebarModule } from 'primeng/sidebar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { MessageModule } from 'primeng/message';
import { InputTextModule } from 'primeng/inputtext';

const routes: Routes = [{ path: '', component: PagesConfigurationComponent }];

@NgModule({
  declarations: [PagesConfigurationComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    TableModule,
    FormsModule,
    DividerModule,
    DropdownModule,
    ButtonModule,
    DialogModule,
    CardModule,
    ComponentsModule,
    SplitButtonModule,
    PipesModule,
    DirectivesModule,
    SplitButtonModule,
    SidebarModule,
    RadioButtonModule,
    CheckboxModule,
    MessageModule,
    InputTextModule,
  ],
  exports: [RouterModule],
})
export class PagesConfigurationModule {}
