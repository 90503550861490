import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { shareCtaTargets } from '../../entities/CTAButton';
import { TextEventStagePageModuleFactory } from '../../factories/pagemodules/TextEventStagePageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const textEventStagePageModuleInputs: Inputs = {
  ...commonPageModuleInputs('TextEventStage'),
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].ctalist": {
    factory: async () => [],
    childFactory: async () => new TextEventStagePageModuleFactory().textEventStagePageModuleButton({}),
    list: true,
    listKey: '_id',
    header: 'CTA Button'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].ctalist[$index].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].ctalistInteraction": {
    factory: async () => [],
    childFactory: async () => new TextEventStagePageModuleFactory().textEventStagePageModuleButton({}),
    list: true,
    listKey: '_id',
    header: 'CTA Button'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].ctalistInteraction[$index].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].local.$language.background": {},
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new TextEventStagePageModuleFactory().textEventStagePageModuleLocal({})
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].local.$language.smallHeadline": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: obj => {
        return obj;
      }
    }
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].local.$language.text": {},
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].showShareButton": {
    header: 'Show Share Button'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].showParticipatingTag": {
    header: 'Show Participating Tag'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].shareButtonTargets": {
    type: 'multiselect',
    header: 'Share targets',
    description: 'If none is selected, the module will display all possible targets.',
    placeholder: 'All targets',
    showToggleAll: true,
    multiselectOptions: [...shareCtaTargets].map(a => ({ label: a, value: a }))
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].showCalendarButton": {
    header: 'Show Calendear Button'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].showCountdown": {
    header: 'Show Countdown'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].layout": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Default', value: 'Default' },
      { label: 'Background Focused', value: 'BackgroundFocused' }
    ]
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].local.$language.subtext": {},
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].discoverMore": {
    header: 'Show Discover More',
    type: 'switch'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].stageHeight": {
    factory: async () => new TextEventStagePageModuleFactory().textEventStagePageModuleStageHeight({})
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].stageHeight.desktop": {
    type: 'number',
    header: 'Stage height desktop'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].stageHeight.desktopLarge": {
    type: 'number',
    header: 'Stage height desktop large'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].stageHeight.desktopWidescreen": {
    type: 'number',
    header: 'Stage height desktop widescreen'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].stageHeight.tablet": {
    type: 'number',
    header: 'Stage height tablet'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].stageHeight.tabletLarge": {
    type: 'number',
    header: 'Stage height tablet large'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].stageHeight.mobile": {
    header: 'Stage height mobile',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].stageHeight.mobileLarge": {
    header: 'Stage height mobile large',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint": {
    factory: async () => new TextEventStagePageModuleFactory().textEventStagePageModuleButtonSpacingTopBreakpoint({})
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.desktop": {
    header: 'Button spacing top desktop',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.desktopLarge": {
    header: 'Button spacing top desktop large',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.desktopWidescreen": {
    header: 'Button spacing top desktop widescreen',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.tablet": {
    header: 'Button spacing top tablet',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.tabletLarge": {
    header: 'Button spacing top tablet large',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.mobile": {
    header: 'Button spacing top mobile',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.mobileLarge": {
    header: 'Button spacing top mobile large',
    type: 'number'
  }
};
