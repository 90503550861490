import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { TextPageModuleFactory } from './../../factories/pagemodules/TextPageModuleFactory';
import { contentPosition, titlePositions } from '../../entities/PageModule';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const textPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('Text'),

  "$.pageModules[?(@.pageModuleType=='Text' && @._id=='$pageModuleId')].ctaButton": {},

  "$.pageModules[?(@.pageModuleType=='Text' && @._id=='$pageModuleId')].contentPosition": {
    type: 'dropdown',
    dropdownOptions: [...contentPosition].map((a) => ({ label: a, value: a })),
  },

  "$.pageModules[?(@.pageModuleType=='Text' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new TextPageModuleFactory().createTextPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='Text' && @._id=='$pageModuleId')].local.$language.title": {
    header: 'Title',
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='Text' && @._id=='$pageModuleId')].local.$language.text": {
    header: 'Text',
    type: 'textarea',
  },
  "$.pageModules[?(@.pageModuleType=='Text' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [...titlePositions].map((item) => ({
      label: item,
      value: item,
    })),
  },
};
