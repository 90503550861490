import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingsComponent } from './onboardings.component';
import { AccordionModule } from 'primeng/accordion';
import { SidebarModule } from 'primeng/sidebar';

const routes: Routes = [{ path: '', component: OnboardingsComponent }];

@NgModule({
  declarations: [OnboardingsComponent],
  imports: [CommonModule, RouterModule.forChild(routes), TableModule, TranslateModule, ButtonModule, ComponentsModule, PipesModule, DirectivesModule, AccordionModule, SidebarModule],
  exports: [RouterModule],
})
export class OnboardingsModule {}
