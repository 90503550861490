import { CTAButton } from '../CTAButton';
import { EventStage } from './EventStage';

export const teaserItemTypes = ['Highlight', 'Link', 'CTA'] as const;
export type TeaserItemType = (typeof teaserItemTypes)[number];

export interface TeaserItem {
  _id: string;
  teaserItemType: TeaserItemType;
}

export interface HighlightTeaserItem extends TeaserItem {
  teaserItemType: 'Highlight';
  session: string;
}

export interface LinkTeaserItem extends TeaserItem {
  teaserItemType: 'Link';
  image: string;
  title: string;
  link: string;
}

export interface CTATeaserItem extends TeaserItem {
  teaserItemType: 'CTA';
  image: string;
  title: string;
  cta: CTAButton;
}

export interface TeaserEventStageLocal {
  smallHeadline: string;
  title: string;
  subtitle: string;
  items: TeaserItem[];
}

export interface TeaserEventStage extends EventStage {
  eventStageType: 'Teaser';
  local: { [language: string]: TeaserEventStageLocal };
  ctaButton?: CTAButton;
  showCountdown: boolean;
}

export function isHighlightTeaserItem(object: any): object is HighlightTeaserItem {
  return object?.teaserItemType === 'Highlight';
}

export function isLinkTeaserItem(object: any): object is LinkTeaserItem {
  return object?.teaserItemType === 'Link';
}

export function isCTATeaserItem(object: any): object is CTATeaserItem {
  return object?.teaserItemType === 'CTA';
}

export function isTeaserEventStage(object: any): object is TeaserEventStage {
  return object?.eventStageType === 'Teaser';
}
