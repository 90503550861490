<p-button styleClass="p-button-text" icon="pi icon-ic-add-item" [disabled]="disabled" (onClick)="openVipTicketAddDialog()" [label]="'Add VIPs'"></p-button>

<p-dialog [(visible)]="showVipTicketAddDialog" [modal]="true" [appendTo]="'body'" [header]="'New VIP'" [contentStyle]="{ overflow: 'visible', width: '50vw' }">
  <div *ngIf="showVipTicketAddDialog">
    <div class="input-group">
      <div class="input">
        <textarea [(ngModel)]="emails" [disabled]="working"></textarea>
      </div>
      <div class="label">Users</div>
      <div class="description">Comma seperated list of email addresses</div>
    </div>
    <div class="input-group">
      <div class="input">
        <p-dropdown [(ngModel)]="eventTicket" [options]="eventTickets" [disabled]="working"></p-dropdown>
      </div>
      <div class="label">Ticket</div>
    </div>

    <p-footer>
      <div class="text-right">
        <p-button [label]="'GENERAL_SAVE' | translate" (onClick)="save()" [disabled]="working || !eventTicket"></p-button>
      </div>
    </p-footer>
  </div>

  <div class="result" *ngIf="vipTicketsResponse">
    <c-table [options]="resultTableOptions" [singleTemplate]="true" [data]="vipTicketsResponse">
      <ng-template let-response>
        <tr>
          <td>
            <span *ngIf="asUser(response.user) as user">{{ user.emailLower }}</span>
            <span *ngIf="!asUser(response.user)">{{ response.user }}</span>
          </td>
          <td>
            <span *ngIf="asUser(response.user) as user">{{ user.lastName }}</span>
            <span *ngIf="!asUser(response.user)">{{ 'GENERAL_UNKNOWN' | translate }}</span>
          </td>
          <td>
            <span *ngIf="asUser(response.user) as user">{{ user.firstName }}</span>
            <span *ngIf="!asUser(response.user)">{{ 'GENERAL_UNKNOWN' | translate }}</span>
          </td>
          <td>
            <span class="successful" *ngIf="response.vipTicket"> <i class="pi pi-check-circle"></i> Successful </span>
            <span class="error" *ngIf="!response.vipTicket"> <i class="pi pi-times-circle"></i> Error </span>
          </td>
        </tr>
      </ng-template>
    </c-table>
  </div>
</p-dialog>
