<div *ngIf="event?.currentEventVersion" class="panel">
  <div class="panel-header">
    {{ 'GENERAL_SESSIONS' | translate }}
    <div class="actions" *ngIf="false">
      <p-button icon="pi icon-ic-version" styleClass="p-button-text" (onClick)="showVersions = true" [label]="'Versions'" [disabled]="loading"></p-button>
    </div>
  </div>
  <div class="panel-body" *ngIf="false">
    <div *ngIf="event.currentEventVersion._id === eventVersion?._id">
      Those are the sessions of the <strong>current published version</strong>. Starting or stopping them will have a direct impact for the event viewers.
    </div>
    <div *ngIf="event.currentEventVersion._id !== eventVersion?._id && !preview">
      Those are the sessions of <strong>version {{ eventVersion?.version }}</strong
      >. You can only start or stop them if you select a preview.

      <div>
        <p-button [label]="'Select Preview'" (onClick)="showPreview = true"></p-button>
      </div>
    </div>
    <div *ngIf="event.currentEventVersion._id !== eventVersion?._id && preview">
      Those are the sessions of <strong>version {{ eventVersion?.version }}</strong> in a preview mode.
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <th class="selected">{{ 'GENERAL_START_AT' | translate }}</th>
        <th>{{ 'GENERAL_END_AT' | translate }}</th>
        <th>{{ 'GENERAL_ACTUAL_START_AT' | translate }}</th>
        <th>{{ 'GENERAL_ACTUAL_END_AT' | translate }}</th>
        <th>{{ 'GENERAL_TITLE' | translate }}</th>
        <th *hasRight="'chats.manage'">{{ 'GENERAL_CHAT' | translate }}</th>
        <th *hasRight="'chats.manage'">{{ 'GENERAL_CHAT_INFO' | translate }}</th>
        <th class="text-right" *hasRight="['streams.list', 'streams.create', 'streams.edit', 'streams.delete']">Stream</th>
        <th class="text-right">Live Viewers</th>
        <th class="text-right">Demand Viewers</th>
        <th class="text-right">{{ 'GENERAL_START' | translate }}</th>
        <th class="text-right">{{ 'GENERAL_STOP' | translate }}</th>
      </tr>
    </thead>
    <tbody *ngFor="let sessions of eventVersion.sessions | byDate : '$.startAt' | keyvalue">
      <tr class="subheader">
        <td colspan="12">{{ 'GENERAL_DAY' | translate }} {{ sessions.key || ('GENERAL_UNKNOWN' | translate) }}</td>
      </tr>
      <tr *ngFor="let session of sessions.value" [class.live]="isLive(session)">
        <td class="selected">{{ session.startAt | date : 'shortTime' }}</td>
        <td>{{ session.endAt | date : 'shortTime' }}</td>

        <td>
          <div *hasRight="{ resource: 'events:' + event._id, rights: 'eventsessions.edit' }">
            <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="editSession(session)">
              {{ session.actualStartAt | date : 'shortTime' }}
            </a>
          </div>
          <div *hasNoRight="{ resource: 'events:' + event._id, rights: 'eventsessions.edit' }">
            {{ session.actualStartAt | date : 'shortTime' }}
          </div>
        </td>
        <td>
          <div *hasRight="{ resource: 'events:' + event._id, rights: 'eventsessions.edit' }">
            <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="editSession(session)">
              {{ session.actualEndAt | date : 'shortTime' }}
            </a>
          </div>
          <div *hasNoRight="{ resource: 'events:' + event._id, rights: 'eventsessions.edit' }">
            {{ session.actualEndAt | date : 'shortTime' }}
          </div>
        </td>

        <td>
          <div *ngFor="let language of session.local | keyvalue">
            {{ session.local[language.key].title || ('GENERAL_UNNAMED' | translate) }}
          </div>
        </td>

        <td *hasRight="'chats.manage'">
          <div *ngFor="let language of session.local | keyvalue">
            <a *ngIf="language.value.chat" [routerLink]="['/live/', event._id, 'chats', language.value.chat]">{{ getChatName(chats[language.value.chat] | async) }}</a>
            <div *ngIf="!language.value.chat"></div>
          </div>
        </td>
        <td *hasRight="'chats.manage'">
          <div *ngFor="let language of session.local | keyvalue" [pTooltip]="'PAGE_LIVE_OVERVIEW_CHAT_INFO' | translate">
            {{ session.local[language.key].chat ? getChatInfo(chats[session.local[language.key].chat] | async) : '' }}
          </div>
        </td>

        <td class="text-right" *hasRight="['streams.list', 'streams.create', 'streams.edit', 'streams.delete']">
          <ng-container *ngIf="streams">
            <div *ngFor="let lang of toStreamSession(session).sessionMedia | keyvalue">
              <a *ngIf="toStreamId(lang)" [routerLink]="['/live/', event._id, 'streams', toStreamId(lang)]">{{ streams[toStreamId(lang)]?.internalName }}</a>
              <div *ngIf="!toStreamId(lang)"></div>
            </div>
          </ng-container>
        </td>

        <td class="text-right">
          {{ viewers[session._id]?.live || '-' }}
        </td>
        <td class="text-right">
          {{ viewers[session._id]?.demand || '-' }}
        </td>

        <td class="text-right">
          <i
            *hasRight="{ resource: 'events:' + event._id, rights: 'eventsessions.start' }"
            class="link pi icon-ic-start"
            [pTooltip]="'GENERAL_START' | translate"
            tooltipPosition="left"
            (click)="startSession(session); $event.stopPropagation()"
          ></i>
        </td>
        <td class="text-right">
          <i
            *hasRight="{ resource: 'events:' + event._id, rights: 'eventsessions.stop' }"
            class="link pi icon-ic-stop"
            [pTooltip]="'GENERAL_STOP' | translate"
            tooltipPosition="left"
            (click)="stopSession($event, session); $event.stopPropagation()"
          ></i>
          <p-confirmPopup></p-confirmPopup>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Registration Custom Field Edit Dialog -->
<p-sidebar *ngIf="showSessionEdit && session" [visible]="showSessionEdit" styleClass="p-sidebar-st" position="right" [modal]="true" [appendTo]="'body'" (onHide)="closeSessionDialog()">
  <c-sidebar-header [headerTitle]="session.startAt | date : 'fullDate'" [headerSubTitle]="session.local['en']?.title || session.local['de']?.title" [showCloseButton]="false"></c-sidebar-header>

  <div class="sidebar-body">
    <c-collaboration-patch-input [object]="session" jsonpath="$.actualStartAt" [inputs]="sessionsInputs"></c-collaboration-patch-input>
    <c-collaboration-patch-input [object]="session" jsonpath="$.actualEndAt" [inputs]="sessionsInputs"></c-collaboration-patch-input>
  </div>

  <c-sidebar-footer
    [showCancelButton]="true"
    (onCancel)="closeSessionDialog()"
    [showSaveButton]="true"
    [disableSaveButton]="!isSessionChanged"
    (onSave)="undoChanges = false; closeSessionDialog()"
  ></c-sidebar-footer>
</p-sidebar>

<!-- <c-event-version-select
  [(show)]="showVersions"
  [working]="loading"
  [event]="event"
  [eventVersion]="eventVersion"
  (eventVersionChange)="loadEventVersion($event._id)"
  [showPublish]="false"
></c-event-version-select>

<p-sidebar [(visible)]="showPreview" styleClass="p-sidebar-st" position="right" [modal]="true" [appendTo]="'body'" [showCloseIcon]="false">
  <c-sidebar-header
    [headerTitle]="'Preview Links'"
    (onClose)="showPreview = false"
  ></c-sidebar-header>

  <c-event-preview *ngIf="showPreview"
    [event]="event"
    [eventVersion]="eventVersion"
    (selected)="loadPreview($event._id);"
    mode="select"
  ></c-event-preview>
</p-sidebar> -->
