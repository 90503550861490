import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  AndCondition,
  Condition,
  ContainsCondition,
  EndsWithCondition,
  EqualCondition,
  EqualOrChildOfCondition,
  NotEqualCondition,
  OrCondition,
  StartsWithCondition,
} from 'src/common/entities/Condition';

@Component({
  selector: 'c-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.scss'],
})
export class ConditionComponent implements OnChanges {
  @Input()
  condition: Condition;

  andCondition: AndCondition;
  orCondition: OrCondition;
  endsWithCondition: EndsWithCondition;
  startsWithCondition: StartsWithCondition;
  containsCondition: ContainsCondition;
  equalCondition: EqualCondition;
  notEqualCondition: NotEqualCondition;
  equalOrChildOf: EqualOrChildOfCondition;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.condition) {
      this.andCondition = this.condition?.type === 'And' ? (this.condition as AndCondition) : null;
      this.orCondition = this.condition?.type === 'Or' ? (this.condition as OrCondition) : null;
      this.equalCondition = this.condition?.type === 'Equal' ? (this.condition as EqualCondition) : null;
      this.notEqualCondition = this.condition?.type === 'NotEqual' ? (this.condition as NotEqualCondition) : null;
      this.equalOrChildOf = this.condition?.type === 'EqualOrChildOf' ? (this.condition as EqualOrChildOfCondition) : null;
      this.endsWithCondition = this.condition?.type === 'EndsWith' ? (this.condition as EndsWithCondition) : null;
      this.startsWithCondition = this.condition?.type === 'StartsWith' ? (this.condition as StartsWithCondition) : null;
      this.containsCondition = this.condition?.type === 'Contains' ? (this.condition as ContainsCondition) : null;
    }
  }
}
