import { BookmarksPageModuleAttributes } from './../../entities/pagemodules/BookmarksPageModule';
import { HeadlineType } from './../../entities/Attributes';
import { BookmarksPageModule } from '../../entities/pagemodules/BookmarksPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class BookmarksPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public bookmarksPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): BookmarksPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public createBookmarksPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.bookmarksPageModuleAttributes(values?.title_attr),
    };
  }

  public async bookmarksPageModule(values: any): Promise<BookmarksPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'Bookmarks',
      local: await this.local(values, (val) => this.createBookmarksPageModuleLocal(val)),
      speakerDisplayType: values?.speakerDisplayType || 'list',
      bookmarkType: values?.bookmarkType || 'sessionbookmark'
    };
  }
}
