import {
  EmailFormNotification,
  Form,
  FormField,
  FormFieldLocal,
  FormLocal,
  FormNotification,
  isBooleanFormField,
  isEmailFormNotification,
  isNoneFormField,
  isSelectFormField,
  isTextareaFormField,
  isTextFormField,
  NoneFormField,
  SelectFormField,
  TextFormField,
} from '../entities/Form';
import { AbstractFactory, FactoryOptions, Values } from './AbstractFactory';

export class FormFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createForm(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      fields: await Promise.all((values?.fields || []).map((f: any) => this.createField(f))),
      local: Object.keys(values?.local || {}).reduce((a, b) => ({ ...a, [b]: this.createFormLocal(values.local[b]) }), {}),
      notifications: (values?.notifications || []).map((n: any) => this.createFormNotification(n)),
    } as Form;
  }

  createFormLocal(value?: any) {
    return {
      title: value?.title || '',
      ctaButtonText: value?.ctaButtonText || '',
      confirmationTitle: value?.confirmationTitle || '',
      confirmationText: value?.confirmationText || '',
      confirmationOk: value?.confirmationOk || '',
    } as FormLocal;
  }

  createFormNotification(value?: FormNotification & any) {
    const notification = {
      formNotificationType: 'Email',
    } as FormNotification;

    if (isEmailFormNotification(value)) {
      return {
        ...notification,
        recipients: value?.recipients || [],
        subject: value?.subject || '',
        language: value?.language || '',
        body: value?.body || '',
      } as EmailFormNotification;
    }

    throw new Error(`Invalid form notification type ${value.formNotificationType}`);
  }

  async createField(value: Values<FormField> & any) {
    const formField = {
      ...value,
      _id: await this.id(value?._id),
      formFieldType: value.formFieldType,
      internalName: value?.internalName || '',
      hidden: typeof value?.hidden === 'boolean' ? value.hidden : false,
      local: Object.keys(value?.local || {}).reduce((a: any, b: any) => ({ ...a, [b]: this.createFieldLocal(value.local[b]) }), {}),
    } as FormField;

    if (isTextFormField(value) || isTextareaFormField(value) || isBooleanFormField(value)) {
      return {
        ...formField,
        mandatory: typeof value?.mandatory === 'boolean' ? value.mandatory : false,
        default: value?.default,
      } as TextFormField;
    }
    if (isSelectFormField(value)) {
      return {
        ...formField,
        mandatory: typeof value?.mandatory === 'boolean' ? value.mandatory : false,
        default: value?.default,
        lookup: value?.lookup,
      } as SelectFormField;
    }
    if (isNoneFormField(value)) {
      return {
        ...formField,
      } as NoneFormField;
    }

    throw new Error(`Invalid field type ${value.formFieldType}`);
  }

  createFieldLocal(values?: any) {
    return {
      title: values?.title || '',
      description: values?.description || '',
    } as FormFieldLocal;
  }

  createEmailFormNotification(values?: any) {
    return {
      formNotificationType: 'Email',
      language: values?.language || 'en',
      recipients: values?.recipients || [],
      subject: values?.subject || '',
      body: values?.body || '',
    } as EmailFormNotification;
  }
}
