<ng-content select="[before]"></ng-content>
<div *ngFor="let jp of jsonpaths; trackBy: trackByIndex">
  <div *ngIf="!isListInput[jp]">
    <c-collaboration-patch-input
      [jsonpath]="jp"
      [jsonpathParams]="jsonpathParams"
      [collaborationKey]="collaborationKey"
      [inputs]="inputs"
      [object]="object"
      [disabled]="disabled || isDisabled"
    ></c-collaboration-patch-input>
  </div>
</div>
<div *ngFor="let p of listInputParents | keyvalue">
  <div *ngIf="show(p.key)">
    <div *ngFor="let e of p.value.element; trackBy: trackByIndexForElement; let index = index; let last = last; let first = first" class="element">
      <div *ngIf="p.value.singleValue">
        <c-collaboration-patch-input
          [jsonpath]="p.value.childJsonpath"
          [jsonpathParams]="jsonpathParamsWithIndex(jsonpathParams, index)"
          [collaborationKey]="collaborationKey"
          [inputs]="inputs"
          [object]="object"
          [disabled]="disabled || isDisabled"
        ></c-collaboration-patch-input>
      </div>
      <div *ngIf="!p.value.singleValue">
        <c-collaboration-patch-input
          *ngFor="let c of p.value.children"
          [jsonpath]="c"
          [jsonpathParams]="jsonpathParamsWithIndex(jsonpathParams, index)"
          [collaborationKey]="collaborationKey"
          [inputs]="inputs"
          [object]="object"
          [disabled]="disabled || isDisabled"
        ></c-collaboration-patch-input>
      </div>
      <button *ngIf="!disabled" (click)="remove(p.value.childJsonpath, index)">Remove</button>
      <button *ngIf="!disabled && !first" (click)="up(p.value.childJsonpath, index)">Up</button>
      <button *ngIf="!disabled && !last" (click)="down(p.value.childJsonpath, index)">Down</button>
    </div>
    <button *ngIf="!disabled" (click)="add(p.key)">Add</button>
  </div>
</div>
<ng-content select="[after]"></ng-content>
