<div class="content">
  <div class="tab-header">
    <div class="header-content">
      <i class="back pi pi-angle-left" (click)="back()"></i>
      <h1>
        {{ 'GENERAL_LIVE' | translate }}&nbsp;{{ 'GENERAL_EVENT' | translate }}&nbsp;<span class="subheader" *ngIf="loading">{{ 'GENERAL_LOADING' | translate }} ...</span>
        <span class="subheader" *ngIf="!loading">{{ event?.internalName }}</span>
        <span class="subheader" *ngIf="stream">:&nbsp;{{ stream.internalName }}</span>
      </h1>
    </div>
  </div>
  <div class="tab-menu">
    <p-button *ngIf="tabIndex === 1" (onClick)="refreshPostProduction()" label="Refresh" icon="pi icon-ic-refresh" [disabled]="loading"></p-button>
  </div>

  <div class="p-grid" *ngIf="stream && eventId">
    <div class="p-col">
      <p-tabView [(activeIndex)]="tabIndex">
        <p-tabPanel header="{{ 'PAGE_EVENT_SECTION_REACTIONS_HEADER' | translate }}">
          <div class="p-grid">
            <div class="p-col">
              <div class="p-col">
                <div style="display: flex">
                  <ng-lottie *ngFor="let animation of allQuickReactions" [options]="animation" width="50px" height="50px"></ng-lottie>
                </div>

                <div class="panel">
                  <div class="panel-header">
                    {{ 'PAGE_EVENT_SECTION_REACTIONS_HTML_OUTPUTS' | translate }}
                    <div class="actions">
                      <button pButton type="button" class="p-button" [label]="'GENERAL_ADD' | translate" icon="pi icon-ic-add-item" (click)="addReaction()"></button>
                      <c-table-options #to></c-table-options>
                    </div>
                  </div>

                  <c-table #table [data]="reactions" [options]="quickReactionsTableOptions" [tableOptionsComponent]="to">
                    <ng-template let-reaction>
                      <a [routerLink]="[]" queryParamsHandling="merge" (click)="openReactionDialog(reaction)">{{ reaction.internalName }}</a>
                    </ng-template>
                    <ng-template let-reaction><c-asset-preview [asset]="getAsset(reaction.background) | async"></c-asset-preview></ng-template>
                    <ng-template let-reaction><div [ngStyle]="{ 'background-color': reaction.backgroundColor }">&nbsp;</div></ng-template>
                    <ng-template let-reaction>{{ reaction.maxParallelConnections }}</ng-template>
                    <ng-template let-reaction>
                      <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="showReactionResult(reaction)">Show HTML Page</a>
                    </ng-template>
                  </c-table>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>

        <p-tabPanel header="{{ 'PAGE_EVENT_LIVE_STREAM_SECTION_POST_PRODUCTION' | translate }}">
          <app-live-stream-post-production #postProduction [eventId]="eventId" [streamId]="streamId"></app-live-stream-post-production>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>

<p-confirmPopup></p-confirmPopup>

<p-dialog *ngIf="htmlOutputUrl" [(visible)]="htmlOutputUrl" (onHide)="htmlOutputUrl = null" [modal]="true" [style]="{ width: '50vw', height: '50vw' }">
  <div class="device-container">
    <div class="device" [ngStyle]="deviceStyle">
      <iframe #frame class="preview-frame" [src]="htmlOutputUrl" [ngStyle]="frameStyle"></iframe>
    </div>
  </div>
</p-dialog>

<p-dialog
  *ngIf="editableReaction"
  [header]="'PAGE_EVENT_SECTION_REACTIONS_EDIT_REACTION' | translate"
  [(visible)]="showReactionDialog"
  (onHide)="closeReactionDialog()"
  [modal]="true"
  [style]="{ width: '50vw' }"
>
  <div>
    <c-basic-patch-input jsonpath="$.internalName" [object]="editableReaction" [inputs]="reactionsInputs"></c-basic-patch-input>

    <c-basic-patch-input jsonpath="$.background" [object]="editableReaction" [inputs]="reactionsInputs"></c-basic-patch-input>
    <c-basic-patch-input jsonpath="$.backgroundColor" [object]="editableReaction" [inputs]="reactionsInputs"></c-basic-patch-input>

    <c-basic-patch-input jsonpath="$.maxParallelConnections" [object]="editableReaction" [inputs]="reactionsInputs"></c-basic-patch-input>
  </div>

  <!-- Dialog Footer with Save Button -->
  <p-footer>
    <p-button
      [label]="'GENERAL_SAVE' | translate"
      icon="pi pi-check"
      styleClass="p-button-sm"
      (click)="undoChanges = false; closeReactionDialog()"
      [disabled]="!isValidReaction(editableReaction) || !isReactionChanged"
    ></p-button>
  </p-footer>
</p-dialog>
