import { BreakpointValue } from '../../entities/Breakpoint';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';
import { ExploreStagePageModule } from '../../entities/pagemodules/ExploreStagePageModule';
import { StageHeightDefaultBreakpointValue } from './DefaultBreakponitValue';

export class ExploreStagePageModuleFactory extends BasePageModuleFactory {
  exploreStagePageModuleLocal: any;
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public createExploreStagePageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      buttonLabel: values?.buttonLabel || ''
    };
  }

  public exploreStagePageModuleBreakpoint(values?: any) {
    return {
      desktop: values?.desktop || null,
      desktopLarge: values?.desktopLarge || null,
      desktopWidescreen: values?.desktopWidescreen || null,
      tablet: values?.tablet || null,
      tabletLarge: values?.tabletLarge || null,
      mobile: values?.mobile || null,
      mobileLarge: values?.mobileLarge || null
    };
  }

  public exploreStagePageModuleStageHeight(defaultValues: BreakpointValue<number>, values?: any): BreakpointValue<number> {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge
    };
  }

  public async exploreStagePageModule(values: any): Promise<ExploreStagePageModule> {
    const isMongoId = (str: string): boolean => str?.length === 24 && /^[A-F0-9]+$/i.test(str);
    const processImage = (value: string) => {
      if (isMongoId(value)) {
        return this.exploreStagePageModuleBreakpoint({ desktop: value, mobile: null, tablet: null });
      } else {
        return this.exploreStagePageModuleBreakpoint(value);
      }
    };
    const backgroundImage = processImage(values?.backgroundImage);
    const video = processImage(values?.video);
    const sliderBackgroundImage = processImage(values?.sliderBackgroundImage);

    return {
      ...(await this.baseValues(values)),
      video: video,
      backgroundImage: backgroundImage,
      sliderBackgroundImage: sliderBackgroundImage,
      pageModuleType: 'ExploreStage',
      local: await this.local(values, val => this.createExploreStagePageModuleLocal(val)),
      products: values?.products || [],
      buttonPositionTop: values?.buttonPositionTop || 50,
      buttonPositionLeft: values?.buttonPositionLeft || 50,
      stageHeight: values?.stageHeight ? values.stageHeight : this.exploreStagePageModuleStageHeight(StageHeightDefaultBreakpointValue, values?.stageHeight)
    };
  }
}
