import { ShareCTATarget } from '../CTAButton';
import { SpeakerDisplayType, ViewType } from './AgendaPageModule';
import { PageModule } from './PageModule';

export const gridTypes = ['3grid', '2grid', '2grid_without_agenda'] as const;
export type GridType = (typeof gridTypes)[number];

export const initGrid = ['agenda', 'middle', 'chat'] as const;
export type InitGrid = (typeof initGrid)[number];

export interface GridPageModule extends PageModule {
  pageModuleType: 'Grid';
  initGrid?: InitGrid;
  event?: string;
  gridType?: GridType;
  viewType?: ViewType;
  speakerDisplayType?: SpeakerDisplayType;
  showFilterOptions?: boolean;
  showAgendaCalendarButton?: boolean;
  showAgendaShareButton?: boolean;
  agendaShareButtonTargets?: ShareCTATarget[];
  showCurrentTime?: boolean;
  showBookmarkButton?: boolean;
  showDescription?: boolean;
  showTime?: boolean;
  showCalendarButton?: boolean;
  showShareButton?: boolean;
  shareButtonTargets?: ShareCTATarget[];
  showRelatedDocuments?: boolean;
  showReactions?: boolean;
  parallelReactions?: number;
  sticky?: boolean;
  chatWithoutLogin?: boolean;
  showPolls?: boolean;
  showCountdown?: boolean;
  showStickyPlayerOnMobile?: boolean;
  showMapButton?: boolean;
}

export function isGridPageModule(object: any): object is GridPageModule {
  return object?.pageModuleType === 'Grid';
}
