import { TextAttributes } from './../Attributes';
import { CTAButton } from '../CTAButton';
import { BackgroundTheme, PageModule, TitlePositions } from './PageModule';
import { SortDirection } from '../Sorting';
import { Asset } from '../Asset';
import { Query } from '../Query';
import { AccessPolicy } from '../AccessPolicy';

export const VideoTeaserCollectionTypes = ['Default', 'Keytopic', 'KeytopicAllEvents', 'Highlights', 'Query', 'QueryFromURL'] as const;
export type VideoTeaserCollectionType = (typeof VideoTeaserCollectionTypes)[number];

export const alignmentTypes = ['center', 'left'] as const;
export type AlignmentType = (typeof alignmentTypes)[number];

export const VideoTeaserCollectionDisplayTypes = ['slider', 'grid'] as const;
export type VideoTeaserCollectionDisplayType = (typeof VideoTeaserCollectionDisplayTypes)[number];

export const VideoTeaserCollectionLayoutVariationTypes = [1, 2, 3] as const;
export type VideoTeaserCollectionLayoutVariationType = (typeof VideoTeaserCollectionLayoutVariationTypes)[number];

export const VideoTeaserCollectionSortingFieldTypes = ['uploadDate', 'publishDate', 'viewAmount'] as const;
export type VideoTeaserCollectionSortingFieldType = (typeof VideoTeaserCollectionSortingFieldTypes)[number];

export const VideoTeaserCollectionNavigationTypes = ['pagination', 'loadMore'] as const;
export type VideoTeaserCollectionNavigationType = (typeof VideoTeaserCollectionNavigationTypes)[number];

export interface VideoGridSettings {
  showAssetTags?: boolean;
  showLanguageTags?: boolean;
  showEventtypeTags?: boolean;
  showDate?: boolean;
}

export interface VideoTeaserCollectionSettings extends VideoGridSettings {
  teaserLayoutVariation: VideoTeaserCollectionLayoutVariationType;
  showVideoTitle?: boolean;
  showVideoStatusFlag?: boolean;
  showProgressBar?: boolean;
  showPlayButton?: boolean;
  navigationType?: VideoTeaserCollectionNavigationType;
  pagination: number;
  loadMoreInitialAmount?: number;
  loadMoreAmount?: number;
  backgroundTheme?: BackgroundTheme;
  accessPolicy?: AccessPolicy[];
  paddingTop?: boolean;
  paddingBottom?: boolean;
  local: {
    [language: string]: {
      loadMoreButtonLabel?: string;
    };
  };
}

export interface VideoTeaserCollectionPageModuleLocal {
  title: string;
  title_attr?: VideoTeaserCollectionPageModuleAttributes;
  videos: string[];
  discoverMoreTitle?: string;
  loadMoreButtonLabel?: string;
}

export interface VideoTeaserCollectionPageModule extends PageModule, VideoTeaserCollectionSettings {
  pageModuleType: 'VideoTeaserCollection';
  titlePosition?: TitlePositions;
  displayAs: VideoTeaserCollectionDisplayType;
  showHighlightSessions?: boolean;
  keytopics?: string[];
  /** @deprecated Use `keytopics`. */
  keytopic?: string;
  event?: string;
  VideoTeaserCollectionType: VideoTeaserCollectionType;
  local: { [language: string]: VideoTeaserCollectionPageModuleLocal };
  ctaButton?: CTAButton;
  discoverMoreBackgroundImage?: Asset;
  discoverMoreIcon?: Asset;
  discoverMoreCta?: CTAButton;
  maxCount?: number;
  showInProgressVideos?: boolean;
  showViewedVideos?: boolean;
  showNewVideos?: boolean;
  showSliderPagination?: boolean;
  showResultLabel?: boolean;
  languages?: string[];
  teaserAlignment?: AlignmentType;
  showDiscoverMoreLast?: boolean;
  defaultSortingField?: VideoTeaserCollectionSortingFieldType;
  defaultSortingDirection?: SortDirection;
  sortingOptions?: VideoTeaserCollectionSortingOption[];
  query?: Query;
}

export interface VideoTeaserCollectionSortingOption {
  _id: string;
  sortingField?: VideoTeaserCollectionSortingFieldType;
  sortingDirection?: SortDirection;
  local: {
    [language: string]: {
      title?: string;
    };
  };
}

export type VideoTeaserCollectionPageModuleAttributes = TextAttributes & {};

export function isVideoTeaserCollectionPageModule(object: any): object is VideoTeaserCollectionPageModule {
  return object?.pageModuleType === 'VideoTeaserCollection';
}
