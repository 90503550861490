import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';

import { Lookup } from 'src/common/entities/Lookup';
import { GetLookupsQuery, GetLookupsResponse } from 'src/common/api/v1/configuration/lookups/GetLookups';
import { GetLookupQuery, GetLookupResponse } from 'src/common/api/v1/configuration/lookups/GetLookup';
import { PostLookupBody, PostLookupQuery, PostLookupResponse } from 'src/common/api/v1/configuration/lookups/PostLookup';

@Injectable({
  providedIn: 'root',
})
export class LookupsService {
  constructor(private apiService: ApiService) {}

  async createLookup(lookup: Lookup): Promise<Lookup> {
    return this.apiService.post<PostLookupQuery, PostLookupBody, PostLookupResponse>(`/api/v1/configuration/lookups/`, lookup).toPromise();
  }
  async getLookups(query?: GetLookupsQuery): Promise<GetLookupsResponse> {
    return await this.apiService.get<GetLookupsQuery, GetLookupsResponse>('/api/v1/configuration/lookups', query).toPromise();
  }

  getLookup(lookupId: string): Promise<Lookup> {
    return this.apiService.get<GetLookupQuery, GetLookupResponse>(`/api/v1/configuration/lookups/${lookupId}`).toPromise();
  }

  updateLookup(lookup: Lookup): Promise<Lookup> {
    return this.apiService.post<PostLookupQuery, PostLookupBody, PostLookupResponse>(`/api/v1/configuration/lookups/${lookup._id}`, lookup).toPromise();
  }
}
