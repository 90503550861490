import { Component, EventEmitter, Output } from '@angular/core';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { FilterMatchMode, filterMatchModes, IFilterList } from 'src/common/api/interfaces';
import { defaultMatchModes, TableColumnSettings, TableFilter, TableOptions } from '../table.interfaces';

type IFilterConditionEdit = {
  value?: string | number | boolean | string[] | Date;
  matchMode: FilterMatchMode;
};

type IFilterListEdit = { [filterKey: string]: IFilterConditionEdit };

@Component({
  selector: 'c-table-options',
  templateUrl: './table-options.component.html',
  styleUrls: ['./table-options.component.scss'],
})
export class TableOptionsComponent<T> {
  _tableOptions: TableOptions<T>;
  _tableColumnSettings: TableColumnSettings;
  _tableColumnSettingsEdit: TableColumnSettings;
  _filterList: IFilterList;
  _filterListEdit: IFilterListEdit;
  _filterValues: { [path: string]: { label: string; value: string }[] } = {};
  _matchModeOptions: { [path: string]: { label: string; value: FilterMatchMode }[] } = {};

  _booleanDropDownOptions: { label: string; value: any }[] = [
    { label: 'Any', value: null },
    { label: 'True', value: true },
    { label: 'False', value: false },
  ];

  @Output()
  tableColumnSettingsChange: EventEmitter<TableColumnSettings> = new EventEmitter<TableColumnSettings>();

  @Output()
  filterListChange: EventEmitter<IFilterList> = new EventEmitter<IFilterList>();

  filterMatchModes = [...filterMatchModes];

  show = false;

  constructor(private utilsService: UtilsService) {}

  async init(tableOptions: TableOptions<T>, tableColumnSettings: TableColumnSettings, filterList: IFilterList) {
    this._tableOptions = tableOptions;
    this._tableColumnSettings = tableColumnSettings;
    this._filterList = filterList;
    this._filterValues = {};
    this.filterListChange.emit(this._filterList);

    for (const filterWithValues of (tableOptions.filters || []).filter((f) => f.values)) {
      const values = await Promise.resolve(filterWithValues.values);

      this._filterValues[filterWithValues.path] = [{ label: 'Any', value: null }, ...values];
    }
  }

  async showFilters() {
    this.show = true;
    this._tableColumnSettingsEdit = null;
    this._filterListEdit = JSON.parse(JSON.stringify(this._filterList));
    const translations = await this.utilsService.translateAll(this.filterMatchModes.map((f) => `GENERAL_MATCHMODE_${f.toUpperCase()}`));

    for (const filter of this._tableOptions.filters || []) {
      const matchModes = filter.matchModes || defaultMatchModes[filter.type || 'string'];
      this._matchModeOptions[filter.path] = matchModes.map((m) => ({
        value: m,
        label: translations[`GENERAL_MATCHMODE_${m.toUpperCase()}`],
      }));

      this._filterListEdit[filter.path] = {
        matchMode: this._filterListEdit[filter.path]?.matchMode ? this._filterListEdit[filter.path].matchMode : matchModes[0],
        value: filter.type === 'date' && this._filterListEdit[filter.path]?.value ? new Date(this._filterListEdit[filter.path].value as string) : this._filterListEdit[filter.path]?.value,
      };
    }
  }

  showColumns() {
    this.show = true;
    this._tableColumnSettingsEdit = JSON.parse(JSON.stringify(this._tableColumnSettings));
    this._filterListEdit = null;
  }

  saveColumnSettings() {
    this._tableColumnSettings = this._tableColumnSettingsEdit;
    this.tableColumnSettingsChange.emit(this._tableColumnSettings);
    this.show = false;
  }

  filterChange(filter: TableFilter, change: { matchMode?: any; value?: any }) {
    this._filterListEdit[filter.path] = {
      matchMode: change.matchMode || this._filterListEdit[filter.path]?.matchMode,
      value: typeof change.value !== 'undefined' ? change.value : this._filterListEdit[filter.path]?.value,
    };
  }

  applyFilter() {
    for (const path of Object.keys(this._filterListEdit)) {
      const filter = this._tableOptions.filters.find((f) => f.path === path);

      if (filter) {
        if (filter.type !== 'boolean') {
          if (!this._filterListEdit[path].value) {
            delete this._filterListEdit[path];
          }
        } else if (typeof this._filterListEdit[path].value !== 'boolean') {
          delete this._filterListEdit[path];
        }
      }
    }

    this._filterList = {};

    for (const filter of this._tableOptions.filters || []) {
      if (this._filterListEdit[filter.path]) {
        this._filterList[filter.path] = {
          caseInsensitive: true,
          matchMode: this._filterListEdit[filter.path].matchMode,
          value: this._filterListEdit[filter.path].value as string | number | boolean | string[],
        };
      }
    }

    this.filterListChange.emit(this._filterList);
    this.show = false;
  }
}
