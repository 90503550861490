import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { Poll, PollOption, PollOptionLocal } from '../entities/Poll';

export class PollFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createPoll(values?: any) {
    return {
      _id: await this.id(values?._id),
      minSelections: values?.minSelections || 1,
      maxSelections: values?.maxSelections || null,
      internalName: values?.internalName || '',
      local: this.localKeys(values?.local).reduce((a, b) => ({ ...a, [b]: this.createPollLocal(values.local[b]) }), {}),
      options: await Promise.all((values?.options || []).map((o: any) => this.createPollOption(o))),
      theme: values?.theme ? values?.theme : await this.createPollTheme(values?.theme)
    } as Poll;
  }

  async createPollTheme(values?: any) {
    return {
      _id: await this.id(values?._id),
      background: values?.background || null,
      font: values?.font || 'NettoWebPro',
      fontcolor: values?.fontcolor || '',
      leadingBarBackgroundColor: values?.leadingBarBackgroundColor || '',
      leadingBarFontColor: values?.leadingBarFontColor || '',
      barBackgroundColor: values?.barBackgroundColor || '',
      barFontColor: values?.barFontColor || '',
      showVotesInBar: typeof values?.showVotesInBar === 'boolean' ? values?.showVotesInBar : true,
      showVotesTotal: typeof values?.showVotesTotal === 'boolean' ? values?.showVotesTotal : true
    };
  }

  public async createPollOption(values?: any): Promise<PollOption> {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      local: await this.local(values, val => this.createPollOptionLocal(val))
    };
  }

  createPollLocal(values?: any) {
    return {
      title: values?.title || '',
      description: values?.description || ''
    };
  }

  public createPollOptionLocal(values?: any): PollOptionLocal {
    return {
      label: values?.label || '',
      description: values?.description || ''
    };
  }
}
