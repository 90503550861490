import { Component, OnInit } from '@angular/core';
import { StreamsService } from 'src/app/services/streams/streams.service';
import { isLiveSessionMedia, isSessionWithSessionMedia } from 'src/common/entities/Session';
import { StreamWithSessions } from 'src/common/entities/Stream';
import { Event } from 'src/common/entities/Event';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth/auth.service';

interface RelatedSession {
  sessionId: string;
  sessionTitle: string;
  eventId: string;
  eventTitle: string;
  startAt: Date;
  endAt: Date;
  actualStartAt: Date;
  actualEndAt: Date;
  language: string;
}

@Component({
  selector: 'app-streams',
  templateUrl: './streams.component.html',
  styleUrls: ['./streams.component.scss'],
})
export class StreamsComponent implements OnInit {
  loading = true;

  streams: StreamWithSessions[] = [];
  stream: StreamWithSessions | null = null;
  hasReadRights: boolean = false;
  hasEditRights: boolean = false;

  private _relatedSessions: { [stream: string]: RelatedSession[] } = {};

  get showStream(): boolean {
    return !!this.stream;
  }
  set showStream(val: boolean) {
    if (!val) {
      this.stream = null;
    }
  }

  constructor(private authService: AuthService, private streamsService: StreamsService, private messageService: MessageService) {}

  async ngOnInit() {
    this.hasReadRights = await this.authService.hasRight('streams.read');
    this.hasEditRights = await this.authService.hasRight('streams.edit');
    this.streams = (await this.streamsService.getStreams()).items;
    this.loading = false;
  }

  // executeStreamOperation is passed as a callback, keep it as arrow function to keep `this` context
  executeStreamOperation = async (streamPromise: Promise<StreamWithSessions>) => {
    this.loading = true;

    const newStream = await streamPromise;

    if (this.stream) {
      delete this._relatedSessions[this.stream._id];
      delete this._relatedSessions[newStream._id];

      if (this.stream._id === newStream._id) {
        this.stream = newStream;
      }

      const streamIndex = this.streams.findIndex((s) => s._id === this.stream._id) || -1;

      if (this.stream.deletedAt) {
        if (streamIndex >= 0) {
          this.streams.splice(streamIndex, 1);
        }
        this.stream = null;
      } else if (streamIndex >= 0) {
        Object.assign(this.streams[streamIndex], this.stream);
      } else {
        this.streams = (await this.streamsService.getStreams()).items;
      }
    }

    this.loading = false;

    return newStream;
  };

  async createStream(): Promise<void> {
    if (this.streams?.length >= 5) {
      this.messageService.add({
        severity: 'error',
        summary: 'Maximum number of parallel streams reached',
        detail: `It is only possible to have 5 Streams currently. Please delete an existing Stream before creating a new one.`,
      });
    } else {
      this.stream = await this.executeStreamOperation(this.streamsService.createStream({ internalName: 'New Stream' }));
    }
  }

  async openStream(streamId: string) {
    if (!this.hasReadRights && !this.hasEditRights) return;
    this.stream = await this.executeStreamOperation(this.streamsService.getStream(streamId));
  }

  trackById(_index: number, item: { _id: string }) {
    return item._id;
  }

  relatedSessions(stream: StreamWithSessions): RelatedSession[] {
    if (!this._relatedSessions[stream._id]) {
      this._relatedSessions[stream._id] = [];

      for (const session of stream.sessions) {
        if (isSessionWithSessionMedia(session)) {
          const languages = Object.keys(session.sessionMedia);
          for (const language of languages) {
            const sessionMedia = session.sessionMedia[language];
            if (isLiveSessionMedia(sessionMedia) && sessionMedia.stream === stream._id) {
              this._relatedSessions[stream._id].push({
                sessionId: session._id,
                sessionTitle: session.local[language]?.title || 'Untitled',
                eventId: (session.event as unknown as Event)._id,
                eventTitle: (session.event as unknown as Event).internalName,
                startAt: new Date(session.startAt),
                endAt: new Date(session.endAt),
                actualStartAt: new Date(session.actualStartAt),
                actualEndAt: new Date(session.actualEndAt),
                language: language,
              });
            }
          }
        }
      }
    }
    return this._relatedSessions[stream._id];
  }
}
