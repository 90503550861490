import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { StreamEventStagePageModuleFactory } from '../../factories/pagemodules/StreamEventStagePageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const streamEventStagePageModuleInputs: Inputs = {
  ...commonPageModuleInputs('StreamEventStage'),
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].ctalist": {
    factory: async () => [],
    childFactory: async () => new StreamEventStagePageModuleFactory().streamEventStagePageModuleButton({}),
    list: true,
    listKey: '_id',
    header: 'CTA Button',
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].ctalist[$index].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].ctalistInteraction": {
    factory: async () => [],
    childFactory: async () => new StreamEventStagePageModuleFactory().streamEventStagePageModuleButton({}),
    list: true,
    listKey: '_id',
    header: 'CTA Button',
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].ctalistInteraction[$index].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].local.$language.background": {},
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new StreamEventStagePageModuleFactory().streamEventStagePageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].local.$language.smallHeadline": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].local.$language.subtext": {},
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].discoverMore": {
    header: 'Show Discover More',
    type: 'switch',
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].stageHeight": {
    factory: async () => new StreamEventStagePageModuleFactory().streamEventStagePageModuleStageHeight({}),
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].stageHeight.desktop": {
    type: 'number',
    header: 'Stage height desktop',
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].stageHeight.desktopLarge": {
    type: 'number',
    header: 'Stage height desktop large',
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].stageHeight.desktopWidescreen": {
    type: 'number',
    header: 'Stage height desktop widescreen',
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].stageHeight.tablet": {
    type: 'number',
    header: 'Stage height tablet',
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].stageHeight.tabletLarge": {
    type: 'number',
    header: 'Stage height tablet large',
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].stageHeight.mobile": {
    header: 'Stage height mobile',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].stageHeight.mobileLarge": {
    header: 'Stage height mobile large',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint": {
    factory: async () => new StreamEventStagePageModuleFactory().streamEventStagePageModuleButtonSpacingTopBreakpoint({}),
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.desktop": {
    header: 'Button spacing top desktop',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.desktopLarge": {
    header: 'Button spacing top desktop large',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.desktopWidescreen": {
    header: 'Button spacing top desktop widescreen',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.tablet": {
    header: 'Button spacing top tablet',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.tabletLarge": {
    header: 'Button spacing top tablet large',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.mobile": {
    header: 'Button spacing top mobile',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='StreamEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.mobileLarge": {
    header: 'Button spacing top mobile large',
    type: 'number',
  },
};
