import { Pipe, PipeTransform } from '@angular/core';
import { RegionsService } from 'src/app/services/regions/regions.service';
import { Region } from 'src/common/entities/Region';

@Pipe({
  name: 'region',
})
export class RegionPipe implements PipeTransform {
  constructor(private regionsService: RegionsService) {}

  transform(region: string, ...args: unknown[]): Promise<Region> {
    return this.regionsService.getRegion(region);
  }
}
