import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';
import 'moment-timezone';

import { RegionsService } from 'src/app/services/regions/regions.service';
import { Region } from 'src/common/entities/Region';

@Component({
  selector: 'app-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss'],
})
export class RegionsComponent implements OnInit {
  regions: Region[] = [];

  constructor(private regionsService: RegionsService) {}

  ngOnInit(): void {
    this._retrieveData();
  }

  private async _retrieveData() {
    try {
      const result = await this.regionsService.getRegions();
      this.regions = result.items;

      // Add UTC offset to timeZone
      this.regions.forEach((region) => (region.timeZone = region.timeZone + ' ' + this.getCurrentUtcOffset(region.timeZone)));
    } catch (err) {}
  }

  private getCurrentUtcOffset(timeZone: string): string {
    if (!timeZone) return '';

    const utcOffset: number = moment().tz(timeZone).utcOffset();
    const hours: number = Math.abs(Math.trunc(utcOffset / 60));
    const hoursTxt: string = String('0').repeat(2 - hours.toString().length) + hours.toString();
    const minutes: number = Math.abs(utcOffset - Math.trunc(utcOffset / 60) * 60);
    const minutesTxt: string = String('0').repeat(2 - minutes.toString().length) + minutes.toString();
    const utcOffsetTxt: string = (utcOffset >= 0 ? '+' : '-') + hoursTxt + ':' + minutesTxt;
    return '(UTC' + utcOffsetTxt + ')';
  }
}
