import { TextAttributes } from './../Attributes';
import { CTAButtonType } from './../CTAButton';
import { PageModule, TitlePositions } from './PageModule';
import { CTAButton } from '../CTAButton';

export const tileSizes = ['normal', 'small'] as const;
export type TileSize = (typeof tileSizes)[number];

export const displayAs = ['imageWithTitle', 'titleOnly'] as const;
export type TilesDisplayType = (typeof displayAs)[number];

export interface TilesPageModule extends PageModule {
  pageModuleType: 'Tiles';
  titlePosition?: TitlePositions;
  displayAs?: TilesDisplayType;
  tileSize?: TileSize;
  showTitleBox: boolean;
  local: {
    [language: string]: {
      title: string;
      title_attr?: TilesPageModuleAttributes;
      items: TilesPageModuleItem[];
    };
  };
  ctaButton?: CTAButton;
}

export interface TilesPageModuleItem {
  image: string;
  title: string;
  linkType: CTAButtonType;
  link?: string;
}

export type TilesPageModuleAttributes = TextAttributes & {};

export function isTilesPageModule(object: any): object is TilesPageModule {
  return object?.pageModuleType === 'Tiles';
}
