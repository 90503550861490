import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { EmbeddedPagesComponent } from './embedded-pages.component';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ComponentsModule } from '../../components/components.module';
import { DialogModule } from 'primeng/dialog';
import { DirectivesModule } from '../../directives/directives.module';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { PipesModule } from '../../pipes/pipes.module';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SharedModule } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [{ path: '', component: EmbeddedPagesComponent }];

@NgModule({
  declarations: [EmbeddedPagesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ButtonModule,
    CheckboxModule,
    ComponentsModule,
    DialogModule,
    DirectivesModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    MessageModule,
    PipesModule,
    RadioButtonModule,
    SharedModule,
    SidebarModule,
    TranslateModule,
  ],
  exports: [RouterModule],
})
export class EmbeddedPagesModule {}
