import { PageModuleFactory } from './../../common/factories/pagemodules/PageModuleFactory';
import { pageInputs } from 'src/common/inputs/page/PageInputs';
import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
import { Subscription } from 'rxjs';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { isPublicAccessPolicy } from 'src/common/entities/AccessPolicy';
import { EventPhase } from 'src/common/entities/Event';
import { EventStage, EventStageType } from 'src/common/entities/EventStage';
import { isPageWithEventStage, isPageWithPageModules, Page, PageWithPageModules } from 'src/common/entities/Page';
import { PageModule, PageModuleType } from 'src/common/entities/PageModule';
import { EventStageFactory } from 'src/common/factories/EventStageFactory';
import { Device, devices, ViewMode, viewModels } from './page-layout-editor/page-layout-editor.devices';
import { LayoutContext, SelectedUser, SiteStructureTab, SiteStructureTabs } from './layout-editor';
import { ConfirmationService, MessageService } from 'primeng/api';
import { eventVersionInputs } from 'src/common/inputs/event/EventVersionInputs';
import { WindowMessageService } from '../services/window-message/window-message.service';
import { PageModuleItem } from '../components/page-module-type-select/page-module-type-select.component';
import { CustomPageModuleFactory } from 'src/common/factories/pagemodules/CustomPageModuleFactory';
import { isCustomPageModule } from 'src/common/entities/pagemodules/CustomPageModule';
import { IPlugin } from 'src/common/plugin/IPlugin';

@Component({
  selector: 'c-layout-editor',
  templateUrl: './layout-editor.component.html',
  styleUrls: ['./layout-editor.component.scss'],
})
export class LayoutEditorComponent implements OnInit, OnDestroy {
  get page(): Page {
    return this.context.pageVersion || this.context.page;
  }

  get languages(): string[] {
    return this.context.eventVersion?.languages || this.page?.languages || [];
  }

  get showUserSelect(): boolean {
    return this._showUserSelect;
  }

  set showUserSelect(val: boolean) {
    if (val) {
      this.selectedUserEdit = JSON.parse(JSON.stringify(this.selectedUser));
      this._showUserSelect = true;
    } else {
      this._showUserSelect = false;
      this.selectedUserEdit = null;
    }
  }

  get selectedUserEditMode(): 'public' | 'platform' | 'eventticket' | 'ignoreConditions' {
    if (this.selectedUserEdit.ignoreConditions) {
      return 'ignoreConditions';
    }
    if (!this.selectedUserEdit.loggedIn) {
      return 'public';
    }
    if (this.selectedUserEdit.loggedIn && !this.selectedUserEdit.eventTickets) {
      return 'platform';
    }
    return 'eventticket';
  }
  set selectedUserEditMode(val: 'public' | 'platform' | 'eventticket' | 'ignoreConditions') {
    this.selectedUserEdit = {
      loggedIn: val !== 'public',
      ignoreConditions: val === 'ignoreConditions',
      eventTickets: val === 'eventticket' ? [] : null,
    };
  }

  get selectedUserLabel(): string {
    if (this.selectedUser.ignoreConditions) {
      return 'IGNORE';
    }
    if (!this.selectedUser.loggedIn) {
      return 'None';
    }
    if (this.selectedUser.loggedIn && !this.selectedUser.eventTickets) {
      return 'Registered on platform';
    }
    return `With event ticket (${this.tickets
      .filter((t) => (this.selectedUser.eventTickets || []).includes(t.value))
      .map((t) => t.label)
      .join(', ')})`;
  }

  get tickets(): { label: string; value: string }[] {
    return (this.context?.eventVersion?.eventTickets || []).map((t) => ({
      label: t.internalName,
      value: t._id,
    }));
  }
  get showEditEventStage(): boolean {
    return this.editEventStage !== null;
  }
  set showEditEventStage(val: boolean) {
    if (!val) {
      this.editEventStage = null;
    }
  }
  get showEditPageModule(): boolean {
    return this.editPageModule !== null;
  }
  set showEditPageModule(val: boolean) {
    if (!val) {
      this.editPageModule = null;
    }
  }
  get showInsertPageModule(): boolean {
    return this.insertPageModule !== null;
  }
  set showInsertPageModule(val: boolean) {
    if (!val) {
      this.insertPageModule = null;
    }
  }
  get showAddEventStage(): boolean {
    return this.addEventStage !== null;
  }
  set showAddEventStage(val: boolean) {
    if (!val) {
      this.addEventStage = null;
    }
  }

  get jsonpathParams(): any {
    return { eventPhase: this.context?.eventPhase, pageId: this.context?.page?._id };
  }

  get collaborationKey(): string {
    if (this.context?.eventVersion) {
      return `eventversion:${this.context?.eventVersion._id}`;
    } else {
      return `pageversion:${this.context?.pageVersion._id}`;
    }
  }

  constructor(
    private utilsService: UtilsService,
    private collaborationService: CollaborationService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private windowMessageService: WindowMessageService,
    private router: Router
  ) {
    const deviceIndex = localStorage.getItem('deviceIndex');

    if (deviceIndex && parseInt(deviceIndex, 10) < devices.length) {
      this.currentDevice = devices[deviceIndex];
    }

    const viewModeIndex = localStorage.getItem('viewModeIndex');

    if (viewModeIndex && parseInt(viewModeIndex, 10) < viewModels.length) {
      this.currentViewMode = viewModels[viewModeIndex];
    }
  }

  public static attachedListenerHandler = null;

  @Input()
  context: LayoutContext = {};

  @Output()
  contextChange: EventEmitter<LayoutContext> = new EventEmitter<LayoutContext>();

  @Input()
  pageJsonpath = "$.phase.$eventPhase.pages[?(@._id=='$pageId')]";

  @Input()
  language: string;

  @Input()
  enableProperties: boolean = true;

  @Input()
  message?: { severity: string; text: string } = null;

  @HostBinding('class.fullscreen')
  fullscreen = false;

  @ViewChild('NavSideBar')
  navbar;

  _showUserSelect = false;
  selectedUser: SelectedUser = {
    loggedIn: true,
    ignoreConditions: true,
    eventTickets: [],
  };
  selectedUserEdit: SelectedUser | null = null;

  allDevices = [...devices];
  allViewModels = [...viewModels];
  currentDevice: Device = devices[0];
  currentViewMode: ViewMode = viewModels[0];
  headerMenuItems: MenuItem[] = [];
  showPages = false;
  showSiteStructure = false;
  editGlobalPageProperties = false;
  contextLeft = false;

  activeSiteStructureTab: SiteStructureTab = 'mobileMenuItems';
  siteStructureTabs: SiteStructureTabs = [
    { key: 'mobileMenuItems', label: 'Mobile Quick Access' },
    { key: 'appMenuItems', label: 'App Navigation' },
    { key: 'webMenuItems', label: 'Web Menu' },
    { key: 'appNavigationMenuItems', label: 'App Menu' },
  ];

  subscriptions: Subscription[] = [];
  routerSubscrription: Subscription;
  currentLanguage = 'en';

  editEventStage: EventStage = null;
  editPageModule: PageModule = null;

  insertPageModule: {
    index: number;
    type?: PageModuleType;
  } | null = null;

  addEventStage: {
    type?: EventStageType;
  } | null = null;

  hasConditions(): boolean {
    const pageWithPageModules = isPageWithPageModules(this.page) ? this.page : null;

    if (!pageWithPageModules) {
      return false;
    }

    return pageWithPageModules.pageModules?.some((pageModule) => pageModule.accessPolicy?.some((a) => !isPublicAccessPolicy(a)));
  }

  private listenIframe(): void {
    const listeningMethodName = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const removingMethodName = window.addEventListener ? 'removeEventListener' : 'detachEvent';
    const eventListeningMethod = window[listeningMethodName];
    const eventRemovingMethod = window[removingMethodName];

    const messageEvent = eventListeningMethod === 'attachEvent' ? 'onmessage' : 'message';

    if (LayoutEditorComponent.attachedListenerHandler) {
      eventRemovingMethod(messageEvent, LayoutEditorComponent.attachedListenerHandler);
    }

    LayoutEditorComponent.attachedListenerHandler = this.onMessage;

    // Listen to message from child IFrame window
    eventListeningMethod(messageEvent, this.onMessage, false);
  }

  async ngOnInit(): Promise<void> {
    this.routerSubscrription = this.router.events.subscribe((event: RouterEvent) => {
      if (event.url?.includes('/assets/')) {
        this.navbar.onHide.emit({});
      }
    });
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((queryParams) => {
        this.currentLanguage = queryParams.language || 'en';
      })
    );

    this.listenIframe();

    this.headerMenuItems = [
      {
        label: await this.utilsService.translate('GENERAL_DEVICES'),
        items: await Promise.all(
          this.allDevices.map(async (d) => {
            return {
              icon: d.icon,
              label: await this.utilsService.translate(d.label),
              command: () => {
                this.deviceChange(d);
              },
            };
          })
        ),
      },
      {
        label: await this.utilsService.translate('GENERAL_VIEW_MODES'),
        items: await Promise.all(
          this.allViewModels.map(async (d) => {
            return {
              icon: d.icon,
              label: await this.utilsService.translate(d.label),
              command: () => {
                this.viewModeChange(d);
              },
            };
          })
        ),
      },
    ];

    if (this.context.eventVersion) {
      const sessionSelectedUser = sessionStorage.getItem(`event:${this.context.event._id}:selecteduser`);

      if (sessionSelectedUser) {
        const selectedUser = JSON.parse(sessionSelectedUser) as SelectedUser;
        const validTickets = (selectedUser.eventTickets || []).filter((t) => this.context.eventVersion.eventTickets.some((e) => e._id === t));
        this.selectedUser = {
          loggedIn: selectedUser.loggedIn,
          ignoreConditions: selectedUser.ignoreConditions,
          eventTickets: validTickets.length > 0 ? validTickets : null,
        };
      } else {
        this.selectedUser = {
          loggedIn: true,
          ignoreConditions: true,
          eventTickets: [],
        };
      }
    } else if (this.context.pageVersion) {
      const sessionSelectedUser = sessionStorage.getItem(`page:${this.context.page._id}:selecteduser`);

      if (sessionSelectedUser) {
        const selectedUser = JSON.parse(sessionSelectedUser) as SelectedUser;
        this.selectedUser = {
          loggedIn: selectedUser.loggedIn,
          ignoreConditions: selectedUser.ignoreConditions,
          eventTickets: null,
        };
      } else {
        this.selectedUser = {
          loggedIn: true,
          ignoreConditions: true,
          eventTickets: [],
        };
      }
    }
    this.saveToLocalSession();
  }

  setSelectedUser(): void {
    this.selectedUser = this.selectedUserEdit;
    this.showUserSelect = false;
    this.saveToLocalSession();
  }

  saveToLocalSession(): void {
    if (this.context.eventVersion) {
      sessionStorage.setItem(`event:${this.context.event._id}:selecteduser`, JSON.stringify(this.selectedUser));
    } else if (this.context.pageVersion) {
      sessionStorage.setItem(`page:${this.context.page._id}:selecteduser`, JSON.stringify(this.selectedUser));
    }
  }

  updateContext(context: LayoutContext): void {
    this.context = {
      ...this.context,
      ...context,
    };

    this.contextChange.emit(this.context);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.routerSubscrription.unsubscribe();
    window.removeEventListener('message', this.onMessage);
  }

  deviceChange(device: Device): void {
    const deviceIndex = devices.indexOf(device);
    localStorage.setItem('deviceIndex', deviceIndex.toString());
    this.currentDevice = devices[deviceIndex];
  }

  viewModeChange(viewMode: ViewMode): void {
    const viewModeIndex = viewModels.indexOf(viewMode);
    localStorage.setItem('viewModeIndex', viewModeIndex.toString());
    this.currentViewMode = viewModels[viewModeIndex];
  }

  newContext(context: { eventPhase: EventPhase; page: Page }): void {
    if (this.context?.eventVersion.phase[context.eventPhase].pages.find((p) => p._id === context.page._id)) {
      this.updateContext({
        page: context.page,
        eventPhase: context.eventPhase,
      });
    }
  }

  hideSidebar(): void {
    this.showPages = false;
  }

  onMessage = (event) => {
    setTimeout(async () => {
      if (event.data?.type === 'page:selected') {
        if (this.context.eventVersion) {
          let page: Page | null = null;
          let eventPhase = this.context.eventPhase;

          // search in current phase first
          page = this.context?.eventVersion.phase[this.context.eventPhase]?.pages.find((p) => event.data.pageId === p._id);

          if (!page) {
            for (const phase of Object.keys(this.context?.eventVersion.phase)) {
              page = this.context?.eventVersion.phase[this.context.eventPhase]?.pages.find((p) => event.data.pageId === p._id);

              if (page) {
                eventPhase = phase as EventPhase;
                break;
              }
            }
          }

          if (page) {
            this.contextLeft = false;
            this.newContext({ page, eventPhase });
          } else {
            this.contextLeft = true;
          }
        } else if (this.context.pageVersion) {
          this.contextLeft = event.data.pageVersionId !== this.context.pageVersion._id;
        }
      } else if (event.data?.type?.startsWith('pagemodule:')) {
        const getPage = () => {
          return this.context?.eventVersion
            ? Object.values(this.context?.eventVersion.phase)
                .reduce((a, b) => a.concat(b.pages), [] as Page[])
                .find((p) => p._id === event.data?.pageId)
            : this.page;
        };
        if (event.data.type === 'pagemodule:insert') {
          this.insertPageModule = {
            index: event.data.index,
          };
        } else {
          const pageModules = this.asPageWithPageModules(this.page)?.pageModules;
          const pageModule = pageModules?.find((p) => p._id === event.data.pageModuleId);
          if (pageModule) {
            if (event.data?.type === 'pagemodule:copy') {
              await this.pageModuleCopy(pageModule);
            } else if (event.data?.type === 'pagemodule:edit') {
              this.pageModuleEdit(pageModule);
            } else if (event.data?.type === 'pagemodule:remove') {
              await this.pageModuleRemove(pageModule);
            } else if (event.data?.type === 'pagemodule:up') {
              await this.pageModuleUp(pageModule);
            } else if (event.data?.type === 'pagemodule:down') {
              await this.pageModuleDown(pageModule);
            }
          }

          if (event.data.type === 'pagemodule:paste') {
            const clipboard = this.windowMessageService.getContextCheckedClipboardData(this.context);
            if (clipboard) {
              const insertPageModule = {
                ...clipboard.pageModule,
                _id: null,
                index: event.data.index,
              };
              await this.pageModuleTypeSelected({ pageModuleType: clipboard.pageModuleType }, insertPageModule);
              this.messageService.add({ severity: 'success', summary: `${clipboard.pageModuleType.split(/(?=[A-Z])/).join(' ')} Module`, detail: `Page module inserted` });
            } else {
              this.messageService.add({ severity: 'error', summary: `${event.data.pageModuleType.split(/(?=[A-Z])/).join(' ')} Module`, detail: `Page module inserting failed` });
            }
          } else if (event.data?.type === 'pagemodule:refreshClipboardData') {
            this.windowMessageService.sendToCurrentIframe(this.context);
          }
        }
      } else if (event.data?.type?.startsWith('eventstage:')) {
        if (event.data?.type === 'eventstage:add') {
          this.addEventStage = {};
        }
        if (event.data?.type === 'eventstage:edit') {
          this.eventStageEdit();
        }
        if (event.data?.type === 'eventstage:remove') {
          await this.eventStageRemove();
        }
      }
    }, 0);
  };

  eventStageEdit(): void {
    if (isPageWithEventStage(this.context.page)) {
      this.editEventStage = this.context.page.eventStage;
      this.editPageModule = null;
    }
  }

  async eventStageRemove(): Promise<void> {
    if (isPageWithEventStage(this.context.page)) {
      await this.collaborationService.patch(this.collaborationKey, this.context.pageVersion || this.context?.eventVersion, {
        command: 'set',
        value: null,
        jsonpath: this.utilsService.combineJsonpath([this.pageJsonpath, '$.eventStage']),
        jsonpathParams: {
          ...this.jsonpathParams,
        },
      });
    }
  }

  async eventStageTypeSelected(eventStageType: EventStageType): Promise<void> {
    this.addEventStage.type = eventStageType;

    const newEventStage: EventStage = await new EventStageFactory().eventStage({ eventStageType });

    if (newEventStage) {
      await this.collaborationService.patch(this.collaborationKey, this.context.pageVersion || this.context?.eventVersion, {
        command: 'set',
        jsonpath: this.utilsService.combineJsonpath([this.pageJsonpath, '$.eventStage']),
        jsonpathParams: this.jsonpathParams,
        value: newEventStage,
      });

      this.eventStageEdit();
    }

    this.addEventStage = null;
  }

  async pageModuleCopy(pageModule: PageModule): Promise<void> {
    const _pageModule: PageModule = (await new PageModuleFactory().createPageModule(pageModule)) as PageModule;
    this.windowMessageService.createNewClipboardData(this.context, _pageModule);
    this.windowMessageService.sendToCurrentIframe(this.context);
    this.messageService.add({ severity: 'success', summary: `${_pageModule.pageModuleType.split(/(?=[A-Z])/).join(' ')} Module`, detail: `Page module copied to clipboard.` });
  }

  pageModuleEdit(pageModule: PageModule): void {
    this.editEventStage = null;
    this.editPageModule = pageModule;
  }

  async pageModuleRemove(pageModule: PageModule): Promise<void> {
    this.confirmationService.confirm({
      key: 'pageModuleRemove',
      message: await this.utilsService.translate('COMPONENT_LAYOUT_EDITOR_DELETE_CONFIRMATION'),
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        await this.collaborationService.patch(this.collaborationKey, this.context.pageVersion || this.context.eventVersion, {
          command: 'delete',
          jsonpath: this.utilsService.combineJsonpath([this.pageJsonpath, "$.pageModules[?(@._id=='$pageModuleId')]"]),
          jsonpathParams: {
            ...this.jsonpathParams,
            pageModuleId: pageModule._id,
          },
        });
      },
      reject: () => {
        // Nothing to do
      },
    });
  }

  async pageModuleUp(pageModule: PageModule): Promise<void> {
    await this.collaborationService.patch(this.collaborationKey, this.context.pageVersion || this.context?.eventVersion, {
      command: 'up',
      jsonpath: this.utilsService.combineJsonpath([this.pageJsonpath, "$.pageModules[?(@._id=='$pageModuleId')]"]),
      jsonpathParams: {
        ...this.jsonpathParams,
        pageModuleId: pageModule._id,
      },
    });
  }

  async pageModuleDown(pageModule: PageModule): Promise<void> {
    await this.collaborationService.patch(this.collaborationKey, this.context.pageVersion || this.context?.eventVersion, {
      command: 'down',
      jsonpath: this.utilsService.combineJsonpath([this.pageJsonpath, "$.pageModules[?(@._id=='$pageModuleId')]"]),
      jsonpathParams: {
        ...this.jsonpathParams,
        pageModuleId: pageModule._id,
      },
    });
  }

  asPageWithPageModules(page: Page): PageWithPageModules {
    return isPageWithPageModules(page) ? page : null;
  }

  globalPageJsonpath(): string {
    return this.pageJsonpath;
  }

  eventStageJsonpath(): string[] {
    return [this.pageJsonpath, `$.eventStage`];
  }

  pageModuleJsonpath(pageModule: PageModule): string[] {
    return [this.pageJsonpath, `$.pageModules[?(@.pageModuleType=='${pageModule.pageModuleType}' && @._id=='$pageModuleId')]`];
  }

  pageModuleJsonpathParams(pageModule: PageModule): any {
    return {
      ...this.jsonpathParams,
      pageModuleId: pageModule._id,
    };
  }

  async pageModuleTypeSelected(pageModuleItem: { pageModuleType: PageModuleType; customPageModuleType?: string; plugin?: IPlugin }, insertPageModule = this.insertPageModule): Promise<void> {
    const pageModuleType = pageModuleItem.pageModuleType;
    const superModule = this.insertPageModule ? { type: pageModuleType } : insertPageModule;

    let newPageModule: PageModule | null = null;

    if (pageModuleType === 'Custom') {
      if (pageModuleItem.customPageModuleType && pageModuleItem.plugin) {
        newPageModule = await new CustomPageModuleFactory().customPageModule({
          ...superModule,
          customPageModuleType: pageModuleItem.customPageModuleType,
          plugin: pageModuleItem.plugin,
        });
      }
    } else {
      newPageModule = await new PageModuleFactory({ ensureLocals: this.languages }).createPageModule({
        ...superModule,
        pageModuleType: pageModuleType,
      });
    }

    if (newPageModule) {
      await this.collaborationService.patch(this.collaborationKey, this.context?.eventVersion || this.context.pageVersion, {
        command: 'splice',
        index: insertPageModule.index,
        delete: 0,
        jsonpath: this.utilsService.combineJsonpath([this.pageJsonpath, '$.pageModules']),
        jsonpathParams: this.jsonpathParams,
        value: newPageModule,
      });

      newPageModule = this.utilsService.resolveJsonpathValue(this.context?.eventVersion || this.context.pageVersion, [this.pageJsonpath, '$.pageModules'], this.jsonpathParams)[insertPageModule.index];

      const inputs = this.context.pageVersion ? pageInputs : eventVersionInputs;

      const pageModuleInputs = Object.keys(inputs).filter((i) =>
        i.startsWith(`$.phase.$eventPhase.pages[?(@._id=='$pageId')].pageModules[?(@.pageModuleType=='${pageModuleType}' && @._id=='$pageModuleId')]`)
      );
      await Promise.all(
        pageModuleInputs.map(async (input) => {
          let jsonpath = this.utilsService.resolveJsonpath(input, {
            ...this.jsonpathParams,
            pageModuleId: newPageModule._id,
            language: 'language',
          });

          if (/\$language\.[a-zA-Z0-9]+$/.test(input) && this.utilsService.guessInputType(jsonpath) === 'imageasset') {
            jsonpath = this.utilsService.resolveJsonpath(input, {
              ...this.jsonpathParams,
              pageModuleId: newPageModule._id,
              language: '$language',
            });

            await this.collaborationService.patch(this.collaborationKey, this.context?.eventVersion || this.context.pageVersion, {
              command: 'push',
              jsonpath: '$.globalProperties',
              value: jsonpath,
            });
          }
        })
      );
      if (this.insertPageModule) {
        this.pageModuleEdit(newPageModule);
      }
    }

    this.insertPageModule = null;
  }
}
