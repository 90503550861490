import { Inject, Injectable, InjectionToken } from '@angular/core';
import { EventRegistrationAutoAssignment, GlobalRegistrationAutoAssignment } from '../../../common/entities/AutoAssignment';
import { GetEventRegistrationAutoAssignmentsQuery, GetEventRegistrationAutoAssignmentsResponse } from '../../../common/api/v1/events/GetEventRegistrationAutoAssignments';
import { GetGlobalRegistrationAutoAssignmentQuery } from 'src/common/api/v1/configuration/auto-assignment/GetGlobalRegistrationAutoAssignment';
import { GetGlobalRegistrationAutoAssignmentsResponse } from 'src/common/api/v1/configuration/auto-assignment/GetGlobalRegistrationAutoAssignments';

export const AutoAssignmentsKey = new InjectionToken('auto assignments service');

@Injectable()
export abstract class AbstractAutoAssignmentsService {
  abstract getAutoAssignment(event: string, id: string): Promise<EventRegistrationAutoAssignment | GlobalRegistrationAutoAssignment>;

  abstract getAutoAssignments(
    query?: GetEventRegistrationAutoAssignmentsQuery | GetGlobalRegistrationAutoAssignmentQuery,
    event?: string
  ): Promise<GetEventRegistrationAutoAssignmentsResponse | GetGlobalRegistrationAutoAssignmentsResponse>;

  abstract createAutoAssignment(
    vent: string,
    autoAssignment: EventRegistrationAutoAssignment | GlobalRegistrationAutoAssignment
  ): Promise<EventRegistrationAutoAssignment | GlobalRegistrationAutoAssignment>;

  abstract updateAutoAssignment(
    event: string,
    autoAssignment: EventRegistrationAutoAssignment | GlobalRegistrationAutoAssignment
  ): Promise<EventRegistrationAutoAssignment | GlobalRegistrationAutoAssignment>;

  abstract deleteAutoAssignment(
    event: string,
    autoAssignment: EventRegistrationAutoAssignment | GlobalRegistrationAutoAssignment
  ): Promise<EventRegistrationAutoAssignment | GlobalRegistrationAutoAssignment>;
}

@Injectable()
export class AutoAssignmentsService {
  constructor(@Inject(AutoAssignmentsKey) private abstractAutoAssignmentsService: AbstractAutoAssignmentsService) {}

  getAutoAssignment(event: string, id: string) {
    return this.abstractAutoAssignmentsService.getAutoAssignment(event, id);
  }

  getAutoAssignments(query?: GetEventRegistrationAutoAssignmentsQuery | GetGlobalRegistrationAutoAssignmentQuery, event?: string) {
    return this.abstractAutoAssignmentsService.getAutoAssignments(query, event);
  }

  createAutoAssignment(autoAssignment: EventRegistrationAutoAssignment | GlobalRegistrationAutoAssignment, event?: string) {
    return this.abstractAutoAssignmentsService.createAutoAssignment(event, autoAssignment);
  }

  updateAutoAssignment(autoAssignment: EventRegistrationAutoAssignment | GlobalRegistrationAutoAssignment, event?: string) {
    return this.abstractAutoAssignmentsService.updateAutoAssignment(event, autoAssignment);
  }

  deleteAutoAssignment(autoAssignment: EventRegistrationAutoAssignment | GlobalRegistrationAutoAssignment, event?: string) {
    return this.abstractAutoAssignmentsService.deleteAutoAssignment(event, autoAssignment);
  }
}
