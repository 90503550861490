<div>
  <div class="tab-header">
    <h1>{{ 'PAGE_INTERACTION' | translate }}</h1>
  </div>
  <div class="tab-menu">
    <p-button *hasRight="'configuration.edit'" icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" [disabled]="working" (onClick)="save()"></p-button>
    <c-language-button
      *hasRight="'configuration.edit'"
      [disabled]="working"
      [languages]="languages"
      [(currentLanguage)]="currentLanguage"
      (languagesChange)="ensureLanguages($event)"
    ></c-language-button>
    <c-language-button
      *hasNoRight="'configuration.edit'"
      [manageLanguages]="false"
      [disabled]="working"
      [languages]="languages"
      [(currentLanguage)]="currentLanguage"
      (languagesChange)="ensureLanguages($event)"
    ></c-language-button>
    <c-domain-button *hasRight="'configuration.edit'" [configKey]="'interaction'" [domainCollectionId]="domainCollectionId" (onDomainCollectionIdChange)="loadDialog($event)"></c-domain-button>
    <c-domain-button
      *hasNoRight="'configuration.edit'"
      [enableUnification]="false"
      [configKey]="'interaction'"
      [domainCollectionId]="domainCollectionId"
      (onDomainCollectionIdChange)="loadDialog($event)"
    ></c-domain-button>
  </div>

  <div class="panel" *skeleton="{ show: tab.loading, type: 'table' }">
    <div class="panel-header">
      {{ 'PAGE_LIVESTREAM_QUICK_REACTIONS' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button *hasRight="'configuration.create'" [label]="'PAGE_INTERACTION_NEW' | translate" [disabled]="working" icon="pi icon-ic-add-item" (onClick)="addReaction()"></p-button>
      </div>
    </div>
    <c-table
      #interactionTable
      [options]="interactionTableOptions"
      [data]="interactionConfiguration?.quickReactions"
      mode="static"
      (itemSelect)="tableItemSelect($event)"
      [selectWithDataUseIndex]="true"
      queryParamsHandling="merge"
    >
      <ng-template let-interaction>
        {{ interaction?.internalName || ('GENERAL_UNNAMED' | translate) }}
      </ng-template>
      <ng-template let-interaction>
        <i *ngIf="interaction.enabled" class="pi pi-check success"></i>
        <i *ngIf="!interaction.enabled" class="pi pi-times danger"></i>
      </ng-template>
      <ng-template *hasRight="'configuration.edit'" let-interaction let-first="first" let-last="last" let-index="index">
        <i *ngIf="!first" class="clickable pi pi-sort-up p-mr-2" [pTooltip]="'GENERAL_MOVE_UP' | translate" tooltipPosition="left" (click)="interactionUp(index)"></i>
        <i
          *ngIf="!last"
          class="clickable pi pi-sort-down"
          [ngClass]="{ 'down-padding-left': first }"
          [pTooltip]="'GENERAL_MOVE_DOWN' | translate"
          tooltipPosition="left"
          (click)="interactionDown(index)"
        ></i>
      </ng-template>
    </c-table>
  </div>
</div>

<p-sidebar [(visible)]="showEditQuickReactions" styleClass="p-sidebar-st" position="right" [modal]="true" [showCloseIcon]="false">
  <c-sidebar-header [headerTitle]="edit_mode ? 'Edit Quick Reaction' : 'Add Quick Reaction'" (onClose)="showEditQuickReactions = false"></c-sidebar-header>
  <div *ngIf="quickReactionIndex >= 0" class="sidebar-body">
    <c-collaboration-patch-input
      [object]="interactionConfiguration"
      [collaborationKey]="'interactionconfiguration'"
      [jsonpath]="'$.quickReactions[$index].internalName'"
      [jsonpathParams]="{ index: quickReactionIndex }"
      [inputs]="inputs"
    ></c-collaboration-patch-input>
    <c-collaboration-patch-input
      [object]="interactionConfiguration"
      [collaborationKey]="'interactionconfiguration'"
      [jsonpath]="'$.quickReactions[$index].enabled'"
      [jsonpathParams]="{ index: quickReactionIndex }"
      [inputs]="inputs"
    ></c-collaboration-patch-input>
    <div class="input-group compact">
      <div class="input">
        <ngx-file-drop class="file-drop" accept=".json" contentClassName="file-drop-content" (onFileDrop)="dropped($event)" [multiple]="false" dropZoneClassName="drop-zone">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div class="lottie-wrapper">
              <div class="lottie-player" *ngIf="interactionConfiguration.quickReactions[quickReactionIndex].animationJSON">
                <ng-lottie [options]="createAnmation" width="100px" height="100px"></ng-lottie>
              </div>
              <div class="actions-upload as-layer" *ngIf="interactionConfiguration.quickReactions[quickReactionIndex].animationJSON">
                <span>
                  <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="downloadLottie()">{{ 'GENERAL_DOWNLOAD' | translate }}</a
                  >&nbsp;/&nbsp; <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openFileSelector()">{{ 'GENERAL_UPLOAD' | translate }}</a
                  >&nbsp;/&nbsp; <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="removeLottie()">Remove</a>&nbsp;
                </span>
              </div>
              <div class="actions-upload" *ngIf="!interactionConfiguration.quickReactions[quickReactionIndex].animationJSON">
                <span>
                  <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openFileSelector()">{{ 'GENERAL_UPLOAD' | translate }}</a
                  >&nbsp;
                  {{ 'COMPONENT_ASSET_SELECT_DROP_A_FILE' | translate }}
                </span>
              </div>
            </div>
          </ng-template>
        </ngx-file-drop>
      </div>
      <div class="title">Lottie</div>
    </div>
    <c-collaboration-patch-input
      [object]="interactionConfiguration"
      [collaborationKey]="'interactionconfiguration'"
      [jsonpath]="'$.quickReactions[$index].local.$language.label'"
      [jsonpathParams]="{ index: quickReactionIndex }"
      [inputs]="inputs"
    ></c-collaboration-patch-input>
  </div>
  <c-sidebar-footer *hasRight="'configuration.delete'" [showDeleteButton]="true" (onDelete)="deleteReaction(quickReactionIndex); showEditQuickReactions = false"></c-sidebar-footer>
  <c-sidebar-footer *hasNoRight="'configuration.delete'" [showDeleteButton]="false" (onDelete)="deleteReaction(quickReactionIndex); showEditQuickReactions = false"></c-sidebar-footer>
</p-sidebar>
