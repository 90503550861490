import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventVersion } from 'src/common/entities/EventVersion';
import { eventVersionInputs } from 'src/common/inputs/event/EventVersionInputs';
import { InputConfiguration, Inputs } from 'src/common/inputs/Inputs';

export interface Jsonpath {
  originalPath: string;
  resolvedPath: string;
  params: { [key: string]: any };
}

@Component({
  selector: 'c-event-patch-input',
  templateUrl: './event-patch-input.component.html',
  styleUrls: ['./event-patch-input.component.scss'],
})
export class EventPatchInputComponent {
  @Input()
  jsonpath: string | string[];

  @Input()
  jsonpathParams: { [key: string]: any } = {};

  @Input()
  eventVersion: EventVersion;

  @Input()
  inputs: Inputs;

  @Input()
  inputConfiguration: InputConfiguration;

  @Input()
  disabled: boolean;

  @Input()
  showHeader: boolean = true;

  @Input()
  showDescription: boolean = true;

  @Input()
  header: string;

  @Input()
  description: string;

  @Input()
  placeholder: string;

  @Input()
  placeholderDescription: string;

  @Input()
  showPlaceholderDescription = false;

  @Output()
  onValueChanged: EventEmitter<any> = new EventEmitter<any>();

  eventVersionInputs = eventVersionInputs;

  constructor() {}

  emitValueChanged($event) {
    this.onValueChanged.emit($event);
  }
}
