import { Injectable } from '@angular/core';
import { GetStreamQuery, GetStreamResponse } from 'src/common/api/v1/streams/GetStream';
import { PostStreamBody, PostStreamQuery, PostStreamResponse } from 'src/common/api/v1/streams/PostStream';
import { ApiService } from '../api/api.service';
import { StreamWithSessions } from 'src/common/entities/Stream';
import { GetStreamsQuery, GetStreamsResponse } from 'src/common/api/v1/streams/GetStreams';
import { PostStreamsBody, PostStreamsQuery, PostStreamsResponse } from 'src/common/api/v1/streams/PostStreams';
import { PostStreamActivateBody, PostStreamActivateQuery, PostStreamActivateResponse } from 'src/common/api/v1/streams/PostStreamActivate';
import { PostStreamDeactivateBody, PostStreamDeactivateQuery, PostStreamDeactivateResponse } from 'src/common/api/v1/streams/PostStreamDeactivate';
import { PostStreamStartBody, PostStreamStartQuery, PostStreamStartResponse } from 'src/common/api/v1/streams/PostStreamStart';
import { PostStreamStopBody, PostStreamStopQuery, PostStreamStopResponse } from 'src/common/api/v1/streams/PostStreamStop';
import { PostStreamStandbyBody, PostStreamStandbyQuery, PostStreamStandbyResponse } from 'src/common/api/v1/streams/PostStreamStandby';
import { PostStreamDeleteBody, PostStreamDeleteQuery, PostStreamDeleteResponse } from 'src/common/api/v1/streams/PostStreamDelete';
import { PostStreamRefreshBody, PostStreamRefreshQuery, PostStreamRefreshResponse } from 'src/common/api/v1/streams/PostStreamRefresh';
import { GetStreamVodAssetSessionsQuery, GetStreamVodAssetSessionsResponse } from 'src/common/api/v1/streams/GetStreamVodAssetSessions';
import {
  PostStreamExportVODAssetBody,
  PostStreamExportVODAssetQuery,
  PostStreamExportVODAssetResponse,
  PostStreamExportVODAssetResponseError,
} from 'src/common/api/v1/streams/PostStreamExportVODAsset';
import { PostStreamVODCreateBody, PostStreamVODCreateQuery, PostStreamVODCreateResponse } from 'src/common/api/v1/streams/PostStreamVODCreate';

@Injectable({
  providedIn: 'root',
})
export class StreamsService {
  constructor(private apiService: ApiService) {}

  getStream(streamId: string): Promise<StreamWithSessions> {
    return this.apiService.get<GetStreamQuery, GetStreamResponse>(`/api/v1/streams/${streamId}`).toPromise();
  }

  getStreams(query: GetStreamsQuery = null): Promise<GetStreamsResponse> {
    return this.apiService.get<GetStreamsQuery, GetStreamsResponse>(`/api/v1/streams`, query).toPromise();
  }

  createStream(stream: PostStreamsBody): Promise<StreamWithSessions> {
    return this.apiService.post<PostStreamsQuery, PostStreamsBody, PostStreamsResponse>(`/api/v1/streams`, stream).toPromise();
  }

  deleteStream(streamId: string): Promise<StreamWithSessions> {
    return this.apiService.post<PostStreamDeleteQuery, PostStreamDeleteBody, PostStreamDeleteResponse>(`/api/v1/streams/${streamId}/delete`, {}).toPromise();
  }

  updateStream(streamId: string, stream: PostStreamBody): Promise<StreamWithSessions> {
    return this.apiService.post<PostStreamQuery, PostStreamBody, PostStreamResponse>(`/api/v1/streams/${streamId}`, stream).toPromise();
  }

  activateStream(streamId: string): Promise<StreamWithSessions> {
    return this.apiService.post<PostStreamActivateQuery, PostStreamActivateBody, PostStreamActivateResponse>(`/api/v1/streams/${streamId}/activate`, {}).toPromise();
  }

  deactivateStream(streamId: string): Promise<StreamWithSessions> {
    return this.apiService.post<PostStreamDeactivateQuery, PostStreamDeactivateBody, PostStreamDeactivateResponse>(`/api/v1/streams/${streamId}/deactivate`, {}).toPromise();
  }

  startStream(streamId: string): Promise<StreamWithSessions> {
    return this.apiService.post<PostStreamStartQuery, PostStreamStartBody, PostStreamStartResponse>(`/api/v1/streams/${streamId}/start`, {}).toPromise();
  }

  stopStream(streamId: string): Promise<StreamWithSessions> {
    return this.apiService.post<PostStreamStopQuery, PostStreamStopBody, PostStreamStopResponse>(`/api/v1/streams/${streamId}/stop`, {}).toPromise();
  }

  standbyStream(streamId: string): Promise<StreamWithSessions> {
    return this.apiService.post<PostStreamStandbyQuery, PostStreamStandbyBody, PostStreamStandbyResponse>(`/api/v1/streams/${streamId}/standby`, {}).toPromise();
  }

  refreshStream(streamId: string): Promise<StreamWithSessions> {
    return this.apiService.post<PostStreamRefreshQuery, PostStreamRefreshBody, PostStreamRefreshResponse>(`/api/v1/streams/${streamId}/refresh`, {}).toPromise();
  }

  vodAssetSessions(streamId: string) {
    return this.apiService.get<GetStreamVodAssetSessionsQuery, GetStreamVodAssetSessionsResponse>(`/api/v1/streams/${streamId}/vod-asset/sessions`).toPromise();
  }

  exportVodAsset(streamId: string) {
    return this.apiService
      .post<PostStreamExportVODAssetQuery, PostStreamExportVODAssetBody, PostStreamExportVODAssetResponse | PostStreamExportVODAssetResponseError>(`/api/v1/streams/${streamId}/export-vod-asset`, {})
      .toPromise();
  }

  createVOD(streamId: string) {
    return this.apiService.post<PostStreamVODCreateQuery, PostStreamVODCreateBody, PostStreamVODCreateResponse>(`/api/v1/streams/${streamId}/vod/create`, {}).toPromise();
  }
}
