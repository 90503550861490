<div class="sidebar-header">
  <div class="title p-mr-1">
    {{ headerTitle | translate }}
    <div class="sub-title p-mr-2">{{ headerSubTitle | translate }}</div>
  </div>
  <div class="actions">
    <button
      *ngIf="showBackButton"
      pButton
      type="button"
      class="p-button-sm p-button-outlined p-mr-2"
      [label]="'GENERAL_BACK' | translate"
      icon="pi pi-chevron-left"
      (click)="onBack.emit(true)"
    ></button>

    <button *ngIf="showSaveButton && !showCloseButton" pButton type="button" class="p-button-sm p-button-outlined p-mr-2" [label]="'GENERAL_SAVE' | translate" (click)="onSave.emit(true)"></button>
    <button
      *ngIf="showSaveButton && showCloseButton"
      pButton
      type="button"
      class="p-button-sm p-button-outlined p-mr-2"
      [label]="('GENERAL_SAVE' | translate) + ' & ' + ('GENERAL_CLOSE' | translate)"
      (click)="onSave.emit(true); onClose.emit(true)"
    ></button>

    <button *ngIf="showCloseButton" pButton type="button" class="p-button-sm p-button-outlined" [label]="'GENERAL_CLOSE' | translate" icon="pi pi-times" (click)="onClose.emit(true)"></button>
  </div>
</div>
