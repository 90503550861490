import * as yup from 'yup';

// export const eventSlotValidator = yup.object({
//   event: yup.string().required(),
//   startAt: yup.date().required(),
//   endAt: yup.date().required(),
//   capacity:  yup.number().min(1).nullable(),
//   warningCapacity: yup.number().min(1).nullable().when('capacity', (capacity: number, schema: any) => {
//     if (capacity) return schema.max(capacity);
//   }),
//   eventTickets: yup.array().of(yup.string().required()).nullable(),
//   eventTimeSlots: yup.array().of(eventTimeSlotValidator),
// });

// Event Validators

export const eventImagesValidator = yup.object({});
export const eventLocalValidator = yup.object({});
export const eventValidator = yup.object({});
export const eventTeasersValidator = yup.object({});
export const eventSlotValidator = yup.object({});
export const eventTimeSlotValidator = yup.object({});

export const eventCreateValidator = yup.object({
  internalName: yup.string().required(),
  eventType: yup.string().optional(),
  shortId: yup.string().optional(),
  template: yup.boolean().optional(),
  contactmail: yup.array().of(yup.string()).optional(),
  country: yup.string().optional(),
  eventSerie: yup.string().nullable().optional(),
  fromTemplate: yup
    .object({
      event: yup.string(),
      eventVersion: yup.string().optional()
    })
    .optional()
});

export const eventUpdateValidator = yup.object({
  internalName: yup.string().optional(),
  shortId: yup.string().optional(),
  public: yup.boolean().optional(),
  eventType: yup.string().optional(),
  country: yup.string().nullable().optional(),
  contactmail: yup.array().of(yup.string()).optional(),
  hideLanguageSelector: yup.boolean().optional(),
  hideBeaconIcon: yup.boolean().optional(),
  hideLoginButton: yup.boolean().optional(),
  showBackButton: yup.boolean().optional(),
  eventSerie: yup.string().nullable().optional()
});
