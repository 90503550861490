import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { titlePositions } from './../../entities/pagemodules/PageModule';
import { TilesPageModuleFactory } from './../../factories/pagemodules/TilesPageModuleFactory';
import { EventVersion } from '../../entities/EventVersion';
import { isPage, isPageWithPageModules, Page } from '../../entities/Page';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';
import { displayAs, isTilesPageModule, tileSizes, TilesPageModuleItem } from '../../entities/pagemodules/TilesPageModule';

export const tilesPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('Tiles'),
  "$.pageModules[?(@.pageModuleType=='Tiles' && @._id=='$pageModuleId')].showTitleBox": {
    type: 'switch',
  },
  "$.pageModules[?(@.pageModuleType=='Tiles' && @._id=='$pageModuleId')].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='Tiles' && @._id=='$pageModuleId')].tileSize": {
    type: 'dropdown',
    dropdownOptions: [...tileSizes].map((item) => ({
      label: item,
      value: item,
    })),
  },
  "$.pageModules[?(@.pageModuleType=='Tiles' && @._id=='$pageModuleId')].displayAs": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Image with a Title below', value: 'imageWithTitle' },
      { label: 'Title Only', value: 'titleOnly' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='Tiles' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new TilesPageModuleFactory().createTilesPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='Tiles' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='Tiles' && @._id=='$pageModuleId')].local.$language.items": {
    factory: async () => [],
    list: true,
    childFactory: async () => new TilesPageModuleFactory().createTilesPageModuleLocalItem({}),
    childLabel: (item: TilesPageModuleItem, index: number) => item?.title as string,
  },
  "$.pageModules[?(@.pageModuleType=='Tiles' && @._id=='$pageModuleId')].local.$language.items[$index]": {},
  "$.pageModules[?(@.pageModuleType=='Tiles' && @._id=='$pageModuleId')].local.$language.items[$index].title": {},
  "$.pageModules[?(@.pageModuleType=='Tiles' && @._id=='$pageModuleId')].local.$language.items[$index].image": {},
  "$.pageModules[?(@.pageModuleType=='Tiles' && @._id=='$pageModuleId')].local.$language.items[$index].linkType": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'None', value: null },
      { label: 'Register Platform', value: 'registerplatform' },
      { label: 'Register Event', value: 'registerevent' },
      { label: 'Meet your Expert', value: 'meetyourexpert' },
      { label: 'External Link', value: 'externallink' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='Tiles' && @._id=='$pageModuleId')].local.$language.items[$index].link": {
    condition: (obj, jsonpath, jsonpathParams) => {
      let page: Page | null | undefined = null;

      if (isPage(obj)) {
        page = obj;
      } else {
        const eventVersion = obj as EventVersion;
        page = eventVersion.phase[jsonpathParams.eventPhase].pages.find((p) => p._id === jsonpathParams.pageId);
      }

      if (isPage(page)) {
        if (isPageWithPageModules(page)) {
          const pageModule = page.pageModules.find((p) => p._id === jsonpathParams.pageModuleId);

          if (isTilesPageModule(pageModule)) {
            return pageModule.local[jsonpathParams.language].items[jsonpathParams.index].linkType === 'externallink';
          }
        }
      }

      return false;
    },
  },
  "$.pageModules[?(@.pageModuleType=='Tiles' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [...titlePositions].map((item) => ({
      label: item,
      value: item,
    })),
  },
};
