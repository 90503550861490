import { Component, Input, OnInit } from '@angular/core';

import { Event, eventPhase, EventPhase, eventPhases } from 'src/common/entities/Event';
import { ConfirmationService } from 'primeng/api';
import { EventsService } from 'src/app/services/events/events.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { Inputs } from 'src/common/inputs/Inputs';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'c-event-phases',
  templateUrl: './event-phases.component.html',
  styleUrls: ['./event-phases.component.scss'],
  providers: [ConfirmationService],
})
export class EventPhasesComponent implements OnInit {
  @Input()
  event: Event;

  inputs: Inputs = {
    '$.announcedAt': {
      header: 'PAGE_EVENT_DIALOG_PLAN_ANNOUNCEMENT_ANNOUNCED_AT',
      description: 'PAGE_EVENT_DIALOG_PLAN_ANNOUNCEMENT_ANNOUNCED_AT_DESCRIPTION',
      type: 'datetime',
      datetimeOptions: {
        showSeconds: false,
      },
    },
    '$.releasedAt': {
      header: 'PAGE_EVENT_DIALOG_PLAN_RELEASE_RELEASED_AT',
      description: 'PAGE_EVENT_DIALOG_PLAN_RELEASE_RELEASED_AT_DESCRIPTION',
      type: 'datetime',
      datetimeOptions: {
        showSeconds: false,
      },
    },
  };
  edit: any = {
    announcedAt: new Date(),
    releasedAt: new Date(),
  };

  dialogPlanAnnouncmentVisible: boolean = false;
  dialogPlanReleaseVisible: boolean = false;

  constructor(private confirmationService: ConfirmationService, private eventsService: EventsService, private configurationService: ConfigurationService, private utilsService: UtilsService) {}

  async ngOnInit() {
    if (this.event.announcedAt) {
      this.edit.announcedAt = new Date(this.event.announcedAt);
    } else {
      this.edit.announcedAt = null;
    }

    if (this.event.releasedAt) {
      this.edit.releasedAt = new Date(this.event.releasedAt);
    } else {
      this.edit.releasedAt = null;
    }
  }

  getEventPhase(): EventPhase {
    return eventPhase(this.event, null, this.configurationService.serverTime());
  }

  phaseActive(phase: EventPhase): boolean {
    return phase === this.getEventPhase();
  }

  phaseCompleted(phase: EventPhase): boolean {
    return eventPhases.indexOf(this.getEventPhase()) > eventPhases.indexOf(phase);
  }

  openDialogSetAnnouncedAt() {
    this.dialogPlanAnnouncmentVisible = true;
  }

  openDialogSetReleasedAt() {
    this.dialogPlanReleaseVisible = true;
  }

  async announce() {
    this.dialogPlanAnnouncmentVisible = false;
    this.event = await this.eventsService.announce(this.event._id, { announceAt: this.edit.announcedAt });
  }

  async release() {
    this.dialogPlanReleaseVisible = false;
    this.event = await this.eventsService.release(this.event._id, { releaseAt: this.edit.releasedAt });
  }

  async confirmSetOffstage() {
    this.confirmationService.confirm({
      header: await this.utilsService.translate('PAGE_EVENT_SET_OFFSTAGE_CONFIRM_HEADER'),
      message: await this.utilsService.translate('PAGE_EVENT_SET_OFFSTAGE_CONFIRM_MESSAGE'),
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        this.event = await this.eventsService.cancel(this.event._id);
      },
      reject: () => {
        // Do nothing
      },
    });
  }
}
