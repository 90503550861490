import { VideoAsset } from './Asset';
import { Event } from './Event';
import { RelatedContent } from './RelatedContent';

export const sessionTypes = ['SpeakerSession', 'BreakoutSession'] as const;
export type SessionType = (typeof sessionTypes)[number];

export const speakerRoles = ['MainSpeaker', 'AdditionalSpeaker'] as const;
export type SpeakerRole = (typeof speakerRoles)[number];

export type SessionWithSessionMedia = Session & { sessionMedia: { [language: string]: SessionMedia } };

export type SessionWithEvent = Session & { event: Event };

export interface Session {
  _id: string;
  sessionType: SessionType;
  hidden: boolean;
  skippable: boolean;
  event: string;

  /**
   * @deprecated
   */
  regional: { [region: string]: SessionRegion };

  local: { [language: string]: SessionLanguage };
  maxChatMessages?: number;

  startAt: string | Date;
  endAt: string | Date;

  actualStartAt: string | Date;
  actualEndAt: string | Date;

  highlight: boolean;

  /**
   * @deprecated
   */
  mainStageHighlight: boolean;

  tags: { [id: string]: string[] };
  stages: string[];

  keytopics: string[];

  images: {
    main: string;
  };

  /* for external event sessions */
  plain?: {
    session: any;
  };
  externalSession?: {
    externalSessionId: string;
    assets: {
      [language: string]: {
        externalAssetId: string;
        assetVersion: number;
      };
    };
  };
}

export interface SessionRegion {
  title: string;
  description: string;
  highlightTeaser: string;
  onDemandOnly: boolean;

  relatedContent: RelatedContent[];
}

export interface SessionLanguage {
  title: string;
  description: string;
  highlightTeaser: string;
  onDemandOnly: boolean;
  chat: string;
  reaction: string;

  relatedContent: RelatedContent[];
}

export interface SpeakerSession extends Session {
  sessionType: 'SpeakerSession';
  sessionMedia: { [language: string]: SessionMedia };
  speakers: SpeakerRoleAssignment[];
}

export interface BreakoutSession extends Session {
  sessionType: 'BreakoutSession';
  sessionMedia: { [language: string]: SessionMedia };
  speakers: SpeakerRoleAssignment[];
  breakouts: Breakout[];
}

export interface Breakout {
  _id: string;
  asset?: VideoAsset;
  link: string;
  speakers: string[];
  keytopics: string[];
  local: { [language: string]: BreakoutLangauge };
}

export interface BreakoutLangauge {
  shortTitle: string;
  title: string;
  description: string;
}

export interface SpeakerRoleAssignment {
  role: SpeakerRole;
  speaker: string;
}

export const sessionMediaTypes = ['VodSessionMedia', 'LiveSessionMedia', 'ReLiveSessionMedia'] as const;
export type SessionMediaType = (typeof sessionMediaTypes)[number];

export interface SessionMedia {
  sessionMediaType: SessionMediaType;
  asset?: string;
}

export interface VodSessionMedia extends SessionMedia {
  sessionMediaType: 'VodSessionMedia';
  asset: string;
}

export interface LiveSessionMedia extends SessionMedia {
  sessionMediaType: 'LiveSessionMedia';
  stream: string;
  asset?: string;
}

export interface ReLiveSessionMedia extends SessionMedia {
  sessionMediaType: 'ReLiveSessionMedia';
  asset: string;
}

export function isSpeakerSession(object: any): object is SpeakerSession {
  return object?.sessionType === 'SpeakerSession';
}

export function isBreakoutSession(object: any): object is BreakoutSession {
  return object?.sessionType === 'BreakoutSession';
}

export function isSessionWithSessionMedia(object: any): object is SessionWithSessionMedia {
  return isSpeakerSession(object) || isBreakoutSession(object);
}

export function isVodSessionMedia(object: any): object is VodSessionMedia {
  return object.sessionMediaType === 'VodSessionMedia';
}

export function isLiveSessionMedia(object: any): object is LiveSessionMedia {
  return object.sessionMediaType === 'LiveSessionMedia';
}

export function isReLiveSessionMedia(object: any): object is ReLiveSessionMedia {
  return object.sessionMediaType === 'ReLiveSessionMedia';
}

export function isSessionMediaWithAsset(object: any): object is { asset: string } {
  return isVodSessionMedia(object) || isLiveSessionMedia(object) || isReLiveSessionMedia(object);
}
