import Quill from 'quill';
import Emitter from 'quill/core/emitter';
import { Range } from 'quill/core/selection';
import { BaseTooltip } from 'quill/themes/base';

import { environment } from 'src/environments/environment';

// override Block element to use `div` instead of `p` tags.
// however, there is some internal logic, that if last tag is p (or div), the next tag will also be the same tag after pressing ENTER
// so it might not work sometimes as expected
function overrideBlock() {
  const Block = Quill.import('blots/block');
  class DivBlock extends Block {}
  DivBlock.tagName = 'DIV';
  Quill.register('blots/block', DivBlock, true);
}

function registerCustomTheme() {
  class CustomTooltip extends BaseTooltip {
    // type fix
    [x: string]: any;
    static TEMPLATE: string;

    constructor(quill, bounds) {
      super(quill, bounds);
      const root = document.createElement('div');
      root.classList.add('ql-tooltip', 'custom-tooltip');
      this.root = quill.addContainer(root);
      this.root.innerHTML = CustomTooltip.TEMPLATE;
      this.preview = this.root.querySelector('a.ql-preview');
      this.textbox = this.root.querySelector('input[name="href"]');
      this.namebox = this.root.querySelector('input[name="name"]');
      if (this.quill.root === this.quill.scrollingContainer) {
        this.quill.root.addEventListener('scroll', () => {
          this.root.style.marginTop = `${-1 * this.quill.root.scrollTop}px`;
        });
      }
      this.listen();
      this.hide();
    }

    listen() {
      super.listen();
      this.root.querySelector('a.ql-action').addEventListener('click', (event) => {
        if (this.root.classList.contains('ql-editing')) {
          this.save();

          // hack fix: our custom links/tooltip allows to edit link display name
          // after that action, the link is not editable anymore (by some internal Quill query logic)
          // workaround for that is to reinitialize editor
          this.quill.__reinit?.();
        } else {
          this.edit('link', this.preview.textContent, this.namebox.value);
        }
        event.preventDefault();
      });
      this.root.querySelector('a.ql-remove').addEventListener('click', (event) => {
        if (this.linkRange != null) {
          const range = this.linkRange;
          this.restoreFocus();
          this.quill.formatText(range, 'link', false, Emitter.sources.USER);
          delete this.linkRange;
        }
        event.preventDefault();
        this.hide();
      });

      const LinkBlot = Quill.import('formats/link');

      this.quill.on(Emitter.events.SELECTION_CHANGE, (range, oldRange, source) => {
        if (range == null) return;
        if (range.length === 0 && source === Emitter.sources.USER) {
          const [link, offset] = this.quill.scroll.descendant(LinkBlot, range.index);
          if (link != null) {
            this.linkRange = new Range(range.index - offset, link.length());
            const linkFormats = LinkBlot.formats(link.domNode);
            this.preview.textContent = linkFormats.url;
            this.preview.setAttribute('href', linkFormats.url);
            if (linkFormats.name) {
              this.namebox.value = linkFormats.name;
            }

            this.show();
            this.position(this.quill.getBounds(this.linkRange));
            return;
          }
        } else {
          delete this.linkRange;
        }
        this.hide();
      });
    }

    save() {
      const dataMode = this.root.getAttribute('data-mode');
      if (dataMode !== 'link') {
        return super.save();
      }

      let { value: url } = this.textbox;
      let { value: name } = this.namebox;
      const { scrollTop } = this.quill.root;
      if (this.linkRange) {
        this.quill.formatText(this.linkRange, 'link', { url, name }, Emitter.sources.USER);
        delete this.linkRange;
      } else {
        this.restoreFocus();
        this.quill.format('link', { url, name }, Emitter.sources.USER);
      }
      this.quill.root.scrollTop = scrollTop;
      this.textbox.value = '';
      this.hide();
    }

    edit(mode = 'link', preview = null, text = null) {
      if (mode !== 'link') {
        return super.edit(mode, preview);
      }

      this.root.classList.remove('ql-hidden');
      this.root.classList.add('ql-editing');

      if (preview != null) {
        this.textbox.value = preview;
      } else if (mode !== this.root.getAttribute('data-mode')) {
        this.textbox.value = '';
      }
      this.position(this.quill.getBounds(this.quill.selection.savedRange));
      this.textbox.select();
      this.textbox.setAttribute('placeholder', this.textbox.getAttribute(`data-link`) || '');
      this.root.setAttribute('data-mode', mode);

      if (text) {
        this.root.classList.remove('hide-name-input');
        this.namebox.value = text;
      } else {
        this.root.classList.add('hide-name-input');
      }
    }

    show() {
      super.show();
      this.root.removeAttribute('data-mode');
    }
  }

  CustomTooltip.TEMPLATE = [
    '<a class="ql-preview" rel="noopener noreferrer" target="_blank" href="about:blank"></a>',
    '<label class="c-ql-text-input">Display name: <input type="text" name="name" autocomplete="off"></label>',
    `<label>Link: <input type="text" name="href" data-formula="e=mc^2" data-link="${environment.frontend}" data-video="Embed URL" autocomplete="off"></label>`,
    '<a class="ql-action"></a>',
    '<a class="ql-remove"></a>',
  ].join('');

  const SnowTheme = Quill.import('themes/snow');
  class CustomTheme extends SnowTheme {
    constructor(...args) {
      super(...args);
    }

    extendToolbar(toolbar) {
      super.extendToolbar(toolbar);
      this.tooltip = new CustomTooltip(this.quill, this.options.bounds);
    }
  }

  Quill.register('themes/custom-theme', CustomTheme, true);
}

function overrideLink() {
  const Link = Quill.import('formats/link');

  class CustomLink extends Link {
    static create(value) {
      const node = super.create(value) as HTMLAnchorElement;
      node.setAttribute('href', value.url);
      return node;
    }

    static formats(domNode) {
      return {
        url: domNode.getAttribute('href'),
        name: domNode.innerText,
      };
    }

    format(name, value) {
      if (name !== this.statics.blotName || !value?.url) {
        return super.format(name, value?.url);
      }

      this.domNode.setAttribute('href', CustomLink.sanitize(value.url));

      if (this.domNode.innerHTML !== value.name) {
        this.domNode.innerHTML = value.name;
      }
    }
  }

  Quill.register('formats/link', CustomLink, true);
}

let didOverride = false;
export function overrideQuill() {
  if (didOverride) return;
  didOverride = true;
  overrideBlock();
  overrideLink();
  registerCustomTheme();
}
