import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { TableComponent } from 'src/app/components/table/table.component';
import { TableOptions } from 'src/app/components/table/table.interfaces';
import { EventsService } from 'src/app/services/events/events.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Session } from 'src/common/entities/Session';
import { Stage } from 'src/common/entities/Stage';
import { Factory } from 'src/common/factories/Factory';

@Component({
  selector: 'c-event-stages',
  templateUrl: './event-stages.component.html',
  styleUrls: ['./event-stages.component.scss'],
})
export class EventStagesComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) table: TableComponent<Stage>;

  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  editableStage: Stage;
  loading = false;

  get showStage(): boolean {
    return !!this.editableStage;
  }
  set showStage(val: boolean) {
    if (!val) {
      this.editableStage = null;
    }
  }

  sessions: { [stage: string]: Session[] } = {};
  subscriptions: Subscription[] = [];

  stagesTableOptions: TableOptions<Stage>;

  constructor(private eventsService: EventsService, private utilsService: UtilsService) {
    this.stagesTableOptions = {
      columns: [
        {
          header: 'Internal Name',
          sort: { type: 'string', property: 'internalName' },
        },
        { header: 'Sessions' },
        { header: '' },
      ],
    };
  }

  async ngOnInit(): Promise<void> {
    for (const stage of this.eventVersion.stages || []) {
      this.sessions[stage._id] = this.eventVersion.sessions.filter((s) => (s.stages || []).includes(stage._id));
    }
    this.subscriptions.push(
      this.eventsService.lastEventVersionPatch(this.eventVersion._id).subscribe((patch) => {
        if (this.utilsService.startsWithJsonpath(patch.patch.jsonpath, '$.stages', patch.patch.jsonpathParams)) {
          this.table.refresh();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  closeDialog(): void {
    this.editableStage = null;
    this.showStage = false;
  }

  async addStage(): Promise<void> {
    this.loading = true;

    try {
      const stage = await Factory.stage().createStage({
        internalName: 'New Stage',
      });
      await this.eventsService.patch(this.eventVersion, {
        command: 'push',
        jsonpath: '$.stages',
        value: stage,
      });
      this.openStage(stage);
    } catch (err) {
      console.error(err);
    }

    this.loading = false;
  }

  openStage(stage: Stage): void {
    this.editableStage = stage;
  }

  async deleteStage(stage: Stage): Promise<void> {
    await this.eventsService.patch(this.eventVersion, {
      command: 'delete',
      jsonpath: `$.stages[?(@._id=='$stageId')]`,
      jsonpathParams: {
        stageId: stage._id,
      },
    });
  }
}
