import { Pipe, PipeTransform } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { Language } from 'src/common/entities/Language';

@Pipe({
  name: 'language',
})
export class LanguagePipe implements PipeTransform {
  constructor(private languagesService: LanguagesService) {}

  transform(languageTagOrId: string, refresh?: string): Observable<Language> {
    if (!languageTagOrId) return null;

    if (languageTagOrId === 'default') {
      const observable: Observable<Language> = new Observable((subscriber) => {
        subscriber.next({ languageName: 'ALL - Click to localize' } as Language);
      });
      return observable;
    } else if (languageTagOrId.length === 2) {
      return from(this.languagesService.getLanguages()).pipe(
        map((l) => l.items.find((i) => i.language === languageTagOrId)),
        mergeMap((i) => this.languagesService.asObservable(i._id))
      );
    } else {
      return this.languagesService.asObservable(languageTagOrId);
    }
  }
}
