<c-collaboration-patch-input
  *ngIf="inputs"
  [jsonpath]="jsonpath"
  [jsonpathParams]="jsonpathParams"
  [collaborationKey]="collaborationKey"
  [object]="object"
  [inputConfiguration]="inputConfiguration"
  [showHeader]="showHeader"
  [showDescription]="showDescription"
  [showDeleteButton]="showDeleteButton"
  [header]="header"
  [description]="description"
  [inputs]="inputs"
  (onValueChanged)="onValueChanged.emit($event)"
></c-collaboration-patch-input>
