import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { AccessPolicyFactory } from './AccessPolicyFactory';
import { ConfigurationFactory } from './ConfigurationFactory';
import { EventFactory } from './EventFactory';
import { EventVersionFactory } from './EventVersionFactory';
import { MenuItemFactory } from './MenuItemFactory';
import { PageFactory } from './PageFactory';
import { RegistrationCustomFieldFactory } from './RegistrationCustomFieldFactory';
import { SlotFactory } from './SlotFactory';
import { EventTicketFactory } from './EventTicketFactory';
import { StageFactory } from './StageFactory';
import { EventStageFactory } from './EventStageFactory';
import { FormFactory } from './FormFactory';
import { RBACFactory } from './RBACFactory';
import { ServiceHookFactory } from './ServiceHookFactory';
import { AdminUserFactory } from './AdminUserFactory';
import { MapFactory } from './MapFactory';

export class Factory {
  public static get<T extends AbstractFactory>(type: new (options?: FactoryOptions) => T, options?: FactoryOptions): T {
    return AbstractFactory.get(type, options);
  }

  /*
    DONT USE THIS ANYMORE

    just create the factory on the fly
    e.g. new SlotFactory().createSlot(...)
  */

  public static accessPolicy(options?: FactoryOptions): AccessPolicyFactory {
    return Factory.get(AccessPolicyFactory, options);
  }
  public static configuration(options?: FactoryOptions): ConfigurationFactory {
    return Factory.get(ConfigurationFactory, options);
  }
  public static event(options?: FactoryOptions): EventFactory {
    return Factory.get(EventFactory, options);
  }
  public static eventVersion(options?: FactoryOptions): EventVersionFactory {
    return Factory.get(EventVersionFactory, options);
  }
  public static menuItem(options?: FactoryOptions): MenuItemFactory {
    return Factory.get(MenuItemFactory, options);
  }
  public static page(options?: FactoryOptions): PageFactory {
    return Factory.get(PageFactory, options);
  }

  public static eventTicket(options?: FactoryOptions): EventTicketFactory {
    return Factory.get(EventTicketFactory, options);
  }
  public static customField(options?: FactoryOptions): RegistrationCustomFieldFactory {
    return Factory.get(RegistrationCustomFieldFactory, options);
  }
  public static slot(options?: FactoryOptions): SlotFactory {
    return Factory.get(SlotFactory, options);
  }

  public static stage(options?: FactoryOptions): StageFactory {
    return Factory.get(StageFactory, options);
  }
  public static eventStage(options?: FactoryOptions): EventStageFactory {
    return Factory.get(EventStageFactory, options);
  }
  public static form(options?: FactoryOptions): FormFactory {
    return Factory.get(FormFactory, options);
  }
  public static map(options?: FactoryOptions): MapFactory {
    return Factory.get(MapFactory, options);
  }
  public static rbac(options?: FactoryOptions): RBACFactory {
    return Factory.get(RBACFactory, options);
  }
  public static serviceHook(options?: FactoryOptions): ServiceHookFactory {
    return Factory.get(ServiceHookFactory, options);
  }
  public static adminUser(options?: FactoryOptions): AdminUserFactory {
    return Factory.get(AdminUserFactory, options);
  }
}
