export interface Notification {
  _id: string;
  internalName: string;
  createdBy: string;
  createdAt: Date;
  local: Record<string, NotificationLocal>;
  data?: NotificationData | EventPageNotificationData;
}

export interface NotificationLocal {
  title: string;
  text: string;
}
interface NotificationData {
  type: 'OpenApp' | 'OpenEventPage';
}

interface EventPageNotificationData extends NotificationData {
  type: 'OpenEventPage';
  event: string;
  page?: string;
}

interface AppNotificationData extends NotificationData {
  type: 'OpenApp';
  event?: string;
}

export function isEventPageNotificationData(object: any): object is EventPageNotificationData {
  return object?.type === 'OpenEventPage';
}

export function isAppNotificationData(object: any): object is AppNotificationData {
  return object?.type === 'OpenApp';
}
