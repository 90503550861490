export type Device =
  | {
      type: 'desktop';
      label: string;
      icon?: string;
    }
  | {
      type: 'mobile';
      label: string;
      width: number;
      height: number;
      viewport?: string;
      zoom?: number;
      icon?: string;
    };

export type ViewMode =
  | {
      type: 'web';
      label: string;
      icon?: string;
    }
  | {
      type: 'app';
      label: string;
      icon?: string;
    };

export const viewModels: ViewMode[] = [
  {
    type: 'web',
    label: 'Web',
    icon: 'pi icon-ic-language',
  },
  {
    type: 'app',
    label: 'App',
    icon: 'pi icon-ic-devices-preview-phone',
  },
];

export const devices: Device[] = [
  {
    type: 'desktop',
    label: 'Desktop',
    icon: 'pi icon-ic-devices-preview-desktop',
  },
  {
    type: 'mobile',
    label: 'Phone',
    width: 414,
    height: 736,
    icon: 'pi icon-ic-devices-preview-phone',
  },
  {
    type: 'mobile',
    label: 'Tablet',
    width: 1334,
    height: 750,
    icon: 'pi icon-ic-devices-preview-tablet',
  },
];
