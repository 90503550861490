import { DirectivesModule } from 'src/app/directives/directives.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';

import { ComponentsModule } from 'src/app/components/components.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

import { OperatingRegionsComponent } from './operating-regions.component';
import { SidebarModule } from 'primeng/sidebar';

const routes: Routes = [{ path: '', component: OperatingRegionsComponent }];

@NgModule({
  declarations: [OperatingRegionsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ButtonModule,
    FormsModule,
    TranslateModule,
    TableModule,
    CardModule,
    CheckboxModule,
    ComponentsModule,
    PipesModule,
    DirectivesModule,
    SidebarModule,
  ],
  exports: [RouterModule],
})
export class OperatingRegionsModule {}
