import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ComponentsModule } from 'src/app/components/components.module';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QRCodesComponent } from './qr-codes.component';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';

const routes: Routes = [{ path: '', component: QRCodesComponent }];

@NgModule({
  declarations: [QRCodesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TableModule,
    FormsModule,
    TranslateModule,
    ButtonModule,
    DividerModule,
    ComponentsModule,
    SplitButtonModule,
    PipesModule,
    DirectivesModule,
    SidebarModule,
    DropdownModule,
  ],
  exports: [RouterModule],
})
export class QRCodesModule {}
