import * as yup from 'yup';

// Http

export interface IQuery {
  [key: string]: string | number | boolean | IFilterList | undefined | string[];
}

export interface IDeleteQuery extends IQuery {
  ids: string[];
}

export interface IListQuery extends IQuery {
  limit?: number;
  skip?: number;
}

export interface ISortableListQuery extends IListQuery {
  orderBy?: string;
  orderDirection?: number;
}

export interface IFilterableListQuery extends ISortableListQuery {
  filter?: IFilterList;
}

export interface IResponse {}
export interface IListResponse<T> extends IResponse {
  items: T[];
  totalCount: number;
  additionalCount?: number;
}
export interface ITreeResponse<T> extends IResponse {}
export interface IResponseError {
  errorTag: string;
  message?: string;
}

export interface IBody {}

// Websocket
export interface IMessage {}

// Filters

export const filterMatchModes = [
  'in',
  'startsWith',
  'endsWith',
  'equals',
  'notEquals',
  'exists',
  'contains',
  'notContains',
  'dateIs',
  'dateIsNot',
  'dateBefore',
  'dateAfter',
  'gt',
  'gte',
  'lt',
  'lte',
  'equalsObjectId',
  'notEqualsObjectId',
  'inObjectId'
] as const;
export type FilterMatchMode = (typeof filterMatchModes)[number];
export const filterMatchModeValidator = yup
  .string()
  .oneOf([...filterMatchModes])
  .notRequired();

export const filterOperators = ['and', 'or'] as const;
export type FilterOperator = (typeof filterOperators)[number];
export const filterOperatorValidator = yup
  .string()
  .oneOf([...filterOperators])
  .notRequired();

export const filterFieldValidator = yup.object({
  matchMode: filterMatchModeValidator,
  operator: filterOperatorValidator,
  caseInsensitive: yup.boolean().notRequired()
});

export type FilterValue = string | number | boolean | string[] | IFilterList;

export interface IFilterCondition {
  value?: FilterValue;
  matchMode?: FilterMatchMode;
  operator?: FilterOperator;
  caseInsensitive?: boolean;
}

export interface IFilterList {
  [filterKey: string]: IFilterCondition;
}
