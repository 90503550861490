<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'GENERAL_EMAIL_TEMPLATES' | translate }}</h1>
  </div>
  <div class="tab-menu"></div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_EMAIL_TEMPLATES' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button *hasRight="'configuration.create'" [label]="'PAGE_EMAIL_CONFIGURATION_NEW' | translate" icon="pi icon-ic-add-item" (onClick)="newEmailConfiguration()"></p-button>
      </div>
    </div>
    <c-table mode="query" [options]="tableOptions" [tableOptionsComponent]="toc" (query)="query($event)" [baseRoute]="'/configuration/email-templates'" [routePathProperty]="'_id'">
      <ng-template let-email_template>
        <div *hasRight="['configuration.read', 'configuration.edit']">{{ email_template.internalName }}</div>
        <span *hasNoRight="['configuration.read', 'configuration.edit']">{{ email_template.internalName }}</span>
      </ng-template>
      <ng-template let-email_template>
        {{ email_template.emailTemplateType | titlecase }}
      </ng-template>
    </c-table>
  </div>
</div>
