<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'GENERAL_STATIC_CONTENTS' | translate }}</h1>
  </div>
  <div class="tab-menu"></div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_STATIC_CONTENTS' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
      </div>
    </div>
    <c-table mode="query" [options]="tableOptions" [tableOptionsComponent]="toc" (query)="query($event)" [baseRoute]="'/configuration/static-contents'" [routePathProperty]="'shortId'">
      <ng-template let-content>
        <a *hasRight="['configuration.read', 'configuration.edit']">{{ content.internalName }}</a>
        <span *hasNoRight="['configuration.read', 'configuration.edit']">{{ content.internalName }}</span>
      </ng-template>
    </c-table>
  </div>
</div>
