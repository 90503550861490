<div class="layout-menu">
  <div *ngFor="let eventPhase of eventPhases; trackBy: trackByIndex">
    <div class="item">Phase {{ eventPhase | titlecase }}</div>
    <div
      class="sub-item"
      *ngFor="let menuItem of context.eventVersion.phase[eventPhase][tab]; let index = index; let last = last; let first = first"
      (click)="editMenuItem(eventPhase, menuItem); $event.stopPropagation()"
    >
      <div class="title">{{ menuItemTitle(menuItem) }}</div>
      <div *hasRight="'events.editContent'" class="actions">
        <i class="pi pi-chevron-up" (click)="menuItemUp(eventPhase, menuItem); $event.stopPropagation()" *ngIf="!first"></i>
        <i class="pi pi-chevron-down" (click)="menuItemDown(eventPhase, menuItem); $event.stopPropagation()" *ngIf="!last"></i>
        <i class="pi pi-trash" (click)="confirmDeleteMenuItem($event, eventPhase, menuItem); $event.stopPropagation()"></i>
        <i class="pi icon-ic-edit" (click)="editMenuItem(eventPhase, menuItem); $event.stopPropagation()"></i>
      </div>
    </div>
    <div class="sub-item add" *ngIf="!(context.eventVersion | isDisabled)">
      <button
        *hasRight="'events.editContent'"
        pButton
        type="button"
        class="p-button-sm p-button-outlined"
        [label]="'GENERAL_ADD' | translate"
        icon="pi icon-ic-add-item"
        (click)="edit = { mode: 'selectType', eventPhase: eventPhase }"
      ></button>
    </div>
  </div>
</div>

<c-sidebar [visible]="!!edit" (visibleChange)="edit = $event ? edit : null" [header]="getEditTitle()">
  <div class="sidebar-search" *ngIf="edit?.mode === 'selectType'">
    <div *ngIf="tab === 'appMenuItems'; else elseBlock">Please select a App Navigation Button Type:</div>
    <ng-template #elseBlock>Please select a Mobile Access Button Type:</ng-template>
    <div class="search-results">
      <div class="search-result text" (click)="addMenuItem(edit.eventPhase, 'Page')">
        Page
        <div class="description">Select a page from the current event phase</div>
      </div>
      <div class="search-result text" (click)="addMenuItem(edit.eventPhase, 'Link')">
        Link
        <div class="description">Enter any link, e.g. a link to an external website</div>
      </div>
      <div class="search-result text" (click)="addMenuItem(edit.eventPhase, 'Dialog')">
        Dialog
        <div class="description">Display a dialog, e.g. for event registration</div>
      </div>
      <div class="search-result text" (click)="addMenuItem(edit.eventPhase, 'Form')">
        Form
        <div class="description">Select a form that is defined in the Data&nbsp;&rArr;&nbsp;Forms section</div>
      </div>
      <div *ngIf="tab === 'appMenuItems'" class="search-result text" (click)="addMenuItem(edit.eventPhase, 'AppFunction')">
        App Function
        <div class="description">If an app is activated for this event, this type can trigger functions and actions in the app</div>
      </div>
    </div>
  </div>
  <div *ngIf="edit?.mode === 'edit'">
    <c-page-patch-input [jsonpath]="[jsonpathEdit, '$.menuItemType']" [jsonpathParams]="jsonpathParamsEdit" [object]="context.eventVersion || context.page"></c-page-patch-input>
    <c-page-patch-input [jsonpath]="[jsonpathEdit, '$.local.$language.title']" [jsonpathParams]="jsonpathParamsEdit" [object]="context.eventVersion || context.page"></c-page-patch-input>
    <c-page-patch-input
      [jsonpath]="[jsonpathEdit, '$.local.$language.link']"
      *ngIf="edit.menuItem.menuItemType === 'Link'"
      [jsonpathParams]="jsonpathParamsEdit"
      [object]="context.eventVersion || context.page"
    ></c-page-patch-input>
    <c-page-patch-input
      [jsonpath]="[jsonpathEdit, '$.page']"
      *ngIf="edit.menuItem.menuItemType === 'Page'"
      [jsonpathParams]="jsonpathParamsEdit"
      [object]="context.eventVersion || context.page"
      [inputs]="inputs"
    ></c-page-patch-input>
    <c-page-patch-input
      [jsonpath]="[jsonpathEdit, '$.dialog']"
      *ngIf="edit.menuItem.menuItemType === 'Dialog'"
      [jsonpathParams]="jsonpathParamsEdit"
      [object]="context.eventVersion || context.page"
      [inputs]="inputs"
    ></c-page-patch-input>
    <c-page-patch-input
      [jsonpath]="[jsonpathEdit, '$.appFunction.action']"
      *ngIf="edit.menuItem.menuItemType === 'AppFunction'"
      [jsonpathParams]="jsonpathParamsEdit"
      [object]="context.eventVersion || context.page"
      [inputs]="inputs"
    ></c-page-patch-input>
    <c-page-patch-input
      [jsonpath]="[jsonpathEdit, '$.appFunction.local.$language.actionData']"
      *ngIf="edit.menuItem.menuItemType === 'AppFunction'"
      [jsonpathParams]="jsonpathParamsEdit"
      [object]="context.eventVersion || context.page"
      [inputs]="inputs"
    ></c-page-patch-input>
    <c-page-patch-input
      [jsonpath]="[jsonpathEdit, '$.form']"
      *ngIf="edit.menuItem.menuItemType === 'Form'"
      [jsonpathParams]="jsonpathParamsEdit"
      [object]="context.eventVersion || context.page"
    ></c-page-patch-input>
    <c-page-patch-input
      [jsonpath]="[jsonpathEdit, '$.icon']"
      [jsonpathParams]="jsonpathParamsEdit"
      [object]="context.eventVersion || context.page"
      [inputConfiguration]="{ type: 'imageasset', position: 'left', mimeTypes: ['image/svg+xml'] }"
    ></c-page-patch-input>
    <c-page-patch-input
      [jsonpath]="[jsonpathEdit, '$.hoverIcon']"
      [jsonpathParams]="jsonpathParamsEdit"
      [object]="context.eventVersion || context.page"
      [inputConfiguration]="{ type: 'imageasset', position: 'left', mimeTypes: ['image/svg+xml'] }"
    ></c-page-patch-input>
    <c-page-patch-input
      [jsonpath]="[jsonpathEdit, '$.activeIcon']"
      [jsonpathParams]="jsonpathParamsEdit"
      [object]="context.eventVersion || context.page"
      [inputConfiguration]="{ type: 'imageasset', position: 'left', mimeTypes: ['image/svg+xml'] }"
    ></c-page-patch-input>
    <c-page-patch-input
      [jsonpath]="[jsonpathEdit, '$.readAccess']"
      [jsonpathParams]="jsonpathParamsEdit"
      [object]="context.eventVersion || context.page"
      [inputConfiguration]="inputConfigurationReadAccess"
    ></c-page-patch-input>
    <c-page-patch-input
      [jsonpath]="[jsonpathEdit, '$.executeAccess']"
      [jsonpathParams]="jsonpathParamsEdit"
      [object]="context.eventVersion || context.page"
      [inputConfiguration]="inputConfigurationExecuteAccess"
    ></c-page-patch-input>
    <c-page-patch-input
      [jsonpath]="[jsonpathEdit, '$.local.$language.topTitle']"
      *ngIf="tab === 'mobileMenuItems'"
      [jsonpathParams]="jsonpathParamsEdit"
      [object]="context.eventVersion || context.page"
    ></c-page-patch-input>
    <c-page-patch-input
      [jsonpath]="[jsonpathEdit, '$.showAsCircleButton']"
      *ngIf="tab === 'mobileMenuItems' || tab === 'appMenuItems'"
      [jsonpathParams]="jsonpathParamsEdit"
      [object]="context.eventVersion || context.page"
    ></c-page-patch-input>
  </div>
</c-sidebar>

<p-confirmPopup></p-confirmPopup>
