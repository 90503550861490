<p-card class="tiered-menu">
  <ng-template pTemplate="header">
    <div class="card-header">
      {{ 'GENERAL_LOCAL_PROPERTIES' | translate }}
      <a class="delete-link" *ngIf="currentLanguage" (click)="deleteLocal(currentLanguage)" [routerLink]="[]" [queryParamsHandling]="'merge'" [ngClass]="{ 'is-disabled': deleteDisabled }"
        >Delete Language</a
      >
    </div>
  </ng-template>
  <c-skeleton *ngIf="skeleton" [menu]="true" [fields]="['text', 'text', 'text']"></c-skeleton>

  <c-select-menu
    [labelAddButton]="'GENERAL_LOCAL_PROPERTIES_ADD_LANGUAGE' | translate"
    [(currentItem)]="currentLanguage"
    [items]="activeLanguages | selectMenuItems : 'language' : 'languageName'"
    [selectedItems]="selectedLanguages"
    [invalidItems]="invalidLanguages"
    (selectedItemsChange)="selectedItemsChange($event)"
    (currentItemChange)="currentItemChange()"
  >
  </c-select-menu>

  <div *ngIf="currentLanguage" class="tiered-menu-content">
    <ng-content></ng-content>
  </div>
</p-card>
