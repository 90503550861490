<div (click)="selectedSpeaker || openSearch()">
  <c-speaker-item
    *ngIf="selectedSpeaker"
    (click)="openSearch()"
    [asset]="allTeaserAssets[selectedSpeaker._id]"
    [displayName]="selectedSpeaker.displayName"
    [title]="selectedSpeaker.local[currentLanguage]?.title"
  ></c-speaker-item>
  <div class="actions as-layer" *ngIf="selectedSpeaker && !this.disabled">
    <span>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openSearch()">{{ 'GENERAL_SEARCH' | translate }}</a>
      <span *ngIf="!hideRemoveLink">&nbsp;/&nbsp;<a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="removeSpeaker()">Remove</a>&nbsp;</span>
    </span>
  </div>
  <div class="actions" *ngIf="!selectedSpeaker && !this.disabled">
    <span>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openSearch()">{{ 'GENERAL_SEARCH' | translate }}</a>
    </span>
  </div>
  <div class="actions" *ngIf="this.disabled && !selectedSpeaker">
    <span> &nbsp;&nbsp; </span>
  </div>
</div>

<c-sidebar [header]="'COMPONENT_SPEAKER_SELECT_SEARCH_HEADER' | translate" [(visible)]="showSearch">
  <div *ngIf="searchResult" class="sidebar-search">
    <div class="search-input input-group compact">
      <div class="input">
        <input [placeholder]="'Search ' + (templateSearch ? 'template' : 'Speaker') + ' ...'" pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
      </div>
    </div>
    <div infinite-scroll [scrollWindow]="false" (scrolled)="executeSearch()" class="search-results">
      <div class="search-result" *ngFor="let spk of searchResult" (click)="selectSpeaker(spk)">
        <c-speaker-item [asset]="allTeaserAssets[spk._id]" [displayName]="spk.displayName" [title]="spk.local[currentLanguage]?.title"></c-speaker-item>
      </div>
    </div>
  </div>
</c-sidebar>
