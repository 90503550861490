import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Observable, of } from 'rxjs';
import { mergeMap, scan } from 'rxjs/operators';
import { IBody, IQuery, IResponse } from 'src/common/api/interfaces';
import { Environment } from 'src/environments/environment';

export interface Upload<R> {
  file?: File;
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
  progress: number;
  response?: R;
}

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
  return event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public get endpoint(): string {
    return this.environment.apiEndpoint;
  }

  constructor(
    private httpClient: HttpClient,
    // private msalService: MsalService,
    private environment: Environment
  ) {}

  token(): Observable<string> {
    // return this.msalService.tokenAsObservable();
    return of(localStorage.getItem('token'));
  }

  get<Q extends IQuery, R extends IResponse>(url: string, query?: Q, headers?: any): Observable<R> {
    return this.token().pipe(
      mergeMap((token) => {
        return this.httpClient.get<R>(`${this.endpoint}${url}${this.queryParams(query)}`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            ...headers,
          },
        });
      })
    );
  }

  post<Q extends IQuery, B extends IBody, R extends IResponse>(url: string, body: B, query?: Q, headers?: any): Observable<R> {
    return this.token().pipe(
      mergeMap((token) => {
        return this.httpClient.post<R>(`${this.endpoint}${url}${this.queryParams(query)}`, body, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            ...headers,
          },
        });
      })
    );
  }

  postWithHttpResponse<Q extends IQuery, B extends IBody, R extends IResponse>(url: string, body: B, query?: Q, headers?: any): Observable<HttpResponse<R>> {
    return this.token().pipe(
      mergeMap((token) => {
        return this.httpClient.post<R>(`${this.endpoint}${url}${this.queryParams(query)}`, body, {
          withCredentials: true,
          observe: 'response',
          headers: {
            Authorization: `Bearer ${token}`,
            ...headers,
          },
        });
      })
    );
  }

  upload<Q extends IQuery, R extends IResponse>(url: string, file: File, data?: { [key: string]: string }, query?: Q): Observable<Upload<R>> {
    const form = new FormData();
    form.append('file', file, file.name);

    for (const key in data || {}) {
      form.append(key, data[key]);
    }

    const initialState: Upload<R> = { state: 'PENDING', progress: 0 };
    const calculateState = (upload: Upload<R>, event: HttpEvent<unknown>): Upload<R> => {
      if (isHttpProgressEvent(event)) {
        return {
          progress: event.total ? Math.round((100 * event.loaded) / event.total) : upload.progress,
          state: 'IN_PROGRESS',
        };
      }
      if (isHttpResponse(event)) {
        return {
          progress: 100,
          state: 'DONE',
          response: event.body as R,
        };
      }
      return upload;
    };

    return this.token().pipe(
      mergeMap((token) => {
        return this.httpClient
          .post(`${this.endpoint}${url}${this.queryParams(query)}`, form, {
            withCredentials: true,
            reportProgress: true,
            observe: 'events',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .pipe(scan(calculateState, initialState));
      })
    );
  }

  download<Q extends IQuery>(url: string, fileName: string, mimeType: string, query?: Q, headers?: any) {
    const token = localStorage.getItem('token');
    this.httpClient
      .get(`${this.endpoint}${url}${this.queryParams(query)}`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
          ...headers,
        },
        responseType: 'arraybuffer',
      })
      .subscribe((response) => this.downLoadFile(response, fileName, mimeType));
  }

  blob<Q extends IQuery>(url: string, query?: Q, headers?: any) {
    const token = localStorage.getItem('token');
    return this.httpClient.get(`${this.endpoint}${url}${this.queryParams(query)}`, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
      },
      responseType: 'blob',
    });
  }

  private downLoadFile(data: any, fileName: string, mimeType: string) {
    const blob = new Blob([data], { type: mimeType });
    const file = new File([blob], fileName, { type: mimeType });
    FileSaver.saveAs(file);
  }

  private queryParams(query?: IQuery): string {
    if (!query) return '';
    return (
      '?' +
      Object.keys(query)
        .filter((q) => typeof query[q] !== 'undefined')
        .map((q) => `${q}=${encodeURIComponent(this.queryParam(query[q] as any))}`)
        .join('&')
    );
  }

  private queryParam(val: any): string {
    if (typeof val === 'string') return val;
    if (typeof val === 'number') return val.toString();
    if (typeof val === 'boolean') return val ? 'true' : 'false';
    if (typeof val === 'object') return JSON.stringify(val);
    return '';
  }
}
