import { NgIf } from '@angular/common';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Directive({
  selector: '[hasNoRight]',
})
export class HasNoRightDirective {
  private _rights: string[];
  private _ngIfDirective: NgIf;

  @Input()
  public set hasNoRight(val: string | string[] | { resource: string; rights: string | string[] }) {
    if (val) {
      if (typeof val === 'string') {
        this._rights = [val];
      } else if (Array.isArray(val)) {
        this._rights = val;
      } else {
        const rights = typeof val.rights === 'string' ? [val.rights] : val.rights;
        this._rights = rights.map((r) => `${val.resource}$${r}`);
      }
    }
    this.setNgIf();
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthService) {
    this._ngIfDirective = new NgIf(this.viewContainer, this.templateRef);
    this.setNgIf();
  }

  async setNgIf() {
    if (this._rights) {
      if (this._rights.length > 0) {
        if (this.authService.hasGlobalRight(this._rights)) {
          this._ngIfDirective.ngIf = false;
        } else {
          this._ngIfDirective.ngIf = !(await this.authService.hasRight(this._rights));
        }
      } else {
        this._ngIfDirective.ngIf = false;
      }
    }
  }
}
