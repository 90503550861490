<c-sidebar-header [headerTitle]="'GENERAL_PAGEMODULE_EDIT'" [headerSubTitle]="'GENERAL_PAGEMODULETYPE_' + pageModule.pageModuleType.toUpperCase()" (onClose)="onClose.emit(true)"></c-sidebar-header>

<c-sidebar-body>
  <div *ngIf="!noEditComponent; else elseBlock" class="sidebar-outer-body">
    <template #child></template>
  </div>
  <ng-template #elseBlock>
    <div class="sidebar-body">No configurations required for this event stage module</div>
  </ng-template>
</c-sidebar-body>

<c-sidebar-footer [autoSaveInfo]="true" (onClose)="onClose.emit(true)"></c-sidebar-footer>
