import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdminUsersService } from 'src/app/services/admin-users/admin-users.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { groupValidator } from 'src/common/api/v1/configuration/groups/validators/GroupValidator';
import { AdminUser } from 'src/common/entities/AdminUser';
import { Group } from 'src/common/entities/Group';
import { Role } from 'src/common/entities/Role';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  providers: [ConfirmationService]
})
export class GroupComponent implements OnInit, OnDestroy {
  groupId: string = 'undefined';
  group: Group | null = null;
  tab: Tab;

  groupMembers: AdminUser[] = [];
  newMembers: string[] = [];
  newMembersResult: { [identifier: string]: boolean };

  usedRoles: Role[] = [];
  allRoles: Role[] = [];
  filteredRoles: Role[] = [];

  loading: boolean = true;
  saving: boolean = false;
  saveMode: string = null;

  paramsSubscription: Subscription;

  private _ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private router: Router,
    private validatorService: ValidatorService,
    public utilsService: UtilsService,
    private configurationService: ConfigurationService,
    private adminUsersService: AdminUsersService,
    private tabsService: TabsService
  ) {
    this.tab = this.tabsService.register({
      category: 'configuration',
      parent: '/configuration/groups',
      loading: true,
      route: this.activatedRoute.snapshot
    });
  }

  async ngOnInit(): Promise<void> {
    this.paramsSubscription = this.activatedRoute.params.pipe(takeUntil(this._ngUnsubscribe)).subscribe(async params => {
      this.groupId = params.groupId;

      this.allRoles = (await this.configurationService.getRoles()).items;
      this.allRoles.sort((a, b) => (a.name > b.name ? 1 : a.name === b.name ? 0 : -1));
      this.filteredRoles = this.allRoles;

      if (this.groupId === 'new') {
        this.group = {
          _id: '',
          name: '',
          roles: [],
          default: false
        };
        this.loading = false;
      } else if (this.groupId) {
        await this.retrieveData();
      }
    });
  }

  ngOnDestroy() {
    // Unsubscribe subscriptions
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  async retrieveData() {
    this.loading = true;
    try {
      this.group = await this.configurationService.getGroup(this.groupId);

      // Set up usedRoles to get p-autoComplete component going
      this.usedRoles = this.group.roles.map(roleInUse => this.allRoles.find(role => role._id == roleInUse));

      // Set up filteredRoles for use as p-autoComplete components suggestions list
      this.filterRoles();

      // Get users in group
      this.groupMembers = (
        await this.adminUsersService.getAdminUsers({
          source: 'Local',
          filter: {
            groups: {
              matchMode: 'equalsObjectId',
              value: this.groupId
            }
          }
        })
      ).items;
    } catch (err) {}
    this.loading = false;
  }

  async onSave() {
    this.saving = true;
    try {
      if (this.group) {
        // Transfer usedRoles to group.roles for saving
        this.group.roles = this.usedRoles.map(roleInUse => roleInUse._id);

        if (this.groupId === 'new') {
          this.group = await this.configurationService.createGroup(this.group);
          await this.router.navigate(['/configuration/groups', this.group._id], {
            replaceUrl: true
          });
          this.tabsService.close(this.tab);
        } else {
          await this.configurationService.updateGroup(this.group);
          this.retrieveData();
        }
      }
    } catch (err) {}
    this.saving = false;
  }

  async addAdminUsers() {
    this.saving = true;
    this.saveMode = 'add';
    this.newMembersResult = {};
    try {
      this.newMembersResult = await this.adminUsersService.membership({
        identifiers: this.newMembers,
        group: this.groupId,
        action: 'add'
      });

      this.newMembers = this.newMembers.filter(m => !this.newMembersResult[m]);

      await this.retrieveData();
    } catch (err) {}
    this.saving = false;
  }

  async removeAdminUser(userId: string) {
    this.saving = true;
    this.saveMode = 'remove';
    this.newMembersResult = {};
    try {
      this.newMembersResult = await this.adminUsersService.membership({
        identifiers: [userId],
        group: this.groupId,
        action: 'remove'
      });

      await this.retrieveData();
    } catch (err) {}
    this.saving = false;
  }

  async confirmRemoveAdminUser(userId: string) {
    this.confirmationService.confirm({
      header: await this.utilsService.translate('PAGE_ADMINUSER_GROUP_USER_DELETE_HEADER'),
      message: await this.utilsService.translate('GENERAL_DELETE_QUESTION'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removeAdminUser(userId);
      }
    });
  }

  filterRoles(event?) {
    // Only unused roles are listed in suggestions
    this.filteredRoles = [];
    this.allRoles.forEach(role => {
      if (!this.usedRoles.find(roleInUse => roleInUse._id == role._id)) {
        this.filteredRoles.push(role);
      }
    });

    // Apply filter expression to suggestions list
    if (event && event.query) {
      this.filteredRoles = this.filteredRoles.filter(role => role.name.toLowerCase().includes(event.query.toLowerCase()));
    }
  }

  isValid(): boolean {
    return this.validatorService.isValid(groupValidator, this.group);
  }

  errorAt(path: string): string | null {
    return this.validatorService.errorAt(groupValidator, this.group, path);
  }
}
