<div>
  <div class="p-d-inline-flex">
    <p-calendar [(ngModel)]="startDate" [showTime]="false" (onSelect)="changedStart()"></p-calendar>
    <p-inputMask mask="99:99" [(ngModel)]="startTime" placeholder="HH:MM" slotChar="HH:MM" styleClass="timeSelector" class="p-ml-2" (onComplete)="changedStart()"></p-inputMask>
    <p class="p-ml-2 p-mr-2">{{ 'GENERAL_DATES_UNTIL' | translate }}</p>
    <p-calendar [(ngModel)]="endDate" [showTime]="false" (onSelect)="changedEnd()"></p-calendar>
    <p-inputMask mask="99:99" class="p-ml-2" [(ngModel)]="endTime" placeholder="HH:MM" slotChar="HH:MM" styleClass="timeSelector" (onComplete)="changedEnd()"></p-inputMask>
  </div>
  <div>
    <ng-content></ng-content>
    <div class="error-description" *ngIf="hasError">{{ error }}</div>
  </div>
</div>
