import { ActivatedRoute } from '@angular/router';
import { TabsService } from './../../tabs/services/tabs.service';
import { TableOptions, TableQuery } from './../../components/table/table.interfaces';
import { Tab } from './../../tabs/classes/tab';
import { Component, OnInit } from '@angular/core';

import { UtilsService } from 'src/app/services/utils/utils.service';

import { StaticContentConfigurationsService } from 'src/app/services/static-content-configurations/static-content-configurations.service';
import { StaticContentConfiguration } from 'src/common/entities/StaticContentConfiguration';

@Component({
  selector: 'app-static-contents',
  templateUrl: './static-contents.component.html',
  styleUrls: ['./static-contents.component.scss'],
})
export class StaticContentsComponent implements OnInit {
  tab: Tab;
  tableOptions: TableOptions<StaticContentConfiguration>;

  constructor(
    private staticContentConfigurationsService: StaticContentConfigurationsService,
    private utilsService: UtilsService,
    private tabsService: TabsService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.tab = this.tabsService.register({
      category: 'data',
      route: this.activatedRoute.snapshot,
      title: 'Static Content',
    });

    this.tableOptions = {
      size: 50,
      columns: [{ header: 'PAGE_STATIC_CONTENT_INTERNAL_NAME', sort: 'internalName' }],
      filters: [{ header: 'PAGE_STATIC_CONTENT_INTERNAL_NAME', path: 'internalName' }],
    };
  }

  query(query: TableQuery<StaticContentConfiguration>) {
    query.result = this.staticContentConfigurationsService.getStaticContentConfigurations(query.query);
  }
}
