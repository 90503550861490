import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';
import { SpacerPageModuleFactory } from '../../factories/pagemodules/SpacerPageModuleFactory';

export const spacerPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('Spacer'),

  "$.pageModules[?(@.pageModuleType=='Spacer' && @._id=='$pageModuleId')].heightBreakpoint": {
    factory: async () => new SpacerPageModuleFactory().spacerPageModuleHeight({}),
  },
  "$.pageModules[?(@.pageModuleType=='Spacer' && @._id=='$pageModuleId')].heightBreakpoint.desktop": {
    header: 'Height Desktop Size',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='Spacer' && @._id=='$pageModuleId')].heightBreakpoint.desktopLarge": {
    header: 'Height Desktop Large Size',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='Spacer' && @._id=='$pageModuleId')].heightBreakpoint.desktopWidescreen": {
    header: 'Height Desktop Widescreen Size',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='Spacer' && @._id=='$pageModuleId')].heightBreakpoint.tablet": {
    header: 'Height Tablet Size',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='Spacer' && @._id=='$pageModuleId')].heightBreakpoint.tabletLarge": {
    header: 'Height Tablet Large Size',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='Spacer' && @._id=='$pageModuleId')].heightBreakpoint.mobile": {
    header: 'Height Mobile Size',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='Spacer' && @._id=='$pageModuleId')].heightBreakpoint.mobileLarge": {
    header: 'Height Mobile Large Size',
    type: 'number',
  },
};
