<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'PAGE_ONBOARDINGS' | translate }}</h1>
  </div>
  <div class="tab-menu">
    <div class="tab-menu">
      <c-language-button [languages]="languages" [(currentLanguage)]="currentLanguage" (languagesChange)="ensureLanguages($event)"></c-language-button>
    </div>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_ONBOARDINGS' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button [label]="'PAGE_ONBOARDINGS_NEW_ONBOARDING' | translate" icon="pi icon-ic-add-item" (onClick)="createOnboarding()"></p-button>
      </div>
    </div>
    <c-table #onboardingsTable mode="query" [options]="tableOptions" [tableOptionsComponent]="toc" (query)="query($event)" [queryParamsHandling]="'merge'" (itemSelect)="selectOnboarding($event)">
      <ng-template let-onboardingItem let-index="index">
        {{ onboardingItem.internalName || ('GENERAL_EMPTY' | translate) }}
      </ng-template>
      <ng-template let-onboarding> {{ onboarding.steps.length ?? 0 }} {{ 'PAGE_ONBOARDINGS_STEPS' | translate }} </ng-template>
      <ng-template let-onboarding>
        {{ (onboarding.local[currentLanguage].description | shorten : 40) || ('GENERAL_EMPTY' | translate) }}
      </ng-template>
    </c-table>
  </div>
</div>

<p-sidebar [(visible)]="showEditSidebar" styleClass="p-sidebar-st" position="right" [modal]="false" [showCloseIcon]="false">
  <c-sidebar-header [headerTitle]="edit_mode ? 'Edit Onboarding' : 'Add Onboarding'" (onClose)="showEditSidebar = false"> </c-sidebar-header>

  <div class="sidebar-tabs">
    <button [class.active]="sidebarTab === 'general'" (click)="sidebarTab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
    <button [class.active]="sidebarTab === 'steps'" (click)="sidebarTab = 'steps'">{{ 'PAGE_ONBOARDINGS_ONBOARDING_STEPS' | translate }}</button>
  </div>

  <div *ngIf="onboardingsIndex >= 0 && sidebarTab === 'general'" class="sidebar-body">
    <c-collaboration-patch-input [object]="onboarding" [collaborationKey]="'onboarding:' + onboarding._id" [jsonpath]="'$.internalName'" [inputs]="inputs"> </c-collaboration-patch-input>
    <c-collaboration-patch-input
      [object]="onboarding"
      [collaborationKey]="'onboarding:' + onboarding._id"
      [jsonpath]="'$.local.$language.description'"
      [inputs]="inputs"
      [inputConfiguration]="inputConfig"
    >
    </c-collaboration-patch-input>
    <c-collaboration-patch-input [object]="onboarding" [collaborationKey]="'onboarding:' + onboarding._id" [jsonpath]="'$.showOnboarding'" [inputs]="inputs" [inputConfiguration]="inputConfig">
    </c-collaboration-patch-input>
    <div *ngIf="onboarding.showOnboarding === true">
      <c-collaboration-patch-input [object]="onboarding" [collaborationKey]="'onboarding:' + onboarding._id" [jsonpath]="'$.startingPageImage'" [inputs]="inputs" [inputConfiguration]="inputConfig">
      </c-collaboration-patch-input>
      <c-collaboration-patch-input
        [object]="onboarding"
        [collaborationKey]="'onboarding:' + onboarding._id"
        [jsonpath]="'$.startingPageImageMobile'"
        [inputs]="inputs"
        [inputConfiguration]="inputConfig"
      ></c-collaboration-patch-input>
      <c-collaboration-patch-input
        [object]="onboarding"
        [collaborationKey]="'onboarding:' + onboarding._id"
        [jsonpath]="'$.startingPageImageTablet'"
        [inputs]="inputs"
        [inputConfiguration]="inputConfig"
      ></c-collaboration-patch-input>
      <c-collaboration-patch-input
        [object]="onboarding"
        [collaborationKey]="'onboarding:' + onboarding._id"
        [jsonpath]="'$.local.$language.startButtonLabel'"
        [inputs]="inputs"
        [inputConfiguration]="inputConfig"
      >
      </c-collaboration-patch-input>
    </div>
    <c-collaboration-patch-input [object]="onboarding" [collaborationKey]="'onboarding:' + onboarding._id" [jsonpath]="'$.forceLogin'" [inputs]="inputs" [inputConfiguration]="inputConfig">
    </c-collaboration-patch-input>
    <div *ngIf="onboarding.forceLogin === true">
      <!--      Login data-->
      <c-collaboration-patch-input
        [object]="onboarding"
        [collaborationKey]="'onboarding:' + onboarding._id"
        [jsonpath]="'$.loginPageImage'"
        [inputs]="inputs"
        [inputConfiguration]="inputConfig"
      ></c-collaboration-patch-input>
      <c-collaboration-patch-input
        [object]="onboarding"
        [collaborationKey]="'onboarding:' + onboarding._id"
        [jsonpath]="'$.loginPageImageMobile'"
        [inputs]="inputs"
        [inputConfiguration]="inputConfig"
      ></c-collaboration-patch-input>
      <c-collaboration-patch-input
        [object]="onboarding"
        [collaborationKey]="'onboarding:' + onboarding._id"
        [jsonpath]="'$.loginPageImageTablet'"
        [inputs]="inputs"
        [inputConfiguration]="inputConfig"
      ></c-collaboration-patch-input>
      <c-collaboration-patch-input
        [object]="onboarding"
        [collaborationKey]="'onboarding:' + onboarding._id"
        [jsonpath]="'$.local.$language.loginPageTitle'"
        [inputs]="inputs"
        [inputConfiguration]="inputConfig"
      >
      </c-collaboration-patch-input>
      <c-collaboration-patch-input
        [object]="onboarding"
        [collaborationKey]="'onboarding:' + onboarding._id"
        [jsonpath]="'$.local.$language.loginPageText'"
        [inputs]="inputs"
        [inputConfiguration]="inputConfig"
      >
      </c-collaboration-patch-input>
    </div>
  </div>
  <div *ngIf="onboardingsIndex >= 0 && sidebarTab === 'steps'" class="sidebar-body">
    <c-collaboration-patch-input
      *ngIf="inputs"
      [jsonpath]="'$.steps'"
      [collaborationKey]="'onboarding:' + onboarding._id"
      [object]="onboarding"
      [inputConfiguration]="inputConfig"
      [inputs]="inputs"
    ></c-collaboration-patch-input>
  </div>
  <c-sidebar-footer [showSaveButton]="true" (onSave)="save(onboarding); showEditSidebar = false" [showDeleteButton]="edit_mode" (onDelete)="deleteOnboarding(onboarding); showEditSidebar = false">
  </c-sidebar-footer>
</p-sidebar>
