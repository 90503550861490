<div class="content" [class.noScroll]="noScroll" [class.fade-out]="currentIndex >= 0" [class.fade-in]="currentIndex < 0">
  <ng-content></ng-content>
</div>
<div class="elements" *ngIf="sidebarElements.length > 0" [class.left]="position === 'left'">
  <div
    class="element"
    *ngFor="let element of sidebarElements; trackBy: trackByIndex; let index = index; let first = first"
    [ngStyle]="{ top: 'calc(0.75rem + ' + index * 34 + 'px)', 'z-index': index * 100 }"
    [class.show]="index <= currentIndex"
    [class.fade-in-left]="position === 'left' && index <= currentIndex"
    [class.fade-in-right]="position === 'right' && index <= currentIndex"
  >
    <c-sidebar-element [active]="index === currentIndex" [element]="element"></c-sidebar-element>
  </div>
</div>

<!-- [@element]="index <= currentIndex ? 'show' :'hide'" -->
