import { DirectivesModule } from './../../directives/directives.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';

import { ComponentsModule } from 'src/app/components/components.module';

import { GroupsComponent } from './groups.component';

const routes: Routes = [{ path: '', component: GroupsComponent }];

@NgModule({
  declarations: [GroupsComponent],
  imports: [CommonModule, RouterModule.forChild(routes), ComponentsModule, TableModule, DividerModule, ButtonModule, TranslateModule, ComponentsModule, DirectivesModule],
  exports: [RouterModule],
})
export class GroupsModule {}
