<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'GENERAL_ADMINUSER_ROLES' | translate }}</h1>
  </div>
  <div class="tab-menu"></div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_ADMINUSER_ROLES' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button [label]="('GENERAL_NEW' | translate) + ' ' + ('GENERAL_ADMINUSER_ROLE' | translate)" icon="pi icon-ic-add-item" (onClick)="newRoles()"></p-button>
      </div>
    </div>
    <c-table mode="query" [options]="tableOptions" [tableOptionsComponent]="toc" (query)="query($event)" baseRoute="/configuration/roles" [routePathProperty]="'_id'">
      <ng-template let-role>
        {{ role.name }}
      </ng-template>
      <ng-template let-role>
        {{ role.type | titlecase }}
      </ng-template>
    </c-table>
  </div>
</div>
