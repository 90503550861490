import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { CacheContainer } from '../cache/cache-container';
import { CacheService } from '../cache/cache.service';
import { OperatingRegion } from 'src/common/entities/OperatingRegion';
import { GetOperatingRegionQuery, GetOperatingRegionResponse } from 'src/common/api/v1/operating-regions/GetOperatingRegion';
import { GetOperatingRegionsQuery, GetOperatingRegionsResponse } from 'src/common/api/v1/operating-regions/GetOperatingRegions';
import { PostOperatingRegionBody, PostOperatingRegionQuery, PostOperatingRegionResponse } from 'src/common/api/v1/operating-regions/PostOperatingRegion';

@Injectable({
  providedIn: 'root',
})
export class OperatingRegionsService {
  private _cache: CacheContainer<OperatingRegion>;

  constructor(private apiService: ApiService, private cacheService: CacheService) {
    this._cache = this.cacheService.create<OperatingRegion>({
      get: async (operatingRegionId) => await this.apiService.get<GetOperatingRegionQuery, GetOperatingRegionResponse>(`/api/v1/configuration/operating-regions/${operatingRegionId}`).toPromise(),
      socketEvents: ['operating-region:update'],
    });
  }

  async getOperatingRegions(query?: GetOperatingRegionsQuery): Promise<GetOperatingRegionsResponse> {
    const operatingRegions = await this.apiService
      .get<GetOperatingRegionsQuery, GetOperatingRegionsResponse>('/api/v1/configuration/operating-regions', {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();

    this._cache.fill(operatingRegions.items);

    return operatingRegions;
  }

  async getOperatingRegion(operatingRegionId: string): Promise<OperatingRegion> {
    return this._cache.asPromise(operatingRegionId);
  }

  async createOperatingRegion(operatingRegion: OperatingRegion): Promise<OperatingRegion> {
    return this._cache.post<PostOperatingRegionQuery, PostOperatingRegionBody, PostOperatingRegionResponse>('/api/v1/configuration/operating-regions', operatingRegion);
  }

  async updateOperatingRegion(operatingRegion: OperatingRegion): Promise<OperatingRegion> {
    return this._cache.post<PostOperatingRegionQuery, PostOperatingRegionBody, PostOperatingRegionResponse>(`/api/v1/configuration/operating-regions/${operatingRegion._id}`, operatingRegion);
  }

  deleteOperatingRegion(operatingRegionId: string): Promise<OperatingRegion> {
    return this._cache.post<PostOperatingRegionQuery, PostOperatingRegionBody, PostOperatingRegionResponse>(`/api/v1/configuration/operating-regions/${operatingRegionId}/delete`, {});
  }
}
