import { BreakpointValue } from './../../entities/Breakpoint';

export const TopAreaHeightDefaultBreakpointValue: BreakpointValue<number> = {
  desktop: 120,
  desktopLarge: 120,
  desktopWidescreen: 120,
  tablet: 120,
  tabletLarge: 120,
  mobile: 120,
  mobileLarge: 120,
};

export const ContentAreaHeightDefaultBreakpointValue: BreakpointValue<number> = {
  desktop: 440,
  desktopLarge: 440,
  desktopWidescreen: 440,
  tablet: 440,
  tabletLarge: 440,
  mobile: 440,
  mobileLarge: 440,
};

export const CTAAreaHeightDefaultBreakpointValue: BreakpointValue<number> = {
  desktop: 100,
  desktopLarge: 100,
  desktopWidescreen: 100,
  tablet: 100,
  tabletLarge: 100,
  mobile: 100,
  mobileLarge: 100,
};

export const StageHeightDefaultBreakpointValue: BreakpointValue<number> = {
  desktop: 680,
  desktopLarge: 700,
  desktopWidescreen: 720,
  tablet: 640,
  tabletLarge: 640,
  mobile: 540,
  mobileLarge: 540,
};

export const ButtonSpacingTopDefaultBreakpointValue: BreakpointValue<number> = {
  desktop: 190,
  desktopLarge: 195,
  desktopWidescreen: 260,
  tablet: 190,
  tabletLarge: 170,
  mobile: 100,
  mobileLarge: 110,
};
