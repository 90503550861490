import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageModule, PageModuleClipboardData } from '../../../common/entities/pagemodules/PageModule';

@Injectable({
  providedIn: 'root',
})
export class WindowMessageService {
  private iframeMessageSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private fromSessionStorage(name: string): PageModuleClipboardData | null {
    const data = sessionStorage.getItem(name);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  private toSessionStorage(name: string, pageModuleClipboardData: PageModuleClipboardData | null): void {
    sessionStorage.setItem(name, pageModuleClipboardData ? JSON.stringify(pageModuleClipboardData) : null);
  }

  get currentGlobalPageModuleClipboardData(): PageModuleClipboardData | null {
    return this.fromSessionStorage('currentGlobalPageModuleClipboardData');
  }

  set currentGlobalPageModuleClipboardData(pageModuleClipboardData: PageModuleClipboardData | null) {
    this.toSessionStorage('currentGlobalPageModuleClipboardData', pageModuleClipboardData);
  }

  get currentEventPageModuleClipboardData(): PageModuleClipboardData | null {
    return this.fromSessionStorage('currentEventPageModuleClipboardData');
  }

  set currentEventPageModuleClipboardData(pageModuleClipboardData: PageModuleClipboardData | null) {
    this.toSessionStorage('currentEventPageModuleClipboardData', pageModuleClipboardData);
  }

  public listeningForCurrentIframe(): Observable<any> {
    return this.iframeMessageSubject.asObservable();
  }

  public sendToCurrentIframe(context): void {
    const clipboardData = this.getContextCheckedClipboardData(context);
    if (clipboardData) {
      return this.iframeMessageSubject.next({ type: 'pagemodule:clipboardData', clipboardData: { ...clipboardData, pageModule: undefined } });
    } else {
      return this.iframeMessageSubject.next({ type: 'pagemodule:clipboardData', clipboardData: null });
    }
  }

  public setViewModeInCurrentIframe(viewMode: string): void {
    this.iframeMessageSubject.next({ type: 'page:viewMode', viewMode });
  }

  public getContextCheckedClipboardData(context): PageModuleClipboardData | null {
    if (!context.pageVersion && context.eventVersion.dirty) {
      const data = this.currentEventPageModuleClipboardData;
      if (data && data.type === 'event' && data.eventId === context.event?._id && data.eventVersionId === context.eventVersion?._id) {
        return data;
      }
    } else {
      const data = this.currentGlobalPageModuleClipboardData;
      if (data && data.type === 'global' && !(data.eventId || data.eventVersionId) && context.pageVersion?.dirty) {
        return data;
      }
    }

    return null;
  }

  createNewClipboardData(context: any, pageModule: PageModule): void {
    const clipboardData: PageModuleClipboardData = {
      type: context.pageVersion ? 'global' : 'event',
      pageModule: JSON.parse(JSON.stringify(pageModule)),
      pageModuleType: pageModule.pageModuleType,
      eventId: context.event?._id,
      eventVersionId: context.eventVersion?._id,
    };

    if (context.pageVersion) {
      this.currentGlobalPageModuleClipboardData = clipboardData;
    } else {
      this.currentEventPageModuleClipboardData = clipboardData;
    }
  }
}
