import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';

import { BlacklistEntry } from 'src/common/entities/BlacklistEntry';
import { GetBlacklistEntryQuery, GetBlacklistEntryResponse } from 'src/common/api/v1/configuration/blacklistEntries/GetBlacklistEntry';
import { GetBlacklistEntriesQuery, GetBlacklistEntriesResponse } from 'src/common/api/v1/configuration/blacklistEntries/GetBlacklistEntries';
import { PostBlacklistEntryBody, PostBlacklistEntryQuery, PostBlacklistEntryResponse } from 'src/common/api/v1/configuration/blacklistEntries/PostBlacklistEntry';
import { PostBlacklistEntriesBody, PostBlacklistEntriesQuery, PostBlacklistEntriesResponse } from 'src/common/api/v1/configuration/blacklistEntries/PostBlacklistEntries';

@Injectable({
  providedIn: 'root',
})
export class BlacklistEntriesService {
  constructor(private apiService: ApiService) {}

  getBlacklistEntry(id: string): Promise<BlacklistEntry> {
    return this.apiService.get<GetBlacklistEntryQuery, GetBlacklistEntryResponse>(`/api/v1/configuration/blacklistentries/${id}`).toPromise();
  }

  getBlacklistEntries(query?: GetBlacklistEntriesQuery): Promise<GetBlacklistEntriesResponse> {
    return this.apiService.get<GetBlacklistEntriesQuery, GetBlacklistEntriesResponse>('/api/v1/configuration/blacklistentries', query).toPromise();
  }

  createBlacklistEntry(blacklistEntry: BlacklistEntry): Promise<BlacklistEntry> {
    return this.apiService.post<PostBlacklistEntriesQuery, PostBlacklistEntriesBody, PostBlacklistEntriesResponse>('/api/v1/configuration/blacklistentries', blacklistEntry).toPromise();
  }

  updateBlacklistEntry(blacklistEntry: BlacklistEntry): Promise<BlacklistEntry> {
    return this.apiService
      .post<PostBlacklistEntryQuery, PostBlacklistEntryBody, PostBlacklistEntryResponse>(`/api/v1/configuration/blacklistentries/${blacklistEntry._id}`, blacklistEntry)
      .toPromise();
  }

  deleteBlacklistEntry(blacklistEntry: BlacklistEntry): Promise<BlacklistEntry> {
    return this.apiService
      .post<PostBlacklistEntryQuery, PostBlacklistEntryBody, PostBlacklistEntryResponse>(`/api/v1/configuration/blacklistentries/${blacklistEntry._id}/delete`, blacklistEntry)
      .toPromise();
  }
}
