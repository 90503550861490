import { TextAttributes } from './../Attributes';
import { BreakpointValue } from '../Breakpoint';
import { CTAButton, ShareCTATarget } from '../CTAButton';
import { PageModule } from './PageModule';

export const textEventStageLayout = ['Default', 'BackgroundFocused'] as const;
export type TextEventStagePageModuleLayout = (typeof textEventStageLayout)[number];

export interface TextEventStagePageModule extends PageModule {
  pageModuleType: 'TextEventStage';
  layout: TextEventStagePageModuleLayout;
  showShareButton: boolean;
  showParticipatingTag?: boolean;
  shareButtonTargets?: ShareCTATarget[];
  showCalendarButton: boolean;
  ctaButton?: CTAButton;
  local: { [language: string]: TextEventStagePageModuleLocal };
  ctalist?: TextEventStageButton[];
  ctalistInteraction?: TextEventStageButton[];
  showCountdown: boolean;
  discoverMore?: boolean;
  stageHeight?: BreakpointValue<number>;
  buttonSpacingTopBreakpoint?: BreakpointValue<number>;
}
export interface TextEventStageButton {
  _id: string;
  ctaButton: CTAButton;
}
export interface TextEventStagePageModuleLocal {
  smallHeadline: string;
  smallHeadline_attr?: TextEventStagePageModuleAttributes;
  text: string;
  subtext: string;
  background: string;
}

export type TextEventStagePageModuleAttributes = TextAttributes & {};

export function isTextEventStagePageModule(object: any): object is TextEventStagePageModule {
  return object?.pageModuleType === 'TextEventStage';
}
