import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { EventsService } from 'src/app/services/events/events.service';
import { KeytopicsService } from 'src/app/services/keytopics/keytopics.service';
import { SpeakersService } from 'src/app/services/speakers/speakers.service';
import { StreamsService } from 'src/app/services/streams/streams.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Keytopic } from 'src/common/entities/Keytopic';
import { AssetRelatedContent, isAssetRelatedContent, isLinkRelatedContent, LinkRelatedContent, RelatedContent, relatedContentTypes } from 'src/common/entities/RelatedContent';
import {
  Breakout,
  BreakoutSession,
  isBreakoutSession,
  isLiveSessionMedia,
  isSessionWithSessionMedia,
  isSpeakerSession,
  Session,
  sessionMediaTypes,
  SessionType,
  sessionTypes,
  SpeakerRole,
  speakerRoles,
  SpeakerSession,
} from 'src/common/entities/Session';
import { Speaker } from 'src/common/entities/Speaker';
import { BreakoutSessionFactory } from 'src/common/factories/sessions/BreakoutSessionFactory';
import { SessionFactory } from 'src/common/factories/sessions/SessionFactory';
import { InputConfiguration } from 'src/common/inputs/Inputs';
import { GroupingMode } from '../../../pipes/by-date/by-date.pipe';

@Component({
  selector: 'c-event-sessions',
  templateUrl: './event-sessions.component.html',
  styleUrls: ['./event-sessions.component.scss'],
})
export class EventSessionsComponent implements OnInit, OnDestroy {
  sessionEditTab = 'general';

  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  disabled: boolean = false;

  streamInputConfiguration: InputConfiguration;

  showSessionSidebar: boolean = false;

  sessionTypes = [...sessionTypes];
  sessionMediaTypes = [...sessionMediaTypes];
  speakerRoles = [...speakerRoles];
  relatedContentTypes = [...relatedContentTypes];
  relatedContent: RelatedContent = { relatedContentType: 'LinkRelatedContent' };

  currentLanguage: string;

  session: Session = null;

  addSessionItems: MenuItem[];

  filteredSessionKeytopics: Keytopic[] = [];
  currentSessionKeytopic: Keytopic = null;

  filteredSessionSpeakers: Speaker[] = [];
  currentSessionSpeaker: Speaker = null;
  currentSessionSpeakerRole: SpeakerRole = [...speakerRoles][0];

  currentSessionStage: string = null;

  chatOptions: { label: string; value: string }[] = [];
  stageOptions: { label: string; value: string }[] = [];

  subscriptions: Subscription[] = [];

  breakoutSpeakerInputConfiguration: InputConfiguration;
  breakoutKeytopicInputConfiguration: InputConfiguration;

  constructor(
    private speakersService: SpeakersService,
    private keytopicsService: KeytopicsService,
    private streamsService: StreamsService,
    private eventsService: EventsService,
    private utilsService: UtilsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.breakoutSpeakerInputConfiguration = {
      type: 'autocomplete',
      autocompleteOptions: {
        multiple: true,
        query: async (value) => {
          return (
            await this.speakersService.getSpeakers({
              filter: {
                displayName: {
                  matchMode: 'contains',
                  caseInsensitive: true,
                  value: value,
                },
              },
            })
          ).items.map((s) => s._id);
        },
        displayField: async (value: string) => (await this.speakersService.asPromise(value))?.displayName || '<Unknown>',
      },
    };
    this.breakoutKeytopicInputConfiguration = {
      type: 'autocomplete',
      autocompleteOptions: {
        multiple: true,
        query: async (value) => {
          const eventKeytopics = await Promise.all(this.eventVersion.eventKeytopics.map((k) => this.keytopicsService.getKeytopic(k.keytopic)));
          const filteredSessionKeytopics = eventKeytopics.filter((e) => e.internalName.toLowerCase().startsWith(value.toLowerCase()));
          return filteredSessionKeytopics.map((k) => k._id);
        },
        displayField: async (value: string) => {
          const keytopic = await this.keytopicsService.getKeytopic(value);
          return keytopic.internalName;
        },
      },
    };
  }

  async ngOnInit() {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.currentLanguage = params.language;
      })
    );

    this.subscriptions.push(
      this.eventsService.lastEventVersionPatch(this.eventVersion._id).subscribe((patch) => {
        if (this.utilsService.startsWithJsonpath(patch.patch.jsonpath, '$.sessions', patch.patch.jsonpathParams)) {
          this.eventVersion.sessions = [...this.eventVersion.sessions];
        }
      })
    );

    this.addSessionItems = [
      {
        label: 'Speaker Session',
        icon: 'pi pi-refresh',
        command: () => {
          this.addSession('SpeakerSession');
        },
      },
      {
        label: 'Breakout Session',
        icon: 'pi pi-times',
        command: () => {
          this.addSession('BreakoutSession');
        },
      },
    ];

    // this.streamOptions = ;
    this.chatOptions = [{ label: 'No Chat', value: null }].concat(
      (this.eventVersion.chats || []).map((c) => ({
        label: c.internalName,
        value: c.chat,
      }))
    );

    this.stageOptions = [{ label: '-', value: null }].concat(
      (this.eventVersion.stages || []).map((c) => ({
        label: c.internalName,
        value: c._id,
      }))
    );

    this.streamInputConfiguration = {
      type: 'dropdown',
      condition: (obj, jsonpath, jsonpathParams, parent) => {
        return isLiveSessionMedia(parent);
      },
      dropdownOptions: [{ label: 'No Stream', value: null }].concat(
        (await this.streamsService.getStreams()).items.map((s) => ({
          label: s.internalName,
          value: s._id,
        }))
      ),
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  asSpeakerSession = (session: Session): SpeakerSession => {
    return isSpeakerSession(session) ? session : null;
  };

  asBreakoutSession = (session: Session): BreakoutSession => {
    return isBreakoutSession(session) ? session : null;
  };

  isSessionWithMedia(session: Session) {
    return isSpeakerSession(session) || isBreakoutSession(session);
  }

  async addSession(sessionType?: SessionType) {
    const newSession = await new SessionFactory().createSession({
      sessionType: sessionType || 'SpeakerSession',
      event: this.event._id,
    });

    await this.eventsService.patch(this.eventVersion, {
      command: 'push',
      value: newSession,
      jsonpath: '$.sessions',
    });

    const session = this.eventVersion.sessions.find((s) => s._id === newSession._id);
    this.openSidebar(session);
  }

  async openSidebar(session?: Session) {
    this.session = session;
    this.showSessionSidebar = true;
  }

  closeSidebar() {
    this.showSessionSidebar = false;
    this.session = null;
  }

  async deleteSession(session: Session) {
    this.closeSidebar();
    await this.eventsService.patch(this.eventVersion, {
      command: 'delete',
      jsonpath: `$.sessions[?(@._id=='$sessionId')]`,
      jsonpathParams: {
        sessionId: session._id,
      },
    });
  }

  async deleteCurrentLanguage(session: Session) {
    this.closeSidebar();
    await this.eventsService.patch(this.eventVersion, {
      command: 'delete',
      jsonpath: `$.sessions[?(@._id=='$sessionId')].local.${this.currentLanguage}`,
      jsonpathParams: {
        sessionId: session._id,
      },
    });

    if (isSessionWithSessionMedia(session)) {
      await this.eventsService.patch(this.eventVersion, {
        command: 'delete',
        jsonpath: `$.sessions[?(@._id=='$sessionId')].sessionMedia.${this.currentLanguage}`,
        jsonpathParams: {
          sessionId: session._id,
        },
      });
    }
  }

  // Related Content

  isLinkRelatedContent(relatedContent: RelatedContent): boolean {
    return isLinkRelatedContent(relatedContent);
  }

  asLinkRelatedContent(relatedContent: RelatedContent): LinkRelatedContent {
    return isLinkRelatedContent(relatedContent) ? relatedContent : null;
  }

  asAssetRelatedContent(relatedContent: RelatedContent): AssetRelatedContent {
    return isAssetRelatedContent(relatedContent) ? relatedContent : null;
  }

  async addRelatedContent() {
    await this.eventsService.patch(this.eventVersion, {
      command: 'push',
      jsonpath: `$.sessions[?(@._id=='$sessionId')].local.$language.relatedContent`,
      jsonpathParams: {
        sessionId: this.session._id,
        language: this.currentLanguage,
      },
      value: this.relatedContent,
    });

    this.clearRelatedContent();
  }

  clearRelatedContent() {
    this.relatedContent = {
      relatedContentType: this.relatedContent.relatedContentType,
    };
  }

  async removeRelatedContent(index: number) {
    await this.eventsService.patch(this.eventVersion, {
      command: 'delete',
      jsonpath: `$.sessions[?(@._id=='$sessionId')].local.$language.relatedContent[$index]`,
      jsonpathParams: {
        sessionId: this.session._id,
        language: this.currentLanguage,
        index: index,
      },
    });
  }

  relatedContentTypeChanged(event) {
    if (event.value == 'AssetRelatedContent') {
      this.relatedContent = {
        relatedContentType: 'AssetRelatedContent',
        asset: null,
      } as AssetRelatedContent;
    } else if (event.value == 'LinkRelatedContent') {
      this.relatedContent = {
        relatedContentType: 'LinkRelatedContent',
      };
    }
  }

  // Session Keytopics

  async filterSessionKeytopics(event) {
    const eventKeytopics = await Promise.all(this.eventVersion.eventKeytopics.map((k) => this.keytopicsService.getKeytopic(k.keytopic)));
    this.filteredSessionKeytopics = eventKeytopics.filter((e) => e.internalName.startsWith(event.query));
  }

  async addCurrentSessionKeytopic() {
    await this.eventsService.patch(this.eventVersion, {
      command: 'push',
      jsonpath: `$.sessions[?(@._id=='$sessionId')].keytopics`,
      jsonpathParams: {
        sessionId: this.session._id,
      },
      value: this.currentSessionKeytopic._id,
    });
    // this.session.keytopics = [...new Set([...this.session.keytopics, this.currentSessionKeytopic._id])];
    // this.currentSessionKeytopic = null;
  }

  async removeSessionKeytopic(index: number) {
    await this.eventsService.patch(this.eventVersion, {
      command: 'delete',
      jsonpath: `$.sessions[?(@._id=='$sessionId')].keytopics[$index]`,
      jsonpathParams: {
        sessionId: this.session._id,
        language: this.currentLanguage,
        index: index,
      },
    });
    // if (this.session.keytopics.find(skt => skt == sessionKeytopic)) {
    //   this.session.keytopics = this.session.keytopics.filter(skt => skt != sessionKeytopic);
    // }
  }

  // Session Speakers

  async filterSessionSpeakers(event) {
    const speakers = await this.speakersService.getSpeakers({
      filter: {
        displayName: {
          matchMode: 'contains',
          value: event.query,
          // caseInsensitive: false,
        },
      },
    });
    this.filteredSessionSpeakers = speakers.items;
  }

  async addCurrentSessionSpeaker() {
    await this.eventsService.patch(this.eventVersion, {
      command: 'push',
      jsonpath: `$.sessions[?(@._id=='$sessionId')].speakers`,
      jsonpathParams: {
        sessionId: this.session._id,
        language: this.currentLanguage,
      },
      value: {
        speaker: this.currentSessionSpeaker._id,
        role: this.currentSessionSpeakerRole,
      },
    });
    // if (isSpeakerSession(this.session) || isBreakoutSession(this.session)) {
    //   if (!this.session.speakers.find(s => s.speaker === this.currentSessionSpeaker._id)) {
    //     this.session.speakers.push({
    //       speaker: this.currentSessionSpeaker._id,
    //       role: this.currentSessionSpeakerRole
    //     });
    //   }
    //   this.currentSessionSpeaker = null;
    // }
  }

  async removeSessionSpeaker(speakerId: string) {
    await this.eventsService.patch(this.eventVersion, {
      command: 'delete',
      jsonpath: `$.sessions[?(@._id=='$sessionId')].speakers[?(@.speaker=='$speakerId')]`,
      jsonpathParams: {
        sessionId: this.session._id,
        language: this.currentLanguage,
        speakerId: speakerId,
      },
    });
    // if (isSpeakerSession(this.session) || isBreakoutSession(this.session)) {
    //   if (this.session.speakers.find(skt => skt.speaker == speakerId)) {
    //     this.session.speakers = this.session.speakers.filter(skt => skt.speaker != speakerId);
    //   }
    // }
  }

  async addCurrentSessionStage() {
    await this.eventsService.patch(this.eventVersion, {
      command: 'push',
      jsonpath: `$.sessions[?(@._id=='$sessionId')].stages`,
      value: this.currentSessionStage,
      jsonpathParams: {
        sessionId: this.session._id,
      },
    });
    this.currentSessionStage = null;
  }

  async removeSessionStage(index: number) {
    await this.eventsService.patch(this.eventVersion, {
      command: 'delete',
      jsonpath: `$.sessions[?(@._id=='$sessionId')].stages[$index]`,
      jsonpathParams: {
        sessionId: this.session._id,
        index: index,
      },
    });
  }

  stageName(stage: string): string {
    return this.eventVersion.stages.find((s) => s._id === stage)?.internalName || 'Unknown';
  }

  trackByForBreakout(index: number, breakout: Breakout) {
    return breakout._id;
  }

  async addBreakout(session: BreakoutSession) {
    await this.eventsService.patch(this.eventVersion, {
      command: 'push',
      jsonpath: `$.sessions[?(@._id=='$sessionId')].breakouts`,
      jsonpathParams: {
        sessionId: session._id,
      },
      value: await new BreakoutSessionFactory().createBreakout({}),
    });
  }

  async removeBreakout(session: BreakoutSession, breakout: Breakout) {
    await this.eventsService.patch(this.eventVersion, {
      command: 'delete',
      jsonpath: `$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')]`,
      jsonpathParams: {
        sessionId: session._id,
        breakoutId: breakout._id,
      },
    });
  }

  async breakoutUp(session: BreakoutSession, breakout: Breakout) {
    await this.eventsService.patch(this.eventVersion, {
      command: 'up',
      jsonpath: `$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')]`,
      jsonpathParams: {
        sessionId: session._id,
        breakoutId: breakout._id,
      },
    });
  }

  async breakoutDown(session: BreakoutSession, breakout: Breakout) {
    await this.eventsService.patch(this.eventVersion, {
      command: 'down',
      jsonpath: `$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')]`,
      jsonpathParams: {
        sessionId: session._id,
        breakoutId: breakout._id,
      },
    });
  }

  async addBreakoutSpeaker(session: BreakoutSession, breakout: Breakout) {}

  async removeBreakoutSpeaker(session: BreakoutSession, breakout: Breakout, index: number) {}

  async breakoutSpeakerUp(session: BreakoutSession, breakout: Breakout, index: number) {}

  async breakoutSpeakerDown(session: BreakoutSession, breakout: Breakout, index: number) {}

  protected readonly GroupingMode = GroupingMode;
}
