import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';

import { ComponentsModule } from 'src/app/components/components.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

import { LookupComponent } from './lookup.component';

const routes: Routes = [{ path: '', component: LookupComponent }];

@NgModule({
  declarations: [LookupComponent],
  imports: [CommonModule, RouterModule.forChild(routes), TranslateModule, FormsModule, ButtonModule, CardModule, InputTextModule, ComponentsModule, DirectivesModule, PipesModule, MessageModule],
  exports: [RouterModule],
})
export class LookupModule {}
