import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import * as msal from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MsalService {
  private _msalConfig: msal.Configuration;
  private _msalInstance: msal.PublicClientApplication;
  private _tokenResponse: msal.AuthenticationResult;

  private _accountInfo: BehaviorSubject<msal.AccountInfo> = new BehaviorSubject<msal.AccountInfo>(null);
  private _token: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _redirectHash: string;

  constructor(private environment: Environment, private location: Location) {
    const hash = this.location.path(true).split('#').pop();

    if (hash) {
      this._redirectHash = `#${hash}`;
    }

    this._msalConfig = environment.azureAd.authority
      ? {
          auth: {
            clientId: environment.azureAd.clientId,
            authority: environment.azureAd.authority,
            redirectUri: environment.azureAd.redirectUri,
          },
        }
      : {
          auth: {
            clientId: environment.azureAd.clientId,
            authority: environment.azureAd.b2cPolicies.authorities.signIn.authority,
            knownAuthorities: [environment.azureAd.b2cPolicies.authorityDomain],
            redirectUri: environment.azureAd.redirectUri,
          },
        };

    this._msalInstance = new msal.PublicClientApplication(this._msalConfig);
  }

  private _setAccountInfo() {
    if (this._msalInstance.getAllAccounts().length > 0) {
      const accountInfo = this._msalInstance.getAllAccounts()[0];
      this._msalInstance.setActiveAccount(accountInfo);
      this._accountInfo.next(accountInfo);
    } else {
      this._accountInfo.next(null);
    }
  }

  async ready(): Promise<string | null> {
    try {
      this._tokenResponse = await this._msalInstance.handleRedirectPromise(this._redirectHash);

      if (this._tokenResponse) {
        this._setAccountInfo();
        localStorage.setItem('logincount', '0');
        this._token.next(this._tokenResponse.idToken);
        return this._tokenResponse.idToken;
      }
    } catch (err) {
      console.error(err);
    }

    return null;
  }

  async login() {
    try {
      localStorage.setItem('logincount', `${parseInt(localStorage.getItem('logincount') || '0') + 1}`);

      if (parseInt(localStorage.getItem('logincount') || '0') > 3) {
        return;
      }

      await this._msalInstance.loginRedirect({
        scopes: this.environment.azureAd.apiConfig.scopes,
        prompt: 'select_account',
      });
    } catch (err) {
      throw err;
    }
  }

  async token(): Promise<string | null> {
    const exp = new Date((this._tokenResponse.idTokenClaims as any).exp * 1000);

    if (exp.getTime() < new Date().getTime() - 1000 * 60) {
      try {
        this._tokenResponse = await this._msalInstance.acquireTokenSilent({
          scopes: this.environment.azureAd.apiConfig.scopes,
        });

        if (this._tokenResponse) {
          return this._tokenResponse.idToken;
        }
      } catch (err) {
        console.error(err);
      }
    }

    return this._tokenResponse?.idToken || null;
  }

  currentAccountInfo(): msal.AccountInfo {
    return this._accountInfo.getValue();
  }
}
