import { CTAButton } from './../CTAButton';
import { ImageAsset } from './../../entities/Asset';
import { BreakpointValue } from './../Breakpoint';
import { PageModule } from './PageModule';

export interface ImageBannerPageModule extends PageModule {
  pageModuleType: 'ImageBanner';
  link?: CTAButton;
  width: 'content-area' | 'fullwidth';
  local: { [language: string]: ImageBannerPageModuleLocal };
}
export interface ImageBannerPageModuleLocal {
  imageAssetBreakpoint?: BreakpointValue<ImageAsset>;
}

export function isImageBannerPageModule(object: any): object is ImageBannerPageModule {
  return object?.pageModuleType === 'ImageBanner';
}
