import { Page } from 'src/common/entities/Page';
import { InputConfiguration } from 'src/common/inputs/Inputs';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Component, Input, OnInit } from '@angular/core';
import { Event } from 'src/common/entities/Event';
import { SpacerPageModule } from '../../../../common/entities/pagemodules/SpacerPageModule';

@Component({
  selector: 'app-grid-page-module-edit',
  templateUrl: './spacer-page-module-edit.component.html',
  styleUrls: ['./spacer-page-module-edit.component.scss'],
})
export class SpacerPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: SpacerPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  mobileJsonpathes: string[] = ['$.heightBreakpoint.mobile', '$.heightBreakpoint.mobileLarge'];

  tabletJsonpathes: string[] = ['$.heightBreakpoint.tablet', '$.heightBreakpoint.tabletLarge'];

  desktopJsonpathes: string[] = ['$.heightBreakpoint.desktop', '$.heightBreakpoint.desktopLarge', '$.heightBreakpoint.desktopWidescreen'];

  initialized = false;
  tab = 'general';

  accessInputConfiguration: InputConfiguration;

  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }

    this.initialized = true;
  }
}
