<div *skeleton="{ show: tab.loading, menu: true, tabs: true }" #overlaytarget>
  <div class="tab-header">
    <h1>
      <span *ngIf="!templateMode" class="pre-title">{{ (eventId === 'new' ? 'PAGE_EVENT_NEW' : 'PAGE_EVENT') | translate }}</span>
      <span *ngIf="templateMode" class="pre-title">{{ (eventId === 'new' ? 'PAGE_EVENT_TEMPLATE_NEW' : 'PAGE_EVENT_TEMPLATE') | translate }}</span>
      <span>{{ event?.internalName }}</span>
      <a [href]="eventURL()" target="_blank">
        <i *ngIf="event?.public" class="pi pi-external-link"></i>
      </a>
    </h1>

    <!-- <h1> -->
    <!-- <span *ngIf="loading">{{ "GENERAL_LOADING" | translate }} ...</span> -->
    <!-- <span *ngIf="!loading && eventId === 'new'">{{ "GENERAL_NEW" | translate }}</span> -->
    <!-- <span *ngIf="!loading && eventId !== 'new'">{{ event?.internalName }}</span> -->
    <!-- </h1> -->

    <div class="info">
      <span *ngIf="((!templateMode && (hasEventEditRight || hasEventEditContentRight)) || (templateMode && hasTemplateEditRight)) && eventVersion?.dirty">{{
        'GENERAL_CHANGES_WILL_BE_SAVED_AUTOMATICALLY' | translate
      }}</span>

      <div class="info-box" *ngIf="!templateMode">
        <div class="value">{{ eventPhase() }}</div>
        <div class="title">Event<br />Phase</div>
      </div>
      <div class="info-box">
        <div class="value">{{ eventVersion?.version || '-' }}</div>
        <div class="title">Current<br />Version</div>
      </div>
      <div class="info-box" *ngIf="!templateMode">
        <div class="value">{{ event?.currentEventVersion?.version || '-' }}</div>
        <div class="title">Published<br />Version</div>
      </div>
    </div>
  </div>
  <div class="tab-menu">
    <p-button
      *ngIf="(!templateMode && hasEventEditRight) || (templateMode && hasTemplateEditRight)"
      styleClass="p-button-text"
      icon="pi icon-ic-event-configuration"
      (onClick)="showConfiguration = true"
      label="Configuration"
      [disabled]="working"
    ></p-button>
    <p-button
      *ngIf="(!templateMode && hasEventEditRight) || (templateMode && hasTemplateEditRight)"
      styleClass="p-button-text"
      icon="pi icon-ic-event-configuration"
      (onClick)="openCRMConfiguration()"
      label="CRM Configuration"
      [disabled]="working"
    ></p-button>
    <p-button
      *ngIf="showGoToWebinarButton"
      styleClass="p-button-text"
      icon="pi icon-ic-event-configuration"
      (onClick)="showGoToWebinarConnectDialog = true"
      label="GoToWebinar"
      [disabled]="working"
    ></p-button>
    <!-- <p-button styleClass="p-button-text" icon="pi pi-flag" [label]="(currentLanguage | language | async)?.languageName" (click)="menu.toggle($event)"></p-button> -->
    <c-language-button
      [disabled]="!eventVersion || !eventVersion?.dirty || working"
      [(currentLanguage)]="currentLanguage"
      [languages]="eventVersion?.languages"
      (languagesChange)="onActiveLanguageChange($event)"
      [manageLanguages]="(!templateMode && hasEventEditContentRight) || (templateMode && hasTemplateEditRight)"
    ></c-language-button>
    <p-button
      *ngIf="(!templateMode && hasEventEditContentRight) || (templateMode && hasTemplateEditRight)"
      [disabled]="eventVersion?.dirty || working"
      icon="pi icon-ic-edit"
      styleClass="p-button-text"
      (onClick)="forkEventVersion(eventVersion?._id)"
      label="Edit"
    ></p-button>
    <p-button
      *ngIf="(!templateMode && hasEventEditContentRight) || (templateMode && hasTemplateEditRight)"
      [disabled]="!eventVersion?.dirty || working"
      styleClass="p-button-text"
      (onClick)="saveEventVersion(eventVersion?._id)"
      label="Finalize Version"
    ></p-button>
    <p-button icon="pi icon-ic-version" styleClass="p-button-text" (onClick)="showVersions = true" [label]="'Versions' + (!templateMode ? ' & Publishing' : '')" [disabled]="working"></p-button>
    <p-splitButton
      *ngIf="(!templateMode && hasEventCreateRight) || (templateMode && hasTemplateCreateRight)"
      [disabled]="eventVersion?.dirty"
      [appendTo]="overlaytarget"
      icon="pi icon-ic-clone"
      styleClass="p-button-text"
      (onClick)="openCloneEventModal(false)"
      [label]="(!templateMode ? 'GENERAL_DUPLICATE_EVENT' : 'GENERAL_DUPLICATE_TEMPLATE') | translate"
      [model]="cloneButtonModel"
    ></p-splitButton>

    <div class="right">
      <p-button styleClass="p-button-text" icon="pi pi-eye" (onClick)="showPreview = true" label="Preview"></p-button>
      <p-button *hasRight="'admin'" styleClass="p-button-text" icon="pi icon-ic-manage-access" (onClick)="showAccess = true" label="Manage Access"></p-button>
    </div>

    <!-- <p-menu #menu [popup]="true" [appendTo]="'body'" [model]="eventLanguageOptions" (onShow)="fillLanguageOptions()"></p-menu> -->
    <p-confirmPopup></p-confirmPopup>
  </div>
  <div class="tab-subtabs">
    <div class="tabs">
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'status' }" [class.active]="currentSubTab === 'status'">Status</a>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'basic-information' }" [class.active]="currentSubTab === 'basic-information'">Basic Information</a>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'layout' }" [class.active]="currentSubTab === 'layout'">Layout & Pages</a>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'keytopics' }" [class.active]="currentSubTab === 'keytopics'">Keytopics</a>
      <a *hasRight="'products.list'" [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'products' }" [class.active]="currentSubTab === 'products'">Products</a>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'sessions' }" [class.active]="currentSubTab === 'sessions'">Sessions</a>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'tickets' }" [class.active]="currentSubTab === 'tickets'">Tickets</a>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'slots' }" [class.active]="currentSubTab === 'slots'">Ticket Slots</a>
      <a *hasRight="'customdata.list'" [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'custom-fields' }" [class.active]="currentSubTab === 'custom-fields'">Custom Fields</a>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'chats' }" [class.active]="currentSubTab === 'chats'">Chats</a>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'maps' }" [class.active]="currentSubTab === 'maps'">Maps</a>
    </div>
  </div>

  <div *ngIf="event && eventVersion">
    <div class="tab-messages" *ngIf="event.totalVersions > eventVersion.version">
      <p-message severity="warn" text="You are currently not working on the latest version"></p-message>
    </div>

    <c-event-status *ngIf="currentSubTab === 'status'" [event]="event" [eventVersion]="eventVersion"></c-event-status>
    <c-event-basic-information *ngIf="currentSubTab === 'basic-information'" [event]="event" [eventVersion]="eventVersion"></c-event-basic-information>
    <c-event-layout *ngIf="currentSubTab === 'layout'" [event]="event" [eventVersion]="eventVersion" [language]="currentLanguage"></c-event-layout>
    <c-event-keytopics *ngIf="currentSubTab === 'keytopics'" [event]="event" [eventVersion]="eventVersion" [language]="currentLanguage"></c-event-keytopics>
    <c-event-products *ngIf="currentSubTab === 'products'" [event]="event" [eventVersion]="eventVersion" [language]="currentLanguage"></c-event-products>
    <c-event-sessions
      *ngIf="currentSubTab === 'sessions'"
      [event]="event"
      [eventVersion]="eventVersion"
      [disabled]="(!templateMode && !hasEventEditContentRight) || (templateMode && !hasTemplateEditRight)"
    ></c-event-sessions>
    <c-event-tickets *ngIf="currentSubTab === 'tickets'" [event]="event" [eventVersion]="eventVersion"></c-event-tickets>
    <c-event-custom-fields *ngIf="currentSubTab === 'custom-fields'" [event]="event" [eventVersion]="eventVersion" [language]="currentLanguage"></c-event-custom-fields>
    <c-event-slots *ngIf="currentSubTab === 'slots'" [event]="event" [eventVersion]="eventVersion"></c-event-slots>
    <c-event-chats *ngIf="currentSubTab === 'chats'" [event]="event" [eventVersion]="eventVersion"></c-event-chats>
    <c-event-stages *ngIf="currentSubTab === 'stages'" [event]="event" [eventVersion]="eventVersion"></c-event-stages>
    <c-event-maps *ngIf="currentSubTab === 'maps'" [event]="event" [eventVersion]="eventVersion" [language]="currentLanguage"></c-event-maps>
  </div>

  <c-version-select
    [(show)]="showVersions"
    [working]="working"
    [event]="event"
    [eventVersion]="eventVersion"
    (versionChange)="loadEventVersion($event._id); showVersions = false"
    [showPublish]="hasEventPublishRight || (templateMode && hasTemplateEditRight)"
    (publish)="publishEventVersion($event._id); showVersions = false"
  ></c-version-select>

  <p-dialog [(visible)]="showConfiguration" [modal]="true" [header]="'PAGE_EVENT_CONFIGURATION' | translate" [style]="{ 'min-width': '50vw' }">
    <div *ngIf="event">
      <div class="input-group">
        <div class="input">
          <input type="text" pInputText name="internalName" [disabled]="working" [(ngModel)]="event.internalName" />
        </div>
        <div class="title">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_INTERNAL_NAME' | translate }}</div>
        <div class="description">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_INTERNAL_NAME_DESCRIPTION' | translate }}</div>
      </div>

      <div *ngIf="!event.template" class="input-group">
        <div class="input">
          <input type="text" pInputText name="shortId" [disabled]="working" [(ngModel)]="event.shortId" />
        </div>
        <div class="title">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_SHORT_ID' | translate }}</div>
        <div class="description">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_SHORT_ID_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group dropdown">
        <div class="input">
          <p-dropdown name="eventType" [disabled]="working" [options]="eventTypeOptions" [(ngModel)]="event.eventType" appendTo="body"></p-dropdown>
        </div>
        <div class="title">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_EVENTTYPE' | translate }}</div>
        <div class="description">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_EVENTTYPE_DESCRIPTION' | translate }}</div>
      </div>

      <div *ngIf="!event.template" class="input-group switch">
        <div class="input">
          <p-inputSwitch [disabled]="working || !event.currentEventVersion" [(ngModel)]="event.public"></p-inputSwitch>
        </div>
        <div class="title">{{ 'PAGE_EVENT_BASIC_INFORMATION_PUBLIC' | translate }}</div>
        <div class="description">{{ 'PAGE_EVENT_BASIC_INFORMATION_PUBLIC_DESCRIPTION' | translate }}</div>
      </div>

      <div *ngIf="!event.template" class="input-group switch">
        <div class="input">
          <p-inputSwitch [disabled]="working" [(ngModel)]="event.hideLoginButton"></p-inputSwitch>
        </div>
        <div class="title">{{ 'PAGE_EVENT_BASIC_INFORMATION_HIDE_LOGIN_BUTTON' | translate }}</div>
        <div class="description">{{ 'PAGE_EVENT_BASIC_INFORMATION_HIDE_LOGIN_BUTTON_DESCRIPTION' | translate }}</div>
      </div>

      <div *ngIf="!event.template" class="input-group switch">
        <div class="input">
          <p-inputSwitch [disabled]="working" [(ngModel)]="event.hideLanguageSelector"></p-inputSwitch>
        </div>
        <div class="title">{{ 'PAGE_EVENT_BASIC_INFORMATION_HIDE_LANGUAGE_SELECTOR' | translate }}</div>
        <div class="description">{{ 'PAGE_EVENT_BASIC_INFORMATION_HIDE_LANGUAGE_SELECTOR_DESCRIPTION' | translate }}</div>
      </div>

      <div *ngIf="!event.template" class="input-group switch">
        <div class="input">
          <p-inputSwitch [disabled]="working" [(ngModel)]="event.hideBeaconIcon"></p-inputSwitch>
        </div>
        <div class="title">{{ 'PAGE_EVENT_BASIC_INFORMATION_HIDE_BEACON_ICON' | translate }}</div>
        <div class="description">{{ 'PAGE_EVENT_BASIC_INFORMATION_HIDE_BEACON_ICON_DESCRIPTION' | translate }}</div>
      </div>

      <div *ngIf="!event.template" class="input-group switch">
        <div class="input">
          <p-inputSwitch [disabled]="working" [(ngModel)]="event.showBackButton"></p-inputSwitch>
        </div>
        <div class="title">{{ 'PAGE_EVENT_BASIC_INFORMATION_SHOW_BACK_BUTTON' | translate }}</div>
        <div class="description">{{ 'PAGE_EVENT_BASIC_INFORMATION_SHOW_BACK_BUTTON_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group">
        <div class="input" style="border: none">
          <div class="p-grid p-ai-center vertical-container">
            <p-chips class="p-col" [style]="{ width: '28.5vw', display: 'block' }" [allowDuplicate]="false" [disabled]="working" [(ngModel)]="event.contactmail" separator=","> </p-chips>
          </div>
        </div>
        <div class="title">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_CONTACT_MAIL' | translate }}</div>
        <div class="description">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_CONTACT_MAIL_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group dropdown">
        <div class="input">
          <p-dropdown name="eventSerie" [disabled]="working" [options]="eventSerieOptions" [(ngModel)]="event.eventSerie" appendTo="body"></p-dropdown>
        </div>
        <div class="title">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_EVENT_SERIE' | translate }}</div>
        <div class="description">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_EVENT_SERIE_DESCRIPTION' | translate }}</div>
      </div>

      <div class="text-right">
        <button class="p-button" [disabled]="working" (click)="saveConfiguration()">Save</button>
      </div>
    </div>
  </p-dialog>

  <p-dialog [(visible)]="showCRMConfiguration" [modal]="true" header="CRM Configuration" [style]="{ 'min-width': '50vw' }">
    <div *ngIf="crmEvent && !event.currentEventVersion">The event needs to be published once before.</div>
    <div *ngIf="crmEvent && event.currentEventVersion">
      <div class="input-group">
        <div class="input">
          <input pInputText type="text" [(ngModel)]="crmEvent.data.id" [disabled]="working" />
        </div>
        <div class="title">Campaign ID</div>
      </div>
      <!--<div class="input-group switch">
        <div class="input">
           <p-checkbox [(ngModel)]="crmEvent.data.transferDates" [disabled]="working" [binary]="true"></p-checkbox>
        </div>
        <div class="title">Transfer dates</div>
      </div>-->
      <div class="line"></div>
      <div class="input-group dropdown">
        <div class="input">
          <p-dropdown [options]="crmEventSubidModeOptions" [(ngModel)]="crmEventSubidMode" (ngModelChange)="crmEventSubidModeChange()" [appendTo]="'body'" [disabled]="working"></p-dropdown>
        </div>
        <div class="title">Use Campaign Sub IDs</div>
      </div>

      <div *ngFor="let subId of crmEvent.data.subIds">
        <div class="input-group">
          <div class="input">
            <input pInputText type="text" [(ngModel)]="subId.id" [disabled]="working" />
          </div>
          <div class="title">
            <span *ngIf="subId.eventTicket">{{ (event.currentEventVersion | extract : "$.eventTickets[?(@._id=='" + subId.eventTicket + "')]")?.internalName }} Campaign ID</span>
            <span *ngIf="subId.type === 'digital'">Digital Campaign ID</span>
            <span *ngIf="subId.type === 'onSite'">On Site Campaign ID</span>
          </div>
        </div>
        <!--
          //This used to add checkboxes to enable/disable "transfer data".
          <div class="input-group switch">
          <div class="input">
             <p-checkbox [(ngModel)]="subId.transferDates" [disabled]="working" [binary]="true"></p-checkbox>
          </div>
          <div class="title">
            <span>Transfer dates for </span>
            <span *ngIf="subId.eventTicket">{{ (event.currentEventVersion | extract : "$.eventTickets[?(@._id=='" + subId.eventTicket + "')]")?.internalName }} Campaign</span>
            <span *ngIf="subId.type === 'digital'">Digital Campaign</span>
            <span *ngIf="subId.type === 'onSite'">On Site Campaign</span>
          </div>
        </div>
        -->
      </div>

      <p *ngIf="crmEventSubidMode === 'eventticket' && crmEvent.data.subIds.length > 0">
        This are the event tickets of the currently published version. You might want to publish a new version first, if the event tickets have changed.
      </p>
      <p *ngIf="crmEventSubidMode === 'eventticket' && crmEvent.data.subIds.length === 0">
        There are no event tickets in the currently published version. You might want to publish a new version first, if the event tickets have changed.
      </p>
      <div class="text-right">
        <button class="p-button" [disabled]="working" (click)="saveCRMConfiguration()">Save</button>
      </div>
    </div>
  </p-dialog>

  <!-- Rights Dialog -->
  <p-dialog id="dialog" styleClass="right-dialog active" [style]="{ 'min-width': '50vw', 'min-height': '30vw' }" header="Rights: {{ event?.internalName }}" [(visible)]="showAccess">
    <c-rbac [resource]="rbac_resouce"></c-rbac>
  </p-dialog>

  <p-dialog [(visible)]="showGoToWebinarConnectDialog" [header]="'GoToWebinar Integration'" [modal]="true">
    <div *ngIf="event?.externalEvent">
      <p>This event is connected to a GoToWebinar event.</p>
      <p>
        External Event ID: <b>{{ event.externalEvent.externalEventId }}</b>
      </p>
    </div>
    <div *ngIf="!event?.externalEvent">
      <div>
        <p>This event is not yet connected to a GoToWebinar event.</p>
        <p>Select a webinar from GoToWebinar which is not yet used in the list below.</p>
      </div>
      <div class="import-search-controls">
        <div class="input-group">
          <div class="title">From date:</div>
          <div class="input">
            <p-calendar
              [ngModel]="externalEventsQuery.fromTime"
              (ngModelChange)="setExternalEventsQueryValue($event, 'fromTime')"
              appendTo="body"
              [showOnFocus]="false"
              [showIcon]="true"
              [showButtonBar]="true"
            ></p-calendar>
          </div>
        </div>
        <div class="input-group">
          <div class="title">To date:</div>
          <div class="input">
            <p-calendar
              [ngModel]="externalEventsQuery.toTime"
              (ngModelChange)="setExternalEventsQueryValue($event, 'toTime')"
              appendTo="body"
              [showOnFocus]="false"
              [showIcon]="true"
              [showButtonBar]="true"
            ></p-calendar>
          </div>
        </div>

        <p-button styleClass="p-button-text" (click)="searchExternalEvents()" [label]="'GENERAL_SEARCH' | translate"></p-button>
      </div>

      <p-table [value]="externalEvents" [paginator]="true" [rows]="15" [totalRecords]="externalEvents.length" [loading]="externalEventsLoading">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="internalName">
              {{ 'PAGE_EVENTS_HEADER_INTERNAL_NAME' | translate }}
              <p-sortIcon field="internalName"></p-sortIcon>
            </th>
            <th pSortableColumn="totalStartAt">
              {{ 'PAGE_EVENTS_HEADER_START' | translate }}
              <p-sortIcon field="totalStartAt"></p-sortIcon>
            </th>
            <th pSortableColumn="totalEndAt">
              {{ 'PAGE_EVENTS_HEADER_END' | translate }}
              <p-sortIcon field="totalEndAt"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-event>
          <tr>
            <td>{{ event.internalName }}</td>
            <td>{{ event.totalStartAt | date : 'short' }}</td>
            <td>{{ event.totalEndAt | date : 'short' }}</td>
            <td class="import-event-button-area">
              <div *ngIf="externalEventLinks[event.externalEvent.externalEventId]" class="vertical-center">Already connected</div>
              <p-button *ngIf="externalEventLinks[event.externalEvent.externalEventId]" [routerLink]="'../' + externalEventLinks[event.externalEvent.externalEventId]">Open event </p-button>
              <p-button *ngIf="!externalEventLinks[event.externalEvent.externalEventId]" (click)="connectExternalEvent(event)" [disabled]="externalEventConnectInProgress">Connect </p-button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-dialog>

  <p-sidebar [(visible)]="showPreview" styleClass="p-sidebar-st" position="right" [modal]="true" [appendTo]="'body'" [showCloseIcon]="false">
    <c-sidebar-header [headerTitle]="'Preview Links'" (onClose)="showPreview = false"></c-sidebar-header>

    <c-preview *ngIf="showPreview" [event]="event" [eventVersion]="eventVersion" type="event"></c-preview>
  </p-sidebar>
</div>

<p-dialog
  [header]="(cloneEvent.template ? 'PAGE_EVENT_DUPLICATE_EVENT_MODAL_HEADER_AS_TEMPLATE' : 'PAGE_EVENT_DUPLICATE_EVENT_MODAL_HEADER') | translate"
  *ngIf="showCloneEventModal"
  [(visible)]="showCloneEventModal"
  [modal]="true"
>
  <div class="input-group compact">
    <div class="input">
      <input type="text" pInputText name="internalName" [(ngModel)]="cloneEvent.internalName" />
    </div>
    <div class="title">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_INTERNAL_NAME' | translate }}</div>
    <div class="description">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_INTERNAL_NAME_DESCRIPTION' | translate }}</div>
  </div>

  <div class="input-group compact">
    <div class="input">
      <input type="text" pInputText name="shortId" [(ngModel)]="cloneEvent.shortId" />
    </div>
    <div class="title">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_SHORT_ID' | translate }}</div>
    <div class="description">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_SHORT_ID_DESCRIPTION' | translate }}</div>
  </div>

  <button class="p-button" (click)="duplicateEvent()">{{ 'GENERAL_CREATE' | translate }}</button>
</p-dialog>
