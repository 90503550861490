import { EventVersionFactory } from '../../factories/EventVersionFactory';
import { Factory } from '../../factories/Factory';
import { Inputs } from '../Inputs';
import { ContentChecklistGroup, ContentChecklistItem } from '../../entities/EventVersion';

export const eventVersionContentChecklistInputs: Inputs = {
  '$.contentChecklistGroups': {
    header: 'Content Checklist Groups',
    factory: async () => [],
    list: true,
    listKey: '_id',
    listValue: 'elementId',
    childFactory: async () => await new EventVersionFactory().createContentChecklistGroup({}),
    childLabel: (item: ContentChecklistGroup, index: number, lang?: string) => item?.local[lang ? lang : 'en']?.title || (('Group #' + (index + 1)) as string),
    childDescription: (item: ContentChecklistGroup, index: number, lang?: string) => item?.local[lang ? lang : 'en']?.description as string
  },
  "$.contentChecklistGroups[?(@._id=='$elementId')].local.$language.title": {
    header: 'Checklist Group Title',
    type: 'text'
  },
  "$.contentChecklistGroups[?(@._id=='$elementId')].local.$language.description": {
    header: 'Checklist Group Description',
    description: 'Briefly describe the contents of this Content Checklist Group',
    type: 'text'
  },
  "$.contentChecklistGroups[?(@._id=='$elementId')].items": {
    header: 'Checklist Items',
    factory: async () => [],
    list: true,
    listKey: '_id',
    listValue: 'childElementId',
    childFactory: async () => await new EventVersionFactory().createContentChecklistItem({}),
    childLabel: (item: ContentChecklistItem, index: number, lang?: string) => item?.local[lang ? lang : 'en']?.title as string
  },
  "$.contentChecklistGroups[?(@._id=='$elementId')].items[?(@._id=='$childElementId')].local.$language": {
    factory: async () => {
      return new EventVersionFactory().createContentChecklistItemLocal({});
    }
  },
  "$.contentChecklistGroups[?(@._id=='$elementId')].items[?(@._id=='$childElementId')].local.$language.title": {
    header: 'Title',
    type: 'text'
  },
  "$.contentChecklistGroups[?(@._id=='$elementId')].items[?(@._id=='$childElementId')].link": {
    header: 'Link',
    type: 'text'
  }
};
