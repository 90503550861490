<div *hasRight="['products.read', 'products.edit']">
  <c-product-item *ngIf="id" [product]="product"></c-product-item>
  <div class="actions as-layer" *ngIf="id && !disabled">
    <span>
      <a *hasRight="['products.list']" [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openSearch()">{{ 'GENERAL_SEARCH' | translate }}</a
      >&nbsp;/&nbsp; <a *hasRight="['products.remove']" [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="removeProduct()">Remove</a>&nbsp;
    </span>
  </div>

  <div class="actions" *ngIf="!id">
    <span *ngIf="!disabled">
      <a *hasRight="['products.list']" [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openSearch()">{{ 'GENERAL_SEARCH' | translate }}</a
      >&nbsp;
      <span *hasNoRight="['products.list']">{{ 'COMPONENT_PRODUCT_SELECT_NO_PERMISSION' | translate }}</span>
    </span>
  </div>

  <p-sidebar *hasRight="['products.list']" [(visible)]="showSearch" styleClass="p-sidebar-st" position="right" [modal]="true" [showCloseIcon]="false" [appendTo]="'body'">
    <c-sidebar-header [headerTitle]="'Select Product'" (onClose)="showSearch = false"></c-sidebar-header>
    <div class="sidebar-body">
      <c-product-select-search *ngIf="showSearch" (productSelected)="selectProduct($event)"></c-product-select-search>
    </div>
    <c-sidebar-footer [showDeleteButton]="false"></c-sidebar-footer>
  </p-sidebar>
</div>
<div *hasNoRight="['products.read', 'products.edit']" class="p-error p-mb-2">{{ 'COMPONENT_PRODUCT_SELECT_NO_PERMISSION' | translate }}</div>
