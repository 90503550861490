<div class="chat-user-management">
  <div class="add-user">
    <div class="p-grid p-ai-center vertical-container">
      <div class="p-col-2">
        <p-dropdown [options]="userTypes" [(ngModel)]="selectedUserTypeIsExpert" optionValue="value" optionLabel="title" [style]="{ width: '100%' }"></p-dropdown>
      </div>

      <div class="p-col">
        <input type="text" pInputText [(ngModel)]="firstName" [placeholder]="'PAGE_CHAT_USERS_PLACEHOLDER_FIRSTNAME' | translate" [class]="!firstName ? 'ng-invalid ng-dirty' : ''" />
      </div>

      <div class="p-col">
        <input type="text" pInputText [(ngModel)]="lastName" [placeholder]="'PAGE_CHAT_USERS_PLACEHOLDER_LASTNAME' | translate" [class]="!lastName ? 'ng-invalid ng-dirty' : ''" />
      </div>

      <div class="p-col-fixed" style="width: 8rem">
        <p-inputSwitch [(ngModel)]="active" class="p-mr-2"></p-inputSwitch>
        {{ active ? ('GENERAL_ACTIVE' | translate) : ('GENERAL_INACTIVE' | translate) }}
      </div>

      <!-- <div class="p-col-5">
          <c-tag-selection [(tagValues)]="tagValues" [addTags]="true" tagScope="ArtificialUser"></c-tag-selection>
        </div> -->

      <div class="p-col-fixed" style="width: 6rem">
        <button pButton [label]="'GENERAL_ADD' | translate" icon="pi pi-check" (click)="createUser()" [disabled]="!firstName || !lastName"></button>
      </div>
    </div>
  </div>

  <p-table
    [value]="users"
    dataKey="_id"
    [lazy]="true"
    (onLazyLoad)="loadUsers($event)"
    [paginator]="true"
    [rows]="utilsService.paginatorRows"
    [totalRecords]="usersTotalRecords"
    sortField="lastName"
    [sortOrder]="1"
    [loading]="isBusy"
    editMode="row"
    styleClass="p-datatable-sm"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="expert" style="width: 12rem">{{ 'PAGE_CHAT_USERS_HEADER_TYPE' | translate }} <p-sortIcon field="expert"></p-sortIcon></th>
        <th pSortableColumn="firstName">{{ 'PAGE_CHAT_USERS_HEADER_FIRSTNAME' | translate }} <p-sortIcon field="firstName"></p-sortIcon></th>
        <th pSortableColumn="lastName">{{ 'PAGE_CHAT_USERS_HEADER_LASTNAME' | translate }} <p-sortIcon field="lastName"></p-sortIcon></th>
        <th pSortableColumn="active" style="width: 9rem">{{ 'GENERAL_ACTIVE' | translate }} <p-sortIcon field="active"></p-sortIcon></th>
        <th>{{ 'GENERAL_TAGS' | translate }}</th>
        <th style="width: 6rem"></th>
      </tr>

      <tr>
        <c-table-filter type="select" [options]="userTypes" optionValue="value" optionLabel="title" [nullOption]="true" [(value)]="search.expert" (valueChange)="onSearchChange()"></c-table-filter>
        <td><c-table-filter type="string" [(value)]="search.firstName" (valueChange)="onSearchChange()"></c-table-filter></td>
        <td><c-table-filter type="string" [(value)]="search.lastName" (valueChange)="onSearchChange()"></c-table-filter></td>
        <td><c-table-filter type="boolean-radio" [(value)]="search.active" (valueChange)="onSearchChange()"></c-table-filter></td>
        <td><c-table-filter type="tag" [(value)]="search.tags" (valueChange)="onSearchChange()"></c-table-filter></td>
        <td><c-table-filter></c-table-filter></td>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-user let-editing="editing" let-ri="rowIndex">
      <tr [pEditableRow]="user">
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-dropdown [options]="userTypes" [(ngModel)]="user.expert" optionValue="value" optionLabel="title" [style]="{ width: '100%' }"></p-dropdown>
            </ng-template>
            <ng-template pTemplate="output">
              {{ getUserType(user.expert) }}
            </ng-template>
          </p-cellEditor>
        </td>

        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="user.firstName" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ user.firstName }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="user.lastName" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ user.lastName }}
            </ng-template>
          </p-cellEditor>
        </td>

        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-inputSwitch [(ngModel)]="user.active"></p-inputSwitch>
            </ng-template>
            <ng-template pTemplate="output">
              <a *ngIf="user.active" href="#"><i class="pi pi-check success"></i></a>
              <a *ngIf="user.active === false" href="#"><i class="pi pi-times danger"></i></a>
            </ng-template>
          </p-cellEditor>
        </td>

        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <!-- <c-tag-selection [(tagValues)]="user.tags" [addTags]="true" tagScope="ArtificialUser"></c-tag-selection> -->
            </ng-template>
            <ng-template pTemplate="output">
              <!-- <c-tag-viewer [tagValues]="user.tags"></c-tag-viewer> -->
            </ng-template>
          </p-cellEditor>
        </td>

        <td class="text-right">
          <a *ngIf="!editing" pInitEditableRow (click)="onRowEditInit(user)" href="#"><i class="pi icon-ic-edit p-mr-2"></i></a>
          <a *ngIf="editing" pSaveEditableRow (click)="onRowEditSave(user)" href="#"><i class="pi pi-check success p-mr-3"></i></a>
          <a *ngIf="editing" pCancelEditableRow (click)="onRowEditCancel(user, ri)" href="#"><i class="pi pi-times danger p-mr-2"></i></a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
