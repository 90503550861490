import { Pipe, PipeTransform } from '@angular/core';
import { MapsService } from '../../services/maps/maps.service';
import { Map } from './../../../common/entities/Map';

@Pipe({
  name: 'maps',
})
export class MapsPipe implements PipeTransform {
  constructor(private mapsService: MapsService) {}

  transform(id: string, ...args: unknown[]): Promise<Map> {
    if (!id) return null;

    return this.mapsService.getMap(id);
  }
}
