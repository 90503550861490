import { MenuItemFactory } from '../../../../common/factories/MenuItemFactory';
import { MenuItem, MenuItemType } from '../../../../common/entities/MenuItem';

export interface MenuData {
  title: string;
  factory: (menuItemType: MenuItemType) => Promise<MenuItem>;
}

export const SITE_STRUCTURE_MENU_DATA = [
  [
    'mobileMenuItems',
    {
      title: 'Mobile Quick Access Item',
      factory: async (menuItemType: MenuItemType) => {
        return new MenuItemFactory().asMobileQuickAccessMenuItem({ menuItemType });
      },
    },
  ],
  [
    'appMenuItems',
    {
      title: 'App Navigation Items',
      factory: async (menuItemType: MenuItemType) => {
        return new MenuItemFactory().asAppNavigationMenuItem({ menuItemType });
      },
    },
  ],
  [
    'appNavigationMenuItems',
    {
      title: 'App Menu Items',
      factory: async (menuItemType: MenuItemType) => {
        return new MenuItemFactory().asAppMenuItem({ menuItemType });
      },
    },
  ],
  [
    'webMenuItems',
    {
      title: 'Web Menu Items',
      factory: async (menuItemType: MenuItemType) => {
        return new MenuItemFactory().asWebMenuItem({ menuItemType });
      },
    },
  ],
] as const;
