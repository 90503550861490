import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { FormsModule } from '@angular/forms';

import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';

import { ComponentsModule } from '../components/components.module';
import { EventStagesModule } from '../event-stages/event-stages.module';
import { PageModulesModule } from '../page-modules/page-modules.module';
import { PipesModule } from '../pipes/pipes.module';
import { PageEditComponent } from './page-edit/page-edit.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';

const components = [PageEditComponent];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    ComponentsModule,
    DropdownModule,
    PipesModule,
    PageModulesModule,
    InputSwitchModule,
    InputTextModule,
    EventStagesModule,
    SidebarModule,
    FormsModule,
    TranslateModule,
    ButtonModule,
    ConfirmDialogModule,
    CheckboxModule,
    MessageModule,
  ],
  exports: [...components],
})
export class PageModule {}
