import { AgendaPageModuleAttributes } from './../../entities/pagemodules/AgendaPageModule';
import { HeadlineType } from './../../entities/Attributes';
import { AgendaPageModule } from '../../entities/pagemodules/AgendaPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class AgendaPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public createAgendaPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.agendaPageModuleAttributes(values?.title_attr),
      preCtaText: values?.preCtaText || null,
    };
  }

  public agendaPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): AgendaPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async agendaPageModule(values: any): Promise<AgendaPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'Agenda',
      titlePosition: values?.titlePosition || 'left',
      event: values?.event || values?.eventId,
      showTime: values?.showTime || false,
      showThumbnail: values?.showThumbnail || false,
      showCalendarButton: values?.showCalendarButton || false,
      showShareButton: values?.showShareButton || false,
      indicateLiveSessions: values?.indicateLiveSessions || false,
      indicatePastSessions: values?.indicatePastSessions || false,
      indicateCurrentSession: typeof values?.indicateCurrentSession === 'boolean' ? values?.indicateCurrentSession : true,
      maxSessionCount: typeof values?.maxSessionCount === 'undefined' ? 5 : values.maxSessionCount,
      highlightSessions: values?.highlightSessions?.filter((h: any) => !!h) || [],
      local: await this.local(values, (val) => this.createAgendaPageModuleLocal(val)),
      ctaButton: values?.ctaButton || null,
      viewType: values?.viewType || 'tabs',
      speakerDisplayType: values?.speakerDisplayType || 'list',
      showFilterOptions: values?.showFilterOptions || false,
      showAgendaCalendarButton: values?.showAgendaCalendarButton || false,
      showAgendaShareButton: values?.showAgendaShareButton || false,
      showCurrentTime: values?.showCurrentTime || false,
      showBookmarkButton: values?.showBookmarkButton || false,
      showDescription: values?.showDescription || false,
      showMapButton: values?.showMapButton || false,
    };
  }
}
