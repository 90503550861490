import { TextAttributes } from './../Attributes';
import { CTAButton } from '../CTAButton';
import { PageModule, TitlePositions } from './PageModule';

export const trendingTopicsType = ['default', 'story'] as const;
export type TrendingTopicsType = (typeof trendingTopicsType)[number];

export interface TrendingTopicsPageModule extends PageModule {
  pageModuleType: 'TrendingTopics';
  titlePosition?: TitlePositions;
  trendingTopicsType: TrendingTopicsType;
  useFullWidth: boolean;
  local: {
    [language: string]: {
      title: string;
      title_attr?: TrendingTopicsPageModuleAttributes;
      topics: TrendingTopicsPageModuleElement[];
    };
  };
  ctaButton?: CTAButton;
}

export interface TrendingTopicsPageModuleElement {
  _id: string;
  title: string;
  isHighlighted: boolean;
  storyAssets?: TrendingTopicsStoryAssets[];
  image: string;
  cta?: CTAButton;
}

export interface TrendingTopicsStoryAssets {
  _id?: string;
  title?: string;
  asset?: string;
  cta?: CTAButton;
}

export type TrendingTopicsPageModuleAttributes = TextAttributes & {};

export function isTrendingTopicsPageModule(object: any): object is TrendingTopicsPageModule {
  return object?.pageModuleType === 'TrendingTopics';
}
