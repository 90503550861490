import * as yup from 'yup';
import { customFieldTypes } from '../../../../../entities/CustomField';

declare module 'yup' {
  interface ArraySchema<T> {
    unique(mapper: (a: T) => T, message?: any): ArraySchema<T>;
  }
}

yup.addMethod(yup.array, 'unique', function (mapper = (a: any) => a, message: string = 'option key must be unique') {
  return this.test('unique', message, (list) => {
    if (list) {
      return list?.length === new Set(list?.map(mapper)).size;
    } else {
      return true;
    }
  });
});

export const customFieldOptionValidator = yup.object({
  key: yup.string().trim().required('option key is required'),
  local: yup.lazy((local) => {
    return yup.object(Object.keys(local).reduce((a, b) => ({ ...a, [b]: yup.string().required() }), {}));
  }),
});

export const customFieldTicketAttendeeOptionValidator = yup.object({
  _id: yup.string().required(),
  fieldType: yup.string().nullable().required(),
  mandatory: yup.boolean().required(),
  internalName: yup.string().nullable().optional(),
  maxCharacters: yup.number().nullable().optional(),
  local: yup.lazy((lang) => {
    return yup.object(
      lang
        ? Object.keys(lang).reduce(
            (a, b) => ({
              ...a,
              [b]: yup.object({
                title: yup.string().when('deletedAt', {
                  is: null,
                  then: yup.string().required(),
                  otherwise: yup.string().nullable().optional(),
                }),
              }),
            }),
            {}
          )
        : {}
    );
  }),
  deletedAt: yup.date().nullable().optional(),
  deletedBy: yup.string().nullable().optional(),
});

export const customFieldValidator = yup.object({
  internalName: yup.string().required(),
  local: yup.lazy((locals) => {
    return yup.object(locals ? Object.keys(locals).reduce((a, b) => ({ ...a, [b]: customFieldLocalValidator }), {}) : {});
  }),
  default: yup.boolean().optional(),
  customFieldType: yup.string().oneOf([...customFieldTypes]),
  fields: yup
    .array()
    .when('customFieldType', {
      is: 'ticketAttendees',
      then: yup.array().of(customFieldTicketAttendeeOptionValidator).optional(),
    })
    .default([]),
  options: yup
    .array()
    .when('customFieldType', {
      is: 'select',
      then: yup
        .array()
        .of(customFieldOptionValidator)
        .unique((s: any) => s.key)
        .optional(),
    })
    .default([]),
});

export const customFieldLocalValidator = yup.object({
  title: yup.string().required(),
  description: yup.string().required(),
  default: yup.string().nullable().optional(),
  checkboxDescription: yup.string().nullable().optional(),
});
