import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'c-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
})
export class FieldComponent {
  @Input()
  header: string = '';

  @Input()
  description: string = '';

  @HostBinding('class.error')
  get hasError(): boolean {
    return typeof this.error === 'string' && this.error.length > 0;
  }

  @Input()
  error: string | null = null;

  @HostBinding('class.warning')
  get hasWarning(): boolean {
    return typeof this.warning === 'string' && this.warning.length > 0;
  }

  @Input()
  warning: string | null = null;

  @Input()
  layout: 'horizontal' | 'vertical' = 'horizontal';

  constructor() {}
}
