<div *ngIf="loading" class="loading">
  <p-progressSpinner></p-progressSpinner>
</div>
<div *ngIf="!loading && !poll">Poll not found!</div>

<div *ngIf="!loading && poll">
  <!-- <div class="menu">
  </div> -->

  <h1>{{ poll.local[currentLanguage].title }}</h1>
  <p>{{ poll.local[currentLanguage].description }}</p>

  <div class="graph">
    <div *ngFor="let pollResult of pollResults" class="option-container">
      <div class="option" [class.leading]="pollResult.count === maxCount">
        <div class="option-background" [ngStyle]="{ width: pollResult.width }"></div>
        <div class="option-details">
          <div class="option-label">
            {{ pollResult?.option?.local[currentLanguage]?.label ? pollResult.option.local[currentLanguage].label : '' }}
          </div>
          <div class="option-value">
            {{ pollResult.percentage }}%
            <div *ngIf="poll.theme?.showVotesInBar" class="option-votes">{{ pollResult.count }} Votes</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p *ngIf="poll.theme?.showVotesTotal">Total answers: {{ totalCount }} from {{ users }} users</p>
</div>
