import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';

import { BaseUser, isAzureAdUser, isLocalUser, User } from 'src/common/entities/User';
import { GetUserQuery, GetUserResponse } from 'src/common/api/v1/users/GetUser';
import { GetUsersQuery, GetUsersResponse } from 'src/common/api/v1/users/GetUsers';
import { GetUserEventRegistrationsQuery, GetUserEventRegistrationsResponse } from 'src/common/api/v1/users/GetUserEventRegistrations';

import { ApiSocketService } from '../api-socket/api-socket.service';
import { PostUserQuery, PostUserResponse } from 'src/common/api/v1/users/PostUser';
import { CacheContainer } from '../cache/cache-container';
import { CacheService } from '../cache/cache.service';
import { PostUsersBody, PostUsersQuery, PostUsersResponse } from 'src/common/api/v1/users/PostUsers';
import { GetFailedCRMUsersQuery, GetFailedCRMUsersResponse } from 'src/common/api/v1/users/GetFailedCRMUsers';
import { PostUserCRMUserRetryBody, PostUserCRMUserRetryQuery, PostUserCRMUserRetryResponse } from 'src/common/api/v1/users/PostUserCRMUserRetry';
import { GetUserTagsQuery, GetUserTagsResponse } from 'src/common/api/v1/users/GetUserTags';
import { GetUserTagAssignmentsQuery, GetUserTagAssignmentsResponse } from 'src/common/api/v1/users/GetUserTagAssignments';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private _users: { [user: string]: Promise<BaseUser> } = {};

  private _cache: CacheContainer<BaseUser>;

  constructor(private apiService: ApiService, private apiSocketService: ApiSocketService, private cacheService: CacheService) {
    this.apiSocketService.on<BaseUser>('user:update', (user: BaseUser) => {
      this._users[user._id] = Promise.resolve(user);
    });

    this._cache = this.cacheService.create<BaseUser>({
      get: async (user) => {
        const tempUser = await this.apiService.get<GetUserQuery, GetUserResponse>(`/api/v1/users/${user}`).toPromise();
        if (!tempUser.tags) tempUser.tags = {};
        if (!tempUser.userType) tempUser.userType = 'LocalUser';
        return tempUser;
      },
      socketEvents: ['user:update'],
      transform: (user) => {
        user.displayName = user.displayName || `${user.lastName}, ${user.firstName}` || (isLocalUser(user) || isAzureAdUser(user) ? user.email : null) || user._id;
      },
    });
  }

  getUser(userId: string): Promise<BaseUser> {
    if (!userId) return null;

    return this._cache.asPromise(userId);
  }

  async getUsers(query?: GetUsersQuery): Promise<GetUsersResponse> {
    const result = await this.apiService.get<GetUsersQuery, GetUsersResponse>('/api/v1/users', query).toPromise();
    result.items.forEach((user) => {
      if (!user.tags) user.tags = {};
    });
    return result;
  }

  async getArtificialUsers(query?: GetUsersQuery): Promise<GetUsersResponse> {
    query.filter['userType'] = {
      matchMode: 'equals',
      value: 'ArtificialUser',
    };

    return this.getUsers(query);
  }

  async getCustomers(query?: GetUsersQuery): Promise<GetUsersResponse> {
    if (!Object.keys(query?.filter).includes('userType')) {
      let tempQuery: GetUsersQuery = { ...query };
      tempQuery.filter = { ...query.filter };
      tempQuery.filter['userType'] = {
        matchMode: 'in',
        value: ['LocalUser', 'AzureAdUser', 'OpenIdConnectUser'],
      };

      return this.getUsers(tempQuery);
    }
    return this.getUsers(query);
  }

  async createUser(user: BaseUser): Promise<BaseUser> {
    return this._cache.post<PostUsersQuery, PostUsersBody, PostUsersResponse>('/api/v1/users', user);
  }

  async updateUser(user: BaseUser): Promise<BaseUser> {
    return this._cache.post<PostUserQuery, PostUserResponse, User>(`/api/v1/users/${user._id}`, user);
  }

  async getUserEventRegistrations(user: string, query?: GetUserEventRegistrationsQuery): Promise<GetUserEventRegistrationsResponse> {
    return this.apiService.get<GetUserEventRegistrationsQuery, GetUserEventRegistrationsResponse>(`/api/v1/users/${user}/eventregistrations`, query).toPromise();
  }

  async getUserTags(userId: string): Promise<GetUserTagsResponse> {
    return this.apiService.get<GetUserTagsQuery, GetUserTagsResponse>(`/api/v1/users/${userId}/tags`).toPromise();
  }

  async getUserTagAssignments(userId: string): Promise<GetUserTagAssignmentsResponse> {
    return this.apiService.get<GetUserTagAssignmentsQuery, GetUserTagAssignmentsResponse>(`/api/v1/users/${userId}/tagassignments`).toPromise();
  }

  async getFailedCRMUsers(query?: GetFailedCRMUsersQuery): Promise<GetFailedCRMUsersResponse> {
    return this.apiService.get<GetFailedCRMUsersQuery, GetFailedCRMUsersResponse>(`/api/v1/users/failedcrmusers`, query).toPromise();
  }

  async crmUserRetry(userId: string): Promise<PostUserCRMUserRetryResponse> {
    return this.apiService.post<PostUserCRMUserRetryQuery, PostUserCRMUserRetryBody, PostUserCRMUserRetryResponse>(`/api/v1/users/${userId}/crmuser/retry`, {}).toPromise();
  }
}
