export const blacklistEntryTypes = ['DomainBlacklistEntry'] as const;
export type BlacklistEntryType = (typeof blacklistEntryTypes)[number];

export interface BlacklistEntry {
  _id: string;
  blacklistEntryType: BlacklistEntryType;
}

export interface DomainBlacklistEntry extends BlacklistEntry {
  blacklistEntryType: 'DomainBlacklistEntry';
  domain: string;
}

export function isDomainBlacklistEntry(object: any): object is DomainBlacklistEntry {
  return object.blacklistEntryType === 'DomainBlacklistEntry';
}
