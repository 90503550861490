<div class="sidebar-tabs">
  <button [class.active]="tab === 'general'" (click)="tab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
  <button [class.active]="tab === 'videos'" (click)="tab = 'videos'">{{ 'GENERAL_VIDEOS' | translate }}</button>
  <button [class.active]="tab === 'design'" (click)="tab = 'design'">{{ 'GENERAL_DESIGN' | translate }}</button>
  <button [class.active]="tab === 'accesspolicy'" (click)="tab = 'accesspolicy'">{{ 'GENERAL_CONDITIONS' | translate }}</button>
</div>

<div *ngIf="tab == 'general'" class="sidebar-body">
  <c-page-patch-input
    *ngFor="let inputJsonpath of inputJsonpathesGeneral"
    [jsonpath]="[jsonpath, inputJsonpath]"
    [jsonpathParams]="jsonpathParams"
    [object]="eventVersion || page"
  ></c-page-patch-input>
</div>

<div *ngIf="tab == 'videos'" class="sidebar-body">
  <c-page-patch-input
    *ngFor="let inputJsonpath of inputJsonpathesVideos"
    [jsonpath]="[jsonpath, inputJsonpath]"
    [jsonpathParams]="jsonpathParams"
    [object]="eventVersion || page"
    [inputConfiguration]="inputJsonpath === '$.keytopic' ? inputConfigurationKeytopic : inputJsonpath === '$.event' ? { type: 'event' } : {}"
  ></c-page-patch-input>
</div>

<div *ngIf="tab == 'design'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.backgroundTheme']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingTop']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingBottom']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingLeft']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingRight']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'accesspolicy'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.accessPolicy']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page" [inputConfiguration]="accessInputConfiguration"></c-page-patch-input>
</div>
