import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { TableComponent } from 'src/app/components/table/table.component';
import { TableFilter, TableOptions, TableQuery } from 'src/app/components/table/table.interfaces';
import { AdminUsersService } from 'src/app/services/admin-users/admin-users.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CustomFieldsService } from 'src/app/services/customFields/custom-fields.service';
import { EventsService } from 'src/app/services/events/events.service';
import { LookupsService } from 'src/app/services/lookups/lookups.service';
import { UsersService } from 'src/app/services/users/users.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { AdminUser } from 'src/common/entities/AdminUser';
import {
  CustomField,
  CustomFieldCheckbox,
  CustomFieldSelect,
  CustomFieldSelectOption,
  CustomFieldText,
  CustomFieldTextarea,
  CustomFieldTicketAttendees,
  CustomFieldTicketAttendeesOption,
  isCustomFieldCheckbox,
  isCustomFieldSelect,
  isCustomFieldText,
  isCustomFieldTextarea,
  isCustomFieldTicketAttendees,
} from 'src/common/entities/CustomField';
import { Event } from 'src/common/entities/Event';
import { EventRegistration, EventRegistrationData } from 'src/common/entities/EventRegistration';
import { EventSlot, EventTimeSlot } from 'src/common/entities/EventSlot';
import { EventTicket } from 'src/common/entities/EventTicket';
import { Lookup } from 'src/common/entities/Lookup';
import { asUser, User } from 'src/common/entities/User';
import { CollaborationService } from './../../../services/collaboration/collaboration.service';

@Component({
  selector: 'app-live-users',
  templateUrl: './live-users.component.html',
  styleUrls: ['./live-users.component.scss'],
  providers: [ConfirmationService],
})
export class LiveUsersComponent implements OnInit {
  showNewEventRegistrationDialog = false;
  showEventRegistrationDialog = false;

  @ViewChild(TableComponent) table: TableComponent<EventRegistration>;

  @Input()
  event: Event = null;

  user: User = null;
  userId: string = null;
  salesManager: AdminUser = null;
  luSalutation: Lookup = null;
  luJobTitle: Lookup = null;

  eventTickets: EventTicket[] = [];

  eventTicketSlots: EventSlot[] = [];
  eventTicketSlotInfos: any[] = [];
  visibleEventTicketSlots: EventSlot[] = [];
  selectedEventTicketSlots: { [ticketSlotId: string]: boolean };
  selectedEventTicketTimeSlots: { [ticketTimeSlotId: string]: boolean };

  // registrationCustomFields: RegistrationCustomField[] = [];
  customFields: CustomField[] = [];
  // customFieldOptions: CustomFieldOptions = {};
  activatedCustomFields: CustomField[] = [];

  visibleEventTicketSlots2: EventSlot[] = [];
  eventTicketSlots2: EventSlot[] = [];
  eventTicketSlotInfos2: any[] = [];
  activatedCustomFields2: CustomField[] = [];

  eventRegistrations: EventRegistration[] = [];

  // lazyLoad: boolean = false;
  loading: boolean = false;
  saving: boolean = false;
  sendMail: boolean = true;

  rightToRead: boolean = false;
  rightToEdit: boolean = false;
  rightToDelete: boolean = false;
  rightToOpenUser: boolean = false;

  // showEventRegistrationDialog: boolean = false;
  newRegistration: boolean = false;
  existingRegistration: boolean = false;
  tabIndexRegistrationData: number = 0;
  editableRegistation: EventRegistration = null;
  editableRegistationOriginal: string = null;

  expanded: { [eventRegistrationId: string]: boolean } = {};
  eventRegistrationsTableOptions: TableOptions<EventRegistration>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    public utilsService: UtilsService,
    private eventsService: EventsService,
    public customFieldsService: CustomFieldsService,
    private usersService: UsersService,
    private adminUsersService: AdminUsersService,
    private lookupsService: LookupsService,
    private datePipe: DatePipe,
    private collaborationService: CollaborationService
  ) {}

  async ngOnInit(): Promise<void> {
    // Check right's
    this.rightToRead = this.authService.hasGlobalRight('eventregistrations.read');
    this.rightToEdit = this.authService.hasGlobalRight('eventregistrations.edit');
    this.rightToDelete = this.authService.hasGlobalRight('eventregistrations.delete');
    this.rightToOpenUser = this.authService.hasGlobalRight('users.edit');

    const [event, salutation, jobTitle] = await Promise.all([this.eventsService.getEvent(this.event._id), this.lookupsService.getLookup('salutations'), this.lookupsService.getLookup('departments')]);
    this.luSalutation = salutation;
    this.luJobTitle = jobTitle;
    this.event = event;

    this.customFields = await Promise.all(
      this.event.currentEventVersion.registrationCustomFields.map((r) => {
        return this.customFieldsService.getCustomField(r.customField);
      })
    );

    try {
      // Prepare event related information
      this.prepareRegistrationTickets();
      this.prepareRegistrationTicketSlots();
      // this.prepareRegistrationCustomFields();

      const customFieldFilters: TableFilter[] = this.customFields.map((c) => {
        return {
          header: c.internalName,
          path: `registrationData.customFields.${c._id}`,
          ...(isCustomFieldCheckbox(c)
            ? {
                type: 'boolean',
              }
            : {}),
          ...(isCustomFieldSelect(c)
            ? {
                values: c.options.map((o) => ({ label: o.key, value: o.key })),
              }
            : {}),
        };
      });

      const attendeesFilters: TableFilter[] = this.customFieldsAttendees().map((c) => {
        return {
          header: c.internalName,
          path: `registrationData.attendees.${c._id}`,
          type: 'string',
        };
      });

      this.eventRegistrationsTableOptions = {
        size: 50,
        columns: [
          { header: '' },
          { header: 'Last Name', sort: 'user.lastNameLower', visible: 'fixed' },
          { header: 'First Name', sort: 'user.firstName' },
          { header: 'Email', sort: 'user.lastName' },
          { header: 'Organization', sort: 'user.organization' },
          { header: 'Country' },
          { header: 'Registered At', sort: 'registeredAt' },
          { header: 'Ticket' },
          { header: 'Time Slot' },
          ...this.customFieldsAttendees().map((i) => ({ header: i.internalName })),
          ...this.customFields.map((c) => ({ header: c.internalName })),

          { header: 'Attended' },
          { header: 'Auto Assignment' },
          { header: '' },
        ],
        filters: [
          { header: 'Last Name', path: 'user.lastName' },
          { header: 'First Name', path: 'user.firstName' },
          { header: 'Email', path: 'emailFilter' },
          { header: 'Organization', path: 'user.organization' },
          { header: 'Country', path: 'user.country' },
          { header: 'Registered At', path: 'registeredAt', type: 'date' },
          {
            header: 'Ticket',
            path: 'registrationData.eventTicket',
            type: 'objectid',
            values: [
              ...this.event.currentEventVersion.eventTickets.map((t) => ({
                label: t.internalName,
                value: t._id,
              })),
            ],
            value: this.activatedRoute.snapshot.queryParams.ticket,
          },
          {
            header: 'Ticket Slot',
            path: 'registrationData.eventSlots.eventSlot',
            type: 'objectid',
            values: [
              ...this.event.currentEventVersion.eventSlots.map((e) => ({
                label: `${this.datePipe.transform(e.startAt, 'short')} - ${this.datePipe.transform(e.endAt, 'shortTime')}`,
                value: e._id,
              })),
            ],
            value: this.activatedRoute.snapshot.queryParams.eventSlot,
          },
          // { header: 'Attendees', path: 'registrationData.additionalAttendees', type: 'string'},
          {
            header: 'Ticket Time Slot',
            path: 'registrationData.eventSlots.eventTimeSlots',
            type: 'objectid',
            values: [
              ...this.event.currentEventVersion.eventSlots
                .reduce((a, b) => a.concat(b.eventTimeSlots || []), [] as EventTimeSlot[])
                .map((e) => ({
                  label: `${this.datePipe.transform(e.startAt, 'short')} - ${this.datePipe.transform(e.endAt, 'shortTime')}`,
                  value: e._id,
                })),
            ],
            value: this.activatedRoute.snapshot.queryParams.eventTimeSlot,
          },
          ...customFieldFilters,
          ...attendeesFilters,
          { header: 'Auto Assignment', path: 'autoAssigned', type: 'boolean', matchModes: ['exists'] },
          { header: 'Platform registration', path: 'user', type: 'boolean', matchModes: ['exists'] },
        ],
      };
    } catch (err) {
      console.error(err);
    }

    if (this.activatedRoute.snapshot.queryParams.eventRegistration) {
      const a = await this.eventsService.getEventRegistration(this.event._id, this.activatedRoute.snapshot.queryParams.eventRegistration);
      this.showDialog(a);
    }
  }

  customFieldsAttendees() {
    const a = this.customFields.find((item) => item.customFieldType === 'ticketAttendees');
    if (isCustomFieldTicketAttendees(a)) {
      return a.fields.filter((i) => !i.deletedAt);
    } else {
      return [];
    }
  }

  query(query: TableQuery<EventRegistration>) {
    query.result = this.eventsService.getEventRegistrations(this.event._id, query.query);
  }

  numberOfSlots(registration: EventRegistration) {
    let number = 0;
    for (const registrationData of Array.isArray(registration.registrationData) ? registration.registrationData : [registration.registrationData]) {
      for (const eventSlot of registrationData.eventSlots) {
        number += eventSlot.eventTimeSlots?.length || 1;
      }
    }
    return number;
  }

  async download() {
    // this.retrieveEventRegistations(this.lastLoadEvent, true)
    if (this.table.lastQuery) {
      await this.eventsService.downloadEventRegistrations(this.event._id, {
        ...this.table.lastQuery,
        limit: 9999999,
        skip: 0,
      });
    }
  }

  // ---------------------------- Registration Dialog ----------------------------

  async showDialog(eventRegistration?: EventRegistration) {
    // Initialize dialog
    this.existingRegistration = false;
    this.tabIndexRegistrationData = 0;
    this.editableRegistation = null;
    this.user = null;
    this.userId = null;

    if (!eventRegistration) {
      // Open new registration dialog
      this.newRegistration = true;
      this.showEventRegistrationDialog = true;
    } else {
      // Get neccessary data to open edit registration dialog
      this.newRegistration = false;
      await this.showEventRegistration(eventRegistration);
    }
  }

  closeDialog() {
    this.showEventRegistrationDialog = false;
    this.editableRegistationOriginal = null;
    this.editableRegistation = null;
  }

  async showEventRegistration(eventRegistration: EventRegistration) {
    this.loading = true;
    try {
      // Get/prepare neccessary data
      if (!eventRegistration) {
        this.editableRegistation = {
          user: this.user._id,
          event: this.event._id,
          attended: false,
          registered: false,
          registeredAt: null,
          registrationData: [],
          utm: null,
        };
        (this.editableRegistation.registrationData as EventRegistrationData[]).push({
          customFields: {},
          eventTicket: this.table._filterList['registrationData.eventTicket'] ? String(this.table._filterList['registrationData.eventTicket'].value) : null,
          eventSlots: [],
        });
      } else {
        const [promiseEventRegistration, promiseUser] = await Promise.all([
          this.eventsService.getEventRegistration(this.event._id, eventRegistration._id),
          eventRegistration.user ? this.usersService.getUser(this.getUserId(eventRegistration)) : null,
        ]);
        this.editableRegistation = promiseEventRegistration;
        this.editableRegistationOriginal = JSON.stringify(this.editableRegistation);
        this.user = asUser(promiseUser);
      }

      if (this.user?.salesManager !== undefined) {
        this.salesManager = await this.adminUsersService.getAdminUser(this.user.salesManager);
      } else {
        this.salesManager = null;
      }

      // Open dialog
      // console.log(this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventTicket)
      this.prepareRegistrationForEdit(this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventTicket);
      this.showEventRegistrationDialog = true;
      this.loading = false;

      if (this.newRegistration && eventRegistration) {
        // Give user a short info about opened a existing registration
        this.existingRegistration = true;
        this.newRegistration = false;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async saveEventRegistration() {
    this.showEventRegistrationDialog = false;
    this.saving = true;
    try {
      if (this.newRegistration) {
        // this.editableRegistation = await this.eventsService.createEventRegistration(this.event._id, this.editableRegistation);
      } else {
        console.log('Send Email', this.sendMail);
        this.editableRegistation = await this.eventsService.updateEventRegistration(this.event._id, this.editableRegistation, this.sendMail);
      }
      // Visualize changes
      // this.retrieveEventRegistations()
      this.table.refresh();
    } catch (err) {
      console.error(err);
    }
    this.saving = false;
  }

  async confirmDeleteEventRegistration() {
    this.confirmationService.confirm({
      header: await this.utilsService.translate('PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_DELETE_HEADER'),
      message: await this.utilsService.translate('PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_DELETE_MESSAGE'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteEventRegistration();
      },
    });
  }

  async deleteEventRegistration() {
    this.showEventRegistrationDialog = false;
    this.saving = true;
    try {
      if (this.newRegistration) {
        this.editableRegistation = null;
      } else {
        await this.eventsService.setEventRegistrationUnregistered(this.event._id, this.editableRegistation);
        // Visualize changes
        // this.retrieveEventRegistations()
        this.table.refresh();
      }
    } catch (err) {
      console.error(err);
    }
    this.saving = false;
  }

  async confirmDeleteEventRegistrationData() {
    this.confirmationService.confirm({
      header: await this.utilsService.translate('PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATIONDATA_DELETE'),
      message: await this.utilsService.translate('PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATIONDATA_DELETE_MESSAGE'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteEventRegistrationData();
      },
    });
  }

  async deleteEventRegistrationData() {
    const registrationData: EventRegistrationData[] = this.editableRegistation.registrationData as EventRegistrationData[];
    registrationData.splice(this.tabIndexRegistrationData, 1);
    if (this.tabIndexRegistrationData > 0) this.tabIndexRegistrationData--;
    this.prepareRegistrationForEdit(this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventTicket);
  }

  async onNewRegistrationsUserSelected() {
    // Hide dialog till all is data prepared
    this.showEventRegistrationDialog = false;
    // Check new registrations user
    this.loading = true;
    this.user = asUser(await this.usersService.getUser(this.userId));

    const result = await this.eventsService.getEventRegistrations(this.event._id, {
      filter: {
        'user._id': {
          matchMode: 'equalsObjectId',
          value: this.userId,
          caseInsensitive: true,
        },
      },
    });

    if (result.totalCount > 0) {
      // Get neccessary data for existing registration dialog
      const eventRegistration = result.items[0];
      this.showEventRegistration(eventRegistration);
    } else {
      // Get neccessary data for new registration dialog
      this.showEventRegistration(null);
    }
    this.loading = false;
  }

  prepareRegistrationTickets() {
    // Separate actual event tickets from event data
    this.eventTickets = [];
    this.event.currentEventVersion.eventTickets.forEach((eventTicket) => {
      this.eventTickets.push(eventTicket);
    });
  }

  prepareRegistrationTicketSlots() {
    // Separate actual event ticket slots + ticket time slots from event data
    this.eventTicketSlots = [];
    this.event.currentEventVersion.eventSlots.forEach((eventSlot) => {
      this.eventTicketSlots.push(eventSlot);

      this.eventTicketSlotInfos.push({
        valueSlot: eventSlot._id,
        valueTimeSlot: eventSlot._id,
        label: new Date(eventSlot.startAt).toDateString() + ' - ' + new Date(eventSlot.endAt).toLocaleTimeString(),
      });

      eventSlot.eventTimeSlots.forEach((eventTimeSlot) => {
        this.eventTicketSlotInfos.push({
          valueSlot: eventSlot._id,
          valueTimeSlot: eventTimeSlot._id,
          label:
            ' * ' + new Date(eventTimeSlot.startAt).toLocaleTimeString() + ' - ' + new Date(eventTimeSlot.endAt).toLocaleTimeString() + ': ' + this.utilsService.localize(eventTimeSlot.local)?.title,
        });
      });
    });
  }

  prepareRegistrationForEdit(ticketId?: string) {
    // Preselect ticket if neccessary and possible (for new registrations)
    if (!ticketId && this.eventTickets.length > 0) {
      ticketId = this.eventTickets[0]._id;
      this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventTicket = ticketId;
    }

    // Check and set ticket related events slot sliders
    this.visibleEventTicketSlots = [];
    this.selectedEventTicketSlots = {};
    this.selectedEventTicketTimeSlots = {};
    this.eventTicketSlots.forEach((ticketSlot) => {
      // Is events ticket slot registered for current ticket
      if (ticketSlot.eventTickets.includes(ticketId)) {
        // Make events ticket slot visible
        this.visibleEventTicketSlots.push(ticketSlot);
        // Check if events ticket slot is activated in useres registration
        const activatedEventSlot = this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventSlots.find((eventSlot) => eventSlot.eventSlot === ticketSlot._id);
        if (!activatedEventSlot) {
          // Set ticket slot and ticket time slot switches to false
          this.selectedEventTicketSlots[ticketSlot._id] = false;
          ticketSlot.eventTimeSlots.forEach((eventTimeSlot) => {
            this.selectedEventTicketTimeSlots[eventTimeSlot._id] = false;
          });
        } else {
          // Set ticket slot switch activated
          this.selectedEventTicketSlots[ticketSlot._id] = true;
          // Check if to activate ticket time slot switches
          ticketSlot.eventTimeSlots.forEach((eventTimeSlot) => {
            const activatedEventTimeSlot = (activatedEventSlot.eventTimeSlots as string[]).find((usedEventTimeSlot) => usedEventTimeSlot === eventTimeSlot._id);
            if (!activatedEventTimeSlot) {
              // Set ticket slot switch activated
              this.selectedEventTicketTimeSlots[eventTimeSlot._id] = false;
            } else {
              // Set ticket slot switch false
              this.selectedEventTicketTimeSlots[eventTimeSlot._id] = true;
            }
          });
        }
      }
    });

    // Prepare ticket related custom fields
    this.activatedCustomFields = [];
    this.event.currentEventVersion.registrationCustomFields.forEach((registrationCustomField) => {
      if (!registrationCustomField.eventTickets || registrationCustomField.eventTickets.length === 0) {
        const customField = this.customFields.find((customField) => customField._id === registrationCustomField.customField);
        if (customField.customFieldType !== 'ticketSlotSelection') {
          this.activatedCustomFields.push(customField);
        }
      } else {
        if (registrationCustomField.eventTickets.find((eventTicket) => eventTicket === ticketId)) {
          const customField = this.customFields.find((customField) => customField._id === registrationCustomField.customField);
          if (customField.customFieldType !== 'ticketSlotSelection') {
            this.activatedCustomFields.push(customField);
          }
        }
      }
    });
    // Set ticket related custom field defaults
    this.activatedCustomFields.forEach((customField) => {
      if (!this.editableRegistation.registrationData[this.tabIndexRegistrationData].customFields[customField._id]) {
        if (customField.local[this.utilsService.currentLanguage].default) {
          this.editableRegistation.registrationData[this.tabIndexRegistrationData].customFields[customField._id] = customField.local[this.utilsService.currentLanguage].default;
        } else {
          if (customField.customFieldType === 'checkbox') {
            this.editableRegistation.registrationData[this.tabIndexRegistrationData].customFields[customField._id] = false;
          } else if (customField.customFieldType === 'select') {
            if (this.asCustomFieldSelect(customField).options[this.tabIndexRegistrationData]) {
              this.editableRegistation.registrationData[this.tabIndexRegistrationData].customFields[customField._id] = this.asCustomFieldSelect(customField).options[this.tabIndexRegistrationData].key;
            } else {
              this.editableRegistation.registrationData[this.tabIndexRegistrationData].customFields[customField._id] = null;
            }
          } else {
            this.editableRegistation.registrationData[this.tabIndexRegistrationData].customFields[customField._id] = null;
          }
        }
      }
    });
  }

  onChangedEventTicketSlot(eventSlotId: string, value: boolean) {
    // Set all eventTimeSlots to true/false, too
    const eventTicketSlot = this.visibleEventTicketSlots.find((eventTicketSlot) => eventTicketSlot._id === eventSlotId);
    if (eventTicketSlot) {
      eventTicketSlot.eventTimeSlots.forEach((eventTimeSlot) => {
        this.selectedEventTicketTimeSlots[eventTimeSlot._id] = value;
      });
    }
    // Synchronize changes with this.editableRegistation.registrationData.eventSlots
    const eventSlotIndex = this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventSlots.findIndex((eventSlot) => eventSlot.eventSlot === eventSlotId);
    if (!value) {
      // Delete if exists
      if (eventSlotIndex !== -1) this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventSlots.splice(eventSlotIndex, 1);
    } else {
      // Add if not exists
      if (eventSlotIndex === -1) {
        const visibleEventTimeSlots: string[] = [];
        eventTicketSlot.eventTimeSlots.forEach((visibleEventTimeSlot) => {
          visibleEventTimeSlots.push(visibleEventTimeSlot._id);
        });
        const addEventSlot = {
          eventSlot: eventSlotId,
          eventTimeSlots: visibleEventTimeSlots,
        };
        this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventSlots.push(addEventSlot);
      }
    }
  }

  onChangedEventTicketTimeSlot(eventTimeSlotId: string, value: boolean) {
    // Find parent eventSlot
    const eventTicketSlot = this.visibleEventTicketSlots.find((eventTicketSlot) => eventTicketSlot.eventTimeSlots.find((eventTimeSlot) => eventTimeSlot._id === eventTimeSlotId) !== undefined);
    // Find parent registrationData eventSlot
    const eventSlotIndex = this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventSlots.findIndex((eventSlot) => eventSlot.eventSlot === eventTicketSlot._id);
    if (value) {
      // Set parent eventSlots to true, too
      this.selectedEventTicketSlots[eventTicketSlot._id] = value;
      // Synchronize changes with this.editableRegistation.registrationData.eventSlots
      if (eventSlotIndex === -1) {
        // Add eventSlot and eventTimeSlot
        const addEventSlot = {
          eventSlot: eventTicketSlot._id,
          eventTimeSlots: [eventTimeSlotId],
        };
        this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventSlots.push(addEventSlot);
      } else {
        // Add eventTimeSlot
        (this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventSlots[eventSlotIndex].eventTimeSlots as string[]).push(eventTimeSlotId);
      }
    } else {
      // Remove eventTimeSlot
      const eventTimeSlotIndex = this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventSlots[eventSlotIndex].eventTimeSlots.findIndex(
        (eventTimeSlot) => eventTimeSlot === eventTimeSlotId
      );
      this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventSlots[eventSlotIndex].eventTimeSlots.splice(eventTimeSlotIndex, 1);
      // Removed all eventTimeSlots => Remove eventSlot, too
      if (this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventSlots[eventSlotIndex].eventTimeSlots.length === 0) {
        this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventSlots.splice(eventSlotIndex, 1);
        // Set parent eventSlots to false, too
        this.selectedEventTicketSlots[eventTicketSlot._id] = value;
      }
    }
  }

  onTabIndexChangeRegistrationData(event) {
    const registrationData: EventRegistrationData[] = this.editableRegistation.registrationData as EventRegistrationData[];
    if (this.tabIndexRegistrationData === registrationData.length) {
      registrationData.push({
        customFields: {},
        eventTicket: null,
        eventSlots: [],
      });
    }
    this.prepareRegistrationForEdit(this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventTicket);
  }

  getTicketInfo(ticket: string | EventTicket): string {
    if (typeof ticket === 'string') {
      if (!ticket) return null;
      const foundTicket = this.eventTickets.find((foundTicket) => foundTicket._id === ticket);
      if (!foundTicket) return null;
      return foundTicket.internalName;
    }
    return ticket.internalName;
  }

  getCustomFieldSelectOptionLabel(key, options: CustomFieldSelectOption[]): string {
    if (!options) {
      return null;
    }
    const option = options.find((opt) => opt.key === key);
    if (!option) {
      return null;
    }
    return option.local[this.utilsService.currentLanguage];
  }

  errorAtEventRegistration(path: string): string | null {
    return null; // Not implemented yet
  }

  errorAtTicketSlots(): boolean {
    if (!this.editableRegistation) return false;
    if (this.visibleEventTicketSlots.length > 0) {
      return this.editableRegistation.registrationData[this.tabIndexRegistrationData].eventSlots.length === 0 ? true : false;
    }
    return false;
  }

  isValidEventRegistration(eventRegistration: EventRegistration): boolean {
    return true; // Not implemented yet
    // return this.validatorService.isValid(eventRegistrationValidator, eventRegistration);
  }

  asRegistrationDataArray(registrationData: EventRegistrationData | EventRegistrationData[]): EventRegistrationData[] {
    if (!Array.isArray(registrationData)) {
      return [registrationData];
    }
    return registrationData;
  }

  asCustomFieldText(customField: any): CustomFieldText {
    return isCustomFieldText(customField) ? customField : null;
  }

  asCustomFieldTextarea(customField: any): CustomFieldTextarea {
    return isCustomFieldTextarea(customField) ? customField : null;
  }

  isCustomFieldSelfVisit(customField: any): boolean {
    return customField?.customFieldType === 'ticketSelfVisit';
  }

  isCustomFieldAttendees(customField: any): boolean {
    return customField?.customFieldType === 'ticketAttendees';
  }

  asCustomFieldAttendees(customField: any): CustomFieldTicketAttendees {
    return isCustomFieldTicketAttendees(customField) ? customField : null;
  }

  getAttendeesOptions(cftao: CustomFieldTicketAttendeesOption[], key: any): string {
    const item = cftao.find((item) => item._id === key);
    if (item) {
      return item.internalName;
    } else {
      return ' - ';
    }
  }

  asCustomFieldCheckbox(customField: any): CustomFieldCheckbox {
    return isCustomFieldCheckbox(customField) ? customField : null;
  }

  asCustomFieldSelect(customField: any): CustomFieldSelect {
    return isCustomFieldSelect(customField) ? customField : null;
  }

  isEditableChanged() {
    return this.editableRegistationOriginal === JSON.stringify(this.editableRegistation);
  }

  showMassRegistrationDialog() {
    this.showEventRegistrationDialog = true;
  }

  massRegistrationTicketSlots(ticketId: string) {
    this.eventTicketSlots.forEach((ticketSlot) => {
      if (ticketSlot.eventTickets.includes(ticketId)) {
        this.visibleEventTicketSlots.push(ticketSlot);
      }
    });

    this.eventTicketSlots2 = [];
    this.event.currentEventVersion.eventSlots.forEach((eventSlot) => {
      this.eventTicketSlots2.push(eventSlot);

      this.eventTicketSlotInfos2.push({
        valueSlot: eventSlot._id,
        valueTimeSlot: eventSlot._id,
        label: new Date(eventSlot.startAt).toDateString() + ' - ' + new Date(eventSlot.endAt).toLocaleTimeString(),
      });

      eventSlot.eventTimeSlots.forEach((eventTimeSlot) => {
        this.eventTicketSlotInfos2.push({
          valueSlot: eventSlot._id,
          valueTimeSlot: eventTimeSlot._id,
          label:
            new Date(eventTimeSlot.startAt).toLocaleTimeString() + ' - ' + new Date(eventTimeSlot.endAt).toLocaleTimeString() + ': ' + eventTimeSlot.local[this.utilsService.currentLanguage].title,
        });
      });
    });
  }

  massRegistrationCustomFields(ticketId: string) {
    this.activatedCustomFields2 = [];
    this.event.currentEventVersion.registrationCustomFields.forEach((registrationCustomField) => {
      if (!registrationCustomField.eventTickets || registrationCustomField.eventTickets.length === 0) {
        const customField = this.customFields.find((customField) => customField._id === registrationCustomField.customField);
        this.activatedCustomFields2.push(customField);
      } else {
        if (registrationCustomField.eventTickets.find((eventTicket) => eventTicket === ticketId)) {
          const customField = this.customFields.find((customField) => customField._id === registrationCustomField.customField);
          this.activatedCustomFields2.push(customField);
        }
      }
    });
  }

  isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  countRows(stringContent: string, minRows): number {
    return Math.max((stringContent?.match(/\n/g) || []).length, minRows) + 1;
  }

  private getUserId(eventRegistration: EventRegistration): string {
    const { user } = eventRegistration;
    if (typeof (user as User)?._id === 'string') return (user as User)?._id;
    return user.toString();
  }
}
