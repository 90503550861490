import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class HasRightGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (!(await this.authService.checkAccount())) {
      this.router.navigate(['/login']);
      return false;
    }
    if (Array.isArray(route.data.hasRight)) {
      return this.authService.hasRight(route.data.hasRight);
    } else {
      const params = route.params;

      let resource: string = route.data.hasRight.resource;

      for (const param in params) {
        if (resource.includes('$' + param)) {
          resource = resource.replace('$' + param, route.params[param]);
        }
      }
      const hasRight = await this.authService.hasRight(route.data.hasRight.rights.map((right) => `${resource}$${right}`));
      if (hasRight) {
        return true;
      }
      this.router.navigate(['/error-unauthorized'], { skipLocationChange: true });
      return false;
    }
  }
}
