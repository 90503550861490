import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { MenuItem } from 'src/common/entities/MenuItem';
import { Factory } from 'src/common/factories/Factory';

@Component({
  selector: 'c-user-context-menu-items',
  templateUrl: './user-context-menu-items.component.html',
  styleUrls: ['./user-context-menu-items.component.scss'],
})
export class UserContextMenuItemsComponent implements OnChanges, OnDestroy {
  @Input()
  menuItems: MenuItem[];

  @Input()
  language: string;

  selectedMenuItem: MenuItem;
  @Output() selectedMenuItemEvent = new EventEmitter<MenuItem>();

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.language && changes.language.currentValue !== changes.language.previousValue) {
      for (const menuItem of this.menuItems) {
        if (!menuItem.local[this.language]) menuItem.local[this.language] = await Factory.menuItem().menuItemLocal({});
      }
    }
  }

  ngOnDestroy(): void {
    this.selectedMenuItemEvent.emit(null);
  }

  async addMenuItem() {
    const menuItem = await Factory.menuItem().menuItem({ menuItemType: 'Page' });
    this.menuItems.push(menuItem);
    this.selectMenuItem(menuItem);
  }

  removeMenuItem(index: number) {
    this.menuItems.splice(index, 1);
  }

  moveMenuItemUp(index: number) {
    if (index > 0) {
      const menuItem = this.menuItems[index];
      this.menuItems.splice(index, 1);
      this.menuItems.splice(index - 1, 0, menuItem);
    }
  }

  moveMenuItemDown(index: number) {
    if (index < this.menuItems.length - 1) {
      const menuItem = this.menuItems[index];
      this.menuItems.splice(index, 1);
      this.menuItems.splice(index + 1, 0, menuItem);
    }
  }

  async selectMenuItem(menuItem: MenuItem) {
    if (!menuItem.local[this.language]) menuItem.local[this.language] = await Factory.menuItem().menuItemLocal({});
    this.selectedMenuItem = menuItem;
    this.selectedMenuItemEvent.emit(menuItem);
  }
}
