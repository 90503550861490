import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventsService } from 'src/app/services/events/events.service';
import { PostEventVipTicketsResponse } from 'src/common/api/v1/events/PostEventVipTickets';
import { Event } from 'src/common/entities/Event';
import { isUser, User } from 'src/common/entities/User';
import { VIPTicket } from 'src/common/entities/VIPTicket';
import { TableOptions } from '../table/table.interfaces';

@Component({
  selector: 'c-vip-ticket-add-dialog',
  templateUrl: './vip-ticket-add-dialog.component.html',
  styleUrls: ['./vip-ticket-add-dialog.component.scss'],
})
export class VipTicketAddDialogComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  disabled: boolean = false;

  @Output() onSave: EventEmitter<void> = new EventEmitter();

  showVipTicketAddDialog = false;
  working = false;
  emails: string = '';
  eventTickets: { label: string; value: string }[] = [];
  eventTicket: string = null;

  vipTicketsResponse: PostEventVipTicketsResponse | null = null;
  resultTableOptions: TableOptions<{ user: string | User; error?: any; vipTicket?: VIPTicket }>;

  constructor(private eventsService: EventsService) {}

  ngOnInit(): void {
    this.resultTableOptions = {
      columns: [{ header: 'Email' }, { header: 'Last Name' }, { header: 'First Name' }, { header: 'Status' }],
    };
  }

  openVipTicketAddDialog() {
    this.emails = '';
    this.eventTicket = null;
    this.vipTicketsResponse = null;

    this.eventTickets = [{ label: '-', value: '' }].concat(
      this.event.currentEventVersion.eventTickets.map((t) => ({
        label: t.internalName,
        value: t._id,
      }))
    );

    this.showVipTicketAddDialog = true;
  }

  asUser(user: any): User {
    return isUser(user) ? user : null;
  }

  async save() {
    this.working = true;

    try {
      this.vipTicketsResponse = await this.eventsService.createVipTickets(this.event._id, {
        emails: this.emails.split(','),
        eventTicket: this.eventTicket,
      });
    } catch (err) {
      console.error(err);
    }

    this.working = false;
    this.onSave.emit();
  }
}
