import { HeadlineType } from './../../entities/Attributes';
import { ContentAreaHeightDefaultBreakpointValue, CTAAreaHeightDefaultBreakpointValue, TopAreaHeightDefaultBreakpointValue } from './DefaultBreakponitValue';
import { BreakpointValue } from './../../entities/Breakpoint';
import { Stage2TextEventStagePageModule, Stage2TextEventStagePageModuleAttributes, Stage2TextEventStagePageModuleLocal } from './../../entities/pagemodules/Stage2TextEventStagePageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class Stage2TextEventStagePageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public textEventStage2PageModuleAreaHeight(defaultValues: BreakpointValue<number>, values?: any) {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge,
    };
  }

  public async textEventStage2PageModuleLocal(values?: any): Promise<Stage2TextEventStagePageModuleLocal> {
    return {
      smallHeadline: values?.smallHeadline || '',
      smallHeadline_attr: this.stage2TextEventStagePageModuleAttributes(values?.smallHeadline_attr, 'no headline'),
      text: values?.text || '',
      subtext: values?.subtext || '',
      background: values?.background || null,
    };
  }

  public stage2TextEventStagePageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): Stage2TextEventStagePageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async textEventStage2PageModuleButton(values?: any) {
    return {
      _id: await this.id(values?._id),
      ctaButton: values?.ctaButton || null,
    };
  }

  public async textEventStage2PageModule(values: any): Promise<Stage2TextEventStagePageModule> {
    if (values?.background) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.background = item.background || values.background;
      });
    }

    const pageModule = {
      ...(await this.baseValues(values)),
      layout: values?.layout || 'Default',
      pageModuleType: 'TextEventStage2',
      background: undefined,
      local: await this.local(values, (val) => this.textEventStage2PageModuleLocal(val)),
      ctalist: values?.ctalist || [],
      ctalistInteraction: values?.ctalistInteraction || [],
      showCountdown: values.showCountdown || false,
      discoverMore: values?.discoverMore || false,
      ctaAreaHeight: values?.ctaAreaHeight ? values?.ctaAreaHeight : this.textEventStage2PageModuleAreaHeight(CTAAreaHeightDefaultBreakpointValue, values?.ctaAreaHeight),
      contentAreaHeight: values?.contentAreaHeight ? values?.contentAreaHeight : this.textEventStage2PageModuleAreaHeight(ContentAreaHeightDefaultBreakpointValue, values?.contentAreaHeight),
      topAreaHeight: values?.topAreaHeight ? values?.topAreaHeight : this.textEventStage2PageModuleAreaHeight(TopAreaHeightDefaultBreakpointValue, values?.topAreaHeight),
    };

    return { ...pageModule, pageModuleType: 'TextEventStage2' };
  }
}
