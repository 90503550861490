import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { EventsService } from 'src/app/services/events/events.service';
import { PagesService } from 'src/app/services/pages/pages.service';
import { Event, EventPhase } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page, PageVersion } from 'src/common/entities/Page';
import { Preview } from 'src/common/entities/Preview';
import { Device, devices, ViewMode } from './page-layout-editor.devices';
import { WindowMessageService } from '../../services/window-message/window-message.service';
import { Subscription } from 'rxjs';
import { DomainService } from '../../services/domain/domain.service';

@Component({
  selector: 'c-page-layout-editor',
  templateUrl: './page-layout-editor.component.html',
  styleUrls: ['./page-layout-editor.component.scss'],
})
export class PageLayoutEditorComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('frame') frame: ElementRef;

  @Input()
  page: Page;

  @Input()
  pageVersion: PageVersion;

  @Input()
  eventVersion: EventVersion;

  @Input()
  event: Event;

  @Input()
  eventPhase: EventPhase;

  @Input()
  device: Device = null;

  @Input()
  viewMode: ViewMode = null;

  @Input()
  language: string;

  @Input()
  loggedIn = false;

  @Input()
  ignoreConditions = false;

  @Input()
  eventTickets: string[] = [];

  preview: Preview;
  previewUrl: SafeUrl;

  subscription: Subscription;

  get frameStyle(): any {
    return {
      width: this.device.type === 'mobile' ? this.device.width + 4 + 'px' : '100%', // add border on mobile
      height: this.device.type === 'mobile' ? this.device.height + 4 + 'px' : '100vh', // add border on mobile
    };
  }

  get deviceStyle(): any {
    return {
      transform: this.device.type === 'mobile' && typeof this.device.zoom === 'number' ? `scale(${this.device.zoom})` : 'none',
    };
  }

  constructor(
    private eventsService: EventsService,
    private pagesService: PagesService,
    private domainService: DomainService,
    private domSanitizer: DomSanitizer,
    private windowMessageService: WindowMessageService
  ) {}

  ngOnInit(): void {
    if (!this.device) {
      this.device = devices[0];
    }

    this.subscription = this.windowMessageService.listeningForCurrentIframe().subscribe((msg) => {
      if (this.frame) {
        this.frame.nativeElement?.contentWindow?.postMessage(msg, '*');
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.loggedIn || changes.eventTickets || changes.language || changes.viewMode || (changes.page && changes.page.currentValue !== changes.page.previousValue)) {
      this.preview = null;
      this.previewUrl = null;

      if (this.event && this.eventVersion) {
        this.preview = await this.eventsService.createPreview(this.event._id, {
          eventVersion: this.eventVersion._id,
          startDate: new Date().toISOString(),
          loggedIn: this.loggedIn,
          ignoreConditions: this.ignoreConditions,
          eventTickets: this.eventTickets || [],
        });

        this.previewUrl = this.url(this.preview, this.domainService.findFirstFrontendDomain());
      } else if (this.pageVersion) {
        this.preview = await this.pagesService.createPreview(this.page._id, {
          pageVersion: this.pageVersion._id,
          ignoreConditions: this.ignoreConditions,
          loggedIn: this.loggedIn,
        });

        this.previewUrl = this.url(this.preview, this.domainService.findFirstFrontendDomain(this.page.domainCollection));
      }
    }
  }

  url(preview: Preview, domain): SafeResourceUrl {
    const layoutEditorMode = {
      page: this.page._id,
      pageVersion: this.pageVersion?._id,
      event: this.event?._id,
      eventShortId: this.event?.shortId,
      eventVersion: this.eventVersion?._id,
      eventPhase: this.eventPhase,
      previewToken: preview.token,
      viewport: this.device.type === 'mobile' ? this.device.viewport : null,
    };
    const viewmode = this.viewMode.type === 'app' ? 'singleeventapp' : '';
    const language = this.language || 'en';
    const eventPath = this.event ? `/events/${this.event._id}` : '';
    const pagePath = this.page._id ? '/' + this.page._id : '';

    return this.domSanitizer.bypassSecurityTrustResourceUrl(`${domain}${eventPath}${pagePath}?lang=${language}&_edit=${encodeURIComponent(JSON.stringify(layoutEditorMode))}&viewmode=${viewmode}`);
  }

  handleFrameLoad() {
    this.windowMessageService.setViewModeInCurrentIframe(this.viewMode.type);
  }
}
