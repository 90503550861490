import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TimezoneSwitchService } from '../../services/utils/timezone-switch.service';

@Pipe({
  name: 'timeZoneByName',
})
export class TimeZoneByNamePipe implements PipeTransform {
  constructor(private activatedRoute: ActivatedRoute, private timezoneSwitchService: TimezoneSwitchService) {}

  transform(date: Date | string, timeZoneName: string = this.activatedRoute.snapshot.queryParams.eTz || 'Europe/Berlin'): Date | string {
    return date ? this.timezoneSwitchService.encodeByUrlTimeZone(date, timeZoneName) : null;
  }
}
