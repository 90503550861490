import { EmailTemplate } from './../../../common/entities/EmailTemplate';
import { EmailTemplatesService } from 'src/app/services/email-templates/email-templates.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TabsService } from './../../tabs/services/tabs.service';
import { TableOptions, TableQuery } from './../../components/table/table.interfaces';
import { Tab } from './../../tabs/classes/tab';
import { Component, OnInit } from '@angular/core';

import { UtilsService } from 'src/app/services/utils/utils.service';

import { EmailTemplateConfigurationsService } from 'src/app/services/email-template-configurations/email-template-configurations.service';
import { EmailTemplateConfiguration } from 'src/common/entities/EmailTemplateConfiguration';

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss'],
})
export class EmailTemplatesComponent implements OnInit {
  tab: Tab;
  tableOptions: TableOptions<EmailTemplateConfiguration>;

  constructor(
    private emailTemplateConfigurationsService: EmailTemplateConfigurationsService,
    private emailTemplateService: EmailTemplatesService,
    private utilsService: UtilsService,
    private tabsService: TabsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.tab = this.tabsService.register({
      category: 'data',
      route: this.activatedRoute.snapshot,
      title: 'Adminportal Users',
    });

    this.tableOptions = {
      size: 50,
      columns: [
        { header: 'PAGE_EMAIL_TEMPLATES_INTERNAL_NAME', sort: 'internalName' },
        { header: 'PAGE_EMAIL_TEMPLATES_TYPE', sort: 'emailTemplateType' },
      ],
      filters: [{ header: 'PAGE_EMAIL_TEMPLATES_INTERNAL_NAME', path: 'internalName' }],
    };
  }

  query(query: TableQuery<EmailTemplate>) {
    query.result = this.emailTemplateService.getEmailTemplates(query.query);
  }

  newEmailConfiguration() {
    this.router.navigate(['/configuration/email-templates/new']);
  }
}
