import { InputConfiguration } from 'src/common/inputs/Inputs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { UtilsService } from 'src/app/services/utils/utils.service';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { SlideshowStagePageModule } from 'src/common/entities/pagemodules/SlideshowStagePageModule';

@Component({
  selector: 'app-slideshow-page-module-edit',
  templateUrl: './slideshow-page-module-edit.component.html',
  styleUrls: ['./slideshow-page-module-edit.component.scss'],
})
export class SlideshowPageModuleEditComponent implements OnInit, OnDestroy {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: SlideshowStagePageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  currentLanguage: string = null;

  inputJsonpathes: string[] = ['$.local.$language.title', '$.local.$language.background', '$.switchTimerEnabled', '$.switchTime', '$.anchor'];

  stageHeightJsonpathes: string[] = [
    '$.stageHeight.mobile',
    '$.stageHeight.mobileLarge',
    '$.stageHeight.tablet',
    '$.stageHeight.tabletLarge',
    '$.stageHeight.desktop',
    '$.stageHeight.desktopLarge',
    '$.stageHeight.desktopWidescreen',
  ];

  buttonSpacingTopBreakpointJsonpathes: string[] = [
    '$.buttonSpacingTopBreakpoint.mobile',
    '$.buttonSpacingTopBreakpoint.mobileLarge',
    '$.buttonSpacingTopBreakpoint.tablet',
    '$.buttonSpacingTopBreakpoint.tabletLarge',
    '$.buttonSpacingTopBreakpoint.desktop',
    '$.buttonSpacingTopBreakpoint.desktopLarge',
    '$.buttonSpacingTopBreakpoint.desktopWidescreen',
  ];

  subscriptions: Subscription[] = [];

  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  constructor(private collaborationService: CollaborationService, private utilsService: UtilsService, private activatedRoute: ActivatedRoute, private utils: UtilsService) {}

  async ngOnInit() {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.currentLanguage = params.language;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  isDisabled(): boolean {
    return this.collaborationService.isDisabled(this.collaborationKey);
  }
}
