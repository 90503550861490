import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { IconGroupPageModuleFactory } from './../../factories/pagemodules/IconGroupPageModuleFactory';
import { IconGroupPageModuleChildElement, titlePositions } from '../../entities/PageModule';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const iconGroupPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('IconGroup'),
  "$.pageModules[?(@.pageModuleType=='IconGroup' && @._id=='$pageModuleId')].ctaButton": {},

  "$.pageModules[?(@.pageModuleType=='IconGroup' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new IconGroupPageModuleFactory().createIconGroupPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='IconGroup' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },

  "$.pageModules[?(@.pageModuleType=='IconGroup' && @._id=='$pageModuleId')].local.$language.icons": {
    factory: async () => [],
    list: true,
    childFactory: async () => new IconGroupPageModuleFactory().createIconGroupPageModuleLocalIcon({}),
    childLabel: (item: IconGroupPageModuleChildElement, index: number) => item?.title as string,
  },
  "$.pageModules[?(@.pageModuleType=='IconGroup' && @._id=='$pageModuleId')].local.$language.icons[$index]": {},
  "$.pageModules[?(@.pageModuleType=='IconGroup' && @._id=='$pageModuleId')].local.$language.icons[$index].icon": {},
  "$.pageModules[?(@.pageModuleType=='IconGroup' && @._id=='$pageModuleId')].local.$language.icons[$index].title": {},
  "$.pageModules[?(@.pageModuleType=='IconGroup' && @._id=='$pageModuleId')].local.$language.icons[$index].text": {},
  "$.pageModules[?(@.pageModuleType=='IconGroup' && @._id=='$pageModuleId')].local.$language.icons[$index].link": {},
  "$.pageModules[?(@.pageModuleType=='IconGroup' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [...titlePositions].map((item) => ({
      label: item,
      value: item,
    })),
  },
};
