<div class="out-wrapper">
  <div class="wrapper">
    <div class="item">
      <c-field header="User / Groups" description="User / Groups">
        <p-autoComplete
          [forceSelection]="true"
          [(ngModel)]="selectedUserOrGroup"
          [suggestions]="filtredResultList"
          field="name"
          [dropdown]="true"
          (completeMethod)="searchAutoComplete($event)"
        ></p-autoComplete>
      </c-field>
    </div>
    <div class="item">
      <c-field header="Roles" description="Roles">
        <p-multiSelect styleClass="multiselect" [options]="optionRoles" [(ngModel)]="selectedRights" display="chip" optionLabel="name" optionValue="code"></p-multiSelect>
      </c-field>
    </div>
    <div class="item">
      <div class="wrapper-tr">
        <button pButton [disabled]="valid()" class="button-item" type="button" label="Save" (click)="onAdd()"></button>
        <button pButton class="button-item p-button-danger" type="button" label="Clear" (click)="clearModel()"></button>
      </div>
    </div>
  </div>
  <p-table [value]="(rbacs$ | async)?.items" [paginator]="true" [rows]="10" [totalRecords]="(rbacs$ | async)?.totalCount">
    <ng-template pTemplate="header">
      <tr>
        <th>User / Group</th>
        <th>Roles</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rbac>
      <tr>
        <td>
          <b>{{ rbac.adminUser ? 'User:' : 'Group:' }} </b> {{ rbac.adminUser?.displayName }} {{ rbac.group?.name }}
        </td>
        <td>
          <div class="wrapper">
            <p-chips styleClass="contact-chips" [disabled]="true" [ngModel]="rbac.roles | rbacDisplay : optionRoles"></p-chips>
          </div>
        </td>
        <td>
          <div class="wrapper">
            <button class="p-button-sm button-item" pButton type="button" label="Edit" (click)="onEdit(rbac)"></button>
            <button class="p-button-sm p-button-danger button-item" pButton type="button" label="Delete" (click)="onDelete(rbac)"></button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
