import { IRBAC } from './../entities/RBAC';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';

export class RBACFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createRBAC(values?: any) {
    return {
      _id: await this.id(values?._id),
      resource: values?.resource || '',
      adminUser: values?.adminUser || '',
      group: values?.group || '',
      roles: values?.roles || [],
    } as IRBAC;
  }
}
