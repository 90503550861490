// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export class Environment {
  production: boolean;
  apiEndpoint: string;
  frontend: string;

  instanceAlias?: string;
  instanceAliasColor?: string;

  azureAd: {
    clientId: string;
    authority?: string;
    b2cPolicies?: {
      authorities: {
        signIn: {
          authority: string;
        };
      };
      authorityDomain: string;
    };
    apiConfig: {
      scopes: string[];
      uri: string;
    };
    redirectUri: string;
    postLogoutRedirectUri: string;
  };

  cdn: {
    images: string;
    documents: string;
  };

  showroom: {
    trackingUrl: string;
  };
}

export const environment: Environment = {
  production: false,
  apiEndpoint: '',
  frontend: 'https://dev.asys365platform.com',
  //frontend: 'http://localhost:3000',

  azureAd: {
    clientId: 'e443c2a8-583c-4e6a-a954-297650c49798',
    b2cPolicies: {
      authorities: {
        signIn: {
          authority: 'https://asys365platform.b2clogin.com/asys365platform.onmicrosoft.com/b2c_1_si',
        },
      },
      authorityDomain: 'asys365platform.b2clogin.com',
    },
    apiConfig: {
      scopes: ['openid', 'profile'],
      uri: 'http://localhost:4200/api/*',
    },
    redirectUri: 'http://localhost:4200',
    postLogoutRedirectUri: 'http://localhost:4200',
  },

  cdn: {
    images: 'https://dev.cdn.asys365platform.com',
    documents: 'https://dev.cdn.asys365platform.com',
  },

  showroom: {
    trackingUrl: 'https://devjntracking.asys365platform.com/api/v1/showrooms',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import "zone.js/dist/zone-error";  // Included with Angular CLI.
