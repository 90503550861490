<div class="filter" *ngIf="currentFilter.length > 0">
  <strong>Current Filter</strong>
  <span *ngFor="let filter of currentFilter; let last = last">
    <span *ngIf="_filterList[filter.path].matchMode === 'exists'"> {{ filter.header | translate }}&nbsp;{{ filterValue(filter) ? 'exists' : 'does not exist' }}<span *ngIf="!last">, </span> </span>
    <span *ngIf="_filterList[filter.path].matchMode !== 'exists'">
      {{ filter.header | translate }}&nbsp;{{ 'GENERAL_MATCHMODE_' + _filterList[filter.path].matchMode.toUpperCase() | translate }}&nbsp;{{ filterValue(filter) }}<span *ngIf="!last">, </span>
    </span>
  </span>
  <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="clearFilter()">[Clear]</a>
</div>
<div class="data-container">
  <table *ngIf="_tableColumnSettings && _filterList">
    <thead>
      <tr>
        <div *ngIf="options.selectable" class="table-select-box">
          <p-checkbox [binary]="true" [(ngModel)]="_selectAll" (ngModelChange)="onSelectAll($event)"></p-checkbox>
        </div>
        <th
          *ngFor="let col of options.columns; let index = index"
          [class.selected]="_sort?.columnIndex == index"
          [class.clickable]="col.sort"
          [class.hide]="_tableColumnSettings[index].visible === false"
          (click)="col.sort && sort(index)"
        >
          {{ col.header | translate }}
          <i *ngIf="index === _sort?.columnIndex" class="pi" [class.pi-sort-amount-down-alt]="_sort.direction === -1" [class.pi-sort-amount-up-alt]="_sort.direction === 1"></i>
        </th>
      </tr>
    </thead>

    <tbody *ngIf="_templates && !singleTemplate">
      <tr
        *ngFor="let d of _data; let index = index; let first = first; let last = last"
        [ngClass]="{ 'active-link': (baseRoute && routePathProperty) || itemSelectedHasSubcriber }"
        (click)="itemIsSelected(d, index); navigateTo([baseRoute, d[routePathProperty]])"
      >
        <div *ngIf="options.selectable" class="table-select-box">
          <p-checkbox [binary]="true" [(ngModel)]="_selection[index]" (ngModelChange)="onSelect($event)"></p-checkbox>
        </div>
        <td
          *ngFor="let col of options.columns; let columnIndex = index; let columnFirst = first; let columnLast = last"
          [class.selected]="_sort.columnIndex == columnIndex"
          [class.hide]="_tableColumnSettings[columnIndex].visible === false"
          [className]="_tableColumnSettings[columnIndex].actions ? 'actions' : ''"
        >
          <ng-template
            [ngTemplateOutlet]="_templates[columnIndex]"
            [ngTemplateOutletContext]="{ $implicit: d, index: index, first: first, last: last, columnIndex: columnIndex, columnFirst: columnFirst, columnLast: columnLast }"
          ></ng-template>
        </td>
      </tr>
    </tbody>

    <tbody *ngIf="_templates && singleTemplate">
      <ng-container *ngFor="let d of _data; let index = index; let first = first; let last = last">
        <ng-template [ngTemplateOutlet]="_templates[0]" [ngTemplateOutletContext]="{ $implicit: d, index: index, first: first, last: last }"></ng-template>
      </ng-container>
    </tbody>
  </table>
  <c-loading [loading]="loading" [hideTitle]="true"></c-loading>
</div>
<div class="paginator" *ngIf="!options || !options.size || options.size > 0">
  <p-paginator (onPageChange)="onPageChange($event)" [rows]="options?.size || _defaultSize" [totalRecords]="_totalRecords"></p-paginator>
</div>
