export const globalButtonStyleTypes = ['default', 'croppedCorner', 'arrow'] as const;
export type GlobalButtonStyleType = (typeof globalButtonStyleTypes)[number];

export const iconFontTypes = ['v2', 'default'] as const;
export type IconFontType = (typeof iconFontTypes)[number];

export const buttonAlignmentSection = ['left', 'right'] as const;
export type ButtonAlignmentSection = (typeof buttonAlignmentSection)[number];

export const dialogModalType = ['closeIconInside', 'closeIconOutside'] as const;
export type DialogModalType = (typeof dialogModalType)[number];

export const headerStyle = ['centeredWithLogo', 'rightAlignedWithLogo'] as const;
export const headerStyleLabels = ['Centered Navigation with Logo above', 'Right-Aligned Navigation with Logo'] as const;
export type HeaderStyleType = (typeof headerStyle)[number];
export interface ThemeConfiguration {
  internalName: string;
  description: string;
  icon: string;
  sharingImage: string;
  logo: string;
  logoMobile: string;
  appLogo: string;
  globalButtonStyle: GlobalButtonStyleType;
  iconFont: IconFontType;
  theme: string;
  headerStyle: HeaderStyleType;
  mobileNavigationOnly?: boolean;
  stickyNavigation?: boolean;
  manifest?: Manifest;
  linkTags?: LinkTag[];
  loginDialogHeaderImage?: string;
  loginDialogBackgroundImage?: string;
  loginDialogCheckIcon?: string;
  loginDialogArrowIcon?: string;
  loginDialogButtonAlignmentSection?: ButtonAlignmentSection;
  loginDialogModalType?: DialogModalType;
}

export interface Manifest {
  icons: ManifestIcon[];
}

export interface ManifestIcon {
  src: string;
  type: string;
  sizes: string;
}

export interface LinkTag {
  rel: string;
  href: string;
  type?: string;
  sizes?: string;
}
