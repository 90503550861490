import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { Lookup } from '../entities/Lookup';

export class LookupFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createLookup(values?: any) {
    return {
      _id: await this.id(values?._id),
      shortId: values?.shortId || '',
      internalName: values.internalName || 'New Lookup',
      description: values.description || 'Description',
      local: await this.local(values, async (v) => this.createLookupLocal(v)),
    } as Lookup;
  }

  async createLookupLocal(values?: any) {
    return {
      keys: values.keys || [],
    };
  }

  /*   async createLookupRegional(values?: any) {
    return {
      keys: values.keys || []
    }
  }

  async createLookupData(values?: any) {
    return {
      keys: values.keys || []
    }
  } */
}
