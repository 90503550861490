import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { titlePositions } from './../../entities/pagemodules/PageModule';
import { ListImagePageModuleFactory } from '../../factories/pagemodules/ListImagePageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const listImagePageModulesInputs: Inputs = {
  ...commonPageModuleInputs('ListImage'),
  "$.pageModules[?(@.pageModuleType=='ListImage' && @._id=='$pageModuleId')].ctaButton": {},

  "$.pageModules[?(@.pageModuleType=='ListImage' && @._id=='$pageModuleId')].imageSize": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Large', value: 'large' },
      { label: 'Medium', value: 'medium' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='ListImage' && @._id=='$pageModuleId')].imagePosition": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Left', value: 'left' },
      { label: 'Right', value: 'right' },
    ],
  },

  "$.pageModules[?(@.pageModuleType=='ListImage' && @._id=='$pageModuleId')].imagePositionWithinImageArea": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Left', value: 'left' },
      { label: 'Right', value: 'right' },
      { label: 'Center', value: 'center' },
    ],
  },

  "$.pageModules[?(@.pageModuleType=='ListImage' && @._id=='$pageModuleId')].imagePositionMobile": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Bottom', value: 'bottom' },
      { label: 'Top', value: 'top' },
    ],
  },

  "$.pageModules[?(@.pageModuleType=='ListImage' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new ListImagePageModuleFactory().listImagePageModuleLocal({}),
  },

  "$.pageModules[?(@.pageModuleType=='ListImage' && @._id=='$pageModuleId')].local.$language.itemIcon": {
    type: 'imageasset',
  },
  "$.pageModules[?(@.pageModuleType=='ListImage' && @._id=='$pageModuleId')].local.$language.image": {
    type: 'imageasset',
  },
  "$.pageModules[?(@.pageModuleType=='ListImage' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='ListImage' && @._id=='$pageModuleId')].local.$language.items": {
    factory: async () => [],
    list: true,
    childFactory: async () => '',
  },
  "$.pageModules[?(@.pageModuleType=='ListImage' && @._id=='$pageModuleId')].local.$language.items[$index]": {
    header: 'Item',
  },
  "$.pageModules[?(@.pageModuleType=='ListImage' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [{ label: 'content', value: 'content' }].concat(
      [...titlePositions].map((item) => ({
        label: item,
        value: item,
      }))
    ),
  },
};
