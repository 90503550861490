export const appointmentTypes = ['MSTeams'] as const;
export type AppointmentType = (typeof appointmentTypes)[number];

export const appointmentStatus = ['Inquired', 'Confirmed', 'Rejected', 'Done', 'Deleted'] as const;
export type AppointmentStatus = (typeof appointmentStatus)[number];

export const appointmentOrigins = ['Customer', 'Host', 'Organizer'] as const;
export type AppointmentOrigin = (typeof appointmentOrigins)[number];

export interface AppointmentContent {
  appointmentType?: AppointmentType;

  alreadyContact?: boolean;
  topic?: string;
  otherTopics?: string[];
  description?: string;

  region?: string;
  status?: AppointmentStatus;
  user?: string;

  dateFrom?: string | Date;
  dateTo?: string | Date;
  actualDateFrom?: string | Date;
  actualDateTo?: string | Date;
  joinedDigitalShowRoomAt?: string | Date; // first join date

  alternative1DateFrom?: string | Date;
  alternative1DateTo?: string | Date;
  alternative2DateFrom?: string | Date;
  alternative2DateTo?: string | Date;

  organizer?: string;

  participants: {
    users: string[];
    adminUsers?: string[];
    host?: string;
  };
}

export interface Appointment extends AppointmentContent {
  _id: string;
  users?: string[];
  createdAt?: string | Date;
  deletedAt?: boolean;
  externalAppointment?: ExternalAppointment;
  session?: string;
  event?: string;
  initiatedBy: string;
  origin: AppointmentOrigin;
}

export interface ExternalAppointment {
  id?: string;
  link?: string;
  plugin: {
    pluginName: string;
    pluginType: string;
    name: string;
    version: string;
  };
  plain?: any;
  error?: any;
}

export interface MSTeamsAppointment extends Appointment {
  appointmentType: 'MSTeams';
}

export function isMSTeamsAppointment(object: any): object is MSTeamsAppointment {
  return object?.appointmentType === 'MSTeamsAppointment';
}

export interface ScheduledAppointment {
  externalAppointment: {
    id: string;
  };
  dateFrom: string;
  dateTo: string;
}

export function isScheduledAppointment(object: any): object is ScheduledAppointment {
  return object?.externalAppointment?.id && object?.dateFrom && object?.dateTo;
}
