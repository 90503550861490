import { InteractionConfiguration } from './../Interaction';
import { ContactConfiguration } from './ContactConfiguration';
import { LoginConfiguration } from './LoginConfiguration';
import { MenuConfiguration } from './MenuConfiguration';
import { PlatformConfiguration } from './PlatformConfiguration';
import { PluginsConfiguration } from './PluginsConfiguration';
import { ThemeConfiguration } from './ThemeConfiguration';
import { NotificationConfiguration } from './NotificationConfiguration';
import { SystemPluginsConfiguration } from './SystemPluginsConfiguration';
import { AssetConfiguration } from './AssetConfiguration';
import { DomainConfiguration } from './DomainConfiguration';
import { AppConfiguration } from './AppConfiguration';
import { VersionConfiguration } from './VersionConfiguration';

export * from './ContactConfiguration';
export * from './LoginConfiguration';
export * from './MenuConfiguration';
export * from './PlatformConfiguration';
export * from './PluginsConfiguration';
export * from './ThemeConfiguration';
export * from './NotificationConfiguration';
export * from './SystemPluginsConfiguration';
export * from './AssetConfiguration';
export * from './AppConfiguration';
export * from './VersionConfiguration';

export const multiValueKeys = ['theme'];

export type ConfigurationType =
  | DomainConfiguration
  | PlatformConfiguration
  | MenuConfiguration
  | ThemeConfiguration
  | LoginConfiguration
  | PluginsConfiguration
  | ContactConfiguration
  | NotificationConfiguration
  | SystemPluginsConfiguration
  | AssetConfiguration
  | InteractionConfiguration
  | AppConfiguration
  | VersionConfiguration;

export interface Configuration {
  [key: string]: any;
  domain: DomainConfiguration;
  platform: PlatformConfiguration;
  menu: MenuConfiguration;
  theme: ThemeConfiguration;
  login: LoginConfiguration;
  plugins: PluginsConfiguration;
  contact: ContactConfiguration;
  interaction: InteractionConfiguration;
  notification: NotificationConfiguration;
  systemPlugins: SystemPluginsConfiguration;
  asset: AssetConfiguration;
  app: AppConfiguration;
  version: VersionConfiguration;
}

export interface ConfigurationEntry {
  _id?: string;
  domainCollection: string;
  active: boolean;
  key: string;
  value: ConfigurationType;
}
