import { TextAttributes } from './../Attributes';
import { CTAButton } from '../CTAButton';
import { PageModule, TitlePositions } from './PageModule';

export interface Text3DImagePageModule extends PageModule {
  pageModuleType: 'Text3DImage';
  titlePosition?: TitlePositions;
  imagePosition: 'left' | 'right';
  local: {
    [language: string]: {
      title: string;
      title_attr?: Text3DImagePageModuleAttributes;
      text: string;
      image: string;
    };
  };
  ctaButton?: CTAButton;
}

export type Text3DImagePageModuleAttributes = TextAttributes & {};

export function isText3DImagePageModule(object: any): object is Text3DImagePageModule {
  return object?.pageModuleType === 'Text3DImage';
}
