<div class="layout-container">
  <div class="panel">
    <div class="panel-header left-actions">
      <div class="actions">
        <p-button *ngIf="context.event" [label]="'COMPONENT_LAYOUT_EDITOR_SITE_STRUCTURE' | translate" icon="pi icon-ic-version" (onClick)="showSiteStructure = !showSiteStructure"></p-button>
        <p-button *ngIf="context.event" [label]="'GENERAL_PAGES' | translate" icon="pi icon-ic-pages" (onClick)="showPages = !showPages"></p-button>
        <p-button
          *ngIf="!context.event && enableProperties"
          [label]="'GENERAL_PROPERTIES' | translate"
          icon="pi icon-ic-pages"
          (onClick)="editGlobalPageProperties = !editGlobalPageProperties"
        ></p-button>

        <div class="info-box" *ngIf="context.page && context.event">
          <div class="value">{{ context.eventPhase }}</div>
          <div class="title">Event Phase</div>
        </div>
        <div class="info-box" *ngIf="context.page && context.event">
          <div class="value">{{ context.page?.local[currentLanguage]?.title || ('GENERAL_UNNAMED' | translate) }}</div>
          <div class="title">Title</div>
        </div>
        <div class="info-box" *ngIf="context.page && context.event">
          <div class="value">/&nbsp;{{ context.page?.path }}</div>
          <div class="title">Path</div>
        </div>
        <div class="info-box" *ngIf="hasConditions()">
          <div class="warn"><i class="pi pi-lock"></i> has conditions</div>
        </div>

        <div *ngIf="message" class="dynamic-message">
          <p-message [severity]="message.severity" [text]="message.text"></p-message>
        </div>
      </div>

      <div class="actions">
        <p-button [label]="selectedUserLabel === 'IGNORE' ? 'Ignore Conditions' : 'Selected User: ' + selectedUserLabel" (onClick)="showUserSelect = !showUserSelect"></p-button>
        <p-splitButton
          [label]="(fullscreen ? 'GENERAL_MINIMIZE' : 'GENERAL_FULLSCREEN') | translate"
          [model]="headerMenuItems"
          (onClick)="fullscreen = !fullscreen"
          [appendTo]="'body'"
        ></p-splitButton>
      </div>
    </div>

    <div class="layout-canvas no-page" *ngIf="!context.page && context.eventVersion">
      <p-message severity="warn" text="There are currently no pages defined"></p-message>
    </div>

    <p-confirmDialog [closable]="false" key="pageModuleRemove"></p-confirmDialog>

    <div class="layout-canvas" *ngIf="context.page && context.eventVersion">
      <div class="layout-device">
        <c-page-layout-editor
          [page]="context.page"
          [eventVersion]="context.eventVersion"
          [event]="context.event"
          [eventPhase]="context.eventPhase"
          [device]="currentDevice"
          [viewMode]="currentViewMode"
          [loggedIn]="selectedUser.loggedIn"
          [ignoreConditions]="selectedUser.ignoreConditions"
          [eventTickets]="selectedUser.eventTickets"
          [language]="language"
        ></c-page-layout-editor>
      </div>
    </div>
    <div class="layout-canvas" *ngIf="context.page && context.pageVersion">
      <div class="layout-device">
        <c-page-layout-editor
          [page]="context.page"
          [pageVersion]="context.pageVersion"
          [loggedIn]="selectedUser.loggedIn"
          [ignoreConditions]="selectedUser.ignoreConditions"
          [device]="currentDevice"
          [viewMode]="currentViewMode"
          [language]="language"
        ></c-page-layout-editor>
      </div>
    </div>
  </div>
</div>

<!-- Edit Event Stage Sidebar Dialog -->
<p-sidebar
  *ngIf="showEditEventStage"
  [(visible)]="showEditEventStage"
  #NavSideBar
  (onHide)="NavSideBar.destroyModal()"
  styleClass="p-sidebar-st"
  position="right"
  [modal]="false"
  [showCloseIcon]="false"
>
  <c-event-stage-edit
    *ngIf="editEventStage"
    class="sidebar-container"
    [event]="context.event"
    [eventVersion]="context.eventVersion"
    [eventStage]="editEventStage"
    [page]="page"
    [collaborationKey]="collaborationKey"
    [jsonpath]="eventStageJsonpath()"
    [jsonpathParams]="jsonpathParams"
    (onClose)="showEditEventStage = false"
  ></c-event-stage-edit>
</p-sidebar>

<!-- Add Event Stage Sidebar Dialog -->
<p-sidebar *ngIf="showAddEventStage" [(visible)]="showAddEventStage" #NavSideBar (onHide)="NavSideBar.destroyModal()" styleClass="p-sidebar-st" position="right" [modal]="true" [showCloseIcon]="false">
  <c-loading *ngIf="addEventStage && addEventStage.type"></c-loading>
  <c-event-stage-type-select
    *ngIf="addEventStage && !addEventStage.type"
    class="sidebar-container"
    (selected)="eventStageTypeSelected($event)"
    (onClose)="showAddEventStage = false"
  ></c-event-stage-type-select>
</p-sidebar>

<!-- Edit Page Module Sidebar Dialog -->
<p-sidebar
  *ngIf="showEditPageModule"
  [(visible)]="showEditPageModule"
  #NavSideBar
  styleClass="p-sidebar-st"
  position="right"
  [modal]="true"
  [showCloseIcon]="false"
  (onHide)="NavSideBar.destroyModal()"
>
  <c-page-module-edit
    *ngIf="editPageModule"
    class="sidebar-container"
    [event]="context.event"
    [eventVersion]="context.eventVersion"
    [page]="page"
    [pageModule]="editPageModule"
    [collaborationKey]="collaborationKey"
    [jsonpath]="pageModuleJsonpath(editPageModule)"
    [jsonpathParams]="pageModuleJsonpathParams(editPageModule)"
    (onClose)="showEditPageModule = false"
  ></c-page-module-edit>
</p-sidebar>

<!-- Insert Page Module Sidebar Dialog -->
<p-sidebar
  *ngIf="showInsertPageModule"
  [(visible)]="showInsertPageModule"
  #NavSideBar
  (onHide)="NavSideBar.destroyModal()"
  styleClass="p-sidebar-st"
  position="right"
  [modal]="true"
  [showCloseIcon]="false"
>
  <c-loading *ngIf="insertPageModule && insertPageModule.type"></c-loading>
  <c-page-module-type-select
    *ngIf="insertPageModule && !insertPageModule.type"
    class="sidebar-container"
    (selected)="pageModuleTypeSelected($event)"
    (onClose)="showInsertPageModule = false"
    [isEventPage]="!!context.eventVersion"
  ></c-page-module-type-select>
</p-sidebar>

<!-- Edit Global Page Properties Sidebar Dialog -->
<p-sidebar
  *ngIf="context && enableProperties"
  [(visible)]="editGlobalPageProperties"
  #NavSideBar
  styleClass="p-sidebar-st"
  position="left"
  [modal]="true"
  [showCloseIcon]="false"
  (onHide)="updateContext({}); NavSideBar.destroyModal()"
>
  <c-page-edit
    class="sidebar-container"
    [event]="context.event"
    [eventVersion]="context.eventVersion"
    [eventPhase]="context.eventPhase"
    [page]="page"
    [jsonpath]="globalPageJsonpath()"
    [jsonpathParams]="jsonpathParams"
    (onClose)="editGlobalPageProperties = false"
    (pageDeleted)="context.page = null; editGlobalPageProperties = false"
  ></c-page-edit>
</p-sidebar>

<!-- Site Structure Dialog -->
<c-sidebar
  *ngIf="context.event && showSiteStructure"
  [(visible)]="showSiteStructure"
  position="left"
  [header]="'GENERAL_OVERVIEW'"
  [subheader]="'COMPONENT_LAYOUT_EDITOR_SITE_STRUCTURE'"
  [tabs]="siteStructureTabs"
  [activeTab]="activeSiteStructureTab"
  (activeTabChange)="activeSiteStructureTab = $event"
>
  <c-site-structure class="sidebar-container" [context]="context" [tab]="activeSiteStructureTab" (onClose)="showSiteStructure = false"></c-site-structure>
</c-sidebar>

<!-- Page Overview Sidebar Dialog -->
<p-sidebar *ngIf="showPages" [(visible)]="showPages" #NavSideBar (onHide)="NavSideBar.destroyModal()" styleClass="p-sidebar-st" position="left" [modal]="true" [showCloseIcon]="false">
  <c-event-layout-pages
    class="sidebar-container"
    [event]="context.event"
    [eventVersion]="context.eventVersion"
    [currentPage]="context.page"
    (currentChange)="newContext($event)"
    [currentEventPhase]="context.eventPhase"
    (onClose)="hideSidebar()"
  ></c-event-layout-pages>
</p-sidebar>

<p-dialog [(visible)]="showUserSelect" [modal]="true" header="Select User" [appendTo]="'body'">
  <div *ngIf="selectedUserEdit">
    <div class="p-field-radiobutton">
      <p-radioButton name="mode" value="ignoreConditions" [(ngModel)]="selectedUserEditMode" inputId="ignoreConditions"></p-radioButton>
      <label class="radio-box" for="ignoreConditions">
        <div class="title">Ignore all conditions</div>
        <div class="description">Ignore all conditions to see and configure all content of the page</div>
      </label>
    </div>
    <div class="line"></div>
    <div class="p-field-radiobutton">
      <p-radioButton name="mode" value="public" [(ngModel)]="selectedUserEditMode" inputId="public"></p-radioButton>
      <label class="radio-box" for="public">
        <div class="title">Not logged in</div>
        <div class="description">How a user, who is not logged in to the platform, would see the page</div>
      </label>
    </div>
    <div class="p-field-radiobutton">
      <p-radioButton name="mode" value="platform" [(ngModel)]="selectedUserEditMode" inputId="platform"></p-radioButton>
      <label class="radio-box" for="platform">
        <div class="title">Logged in</div>
        <div class="description">How a user, who is logged in to the platform, would see the page</div>
      </label>
    </div>
    <div *ngIf="context.eventVersion">
      <div class="p-field-radiobutton">
        <p-radioButton name="mode" value="eventticket" [(ngModel)]="selectedUserEditMode" inputId="eventticket"></p-radioButton>
        <label class="radio-box" for="eventticket">
          <div class="title">With ticket</div>
          <div class="description">How a user, who is logged in to the platform and has a specific ticket, would see the page</div>
        </label>
      </div>
      <div class="radiobutton-subcontent" *ngIf="selectedUserEditMode === 'eventticket' && selectedUserEdit?.eventTickets">
        <p-multiSelect [options]="tickets" [(ngModel)]="selectedUserEdit.eventTickets" defaultLabel="Select a Ticket" [appendTo]="'body'"></p-multiSelect>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button label="Set User" (onClick)="setSelectedUser()" [disabled]="selectedUserEditMode === 'eventticket' && selectedUserEdit?.eventTickets?.length === 0"></p-button>
  </ng-template>
</p-dialog>
