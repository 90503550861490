import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ValidatorService } from 'src/app/services/validator/validator.service';

import { BaseUser, isUser, User } from 'src/common/entities/User';
import { UsersService } from 'src/app/services/users/users.service';
import { userValidator } from 'src/common/api/v1/users/validators/UserValidator';
import { DomainBlacklistEntry, isDomainBlacklistEntry } from 'src/common/entities/BlacklistEntry';

import { Lookup } from 'src/common/entities/Lookup';
import { LookupsService } from 'src/app/services/lookups/lookups.service';
import { Language } from 'src/common/entities/Language';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { I18nService } from 'src/app/services/i18n/i18n.service';
import { EventRegistration } from 'src/common/entities/EventRegistration';
import { EventsService } from 'src/app/services/events/events.service';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { TagAssignment } from 'src/common/entities/TagAssignment';
import { TableComponent } from 'src/app/components/table/table.component';
import { TableOptions, TableQuery } from 'src/app/components/table/table.interfaces';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit, OnDestroy {
  readonly = false; // Current working users readonly setting
  hasEditRights: boolean = false;
  tagAssignmentsTableOptions: TableOptions<TagAssignment>;

  userId: string;
  user: User = null;

  currentUserEvents: EventRegistration[] = [];
  userEvents: EventRegistration[] = [];
  userEventsTotalRecords: number = null;
  userTags: string[] = [];
  userTagAssignments: TagAssignment[] = [];

  // salesManager: AdminUser = null;
  // filteredSalesManager: AdminUser[] = []

  languages: Language[] = [];
  luSalutation: Lookup = null;
  luJobTitle: Lookup = null;
  allEventTypes: { [id: string]: string } = {};

  tab: Tab;
  currentSubTab: 'userstatus' | 'userdata' | 'eventregistration' | 'tagassignment' = 'userstatus';
  @ViewChild('tagAssignmentsTable') tagAssignmentsTable: TableComponent<any>;

  private _ngUnsubscribe: Subject<any> = new Subject<any>();

  countries: {
    key: string;
    value: string;
  }[] = [];

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private validatorService: ValidatorService,
    private usersService: UsersService,
    private eventsService: EventsService,
    private languagesService: LanguagesService,
    private lookupsService: LookupsService,
    private i18nService: I18nService,
    private tabsService: TabsService
  ) {
    this.tab = this.tabsService.register({
      category: 'users',
      parent: '/users',
      loading: true,
      route: this.activatedRoute.snapshot,
    });

    this.tagAssignmentsTableOptions = {
      size: 50,
      columns: [{ header: 'Type' }, { header: 'Condition' }, { header: 'Tags' }],
    };
  }

  async ngOnInit(): Promise<void> {
    const countries = this.i18nService.getLocaleForEn().countries;
    this.countries = Object.keys(countries).map((i) => ({
      key: i.toLowerCase(),
      value: Array.isArray(countries[i]) ? countries[i][0] : countries[i],
    }));

    this.activatedRoute.params.pipe(takeUntil(this._ngUnsubscribe)).subscribe(async (params) => {
      this.userId = params.userId;
      await this.retrieveData();
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.tab && params.tab !== this.currentSubTab) {
        this.currentSubTab = params.tab;
      }
    });
    this.hasEditRights = await this.authService.hasRight('users:' + this.userId + '$' + 'users.edit');
  }

  ngOnDestroy(): void {
    // Unsubscribe subscriptions
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  tagAssignmentsQuery(query: TableQuery<TagAssignment>) {
    // For simplicity retrieve only via id
    query.result = this.usersService.getUserTagAssignments(this.userId);
  }

  asUser(baseUser: BaseUser): User {
    return isUser(baseUser) ? baseUser : null;
  }

  async retrieveData(): Promise<void> {
    this.tab.loading = true;
    // Get requested user, salutation lookup and languages
    const [promiseUser, promiseCurrentUserEvents, promiseUserEvents, promiseSalutation, promiseJobTitle, promiseLanguages, promiseEventTypes, userTags, userTagAssignments] = await Promise.all([
      this.usersService.getUser(this.userId),
      this.usersService.getUserEventRegistrations(this.userId, {
        filter: {
          unregisteredAt: { matchMode: 'equals', value: null },
        },
      }),
      this.usersService.getUserEventRegistrations(this.userId, {}),
      this.lookupsService.getLookup('salutations'),
      this.lookupsService.getLookup('departments'),
      this.languagesService.getActiveLanguagesAsPromise(),
      this.eventsService.getEventTypes(),
      this.usersService.getUserTags(this.userId),
      this.usersService.getUserTagAssignments(this.userId),
    ]);
    this.user = this.asUser(promiseUser);
    this.tab.title = this.user.displayName;
    this.currentUserEvents = promiseCurrentUserEvents.items as EventRegistration[];
    this.userEvents = promiseUserEvents.items as EventRegistration[];
    this.userEventsTotalRecords = promiseUserEvents.totalCount;
    this.userTags = userTags.items;
    this.userTagAssignments = userTagAssignments.items;

    // if (this.user.salesManager) {
    //   this.salesManager = await this.adminUsersService.getAdminUser(this.user.salesManager);
    // } else {
    //   this.salesManager = null;
    // }
    this.luSalutation = promiseSalutation;
    this.luJobTitle = promiseJobTitle;
    this.languages = promiseLanguages;
    promiseEventTypes.items.forEach((et) => (this.allEventTypes[et._id] = et.internalName));
    this.tab.loading = false;
  }

  async save(): Promise<void> {
    this.tab.loading = true;
    try {
      const baseUser = await this.usersService.updateUser(this.user);
      this.user = this.asUser(baseUser);
      // if (this.user.salesManager) {
      //   this.salesManager = await this.adminUsersService.getAdminUser(this.user.salesManager);
      // } else {
      //   this.salesManager = null;
      // }
    } catch (err) {
      this.user = null;
      throw err;
    }

    this.tab.loading = false;
  }

  asDomainBlacklistEntry(): DomainBlacklistEntry {
    return isUser(this.user) && isDomainBlacklistEntry(this.user.blacklistEntry) ? (this.user.blacklistEntry as DomainBlacklistEntry) : null;
  }

  isValid(): boolean {
    return this.validatorService.isValid(userValidator, this.user);
  }

  errorAt(path: string): string | null {
    return this.validatorService.errorAt(userValidator, this.user, path);
  }

  async retryCRMEventRegistration(eventRegistration: EventRegistration): Promise<void> {
    this.tab.loading = true;

    try {
      const eventId = typeof eventRegistration.event === 'object' ? eventRegistration.event._id : eventRegistration.event;
      Object.assign(eventRegistration, await this.eventsService.crmEventRegistrationRetry(eventId, eventRegistration._id));
    } catch (err) {
      console.error(err);
    }

    this.tab.loading = false;
  }

  // async filterSalesManager(event) {
  //   // Temporary solution: All admin users are available
  //   const remoteAdminUserFilter = {
  //     source: 'Remote',
  //     filter: {
  //       displayName: {
  //         matchMode: 'contains',
  //         value: event.query
  //       }
  //     }
  //   };
  //   this.filteredSalesManager = (await this.adminUsersService.getRemoteUsers(remoteAdminUserFilter as GetAdminUsersQuery)).items;
  // }

  // async selectSalesManager(choosenUser: AzureADAdminUser | AdminUser) {
  //   this.salesManager = choosenUser;
  //   if (isAzureADAdminUser(choosenUser)) {
  //     const adminUser = await this.adminUsersService.getAdminUser(choosenUser.oid);
  //     this.user.salesManager = adminUser._id;
  //   } else {
  //     this.user.salesManager = choosenUser._id;
  //   }
  // }
}
