import { VideoSliderPageModuleModuleAttributes } from './../../entities/pagemodules/VideoSliderPageModule';
import { HeadlineType } from './../../entities/Attributes';
import { VideoSliderPageModule } from '../../entities/pagemodules/VideoSliderPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class VideoSliderPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async createVideoSliderPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.videoSliderPageModuleModuleAttributes(values?.title_attr),
      videos: values?.videos || [],
    };
  }

  public videoSliderPageModuleModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): VideoSliderPageModuleModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async videoSliderPageModule(values: any): Promise<VideoSliderPageModule> {
    const pageModule = {
      ...(await this.baseValues(values)),
      pageModuleType: 'VideoSlider',
      videoSliderType: !values?.videoSliderType && values.showHighlightSessions ? 'Highlights' : values?.videoSliderType || 'Default',
      local: await this.local(values, (val) => this.createVideoSliderPageModuleLocal(val)),
      ctaButton: values?.ctaButton || null,
    };
    return { ...pageModule, pageModuleType: 'VideoSlider' };
  }
}
