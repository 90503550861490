import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetsService } from 'src/app/services/assets/assets.service';
import { Asset } from 'src/common/entities/Asset';
import { Product } from 'src/common/entities/Product';

@Component({
  selector: 'c-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent implements OnInit, OnChanges {
  @Input()
  product: Product;
  productThumbnail: Observable<Asset>;

  @Input()
  disableDetails = false;

  constructor(private assetsService: AssetsService) {}

  ngOnInit(): void {
    if (this.product?.image) {
      this.productThumbnail = this.assetsService.getAsset(this.product.image);
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.product?.currentValue && changes.product?.currentValue !== changes.product?.previousValue) {
      this.productThumbnail = null;
      if (this.product?.image) {
        this.productThumbnail = this.assetsService.getAsset(this.product.image);
      }
    }
  }
}
