<p-button styleClass="p-button-text" icon="pi pi-globe" [label]="'Domain: ' + currentButtonLabel" (click)="domainCollectionId ? menu.toggle($event) : openSplitDialog($event)"></p-button>
<p-menu #menu [popup]="true" [appendTo]="'body'" [model]="domainOptions" (onShow)="fillDomainOptions()"></p-menu>
<p-confirmPopup [key]="'languageButtonPopup'"></p-confirmPopup>
<p-dialog
  header="Unify Configuration again"
  [(visible)]="showUnifyModal"
  [modal]="true"
  (onHide)="showUnifyModal = false"
  [closable]="true"
  [closeOnEscape]="true"
  [appendTo]="'body'"
  (close)="showUnifyModal = false"
  [style]="{ 'min-width': '35vw' }"
>
  <div>
    <div class="input-group dropdown">
      <div class="input">
        <p-dropdown [appendTo]="'body'" [disabled]="working" [options]="unifyOptions" #fromDomain></p-dropdown>
      </div>
      <div class="title">Choose which domain-based Config to keep</div>
    </div>

    <div class="text-right">
      <p-button icon="pi pi-trash" styleClass="p-button-sm" [label]="'Unify'" (click)="unify(fromDomain.selectedOption.value)"> </p-button>
    </div>
  </div>
</p-dialog>
