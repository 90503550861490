import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { AutoAssignment, AutoAssignmentType, EventRegistrationAutoAssignment, GlobalRegistrationAutoAssignment } from '../entities/AutoAssignment';
import { SlotFactory } from './SlotFactory';
import { RegistrationCustomFieldFactory } from './RegistrationCustomFieldFactory';

export class AutoAssignmentFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async createAutoAssignment(values: { autoAssignmentType: AutoAssignmentType }): Promise<AutoAssignment> {
    switch (values.autoAssignmentType) {
      case 'GlobalRegistration':
        return { ...values, ...(await this.createGlobalRegistrationAutoAssignment(values)) };
      case 'EventRegistration':
        return { ...values, ...(await this.createEventRegistrationAutoAssignment(values)) };
    }
  }

  async createGlobalRegistrationAutoAssignment(values?: any): Promise<GlobalRegistrationAutoAssignment> {
    return {
      _id: await this.id(values?._id),
      query: values?.query || null,
      autoAssignmentType: 'GlobalRegistration',
      globalTicket: values?.globalTicket || '',
      validFrom: values?.validFrom || new Date(),
      validUntil: values?.validUntil || new Date()
    } as GlobalRegistrationAutoAssignment;
  }

  async createEventRegistrationAutoAssignment(values?: any): Promise<EventRegistrationAutoAssignment> {
    return {
      _id: await this.id(values?._id),
      sendMail: values?.sendMail || false,
      autoAssignmentType: 'EventRegistration',
      event: values?.event || '',
      query: values?.query || null,
      eventTicket: values?.eventTicket || '',
      eventSlots: await Promise.all((values?.eventSlots || []).map((s: any) => new SlotFactory(this.options).createCombinedEventSlot(s))),
      customFields: values?.customFields || [].map(c => new RegistrationCustomFieldFactory(this.options).createCustomField(c))
    } as EventRegistrationAutoAssignment;
  }
}
