import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { eventImagesValidator, eventLocalValidator, eventValidator } from 'src/common/api/v1/events/validators/EventValidator';
import { Event } from 'src/common/entities/Event';
import { ContentChecklistGroup, ContentChecklistItem } from 'src/common/entities/EventVersion';
import { EventVersionFactory } from 'src/common/factories/EventVersionFactory';
import { AbstractFactory } from 'src/common/factories/AbstractFactory';
import { EventVersion } from 'src/common/entities/EventVersion';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import 'moment-timezone';
import * as tz from 'src/assets/timezones/tz-names';

import { InputConfiguration, Inputs } from 'src/common/inputs/Inputs';
import { ListeningQueryParamService } from 'src/app/services/utils/listening-query-param.service';
import { I18nService } from '../../../services/i18n/i18n.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'c-event-basic-information',
  templateUrl: './event-basic-information.component.html',
  styleUrls: ['./event-basic-information.component.scss'],
})
export class EventBasicInformationComponent implements OnInit, OnDestroy {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  currentLanguage: string;

  descriptionStartAt: string;
  descriptionEndAt: string;

  inputConfigurationEventTimeZone: InputConfiguration = {};
  inputConfigurationEventStartAt: InputConfiguration = {};
  inputConfigurationEventEndAt: InputConfiguration = {};
  inputConfigurationLinkTitle: InputConfiguration = {};
  inputConfigurationLinkDescription: InputConfiguration = {};
  inputConfigurationCountry: InputConfiguration = {};

  subscriptions: Subscription[] = [];

  get linkTitlePlaceholder(): string {
    return this.eventVersion?.local[this.currentLanguage]?.title || '';
  }

  get linkDescriptionPlaceholder(): string {
    return this.eventVersion?.local[this.currentLanguage]?.shortDescription || '';
  }

  constructor(
    private validatorService: ValidatorService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private listeningQueryParamService: ListeningQueryParamService,
    private i18nService: I18nService
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentLanguage = this.activatedRoute.snapshot.queryParams.language || 'en';

    this.inputConfigurationLinkTitle = {
      onFocus: () => {
        const currentLocal = this.eventVersion.local[this.currentLanguage];
        if (currentLocal && !currentLocal.linkTitle) {
          currentLocal.linkTitle = currentLocal.title;
        }
      },
    };

    this.inputConfigurationLinkDescription = {
      onFocus: () => {
        const currentLocal = this.eventVersion.local[this.currentLanguage];
        if (currentLocal && !currentLocal.linkDescription) {
          currentLocal.linkDescription = currentLocal.shortDescription;
        }
      },
    };

    this.inputConfigurationEventTimeZone = {
      header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_TIMEZONE',
      description: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_TIMEZONE_DESCRIPTION',
      type: 'dropdown',
      dropdownOptions: [{ label: 'None', value: null }].concat(
        tz.timeZoneNames.map((tzName) => ({
          label: tzName,
          value: tzName,
        }))
      ),
      onChange: (newValue) => {
        this.router.navigate([], {
          queryParamsHandling: 'merge',
          queryParams: {
            eTz: newValue,
          },
        });
      },
    };

    this.inputConfigurationCountry = {
      header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_COUNTRY',
      dropdownOptions: this.i18nService.countryList().map(({ code, name }) => ({ label: name, value: code })),
    };

    this.subscriptions.push(
      this.listeningQueryParamService
        .listeningToQueryParam(
          'eTz',
          'GENERAL_EVENT_CURRENT_TIMEZONE',
          (urlvalue) => ({
            eTz: urlvalue,
            isTimeZoneDifferentToLocal: moment.tz.guess() !== urlvalue ? 'timeZoneDifferentToLocal' : '',
          }),
          this.activatedRoute
        )
        .subscribe((desc) => {
          this.descriptionStartAt = this.descriptionEndAt = desc;

          this.inputConfigurationEventStartAt = {
            header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_STARTAT',
            description: this.descriptionStartAt,
            type: 'datetime',
          };

          this.inputConfigurationEventEndAt = {
            header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_ENDAT',
            description: this.descriptionEndAt,
            type: 'datetime',
          };
        })
    );

    this.inputConfigurationEventStartAt = {
      header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_STARTAT',
      description: this.descriptionStartAt,
      type: 'datetime',
    };

    this.inputConfigurationEventEndAt = {
      header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_ENDAT',
      description: this.descriptionEndAt,
      type: 'datetime',
    };
  }

  eventErrorAt(path: string): string | null {
    return this.validatorService.errorAt(eventValidator, this.event, path);
  }

  imagesErrorAt(path: string): string | null {
    return this.validatorService.errorAt(eventImagesValidator, this.eventVersion.images, path);
  }

  languageErrorAt(language: string, path: string): string | null {
    return this.validatorService.errorAt(eventLocalValidator, this.eventVersion.local[language], path);
  }

  async languageAdded(language): Promise<any> {
    this.event.currentEventVersion.local[language] = await AbstractFactory.get(EventVersionFactory).createEventVersionLanguage();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sc) => sc.unsubscribe());
  }
}
