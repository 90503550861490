import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AssetsService } from 'src/app/services/assets/assets.service';
import { ImageAsset } from 'src/common/entities/Asset';
import { Marker, MarkerIcon } from 'src/common/entities/Map';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'c-image-markers-map',
  templateUrl: './image-markers-map.component.html',
  styleUrls: ['./image-markers-map.component.scss'],
})
export class ImageMarkersMapComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('container', { read: ElementRef })
  container: ElementRef;

  @Input()
  background: string;

  @Input()
  active: boolean;

  @Input()
  markers: Marker[] = [];

  @Input()
  markerIcons: MarkerIcon[] = [];

  @Input()
  selectedMarker: Marker | null = null;

  @Output()
  clickedMarkerChange: EventEmitter<Marker> = new EventEmitter<Marker>();

  @Output()
  selectedMarkerChange: EventEmitter<Marker> = new EventEmitter<Marker>();

  @Input()
  hoveredMarker: Marker | null = null;

  @Output()
  hoveredMarkerChange: EventEmitter<Marker> = new EventEmitter<Marker>();

  @Output()
  markerMoved: EventEmitter<Marker> = new EventEmitter<Marker>();

  @Input()
  width: number;

  @Input()
  height: number;

  get backgroundUrl(): string {
    if (this.backgroundAsset && this.backgroundAsset.paths) {
      if (this.backgroundAsset.mimeType === 'image/svg+xml') {
        return `url("${this.backgroundAsset.paths.dataUri}")`;
      } else {
        return `url(${this.environment.cdn.images}/${this.backgroundAsset.paths.thumbnail})`;
      }
    }

    return 'none';
  }

  get backgroundStyle() {
    return {
      'background-image': this.backgroundUrl,
      width: this.backgroundSize?.width + 'px',
      height: this.backgroundSize?.height + 'px',
    };
  }

  backgroundAsset: ImageAsset;
  markerIconAssets: { [assetId: string]: ImageAsset } = {};

  backgroundSize: {
    width: number;
    height: number;
  };

  markerIconSizes: {
    [id: string]: {
      width: number;
      height: number;
    };
  } = {
    '': {
      width: 10,
      height: 10,
    },
  };

  moveMarker: {
    marker: Marker;
    offsetLeft: number;
    offsetTop: number;
  };

  subscriptions: Subscription[] = [];

  constructor(private environment: Environment, private assetsService: AssetsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.subscriptions.push(
      this.assetsService.getAsset(this.background).subscribe((asset) => {
        this.backgroundAsset = asset as ImageAsset;
        if (this.height && this.width) {
          this.backgroundSize = { width: this.width, height: this.height };
          return;
        }
        this.backgroundSize = this.imageSize(this.backgroundAsset);
      })
    );

    for (const markerIcon of this.markerIcons) {
      if (markerIcon.icon) {
        this.subscriptions.push(
          this.assetsService.getAsset(markerIcon.icon).subscribe((asset) => {
            this.markerIconAssets[markerIcon.icon] = asset as ImageAsset;
            this.markerIconSizes[markerIcon._id] = this.imageSize(asset as ImageAsset);
          })
        );
      }
      if (markerIcon.iconHover) {
        this.subscriptions.push(
          this.assetsService.getAsset(markerIcon.iconHover).subscribe((asset) => {
            this.markerIconAssets[markerIcon.iconHover] = asset as ImageAsset;
            this.markerIconSizes[markerIcon._id] = this.imageSize(asset as ImageAsset);
          })
        );
      }
      if (markerIcon.iconSelected) {
        this.subscriptions.push(
          this.assetsService.getAsset(markerIcon.iconSelected).subscribe((asset) => {
            this.markerIconAssets[markerIcon.iconSelected] = asset as ImageAsset;
            this.markerIconSizes[markerIcon._id] = this.imageSize(asset as ImageAsset);
          })
        );
      }
    }
  }

  async ngOnInit() {
    this.subscriptions.push(
      this.assetsService.getAsset(this.background).subscribe((asset) => {
        this.backgroundAsset = asset as ImageAsset;
        if (this.height && this.width) {
          this.backgroundSize = { width: this.width, height: this.height };
          return;
        }
        this.backgroundSize = this.imageSize(this.backgroundAsset);
      })
    );

    for (const markerIcon of this.markerIcons) {
      if (markerIcon.icon) {
        this.subscriptions.push(
          this.assetsService.getAsset(markerIcon.icon).subscribe((asset) => {
            this.markerIconAssets[markerIcon.icon] = asset as ImageAsset;
            this.markerIconSizes[markerIcon._id] = this.imageSize(asset as ImageAsset);
          })
        );
      }
      if (markerIcon.iconHover) {
        this.subscriptions.push(
          this.assetsService.getAsset(markerIcon.iconHover).subscribe((asset) => {
            this.markerIconAssets[markerIcon.iconHover] = asset as ImageAsset;
            this.markerIconSizes[markerIcon._id] = this.imageSize(asset as ImageAsset);
          })
        );
      }
      if (markerIcon.iconSelected) {
        this.subscriptions.push(
          this.assetsService.getAsset(markerIcon.iconSelected).subscribe((asset) => {
            this.markerIconAssets[markerIcon.iconSelected] = asset as ImageAsset;
            this.markerIconSizes[markerIcon._id] = this.imageSize(asset as ImageAsset);
          })
        );
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  imageSize(image: ImageAsset): { width: number; height: number } {
    if (image.mimeType === 'image/svg+xml') {
      let svg = image.paths.dataUri.replace(/data:image\/svg\+xml(;base64)?,/, '');

      svg = /data:image\/svg\+xml;base64,/.test(image.paths.dataUri) ? atob(svg) : svg;

      if (svg.startsWith('%3c')) {
        svg = decodeURIComponent(svg);
      }

      const div = document.createElement('div');

      div.setAttribute('style', 'visibility: hidden; position: fixed;');
      div.innerHTML = svg;

      document.querySelector('body').append(div);

      const bbox = div.querySelector('svg').getBBox();

      div.remove();

      return {
        width: bbox.width,
        height: bbox.height,
      };
    } else {
      return {
        width: image.width,
        height: image.height,
      };
    }
  }

  markerStyle(marker: Marker) {
    const markerIcon = this.markerIcons.find((i) => i._id === marker.markerIcon);
    const markerSize = this.markerIconSizes[marker.markerIcon] || this.markerIconSizes[''];

    const translateX = markerSize.width * (typeof markerIcon?.centerX === 'number' ? markerIcon.centerX : 0.5);
    const translateY = markerSize.height * (typeof markerIcon?.centerY === 'number' ? markerIcon.centerY : 0.5);

    return {
      top: marker.y * 100 + '%',
      left: marker.x * 100 + '%',
      transform: `translate(-${translateX}px, -${translateY}px)`,
    };
  }

  markerDown(event: any, marker: Marker) {
    if (this.active) {
      const offsetLeft = event.target?.offsetLeft || event.srcElement?.offsetLeft || (event.currentTarget?.offsetLeft as any);
      const offsetTop = event.target?.offsetTop || event.srcElement?.offsetTop || (event.currentTarget?.offsetTop as any);

      this.moveMarker = {
        marker: marker,
        offsetLeft: offsetLeft - event.clientX,
        offsetTop: offsetTop - event.clientY,
      };

      this.container.nativeElement.addEventListener('mousemove', this.markerMove);
    }
  }

  markerMove = (event: MouseEvent) => {
    if (this.moveMarker) {
      let newX = (event.clientX + this.moveMarker.offsetLeft) / this.backgroundSize.width;
      let newY = (event.clientY + this.moveMarker.offsetTop) / this.backgroundSize.height;

      if (newX < 0) newX = 0;
      if (newY < 0) newY = 0;

      if (newX > 1) newX = 1;
      if (newY > 1) newY = 1;

      this.moveMarker.marker.x = newX;
      this.moveMarker.marker.y = newY;
    }
  };

  @HostListener('document:mouseup')
  markerUp() {
    if (this.moveMarker) {
      this.markerMoved.emit(this.moveMarker.marker);
      this.moveMarker = null;
    }

    this.container.nativeElement.removeEventListener('mousemove', this.markerMove);
  }

  markerEnter(event: any, marker: Marker) {
    this.hoveredMarker = marker;
    this.hoveredMarkerChange.emit(this.hoveredMarker);
  }

  markerLeave(event: any, marker: Marker) {
    if (this.hoveredMarker?._id === marker._id) {
      this.hoveredMarker = null;
      this.hoveredMarkerChange.emit(this.hoveredMarker);
    }
  }

  markerIcon(marker: Marker): MarkerIcon | null {
    return this.markerIcons.find((i) => i._id === marker.markerIcon);
  }

  markerIconUrl(marker: Marker, markerIcon: MarkerIcon): string {
    const icon = this.selectedMarker?._id === marker._id ? markerIcon.iconSelected : (this.hoveredMarker?._id === marker._id ? markerIcon.iconHover : markerIcon.icon) || markerIcon.icon;

    if (this.markerIconAssets[icon] && this.markerIconAssets[icon].paths) {
      if (this.markerIconAssets[icon].mimeType === 'image/svg+xml') {
        return `url("${this.markerIconAssets[icon].paths.dataUri}")`;
      } else {
        return `url(${this.environment.cdn.images}/${this.markerIconAssets[icon].paths.thumbnail})`;
      }
    }

    return 'none';
  }

  markerIconStyle(marker: Marker, markerIcon: MarkerIcon) {
    return {
      'background-image': this.markerIconUrl(marker, markerIcon),
      width: this.markerIconSizes[markerIcon._id]?.width + 'px',
      height: this.markerIconSizes[markerIcon._id]?.height + 'px',
    };
  }

  trackByMarker(index: number, marker: Marker) {
    return marker._id;
  }

  selectMarker(marker: Marker) {
    this.selectedMarker = marker;
    this.selectedMarkerChange.emit(this.selectedMarker);
  }

  clickMarker(marker: Marker) {
    this.clickedMarkerChange.emit(marker);
  }
}
