import { DirectivesModule } from './../../directives/directives.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportsComponent } from './exports.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { ComponentsModule } from 'src/app/components/components.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { DividerModule } from 'primeng/divider';

const routes: Routes = [{ path: '', component: ExportsComponent }];

@NgModule({
  declarations: [ExportsComponent],
  imports: [CommonModule, RouterModule.forChild(routes), TranslateModule, TableModule, CardModule, ComponentsModule, PipesModule, DividerModule, DirectivesModule],
  exports: [RouterModule],
})
export class ExportsModule {}
