import { Injectable } from '@angular/core';
import { GetAppointmentQuery, GetAppointmentResponse } from 'src/common/api/v1/appointments/GetAppointment';
import { GetAppointmentsQuery, GetAppointmentsResponse } from 'src/common/api/v1/appointments/GetAppointments';
import { PostAppointmentBody, PostAppointmentQuery, PostAppointmentResponse } from 'src/common/api/v1/appointments/PostAppointment';
import { PostAppointmentAssignBody, PostAppointmentAssignQuery, PostAppointmentAssignResponse } from 'src/common/api/v1/appointments/PostAppointmentAssign';
import { PostAppointmentDeleteBody, PostAppointmentDeleteQuery, PostAppointmentDeleteResponse } from 'src/common/api/v1/appointments/PostAppointmentDelete';
import { PostAppointmentsBody, PostAppointmentsQuery, PostAppointmentsResponse } from 'src/common/api/v1/appointments/PostAppointments';
import { PostAppointmentSendInvitationsBody, PostAppointmentSendInvitationsQuery, PostAppointmentSendInvitationsResponse } from 'src/common/api/v1/appointments/PostAppointmentSendInvitations';
import {
  PostAppointmentShowDigitalShowroomBody,
  PostAppointmentShowDigitalShowroomQuery,
  PostAppointmentShowDigitalShowroomResponse,
} from 'src/common/api/v1/appointments/PostAppointmentShowDigitalShowroom';
import { PostAppointmentStopBody, PostAppointmentStopQuery, PostAppointmentStopResponse } from 'src/common/api/v1/appointments/PostAppointmentStop';
import { PostAppointmentStartBody, PostAppointmentStartQuery, PostAppointmentStartResponse } from 'src/common/api/v1/appointments/PostAppointmentStart';
import { Appointment } from 'src/common/entities/Appointment';
import { ApiService } from '../api/api.service';
import { CacheService } from '../cache/cache.service';
import { CacheContainer } from '../cache/cache-container';
import { Observable } from 'rxjs';
import { PostAppointmentRejectBody, PostAppointmentRejectQuery, PostAppointmentRejectResponse } from 'src/common/api/v1/appointments/PostAppointmentReject';
import { IFilterableListQuery } from 'src/common/api/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {
  private _cache: CacheContainer<Appointment>;

  constructor(private apiService: ApiService, private cacheService: CacheService) {
    this._cache = this.cacheService.create<Appointment>({
      get: (_id: string) => this.apiService.get<GetAppointmentQuery, GetAppointmentResponse>(`/api/v1/appointments/${_id}`).toPromise(),
      socketEvents: ['appointment:update'],
      groups: [
        {
          name: 'open',
          filter: (appointment: Appointment) => ['Inquired', 'Confirmed'].includes(appointment.status) && !appointment.deletedAt,
          fill: async () =>
            (
              await this.getAppointments({
                filter: {
                  status: {
                    matchMode: 'in',
                    value: ['Inquired', 'Confirmed'],
                  },
                },
                orderBy: 'dateFrom',
                orderDirection: 1,
                skip: 0,
                limit: 99999,
              })
            ).items,
          query: async (query: IFilterableListQuery, currentValues: Appointment[]): Promise<Appointment[]> => {
            return currentValues
              .filter((a) => {
                let result = true;

                if (query.filter) {
                  if (query.filter['participants.host']) result = result && a.participants?.host === query.filter['participants.host'].value;
                  if (query.filter['participants.users']) result = result && a.participants?.users.includes(query.filter['participants.users'].value.toString());
                  if (query.filter['origin']) result = result && a.origin === query.filter['origin'].value;

                  if (query.filter['organizer']) {
                    if (query.filter['organizer'].value === null) {
                      result = result && ((!!a.organizer && query.filter['organizer'].matchMode === 'notEquals') || (!a.organizer && query.filter['organizer'].matchMode === 'equals'));
                    } else {
                      result = result && a.organizer === query.filter['organizer'].value;
                    }
                  }
                }

                return result;
              })
              .sort((a, b) => {
                if (query.orderBy === 'createdAt' && query.orderDirection === 1) {
                  return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
                } else if (query.orderBy === 'createdAt' && query.orderDirection === -1) {
                  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                } else if (query.orderBy === 'dateFrom' && query.orderDirection === 1) {
                  return !a.dateFrom ? 1 : !b.dateFrom ? -1 : new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime();
                } else {
                  return new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime();
                }
              });
          },
        },
        {
          name: 'closed',
          filter: (appointment: Appointment) => ['Done', 'Rejected'].includes(appointment.status) && !appointment.deletedAt,
          // fill: async () => (await this.getAppointments({ filter: {
          //   status: {
          //     matchMode: "in",
          //     value: ['Done', 'Rejected']
          //   }
          // }, orderBy: "dateFrom", orderDirection: 1, skip: 0, limit: 99999 })).items
        },
      ],
    });
  }

  async getAppointment(appointmentId: string): Promise<Appointment> {
    return this.convertDates(await this._cache.get<GetAppointmentQuery, GetAppointmentResponse>(`/api/v1/appointments/${appointmentId}`));
  }

  getAppointments(query?: GetAppointmentsQuery): Promise<GetAppointmentsResponse> {
    return this.apiService.get<GetAppointmentsQuery, GetAppointmentsResponse>('/api/v1/appointments', query).toPromise();
  }

  appointmentsByGroup(group: string, query?: IFilterableListQuery): Observable<Appointment[]> {
    return this._cache.group(group, query);
  }

  closedAppointments(query: IFilterableListQuery): Promise<GetAppointmentsResponse> {
    this._cache.clearGroup('closed');
    return this.getAppointments({
      ...query,
      filter: {
        ...query.filter,
        status: {
          matchMode: 'in',
          value: ['Done', 'Rejected'],
        },
      },
    });
  }

  async updateAppointment(appointment: Appointment): Promise<Appointment> {
    return this.convertDates(await this._cache.post<PostAppointmentQuery, PostAppointmentBody, PostAppointmentResponse>(`/api/v1/appointments/${appointment._id}`, appointment));
  }

  async createAppointment(appointment: Appointment): Promise<Appointment> {
    return this.convertDates(await this._cache.post<PostAppointmentsQuery, PostAppointmentsBody, PostAppointmentsResponse>('/api/v1/appointments', appointment));
  }

  async deleteAppointment(appointment: Appointment): Promise<Appointment> {
    return this.convertDates(
      await this._cache.post<PostAppointmentDeleteQuery, PostAppointmentDeleteBody, PostAppointmentDeleteResponse>(`/api/v1/appointments/${appointment._id}/delete`, appointment)
    );
  }

  async showDigitalShowroom(appointment: Appointment): Promise<Appointment> {
    return this.convertDates(
      await this._cache.post<PostAppointmentShowDigitalShowroomQuery, PostAppointmentShowDigitalShowroomBody, PostAppointmentShowDigitalShowroomResponse>(
        `/api/v1/appointments/${appointment._id}/showdigitalshowroom`,
        null
      )
    );
  }

  async startAppointment(appointment: Appointment): Promise<Appointment> {
    return this.convertDates(await this._cache.post<PostAppointmentStartQuery, PostAppointmentStartBody, PostAppointmentStartResponse>(`/api/v1/appointments/${appointment._id}/start`, null));
  }

  async stopAppointment(appointment: Appointment): Promise<Appointment> {
    return this.convertDates(await this._cache.post<PostAppointmentStopQuery, PostAppointmentStopBody, PostAppointmentStopResponse>(`/api/v1/appointments/${appointment._id}/stop`, null));
  }

  async rejectAppointment(appointment: Appointment): Promise<Appointment> {
    return this.convertDates(await this._cache.post<PostAppointmentRejectQuery, PostAppointmentRejectBody, PostAppointmentRejectResponse>(`/api/v1/appointments/${appointment._id}/reject`, null));
  }

  async assignAppointment(appointment: Appointment, newAdminUser: string): Promise<Appointment> {
    return await this._cache.post<PostAppointmentAssignQuery, PostAppointmentAssignBody, PostAppointmentAssignResponse>(`/api/v1/appointments/${appointment._id}/assign`, {
      currentAdminUser: appointment.organizer,
      newAdminUser: newAdminUser,
    });
  }

  async sendInvitations(appointment: Appointment): Promise<Appointment> {
    return this.convertDates(
      await this._cache.post<PostAppointmentSendInvitationsQuery, PostAppointmentSendInvitationsBody, PostAppointmentSendInvitationsResponse>(
        `/api/v1/appointments/${appointment._id}/sendinvitations`,
        appointment
      )
    );
  }

  convertDates(appointment: Appointment) {
    appointment.dateFrom = appointment.dateFrom ? new Date(appointment.dateFrom) : null;
    appointment.dateTo = appointment.dateTo ? new Date(appointment.dateTo) : null;

    appointment.alternative1DateFrom = appointment.alternative1DateFrom ? new Date(appointment.alternative1DateFrom) : null;
    appointment.alternative1DateTo = appointment.alternative1DateTo ? new Date(appointment.alternative1DateTo) : null;

    appointment.alternative2DateFrom = appointment.alternative2DateFrom ? new Date(appointment.alternative2DateFrom) : null;
    appointment.alternative2DateTo = appointment.alternative2DateTo ? new Date(appointment.alternative2DateTo) : null;

    return appointment;
  }
}
