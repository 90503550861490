import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';

import { ComponentsModule } from 'src/app/components/components.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

import { SpeakersComponent } from './speakers.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';

const routes: Routes = [{ path: '', component: SpeakersComponent }];

@NgModule({
  declarations: [SpeakersComponent],
  imports: [CommonModule, RouterModule.forChild(routes), TranslateModule, DirectivesModule, ButtonModule, TableModule, CardModule, ComponentsModule, PipesModule, PaginatorModule],
  exports: [RouterModule],
})
export class SpeakersModule {}
