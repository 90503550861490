import { TextAttributes } from './../Attributes';
import { BreakpointValue } from './../Breakpoint';
import { CTAButton } from '../CTAButton';
import { PageModule } from './PageModule';

export interface Stage2MultiEventStagePageModule extends PageModule {
  pageModuleType: 'MultiEventStage2';
  /** @deprecated Use `local..events` */
  events?: string[] | null;
  local: {
    [language: string]: {
      preHeadline?: string;
      preHeadline_attr?: Stage2MultiEventStagePageModuleAttributes;
      title: string;
      title_attr?: Stage2MultiEventStagePageModuleAttributes;
      subtitle: string;
      subtitle_attr?: Stage2MultiEventStagePageModuleAttributes;
      subtext: string;
      events?: string[];
      background: string;
    };
  };
  ctalist?: Stage2MultiEventStageButton[];
  ctalistInteraction?: Stage2MultiEventStageButton[];
  discoverMore?: boolean;
  ctaAreaHeight?: BreakpointValue<number>;
  contentAreaHeight?: BreakpointValue<number>;
  topAreaHeight?: BreakpointValue<number>;
}

export type Stage2MultiEventStagePageModuleAttributes = TextAttributes & {};

export interface Stage2MultiEventStageButton {
  _id: string;
  ctaButton: CTAButton;
}

export function isMultiEventStage2PageModule(object: any): object is Stage2MultiEventStagePageModule {
  return object?.pageModuleType === 'MultiEventStage2';
}
