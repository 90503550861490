<div *ngIf="currentUser">
  <div *ngIf="userType == 'USER' && asUser(currentUser)">
    <a [routerLink]="['/users/' + currentUser._id]">{{ currentUser.displayName }}</a>
  </div>
  <div *ngIf="!asUser(currentUser)">{{ currentUser.displayName }}</div>

  <div *ngIf="currentUser | fn : asUser as user">
    <div *ngIf="user.email">
      <small>{{ 'PAGE_USER_EMAIL' | translate }}: {{ user.email }}</small>
    </div>
    <div *ngIf="user.organization">
      <small>{{ 'PAGE_USER_ORGANIZATION' | translate }}: {{ user.organization }}</small>
    </div>
    <div *ngIf="user.salesManager">
      <small>{{ 'PAGE_USER_SALESMANAGER' | translate }}: {{ (user.salesManager | adminUser | async)?.displayName }}</small>
    </div>
  </div>

  <div *ngIf="currentUser | fn : asArtificialUser as artificialUser">
    <div *ngIf="!artificialUser.expert">
      <small>{{ 'GENERAL_FAKE_USER' | translate }}</small>
    </div>
    <div *ngIf="artificialUser.expert">
      <small>{{ 'GENERAL_FAKE_EXPERT_USER' | translate }}</small>
    </div>
  </div>
</div>
