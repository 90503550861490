import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { SpeakersService } from 'src/app/services/speakers/speakers.service';
import { Speaker } from 'src/common/entities/Speaker';

@Pipe({
  name: 'speaker',
})
export class SpeakerPipe implements PipeTransform {
  constructor(private speakersService: SpeakersService) {}

  transform(speaker: string, ...args: unknown[]): Observable<Speaker> {
    return this.speakersService.asObservable(speaker);
  }
}
