import * as yup from 'yup';

export const speakerLocalValidator = yup.object({
  title: yup.string(),
  shortDescription: yup.string(),
  longDescription: yup.string(),
});

export const speakerContentValidator = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  company: yup.string().nullable(),
  picture: yup.string().nullable(),

  skills: yup.array().of(yup.string()).nullable(),
  mail: yup.string().nullable(),
  externalProfileLink: yup.string().nullable(),
  linkedIn: yup.string().nullable(),
  xing: yup.string().nullable(),
  twitter: yup.string().nullable(),

  local: yup.lazy((locals) => {
    return yup.object(Object.keys(locals || {}).reduce((a, b) => ({ ...a, [b]: speakerLocalValidator }), {}));
  }),
});

export const speakerValidator = yup.object({}).concat(speakerContentValidator);
