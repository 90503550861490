import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';
import { EmailTemplateConfiguration } from 'src/common/entities/EmailTemplateConfiguration';
import { EmailTemplateConfigurationsService } from 'src/app/services/email-template-configurations/email-template-configurations.service';
import { InputConfiguration } from './../../../../common/inputs/Inputs';

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { Event } from 'src/common/entities/Event';
import { EventTicket, EventTicketCode } from 'src/common/entities/EventTicket';
import { EventVersion } from 'src/common/entities/EventVersion';

import { EventsService } from 'src/app/services/events/events.service';
import { Clipboard } from '@angular/cdk/clipboard';

import { Factory } from 'src/common/factories/Factory';
import { TableOptions } from 'src/app/components/table/table.interfaces';
import { TableComponent } from 'src/app/components/table/table.component';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { EmailTemplatesService } from 'src/app/services/email-templates/email-templates.service';

@Component({
  selector: 'c-event-tickets',
  templateUrl: './event-tickets.component.html',
  styleUrls: ['./event-tickets.component.scss'],
})
export class EventTicketsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(TableComponent) table: TableComponent<EventTicket>;

  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  currentTicket: EventTicket = null;
  currentLanguage: string = null;

  subscriptions: Subscription[] = [];

  ticketsTableOptions: TableOptions<EventTicket>;
  ticketEditTab = 'general';
  inputConfigurationEventTicketReadAccess: InputConfiguration = {};
  inputConfigurationEmailTemplate: InputConfiguration = {};
  inputConfigurationEmailTemplateParts: InputConfiguration[] = [];

  inputChangedConfigurationEmailTemplate: InputConfiguration = {};
  inputChangedConfigurationEmailTemplateParts: InputConfiguration[] = [];

  inputConfigurationWarning: InputConfiguration = {
    header: 'Warning Message',
    description: 'If configured, this message will be displayed at the top of the ticket dialog',
    type: 'textarea',
  };

  inputConfigurationWarningCTA: InputConfiguration = {
    header: 'Warning Message CTA',
    description: 'If configured, the warning message is clickable',
    type: 'ctabutton',
  };

  inputConfigurationUpgrade: InputConfiguration;

  ticketCodesInputConfiguration: InputConfiguration = {
    childLabel: (item: EventTicketCode, index: number) => item?.code as string,
  };

  get showTicket(): boolean {
    return !!this.currentTicket;
  }

  set showTicket(val: boolean) {
    if (!val) this.currentTicket = null;
  }

  showEditEmailTemplate: boolean = false;
  showEmailTemplateButton: boolean = false;
  emailTemplateConfiguration: EmailTemplateConfiguration;

  showChangedEditEmailTemplate: boolean = false;
  showChangedEmailTemplateButton: boolean = false;
  emailChangedTemplateConfiguration: EmailTemplateConfiguration;

  public onCompare = (a, b) => a.key;

  constructor(
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private eventsService: EventsService,
    private emailTemplatesService: EmailTemplatesService,
    public clipboard: Clipboard,
    private emailTemplatesConfigurationsService: EmailTemplateConfigurationsService,
    private collaborationService: CollaborationService
  ) {
    this.ticketsTableOptions = {
      columns: [{ header: 'Internal Name' }, { header: 'GENERAL_MOVE' }],
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.eventVersion && changes.eventVersion.currentValue !== changes.eventVersion.previousValue) {
      this.eventVersion.eventTickets = this.eventVersion.eventTickets.filter((ticketList) => ticketList.deletedAt === null || !ticketList.deletedAt);
    }
  }

  async ngOnInit(): Promise<void> {
    // Listen to activatedRoute queryParams language
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((queryParams) => {
        if (queryParams.language && queryParams.language !== this.currentLanguage) {
          this.currentLanguage = queryParams.language;
        }
      })
    );

    // Listen to eventversion patches
    this.subscriptions.push(
      this.eventsService.lastEventVersionPatch(this.eventVersion._id).subscribe((patch) => {
        if (this.utilsService.startsWithJsonpath(patch.patch.jsonpath, '$.eventTickets', patch.patch.jsonpathParams)) {
          this.table.refresh();
        }

        if (this.currentTicket) {
          if (!this.eventVersion.eventTickets.includes(this.currentTicket)) {
            this.currentTicket = null;
          }
        }
      })
    );

    this.inputConfigurationEventTicketReadAccess = {
      type: 'accesspolicy',
      allowedAccessPolicyTypes: ['Public', 'VIPTicket'],
      eventVersion: this.eventVersion,
    };

    this.inputConfigurationEmailTemplate = {
      type: 'dropdown',
      dropdownOptions: [{ value: null, label: '-' }].concat(
        (await this.emailTemplatesService.getEmailTemplates()).items
          .filter((item) => item.emailTemplateType === 'eventregistration')
          .map((e) => ({
            label: e.internalName,
            value: e._id,
          }))
      ),
      onChange: (newValue) => {
        newValue ? (this.showEmailTemplateButton = true) : (this.showEmailTemplateButton = false);
      },
    };

    this.inputChangedConfigurationEmailTemplate = {
      type: 'dropdown',
      dropdownOptions: [{ value: null, label: '-' }].concat(
        (await this.emailTemplatesService.getEmailTemplates()).items
          .filter((item) => item.emailTemplateType === 'changedeventregistration')
          .map((e) => ({
            label: e.internalName,
            value: e._id,
          }))
      ),
      onChange: (newValue) => {
        newValue ? (this.showChangedEmailTemplateButton = true) : (this.showChangedEmailTemplateButton = false);
      },
    };
    this.eventVersion.eventTicketConfiguration.emailTemplate ? (this.showEmailTemplateButton = true) : (this.showEmailTemplateButton = false);
    this.eventVersion.eventTicketConfiguration.emailChangedTemplate ? (this.showChangedEmailTemplateButton = true) : (this.showChangedEmailTemplateButton = false);
  }

  selectTicket(ticket: EventTicket) {
    this.inputConfigurationUpgrade = {
      header: 'Is Upgrade Of',
      description: 'Provide a ticket that is upgraded by this current ticket.',
      type: 'dropdown',
      dropdownOptions: [{ value: null, label: '-' }].concat(
        this.eventVersion.eventTickets
          .filter((t) => t._id !== ticket._id)
          .map((t) => ({
            value: t._id,
            label: t.internalName,
          }))
      ),
    };
    this.currentTicket = ticket;
  }

  async editChangedCustomEmailTemplate() {
    this.inputChangedConfigurationEmailTemplateParts = [];
    const eMailTemplate = await this.emailTemplatesService.getEmailTemplate(this.eventVersion.eventTicketConfiguration.emailChangedTemplate);
    this.emailChangedTemplateConfiguration = await this.emailTemplatesConfigurationsService.getEmailTemplateConfiguration(eMailTemplate.emailTemplateConfigurationId);
    if (!this.eventVersion.eventTicketConfiguration.local[this.currentLanguage].customChangedEmailTemplateParts) {
      await this.collaborationService.patch(this.collaborationService.collaborationKey(this.eventVersion), this.eventVersion, {
        jsonpath: '$.eventTicketConfiguration.local.$language.customChangedEmailTemplateParts',
        jsonpathParams: { language: this.currentLanguage },
        value: {},
        command: 'set',
      });
    }

    Object.keys(this.emailChangedTemplateConfiguration.parts).forEach(async (item, index) => {
      this.inputChangedConfigurationEmailTemplateParts.push({
        type: this.emailChangedTemplateConfiguration.parts[item] === 'asset' ? 'imageasset' : this.emailChangedTemplateConfiguration.parts[item] === 'string' ? 'textarea' : 'html',
        header: item,
      });
      const valuePatch = this.eventVersion.eventTicketConfiguration.local[this.currentLanguage].customChangedEmailTemplateParts[item]
        ? this.eventVersion.eventTicketConfiguration.local[this.currentLanguage].customChangedEmailTemplateParts[item]
        : eMailTemplate.local[this.currentLanguage].parts[item]
        ? eMailTemplate.local[this.currentLanguage].parts[item]
        : '';
      await this.collaborationService.patch(this.collaborationService.collaborationKey(this.eventVersion), this.eventVersion, {
        jsonpath: '$.eventTicketConfiguration.local.$language.customChangedEmailTemplateParts.$parts',
        jsonpathParams: { parts: item, language: this.currentLanguage },
        value: valuePatch,
        command: 'set',
      });
    });

    this.showChangedEditEmailTemplate = true;
  }

  async editCustomEmailTemplate() {
    this.inputConfigurationEmailTemplateParts = [];
    const eMailTemplate = await this.emailTemplatesService.getEmailTemplate(this.eventVersion.eventTicketConfiguration.emailTemplate);
    this.emailTemplateConfiguration = await this.emailTemplatesConfigurationsService.getEmailTemplateConfiguration(eMailTemplate.emailTemplateConfigurationId);
    if (!this.eventVersion.eventTicketConfiguration.local[this.currentLanguage].customEmailTemplateParts) {
      await this.collaborationService.patch(this.collaborationService.collaborationKey(this.eventVersion), this.eventVersion, {
        jsonpath: '$.eventTicketConfiguration.local.$language.customEmailTemplateParts',
        jsonpathParams: { language: this.currentLanguage },
        value: {},
        command: 'set',
      });
    }

    Object.keys(this.emailTemplateConfiguration.parts).forEach(async (item, index) => {
      this.inputConfigurationEmailTemplateParts.push({
        type: this.emailTemplateConfiguration.parts[item] === 'asset' ? 'imageasset' : this.emailTemplateConfiguration.parts[item] === 'string' ? 'textarea' : 'html',
        header: item,
      });
      const valuePatch = this.eventVersion.eventTicketConfiguration.local[this.currentLanguage].customEmailTemplateParts[item]
        ? this.eventVersion.eventTicketConfiguration.local[this.currentLanguage].customEmailTemplateParts[item]
        : eMailTemplate.local[this.currentLanguage].parts[item]
        ? eMailTemplate.local[this.currentLanguage].parts[item]
        : '';
      await this.collaborationService.patch(this.collaborationService.collaborationKey(this.eventVersion), this.eventVersion, {
        jsonpath: '$.eventTicketConfiguration.local.$language.customEmailTemplateParts.$parts',
        jsonpathParams: { parts: item, language: this.currentLanguage },
        value: valuePatch,
        command: 'set',
      });
    });

    this.showEditEmailTemplate = true;
  }

  async deleteCustomEmailTemplate() {
    await this.collaborationService.patch(this.collaborationService.collaborationKey(this.eventVersion), this.eventVersion, {
      jsonpath: '$.eventTicketConfiguration.local.$language.customEmailTemplateParts',
      jsonpathParams: { language: this.currentLanguage },
      command: 'delete',
    });
    this.showEmailTemplateButton = false;
  }

  async deleteChangedCustomEmailTemplate() {
    await this.collaborationService.patch(this.collaborationService.collaborationKey(this.eventVersion), this.eventVersion, {
      jsonpath: '$.eventTicketConfiguration.local.$language.customChangedEmailTemplateParts',
      jsonpathParams: { language: this.currentLanguage },
      command: 'delete',
    });
    this.showChangedEmailTemplateButton = false;
  }

  showCustomEmailMessage(): boolean {
    if (this.eventVersion?.eventTicketConfiguration?.local[this.currentLanguage]?.customEmailTemplateParts) {
      return Object.keys(this.eventVersion.eventTicketConfiguration.local[this.currentLanguage].customEmailTemplateParts).length > 0 ? true : false;
    }
    return false;
  }

  showCustomEmailTemplate(): boolean {
    let returnvalue = false;
    Object.keys(this.eventVersion?.eventTicketConfiguration?.local).forEach((language) => {
      if (this.eventVersion?.eventTicketConfiguration?.local[language]?.customEmailTemplateParts) {
        if (Object.keys(this.eventVersion.eventTicketConfiguration.local[language].customEmailTemplateParts).length > 0) {
          returnvalue = true;
          return true;
        }
      }
      return false;
    });

    return returnvalue;
  }

  showChangedEmailTemplate(): boolean {
    let returnvalue = false;
    Object.keys(this.eventVersion?.eventTicketConfiguration?.local).forEach((language) => {
      if (this.eventVersion?.eventTicketConfiguration?.local[language]?.customChangedEmailTemplateParts) {
        if (Object.keys(this.eventVersion.eventTicketConfiguration.local[language].customChangedEmailTemplateParts).length > 0) {
          returnvalue = true;
          return true;
        }
      }
      return false;
    });

    return returnvalue;
  }

  showChangedCustomEmailMessage(): boolean {
    if (this.eventVersion?.eventTicketConfiguration?.local[this.currentLanguage]?.customChangedEmailTemplateParts) {
      return Object.keys(this.eventVersion.eventTicketConfiguration.local[this.currentLanguage].customChangedEmailTemplateParts).length > 0 ? true : false;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async addTicket() {
    this.inputConfigurationUpgrade = {
      header: 'Is Upgrade Of',
      description: 'Provide a ticket that is upgraded by this current ticket.',
      type: 'dropdown',
      dropdownOptions: [{ value: null, label: '-' }].concat(
        this.eventVersion.eventTickets.map((t) => ({
          value: t._id,
          label: t.internalName,
        }))
      ),
    };
    try {
      const ticket = await Factory.eventTicket().createEventTicket({});
      await this.eventsService.patch(this.eventVersion, {
        command: 'push',
        jsonpath: `$.eventTickets`,
        value: ticket,
      });
    } catch (err) {
      console.error(err);
    }
    // Use new eventTicket for editing
    this.currentTicket = this.eventVersion.eventTickets[this.eventVersion.eventTickets.length - 1];
  }

  async onDeleteTicket(eventTicket: EventTicket) {
    await this.eventsService.patch(this.eventVersion, {
      command: 'set',
      jsonpath: `$.eventTickets[?(@._id=='$ticketId')].deletedAt`,
      jsonpathParams: {
        ticketId: eventTicket._id,
      },
      value: new Date().toISOString(),
    });
    this.eventVersion.eventTickets = this.eventVersion.eventTickets.filter((ticketList) => ticketList.deletedAt === null || !ticketList.deletedAt);
  }

  async onAddAdvantage() {
    try {
      const successful = await this.eventsService.patch(this.eventVersion, {
        command: 'push',
        jsonpath: `$.eventTickets[?(@._id=='$tickedId')].local.$language.advantages`,
        jsonpathParams: { tickedId: this.currentTicket._id, language: this.currentLanguage },
        value: '',
      });
      if (!successful) {
        return;
      }
    } catch (err) {
      return;
    }
  }

  async onDeleteAdvantage(atIndex: number) {
    try {
      const successful = await this.eventsService.patch(this.eventVersion, {
        command: 'delete',
        jsonpath: `$.eventTickets[?(@._id=='$tickedId')].local['$language'].advantages[$index]`,
        jsonpathParams: { tickedId: this.currentTicket._id, language: this.currentLanguage, index: atIndex },
      });
      if (!successful) {
        return;
      }
    } catch (err) {
      return;
    }
  }

  trackByEventTicket(index, eventTicket: EventTicket) {
    return eventTicket._id;
  }

  trackByIndex(index: number, item: any) {
    return index;
  }

  eventTicketUp(eventTicket: EventTicket) {
    this.eventsService.patch(this.eventVersion, {
      command: 'up',
      jsonpath: `$.eventTickets[?(@._id=='$ticketId')]`,
      jsonpathParams: {
        ticketId: eventTicket._id,
      },
    });
  }

  eventTicketDown(eventTicket: EventTicket) {
    this.eventsService.patch(this.eventVersion, {
      command: 'down',
      jsonpath: `$.eventTickets[?(@._id=='$ticketId')]`,
      jsonpathParams: {
        ticketId: eventTicket._id,
      },
    });
  }
}
