import { TextAttributes } from './../Attributes';
import { PageModule, TitlePositions } from './PageModule';
import { CTAButton } from '../CTAButton';

export const documentCollectionDisplayTypeValues = ['list', 'card', 'slider'];
export type DocumentCollectionDisplayType = (typeof documentCollectionDisplayTypeValues)[number];
export interface DocumentCollectionPageModule extends PageModule {
  pageModuleType: 'DocumentCollection';
  titlePosition?: TitlePositions;
  local: { [language: string]: DocumentCollectionPageModuleLocal };
  ctaButton?: CTAButton;
  displayAs: DocumentCollectionDisplayType;
  pagination: number;
}

export interface DocumentCollectionPageModuleLocal {
  title: string;
  title_attr?: DocumentCollectionPageModuleAttributes;
  assets: string[];
}

export type DocumentCollectionPageModuleAttributes = TextAttributes & {};

export function isDocumentCollectionPageModule(object: any): object is DocumentCollectionPageModule {
  return object?.pageModuleType === 'DocumentCollection';
}
