// import { EventStage, EventStageType } from './EventStage';
// import { createStreamEventStage } from './StreamEventStage';
// import { createTeaserEventStage } from './TeaserEventStage';
// import { createTextEventStage } from './TextEventStage';
// import { createTitleEventStage } from './TitleEventStage';

export * from './EventStage';
export * from './TextEventStage';
export * from './TitleEventStage';
export * from './StreamEventStage';
export * from './TeaserEventStage';

// export async function createEventStage(eventStage: {eventStageType: EventStageType}, newId: (previousId: string) => Promise<string>) {
//   let e: EventStage | null = null
//   if (eventStage.eventStageType as string === 'TextEventStage') e = await createTextEventStage(eventStage, newId); // for backward compability

//   switch (eventStage.eventStageType) {
//     case 'Text': e = await createTextEventStage(eventStage, newId); break;
//     case 'Title': e = await createTitleEventStage(eventStage, newId); break;
//     case 'Stream': e = await createStreamEventStage(eventStage, newId); break;
//     case 'Teaser': e = await createTeaserEventStage(eventStage, newId); break;
//   }

//   return {...eventStage, ...e}
// }
