import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maybeJson',
})
export class MaybeJsonPipe implements PipeTransform {
  transform(input: any): any {
    if (typeof input === 'object') {
      try {
        return JSON.stringify(input, null, 2);
      } catch (error) {
        return input;
      }
    }

    if (typeof input === 'string') {
      try {
        return JSON.stringify(JSON.parse(input), null, 2);
      } catch (error) {
        return input;
      }
    }

    return input;
  }
}
