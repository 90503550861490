import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { EventsListPageModuleFactory } from './../../factories/pagemodules/EventsListPageModuleFactory';
import { dateTypes, displayTypes, paginationTypes, SortingTypeElement, sortingTypes } from '../../entities/pagemodules/EventsListPageModule';
import { titlePositions } from '../../entities/PageModule';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const EventsListPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('EventsList'),
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new EventsListPageModuleFactory().createEventsListPageModuleLocal({})
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: obj => {
        return obj;
      }
    }
  },

  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].displayAs": {
    type: 'dropdown',
    dropdownOptions: [...displayTypes].map(a => ({ label: a, value: a }))
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].pagination": {
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].paginationType": {
    type: 'dropdown',
    dropdownOptions: [...paginationTypes].map(a => ({ label: a, value: a }))
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].eventTypes": {
    header: 'Event Type Filter',
    description: 'If none is selected, the module will display all possible event types.',
    placeholder: 'All Event Types',
    type: 'multiselect',
    showToggleAll: true,
    multiselectOptions: ['DigitalEvent', 'Webinar', 'HybridEvent', 'OnSiteEvent'].map(a => ({ label: a, value: a }))
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].keytopics": {
    type: 'multiselect',
    header: 'Keytopic Filter',
    description: 'If none is selected, the module will display all possible keytopics.',
    placeholder: 'All Keytopics',
    showToggleAll: true
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].eventSeries": {
    type: 'multiselect',
    header: 'Event Series Filter',
    description: 'If none is selected, the module will display all possible event series.',
    placeholder: 'All Event Series',
    showToggleAll: true
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].dates": {
    type: 'multiselect',
    header: 'Date Filter',
    description: 'If none is selected, the module will display all possible dates.',
    placeholder: 'All Dates',
    showToggleAll: true,
    multiselectOptions: [...dateTypes].map(a => ({ label: a, value: a }))
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].languages": {
    type: 'multiselect',
    header: 'Languages Filter',
    description: 'If none is selected, the module will display all possible languages.',
    placeholder: 'All Languages',
    showToggleAll: true
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].countries": {
    header: 'Countries Filter',
    description: 'If none is selected, the module will display all possible countries.',
    placeholder: 'All Countries',
    type: 'multiselect',
    showToggleAll: true
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].sorting": {
    header: 'GUI Sorting',
    description: 'If no option is selected, the module will not apply sorting.',
    placeholder: 'No Sorting',
    type: 'multiselect',
    multiselectOptions: [...sortingTypes].map((a: SortingTypeElement) => ({ label: a.label, value: a }))
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].defaultSortingField": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Title', value: 'sort.{language}.title' },
      { label: 'Start At', value: 'sort.{language}.startAt' }
    ]
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].defaultSortingDirection": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Ascending', value: '1' },
      { label: 'Descending', value: '-1' }
    ]
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].myBookmarkedEvents": {
    type: 'switch',
    header: 'My Bookmarked Events Filter'
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].myBookedEvents": {
    type: 'switch',
    header: 'My Booked Events Filter'
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].showWithSessionVideosOnly": {
    type: 'switch',
    header: 'Show Events with Session-Videos only'
  },

  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].showTicketInformation": {
    type: 'switch'
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].showSortButton": {
    type: 'switch'
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].showTicketCancellationButton": {
    type: 'switch'
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].showEventShareButton": {
    type: 'switch'
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].showEventCalendarButton": {
    type: 'switch'
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].showEventBookmarkButton": {
    type: 'switch'
  },
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].showEventRecommendationButton": {
    type: 'switch'
  },

  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='EventsList' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [...titlePositions].map(item => ({
      label: item,
      value: item
    }))
  }
};
