import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as jp from 'jsonpath';
import * as moment from 'moment';
import 'moment-timezone';
import * as sanitizeHtml from 'sanitize-html';

import { UtilsService } from 'src/app/services/utils/utils.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { InputConfiguration } from 'src/common/inputs/Inputs';
import { TimezoneSwitchService } from '../../services/utils/timezone-switch.service';

@Component({
  selector: 'c-patch-input',
  templateUrl: './patch-input.component.html',
  styleUrls: ['./patch-input.component.scss'],
})
export class PatchInputComponent implements OnChanges, OnDestroy, OnInit {
  @Input()
  jsonpath: string | string[];

  @Input()
  jsonpathParams: { [key: string]: any } = {};

  @Input()
  object: any;

  @Input()
  inputConfiguration: InputConfiguration = {};

  @Input()
  disabled = false;

  @Input()
  placeholder?: string;

  @Input()
  showDeleteButton?: boolean = true;

  @Output()
  focus: EventEmitter<void> = new EventEmitter<void>(true);

  @Output()
  blur: EventEmitter<void> = new EventEmitter<void>(true);

  @Output()
  valueChanged: EventEmitter<any> = new EventEmitter<any>(true);

  resolvedJsonpath: string;
  parent: any;
  key: any;
  newValue: any;
  onlyViewValue: any;

  calendarShowDates = false;

  autocompleteCache: { [key: string]: Promise<string> } = {};
  autocompleteResults: any[] = [];

  activatedHTMLEditor = false;
  showRawHTMLInput = false;

  ctaButtonActivated = false;

  documentMimeType: string[] = ['application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'video/*'];

  // currentLanguage: string;

  subscriptions: Subscription[] = [];

  private htmlPreviewAllowedTags: string[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private timezoneSwitchService: TimezoneSwitchService
  ) {}

  ngOnInit() {
    // this.subscriptions.push(this.activatedRoute.queryParams.subscribe((queryParams) => {
    //   this.currentLanguage = queryParams.language || 'en'
    // }))
    // this.currentLanguage = this.activatedRoute.snapshot.queryParams.language || 'en';

    if (this.inputConfiguration.type === 'ctabutton') {
      if (this.parent[this.key]) {
        this.ctaButtonActivated = true;
      }
    }

    if (this.inputConfiguration.type === 'dropdown') {
      // setTimeout(() => {this.inputConfiguration.type === 'dropdown' ?  this.inputConfiguration.dropdownOptions  : null},0)
      // console.log("hallo ", this.inputConfiguration.dropdownOptions)
    }

    if (this.inputConfiguration.type === 'html') {
      this.htmlPreviewAllowedTags = sanitizeHtml.defaults.allowedTags.concat(['iframe', 'img']);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.object?.currentValue !== changes.object?.previousValue ||
      (changes.jsonpath && JSON.stringify(changes.jsonpath.currentValue) !== JSON.stringify(changes.jsonpath.previousValue)) ||
      (changes.jsonpathParams && JSON.stringify(changes.jsonpathParams.currentValue) !== JSON.stringify(changes.jsonpathParams.previousValue)) ||
      (changes.inputConfiguration && JSON.stringify(changes.inputConfiguration.currentValue) !== JSON.stringify(changes.inputConfiguration.previousValue))
    ) {
      if (this.object && this.jsonpath) {
        this.resolvedJsonpath = this.utilsService.resolveJsonpath(this.jsonpath, this.jsonpathParams);
        const parts = jp.parse(this.resolvedJsonpath);
        const parentPath = jp.stringify(parts.slice(0, parts.length - 1));
        this.parent = jp.value(this.object, parentPath);

        if (parts?.length > 0 && this.parent) {
          this.key = parts[parts.length - 1].expression.value;
          this.newValue = this.parent[this.key];

          this.encodeViewValue();
        }

        return;
      }

      this.parent = null;
      this.key = null;
    }
  }

  setValue(newValue: any): void {
    this.newValue = this.decodeViewValue(newValue);
  }

  encodeViewValue(): void {
    if (['datetime', 'date', 'time'].indexOf(this.inputConfiguration.type) >= 0 && this.parent[this.key]) {
      this.onlyViewValue = this.timezoneSwitchService.encodeByUrlTimeZone(this.parent[this.key], this.activatedRoute.snapshot.queryParams.eTz);
    }
  }

  decodeViewValue(changedValue: any): Date {
    if (changedValue === null) {
      return changedValue;
    }
    if (['datetime', 'date', 'time'].indexOf(this.inputConfiguration.type) >= 0) {
      return this.timezoneSwitchService.decodeByUrlTimeZone(changedValue);
    }
    return changedValue;
  }

  emitNewValue(): void {
    this.valueChanged.emit(this.newValue);
    if (this.inputConfiguration.onChange) {
      this.inputConfiguration.onChange(this.newValue, this.parent[this.key]);
    }
  }

  sendValue(): void {
    if (typeof this.parent[this.key] === 'object' && typeof this.newValue === 'object') {
      if ((this.parent[this.key] === null || this.parent[this.key] instanceof Date) && (this.newValue === null || this.newValue instanceof Date)) {
        if (this.parent[this.key] !== this.newValue) {
          this.emitNewValue();
        }
      } else {
        this.emitNewValue();
      }
    } else {
      if (this.parent[this.key] !== this.newValue) {
        this.emitNewValue();
      }
    }
  }

  onFocus() {
    if (this.inputConfiguration.type !== 'html') {
      this.newValue = this.parent[this.key];
    }
    this.focus.emit();
    this.inputConfiguration.onFocus?.();
  }

  onBlur() {
    this.blur.emit();
    if (['dropdown', 'autocomplete'].indexOf(this.inputConfiguration.type) === -1) {
      this.sendValue();
    }
  }

  onHTMLPreviewClick() {
    if (!(this.inputConfiguration.disabled || this.disabled)) {
      this.activatedHTMLEditor = true;
    }
  }

  getPreviewHTML() {
    if (!this.parent[this.key]) {
      // placeholder
      return '<p style="min-height: 1px; margin: 0; padding: 15px 0;"></p>';
    }

    return sanitizeHtml(this.parent[this.key], {
      allowedTags: this.htmlPreviewAllowedTags,
      disallowedTagsMode: 'escape',
      allowedAttributes: false,
    });
  }

  showHtmlEditorCloseButton() {
    return !this.authService.hasRight('input.html.raw');
  }

  async autocompleteQuery(event) {
    if (this.inputConfiguration.type === 'autocomplete') {
      this.autocompleteResults = await this.inputConfiguration.autocompleteOptions.query(event.query);
    } else {
      this.autocompleteResults = [];
    }
  }

  autocompleteDisplay(val: any): Promise<string> {
    const key = typeof val === 'object' ? (val._id ? val._id : JSON.stringify(val)) : val;

    if (!this.autocompleteCache[key]) {
      this.autocompleteCache[key] = new Promise<string>(async (resolve: (result: string) => void, reject: (err: any) => void) => {
        if (this.inputConfiguration.type === 'autocomplete') {
          const displayField = this.inputConfiguration.autocompleteOptions.displayField;

          if (typeof displayField === 'string') {
            resolve(val[displayField]);
          } else if (typeof displayField === 'function') {
            resolve(await displayField(val));
          } else {
            resolve(val);
          }
        }

        reject(new Error('Invalid input configuration'));
      });
    }

    return this.autocompleteCache[key];
  }

  autocompleteLeft(value: any) {
    const index = this.parent[this.key].indexOf(value);

    if (index > 0) {
      this.parent[this.key].splice(index, 1);
      this.parent[this.key].splice(index - 1, 0, value);
    }
  }

  autocompleteRight(value: any) {
    const index = this.parent[this.key].indexOf(value);

    if (index < this.parent[this.key].length - 1) {
      this.parent[this.key].splice(index, 1);
      this.parent[this.key].splice(index + 1, 0, value);
    }
  }

  setDateTime(toNull: boolean = false) {
    if (toNull) {
      this.setValue(null);
    } else {
      if (this.inputConfiguration.type === 'time' && this.parent[this.key] != null) {
        const originalDateStr = (this.parent[this.key] as Date).toISOString().split('T').shift();
        let currentTimeStr = new Date().toLocaleTimeString();
        const newTimeAndDate = moment(originalDateStr + ' ' + currentTimeStr).toDate();
        this.setValue(newTimeAndDate);
      } else {
        this.setValue(new Date());
      }
    }
  }

  // onChangeCTAButton(active){
  //   if (active) {
  //     var ctaButton: CTAButton;
  //     ctaButton = createCTAButton({})
  //     ctaButton.local[this.currentLanguage] = createCTAButtonLocal({});
  //     this.setValue(ctaButton);
  //   } else {
  //     this.setValue(null);
  //   }
  // }

  countRows(stringContent: string, minRows): number {
    return Math.max((stringContent?.match(/\n/g) || []).length, minRows) + 1;
  }
}
