<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'GENERAL_LOOKUPS' | translate }}</h1>
  </div>
  <div class="tab-menu"></div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_LOOKUPS' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button *hasRight="'configuration.create'" [label]="('GENERAL_NEW' | translate) + ' ' + ('GENERAL_LOOKUP' | translate)" icon="pi icon-ic-add-item" (onClick)="createLookup()"></p-button>
      </div>
    </div>

    <c-table mode="query" [options]="tableOptions" [tableOptionsComponent]="toc" (query)="query($event)" [baseRoute]="'/configuration/lookups'" [routePathProperty]="'_id'">
      <ng-template let-lookup>
        <div *hasRight="['configuration.read', 'configuration.edit']">{{ lookup.internalName }}</div>
        <span *hasNoRight="['configuration.read', 'configuration.edit']">{{ lookup.internalName }}</span>
      </ng-template>
      <ng-template let-lookup>{{ lookup.description }}</ng-template>
      <ng-template let-lookup>{{ (lookup.local ? 'PAGE_LOOKUP_LOCAL_PROPERTIES' : lookup.regional ? 'PAGE_LOOKUP_REGIONAL_PROPERTIES' : 'PAGE_LOOKUP_DATA_PROPERTIES') | translate }}</ng-template>
      <ng-template let-lookup><p-tag *ngIf="lookup.systemLookup" value="{{ 'PAGE_LOOKUP_SYSTEMLOOKUP' | translate }}"></p-tag></ng-template>
    </c-table>

    <!-- <div>
      <p-table [value]="lookups" [lazy]="true" (onLazyLoad)="retrieveData($event)" [paginator]="true" [rows]="15" [totalRecords]="totalRecords">
        <ng-template pTemplate="header">
          <tr>
            <th>{{'PAGE_LOOKUP_INTERNAL' | translate}}</th>
            <th>{{'PAGE_LOOKUP_DESCRIPTION' | translate}}</th>
            <th>{{'PAGE_LOOKUP_TYPE' | translate}}</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-lookup>
          <tr>
            <td>{{ lookup.internalName }}</td>
            <td>{{ lookup.description }}</td>
            <td>{{ (lookup.local ? 'PAGE_LOOKUP_LOCAL_PROPERTIES' : lookup.regional ? 'PAGE_LOOKUP_REGIONAL_PROPERTIES' : 'PAGE_LOOKUP_DATA_PROPERTIES') | translate }}</td>
            <td class="text-right"><a [routerLink]="[lookup.shortId]">{{ "GENERAL_DETAILS" | translate }}</a></td>
          </tr>
        </ng-template>
      </p-table>
    </div> -->
  </div>
</div>
