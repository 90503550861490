import { ListImagePageModuleAttributes } from './../../entities/pagemodules/ListImagePageModule';
import { HeadlineType } from './../../entities/Attributes';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';
import { ListImagePageModule, ListImagePageModuleLocal } from '../../entities/PageModule';

export class ListImagePageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public listImagePageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): ListImagePageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async listImagePageModuleLocal(values?: any): Promise<ListImagePageModuleLocal> {
    return {
      title: values?.title || '',
      title_attr: this.listImagePageModuleAttributes(values?.title_attr),
      items: values?.items || [],
      itemIcon: values?.itemIcon || null,
      image: values?.image || null,
    };
  }

  public async listImagePageModule(values: any): Promise<ListImagePageModule> {
    if (values?.image || values?.itemIcon) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.image = item.image || values.image;
        item.itemIcon = item.itemIcon || values.itemIcon;
      });
    }

    const pageModule = {
      ...(await this.baseValues(values)),
      pageModuleType: 'ListImage',
      titlePosition: values?.titlePosition || 'content',
      local: await this.local(values, (val) => this.listImagePageModuleLocal(val)),
      itemIcon: undefined,
      image: undefined,
      imageSize: values?.imageSize || 'large',
      imagePosition: values?.imagePosition || 'left',
      imagePositionWithinImageArea: values?.imagePositionWithinImageArea || 'left',
      imagePositionMobile: values?.imagePositionMobile || 'bottom',
      ctaButton: values?.ctaButton || null,
    };

    return { ...pageModule, pageModuleType: 'ListImage' };
  }
}
