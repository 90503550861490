import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import * as uuid from 'uuid';

@Component({
  selector: 'c-sidebar-footer',
  templateUrl: './sidebar-footer.component.html',
  styleUrls: ['./sidebar-footer.component.scss'],
})
export class SidebarFooterComponent {
  @Input()
  footerInfo: string;

  @Input()
  autoSaveInfo: boolean = false;

  @Input()
  showDeleteButton: boolean = false;
  @Input()
  disableDeleteButton: boolean = false;
  @Input()
  onDeleteButton: Function;
  @Output()
  onDelete = new EventEmitter<boolean>();

  @Input()
  showCancelButton: boolean = false;
  @Output()
  onCancel = new EventEmitter<boolean>();

  @Input()
  showSaveButton: boolean = false;
  @Input()
  disableSaveButton: boolean = false;
  @Output()
  onSave = new EventEmitter<boolean>();

  @Input()
  showCloseButton: boolean = false;
  @Output()
  onClose = new EventEmitter<boolean>();

  @Input()
  disableOptionalButton: boolean = false;
  @Input()
  showOptionalButton: boolean = false;
  @Input()
  optionalButtonLabel: string = '';
  @Output()
  onOptional = new EventEmitter<boolean>();
  @Input()
  onOptionalButton: Function;

  generatedConfirmPopupKey = uuid.v4();

  constructor(private translate: TranslateService, private confirmationService: ConfirmationService) {}

  async confirmDelete(event) {
    if (this.onDeleteButton) {
      this.onDeleteButton(event);
    } else {
      this.confirmationService.confirm({
        key: this.generatedConfirmPopupKey,
        target: event.target,
        message: this.translate.instant('GENERAL_CONFIRM_DELETE'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.onDelete.emit(true);
        },
        reject: () => {
          // Nothing to do
        },
      });
    }
  }

  async confirmOptional(event) {
    if (this.onOptionalButton) {
      this.onOptionalButton(event);
    } else {
      this.confirmationService.confirm({
        key: this.generatedConfirmPopupKey,
        target: event.target,
        message: this.translate.instant('GENERAL_CONFIRM_DELETE_LANGUAGE'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.onOptional.emit(true);
        },
        reject: () => {
          // Nothing to do
        },
      });
    }
  }
}
