<div class="panel">
  <div class="panel-header">
    <span>{{ 'PAGE_LIVE_CHAT_CHAT_REQUESTS' | translate }}</span>
    <div class="actions">
      <p-dropdown [(ngModel)]="selectedMessageFilter" [options]="messageFilters" optionValue="value" optionLabel="title"></p-dropdown>
    </div>
  </div>
  <div class="chat-container" *ngIf="chatContainer | async as cc">
    <div class="messages">
      <div class="message" *ngFor="let message of cc.getFilteredMessages(selectedMessageFilter); let index = index" [ngClass]="!message.expert ? 'left' : 'right'">
        <div class="bubble">
          <div class="bubble-head p-d-flex p-ai-center">
            <span *ngIf="message.messageType === 'PollMessage' && asPollMessage(message) as poll" class="user p-d-flex p-ai-center p-mr-1">
              <i class="pi pi-chart-bar p-mr-1"></i>
              {{ 'PAGE_LIVE_CHAT_POLLS_REQUEST' | translate }}
            </span>

            <span *ngIf="message.expert" class="p-d-flex p-ai-center p-mr-1">
              <i class="pi pi-star" [pTooltip]="'GENERAL_EXPERT_USER' | translate"></i>
            </span>

            <span *ngIf="message.messageType !== 'PollMessage'" class="user p-mr-1" (mouseenter)="userDetails.show($event)" (mouseleave)="userDetails.hide()">
              <a *ngIf="message.user.userType != 'ArtificialUser' && message.user.displayName" [routerLink]="['/users/' + message.user._id]">{{ message.user?.displayName }} </a>
              <a *ngIf="message.user.userType == 'ArtificialUser' && message.user.displayName" [routerLink]="[]" (click)="openArtificialUser(message.user._id)">
                {{ message.user?.displayName }}
              </a>
              <span *ngIf="message.user.userType != 'ArtificialUser' && !message.user?.displayName"> {{ message.user?.displayName }}</span>
              <p-overlayPanel #userDetails appendTo="body" (click)="userDetails.toggle($event)">
                <ng-template pTemplate>
                  <c-user-details [userType]="'USER'" [user]="message.user._id"></c-user-details>
                </ng-template>
              </p-overlayPanel>
            </span>

            <span class="date p-mr">{{ message.date | date : 'short' }}</span>

            <span class="state p-mr">&nbsp;-&nbsp;{{ message.messageState }}</span>
          </div>

          <div class="reply-container" *ngIf="message?.replyTo">
            <div class="message">
              <span *ngIf="message.originalMessage.messageType === 'TextMessage'" class="user">{{ message.originalMessage.user?.displayName || 'Unknown User' }}</span>
              <span *ngIf="message.originalMessage.messageType === 'PollMessage'" class="user">
                <i class="pi pi-chart-bar p-mr-1"></i>
                {{ 'PAGE_LIVE_CHAT_POLLS_REQUEST' | translate }}
              </span>
              <span class="date">{{ (message.originalMessage.date | date : 'short') || '-' }}</span>
              <div *ngIf="message.originalMessage.messageType === 'TextMessage'" class="text">{{ message.originalMessage.data.text || 'Message Deleted' }}</div>
            </div>
          </div>

          <div class="text" *ngIf="message.messageType === 'TextMessage'">{{ message.data.text }}</div>
          <div class="rejectionReason" *ngIf="message.rejectionReason">{{ 'PAGE_LIVE_CHAT_REJECT_REASON' | translate }}: {{ message.rejectionReason }}</div>
          <div class="quotedMessage" *ngIf="getTextMessage(message).relatedMessage">{{ 'PAGE_LIVE_CHAT_RELATED_MESSAGE' | translate }}: {{ getTextMessage(message).relatedMessage.data.text }}</div>

          <div class="text" *ngIf="message.messageType === 'PollMessage' && asPollMessage(message) as pollMessage">
            <c-poll-viewer [data]="pollMessage.data" [bcp]="false"></c-poll-viewer>
          </div>

          <div class="messageActions p-d-flex p-jc-end" *ngIf="message.messageState == 'OPEN'">
            <button pButton pRipple type="button" [label]="'PAGE_LIVE_CHAT_CONFIRM' | translate" class="p-button-success p-button-text" (click)="confirm(message)"></button>
            <button pButton pRipple type="button" [label]="'PAGE_LIVE_CHAT_CONFIRM_AND_REPLY' | translate" class="p-button-success p-button-text" (click)="confirmAndReply(message)"></button>
            <button pButton pRipple type="button" [label]="'PAGE_LIVE_CHAT_REJECT' | translate" class="p-button-danger p-button-text" (click)="reject(message)"></button>
            <button
              *ngIf="message.messageType === 'TextMessage'"
              pButton
              pRipple
              type="button"
              [label]="'PAGE_LIVE_CHAT_REJECT_WITH_REASON' | translate"
              class="p-button-danger p-button-text"
              (click)="rejectAndAnswerUser(message)"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
