import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { Stage2TeaserEventStagePageModuleFactory } from '../../factories/pagemodules/Stage2TeaserEventStagePageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const stage2TeaserEventStagePageModuleInputs: Inputs = {
  ...commonPageModuleInputs('TeaserEventStage2'),
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].ctalist": {
    factory: async () => [],
    childFactory: async () => new Stage2TeaserEventStagePageModuleFactory().teaserEventStage2PageModuleButton({}),
    list: true,
    listKey: '_id',
    header: 'CTA Button',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].ctalist[$index].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].ctalistInteraction": {
    factory: async () => [],
    childFactory: async () => new Stage2TeaserEventStagePageModuleFactory().teaserEventStage2PageModuleButton({}),
    list: true,
    listKey: '_id',
    header: 'CTA Button',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].ctalistInteraction[$index].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].local.$language.background": {},
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new Stage2TeaserEventStagePageModuleFactory().teaserEventStage2PageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].local.$language.smallHeadline": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].local.$language.subtitle": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].showCountdown": {
    header: 'Show Countdown',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].local.$language.items": {
    factory: async () => [],
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].local.$language.items[?(@.teaserItemType=='Highlight' && ?(@._id=='$itemId')]": {
    factory: async () => new Stage2TeaserEventStagePageModuleFactory().highlightTeaserItem({}),
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].local.$language.items[?(@.teaserItemType=='Link' && ?(@._id=='$itemId')]": {
    factory: async () => new Stage2TeaserEventStagePageModuleFactory().linkTeaserItem({}),
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].local.$language.items[?(@.teaserItemType=='CTA' && ?(@._id=='$itemId')]": {
    factory: async () => new Stage2TeaserEventStagePageModuleFactory().ctaTeaserItem({}),
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].local.$language.subtext": {},
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].discoverMore": {
    header: 'Show Discover More',
    type: 'switch',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].topAreaHeight": {
    factory: async () => new Stage2TeaserEventStagePageModuleFactory().teaserEventStage2PageModuleAreaHeight({}),
  },

  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.topAreaHeight.desktopWidescreen": {
    header: 'Top Area Height Desktop Widescreen',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.topAreaHeight.desktop": {
    header: 'Top Area Height Desktop',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.topAreaHeight.desktopLarge": {
    header: 'Top Area Height Desktop large',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.topAreaHeight.mobile": {
    header: 'Top Area Height mobile',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.topAreaHeight.mobileLarge": {
    header: 'Top Area Height mobile large',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.topAreaHeight.tablet": {
    header: 'Top Area Height tablet ',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.topAreaHeight.tabletLarge": {
    header: 'Top Area Height tablet large',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].contentAreaHeight": {
    factory: async () => new Stage2TeaserEventStagePageModuleFactory().teaserEventStage2PageModuleAreaHeight({}),
  },

  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.contentAreaHeight.desktopWidescreen": {
    header: 'Top Area Height Desktop Widescreen',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.contentAreaHeight.desktop": {
    header: 'Top Area Height Desktop',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.contentAreaHeight.desktopLarge": {
    header: 'Top Area Height Desktop large',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.contentAreaHeight.mobile": {
    header: 'Top Area Height mobile',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.contentAreaHeight.mobileLarge": {
    header: 'Top Area Height mobile large',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.contentAreaHeight.tablet": {
    header: 'Top Area Height tablet ',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.contentAreaHeight.tabletLarge": {
    header: 'Top Area Height tablet large',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].ctaAreaHeight": {
    factory: async () => new Stage2TeaserEventStagePageModuleFactory().teaserEventStage2PageModuleAreaHeight({}),
  },

  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.ctaAreaHeight.desktopWidescreen": {
    header: 'Top Area Height Desktop Widescreen',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.ctaAreaHeight.desktop": {
    header: 'Top Area Height Desktop',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.ctaAreaHeight.desktopLarge": {
    header: 'Top Area Height Desktop large',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.ctaAreaHeight.mobile": {
    header: 'Top Area Height mobile',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.ctaAreaHeight.mobileLarge": {
    header: 'Top Area Height mobile large',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.ctaAreaHeight.tablet": {
    header: 'Top Area Height tablet ',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage2' && @._id=='$pageModuleId')].$.ctaAreaHeight.tabletLarge": {
    header: 'Top Area Height tablet large',
  },
};
