import { NewsfeedEntryPageModuleFactory } from '../../factories/pagemodules/NewsfeedEntryPageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const newsfeedEntryPageModuleInputs: Inputs = {
  ...commonPageModuleInputs('NewsfeedEntry'),
  
  "$.pageModules[?(@.pageModuleType=='NewsfeedEntry' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new NewsfeedEntryPageModuleFactory().createNewsfeedEntryPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='NewsfeedEntry' && @._id=='$pageModuleId')].local.$language.image": {},
  "$.pageModules[?(@.pageModuleType=='NewsfeedEntry' && @._id=='$pageModuleId')].local.$language.title": {},
  "$.pageModules[?(@.pageModuleType=='NewsfeedEntry' && @._id=='$pageModuleId')].local.$language.text": {},
  "$.pageModules[?(@.pageModuleType=='NewsfeedEntry' && @._id=='$pageModuleId')].tags": {
    type: 'tags'
  },
  "$.pageModules[?(@.pageModuleType=='NewsfeedEntry' && @._id=='$pageModuleId')].ctaButton": {},
};
