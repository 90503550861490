import { DirectivesModule } from './../../directives/directives.module';
import { ComponentsModule } from './../../components/components.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';

import { AdminUsersComponent } from './admin-users.component';

const routes: Routes = [{ path: '', component: AdminUsersComponent }];

@NgModule({
  declarations: [AdminUsersComponent],
  imports: [CommonModule, RouterModule.forChild(routes), FormsModule, TranslateModule, TableModule, CheckboxModule, DividerModule, ButtonModule, ComponentsModule, DirectivesModule],
  exports: [RouterModule],
})
export class AdminUsersModule {}
