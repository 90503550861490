<div *skeleton="{ show: loading }">
  <div class="tab-header">
    <h1>
      <span class="pre-title">{{ 'GENERAL_ADMINUSER_ROLE' | translate }}</span>
      <span>{{ role?.name }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <p-button icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" [disabled]="loading || saving || !isValid() || isEditable()" (onClick)="onSave()"></p-button>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_PROPERTIES' | translate }}
    </div>
    <div *ngIf="role" class="panel-body">
      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [(ngModel)]="role.name" [disabled]="isEditable()" />
        </div>
        <div class="title">{{ 'PAGE_ADMINUSER_ROLE_ROLE_NAME' | translate }}</div>
        <div class="description">{{ 'PAGE_ADMINUSER_ROLE_ROLE_NAME_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <p-dropdown [options]="options" optionLabel="name" optionValue="value" [(ngModel)]="role.type" [disabled]="isEditable()"></p-dropdown>
        </div>
        <div class="title">{{ 'PAGE_ADMINUSER_ROLE_ROLE_TYPE' | translate }}</div>
        <div class="description">{{ 'PAGE_ADMINUSER_ROLE_ROLE_TYPE_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <p-autoComplete
            [(ngModel)]="usedRights"
            [suggestions]="filteredRights"
            (completeMethod)="filterRights($event)"
            field="name"
            [forceSelection]="true"
            [multiple]="true"
            [dropdown]="true"
            [disabled]="isEditable()"
          >
          </p-autoComplete>
        </div>
        <div class="title">{{ 'PAGE_ADMINUSER_ROLE_RIGHTS' | translate }}</div>
        <div class="description">{{ 'PAGE_ADMINUSER_ROLE_RIGHTS_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <p-chips *ngIf="roleGroups" [(ngModel)]="roleGroups" [disabled]="true">
            <ng-template let-group pTemplate="group">
              {{ group.name }}
            </ng-template>
          </p-chips>
        </div>
        <div class="title">{{ 'PAGE_ADMINUSER_ROLE_GROUPS' | translate }}</div>
        <div class="description">{{ 'PAGE_ADMINUSER_ROLE_GROUPS_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input no-border">
          <p-table *ngIf="roleUsers" [value]="roleUsers" styleClass="p-datatable-sm">
            <ng-template pTemplate="header">
              <tr>
                <th>{{ 'PAGE_ADMINUSER_DISPLAY_NAME' | translate }}</th>
                <th>{{ 'PAGE_ADMINUSER_DISPLAY_EMAIL' | translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-roleUser>
              <tr>
                <td>{{ roleUser.displayName }}</td>
                <td>{{ roleUser.email }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="title">{{ 'PAGE_ADMINUSER_ROLE_GROUP_USERS' | translate }}</div>
        <div class="description">{{ 'PAGE_ADMINUSER_ROLE_GROUP_USERS_DESCRIPTION' | translate }}</div>
      </div>
    </div>
  </div>
</div>
