import { TabsService } from './../../tabs/services/tabs.service';
import { TableOptions, TableQuery } from './../../components/table/table.interfaces';
import { Tab } from './../../tabs/classes/tab';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/events/events.service';
import { EventType } from 'src/common/entities/EventType';

@Component({
  selector: 'app-event-types-configuration',
  templateUrl: './event-types-configuration.component.html',
  styleUrls: ['./event-types-configuration.component.scss'],
})
export class EventTypesConfigurationComponent implements OnInit {
  @Input()
  language: string;

  eventTypes: EventType[] = [];

  loading: boolean = false;

  currentLanguage: string = null;

  tab: Tab;
  tableOptions: TableOptions<EventType>;

  constructor(private eventsService: EventsService, private tabsService: TabsService, private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.tab = this.tabsService.register({
      category: 'data',
      route: this.activatedRoute.snapshot,
      title: 'Contact',
    });

    this.tableOptions = {
      size: 50,
      columns: [
        { header: 'PAGE_EVENT_TYPE_CONFIGURATION_INTERNALNAME', sort: 'internalName' },
        { header: 'PAGE_EVENT_TYPE_CONFIGURATION_TITLE', sort: 'local.en.title' },
        { header: 'PAGE_EVENT_TYPE_CONFIGURATION_DEFAULT_TEMPLATE', sort: 'defaultTemplate' },
      ],
      filters: [
        { header: 'PAGE_EVENT_TYPE_CONFIGURATION_INTERNALNAME', path: 'internalName' },
        { header: 'PAGE_EVENT_TYPE_CONFIGURATION_TITLE', path: 'local.en.title' },
        { header: 'PAGE_EVENT_TYPE_CONFIGURATION_DEFAULT_TEMPLATE', path: 'defaultTemplate' },
      ],
    };
  }

  query(query: TableQuery<EventType>) {
    query.result = this.eventsService.getEventTypes(query.query);
  }

  newEventTyp() {
    this.router.navigate(['/configuration/types/new']);
  }
}
