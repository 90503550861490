import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TableComponent } from 'src/app/components/table/table.component';
import { TableOptions, TableQuery } from 'src/app/components/table/table.interfaces';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { EventsService } from 'src/app/services/events/events.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { PostEventsBody } from 'src/common/api/v1/events/PostEvents';
import { GoToWebinarSystemPlugin, isGoToWebinarSystemPlugin, SystemPlugin } from 'src/common/entities/Configuration';
import { Event, eventPhase } from 'src/common/entities/Event';
import { RBAC } from 'src/common/entities/RBAC';
import { Factory } from 'src/common/factories/Factory';
import { LanguagesService } from '../../services/languages/languages.service';
import { Language } from '../../../common/entities/Language';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { QuickFilter } from 'src/common/entities/QuickFilter';
import { AuthService } from 'src/app/services/auth/auth.service';

interface Search {
  internalName: string;
  shortId: string;
  eventType: string;
  totalStartAt: string;
  totalEndAt: string;
}

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit, OnDestroy {
  @ViewChildren(TableComponent) private tables: QueryList<TableComponent<any>>;
  @ViewChild('modalCreateEventSideBar') private modalCreateEventSideBar: SidebarComponent;

  searchTextSubject: Subject<string> = new Subject<string>();
  searchText: string;
  searchTextUsed = false;
  eventTypes: { label: string; value: string }[] = [];
  eventSeries: { label: string; value: string }[] = [];
  eventTemplates: { label: string; value: string }[] = [];
  working = false;
  mode: 'events' | 'templates' = 'events';
  newEvent: PostEventsBody | null = null;
  refreshTable = this.searchTextSubject.pipe(debounceTime(500));

  languages: Language[] = [];
  newEventBaseType: 'none' | 'event' | 'template' = 'none';
  idTemplate: string;
  idEvent: string;
  get showNewEventModal(): boolean {
    return !!this.newEvent;
  }
  set showNewEventModal(val: boolean) {
    if (!val) this.newEvent = null;
  }

  tab: Tab;

  eventsTableOptions: TableOptions<RBAC<Event>>;
  templatesTableOptions: TableOptions<RBAC<Event>>;

  subscriptions: Subscription[] = [];
  externalEventImportInProgress = false;
  showImportButton = false;
  externalEventsLoading = false;
  showImportEventsModal: boolean = false;
  externalEvents: RBAC<Event>[] = [];
  externalEventLinks: Record<string, string> = {};
  externalEventsQuery = {
    fromTime: new Date(),
    toTime: new Date(),
  };
  includeAssetsImportCheckboxes: Record<string, boolean> = {};
  externalEventImportTemplate: Record<string, string> = {};
  externalEventPlugin: SystemPlugin;

  displayRights: boolean = false;
  rbac_resouce: string;
  rbac_rights: string[];
  rightsDialogTitle: string;

  quickFilters: QuickFilter[] = [
    {
      name: 'Draft',
      active: true,
      value: 'DRAFT',
    },
    {
      name: 'Announced',
      active: true,
      value: 'ANNOUNCED',
    },
    {
      name: 'Released',
      active: true,
      value: 'RELEASED',
    },
    {
      name: 'Live',
      active: true,
      value: 'LIVE',
    },
    {
      name: 'Archive',
      active: true,
      value: 'ARCHIVE',
    },
  ];

  quickFiltersActive = true;
  hasEventCreateRight = false;
  hasTemplateCreateRight = false;

  // regions: Region[];

  constructor(
    private router: Router,
    private eventsService: EventsService,
    private utilsService: UtilsService,
    private configurationService: ConfigurationService,
    private tabsService: TabsService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private languagesService: LanguagesService,
    private authService: AuthService
  ) {
    this.mode = window.location.href.includes('/events') ? 'events' : 'templates';

    this.tab = this.tabsService.register({
      category: 'events',
      route: this.activatedRoute.snapshot,
      title: 'Events',
      loading: true,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async ngOnInit(): Promise<void> {
    this.hasEventCreateRight = await this.authService.hasRight('events.create');
    this.hasTemplateCreateRight = await this.authService.hasRight('templates.create');
    this.externalEventsQuery.toTime.setMonth(new Date().getMonth() + 1);

    this.eventTypes = (await this.eventsService.getEventTypes()).items.map((i) => ({ value: i._id, label: i.internalName }));
    this.eventSeries = (await this.eventsService.getEventSeries()).items.map((i) => ({ value: i._id, label: i.internalName }));
    this.eventTemplates = (
      await this.eventsService.getEvents({
        filter: { template: { matchMode: 'equals', value: true } },
      })
    ).items.map((e) => ({
      label: e.internalName,
      value: e._id,
    }));

    this.eventsTableOptions = {
      columns: [
        { header: 'Internal Name', sort: 'lowerInternalName', visible: 'fixed' },
        { header: 'Live Area' },
        { header: 'Short Id (URL)', sort: 'lowerShortId' },
        { header: 'Start At', sort: 'totalStartAt' },
        { header: 'End At', sort: 'totalEndAt' },
        { header: 'Event Type', sort: 'lowerEventType' },
        { header: 'Event Serie', sort: 'lowerEventSerie' },
        { header: 'Phase' },
        { header: 'Public' },
      ],
      filters: [
        { header: 'Internal Name', path: 'internalName' },
        { header: 'Short Id (URL)', path: 'shortId' },
        { header: 'Start At', path: 'totalStartAt', type: 'date' },
        { header: 'End At', path: 'totalEndAt', type: 'date' },
        { header: 'Event Type', path: 'eventType', type: 'objectid', values: this.eventTypes },
        { header: 'Event Serie', path: 'eventSerie', type: 'objectid', values: this.eventSeries },
      ],
    };

    this.templatesTableOptions = {
      columns: [{ header: 'Internal Name', sort: 'lowerInternalName', visible: 'fixed' }, { header: 'Event Type', sort: 'lowerEventType' }, { header: '' }],
      filters: [
        { header: 'Internal Name', path: 'internalName' },
        { header: 'Short Id (URL)', path: 'shortId' },
      ],
    };

    this.tab.loading = false;

    this.externalEventPlugin = Factory.configuration()
      .systemPluginsConfiguration(this.configurationService.configuration().systemPlugins)
      .plugins.find((plugin) => isGoToWebinarSystemPlugin(plugin));

    if (this.externalEventPlugin) {
      this.showImportButton = true;
    }

    this.languages = await this.languagesService.getActiveLanguagesAsPromise();
  }

  async searchTextKeyUp() {
    this.searchTextUsed = true;
    this.searchTextSubject.next();
  }

  query(query: TableQuery<RBAC<Event>>): void {
    query.query.filter.template = {
      matchMode: this.mode === 'templates' ? 'equals' : 'notEquals',
      value: true,
    };
    if (this.searchText && this.searchTextUsed) {
      query.query.filter.internalName = {
        matchMode: 'contains',
        caseInsensitive: true,
        value: this.searchText,
      };
    } else if (query.query.filter.internalName?.value) {
      this.searchText = query.query.filter.internalName.value as string;
    }
    this.searchTextUsed = false;

    if (this.quickFiltersActive) {
      query.query.filter.eventPhase = {
        matchMode: 'in',
        value: this.quickFilters.map((item) => {
          if (item.active) {
            return item.value;
          }
          return null;
        }),
      };
    } else {
      delete query.query.filter.eventPhase;
    }
    query.result = this.eventsService.getEvents(query.query);
  }

  setQuickFilters(quickFilters: QuickFilter[]) {
    this.quickFilters = [...quickFilters];
    this.quickFiltersActive = this.quickFilters.find((q) => q.active == true) != null;
    this.refresh();
  }

  async refresh(clear = false): Promise<void> {
    this.working = true;
    const tables = this.tables.toArray();
    await Promise.all(
      tables.map((t) => {
        if (clear) {
          t.clearFilter();
        } else {
          t.refresh();
        }
      })
    );

    this.working = false;
  }

  phase(event: Event): string {
    return eventPhase(event, null, this.configurationService.serverTime());
  }

  createEvent(): void {
    this.working = true;

    if (this.newEventBaseType === 'event' && this.idEvent) {
      this.newEvent.fromTemplate = { event: this.idEvent };
    } else if (this.newEventBaseType === 'template' && this.idTemplate) {
      this.newEvent.fromTemplate = { event: this.idTemplate };
    } else {
      delete this.newEvent.fromTemplate;
    }

    this.eventsService
      .createEvent(this.newEvent)
      .then((event) => {
        this.newEvent = null;
        if (event.template) {
          this.router.navigate(['/templates', event._id]);
        } else {
          this.router.navigate(['/events', event._id]);
        }
        this.working = false;
        this.newEventBaseType = 'none';
      })
      .catch((error) => {
        this.working = false;
        console.error(error);
        const summary = typeof error.error?.message === 'string' ? error.error.message : 'Something went wrong';
        this.messageService.add({ severity: 'error', summary });
      });
    this.modalCreateEventSideBar.close();
  }

  openNewEventOrTemplateModal(overrides = {}, checkTemplate = false) {
    this.newEvent = {
      internalName: '',
      eventType: this.eventTypes[0].value,
      currentEventVersion: { languages: [] },
      shortId: '',
      ...overrides,
    };

    if (this.newEvent.fromTemplate?.event) {
      if (checkTemplate) {
        this.newEventBaseType = 'template';
        this.idTemplate = this.newEvent.fromTemplate.event;
      } else {
        this.idEvent = this.newEvent.fromTemplate.event;
        this.newEventBaseType = 'event';
      }
      this.setLanguageFromEventIdToNewEventObj(this.newEvent.fromTemplate.event);
    }
  }

  async openImportEventsModal() {
    this.showImportEventsModal = true;
  }

  onImportEventsDialogClose() {
    this.externalEvents = [];
  }

  async searchExternalEvents() {
    this.externalEventsLoading = true;

    // ISO8601 format without milliseconds
    const fromTime = this.externalEventsQuery.fromTime.toISOString().split('.')[0] + 'Z';
    const toTime = this.externalEventsQuery.toTime.toISOString().split('.')[0] + 'Z';

    try {
      const result = await this.eventsService.getEvents({
        plugin: 'GoToWebinar',
        fromTime,
        toTime,
        limit: 200,
      });
      this.externalEvents = result.items;
      this.includeAssetsImportCheckboxes = {};
      this.externalEventImportTemplate = {};
      this.externalEvents.forEach((event) => {
        this.includeAssetsImportCheckboxes[event.externalEvent.externalEventId] = false;
        this.externalEventImportTemplate[event.externalEvent.externalEventId] = (this.externalEventPlugin as GoToWebinarSystemPlugin).eventTemplate || null;
        if (event._id) {
          this.externalEventLinks[event.externalEvent.externalEventId] = event._id;
        }
      });
    } catch (error) {
      console.error(error);
      const summary = typeof error.error?.message === 'string' ? error.error.message : 'Something went wrong';
      this.messageService.add({ severity: 'error', summary });
    }

    this.externalEventsLoading = false;
  }

  async importExternalEvent(event: Event) {
    this.externalEventImportInProgress = true;

    try {
      const includeAssets = this.includeAssetsImportCheckboxes[event.externalEvent.externalEventId];
      const fromTemplate = this.externalEventImportTemplate[event.externalEvent.externalEventId];
      const result = await this.eventsService.importEvent({ plugin: 'GoToWebinar', externalEventId: event.externalEvent.externalEventId, includeAssets, fromTemplate });

      if (result.status === 201) {
        this.messageService.add({ severity: 'success', summary: 'New event created' });
      } else {
        this.messageService.add({ severity: 'success', summary: 'Event updated' });
      }

      this.externalEventLinks[event.externalEvent.externalEventId] = result.body._id;
    } catch (error) {
      console.error(error);
      const summary = typeof error.error?.message === 'string' ? error.error.message : 'Something went wrong';
      this.messageService.add({ severity: 'error', summary });
    }

    this.externalEventImportInProgress = false;
  }

  externalEventHasAssets(event: Event): boolean {
    return event.currentEventVersion.sessions.some((session) => {
      for (const lang in session.externalSession.assets) {
        if (session.externalSession.assets[lang].externalAssetId) {
          return true;
        }
      }
      return false;
    });
  }

  setExternalEventsQueryValue(date: Date, key: string): void {
    this.externalEventsQuery[key] = date;
  }

  eventCreateChange(eventId: string): void {
    this.idEvent = eventId;
    this.setLanguageFromEventIdToNewEventObj(eventId);
  }

  templateCreateChange(templateId: string): void {
    this.idTemplate = templateId;
    this.setLanguageFromEventIdToNewEventObj(templateId);
  }

  setLanguageFromEventIdToNewEventObj(eventId: string): void {
    if (!this.newEvent.currentEventVersion.languages.length) {
      this.eventsService.getEvent(eventId).then((e) => (this.newEvent.currentEventVersion.languages = e.currentEventVersion?.languages || []));
    }
  }

  linkClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
