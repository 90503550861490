import { MultiEventStagePageModuleFactory } from '../../factories/pagemodules/MultiEventStagePageModuleFactory';

import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const multiEventStagePageModulesInputs: Inputs = {
  ...commonPageModuleInputs('MultiEventStage'),
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].ctalist": {
    factory: async () => [],
    childFactory: async () => new MultiEventStagePageModuleFactory().multiEventStagePageModuleButton({}),
    list: true,
    listKey: '_id',
    header: 'CTA Button'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].ctalist[$index].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].ctalistInteraction": {
    factory: async () => [],
    childFactory: async () => new MultiEventStagePageModuleFactory().multiEventStagePageModuleButton({}),
    list: true,
    listKey: '_id',
    header: 'CTA Button'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].ctalistInteraction[$index].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].local.$language.background": {},
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].local.$language.events": {
    factory: async () => [],
    list: true,
    childFactory: async () => null
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].local.$language.events[$index]": {
    type: 'event',
    header: 'Event',
    hideRemoveLink: true
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new MultiEventStagePageModuleFactory().multiEventStagePageModuleLocal({})
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].local.$language.title": {},
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].local.$language.subtitle": {},
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].local.$language.preHeadline": {},
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].local.$language.subtext": {},
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].discoverMore": {
    header: 'Show Discover More',
    type: 'switch'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].hideEventName": {
    type: 'switch'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].hideEventDate": {
    type: 'switch'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].hideEventTags": {
    type: 'switch'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].stageHeight": {
    factory: async () => new MultiEventStagePageModuleFactory().multiEventStagePageModuleStageHeight({})
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].stageHeight.desktop": {
    type: 'number',
    header: 'Stage height desktop'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].stageHeight.desktopLarge": {
    type: 'number',
    header: 'Stage height desktop large'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].stageHeight.desktopWidescreen": {
    type: 'number',
    header: 'Stage height desktop widescreen'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].stageHeight.tablet": {
    type: 'number',
    header: 'Stage height tablet'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].stageHeight.tabletLarge": {
    type: 'number',
    header: 'Stage height tablet large'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].stageHeight.mobile": {
    header: 'Stage height mobile',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].stageHeight.mobileLarge": {
    header: 'Stage height mobile large',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint": {
    factory: async () => new MultiEventStagePageModuleFactory().multiEventStagePageModuleButtonSpacingTopBreakpoint({})
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.desktop": {
    header: 'Button spacing top desktop',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.desktopLarge": {
    header: 'Button spacing top desktop large',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.desktopWidescreen": {
    header: 'Button spacing top desktop widescreen',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.tablet": {
    header: 'Button spacing top tablet',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.tabletLarge": {
    header: 'Button spacing top tablet large',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.mobile": {
    header: 'Button spacing top mobile',
    type: 'number'
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.mobileLarge": {
    header: 'Button spacing top mobile large',
    type: 'number'
  }
};
