<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'PAGE_SPEAKERS_HEADER' | translate }}</h1>
  </div>
  <div class="tab-menu"></div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_SPEAKERS_HEADER' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button *hasRight="'people.create'" [label]="'New Person'" icon="pi icon-ic-add-item" (onClick)="newSpeaker()"></p-button>
      </div>
    </div>
    <div class="search">
      <input placeholder="Search people ..." pInputText [(ngModel)]="searchText" (keyup)="handleTextKeyUp()" />
    </div>
    <c-table
      #table
      mode="query"
      [options]="tableOptions"
      [tableOptionsComponent]="toc"
      (query)="query($event)"
      (clearFilterList)="searchText = ''"
      [baseRoute]="'/speakers'"
      [routePathProperty]="'_id'"
    >
      <ng-template let-speaker>
        <div
          *hasRight="{
            resource: 'people:' + speaker._id,
            rights: ['people.read', 'people.edit']
          }"
        >
          {{ speaker.lastName || ('GENERAL_UNNAMED' | translate) }}
        </div>
        <span
          *hasNoRight="{
            resource: 'people:' + speaker._id,
            rights: ['people.read', 'people.edit']
          }"
          >{{ speaker.lastName || ('GENERAL_UNNAMED' | translate) }}</span
        >
      </ng-template>
      <ng-template let-speaker>
        <div
          *hasRight="{
            resource: 'people:' + speaker._id,
            rights: ['people.read', 'people.edit']
          }"
        >
          {{ speaker.firstName || ('GENERAL_UNNAMED' | translate) }}
        </div>
        <span
          *hasNoRight="{
            resource: 'people:' + speaker._id,
            rights: ['people.read', 'people.edit']
          }"
        >
          {{ speaker.firstName || ('GENERAL_UNNAMED' | translate) }}
        </span>
      </ng-template>
      <ng-template let-speaker>{{ speaker.company }}</ng-template>
      <ng-template let-speaker>{{ (speaker.local || {} | keys).length }}</ng-template>
      <ng-template let-speaker>{{ speaker.local?.en?.title }}</ng-template>
      <ng-template let-speaker>{{ speaker.local?.en?.shortDescription }}</ng-template>
      <ng-template let-speaker>
        <div class="badges p-d-inline-flex p-flex-wrap">
          <span class="p-tag p-tag-rounded p-mr-1 p-d-inline-flex p-flex-wrap">
            <div class="p-mr-1">
              {{ speaker.type }}
            </div>
          </span>
        </div>
      </ng-template>
    </c-table>
  </div>
</div>
