import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DomainCollection } from '../../../common/entities/configuration/DomainConfiguration';
import { ConfigurationService } from '../../services/configuration/configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { ServiceHooksService } from '../../services/service-hooks/service-hooks.service';
import { PagesService } from '../../services/pages/pages.service';
import { StaticContentsService } from 'src/app/services/static-contents/static-contents.service';

@Component({
  selector: 'c-domain-button[configKey][configKey]',
  templateUrl: './domain-button.component.html',
  styleUrls: ['./domain-button.component.scss'],
})
export class DomainButtonComponent implements OnInit {
  currentButtonLabel = 'All';

  @Input()
  domainCollectionId: string;

  @Input()
  configKey: string;

  @Input()
  disabled = false;

  @Input()
  enableUnification = true;

  @Input()
  shortId?: string = '';

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onDomainCollectionIdChange: EventEmitter<string> = new EventEmitter<string>();

  allDomains: DomainCollection[];
  allConfigs: any[];
  domainOptions: MenuItem[] = [];
  unifyOptions = [];
  working = false;

  showUnifyModal = false;

  constructor(
    private configService: ConfigurationService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private serviceHooksService: ServiceHooksService,
    private pagesService: PagesService,
    private staticContentsService: StaticContentsService
  ) {
    this.allDomains = this.configService.configuration().domain.domainCollections;
  }

  async ngOnInit(): Promise<void> {
    if (this.configKey === 'variables') {
      this.allConfigs = (await this.configService.getVariables(null)).items;
    } else if (this.configKey === 'service_hooks') {
      this.allConfigs = (await this.serviceHooksService.getServiceHooks(null)).items;
    } else if (this.configKey === 'pages') {
      this.allConfigs = (await this.pagesService.getPages(null)).items;
    } else if (this.configKey === 'static_contents') {
      if (this.shortId && this.shortId.length > 2) this.allConfigs = (await this.staticContentsService.getStaticContents(this.shortId)).items;
    } else {
      this.allConfigs = (await this.configService.getConfigurations(null, this.configKey)).items;
    }

    this.domainCollectionId = this.allConfigs.find((cfg) => cfg.domainCollection)?.domainCollection || null;

    this.onDomainCollectionIdChange.emit(this.domainCollectionId);

    this.fillDomainOptions();

    this.initCurrentButtonLabel();
  }

  private initCurrentButtonLabel(domain = null): void {
    this.currentButtonLabel = this.domainCollectionId
      ? (domain || this.allDomains.find((d) => d._id === this.domainCollectionId))?.internalName || this.translateService.instant('GENERAL_UNNAMED')
      : 'All';
  }

  fillDomainOptions(): void {
    if (this.domainCollectionId) {
      this.domainOptions = this.allDomains
        .map((domain) => ({
          label: domain.internalName || this.translateService.instant('GENERAL_UNNAMED'),
          command: () => {
            setTimeout(() => {
              this.initCurrentButtonLabel(domain);
              this.onDomainCollectionIdChange.emit(domain._id);
              this.working = false;
            }, 0);
          },
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      this.domainOptions.push({ separator: true });
      if (this.enableUnification) {
        this.domainOptions.push({
          label: 'Unify Configuration...',
          command: async () => {
            this.showUnifyModal = true;
            this.working = false;
          },
        });
      }

      this.unifyOptions = this.allDomains
        .filter((d) => this.allConfigs.find((c) => c.domainCollection === d._id))
        .map((d) => ({ label: d.internalName || this.translateService.instant('GENERAL_UNNAMED'), value: d._id }));
    } else {
      this.domainOptions = [];
      this.unifyOptions = [];
    }
  }

  unify(domainId: string): void {
    this.configService.unifyConfig(this.configKey, domainId).then((cfgs) => {
      if (cfgs.length) {
        this.allConfigs = cfgs;
        this.domainCollectionId = cfgs[0].domainCollection || null;
        this.initCurrentButtonLabel();
        this.fillDomainOptions();
        this.onDomainCollectionIdChange.emit(cfgs[0].domainCollection);
        this.showUnifyModal = false;
      }
    });
  }

  openSplitDialog(event: Event): void {
    this.confirmationService.confirm({
      key: 'languageButtonPopup',
      target: event.target,
      message: 'Do you really want to split to domain-based config?',
      accept: () => {
        this.configService.splitConfig(this.configKey).then((cfgs) => {
          if (cfgs?.length) {
            this.allConfigs = cfgs;
            this.domainCollectionId = cfgs[0].domainCollection;
            this.initCurrentButtonLabel();
            this.fillDomainOptions();
            this.onDomainCollectionIdChange.emit(cfgs[0].domainCollection);
          }
        });
      },
      reject: () => {
        // Nothing to do
      },
    });
  }
}
