import { TextAttributes } from './../Attributes';
import { PageModule } from './PageModule';
import { SpeakerDisplayType } from './AgendaPageModule';

export const bookmarkTypes = ['sessionbookmark', 'productbookmark'] as const;
export type BookmarkType = typeof bookmarkTypes[number];

export interface BookmarksPageModule extends PageModule {
  pageModuleType: 'Bookmarks';
  bookmarkType?: BookmarkType;
  local: {
    [language: string]: {
      title?: string;
      title_attr?: BookmarksPageModuleAttributes;
    };
  };
  speakerDisplayType?: SpeakerDisplayType;
}

export type BookmarksPageModuleAttributes = TextAttributes & {};

export function isBookmarksPageModule(object: any): object is BookmarksPageModule {
  return object?.pageModuleType === 'Bookmarks';
}
