import { Component, Input } from '@angular/core';
import { Query } from 'src/common/entities/Query';

@Component({
  selector: 'c-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss'],
})
export class QueryComponent {
  @Input()
  query: Query;

  constructor() {}
}
