import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { TableOptions } from 'src/app/components/table/table.interfaces';
import { AdminUsersService } from 'src/app/services/admin-users/admin-users.service';
import { ChatMessageTemplatesService } from 'src/app/services/chatmessagetemplates/chatmessagetemplate.service';
import { ChatsService } from 'src/app/services/chats/chats.service';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';
import { EventsService } from 'src/app/services/events/events.service';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { PollsService } from 'src/app/services/polls/polls.service';
import { UsersService } from 'src/app/services/users/users.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { IFilterableListQuery } from 'src/common/api/interfaces';
import { AdminUser, isAzureADAdminUser } from 'src/common/entities/AdminUser';
import { ChatMessageTemplate } from 'src/common/entities/ChatMessageTemplate';
import { Event } from 'src/common/entities/Event';
import { EventVersionChat } from 'src/common/entities/EventVersion';
import { Message, MessageUser } from 'src/common/entities/Message';
import { Poll, PollOption } from 'src/common/entities/Poll';
import { SpeakerSession } from 'src/common/entities/Session';
import { TagValues } from 'src/common/entities/Tag';
import { ArtificialUser } from 'src/common/entities/User';
import { PollFactory } from 'src/common/factories/PollFactory';
import { InputConfiguration, Inputs } from 'src/common/inputs/Inputs';
import { pollInputs } from 'src/common/inputs/poll/PollInputs';

const expertUser = 'expert';
const fakeUser = 'fake';
const fakeExpertUser = 'fakeExpert';
type SubTab = 'chat' | 'polls';

@Component({
  selector: 'app-live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.scss'],
})
export class LiveChatComponent implements OnInit {
  currentSubTab: SubTab = 'chat';
  userFilters: SelectItem[];
  selectedUserFilter: string = fakeUser;

  filteredSendingUsers: AdminUser[] | ArtificialUser[] = [];
  currentSendingUser: AdminUser | ArtificialUser = null;

  displayUserManagement: boolean = false;
  displayChatMessageTemplateManagement: boolean = false;

  inputConfig: InputConfiguration;

  chatMessage = '';
  quotedMessage: Message;
  rejectedMessage: Message;
  replyMessage: Message;
  chatMessageToAdd = '';

  _experts: AdminUser[] = [];
  _fakeUsers: ArtificialUser[] = [];
  _fakeExperts: ArtificialUser[] = [];

  tagValues: TagValues = {};

  chatMessageTemplates: ChatMessageTemplate[] = [];

  pollsSidebarTab = 'general';
  polls: Poll[] = [];
  showPollDialog: boolean = false;
  pollsInputs: Inputs = pollInputs;
  editablePoll: Poll;
  languages;
  language: string = 'en';
  newPoll: boolean = false;

  chatId: string;
  eventVersionChat: EventVersionChat;
  eventId: string;
  event: Event;
  session: SpeakerSession;

  selectedChatMessageTemplate: ChatMessageTemplate;
  selectedChatPollTemplate: Poll;

  loading: boolean = false;

  chatPollsTableOptions: TableOptions<Poll> = {
    columns: [
      { header: 'GENERAL_INTERNAL_NAME', visible: 'fixed' },
      { header: 'GENERAL_TITLE' },
      { header: 'GENERAL_DESCRIPTION' },
      { header: 'GENERAL_STARTED_AT' },
      { header: 'GENERAL_CLOSED_AT' },
      { header: 'PAGE_LIVE_CHAT_PUBLISHED_RESULT_AT' },
      { header: '' },
      { header: '' },
      { header: '' },
    ],
  };

  constructor(
    private confirmationService: ConfirmationService,
    private userServie: UsersService,
    private adminUsersService: AdminUsersService,
    private chatService: ChatsService,
    private chatMessageTemplateService: ChatMessageTemplatesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private eventService: EventsService,
    private pollsService: PollsService,
    private utilsService: UtilsService,
    private languagesService: LanguagesService,
    private collaborationService: CollaborationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.languages = (await this.languagesService.getLanguages())?.items.filter((i) => i.selectable).map((i) => i.language);
    this.language = this.activatedRoute.snapshot.queryParams.language || 'en';
    this.inputConfig = { languages: this.languages };
    this.userFilters = [
      // {title: await this.utilsService.translate('GENERAL_EXPERT_USER'), value: expertUser},
      { title: await this.utilsService.translate('GENERAL_FAKE_USER'), value: fakeUser },
      { title: await this.utilsService.translate('GENERAL_FAKE_EXPERT_USER'), value: fakeExpertUser },
    ];

    this.eventId = this.activatedRoute.snapshot.parent.params['eventId'];
    this.chatId = this.activatedRoute.snapshot.params['chatId'];

    this.loading = true;
    this.loadEvent();
    this.loadPolls();
    this.loadChatMessageTemplates();
    this.loadUsers();
    this.loading = false;

    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.currentSubTab = queryParams.tab || 'chat';
    });
  }

  back() {
    this.router.navigate(['/live', this.event._id], {});
  }

  async loadChatMessageTemplates() {
    this.chatMessageTemplates = (await this.chatMessageTemplateService.getChatMessageTemplates()).items.filter((item) => item.active);
  }

  async loadPolls() {
    this.polls = (
      await this.pollsService.getPolls({
        filter: {
          event: {
            matchMode: 'equalsObjectId',
            value: this.eventId,
          },
        },
      })
    ).items;
  }

  async loadEvent() {
    this.event = await this.eventService.getEvent(this.eventId);
    this.session = this.event.currentEventVersion.sessions[0] as SpeakerSession;
    this.eventVersionChat = this.event.currentEventVersion.chats.find((c) => c.chat === this.chatId);
  }

  async loadUsers() {
    this._experts = (await this.adminUsersService.getExperts()).items;
    // Only use AdminUser experts with existing user
    this._experts = this._experts.filter((expert) => expert.user);

    const artificialUsers = (
      await this.userServie.getUsers({
        filter: {
          userType: {
            matchMode: 'equals',
            value: 'ArtificialUser',
          },
        },
      })
    ).items;
    this._fakeUsers = artificialUsers as ArtificialUser[];
    this._fakeExperts = this._fakeUsers.filter((user) => user.expert && user.expert == true);
    this._fakeUsers = this._fakeUsers.filter((user) => !user.expert || !user.expert);

    this.changedSelectedUserFilter({ value: this.selectedUserFilter });
  }

  changedSelectedUserFilter(event) {
    // Reset choosen user after changing user type
    this.currentSendingUser = null;
  }

  async changedSendingUser(choosenUser: ArtificialUser | AdminUser) {
    if (isAzureADAdminUser(choosenUser)) {
      const adminUser = await this.adminUsersService.getAdminUser(choosenUser.oid);
      this.currentSendingUser = adminUser;
    } else {
      this.currentSendingUser = choosenUser;
    }
  }

  async filterSendingUser(event) {
    if (this.selectedUserFilter === expertUser) {
      this.filterExperts(event);
    } else if (this.selectedUserFilter === fakeExpertUser || this.selectedUserFilter === fakeUser) {
      this.filterArtificialUsers(event);
    }
  }

  async filterExperts(event) {
    // NOT IN USE TILL YET
    this.filteredSendingUsers = await (
      await this.adminUsersService.getRemoteUsers({
        source: 'Remote',
        filter: {
          displayName: {
            matchMode: 'contains',
            value: event.query,
            caseInsensitive: false,
          },
        },
      })
    ).items;
  }

  async filterArtificialUsers(event) {
    var query: IFilterableListQuery = {};
    query.filter = { ...query.filter };
    // Apply artificial user filter setting
    query.filter['userType'] = {
      matchMode: 'equals',
      value: 'ArtificialUser',
    };
    // Apply users autocomplete filter setting
    if (event.query) {
      query.filter['displayName'] = {
        matchMode: 'contains',
        value: event.query,
        caseInsensitive: false,
      };
    }

    // Get & transform users
    const users = (await this.userServie.getUsers(query)).items;
    const artificialUsers = users as ArtificialUser[];

    // Apply expert filter
    if (this.selectedUserFilter == fakeUser) {
      this.filteredSendingUsers = artificialUsers.filter((user) => user.active && !user.expert);
    } else {
      this.filteredSendingUsers = artificialUsers.filter((user) => user.active && user.expert);
    }
  }

  async sendMessage(): Promise<void> {
    let messageUser: MessageUser | undefined = null;
    let expert: boolean = false;

    if (this.rejectedMessage) {
      await this.chatService.rejectChatMessage(this.chatId, this.rejectedMessage._id, this.chatMessage);
    } else {
      if (isAzureADAdminUser(this.currentSendingUser)) {
        messageUser = {
          _id: this.currentSendingUser._id,
          firstName: this.currentSendingUser.firstName,
          lastName: this.currentSendingUser.lastName,
        };
      } else {
        messageUser = messageUser = {
          _id: this.currentSendingUser._id,
          firstName: null,
          lastName: null,
        };
      }
      if (!messageUser) return;

      if (this.selectedUserFilter == expertUser || this.selectedUserFilter == fakeExpertUser) {
        expert = true;
      }

      await this.chatService.createChatMessage(this.chatId, {
        data: {
          text: this.chatMessage,
        },
        messageType: 'TextMessage',
        replyTo: this.replyMessage ? this.replyMessage._id : null,
        relatedMessage: this.quotedMessage ? this.quotedMessage : null,
        user: messageUser,
        expert: expert,
      });
    }

    this.chatMessage = '';
    this.rejectedMessage = null;
    this.replyMessage = null;
    this.quotedMessage = null;
  }

  createQuote(message: Message) {
    this.replyMessage = null;
    this.rejectedMessage = null;
    this.quotedMessage = message;
  }

  confirmMessage(message: Message) {
    this.loadPolls();
  }

  createReject(message: Message) {
    this.replyMessage = null;
    this.quotedMessage = null;
    this.rejectedMessage = message;
  }

  createReply(message: Message) {
    this.quotedMessage = null;
    this.rejectedMessage = null;
    this.replyMessage = message;
  }

  showUserManagementDialog() {
    this.displayUserManagement = true;
  }

  showChatMessageTemplateDialog() {
    this.chatMessageToAdd = '';
    this.displayChatMessageTemplateManagement = true;
  }
  bookmarkMessageAsTemplate() {
    this.chatMessageToAdd = this.chatMessage;
    this.displayChatMessageTemplateManagement = true;
  }
  selectChatMessageTemplate(event) {
    this.selectedChatMessageTemplate = event.value;
  }
  addChatMessageTemplateToChatMessage() {
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach((textarea) => {
      if (textarea.id === 'message') {
        textarea.setRangeText(this.selectedChatMessageTemplate?.content, textarea.selectionStart, textarea.selectionEnd, 'end');
        this.chatMessage = textarea.value; // Otherwise changes not detected on textarea
      }
    });
  }

  async confirmSendPoll(event, poll: Poll) {
    this.confirmationService.confirm({
      target: event.target,
      message: await this.utilsService.translate('PAGE_EVENT_SECTION_POLLS_SEND_CONFIRM_MESSAGE'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.sendPoll(poll);
      },
      reject: () => {
        // Nothing to do
      },
    });
  }
  async sendPoll(poll: Poll): Promise<void> {
    await this.chatService.createChatPollMessage(this.chatId, {
      data: {
        poll: poll._id,
      },
      messageType: 'PollMessage',
    });
    this.loadPolls();
  }

  async openPollDialog(poll: Poll) {
    this.editablePoll = null;
    this.editablePoll = poll;
    this.collaborationService.registerLocal(`poll:${poll._id}`, poll, true);
    this.showPollDialog = true;
  }
  async closePollDialog() {
    // Replace original data by edited work copy if there are changes
    if (this.newPoll) {
      await this.pollsService.createPoll(this.editablePoll);
    } else {
      await this.pollsService.updatePoll(this.editablePoll);
    }
    // Refresh poll tables data
    this.showPollDialog = false;
    this.editablePoll = null;
    this.loadPolls();
  }

  async addPoll(clonePoll?: Poll) {
    if (!clonePoll) clonePoll = null;
    this.editablePoll = await new PollFactory().createPoll(clonePoll);
    if (!clonePoll) {
      // Prepare local properties for current language
      this.editablePoll.local[this.language] = new PollFactory().createPollLocal();
    } else {
      this.editablePoll.internalName += ' (clone)';
    }
    this.editablePoll.event = this.eventId;
    this.newPoll = true;
    this.openPollDialog(this.editablePoll);
  }
  async confirmDeletePoll(event, poll: Poll) {
    this.confirmationService.confirm({
      target: event.target,
      message: await this.utilsService.translate('PAGE_EVENT_SECTION_POLLS_DELETE_CONFIRM_MESSAGE'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deletePoll(poll);
      },
      reject: () => {
        // Nothing to do
      },
    });
  }
  async deletePoll(poll: Poll) {
    // !!! Don't implement without poll in use validation !!!
  }

  trackByPollOption(index, pollOption: PollOption) {
    return pollOption._id;
  }

  showPollResult(poll: Poll) {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/polls/${poll._id}`]));
    window.open(url, '_blank');
  }

  countRows(stringContent: string, minRows): number {
    return Math.max((stringContent?.match(/\n/g) || []).length, minRows) + 1;
  }
}
