import { Component, Input, OnInit } from '@angular/core';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { TrendingTopicsPageModule } from 'src/common/entities/pagemodules/TrendingTopicsPageModule';
import { Product } from 'src/common/entities/Product';
import { InputConfiguration } from 'src/common/inputs/Inputs';

@Component({
  selector: 'app-trendingtopics-page-module-edit',
  templateUrl: './trendingtopics-page-module-edit.component.html',
  styleUrls: ['./trendingtopics-page-module-edit.component.scss']
})
export class TrendingtopicsPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: TrendingTopicsPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathesGeneral: string[] = ['$.local.$language.title', '$.titlePosition', '$.ctaButton', '$.anchor'];

  inputJsonpathesTopics: string[] = ['$.trendingTopicsType', '$.local.$language.topics'];

  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion
      };
    }
  }
}
