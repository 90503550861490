import { InputConfiguration } from 'src/common/inputs/Inputs';
import { ImageBannerPageModule } from './../../../../common/entities/pagemodules/ImageBannerPageModule';
import { Page } from './../../../../common/entities/Page';
import { EventVersion } from './../../../../common/entities/EventVersion';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-banner-page-module-edit',
  templateUrl: './image-banner-page-module-edit.component.html',
  styleUrls: ['./image-banner-page-module-edit.component.scss'],
})
export class ImageBannerPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: ImageBannerPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  tab = 'general';

  inputJsonpathes: string[] = ['$.link', '$.width', '$.anchor'];

  mobileJsonpathes: string[] = ['$.local.$language.imageAssetBreakpoint.mobile', '$.local.$language.imageAssetBreakpoint.mobileLarge'];

  tabletJsonpathes: string[] = ['$.local.$language.imageAssetBreakpoint.tablet', '$.local.$language.imageAssetBreakpoint.tabletLarge'];

  desktopJsonpathes: string[] = ['$.local.$language.imageAssetBreakpoint.desktop', '$.local.$language.imageAssetBreakpoint.desktopLarge', '$.local.$language.imageAssetBreakpoint.desktopWidescreen'];

  accessInputConfiguration: InputConfiguration;

  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
  }
}
