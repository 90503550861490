<div class="sidebar-tabs">
  <button [class.active]="tab === 'general'" (click)="tab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
  <button [class.active]="tab === 'teasers'" (click)="tab = 'teasers'">{{ 'GENERAL_TEASERS' | translate }}</button>
</div>

<div *ngIf="tab == 'general'" class="sidebar-body">
  <c-page-patch-input *ngFor="let inputJsonpath of inputJsonpathes" [jsonpath]="[jsonpath, inputJsonpath]" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'teasers'" class="sidebar-body">
  <div *ngIf="currentLanguage && eventStage.local[currentLanguage]">
    <div *ngFor="let item of eventStage.local[currentLanguage].items; let i = index; trackBy: trackByIndex">
      <div *ngIf="item.teaserItemType == 'Highlight'">
        <c-page-patch-input
          [jsonpath]="[jsonpath, itemJsonpath, '$.session']"
          [jsonpathParams]="itemJsonpathParams(item)"
          [object]="eventVersion || page"
          [inputConfiguration]="{ type: 'dropdown', dropdownOptions: sessions }"
        ></c-page-patch-input>
      </div>

      <div *ngIf="item.teaserItemType == 'Link'">
        <c-page-patch-input [jsonpath]="[jsonpath, itemJsonpath, '$.image']" [jsonpathParams]="itemJsonpathParams(item)" [object]="eventVersion || page"></c-page-patch-input>
        <c-page-patch-input [jsonpath]="[jsonpath, itemJsonpath, '$.title']" [jsonpathParams]="itemJsonpathParams(item)" [object]="eventVersion || page"></c-page-patch-input>
        <c-page-patch-input [jsonpath]="[jsonpath, itemJsonpath, '$.link']" [jsonpathParams]="itemJsonpathParams(item)" [object]="eventVersion || page"></c-page-patch-input>
      </div>

      <div *ngIf="item.teaserItemType == 'CTA'">
        <c-page-patch-input [jsonpath]="[jsonpath, itemJsonpath, '$.image']" [jsonpathParams]="itemJsonpathParams(item)" [object]="eventVersion || page"></c-page-patch-input>
        <c-page-patch-input [jsonpath]="[jsonpath, itemJsonpath, '$.title']" [jsonpathParams]="itemJsonpathParams(item)" [object]="eventVersion || page"></c-page-patch-input>
        <c-page-patch-input
          [jsonpath]="[jsonpath, itemJsonpath, '$.cta']"
          [jsonpathParams]="itemJsonpathParams(item)"
          [object]="eventVersion || page"
          [inputConfiguration]="{
            type: 'ctabutton',
            options: {
              types: ['meetyourexpert', 'registerplatform', 'registerevent', 'videoasset', 'externalevent']
            }
          }"
        ></c-page-patch-input>
      </div>

      <div class="fieldset-buttons p-d-flex">
        <!-- <button *ngIf="!first" pButton type="button" class="p-button-sm p-button-outlined p-mr-2" icon="pi pi-arrow-up"
          (click)="up(index)" 
          [disabled]="isDisabled()" 
        ></button>
        <button *ngIf="!last" pButton type="button" class="p-button-sm p-button-outlined" icon="pi pi-arrow-down" 
          (click)="down(index)" 
          [disabled]="isDisabled()" 
        ></button> -->
        <div class="p-ml-auto"></div>
        <button
          pButton
          type="button"
          class="p-button-sm p-button-outlined"
          [label]="'GENERAL_DELETE' | translate"
          icon="pi pi-trash"
          (click)="deleteTeaserItem(item)"
          [disabled]="isDisabled()"
        ></button>
      </div>
    </div>

    <div class="fieldset-last-button p-d-flex">
      <p-dropdown [options]="teaserItemTypes" [(ngModel)]="newTeaserItemType" style="width: 100%" class="p-mr-2"></p-dropdown>
      <div class="p-ml-auto"></div>
      <button
        pButton
        type="button"
        class="p-button-sm p-button-outlined"
        [label]="('GENERAL_ADD' | translate) + ' Teaser Item'"
        icon="pi icon-ic-add-item"
        style="min-width: max-content"
        (click)="addTeaserItem()"
        [disabled]="isDisabled()"
      ></button>
    </div>
  </div>
</div>
