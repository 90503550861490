<span *ngIf="andCondition" class="and-condition">
  <span *ngFor="let cond of andCondition.conditions; let last = last">
    <c-condition [condition]="cond"></c-condition>
    <span *ngIf="!last" class="and">and</span>
  </span>
</span>

<span *ngIf="orCondition" class="or-condition">
  <span *ngFor="let cond of orCondition.conditions; let last = last">
    <c-condition [condition]="cond"></c-condition>
    <span *ngIf="!last" class="or">or</span>
  </span>
</span>

<span *ngIf="equalCondition" class="equal-condition">
  <span class="field">{{ equalCondition.field }}</span>
  <span class="equal">=</span>
  <span class="value">{{ equalCondition.value }}</span>
</span>

<span *ngIf="notEqualCondition" class="notequal-condition">
  <span class="field">{{ notEqualCondition.field }}</span>
  <span class="notequal">!=</span>
  <span class="value">{{ notEqualCondition.value }}</span>
</span>

<span *ngIf="endsWithCondition" class="notequal-condition">
  <span class="field">{{ endsWithCondition.field }}</span>
  <span class="notequal">ends with</span>
  <span class="value">{{ endsWithCondition.value }}</span>
</span>

<span *ngIf="startsWithCondition" class="notequal-condition">
  <span class="field">{{ startsWithCondition.field }}</span>
  <span class="notequal">starts with</span>
  <span class="value">{{ startsWithCondition.value }}</span>
</span>

<span *ngIf="containsCondition" class="notequal-condition">
  <span class="field">{{ containsCondition.field }}</span>
  <span class="notequal">contains</span>
  <span class="value">{{ containsCondition.value }}</span>
</span>
