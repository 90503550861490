import { Component, Input, OnInit } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { AgendaPageModule } from 'src/common/entities/pagemodules/AgendaPageModule';

import { InputConfiguration } from 'src/common/inputs/Inputs';

@Component({
  selector: 'c-agenda-page-module-edit',
  templateUrl: './agenda-page-module-edit.component.html',
  styleUrls: ['./agenda-page-module-edit.component.scss'],
})
export class AgendaPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: AgendaPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathes: string[] = [
    '$.local.$language.title',
    '$.titlePosition',
    '$.event',
    '$.viewType',
    '$.showFilterOptions',
    '$.showAgendaCalendarButton',
    '$.showAgendaShareButton',
    '$.agendaShareButtonTargets',
    '$.showCurrentTime',
    '$.local.$language.preCtaText',
    '$.ctaButton',
    '$.anchor',
  ];

  sessionsJsonpathes: string[] = [
    '$.maxSessionCount',
    '$.speakerDisplayType',
    '$.showTime',
    '$.showThumbnail',
    '$.showCalendarButton',
    '$.showShareButton',
    '$.shareButtonTargets',
    '$.showBookmarkButton',
    '$.indicateCurrentSession',
    '$.indicateLiveSessions',
    '$.indicatePastSessions',
    '$.showDescription',
    '$.showMapButton',
  ];

  inputConfigurationHighlightSessions: InputConfiguration = {};

  initialized: boolean = false;
  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  async ngOnInit() {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };

      this.inputConfigurationHighlightSessions = {
        type: 'dropdown',
        dropdownOptions: [{ label: '', value: null }].concat(
          this.eventVersion.sessions
            .filter((s) => s.highlight)
            .map((s) => {
              const title = s.local['en']?.title || s.local['de']?.title || (Object.keys(s.local).length > 0 ? s.local[Object.keys(s.local)[0]] : '');
              return {
                label: `${new Date(s.startAt).toLocaleDateString()} ${new Date(s.startAt).toLocaleTimeString()} - ${new Date(s.endAt).toLocaleDateString()} ${new Date(
                  s.endAt
                ).toLocaleTimeString()} | ${title}`,
                value: s._id,
              };
            })
        ),
      };
    }
    this.initialized = true;
  }
}
