import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/events/events.service';
import { take } from 'rxjs/operators';
import { Event } from 'src/common/entities/Event';
import { StreamStatus, StreamWithSessions } from '../../../../common/entities/Stream';
import { StreamsService } from '../../../services/streams/streams.service';
import { AssetsService } from '../../../services/assets/assets.service';
import { VideoAsset } from '../../../../common/entities/Asset';
import { GetStreamVodAssetSessionsResponse } from 'src/common/api/v1/streams/GetStreamVodAssetSessions';
import { Router } from '@angular/router';

type VODStatus = 'no-vod' | 'in-progress' | 'available';

@Component({
  selector: 'app-live-stream-post-production',
  templateUrl: './live-stream-post-production.component.html',
  styleUrls: ['./live-stream-post-production.component.scss'],
})
export class LiveStreamPostProductionComponent implements OnInit {
  @Input() eventId: string;
  @Input() streamId: string;

  showStreamDetails = false;
  loading = true;

  event: Event;
  stream: StreamWithSessions;
  relatedSessions: GetStreamVodAssetSessionsResponse['sessions'] = [];
  relatedSessionAssets: Record<string, VideoAsset> = {};
  vodAsset: VideoAsset | null = null;

  get showExportButton() {
    return !this.vodAsset.editings?.length;
  }

  get isEditingProcessFinished() {
    return !this.showExportButton && this.vodAsset.editings[this.vodAsset.editings.length - 1].status === 'FINISHED';
  }

  get showEditingInProgress() {
    return !this.showExportButton && this.vodAsset.editings[this.vodAsset.editings.length - 1].status !== 'FINISHED';
  }

  get lastEditing() {
    return this.vodAsset.editings?.[this.vodAsset.editings?.length - 1];
  }

  constructor(private eventsService: EventsService, private streamsService: StreamsService, private assetsService: AssetsService, private router: Router) {}

  ngOnInit() {
    this.fetchData();
  }

  public async fetchData() {
    this.loading = true;
    const requests = await Promise.all([this.eventsService.getEvent(this.eventId), this.streamsService.getStream(this.streamId)]);

    this.event = requests[0];
    this.stream = requests[1];

    await this.fetchRelatedSessions();
    await this.fetchVODAsset();

    this.loading = false;
  }

  // executeStreamOperation is passed as a callback, keep it as arrow function to keep `this` context
  executeStreamOperation = async (streamPromise: Promise<StreamWithSessions>) => {
    this.loading = true;
    this.stream = await streamPromise;
    await this.fetchRelatedSessions();
    await this.fetchVODAsset();
    this.loading = false;
  };

  openStreamDetails() {
    this.showStreamDetails = true;
  }

  async createVOD() {
    this.loading = true;
    this.stream = await this.streamsService.createVOD(this.streamId);
    await this.fetchVODAsset();
    this.loading = false;
  }

  async fetchRelatedSessions() {
    if (this.stream.vodAsset) {
      const { sessions } = await this.streamsService.vodAssetSessions(this.streamId);
      this.relatedSessions = sessions;
      this.relatedSessions.sort((a, b) => new Date(a.startAt).getTime() - new Date(b.startAt).getTime());
      this.relatedSessionAssets = {};
      await Promise.all(
        sessions.map(async (session) => {
          const { currentVodAsset, latestEditedVodAsset } = session;
          if (currentVodAsset && !(currentVodAsset in this.relatedSessionAssets)) {
            this.relatedSessionAssets[currentVodAsset] = (await this.assetsService.getAsset(currentVodAsset, true).pipe(take(1)).toPromise()) as VideoAsset;
          }

          if (latestEditedVodAsset && !(latestEditedVodAsset in this.relatedSessionAssets)) {
            this.relatedSessionAssets[latestEditedVodAsset] = (await this.assetsService.getAsset(latestEditedVodAsset, true).pipe(take(1)).toPromise()) as VideoAsset;
          }
        })
      );
    }
  }

  async fetchVODAsset() {
    if (typeof this.stream.vodAsset === 'string') {
      this.vodAsset = (await this.assetsService.getAsset(this.stream.vodAsset, true).pipe(take(1)).toPromise()) as VideoAsset;
    } else {
      this.vodAsset = this.stream.vodAsset as VideoAsset;
    }
  }

  setVodAsset(id: string) {
    this.executeStreamOperation(this.streamsService.updateStream(this.streamId, { vodAsset: id }));
  }

  getSessionCutStatus(sessionId: string) {
    const editings = this.vodAsset.editings;
    if (!editings) return undefined;
    return editings[editings.length - 1].cuts.find((c) => c.session === sessionId).status;
  }

  getSessionCutProgress(sessionId: string) {
    const editings = this.vodAsset.editings;
    if (!editings) return 0;
    return editings[editings.length - 1].cuts.find((c) => c.session === sessionId)?.progress;
  }

  isAllowedToCreateVOD() {
    return this.stream.status === 'STOPPED';
  }

  showStep2() {
    // anything after STOPPED
    const allowedStatuses: StreamStatus[] = ['STOPPED', 'DELETED', 'DEACTIVATED'];
    return allowedStatuses.includes(this.stream.status);
  }

  showStep3And4() {
    return this.getVODStatus() === 'available' && this.showStep2();
  }

  async exportToEditor() {
    this.loading = true;
    await this.streamsService.exportVodAsset(this.streamId);
    await this.fetchVODAsset();
    this.loading = false;
  }

  async publishSessionVOD(session: GetStreamVodAssetSessionsResponse['sessions'][number]) {
    this.loading = true;
    await this.eventsService.publishSessionVOD(this.eventId, session._id, session.latestEditedVodAsset, session.language);
    await this.fetchRelatedSessions();
    this.loading = false;
  }

  openSessionCutInEditor(session: GetStreamVodAssetSessionsResponse['sessions'][number]) {
    window.open(session.externalEditorDeeplink, '_blank');
  }

  openAssetView(assetId: string) {
    this.router.navigate(['/assets', assetId]);
  }

  getVODStatus(): VODStatus {
    if (!this.vodAsset) {
      return 'no-vod';
    }

    if (this.vodAsset.status !== 'FINISHED') {
      return 'in-progress';
    }

    return 'available';
  }

  isPublishSessionVODDisabled(session: GetStreamVodAssetSessionsResponse['sessions'][number]) {
    const currentVODAsset = this.relatedSessionAssets[session.currentVodAsset];
    const latestEditedVODAsset = this.relatedSessionAssets[session.latestEditedVodAsset];
    return this.loading || currentVODAsset?._id === latestEditedVODAsset?._id || latestEditedVODAsset?.status !== 'FINISHED';
  }
}
