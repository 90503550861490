<div class="content">
  <h1>
    {{ 'GENERAL_EVENT_REGIONS' | translate }}
    <button pButton type="button" class="p-button" [routerLink]="['./new']">{{ 'GENERAL_NEW' | translate }}&nbsp;{{ 'GENERAL_EVENT_REGION' | translate }}</button>
  </h1>
  <div>
    <c-skeleton *ngIf="!regions" [fields]="['table']"></c-skeleton>
    <p-table [value]="regions">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'PAGE_REGION_TAG' | translate }}</th>
          <th>{{ 'PAGE_REGION_NAME' | translate }}</th>
          <th>{{ 'PAGE_REGION_LANGUAGE' | translate }}</th>
          <th>{{ 'PAGE_REGION_TIMEZONE' | translate }}</th>
          <th>{{ 'GENERAL_ACTIVE' | translate }}</th>
          <th>{{ 'PAGE_REGION_SORT' | translate }}</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-region>
        <tr>
          <td>{{ region.region }}</td>
          <td>{{ region.regionName }}</td>
          <td>{{ (region.language | language | async)?.languageName }}</td>
          <td>{{ region.timeZone }}</td>
          <td>
            <p-checkbox [ngModel]="region.active ? region.active : false" [binary]="true" [disabled]="true"></p-checkbox>
          </td>
          <td>{{ region.sort }}</td>
          <td class="text-right">
            <a [routerLink]="[region.region]">{{ 'GENERAL_DETAILS' | translate }}</a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
