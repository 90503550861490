<div *ngIf="!displayAppointmentDetail">
  <div class="tab-header">
    <h1>
      <span>{{ 'GENERAL_APPOINTMENTS' | translate }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <p-button *hasRight="'appointments.start'" icon="pi icon-ic-start" [label]="'PAGE_APPOINTMENTS_START_ADHOC_APPOINTMENT' | translate" (onClick)="startAdhocAppointment()"></p-button>
    <p-button *hasRight="'appointments.create'" icon="pi icon-ic-add-item" [label]="'PAGE_APPOINTMENTS_NEW_APPOINTMENT' | translate" [routerLink]="['./new']"></p-button>
  </div>
</div>

<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_APPOINTMENTS_HEADER_OPEN_APPOINTMENTS' | translate }}
  </div>
  <div class="panel-body">
    <app-appointments-table group="open"></app-appointments-table>
  </div>
</div>

<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_APPOINTMENTS_HEADER_CLOSED_APPOINTMENTS' | translate }}
  </div>
  <div class="panel-body">
    <app-appointments-table group="closed"></app-appointments-table>
  </div>
</div>

<!--   
  <div class="panel">
    <div  class="panel-body">
      <app-appointments-table group="open" header="PAGE_APPOINTMENTS_HEADER_OPEN_APPOINTMENTS"></app-appointments-table>
      <app-appointments-table group="closed" header="PAGE_APPOINTMENTS_HEADER_CLOSED_APPOINTMENTS"></app-appointments-table>
    </div>
  </div>
</div>
 -->

<p-dialog
  *hasRight="['appointments.read', 'appointments.edit']"
  [showHeader]="false"
  [(visible)]="displayAppointmentDetail"
  (onShow)="showDialogMaximized($event, appointmentDetail)"
  #appointmentDetail
>
  <app-appointment-detail *ngIf="displayAppointmentDetail" [adhocAppointment]="true" [dialog]="appointmentDetail"></app-appointment-detail>
</p-dialog>
