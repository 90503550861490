import { TextAttributes } from './../Attributes';
import { BreakpointValue } from './../Breakpoint';
import { CTAButton } from './../CTAButton';
import { PageModule } from './PageModule';

export const iconTypes = ['circle', 'no_adjustment'] as const;
export type IconType = (typeof iconTypes)[number];

export interface TitleEventStagePageModuleLocal {
  title: string;
  title_attr?: TitleEventStagePageModuleAttributes;
  icon: string;
  background: string;
}

export type TitleEventStagePageModuleAttributes = TextAttributes & {};

export interface TitleEventStagePageModule extends PageModule {
  pageModuleType: 'TitleEventStage';
  showLiveButton: boolean;
  ctalist: TitleEventStageButton[];
  ctalistInteraction: TitleEventStageButton[];
  local: { [language: string]: TitleEventStagePageModuleLocal };
  ctaAreaHeight?: BreakpointValue<number>;
  contentAreaHeight?: BreakpointValue<number>;
  topAreaHeight?: BreakpointValue<number>;
  iconStyle?: IconType;
}

export interface TitleEventStageButton {
  _id: string;
  ctaButton: CTAButton;
}

export interface TitleEventStageButton {
  _id: string;
  ctaButton: CTAButton;
}

export function isTitleEventStagePageModule(object: any): object is TitleEventStagePageModule {
  return object?.pageModuleType === 'TitleEventStage';
}
