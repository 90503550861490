import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HasRightGuard } from './guards/has-right.guard';

export const routes: Routes = [
  { path: '', redirectTo: '/events', pathMatch: 'full' },
  {
    path: 'polls/:pollId',
    canActivate: [HasRightGuard],
    data: {
      hasRight: {
        resource: 'polls:$pollsId',
        rights: ['polls.list', 'polls.edit'],
      },
    },
    loadChildren: () => import('./pages/poll/poll.module').then((m) => m.PollModule),
  },
  // {
  //   path: 'dashboard',
  //   canActivate: [HasRightGuard],
  //   data: {
  //     hasRight: [],
  //   },
  //   loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
  // },
  {
    path: 'live/:eventId',
    canActivate: [HasRightGuard],
    data: {
      hasRight: [
        'eventsessions.list',
        'eventsessions.create',
        'eventsessions.read',
        'eventsessions.edit',
        'eventsessions.delete',
        'eventregistrations.list',
        'eventregistrations.create',
        'eventregistrations.read',
        'eventregistrations.edit',
        'eventregistrations.delete',
        'eventregistrations.download',
        'eventticketslots.list',
        'eventticketslots.create',
        'eventticketslots.read',
        'eventticketslots.edit',
        'eventticketslots.delete',
      ],
    },
    loadChildren: () => import('./pages/live/live.module').then((m) => m.LiveModule),
  },
  {
    path: 'events',
    canActivate: [HasRightGuard],
    data: {
      hasRight: [
        '*$events.read',
        '*$events.edit',
        '*$events.editContent',
        '*$events.list',
        '*$eventregistrations.list',
        '*$eventregistrations.read',
        '*$eventregistrations.edit',
        '*$eventregistrations.create',
        '*$eventregistrations.delete',
        '*$eventticketslots.list',
        '*$eventticketslots.read',
        '*$eventticketslots.edit',
        '*$live.read',
        '*$live.manage',
      ],
    },
    loadChildren: () => import('./pages/events/events.module').then((m) => m.EventsModule),
  },
  {
    path: 'pages',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$pages.list'],
    },
    loadChildren: () => import('./pages/pages-configuration/pages-configuration.module').then((m) => m.PagesConfigurationModule),
  },
  {
    path: 'pages/:pageId',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$pages.read', '*$pages.edit'],
    },
    loadChildren: () => import('./pages/page-configuration/page-configuration.module').then((m) => m.PageConfigurationModule),
  },
  {
    path: 'embedded-pages',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$pages.list'],
    },
    loadChildren: () => import('./pages/embedded-pages/embedded-pages.module').then((m) => m.EmbeddedPagesModule),
  },
  {
    path: 'embedded-pages/:pageId',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$pages.read', '*$pages.edit'],
    },
    loadChildren: () => import('./pages/page-configuration/page-configuration.module').then((m) => m.PageConfigurationModule),
  },
  {
    path: 'events/:eventId',
    canActivate: [HasRightGuard],
    data: {
      hasRight: {
        resource: 'events:$eventId',
        rights: ['*$events.read', '*$events.edit', '*$events.editContent'],
      },
    },
    loadChildren: () => import('./pages/event/event.module').then((m) => m.EventModule),
  },
  {
    path: 'templates',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$templates.edit', '*$templates.list', '*$templates.read'],
    },
    loadChildren: () => import('./pages/events/events.module').then((m) => m.EventsModule),
  },
  {
    path: 'event-series',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$eventseries.list', '*$eventseries.create', '*$eventseries.read', '*$eventseries.edit'],
    },
    loadChildren: () => import('./pages/event-series/event-series.module').then((m) => m.EventSeriesModule),
  },
  {
    path: 'event-series/:eventSerieId',
    canActivate: [HasRightGuard],
    data: {
      hasRight: {
        resource: 'eventseries:$eventSerieId',
        rights: ['*$eventseries.create', '*$eventseries.read', '*$eventseries.edit'],
      },
    },
    loadChildren: () => import('./pages/event-serie-edit/event-serie-edit.module').then((m) => m.EventSerieEditModule),
  },
  {
    path: 'templates/:eventId',
    canActivate: [HasRightGuard],
    data: {
      hasRight: {
        resource: 'templates:$eventId',
        rights: ['*$templates.edit', '*$templates.read'],
      },
    },
    loadChildren: () => import('./pages/event/event.module').then((m) => m.EventModule),
  },
  {
    path: 'streams',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$streams.list'],
    },
    loadChildren: () => import('./pages/streams/streams.module').then((m) => m.StreamsModule),
  },
  {
    path: 'keytopics',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$keytopics.list'],
    },
    loadChildren: () => import('./pages/keytopics/keytopics.module').then((m) => m.KeytopicsModule),
  },
  {
    path: 'keytopics/:keytopicId',
    canActivate: [HasRightGuard],
    data: {
      hasRight: {
        resource: 'keytopics:$keytopicId',
        rights: ['*$keytopics.edit', '*$keytopics.read'],
      },
    },
    loadChildren: () => import('./pages/keytopic/keytopic.module').then((m) => m.KeytopicModule),
  },
  {
    path: 'taxonomy',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$tags.manage'],
    },
    loadChildren: () => import('./pages/taxonomy/taxonomy.module').then((m) => m.TaxonomyModule),
  },
  {
    path: 'speakers',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$people.list'],
    },
    loadChildren: () => import('./pages/speakers/speakers.module').then((m) => m.SpeakersModule),
  },
  {
    path: 'speakers/:speakerId',
    canActivate: [HasRightGuard],
    data: {
      hasRight: {
        resource: 'people:$speakerId',
        rights: ['*$people.edit', '*$people.read'],
      },
    },
    loadChildren: () => import('./pages/speaker/speaker.module').then((m) => m.SpeakerModule),
  },
  {
    path: 'assets',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$assets.list'],
    },
    loadChildren: () => import('./pages/assets/assets.module').then((m) => m.AssetsModule),
  },
  {
    path: 'assets/:assetId',
    canActivate: [HasRightGuard],
    data: {
      hasRight: {
        resource: 'assets:$assetId',
        rights: ['assets.read', 'assets.edit'],
      },
    },
    loadChildren: () => import('./pages/asset-edit/asset-edit.module').then((m) => m.AssetEditModule),
  },
  {
    path: 'asset-series',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$assets.list'],
    },
    loadChildren: () => import('./pages/asset-series/asset-series.module').then((m) => m.AssetSeriesModule),
  },
  {
    path: 'asset-series/:assetId',
    canActivate: [HasRightGuard],
    data: {
      hasRight: {
        resource: 'asset-series:$assetId',
        rights: ['*$assets.edit', '*$assets.read'],
      },
    },
    loadChildren: () => import('./pages/asset-series/asset-series.module').then((m) => m.AssetSeriesModule),
  },
  {
    path: 'users',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$users.list'],
    },
    loadChildren: () => import('./pages/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'users/:userId',
    canActivate: [HasRightGuard],
    data: {
      hasRight: {
        resource: 'users:$userId',
        rights: ['*$users.read', '*$users.edit'],
      },
    },
    loadChildren: () => import('./pages/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'configuration',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$configuration.list', '*$configuration.read', '*$pages.list'],
    },
    loadChildren: () => import('./pages/configuration/configuration.module').then((m) => m.ConfigurationModule),
  },
  {
    path: 'appointments',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$appointments.list'],
    },
    loadChildren: () => import('./pages/appointments/appointments.module').then((m) => m.AppointmentsModule),
  },
  {
    path: 'appointments/:appointmentId',
    canActivate: [HasRightGuard],
    data: {
      hasRight: {
        resource: 'appointments:$appointmentId',
        rights: ['*$appointments.read', '*$appointments.edit'],
      },
    },
    loadChildren: () => import('./pages/appointment/appointment.module').then((m) => m.AppointmentModule),
  },
  {
    path: 'exports',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$exports.list', '*$exports.read'],
    },
    loadChildren: () => import('./pages/exports/exports.module').then((m) => m.ExportsModule),
  },
  {
    path: 'forms',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$data.misc.manage'],
    },
    loadChildren: () => import('./pages/forms/forms.module').then((m) => m.FormsModule),
  },
  {
    path: 'forms/:id',
    canActivate: [HasRightGuard],
    data: {
      hasRight: {
        resource: 'forms:$formsId',
        rights: ['*$data.misc.manage'],
      },
    },
    loadChildren: () => import('./pages/form/form.module').then((m) => m.FormModule),
  },
  {
    path: 'maps',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$data.misc.manage'],
    },
    loadChildren: () => import('./pages/maps/maps.module').then((m) => m.MapsModule),
  },
  {
    path: 'maps/:id',
    canActivate: [HasRightGuard],
    data: {
      hasRight: {
        resource: 'maps:$mapsId',
        rights: ['*$data.misc.manage'],
      },
    },
    loadChildren: () => import('./pages/map/map.module').then((m) => m.MapModule),
  },
  {
    path: 'products',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$products.list'],
    },
    loadChildren: () => import('./pages/products/products.module').then((m) => m.ProductsModule),
  },
  {
    path: 'global-tickets',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$globaltickets.list'],
    },
    loadChildren: () => import('./pages/global-tickets/global-tickets.module').then((m) => m.GlobalTicketsModule),
  },
  {
    path: 'crm-status',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$data.misc.manage'],
    },
    loadChildren: () => import('./pages/crm-status/crm-status.module').then((m) => m.CRMStatusModule),
  },
  {
    path: 'logs',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$data.misc.manage'],
    },
    loadChildren: () => import('./pages/logs/logs.module').then((m) => m.LogsModule),
  },
  {
    path: 'qr-codes',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$data.misc.manage'],
    },
    loadChildren: () => import('./pages/qr-codes/qr-codes.module').then((m) => m.QRCodesModule),
  },
  {
    path: 'onboardings',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$data.misc.manage'],
    },
    loadChildren: () => import('./pages/onboardings/onboardings.module').then((m) => m.OnboardingsModule),
  },
  {
    path: 'csp-reports',
    canActivate: [HasRightGuard],
    data: {
      hasRight: ['*$data.misc.manage'],
    },
    loadChildren: () => import('./pages/csp-reports/csp-reports.module').then((m) => m.CSPReportsModule),
  },
  {
    path: 'error-unauthorized',
    loadChildren: () => import('./pages/error-unauthorized/error-unauthorized.module').then((m) => m.ErrorUnauthorizedModule),
  },
  { path: '**', loadChildren: () => import('./pages/error/error.module').then((m) => m.ErrorModule) },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: isIframe ? 'disabled' : 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
