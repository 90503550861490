<div class="content">
  <h1>
    {{ 'GENERAL_EVENT_REGION' | translate }}
    <span class="subheader" *ngIf="loading">{{ 'GENERAL_LOADING' | translate }} ...</span>
    <span class="subheader" *ngIf="!loading && regionId === 'new'">{{ 'GENERAL_NEW' | translate }}</span>
    <span class="subheader" *ngIf="!loading && regionId !== 'new'">{{ region.regionName }}</span>
    <button pButton type="button" [label]="' ' + ('GENERAL_SAVE' | translate)" icon="pi pi-save" class="p-button" [disabled]="loading || saving || !isValid()" (click)="save()"></button>
    <!-- <button pButton type="button" [label]="' ' + ('GENERAL_DELETE' | translate)" icon="pi pi-trash" class="p-button-text p-button-danger" [disabled]="loading || saving || regionId === 'new'" (click)="delete()"></button> -->
  </h1>

  <p-card [header]="'GENERAL_GLOBAL_PROPERTIES' | translate">
    <c-skeleton *ngIf="!region" [fields]="['text', 'text', 'text', 'text']"></c-skeleton>
    <div *ngIf="region">
      <c-field [error]="errorAt('region')" header="PAGE_REGION_TAG" description="PAGE_REGION_TAG_DESCRIPTION">
        <input type="text" pInputText name="region" [(ngModel)]="region.region" [readOnly]="regionId !== 'new'" />
      </c-field>
      <c-field [error]="errorAt('regionName')" header="PAGE_REGION_NAME" description="PAGE_REGION_NAME_DESCRIPTION">
        <input type="text" pInputText name="regionName" [(ngModel)]="region.regionName" />
      </c-field>
      <c-field [error]="errorAt('language')" header="PAGE_REGION_LANGUAGE" description="PAGE_REGION_LANGUAGE_DESCRIPTION">
        <p-dropdown [options]="languages" optionLabel="languageName" [(ngModel)]="selectedLanguage" (onChange)="changedLanguage($event)"></p-dropdown>
      </c-field>
      <c-field [error]="errorAt('timeZone')" header="PAGE_REGION_TIMEZONE" description="PAGE_REGION_TIMEZONE_DESCRIPTION">
        <p-autoComplete
          [(ngModel)]="currentTimeZone"
          (onSelect)="selectTimeZone($event)"
          (onClear)="clearTimeZone($event)"
          [forceSelection]="true"
          [suggestions]="filteredTimeZones"
          [dropdown]="true"
          dropdownMode="current"
          field="name"
          (completeMethod)="filterTimeZones($event)"
        >
        </p-autoComplete>
        {{ region.timeZone }}
      </c-field>
      <c-field [error]="errorAt('active')" header="GENERAL_ACTIVE" description="PAGE_REGION_ACTIVE_DESCRIPTION">
        <p-inputSwitch [(ngModel)]="region.active"></p-inputSwitch>
      </c-field>
      <c-field [error]="errorAt('sort')" header="PAGE_REGION_SORT" description="PAGE_REGION_SORT_DESCRIPTION">
        <input type="number" pInputText name="sort" [(ngModel)]="region.sort" />
      </c-field>
      <c-field [error]="errorAt('icon')" header="PAGE_REGION_ICON" description="PAGE_REGION_ICON_DESCRIPTION">
        <c-asset-select [(id)]="region.icon" [mimeType]="'image/svg+xml'"></c-asset-select>
      </c-field>
    </div>
  </p-card>
</div>
