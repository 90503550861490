import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { Group } from './../../../common/entities/Group';
import { Role } from './../../../common/entities/Role';
import { AdminUsersService } from './../../services/admin-users/admin-users.service';
import { ConfigurationService } from './../../services/configuration/configuration.service';
import { AdminUser, AdminUserType } from 'src/common/entities/AdminUser';
import { AdminUserFactory } from 'src/common/factories/AdminUserFactory';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';
import { generate } from 'generate-password-ts';
import copy from 'copy-to-clipboard';

interface UserImport {
  adminUserType: AdminUserType;
  identifier: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  groups: Group[];
  roles: Role[];
}

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.scss'],
})
export class AdminUserComponent implements OnInit {
  tab: Tab;
  roles: Role[];
  filteredRoles: Role[];
  filteredGroups: Group[];
  groups: Group[];
  identifier: string;
  adminId: string;
  user: AdminUser;
  userTypes: {}[] = ['LocalAdminUser', 'AzureADAdminUser', 'ServiceAdminUser'].map((v) => {
    return { title: v, value: v };
  });
  disabled: boolean = true;

  userImport: UserImport = {
    adminUserType: 'LocalAdminUser',
    identifier: '',
    email: '',
    firstName: '',
    lastName: '',
    password: generate({ length: 32 }),
    groups: [],
    roles: [],
  };

  constructor(
    private router: Router,
    private collaborationService: CollaborationService,
    private configurationService: ConfigurationService,
    private tabsService: TabsService,
    private activatedRoute: ActivatedRoute,
    private adminUsersService: AdminUsersService
  ) {}

  async ngOnInit() {
    this.adminId = this.activatedRoute.snapshot.params.adminUserId;

    this.tab = this.tabsService.register({
      category: 'configuration',
      parent: '/admin-users',
      route: this.activatedRoute.snapshot,
    });

    const [roles, groups] = await Promise.all([this.configurationService.getRoles(), this.configurationService.getGroups()]);

    this.roles = roles.items;
    this.groups = groups.items;

    if (this.adminId !== 'new') {
      this.user = await new AdminUserFactory().createAdminUser(await this.adminUsersService.getAdminUser(this.adminId));
    } else {
      this.user = await new AdminUserFactory().createAdminUser({});
    }

    this.collaborationService.registerLocal(`admin-user:${this.user._id}`, this.user, true);

    this.userImport.adminUserType = this.user.adminUserType || 'LocalAdminUser';
    this.userImport.identifier = this.user.oid;
    this.userImport.email = this.user.email;
    this.userImport.firstName = this.user.firstName;
    this.userImport.lastName = this.user.lastName;
    this.userImport.roles = this.user.roles ? this.roles.filter((role) => this.user.roles.includes(role._id)) : [];
    this.userImport.groups = this.user.groups ? this.groups.filter((group) => this.user.groups.includes(group._id)) : [];
    this.disabled = false;
  }

  filterRoles(event?) {
    // Only unused groups are listed in suggestions
    this.filteredRoles = this.roles.filter((role) => !this.userImport.roles.includes(role));
    // Apply filter expression to suggestions list
    if (event && event.query) {
      this.filteredRoles = this.filteredRoles.filter((role) => role.name.toLowerCase().includes(event.query.toLowerCase()));
    }
  }

  filterGroups(event?) {
    // Only unused groups are listed in suggestions
    this.filteredGroups = this.groups.filter((group) => !this.userImport.groups.includes(group));
    // Apply filter expression to suggestions list
    if (event && event.query) {
      this.filteredGroups = this.filteredGroups.filter((group) => group.name.toLowerCase().includes(event.query.toLowerCase()));
    }
  }

  copyText(text: string) {
    copy(text);
  }

  generatePassword() {
    this.userImport.password = generate({ length: 32 });
  }

  async save() {
    this.disabled = true;
    this.user.firstName = this.userImport.firstName;
    this.user.lastName = this.userImport.lastName;
    this.user.roles = this.userImport.roles.map((role) => role._id);
    this.user.groups = this.userImport.groups.map((group) => group._id);
    if (this.adminId === 'new') {
      this.user.adminUserType = this.userImport.adminUserType;
      this.user.email = this.userImport.email;
      this.user.password = this.userImport.password;
      this.user = await this.adminUsersService.createAdminUser(this.user);
      await this.router.navigate(['/configuration/admin-users', this.user._id], {
        replaceUrl: true,
      });
    } else {
      await this.adminUsersService.updateAdminUser(this.user);
    }
    this.disabled = false;
  }
}
