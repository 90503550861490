import { TextAttributes } from './../Attributes';
import { PageModule, TitlePositions } from './PageModule';
import { CTAButton } from '../CTAButton';

export interface ImagePageModule extends PageModule {
  pageModuleType: 'Image';
  titlePosition?: TitlePositions;
  local: { [language: string]: ImagePageModuleLocal };
  ctaButton?: CTAButton;
}

export type ImagePageModuleAttributes = TextAttributes & {};

export interface ImagePageModuleLocal {
  title: string;
  title_attr?: ImagePageModuleAttributes;
  image: string;
}

export function isImagePageModule(object: any): object is ImagePageModule {
  return object?.pageModuleType === 'Image';
}
