import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ValidatorService } from 'src/app/services/validator/validator.service';

import { BlacklistEntry, blacklistEntryTypes, DomainBlacklistEntry, isDomainBlacklistEntry } from 'src/common/entities/BlacklistEntry';
import { blacklistEntryValidator, domainBlacklistEntryValidator } from 'src/common/api/v1/configuration/blacklistEntries/validators/BlacklistEntryValidator';
import { BlacklistEntriesService } from 'src/app/services/blacklistEntries/blacklist-entries.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';

@Component({
  selector: 'app-blacklist-entry',
  templateUrl: './blacklist-entry.component.html',
  styleUrls: ['./blacklist-entry.component.scss']
})
export class BlacklistEntryComponent implements OnInit, OnDestroy {
  blacklistEntryId: string = 'undefined';
  blacklistEntry: BlacklistEntry;
  tab: Tab;

  blacklistEntryTypes = [...blacklistEntryTypes];

  loading: boolean = true;
  saving: boolean = false;

  private _ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private validatorService: ValidatorService,
    private blacklistEntriesService: BlacklistEntriesService,
    private utilsService: UtilsService,
    private tabsService: TabsService
  ) {
    this.tab = this.tabsService.register({
      category: 'configuration',
      parent: '/configuration/blacklistentries',
      loading: true,
      route: this.activatedRoute.snapshot
    });
  }

  async ngOnInit(): Promise<void> {
    // this.blacklistEntryTypes = blacklistEntryTypes; // await this.utilsService.createDropdownOptions(, 'BlacklistEntryType');
    // debugger

    this.activatedRoute.params.pipe(takeUntil(this._ngUnsubscribe)).subscribe(async params => {
      this.blacklistEntryId = params.blacklistEntryId;
      if (this.blacklistEntryId === 'new') {
        this.blacklistEntry = {
          _id: '',
          blacklistEntryType: 'DomainBlacklistEntry'
          // domain: ''
        };
        this.loading = false;
      } else if (this.blacklistEntryId) {
        await this.retrieveData();
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe subscriptions
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  async retrieveData() {
    this.loading = true;
    try {
      this.blacklistEntry = await this.blacklistEntriesService.getBlacklistEntry(this.blacklistEntryId);
    } catch (err) {}
    this.loading = false;
  }

  async save() {
    this.saving = true;
    try {
      if (this.blacklistEntry) {
        if (this.blacklistEntryId === 'new') {
          this.blacklistEntry = await this.blacklistEntriesService.createBlacklistEntry(this.blacklistEntry);
          await this.router.navigate(['/configuration/blacklistentries', this.blacklistEntry._id], { replaceUrl: true });
          this.tabsService.close(this.tab);
        } else {
          this.blacklistEntry = await this.blacklistEntriesService.updateBlacklistEntry(this.blacklistEntry);
        }
      }
    } catch (err) {
      console.error(err);
    }
    this.saving = false;
  }

  async delete() {
    this.saving = true;
    try {
      if (this.blacklistEntry) {
        this.blacklistEntry = await this.blacklistEntriesService.deleteBlacklistEntry(this.blacklistEntry);
      }
    } catch (err) {}
    this.saving = false;
  }

  isValid(): boolean {
    if (isDomainBlacklistEntry(this.blacklistEntry)) {
      return this.validatorService.isValid(domainBlacklistEntryValidator, this.blacklistEntry);
    }
    return this.validatorService.isValid(blacklistEntryValidator, this.blacklistEntry);
  }

  errorAt(path: string): string | null {
    if (isDomainBlacklistEntry(this.blacklistEntry)) {
      return this.validatorService.errorAt(domainBlacklistEntryValidator, this.blacklistEntry, path);
    }
    return this.validatorService.errorAt(blacklistEntryValidator, this.blacklistEntry, path);
  }

  asDomainBlacklistEntry(): DomainBlacklistEntry {
    return isDomainBlacklistEntry(this.blacklistEntry) ? (this.blacklistEntry as DomainBlacklistEntry) : null;
  }
}
