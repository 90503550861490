import { DirectivesModule } from './../../directives/directives.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';

import { ComponentsModule } from 'src/app/components/components.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

import { CustomFieldsComponent } from './custom-fields.component';

const routes: Routes = [{ path: '', component: CustomFieldsComponent }];

@NgModule({
  declarations: [CustomFieldsComponent],
  imports: [CommonModule, RouterModule.forChild(routes), FormsModule, TranslateModule, TableModule, DividerModule, ButtonModule, ComponentsModule, PipesModule, DirectivesModule],
  exports: [RouterModule],
})
export class CustomFieldsModule {}
