import { InputConfiguration } from 'src/common/inputs/Inputs';
import { Component, Input, OnInit } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { TextPageModule } from 'src/common/entities/PageModule';

@Component({
  selector: 'c-text-page-module-edit',
  templateUrl: './text-page-module-edit.component.html',
  styleUrls: ['./text-page-module-edit.component.scss'],
})
export class TextPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: TextPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  inputJsonpathes: string[] = ['$.local.$language.title', '$.titlePosition', '$.local.$language.text', '$.ctaButton', '$.anchor', '$.contentPosition'];

  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
  }
}
