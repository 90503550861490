import { ActivatedRoute } from '@angular/router';
import { TabsService } from './../../tabs/services/tabs.service';
import { Tab } from './../../tabs/classes/tab';
import { TableOptions, TableQuery } from './../../components/table/table.interfaces';
import { Component, OnInit } from '@angular/core';
import { ExportsService } from 'src/app/services/exports/exports.service';
import { Export, ExportResult } from 'src/common/entities/Export';

@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.scss'],
})
export class ExportsComponent implements OnInit {
  tab: Tab;
  tableOptions: TableOptions<Export>;

  showResults: { [exportId: string]: boolean } = {};

  constructor(private exportsService: ExportsService, private tabsService: TabsService, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.tab = this.tabsService.register({
      category: 'data',
      route: this.activatedRoute.snapshot,
      title: 'Exports',
    });

    this.tableOptions = {
      size: 50,
      columns: [{ header: 'Type' }, { header: 'CreatedAt', sort: 'createdAt' }, { header: 'FinishedAt' }, { header: 'Status' }, { header: 'Params' }, { header: 'Results' }],
    };
  }

  query(query: TableQuery<Export>) {
    query.result = this.exportsService.getExports(query.query);
  }

  async download(exp: Export, exportResult: ExportResult) {
    const url = await this.exportsService.downloadUrl(exp._id, exportResult._id);

    let downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.setAttribute('download', exportResult.fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
