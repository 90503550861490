import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { ComponentsModule } from 'src/app/components/components.module';

import { PollComponent } from './poll.component';

const routes: Routes = [{ path: '', component: PollComponent }];

@NgModule({
  declarations: [PollComponent],
  imports: [CommonModule, RouterModule.forChild(routes), TranslateModule, ProgressSpinnerModule, ComponentsModule],
  exports: [RouterModule],
})
export class PollModule {}
