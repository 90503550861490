import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent {
  items: MenuItem[] = [];

  constructor(private utilsService: UtilsService) {}
}
