import { MapVersion } from './../../../common/entities/Map';
import { MapsService } from './../maps/maps.service';
import { Injectable } from '@angular/core';
import * as jp from 'jsonpath';
import { EventBlur } from 'src/common/api/v1/websocket/EventBlur';
import { EventFocus } from 'src/common/api/v1/websocket/EventFocus';
import { EventVersion } from 'src/common/entities/EventVersion';
import { isPage, Page } from 'src/common/entities/Page';
import { Inputs } from 'src/common/inputs/Inputs';
import { Patch } from 'src/common/patch/Patch';
import { PatchExecutor } from 'src/common/patch/PatchExecutor';
import { ApiSocketService } from '../api-socket/api-socket.service';
import { EventsService } from '../events/events.service';
import { PagesService } from '../pages/pages.service';
import { UtilsService } from '../utils/utils.service';

export type EventFocusCallback = (users: EventFocus[]) => void;

type PathQueueElement = {
  object: any;
  inputs: Inputs;
  jsonpath: string;
  jsonpathParams: { [key: string]: any };
  resolve: (value: any) => void;
  reject: () => void;
};

@Injectable({
  providedIn: 'root',
})
export class CollaborationService {
  private _focus: {
    [key: string]: {
      callbacks: EventFocusCallback[];
      users: EventFocus[];
    };
  } = {};

  private _local: { [key: string]: any } = {};

  private _focusUser: { [userKey: string]: EventFocus } = {};
  private _ensurePathQueueInProgress: { [collaborationKey: string]: boolean } = {};
  private _ensurePathQueue: { [collaborationKey: string]: PathQueueElement[] } = {};
  private _disabledCollaborationKeys: string[] = [];

  constructor(
    private apiSocket: ApiSocketService,
    private eventsService: EventsService,
    private pagesService: PagesService,
    private mapsService: MapsService,
    private utilsService: UtilsService // private authService: AuthService,
  ) {
    this.apiSocket.on<EventFocus>('focus', (focus) => {
      if (focus.socket === this.apiSocket.socketId) return;

      if (focus.type && focus.jsonpath) {
        const key = `${focus.type}:${focus.jsonpath}`;

        if (this._focusUser[`${focus.adminUser}:${focus.socket}`]) {
          const oldEventFocus = this._focusUser[`${focus.adminUser}:${focus.socket}`];
          const oldKey = `${oldEventFocus.type}:${oldEventFocus.jsonpath}`;
          if (this._focus[oldKey]) {
            this._focus[oldKey].users = this._focus[oldKey].users.filter((u) => !(u.adminUser === focus.adminUser && u.socket === focus.socket));
          }
          delete this._focusUser[`${focus.adminUser}:${focus.socket}`];
        }

        if (!this._focus[key]) {
          this._focus[key] = {
            callbacks: [],
            users: [],
          };
        }

        if (!this._focus[key].users.find((u) => u.adminUser === focus.adminUser && u.socket === focus.socket)) {
          this._focus[key].users.push(focus);
          this._focus[key].callbacks.forEach((c) => c(this._focus[key].users));
        }

        this._focusUser[`${focus.adminUser}:${focus.socket}`] = focus;
        this.checkClear(focus.type, focus.jsonpath);
      }
    });

    this.apiSocket.on<EventBlur>('blur', (blur) => {
      if (blur.socket === this.apiSocket.socketId) return;

      const key = `${blur.type}:${blur.jsonpath}`;
      const userKey = `${blur.adminUser}:${blur.socket}`;

      if (this._focus[key]) {
        this._focus[key].users = this._focus[key].users.filter((u) => !(u.adminUser === blur.adminUser && u.socket === blur.socket && new Date(u.date) < new Date(blur.date)));
        this._focus[key].callbacks.forEach((c) => c(this._focus[key].users));

        this.checkClear(blur.type, blur.jsonpath);
      }

      if (this._focusUser[userKey] && new Date(this._focusUser[userKey].date) > new Date(blur.date)) {
        delete this._focusUser[userKey];
      }
    });
  }

  private checkClear(type: string, jsonpath: string) {
    const key = `${type}:${jsonpath}`;
    if (this._focus[key]?.callbacks.length === 0 && this._focus[key]?.users.length === 0) {
      delete this._focus[key];
    }
  }

  focus(type: string, jsonpath: string) {
    this.apiSocket.emit<EventFocus>('focus', { type, jsonpath });
  }

  blur(type: string, jsonpath: string) {
    this.apiSocket.emit<EventBlur>('blur', { type, jsonpath });
  }

  registerFocus(type: string, jsonpath: string, callback: EventFocusCallback) {
    const key = `${type}:${jsonpath}`;

    if (!this._focus[key]) {
      this._focus[key] = {
        callbacks: [],
        users: [],
      };
    }

    this._focus[key].callbacks.push(callback);
  }

  unregisterFocus(type: string, jsonpath: string, callback: EventFocusCallback) {
    const key = `${type}:${jsonpath}`;

    if (this._focus[key]) {
      const index = this._focus[key].callbacks.indexOf(callback);
      this._focus[key].callbacks.splice(index, 1);
    }

    this.checkClear(type, jsonpath);
  }

  registerLocal(collaborationKey: string, obj: any, override?: boolean) {
    if (override) {
      this._local[collaborationKey] = obj;
    }
    if (!this._local[collaborationKey]) {
      this._local[collaborationKey] = obj;
    }
  }

  unregisterLocal(collaborationKey: string) {
    if (this._local[collaborationKey]) {
      delete this._local[collaborationKey];
    }
  }

  collaborationKey(obj: Page | EventVersion | MapVersion): string {
    if (isPage(obj)) {
      return `pageversion:${obj._id}`;
    } else {
      return `eventversion:${obj._id}`;
    }
  }

  async patch(collaborationKey: string, object: any, patch: Patch): Promise<boolean> {
    if (this._local[collaborationKey]) {
      return PatchExecutor.patch(this._local[collaborationKey], patch);
    }

    switch (collaborationKey.split(':')[0]) {
      case 'eventversion':
        return await this.eventsService.patch(object, patch);
      case 'pageversion':
        return await this.pagesService.patch(object, patch);
      case 'mapversion':
        return await this.mapsService.patch(object, patch);
    }
    return false;
  }

  async ensurePath(collaborationKey: string, object: any, jsonpath: string, jsonpathParams: { [key: string]: any }, inputs: Inputs) {
    if (this.isDisabled(collaborationKey)) return Promise.resolve(false);
    return new Promise((resolve: (value: any) => void, reject: () => void) => {
      if (!this._ensurePathQueue[collaborationKey]) {
        this._ensurePathQueue[collaborationKey] = [];
      }

      this._ensurePathQueue[collaborationKey].push({
        jsonpath,
        jsonpathParams,
        object,
        inputs,
        reject,
        resolve,
      });

      this.executeEnsurePath(collaborationKey);
    });
  }

  private async executeEnsurePath(collaborationKey: string) {
    if (this._ensurePathQueueInProgress[collaborationKey] || !this._ensurePathQueue[collaborationKey] || this._ensurePathQueue[collaborationKey].length === 0) return;
    this._ensurePathQueueInProgress[collaborationKey] = true;

    const ep = this._ensurePathQueue[collaborationKey].shift();

    try {
      if (await this.processEnsurePath(collaborationKey, ep)) {
        ep.resolve(true);
      } else {
        ep.reject();
      }
    } catch (err) {
      console.error(err);
    }

    this._ensurePathQueueInProgress[collaborationKey] = false;
    if (this._ensurePathQueue[collaborationKey]?.length > 0) {
      this.executeEnsurePath(collaborationKey);
    }
  }

  private async processEnsurePath(collaborationKey: string, ep: PathQueueElement) {
    try {
      let epJsonpath = ep.jsonpath;

      // pre check
      if (jp.paths(ep.object, this.utilsService.resolveJsonpath(epJsonpath, ep.jsonpathParams))[0]) {
        return true;
      }

      // if there are number variables used as index, we can directly replace them
      // we will replace the real index by a new "fake" index, which can be seen as a new variable and will be replaced afterwards
      // e.g. index 0 will be variable 99999999
      const numericIndexVariableMapping: {
        param: string;
        originalValue: number;
        newValue: number;
      }[] = [];
      let currentVariable = 99999999;

      for (const param of Object.keys(ep.jsonpathParams || {})) {
        if (typeof ep.jsonpathParams[param] === 'number') {
          numericIndexVariableMapping.push({
            param: param,
            originalValue: ep.jsonpathParams[param],
            newValue: currentVariable,
          });
          epJsonpath = epJsonpath.replace(new RegExp(`\\[\\$${param}\\]`, 'g'), `[${currentVariable}]`);
          currentVariable--;
        }
      }

      // jp parse cant handle the $ variables, therefore we will use ___
      let parts = jp.parse('$' + epJsonpath.slice(1).replace(/\$/g, '___')) as {
        expression: {
          type: string;
          value: string;
        };
        operation: string;
        scope: string;
      }[];

      for (let i = 0; i < parts.length; i++) {
        const jsonpathWithNumericIndexParams = jp.stringify(parts.slice(0, i + 1) as any[]).replace(/___/g, '$');

        const jsonpath = numericIndexVariableMapping.reduce((a, b) => {
          return a.replace(new RegExp(b.newValue.toString(), ''), `$${b.param}`);
        }, jsonpathWithNumericIndexParams);

        const resolvedPath = this.utilsService.resolveJsonpath(jsonpath, ep.jsonpathParams);
        const paths = jp.paths(ep.object, resolvedPath)[0];
        let hasValue = true;

        try {
          const val = jp.value(ep.object, resolvedPath);
          hasValue = typeof val !== 'undefined' && val !== null;
        } catch (err) {}

        if (!paths || !hasValue) {
          const factory = ep.inputs[resolvedPath]?.factory || ep.inputs[jsonpath]?.factory;

          if (factory) {
            const value = await factory(ep.object, ep.jsonpathParams);

            if (value) {
              await this.patch(collaborationKey, ep.object, {
                command: 'set',
                jsonpath: resolvedPath,
                value: value,
              });
            } else {
              return false;
            }
          } else if (ep.inputs[resolvedPath]?.list || ep.inputs[jsonpath]?.list) {
            await this.patch(collaborationKey, ep.object, {
              command: 'set',
              jsonpath: resolvedPath,
              value: [],
            });
          } else {
            return true;
          }
        }
      }

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  disable(collaborationKey: string) {
    if (!this._disabledCollaborationKeys.includes(collaborationKey)) {
      this._disabledCollaborationKeys.push(collaborationKey);
    }
  }

  enable(collaborationKey: string) {
    const index = this._disabledCollaborationKeys.indexOf(collaborationKey);
    if (index >= 0) {
      this._disabledCollaborationKeys.splice(index, 1);
    }
  }

  isDisabled(collaborationKey: string | EventVersion | Page | MapVersion) {
    if (typeof collaborationKey === 'string') {
      return this._disabledCollaborationKeys.includes(collaborationKey);
    } else if (isPage(collaborationKey)) {
      return this.isDisabled(`pageversion:${collaborationKey._id}`);
    } else if (collaborationKey) {
      return this.isDisabled(`mapversion:${collaborationKey._id}`);
    } else {
      return this.isDisabled(`eventversion:${collaborationKey._id}`);
    }
  }
}
