import { Component, Input, OnInit } from '@angular/core';

import { EventsService } from 'src/app/services/events/events.service';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { Stage2MultiEventStagePageModule } from 'src/common/entities/pagemodules/Stage2MultiEventStagePageModule';

import { InputConfiguration } from 'src/common/inputs/Inputs';

@Component({
  selector: 'c-stage2-multieventstage-page-module-edit',
  templateUrl: './stage2-multieventstage-page-module-edit.component.html',
  styleUrls: ['./stage2-multieventstage-page-module-edit.component.scss'],
})
export class Stage2MultiEventStagePageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: Stage2MultiEventStagePageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathes: string[] = ['$.local.$language.title', '$.local.$language.subtitle', '$.local.$language.preHeadline', '$.local.$language.background', '$.local.$language.subtext', '$.discoverMore'];

  topAreaJsonpathes: string[] = [
    '$.topAreaHeight.mobile',
    '$.topAreaHeight.mobileLarge',
    '$.topAreaHeight.tablet',
    '$.topAreaHeight.tabletLarge',
    '$.topAreaHeight.desktop',
    '$.topAreaHeight.desktopLarge',
    '$.topAreaHeight.desktopWidescreen',
  ];

  contentAreaJsonpathes: string[] = [
    '$.contentAreaHeight.mobile',
    '$.contentAreaHeight.mobileLarge',
    '$.contentAreaHeight.tablet',
    '$.contentAreaHeight.tabletLarge',
    '$.contentAreaHeight.desktop',
    '$.contentAreaHeight.desktopLarge',
    '$.contentAreaHeight.desktopWidescreen',
  ];

  ctaAreaJsonpathes: string[] = [
    '$.ctaAreaHeight.mobile',
    '$.ctaAreaHeight.mobileLarge',
    '$.ctaAreaHeight.tablet',
    '$.ctaAreaHeight.tabletLarge',
    '$.ctaAreaHeight.desktop',
    '$.ctaAreaHeight.desktopLarge',
    '$.ctaAreaHeight.desktopWidescreen',
  ];

  inputConfigurationEvent: InputConfiguration = {};

  initialized: boolean = false;
  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  constructor(private eventsService: EventsService) {}

  async ngOnInit(): Promise<void> {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
    this.inputConfigurationEvent = {
      type: 'multiselect',
      multiselectOptions: [{ label: 'None', value: null }].concat((await this.eventsService.getEvents({ limit: 70 })).items.map((e) => ({ label: e.internalName, value: e._id }))),
    };

    this.initialized = true;
  }
}
