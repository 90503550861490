import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormsService } from 'src/app/services/forms/forms.service';
import { IFilterList } from 'src/common/api/interfaces';
import { Form } from 'src/common/entities/Form';

@Component({
  selector: 'c-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
})
export class FormSelectComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input()
  onBlur?: Function;

  @Input()
  onFocus?: Function;

  @Input()
  disabled = false;

  @Input()
  value: string;

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('searchInput')
  searchInput: ElementRef<HTMLInputElement>;

  showSearch: boolean = false;
  searchText: string;
  searchTextSubject: Subject<string> = new Subject<string>();
  searchTextSubscription: Subscription;
  searchResult: Form[] = [];

  form: Form | null = null;

  constructor(private formsService: FormsService) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.value && changes.value.currentValue !== changes.value.previousValue) {
      if (changes.value.currentValue) {
        this.form = await this.formsService.getForm(this.value);
      } else {
        this.form = null;
      }
    }
  }

  async ngOnInit() {
    this.searchTextSubscription = this.searchTextSubject.pipe(debounceTime(500)).subscribe(() => this.executeSearch());
  }

  ngOnDestroy() {
    this.searchTextSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.searchInput?.nativeElement?.focus();
    }, 100);
  }

  searchTextKeyUp() {
    this.searchTextSubject.next(this.searchText);
  }

  async executeSearch(append: boolean = false) {
    // this.loading = true

    const filter: IFilterList = {};

    if (this.searchText) {
      filter.internalName = {
        matchMode: 'contains',
        value: this.searchText,
        caseInsensitive: true,
      };
    }

    if (!append) {
      this.searchResult = [];
    }

    const result = await this.formsService.getForms({
      limit: 10,
      skip: 0,
      filter,
    });

    this.searchResult = this.searchResult.concat(result.items);

    // this.loading = false
  }

  selectForm(form: Form) {
    this.valueChange.emit(form._id);
    this.showSearch = false;
  }

  removeForm() {
    this.valueChange.emit(null);
  }
}
