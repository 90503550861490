import { Component, HostBinding, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { AssetsService } from 'src/app/services/assets/assets.service';
import { Asset } from 'src/common/entities/Asset';
import { AssetSerie } from 'src/common/entities/AssetSerie';

@Component({
  selector: 'c-asset-serie-select',
  templateUrl: './asset-serie-select.component.html',
  styleUrls: ['./asset-serie-select.component.scss']
})
export class AssetSerieSelectComponent implements OnDestroy, OnChanges {
  @Input()
  id: string;

  @Input()
  disabled = false;

  @Input()
  minWidth: number;

  @Input()
  minHeight: number;

  @Input()
  width: number;

  @Input()
  height: number;

  @Input()
  @HostBinding('style.width')
  inputWidth: string = '100%';

  assetSerie: AssetSerie;

  assetSerieSubscription: Subscription;

  assetInvalid = false;

  thumbnailAsset: Asset = null;

  constructor(private assetsService: AssetsService) {}

  ngOnDestroy() {
    this.assetSerieSubscription?.unsubscribe();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.id?.currentValue && changes.id.currentValue !== changes.id.previousValue) {
      this._subscribe();
    } else if (!this.id) {
      this.assetSerieSubscription?.unsubscribe();
      this.assetSerie = null;
    }
  }

  private _subscribe() {
    this.assetSerieSubscription?.unsubscribe();

    this.assetSerieSubscription = this.assetsService.getAssetSerie(this.id).subscribe(
      asset => {
        if (!asset) {
          this.assetSerie = null;
          this.assetInvalid = true;
        } else {
          this.assetSerie = asset;
          this.assetInvalid = false;
          if (asset.thumbnail) {
            this.assetsService.getAsset(asset.thumbnail).subscribe(item => {
              this.thumbnailAsset = item;
            });
          }
        }
      },
      error => {
        console.error(error);
      }
    );
  }
}
