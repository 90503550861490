import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { StreamsComponent } from './streams.component';
import { RouterModule, Routes } from '@angular/router';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { ComponentsModule } from 'src/app/components/components.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { DirectivesModule } from './../../directives/directives.module';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';

const routes: Routes = [{ path: '', component: StreamsComponent }];

@NgModule({
  declarations: [StreamsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    DividerModule,
    FormsModule,
    ComponentsModule,
    ConfirmPopupModule,
    ButtonModule,
    InputTextModule,
    PipesModule,
    DirectivesModule,
    AccordionModule,
    DialogModule,
  ],
  exports: [],
  providers: [ConfirmationService],
})
export class StreamsModule {}
