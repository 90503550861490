import { Component, ContentChild, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { TableComponent } from 'src/app/components/table/table.component';
import { TableOptions } from 'src/app/components/table/table.interfaces';
import { EventsService } from 'src/app/services/events/events.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Event } from 'src/common/entities/Event';
import { EventSlot, EventTimeSlot } from 'src/common/entities/EventSlot';
import { EventTicket } from 'src/common/entities/EventTicket';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Factory } from 'src/common/factories/Factory';
import { InputConfiguration } from 'src/common/inputs/Inputs';

@Component({
  selector: 'c-event-slots',
  templateUrl: './event-slots.component.html',
  styleUrls: ['./event-slots.component.scss'],
})
export class EventSlotsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(TableComponent) table: TableComponent<EventTicket>;

  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  editableEventSlot: EventSlot = null;
  eventSlotEditTab = 'general';

  @ContentChild('abc') test: TemplateRef<{ $implicit: { b: string } }>;

  get showEventSlotEdit() {
    return this.editableEventSlot !== null;
  }

  set showEventSlotEdit(val: boolean) {
    if (!val) this.editableEventSlot = null;
  }

  inputConfigurationTickets: InputConfiguration;

  subscriptions: Subscription[] = [];

  eventSlotsTableOptions: TableOptions<EventSlot> = {
    columns: [
      { header: 'PAGE_EVENT_SECTION_SLOTS_STARTAT', sort: { type: 'date', property: 'startAt' } },
      { header: 'PAGE_EVENT_SECTION_SLOTS_ENDAT', sort: { type: 'date', property: 'endAt' } },
      { header: 'PAGE_EVENT_SECTION_SLOTS_CAPACITY', sort: { type: 'number', property: 'capacity' } },
      { header: 'PAGE_EVENT_SECTION_SLOTS_WARNINGCAPACITY', sort: { type: 'number', property: 'warningCapacity' } },
      { header: 'PAGE_EVENT_SECTION_SLOTS_TICKETS' },
    ],
  };

  constructor(private eventsService: EventsService, private utilsService: UtilsService) {}

  async ngOnInit() {
    this.inputConfigurationTickets = {
      type: 'multiselect',
      multiselectOptions: this.eventVersion.eventTickets
        ? this.eventVersion.eventTickets
            .filter((item) => !item.deletedAt)
            .map((t) => ({
              label: t.internalName,
              value: t._id,
            }))
        : [],
    };

    // Listen to eventversion patches
    this.subscriptions.push(
      this.eventsService.lastEventVersionPatch(this.eventVersion._id).subscribe((patch) => {
        if (this.utilsService.startsWithJsonpath(patch.patch.jsonpath, '$.eventSlots', patch.patch.jsonpathParams)) {
          this.table.refresh();
        }

        // Check if editableEventSlot still exists
        if (this.editableEventSlot) {
          if (!this.eventVersion.eventSlots.find((slot) => slot._id === this.editableEventSlot._id)) {
            // Currenty opended EventSlot doesn't exist any more
            this.showEventSlotEdit = false;
            return;
          }
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.eventVersion && changes.eventVersion.currentValue !== changes.eventVersion.previousValue) {
      this.eventVersion.eventSlots = this.eventVersion.eventSlots.filter((ticketList) => ticketList.deletedAt === null || !ticketList.deletedAt);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getTicketInfo(id: string) {
    return this.eventVersion.eventTickets.find((ticket) => ticket._id === id)?.internalName || '';
  }

  async addEventSlot() {
    try {
      const eventSlot = await Factory.slot().createSlot();
      const successful = await this.eventsService.patch(this.eventVersion, {
        command: 'push',
        jsonpath: '$.eventSlots',
        value: eventSlot,
      });

      if (successful) {
        this.editableEventSlot = this.eventVersion.eventSlots.find((e) => e._id === eventSlot._id);
      }
    } catch (err) {
      console.error(err);
    }
  }

  // !!! NOT IN USE TILL NOW: PROBLEM RELATIONS !!!
  // async deleteEventSlot(eventSlot: EventSlot) {
  //   try {
  //     const successful = await this.eventsService.patch(this.eventVersion, {
  //       command: 'delete',
  //       jsonpath: "$.eventSlots[?(@._id=='$eventSlot')]",
  //       jsonpathParams: { eventSlot: eventSlot._id }
  //     });
  //   } catch (err) { console.error(err) }
  // }

  async addTimeSlot() {
    try {
      const timeSlot = await Factory.slot().createTimeSlot();
      await this.eventsService.patch(this.eventVersion, {
        command: 'push',
        jsonpath: "$.eventSlots[?(@._id=='$eventSlot')].eventTimeSlots",
        jsonpathParams: { eventSlot: this.editableEventSlot._id },
        value: timeSlot,
      });
    } catch (err) {
      console.error(err);
    }
  }

  async deleteTimeSlot(eventTimeSlot: EventTimeSlot) {
    try {
      await this.eventsService.patch(this.eventVersion, {
        command: 'delete',
        jsonpath: "$.eventSlots[?(@._id=='$eventSlot')].eventTimeSlots[?(@._id=='$timeSlot')]",
        jsonpathParams: { eventSlot: this.editableEventSlot._id, timeSlot: eventTimeSlot._id },
      });
    } catch (err) {
      console.error(err);
    }
  }

  async onDeleteSlot(editableEventSlot) {
    await this.eventsService.patch(this.eventVersion, {
      command: 'set',
      jsonpath: `$.eventSlots[?(@._id=='$ticketId')].deletedAt`,
      jsonpathParams: {
        ticketId: editableEventSlot._id,
      },
      value: new Date().toISOString(),
    });
    this.eventVersion.eventSlots = this.eventVersion.eventSlots.filter((slotList) => slotList.deletedAt === null || !slotList.deletedAt);
  }

  trackByIndex(index: number, item: any) {
    return index;
  }
}
