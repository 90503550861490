import { Component, Input } from '@angular/core';
import { Asset } from '../../../../common/entities/Asset';

@Component({
  selector: 'c-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
})
export class EventItemComponent {
  @Input()
  internalName: string;

  @Input()
  shortId: string;

  @Input()
  eventTypeName: string;

  @Input()
  asset: Asset;
}
