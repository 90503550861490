import * as yup from 'yup';
import { filterFieldValidator } from '../../../interfaces';

export const keytopicLocalValidator = yup.object({
  title: yup.string().required(),
  shortDescription: yup.string().required(),
  longDescription: yup.string().required(),
});

export const keytopicContentValidator = yup.object({
  icon: yup.string().required(),
  header: yup.string().required(),
  local: yup.lazy((locals) => {
    return yup.object(Object.keys(locals).reduce((a, b) => ({ ...a, [b]: keytopicLocalValidator }), {}));
  }),
});

export const keytopicValidator = yup
  .object({
    internalName: yup.string().required(),
  })
  .concat(keytopicContentValidator);

export const keytopicsFilterValidator = yup.object({
  internalName: filterFieldValidator.concat(yup.object({ value: yup.string().nullable() })),
  'local.en.title': filterFieldValidator.concat(yup.object({ value: yup.string().nullable() })),
  'local.en.shortDescription': filterFieldValidator.concat(yup.object({ value: yup.string().nullable() })),
});
