<div (click)="selectedEvent || openSearch()">
  <c-event-item
    *ngIf="selectedEvent"
    (click)="openSearch()"
    [asset]="allTeaserAssets[selectedEvent._id]"
    [internalName]="isCurrentEvent() ? ('GENERAL_CURRENT_EVENT_MARKER' | translate: { internalName: selectedEvent.internalName }) : selectedEvent.internalName"
    [shortId]="selectedEvent.shortId"
    [eventTypeName]="allEventTypes[selectedEvent.eventType]"
  ></c-event-item>
  <div class="actions as-layer" *ngIf="selectedEvent && !this.disabled">
    <span>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openSearch()">{{ 'GENERAL_SEARCH' | translate }}</a>
      <span *ngIf="!hideRemoveLink">&nbsp;/&nbsp;<a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="removeEvent()">Remove</a>&nbsp;</span>
    </span>
  </div>
  <div class="actions" *ngIf="!selectedEvent && !this.disabled">
    <span>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openSearch()">{{ 'GENERAL_SEARCH' | translate }}</a
      >&nbsp;
    </span>
  </div>
  <div class="actions" *ngIf="this.disabled && !selectedEvent">
    <span> &nbsp;&nbsp; </span>
  </div>
</div>

<c-sidebar [header]="'COMPONENT_EVENT_SELECT_SEARCH_HEADER' + (templateSearch ? '_TEMPLATE' : '') | translate" [(visible)]="showSearch">
  <div *ngIf="searchResult" class="sidebar-search">
    <div class="search-input input-group compact">
      <div class="input">
        <input [placeholder]="'Search ' + (templateSearch ? 'template' : 'event') + ' ...'" pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
      </div>
    </div>
    <div infinite-scroll [scrollWindow]="false" (scrolled)="executeSearch()" class="search-results">
      <div class="search-result" *ngFor="let evt of searchResult" (click)="selectEvent(evt)">
        <c-event-item [asset]="allTeaserAssets[evt._id]" [internalName]="evt.internalName" [shortId]="evt.shortId" [eventTypeName]="allEventTypes[evt.eventType]"></c-event-item>
      </div>
    </div>
  </div>
</c-sidebar>
