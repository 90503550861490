import { Factory } from '../../factories/Factory';
import { Inputs } from '../Inputs';

export const eventVersionLocalInputs: Inputs = {
  '$.local.$language': {
    factory: async () => Factory.eventVersion().createEventVersionLanguage({})
  },
  '$.local.$language.title': {
    header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_TITLE',
    description: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_TITLE_DESCRIPTION'
  },
  '$.local.$language.shortDescription': {
    header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_SHORTDESCIPTION',
    description: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_SHORTDESCIPTION_DESCRIPTION',
    type: 'textarea'
  },
  '$.local.$language.linkTitle': {
    header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_LINKTITLE',
    description: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_LINKTITLE_DESCRIPTION'
  },
  '$.local.$language.linkDescription': {
    header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_LINKDESCRIPTION',
    description: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_LINKDESCRIPTION_DESCRIPTION',
    type: 'textarea'
  },
  '$.local.$language.longDescription': {
    header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_LONGDESCIPTION',
    description: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_LONGDESCIPTION_DESCRIPTION',
    type: 'textarea'
  },
  '$.local.$language.city': {
    header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_CITY'
  },
  '$.local.$language.fullAddress': {
    type: 'textarea',
    header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_FULLADDRESS'
  },
  '$.local.$language.mapsLink': {
    type: 'text',
    header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_GOOGLEMAPS',
    description: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_GOOGLEMAPS_DESCRIPTION'
  },
  '$.local.$language.eventRegistration.ticketHeader': {
    header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_TICKETHEADER',
    description: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_TICKETHEADER_DESCRIPTION'
  },
  '$.local.$language.eventRegistration.ticketSubheader': {
    header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_TICKETSUBHEADER',
    description: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_TICKETSUBHEADER_DESCRIPTION'
  },
  '$.local.$language.eventRegistration.dataHeader': {
    header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_DATAHEADER',
    description: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_DATAHEADER_DESCRIPTION'
  },
  '$.local.$language.contentChecklistTitle': {
    header: 'PAGE_EVENT_CONTENT_CHECKLIST_TITLE',
    description: 'PAGE_EVENT_CONTENT_CHECKLIST_TITLE_DESCRIPTION'
  }
};
