import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { AssetsService, AssetUpload } from 'src/app/services/assets/assets.service';
import { debounceTime } from 'rxjs/operators';
import { Asset } from 'src/common/entities/Asset';
import { TableOptions, TableQuery } from '../../components/table/table.interfaces';
import { QuickFilter } from 'src/common/entities/QuickFilter';
import { TableComponent } from 'src/app/components/table/table.component';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss'],
})
export class AssetsComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) table: TableComponent<Asset>;

  assetUploads: AssetUpload[] = [];
  tableOptions: TableOptions<Asset> = {
    size: 36,
    columns: [{ header: '' }],
    filters: [
      { header: 'PAGE_ASSETS_FILTER_INTERNAL_NAME', path: 'internalName' },
      { header: 'PAGE_ASSET_FILTER_EXTERNAL_ID', path: 'externalAsset.externalAssetId' },
    ],
    defaultSortDirection: 'desc',
  };

  searchTextSubject: Subject<string> = new Subject<string>();
  searchText: string;

  $refresh = new BehaviorSubject<boolean>(true);

  async searchTextKeyUp() {
    this.searchTextSubject.next();
  }
  typeQuickFilters: QuickFilter[] = [
    {
      name: 'Documents',
      active: true,
      value: 'DocumentAsset',
    },
    {
      name: 'Images',
      active: true,
      value: 'ImageAsset',
    },
    {
      name: 'Video',
      active: true,
      value: 'VideoAsset',
    },
  ];
  typeQuickFiltersActive = true;

  sourceQuickFilters: QuickFilter[] = [
    {
      name: 'Internal',
      active: true,
      value: 'Internal',
    },
    {
      name: 'External',
      active: true,
      value: 'External',
    },
  ];
  sourceQuickFiltersActive = true;

  working = false;

  subscriptions: Subscription[] = [];
  importData: {
    url: string;
    internalName: string;
  } = {
    url: '',
    internalName: '',
  };

  assetImports: Observable<Asset[]>;

  get visible(): boolean {
    return this.assetUploads.length > 0;
  }

  set visible(val: boolean) {
    if (!val) {
      this.assetUploads = [];
    }
  }

  constructor(private assetsService: AssetsService) {}

  ngOnInit(): void {
    this.subscriptions.push(this.searchTextSubject.pipe(debounceTime(500)).subscribe(() => this.refresh(!this.searchText)));
    this.assetImports = this.assetsService.currentAssetImports();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  currentAssetUploads(): Observable<AssetUpload[]> {
    return this.assetsService.currentAssetUploads();
  }

  startUpload() {
    for (const assetUpload of this.assetUploads) {
      this.subscriptions.push(
        this.assetsService.uploadAsset(assetUpload).subscribe((upload) => {
          //  upload.response
        })
      );
    }

    this.assetUploads = [];
  }

  setTypeQuickFilters(quickFilters: QuickFilter[]) {
    this.typeQuickFilters = [...quickFilters];
    this.typeQuickFiltersActive = this.typeQuickFilters.find((q) => q.active == true) != null;
    this.refresh();
  }

  setSourceQuickFilters(quickFilters: QuickFilter[]) {
    this.sourceQuickFilters = [...quickFilters];
    this.sourceQuickFiltersActive = this.sourceQuickFilters.find((q) => q.active == true) != null;
    this.refresh();
  }

  valid(): boolean {
    return this.assetUploads.every((a) => a.internalName);
  }

  public dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.assetUploads.push({
            file: file,
            internalName: file.name.split('.').slice(0, -1).join('.'),
          });
        });
      }
    }
  }

  async import() {
    if (!this.importData.url) return;

    this.working = true;

    try {
      await this.assetsService.importAsset(this.importData);
      this.importData.url = '';
      this.importData.internalName = '';
    } catch (err) {
      console.error(err);
    }

    this.working = false;
  }

  query(query: TableQuery<Asset>) {
    if (this.searchText) {
      query.query.filter.internalName = {
        matchMode: 'contains',
        caseInsensitive: true,
        value: this.searchText,
      };
    }

    // Type QuickFilters
    if (this.typeQuickFiltersActive) {
      query.query.filter.assetType = {
        matchMode: 'in',
        value: this.typeQuickFilters.map((item) => {
          if (item.active) {
            return item.value;
          }
          return null;
        }),
      };
    } else {
      delete query.query.filter.assetType;
    }

    // Source QuickFilters
    if (this.sourceQuickFilters.find((q) => q.active == true && q.value == 'Internal') != null && this.sourceQuickFilters.find((q) => q.active == true && q.value == 'External') != null) {
      delete query.query.filter['source.system'];
    } else if (this.sourceQuickFiltersActive && this.sourceQuickFilters.find((q) => q.active == true && q.value == 'Internal') != null) {
      query.query.filter['source.system'] = {
        matchMode: 'contains',
        value: 'Internal',
      };
    } else if (this.sourceQuickFiltersActive && this.sourceQuickFilters.find((q) => q.active == true && (q.value == 'External') != null)) {
      query.query.filter['source.system'] = {
        matchMode: 'notContains',
        value: 'Internal',
      };
    } else {
      delete query.query.filter['source.system'];
    }

    query.result = this.assetsService.getAssets(query.query);
  }

  async refresh(clear = false): Promise<void> {
    this.working = true;

    if (clear) {
      this.table.clearFilter();
    } else {
      this.table.refresh();
    }

    this.working = false;
  }
}
