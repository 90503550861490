import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';

import { Region } from 'src/common/entities/Region';
import { GetRegionQuery, GetRegionResponse } from 'src/common/api/v1/configuration/regions/GetRegion';
import { GetRegionsQuery, GetRegionsResponse } from 'src/common/api/v1/configuration/regions/GetRegions';
import { PostRegionBody, PostRegionQuery, PostRegionResponse } from 'src/common/api/v1/configuration/regions/PostRegion';
import { PostRegionsBody, PostRegionsQuery, PostRegionsResponse } from 'src/common/api/v1/configuration/regions/PostRegions';
import { ApiSocketService } from '../api-socket/api-socket.service';
import { Observable } from 'rxjs';
import { CacheService } from '../cache/cache.service';
import { CacheContainer } from '../cache/cache-container';

@Injectable({
  providedIn: 'root',
})
export class RegionsService {
  // private _regions: {[region: string] : Promise<Region>} = {};
  // private _regions: BehaviorSubject<Region[]> = new BehaviorSubject<Region[]>(null);

  private _cache: CacheContainer<Region>;

  constructor(private apiService: ApiService, private apiSocketService: ApiSocketService, private cacheService: CacheService) {
    this._cache = this.cacheService.create<Region>({
      id: (value) => value.region,
      fill: async () => (await this.apiService.get<GetRegionsQuery, GetRegionsResponse>('/api/v1/configuration/regions').toPromise()).items,
      get: async (region) => await this.apiService.get<GetRegionQuery, GetRegionResponse>(`/api/v1/configuration/regions/${region}`).toPromise(),
      groups: [
        {
          name: 'active',
          filter: (value) => value.active,
        },
      ],
      socketEvents: ['region:update'],
    });
    // this.apiSocketService.on<Region>('region:update', (region: Region) => {
    //   this._ensureRegion(region);
    // })
  }

  async getRegion(region: string): Promise<Region> {
    return this._cache.asPromise(region);
  }

  async getRegions(query?: GetRegionsQuery): Promise<GetRegionsResponse> {
    const result = await this.apiService
      .get<GetRegionsQuery, GetRegionsResponse>('/api/v1/configuration/regions', {
        ...query,
      })
      .toPromise();

    this._cache.fill(result.items);

    return result;
  }

  getActiveRegions(): Observable<Region[]> {
    return this._cache.group('active');
  }

  async getActiveRegionsAsPromise(): Promise<Region[]> {
    return (await this.getRegions()).items.filter((l) => l.active);
  }

  async createRegion(region: Region): Promise<Region> {
    return this._cache.post<PostRegionsQuery, PostRegionsBody, PostRegionsResponse>('/api/v1/configuration/regions', region);
  }

  async updateRegion(region: Region): Promise<Region> {
    return this._cache.post<PostRegionQuery, PostRegionBody, PostRegionResponse>(`/api/v1/configuration/regions/${region.region}`, region);
  }

  public currentRegions(): Region[] {
    return this._cache.values();
  }

  // private _ensureRegion(region: Region) : Region {
  //   let currentRegions = this._regions.getValue();
  //   if (!currentRegions) currentRegions = [];
  //   let currentRegion = this._regions.getValue().find(l => l._id === region._id);

  //   if (currentRegion) {
  //     Object.assign(currentRegion, region);
  //   } else {
  //     currentRegions.push(region);
  //     this._regions.next(currentRegions);
  //   }

  //   return currentRegion || region;
  // }

  // async getRegion(region: string): Promise<Region> {
  //   return this._ensureRegion(await this.apiService.get<GetRegionQuery, GetRegionResponse>(`/api/v1/configuration/regions/${region}`).toPromise());
  // }

  // async getRegions(query?: GetRegionsQuery): Promise<GetRegionsResponse> {
  //   const result = await this.apiService.get<GetRegionsQuery, GetRegionsResponse>('/api/v1/configuration/regions', {
  //     ...query,
  //   }).toPromise();

  //   this._regions.next(result.items);

  //   return result;
  // }

  // async createRegion(region: Region) : Promise<Region> {
  //   return this._ensureRegion(await this.apiService.post<PostRegionsQuery, PostRegionsBody, PostRegionsResponse>('/api/v1/configuration/regions', region).toPromise());
  // }

  // async updateRegion(region: Region) : Promise<Region> {
  //   return this._ensureRegion(await this.apiService.post<PostRegionQuery, PostRegionBody, PostRegionResponse>(`/api/v1/configuration/regions/${region.region}`, region).toPromise());
  // }

  /* deleteRegion(region: Region) : Promise<Region> {
    return this.apiService.post<PostRegionQuery, PostRegionBody, PostRegionResponse>(`/api/v1/configuration/regions/${region.region}/delete`, region).toPromise();
  } */
}
