import { InputConfiguration } from 'src/common/inputs/Inputs';
import { Component, Input, OnInit } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { BannerPageModule } from 'src/common/entities/pagemodules/BannerPageModule';

@Component({
  selector: 'c-banner-page-module-edit',
  templateUrl: './banner-page-module-edit.component.html',
  styleUrls: ['./banner-page-module-edit.component.scss'],
})
export class BannerPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: BannerPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathes: string[] = ['$.local.$language.title', '$.local.$language.text', '$.local.$language.image', '$.local.$language.background', '$.backgroundColor', '$.ctaButton', '$.anchor'];

  tab = 'general';

  accessInputConfiguration: InputConfiguration;

  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
  }
}
