import { HeadlineType } from './../../entities/Attributes';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';
import { ListVideoPageModule, ListVideoPageModuleAttributes, ListVideoPageModuleLocal } from '../../entities/PageModule';

export class ListVideoPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async listVideoPageModuleLocal(values?: any): Promise<ListVideoPageModuleLocal> {
    return {
      title: values?.title || '',
      title_attr: this.listVideoPageModuleAttributes(values?.title_attr),
      items: values?.items || [],
      upperText: values?.upperText || '',
      lowerText: values?.lowerText || '',
      video: values?.video || null,
      itemIcon: values?.itemIcon || null,
    };
  }

  public listVideoPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): ListVideoPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async listVideoPageModule(values?: any): Promise<ListVideoPageModule> {
    if (values?.itemIcon) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.itemIcon = item.itemIcon || values.itemIcon;
      });
    }

    const pageModule = {
      ...(await this.baseValues(values)),
      pageModuleType: 'ListVideo',
      titlePosition: values?.titlePosition || 'content',
      local: await this.local(values, (val) => this.listVideoPageModuleLocal(val)),
      itemIcon: undefined,
      videoSize: values?.videoSize || 60,
      videoPosition: values?.videoPosition || 'left',
      videoPositionMobile: values?.videoPositionMobile || 'bottom',
      ctaButton: values?.ctaButton || null,
      video: values?.video || null,
      videoAppearanceMobile: values?.videoAppearanceMobile || 'contentArea',
    };

    return { ...pageModule, pageModuleType: 'ListVideo' };
  }
}
