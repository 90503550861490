import { AccessPolicy } from './AccessPolicy';

export const assetTypes = ['VideoAsset', 'ImageAsset', 'DocumentAsset'] as const;
export type AssetType = (typeof assetTypes)[number];

export const videoAssetDistributorTypes = ['Bitmovin', 'MediaServices'] as const;
export type VideoAssetDistributorType = (typeof videoAssetDistributorTypes)[number];

export type AssetSourceSystem = 'Internal' | 'CoreMedia' | 'Censhare';

export type AssetLocal = {
  title: string;
  description: string;
};

export interface Asset {
  _id: string;
  assetType: AssetType;
  status: 'DOWNLOADING' | 'PROCESSING' | 'FINISHED' | 'ERROR';
  error?: string;

  executeAccess?: AccessPolicy[];
  readAccess?: AccessPolicy[];

  internalName: string;
  mimeType: string;
  fileName: string;
  size: number;
  contentLength: number;
  height: number;
  width: number;

  uploadedAt: string;
  uploadedBy: string;
  uploadCompletedAt: string;
  uploadUrl: string;
  processingCompletedAt: string;
  publishedAt?: string;
  publishedBy?: string;

  local: { [language: string]: AssetLocal };

  source: {
    system: AssetSourceSystem;
    plugin?: string;
    data?: any;
  };

  download?: {
    url: string;
    progress: number;
  };
  contentLanguages?: string[];
  externalAsset?: {
    externalAssetId: string;
    url: string;
    mimeType?: string;
    assetVersion: number;
    thumbnailurl?: string;
    thumbnailuploaddate?: string | Date;
  };

  tags: string[];
  assetSeries?: string[];

  hideFromFrontend?: boolean;
}

export interface VideoAssetEditingCut {
  _id: string;
  session?: string;
  language?: string;
  startAt: number;
  endAt: number;
  asset?: string;
  status: 'CREATED' | 'PROCESSING' | 'FINISHED';
  progress: number;
  externalEditorDeeplink?: string;
}

export interface VideoAssetEditing {
  _id: string;
  plugin: any; // plugin object
  createdAt: string | Date;
  createdBy: string;
  cuts?: VideoAssetEditingCut[];
  externalReference?: any; // could be exteral asset id or other reference, depending on plugin
  status: 'PROCESSING' | 'FINISHED';
  progress: number;
}

export interface VideoAsset extends Asset {
  assetType: 'VideoAsset';
  distributors: VideoAssetDistributor[];
  duration: number;
  streamingUrls: string[];
  initThumbnail?: boolean;
  thumbnail?: string; // Asset
  statusNewUntil?: string | Date | null;
  editings?: VideoAssetEditing[];
  recordedAt?: string | Date | null;
}

export interface VideoAssetDistributor {
  _id: string;
  videoAssetDistributorType: VideoAssetDistributorType;
  streamingUrls?: string[];
  status: 'CANCELED' | 'CANCELING' | 'ERROR' | 'FINISHED' | 'PROCESSING' | 'QUEUED' | 'SCHEDULED';
}

export interface MediaServicesVideoAssetDistributor extends VideoAssetDistributor {
  videoAssetDistributorType: 'MediaServices';
  jobId: string;
  progress: number;
  error?: string;
  date: string | Date;
  jobDetails: any;
}

export interface BitmovinVideoAssetDistributor extends VideoAssetDistributor {
  videoAssetDistributorType: 'Bitmovin';
  jobId: string;
  progress: number;
  error?: string;
  date: string | Date;
  jobDetails: any;
}

export interface ImageAsset extends Asset {
  assetType: 'ImageAsset';

  paths: { [size: string]: string };
  // original: "images/6053bcf127085243249ca23b.jpeg",
  // thumbnail: "images/6053bcf127085243249ca23b.thumbnail.jpeg", // max
  // small: "images/6053bcf127085243249ca23b.small.jpeg",  // max 650 width
  // medium: "images/6053bcf127085243249ca23b.medium.jpeg",  // max 1100 width
  // large: "images/6053bcf127085243249ca23b.large.jpeg",  // max 1900 width

  placeholder: string; // base64 svg image

  local: {
    [language: string]: AssetLocal & {
      alt?: string;
    };
  };
}

export interface DocumentAsset extends Asset {
  assetType: 'DocumentAsset';
  path: string;
  thumbnail?: string;
}

export function isVideoAsset(object: any): object is VideoAsset {
  return object?.assetType === 'VideoAsset';
}

export function isImageAsset(object: any): object is ImageAsset {
  return object?.assetType === 'ImageAsset';
}

export function isDocumentAsset(object: any): object is DocumentAsset {
  return object?.assetType === 'DocumentAsset';
}

export function isMediaServicesVideoAssetDistributor(object: any): object is MediaServicesVideoAssetDistributor {
  return object?.videoAssetDistributorType === 'MediaServices';
}

export function isBitmovinVideoAssetDistributor(object: any): object is BitmovinVideoAssetDistributor {
  return object?.videoAssetDistributorType === 'Bitmovin';
}
