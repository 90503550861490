import * as yup from 'yup';

export const blacklistEntryValidator = yup.object({
  blacklistEntryType: yup.string().oneOf(['DomainBlacklistEntry']),
});

export const domainBlacklistEntryValidator = yup
  .object({
    domain: yup.string().required(),
  })
  .concat(blacklistEntryValidator);
