<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_EVENT_SECTION_CHATS' | translate }}
    <div class="actions">
      <button *hasRight="'events.editContent'" pButton type="button" [label]="'PAGE_EVENT_SECTION_CHATS_ADD_CHAT' | translate" icon="pi icon-ic-add-item" class="p-button" (click)="addChat()"></button>
    </div>
  </div>
  <c-table #table [options]="chatTableOptions" [data]="eventVersion.chats">
    <ng-template let-chat>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openChatDialog(chat)">
        {{ chat.internalName || ('GENERAL_UNNAMED' | translate) }}
      </a>
    </ng-template>
    <ng-template let-chat>
      <div *ngFor="let session of sessions[chat.chat]">
        <div *ngFor="let keyval of session.local | keyvalue">
          <div *ngIf="keyval.value.chat === chat.chat">{{ (keyval.key | language | async)?.languageName }}: {{ keyval.value.title }}</div>
        </div>
      </div>
    </ng-template>
  </c-table>
</div>

<p-sidebar [(visible)]="showChat" styleClass="p-sidebar-st" position="right" [modal]="true" [appendTo]="'body'" [showCloseIcon]="false">
  <c-sidebar-header *ngIf="editableChat" [headerTitle]="'PAGE_EVENT_SECTION_CHATS_EDIT_CHAT' | translate" [headerSubTitle]="editableChat.internalName" (onClose)="showChat = false"></c-sidebar-header>

  <div class="sidebar-body" *ngIf="editableChat">
    <c-event-patch-input jsonpath="$.chats[?(@.chat=='$chatId')].internalName" [jsonpathParams]="{ chatId: editableChat.chat }" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.chats[?(@.chat=='$chatId')].autoConfirm" [jsonpathParams]="{ chatId: editableChat.chat }" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.chats[?(@.chat=='$chatId')].chatReply" [jsonpathParams]="{ chatId: editableChat.chat }" [eventVersion]="eventVersion"></c-event-patch-input>
  </div>

  <c-sidebar-footer
    *hasRight="'events.editContent'"
    [autoSaveInfo]="true"
    (onClose)="showChat = false"
    [showDeleteButton]="true"
    (onDelete)="deleteChat(editableChat); showChat = false"
  ></c-sidebar-footer>
</p-sidebar>
