<div *skeleton="{ show: tab.loading }">
  <div class="tab-header">
    <h1>
      <span class="pre-title">{{ (userId === 'new' ? 'PAGE_USER_NEW' : 'GENERAL_USER') | translate }}</span>
      <span>{{ user.firstName }}&nbsp;{{ user.lastName }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <p-button *ngIf="hasEditRights" icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" [disabled]="!isValid()" (onClick)="save()"></p-button>
  </div>

  <div class="tab-subtabs">
    <div class="tabs">
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'userstatus' }" [class.active]="currentSubTab === 'userstatus'">Status Information</a>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'userdata' }" [class.active]="currentSubTab === 'userdata'">User Data</a>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'eventregistration' }" [class.active]="currentSubTab === 'eventregistration'">Event Registration</a>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'tagassignment' }" [class.active]="currentSubTab === 'tagassignment'">Tag Assignment</a>
    </div>
  </div>

  <div *ngIf="currentSubTab === 'userstatus'" class="panel">
    <div class="panel-header">
      {{ 'PAGE_USER_STATUS_INFORMATION' | translate }}
    </div>
    <div *ngIf="user" class="panel-body">
      <div class="input-group switch">
        <div class="input">
          <p-checkbox [(ngModel)]="user.active" [binary]="true" [disabled]="true"></p-checkbox>
        </div>
        <div class="title">{{ 'GENERAL_ACTIVE' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_ACTIVE_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [ngModel]="'PAGE_USER_USERTYPE_' + user.userType.toUpperCase() | translate" [disabled]="true" [class]="!user.registeredAt ? 'ng-invalid ng-dirty' : ''" />
        </div>
        <div class="title">{{ 'PAGE_USER_USERTYPE' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_USERTYPE_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [ngModel]="user.registeredAt | date : 'short'" [disabled]="true" [class]="!user.registeredAt ? 'ng-invalid ng-dirty' : ''" />
        </div>
        <div class="title">{{ 'PAGE_USER_REGISTEREDAT' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_REGISTEREDAT_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [ngModel]="user.activatedAt | date : 'short'" [disabled]="true" [class]="!user.activatedAt ? 'ng-invalid ng-dirty' : ''" />
        </div>
        <div class="title">{{ 'PAGE_USER_ACTIVATEDAT' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_ACTIVATEDAT_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [ngModel]="user.lastAuthAt | date : 'short'" [disabled]="true" [class]="!user.lastLoginAt ? 'ng-invalid ng-dirty' : ''" />
        </div>
        <div class="title">{{ 'PAGE_USER_LASTLOGINAT' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_LASTLOGINAT_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group switch">
        <div class="input">
          <p-checkbox [ngModel]="user.privacyPolicy.accepted" [binary]="true" [disabled]="true"></p-checkbox>
        </div>
        <div class="title">{{ 'PAGE_USER_PRIVACY_POLICY' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_PRIVACY_POLICY_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [ngModel]="user.privacyPolicy.acceptedAt | date : 'short'" [disabled]="true" [class]="!user.privacyPolicy.acceptedAt ? 'ng-invalid ng-dirty' : ''" />
        </div>
        <div class="title">{{ 'PAGE_USER_PRIVACY_POLICYAT' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_PRIVACY_POLICYAT_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group">
        <div class="input no-border">
          <div class="p-chips">
            <div class="p-chips-multiple-container">
              <ng-template ngFor let-tag [ngForOf]="userTags">
                <div class="p-chips-token">
                  <i class="pi pi-tag ml-2"></i>
                  <span>{{ (tag | tag | async)?.label }}</span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="title">{{ 'PAGE_USER_TAGS' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_TAGS_DESCRIPTION' | translate }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="currentSubTab === 'userstatus'" class="panel">
    <div class="panel-header">
      {{ 'PAGE_USER_BLACKLIST_INFORMATION' | translate }}
    </div>
    <div *ngIf="user" class="panel-body">
      <div class="input-group switch">
        <div class="input">
          <p-checkbox [(ngModel)]="user.blocked" [disabled]="readonly || !hasEditRights" [binary]="true"></p-checkbox>
        </div>
        <div class="title">{{ 'PAGE_USER_BLOCKED' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_BLOCKED_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group" *ngIf="user.blacklistEntry && asDomainBlacklistEntry() as domainBlacklistEntry">
        <div class="input no-border">{{ domainBlacklistEntry.domain }}</div>
        <div class="title">{{ 'PAGE_BLACKLIST_ENTRY_DOMAIN' | translate }}</div>
        <div class="description">{{ 'PAGE_BLACKLIST_ENTRY_DOMAIN_DESCRIPTION' | translate }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="currentSubTab === 'userdata'" class="panel">
    <div class="panel-header">
      {{ 'PAGE_USER_PERSONAL_DATA' | translate }}
    </div>
    <div *ngIf="user" class="panel-body">
      <div class="input-group">
        <div class="input">
          <p-dropdown
            [options]="luSalutation.local[user.language || 'en'] | keyvalue"
            optionLabel="value"
            optionValue="key"
            [(ngModel)]="user.salutation"
            [disabled]="readonly || !hasEditRights"
            [class]="!user.salutation ? 'ng-invalid ng-dirty' : ''"
            [style]="{ width: '100%' }"
          ></p-dropdown>
        </div>
        <div class="title">{{ 'PAGE_USER_SALUTATION' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_SALUTATION_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [(ngModel)]="user.firstName" [disabled]="readonly || !hasEditRights" [class]="!user.firstName ? 'ng-invalid ng-dirty' : ''" />
        </div>
        <div class="title">{{ 'PAGE_USER_FIRSTNAME' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_FIRSTNAME_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [(ngModel)]="user.lastName" [disabled]="readonly || !hasEditRights" [class]="!user.lastName ? 'ng-invalid ng-dirty' : ''" />
        </div>
        <div class="title">{{ 'PAGE_USER_LASTNAME' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_LASTNAME_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [(ngModel)]="user.email" [disabled]="true" [class]="!user.email ? 'ng-invalid ng-dirty' : ''" />
        </div>
        <div class="title">{{ 'PAGE_USER_EMAIL' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_EMAIL_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [(ngModel)]="user.phone" [disabled]="readonly || !hasEditRights" [class]="!user.phone ? 'ng-invalid ng-dirty' : ''" />
        </div>
        <div class="title">{{ 'PAGE_USER_PHONENUMBER' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_PHONENUMBER_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <p-dropdown
            [options]="languages"
            optionLabel="languageName"
            optionValue="language"
            [(ngModel)]="user.language"
            [disabled]="readonly || !hasEditRights"
            [class]="!user.language ? 'ng-invalid ng-dirty' : ''"
            [style]="{ width: '100%' }"
          ></p-dropdown>
        </div>
        <div class="title">{{ 'PAGE_USER_LANGUAGE' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_LANGUAGE_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <c-user-picker [(user)]="user.salesManager" [disabled]="readonly || !hasEditRights" userType="REMOTE_ADMIN_USER" [multi]="false"></c-user-picker>
        </div>
        <div class="title">{{ 'PAGE_USER_SALESMANAGER' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_SALESMANAGER_DESCRIPTION' | translate }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="currentSubTab === 'userdata'" class="panel">
    <div class="panel-header">
      {{ 'PAGE_USER_COMPANY_DATA' | translate }}
    </div>
    <div *ngIf="user" class="panel-body">
      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [(ngModel)]="user.organization" [disabled]="readonly || !hasEditRights" [class]="!user.organization ? 'ng-invalid ng-dirty' : ''" />
        </div>
        <div class="title">{{ 'PAGE_USER_ORGANIZATION' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_ORGANIZATION_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group">
        <div class="input">
          <p-dropdown
            [options]="luJobTitle.local[user.language || 'en'] | keyvalue"
            optionLabel="value"
            optionValue="key"
            [(ngModel)]="user.department"
            [disabled]="readonly || !hasEditRights"
            [style]="{ width: '100%' }"
            [class]="!user.department ? 'ng-invalid ng-dirty' : ''"
          ></p-dropdown>
        </div>
        <div class="title">{{ 'PAGE_USER_DEPARTMENT' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_DEPARTMENT_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [(ngModel)]="user.street" [disabled]="readonly || !hasEditRights" [class]="!user.street ? 'ng-invalid ng-dirty' : ''" />
        </div>
        <div class="title">{{ 'PAGE_USER_STREET' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_STREET_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [(ngModel)]="user.zipCode" [disabled]="readonly || !hasEditRights" [class]="!user.zipCode ? 'ng-invalid ng-dirty' : ''" />
        </div>
        <div class="title">{{ 'PAGE_USER_ZIPCODE' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_ZIPCODE_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [(ngModel)]="user.city" [disabled]="readonly || !hasEditRights" [class]="!user.city ? 'ng-invalid ng-dirty' : ''" />
        </div>
        <div class="title">{{ 'PAGE_USER_CITY' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_CITY_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <p-dropdown
            [options]="countries"
            optionLabel="value"
            optionValue="key"
            [(ngModel)]="user.country"
            [disabled]="readonly || !hasEditRights"
            [style]="{ width: '100%' }"
            [class]="!user.country ? 'ng-invalid ng-dirty' : ''"
          ></p-dropdown>
        </div>
        <div class="title">{{ 'PAGE_USER_COUNTRY' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_COUNTRY_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [(ngModel)]="user.state" [disabled]="readonly || !hasEditRights" />
        </div>
        <div class="title">{{ 'PAGE_USER_STATE' | translate }}</div>
        <div class="description">{{ 'PAGE_USER_STATE_DESCRIPTION' | translate }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="currentSubTab === 'eventregistration'" class="panel">
    <div class="panel-header">
      {{ 'PAGE_USER_CURRENT_EVENT_REGISTRATIONS' | translate }}
    </div>
    <div *ngIf="currentUserEvents" class="panel-body">
      <p-table [value]="currentUserEvents" styleClass="p-datatable-sm">
        <ng-template pTemplate="header">
          <tr>
            <th>{{ 'PAGE_EVENTS_HEADER_INTERNAL_NAME' | translate }}</th>
            <th>{{ 'PAGE_EVENTS_HEADER_EVENT_TYPE' | translate }}</th>
            <th>{{ 'PAGE_EVENTS_HEADER_EVENT_START' | translate }}</th>
            <th>{{ 'PAGE_USER_EVENT_REGISTRATIONS_REGISTERED_AT' | translate }}</th>
            <th>{{ 'PAGE_USER_EVENT_REGISTRATIONS_ATTENDED' | translate }}</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-registration>
          <tr>
            <td>{{ registration.event.internalName }}</td>
            <td>{{ allEventTypes[registration.event.eventType] }}</td>
            <td>{{ registration.event.totalStartAt | date : 'short' }}</td>
            <td>{{ registration.registeredAt | date : 'short' }}</td>
            <td>
              <p-checkbox [ngModel]="registration.attended" [binary]="true" [disabled]="true"></p-checkbox>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div *ngIf="currentSubTab === 'eventregistration'" class="panel">
    <div class="panel-header">
      {{ 'PAGE_USER_FULL_HISTORY_EVENT_REGISTRATIONS' | translate }}
    </div>
    <div *ngIf="userEvents" class="panel-body">
      <p-table [value]="userEvents" styleClass="p-datatable-sm">
        <ng-template pTemplate="header">
          <tr>
            <th>{{ 'PAGE_EVENTS_HEADER_INTERNAL_NAME' | translate }}</th>
            <th>{{ 'PAGE_EVENTS_HEADER_EVENT_TYPE' | translate }}</th>
            <th>{{ 'PAGE_EVENTS_HEADER_EVENT_START' | translate }}</th>
            <th>{{ 'PAGE_USER_EVENT_REGISTRATIONS_REGISTERED_AT' | translate }}</th>
            <th>{{ 'PAGE_USER_EVENT_REGISTRATIONS_UNREGISTERED_AT' | translate }}</th>
            <th>{{ 'PAGE_USER_EVENT_REGISTRATIONS_ATTENDED' | translate }}</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-registration>
          <tr>
            <td>{{ registration.event.internalName }}</td>
            <td>{{ allEventTypes[registration.event.eventType] }}</td>
            <td>{{ registration.event.totalStartAt | date : 'short' }}</td>
            <td>{{ registration.registeredAt | date : 'short' }}</td>
            <td>{{ registration.unregisteredAt | date : 'short' }}</td>
            <td>
              <p-checkbox [ngModel]="registration.attended" [binary]="true" [disabled]="true"></p-checkbox>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div *ngIf="currentSubTab === 'tagassignment'" class="panel">
    <div class="panel-header">
      {{ 'PAGE_USER_TAG_ASSIGNMENTS' | translate }}
    </div>
    <c-table #tagAssignmentsTable mode="query" [options]="tagAssignmentsTableOptions" [singleTemplate]="true" (query)="tagAssignmentsQuery($event)">
      <ng-template let-tagAssignment>
        <tr>
          <td>{{ tagAssignment.type }}</td>
          <td><c-query [query]="tagAssignment.query"></c-query></td>
          <td>
            <div class="p-chips">
              <div class="p-chips-multiple-container">
                <ng-template ngFor let-tag [ngForOf]="tagAssignment.tags">
                  <div class="p-chips-token">
                    <i class="pi pi-tag ml-2"></i>
                    <span>{{ (tag | tag | async)?.label }}</span>
                  </div>
                </ng-template>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </c-table>
  </div>
</div>
