import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ComponentsModule } from 'src/app/components/components.module';
import { CardModule } from 'primeng/card';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ChipModule } from 'primeng/chip';
import { DividerModule } from 'primeng/divider';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChipsModule } from 'primeng/chips';
import { AppointmentDetailComponent } from './appointment-detail.component';
import { DialogModule } from 'primeng/dialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [AppointmentDetailComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    PipesModule,
    DirectivesModule,
    ButtonModule,
    ComponentsModule,
    CardModule,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    ChipModule,
    ProgressSpinnerModule,
    DividerModule,
    AutoCompleteModule,
    ChipsModule,
    DialogModule,
    ConfirmPopupModule,
    OverlayPanelModule,
  ],
  exports: [AppointmentDetailComponent],
  providers: [],
})
export class AppointmentDetailModule {}
