import { Component, OnInit, ViewChild } from '@angular/core';
import { Tab } from '../../tabs/classes/tab';
import { TableOptions, TableQuery } from '../../components/table/table.interfaces';
import { Onboarding } from '../../../common/entities/Onboarding';
import { TableComponent } from '../../components/table/table.component';
import { InputConfiguration, Inputs } from '../../../common/inputs/Inputs';
import { OnboardingFactory } from '../../../common/factories/OnboardingFactory';
import { OnboardingsService } from '../../services/onboardings/onboardings.service';
import { UtilsService } from '../../services/utils/utils.service';
import { TabsService } from '../../tabs/services/tabs.service';
import { ActivatedRoute } from '@angular/router';
import { LanguagesService } from '../../services/languages/languages.service';
import { CollaborationService } from '../../services/collaboration/collaboration.service';

@Component({
  selector: 'app-onboardings',
  templateUrl: './onboardings.component.html',
  styleUrls: ['./onboardings.component.scss'],
})
export class OnboardingsComponent implements OnInit {
  tab: Tab;
  tableOptions: TableOptions<Onboarding>;
  onboardingsIndex = -1;
  edit_mode: boolean = false;

  onboarding: Onboarding;
  languages;
  currentLanguage;
  sidebarTab = 'general';

  executeAccessOptions = [
    { value: 'Public', label: 'Public' },
    { value: 'Platform', label: 'Platform' },
  ];
  readAccessOptions = [...this.executeAccessOptions, { value: 'Tags', label: 'Tags' }];

  @ViewChild('onboardingsTable') onboardingsTable: TableComponent<any>;

  get showEditSidebar(): boolean {
    return this.onboardingsIndex >= 0;
  }
  set showEditSidebar(val: boolean) {
    if (!val) {
      this.onboardingsIndex = -1;
    }
  }

  inputConfig: InputConfiguration;

  inputs: Inputs = {
    '$.internalName': {
      type: 'text',
    },
    '$.steps': {
      header: 'Steps',
      factory: async () => [],
      list: true,
      childFactory: async () => null,
    },
    '$.steps[$index]': {
      factory: async () => {
        return new OnboardingFactory().createOnboardingStep({});
      },
    },
    '$.steps[$index].local.$language': {
      factory: async () => {
        return new OnboardingFactory().createOnboardingStepLocal({});
      },
    },
    '$.steps[$index].local.$language.title': {
      header: 'Title',
      type: 'text',
    },
    '$.steps[$index].local.$language.description': {
      header: 'Description',
      type: 'textarea',
    },
    '$.showOnboarding': {
      header: 'SHOW ONBOARDING STARTING PAGE',
      type: 'switch',
    },
    '$.loginPageImage': {
      header: 'LOGIN PAGE IMAGE DESKTOP',
      type: 'imageasset',
    },
    '$.loginPageImageMobile': {
      header: 'LOGIN PAGE IMAGE MOBILE',
      type: 'imageasset',
    },
    '$.loginPageImageTablet': {
      header: 'LOGIN PAGE IMAGE TABLET',
      type: 'imageasset',
    },
    '$.startingPageImage': {
      header: 'STARTING PAGE IMAGE DESKTOP',
      type: 'imageasset',
    },
    '$.startingPageImageMobile': {
      header: 'STARTING PAGE IMAGE MOBILE',
      type: 'imageasset',
    },
    '$.startingPageImageTablet': {
      header: 'STARTING PAGE IMAGE TABLET',
      type: 'imageasset',
    },
    '$.steps[$index].imageMobile': {
      type: 'imageasset',
    },
    '$.steps[$index].imageTablet': {
      type: 'imageasset',
    },
    '$.steps[$index].imageDesktop': {
      type: 'imageasset',
    },
    '$.forceLogin': {
      header: 'FORCE LOGIN AT THE END OF THE ONBOARDING',
      type: 'switch',
    },
    '$.steps[$index].ctaButton': {
      type: 'ctabutton',
    },
    '$.local.$language': {
      factory: async () => {
        return new OnboardingFactory().createOnboardingLocal({});
      },
    },
    '$.local.$language.description': {
      header: 'Description',
      type: 'textarea',
    },
    '$.local.$language.startButtonLabel': {
      header: 'START BUTTON LABEL',
      type: 'text',
    },
    '$.local.$language.loginPageTitle': {
      header: 'LOGIN PAGE TITLE',
      type: 'text',
    },
    '$.local.$language.loginPageText': {
      header: 'LOGIN PAGE TEXT',
      type: 'textarea',
    },
  };

  constructor(
    private onboardingsService: OnboardingsService,
    public utilsService: UtilsService,
    private tabsService: TabsService,
    private activatedRoute: ActivatedRoute,
    private languagesService: LanguagesService,
    private collaborationService: CollaborationService
  ) {
    this.tab = this.tabsService.register({
      category: 'data',
      loading: true,
      route: this.activatedRoute.snapshot,
    });
  }

  async ngOnInit(): Promise<void> {
    this.languages = (await this.languagesService.getLanguages())?.items.filter((i) => i.selectable).map((i) => i.language);
    const language = this.activatedRoute.snapshot.queryParams.language || 'en';
    this.currentLanguage = this.languages.includes(language) ? language : this.languages[0];
    this.inputConfig = { languages: this.languages };

    this.tableOptions = {
      size: 50,
      columns: [
        { header: 'PAGE_ONBOARDINGS_INTERNAL_NAME', sort: 'internalName' },
        { header: 'PAGE_ONBOARDINGS_ONBOARDING_STEPS', sort: 'steps' },
        { header: 'PAGE_ONBOARDINGS_INTERNAL_DESCRIPTION', sort: 'local.en.description' },
      ],
      filters: [
        { header: 'PAGE_ONBOARDINGS_INTERNAL_NAME', path: 'internalName' },
        { header: 'PAGE_ONBOARDINGS_INTERNAL_DESCRIPTION', path: 'local.en.description' },
      ],
    };
    this.tab.loading = false;
  }

  query(query: TableQuery<Onboarding>) {
    query.result = this.onboardingsService.getOnboardings(query.query);
  }

  async createOnboarding() {
    this.onboarding = await new OnboardingFactory({ ensureLocals: [this.currentLanguage] }).createOnboarding({ internalName: 'New Onboarding' });
    this.collaborationService.registerLocal(`onboarding:${this.onboarding._id}`, this.onboarding, true);
    this.edit_mode = false;
    this.onboardingsIndex = 2;
    this.ensureLanguages(this.languages);
  }

  async deleteOnboarding(item: Onboarding) {
    await this.onboardingsService.deleteOnboarding(item);
    this.onboardingsTable.refresh(true);
  }

  selectOnboarding(item: Onboarding) {
    this.onboarding = item;
    this.ensureLanguages(this.languages);
    this.collaborationService.registerLocal(`onboarding:${this.onboarding._id}`, this.onboarding, true);
    this.edit_mode = true;
    this.onboardingsIndex = 2;
  }

  async save(item: Onboarding) {
    if (!this.edit_mode) {
      await this.onboardingsService.createOnboarding(item);
      this.onboardingsTable.refresh(true);
    } else {
      await this.onboardingsService.updateOnboarding(item);
    }
  }

  ensureLanguages(languages: string[]) {
    for (const language of languages) {
      if (!this.onboarding.local[language]) {
        this.onboarding.local[language] = new OnboardingFactory().createOnboardingLocal({});
      }
    }
  }
}
