<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_EVENT_SECTION_TICKETS_CONFIGURATION' | translate }}
  </div>
  <div class="panel-body">
    <c-event-patch-input jsonpath="$.eventTicketConfiguration.maxEventTickets" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.local.$language.eventRegistration.ticketHeader" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.local.$language.eventRegistration.ticketSubheader" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.local.$language.eventRegistration.dataHeader" [eventVersion]="eventVersion"></c-event-patch-input>
  </div>
</div>

<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_EVENT_TICKET_MAIL_CONFIGURATION' | translate }}
  </div>
  <div class="panel-body">
    <c-event-patch-input jsonpath="$.eventTicketConfiguration.sendTicketConfirmationMail" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input
      [disabled]="showCustomEmailTemplate()"
      jsonpath="$.eventTicketConfiguration.emailTemplate"
      [eventVersion]="eventVersion"
      [inputConfiguration]="inputConfigurationEmailTemplate"
    ></c-event-patch-input>
    <div class="input-group">
      <div class="input-button">
        <div>
          <p-message *ngIf="showCustomEmailMessage()" severity="warn" text="Email Template Customized"></p-message>
        </div>
        <button *hasRight="'events.editContent'" pButton type="button" [label]="'Edit Email Template'" icon="pi icon-ic-add-item" class="p-button" (click)="editCustomEmailTemplate()"></button>
      </div>
      <div class="title">{{ 'PAGE_EMAIL_TEMPLATE_CUSTOMIZE' | translate }}</div>
      <div class="description">{{ 'PAGE_EMAIL_TEMPLATE_CUSTOMIZE_DESCRIPTION' | translate }}</div>
    </div>

    <c-event-patch-input jsonpath="$.eventTicketConfiguration.sendChangedTicketConfirmationMail" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input
      [disabled]="showChangedEmailTemplate()"
      jsonpath="$.eventTicketConfiguration.emailChangedTemplate"
      [eventVersion]="eventVersion"
      [inputConfiguration]="inputChangedConfigurationEmailTemplate"
    ></c-event-patch-input>
    <div class="input-group">
      <div class="input-button">
        <div>
          <p-message *ngIf="showChangedCustomEmailMessage()" severity="warn" text="Changed Email Template Customized"></p-message>
        </div>
        <button
          *hasRight="'events.editContent'"
          pButton
          type="button"
          [label]="'Edit Changed Email Template'"
          icon="pi icon-ic-add-item"
          class="p-button"
          (click)="editChangedCustomEmailTemplate()"
        ></button>
      </div>
      <div class="title">{{ 'PAGE_EMAIL_CHANGED_TEMPLATE_CUSTOMIZE' | translate }}</div>
      <div class="description">{{ 'PAGE_EMAIL_CHANGED_TEMPLATE_CUSTOMIZE_DESCRIPTION' | translate }}</div>
    </div>
  </div>
</div>

<div class="panel">
  <div class="panel-header">{{ 'PAGE_EVENT_TICKET_MESSAGE_CONFIGURATION' | translate }}</div>
  <div class="panel-body">
    <c-event-patch-input jsonpath="$.eventTicketConfiguration.customConfirmationDialog" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input
      jsonpath="$.eventTicketConfiguration.local.$language.customConfirmationDialogHeadline"
      *ngIf="eventVersion.eventTicketConfiguration.customConfirmationDialog"
      [eventVersion]="eventVersion"
    ></c-event-patch-input>
    <c-event-patch-input
      jsonpath="$.eventTicketConfiguration.local.$language.customConfirmationDialogText"
      *ngIf="eventVersion.eventTicketConfiguration.customConfirmationDialog"
      [eventVersion]="eventVersion"
    ></c-event-patch-input>

    <c-event-patch-input jsonpath="$.eventTicketConfiguration.customChangedConfirmationDialog" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input
      jsonpath="$.eventTicketConfiguration.local.$language.customChangedConfirmationDialogHeadline"
      *ngIf="eventVersion.eventTicketConfiguration.customChangedConfirmationDialog"
      [eventVersion]="eventVersion"
    ></c-event-patch-input>
    <c-event-patch-input
      jsonpath="$.eventTicketConfiguration.local.$language.customChangedConfirmationDialogText"
      *ngIf="eventVersion.eventTicketConfiguration.customChangedConfirmationDialog"
      [eventVersion]="eventVersion"
    ></c-event-patch-input>
  </div>
</div>

<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_EVENT_SECTION_TICKETS_REGISTERABLE_TICKETS' | translate }}
    <div class="actions">
      <p-button *hasRight="'events.editContent'" [label]="'PAGE_EVENT_SECTION_TICKETS_ADD' | translate" icon="pi icon-ic-add-item" (click)="addTicket()"></p-button>
      <c-table-options #toc></c-table-options>
    </div>
  </div>
  <c-table #table [data]="eventVersion.eventTickets" [options]="ticketsTableOptions" [tableOptionsComponent]="toc">
    <ng-template let-eventTicket>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="selectTicket(eventTicket)">
        {{ eventTicket.internalName || ('GENERAL_UNNAMED' | translate) }}
      </a>
    </ng-template>
    <ng-template let-eventTicket let-first="first" let-last="last">
      <div *hasRight="'events.editContent'" style="cursor: pointer">
        <i *ngIf="!first" class="pi pi-sort-up p-mr-2" [pTooltip]="'GENERAL_MOVE_UP' | translate" tooltipPosition="left" (click)="eventTicketUp(eventTicket); $event.stopPropagation()"></i>
        <i
          *ngIf="!last"
          class="pi pi-sort-down"
          [ngClass]="{ 'down-padding-left': first }"
          [pTooltip]="'GENERAL_MOVE_DOWN' | translate"
          tooltipPosition="left"
          (click)="eventTicketDown(eventTicket); $event.stopPropagation()"
        ></i>
      </div>
    </ng-template>
  </c-table>
</div>

<p-sidebar [(visible)]="showEditEmailTemplate" styleClass="p-sidebar-st" position="right" [modal]="true" [showCloseIcon]="false">
  <c-sidebar-header [headerTitle]="'Edit Email Template'" (onClose)="showEditEmailTemplate = false"></c-sidebar-header>

  <div class="sidebar-body">
    <div class="input-group compact text" *ngIf="emailTemplateConfiguration?.variables">
      <div class="input">
        <div class="variables">
          <button
            pButton
            *ngFor="let variable of emailTemplateConfiguration?.variables"
            class="p-button-outlined variable-button"
            type="button"
            [label]="'{' + variable + '}'"
            (click)="clipboard.copy('{' + variable + '}')"
            [pTooltip]="'PAGE_EMAIL_TEMPLATE_VARIABLES_TOOLTIP' | translate"
          ></button>
        </div>
      </div>
      <div class="title">Variables</div>
    </div>
    <div *ngIf="emailTemplateConfiguration?.parts">
      <c-event-patch-input
        *ngFor="let config of this.emailTemplateConfiguration.parts | keyvalue : onCompare; let indexOfelement = index"
        jsonpath="$.eventTicketConfiguration.local.$language.customEmailTemplateParts.$parts"
        [eventVersion]="eventVersion"
        [jsonpathParams]="{ parts: config.key, language: currentLanguage }"
        [inputConfiguration]="inputConfigurationEmailTemplateParts[indexOfelement]"
      ></c-event-patch-input>
    </div>
  </div>
  <c-sidebar-footer *hasRight="'events.editContent'" [showDeleteButton]="true" (onDelete)="deleteCustomEmailTemplate(); showEditEmailTemplate = false"></c-sidebar-footer>
</p-sidebar>

<p-sidebar [(visible)]="showTicket" styleClass="p-sidebar-st" position="right" [modal]="true" [appendTo]="'body'" [showCloseIcon]="false">
  <c-sidebar-header
    *ngIf="currentTicket"
    [headerTitle]="'PAGE_EVENT_SECTION_TICKETS_EDIT_TICKET' | translate"
    [headerSubTitle]="currentTicket.internalName"
    (onClose)="showTicket = false"
  ></c-sidebar-header>

  <div class="sidebar-tabs">
    <button [class.active]="ticketEditTab == 'general'" (click)="ticketEditTab = 'general'">General</button>
    <button [class.active]="ticketEditTab == 'appearance'" (click)="ticketEditTab = 'appearance'">Appearance</button>
    <button [class.active]="ticketEditTab == 'warning'" (click)="ticketEditTab = 'warning'">Warning</button>
    <button [class.active]="ticketEditTab == 'advantages'" (click)="ticketEditTab = 'advantages'">Advantages</button>
    <button [class.active]="ticketEditTab == 'codes'" (click)="ticketEditTab = 'codes'">Ticket Codes</button>
  </div>

  <div class="sidebar-body" *ngIf="currentTicket && ticketEditTab == 'general'">
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventTickets[?(@._id=='$ticketId')].internalName" [jsonpathParams]="{ ticketId: currentTicket._id }"></c-event-patch-input>
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventTickets[?(@._id=='$ticketId')].validFrom" [jsonpathParams]="{ ticketId: currentTicket._id }"></c-event-patch-input>
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventTickets[?(@._id=='$ticketId')].validUntil" [jsonpathParams]="{ ticketId: currentTicket._id }"></c-event-patch-input>
    <c-event-patch-input
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].isUpgradeOf"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
      [inputConfiguration]="inputConfigurationUpgrade"
    ></c-event-patch-input>
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventTickets[?(@._id=='$ticketId')].digital" [jsonpathParams]="{ ticketId: currentTicket._id }"></c-event-patch-input>
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventTickets[?(@._id=='$ticketId')].onSite" [jsonpathParams]="{ ticketId: currentTicket._id }"></c-event-patch-input>
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventTickets[?(@._id=='$ticketId')].maxEventSlots" [jsonpathParams]="{ ticketId: currentTicket._id }"></c-event-patch-input>
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventTickets[?(@._id=='$ticketId')].maxAttendees" [jsonpathParams]="{ ticketId: currentTicket._id }"></c-event-patch-input>
    <c-event-patch-input
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].readAccess"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
      [inputConfiguration]="inputConfigurationEventTicketReadAccess"
    ></c-event-patch-input>

    <c-event-patch-input
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].sendRegistrationToExternalSystem"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
    ></c-event-patch-input>
    <c-event-patch-input
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].showInEventRegistrationProcess"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
    ></c-event-patch-input>
  </div>

  <div class="sidebar-body" *ngIf="currentTicket && ticketEditTab == 'advantages'">
    <c-event-patch-input
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].local.$language.advantages"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
    ></c-event-patch-input>
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventTickets[?(@._id=='$ticketId')].useWalletAdvantages" [jsonpathParams]="{ ticketId: currentTicket._id }"></c-event-patch-input>
    <c-event-patch-input
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].local.$language.walletAdvantages"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
    ></c-event-patch-input>
  </div>

  <div class="sidebar-body" *ngIf="currentTicket && ticketEditTab == 'codes'">
    <c-event-patch-input
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].codes"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
      [inputConfiguration]="ticketCodesInputConfiguration"
    ></c-event-patch-input>
  </div>

  <div class="sidebar-body" *ngIf="currentTicket && ticketEditTab == 'appearance'">
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventTickets[?(@._id=='$ticketId')].local.$language.title" [jsonpathParams]="{ ticketId: currentTicket._id }"></c-event-patch-input>
    <c-event-patch-input
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].local.$language.walletTitle"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
    ></c-event-patch-input>
    <c-event-patch-input
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].local.$language.description"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
    ></c-event-patch-input>
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventTickets[?(@._id=='$ticketId')].icon" [jsonpathParams]="{ ticketId: currentTicket._id }"></c-event-patch-input>
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventTickets[?(@._id=='$ticketId')].backgroundImage" [jsonpathParams]="{ ticketId: currentTicket._id }"></c-event-patch-input>
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventTickets[?(@._id=='$ticketId')].logo" [jsonpathParams]="{ ticketId: currentTicket._id }"></c-event-patch-input>
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventTickets[?(@._id=='$ticketId')].backgroundColor" [jsonpathParams]="{ ticketId: currentTicket._id }"></c-event-patch-input>
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventTickets[?(@._id=='$ticketId')].foregroundColor" [jsonpathParams]="{ ticketId: currentTicket._id }"></c-event-patch-input>
    <c-event-patch-input
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].ticketNameBackgroundColor"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
    ></c-event-patch-input>
    <c-event-patch-input
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].ticketNameForegroundColor"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
    ></c-event-patch-input>
    <c-event-patch-input
      header="Show QR Code"
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].showQRCode"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
    ></c-event-patch-input>
    <c-event-patch-input
      header="Show User Info"
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].showUserInfo"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
    ></c-event-patch-input>
    <c-event-patch-input
      header="Show Dates"
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].showDates"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
    ></c-event-patch-input>
  </div>

  <div class="sidebar-body" *ngIf="currentTicket && ticketEditTab == 'warning'">
    <c-event-patch-input
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].local.$language.warning"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
      [inputConfiguration]="inputConfigurationWarning"
    ></c-event-patch-input>
    <c-event-patch-input
      [eventVersion]="eventVersion"
      jsonpath="$.eventTickets[?(@._id=='$ticketId')].warningCTA"
      [jsonpathParams]="{ ticketId: currentTicket._id }"
      [inputConfiguration]="inputConfigurationWarningCTA"
    ></c-event-patch-input>
  </div>

  <c-sidebar-footer
    *hasRight="'events.editContent'"
    [autoSaveInfo]="true"
    [showDeleteButton]="true"
    (onClose)="showTicket = false"
    (onDelete)="onDeleteTicket(currentTicket); showTicket = false"
  ></c-sidebar-footer>
</p-sidebar>

<p-sidebar id="showChangedEdit" [(visible)]="showChangedEditEmailTemplate" styleClass="p-sidebar-st" position="right" [modal]="true" [showCloseIcon]="false">
  <c-sidebar-header [headerTitle]="'Edit Channged Email Template'" (onClose)="showChangedEditEmailTemplate = false"></c-sidebar-header>

  <div class="sidebar-body">
    <div class="input-group compact text" *ngIf="emailChangedTemplateConfiguration?.variables">
      <div class="input">
        <div class="variables">
          <button
            pButton
            *ngFor="let variable of emailChangedTemplateConfiguration?.variables"
            class="p-button-outlined variable-button"
            type="button"
            [label]="'{' + variable + '}'"
            (click)="clipboard.copy('{' + variable + '}')"
            [pTooltip]="'PAGE_EMAIL_TEMPLATE_VARIABLES_TOOLTIP' | translate"
          ></button>
        </div>
      </div>
      <div class="title">Variables</div>
    </div>
    <div *ngIf="emailChangedTemplateConfiguration?.parts">
      <c-event-patch-input
        *ngFor="let config of this.emailChangedTemplateConfiguration.parts | keyvalue : onCompare; let indexOfelement = index"
        jsonpath="$.eventTicketConfiguration.local.$language.customChangedEmailTemplateParts.$parts"
        [eventVersion]="eventVersion"
        [jsonpathParams]="{ parts: config.key, language: currentLanguage }"
        [inputConfiguration]="inputChangedConfigurationEmailTemplateParts[indexOfelement]"
      ></c-event-patch-input>
    </div>
  </div>
  <c-sidebar-footer [showDeleteButton]="true" (onDelete)="deleteChangedCustomEmailTemplate(); showChangedEditEmailTemplate = false"></c-sidebar-footer>
</p-sidebar>
