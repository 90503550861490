import { RBAC } from './../../../common/entities/RBAC';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';

import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';

import { EventsService } from 'src/app/services/events/events.service';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Event, eventPhase, EventPhase } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Language } from 'src/common/entities/Language';

import { AbstractFactory } from 'src/common/factories/AbstractFactory';
import { EventFactory } from 'src/common/factories/EventFactory';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { Tab } from 'src/app/tabs/classes/tab';
import { CRMEvent } from 'src/common/entities/CRMEvent';
import { Factory } from 'src/common/factories/Factory';
import { isGoToWebinarSystemPlugin } from 'src/common/entities/Configuration';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { PostEventsBody } from '../../../common/api/v1/events/PostEvents';
import { DomainService } from '../../services/domain/domain.service';
import { AuthService } from 'src/app/services/auth/auth.service';

type SubTab = 'status' | 'basic-information' | 'layout' | 'keytopics' | 'products' | 'sessions' | 'tickets' | 'custom-fields' | 'slots' | 'chats' | 'stages' | 'maps';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  providers: [ConfirmationService],
})
export class EventComponent implements OnInit, OnDestroy {
  eventId: string = null;
  event: Event | null = null;
  eventVersion: EventVersion;

  crmEvent: CRMEvent | null = null;
  crmEventSubidMode: 'none' | 'eventticket' | 'type' = 'none';
  crmEventSubidModeOptions: { label: string; value: string }[] = [
    { label: '-', value: 'none' },
    { label: 'For each event ticket', value: 'eventticket' },
    { label: 'By digital or on site participation', value: 'type' },
  ];

  currentLanguage: string = null;
  eventLanguageOptions: MenuItem[] = [];
  activeLanguages: Language[] = [];

  showManageLanguages = false;
  showVersions = false;
  showConfiguration = false;
  showCRMConfiguration = false;
  showPreview = false;

  externalEventConnectInProgress = false;
  showGoToWebinarButton = false;
  showGoToWebinarConnectDialog = false;
  externalEventsLoading = false;
  externalEvents: RBAC<Event>[] = [];
  externalEventLinks: Record<string, string> = {};
  externalEventsQuery = {
    fromTime: new Date(),
    toTime: new Date(),
  };

  working = true;
  showAccess = false;
  currentSubTab: SubTab = 'status';

  subscriptions: Subscription[] = [];

  eventTypeOptions: { label: string; value: string }[] = [];
  eventSerieOptions: { label: string; value: string }[] = [];
  eventTimeZoneOptions: { label: string; value: string }[] = [];

  hasEventCreateRight: boolean = false;
  hasEventEditRight: boolean = false;
  hasEventEditContentRight: boolean = false;
  hasEventPublishRight: boolean = false;

  hasTemplateCreateRight: boolean = false;
  hasTemplateEditRight: boolean = false;

  tab: Tab;

  // eventVersionTableOptions: TableOptions<EventVersion>

  displayRights = false;
  rbac_resouce: string;
  templateMode = false;

  cloneEvent: PostEventsBody | null = null;

  get showCloneEventModal(): boolean {
    return !!this.cloneEvent;
  }

  set showCloneEventModal(val: boolean) {
    if (!val) {
      this.cloneEvent = null;
    }
  }

  cloneButtonModel: MenuItem[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private collaborationService: CollaborationService,
    private languagesService: LanguagesService,
    private utilsService: UtilsService,
    private eventsService: EventsService,
    private tabsService: TabsService,
    private domainService: DomainService,
    private configurationService: ConfigurationService,
    private messageService: MessageService,
    private authService: AuthService
  ) {
    this.templateMode = window.location.href.includes('/templates');
    this.eventId = this.activatedRoute.snapshot.params.eventId;

    this.tab = this.tabsService.register({
      category: 'events',
      loading: true,
      route: this.activatedRoute.snapshot,
      titlePrefix: 'Edit',
      parent: '/' + (this.templateMode ? 'templates' : 'events'),
    });
  }

  async ngOnInit(): Promise<void> {
    this.hasEventCreateRight = await this.authService.hasRight('events:' + this.eventId + '$' + 'events.create');
    this.hasEventPublishRight = await this.authService.hasRight('events:' + this.eventId + '$' + 'events.publish');
    this.hasEventEditRight = await this.authService.hasRight('events:' + this.eventId + '$' + 'events.edit');
    this.hasEventEditContentRight = await this.authService.hasRight('events:' + this.eventId + '$' + 'events.editContent');
    this.hasTemplateCreateRight = await this.authService.hasRight('templates:' + this.eventId + '$' + 'templates.create');
    this.hasTemplateEditRight = await this.authService.hasRight('templates:' + this.eventId + '$' + 'templates.edit');

    this.externalEventsQuery.toTime.setMonth(new Date().getMonth() + 1);

    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((queryParams) => {
        this.currentSubTab = queryParams.tab || 'status';
      })
    );

    if (this.eventId === 'new') {
      this.tab.title = 'New Event';
      this.event = await AbstractFactory.get(EventFactory, { newIds: true }).createEvent({ eventType: 'DigitalEvent' });
      await this.setQueryParams({ language: this.event.currentEventVersion.languages[0], eTz: 'Europe/Berlin' });
      this.tab.loading = false;
    } else if (this.eventId) {
      await this.load();
    }

    this.eventTypeOptions = (await this.eventsService.getEventTypes()).items.map((eventType) => ({
      label: eventType.internalName,
      value: eventType._id,
    }));
    this.eventSerieOptions = [
      {
        label: 'None',
        value: null,
      },
    ].concat(
      (await this.eventsService.getEventSeries()).items.map((eventSerie) => ({
        label: eventSerie.internalName,
        value: eventSerie._id,
      }))
    );

    this.showGoToWebinarButton = this.hasEventEditRight
      ? Factory.configuration()
          .systemPluginsConfiguration(this.configurationService.configuration().systemPlugins)
          .plugins.some((plugin) => isGoToWebinarSystemPlugin(plugin))
      : false;

    this.cloneButtonModel = [
      {
        label: await this.utilsService.translate('GENERAL_DUPLICATE_EVENT'),
        icon: 'pi icon-ic-clone',
        command: () => {
          this.openCloneEventModal(false);
        },
      },
      {
        label: await this.utilsService.translate('PAGE_EVENT_DUPLICATE_EVENT_AS_TEMPLATE'),
        icon: 'pi pi-save',
        command: () => {
          this.openCloneEventModal(true);
        },
      },
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  listenToEventVersionPatch(): void {
    this.subscriptions.push(
      this.eventsService.lastEventVersionPatch(this.eventVersion._id).subscribe(async (result) => {
        // Activate another language if current language not exists any more
        if (this.eventVersion && !this.eventVersion.languages.includes(this.currentLanguage)) {
          await this.setQueryParams({ language: this.eventVersion.languages[0] });
        }
      })
    );
  }

  async setQueryParams(queryParams: Params): Promise<void> {
    await this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams,
    });
  }

  setExternalEventsQueryValue(date: Date, key: string): void {
    this.externalEventsQuery[key] = date;
  }

  async searchExternalEvents(): Promise<void> {
    this.externalEventsLoading = true;

    // ISO8601 format without milliseconds
    const fromTime = this.externalEventsQuery.fromTime.toISOString().split('.')[0] + 'Z';
    const toTime = this.externalEventsQuery.toTime.toISOString().split('.')[0] + 'Z';

    try {
      const result = await this.eventsService.getEvents({
        plugin: 'GoToWebinar',
        fromTime,
        toTime,
        limit: 200,
      });
      this.externalEvents = result.items;
      this.externalEvents.forEach((event) => {
        if (event._id) {
          this.externalEventLinks[event.externalEvent.externalEventId] = event._id;
        }
      });
    } catch (error) {
      console.error(error);
      const summary = typeof error.error?.message === 'string' ? error.error.message : 'Something went wrong';
      this.messageService.add({ severity: 'error', summary });
    }

    this.externalEventsLoading = false;
  }

  async connectExternalEvent(event: Event): Promise<void> {
    this.externalEventConnectInProgress = true;

    try {
      const result = await this.eventsService.connectEventToExternalEvent(this.eventId, {
        plugin: 'GoToWebinar',
        externalEventId: event.externalEvent.externalEventId,
      });
      this.messageService.add({ severity: 'success', summary: 'Event connected successfully' });
      this.externalEventLinks[event.externalEvent.externalEventId] = result.body._id;
      this.event.externalEvent = result.body.externalEvent;
    } catch (error) {
      console.error(error);
      const summary = typeof error.error?.message === 'string' ? error.error.message : 'Something went wrong';
      this.messageService.add({ severity: 'error', summary });
    }

    this.externalEventConnectInProgress = false;
  }

  async load(): Promise<void> {
    let initialLoad = true;

    this.subscriptions.push(
      this.eventsService.subscribeEvent(this.eventId).subscribe(async (event: RBAC<Event>) => {
        this.event = event;

        if (!this.templateMode) {
          this.rbac_resouce = `events:${event._id}`;
        } else {
          this.rbac_resouce = `templates:${event._id}`;
        }

        this.tab.title = this.event.internalName;

        if (initialLoad) {
          initialLoad = false;

          const lastEventVersion = (
            await this.eventsService.getEventVersions(this.eventId, {
              orderBy: 'version',
              orderDirection: -1,
              limit: 1,
            })
          ).items[0];

          if (lastEventVersion) {
            await this.loadEventVersion(lastEventVersion._id);
          }

          let queryParams: any = null;
          this.currentLanguage = this.activatedRoute.snapshot.queryParamMap.get('language');

          if (this.eventVersion?.languages?.length > 0 && (!this.currentLanguage || !this.eventVersion.languages.includes(this.currentLanguage))) {
            // Activate events language by route
            queryParams = {
              language: this.eventVersion.languages[0],
            };
          }

          if (this.eventVersion?.eventTimeZone?.length) {
            if (queryParams) {
              queryParams.eTz = this.eventVersion.eventTimeZone;
            } else {
              queryParams = {
                eTz: this.eventVersion.eventTimeZone,
              };
            }
          }

          if (queryParams) {
            await this.setQueryParams(queryParams);
          }

          this.tab.loading = false;
        }
      })
    );
  }

  async deleteEvent(): Promise<void> {
    this.working = true;

    try {
      if (this.event) {
        if (this.eventId !== 'new') {
          this.event = await this.eventsService.deleteEvent(this.event);
          this.router.navigate(['/events'], {
            replaceUrl: true,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }

    this.working = false;
  }

  async confirmDeleteMessage(event): Promise<void> {
    this.confirmationService.confirm({
      target: event.originalEvent.target,
      message: await this.utilsService.translate('PAGE_EVENT_DELETE_CONFIRM_MESSAGE'),
      icon: 'pi pi-exclamation-triangle',
      accept: this.deleteEvent,
    });
  }

  eventPhase(): EventPhase {
    return this.event ? eventPhase(this.event) : 'DRAFT';
  }

  async work(wp: { event?: Promise<Event> | (() => Promise<Event>); eventVersion?: Promise<EventVersion> | (() => Promise<EventVersion>) }): Promise<void> {
    this.working = true;

    try {
      // its important that event version is done first, because of publishing
      if (wp.event) {
        this.event = null;
      }
      if (wp.eventVersion) {
        this.eventVersion = null;
      }

      if (wp.eventVersion) {
        this.eventVersion = await (typeof wp.eventVersion == 'function' ? wp.eventVersion() : wp.eventVersion);
      }
      if (wp.event) {
        this.event = await (typeof wp.event == 'function' ? wp.event() : wp.event);
      }

      this.checkDisable();
    } catch (err) {
      console.error(err);
    }

    this.working = false;
  }

  async loadEventVersion(eventVersionId: string): Promise<void> {
    await this.work({ eventVersion: () => this.eventsService.getEventVersion(this.eventId, eventVersionId) });
  }

  async publishEventVersion(eventVersionId: string): Promise<void> {
    await this.work({
      eventVersion: this.eventsService.publishEventVersion(this.eventId, eventVersionId),
      event: () => this.eventsService.getEvent(this.eventId), // as function, so that it is executed after eventVersion
    });
  }

  async saveEventVersion(eventVersionId: string): Promise<void> {
    await this.work({
      eventVersion: this.eventsService.saveEventVersion(this.eventId, eventVersionId),
      event: this.eventsService.getEvent(this.eventId),
    });
  }

  async forkEventVersion(eventVersionId: string): Promise<void> {
    await this.work({ eventVersion: () => this.eventsService.forkEventVersion(this.eventId, eventVersionId) });
  }

  openCloneEventModal(saveAsTemplate: boolean): void {
    this.cloneEvent = {
      eventType: this.event.eventType,
      internalName: '',
      shortId: '',
      template: saveAsTemplate,
      fromTemplate: {
        event: this.eventId,
        eventVersion: this.eventVersion._id,
      },
    };
  }

  duplicateEvent(): void {
    this.eventsService
      .createEvent(this.cloneEvent)
      .then(() => {
        if (this.cloneEvent.template) {
          this.messageService.add({ severity: 'success', summary: 'New template created' });
        } else {
          this.messageService.add({ severity: 'success', summary: 'New event created' });
        }
        this.cloneEvent = null;
      })
      .catch((error) => {
        console.error(error);
        const summary = typeof error.error?.message === 'string' ? error.error.message : 'Something went wrong';
        this.messageService.add({ severity: 'error', summary });
      });
  }

  async saveConfiguration(): Promise<void> {
    await this.work({ event: this.eventsService.updateEvent(this.event) });
    this.showConfiguration = false;
  }

  async openCRMConfiguration(): Promise<void> {
    this.working = true;

    try {
      this.showCRMConfiguration = true;

      try {
        this.crmEvent = await new EventFactory().createCRMEvent(await this.eventsService.getCRMEvent(this.event._id));
      } catch (err) {
        if (err.error.errorTag === 'CRMEVENT_NOT_FOUND') {
          this.crmEvent = await new EventFactory().createCRMEvent({
            event: this.event._id,
          });
        } else {
          throw err;
        }
      }

      this.crmEventSubidMode = 'none';

      if (this.crmEvent.data?.subIds.find((s) => !!s.eventTicket)) {
        this.crmEventSubidMode = 'eventticket';
      } else if (this.crmEvent.data?.subIds.find((s) => !!s.type)) {
        this.crmEventSubidMode = 'type';
      }
    } catch (err) {
      console.error(err);
    }
    this.working = false;
  }

  crmEventSubidModeChange(): void {
    if (this.crmEventSubidMode === 'none') {
      this.crmEvent.data.subIds = [];
    } else if (this.crmEventSubidMode === 'eventticket') {
      this.crmEvent.data.subIds = this.event.currentEventVersion.eventTickets.map((t) => ({
        eventTicket: t._id,
        id: '',
        type: undefined,
        plain: null,
        ...(this.crmEvent.data.subIds.find((s) => s.eventTicket === t._id) || {}),
      }));
    } else if (this.crmEventSubidMode === 'type') {
      this.crmEvent.data.subIds = ['digital', 'onSite'].map((t) => ({
        eventTicket: undefined,
        id: '',
        type: t as 'digital' | 'onSite',
        plain: null,
        ...(this.crmEvent.data.subIds.find((s) => s.type === t) || {}),
      }));
    }
  }

  async saveCRMConfiguration(): Promise<void> {
    this.working = true;

    try {
      await this.eventsService.updateCRMEvent(this.crmEvent);
    } catch (err) {
      console.error(err);
    }

    this.showCRMConfiguration = false;
    this.working = false;
  }

  checkDisable(): void {
    if (this.eventVersion.dirty && (this.hasEventEditContentRight || (this.templateMode && this.hasTemplateEditRight))) {
      this.collaborationService.enable(`eventversion:${this.eventVersion._id}`);
    } else {
      this.collaborationService.disable(`eventversion:${this.eventVersion._id}`);
    }
  }

  async fillLanguageOptions(): Promise<void> {
    this.eventLanguageOptions = (
      await Promise.all(
        (this.eventVersion?.languages || []).map(async (eventLanguage) => ({
          label: eventLanguage.toUpperCase() + ' | ' + (await this.languagesService.asPromise(eventLanguage)).languageName.toUpperCase(),
          command: () => this.setQueryParams({ language: eventLanguage }),
        }))
      )
    ).sort((a, b) => a.label.localeCompare(b.label));

    this.eventLanguageOptions.push({ separator: true });

    this.eventLanguageOptions.push({
      label: 'Manage Languages...',
      command: async () => {
        this.activeLanguages = await this.languagesService.getActiveLanguagesAsPromise();
        this.showManageLanguages = true;
      },
    });
  }

  async onActiveLanguageChange(languages: string[]): Promise<void> {
    // One language has to stay
    if (!languages || languages.length === 0) {
      languages = ['en'];
    }

    this.eventVersion.languages = languages;

    await this.eventsService.patch(this.eventVersion, {
      command: 'set',
      jsonpath: `$.languages`,
      value: languages,
    });
  }

  eventURL(): string {
    const url = this.domainService.findFirstFrontendDomain();
    return `${url}/events/${this.event?.shortId || this.event?._id}`;
  }
}
