import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { BannerPageModuleFactory } from './../../factories/pagemodules/BannerPageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const bannerPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('Banner'),
  "$.pageModules[?(@.pageModuleType=='Banner' && @._id=='$pageModuleId')].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='Banner' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new BannerPageModuleFactory().createBannerPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='Banner' && @._id=='$pageModuleId')].local.$language.title": {},
  "$.pageModules[?(@.pageModuleType=='Banner' && @._id=='$pageModuleId')].local.$language.text": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='Banner' && @._id=='$pageModuleId')].local.$language.image": {},
  "$.pageModules[?(@.pageModuleType=='Banner' && @._id=='$pageModuleId')].local.$language.background": {},
  "$.pageModules[?(@.pageModuleType=='Banner' && @._id=='$pageModuleId')].backgroundColor": {},
};
