import { Page } from 'src/common/entities/Page';
import { InputConfiguration } from 'src/common/inputs/Inputs';
import { EventVersion } from 'src/common/entities/EventVersion';
import { GridPageModule } from './../../../../common/entities/pagemodules/GridPageModule';
import { Component, Input, OnInit } from '@angular/core';
import { Event } from 'src/common/entities/Event';

@Component({
  selector: 'app-grid-page-module-edit',
  templateUrl: './grid-page-module-edit.component.html',
  styleUrls: ['./grid-page-module-edit.component.scss'],
})
export class GridPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: GridPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathes: string[] = ['$.event', '$.gridType', '$.initGrid', '$.showRelatedDocuments', '$.anchor'];

  inputInteractionJsonpathes: string[] = ['$.showReactions', '$.parallelReactions', '$.chatWithoutLogin', '$.showPolls'];

  agendaJsonpathes: string[] = ['$.viewType', '$.showFilterOptions', '$.showAgendaCalendarButton', '$.showAgendaShareButton', '$.agendaShareButtonTargets', '$.showCurrentTime'];

  sessionsJsonpathes: string[] = [
    '$.speakerDisplayType',
    '$.showTime',
    '$.showCalendarButton',
    '$.showShareButton',
    '$.shareButtonTargets',
    '$.showBookmarkButton',
    '$.showDescription',
    '$.showCountdown',
    '$.showStickyPlayerOnMobile',
    '$.showMapButton',
  ];

  initialized: boolean = false;
  tab = 'general';

  accessInputConfiguration: InputConfiguration;

  async ngOnInit() {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }

    this.initialized = true;
  }
}
