import { TextEventStagePageModuleAttributes } from './../../entities/pagemodules/TextEventStagePageModule';
import { HeadlineType } from './../../entities/Attributes';
import { ButtonSpacingTopDefaultBreakpointValue, StageHeightDefaultBreakpointValue } from './DefaultBreakponitValue';
import { BreakpointValue } from './../../entities/Breakpoint';
import { TextEventStagePageModule, TextEventStagePageModuleLocal } from '../../entities/PageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class TextEventStagePageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public textEventStagePageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): TextEventStagePageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2'
    };
  }

  public async textEventStagePageModuleLocal(values?: any): Promise<TextEventStagePageModuleLocal> {
    return {
      smallHeadline: values?.smallHeadline || '',
      smallHeadline_attr: this.textEventStagePageModuleAttributes(values?.smallHeadline_attr, 'no headline'),
      text: values?.text || '',
      subtext: values?.subtext || '',
      background: values?.background || null
    };
  }

  public async textEventStagePageModuleButton(values?: any) {
    return {
      _id: await this.id(values?._id),
      ctaButton: values?.ctaButton || null
    };
  }

  public textEventStagePageModuleStageHeight(defaultValues: BreakpointValue<number>, values?: any) {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge
    };
  }

  public textEventStagePageModuleButtonSpacingTopBreakpoint(defaultValues: BreakpointValue<number>, values?: any) {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge
    };
  }

  public async textEventStagePageModule(values: any): Promise<TextEventStagePageModule> {
    if (values?.background) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.background = item.background || values.background;
      });
    }

    const pageModule = {
      ...(await this.baseValues(values)),
      layout: values?.layout || 'Default',
      pageModuleType: 'TextEventStage',
      showShareButton: values?.showShareButton || false,
      showParticipatingTag: typeof values?.showParticipatingTag === 'boolean' ? values?.showParticipatingTag : true,
      showCalendarButton: values?.showCalendarButton || false,
      background: undefined,
      ctaButton: values?.ctaButton || null,
      local: await this.local(values, val => this.textEventStagePageModuleLocal(val)),
      ctalist: values?.ctalist || [],
      ctalistInteraction: values?.ctalistInteraction || [],
      showCountdown: values.showCountdown || false,
      discoverMore: values?.discoverMore || false,
      buttonSpacingTopBreakpoint: values?.buttonSpacingTopBreakpoint
        ? values?.buttonSpacingTopBreakpoint
        : this.textEventStagePageModuleButtonSpacingTopBreakpoint(ButtonSpacingTopDefaultBreakpointValue, values?.buttonSpacingTopBreakpoint),
      stageHeight: values?.stageHeight ? values?.stageHeight : this.textEventStagePageModuleStageHeight(StageHeightDefaultBreakpointValue, values?.stageHeight)
    };

    return { ...pageModule, pageModuleType: 'TextEventStage' };
  }
}
