export const sortByInternalName = (a: any, b: any) => {
  if (!a.internalName) {
    a.internalName = '<Unnamed>';
  }
  if (!b.internalName) {
    b.internalName = '<Unnamed>';
  }
  const nameA = a.internalName.toUpperCase();
  const nameB = b.internalName.toUpperCase();
  if (nameA < nameB) {
    return 1;
  }
  if (nameA > nameB) {
    return -1;
  }

  return 0;
};

export const getSortFunction = (path: string) => {
  return (a: any, b: any) => {
    const nameA = normalizeString(a.page[path]);
    const nameB = normalizeString(b.page[path]);
    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }
    return 0;
  };
};

const normalizeString = (str: string) => {
  return str ? str.toUpperCase() : '';
};
