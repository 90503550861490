<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'GENERAL_USERS' | translate }}</h1>
  </div>
  <div class="tab-menu"></div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_USERS' | translate }}
      <div class="status">
        <c-table-status #tst></c-table-status>
      </div>
      <div class="actions">
        <c-table-options #toc></c-table-options>
      </div>
    </div>
    <div class="search">
      <input placeholder="Search user ..." pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
    </div>
    <c-table
      mode="query"
      [options]="tableOptions"
      [tableOptionsComponent]="toc"
      [tableStatusComponent]="tst"
      (query)="query($event)"
      (clearFilterList)="searchText = ''"
      [baseRoute]="'/users'"
      [routePathProperty]="'_id'"
    >
      <ng-template let-user>
        <div
          *hasRight="{
            resource: 'users:' + user._id,
            rights: ['users.read', 'users.edit']
          }"
          [routerLink]="[user._id]"
        >
          {{ user.lastName }}
        </div>
        <span
          *hasNoRight="{
            resource: 'users:' + user._id,
            rights: ['users.read', 'users.edit']
          }"
        >
          {{ user.lastName }}
        </span>
      </ng-template>
      <ng-template let-user>
        <div
          *hasRight="{
            resource: 'users:' + user._id,
            rights: ['users.read', 'users.edit']
          }"
        >
          {{ user.firstName }}
        </div>
        <span
          *hasNoRight="{
            resource: 'users:' + user._id,
            rights: ['users.read', 'users.edit']
          }"
        >
          {{ user.firstName }}
        </span>
      </ng-template>
      <ng-template let-user>{{ user.email }}</ng-template>
      <ng-template let-user>{{ user.organization }}</ng-template>
      <ng-template let-user>{{ user.country }}</ng-template>
      <ng-template let-user>
        <p-checkbox [ngModel]="user.active" [binary]="true" [disabled]="true" (click)="linkClick($event)"></p-checkbox>
      </ng-template>
      <ng-template let-user>{{ (user.userType ? 'PAGE_USER_USERTYPE_' + user.userType.toUpperCase() : '') | translate }}</ng-template>
      <ng-template let-user>{{ user.registeredAt | date : 'short' }}</ng-template>
    </c-table>
  </div>
</div>
