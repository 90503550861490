import { CTAButton } from '../CTAButton';
import { PageModule } from './PageModule';
import { TextAttributes } from './../Attributes';

export interface BannerPageModule extends PageModule {
  pageModuleType: 'Banner';
  background?: string;
  backgroundColor: string;
  local: {
    [language: string]: {
      title: string;
      title_attr?: BannerPageModuleAttributes;
      text: string;
      image: string;
    };
  };
  ctaButton?: CTAButton;
}

export type BannerPageModuleAttributes = TextAttributes & {};

export function isBannerPageModule(object: any): object is BannerPageModule {
  return object?.pageModuleType === 'Banner';
}
