import { TextAttributes } from './../Attributes';
import { BreakpointValue } from './../Breakpoint';
import { CTAButton } from '../CTAButton';
import { PageModule } from './PageModule';

export const textEventStage2Layout = ['Default', 'BackgroundFocused'] as const;
export type Stage2TextEventStagePageModuleLayout = (typeof textEventStage2Layout)[number];

export interface Stage2TextEventStagePageModule extends PageModule {
  pageModuleType: 'TextEventStage2';
  layout: Stage2TextEventStagePageModuleLayout;
  local: { [language: string]: Stage2TextEventStagePageModuleLocal };
  ctalist?: Stage2TextEventStageButton[];
  ctalistInteraction?: Stage2TextEventStageButton[];
  showCountdown: boolean;
  discoverMore?: boolean;
  ctaAreaHeight?: BreakpointValue<number>;
  contentAreaHeight?: BreakpointValue<number>;
  topAreaHeight?: BreakpointValue<number>;
}
export interface Stage2TextEventStageButton {
  _id: string;
  ctaButton: CTAButton;
}
export interface Stage2TextEventStagePageModuleLocal {
  smallHeadline: string;
  smallHeadline_attr?: Stage2TextEventStagePageModuleAttributes;
  text: string;
  subtext: string;
  background: string;
}

export type Stage2TextEventStagePageModuleAttributes = TextAttributes & {};

export function isTextEventStage2PageModule(object: any): object is Stage2TextEventStagePageModule {
  return object?.pageModuleType === 'TextEventStage2';
}
