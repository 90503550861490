import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import Quill from 'quill';
import { take } from 'rxjs/operators';

import { overrideQuill } from './quill-overrides';

@Component({
  selector: 'c-rich-text-input',
  templateUrl: './rich-text-input.component.html',
  styleUrls: ['./rich-text-input.component.scss'],
})
export class RichTextInputComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('htmlEditor')
  public htmlEditor: ElementRef<HTMLDivElement>;

  @ViewChild('toolbar')
  public toolbar: ElementRef<HTMLDivElement>;

  @ViewChild('toolbarTemplate')
  public toolbarTemplate: ElementRef<HTMLDivElement>;

  @Input()
  value: string;

  @Input()
  showCloseButton: boolean;

  @Input()
  disabled: boolean = false;

  @Output()
  valueChanged: EventEmitter<any> = new EventEmitter<any>();

  quill: Quill;

  @Output()
  focus: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  blur: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onCloseButton: EventEmitter<void> = new EventEmitter<void>();

  private valueReceived = new EventEmitter<void>();

  constructor() {
    this.onBlur = this.onBlur.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  ngOnInit(): void {
    overrideQuill();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && this.quill) {
      if (changes.disabled.currentValue === true) {
        this.quill.disable();
      } else {
        this.quill.enable();
      }
    }

    if (changes.value) {
      this.valueReceived.emit();
    }
  }

  ngAfterViewInit(): void {
    this.initQuill();
  }

  onClose() {
    this.onCloseButton.emit();
  }

  private initQuill() {
    this.toolbar.nativeElement.innerHTML = this.toolbarTemplate.nativeElement.innerHTML;

    this.quill = new Quill(this.htmlEditor.nativeElement, {
      theme: 'custom-theme',
      readOnly: this.disabled,
      modules: {
        toolbar: {
          container: this.toolbar.nativeElement,
        },
      },
    });

    (this.quill as any).__reinit = () => {
      this.reinitializeEditor();
    };

    this.quill.root.innerHTML = this.value;

    this.quill.scroll.domNode.addEventListener('focus', this.onFocus);
    this.quill.on('editor-change', this.onEditorChange);
    this.quill.scroll.domNode.addEventListener('blur', this.onBlur);
  }

  private reinitializeEditor() {
    this.quill.scroll.domNode.removeEventListener('focus', this.onFocus);
    this.quill.off('editor-change', this.onEditorChange);
    this.quill.scroll.domNode.removeEventListener('blur', this.onBlur);

    // this will update parent components with latest value
    this.onBlur();

    // we want to re-init quill with latest value
    this.valueReceived
      .pipe(take(1))
      .toPromise()
      .then(() => this.initQuill());
  }

  private onEditorChange() {
    if (this.quill.root.innerText?.trim()) {
      this.valueChanged.emit(this.quill.root.innerHTML);
    } else {
      // just emit empty string, if text is empty, omit any empty HTML
      this.valueChanged.emit('');
    }
  }

  private onFocus() {
    this.focus.emit();
  }

  private onBlur() {
    this.blur.emit();
  }
}
