import { Factory } from '../../factories/Factory';
import { EventVersion } from '../../entities/EventVersion';
import { Patch } from '../../patch/Patch';
import { Inputs } from '../Inputs';

export const eventVersionStagesInputs: Inputs = {
  '$.stages': {
    factory: async () => [],
  },
  '$.stages[?(@._id=="$stageId")]': {
    beforePatching: async (obj: EventVersion, patch: Patch) => {
      if (patch.command === 'delete' && patch.jsonpathParams?.stageId) {
        const patches: Patch[] = [];

        for (const session of obj.sessions) {
          const index = session.stages.indexOf(patch.jsonpathParams.stageId);
          if (index >= 0) {
            patches.push({
              command: 'delete',
              jsonpath: "$.sessions[?(@._id=='$sessionId')].stages[$index]",
              jsonpathParams: {
                sessionId: session._id,
                index: index,
              },
            });
          }
        }

        return patches;
      }
      return [];
    },
  },
  "$.stages[?(@._id=='$stageId')].internalName": {
    header: 'Internal Name',
  },
  "$.stages[?(@._id=='$stageId')].mapLink": {
    header: 'Map Link',
  },
  "$.stages[?(@._id=='$stageId')].local": {
    factory: async () => ({}),
  },
  "$.stages[?(@._id=='$stageId')].local.$language": {
    factory: async () => Factory.stage().createStageLocal(),
  },
  "$.stages[?(@._id=='$stageId')].local.$language.title": {},
};
