<!-- <c-patch-input *ngIf="initialized">
  [jsonpath]="jsonpath"
  [jsonpathParams]="jsonpathParams"
  [object]="object"
  [inputConfiguration]="inputConfiguration"
  [disabled]="disabled"
  [showHeader]="showHeader"
  [showDescription]="showDescription"
  [header]="header"
  [description]="description"
</c-patch-input> -->

<div class="container" *ngIf="show()">
  <div class="header" *ngIf="showHeader">
    {{ header | translate }}
    <div class="description" *ngIf="showDescription" [innerHTML]="description | translate"></div>
  </div>
  <div class="input">
    <div class="patch-input">
      <c-patch-input
        *ngIf="object && initialized"
        [inputConfiguration]="calculatedInputConfiguration"
        [object]="object"
        [jsonpath]="jsonpath"
        [jsonpathParams]="jsonpathParams"
        (valueChanged)="valueChanged($event)"
      ></c-patch-input>
      <!-- [disabled]="loading || users?.length > 0 || isDisabled()"
      (focus)="focus()" 
      (blur)="blur()" -->
    </div>
  </div>
</div>
