import { TableComponent } from 'src/app/components/table/table.component';

import { TabsService } from './../../tabs/services/tabs.service';
import { TableOptions, TableQuery } from './../../components/table/table.interfaces';
import { Tab } from './../../tabs/classes/tab';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Language } from 'src/common/entities/Language';
import { LanguageFactory } from 'src/common/factories/LanguageFactory';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) table: TableComponent<Language>;
  tab: Tab;
  tableOptions: TableOptions<Language>;
  subscription: Subscription;
  constructor(private languagesService: LanguagesService, private tabsService: TabsService, private activatedRoute: ActivatedRoute, private router: Router) {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((t: NavigationEnd) => {
      if (t.url === '/configuration/languages') {
        this.table.refresh();
      }
    });
    this.tab = this.tabsService.register({
      category: 'data',
      route: this.activatedRoute.snapshot,
      title: 'Languages',
    });

    this.tableOptions = {
      size: 50,
      columns: [
        { header: 'PAGE_LANGUAGE_TAG', sort: 'language' },
        { header: 'PAGE_LANGUAGE_NAME', sort: 'languageName' },
        { header: 'GENERAL_ACTIVE', sort: 'active' },
        { header: 'GENERAL_SELECTABLE', sort: 'selectable' },
        { header: '' },
      ],
      filters: [
        { header: 'PAGE_LANGUAGE_TAG', path: 'language' },
        { header: 'PAGE_LANGUAGE_NAME', path: 'languageName' },
        { header: 'PAGE_USER_EMAIL', path: 'active' },
        { header: 'GENERAL_ACTIVE', path: 'selectable' },
      ],
    };
  }

  query(query: TableQuery<Language>) {
    query.result = this.languagesService.getLanguages(query.query);
  }

  async createLanguage() {
    let language: Language;
    language = await new LanguageFactory({ newIds: true }).createLanguage({});
    language = await this.languagesService.createLanguage(language);
    this.router.navigate(['/configuration/languages', language._id], {
      replaceUrl: true,
    });
  }

  linkClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
