import { Breakout, BreakoutLangauge, BreakoutSession } from '../../entities/Session';
import { FactoryOptions } from '../AbstractFactory';
import { BaseSessionFactory } from './BaseSessionFactory';

export class BreakoutSessionFactory extends BaseSessionFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public createBreakoutLocal(values: any) {
    return {
      title: values?.title || '',
      shortTitle: values?.shortTitle || '',
      description: values?.description || '',
    } as BreakoutLangauge;
  }

  public async createBreakout(values: any) {
    return {
      _id: await this.id(values?._id),
      link: values?.link || null,
      asset: values?.asset || null,
      speakers: values?.speakers || [],
      keytopics: values?.keytopics || [],
      local: Object.keys(values?.local || {}).reduce((a, b) => ({ ...a, [b]: this.createBreakoutLocal(values.local[b]) }), {}),
    } as Breakout;
  }

  public async createBreakoutSession(values: any): Promise<BreakoutSession> {
    return {
      ...values,
      _id: await this.id(values?._id),
      sessionType: 'BreakoutSession',
      endAt: values?.endAt || null,
      highlight: values?.highlight || false,
      mainStageHighlight: values?.mainStageHighlight || false,
      startAt: values?.startAt || null,
      tags: values?.tags || {},
      keytopics: values?.keytopics || [],
      actualEndAt: values?.actualEndAt || null,
      actualStartAt: values?.actualStartAt || null,
      regional: values?.regional || {},
      images: {
        main: values?.images?.main || null,
      },
      local: Object.keys(values?.local || {}).reduce((a, b) => ({ ...a, [b]: this.createSessionLocal(values.local[b]) }), {}),
      event: values?.event || null,
      hidden: values?.hidden || false,
      skippable: values?.skippable || false,
      speakers: values?.speakers || [],
      sessionMedia: values?.sessionMedia || null,
      breakouts: await Promise.all((values?.breakouts || []).map((b: any) => this.createBreakout(b))),
      stages: values?.stages || [],
      maxChatMessages: values?.maxChatMessages || 100,
    } as BreakoutSession;
  }
}
