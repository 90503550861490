import { Form } from '../entities/Form';
import { EventServiceHookFilter, EventTicketServiceHookFilter, FormServiceHookFilter, HTTPCallServiceHookAction, ServiceHook, ServiceHookAction, ServiceHookFilter } from '../entities/ServiceHook';
import { AbstractFactory, FactoryOptions, Values } from './AbstractFactory';

export class ServiceHookFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createServiceHook(values?: Values<ServiceHook>) {
    return {
      ...values,
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      enabled: values?.enabled || false,
      serviceHookEventType: values?.serviceHookEventType || 'FormEntryCreated',
      serviceHookFilters: values?.serviceHookFilters || [],
      serviceHookAction: this.createServiceHookAction(values?.serviceHookAction || { serviceHookActionType: 'HTTPCall' })
    } as ServiceHook;
  }

  createServiceHookFilter(values: Values<ServiceHookFilter>) {
    switch (values.serviceHookFilterType) {
      case 'Form':
        return this.createFormServiceHookFilter(values as Values<FormServiceHookFilter>);
      case 'Event':
        return this.createEventServiceHookFilter(values as Values<EventServiceHookFilter>);
      case 'EventTicket':
        return this.createEventTicketServiceHookFilter(values as Values<EventTicketServiceHookFilter>);
    }
  }

  createFormServiceHookFilter(values: Values<FormServiceHookFilter>) {
    return {
      ...values,
      serviceHookFilterType: 'Form',
      forms: values?.forms || []
    } as FormServiceHookFilter;
  }

  createEventServiceHookFilter(values: Values<EventServiceHookFilter>) {
    return {
      ...values,
      serviceHookFilterType: 'Event',
      events: values?.events || []
    } as EventServiceHookFilter;
  }

  createEventTicketServiceHookFilter(values: Values<EventTicketServiceHookFilter>) {
    return {
      ...values,
      serviceHookFilterType: 'EventTicket',
      eventTickets: values?.eventTickets || []
    } as EventTicketServiceHookFilter;
  }

  createServiceHookAction(values: Values<ServiceHookAction>) {
    switch (values.serviceHookActionType) {
      case 'HTTPCall':
        return this.createHTTPCallServiceHookAction(values as Values<HTTPCallServiceHookAction>);
      case 'Email':
        return {};
    }
  }

  createHTTPCallServiceHookAction(values: Values<HTTPCallServiceHookAction>) {
    return {
      serviceHookActionType: 'HTTPCall',
      method: values?.method || 'POST',
      contentType: values?.contentType || 'application/json',
      acceptedContentType: values?.acceptedContentType || values?.contentType || 'application/json',
      templateBody: values?.templateBody || '',
      url: values?.url || '',
      headers: values?.headers || {}
    } as HTTPCallServiceHookAction;
  }
}
