<div class="tab-header">
  <h1>
    <span>{{ 'GENERAL_ASSETS' | translate }}</span>
  </h1>
</div>
<div *hasRight="'assets.create'" class="tab-menu">
  <input pInputText type="text" [(ngModel)]="importData.url" placeholder="Enter URL to import ..." [disabled]="working" />
  <input pInputText type="text" class="p-mr-2" [(ngModel)]="importData.internalName" placeholder="as ..." [disabled]="working" />
  <p-button icon="pi pi-cloud-upload" [label]="'GENERAL_IMPORT' | translate" (onClick)="import()" [disabled]="!importData.url || working"></p-button>
</div>

<div *hasRight="'assets.create'" class="panel">
  <div class="panel-header">
    {{ 'GENERAL_UPLOADS' | translate }}
  </div>
  <div class="panel-body">
    <div class="p-col-12">
      <ngx-file-drop contentClassName="file-drop-content" dropZoneClassName="drop-zone" (onFileDrop)="dropped($event)">
        <ng-template ngx-file-drop-content-tmp> Drop Files here </ng-template>
      </ngx-file-drop>
    </div>
  </div>
</div>

<div *ngIf="assetImports | async as assets">
  <div class="panel" *ngIf="assets.length > 0">
    <div class="panel-header">
      {{ 'GENERAL_IMPORTS' | translate }}
    </div>
    <div class="panel-body">
      <div class="p-grid">
        <div class="p-col-4" *ngFor="let asset of assets">
          <c-asset-item [asset]="asset"></c-asset-item>
        </div>
      </div>
    </div>
    <div class="p-col-4" *ngFor="let assetUpload of currentAssetUploads() | async">
      <c-asset-item [assetUpload]="assetUpload"></c-asset-item>
    </div>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_COMPLETED' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
      </div>
    </div>
    <div class="search">
      <input placeholder="Search asset ..." pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
      <c-quick-filters [title]="'GENERAL_TYPE' | translate" [quickFilters]="typeQuickFilters" [active]="typeQuickFiltersActive" (valueChange)="setTypeQuickFilters($event)"></c-quick-filters>
      <c-quick-filters [title]="'GENERAL_SOURCE' | translate" [quickFilters]="sourceQuickFilters" [active]="sourceQuickFiltersActive" (valueChange)="setSourceQuickFilters($event)"></c-quick-filters>
    </div>
    <div class="panel-body">
      <c-table #table mode="query" [tableOptionsComponent]="toc" [options]="tableOptions" (query)="query($event)" (clearFilterList)="searchText = ''">
        <ng-template let-asset>
          <tr>
            <c-asset-item [asset]="asset"></c-asset-item>
          </tr>
        </ng-template>
      </c-table>
    </div>
  </div>
</div>

<p-dialog header="Upload Assets" [(visible)]="visible" [style]="{ width: '50vw' }" [modal]="true">
  <div class="asset-upload" *ngFor="let asset of assetUploads">
    <div class="p-grid p-d-flex p-ai-center">
      <div class="p-col p-md-6">
        <input type="text" pInputText [(ngModel)]="asset.internalName" placeholder="Internal Name *" />
      </div>
      <div class="p-col p-md-6">
        <div class="asset-upload-name">{{ asset.file.name }}</div>
        <div class="asset-upload-info">{{ asset.file.type }} - {{ asset.file.size | fileSize }}</div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="text-right">
      <button class="p-button" [disabled]="!valid()" (click)="startUpload()">Upload</button>
    </div>
  </ng-template>
</p-dialog>
