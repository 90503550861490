import { AccessPolicy } from './AccessPolicy';
import { ShareCTATarget } from './CTAButton';
import { EventStage } from './EventStage';
import { BackgroundTheme, PageModule, VideoGridSettings, VideoTeaserCollectionSettings } from './PageModule';

export enum PageTypes {
  BlankPage = 'BlankPage',
  AssetPage = 'AssetPage',
  StandardPage = 'StandardPage',
  KeytopicsPage = 'KeytopicsPage',
  ProfilePage = 'ProfilePage',
  EmbeddedPage = 'EmbeddedPage',
  ExplorePage = 'ExplorePage',
  LinkedPage = 'LinkedPage'
  //StreamPage = 'StreamPage'
}

// PageTypes, where versioning shall be disabled
export const nonVersionedPageTypes = [PageTypes.LinkedPage] as const;
export type NonVersionedPageType = (typeof nonVersionedPageTypes)[number] | `${(typeof nonVersionedPageTypes)[number]}`;

// The type 'keyof typeof PageTypes' is retained for backward compatibility.
export type PageType = keyof typeof PageTypes | PageTypes;

export const displayOption = ['everywhere', 'onlyApp', 'onlyWeb'] as const;
export type DisplayOption = (typeof displayOption)[number];

export const globalPageTypes = [PageTypes.BlankPage, PageTypes.AssetPage, PageTypes.ExplorePage, PageTypes.LinkedPage] as const;
export type GlobalPageType = (typeof globalPageTypes)[number] | `${(typeof globalPageTypes)[number]}`;

export interface BasePage {
  _id: string;
  createdAt: string;
  createdBy: string;
  version: number;
  pageVersion: string;
  pageType: PageType;
  internalName: string;
  totalVersions: number;
  domainCollection: string;
  languages: string[];
  showInSubmenu?: boolean;
}

export interface NonVersionedBasePage {
  _id: string;
  createdAt: string;
  createdBy: string;
  pageType: NonVersionedPageType;
  internalName: string;
  domainCollection: string;
}

export interface Page extends BasePage {
  default?: boolean;
  path: string;
  fullPath?: string;
  showInMenu: boolean;
  local: { [language: string]: PageLocal };
  readAccess: AccessPolicy[];
  parentPage?: string;
  sitemap?: boolean;
  previewImage: string;
  displayOption?: DisplayOption;
  onboarding?: string;
  contentChecklistActive?: boolean;
  showSubmenu?: boolean;
  showInSubmenu?: boolean;
}

export interface NonVersionedPage extends NonVersionedBasePage {
  default?: boolean;
  path: string;
  fullPath?: string;
  parentPage?: string;
  showSubmenu?: boolean;
  showInSubmenu?: boolean;
}
export interface PageVersion extends Page {
  page: string;
  finalizedAt: string;
  finalizedBy: string;
  publishedAt: string;
  publishedBy: string;
  dirty: boolean;
  change: number;
  forkedAt: string;
  forkedBy: string;
  modifiedAt: string;
  modifiedBy: string;
}
export interface PageWithEventStage {
  eventStage: EventStage;
}

export interface PageWithPageModules {
  pageModules: PageModule[];
}

export interface BlankPage extends Page, PageWithPageModules {
  pageType: PageTypes.BlankPage;
}

export interface EmbeddedPage extends BasePage, PageWithPageModules {
  local: { [language: string]: EmbeddedPageLocal };
  pageType: PageTypes.EmbeddedPage;
}

export const AssetScopeTypes = ['FromURL', 'SelectAsset'] as const;
export const AssetScopeTypeLabels = ['From URL', 'Select Asset'] as const;
export type AssetScopeType = (typeof AssetScopeTypes)[number];

export interface AssetPage extends Page, PageWithPageModules {
  pageType: PageTypes.AssetPage;
  assetScope: AssetScopeType;
  local: { [language: string]: PageLocal & { asset: string; recomVideo_loadMoreButtonLabel: string } };
  backgroundTheme: BackgroundTheme;
  showShareButton: boolean;
  showLikeButton?: boolean;
  shareButtonTargets?: ShareCTATarget[];
  accessPolicy?: AccessPolicy[];
  paddingTop?: boolean;
  paddingBottom?: boolean;
  paddingLeft?: boolean;
  paddingRight?: boolean;
  playerOverlayPicture?: string;
  videoGridSettings?: VideoGridSettings;
  recommendationsSettings?: VideoTeaserCollectionSettings;
}
export interface ExplorePage extends Page, PageWithPageModules {
  featuredEvents: string[];
}
export interface StandardPage extends Page, PageWithPageModules, PageWithEventStage {
  pageType: PageTypes.StandardPage;
}

export interface ProfilePage extends Page {
  pageType: PageTypes.ProfilePage;
  profileType: 'user' | 'speaker';
  showSummary: boolean;
  stageImage: string;
  stageBackgroundTheme?: BackgroundTheme;
}

export interface KeytopicsPage extends Page {
  path: 'keytopics';
  pageType: PageTypes.KeytopicsPage;
  stageBackgroundTheme?: BackgroundTheme;
}

export interface LinkedPage extends NonVersionedPage {
  pageType: PageTypes.LinkedPage;
  linkedPage: string;
}

export interface PageLocal {
  title: string;
  navigationTitle: string;
  displayOption: string;
  metaDescription?: string;

  linkTitle?: string;
  linkDescription?: string;
}

export interface EmbeddedPageLocal {}

export function isPage(page: any): page is Page {
  return !!page?.pageType;
}

export function isBlankPage(page: any): page is BlankPage {
  return page?.pageType === PageTypes.BlankPage;
}

export function isAssetPage(page: any): page is AssetPage {
  return page?.pageType === PageTypes.AssetPage;
}

export function isExplorePage(page: any): page is ExplorePage {
  return page?.pageType === PageTypes.ExplorePage;
}

export function isLinkedPage(page: any): page is LinkedPage {
  return page?.pageType === PageTypes.LinkedPage;
}

export function isStandardPage(page: any): page is StandardPage {
  return page?.pageType === PageTypes.StandardPage;
}

export function isKeytopicsPage(page: any): page is KeytopicsPage {
  return page?.pageType === PageTypes.KeytopicsPage;
}

export function isProfilePage(page: any): page is ProfilePage {
  return page?.pageType === PageTypes.ProfilePage;
}

export function isEmbeddedPage(page: unknown): page is EmbeddedPage {
  return (<BasePage>page)?.pageType === PageTypes.EmbeddedPage;
}

export function isPageWithEventStage(page: any): page is PageWithEventStage {
  return page?.pageType === PageTypes.StandardPage;
}

export function isPageWithPageModules(page: any): page is PageWithPageModules {
  return (
    page?.pageType === PageTypes.BlankPage ||
    page?.pageType === PageTypes.AssetPage ||
    page?.pageType === PageTypes.ExplorePage ||
    page?.pageType === PageTypes.StandardPage ||
    //page?.pageType === PageTypes.StreamPage ||
    page?.pageType === PageTypes.EmbeddedPage
  );
}

export function isNonVersionedPage(page: Page | NonVersionedPage | EmbeddedPage): page is NonVersionedPage {
  return nonVersionedPageTypes.some((type: string) => page.pageType === type);
}

export const pageVersionDateFields: string[] = [];
