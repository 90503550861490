import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { HasNoRightDirective } from './has-no-right/has-no-right.directive';
import { HasRightDirective } from './has-right/has-right.directive';
import { SkeletonDirective, SkeletonTemplateComponent } from './skeleton/skeleton.directive';

const directives = [HasRightDirective, HasNoRightDirective, SkeletonDirective];

@NgModule({
  declarations: [...directives, SkeletonTemplateComponent],
  imports: [CommonModule, SkeletonModule, ButtonModule],
  exports: [...directives],
})
export class DirectivesModule {}
