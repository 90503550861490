import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguagesService } from 'src/app/services/languages/languages.service';

import { EmailTemplateConfiguration } from 'src/common/entities/EmailTemplateConfiguration';
import { EmailTemplateConfigurationsService } from 'src/app/services/email-template-configurations/email-template-configurations.service';
import { EmailTemplate } from 'src/common/entities/EmailTemplate';
import { EmailTemplatesService } from 'src/app/services/email-templates/email-templates.service';
import * as sanitizeHtml from 'sanitize-html';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { Tab } from 'src/app/tabs/classes/tab';

interface LanguageEmailTemplate {
  [language: string]: EmailTemplate;
}

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss']
})
export class EmailTemplateComponent implements OnInit, OnDestroy {
  emailTemplateConfigurationId: string;
  emailTemplate: EmailTemplate;
  emailTemplateConfiguration: EmailTemplateConfiguration;
  emailTemplateConfigurations: EmailTemplateConfiguration[];
  emailTemplateTypes: { label: string; value: string }[] = [];
  tab: Tab;

  createNew: string; // umbennen

  activatedHTMLEditor: boolean = false;

  currentLanguage: string = 'en';

  languages: string[] = [];
  languageEdit: string = 'en'; // Editable language
  languageCompare: string = this.languageEdit; // Language to compare with
  private htmlPreviewAllowedAttributes: Record<string, sanitizeHtml.AllowedAttribute[]>;
  private htmlPreviewAllowedTags: string[];

  getPreviewHTML(html) {
    if (!html) {
      // placeholder
      return '<p style="min-height: 1px; margin: 0; padding: 15px 0;"></p>';
    }

    return sanitizeHtml(html, {
      allowedTags: this.htmlPreviewAllowedTags,
      disallowedTagsMode: 'escape',
      allowedAttributes: this.htmlPreviewAllowedAttributes
    });
  }

  shortId: string; // Of email template to edit

  loading: boolean = true;
  disableConfigSelection: boolean = true;

  selectedEditor = { name: 'Editor', code: 'editor' };
  editor = [this.selectedEditor, { name: 'HTML', code: 'html' }];

  private _ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private activatedRoute: ActivatedRoute,
    public clipboard: Clipboard,
    private languagesService: LanguagesService,
    private emailTemplateConfigurationsService: EmailTemplateConfigurationsService,
    private emailTemplateService: EmailTemplatesService,
    private router: Router,
    private tabsService: TabsService
  ) {
    this.tab = this.tabsService.register({
      category: 'configuration',
      parent: '/configuration/email-templates',
      loading: true,
      route: this.activatedRoute.snapshot
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe subscriptions
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    this.htmlPreviewAllowedTags = sanitizeHtml.defaults.allowedTags.filter(tag => tag !== 'a');
    const allowedAttributes: Record<string, sanitizeHtml.AllowedAttribute[]> = {};

    this.htmlPreviewAllowedTags.forEach(tag => {
      let allowed: sanitizeHtml.AllowedAttribute[] = ['style'];
      const defaultAllowed = sanitizeHtml.defaults.allowedAttributes[tag];
      if (defaultAllowed) {
        allowed = allowed.concat(defaultAllowed);
      }
      allowedAttributes[tag] = allowed;
    });

    this.htmlPreviewAllowedAttributes = allowedAttributes;

    this.activatedRoute.params.pipe(takeUntil(this._ngUnsubscribe)).subscribe(async params => {
      this.emailTemplateConfigurationId = params.emailTemplateConfigurationId;

      if (this.emailTemplateConfigurationId && this.emailTemplateConfigurationId != '' && this.emailTemplateConfigurationId != 'new') {
        this.disableConfigSelection = true;
        this.createNew = params.emailTemplateConfigurationId;
        this.emailTemplate = await this.emailTemplateService.getEmailTemplate(this.emailTemplateConfigurationId);
        this.emailTemplateConfiguration = await this.emailTemplateConfigurationsService.getEmailTemplateConfiguration(this.emailTemplate.emailTemplateConfigurationId);

        this.emailTemplateTypes = await (await this.emailTemplateConfigurationsService.getEmailTemplateConfigurations()).items.map(item => {
          return { value: item._id, label: item.internalName };
        });
        this.languages = Object.keys(this.emailTemplate.local);
        this.currentLanguage = this.languages[0];

        this.loading = false;
      } else if (this.emailTemplateConfigurationId === 'new') {
        this.createNew = params.emailTemplateConfigurationId;
        this.emailTemplate = {
          _id: null,
          emailTemplateConfigurationId: null,
          internalName: '',
          default: false,
          local: { en: { parts: {} } }
        };
        this.emailTemplateTypes = [{ value: null, label: '-' }].concat(
          await (await this.emailTemplateConfigurationsService.getEmailTemplateConfigurations()).items.map(item => {
            return { value: item._id, label: item.internalName };
          })
        );
        this.loading = false;
        this.disableConfigSelection = false;
        this.tabsService.close(this.tab);
        this.languages = Object.keys(this.emailTemplate.local);
        this.currentLanguage = this.languages[0];
        this.emailTemplateConfiguration = null;
      }
    });
  }

  async save() {
    this.loading = true;
    const emailTemplate = await this.emailTemplateService.createEmailTemplate(this.emailTemplate);
    this.loading = false;
  }

  changeCompareLanguage(event: any) {
    if (event.value) this.languageCompare = event.value;
  }

  async emailTemplateTypeChange() {
    this.loading = true;
    if (this.emailTemplate.emailTemplateConfigurationId) {
      this.emailTemplateConfiguration = await this.emailTemplateConfigurationsService.getEmailTemplateConfiguration(this.emailTemplate.emailTemplateConfigurationId);
      Object.keys(this.emailTemplateConfiguration.parts).forEach(item => (this.emailTemplate.local[this.currentLanguage].parts[item] = ''));

      console.log(this.emailTemplate);
    } else {
      this.emailTemplateConfiguration = null;
      this.emailTemplate.local[this.currentLanguage].parts = null;
    }
    this.loading = false;
  }

  async onActiveLanguageChange(languages: string[]) {
    this.languages = languages;

    for (const language of languages) {
      if (!this.emailTemplate.local[language]) {
        this.emailTemplate.local[language] = { parts: {} };
      }
    }
    for (const language of Object.keys(this.emailTemplate.local)) {
      if (!languages.includes(language)) {
        delete this.emailTemplate.local[language];
      }
    }
  }

  countRows(stringContent: string, minRows): number {
    return Math.max((stringContent?.match(/\n/g) || []).length, minRows) + 1;
  }
}
