import { Inputs } from '../Inputs';

export const textAttributesInputs: Inputs = {
  '$.headlineType': {
    type: 'dropdown',
    dropdownOptions: [
      { value: 'h1', label: 'Headline h1' },
      { value: 'h2', label: 'Headline h2' },
      { value: 'h3', label: 'Headline h3' },
      { value: 'no headline', label: 'No headline' },
    ],
    description: 'Set your headline here, which will be evualuated by search enginges such as Google or Yahoo.',
    header: 'Set Headline',
  },
};
