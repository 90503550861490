import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ValidatorService } from 'src/app/services/validator/validator.service';

import { Language } from 'src/common/entities/Language';
import { languageValidator } from 'src/common/api/v1/configuration/languages/validators/LanguageValidator';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { Inputs } from 'src/common/inputs/Inputs';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit, OnDestroy {
  currentLanguage: Language;
  originalLanguage: string;
  get language(): Language {
    return this.currentLanguage;
  }
  set language(aLanguage: Language) {
    this.currentLanguage = aLanguage;
    this.originalLanguage = JSON.stringify(this.currentLanguage);
  }

  languageId: string = null;

  loading: boolean = true;
  saving: boolean = false;

  inputs: Inputs = {
    '$.language': {
      header: 'PAGE_LANGUAGE_TAG',
      description: 'PAGE_LANGUAGE_TAG_DESCRIPTION',
    },
    '$.languageName': {
      header: 'PAGE_LANGUAGE_NAME',
    },
    '$.active': {
      type: 'switch',
      description: 'PAGE_LANGUAGE_ACTIVE_DESCRIPTION',
    },
    '$.selectable': {
      type: 'switch',
      description: 'PAGE_LANGUAGE_SELECTABLE_DESCRIPTION',
    },
  };
  get jsonPathes(): string[] {
    return Object.keys(this.inputs);
  }

  get isLanguageChanged(): boolean {
    return this.originalLanguage !== JSON.stringify(this.currentLanguage);
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private validatorService: ValidatorService,
    private languagesService: LanguagesService,
    private collaborationService: CollaborationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.languageId = this.activatedRoute.snapshot.params.language;
    if (this.languageId) {
      await this.retrieveData();
    }

    this.collaborationService.registerLocal(`language:${this.languageId}`, this.language);
  }

  ngOnDestroy() {
    this.collaborationService.unregisterLocal(`language:${this.languageId}`);
  }

  async retrieveData() {
    this.loading = true;
    try {
      this.language = await this.languagesService.asPromise(this.languageId);
    } catch (err) {}
    this.loading = false;
  }

  async save() {
    this.saving = true;
    try {
      this.language = await this.languagesService.updateLanguage(this.language);
      this.collaborationService.registerLocal(`language:${this.languageId}`, this.language, true);
      this.saving = false;
      this.router.navigate(['/configuration/languages']);
    } catch (err) {}
  }

  isValid(): boolean {
    return this.validatorService.isValid(languageValidator, this.language);
  }

  errorAt(jsonPath: string): string | null {
    const path = jsonPath.substr(2); // Remove leading '$.'
    return this.validatorService.errorAt(languageValidator, this.language, path);
  }
}
