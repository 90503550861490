import { InputConfiguration } from 'src/common/inputs/Inputs';
import { Component, Input, OnInit } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { TitleEventStage } from 'src/common/entities/EventStage';

@Component({
  selector: 'c-titleeventstage-page-module-edit',
  templateUrl: './titleeventstage-page-module-edit.component.html',
  styleUrls: ['./titleeventstage-page-module-edit.component.scss'],
})
export class TitleEventStagePageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: TitleEventStage;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathes: string[] = ['$.local.$language.title', '$.local.$language.icon', '$.local.$language.background', '$.showLiveButton', '$.anchor'];

  topAreaJsonpathes: string[] = [
    '$.topAreaHeight.mobile',
    '$.topAreaHeight.mobileLarge',
    '$.topAreaHeight.tablet',
    '$.topAreaHeight.tabletLarge',
    '$.topAreaHeight.desktop',
    '$.topAreaHeight.desktopLarge',
    '$.topAreaHeight.desktopWidescreen',
  ];

  contentAreaJsonpathes: string[] = [
    '$.contentAreaHeight.mobile',
    '$.contentAreaHeight.mobileLarge',
    '$.contentAreaHeight.tablet',
    '$.contentAreaHeight.tabletLarge',
    '$.contentAreaHeight.desktop',
    '$.contentAreaHeight.desktopLarge',
    '$.contentAreaHeight.desktopWidescreen',
  ];

  ctaAreaJsonpathes: string[] = [
    '$.ctaAreaHeight.mobile',
    '$.ctaAreaHeight.mobileLarge',
    '$.ctaAreaHeight.tablet',
    '$.ctaAreaHeight.tabletLarge',
    '$.ctaAreaHeight.desktop',
    '$.ctaAreaHeight.desktopLarge',
    '$.ctaAreaHeight.desktopWidescreen',
  ];

  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
  }
}
