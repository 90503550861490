import { TextPageModuleAttributes } from './../../entities/pagemodules/TextPageModule';
import { HeadlineType } from './../../entities/Attributes';
import { TextPageModule } from '../../entities/pagemodules/TextPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class TextPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public textPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): TextPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async createTextPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.textPageModuleAttributes(values?.title_attr),
      text: values?.text || '',
    };
  }

  public async textPageModule(values: any): Promise<TextPageModule> {
    return {
      ...(await this.baseValues(values)),
      titlePosition: values?.titlePosition || 'centered',
      pageModuleType: 'Text',
      local: await this.local(values, (val) => this.createTextPageModuleLocal(val)),
      ctaButton: values?.ctaButton || null,
      contentPosition: values?.contentPosition || 'centered',
    };
  }
}
