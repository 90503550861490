import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguagesService } from 'src/app/services/languages/languages.service';
import { SpeakersService } from 'src/app/services/speakers/speakers.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';

import { CustomExternalAccount, ExternalAccount, ExternalAccountType, externalAccountTypes, personTypes, Speaker } from 'src/common/entities/Speaker';
import { SpeakerFactory } from 'src/common/factories/SpeakerFactory';
import { speakerLocalValidator, speakerValidator } from 'src/common/api/v1/speakers/validators/SpeakerValidator';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';
import { TableOptions } from 'src/app/components/table/table.interfaces';
import { TableComponent } from 'src/app/components/table/table.component';
import { Inputs } from '../../../common/inputs/Inputs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-speaker',
  templateUrl: './speaker.component.html',
  styleUrls: ['./speaker.component.scss'],
})
export class SpeakerComponent implements OnInit, OnDestroy {
  @ViewChild('externalAccountsTable') externalAccountsTable: TableComponent<ExternalAccount>;

  speakerId: string = 'undefined';
  speaker: Speaker = null;

  hasEditRights: boolean = false;
  hasCreateRights: boolean = false;

  languages: string[];
  currentLanguage: string = 'en';

  working: boolean = false;
  tab: Tab;

  externalAccountEditIndex: number = -1;

  get showExternalAccountEdit() {
    return this.externalAccountEditIndex !== -1;
  }

  set showExternalAccountEdit(val: boolean) {
    if (!val) this.externalAccountEditIndex = -1;
  }

  mainInputs: Inputs = {
    '$.firstName': {
      header: 'PAGE_SPEAKER_GLOBAL_PROPERTIES_FIRSTNAME',
      description: 'PAGE_SPEAKER_GLOBAL_PROPERTIES_FIRSTNAME_DESCRIPTION',
    },
    '$.lastName': {
      header: 'PAGE_SPEAKER_GLOBAL_PROPERTIES_LASTNAME',
      description: 'PAGE_SPEAKER_GLOBAL_PROPERTIES_LASTNAME_DESCRIPTION',
    },
    '$.company': {
      header: 'PAGE_SPEAKER_GLOBAL_PROPERTIES_COMPANY',
      description: 'PAGE_SPEAKER_GLOBAL_PROPERTIES_COMPANY_DESCRIPTION',
    },
    '$.picture': {
      type: 'imageasset',
      header: 'PAGE_SPEAKER_GLOBAL_PROPERTIES_PICTURE',
      description: 'PAGE_SPEAKER_GLOBAL_PROPERTIES_PICTURE_DESCRIPTION',
    },
    '$.email': {
      header: 'PAGE_SPEAKER_GLOBAL_PROPERTIES_EMAIL',
      description: 'PAGE_SPEAKER_GLOBAL_PROPERTIES_EMAIL_DESCRIPTION',
    },
    '$.externalProfileLink': {
      header: 'PAGE_SPEAKER_GLOBAL_PROPERTIES_PROFILE_LINK',
      description: 'PAGE_SPEAKER_GLOBAL_PROPERTIES_PROFILE_LINK_DESCRIPTION',
    },
    '$.local.$language.title': {
      header: 'PAGE_SPEAKER_LOCAL_PROPERTIES_TITLE',
      description: 'PAGE_SPEAKER_LOCAL_PROPERTIES_TITLE_DESCRIPTION',
    },
    '$.local.$language.shortDescription': {
      header: 'PAGE_SPEAKER_LOCAL_PROPERTIES_SHORT_DESCRIPTION',
      description: 'PAGE_SPEAKER_LOCAL_PROPERTIES_SHORT_DESCRIPTION_DESCRIPTION',
    },
    '$.local.$language.longDescription': {
      header: 'PAGE_SPEAKER_LOCAL_PROPERTIES_LONG_DESCRIPTION',
      description: 'PAGE_SPEAKER_LOCAL_PROPERTIES_LONG_DESCRIPTION_DESCRIPTION',
    },
    '$.type': {
      header: 'PAGE_SPEAKER_GLOBAL_PROPERTIES_TYPE',
      type: 'dropdown',
      dropdownOptions: [...personTypes].map((a) => ({ label: a, value: a })),
    },
  };

  mainJsonPathes = Object.keys(this.mainInputs);

  externalAccountsTableOptions: TableOptions<ExternalAccount> = {
    columns: [{ header: 'Type', visible: 'fixed', sort: { property: 'externalAccountType' } }, { header: 'Link' }],
  };

  externalAccountInputsCommon: Inputs = {
    '$.externalAccounts[$index].externalAccountType': {
      type: 'dropdown',
      dropdownOptions: [...externalAccountTypes].map((e) => ({
        label: e,
        value: e,
      })),
    },
  };

  externalAccountInputs: { [type: string]: Inputs } = {
    LinkedIn: { '$.externalAccounts[$index].link': {} },
    Xing: { '$.externalAccounts[$index].link': {} },
    Twitter: { '$.externalAccounts[$index].link': {} },
    Custom: {
      '$.externalAccounts[$index].link': {},
      '$.externalAccounts[$index].icon': {},
      '$.externalAccounts[$index].local.$language': {
        hide: true,
        factory: async (obj, jsonpathParams) => ({ title: '' }), // besser: Eine Factory verwenden
      },
      '$.externalAccounts[$index].local.$language.title': {},
    },
  };

  get externalAccountsJsonPathes() {
    return Object.keys(this.externalAccountEditIndex !== -1 ? this.externalAccountInputs[this.speaker.externalAccounts[this.externalAccountEditIndex].externalAccountType] : {});
  }

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private languagesService: LanguagesService,
    private validatorService: ValidatorService,
    private speakersService: SpeakersService,
    private tabsService: TabsService,
    private collaborationService: CollaborationService
  ) {
    this.tab = this.tabsService.register({
      category: 'data',
      parent: '/speakers',
      loading: true,
      route: this.activatedRoute.snapshot,
    });
  }

  async ngOnInit(): Promise<void> {
    this.speakerId = this.activatedRoute.snapshot.params.speakerId;
    this.hasEditRights = await this.authService.hasRight('people:' + this.speakerId + '$' + 'people.edit');
    this.hasCreateRights = await this.authService.hasRight('people.create');

    if (this.speakerId === 'new') {
      this.speaker = await new SpeakerFactory().createSpeaker({
        local: { en: {} },
      });
      this.tab.title = 'PAGE_SPEAKER_NEW_SPEAKER';
    } else {
      this.speaker = await new SpeakerFactory().createSpeaker(await this.speakersService.asPromise(this.speakerId, true));
      this.tab.title = this.speaker.displayName;
    }

    this.languages = this.speaker ? Object.keys(this.speaker.local) : [];

    if (this.hasEditRights || this.hasCreateRights) {
      this.collaborationService.registerLocal(`speaker:${this.speakerId}`, this.speaker);
    }

    this.working = false;
    this.tab.loading = false;
  }

  ngOnDestroy() {
    this.collaborationService.unregisterLocal(`speaker:${this.speakerId}`);
  }

  async languagesChange(languages: string[]) {
    this.languages = languages;

    for (const language of languages) {
      if (!this.speaker.local[language]) {
        this.speaker.local[language] = await new SpeakerFactory().createSpeakerLocal({});
      }
    }
  }

  async save() {
    this.working = true;

    try {
      if (this.speakerId === 'new') {
        this.speaker = await this.speakersService.createSpeaker(this.speaker);

        await this.router.navigate(['/speakers', this.speaker._id], {
          replaceUrl: true,
        });

        this.tab.remove();
      } else {
        this.speaker = await this.speakersService.updateSpeaker(this.speaker);
      }
    } catch (err) {}

    this.working = false;
  }

  async delete() {
    this.working = true;
    this.collaborationService.disable(`speaker:${this.speakerId}`);

    try {
      if (this.speakerId !== 'new') {
        this.speaker = await this.speakersService.deleteSpeaker(this.speaker);
        await this.router.navigate(['/speakers'], {
          replaceUrl: true,
        });
        this.tab.remove();
      }
    } catch (err) {}

    this.working = false;
    this.collaborationService.enable(`speaker:${this.speakerId}`);
  }

  isValid(): boolean {
    return this.validatorService.isValid(speakerValidator, this.speaker) && this.invalidLanguages().length === 0;
  }

  errorAt(path: string): string | null {
    return this.validatorService.errorAt(speakerValidator, this.speaker, path);
  }

  localIsValid(language: string): boolean {
    return this.validatorService.isValid(speakerLocalValidator, this.speaker?.local[language]);
  }

  localErrorAt(language: string, path: string): string | null {
    return this.validatorService.errorAt(speakerLocalValidator, this.speaker?.local[language], path);
  }

  invalidLanguages(): string[] {
    return this.speaker ? Object.keys(this.speaker.local).filter((l) => !this.localIsValid(l)) : [];
  }

  async addExternalAccount(type: ExternalAccountType) {
    this.speaker.externalAccounts.push(await new SpeakerFactory().createExternalAccount({ externalAccountType: type }));
    this.externalAccountEditIndex = this.speaker.externalAccounts.length - 1;
    this.externalAccountsTable.refresh(false);
  }

  deleteExternalAccount(index: number) {
    if (index > -1) {
      this.speaker.externalAccounts.splice(index, 1);
      this.externalAccountsTable.refresh(false);
    }
  }

  asCustomExternalAccount(externalAccount: ExternalAccount): CustomExternalAccount | null {
    return this.isCustomExternalAccount(externalAccount) ? externalAccount : null;
  }

  isCustomExternalAccount(object: any): object is CustomExternalAccount {
    return object.externalAccountType === 'Custom';
  }

  trackByIndex(index: number) {
    return index;
  }

  editExternalAccount(externalAccount: ExternalAccount) {
    this.externalAccountEditIndex = this.speaker.externalAccounts.indexOf(externalAccount);
  }
}
