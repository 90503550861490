import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss'],
})
export class PollComponent {
  pollId: string;

  constructor(private activatedRoute: ActivatedRoute) {
    this.pollId = this.activatedRoute.snapshot.params.pollId;
  }
}
