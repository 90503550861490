import {
  AreaDialogOverlay,
  AreaDialogOverlayLocal,
  BeaconGroup,
  BeaconGroupLocal,
  BeaconLocal,
  BeaconSearch,
  BeaconSearchLocal,
  FullscreenDialogMarkerAction,
  ImageListOverlay,
  ImageListOverlayLocal,
  ImageTextOverlay,
  ImageTextOverlayLocal,
  LinkMarkerAction,
  LinkMarkerActionLocal,
  Map,
  Marker,
  MarkerActionType,
  MarkerCategory,
  MarkerCategoryLocal,
  MarkerGroup,
  MarkerGroupLocal,
  MarkerIcon,
  MarkerLocal,
  Overlay,
  OverlayMarkerAction,
  OverlayType,
  ProductDialogOverlay,
  ProductDialogOverlayLocal,
  Submap,
  SubmapLocal,
  SubmapMarkerAction,
} from '../entities/Map';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';

export class MapFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createMap(values?: any) {
    return {
      _id: await this.id(values?._id),
      version: values?.version || 0,
      mapVersion: values?.mapVersion || null,
      internalName: values?.internalName || '',
      idleTime: values?.idleTime || 120,
      beaconSearch: await this.createBeaconSearch(values.beaconSearch),
      beaconSearchTimer: values?.beaconSearchTimer || 1,
      beaconRefreshInterval: values?.beaconRefreshInterval || 6,
      markerGroups: await Promise.all((values?.markerGroups || []).map((m: any) => this.createMarkerGroup(m))),
      markerIcons: await Promise.all((values?.markerIcons || []).map((m: any) => this.createMarkerIcon(m))),
      overlays: await Promise.all((values?.overlays || []).map((m: any) => this.createOverlay(m))),
      beacons: await Promise.all((values?.beacons || []).map((m: any) => this.createBeacon(m))),
      beaconGroups: await Promise.all((values?.beaconGroups || []).map((m: any) => this.createBeaconGroup(m))),
      submaps: await Promise.all((values?.submaps || []).map((s: any) => this.createSubmap(s))),
      totalVersions: values?.totalVersions || 0,
      languages: values?.languages || [],
    } as Map;
  }

  async createBeaconSearch(values?: any) {
    return {
      showBeaconSearchIcon: values?.showBeaconSearchIcon ?? false,
      local: await this.local(values, val => this.createBeaconSearchLocal(val)),
      beaconSearchCTA: values?.beaconSearchCTA || null,
      beaconNoRightsCTA: values?.beaconNoRightsCTA || null,
    } as BeaconSearch;
  }

  createBeaconSearchLocal(values?: any) {
    return {
      beaconSearchText: values?.beaconSearchText ?? '',
      beaconNoRightsText: values?.beaconNoRightsText ?? '',
    } as BeaconSearchLocal;
  }

  async createSubmap(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      markers: await Promise.all((values?.markers || []).map((m: any) => this.createMarker(m))),
      local: await this.local(values, val => this.createSubmapLocal(val)),
      navigationItems: values?.navigationItems || [],
      isZoomable: typeof values?.isZoomable === 'boolean' ? values.isZoomable : true,
      minZoom: typeof values?.minZoom === 'number' ? values.minZoom : 1,
      maxZoom: typeof values?.maxZoom === 'number' ? values.maxZoom : 3,
      startZoom: typeof values?.minZoom === 'number' ? values.minZoom : 1,
      scaleBy: typeof values?.maxZoom === 'number' ? values.scaleBy : 1.5,
      unselectedMarkerOpacity: typeof values?.unselectedMarkerOpacity === 'number' ? values.unselectedMarkerOpacity : 1,
    } as Submap;
  }

  createSubmapLocal(values?: any) {
    return {
      background: values?.background || null,
      backgroundColor: values?.backgroundColor || '#ffffff',
      noSelectionText: values?.noSelectionText || '',
    } as SubmapLocal;
  }

  async createMarkerGroup(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      groupIcon: values?.groupIcon || null,
      position: values?.position || 'right',
      local: await this.local(values, val => this.createMarkerGroupLocal(val)),
      markerCategories: await Promise.all((values?.markerCategories || []).map((g: any) => this.createMarkerCategory(g))),
    } as MarkerGroup;
  }

  createMarkerLocal(values?: any) {
    return {
      title: values?.title || '',
    } as MarkerLocal;
  }

  async createBeacon(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      local: await this.local(values, val => this.createBeaconLocal(val)),
      beaconUUID: values?.beaconUUID || '',
      beaconMinorValue: values?.beaconMinorValue || '',
      beaconMajorValue: values?.beaconMajorValue || '',
      beaconGroup: values?.beaconGroup || null,
      minRange: values?.minRange || 0,
      maxRange: values?.maxRange || 15,
      priority: values?.priority || 1,
    };
  }

  createBeaconLocal(values?: any) {
    return {
      title: values?.title || '',
    } as BeaconLocal;
  }

  async createBeaconGroup(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      local: await this.local(values, val => this.createBeaconGroupLocal(val)),
      action: values.action || null,
    } as BeaconGroup;
  }

  createBeaconGroupLocal(values?: any) {
    return {
      title: values?.title || '',
      actionTitle: values?.actionTitle || '',
    } as BeaconGroupLocal;
  }

  async createMarker(values?: any) {
    return {
      _id: await this.id(values?._id),
      markerType: values?.markerType || 'normal',
      beaconGroup: values?.beaconGroup || null,
      belongToAreaMarker: values?.belongToAreaMarker || null,
      local: await this.local(values, val => this.createMarkerLocal(val)),
      markerIcon: values?.markerIcon || null,
      x: typeof values?.x === 'number' ? values.x : 0.5,
      y: typeof values?.y === 'number' ? values.y : 0.5,
      action: values?.action || null,
      markerGroup: values?.markerGroup || null,
      markerCategory: values?.markerCategory || null,
    } as Marker;
  }

  async createMarkerGroupLocal(values?: any) {
    return {
      title: values?.title || '',
      bottomText: values?.bottomText || '',
    } as MarkerGroupLocal;
  }

  async createMarkerIcon(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      icon: values?.icon || null,
      iconHover: values?.iconHover || null,
      iconSelected: values?.iconSelected || null,
      centerX: typeof values?.centerX === 'number' ? values.centerX : 0.5,
      centerY: typeof values?.centerY === 'number' ? values.centerY : 0.5,
      locationIconX: typeof values?.locationIconX === 'number' ? values.locationIconX : 0.5,
      locationIconY: typeof values?.locationIconY === 'number' ? values.locationIconY : 0.5,
    } as MarkerIcon;
  }

  createMarkerCategoryLocal(values?: any) {
    return {
      title: values?.title || '',
    } as MarkerCategoryLocal;
  }

  async createMarkerCategory(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      markerCategories: await Promise.all((values?.markerCategories || []).map((g: any) => this.createMarkerCategory(g))),
      local: await this.local(values, val => this.createMarkerCategoryLocal(val)),
      categoryIcon: values?.categoryIcon || null,
    } as MarkerCategory;
  }

  async createProductDialogOverlay(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      ctaButton: values?.ctaButton || null,
      product: values?.product || '',
      local: await this.local(values, val => this.createProductDialogOverlayLocal(val)),
      overlayType: 'ProductDialog',
      relatedAssets: values?.relatedAssets || [],
    } as ProductDialogOverlay;
  }

  createProductDialogOverlayLocal(values?: any) {
    return {
      title: values?.title || '',
    } as ProductDialogOverlayLocal;
  }

  createImageTextOverlayLocal(values?: any) {
    return {
      image: values?.image || null,
      text: values?.text || '',
      shortDescription: values?.shortDescription || '',
      title: values?.title || '',
      qrcode: values?.qrcode || null,
    } as ImageTextOverlayLocal;
  }

  async createImageTextOverlay(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      ctaButton: values?.ctaButton || null,
      local: await this.local(values, val => this.createImageTextOverlayLocal(val)),
      overlayType: 'ImageText',
      relatedAssets: values?.relatedAssets || [],
    } as ImageTextOverlay;
  }

  async createAreaDialogOverlay(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      ctaButton: values?.ctaButton || null,
      local: await this.local(values, val => this.createAreaDialogOverlayLocal(val)),
      overlayType: 'AreaDialog',
      relatedAssets: values?.relatedAssets || [],
    } as AreaDialogOverlay;
  }

  createAreaDialogOverlayLocal(values?: any) {
    return {
      shortDescription: values?.shortDescription || '',
      text: values?.text || '',
      title: values?.title || '',
      image: values?.image || null,
      qrcode: values?.qrcode,
    } as AreaDialogOverlayLocal;
  }

  createImageListOverlayLocal(values?: any) {
    return {
      image: values?.image || null,
      shortDescription: values?.shortDescription || '',
      items: values?.items || [],
      title: values?.title || '',
    } as ImageListOverlayLocal;
  }

  async createImageListOverlay(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      local: await this.local(values, val => this.createImageListOverlayLocal(val)),
      overlayType: 'ImageList',
      relatedAssets: values?.relatedAssets || [],
    } as ImageListOverlay;
  }

  async createOverlay(values: { overlayType: OverlayType }) {
    switch (values?.overlayType) {
      case 'ImageText':
        return this.createImageTextOverlay(values);
      case 'ImageList':
        return this.createImageListOverlay(values);
      case 'ProductDialog':
        return this.createProductDialogOverlay(values);
      case 'AreaDialog':
        return this.createAreaDialogOverlay(values);
    }
  }

  createOverlayMarkerAction(values?: any) {
    return {
      markerActionType: 'Overlay',
      overlay: values?.overlay || null,
    } as OverlayMarkerAction;
  }

  createFullscreenDialogMarkerAction(values?: any) {
    return {
      markerActionType: 'FullscreenDialog',
      overlay: values?.overlay || null,
    } as FullscreenDialogMarkerAction;
  }

  createSubmapMarkerAction(values?: any) {
    return {
      markerActionType: 'Submap',
      submap: values?.submap || null,
    } as SubmapMarkerAction;
  }

  createLinkMarkerActionLocal(values?: any) {
    return {
      link: values?.link || '',
    } as LinkMarkerActionLocal;
  }

  async createLinkMarkerAction(values?: any) {
    return {
      markerActionType: 'Link',
      local: await this.local(values, val => this.createLinkMarkerActionLocal(val)),
    } as LinkMarkerAction;
  }

  async createMarkerAction(values: { markerActionType: MarkerActionType }) {
    switch (values.markerActionType) {
      case 'Overlay':
        return this.createOverlayMarkerAction(values);
      case 'Submap':
        return this.createSubmapMarkerAction(values);
      case 'Link':
        return this.createLinkMarkerAction(values);
      case 'FullscreenDialog':
        return this.createFullscreenDialogMarkerAction(values);
    }
  }

  public async mapVersion(values: any) {
    return {
      ...values,
      ...(await this.createMap(values)),
      finalizedAt: values?.finalizedAt || null,
      finalizedBy: values?.finalizedBy || null,
      publishedAt: values?.publishedAt || null,
      publishedBy: values?.publishedBy || null,
      dirty: typeof values?.dirty === 'boolean' ? values.dirty : true,
      change: values?.change || 0,
    };
  }
}
