import { titlePositions } from './../../entities/pagemodules/PageModule';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const formPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('Form'),

  "$.pageModules[?(@.pageModuleType=='Form' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [...titlePositions].map((item) => ({
      label: item,
      value: item,
    })),
  },

  "$.pageModules[?(@.pageModuleType=='Form' && @._id=='$pageModuleId')].form": {
    type: 'dropdown',
  },

  "$.pageModules[?(@.pageModuleType=='Form' && @._id=='$pageModuleId')].buttonFullWidth": {
    type: 'switch',
  },
};
