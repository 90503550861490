import { Pipe, PipeTransform } from '@angular/core';
import { SelectMenuItem } from 'src/app/components/select-menu/select-menu.component';

@Pipe({
  name: 'selectMenuItems',
})
export class SelectMenuItemsPipe implements PipeTransform {
  transform(value: any[], ...args: string[]): SelectMenuItem[] {
    return (value || []).map((v) => {
      return {
        id: v[args[0]],
        title: v[args[1]],
      };
    });
  }
}
