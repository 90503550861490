export interface SystemPluginsConfiguration {
  plugins: SystemPlugin[];
}

export const systemPluginTypes = ['GoToWebinar', 'O365', 'TagsImport', 'ProductsImport'] as const;
export type SystemPluginType = (typeof systemPluginTypes)[number];

export interface SystemPluginsConfig {
  plugins: SystemPlugin[];
}

export interface SystemPlugin {
  pluginType: SystemPluginType;
}

export interface GoToWebinarSystemPlugin extends SystemPlugin {
  pluginType: 'GoToWebinar';
  adminUser: string;
  eventTemplate: string;
  eventType: string;
  defaultRegistrationValue: string;
}

export function isGoToWebinarSystemPlugin(obj?: any): obj is GoToWebinarSystemPlugin {
  return obj?.pluginType === 'GoToWebinar';
}

export interface O365SystemPlugin extends SystemPlugin {
  pluginType: 'O365';
}

export interface TagsImportPlugin extends SystemPlugin {
  pluginType: 'TagsImport';
}

export function isTagsSystemPlugin(obj?: any): obj is TagsImportPlugin {
  return obj?.pluginType === 'TagsImport';
}

export interface ProductsImportPlugin extends SystemPlugin {
  pluginType: 'ProductsImport';
}

export function isProductsSystemPlugin(obj?: any): obj is ProductsImportPlugin {
  return obj?.pluginType === 'ProductsImport';
}

export function isO365SystemPlugin(obj?: any): obj is O365SystemPlugin {
  return obj?.pluginType === 'O365';
}
