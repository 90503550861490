import { Component, Input } from '@angular/core';
import { TableOptions, TableQuery } from '../../../../components/table/table.interfaces';
import { AutoAssignmentsKey, AutoAssignmentsService } from '../../../../services/auto-assignments/auto-assignments.service';
import { Event } from 'src/common/entities/Event';
import { EventRegistrationAutoAssignment } from '../../../../../common/entities/AutoAssignment';
import { AutoAssignmentFactory } from '../../../../../common/factories/AutoAssignmentFactory';
import { BehaviorSubject } from 'rxjs';
import { Inputs } from '../../../../../common/inputs/Inputs';
import { EventRegistrationAutoAssignmentsService } from 'src/app/services/auto-assignments/event-registration-auto-assignments.service';
import { GetEventRegistrationAutoAssignmentsResponse } from 'src/common/api/v1/events/GetEventRegistrationAutoAssignments';

@Component({
  selector: 'app-live-auto-assignments',
  templateUrl: './live-auto-assignments.component.html',
  styleUrls: ['./live-auto-assignments.component.scss'],
  providers: [
    {
      provide: AutoAssignmentsKey,
      useClass: EventRegistrationAutoAssignmentsService,
    },
    AutoAssignmentsService,
  ],
})
export class LiveAutoAssignmentsComponent {
  @Input() event: Event;

  showSidebar: boolean = false;
  isWorking = false;

  editedAutoAssignment?: EventRegistrationAutoAssignment;

  autoAssignmentsTableOptions: TableOptions<EventRegistrationAutoAssignment> = {
    columns: [
      { header: '', visible: 'fixed' }, // for row expansion control
      { header: 'PAGE_EVENT_SECTION_AUTO_ASSIGNMENTS_TYPE', visible: 'fixed' },
      { header: 'PAGE_EVENT_SECTION_AUTO_ASSIGNMENTS_CONDITION', visible: 'fixed' },
      { header: 'PAGE_EVENT_SECTION_AUTO_ASSIGNMENTS_TICKET', visible: 'fixed' },
      { header: 'PAGE_EVENT_SECTION_AUTO_ASSIGNMENTS_SEND_MAIL', visible: 'fixed' },
      { header: '' },
    ],
  };

  inputs: Inputs = {
    '$.query': {
      type: 'query',
      useTags: false,
      header: 'Conditions',
      fieldTypes: ['Country', 'Email'],
    },
    '$.sendMail': {
      type: 'switch',
    },
  };

  refresh = new BehaviorSubject<unknown>(null);
  private editMode = false;

  constructor(private autoAssignmentsService: AutoAssignmentsService) {}

  retrieveAutoAssignments(query: TableQuery<EventRegistrationAutoAssignment>) {
    query.result = this.autoAssignmentsService.getAutoAssignments(query.query, this.event._id) as unknown as GetEventRegistrationAutoAssignmentsResponse;
  }

  closeAutoAssignmentRegistrationDialog() {
    // Hide dialog
    this.showSidebar = false;
  }

  async saveAutoAssignment() {
    if (this.editMode) {
      await this.autoAssignmentsService.updateAutoAssignment(this.editedAutoAssignment, this.event._id);
    } else {
      await this.autoAssignmentsService.createAutoAssignment(this.editedAutoAssignment, this.event._id);
    }
    this.showSidebar = false;
    this.refresh.next(true);
  }

  async deleteAutoAssignment() {
    await this.autoAssignmentsService.deleteAutoAssignment(this.editedAutoAssignment, this.event._id);
    this.showSidebar = false;
    this.refresh.next(true);
  }

  async createNewAutoAssignment() {
    this.editedAutoAssignment = await new AutoAssignmentFactory({ newIds: true }).createEventRegistrationAutoAssignment({ eventId: this.event._id });
    this.editMode = false;
    this.showSidebar = true;
  }
  async editAutoAssignment(autoAssignment: EventRegistrationAutoAssignment): Promise<void> {
    this.editMode = true;
    this.editedAutoAssignment = await new AutoAssignmentFactory().createEventRegistrationAutoAssignment({ ...autoAssignment, eventId: this.event._id });
    this.showSidebar = true;
  }
}
