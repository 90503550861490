<div *skeleton="{ show: tab.loading, menu: true, type: 'table' }">
  <div class="tab-header">
    <h1 *ngIf="mode === 'events'">{{ 'PAGE_EVENTS_HEADER' | translate }}</h1>
    <h1 *ngIf="mode === 'templates'">{{ 'PAGE_TEMPLATES_HEADER' | translate }}</h1>
  </div>
  <div class="tab-menu">
    <p-button (onClick)="refresh()" label="Refresh" icon="pi icon-ic-refresh" [disabled]="working"></p-button>
  </div>
  <div class="panel">
    <div class="panel-header">
      <span *ngIf="mode === 'events'">{{ 'PAGE_EVENTS_HEADER' | translate }}</span>
      <span *ngIf="mode === 'templates'">{{ 'PAGE_TEMPLATES_HEADER' | translate }}</span>
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button *ngIf="mode === 'events' && showImportButton" styleClass="p-button-text" (onClick)="openImportEventsModal()" [label]="'GENERAL_GO_TO_WEBINAR' | translate"></p-button>
        <p-button *ngIf="hasEventCreateRight && mode === 'events'" [label]="'PAGE_EVENT_NEW' | translate" icon="pi icon-ic-add-item" (onClick)="openNewEventOrTemplateModal()"></p-button>
        <p-button
          *ngIf="hasTemplateCreateRight && mode === 'templates'"
          [label]="'PAGE_EVENT_TEMPLATE_NEW' | translate"
          label="New Template"
          icon="pi icon-ic-add-item"
          (onClick)="openNewEventOrTemplateModal({ template: true })"
        >
        </p-button>
      </div>
    </div>
    <div class="search">
      <input [placeholder]="mode === 'templates' ? ('PAGE_EVENT_TEMPLATE_SEARCH' | translate) : ('PAGE_EVENT_SEARCH' | translate)" pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
      <c-quick-filters
        *ngIf="mode === 'events'"
        [title]="'GENERAL_PHASE' | translate"
        [quickFilters]="quickFilters"
        [active]="quickFiltersActive"
        (valueChange)="setQuickFilters($event)"
      ></c-quick-filters>
    </div>
    <!--<div *hasRight="['events.read', 'events.edit', 'events.editContent']">-->
    <!--<div *hasNoRight="['events.read', 'events.edit', 'events.editContent']">-->
    <c-table
      #table
      [$refresh]="refreshTable"
      *ngIf="mode === 'events'"
      mode="query"
      [options]="eventsTableOptions"
      [tableOptionsComponent]="toc"
      (query)="query($event)"
      (clearFilterList)="searchText = ''"
      [baseRoute]="'/events'"
      [routePathProperty]="'_id'"
    >
      <ng-template let-event>
        <span>
          {{ event.internalName }}
        </span>
      </ng-template>

      <ng-template let-event>
        <div class="button-group">
          <button
            [routerLink]="['/live', event._id]"
            *hasRight="{
              resource: 'events:' + event._id,
              rights: [
                'eventsessions.list',
                'eventsessions.create',
                'eventsessions.read',
                'eventsessions.edit',
                'eventsessions.delete',
                'eventregistrations.list',
                'eventregistrations.create',
                'eventregistrations.read',
                'eventregistrations.edit',
                'eventregistrations.delete',
                'eventregistrations.download',
                'eventticketslots.list',
                'eventticketslots.create',
                'eventticketslots.read',
                'eventticketslots.edit',
                'eventticketslots.delete'
              ]
            }"
            class="live-button"
            (click)="linkClick($event)"
          >
            Live Area
          </button>
          <!-- <button [routerLink]="['/events', event._id]" *ngIf="event._rbac.rights | includes: 'events.edit':'events.read':'events.delete'" class="pi pi-file-edit custom-button"></button> -->
        </div>
      </ng-template>

      <ng-template let-event>{{ event.shortId }}</ng-template>
      <ng-template let-event>{{ event.totalStartAt | timeZoneByName : event.currentEventVersion?.eventTimeZone | date : 'short' }}</ng-template>
      <ng-template let-event>{{ event.totalEndAt | timeZoneByName : event.currentEventVersion?.eventTimeZone | date : 'short' }}</ng-template>

      <ng-template let-event>{{ event.eventType[0]?.internalName }}</ng-template>
      <ng-template let-event>{{ event.eventSerie[0]?.internalName }}</ng-template>

      <ng-template let-event>
        <span *ngIf="phase(event) as p">
          <span class="phase" [ngClass]="p | lowercase"></span>
          {{ p }}
        </span>
      </ng-template>

      <ng-template let-event>
        <i *ngIf="event.public" class="pi pi-check public" [pTooltip]="'GENERAL_EVENT_PUBLIC' | translate" tooltipPosition="left"></i>
      </ng-template>
    </c-table>
    <!--</div>-->
    <c-table
      #table
      *ngIf="mode === 'templates'"
      mode="query"
      [options]="templatesTableOptions"
      [tableOptionsComponent]="toc"
      (query)="query($event)"
      [baseRoute]="'/templates'"
      [routePathProperty]="'_id'"
    >
      <ng-template let-event>
        <span>
          <div *hasRight="['templates.read', 'templates.edit']">
            {{ event.internalName }}
          </div>
          <span *hasNoRight="['templates.read', 'templates.edit']">
            {{ event.internalName }}
          </span>
        </span>
      </ng-template>
      <ng-template let-event>{{ event.eventType[0].internalName }}</ng-template>
      <ng-template let-event *hasRight="'events.create'">
        <p-button styleClass="p-button-sm" (onClick)="linkClick($event); openNewEventOrTemplateModal({ fromTemplate: { event: event._id } }, true)" label="Create Event"></p-button>
      </ng-template>
    </c-table>
  </div>
</div>

<p-sidebar #modalCreateEventSideBar class="new-event-modal" *ngIf="showNewEventModal" [(visible)]="showNewEventModal" styleClass="p-sidebar-st" position="right" [modal]="true" [showCloseIcon]="false">
  <c-sidebar-header [headerTitle]="mode === 'templates' ? 'PAGE_EVENT_TEMPLATE_NEW' : 'PAGE_EVENT_NEW'" (onClose)="newEvent = null"></c-sidebar-header>

  <c-sidebar-body>
    <div class="input-group compact">
      <div class="input">
        <p-dropdown name="eventType" [options]="eventTypes" [(ngModel)]="newEvent.eventType" [disabled]="working"></p-dropdown>
      </div>
      <div class="title">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_EVENTTYPE' | translate }}</div>
      <div class="description">{{ 'PAGE_EVENT_NEW_EVENT_DIALOG_EVENT_TYPE_DESCRIPTION' | translate }}</div>
    </div>

    <div class="input-group compact">
      <div class="input">
        <input type="text" pInputText name="internalName" [disabled]="working" [(ngModel)]="newEvent.internalName" />
      </div>
      <div class="title">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_INTERNAL_NAME' | translate }}</div>
      <div class="description">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_INTERNAL_NAME_DESCRIPTION' | translate }}</div>
    </div>

    <div class="input-group compact">
      <div class="input">
        <input type="text" pInputText name="shortId" [disabled]="working" [(ngModel)]="newEvent.shortId" />
      </div>
      <div class="title">{{ 'PAGE_EVENT_SECTION_BASIC_INFORMATION_SHORT_ID' | translate }}</div>
      <div class="description">{{ 'PAGE_EVENT_NEW_EVENT_DIALOG_SHORT_ID_DESCRIPTION' | translate }}</div>
      <div class="description">{{ 'PAGE_EVENT_NEW_EVENT_DIALOG_SHORT_ID_DESCRIPTION_SEO' | translate }}</div>
    </div>

    <div class="input-group compact">
      <div class="input">
        <p-multiSelect
          name="languages"
          optionLabel="languageName"
          optionValue="language"
          [options]="languages"
          [(ngModel)]="newEvent.currentEventVersion.languages"
          [disabled]="working"
        ></p-multiSelect>
      </div>
      <div class="title">{{ 'GENERAL_LANGUAGES' | translate }}</div>
      <div class="description">{{ 'PAGE_EVENT_NEW_EVENT_DIALOG_LANGUAGES_DESCRIPTION' | translate }}</div>
    </div>

    <div class="input-group compact">
      <div class="description">{{ 'PAGE_EVENT_NEW_EVENT_DIALOG_EVENT_CREATION_BASIS_INFO' | translate }}</div>
    </div>

    <div class="p-field-radiobutton">
      <p-radioButton name="eventTemplate" value="none" [(ngModel)]="newEventBaseType" inputId="none"></p-radioButton>
      <label for="none"
        ><div class="title radio-label">{{ 'PAGE_EVENT_NEW_EVENT_DIALOG_BLANK_EVENT_OPTION' | translate }}</div></label
      >
    </div>

    <div class="p-field-radiobutton">
      <p-radioButton name="eventTemplate" value="template" [(ngModel)]="newEventBaseType" inputId="template"></p-radioButton>
      <label for="template"
        ><div class="title radio-label">{{ 'PAGE_EVENT_NEW_EVENT_DIALOG_FROM_TEMPLATE_OPTION' | translate }}</div></label
      >
    </div>
    <div class="input-group compact" *ngIf="newEventBaseType === 'template'">
      <div class="input">
        <c-event-select
          [templateSearch]="true"
          [refreshManually]="true"
          [value]="idTemplate"
          (valueChange)="templateCreateChange($event)"
          [hideRemoveLink]="true"
          [disabled]="working"
        ></c-event-select>
      </div>
      <div class="title">{{ 'GENERAL_TEMPLATE' | translate }}</div>
    </div>

    <div class="p-field-radiobutton">
      <p-radioButton name="eventTemplate" value="event" [(ngModel)]="newEventBaseType" inputId="event"></p-radioButton>
      <label for="event"
        ><div class="title radio-label">{{ 'PAGE_EVENT_NEW_EVENT_DIALOG_FROM_EVENT_OPTION' | translate }}</div></label
      >
    </div>
    <div class="input-group compact" *ngIf="newEventBaseType === 'event'">
      <div class="input">
        <c-event-select [refreshManually]="true" [value]="idEvent" [hideRemoveLink]="true" (valueChange)="eventCreateChange($event)" [disabled]="working"></c-event-select>
      </div>
      <div class="title">{{ 'GENERAL_EVENT' | translate }}</div>
    </div>

    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button class="p-button" [disabled]="working" (click)="createEvent()">Create</button>
    </div>
  </c-sidebar-body>

  <c-sidebar-footer> </c-sidebar-footer>
</p-sidebar>

<p-dialog [(visible)]="showImportEventsModal" [modal]="true" [header]="'GoToWebinar Integration'" (onHide)="onImportEventsDialogClose()">
  <div class="import-search-controls">
    <div class="input-group">
      <div class="title">From date:</div>
      <div class="input">
        <p-calendar
          [ngModel]="externalEventsQuery.fromTime"
          (ngModelChange)="setExternalEventsQueryValue($event, 'fromTime')"
          appendTo="body"
          [showOnFocus]="false"
          [showIcon]="true"
          [showButtonBar]="true"
        ></p-calendar>
      </div>
    </div>
    <div class="input-group">
      <div class="title">To date:</div>
      <div class="input">
        <p-calendar
          [ngModel]="externalEventsQuery.toTime"
          (ngModelChange)="setExternalEventsQueryValue($event, 'toTime')"
          appendTo="body"
          [showOnFocus]="false"
          [showIcon]="true"
          [showButtonBar]="true"
        ></p-calendar>
      </div>
    </div>
    <p-button styleClass="p-button-text" (click)="searchExternalEvents()" [label]="'GENERAL_SEARCH' | translate"></p-button>
  </div>

  <p-table [value]="externalEvents" [paginator]="true" [rows]="15" [totalRecords]="externalEvents.length" [loading]="externalEventsLoading">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="internalName">{{ 'PAGE_EVENTS_HEADER_INTERNAL_NAME' | translate }} <p-sortIcon field="internalName"></p-sortIcon></th>
        <th pSortableColumn="totalStartAt">{{ 'PAGE_EVENTS_HEADER_START' | translate }} <p-sortIcon field="totalStartAt"></p-sortIcon></th>
        <th pSortableColumn="totalEndAt">{{ 'PAGE_EVENTS_HEADER_END' | translate }} <p-sortIcon field="totalEndAt"></p-sortIcon></th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-event>
      <tr>
        <td>{{ event.internalName }}</td>
        <td>{{ event.totalStartAt | date : 'short' }}</td>
        <td>{{ event.totalEndAt | date : 'short' }}</td>
        <td class="import-event-button-area">
          <p-button (click)="importExternalEvent(event)" [disabled]="externalEventImportInProgress">{{
            (externalEventLinks[event.externalEvent.externalEventId] ? 'GENERAL_UPDATE' : 'GENERAL_IMPORT') | translate
          }}</p-button>
          <p-checkbox
            *ngIf="externalEventHasAssets(event)"
            [ngModel]="includeAssetsImportCheckboxes[event.externalEvent.externalEventId]"
            (ngModelChange)="includeAssetsImportCheckboxes[event.externalEvent.externalEventId] = $event"
            label="With OnDemand Asset"
            [binary]="true"
            [disabled]="externalEventImportInProgress"
          >
          </p-checkbox>
          <p-button *ngIf="externalEventLinks[event.externalEvent.externalEventId]" [routerLink]="externalEventLinks[event.externalEvent.externalEventId]">Open event</p-button>
          <p-dropdown
            [options]="eventTemplates"
            [ngModel]="externalEventImportTemplate[event.externalEvent.externalEventId]"
            (ngModelChange)="externalEventImportTemplate[event.externalEvent.externalEventId] = $event"
            appendTo="body"
          ></p-dropdown>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
