import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { GlobalTicketsService } from 'src/app/services/global-tickets/global-tickets.service';
import {
  AccessPolicy,
  AccessPolicyType,
  EventTicketAccessPolicy,
  GlobalTicketAccessPolicy,
  isEventTicketAccessPolicy,
  isGlobalTicketAccessPolicy,
  isNoTicketAccessPolicy,
  isPlatformAccessPolicy,
  isPublicAccessPolicy,
  isVIPTicketAccessPolicy,
  NoTicketAccessPolicy,
  PlatformAccessPolicy,
  VIPTicketAccessPolicy,
} from 'src/common/entities/AccessPolicy';
import { EventVersion } from 'src/common/entities/EventVersion';

@Component({
  selector: 'c-access-policy-edit',
  templateUrl: './access-policy-edit.component.html',
  styleUrls: ['./access-policy-edit.component.scss'],
})
export class AccessPolicyEditComponent implements OnInit {
  _accessPolicies: AccessPolicy[];
  _accessPoliciesLabel: string = '';
  _editAccessPolicies: AccessPolicy[] | null = null;

  @Input()
  set accessPolicies(accessPolicies: AccessPolicy[]) {
    this._accessPolicies = accessPolicies;
    this.ngOnInit();
  }
  get accessPolicies(): AccessPolicy[] {
    return this._accessPolicies || [];
  }

  @Input()
  eventVersion: EventVersion;

  @Input()
  allowedAccessPolicyTypes: AccessPolicyType[];

  @Input()
  inverted: boolean = true;

  @Output()
  accessPoliciesChange: EventEmitter<AccessPolicy[]> = new EventEmitter<AccessPolicy[]>();

  @Output()
  onFocus: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onBlur: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  disabled: boolean = false;

  mode: 'public' | 'platform' | 'platform-inverted' | 'eventticket' | 'globalticket' | 'noticket-loggedin' | 'noticket-loggedout' | 'vipticket' = 'public';
  tickets: { label: string; value: string }[] = [];
  globalTickets: { label: string; value: string }[] = [];
  selectedEventTickets: string[] = [];
  selectedVipTickets: string[] = [];
  selectedGlobalTickets: string[] = [];

  constructor(private configurationService: ConfigurationService, private globalTicketService: GlobalTicketsService) {}

  async ngOnInit(): Promise<void> {
    this.tickets = [];

    if (this.accessPolicies.length > 0) {
      if (this.accessPolicies.find((a) => isEventTicketAccessPolicy(a))) this.mode = 'eventticket';
      if (this.accessPolicies.find((a) => isVIPTicketAccessPolicy(a))) this.mode = 'vipticket';
      if (this.accessPolicies.find((a) => isGlobalTicketAccessPolicy(a))) this.mode = 'globalticket';
      if (this.accessPolicies.find((a) => isPlatformAccessPolicy(a) && !a.inverted)) this.mode = 'platform';
      if (this.accessPolicies.find((a) => isPlatformAccessPolicy(a) && a.inverted)) this.mode = 'platform-inverted';
      if (this.accessPolicies.find((a) => isNoTicketAccessPolicy(a) && a.loggedIn)) this.mode = 'noticket-loggedin';
      if (this.accessPolicies.find((a) => isNoTicketAccessPolicy(a) && !a.loggedIn)) this.mode = 'noticket-loggedout';
      if (this.accessPolicies.find((a) => isPublicAccessPolicy(a))) this.mode = 'public';

      this.selectedEventTickets = this.accessPolicies.filter((a) => a.accessPolicyType === 'EventTicket').map((a) => (a as EventTicketAccessPolicy).eventTicket);
      this.selectedVipTickets = this.accessPolicies.filter((a) => a.accessPolicyType === 'VIPTicket').map((a) => (a as VIPTicketAccessPolicy).eventTicket);
      this.selectedGlobalTickets = this.accessPolicies.filter((a) => a.accessPolicyType === 'GlobalTicket').map((a) => (a as GlobalTicketAccessPolicy).globalTicket);
    }

    this.tickets = (this.eventVersion?.eventTickets || []).map((t) => ({
      label: t.internalName,
      value: t._id,
    }));

    this.globalTickets = (await this.globalTicketService.getGlobalTickets()).items.map((t) => ({
      label: t.internalName,
      value: t._id,
    }));

    const label = await Promise.all(
      this.accessPolicies.map(async (a) => {
        if (a.accessPolicyType === 'EventTicket') {
          return `User has event ticket - ${this.eventVersion?.eventTickets.find((t) => t._id === (a as EventTicketAccessPolicy).eventTicket)?.internalName}`;
        }
        if (a.accessPolicyType === 'VIPTicket') {
          return `User is on the VIP list with ticket - ${this.eventVersion?.eventTickets.find((t) => t._id === (a as VIPTicketAccessPolicy).eventTicket)?.internalName}`;
        }
        if (a.accessPolicyType === 'GlobalTicket' && (a as GlobalTicketAccessPolicy).globalTicket) {
          return `User has global ticket - ${(await this.globalTicketService.getGlobalTicket((a as GlobalTicketAccessPolicy).globalTicket)).internalName}`;
        }
        if (isPlatformAccessPolicy(a)) {
          if (a.inverted) {
            return 'User is not logged in';
          } else {
            return 'User is logged in';
          }
        }
        if (isNoTicketAccessPolicy(a) && a.loggedIn) {
          return 'User has no ticket and is logged in';
        }
        if (isNoTicketAccessPolicy(a) && !a.loggedIn) {
          return 'User has no ticket or is not logged in';
        }
        return a.accessPolicyType;
      })
    );

    this._accessPoliciesLabel = label.join(', ');
  }

  edit() {
    this.onFocus.emit();
    this._editAccessPolicies = this.accessPolicies ? JSON.parse(JSON.stringify(this.accessPolicies)) : [];
  }

  async setAccessPolicy() {
    if (this.mode === 'eventticket') {
      this._editAccessPolicies = [];
      for (const selectedTicket of this.selectedEventTickets) {
        this._editAccessPolicies.push({
          _id: await this.configurationService.newObjectId(),
          accessPolicyType: 'EventTicket',
          event: this.eventVersion.event,
          eventTicket: selectedTicket,
        } as EventTicketAccessPolicy);
      }
    }

    if (this.mode === 'vipticket') {
      this._editAccessPolicies = [];
      for (const selectedTicket of this.selectedVipTickets) {
        this._editAccessPolicies.push({
          _id: await this.configurationService.newObjectId(),
          accessPolicyType: 'VIPTicket',
          event: this.eventVersion.event,
          eventTicket: selectedTicket,
        } as VIPTicketAccessPolicy);
      }
    }

    if (this.mode === 'globalticket') {
      this._editAccessPolicies = [];
      for (const selectedTicket of this.selectedGlobalTickets) {
        this._editAccessPolicies.push({
          _id: await this.configurationService.newObjectId(),
          accessPolicyType: 'GlobalTicket',
          globalTicket: selectedTicket,
        } as GlobalTicketAccessPolicy);
      }
    }

    this.accessPoliciesChange.emit(JSON.parse(JSON.stringify(this._editAccessPolicies)));
    this._editAccessPolicies = null;
  }

  async modeChange() {
    if (this.mode === 'public') {
      this._editAccessPolicies = [{ _id: await this.configurationService.newObjectId(), accessPolicyType: 'Public' }];
    } else if (this.mode === 'platform') {
      this._editAccessPolicies = [{ _id: await this.configurationService.newObjectId(), accessPolicyType: 'Platform', inverted: false } as PlatformAccessPolicy];
    } else if (this.mode === 'platform-inverted') {
      this._editAccessPolicies = [{ _id: await this.configurationService.newObjectId(), accessPolicyType: 'Platform', inverted: true } as PlatformAccessPolicy];
    } else if (this.mode === 'noticket-loggedin') {
      this._editAccessPolicies = [{ _id: await this.configurationService.newObjectId(), accessPolicyType: 'NoTicket', event: this.eventVersion.event, loggedIn: true } as NoTicketAccessPolicy];
    } else if (this.mode === 'noticket-loggedout') {
      this._editAccessPolicies = [{ _id: await this.configurationService.newObjectId(), accessPolicyType: 'NoTicket', event: this.eventVersion.event, loggedIn: false } as NoTicketAccessPolicy];
    } else {
      this._editAccessPolicies = [];
    }
  }
}
