import { Component, Input, OnInit } from '@angular/core';
import { PagesService } from 'src/app/services/pages/pages.service';
import {
  AppFunctionMenuItem,
  DialogMenuItem,
  isAppFunctionMenuItem,
  isDialogMenuItem,
  isLinkMenuItem,
  isPageMenuItem,
  LinkMenuItem,
  MenuItem,
  menuItemTypes,
  PageMenuItem,
} from 'src/common/entities/MenuItem';
import { AppFunctionFactory } from 'src/common/factories/AppFunctionFactory';
import { appFunctionInputs } from 'src/common/inputs/menu-items/AppFunctionInputs';

@Component({
  selector: 'c-user-context-menu-items-edit',
  templateUrl: './user-context-menu-items-edit.component.html',
  styleUrls: ['./user-context-menu-items-edit.component.scss'],
})
export class UserContextMenuItemsEditComponent implements OnInit {
  @Input()
  domainCollectionId: string = null;

  @Input()
  language: string;
  @Input()
  selectedMenuItem: MenuItem;

  menuItemTypeOptions: { label: string; value: string }[] = [];
  pageOptions: { label: string; value: string | null }[];

  get jsonPathParams() {
    return { language: this.language };
  }

  get appFunctionInputs() {
    return appFunctionInputs;
  }

  get appFunction() {
    if (isAppFunctionMenuItem(this.selectedMenuItem)) {
      if (!this.selectedMenuItem.appFunction) {
        this.selectedMenuItem.appFunction = new AppFunctionFactory({ ensureLocals: [this.language] }).createAppFunction();
      }

      if (!this.selectedMenuItem.appFunction.local[this.language]) {
        this.selectedMenuItem.appFunction.local[this.language] = new AppFunctionFactory().createAppFunctionLocal();
      }

      return this.selectedMenuItem.appFunction;
    }

    return {};
  }

  constructor(private pagesService: PagesService) {}

  async ngOnInit() {
    try {
      this.menuItemTypeOptions = [...menuItemTypes].map((m) => ({ label: m, value: m }));

      this.pageOptions = [{ label: 'None', value: null }].concat(
        (await this.pagesService.getPages(this.domainCollectionId)).items.map((e) => ({
          label: `${e.local['en']?.title || (Object.keys(e.local).length > 0 ? e.local[Object.keys(e.local)[0]]?.title : 'Untitled')} ${e.path ? `(/${e.path})` : ''}`,
          value: e._id,
        }))
      );
    } catch (err) {
      console.error(err);
    }
  }

  asLinkMenuItem(menuItem: MenuItem): LinkMenuItem {
    return isLinkMenuItem(menuItem) ? menuItem : null;
  }

  asPageMenuItem(menuItem: MenuItem): PageMenuItem {
    return isPageMenuItem(menuItem) ? menuItem : null;
  }

  asDialogMenuItem(menuItem: MenuItem): DialogMenuItem {
    return isDialogMenuItem(menuItem) ? menuItem : null;
  }
  asAppFunctionMenuItem(menuItem: MenuItem): AppFunctionMenuItem {
    return isAppFunctionMenuItem(menuItem) ? menuItem : null;
  }
}
