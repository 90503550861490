import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { CustomField, CustomFieldSelect, CustomFieldSelectOption, CustomFieldTicketAttendees, CustomFieldTicketAttendeesOption, CustomFieldTicketSelfVisit } from '../entities/CustomField';

export class CustomFieldFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createCustomFieldAttendeeOptionField(values?: any): Promise<CustomFieldTicketAttendeesOption> {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      local: await this.local(values, (val) => this.createTitleLocal(val)),
      fieldType: values?.fieldType || '',
      mandatory: typeof values?.mandatory === 'boolean' ? values.mandatory : true,
      maxCharacters: values?.maxCharacters || null,
      deletedAt: values?.deletedAt || null,
      deletedBy: values?.deletedBy || null,
    };
  }

  createTitleLocal(values?: any) {
    return {
      title: values?.title || '',
    };
  }

  createCustomFieldSelectOptionLocal(values?: any) {
    return values || '';
  }

  async createCustomFieldSelectOptionField(values?: any): Promise<CustomFieldSelectOption> {
    return {
      key: values?.key || null,
      local: await this.local(values, (val) => this.createCustomFieldSelectOptionLocal(val)),
    };
  }

  async createCustomField(values: any): Promise<CustomField> {
    switch (values?.customFieldType) {
      case 'select':
        return this.createCustomFieldSelect(values);
      case 'ticketAttendees':
        return await this.createCustomFieldTicketAttendee(values);
      case 'checkbox':
        return await this.createCustomFieldCheckbox(values);
      case 'ticketSelfVisit':
        return await this.createCustomFieldTicketSelfVisit(values);
    }
    return await this._createCustomField(values);
  }

  private async _createCustomField(values: any): Promise<CustomField> {
    return {
      _id: values?._id || null,
      local: await this.local(values, (val) => this.createCustomFieldLocal(val)),
      customFieldType: values?.customFieldType || null,
      internalName: values?.internalName || null,
      default: values?.default || undefined,
    };
  }

  private createCustomFieldLocal(values: any) {
    return {
      title: values.title || '',
      description: values.description || '',
      checkboxDescription: values.checkboxDescription || '',
      default: values.default || null,
    };
  }

  async createCustomFieldSelect(values: any): Promise<CustomFieldSelect> {
    return {
      ...(await this._createCustomField(values)),
      options: await Promise.all(values?.options?.map(async (opt: any) => await this.createCustomFieldSelectOptionField(opt)) || []),
    } as CustomFieldSelect;
  }

  async createCustomFieldTicketAttendee(values: any): Promise<CustomFieldTicketAttendees> {
    return {
      ...(await this._createCustomField(values)),
      fields: await Promise.all((values?.fields || []).map(async (opt: CustomFieldTicketAttendeesOption) => await this.createCustomFieldAttendeeOptionField(opt))),
    } as CustomFieldTicketAttendees;
  }

  async createCustomFieldTicketSelfVisit(values: any): Promise<CustomFieldTicketSelfVisit> {
    return {
      ...(await this._createCustomField(values)),
      default: typeof values?.default === 'boolean' ? values?.default : true,
    } as CustomFieldTicketSelfVisit;
  }

  async createCustomFieldCheckbox(values: any): Promise<CustomFieldTicketSelfVisit> {
    return {
      ...(await this._createCustomField(values)),
      default: typeof values?.default === 'boolean' ? values?.default : false,
    } as CustomFieldTicketSelfVisit;
  }
}
