<div *skeleton="{ show: loading }">
  <div class="tab-header">
    <h1>
      <span class="pre-title">{{ 'GENERAL_BLACKLIST_ENTRY' | translate }}</span>
      <span *ngIf="asDomainBlacklistEntry() as domainBlacklistEntry">{{ domainBlacklistEntry?.domain }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <p-button *hasRight="'configuration.edit'" icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" [disabled]="loading || saving || !isValid()" (onClick)="save()"></p-button>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_GLOBAL_PROPERTIES' | translate }}
    </div>
    <div *ngIf="blacklistEntry && blacklistEntryTypes" class="panel-body">
      <div class="input-group">
        <div class="input">
          <p-dropdown
            [options]="blacklistEntryTypes | dropdownOptions : 'BlacklistEntryType' | async"
            optionLabel="name"
            optionValue="code"
            [(ngModel)]="blacklistEntry.blacklistEntryType"
            [disabled]="blacklistEntryId !== 'new'"
          ></p-dropdown>
        </div>
        <div class="title">{{ 'PAGE_BLACKLIST_ENTRY_TYPE' | translate }}</div>
        <div class="description">{{ 'PAGE_BLACKLIST_ENTRY_TYPE_DESCRIPTION' | translate }}</div>
      </div>

      <div *ngIf="asDomainBlacklistEntry() as domainBlacklistEntry">
        <div class="input-group">
          <div class="input">
            <input type="text" pInputText [(ngModel)]="domainBlacklistEntry.domain" />
          </div>
          <div class="title">{{ 'PAGE_BLACKLIST_ENTRY_DOMAIN' | translate }}</div>
          <div class="description">{{ 'PAGE_BLACKLIST_ENTRY_DOMAIN_DESCRIPTION' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
