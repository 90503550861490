import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventKeytopic, EventVersion } from 'src/common/entities/EventVersion';
import { Keytopic } from 'src/common/entities/Keytopic';

import { KeytopicsService } from 'src/app/services/keytopics/keytopics.service';
import { EventsService } from 'src/app/services/events/events.service';
import { EventKeytopicFactory } from 'src/common/factories/EventKeytopicFactory';
import { TableOptions } from 'src/app/components/table/table.interfaces';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { TableComponent } from 'src/app/components/table/table.component';

@Component({
  selector: 'c-event-keytopics',
  templateUrl: './event-keytopics.component.html',
  styleUrls: ['./event-keytopics.component.scss'],
})
export class EventKeytopicsComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) table: TableComponent<EventKeytopic>;

  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  language: string;

  keytopics: Keytopic[] = [];
  filterKeytopicsText: string = '';

  get filteredKeytopics(): Keytopic[] {
    return this.keytopics.filter((k) => {
      return !this.eventVersion.eventKeytopics.find((ek) => ek.keytopic === k._id) && k.internalName.toLowerCase().includes(this.filterKeytopicsText.toLowerCase());
    });
  }

  keytopicEditId: string = null;
  keytopicEditHelper: any = {
    override: false,
  };

  get editEventKeytopic(): EventKeytopic {
    return this.eventVersion.eventKeytopics.find((k) => k.keytopic === this.keytopicEditId);
  }

  showKeytopicSelect: boolean = false;

  get showKeytopicEdit() {
    return this.keytopicEditId !== null;
  }

  set showKeytopicEdit(val: boolean) {
    if (!val) this.keytopicEditId = null;
  }

  keytopicsTableOptions: TableOptions<EventKeytopic> = {
    columns: [
      {
        header: 'GENERAL_INTERNAL_NAME',
        visible: 'fixed',
      },
      { header: 'GENERAL_TITLE' },
      { header: 'GENERAL_SHORT_DESCRIPTION' },
      { header: 'GENERAL_OVERRIDE' },
      { header: 'GENERAL_MOVE' },
    ],
  };

  generalJsonPathes: string[] = [
    "$.eventKeytopics[?(@.keytopic == '$keytopicId')].override.icon",
    "$.eventKeytopics[?(@.keytopic == '$keytopicId')].override.header",
    "$.eventKeytopics[?(@.keytopic == '$keytopicId')].override.local.$language.title",
    "$.eventKeytopics[?(@.keytopic == '$keytopicId')].override.local.$language.shortDescription",
    "$.eventKeytopics[?(@.keytopic == '$keytopicId')].override.local.$language.longDescription",
  ];

  keytopicEditTab = 'general';
  subscriptions: Subscription[] = [];

  constructor(private keytopicsService: KeytopicsService, private eventsService: EventsService, private utilsService: UtilsService) {}

  async ngOnInit() {
    this.keytopics = (await this.keytopicsService.getKeytopics()).items;

    this.subscriptions.push(
      this.eventsService.lastEventVersionPatch(this.eventVersion._id).subscribe((patch) => {
        if (this.utilsService.startsWithJsonpath(patch.patch.jsonpath, '$.eventKeytopics', patch.patch.jsonpathParams)) {
          this.table.refresh();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  editKeytopic(keytopic: EventKeytopic) {
    this.keytopicEditHelper.override = keytopic.override !== null;
    this.keytopicEditId = keytopic.keytopic;
  }

  async addKeytopic(keytopic: Keytopic) {
    // Prepare new event keytopic for editing
    const newKeyTopic: EventKeytopic = await new EventKeytopicFactory().createEventKeytopic({
      keytopic: keytopic._id,
    });
    await this.eventsService.patch(this.eventVersion, {
      command: 'push',
      jsonpath: '$.eventKeytopics',
      value: newKeyTopic,
    });
    // Close add keytopic dialog
    this.showKeytopicSelect = false;
    // Open edit keytopic dialog
    this.editKeytopic(newKeyTopic);
  }

  keytopicUp(eventKeytopic: EventKeytopic) {
    this.eventsService.patch(this.eventVersion, {
      command: 'up',
      jsonpath: `$.eventKeytopics[?(@.keytopic == '$keytopicId')]`,
      jsonpathParams: {
        keytopicId: eventKeytopic.keytopic,
      },
    });
  }

  keytopicDown(eventKeytopic: EventKeytopic) {
    this.eventsService.patch(this.eventVersion, {
      command: 'down',
      jsonpath: `$.eventKeytopics[?(@.keytopic == '$keytopicId')]`,
      jsonpathParams: {
        keytopicId: eventKeytopic.keytopic,
      },
    });
  }

  keytopicDelete(eventKeytopic: EventKeytopic) {
    this.eventsService.patch(this.eventVersion, {
      command: 'delete',
      jsonpath: `$.eventKeytopics[?(@.keytopic == '$keytopicId')]`,
      jsonpathParams: {
        keytopicId: eventKeytopic.keytopic,
      },
    });
  }

  overrideKeytopic(override: boolean, eventKeytopic: EventKeytopic) {
    this.eventsService.patch(this.eventVersion, {
      command: 'set',
      jsonpath: `$.eventKeytopics[?(@.keytopic == '$keytopicId')].override`,
      jsonpathParams: {
        keytopicId: eventKeytopic.keytopic,
      },
      value: override ? this.keytopics.find((k) => k._id === eventKeytopic.keytopic) : null,
    });
  }
}
