import { MapFactory } from './../../factories/MapFactory';

import { Inputs } from '../Inputs';
import { Factory } from '../../factories/Factory';

export const mapInputs: Inputs = {
  '$.internalName': {
    header: 'Internal Name',
  },
  '$.beaconSearchTimer': {
    type: 'number',
    description: 'Defines how often the app searches for Beacons (in Seconds). This can only be defined for Android devices.',
  },
  '$.beaconSearch.showBeaconSearchIcon': {
    type: 'switch',
    header: 'Show Beacon Search Icon',
    description: 'If checked, an animated icon is displayed to the user when no beacongroup is found. Otherwise not icon is displayed',
  },
  '$.beaconSearch.local.$language': {
    factory: async () => Factory.map().createBeaconSearchLocal({}),
  },
  '$.beaconSearch.local.$language.beaconSearchText': {
    header: 'Beacon Search Text',
    description: 'Text will be displayed if the user clicks on the icon - when no beacongroup is found',
    type: 'text',
  },
  '$.beaconSearch.beaconSearchCTA': {
    header: 'Beacon Search CTA Button',
    description: 'CTA will be displayed if the user clicks on the icon - when no beacongroup is found',
    type: 'ctabutton',
  },
  '$.beaconSearch.local.$language.beaconNoRightsText': {
    header: 'Beacon No Rights Text',
    description: 'Text will be displayed if the user clicks on the icon - when no rights to search for beacons is granted',
    type: 'text',
  },
  '$.beaconSearch.beaconNoRightsCTA': {
    header: 'Beacon No Rights CTA Button',
    description: 'CTA will be displayed if the user clicks on the icon - when no rights to search for beacons is granted',
    type: 'ctabutton',
  },
  '$.beaconRefreshInterval': {
    type: 'number',
    description:
      'Defines how long (in Seconds) a previously discovered beacon group needs to be unprioritized in order to change to a different beacongroup (to avoid switching back and forth on 2 equality strong signals)',
  },
  '$.idleTime': {
    type: 'number',
  },

  '$.submaps': {
    factory: async () => [],
    childFactory: async () => new MapFactory().createSubmap({}),
  },
  "$.submaps[?(@._id=='$submapId')].markers[?(@._id=='$markerId')].local.$language.title": {},
  "$.submaps[?(@._id=='$submapId')].internalName": {},
  "$.submaps[?(@._id=='$submapId')].isZoomable": {
    type: 'switch',
  },
  "$.submaps[?(@._id=='$submapId')].minZoom": {
    type: 'number',
    maxFractionDigits: 2,
    minFractionDigits: 2,
    mode: 'decimal',
  },
  "$.submaps[?(@._id=='$submapId')].maxZoom": {
    type: 'number',
    maxFractionDigits: 2,
    minFractionDigits: 2,
    mode: 'decimal',
  },
  "$.submaps[?(@._id=='$submapId')].startZoom": {
    type: 'number',
    maxFractionDigits: 2,
    minFractionDigits: 2,
    mode: 'decimal',
  },
  "$.submaps[?(@._id=='$submapId')].scaleBy": {
    type: 'number',
    maxFractionDigits: 2,
    minFractionDigits: 2,
    mode: 'decimal',
  },
  "$.submaps[?(@._id=='$submapId')].unselectedMarkerOpacity": {
    type: 'number',
    maxFractionDigits: 2,
    minFractionDigits: 2,
    mode: 'decimal',
  },
  "$.submaps[?(@._id=='$submapId')].local.$language.background": {
    type: 'imageasset',
  },
  "$.submaps[?(@._id=='$submapId')].markers[?(@._id=='$markerId')].markerIcon": {
    type: 'dropdown',
  },
  "$.submaps[?(@._id=='$submapId')].markers[?(@._id=='$markerId')].markerGroup": {
    type: 'dropdown',
  },
  "$.submaps[?(@._id=='$submapId')].markers[?(@._id=='$markerId')].action.overlay": {
    type: 'dropdown',
  },
  "$.submaps[?(@._id=='$submapId')].markers[?(@._id=='$markerId')].action.submaps": {
    type: 'dropdown',
  },
  "$.submaps[?(@._id=='$submapId')].markers[?(@._id=='$markerId')].action.local.$language.link": {},
};
