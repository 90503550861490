<div *ngIf="!(editPage || editEventPhase || newPage)" class="sidebar-container">
  <c-sidebar-header [headerTitle]="'GENERAL_OVERVIEW'" [headerSubTitle]="('GENERAL_PHASES' | translate) + ' & ' + ('GENERAL_PAGES' | translate)" (onClose)="onClose.emit(true)"></c-sidebar-header>

  <div class="sidebar-body">
    <div class="layout-menu">
      <div *ngFor="let eventPhase of eventPhases; trackBy: trackByIndex">
        <div class="item" [class.selected]="currentEventPhase == eventPhase">
          Phase {{ eventPhase | titlecase }}
          <div *hasRight="'events.editContent'" class="actions">
            <i class="pi icon-ic-edit" (click)="editEventPhase = eventPhase; $event.stopPropagation()"></i>
          </div>
        </div>
        <div
          class="sub-item"
          *ngFor="let page of eventVersion.phase[eventPhase].pages; let index = index; let last = last; let first = first"
          (click)="setCurrentPage(eventPhase, page); onClose.emit(true)"
          [class.selected]="currentPage === page"
        >
          <div class="textIcons">
            <div class="icons">
              <i *ngIf="page.displayOption !== 'onlyApp'" class="pi icon-ic-language"></i>
              <i *ngIf="page.displayOption !== 'onlyWeb'" class="pi icon-ic-devices-preview-phone"></i>
            </div>
            <div class="title">{{ pageTitle(page) }}</div>
          </div>

          <div *hasRight="'events.editContent'" class="actions">
            <i class="pi pi-chevron-up" (click)="pageUp(eventPhase, page); $event.stopPropagation()" *ngIf="!first"></i>
            <i class="pi pi-chevron-down" (click)="pageDown(eventPhase, page); $event.stopPropagation()" *ngIf="!last"></i>
            <i class="pi pi-trash" (click)="confirmDeletePage($event, eventPhase, page); $event.stopPropagation()"></i>
            <i class="pi icon-ic-edit" (click)="editPage = { page: page, eventPhase: eventPhase }; $event.stopPropagation()"></i>
          </div>
        </div>
        <div class="sub-item add" *ngIf="!(eventVersion | isDisabled)">
          <button
            *hasRight="'events.editContent'"
            pButton
            type="button"
            class="p-button-sm p-button-outlined"
            [label]="('GENERAL_ADD' | translate) + ' ' + ('GENERAL_PAGE' | translate)"
            icon="pi icon-ic-add-item"
            (click)="newPage = { eventPhase: eventPhase }"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
<p-confirmPopup [key]="'eventLayoutConfirmPopup'"></p-confirmPopup>
<div *ngIf="newPage" class="sidebar-container">
  <c-sidebar-header [headerTitle]="'GENERAL_ADD'" [headerSubTitle]="'GENERAL_PAGE'" [showBackButton]="true" (onBack)="newPage = null" (onClose)="onClose.emit(true)"></c-sidebar-header>

  <div class="sidebar-body">
    <div *ngIf="!newPage.mode">
      <div class="new-page" (click)="newPage.mode = 'new'">
        <div class="label">{{ 'GENERAL_NEW' | translate }}</div>
        <!-- <div class="icon" [ngStyle]="{'background-image': 'url(' + eventStage.image + ')'}"></div> -->
      </div>
      <div class="new-page" (click)="newPage.mode = 'clone'">
        <div class="label">{{ 'GENERAL_CLONE' | translate }}</div>
        <!-- <div class="icon" [ngStyle]="{'background-image': 'url(' + eventStage.image + ')'}"></div> -->
      </div>
    </div>

    <div *ngIf="newPage.mode === 'new'">
      <div *ngIf="!newPage.type">
        <c-page-type-select (selected)="addPage($event, newPage.eventPhase)" (onClose)="newPage = null"></c-page-type-select>
      </div>
    </div>

    <div *ngIf="newPage.mode === 'clone'">
      <div class="form-container">
        <h3>Select Page to clone:</h3>
        <div class="layout-menu">
          <div *ngFor="let eventPhase of eventPhases; trackBy: trackByIndex">
            <div class="item">Phase {{ eventPhase | titlecase }}</div>
            <div
              class="sub-item"
              *ngFor="let page of eventVersion.phase[eventPhase].pages; let index = index; let last = last; let first = first"
              (click)="clonePage(newPage.eventPhase, page); newPage = null"
              [class.selected]="currentPage === page"
            >
              <div class="title">{{ pageTitle(page) }}</div>
            </div>
            <div class="sub-item add" *ngIf="!(eventVersion | isDisabled)">
              <!-- <p-tieredMenu [model]="addPageMenu[eventPhase]"></p-tieredMenu>  -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Edit Event Phase Sidebar Dialog -->
<div *ngIf="editEventPhase" class="sidebar-container">
  <c-sidebar-header [headerTitle]="'GENERAL_EVENTPHASE_EDIT'" [headerSubTitle]="editEventPhase | titlecase" (onClose)="editEventPhase = null"></c-sidebar-header>

  <div class="sidebar-body">
    <c-event-patch-input jsonpath="$.phase.$eventPhase.showMeetYourExpertInMenu" [jsonpathParams]="{ eventPhase: editEventPhase }" [eventVersion]="eventVersion"></c-event-patch-input>
  </div>

  <c-sidebar-footer [autoSaveInfo]="true"></c-sidebar-footer>
</div>

<!-- Edit Event Page Sidebar Dialog -->
<div *ngIf="editPage != null" class="sidebar-container">
  <c-page-edit
    [event]="event"
    [eventVersion]="eventVersion"
    [eventPhase]="editPage.eventPhase"
    [page]="editPage.page"
    jsonpath="$.phase.$eventPhase.pages[?(@._id=='$pageId')]"
    [jsonpathParams]="{ eventPhase: editPage.eventPhase, pageId: editPage.page._id, language: currentLanguage }"
    (onClose)="editPage = null; onClose.emit(true)"
    class="sidebar-container"
  ></c-page-edit>
</div>
