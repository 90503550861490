import { NgIf } from '@angular/common';
import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, Directive, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

export type SkeletonType = 'form' | 'table';

@Component({
  template: `
    <ng-template #templateRefForm>
      <div class="tab-header">
        <h1>Loading ...</h1>
      </div>
      <div class="tab-menu" *ngIf="menu">
        <div class="skeleton-container" style="width: 150px">
          <p-skeleton></p-skeleton>
        </div>
        <div class="skeleton-container" style="width: 100px">
          <p-skeleton></p-skeleton>
        </div>
        <div class="skeleton-container" style="width: 120px">
          <p-skeleton></p-skeleton>
        </div>
        <div class="skeleton-container" style="width: 150px">
          <p-skeleton></p-skeleton>
        </div>
      </div>
      <div class="tab-menu" *ngIf="!menu"></div>
      <div class="tab-subtabs" *ngIf="tabs">
        <div class="tabs">
          <div class="skeleton-container" style="width: 160px">
            <p-skeleton height="9pt"></p-skeleton>
          </div>
          <div class="skeleton-container" style="width: 140px">
            <p-skeleton height="9pt"></p-skeleton>
          </div>
          <div class="skeleton-container" style="width: 160px">
            <p-skeleton height="9pt"></p-skeleton>
          </div>
          <div class="skeleton-container" style="width: 180px">
            <p-skeleton height="9pt"></p-skeleton>
          </div>
          <div class="skeleton-container" style="width: 140px">
            <p-skeleton height="9pt"></p-skeleton>
          </div>
        </div>
      </div>
      <div class="panel">
        <div class="panel-header">
          <p-skeleton width="20rem"></p-skeleton>
        </div>
        <div class="panel-body">
          <div class="input-group">
            <div class="input">
              <p-skeleton height="2rem"></p-skeleton>
            </div>
            <div class="title">
              <p-skeleton width="20%"></p-skeleton>
            </div>
            <div class="description">
              <p-skeleton width="40%" height="7pt"></p-skeleton>
            </div>
          </div>
          <div class="input-group">
            <div class="input">
              <p-skeleton height="6rem"></p-skeleton>
            </div>
            <div class="title">
              <p-skeleton width="20%"></p-skeleton>
            </div>
            <div class="description">
              <p-skeleton width="40%" height="7pt"></p-skeleton>
            </div>
          </div>
          <div class="input-group">
            <div class="input">
              <p-skeleton height="2rem"></p-skeleton>
            </div>
            <div class="title">
              <p-skeleton width="20%"></p-skeleton>
            </div>
            <div class="description">
              <p-skeleton width="40%" height="7pt"></p-skeleton>
            </div>
          </div>
          <div class="input-group">
            <div class="input">
              <p-skeleton height="2rem"></p-skeleton>
            </div>
            <div class="title">
              <p-skeleton width="20%"></p-skeleton>
            </div>
            <div class="description">
              <p-skeleton width="40%" height="7pt"></p-skeleton>
            </div>
          </div>
          <div class="input-group">
            <div class="input">
              <p-skeleton height="2rem"></p-skeleton>
            </div>
            <div class="title">
              <p-skeleton width="20%"></p-skeleton>
            </div>
            <div class="description">
              <p-skeleton width="40%" height="7pt"></p-skeleton>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #templateRefTable>
      <div class="tab-header">
        <h1>Loading ...</h1>
      </div>
      <div class="tab-menu" *ngIf="menu">
        <div class="skeleton-container" style="width: 150px">
          <p-skeleton></p-skeleton>
        </div>
        <div class="skeleton-container" style="width: 100px">
          <p-skeleton></p-skeleton>
        </div>
        <div class="skeleton-container" style="width: 120px">
          <p-skeleton></p-skeleton>
        </div>
        <div class="skeleton-container" style="width: 150px">
          <p-skeleton></p-skeleton>
        </div>
      </div>
      <div class="tab-menu" *ngIf="!menu"></div>
      <div class="panel">
        <div class="panel-header">
          <p-skeleton width="20rem"></p-skeleton>
        </div>
        <table>
          <thead>
            <tr>
              <th>
                <p-skeleton></p-skeleton>
              </th>
              <th>
                <p-skeleton></p-skeleton>
              </th>
              <th>
                <p-skeleton></p-skeleton>
              </th>
              <th>
                <p-skeleton></p-skeleton>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
            <tr>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
              <td>
                <p-skeleton></p-skeleton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .tab-menu .skeleton-container {
        display: inline-block;
        padding: 10px 16px;
        border-right: 1px solid #efefef;
      }

      .tab-subtabs .skeleton-container {
        display: inline-block;
        padding: 16px 24px;
        border-right: 1px solid #efefef;
      }

      .tab-subtabs .skeleton-container:last-of-type {
        border-right: none;
      }

      .input-group .input {
        border: none;
        display: block;
      }
    `,
  ],
})
export class SkeletonTemplateComponent {
  @ViewChild('templateRefForm', { static: true }) public templateRefForm: TemplateRef<any>;
  @ViewChild('templateRefTable', { static: true }) public templateRefTable: TemplateRef<any>;

  menu: boolean = false;
  tabs: boolean = false;

  public getTemplateRef(type: SkeletonType) {
    switch (type) {
      case 'table':
        return this.templateRefTable;
    }

    return this.templateRefForm;
  }
}

@Directive({
  selector: '[skeleton]',
})
export class SkeletonDirective implements AfterViewInit {
  private componentRef: ComponentRef<SkeletonTemplateComponent>;

  private ngIfDirective: NgIf;

  @Input()
  public set skeleton(val: { show: boolean; type?: 'form' | 'table'; menu?: boolean; tabs?: boolean }) {
    if (val.type && val.type !== this.type) {
      this.type = val.type;
      if (typeof val.menu === 'boolean') this.menu = val.menu;
      if (typeof val.tabs === 'boolean') this.tabs = val.tabs;
      this.initComponentRef();
    }
    this.ngIfDirective.ngIf = !val.show;
    // this.ngIfDirective.ngIf = false;
  }

  type: SkeletonType = 'form';
  menu: boolean;
  tabs: boolean;

  constructor(private templateRef: TemplateRef<any>, private resolver: ComponentFactoryResolver, private viewContainer: ViewContainerRef) {
    this.ngIfDirective = new NgIf(this.viewContainer, this.templateRef);
    this.ngIfDirective.ngIf = false;
  }

  ngAfterViewInit(): void {
    this.initComponentRef();
  }

  private initComponentRef() {
    const factory = this.resolver.resolveComponentFactory(SkeletonTemplateComponent);
    this.componentRef = this.viewContainer.createComponent(factory);

    setTimeout(() => {
      this.componentRef.instance.menu = this.menu;
      this.componentRef.instance.tabs = this.tabs;

      const templateRef = this.componentRef.instance.getTemplateRef(this.type);

      this.ngIfDirective.ngIfElse = templateRef;
    }, 0);

    return this.componentRef;
  }
}
