import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { HighlightTeaserItem, isHighlightTeaserItem, isLinkTeaserItem, LinkTeaserItem, TeaserEventStage, TeaserItem, TeaserItemType, teaserItemTypes } from 'src/common/entities/EventStage';
import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';

import { Factory } from 'src/common/factories/Factory';

import { UtilsService } from 'src/app/services/utils/utils.service';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';

@Component({
  selector: 'c-teaser-event-stage-edit',
  templateUrl: './teaser-event-stage-edit.component.html',
  styleUrls: ['./teaser-event-stage-edit.component.scss'],
})
export class TeaserEventStageEditComponent implements OnInit, OnDestroy {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  eventStage: TeaserEventStage;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  currentLanguage: string = null;

  teaserItemTypes: { value: string; label: string }[] = [];
  newTeaserItemType: TeaserItemType = 'Highlight';

  sessions: { value: string; label: string }[] = [];

  subscriptions: Subscription[] = [];

  itemJsonpath = `$.local.$language.items[?(@._id=='$itemId')]`;

  inputJsonpathes: string[] = ['$.local.$language.title', '$.local.$language.subtitle', '$.local.$language.smallHeadline', '$.background', '$.local.$language.showCountdown', '$.ctaButton'];

  tab = 'general';

  constructor(private collaborationService: CollaborationService, private utilsService: UtilsService, private activatedRoute: ActivatedRoute, private utils: UtilsService) {}

  async ngOnInit() {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.currentLanguage = params.language;
      })
    );

    this.teaserItemTypes = (
      await Promise.all(
        teaserItemTypes.map(async (p) => ({
          value: p,
          label: await this.utils.translate(`GENERAL_TEASERITEMTYPE_${p.toUpperCase()}`),
        }))
      )
    ).sort((a, b) => a.label.localeCompare(b.label));

    this.sessions = [{ label: '', value: null }].concat(
      this.event.currentEventVersion.sessions.map((s) => {
        const title = s.local['en']?.title || s.local['de']?.title || (Object.keys(s.local).length > 0 ? s.local[Object.keys(s.local)[0]] : '');
        return {
          label: `${new Date(s.startAt).toLocaleDateString()} ${new Date(s.startAt).toLocaleTimeString()} - ${new Date(s.endAt).toLocaleDateString()} ${new Date(
            s.endAt
          ).toLocaleTimeString()} | ${title}`,
          value: s._id,
        };
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  isDisabled(): boolean {
    return this.collaborationService.isDisabled(this.collaborationKey);
  }

  trackByIndex(index: number, item: any) {
    return index;
  }

  asHighlightTeaserItem(teaserItem: TeaserItem): HighlightTeaserItem {
    return isHighlightTeaserItem(teaserItem) ? teaserItem : null;
  }

  asLinkTeaserItem(teaserItem: TeaserItem): LinkTeaserItem {
    return isLinkTeaserItem(teaserItem) ? teaserItem : null;
  }

  async addTeaserItem() {
    const item = await Factory.eventStage().teaserItem({
      teaserItemType: this.newTeaserItemType,
    });

    if (item) {
      await this.collaborationService.patch(this.collaborationKey, this.eventVersion || this.page, {
        command: 'push',
        jsonpath: this.utilsService.combineJsonpath([this.jsonpath, '$.local.$language.items']),
        jsonpathParams: {
          ...this.jsonpathParams,
          language: this.currentLanguage,
        },
        value: item,
      });
    }
  }

  async deleteTeaserItem(item: TeaserItem) {
    await this.collaborationService.patch(this.collaborationKey, this.eventVersion || this.page, {
      command: 'delete',
      jsonpath: this.utilsService.combineJsonpath([this.jsonpath, `$.local.$language.items[?(@._id=='$itemId')]`]),
      jsonpathParams: {
        ...this.jsonpathParams,
        itemId: item._id,
        language: this.currentLanguage,
      },
    });
  }

  itemJsonpathParams(item: TeaserItem) {
    return {
      ...this.jsonpathParams,
      language: this.currentLanguage,
      itemId: item._id,
    };
  }
}
