<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'GENERAL_ADMINUSER_GROUPS' | translate }}</h1>
  </div>
  <div class="tab-menu"></div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_ADMINUSER_GROUPS' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button [label]="('GENERAL_NEW' | translate) + ' ' + ('GENERAL_ADMINUSER_GROUP' | translate)" icon="pi icon-ic-add-item" (onClick)="newGroup()"></p-button>
      </div>
    </div>
    <c-table mode="query" [options]="tableOptions" [tableOptionsComponent]="toc" (query)="query($event)" [baseRoute]="'/configuration/groups'" [routePathProperty]="'_id'">
      <ng-template let-group>
        <div>{{ group.name }}</div>
      </ng-template>
    </c-table>
  </div>
</div>
