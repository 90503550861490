import { HeadlineType } from './../../entities/Attributes';
import { TilesPageModule, TilesPageModuleAttributes } from './../../entities/pagemodules/TilesPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class TilesPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public createTilesPageModuleLocalItem(values?: any) {
    return {
      image: values?.image || null,
      title: values?.title || '',
      linkType: values?.linkType || null,
      link: values?.link || '',
    };
  }

  public tilesPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): TilesPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public createTilesPageModuleLocal(values?: any) {
    return {
      title: values?.title || '',
      title_attr: this.tilesPageModuleAttributes(values?.title_attr),
      items: (values?.items || []).map((i: any) => this.createTilesPageModuleLocalItem(i)),
    };
  }

  public async tilesPageModule(values?: any): Promise<TilesPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'Tiles',
      tileSize: values?.tileSize || 'normal',
      titlePosition: values?.titlePosition || 'centered',
      displayAs: values?.displayAs || 'imageWithTitle',
      showTitleBox: values?.showTitleBox || false,
      local: await this.local(values, (val) => this.createTilesPageModuleLocal(val)),
      ctaButton: values?.ctaButton || null,
    };
  }
}
