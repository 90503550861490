import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AndCondition, FieldCondition, fieldConditionMapping, FieldType, OrCondition } from '../../../common/entities/Condition';
import { Query } from '../../../common/entities/Query';
import { TagWithParentLabelPaths, TaxonomyNode } from '../../../common/entities/Tag';
import { TagsService } from '../../services/tags/tags.service';

@Component({
  selector: 'c-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss'],
})
export class QueryBuilderComponent implements OnInit, OnChanges {
  @Input()
  fieldTypes: FieldType[];

  @Input()
  query: Query;

  @Input()
  disabled = true;

  @Input()
  useTags = true;

  @Output()
  queryChange: EventEmitter<Query> = new EventEmitter<Query>();

  fieldConditionMapping = fieldConditionMapping;

  tags: { [tagId: string]: TaxonomyNode<TagWithParentLabelPaths> } = {};
  toolTips: { [tagId: string]: string } = {};
  showTagsSidebar = false;

  currentSelectedIndexes: number[];
  initialized = false;

  constructor(private tagsService: TagsService) {}

  ngOnInit(): void {
    if (!this.useTags) {
      this.initialized = true;
    }
    this.initializeQuery();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.query) {
      this.initializeQuery();
    }
  }

  trackByIndex(index: number): number {
    return index;
  }

  asFieldConditions(orCondition: AndCondition): FieldCondition[] {
    return orCondition?.conditions as FieldCondition[];
  }

  asAndCondition(query: Query): AndCondition[] {
    return (query.condition as OrCondition).conditions as AndCondition[];
  }

  addOrCondition(query: Query): void {
    (query.condition as OrCondition).conditions.push({
      type: 'And',
      conditions: [this.createNewField()],
    } as AndCondition);
  }

  addAndCondition(query: Query, orConditionIndex: number): void {
    (((query.condition as OrCondition).conditions[orConditionIndex] as AndCondition).conditions as FieldCondition[]).push(this.createNewField());
  }

  createNewField(): FieldCondition {
    return {
      field: this.fieldTypes[0],
      type: this.fieldConditionMapping[this.fieldTypes[0]][0],
      value: '',
    };
  }

  removeAndCondition(query: Query, orConditionIndex: number, andConditionIndex: number): void {
    const orConditions = query.condition as OrCondition;
    if (orConditions.conditions.length > 1 || (orConditions.conditions[orConditionIndex] as AndCondition).conditions.length > 1) {
      ((orConditions.conditions[orConditionIndex] as AndCondition).conditions as FieldCondition[]).splice(andConditionIndex, 1);
      if (!(orConditions.conditions[orConditionIndex] as AndCondition).conditions.length) {
        orConditions.conditions.splice(orConditionIndex, 1);
      }
    } else {
      ((orConditions.conditions[orConditionIndex] as AndCondition).conditions as FieldCondition[])[andConditionIndex].value = '';
    }
    this.save();
  }

  async setQueryTag($event: string | string[]): Promise<void> {
    if (this.currentSelectedIndexes?.length === 2) {
      (((this.query.condition as OrCondition).conditions[this.currentSelectedIndexes[0]] as AndCondition).conditions[this.currentSelectedIndexes[1]] as FieldCondition).value = $event;
    }
    if (!this.tags[$event as string]) {
      this.tags[$event as string] = await this.tagsService.getTag($event as string);
    }
    this.save();
  }

  getAllTags(): string[] {
    const initialOrCondition = this.query.condition as OrCondition;
    const { conditions = [] } = initialOrCondition;
    const nonEmptyConditions = conditions.filter((a) => a);
    const flattenedConditions = nonEmptyConditions.flatMap((c) => {
      const andCondition = c as AndCondition;
      const { conditions: andConditions } = andCondition;
      const nonEmptyAndConditions = andConditions.filter((a) => a);
      return nonEmptyAndConditions.map((a) => (a as FieldCondition)?.value);
    });
    return flattenedConditions as string[];
  }

  getTaxonomyToolTip(item: TaxonomyNode<TagWithParentLabelPaths>): string {
    if (item) {
      if (!this.toolTips[item.data._id]) {
        this.toolTips[item.data._id] = `<b>${item.label}</b><br/>${
          item.data.parentLabelPaths
            ?.reverse()
            .slice(1)
            .map((pl) => `&gt;&nbsp;${pl.internalName}<br/>`) || ''
        }`;
      }
      return this.toolTips[item.data._id];
    }
    return '';
  }

  addTag(tags: TaxonomyNode<TagWithParentLabelPaths>[]): void {
    if (this.initialized && !this.tags[tags[0].data._id]) {
      tags.filter((t) => !this.tags[t.data._id]).forEach((t) => (this.tags[t.data._id] = t));
    } else if (!this.initialized) {
      this.tags = tags.filter((b) => b).reduce((a, b) => ({ ...a, [b.data._id]: b }), {} as {});
      this.initialized = true;
      this.disabled = false;
    }
  }

  save(): void {
    this.queryChange.emit(this.query);
  }

  openTagSelect(orConditionIndex: number, andConditionIndex: number): void {
    this.currentSelectedIndexes = [orConditionIndex, andConditionIndex];
    this.showTagsSidebar = true;
  }

  private initializeQuery(): void {
    if (!this.query) {
      this.query = {
        condition: {
          type: 'Or',
          conditions: [
            {
              type: 'And',
              conditions: [this.createNewField()],
            } as AndCondition,
          ],
        } as OrCondition,
      };
    }
  }
}
