<p-button styleClass="p-button-text" icon="pi icon-ic-language" [label]="(currentLanguage | language | async)?.languageName" (click)="menu.toggle($event)"></p-button>
<p-menu #menu [popup]="true" [appendTo]="'body'" [model]="languageOptions" (onShow)="fillLanguageOptions()"></p-menu>

<p-dialog *ngIf="manageLanguages" [(visible)]="showManageLanguages" [appendTo]="'body'" [modal]="true" [header]="'Select Languages'">
  <p-listbox
    [(ngModel)]="languagesEdit"
    [options]="allLanguages"
    optionValue="language"
    [checkbox]="true"
    [metaKeySelection]="false"
    [showToggleAll]="false"
    [multiple]="true"
    [disabled]="disabled || working"
  >
    <ng-template let-language pTemplate="item">
      {{ language.languageName }}
    </ng-template>
  </p-listbox>
  <p-button *ngIf="manageLanguages" [disabled]="languagesEdit.length < 1 || disabled || working" label="Set languages" class="set-language" (onClick)="emitLanguages()"></p-button>
</p-dialog>
