import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberAsTime',
})
export class NumberAsTimePipe implements PipeTransform {
  constructor() {}

  transform(seconds?: number): string {
    if (seconds === undefined || seconds === null) {
      return '';
    }

    const hours = Math.floor(seconds / (60 * 60));
    const minutes = Math.floor((seconds - hours * 60 * 60) / 60);
    const secondsLeft = seconds - hours * 60 * 60 - minutes * 60;

    // pad with leading zeros
    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');
    const secondsString = secondsLeft.toString().padStart(2, '0');

    return `${hoursString}:${minutesString}:${secondsString}`;
  }
}
