<div class="c-fieldset-container p-d-flex p-flex-column" [ngClass]="{ 'list-input': listInputIteration > 0 }" *ngIf="calculatedInputConfiguration && object && initialized && show()">
  <div *ngIf="!isListInput">
    <div
      class="input-group"
      [class.focus]="focussed"
      [class.locked]="isDisabled() && users?.length != 0"
      [class.disabled]="isDisabled() && users?.length == 0"
      [class.compact]="isCompact"
      [ngClass]="calculatedInputConfiguration.type"
      [pTooltip]="isDisabled() && users?.length != 0 ? 'Another person is currently editing this field' : ''"
      tooltipPosition="bottom"
    >
      <div class="input" [class.has-placeholder-description]="placeholderDescription">
        <c-patch-input
          [object]="object"
          [jsonpath]="jsonpath"
          [jsonpathParams]="completeJsonpathParams"
          [inputConfiguration]="calculatedInputConfiguration"
          [placeholder]="placeholder"
          [disabled]="isDisabled()"
          [showDeleteButton]="showDeleteButton"
          (focus)="focus()"
          (blur)="blur()"
          (valueChanged)="valueChanged($event)"
        ></c-patch-input>

        <div *ngIf="users && users.length > 0" class="users">
          <div
            *ngFor="let user of users; let index = index"
            class="user"
            [ngStyle]="{ right: index * 12 + 'px', 'background-color': color(user.adminUser | adminUser | async) }"
            [pTooltip]="(user.adminUser | adminUser | async)?.displayName"
            tooltipPosition="left"
          >
            <span>{{ userName(user.adminUser | adminUser | async) }}</span>
          </div>
        </div>
        <div *ngIf="!isCompact && showPlaceholderDescription" class="placeholder-description" [innerHTML]="placeholderDescription | translate"></div>
      </div>

      <div class="info error" *ngIf="error">
        <i class="pi pi-ban"></i>
        <span>{{ error }}</span>
      </div>

      <div *ngIf="showHeader" class="title">
        {{ header | translate }}

        <span *ngIf="showLanguage && languageMode() as lm">
          <span class="subtitle as-link" *ngIf="lm === 'default-editable'" (click)="confirmMessageDialogLocalization($event, 'Confirm Localization', 'localize')"> All - Click to localize </span>
          <span class="subtitle" *ngIf="lm === 'localized'"> {{ (completeJsonpathParams.language | language : languageMode() | async)?.languageName }} </span>
          <span class="subtitle as-link" *ngIf="lm === 'localized-editable'" (click)="translationMode = true">
            {{ (completeJsonpathParams.language | language : languageMode() | async)?.languageName }}
          </span>
        </span>

        <!-- <span class="subtitle language" *ngIf="showLanguage && hasLanguageParam" (click)="completeJsonpathParams.language === 'default' ? confirmMessageDialogLocalization($event, 'Confirm Localization', 'confirm')  : translationMode = true;">{{  (( completeJsonpathParams.language) | language: languageMode() | async)?.languageName }}</span> -->
        <!-- <span class="subtitle language" *ngIf="inputConfiguration?.type === 'multiselect'" (click)="sortMode = true">Sort</span> -->
      </div>
      <div *ngIf="attributeHeader()" class="headline">
        <span class="subheadline as-link" (click)="attributeMode = true">{{ attributeHeader() }}</span>
      </div>
      <div *ngIf="showDescription" class="description" [innerHTML]="description | translate"></div>
    </div>
  </div>

  <!-- List Input -->
  <div *ngIf="isListInput" class="input-list-container">
    <div class="title">
      {{ header | translate }}
      <!-- <span *ngIf="!listInputParent.isObject" class="subtitle"> [{{ (completeJsonpathParams.language | language : '' | async)?.language }}] </span> -->
      <span class="subtitle"> [{{ (completeJsonpathParams.language | language : '' | async)?.language }}] </span>
    </div>
    <div class="input-list-container-item" *ngFor="let element of listInputParent.element; trackBy: trackByIndexForElement; let index = index; let last = last; let first = first">
      <div *ngIf="listInputParent.isObject">
        <a class="child-link" (click)="setListChildElementSideBarOpen(index, true)">
          <div *ngIf="getCalculatedListChildImage(element, index) as img">
            <c-asset-select [id]="img" [previewOnly]="true" [showDeleteButton]="showDeleteButton"></c-asset-select>
          </div>
          <div *ngIf="isFunction(calculatedInputConfiguration.childImage) && !getCalculatedListChildImage(element, index)" style="padding: 0.5rem">
            <c-asset-preview></c-asset-preview>
          </div>
          <div class="child-info-container">
            <div class="child-label">
              <span>
                {{ getCalculatedListChildLabel(element, index, completeJsonpathParams.language) }}
              </span>
            </div>
            <div *ngIf="getCalculatedListChildDescription(element, index, completeJsonpathParams.language) as desc" class="child-description">
              <span>
                {{ desc }}
              </span>
            </div>
          </div>
        </a>
        <c-sidebar
          [visible]="getListChildElementSideBarOpen(index)"
          (visibleChange)="setListChildElementSideBarOpen(index, $event)"
          [header]="(header | translate) + ' - ' + getCalculatedListChildLabel(element, index)"
        >
          <div *ngFor="let listChildJsonpath of listInputParent.children; trackBy: trackByIndex">
            <c-collaboration-patch-input
              [object]="object"
              [jsonpath]="listChildJsonpath"
              [jsonpathParams]="jsonpathParamsWithIndexAndElement(completeJsonpathParams, index, element, listInputParent.indexName)"
              [inputConfiguration]="calculatedChildInputConfiguration"
              [collaborationKey]="collaborationKey"
              [disabled]="isDisabled()"
              [showHeader]="showHeader"
              [showDescription]="showDescription"
              [showDeleteButton]="showDeleteButton"
              [inputs]="inputs"
              [listInputIteration]="listInputIteration + 1"
            ></c-collaboration-patch-input>
          </div>
        </c-sidebar>
      </div>
      <div class="child-input-container" *ngIf="!listInputParent.isObject" [attr.data-inputtype]="inputs[listInputParent.children[0]].type">
        <div *ngFor="let listChildJsonpath of listInputParent.children; trackBy: trackByIndex">
          <c-collaboration-patch-input
            [object]="object"
            [jsonpath]="listChildJsonpath"
            [jsonpathParams]="jsonpathParamsWithIndexAndElement(completeJsonpathParams, index, element, listInputParent.indexName)"
            [inputConfiguration]="calculatedChildInputConfiguration"
            [collaborationKey]="collaborationKey"
            [disabled]="isDisabled()"
            [showHeader]="false"
            [showDescription]="showDescription"
            [showDeleteButton]="showDeleteButton"
            [inputs]="inputs"
            [listInputIteration]="listInputIteration + 1"
          ></c-collaboration-patch-input>
          <ng-container
            *ngIf="templateRef"
            [ngTemplateOutlet]="templateRef"
            [ngTemplateOutletContext]="{ $implicit: jsonpathParamsWithIndexAndElement(completeJsonpathParams, index, element, listInputParent.indexName) }"
          >
          </ng-container>
        </div>
      </div>
      <div class="list-buttons-container" [ngClass]="{ vertical: calculatedInputConfiguration.childImage }">
        <button pButton type="button" class="p-button-outlined" icon="pi pi-arrow-up" (click)="up(index, listInputParent.indexName)" [disabled]="isDisabled() || first"></button>
        <button pButton type="button" class="p-button-outlined" icon="pi pi-arrow-down" (click)="down(index, listInputParent.indexName)" [disabled]="isDisabled() || last"></button>
        <button *ngIf="showDeleteButton" pButton type="button" class="p-button-outlined" icon="pi pi-trash" (click)="remove(index, listInputParent.indexName)" [disabled]="isDisabled()"></button>
      </div>
    </div>

    <div class="input-list-container-footer p-d-column">
      <div *ngIf="!listInputParent.element || listInputParent.element.length === 0" class="input-list-container-no-items-info">
        {{ 'GENERAL_NO_ITEMS_INFO' | translate : { items: header | translate } }}
      </div>
      <div class="">
        <button
          *ngIf="showAddButton"
          pButton
          type="button"
          class="p-button full-field-width"
          [label]="('GENERAL_ADD' | translate) + ' ' + (header | translate)"
          icon="pi icon-ic-add-item"
          (click)="add()"
          [disabled]="isDisabled()"
        ></button>
      </div>
    </div>
  </div>
</div>

<c-sidebar [visible]="translationMode && languageMode() === 'localized-editable'" (visibleChange)="translationMode = $event" [header]="(header | translate) + ' - Translations'">
  <div *ngIf="translationMode && languageMode() === 'localized-editable'">
    <div class="actions" *ngIf="!calculatedInputConfiguration.languages">
      <button
        pButton
        type="button"
        class="p-button-sm p-button-danger p-mr-2 p-mb-4"
        label="Revert localization"
        icon="pi pi-trash"
        (click)="confirmMessageDialogLocalization($event, 'Confirm Revert Localization', 'globalize')"
      ></button>
    </div>
    <div style="margin-bottom: 10px" *ngIf="['date', 'time', 'datetime'].includes(calculatedInputConfiguration.type)">
      <c-tag-timezone></c-tag-timezone>
    </div>
    <div>
      <c-collaboration-patch-input
        *ngFor="let language of languages"
        [object]="object"
        [collaborationKey]="collaborationKey"
        [jsonpath]="jsonpath"
        [jsonpathParams]="jsonpathParamsForLanguage(language)"
        [inputConfiguration]="inputConfiguration"
        [showHeader]="showHeader"
        [showDescription]="false"
        [showLanguage]="false"
        [header]="(language | language | async)?.languageName"
        [description]="description"
        [inputs]="inputs"
      ></c-collaboration-patch-input>
    </div>
  </div>
</c-sidebar>

<c-sidebar [visible]="attributeMode" (visibleChange)="attributeMode = $event" [header]="(header | translate) + ' - Configuration'">
  <div *ngIf="attributeMode">
    <div>
      <c-collaboration-patch-input
        *ngFor="let item of attributInputConfiguration() | keyvalue : order"
        [object]="object"
        [collaborationKey]="collaborationKey"
        [jsonpath]="attributJsonpath(item.key)"
        [jsonpathParams]="completeJsonpathParams"
        [inputConfiguration]="item.value"
        [inputs]="inputs"
      ></c-collaboration-patch-input>
    </div>
  </div>
</c-sidebar>

<c-sidebar [visible]="inputConfiguration?.type === 'multiselect' && sortMode" (visibleChange)="sortMode = $event" [header]="'Sort ' + (header | translate)">
  <div *ngIf="inputConfiguration?.type === 'multiselect' && sortMode">
    <!-- TODO -->
  </div>
</c-sidebar>
<p-confirmPopup [key]="'collaborationConfirmPopup'"></p-confirmPopup>
