import { BlacklistEntry } from './BlacklistEntry';
import { CRMUser } from './CRMUser';

export const userTypes = ['LocalUser', 'AzureAdUser', 'ArtificialUser', 'OpenIdConnectUser'] as const;
export type UserType = (typeof userTypes)[number];

export interface BaseUser {
  userType: UserType;
  _id: string;
  salutation: string;
  lastName: string;
  firstName: string;
  lastNameLower?: string;
  firstNameLower?: string;
  tags: { [id: string]: string[] };

  // calculated
  displayName?: string;
  fullName: string;
}

export interface User extends BaseUser {
  phone: string;
  email: string;
  emailLower: string;
  language: string;

  department: string;
  organization: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
  state: string;

  salesManager?: string;

  registeredAt: string;
  lastLoginAt: string;
  lastAuthAt: string;
  lastActivity?: string;

  active: boolean;
  activatedAt: string;
  blocked: boolean;
  blockedAt: string;
  blacklistEntry?: BlacklistEntry;

  privacyPolicy?: {
    userAgreement: string;
    accepted: boolean;
    acceptedAt: string;
  };

  termsAndConditions?: {
    userAgreement: string;
    accepted: boolean;
    acceptedAt: string;
  };

  analytics?: {
    userAgreement: string;
    accepted: boolean;
    acceptedAt: string;
  };

  utm?: {
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
    utmTerm: string;
    utmContent: string;
  };

  crmUser?: CRMUser;
}

export interface ArtificialUser extends BaseUser {
  userType: 'ArtificialUser';
  expert: boolean;
  active: boolean;
}

export interface LocalUser extends User {
  userType: 'LocalUser';
  password: string;
}

export interface AzureAdUser extends User {
  userType: 'AzureAdUser';
  oid: string;
  aad: any;
}

export interface OpenIdConnectUser extends User {
  userType: 'OpenIdConnectUser';
  oidc: any;
}

export function isLocalUser(object: any): object is LocalUser {
  return object?.userType === 'LocalUser';
}

export function isUser(object: any): object is User {
  return isLocalUser(object) || isAzureAdUser(object) || isOpenIdConnectUser(object);
}

export function isAzureAdUser(object: any): object is AzureAdUser {
  return object?.userType === 'AzureAdUser';
}

export function isArtificialUser(object: any): object is ArtificialUser {
  return object?.userType === 'ArtificialUser';
}

export function isOpenIdConnectUser(object: any): object is OpenIdConnectUser {
  return object?.userType === 'OpenIdConnectUser';
}

export function asUser(baseUser: BaseUser): User | null {
  return isUser(baseUser) ? baseUser : null;
}

export function asArtificialUser(baseUser: BaseUser): ArtificialUser | null {
  return isArtificialUser(baseUser) ? baseUser : null;
}
