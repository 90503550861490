import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'c-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  @Input()
  onBlur?: Function;

  @Input()
  onFocus?: Function;

  @Input()
  value: string;

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  debounceTimer = null;

  constructor() {}

  ngOnInit(): void {}

  sendValueChange() {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => this.valueChange.emit(this.value), 600);
  }
}
