<!-- Live-Event Header -->
<div class="tab-header">
  <h1>
    <span class="pre-title">{{ 'GENERAL_LIVE_EVENT_CHAT' | translate | translate }}</span>
    <span *ngIf="loading">{{ 'GENERAL_LOADING' | translate }} ...</span>
    <span *ngIf="!loading">{{ event?.internalName }}</span>
    <span *ngIf="eventVersionChat">:&nbsp;{{ eventVersionChat.internalName }}</span>
  </h1>
</div>
<div class="tab-menu">
  <p-button styleClass="p-button-text" icon="pi pi-arrow-left" label="Back" (click)="back()"></p-button>
  <c-language-button [languages]="languages" [(currentLanguage)]="language"></c-language-button>

  <div class="right">
    <span class="info-text">Send Messages as</span>
    <p-dropdown [(ngModel)]="selectedUserFilter" [options]="userFilters" optionValue="value" optionLabel="title" (onChange)="changedSelectedUserFilter($event)" appendTo="body"></p-dropdown>
    <p-autoComplete
      [(ngModel)]="currentSendingUser"
      [suggestions]="filteredSendingUsers"
      [forceSelection]="true"
      [dropdown]="true"
      (completeMethod)="filterSendingUser($event)"
      (onSelect)="changedSendingUser($event)"
      field="displayName"
      scrollHeight="50vh"
      [placeholder]="'GENERAL_USER_PLACEHOLDER' | translate"
      appendTo="body"
    >
    </p-autoComplete>
    <p-button icon="pi icon-ic-manage-access" [label]="'PAGE_LIVE_CHAT_MANAGE_USER' | translate" (onClick)="this.showUserManagementDialog()"></p-button>
    <p-button icon="pi pi-book" [label]="'PAGE_LIVE_CHAT_EDIT_MESSAGE_TEMPLATE' | translate" (onClick)="showChatMessageTemplateDialog()"></p-button>
  </div>
  <p-confirmPopup></p-confirmPopup>
</div>
<div class="tab-subtabs">
  <div class="tabs">
    <a [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'chat' }" [class.active]="currentSubTab === 'chat'">Chat Moderation</a>
    <a [routerLink]="[]" [queryParamsHandling]="'merge'" [queryParams]="{ tab: 'polls' }" [class.active]="currentSubTab === 'polls'">Polls</a>
  </div>
</div>

<!-- Chat Management -->
<div class="chat-management" *ngIf="currentSubTab === 'chat'">
  <div class="p-grid">
    <div class="p-col">
      <c-chat [chatId]="chatId" (onOpenArtificialUser)="this.showUserManagementDialog()" (onReply)="createReply($event)"></c-chat>
    </div>
    <div class="p-col">
      <c-chat-open-messages
        [chatId]="chatId"
        (onConfirm)="confirmMessage($event)"
        (onReply)="createReply($event)"
        (onQuote)="createQuote($event)"
        (onReject)="createReject($event)"
        (onReply)="createReply($event)"
        (onOpenArtificialUser)="this.showUserManagementDialog()"
      ></c-chat-open-messages>
    </div>
  </div>

  <div class="message-section">
    <div class="card reply-card" *ngIf="quotedMessage || rejectedMessage || replyMessage">
      <div class="card-body">
        <div *ngIf="quotedMessage" class="p-col p-d-flex p-p-2 p-ai-center">
          <div class="message">
            <span class="user">{{ quotedMessage.user.displayName || 'Unknown User' }}</span>
            <span class="date">{{ quotedMessage.date | date : 'short' }}</span>
            <div class="text">{{ quotedMessage.data.text }}</div>
          </div>
          <div class="p-ml-auto"><p-button [label]="'X'" (onClick)="quotedMessage = null; chatMessage = null"></p-button></div>
        </div>
        <div *ngIf="rejectedMessage" class="p-col p-d-flex p-p-2 p-ai-center">
          <div class="message rejection">
            <span *ngIf="rejectedMessage.messageType === 'TextMessage'" class="user">{{ rejectedMessage.user?.displayName || 'Unknown User' }}</span>
            <span *ngIf="rejectedMessage.messageType === 'PollMessage'" class="user">
              <i class="pi pi-chart-bar p-mr-1"></i>
              {{ 'PAGE_LIVE_CHAT_POLLS_REQUEST' | translate }}
            </span>
            <span class="date">{{ rejectedMessage.date | date : 'short' }}</span>
            <div *ngIf="rejectedMessage.messageType === 'TextMessage'" class="text">{{ rejectedMessage.data.text }}</div>
          </div>
          <p-button class="p-ml-auto" [label]="'X'" (onClick)="rejectedMessage = null; chatMessage = null"></p-button>
        </div>
        <div *ngIf="replyMessage" class="p-col p-d-flex p-p-2 p-ai-center">
          <div class="message">
            <span *ngIf="replyMessage.messageType === 'TextMessage'" class="user">{{ replyMessage.user?.displayName || 'Unknown User' }}</span>
            <span *ngIf="replyMessage.messageType === 'PollMessage'" class="user">
              <i class="pi pi-chart-bar p-mr-1"></i>
              {{ 'PAGE_LIVE_CHAT_POLLS_REQUEST' | translate }}
            </span>
            <span class="date">{{ replyMessage.date | date : 'short' }}</span>
            <div *ngIf="replyMessage.messageType === 'TextMessage'" class="text">{{ replyMessage.data.text }}</div>
          </div>
          <div class="p-ml-auto"><p-button [label]="'X'" (onClick)="replyMessage = null; chatMessage = null"></p-button></div>
        </div>
      </div>
    </div>
    <div class="p-col message">
      <textarea
        [(ngModel)]="chatMessage"
        [rows]="countRows(chatMessage, 3)"
        pInputTextarea
        id="message"
        style="width: 100%"
        [placeholder]="rejectedMessage ? 'Send a Rejection' : replyMessage ? 'Send a Reply' : 'Send a Message'"
      ></textarea>
    </div>

    <div class="p-col p-d-flex p-p-2 p-ai-center message-actions">
      <div class="input-group p-mr-2" style="width: 30%">
        <div class="input">
          <p-dropdown
            [options]="chatMessageTemplates"
            optionLabel="title"
            (onChange)="selectChatMessageTemplate($event)"
            [placeholder]="'PAGE_LIVE_CHAT_SELECT_MESSAGE_TEMPLATE' | translate"
            class="p-mr-2"
          ></p-dropdown>
        </div>
      </div>

      <p-button [label]="'PAGE_LIVE_CHAT_ADD_MESSAGE' | translate" (onClick)="addChatMessageTemplateToChatMessage()" class="p-mr-2" [disabled]="!selectedChatMessageTemplate"></p-button>

      <c-emoji-picker (text)="chatMessage = $event" textareaId="message" class="p-mr-2"></c-emoji-picker>

      <div class="send-info" *ngIf="!currentSendingUser">
        <i class="pi pi-info-circle"></i>
        <span>Please select User</span>
      </div>
      <p-button
        [ngClass]="{ 'p-ml-auto': currentSendingUser }"
        [label]="rejectedMessage ? 'Reject Message' : replyMessage ? 'Send Reply' : ('PAGE_LIVE_CHAT_SEND_MESSAGE' | translate)"
        (onClick)="sendMessage()"
        [disabled]="!((currentSendingUser || rejectedMessage) && chatMessage)"
      ></p-button>
    </div>
  </div>
</div>

<!-- Dialogs -->
<p-dialog [header]="'PAGE_LIVE_CHAT_MANAGE_USER' | translate" [(visible)]="displayUserManagement" [style]="{ width: '95vw' }">
  <c-chat-user-management (onUserCreated)="loadUsers()" (onUserUpdated)="loadUsers()"></c-chat-user-management>
</p-dialog>
<p-dialog [header]="'PAGE_LIVE_CHAT_EDIT_MESSAGE_TEMPLATE' | translate" [(visible)]="displayChatMessageTemplateManagement" [style]="{ width: '95vw' }">
  <c-chat-messagetemplate-management
    [messageToAdd]="chatMessageToAdd"
    (onMessageTemplateCreated)="loadChatMessageTemplates()"
    (onMessageTemplateUpdated)="loadChatMessageTemplates()"
  ></c-chat-messagetemplate-management>
</p-dialog>

<!-- Polls Management -->
<div class="panel" *ngIf="currentSubTab === 'polls'">
  <div class="panel-header">
    {{ 'PAGE_LIVE_CHAT_POLLS' | translate }}
    <div class="actions">
      <button pButton type="button" class="p-button" [label]="'GENERAL_ADD' | translate" icon="pi icon-ic-add-item" (click)="addPoll()"></button>
      <c-table-options #to></c-table-options>
    </div>
  </div>

  <c-table #table [data]="polls" [options]="chatPollsTableOptions" [tableOptionsComponent]="to">
    <ng-template let-poll>
      <a [routerLink]="[]" queryParamsHandling="merge" (click)="openPollDialog(poll)">{{ poll.internalName }}</a>
    </ng-template>
    <ng-template let-poll>{{ poll.local[language]?.title }}</ng-template>
    <ng-template let-poll>{{ poll.local[language]?.description }}</ng-template>
    <ng-template let-poll>{{ poll.createdAt | date : 'short' }}</ng-template>
    <ng-template let-poll>{{ poll.closedAt | date : 'short' }}</ng-template>
    <ng-template let-poll>{{ poll.publishedResultAt | date : 'short' }}</ng-template>
    <ng-template let-poll>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="confirmSendPoll($event, poll)">Send Poll</a>
    </ng-template>
    <ng-template let-poll>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="addPoll(poll)">Clone Poll</a>
    </ng-template>
    <ng-template let-poll>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="showPollResult(poll)">Show Result</a>
    </ng-template>
  </c-table>
</div>

<p-confirmPopup></p-confirmPopup>

<c-sidebar *ngIf="editablePoll" [(visible)]="showPollDialog" position="right" [header]="'PAGE_EVENT_SECTION_POLLS_EDIT_POLL' | translate" (onHide)="closePollDialog()">
  <div class="sidebar-tabs">
    <button [class.active]="pollsSidebarTab === 'general'" (click)="pollsSidebarTab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
    <button [class.active]="pollsSidebarTab === 'options'" (click)="pollsSidebarTab = 'options'">{{ 'PAGE_EVENT_SECTION_POLLS_OPTIONS' | translate }}</button>
    <button [class.active]="pollsSidebarTab === 'theme'" (click)="pollsSidebarTab = 'theme'">{{ 'PAGE_EVENT_SECTION_POLLS_THEMES' | translate }}</button>
  </div>
  <div *ngIf="pollsSidebarTab === 'general'" class="sidebar-body">
    <c-collaboration-patch-input
      jsonpath="$.internalName"
      [jsonpathParams]="{ language: language }"
      [object]="editablePoll"
      [collaborationKey]="'poll:' + editablePoll._id"
      [inputs]="pollsInputs"
    ></c-collaboration-patch-input>

    <c-collaboration-patch-input
      jsonpath="$.local.$language.title"
      [jsonpathParams]="{ language: language }"
      [inputConfiguration]="inputConfig"
      [object]="editablePoll"
      [collaborationKey]="'poll:' + editablePoll._id"
      [inputs]="pollsInputs"
    ></c-collaboration-patch-input>
    <c-collaboration-patch-input
      jsonpath="$.local.$language.description"
      [jsonpathParams]="{ language: language }"
      [inputConfiguration]="inputConfig"
      [object]="editablePoll"
      [collaborationKey]="'poll:' + editablePoll._id"
      [inputs]="pollsInputs"
    ></c-collaboration-patch-input>

    <c-collaboration-patch-input
      jsonpath="$.minSelections"
      [jsonpathParams]="{ language: language }"
      [object]="editablePoll"
      [collaborationKey]="'poll:' + editablePoll._id"
      [inputs]="pollsInputs"
      [inputConfiguration]="inputConfig"
    ></c-collaboration-patch-input>
    <c-collaboration-patch-input
      jsonpath="$.maxSelections"
      [jsonpathParams]="{ language: language }"
      [object]="editablePoll"
      [collaborationKey]="'poll:' + editablePoll._id"
      [inputs]="pollsInputs"
      [inputConfiguration]="inputConfig"
    ></c-collaboration-patch-input>
  </div>
  <div *ngIf="pollsSidebarTab === 'options'" class="sidebar-body">
    <c-collaboration-patch-input
      jsonpath="$.options"
      [jsonpathParams]="{ language: language }"
      [object]="editablePoll"
      [collaborationKey]="'poll:' + editablePoll._id"
      [inputs]="pollsInputs"
      [inputConfiguration]="inputConfig"
    ></c-collaboration-patch-input>
  </div>

  <div *ngIf="pollsSidebarTab === 'theme'" class="sidebar-body">
    <c-collaboration-patch-input jsonpath="$.theme.background" [object]="editablePoll" [inputs]="pollsInputs"></c-collaboration-patch-input>
    <c-collaboration-patch-input jsonpath="$.theme.font" [object]="editablePoll" [inputs]="pollsInputs"></c-collaboration-patch-input>
    <c-collaboration-patch-input jsonpath="$.theme.fontcolor" [object]="editablePoll" [inputs]="pollsInputs"></c-collaboration-patch-input>
    <c-collaboration-patch-input jsonpath="$.theme.leadingBarBackgroundColor" [object]="editablePoll" [inputs]="pollsInputs"></c-collaboration-patch-input>
    <c-collaboration-patch-input jsonpath="$.theme.leadingBarFontColor" [object]="editablePoll" [inputs]="pollsInputs"></c-collaboration-patch-input>
    <c-collaboration-patch-input jsonpath="$.theme.barBackgroundColor" [object]="editablePoll" [inputs]="pollsInputs"></c-collaboration-patch-input>
    <c-collaboration-patch-input jsonpath="$.theme.barFontColor" [object]="editablePoll" [inputs]="pollsInputs"></c-collaboration-patch-input>
    <c-collaboration-patch-input jsonpath="$.theme.showVotesInBar" [object]="editablePoll" [inputs]="pollsInputs"></c-collaboration-patch-input>
    <c-collaboration-patch-input jsonpath="$.theme.showVotesTotal" [object]="editablePoll" [inputs]="pollsInputs"></c-collaboration-patch-input>
  </div>
  <c-sidebar-footer [autoSaveInfo]="false" [showSaveButton]="true" (onSave)="closePollDialog()"></c-sidebar-footer>
</c-sidebar>
