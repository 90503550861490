<div class="sidebar-search">
  <div class="search-input input-group compact">
    <div class="input">
      <input #searchInput placeholder="Search onboardings ..." pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
    </div>
  </div>
  <div class="search-results">
    <div class="search-result" *ngFor="let onboarding of searchResult" (click)="select(onboarding)">
      <c-onboarding-item [onboarding]="onboarding"></c-onboarding-item>
    </div>
  </div>
</div>
