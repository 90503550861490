import { ListPageModuleAttributes } from './../../entities/pagemodules/ListPageModule';
import { HeadlineType } from './../../entities/Attributes';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';
import { ListPageModule, ListPageModuleLocal } from '../../entities/PageModule';

export class ListPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public listPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): ListPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async listPageModuleLocal(values?: any): Promise<ListPageModuleLocal> {
    return {
      title: values?.title || '',
      title_attr: this.listPageModuleAttributes(values?.title_attr),
      items: values?.items || [],
      itemIcon: values?.itemIcon || null,
    };
  }

  public async listPageModule(values: any): Promise<ListPageModule> {
    if (values?.itemIcon) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.itemIcon = item.itemIcon || values.itemIcon;
      });
    }

    const pageModule = {
      ...(await this.baseValues(values)),
      pageModuleType: 'List',
      itemIcon: undefined,
      local: await this.local(values, (val) => this.listPageModuleLocal(val)),
      ctaButton: values?.ctaButton || null,
      contentPosition: values?.contentPosition || 'centered',
    };

    return { ...pageModule, pageModuleType: 'List' };
  }
}
