import { NgxFileDropModule } from 'ngx-file-drop';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { SidebarModule } from 'primeng/sidebar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';

import { DividerModule } from 'primeng/divider';

import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { InteractionComponent } from './interaction/interaction.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LottieModule } from 'ngx-lottie';

const routes: Routes = [{ path: '', component: InteractionComponent }];

export function playerFactory() {
  //return player;
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [InteractionComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    LottieModule.forRoot({ player: playerFactory }),
    ComponentsModule,
    FormsModule,
    TableModule,
    TranslateModule,
    ButtonModule,
    DividerModule,
    SplitButtonModule,
    PipesModule,
    SidebarModule,
    DirectivesModule,
    TooltipModule,
    InputSwitchModule,
    FileUploadModule,
    NgxFileDropModule,
  ],
  exports: [RouterModule],
})
export class InteractionModule {}
