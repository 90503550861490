import { Stage2TitleEventStagePageModuleEditComponent } from './stage2-titleeventstage-page-module-edit/stage2-titleeventstage-page-module-edit.component';
import { Stage2MultiEventStagePageModuleEditComponent } from './stage2-multieventstage-page-module-edit/stage2-multieventstage-page-module-edit.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { AccordionModule } from 'primeng/accordion';
import { ColorPickerModule } from 'primeng/colorpicker';
import { CardModule } from 'primeng/card';

import { ComponentsModule } from 'src/app/components/components.module';

import { AccordionPageModuleEditComponent } from './accordion-page-module-edit/accordion-page-module-edit.component';
import { AgendaPageModuleEditComponent } from './agenda-page-module-edit/agenda-page-module-edit.component';
import { BannerPageModuleEditComponent } from './banner-page-module-edit/banner-page-module-edit.component';
import { EventkeytopicsicongroupPageModuleEditComponent } from './eventkeytopicsicongroup-page-module-edit/eventkeytopicsicongroup-page-module-edit.component';
import { EventsListPageModuleEditComponent } from './eventslist-page-module-edit/eventslist-page-module-edit.component';
import { FilterPageModuleEditComponent } from './filter-page-module-edit/filter-page-module-edit.component';
import { IcongroupPageModuleEditComponent } from './icongroup-page-module-edit/icongroup-page-module-edit.component';
import { ImagetagtextlinkPageModuleEditComponent } from './imagetagtextlink-page-module-edit/imagetagtextlink-page-module-edit.component';
import { ImagePageModuleEditComponent } from './image-page-module-edit/image-page-module-edit.component';
import { ListPageModuleEditComponent } from './list-page-module-edit/list-page-module-edit.component';
import { ListImagePageModuleEditComponent } from './listimage-page-module-edit/listimage-page-module-edit.component';
import { MultiEventStagePageModuleEditComponent } from './multieventstage-page-module-edit/multieventstage-page-module-edit.component';
import { PageModuleEditComponent } from './page-module-edit.component';
import { ShowroomPageModuleEditComponent } from './showroom-page-module-edit/showroom-page-module-edit.component';
import { StreamEventStagePageModuleEditComponent } from './streameventstage-page-module-edit/streameventstage-page-module-edit.component';
import { TeaserEventStagePageModuleEditComponent } from './teasereventstage-page-module-edit/teasereventstage-page-module-edit.component';
import { Text3dimagePageModuleEditComponent } from './text3dimage-page-module-edit/text3dimage-page-module-edit.component';
import { TextEventStagePageModuleEditComponent } from './texteventstage-page-module-edit/texteventstage-page-module-edit.component';
import { TextlistPageModuleEditComponent } from './textlist-page-module-edit/textlist-page-module-edit.component';
import { TextPageModuleEditComponent } from './text-page-module-edit/text-page-module-edit.component';
import { TextImagePageModuleEditComponent } from './textimage-page-module-edit/textimage-page-module-edit.component';
import { TitleEventStagePageModuleEditComponent } from './titleeventstage-page-module-edit/titleeventstage-page-module-edit.component';
import { UserProfileStagePageModuleEditComponent } from './user-profile-stage-page-module-edit/user-profile-stage-page-module-edit.component';
import { VideosliderPageModuleEditComponent } from './videoslider-page-module-edit/videoslider-page-module-edit.component';
import { VideoTeaserCollectionPageModuleEditComponent } from './videoteasercollection-page-module-edit/videoteasercollection-page-module-edit.component';
import { GridPageModuleEditComponent } from './grid-page-module-edit/grid-page-module-edit.component';
import { ListvideoPageModuleEditComponent } from './listvideo-page-module-edit/listvideo-page-module-edit.component';

// Stage 2.0
import { Stage2TextEventStagePageModuleEditComponent } from './stage2-texteventstage-page-module-edit/stage2-texteventstage-page-module-edit.component';
import { Stage2TeaserEventStagePageModuleEditComponent } from './stage2-teasereventstage-page-module-edit/stage2-teasereventstage-page-module-edit.component';
import { Stage2StreamEventStagePageModuleEditComponent } from './stage2-streameventstage-page-module-edit/stage2-streameventstage-page-module-edit.component';
import { ImageBannerPageModuleEditComponent } from './image-banner-page-module-edit/image-banner-page-module-edit.component';
import { FormPageModuleEditComponent } from './form-page-module-edit/form-page-module-edit.component';
import { SpeakerListPageModuleEditComponent } from './speaker-list-page-module-edit/speaker-list-page-module-edit.component';
import { DocumentcollectionPageModuleEditComponent } from './documentcollection-page-module-edit/documentcollection-page-module-edit.component';
import { TilesPageModuleEditComponent } from './tiles-page-module-edit/tiles-page-module-edit.component';
import { ProductListPageModuleEditComponent } from './product-list-page-module-edit/product-list-page-module-edit.component';
import { ConversionStagePageModuleEditComponent } from './conversion-stage-page-module-edit/conversion-stage-page-module-edit.component';
import { SlideshowPageModuleEditComponent } from './slideshow-page-module-edit/slideshow-page-module-edit.component';
import { TrendingtopicsPageModuleEditComponent } from './trendingtopics-page-module-edit/trendingtopics-page-module-edit.component';
import { SpacerPageModuleEditComponent } from './spacer-page-module-edit/spacer-page-module-edit.component';
import { ProductList2PageModuleEditComponent } from './product-list2-page-module-edit/product-list2-page-module-edit.component';
import { LoginPageModuleEditComponent } from './login-page-module-edit/login-page-module-edit.component';
import { ExploreStagePageModuleEditComponent } from './explore-stage-page-module-edit/explore-stage-page-module-edit.component';
import { BookmarksPageModuleEditComponent } from './bookmarks-page-module-edit/bookmarks-page-module-edit.component';
import { CustomPageModuleEditComponent } from './custom-page-module-edit/custom-page-module-edit.component';
import { NewsfeedEntryPageModuleEditComponent } from './newsfeedentry-page-module-edit/newsfeedentry-page-module-edit.component';

@NgModule({
  declarations: [
    PageModuleEditComponent,
    AgendaPageModuleEditComponent,
    EventkeytopicsicongroupPageModuleEditComponent,
    IcongroupPageModuleEditComponent,
    ImagetagtextlinkPageModuleEditComponent,
    TextPageModuleEditComponent,
    Text3dimagePageModuleEditComponent,
    TextlistPageModuleEditComponent,
    VideosliderPageModuleEditComponent,
    VideoTeaserCollectionPageModuleEditComponent,
    ShowroomPageModuleEditComponent,
    BannerPageModuleEditComponent,
    MultiEventStagePageModuleEditComponent,
    StreamEventStagePageModuleEditComponent,
    TeaserEventStagePageModuleEditComponent,
    TextEventStagePageModuleEditComponent,
    TitleEventStagePageModuleEditComponent,
    AccordionPageModuleEditComponent,
    EventsListPageModuleEditComponent,
    FilterPageModuleEditComponent,
    ListPageModuleEditComponent,
    ListImagePageModuleEditComponent,
    ImagePageModuleEditComponent,
    TextImagePageModuleEditComponent,
    UserProfileStagePageModuleEditComponent,
    GridPageModuleEditComponent,
    ListvideoPageModuleEditComponent,
    ConversionStagePageModuleEditComponent,
    // Stage 2.0
    Stage2TextEventStagePageModuleEditComponent,
    Stage2StreamEventStagePageModuleEditComponent,
    Stage2TeaserEventStagePageModuleEditComponent,
    Stage2MultiEventStagePageModuleEditComponent,
    Stage2TitleEventStagePageModuleEditComponent,
    ImageBannerPageModuleEditComponent,
    FormPageModuleEditComponent,
    SpeakerListPageModuleEditComponent,
    DocumentcollectionPageModuleEditComponent,
    TilesPageModuleEditComponent,
    ProductListPageModuleEditComponent,
    ProductList2PageModuleEditComponent,
    SlideshowPageModuleEditComponent,
    TrendingtopicsPageModuleEditComponent,
    SpacerPageModuleEditComponent,
    LoginPageModuleEditComponent,
    ExploreStagePageModuleEditComponent,
    BookmarksPageModuleEditComponent,
    CustomPageModuleEditComponent,
    NewsfeedEntryPageModuleEditComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    TranslateModule,
    FormsModule,
    ButtonModule,
    TabViewModule,
    AccordionModule,
    DividerModule,
    InputTextModule,
    DropdownModule,
    InputNumberModule,
    ColorPickerModule,
    CardModule,
    InputSwitchModule,
  ],
  exports: [PageModuleEditComponent],
})
export class PageModuleEditModule {}
