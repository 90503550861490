import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';
import { ExploreStagePageModuleFactory } from '../../factories/pagemodules/ExploreStagePageModuleFactory';

export const exploreStagePageModuleInputs: Inputs = {
  ...commonPageModuleInputs('ExploreStage'),

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].backgroundImage": {
    factory: async () => new ExploreStagePageModuleFactory().exploreStagePageModuleBreakpoint({}),
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].sliderBackgroundImage": {
    factory: async () => new ExploreStagePageModuleFactory().exploreStagePageModuleBreakpoint({}),
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].video": {
    factory: async () => new ExploreStagePageModuleFactory().exploreStagePageModuleBreakpoint({}),
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new ExploreStagePageModuleFactory().createExploreStagePageModuleLocal({}),
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].local.$language.title": {},

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].local.$language.buttonlabel": {
    type: 'text',
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].buttonPositionTop": {
    type: 'number',
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].buttonPositionLeft": {
    type: 'number',
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].backgroundImage.desktop": {
    header: 'Background Image Desktop',
    type: 'imageasset',
  },
  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].backgroundImage.mobile": {
    header: 'Background Image Mobile',
    type: 'imageasset',
  },
  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].backgroundImage.tablet": {
    header: 'Background Image Tablet',
    type: 'imageasset',
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].video.desktop": {
    header: 'Video Desktop',
    type: 'videoasset',
  },
  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].video.mobile": {
    header: 'Video Mobile',
    type: 'videoasset',
  },
  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].video.tablet": {
    header: 'Video Tablet',
    type: 'videoasset',
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].sliderBackgroundImage.desktop": {
    header: 'Slider Background Image Desktop',
    type: 'imageasset',
  },
  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].sliderBackgroundImage.mobile": {
    header: 'Slider Background Image Mobile',
    type: 'imageasset',
  },
  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].sliderBackgroundImage.tablet": {
    header: 'Slider Background Image Tablet',
    type: 'imageasset',
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].products": {
    factory: async () => [],
    list: true,
    childFactory: async () => null,
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].products[$index]": {
    type: 'product',
    header: 'Product',
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].stageHeight": {
    factory: async () => new ExploreStagePageModuleFactory().exploreStagePageModuleStageHeight({}),
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].stageHeight.desktop": {
    type: 'number',
    header: 'Stage height desktop',
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].stageHeight.desktopLarge": {
    type: 'number',
    header: 'Stage height desktop large',
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].stageHeight.desktopWidescreen": {
    type: 'number',
    header: 'Stage height desktop widescreen',
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].stageHeight.tablet": {
    type: 'number',
    header: 'Stage height tablet',
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].stageHeight.tabletLarge": {
    type: 'number',
    header: 'Stage height tablet large',
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].stageHeight.mobile": {
    header: 'Stage height mobile',
    type: 'number',
  },

  "$.pageModules[?(@.pageModuleType=='ExploreStage' && @._id=='$pageModuleId')].stageHeight.mobileLarge": {
    header: 'Stage height mobile large',
    type: 'number',
  },
};
