import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { TimezoneSwitchService } from '../../services/utils/timezone-switch.service';

@Pipe({
  name: 'timeZoneByUrl',
})
export class TimeZoneByUrlPipe implements PipeTransform {
  constructor(private activatedRoute: ActivatedRoute, private timezoneSwitchService: TimezoneSwitchService) {}

  transform(date: Date | string): Observable<Date | string> {
    const that = this;
    return date
      ? new Observable((subscriber) => {
          this.activatedRoute.queryParams.subscribe({
            next(params): void {
              if (params !== undefined && params !== null && params.eTz) {
                const next = that.timezoneSwitchService.encodeByUrlTimeZone(date, params.eTz);
                subscriber.next(next);
              } else {
                subscriber.next(date);
              }
            },
            error(error): void {
              subscriber.error(error);
            },
            complete(): void {
              subscriber.complete();
            },
          });
        })
      : EMPTY;
  }
}
