import { Pipe, PipeTransform } from '@angular/core';
import { UsersService } from 'src/app/services/users/users.service';
import { User } from 'src/common/entities/User';

@Pipe({
  name: 'user',
})
export class UserPipe implements PipeTransform {
  constructor(private userService: UsersService) {}

  // transform(user: string, ...args: unknown[]): Promise<BaseUser> {
  //   return this.userService.getUser(user);
  // }
  transform(user: string, ...args: unknown[]): Promise<User> {
    return this.userService.getUser(user) as Promise<User>;
  }
}
