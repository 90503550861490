import { Injectable } from '@angular/core';
import { ConfigurationService } from '../configuration/configuration.service';
import { Environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DomainService {
  constructor(private configurationService: ConfigurationService, private environment: Environment) {}

  findFirstFrontendDomain(domainCollectionId?: string): string {
    // return this.environment.frontend
    let domain;
    const domainCollections = this.configurationService.configuration().domain?.domainCollections;

    if (domainCollections) {
      if (domainCollectionId) {
        domain = domainCollections?.find((dc) => dc._id === domainCollectionId)?.domains[0];
      } else {
        domain = domainCollections.find((dc) => dc.reachableByEvents)?.domains[0];
      }
    }

    if (domain) {
      if (!(domain.startsWith('https://') || domain.startsWith('http://'))) {
        const protocol = this.environment.frontend.substring(0, this.environment.frontend.indexOf('//') + 2);
        domain = protocol + domain;
      }
    } else {
      domain = this.environment.frontend;
    }

    return domain;
  }

  getFrontendDomains(): Map<string, string[]> {
    let domainMap: Map<string, string[]> = new Map<string, string[]>();
    const domainCollections = this.configurationService.configuration().domain?.domainCollections;

    if (domainCollections) {
      for (let domain of domainCollections) {
        for (let item of domain.domains) {
          if (item) {
            if (!(item.startsWith('https://') || item.startsWith('http://'))) {
              const protocol = this.environment.frontend.substring(0, this.environment.frontend.indexOf('//') + 2);
              item = protocol + item;
            }
          } else {
            item = this.environment.frontend;
          }
          domainMap.has(domain._id) ? domainMap.set(domain._id, domainMap.get(domain._id).concat(item)) : domainMap.set(domain._id, [item]);
        }
      }
    }

    return domainMap;
  }
}
