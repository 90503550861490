import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ValidatorService } from 'src/app/services/validator/validator.service';

import { SelectMenuItem } from 'src/app/components/select-menu/select-menu.component';

import { I18n } from 'src/common/entities/I18n';
import { I18nService } from 'src/app/services/i18n/i18n.service';
import { i18nValidator } from 'src/common/api/v1/configuration/i18n/validators/I18nValidator';

import { LanguagesService } from 'src/app/services/languages/languages.service';

@Component({
  selector: 'app-i18n',
  templateUrl: './i18n.component.html',
  styleUrls: ['./i18n.component.scss'],
})
export class I18nComponent implements OnInit, OnDestroy {
  language: string = ''; // Editable language
  currentModule: string = '';

  languages: { code: string; name: string }[] = [];
  compareLanguage: { code: string; name: string }; // Language displayed to compare

  i18nModulesEn: { [module: string]: I18n }; // Holds base i18n setting with all modules and datas
  i18nModulesCompare: { [module: string]: I18n }; // Language displayed to compare i18n with

  i18nModules: { [module: string]: I18n }; // Editable language i18n modules

  loading: boolean = true;
  saving: boolean = false;
  refreshing: boolean = false;

  currentModuleSortedKeys: string[] = [];
  moduleSelectMenuItems: SelectMenuItem[] = [];
  moduleSelectedMenuItems: string[] = [];

  private _ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(private activatedRoute: ActivatedRoute, private validatorService: ValidatorService, private i18nService: I18nService, private languagesService: LanguagesService) {}

  async ngOnInit() {
    this.activatedRoute.params.pipe(takeUntil(this._ngUnsubscribe)).subscribe(async (params) => {
      this.language = params.language;
      if (this.language) {
        this._retrieveLanguages();
        await this._retrieveData();
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe subscriptions
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  private async _retrieveLanguages() {
    try {
      const result = await this.languagesService.getLanguages();
      this.languages = result.items.map((item) => {
        return {
          code: item.language,
          name: item.languageName,
        };
      });
      this.compareLanguage = this.languages.find((l) => (l.code = 'en'));
    } catch (err) {}
  }

  private async _retrieveData() {
    this.loading = true;
    try {
      // Retrieve base language => "en"
      this.i18nModulesEn = await this.i18nService.getI18n('en');

      // Initially compare with base language
      this.i18nModulesCompare = this.i18nModulesEn;

      try {
        // Retrieve language to edit
        this.i18nModules = await this.i18nService.getI18n(this.language);
        // Add missing modules to editable language
        this._addMissingModulesAndData(this.i18nModules);
        this.moduleChanged();

        this.moduleSelectMenuItems = Object.keys(this.i18nModulesEn).map((module) => {
          return {
            id: module,
            title: module,
          };
        });

        this.moduleSelectedMenuItems = Object.keys(this.i18nModulesEn).map((module) => {
          return module;
        });
      } catch (err) {}
    } catch (err) {}
    this.loading = false;
  }

  async retrieveCompareLanguage(event: any) {
    if (event.value) {
      this.refreshing = true;
      this.compareLanguage = event.value;

      try {
        // Retrieve language to compare with
        this.i18nModulesCompare = await this.i18nService.getI18n(this.compareLanguage.code);
        this._addMissingModulesAndData(this.i18nModulesCompare);
      } catch (err) {}

      this.refreshing = false;
    }
  }

  private _addMissingModulesAndData(i18nModules: { [module: string]: I18n }) {
    for (const module in this.i18nModulesEn) {
      // Set first module to display
      if (!this.currentModule) {
        this.currentModule = module;
      }

      // Add missing modules to editable language
      if (!i18nModules[module]) {
        i18nModules[module] = {
          _id: '',
          language: this.language,
          ns: this.i18nModulesEn[module].ns,
          module: module,
          data: {},
        };
      }

      // Add modules missing data to editable language
      for (const key in this.i18nModulesEn[module].data) {
        if (!i18nModules[module].data[key]) {
          i18nModules[module].data[key] = '';
        }
      }

      // Remove unnecessary data from editable language
      for (const key in i18nModules[module].data) {
        if (!this.i18nModulesEn[module].data[key]) {
          delete i18nModules[module].data[key];
        }
      }
    }
  }

  async save() {
    this.saving = true;
    try {
      if (this.i18nModules) {
        this.i18nModules = await this.i18nService.updateI18n(this.language, this.i18nModules);
        // Add missing modules to editable language
        this._addMissingModulesAndData(this.i18nModules);
      }
    } catch (err) {}
    this.saving = false;
  }

  isValid(): boolean {
    //return true;

    return Object.keys(this.i18nModules || {}).every((m) => {
      // this.validatorService.isValid(i18nValidator, m) => old not working version !!!
      // !!! generates Error !!!
      return this.validatorService.isValid(i18nValidator, this.i18nModules[m]);
    });
  }

  moduleChanged() {
    this.currentModuleSortedKeys = Object.keys(this.i18nModulesEn[this.currentModule].data).sort();
  }

  trackBy(index: number, val: string) {
    return index;
  }
}
