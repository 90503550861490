import { BannerPageModuleAttributes } from './../../entities/pagemodules/BannerPageModule';
import { HeadlineType } from './../../entities/Attributes';
import { BannerPageModule } from '../../entities/pagemodules/BannerPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class BannerPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public createBannerPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.bannerPageModuleAttributes(values?.title_attr),
      text: values?.text || null,
      image: values?.image || null,
      background: values?.background || null,
    };
  }

  public bannerPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): BannerPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async bannerPageModule(values: any): Promise<BannerPageModule> {
    if (values?.image || values?.background) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.image = item.image || values.image;
        item.background = item.background || values.background;
      });
    }

    const pageModule = {
      ...(await this.baseValues(values)),
      pageModuleType: 'Banner',
      image: undefined,
      background: undefined,
      backgroundColor: values?.backgroundColor || null,
      local: await this.local(values, (val) => this.createBannerPageModuleLocal(val)),
      ctaButton: values?.ctaButton || null,
    };

    return { ...pageModule, pageModuleType: 'Banner' };
  }
}
