import { EventSlot, EventSlotConfiguration } from './EventSlot';
import { EventTicket, EventTicketConfiguration } from './EventTicket';
import { EventTicketPool } from './EventTicketPool';
import { KeytopicContent } from './Keytopic';
import { MenuItem } from './MenuItem';
import { Page } from './Page';
import { Poll } from './Poll';
import { RelatedContent } from './RelatedContent';
import { Session } from './Session';
import { Stage } from './Stage';
import { ProductContent } from './Product';

// deprecated
export const teaserLinkTypes = ['teasermeetyourexpert', 'teaservirtualshowroom', 'agenda'] as const;
export type TeaserLinkType = (typeof teaserLinkTypes)[number];

export interface EventVersion {
  _id: string;
  version: number;
  change: number;
  dirty: boolean;
  eventTimeZone: string;
  publicStream: boolean;

  announceValid: boolean;
  releaseValid: boolean;

  country?: string;
  languages: string[];

  globalProperties: string[];

  /**
   * @deprecated
   */
  regional: { [region: string]: EventVersionRegion };

  local: { [language: string]: EventVersionLanguage };

  chats: EventVersionChat[];

  eventKeytopics: EventKeytopic[];
  eventProducts: EventProduct[];

  sessions: Session[];

  countSessionVideoTotal?: number;
  countSessionVideo?: {
    [language: string]: number;
  };

  modifiedBy?: string;
  modifiedAt?: string;

  event: string;

  pollMessages: EventVersionPollMessage[];

  images: {
    teaser?: string;
    placeholder?: string;
    share?: string;
    explore?: string;

    /**
     * @deprecated
     */
    stage?: string;
    /**
     * @deprecated
     */
    stageCompact?: string;
    /**
     * @deprecated
     */
    stageShowroom?: string;
  };

  eventTicketPools: EventTicketPool[];
  eventTicketConfiguration: EventTicketConfiguration;
  eventTickets: EventTicket[];
  eventSlotConfiguration?: EventSlotConfiguration;
  eventSlots: EventSlot[];
  registrationCustomFields: RegistrationCustomField[];
  eventMaps: string[];

  polls: Poll[];
  stages: Stage[];

  phase: { [phase: string]: EventVersionPhase };

  forkedAt: string;
  finalizedAt: string;
  finalizedBy: string;
  publishedAt: string;
  publishedBy: string;

  contentChecklistTitle: string;
  contentChecklistGroups?: ContentChecklistGroup[];
}
export interface ContentChecklistGroup {
  _id: string;
  local: { [language: string]: ContentChecklistGroupLocal };
  items: ContentChecklistItem[];
}

export interface ContentChecklistGroupLocal {
  title: string;
  description: string;
}

export interface ContentChecklistItem {
  _id: string;
  local: { [language: string]: ContentChecklistItemLocal };
  link: string;
}

export interface ContentChecklistItemLocal {
  title: string;
}

export interface EventVersionPollMessage {
  _id: string;
  maxSelections: number;
  local: {
    [language: string]: {
      title: string;
      description?: string;
    };
  };
  options: {
    _id: string;
    internalName: string;
    local: {
      [language: string]: {
        label: string;
        description?: string;
      };
    };
  }[];
}

export interface EventVersionChat {
  chat: string;
  internalName: string;
  showPolls?: boolean;
  autoConfirm: boolean;
  chatReply: boolean;
}

export const registrationCustomFieldAutoFillTypes = ['CustomData'] as const;
export type RegistrationCustomFieldAutoFillType = (typeof registrationCustomFieldAutoFillTypes)[number];
export const registrationCustomFieldAutoFillCustomDataModes = ['User', 'UserEvent', 'EventRegistration', 'EventRegistrationData'] as const;
export type RegistrationCustomFieldAutoFillCustomDataMode = (typeof registrationCustomFieldAutoFillCustomDataModes)[number];

export interface RegistrationCustomFieldAutoFill {
  registrationCustomFieldAutoFillType: RegistrationCustomFieldAutoFillType;
}

export interface RegistrationCustomFieldAutoFillCustomData {
  registrationCustomFieldAutoFillType: 'CustomData';
  customData: string;
  property: string;
  mode: RegistrationCustomFieldAutoFillCustomDataMode;
}

export interface RegistrationCustomField {
  customField: string;
  required: boolean;
  hidden?: boolean;
  eventTickets?: string[] | null;
  autoFill?: RegistrationCustomFieldAutoFill;
}

export function isRegistrationCustomFieldAutoFillCustomData(obj: any): obj is RegistrationCustomFieldAutoFillCustomData {
  return obj?.registrationCustomFieldAutoFillType === 'CustomData';
}

export interface EventKeytopic {
  keytopic: string;
  override?: KeytopicContent;
  local?: { [language: string]: EventKeytopicLanguage };

  /**
   * @deprecated
   */
  regional?: { [region: string]: EventKeytopicRegion };
}

export interface EventProduct {
  product: string;
  override?: ProductContent;
}

/**
 * @deprecated
 */
export interface EventKeytopicRegion {
  assets: string[];
}

export interface EventKeytopicLanguage {
  assets: string[];
}

export interface EventSpeaker {
  speaker: string;
}

// deprecated
export interface EventExternalLink {
  tag: string;
  title: string;
  description: string;
  link: string;
  image: string;
}

/**
 * @deprecated
 */
export interface EventVersionRegion {
  title: string; // obsolete
  shortDescription: string; // obsolete
  longDescription: string; // obsolete

  startAt: string | Date;
  endAt: string | Date;
  actualStartAt: string | Date;
  actualEndAt: string | Date;
  onDemandOnly: boolean;

  eventSpeakers: EventSpeaker[];

  teasers: {
    image: string;
    text: string;
    link: TeaserLinkType | string;
  }[];

  eventExternalLinks: EventExternalLink[];

  relatedContent: RelatedContent[];
}

export interface EventVersionLanguage {
  title: string;
  shortDescription: string;
  longDescription: string;

  startAt: string | Date;
  endAt: string | Date;
  onDemandOnly: boolean;

  eventSpeakers: EventSpeaker[];

  linkTitle?: string;
  linkDescription?: string;

  city?: string;
  fullAddress?: string;
  mapsLink?: string;

  /**
   * Should be moved to eventTicketConfiguration
   */
  eventRegistration: {
    ticketHeader: string;
    ticketSubheader: string;
    dataHeader: string;
  };
}

export interface EventVersionPhase {
  showMeetYourExpertInMenu: boolean;
  pages: Page[];
  menuItems: MenuItem[];
  mobileMenuItems: MenuItem[];
  appMenuItems: MenuItem[];
  webMenuItems: MenuItem[];
  appNavigationMenuItems: MenuItem[];
}

export const eventVersionDateFields = [
  '$.local..startAt',
  '$.local..endAt',
  '$.regional..startAt',
  '$.regional..endAt',
  '$.sessions[*].startAt',
  '$.sessions[*].endAt',
  '$.sessions[*].actualStartAt',
  '$.sessions[*].actualEndAt',
  '$.eventSlots[*].startAt',
  '$.eventSlots[*].endAt',
  '$.eventSlots[*].eventTimeSlots[*].startAt',
  '$.eventSlots[*].eventTimeSlots[*].endAt'
];
