import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TooltipModule } from 'primeng/tooltip';
import { EditorModule } from 'primeng/editor';
import { SelectButtonModule } from 'primeng/selectbutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeModule } from 'primeng/tree';

import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { AccessPolicyEditComponent } from './access-policy-edit/access-policy-edit.component';
import { AmpComponent } from './amp/amp.component';
import { AssetItemComponent } from './asset-item/asset-item.component';
import { AssetPreviewComponent } from './asset-preview/asset-preview.component';
import { AssetSelectComponent } from './asset-select/asset-select.component';
import { AssetComponent } from './asset/asset.component';
import { ChatMessageTemplateManagementComponent } from './chat-messagetemplate-management/chat-messagetemplate-management.component';
import { ChatOpenMessagesComponent } from './chat-open-messages/chat-open-messages.component';
import { ChatUserManagementComponent } from './chat-user-management/chat-user-management.component';
import { ChatComponent } from './chat/chat.component';
import { CtaButtonEditComponent } from './cta-button-edit/cta-button-edit.component';
import { CtaButtonComponent } from './cta-button/cta-button.component';
import { DatetimeComponent } from './datetime/datetime.component';
import { EmojiPickerComponent } from './emoji-picker/emoji-picker.component';
import { EventPhasesComponent } from './event-phases/event-phases.component';
import { FieldComponent } from './field/field.component';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { LoadingComponent } from './loading/loading.component';
import { MenuItemsEditComponent } from './menu-items-edit/menu-items-edit.component';
import { UserContextMenuItemsComponent } from './menu-items-edit/user-context-menu-items/user-context-menu-items.component';
import { UserContextMenuItemsEditComponent } from './menu-items-edit/user-context-menu-items/user-context-menu-items-edit/user-context-menu-items-edit.component';

import { SelectMenuComponent } from './select-menu/select-menu.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { PollViewerComponent } from './poll-viewer/poll-viewer.component';
import { UserDetailComponent } from './user-details/user-details.component';
import { UserPickerComponent } from './user-picker/user-picker.component';
import { InputsFormComponent } from './inputs-form/inputs-form.component';

import { PatchInputComponent } from './patch-input/patch-input.component';
import { CollaborationPatchInputComponent } from './patch-input/collaboration-patch-input/collaboration-patch-input.component';
import { EventPatchInputComponent } from './patch-input/event-patch-input/event-patch-input.component';
import { PagePatchInputComponent } from './patch-input/page-patch-input/page-patch-input.component';
import { BasicPatchInputComponent } from './patch-input/basic-patch-input/basic-patch-input.component';
import { PageModuleTypeSelectComponent } from './page-module-type-select/page-module-type-select.component';
import { EventStageTypeSelectComponent } from './event-stage-type-select/event-stage-type-select.component';
import { PageTypeSelectComponent } from './page-type-select/page-type-select.component';
import { TableComponent } from './table/table.component';
import { PaginatorModule } from 'primeng/paginator';
import { TableOptionsComponent } from './table/table-options/table-options.component';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { SidebarHeaderComponent } from './sidebar-header/sidebar-header.component';
import { RightsManagementComponent } from './rights-management/rights-management.component';
import { LanguageButtonComponent } from './language-button/language-button.component';
import { ListboxModule } from 'primeng/listbox';
import { SidebarFooterComponent } from './sidebar-footer/sidebar-footer.component';
import { PagerComponent } from './pager/pager.component';
import { VersionSelectComponent } from './version-select/version-select.component';
import { PreviewComponent } from './preview/preview.component';
import { TableStatusComponent } from './table/table-status/table-status.component';
import { EventRegistrationDialogComponent } from './event-registration-dialog/event-registration-dialog.component';
import { VipTicketAddDialogComponent } from './vip-ticket-add-dialog/vip-ticket-add-dialog.component';
import { EventSlotsEditComponent } from './event-slots-edit/event-slots-edit.component';
import { RichTextInputComponent } from './patch-input/rich-text-input/rich-text-input.component';
import { SidebarBodyComponent } from './sidebar-body/sidebar-body.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarElementComponent } from './sidebar-element/sidebar-element.component';
import { AssetSelectSearchComponent } from './asset-select/asset-select-search/asset-select-search.component';
import { CustomdataSelectComponent } from './customdata-select/customdata-select.component';
import { EventSelectComponent } from './event-select/event-select.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { ImageMarkersMapComponent } from './image-markers-map/image-markers-map.component';
import { TagTimezoneComponent } from './tag-timezone/tag-timezone.component';
import { EventItemComponent } from './event-select/event-item/event-item.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DomainButtonComponent } from './domain-button/domain-button.component';
import { MapPatchInputComponent } from './patch-input/map-patch-input/map-patch-input.component';
import { StreamDetailsComponent } from './stream-details/stream-details.component';
import { StreamStatusComponent } from './stream-details/stream-status/stream-status.component';
import { TagsSelectComponent } from './tags-select/tags-select.component';
import { PageSelectComponent } from './page-select/page-select.component';
import { QueryBuilderComponent } from './query-builder/query-builder.component';
import { ProductSelectComponent } from './product-select/product-select.component';
import { ProductSelectSearchComponent } from './product-select/product-select-search/product-select-search.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { OnboardingSelectComponent } from './onboarding-select/onboarding-select.component';
import { OnboardingSelectSearchComponent } from './onboarding-select/onboarding-select-search/onboarding-select-search.component';
import { OnboardingItemComponent } from './onboarding-item/onboarding-item.component';
import { OperatingRegionSelectComponent } from './operating-region-select/operating-region-select.component';
import { OperatingRegionSelectSearchComponent } from './operating-region-select/operating-region-select-search/operating-region-select-search.component';
import { OperatingRegionItemComponent } from './operating-region-item/operating-region-item.component';
import { AssetSerieSelectComponent } from './asset-serie-select/asset-serie-select.component';
import { QueryComponent } from './query/query.component';
import { ConditionComponent } from './query/condition/condition.component';
import { QuickFiltersComponent } from './quick-filters/quick-filters.component';
import { SpeakerSelectComponent } from './speaker-select/speaker-select.component';
import { SpeakerItemComponent } from './speaker-select/speaker-item/speaker-item.component';
import { EventTicketSelectorComponent } from './event-ticket-selector/event-ticket-selector.component';
import { VersionDiffComponent } from './version-diff/version-diff.component';
import { VjsPlayerComponent } from './vjs-player/vjs-player.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ColorPickerModule } from 'primeng/colorpicker';

const components = [
  AssetComponent,
  AssetItemComponent,
  AssetPreviewComponent,
  AssetSelectComponent,
  EventSelectComponent,
  EventItemComponent,
  ChatComponent,
  ChatMessageTemplateManagementComponent,
  ChatOpenMessagesComponent,
  ChatUserManagementComponent,
  CtaButtonComponent,
  CtaButtonEditComponent,
  DatetimeComponent,
  EmojiPickerComponent,
  EventPhasesComponent,
  FieldComponent,
  LanguageSelectComponent,
  LoadingComponent,
  SelectMenuComponent,
  SkeletonComponent,
  StreamDetailsComponent,
  StreamStatusComponent,
  TableFilterComponent,
  TagsSelectComponent,
  UserDetailComponent,
  UserPickerComponent,
  PageSelectComponent,
  PatchInputComponent,
  RichTextInputComponent,
  EventPatchInputComponent,
  PollViewerComponent,
  AmpComponent,
  MenuItemsEditComponent,
  UserContextMenuItemsComponent,
  UserContextMenuItemsEditComponent,
  AccessPolicyEditComponent,
  CollaborationPatchInputComponent,
  PagePatchInputComponent,
  InputsFormComponent,
  BasicPatchInputComponent,
  PageModuleTypeSelectComponent,
  EventStageTypeSelectComponent,
  PageTypeSelectComponent,
  TableComponent,
  TableOptionsComponent,
  TableStatusComponent,
  RightsManagementComponent,
  SidebarHeaderComponent,
  SidebarFooterComponent,
  LanguageButtonComponent,
  PagerComponent,
  VersionSelectComponent,
  PreviewComponent,
  EventRegistrationDialogComponent,
  VipTicketAddDialogComponent,
  EventSlotsEditComponent,
  SidebarBodyComponent,
  SidebarComponent,
  CustomdataSelectComponent,
  TagTimezoneComponent,
  ImageMarkersMapComponent,
  DomainButtonComponent,
  MapPatchInputComponent,
  QueryBuilderComponent,
  ProductSelectComponent,
  ProductSelectSearchComponent,
  ProductItemComponent,
  OnboardingSelectComponent,
  OnboardingSelectSearchComponent,
  OnboardingItemComponent,
  OperatingRegionSelectComponent,
  OperatingRegionSelectSearchComponent,
  OperatingRegionItemComponent,
  QueryComponent,
  ConditionComponent,
  QuickFiltersComponent,
  EventTicketSelectorComponent,
  VjsPlayerComponent,
  ColorPickerComponent,
];

@NgModule({
  declarations: [
    ...components,
    SidebarElementComponent,
    AssetSelectSearchComponent,
    FormSelectComponent,
    MapPatchInputComponent,
    PageSelectComponent,
    AssetSerieSelectComponent,
    SpeakerSelectComponent,
    SpeakerItemComponent,
    EventTicketSelectorComponent,
    VersionDiffComponent,
    VjsPlayerComponent,
  ],
  imports: [
    AutoCompleteModule,
    ButtonModule,
    CalendarModule,
    CalendarModule,
    TabViewModule,
    CardModule,
    CheckboxModule,
    ChipModule,
    ChipModule,
    ChipsModule,
    CommonModule,
    SidebarModule,
    ListboxModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    DialogModule,
    DirectivesModule,
    DividerModule,
    DropdownModule,
    FormsModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextareaModule,
    InputTextModule,
    InputNumberModule,
    TooltipModule,
    TreeModule,
    EditorModule,
    SelectButtonModule,
    RadioButtonModule,
    MenuModule,
    MultiSelectModule,
    MessagesModule,
    NgxFileDropModule,
    OverlayPanelModule,
    PipesModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    TableModule,
    TieredMenuModule,
    TranslateModule,
    PaginatorModule,
    InfiniteScrollModule,
    ColorPickerModule,
  ],
  exports: [...components],
})
export class ComponentsModule {}
