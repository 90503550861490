import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OnboardingsService } from 'src/app/services/onboardings/onboardings.service';
import { Onboarding } from 'src/common/entities/Onboarding';
@Component({
  selector: 'c-onboarding-select',
  templateUrl: './onboarding-select.component.html',
  styleUrls: ['./onboarding-select.component.scss'],
})
export class OnboardingSelectComponent implements OnInit {
  @Input()
  id: string;

  @Input()
  isEventPage = false;

  @Input()
  disabled = false;

  @Output()
  idChange: EventEmitter<string> = new EventEmitter<string>();

  onboarding: Onboarding;

  showSearch = false;
  filterText = '';

  constructor(private onboardingsService: OnboardingsService) {}

  async ngOnInit(): Promise<void> {
    this.id;
    this.onboarding = await this.onboardingsService.getOnboarding(this.id);
  }

  async select(onboarding: Onboarding): Promise<void> {
    this.showSearch = false;
    this.onboarding = onboarding;
    this.id = this.onboarding._id;
    this.idChange.emit(this.id);
  }

  openSearch() {
    this.showSearch = true;
  }

  remove(): void {
    this.id = null;
    this.idChange.emit(this.id);
  }
}
