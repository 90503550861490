<div class="panel" *ngIf="stream">
  <div class="panel-body">
    <div>
      <div class="step">
        <div class="step-content">
          <div class="step-index">1</div>
          <div class="step-details">
            <div class="step-name">Stream status</div>
            <div class="step-status">
              <span [class.warning]="!showStep2()">Status: {{ stream.status }}</span>
            </div>
            <div class="step-description">The stream needs to be stopped (the stream has ran) in order to continue with the post production.</div>
          </div>
          <div class="step-action">
            <button pButton [label]="'View stream details'" class="step-button" (click)="openStreamDetails()"></button>
          </div>
        </div>
      </div>

      <div class="step" *ngIf="showStep2()">
        <div class="step-content">
          <div class="step-index">2</div>
          <div class="step-details">
            <div class="step-name">Original recorded file</div>
            <div class="step-status">
              <span [class.warning]="getVODStatus() !== 'available'"
                >Status:
                <ng-container [ngSwitch]="getVODStatus()">
                  <span *ngSwitchCase="'no-vod'">No VOD created yet</span>
                  <span *ngSwitchCase="'in-progress'">VOD creation in progress</span>
                  <span *ngSwitchCase="'available'">VOD available</span>
                  <span *ngSwitchDefault>{{ getVODStatus() }}</span>
                </ng-container>
              </span>
            </div>
            <div class="step-description">
              The stream is recorded automatically. By clicking 'Create VoD', a Video on Demand mp4 file is created from the stream and uploaded to the asset management area. This allows you to use
              that file in the application as if you've uploaded a new video file manually.
            </div>
          </div>
          <div class="step-action">
            <button *ngIf="!vodAsset" pButton [label]="'Create VOD'" class="step-button" (click)="createVOD()" [disabled]="!isAllowedToCreateVOD() || loading"></button>
            <div class="vod-input input-group compact">
              <div class="input">
                <c-asset-select [id]="vodAsset?._id" (idChange)="setVodAsset($event)" mimeType="video/*"></c-asset-select>
              </div>
              <div class="title" *ngIf="!stream.vodAsset">Or select a VOD</div>
            </div>
          </div>
        </div>
      </div>

      <div class="step" *ngIf="showStep3And4()">
        <div class="step-content">
          <div class="step-index">3</div>
          <div class="step-details">
            <div class="step-name">Create videos for each session</div>
            <div class="step-status">
              <span [class.warning]="lastEditing?.status !== 'FINISHED'"
                >Status:
                <ng-container [ngSwitch]="lastEditing?.status">
                  <span *ngSwitchCase="'PROCESSING'">Processing</span>
                  <span *ngSwitchCase="'FINISHED'">Status: Available in Editor</span>
                  <span *ngSwitchDefault>Not sent to Editing Application yet</span>
                </ng-container>
              </span>
            </div>
            <div class="step-description">
              The recorded file can be opened in editor for post production. The recorded file is sent to the editing application together with the rough cut information from the live event (e.g. when
              a session has started and finished). Using that information, you can edit a session video. You have to publish the video in editor so that it gets imported and available for your event.
              Then you have to manually publish the newly created video to ensure the possiblity for a quality check.
            </div>
          </div>
          <div class="step-action">
            <button *ngIf="showExportButton" [disabled]="loading" pButton [label]="'Send to editor'" class="step-button" (click)="exportToEditor()"></button>
            <div *ngIf="showEditingInProgress">Processing: {{ lastEditing?.progress }}%</div>
          </div>
        </div>
        <div class="sessions">
          <table>
            <thead>
              <tr>
                <th>Start At</th>
                <th>End At</th>
                <th>Title</th>
                <th>Hidden</th>
                <th>Start Offset</th>
                <th>End Offset</th>
                <th>Duration</th>
                <th>Lang</th>
                <th>Current VoD</th>
                <th>Edited VoD (latest)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let relatedSession of relatedSessions">
                <td>{{ relatedSession.startAt | date : 'short' }}</td>
                <td>{{ relatedSession.endAt | date : 'short' }}</td>
                <td>{{ relatedSession.sessionTitle }}</td>
                <td>{{ relatedSession.isHidden }}</td>
                <td>{{ relatedSession.startAtOffset | numberAsTime }}</td>
                <td>{{ relatedSession.endAtOffset | numberAsTime }}</td>
                <td>{{ relatedSession.endAtOffset - relatedSession.startAtOffset | numberAsTime }}</td>
                <td>{{ (relatedSession.language | language | async)?.languageName }}</td>
                <td>
                  <span (click)="openAssetView(relatedSessionAssets[relatedSession.currentVodAsset]?._id)" class="asset-preview">
                    {{ relatedSessionAssets[relatedSession.currentVodAsset]?.internalName || '' }}
                  </span>
                </td>
                <td>
                  <ng-container [ngSwitch]="getSessionCutStatus(relatedSession._id)">
                    <span *ngSwitchCase="'PROCESSING'">Processing: {{ getSessionCutProgress(relatedSession._id) }}%</span>
                    <span *ngSwitchCase="'FINISHED'" (click)="openAssetView(relatedSessionAssets[relatedSession.latestEditedVodAsset]?._id)" class="asset-preview">
                      {{ relatedSessionAssets[relatedSession.latestEditedVodAsset]?.internalName || '' }}
                    </span>
                  </ng-container>
                </td>
                <td>
                  <ng-container [ngSwitch]="getSessionCutStatus(relatedSession._id)">
                    <button
                      *ngIf="relatedSession.externalEditorDeeplink && getSessionCutStatus(relatedSession._id) === 'CREATED'"
                      pButton
                      [label]="'Open in editor'"
                      [disabled]="!isEditingProcessFinished"
                      class="step-button"
                      (click)="openSessionCutInEditor(relatedSession)"
                    ></button>
                    <button *ngSwitchCase="'PROCESSING'" pButton [label]="'Publish'" [disabled]="true" class="step-button"></button>
                    <button
                      *ngSwitchCase="'FINISHED'"
                      pButton
                      [label]="'Publish'"
                      class="step-button"
                      (click)="publishSessionVOD(relatedSession)"
                      [disabled]="loading || isPublishSessionVODDisabled(relatedSession)"
                    ></button>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="step" *ngIf="showStep3And4()">
        <div class="step-content">
          <div class="step-index">4</div>
          <div class="step-details">
            <div class="step-name">SAVE COSTS BY DEACTIVATING THE STREAM</div>
            <div class="step-status">
              <span [class.warning]="!(stream.status === 'DEACTIVATED' || stream.status === 'DELETED')"
                >Status:
                <ng-container [ngSwitch]="stream.status">
                  <span *ngSwitchCase="'DEACTIVATED'">DEACTIVATED</span>
                  <span *ngSwitchCase="'DELETED'">DELETED</span>
                  <span *ngSwitchDefault>Stream is producing costs at the moment</span>
                </ng-container>
              </span>
            </div>
            <div class="step-description">Make sure to deactivate and delete the stream after the post production is finished because it will produce costs otherwise.</div>
          </div>
          <div class="step-action">
            <button pButton [label]="'View stream details'" class="step-button" (click)="openStreamDetails()"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog [showHeader]="false" [(visible)]="showStreamDetails" [header]="stream?.internalName" [showHeader]="true" [modal]="true" [closable]="true">
  <c-stream-details [stream]="stream" [executeStreamOperation]="executeStreamOperation"></c-stream-details>
</p-dialog>
