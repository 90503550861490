<div class="chat-messagetemplate-management">
  <div class="add-chatmessagetemplate">
    <div class="p-grid">
      <div class="p-col-2">
        <input type="text" pInputText [(ngModel)]="title" [placeholder]="'PAGE_CHAT_MESSAGE_TEMPLATES_PLACEHOLDER_NAME' | translate" />
      </div>

      <div class="p-col">
        <textarea pInputTextarea [rows]="1" [(ngModel)]="content" [placeholder]="'PAGE_CHAT_MESSAGE_TEMPLATES_PLACEHOLDER_CONTENT' | translate" [style]="{ width: '100%' }"></textarea>
      </div>

      <div class="p-col-1 switch">
        <p-inputSwitch [(ngModel)]="active" class="p-mr-2"></p-inputSwitch>
        {{ active ? ('GENERAL_ACTIVE' | translate) : ('GENERAL_INACTIVE' | translate) }}
      </div>

      <div class="p-col-1 p-d-flex p-jc-end">
        <p-button [label]="'GENERAL_ADD' | translate" icon="pi pi-check" (click)="createChatMessageTemplate()" [disabled]="!title || !content"></p-button>
      </div>
    </div>
  </div>

  <p-table
    [value]="chatMessageTemplates"
    [lazy]="true"
    (onLazyLoad)="loadChatMessageTemplates($event)"
    [paginator]="true"
    [rows]="15"
    [totalRecords]="chatMessageTemplateTotalRecords"
    [loading]="chatMessageTemplatesLoading"
    dataKey="_id"
    editMode="row"
    styleClass="p-datatable-sm"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="title">{{ 'PAGE_CHAT_MESSAGE_TEMPLATES_HEADER_NAME' | translate }} <p-sortIcon field="title"></p-sortIcon></th>
        <th pSortableColumn="content">{{ 'PAGE_CHAT_MESSAGE_TEMPLATES_HEADER_CONTENT' | translate }} <p-sortIcon field="content"></p-sortIcon></th>
        <th pSortableColumn="active">{{ 'GENERAL_ACTIVE' | translate }} <p-sortIcon field="active"></p-sortIcon></th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-chatmessagetemplate let-editing="editing" let-ri="rowIndex">
      <tr [pEditableRow]="chatmessagetemplate">
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="chatmessagetemplate.title" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ chatmessagetemplate.title }}
            </ng-template>
          </p-cellEditor>
        </td>

        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="chatmessagetemplate.content" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ chatmessagetemplate.content }}
            </ng-template>
          </p-cellEditor>
        </td>

        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-inputSwitch [(ngModel)]="chatmessagetemplate.active"></p-inputSwitch>
            </ng-template>
            <ng-template pTemplate="output">
              <a *ngIf="chatmessagetemplate.active" href="#"><i class="pi pi-check success"></i></a>
              <a *ngIf="chatmessagetemplate.active === false" href="#"><i class="pi pi-times danger"></i></a>
            </ng-template>
          </p-cellEditor>
        </td>

        <td class="text-right">
          <a *ngIf="!editing" pInitEditableRow (click)="onRowEditInit(chatmessagetemplate)" href="#"><i class="pi icon-ic-edit p-mr-2"></i></a>
          <a *ngIf="editing" pSaveEditableRow (click)="onRowEditSave(chatmessagetemplate)" href="#"><i class="pi pi-check success p-mr-3"></i></a>
          <a *ngIf="editing" pCancelEditableRow (click)="onRowEditCancel(chatmessagetemplate, ri)" href="#"><i class="pi pi-times danger p-mr-2"></i></a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
