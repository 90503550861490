<button class="input-button" (click)="edit()">
  {{ _ctaButtonLabel || 'None' }}
</button>

<c-sidebar [(visible)]="_editMode" (onHide)="onBlur.emit()" header="Edit CTA Button">

  <div class="input-group compact">
    <div class="input">
      <p-dropdown [options]="ctaButtonTypes" [(ngModel)]="currentCTAButtonType" (ngModelChange)="typeChange()" [disabled]="disabled" [appendTo]="'body'"></p-dropdown>
    </div>
    <div class="title">Type</div>
  </div>

  <div class="input-group compact" *ngIf="currentCTAButtonType && _editCTAButton?.local[currentLanguage]">
    <div class="input">
      <input type="text" [(ngModel)]="_editCTAButton.local[currentLanguage].label" [disabled]="disabled" />
    </div>
    <div class="title">
      Label
      <span class="subtitle">{{ (currentLanguage | language | async)?.languageName }}</span>
    </div>
  </div>

  <div class="input-group compact" *ngIf="['externallink', 'querylink'].indexOf(currentCTAButtonType) > -1 && _editCTAButton?.local[currentLanguage]">
    <div class="input">
      <input type="text" [(ngModel)]="_editCTAButton.local[currentLanguage].link" [disabled]="disabled" />
    </div>
    <div class="title">
      Link
      <span class="subtitle">{{ (currentLanguage | language | async)?.languageName }}</span>
    </div>
  </div>

  <div class="input-group compact" *ngIf="currentCTAButtonType === 'videoasset' && _editCTAButton?.local[currentLanguage]">
    <div class="input">
      <c-asset-select [id]="_editCTAButton.local[currentLanguage].video" [disabled]="disabled" (idChange)="setAssetId($event)" mimeType="video/*"></c-asset-select>
    </div>
    <div class="title">
      Asset
      <span class="subtitle">{{ (currentLanguage | language | async)?.languageName }}</span>
    </div>
  </div>

  <div class="input-group compact" *ngIf="currentCTAButtonType === 'share'">
    <div class="title">Share Targets</div>
    <p-multiSelect styleClass="multiselect" [options]="_shareCtaTargetOptions" [(ngModel)]="_editCTAButton.shareCtaTargets" [appendTo]="'body'" placeholder="All targets"></p-multiSelect>
  </div>

  <div class="input-group compact" *ngIf="currentCTAButtonType === 'appfunction'">
    <div class="input">
      <p-dropdown [options]="appFunctionActionTypes" [(ngModel)]="_editCTAButton.appFunction.action" [disabled]="disabled" [appendTo]="'body'"></p-dropdown>
    </div>
    <div class="title">Action</div>
  </div>

  <div class="input-group compact" *ngIf="currentCTAButtonType === 'appfunction'">
    <div class="input">
      <input type="text" [(ngModel)]="_editCTAButton.appFunction.local[currentLanguage].actionData" [disabled]="disabled" />
    </div>
    <div class="title">
      Action data
      <span class="subtitle">{{ (currentLanguage | language | async)?.languageName }}</span>
    </div>
  </div>

  <div class="input-group switch compact">
    <div class="input" *ngIf="currentCTAButtonType != null">
      <p-checkbox [(ngModel)]="_editCTAButton.disabled" [binary]="true" label="Disabled"></p-checkbox>
    </div>
  </div>

  <div class="input-group compact" *ngIf="currentCTAButtonType === 'exploreevent'">
    <div class="input">
      <c-event-select [(value)]="_editCTAButton.event" [disabled]="disabled"></c-event-select>
    </div>
    <div class="title">
      Event
    </div>
  </div>

  <div class="input-group compact" *ngIf="currentCTAButtonType === 'page'">
    <div class="input">
      <c-page-select [(value)]="_editCTAButton.page" [disabled]="disabled"></c-page-select>
    </div>
    <div class="title">
      Page
    </div>
  </div>
  
  <p-button label="Set CTA Button" (onClick)="setCtaButton()" [disabled]="disabled || (_editCTAButton?.ctaButtonType === 'externallink' && !_editCTAButton.local[currentLanguage]?.link)"></p-button>
</c-sidebar>
