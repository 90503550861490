import { InputType } from 'src/common/inputs/Inputs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent } from 'primeng/api';
import { FilterMatchMode, IFilterableListQuery, IFilterList } from 'src/common/api/interfaces';
import { Page } from 'src/common/entities/Page';
import { PatchExecutor } from 'src/common/patch/PatchExecutor';
import * as jp from 'jsonpath';

export interface DropdownItem {
  name: string;
  code: string;
}

export interface MatchMode {
  [field: string]: FilterMatchMode;
}

type RequestState = 'open' | 'closed';
type CollectedRequest = {
  state: RequestState;
  inputs: any[];
  resolveFunctions: ((result: any) => void)[];
  rejectFunctions: ((err: any) => void)[];
};
@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private _collectedRequests: { [identifier: string]: CollectedRequest[] } = {};

  public paginatorRows: number = 50;

  get currentLanguage(): string {
    return 'en';
    // return this.translateService.currentLang ? this.translateService.currentLang : this.translateService.defaultLang;
  }
  get defaultLanguage(): string {
    return 'en';
    // return this.translateService.defaultLang;
  }

  constructor(private translateService: TranslateService) {}

  createDropdownOptions(values: readonly string[], translatePrefix?: string): Promise<DropdownItem[]> {
    return Promise.all(
      values.map(async (value) => {
        return {
          name: translatePrefix ? await this.translate(`${translatePrefix.toUpperCase()}_${value.toUpperCase()}`) : value,
          code: value,
        };
      })
    );
  }

  translate(tag: string, params?: any): Promise<string> {
    return this.translateService.get(tag, params || {}).toPromise();
  }

  translateAll(tags: string[], params?: any): Promise<{ [tag: string]: string }> {
    return this.translateService.get(tags, params || {}).toPromise();
  }

  loadEventToQuery(loadEvent: LazyLoadEvent): IFilterableListQuery {
    const filter = (loadEvent.filters as IFilterList) || {};
    const filterObject = Object.keys(filter)
      .filter((f) => filter[f].value)
      .reduce((a, b) => {
        return { ...a, [b]: filter[b] };
      }, {});
    return {
      skip: loadEvent.first,
      limit: loadEvent.rows,
      orderBy: loadEvent.sortField,
      orderDirection: loadEvent.sortOrder,
      filter: filterObject,
    };
  }

  loadEventArrayToQuery(loadEvent: LazyLoadEvent): IFilterableListQuery {
    const filter = (loadEvent.filters as IFilterList) || {};
    const filterObject = Object.keys(filter)
      .filter((f) => filter[f][0].value)
      .reduce((a, b) => {
        return { ...a, [b]: filter[b][0] };
      }, {});
    return {
      skip: loadEvent.first,
      limit: loadEvent.rows,
      orderBy: loadEvent.sortField,
      orderDirection: loadEvent.sortOrder,
      filter: filterObject,
    };
  }

  pastelColor(inputString: string) {
    //TODO: adjust base colour values below based on theme
    const baseRed = 128;
    const baseGreen = 128;
    const baseBlue = 128;

    //lazy seeded random hack to get values from 0 - 256
    //for seed just take bitwise XOR of first two chars
    let seed = inputString.charCodeAt(0) ^ inputString.charCodeAt(1);
    const rand_1 = Math.abs(Math.sin(seed++) * 10000) % 256;
    const rand_2 = Math.abs(Math.sin(seed++) * 10000) % 256;
    const rand_3 = Math.abs(Math.sin(seed++) * 10000) % 256;

    //build colour
    const red = Math.round((rand_1 + baseRed) / 2);
    const green = Math.round((rand_2 + baseGreen) / 2);
    const blue = Math.round((rand_3 + baseBlue) / 2);

    return { red: red, green: green, blue: blue };
  }

  wait(ms: number): Promise<void> {
    return new Promise((resolve: (result: any) => void) => {
      setTimeout(() => {
        resolve(undefined);
      }, ms);
    });
  }

  pageTitle(page: Page, language: string): string {
    if (page.local && page.local[language]?.title) return page.local[language].title;
    if ((page.pageType === 'StandardPage' || page.pageType === 'BlankPage') && page.path) return '[Untitled]';
    if ((page.pageType === 'StandardPage' || page.pageType === 'BlankPage') && !page.path) return '[Main]';
    //if (page.pageType === 'StreamPage') return 'Stream';
    if (page.pageType === 'KeytopicsPage') return 'Key Topics';
    return '';
  }

  resolveJsonpathValue<T>(value: any, jsonpath: string | string[], params: { [key: string]: any } = {}): T {
    return jp.value(value, this.resolveJsonpath(jsonpath, params));
  }

  resolveJsonpath(jsonpath: string | string[], params: { [key: string]: any } = {}): string {
    return PatchExecutor.resolveJsonpath(this.combineJsonpath(jsonpath), params);
  }

  combineJsonpath(jsonpath: string | string[]): string {
    if (typeof jsonpath === 'string') return jsonpath;
    let combinedJsonpath = '$';
    for (const part of jsonpath) {
      combinedJsonpath = (typeof part === 'string' ? part : this.combineJsonpath(part)).replace(/^\$/, combinedJsonpath);
    }
    return combinedJsonpath;
  }

  startsWithJsonpath(targetJsonpath: string, searchJsonpath: string, targetJsonpathParams: { [key: string]: any } = {}, searchJsonpathParams: { [key: string]: any } = {}) {
    const resolvedTarget = this.resolveJsonpath(targetJsonpath, targetJsonpathParams);
    const resolvedSearch = this.resolveJsonpath(searchJsonpath, searchJsonpathParams);

    return resolvedSearch === resolvedTarget || resolvedTarget.startsWith(resolvedSearch + '.') || resolvedTarget.startsWith(resolvedSearch + '[');
  }

  replaceObject(previous: any, next: any) {
    if (typeof previous !== 'object') return;

    for (const key of Object.keys(previous)) {
      delete previous[key];
    }

    for (const key of Object.keys(next)) {
      previous[key] = next[key];
    }
  }

  async collectedRequest<T, R>(identifier: string, input: T, execution: (inputs: T[]) => Promise<R[]>, waitingTime: number = 20): Promise<R> {
    if (!this._collectedRequests[identifier]) {
      this._collectedRequests[identifier] = [];
    }

    let resolveFunction: (result: R) => void;
    let rejectFunction: (err: any) => void;

    const promise = new Promise<R>((resolve: (result: R) => void, reject: (err: any) => void) => {
      resolveFunction = resolve;
      rejectFunction = reject;
    });

    const openRequest = this._collectedRequests[identifier].find((r) => r.state === 'open');

    if (openRequest) {
      openRequest.inputs.push(input);
      openRequest.resolveFunctions.push(resolveFunction);
      openRequest.rejectFunctions.push(rejectFunction);
    } else {
      const request: CollectedRequest = {
        state: 'open',
        inputs: [input],
        resolveFunctions: [resolveFunction],
        rejectFunctions: [rejectFunction],
      };

      this._collectedRequests[identifier].push(request);

      setTimeout(() => {
        request.state = 'closed';

        execution(request.inputs).then((result) => {
          for (let i = 0; i < result.length; i++) {
            request.resolveFunctions[i](result[i]);
          }
        });

        const index = this._collectedRequests[identifier].indexOf(request);

        if (index >= 0) this._collectedRequests[identifier].splice(index, 1);
        if (this._collectedRequests[identifier].length === 0) delete this._collectedRequests[identifier];
      }, waitingTime);
    }

    return promise;
  }

  public localize<T>(value: { [language: string]: T }, language?: string): T | null {
    if (!value) return null;

    if (language && value[language]) {
      return value[language];
    }

    if (value[this.currentLanguage]) {
      return value[this.currentLanguage];
    }

    if (value['en']) {
      return value['en'];
    }

    if (Object.keys(value).length > 0) {
      return value[Object.keys(value)[0]];
    }

    return null;
  }

  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  public guessInputType(jsonpath: string): InputType {
    const parts = jp.parse(jsonpath);
    let lastPart = parts[parts.length - 1]?.expression?.value;

    if (typeof lastPart === 'string') {
      lastPart = lastPart.toLowerCase();
      if (lastPart.includes('text') || lastPart.includes('description')) {
        return 'textarea';
      }
      if (lastPart.includes('startat') || lastPart.includes('endat')) {
        return 'datetime';
      }
      if (lastPart.includes('color')) {
        return 'color';
      }
      if (lastPart.endsWith('ctabutton')) {
        return 'ctabutton';
      }
      if (lastPart.includes('image') || lastPart.includes('background') || lastPart.includes('icon')) {
        return 'imageasset';
      }
      if (lastPart.startsWith('max') || lastPart.startsWith('min')) {
        return 'number';
      }
      if (lastPart.startsWith('show') || lastPart.startsWith('indicate')) {
        return 'switch';
      }
      if (lastPart.includes('tag')) {
        return 'tags';
      }
    }

    return 'text';
  }
}
