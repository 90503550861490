import { Injectable } from '@angular/core';
import * as yup from 'yup';

/* setLocale({
  // use constant translation keys for messages without values
  mixed: {
    default: 'field_invalid',
  },
  // use functions to generate an error object that includes the value from the schema
  number: {
    min: ({ min }) => ({ key: 'field_too_short', values: { min } }),
    max: ({ max }) => ({ key: 'field_too_big', values: { max } }),
  },
}); */

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  constructor() {}

  isValid(schema: yup.BaseSchema, obj: any, path?: string): boolean {
    if (path) {
      try {
        schema.validateSyncAt(path, obj);
        return true;
      } catch (err) {
        return false;
      }
    } else {
      return schema.isValidSync(obj);
    }
  }

  errorAt(schema: yup.BaseSchema, obj: any, path: string): string | null {
    try {
      schema.validateSyncAt(path, obj);
      return null;
    } catch (err) {
      return err.message;
    }
  }
}
