import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { PagesService } from 'src/app/services/pages/pages.service';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { AssetConfiguration, videoPlayerTypes, videoTargetTypes } from 'src/common/entities/Configuration';
import { isAssetPage } from 'src/common/entities/Page';
import { Factory } from 'src/common/factories/Factory';

@Component({
  selector: 'app-asset-configuration',
  templateUrl: './asset-configuration.component.html',
  styleUrls: ['./asset-configuration.component.scss'],
})
export class AssetConfigurationComponent implements OnInit {
  assetConfiguration: AssetConfiguration;
  tab: Tab;
  domainCollectionId: string = null;

  videoPlayerTypes: { label: string; value: string }[];
  videoTargetTypes: { label: string; value: string }[];
  assetPages: { label: string; value: string | null }[];

  constructor(private configurationService: ConfigurationService, private tabsService: TabsService, private activatedRoute: ActivatedRoute, private pagesService: PagesService) {
    this.tab = this.tabsService.register({
      category: 'configuration',
      loading: true,
      route: this.activatedRoute.snapshot,
    });
  }

  async ngOnInit() {
    this.tab.loading = true;
    try {
      this.videoPlayerTypes = [...videoPlayerTypes].map((v) => ({ label: v, value: v }));
      this.videoTargetTypes = [...videoTargetTypes].map((v) => ({ label: v, value: v }));
    } catch (err) {
      console.error(err);
    }
    await this.loadAssetPages();
    this.tab.loading = false;
  }

  async loadAssetPages() {
    this.tab.loading = true;
    try {
      this.assetPages = [{ label: 'None', value: null }].concat(
        (
          await this.pagesService.getPages(this.domainCollectionId, {
            filter: {
              pageType: { matchMode: 'equals', value: 'AssetPage' },
            },
          })
        ).items
          .filter(isAssetPage)
          .map((e) => ({
            label: `${e.internalName || e.local['en']?.title || (Object.keys(e.local).length > 0 ? e.local[Object.keys(e.local)[0]]?.title : 'Untitled')} ${e.path ? `(/${e.path})` : ''}`,
            value: e._id,
          }))
      );
    } catch (err) {
      console.error(err);
    }
    this.tab.loading = false;
  }

  async loadAssetConfig(domainCollectionId: string) {
    this.tab.loading = true;
    this.assetConfiguration = await Factory.configuration().assetConfiguration(await this.configurationService.getConfigurationByKeyAndDomain('asset', this.domainCollectionId));
    this.tab.loading = false;
  }

  async onDomainChange(domainCollectionId: string) {
    this.domainCollectionId = domainCollectionId;
    await this.loadAssetPages();
    await this.loadAssetConfig(this.domainCollectionId);
  }

  async save() {
    this.tab.loading = true;
    try {
      this.assetConfiguration = await this.configurationService.saveAssetConfiguration(this.assetConfiguration, null, this.domainCollectionId);
    } catch (err) {
      console.error(err);
    }
    this.tab.loading = false;
  }
}
