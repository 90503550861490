import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';
import 'moment-timezone';
import * as importedTimeZones from 'src/assets/timezones/tz-names';

import { ValidatorService } from 'src/app/services/validator/validator.service';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { Language } from 'src/common/entities/Language';

import { createRegion, Region } from 'src/common/entities/Region';
import { regionValidator } from 'src/common/api/v1/configuration/regions/validators/RegionValidator';
import { RegionsService } from 'src/app/services/regions/regions.service';

interface TimeZone {
  name: string;
  timeZone: string;
  utcOffset: number;
}

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss'],
})
export class RegionComponent implements OnInit, OnDestroy {
  regionId: string = 'undefined';
  region: Region | null = null;

  currentLanguage: string = 'en';
  languages: Language[] = [];
  selectedLanguage: Language = null; // Active language in dowpdown

  tzNames: string[];
  timeZones: TimeZone[] = [];
  filteredTimeZones: TimeZone[] = [];
  currentTimeZone: TimeZone;

  loading: boolean = true;
  saving: boolean = false;

  private _ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private validatorService: ValidatorService,
    private regionsService: RegionsService,
    private languagesService: LanguagesService
  ) {}

  async ngOnInit(): Promise<void> {
    // Prepare moments timezones for use with autocomplete
    importedTimeZones.timeZoneNames.forEach((tz) => {
      const utcOffset: number = moment().tz(tz).utcOffset();
      const hours: number = Math.abs(Math.trunc(utcOffset / 60));
      const hoursTxt: string = String('0').repeat(2 - hours.toString().length) + hours.toString();
      const minutes: number = Math.abs(utcOffset - Math.trunc(utcOffset / 60) * 60);
      const minutesTxt: string = String('0').repeat(2 - minutes.toString().length) + minutes.toString();
      const utcOffsetTxt: string = (utcOffset >= 0 ? '+' : '-') + hoursTxt + ':' + minutesTxt;

      this.timeZones.push({
        name: tz + ' (UTC' + utcOffsetTxt + ')',
        timeZone: tz,
        utcOffset: moment().tz(tz).utcOffset(),
      });
    });

    this.activatedRoute.params.pipe(takeUntil(this._ngUnsubscribe)).subscribe(async (params) => {
      this.regionId = params.region;

      await this._retrieveLanguages();

      if (this.regionId === 'new') {
        this.region = createRegion();
        this.loading = false;
      } else if (this.regionId) {
        await this.retrieveData();
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe subscriptions
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  async retrieveData() {
    this.loading = true;
    try {
      this.region = await this.regionsService.getRegion(this.regionId);
      this.currentTimeZone = this.timeZones.find((tz) => tz.timeZone === this.region.timeZone);
      this.selectedLanguage = this.languages.find((l) => l.language === this.region.language);
    } catch (err) {}
    this.loading = false;
  }

  private async _retrieveLanguages() {
    try {
      this.languages = await this.languagesService.getActiveLanguagesAsPromise();
    } catch (err) {}
  }

  async save() {
    this.saving = true;
    try {
      if (this.region) {
        if (this.regionId === 'new') {
          this.region = await this.regionsService.createRegion(this.region);
          this.router.navigate(['/configuration/regions', this.region.region], {
            replaceUrl: true,
          });
        } else {
          this.region = await this.regionsService.updateRegion(this.region);
        }
      }
    } catch (err) {}
    this.saving = false;
  }

  // async delete() {
  //   /* Only Prototype, not in use */
  //   this.saving = true;
  //   try {

  //     if (this.region) {
  //         this.region = await this.regionsService.deleteRegion(this.region);
  //     }

  //   } catch (err) {
  //   }
  //   this.saving = false;
  // }

  isValid(): boolean {
    return this.validatorService.isValid(regionValidator, this.region);
  }

  errorAt(path: string): string | null {
    return this.validatorService.errorAt(regionValidator, this.region, path);
  }

  changedLanguage(event: any) {
    if (event.value) {
      this.region.language = event.value.language;
    }
  }

  filterTimeZones(event) {
    if (!event.query) {
      this.filteredTimeZones = this.timeZones;
    } else {
      this.filteredTimeZones = this.timeZones.filter((tz) => tz.name.toLowerCase().includes(String(event.query).toLowerCase()));
    }
  }

  selectTimeZone(event) {
    this.region.timeZone = event.timeZone;
  }

  clearTimeZone(event) {
    this.region.timeZone = '';
  }
}
