import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TagsService } from 'src/app/services/tags/tags.service';
import { Tag, TagWithParentLabelPaths, TaxonomyNode } from 'src/common/entities/Tag';

@Component({
  selector: 'c-tags-select',
  templateUrl: './tags-select.component.html',
  styleUrls: ['./tags-select.component.scss'],
})
export class TagsSelectComponent implements OnInit, OnChanges {
  @Input()
  disabled = false;

  @Input()
  renderBySelf = false;

  @Input()
  multiselect = true;

  @Input()
  showTooltip = true;

  @Input()
  value: string[] | string;

  @Output()
  onBlur?: EventEmitter<void> = new EventEmitter<void>(true);

  @Output()
  onFocus?: EventEmitter<void> = new EventEmitter<void>(true);

  @Output()
  valueChange: EventEmitter<string[] | string> = new EventEmitter<string[] | string>();

  @Output()
  valueChangeFullTag: EventEmitter<TaxonomyNode<TagWithParentLabelPaths>[]> = new EventEmitter<TaxonomyNode<TagWithParentLabelPaths>[]>();

  @Output()
  sideBarClosed: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  showTagsSidebar = false;

  tree = [];
  tags = [];
  toolTips: { [tagId: string]: string } = {};
  searchText: string;
  searchTextSubject: Subject<string> = new Subject<string>();
  searchTextSubscription: Subscription;

  constructor(private tagsService: TagsService) {}

  async ngOnInit(): Promise<void> {
    this.searchTextSubscription = this.searchTextSubject.pipe(debounceTime(500)).subscribe(() => this.executeSearch());
    this.tagsService.getTags().then((result) => (this.tree = result as TaxonomyNode<Tag>[]));
    await this.getTagsFromValue();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (!this.renderBySelf) {
      if (
        (changes.value?.currentValue && typeof changes.value.currentValue === 'string' && changes.value.currentValue !== changes.value.previousValue) ||
        (Array.isArray(changes.value.currentValue) &&
          (changes.value.currentValue.length !== changes.value.previousValue?.length ||
            changes.value.currentValue.filter((c, i) => changes.value.previousValue.length <= i || c !== changes.value.previousValue[i]).length))
      ) {
        await this.getTagsFromValue();
      } else if (!this.value) {
        this.tags = [];
      }
    }
  }

  async getTagsFromValue(): Promise<void> {
    this.tags = [];
    let _tags = [];

    if (this.value && typeof this.value === 'string') {
      const result = await this.tagsService.getTag(this.value as string);
      _tags.push(result);
    } else if (this.value && Array.isArray(this.value)) {
      for (const v of this.value) {
        _tags.push(v ? await this.tagsService.getTag(v) : null);
      }
      if (!this.multiselect) {
        if (this.renderBySelf) {
          this.valueChangeFullTag.emit(_tags);
        }
        if (_tags.length > 1) {
          // Only hold one value if not multiselect
          _tags = [_tags[0]];
          this.tags = _tags;
          this.updateValue();
        }
      }
    }
    this.tags = _tags;
  }

  async executeSearch(): Promise<void> {
    this.tree = (await this.tagsService.getTags(this.searchText)) as TaxonomyNode<Tag>[];
  }

  searchTextKeyUp(): void {
    this.searchTextSubject.next(this.searchText);
  }

  async onSelect(e): Promise<void> {
    this.addValue(e);
    this.showTagsSidebar = false;

    this.collapseAll(this.tree);
  }

  collapseAll(node: any[]): void {
    node.forEach((t) => {
      t.expanded = false;
      if (t.children?.length) {
        this.collapseAll(t.children);
      }
    });
  }

  async onRemove(): Promise<void> {
    this.updateValue();
  }

  updateValue(): void {
    if (this.multiselect) {
      this.value = this.tags.map((item) => item.data._id);
    } else {
      this.value = this.tags[0].data._id;
    }
    this.valueChange.emit(this.value);
  }

  addValue(e): void {
    let _tags = this.tags;

    if (this.multiselect) {
      _tags.push(e.node);
      _tags = this.preventDuplicates(_tags);
    } else {
      // If not multiselect, swap existing value with new one
      _tags = [e.node];
    }

    // Element has to be replaced in order to proper performing the update
    this.tags = [..._tags];
    this.updateValue();
  }

  preventDuplicates(tags: TaxonomyNode<Tag>[]): TaxonomyNode<Tag>[] {
    return tags.filter((v, i, self) => i === self.findIndex((t) => t._id === v._id));
  }

  async nodeExpand(e): Promise<void> {
    await this.loadChildren(e);
  }

  async loadChildren(e): Promise<void> {
    if (e.node.children.length > 0) {
      return;
    }

    const nodes = await this.tagsService.getTagChildren(e.node.data._id);
    e.node.children = nodes;
  }

  getTaxonomyToolTip(item: TaxonomyNode<TagWithParentLabelPaths>): string {
    if (item) {
      if (!this.toolTips[item.data._id]) {
        this.toolTips[item.data._id] = `<b>${item.label}</b>${
          item.data.parentLabelPaths
            .map((pl) => `<br/>&nbsp;&nbsp;${pl.internalName}`) || ''
        }`;
      }
      return this.toolTips[item.data._id];
    }
    return '';
  }
}
