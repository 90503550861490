import { Injectable } from '@angular/core';
import { Onboarding } from 'src/common/entities/Onboarding';
import { ApiService } from '../api/api.service';
import { CacheContainer } from '../cache/cache-container';
import { CacheService } from '../cache/cache.service';
import { GetOnboardingQuery, GetOnboardingResponse } from '../../../common/api/v1/configuration/onboardings/GetOnboarding';
import { GetOnboardingsQuery, GetOnboardingsResponse } from '../../../common/api/v1/configuration/onboardings/GetOnboardings';
import { PostOnboardingsBody, PostOnboardingsQuery, PostOnboardingsResponse } from '../../../common/api/v1/configuration/onboardings/PostOnboardings';
import { PostOnboardingBody, PostOnboardingQuery, PostOnboardingResponse } from '../../../common/api/v1/configuration/onboardings/PostOnboarding';
import { PostOnboardingDeleteBody, PostOnboardingDeleteQuery, PostOnboardingDeleteResponse } from '../../../common/api/v1/configuration/onboardings/PostOnboardingDelete';

@Injectable({
  providedIn: 'root',
})
export class OnboardingsService {
  private _cache: CacheContainer<Onboarding>;

  constructor(private apiService: ApiService, private cacheService: CacheService) {
    this._cache = this.cacheService.create<Onboarding>({
      get: async (onboardingId) => await this.apiService.get<GetOnboardingQuery, GetOnboardingResponse>(`/api/v1/configuration/onboardings/${onboardingId}`).toPromise(),
      socketEvents: ['onboarding:update'],
    });
  }

  getOnboarding(onboardingId: string): Promise<Onboarding> {
    return this._cache.asPromise(onboardingId);
  }

  async getOnboardings(query?: GetOnboardingsQuery): Promise<GetOnboardingsResponse> {
    const onboardings = await this.apiService
      .get<GetOnboardingsQuery, GetOnboardingsResponse>('/api/v1/configuration/onboardings', {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();

    this._cache.fill(onboardings.items);

    return onboardings;
  }

  createOnboarding(onboarding: Onboarding): Promise<Onboarding> {
    return this._cache.post<PostOnboardingsQuery, PostOnboardingsBody, PostOnboardingsResponse>('/api/v1/configuration/onboardings', onboarding);
  }

  updateOnboarding(onboarding: Onboarding): Promise<Onboarding> {
    return this._cache.post<PostOnboardingQuery, PostOnboardingBody, PostOnboardingResponse>(`/api/v1/configuration/onboardings/${onboarding._id}`, onboarding);
  }

  deleteOnboarding(onboarding: Onboarding): Promise<Onboarding> {
    return this._cache.post<PostOnboardingDeleteQuery, PostOnboardingDeleteBody, PostOnboardingDeleteResponse>(`/api/v1/configuration/onboardings/${onboarding._id}/delete`, onboarding);
  }
}
