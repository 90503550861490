import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Environment, environment } from './environments/environment';

(async () => {
  let selectedEnvironment = environment;

  try {
    const result = await fetch('/environment.json?hash=%RELEASEHASH%');
    selectedEnvironment = { ...selectedEnvironment, ...(await result.json()) };
  } catch (err) {
    console.warn('Could not fetch environment.json. Using default environment.');
  }

  if (selectedEnvironment.production) {
    enableProdMode();
  }

  platformBrowserDynamic([{ provide: Environment, useValue: selectedEnvironment }])
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
})();
