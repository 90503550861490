<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'GENERAL_BLACKLIST_ENTRIES' | translate }}</h1>
  </div>
  <div class="tab-menu"></div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_BLACKLIST_ENTRIES' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button
          *hasRight="'configuration.create'"
          [label]="('GENERAL_NEW' | translate) + ' ' + ('GENERAL_BLACKLIST_ENTRY' | translate)"
          icon="pi icon-ic-add-item"
          (onClick)="newBlacklisstEntry()"
        ></p-button>
      </div>
    </div>
    <c-table mode="query" [options]="tableOptions" [tableOptionsComponent]="toc" (query)="query($event)" [baseRoute]="'/configuration/blacklistentries'" [routePathProperty]="'_id'">
      <ng-template let-blacklistEntry>
        <div *hasRight="['configuration.read', 'configuration.edit']" [routerLink]="[blacklistEntry._id]">{{ prepareEntryTypeTranslation(blacklistEntry) | translate }}</div>
        <span *hasNoRight="['configuration.read', 'configuration.edit']">{{ prepareEntryTypeTranslation(blacklistEntry) | translate }}</span>
      </ng-template>
      <ng-template let-blacklistEntry>
        <div *ngIf="blacklistEntry | fn : asDomainBlacklistEntry as domainBlacklistEntry">
          {{ domainBlacklistEntry.domain }}
        </div>
      </ng-template>
    </c-table>
  </div>
</div>
