<div class="menu-items" [ngClass]="menuStyle">
  <div *ngFor="let menuItem of menuItems; let index = index" class="menu-item" [class.selected]="menuItem === selectedMenuItem" (click)="selectMenuItem(menuItem)">
    <c-asset-preview
      style="vertical-align: middle"
      [wrapperStyle]="'width: var(--control-font-size);height: var(--control-font-size); background: none; border: none;'"
      [asset]="streamAsset(menuItem.icon) | async"
    ></c-asset-preview>
    {{ menuItem.local[language]?.title || 'Untitled' }}
    <i class="pi pi-chevron-left" (click)="moveMenuItemLeft(index)"></i>
    <i class="pi pi-chevron-right" (click)="moveMenuItemRight(index)"></i>
    <i class="pi pi-trash" (click)="removeMenuItem(index)"></i>
  </div>
  <a (click)="addMenuItem()" [routerLink]="[]"><i class="pi icon-ic-add-item"></i> Add</a>
</div>

<div class="selected-menu-item" *ngIf="selectedMenuItem">
  <c-field header="Title" *ngIf="selectedMenuItem.local[language]">
    <input pInputText [(ngModel)]="selectedMenuItem.local[language].title" />
  </c-field>

  <c-field header="Icon">
    <c-asset-select [(id)]="selectedMenuItem.icon" [mimeType]="'image/svg+xml'"></c-asset-select>
  </c-field>

  <c-field header="Hover Icon">
    <c-asset-select [(id)]="selectedMenuItem.hoverIcon" [mimeType]="'image/svg+xml'"></c-asset-select>
  </c-field>

  <c-field header="Active Icon">
    <c-asset-select [(id)]="selectedMenuItem.activeIcon" [mimeType]="'image/svg+xml'"></c-asset-select>
  </c-field>

  <c-field header="Menu Item Type">
    <p-dropdown [options]="menuItemTypeOptions" [(ngModel)]="selectedMenuItem.menuItemType"></p-dropdown>
  </c-field>

  <div *ngIf="selectedMenuItem | fn : asLinkMenuItem as externalMenuItem">
    <c-field header="Link">
      <input pInputText [(ngModel)]="externalMenuItem.local[language].link" placeholder="https://... or /events/eventId" />
    </c-field>
  </div>

  <div *ngIf="selectedMenuItem | fn : asPageMenuItem as pageMenuItem">
    <c-field header="Page">
      <c-page-select [(value)]="pageMenuItem.page" style="background: white"></c-page-select>
    </c-field>
  </div>

  <div *ngIf="selectedMenuItem | fn : asDialogMenuItem as dialogMenuItem">
    <c-field header="Dialog">
      <input pInputText [(ngModel)]="dialogMenuItem.dialog" placeholder="Name of the dialog" />
    </c-field>
  </div>

  <div *ngIf="selectedMenuItem | fn : asAppFunctionMenuItem as appFunctionMenuItem">
    <c-field header="Action">
      <input pInputText [(ngModel)]="appFunctionMenuItem.appFunction" placeholder="Name of the app function" />
    </c-field>
  </div>

  <c-field header="Read Access">
    <c-access-policy-edit [(accessPolicies)]="selectedMenuItem.readAccess"></c-access-policy-edit>
  </c-field>

  <c-field header="Execute Access">
    <c-access-policy-edit [(accessPolicies)]="selectedMenuItem.executeAccess"></c-access-policy-edit>
  </c-field>
</div>
