import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { EventsService } from 'src/app/services/events/events.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { EventType } from 'src/common/entities/EventType';

@Component({
  selector: 'app-event-type-configuration',
  templateUrl: './event-type-configuration.component.html',
  styleUrls: ['./event-type-configuration.component.scss'],
})
export class EventTypeConfigurationComponent implements OnInit, OnDestroy {
  eventTypeId: string;

  eventTypeConfig: EventType;
  initialEventTypeConfig: EventType = {
    _id: '',
    internalName: 'NewEventType',
    local: { en: { title: 'New event Type' } },
  };

  loading: boolean = false;
  saving: boolean = false;

  currentLanguage: string = null;

  private _ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private eventsService: EventsService, private utilsService: UtilsService) {}

  ngOnDestroy(): void {
    // Unsubscribe subscriptions
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  async ngOnInit(): Promise<void> {
    this.currentLanguage = this.utilsService.currentLanguage;
    this.eventTypeId = await this.activatedRoute.snapshot.params.eventTypeId;
    await this.load();
  }

  async load() {
    this.loading = true;

    // Read requested lookup from route params
    if (this.eventTypeId && this.eventTypeId !== '') {
      if (this.eventTypeId === 'new') {
        this.eventTypeConfig = this.initialEventTypeConfig;
      } else {
        try {
          const result = (await this.eventsService.getEventType(this.eventTypeId)) as EventType;
          this.eventTypeConfig = result;
        } catch (err) {
          throw err;
        }
      }
      this.loading = false;
    }
  }

  onLanguageAdded(language: string) {
    if (!this.eventTypeConfig.local[language]) {
      this.eventTypeConfig.local[language] = {
        title: 'New event Type',
      };
    }
  }

  async save() {
    this.saving = true;
    let eventTypConfigResult = null;
    try {
      if (this.eventTypeId === 'new') {
        eventTypConfigResult = await this.eventsService.createEventType(this.eventTypeConfig);
      } else {
        eventTypConfigResult = await this.eventsService.updateEventType(this.eventTypeConfig);
      }
      if (eventTypConfigResult) {
        this.router.navigate(['/configuration/types']);
      }
    } catch (err) {
      console.error(err);
    }
    this.saving = false;
  }
}
