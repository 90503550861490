import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { SidebarService } from 'src/app/services/sidebar/sidebar';
import { Product } from 'src/common/entities/Product';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'c-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.scss'],
})
export class ProductSelectComponent implements OnChanges {
  @Input()
  id: string;

  @Output()
  idChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  disabled = false;

  product: Product;

  showSearch = false;

  constructor(private productsService: ProductsService, private sidebarService: SidebarService) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.id?.currentValue && changes.id.currentValue !== changes.id.previousValue) {
      this.product = null;
      this.product = await this.productsService.getProduct(this.id);
    } else if (!this.id) {
      this.product = null;
    }
  }

  selectProduct(product: Product) {
    this.showSearch = false;
    this.product = product;
    this.id = this.product._id;
    this.idChange.emit(this.id);
  }

  removeProduct() {
    this.id = null;
    this.idChange.emit(this.id);
  }

  openSearch() {
    this.showSearch = true;
  }
}
