<div class="container" #container>
  <div class="background" [ngStyle]="backgroundStyle">
    <div class="markers">
      <div
        *ngFor="let marker of markers; trackBy: trackByMarker"
        class="marker"
        [ngStyle]="markerStyle(marker)"
        [class.moving]="marker === moveMarker?.marker"
        (mousedown)="markerDown($event, marker)"
        (mouseenter)="markerEnter($event, marker)"
        (mouseleave)="markerLeave($event, marker)"
        (click)="clickMarker(marker)"
        (dblclick)="selectMarker(marker)"
      >
        <div *ngIf="markerIcon(marker) as mi">
          <div class="marker-icon" [ngStyle]="markerIconStyle(marker, mi)"></div>
        </div>
        <div *ngIf="!marker.markerIcon || !markerIcon(marker).icon" class="default-marker"></div>
      </div>
    </div>
  </div>
</div>
