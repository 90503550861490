import { FileUpload } from 'primeng/fileupload';
import { Inputs } from './../../../../common/inputs/Inputs';
import { InteractionConfiguration } from './../../../../common/entities/Interaction';
import { ConfigurationService } from './../../../services/configuration/configuration.service';
import { Factory } from './../../../../common/factories/Factory';
import { TableOptions } from 'src/app/components/table/table.interfaces';
import { TableComponent } from 'src/app/components/table/table.component';
import { Tab } from 'src/app/tabs/classes/tab';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { ActivatedRoute } from '@angular/router';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-interaction',
  templateUrl: './interaction.component.html',
  styleUrls: ['./interaction.component.scss'],
})
export class InteractionComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('interactionTable') interactionTable: TableComponent<any>;

  domainCollectionId: string = null;
  tab: Tab;
  interactionConfiguration: InteractionConfiguration = null;
  interactionTableOptions: TableOptions<any>;
  languages: string[];
  currentLanguage: string;
  working = false;
  edit_mode: boolean = false;
  lottieError: boolean = false;

  quickReactionIndex = -1;

  get showEditQuickReactions(): boolean {
    return this.quickReactionIndex >= 0;
  }

  set showEditQuickReactions(val: boolean) {
    if (!val) {
      this.quickReactionIndex = -1;
    }
  }

  @ViewChild('fileUploadComponent') primeFileUpload: FileUpload;

  inputs: Inputs = {
    '$.quickReactions[$index].internalName': {
      type: 'text',
    },
    '$.quickReactions[$index].enabled': {
      type: 'switch',
      header: 'Enabled',
    },
    '$.quickReactions[$index].animationJSON': {},
    '$.quickReactions[$index].local': {
      factory: async () => ({}),
    },
    '$.quickReactions[$index].local.$language': {
      factory: async (obj: any, jsonpathParams: { [key: string]: any }) => {
        return Factory.configuration().quickReactionLocal({});
      },
    },
    '$.quickReactions[$index].local.$language.label': {},
  };

  get jsonPathes(): string[] {
    return Object.keys(this.inputs);
  }

  constructor(
    private tabsService: TabsService,
    private activatedRoute: ActivatedRoute,
    private languagesService: LanguagesService,
    private collaborationService: CollaborationService,
    private configurationService: ConfigurationService
  ) {
    this.tab = this.tabsService.register({
      category: 'configuration',
      route: this.activatedRoute.snapshot,
      loading: true,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.collaborationService.registerLocal(`interactionconfiguration`, this.interactionConfiguration, true);
  }

  async fileUpload($event) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.interactionConfiguration.quickReactions[this.quickReactionIndex].animationJSON = reader.result as string;
      this.updateAnimation(this.quickReactionIndex);
      this.primeFileUpload.clear();
    };
    reader.readAsText($event.files[0]);
  }

  async ngOnInit() {
    this.tab.loading = true;

    this.languages = (await this.languagesService.getLanguages())?.items.filter((i) => i.selectable).map((i) => i.language);

    const language = this.activatedRoute.snapshot.queryParams.language || 'en';

    this.currentLanguage = this.languages.includes(language) ? language : this.languages[0];

    this.interactionTableOptions = {
      columns: [{ header: 'Internal Name' }, { header: 'Enabled' }, { header: 'Move' }],
    };

    this.collaborationService.registerLocal(`interactionconfiguration`, this.interactionConfiguration, true);

    this.tab.loading = false;
  }

  async loadDialog(domainCollectionId: string) {
    this.domainCollectionId = domainCollectionId;
    const c = await this.configurationService.getConfigurationByKeyAndDomain('interaction', this.domainCollectionId);
    this.interactionConfiguration = await Factory.configuration().interactionConfiguration(c);

    this.ensureLanguages(this.languages);
    this.collaborationService.registerLocal(`interactionconfiguration`, this.interactionConfiguration, true);

    this.tab.loading = false;
  }

  ngOnDestroy() {
    this.collaborationService.unregisterLocal(`interactionconfiguration`);
  }

  async interactionUp(index: number) {
    const element = this.interactionConfiguration.quickReactions[index];
    this.interactionConfiguration.quickReactions.splice(index, 1);
    this.interactionConfiguration.quickReactions.splice(index - 1, 0, element);
    this.interactionTable.refresh(false);
  }

  async interactionDown(index: number) {
    const element = this.interactionConfiguration.quickReactions[index];
    this.interactionConfiguration.quickReactions.splice(index, 1);
    this.interactionConfiguration.quickReactions.splice(index + 1, 0, element);
    this.interactionTable.refresh(false);
  }

  ensureLanguages(languages: string[]) {
    for (const language of languages) {
      for (const qr of this.interactionConfiguration.quickReactions) {
        if (!qr.local[language]) {
          qr.local[language] = Factory.configuration().quickReactionLocal({});
        }
      }
    }
  }

  async addReaction() {
    this.edit_mode = false;
    this.interactionConfiguration.quickReactions.push(await Factory.configuration().quickReaction({}));
    this.quickReactionIndex = this.interactionConfiguration.quickReactions.length - 1;
    this.interactionTable.refresh(false);
  }

  deleteReaction(index: number) {
    if (index >= 0) {
      this.interactionConfiguration.quickReactions.splice(index, 1);
      this.interactionTable.refresh(false);
    }
  }

  async save() {
    this.working = true;

    try {
      this.interactionConfiguration = (await this.configurationService.saveInteractionConfiguration(this.interactionConfiguration, this.interactionConfiguration._id, true, this.domainCollectionId))
        .value as InteractionConfiguration;
    } catch (err) {
      console.error(err);
    }
    this.collaborationService.registerLocal(`interactionconfiguration`, this.interactionConfiguration, true);
    this.working = false;
  }

  createAnmation = {
    animationData: {},
  };

  updateAnimation(index) {
    this.createAnmation = {
      ...this.createAnmation,
      animationData: JSON.parse(this.interactionConfiguration.quickReactions[index].animationJSON),
    };
  }

  dropped($event): any {
    $event[0].fileEntry.file((file) => {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.interactionConfiguration.quickReactions[this.quickReactionIndex].animationJSON = reader.result as string;
        this.updateAnimation(this.quickReactionIndex);
      };
      reader.readAsText(file);
    });
  }

  removeLottie() {
    this.interactionConfiguration.quickReactions[this.quickReactionIndex].animationJSON = null;
    this.updateAnimation(this.quickReactionIndex);
  }

  downloadLottie() {
    let blob: any = new Blob([this.interactionConfiguration.quickReactions[this.quickReactionIndex].animationJSON], { type: 'text/json; charset=utf-8' });
    fileSaver.saveAs(
      blob,
      this.interactionConfiguration.quickReactions[this.quickReactionIndex].internalName ? `${this.interactionConfiguration.quickReactions[this.quickReactionIndex].internalName}.json` : `unnamed.json`
    );
  }

  tableItemSelect(data: any) {
    this.quickReactionIndex = data;
    this.updateAnimation(data);
    this.edit_mode = true;
  }
}
