import { PagesService } from 'src/app/services/pages/pages.service';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { EventsService } from 'src/app/services/events/events.service';
import { MapsService } from 'src/app/services/maps/maps.service';
import { format } from 'src/lib/diff-formater/htmlFormatter';

@Component({
  selector: 'app-version-diff',
  templateUrl: './version-diff.component.html',
  styleUrls: ['./version-diff.component.scss'],
})
export class VersionDiffComponent implements OnChanges {
  @Input()
  show: boolean = false;

  @Input()
  eventId: string = null;

  @Input()
  mapId: string = null;

  @Input()
  pageId: string = null;

  @Input()
  type: 'map' | 'event' | 'page';

  @Input()
  version: string;

  firstVersion: { name: number; code: string };
  secondVersion: { name: number; code: string };

  ev: { name: number; code: string }[];

  @Output()
  showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  innerDiv = '';

  constructor(private eventsService: EventsService, private mapService: MapsService, private pagesService: PagesService) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.show.currentValue) {
      this.innerDiv = '';
      if (this.type === 'event') {
        this.ev = await this.eventsService
          .getEventVersions(this.eventId, {
            limit: 0,
            orderBy: 'version',
            orderDirection: -1,
          })
          .then((result) =>
            result.items.map((item) => {
              return { name: item.version, code: item._id };
            })
          );
      }

      if (this.type === 'map') {
        this.ev = await this.mapService
          .getMapVersions(this.mapId, {
            limit: 0,
            orderBy: 'version',
            orderDirection: -1,
          })
          .then((result) =>
            result.items.map((item) => {
              return { name: item.version, code: item._id };
            })
          );
      }

      if (this.type === 'page') {
        this.ev = await this.pagesService
          .getPageVersions(this.pageId, {
            limit: 0,
            orderBy: 'version',
            orderDirection: -1,
          })
          .then((result) =>
            result.items.map((item) => {
              return { name: item.version, code: item._id };
            })
          );
      }

      let index = this.ev.findIndex((item) => item.code === this.version);
      if (index !== -1 && this.ev[index + 1]) {
        this.firstVersion = this.ev[index + 1];
        this.secondVersion = this.ev[index];
        let result = null;
        if (this.type === 'event') {
          result = await this.eventsService.getDiff(this.ev[index + 1].code, this.ev[index].code);
        }
        if (this.type === 'map') {
          result = await this.mapService.getDiff(this.ev[index + 1].code, this.ev[index].code);
        }
        if (this.type === 'page') {
          result = await this.pagesService.getDiff(this.ev[index + 1].code, this.ev[index].code);
        }
        const diff = format(result.diff, result.version);
        this.innerDiv = diff;
      }
    }
  }

  async compare() {
    let result = null;
    if (this.type === 'event') {
      result = await this.eventsService.getDiff(this.firstVersion.code, this.secondVersion.code);
    }
    if (this.type === 'map') {
      result = await this.mapService.getDiff(this.firstVersion.code, this.secondVersion.code);
    }
    if (this.type === 'page') {
      result = await this.pagesService.getDiff(this.firstVersion.code, this.secondVersion.code);
    }

    const diff = format(result.diff, result.version);
    this.innerDiv = diff;
  }
}
