<p-button styleClass="p-button-text" icon="pi icon-ic-add-item" [disabled]="disabled" (onClick)="openEventRegistrationDialog()" [label]="'New Event Registration'"></p-button>

<p-dialog [(visible)]="showEventRegistrationDialog" [modal]="true" [appendTo]="'body'" [header]="'New Event Registration'" [contentStyle]="{ overflow: 'visible', width: '50vw' }">
  <div *ngIf="showEventRegistrationDialog && eventRegistrationsResponse.length === 0">
    <div class="input-group">
      <div class="input">
        <textarea [(ngModel)]="users" [disabled]="working"></textarea>
      </div>
      <div class="label">Users</div>
      <div class="description">Comma seperated list of email addresses</div>
    </div>
    <c-app-event-ticket-selector [(ngModel)]="eventRegistrationData" [event]="event" ngDefaultControl></c-app-event-ticket-selector>
    <div class="input-group switch">
      <div class="input">
        <p-checkbox [disabled]="working" [(ngModel)]="sendEmail" [binary]="true"></p-checkbox>
      </div>
      <div class="label">Send confirmation mail to user</div>
    </div>

    <p-footer>
      <div class="text-right">
        <p-button [label]="'GENERAL_SAVE' | translate" (onClick)="save()" [disabled]="working || !eventRegistrationData.eventTicket"></p-button>
      </div>
    </p-footer>
  </div>

  <div class="result" *ngIf="eventRegistrationsResponse.length > 0">
    <c-table [options]="resultTableOptions" [singleTemplate]="true" [data]="eventRegistrationsResponse">
      <tr *ngFor="let eventRegistration of eventRegistrationsResponse">
        <td>
          <span *ngIf="asUser(eventRegistration.user) as user">{{ user.emailLower }}</span>
          <span *ngIf="!asUser(eventRegistration.user)">{{ eventRegistration.user }}</span>
        </td>
        <td>
          <span *ngIf="asUser(eventRegistration.user) as user">{{ user.displayName }}</span>
          <span class="unknown" *ngIf="!asUser(eventRegistration.user)">{{ 'GENERAL_UNKNOWN' | translate }}</span>
        </td>
        <td>
          <span class="successful" *ngIf="eventRegistration.eventRegistration"> <i class="pi pi-check-circle"></i> Successful </span>
          <span class="error" *ngIf="eventRegistration.error"> <i class="pi pi-times-circle"></i> Error </span>
        </td>
      </tr>
    </c-table>
  </div>
</p-dialog>
