import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';

import { CustomField } from 'src/common/entities/CustomField';
import { GetCustomFieldQuery, GetCustomFieldResponse } from 'src/common/api/v1/configuration/customFields/GetCustomField';
import { GetCustomFieldsQuery, GetCustomFieldsResponse } from 'src/common/api/v1/configuration/customFields/GetCustomFields';
import { PostCustomFieldBody, PostCustomFieldQuery, PostCustomFieldResponse } from 'src/common/api/v1/configuration/customFields/PostCustomField';
import { PostCustomFieldsBody, PostCustomFieldsQuery, PostCustomFieldsResponse } from 'src/common/api/v1/configuration/customFields/PostCustomFields';

@Injectable({
  providedIn: 'root',
})
export class CustomFieldsService {
  constructor(private apiService: ApiService) {}

  getCustomField(id: string): Promise<CustomField> {
    return this.apiService.get<GetCustomFieldQuery, GetCustomFieldResponse>(`/api/v1/configuration/customfields/${id}`).toPromise();
  }

  getCustomFields(query?: GetCustomFieldsQuery): Promise<GetCustomFieldsResponse> {
    return this.apiService
      .get<GetCustomFieldsQuery, GetCustomFieldsResponse>('/api/v1/configuration/customfields', {
        limit: 150,
        skip: 0,
        ...query,
      })
      .toPromise();
  }

  createCustomField(customField: CustomField): Promise<CustomField> {
    return this.apiService.post<PostCustomFieldsQuery, PostCustomFieldsBody, PostCustomFieldsResponse>(`/api/v1/configuration/customfields`, customField).toPromise();
  }

  updateCustomField(customField: CustomField): Promise<CustomField> {
    return this.apiService.post<PostCustomFieldQuery, PostCustomFieldBody, PostCustomFieldResponse>(`/api/v1/configuration/customfields/${customField._id}`, customField).toPromise();
  }
}
