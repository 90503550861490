import { backgroundThemes } from '../../entities/PageModule';

export const commonPageModuleInputs = (pageModuleType: string) => ({
  [`$.pageModules[?(@.pageModuleType=='${pageModuleType}' && @._id=='$pageModuleId')].backgroundTheme`]: {
    type: 'dropdown',
    dropdownOptions: [...backgroundThemes].map(a => ({ label: a, value: a }))
  },
  [`$.pageModules[?(@.pageModuleType=='${pageModuleType}' && @._id=='$pageModuleId')].accessPolicy`]: {
    type: 'accesspolicy'
  },
  [`$.pageModules[?(@.pageModuleType=='${pageModuleType}' && @._id=='$pageModuleId')].anchor`]: {
    type: 'text'
  },
  [`$.pageModules[?(@.pageModuleType=='${pageModuleType}' && @._id=='$pageModuleId')].paddingTop`]: {
    type: 'switch'
  },
  [`$.pageModules[?(@.pageModuleType=='${pageModuleType}' && @._id=='$pageModuleId')].paddingBottom`]: {
    type: 'switch'
  },
  [`$.pageModules[?(@.pageModuleType=='${pageModuleType}' && @._id=='$pageModuleId')].paddingLeft`]: {
    type: 'switch'
  },
  [`$.pageModules[?(@.pageModuleType=='${pageModuleType}' && @._id=='$pageModuleId')].paddingRight`]: {
    type: 'switch'
  }
});
