import { Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

import { AssetsService, AssetUpload } from 'src/app/services/assets/assets.service';
import { Asset, ImageAsset, isImageAsset } from 'src/common/entities/Asset';
import { SidebarService } from 'src/app/services/sidebar/sidebar';

@Component({
  selector: 'c-asset-select',
  templateUrl: './asset-select.component.html',
  styleUrls: ['./asset-select.component.scss'],
})
export class AssetSelectComponent implements OnChanges, OnDestroy {
  @Input()
  id: string;

  @Output()
  idChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  disabled = false;

  @Input()
  previewOnly = false;

  @Input()
  minWidth: number;

  @Input()
  minHeight: number;

  @Input()
  width: number;

  @Input()
  height: number;

  @Input()
  showDeleteButton?: boolean = true;

  @Input()
  position: 'left' | 'right' = 'right' 

  private _mimeType: string[];
  @Input()
  set mimeType(val: string | string[]) {
    this._mimeType = typeof val === 'string' ? [val] : val || [];
  }

  get mimeTypes(): string[] {
    return this._mimeType || [];
  }

  @Input()
  @HostBinding('style.width')
  inputWidth: string = '100%';

  asset: Asset;
  assetUpload: AssetUpload;
  assetSubscription: Subscription;

  assetInvalid = false;
  showError = false;
  showSearch = false;

  constructor(private assetsService: AssetsService, private sidebarService: SidebarService) {}

  ngOnDestroy() {
    this.assetSubscription?.unsubscribe();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.id?.currentValue && changes.id.currentValue !== changes.id.previousValue) {
      this._subscribe();
    } else if (!this.id) {
      this.assetSubscription?.unsubscribe();
      this.asset = null;
    }
  }

  private _subscribe() {
    this.assetSubscription?.unsubscribe();

    this.assetSubscription = this.assetsService.getAsset(this.id).subscribe(
      (asset) => {
        if (!asset) {
          this.asset = null;
          this.assetInvalid = true;
        } else {
          this.asset = asset;
          this.assetInvalid = false;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  upload(file: File) {
    this.assetUpload = {
      file,
      internalName: file.name.split('.').slice(0, -1).join('.'),
    };

    this.assetsService.uploadAsset(this.assetUpload).subscribe((data) => {
      if (data.response) {
        this.selectAsset(data.response);
      }
    });
  }

  public dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.type == 'application/pdf' || file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type == 'application/vnd.ms-excel') {
            this.upload(file);
          } else {
            const img = document.createElement('img');
            img.src = URL.createObjectURL(file);
            img.onload = (e) => {
              if (!this.validFile(file, img.width, img.height)) {
                this.showError = true;
              } else {
                this.upload(file);
              }
            };
          }
        });
      }
    }
  }

  validFile(file: File, width: number, height: number): boolean {
    for (const mimeType of this.mimeTypes) {
      if (mimeType.includes('*')) {
        if (!new RegExp(`^${mimeType.replace('*', '.+')}$`).test(file.type)) {
          return false;
        }
      } else if (mimeType !== file.type) {
        return false;
      }
    }

    if (this.width && this.width !== width) {
      return false;
    }
    if (this.height && this.height !== height) {
      return false;
    }

    if (this.minWidth && this.minWidth < width) {
      return false;
    }
    if (this.minHeight && this.minHeight < height) {
      return false;
    }

    return true;
  }

  asImageAsset(): ImageAsset {
    return isImageAsset(this.asset) ? (this.asset as ImageAsset) : null;
  }

  selectAsset(asset: Asset) {
    this.showSearch = false;
    this.asset = asset;
    this.id = this.asset._id;
    this.idChange.emit(this.id);
  }

  removeAsset() {
    this.id = null;
    this.idChange.emit(this.id);
  }

  openSearch() {
    this.showSearch = true;
  }

}
