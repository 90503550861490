import { NgModule } from '@angular/core';
import { PageModuleEditComponent } from './page-module-edit/page-module-edit.component';
import { PageModuleEditModule } from './page-module-edit/page-module-edit.module';

@NgModule({
  declarations: [],
  imports: [PageModuleEditModule],
  exports: [PageModuleEditComponent],
})
export class PageModulesModule {}
