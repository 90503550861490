import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { Language } from 'src/common/entities/Language';

@Component({
  selector: 'c-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent implements OnInit, OnDestroy {
  @Input()
  object: any;

  @Output()
  languageAdded: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  currentLanguageChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  currentLanguage: string = null;

  @Input()
  localProperty: string = 'local';

  @Input()
  invalidLanguages: string[] = [];

  @Input()
  skeleton: boolean = false;

  @Input()
  languageFilter: string[];

  activeLanguages: Language[] = [];

  subscriptions: Subscription[] = [];

  deleteDisabled = false;

  get selectedLanguages(): string[] {
    return this.object[this.localProperty] ? Object.keys(this.object[this.localProperty]) : [];
  }

  constructor(private languagesService: LanguagesService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params.language && params.language !== this.currentLanguage) {
          this.currentLanguage = params.language;
          this.currentLanguageChange.emit(this.currentLanguage);
        }
      })
    );
  }

  ngOnInit(): void {
    this.currentLanguage = this.object && this.object[this.localProperty] && Object.keys(this.object[this.localProperty]) ? Object.keys(this.object[this.localProperty])[0] : null;
    this.subscriptions.push(
      this.languagesService.getActiveLanguages().subscribe((languages) => {
        this.activeLanguages = languages.filter((l) => !this.languageFilter || this.languageFilter.includes(l.language));
      })
    );

    this.deleteDisabled = this.selectedLanguages.length <= 1;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  deleteLocal(language: string) {
    delete this.object[this.localProperty][language];

    if (language === this.currentLanguage) {
      if (this.object[this.localProperty] && Object.keys(this.object[this.localProperty]).length > 0) {
        this.currentLanguage = Object.keys(this.object[this.localProperty])[0];
      } else {
        this.currentLanguage = null;
      }

      this.deleteDisabled = this.selectedLanguages.length <= 1;

      this.currentLanguageChange.emit(this.currentLanguage);
    }
  }

  selectedItemsChange(languages) {
    const currentLanguages = Object.keys(this.object[this.localProperty]);

    for (const language of languages.filter((l) => !currentLanguages.includes(l))) {
      this.languageAdded.emit(language);
    }

    this.deleteDisabled = this.selectedLanguages.length <= 1;
  }

  currentItemChange() {
    this.router.navigate([], {
      queryParams: {
        language: this.currentLanguage,
      },
      queryParamsHandling: 'merge',
    });
    this.currentLanguageChange.emit(this.currentLanguage);
  }
}
