<div class="sidebar-tabs">
  <button [class.active]="tab === 'general'" (click)="tab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
  <button [class.active]="tab === 'ctalist'" (click)="tab = 'ctalist'">{{ 'GENERAL_CTA_LIST' | translate }}</button>
  <button [class.active]="tab === 'ctalistInteraction'" (click)="tab = 'ctalistInteraction'">{{ 'GENERAL_CTA_LIST_INTERACTION' | translate }}</button>
  <button [class.active]="tab === 'design'" (click)="tab = 'design'">{{ 'GENERAL_DESIGN' | translate }}</button>
  <button [class.active]="tab === 'accesspolicy'" (click)="tab = 'accesspolicy'">{{ 'GENERAL_CONDITIONS' | translate }}</button>
</div>

<div *ngIf="tab == 'general'" class="sidebar-body">
  <c-page-patch-input *ngFor="let inputJsonpath of inputJsonpathes" [jsonpath]="[jsonpath, inputJsonpath]" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'design'" class="sidebar-body">
  <c-page-patch-input *ngFor="let designJsonpath of designJsonpathes" [jsonpath]="[jsonpath, designJsonpath]" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'ctalist'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.ctalist']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'ctalistInteraction'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.ctalistInteraction']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>
<div *ngIf="tab == 'accesspolicy'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.accessPolicy']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page" [inputConfiguration]="accessInputConfiguration"></c-page-patch-input>
</div>
