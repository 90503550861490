import { Factory } from 'src/common/factories/Factory';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { GetRBACsResponse } from './../../../common/api/v1/adminusers/GetRBACs';
import { IRBAC } from './../../../common/entities/RBAC';
import { RBACService } from './../../services/rbac/rbac.service';
import { from, Observable } from 'rxjs';
import { AdminUsersService } from 'src/app/services/admin-users/admin-users.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

export const typ = ['adminUser', 'group'] as const;
export type typRole = (typeof typ)[number];

export interface ResultList {
  _id: string;
  name: string;
  typ: typRole;
}

interface Roles {
  name: string;
  code: string;
}

@Component({
  selector: 'c-rbac',
  templateUrl: './rights-management.component.html',
  styleUrls: ['./rights-management.component.scss'],
})
export class RightsManagementComponent implements OnInit, OnChanges {
  constructor(private adminUsersService: AdminUsersService, private rbacService: RBACService, private configurationService: ConfigurationService) {}

  @Input()
  resource: string;

  results: string[];

  rbacs$: Observable<GetRBACsResponse>;

  filtredResultList: ResultList[];
  resultList: ResultList[];

  optionRoles: Roles[] = [];
  selectedRights: Roles[] = [];
  selectedUserOrGroup: ResultList;
  rbacId: string = null;

  async ngOnInit() {
    this.filterList();
    this.clearModel();
    this.optionRoles = await this.getRoles();
  }

  async filterList() {
    const resultList1 = (await this.configurationService.getGroups()).items.map((r) => ({ _id: r._id, name: 'Group: ' + r.name, typ: 'group' } as ResultList));
    const resultList2 = (await this.adminUsersService.getAdminUsers()).items.map((r) => ({ _id: r._id, name: 'User: ' + r.displayName, typ: 'adminUser' } as ResultList));
    this.resultList = [...resultList1, ...resultList2];
  }

  async getRoles(): Promise<Roles[]> {
    return (await this.configurationService.getRoles()).items.map((r) => ({ name: r.name, code: r._id }));
  }

  searchAutoComplete(event) {
    let filtered: ResultList[] = [];
    let query = event.query;
    for (let i = 0; i < this.resultList.length; i++) {
      let item = this.resultList[i];
      if (item.name.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(item);
      }
    }
    this.filtredResultList = filtered;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'resource': {
            if (this.resource) this.loadRBACs(this.resource);
          }
        }
      }
    }
  }

  onDelete(rbac) {
    this.rbacService.deleteRBAC(rbac);
    this.loadRBACs(this.resource);
  }

  async onEdit(rbac) {
    if (rbac.adminUser) {
      this.selectedUserOrGroup = this.resultList.find((item) => item._id === rbac.adminUser._id);
    }
    if (rbac.group) {
      this.selectedUserOrGroup = this.resultList.find((item) => item._id === rbac.group._id);
    }

    this.selectedRights = rbac.roles;
    this.rbacId = rbac._id;
  }

  async onAdd() {
    const typ = this.resultList.find((item) => item._id === this.selectedUserOrGroup._id).typ;

    let typValue = null;
    if (typ === 'group') {
      typValue = { group: this.selectedUserOrGroup._id };
    } else {
      typValue = { adminUser: this.selectedUserOrGroup._id };
    }

    const rbac: IRBAC = await Factory.rbac().createRBAC({ _id: this.rbacId, roles: this.selectedRights, ...typValue });
    const result: IRBAC = await this.rbacService.postRBAC(rbac, this.resource);

    this.loadRBACs(this.resource);

    if (result) {
      this.clearModel();
    }

    this.loadRBACs(this.resource);
  }

  clearModel() {
    this.selectedUserOrGroup = {} as ResultList;
    this.selectedRights = [];
    this.rbacId = null;
  }

  async loadRBACs(resource: string) {
    this.rbacs$ = from(this.rbacService.getRBACByResource(resource));
    this.rbacs$.subscribe((t) => {});
  }

  valid(): boolean {
    if (this.selectedRights.length > 0 && this.selectedUserOrGroup._id) {
      return false;
    } else {
      return true;
    }
  }
}
