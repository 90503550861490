import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { PageModuleType, pageModuleTypeGlobalPageFilter, pageModuleTypes } from 'src/common/entities/PageModule';
import { IPlugin } from 'src/common/plugin/IPlugin';

export interface PageModuleItem {
  pageModuleType: PageModuleType;
  label: string;
  image: string;
  description: string;
  customPageModuleType?: string;
  plugin?: IPlugin;
}

@Component({
  selector: 'c-page-module-type-select',
  templateUrl: './page-module-type-select.component.html',
  styleUrls: ['./page-module-type-select.component.scss'],
})
export class PageModuleTypeSelectComponent implements OnInit {
  @Input()
  isEventPage = false;

  @Output()
  selected: EventEmitter<PageModuleItem> = new EventEmitter<PageModuleItem>();

  @Output()
  onClose = new EventEmitter<boolean>();

  pageModules: PageModuleItem[] = [];
  filteredPageModules: PageModuleItem[] = [];
  filterText = '';

  constructor(
    private utilsService: UtilsService,
    private configurationService: ConfigurationService,
  ) {}

  async label(pageModuleType: PageModuleType): Promise<string> {
    const key = `GENERAL_PAGEMODULETYPE_${pageModuleType.toUpperCase()}`;
    const translation = await this.utilsService.translate(key);
    return key === translation ? pageModuleType : translation;
  }

  async description(pageModuleType: PageModuleType): Promise<string> {
    const key = `GENERAL_PAGEMODULETYPE_${pageModuleType.toUpperCase()}_DESCRIPTION`;
    const translation = await this.utilsService.translate(key);
    return key === translation ? '' : translation;
  }

  async ngOnInit(): Promise<void> {
    // TODO Product Backlog Item 77038: Adminportal - Hide 'Standard Page' and Page module 'Video Slider'
    const pmTypes = pageModuleTypes.filter((item) => item !== 'VideoSlider')

    const labels = await Promise.all(pmTypes.map((p) => this.label(p)));
    const descriptions = await Promise.all(pmTypes.map((p) => this.description(p)));

    this.pageModules = pmTypes
      .map((p, i) => ({
        pageModuleType: p,
        label: labels[i],
        description: descriptions[i],
        image: `/assets/images/pagemodules/${p.toLowerCase()}_icon.svg`,
      }));

    const customPageModuleTypes = await this.configurationService.customPageModuleTypes()

    this.pageModules.push(...customPageModuleTypes.map(c => ({
      pageModuleType: 'Custom',
      customPageModuleType: c.customPageModuleType,
      label: c.customPageModuleType,
      description: c.customPageModuleType,
      image: '',
      plugin: c.plugin,
    }) as PageModuleItem))

    this.filteredPageModules = this.pageModules.filter((p) => this.isEventPage || pageModuleTypeGlobalPageFilter.indexOf(p.pageModuleType) === -1);
  }

  filter(): void {
    this.filteredPageModules = this.pageModules.filter((p) => {
      return p.label.toLowerCase().includes(this.filterText.toLowerCase()) || p.description.toLowerCase().includes(this.filterText.toLowerCase());
    });
  }

  async select(pageModuleItem: PageModuleItem): Promise<void> {
    this.selected.emit(pageModuleItem);
  }
}
