import { PostCustomDataQuery, PostCustomDataResponse } from './../../../common/api/v1/customdata/PostCustomData';
import { Injectable } from '@angular/core';
import { GetCustomDataQuery, GetCustomDataResponse } from 'src/common/api/v1/customdata/GetCustomData';
import { GetCustomDataListQuery, GetCustomDataListResponse } from 'src/common/api/v1/customdata/GetCustomDataList';
import { CustomData } from 'src/common/entities/CustomData';
import { ApiService, Upload } from '../api/api.service';
import { GetCustomDataEntriesQuery, GetCustomDataEntriesResponse } from 'src/common/api/v1/customdata/GetCustomDataEntries';

@Injectable({
  providedIn: 'root',
})
export class CustomDataService {
  constructor(private apiService: ApiService) {}

  getCustomDataEntries(id: string, query: any): Promise<any> {
    return this.apiService.get<GetCustomDataEntriesQuery, GetCustomDataEntriesResponse>(`/api/v1/customdata/customdataentries/${id}`, { ...query }).toPromise();
  }

  uploadCustomDataCSV(file, internalName, code): Promise<Upload<PostCustomDataResponse>> {
    return this.apiService.upload<PostCustomDataQuery, PostCustomDataResponse>(`/api/v1/customdata/upload/${internalName}`, file, {}, { code: code }).toPromise();
  }

  getCustomData(id: string): Promise<CustomData>;
  getCustomData(query: GetCustomDataListQuery): Promise<GetCustomDataListResponse>;
  getCustomData(query: GetCustomDataListQuery | string) {
    if (typeof query === 'string') {
      return this.apiService.get<GetCustomDataQuery, GetCustomDataResponse>(`/api/v1/customdata/${query}`).toPromise();
    } else {
      return this.apiService
        .get<GetCustomDataListQuery, GetCustomDataListResponse>('/api/v1/customdata', {
          limit: 50,
          skip: 0,
          ...query,
        })
        .toPromise();
    }
  }
}
