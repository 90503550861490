import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { RegistrationCustomField } from '../entities/EventVersion';

export class RegistrationCustomFieldFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  createCustomField(values?: any): RegistrationCustomField {
    return {
      customField: values?.customField || '',
      required: typeof values?.required === 'boolean' ? values?.required : true,
      eventTickets: values?.eventTickets || null,
    };
  }
}
