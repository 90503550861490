import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { Stage } from '../entities/Stage';

export class StageFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createStage(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      mapLink: values?.mapLink || '',
      local: this.localKeys(values?.local).reduce((a, b) => ({ ...a, [b]: this.createStageLocal(values.local[b]) }), {}),
    } as Stage;
  }

  createStageLocal(values?: any) {
    return {
      title: values?.title || '',
    };
  }
}
