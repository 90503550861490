<div class="speaker-item">
  <div class="speaker-preview">
    <c-asset-preview [asset]="asset"></c-asset-preview>
  </div>
  <div class="speaker-info">
    <h1>{{ displayName }}</h1>
    <div class="details">
      <div>Title: {{ title }}</div>
    </div>
  </div>
</div>
