import {
  PostEmailTemplateConfigurationBody,
  PostEmailTemplateConfigurationQuery,
  PostEmailTemplateConfigurationResponse,
} from './../../../common/api/v1/configuration/emailTemplateConfigurations/PostEmailTemplateConfiguration';
import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';

import { EmailTemplateConfiguration } from 'src/common/entities/EmailTemplateConfiguration';
import { GetEmailTemplateConfigurationQuery, GetEmailTemplateConfigurationResponse } from 'src/common/api/v1/configuration/emailTemplateConfigurations/GetEmailTemplateConfiguration';
import { GetEmailTemplateConfigurationsQuery, GetEmailTemplateConfigurationsResponse } from 'src/common/api/v1/configuration/emailTemplateConfigurations/GetEmailTemplateConfigurations';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplateConfigurationsService {
  constructor(private apiService: ApiService) {}

  getEmailTemplateConfiguration(emailTemplateConfigurationId: string): Promise<EmailTemplateConfiguration> {
    return this.apiService
      .get<GetEmailTemplateConfigurationQuery, GetEmailTemplateConfigurationResponse>(`/api/v1/configuration/email-template-configurations/${emailTemplateConfigurationId}`)
      .toPromise();
  }

  postEmailTemplateConfiguration(shortId: string, emailTamplate: EmailTemplateConfiguration): Promise<EmailTemplateConfiguration> {
    return this.apiService
      .post<PostEmailTemplateConfigurationQuery, PostEmailTemplateConfigurationBody, PostEmailTemplateConfigurationResponse>(
        `/api/v1/configuration/email-template-configurations/${shortId}`,
        emailTamplate
      )
      .toPromise();
  }

  async getEmailTemplateConfigurations(query?: GetEmailTemplateConfigurationsQuery): Promise<GetEmailTemplateConfigurationsResponse> {
    return await this.apiService
      .get<GetEmailTemplateConfigurationsQuery, GetEmailTemplateConfigurationsResponse>('/api/v1/configuration/email-template-configurations', {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();
  }
}
