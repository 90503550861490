<div *skeleton="{ show: loading }">
  <div class="tab-header">
    <h1>
      <span class="pre-title">{{ 'GENERAL_ADMINUSER_GROUP' | translate }}</span>
      <span>{{ group?.name }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <p-button icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" [disabled]="loading || saving || !isValid()" (onClick)="onSave()"></p-button>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_PROPERTIES' | translate }}
    </div>
    <div *ngIf="group" class="panel-body">
      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [(ngModel)]="group.name" />
        </div>
        <div class="title">{{ 'PAGE_ADMINUSER_GROUP_GROUP_NAME' | translate }}</div>
        <div class="description">{{ 'PAGE_ADMINUSER_GROUP_GROUP_NAME_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group">
        <div class="input">
          <p-autoComplete [(ngModel)]="usedRoles" [suggestions]="filteredRoles" (completeMethod)="filterRoles($event)" field="name" [forceSelection]="true" [multiple]="true" [dropdown]="true">
          </p-autoComplete>
        </div>
        <div class="title">{{ 'PAGE_ADMINUSER_GROUP_ROLES' | translate }}</div>
        <div class="description">{{ 'PAGE_ADMINUSER_GROUP_ROLES_DESCRIPTION' | translate }}</div>
      </div>

      <div class="input-group">
        <div class="right">
          <!-- TODO: Add input style -->
          <div class="p-grid p-ai-center vertical-container">
            <p-chips
              class="p-col"
              [style]="{ width: '100%', display: 'block' }"
              [placeholder]="'PAGE_ADMINUSER_GROUP_USERS_HINT' | translate"
              [disabled]="saving"
              [(ngModel)]="newMembers"
              [addOnBlur]="true"
              separator=","
            ></p-chips>
            <div class="p-col-fixed">
              <button type="button" pButton pRipple [label]="'PAGE_ADMINUSER_GROUP_USERS_ADD' | translate" (click)="addAdminUsers()" [disabled]="saving"></button>
            </div>
          </div>

          <div class="new-member" *ngFor="let newMemberResult of newMembersResult | keyvalue">
            <span class="success" *ngIf="saveMode === 'add' && newMemberResult.value">{{ 'PAGE_GROUP_MEMBERS_ADD_SUCCESS' | translate : { identifier: newMemberResult.key } }}</span>
            <span class="fail" *ngIf="saveMode === 'add' && !newMemberResult.value">{{ 'PAGE_GROUP_MEMBERS_ADD_FAIL' | translate : { identifier: newMemberResult.key } }}</span>
            <span class="success" *ngIf="saveMode === 'remove' && newMemberResult.value">{{ 'PAGE_GROUP_MEMBERS_REMOVE_SUCCESS' | translate : { identifier: newMemberResult.key } }}</span>
            <span class="fail" *ngIf="saveMode === 'remove' && !newMemberResult.value">{{ 'PAGE_GROUP_MEMBERS_REMOVE_FAIL' | translate : { identifier: newMemberResult.key } }}</span>
          </div>

          <p-table *ngIf="groupMembers" [value]="groupMembers" styleClass="p-datatable-sm">
            <ng-template pTemplate="header">
              <tr>
                <th>{{ 'PAGE_ADMINUSER_DISPLAY_NAME' | translate }}</th>
                <th>{{ 'PAGE_ADMINUSER_DISPLAY_EMAIL' | translate }}</th>
                <th style="width: 6rem"></th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-groupMember>
              <tr>
                <td>{{ groupMember.displayName }}</td>
                <td>{{ groupMember.email }}</td>
                <td>
                  <a (click)="confirmRemoveAdminUser(groupMember._id)" class="warning"><i class="pi pi-trash warning p-mr-2"></i>{{ 'GENERAL_REMOVE' | translate }}</a>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="title">{{ 'PAGE_ADMINUSER_GROUP_USERS' | translate }}</div>
        <div class="description">{{ 'PAGE_ADMINUSER_GROUP_USERS_DESCRIPTION' | translate }}</div>
      </div>
    </div>
  </div>
  <p-confirmDialog></p-confirmDialog>
</div>
