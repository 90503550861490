import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { ConfigurationEntry } from 'src/common/entities/Configuration';

@Component({
  selector: 'app-templates-configuration',
  templateUrl: './templates-configuration.component.html',
  styleUrls: ['./templates-configuration.component.scss'],
})
export class TemplatesConfigurationComponent implements OnInit {
  templatesConfigurations: ConfigurationEntry[] = [];

  totalRecords: number = 0;
  loading: boolean = true;

  constructor(private configurationService: ConfigurationService) {}

  ngOnInit(): void {
    this.loading = false;
  }

  async retrieveData(loadEvent?: LazyLoadEvent): Promise<void> {
    this.loading = true;
    try {
      const result = await this.configurationService.getConfigurations(null, 'templates');
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
  }
}
