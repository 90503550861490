<div class="panel">
  <div class="panel-header">
    Notifications
    <div class="actions">
      <c-table-options #toc></c-table-options>
      <p-button *hasRight="'notifications.create'" styleClass="p-button-sm" (click)="create()" icon="pi icon-ic-add-item" [disabled]="loading || saving" [label]="'Add Notification'"></p-button>
    </div>
  </div>
  <c-table [$refresh]="refresh" #table mode="query" [singleTemplate]="true" [options]="notificationsTableOptions" (query)="notificationQuery($event)">
    <ng-template let-notification>
      <tr>
        <td>{{ notification.internalName || 'GENERAL_UNKNOWN' | translate }}</td>
        <td>{{ notification.local[currentLanguage].title || 'GENERAL_UNKNOWN' | translate }}</td>
        <td class="message-text">{{ notification.local[currentLanguage].text || 'GENERAL_UNKNOWN' | translate }}</td>
        <td class="actions">
          <div class="buttons">
            <p-button *hasRight="'notification.send'" label="Send" (onClick)="send(notification)"></p-button>
          </div>
        </td>
        <td class="actions">
          <div class="buttons">
            <p-button *hasRight="'notification.edit'" label="Edit" (onClick)="edit(notification)"></p-button>
          </div>
        </td>
      </tr>
    </ng-template>
  </c-table>
  <p-confirmDialog></p-confirmDialog>
</div>

<!-- ------------------ Notification Send Dialog  ------------------ -->

<p-sidebar *ngIf="notification" [(visible)]="showSendSidebar" styleClass="p-sidebar-st" position="right" [appendTo]="'body'" [modal]="true" [showCloseIcon]="false">
  <c-sidebar-header [headerTitle]="'Send Notification'" (onClose)="closeNotificationSendDialog()"></c-sidebar-header>
  <div class="sidebar-body">
    <c-collaboration-patch-input *ngFor="let jsonPath of jsonPathes" [object]="confirm" [jsonpath]="jsonPath" [inputs]="confirmInputs"></c-collaboration-patch-input>
  </div>

  <div class="sidebar-body"></div>
  <c-sidebar-footer [showDeleteButton]="false" [showSaveButton]="false" [showOptionalButton]="true" [onOptionalButton]="optionalButton" [optionalButtonLabel]="'Send'"></c-sidebar-footer>
</p-sidebar>

<!-- ------------------ Notification Dialog  ------------------ -->

<p-sidebar *ngIf="notification" [(visible)]="showSidebar" styleClass="p-sidebar-st" position="right" [appendTo]="'body'" [modal]="false" [showCloseIcon]="false">
  <c-sidebar-header [headerTitle]="(editMode ? ('GENERAL_EDIT' | translate) : ('GENERAL_ADD' | translate)) + ' ' + 'Notification'" (onClose)="closeNotificationDialog()"></c-sidebar-header>

  <div class="sidebar-body">
    <c-collaboration-patch-input
      [object]="notification"
      [collaborationKey]="'notification:' + notification._id"
      [jsonpath]="'$.internalName'"
      [inputConfiguration]="inputConfig"
      [inputs]="inputs"
    ></c-collaboration-patch-input>
    <c-collaboration-patch-input
      [object]="notification"
      [collaborationKey]="'notification:' + notification._id"
      [jsonpath]="'$.local.$language.title'"
      [jsonpathParams]="{ language: currentLanguage }"
      [inputConfiguration]="inputConfig"
      [inputs]="inputs"
    ></c-collaboration-patch-input>
    <c-collaboration-patch-input
      [object]="notification"
      [collaborationKey]="'notification:' + notification._id"
      [jsonpath]="'$.local.$language.text'"
      [jsonpathParams]="{ language: currentLanguage }"
      [inputConfiguration]="inputConfig"
      [inputs]="inputs"
    ></c-collaboration-patch-input>
    <c-collaboration-patch-input [object]="notification" [collaborationKey]="'notification:' + notification._id" [jsonpath]="'$.data.type'" [inputs]="inputs"></c-collaboration-patch-input>
    <c-collaboration-patch-input
      [object]="notification"
      [collaborationKey]="'notification:' + notification._id"
      [jsonpath]="'$.data.page'"
      [inputConfiguration]="typeInputConfig"
      [inputs]="inputs"
    ></c-collaboration-patch-input>
  </div>
  <c-sidebar-footer [showDeleteButton]="false" [showSaveButton]="true" [disableSaveButton]="false" (onSave)="save()" (onDelete)="delete()"></c-sidebar-footer>
</p-sidebar>
