import { EventPhase } from '../Event';
import { AccessPolicy } from './../../entities/AccessPolicy';

export const pageModuleTypes = [
  'Accordion',
  'Agenda',
  'Banner',
  'Bookmarks',
  'Custom',
  'DocumentCollection',
  'EventKeytopicsIconGroup',
  'EventsList',
  'ExploreStage',
  'Filter',
  'Form',
  'Grid',
  'IconGroup',
  'ListImage',
  'ListVideo',
  'Login',
  'ImageBanner',
  'ImageTagTextLink',
  'Image',
  'MultiEventStage',
  'List',
  'Showroom',
  'StreamEventStage',
  'SpeakerList',
  'TeaserEventStage',
  'Text3DImage',
  'TextEventStage',
  'TextList',
  'TextImage',
  'Text',
  'TitleEventStage',
  'Tiles',
  'UserProfileStage',
  'VideoSlider',
  'VideoTeaserCollection',
  'NewsfeedEntry',
  'TextEventStage2',
  'StreamEventStage2',
  'TeaserEventStage2',
  'MultiEventStage2',
  'TitleEventStage2',
  'ProductList',
  'ProductList2',
  'ConversionStage',
  'SlideshowStage',
  'TrendingTopics',
  'Spacer',
] as const;
export type PageModuleType = (typeof pageModuleTypes)[number];

export const backgroundThemes = ['Light', 'Dark', 'Highlight'] as const;
export type BackgroundTheme = (typeof backgroundThemes)[number];

export const titlePositions = ['left', 'centered'] as const;
export type TitlePositions = (typeof titlePositions)[number];

export const pageModuleTypeGlobalPageFilter = ['EventKeytopicsIconGroup', 'StreamEventStage', 'StreamEventStage2'];

export interface PageModuleClipboardData {
  type: 'event' | 'global';
  pageModule?: PageModule;
  pageModuleType: PageModuleType;
  eventId?: string;
  eventVersionId?: string;
}

export interface PageModule {
  _id: string;
  pageModuleType: PageModuleType;
  backgroundTheme?: BackgroundTheme;
  accessPolicy?: AccessPolicy[];
  anchor?: string;
  paddingTop?: boolean;
  paddingBottom?: boolean;
  paddingRight?: boolean;
  paddingLeft?: boolean;
}

export interface SavedPageModule {
  page: string;
  phase: EventPhase,
  eventVersion: string;
  index: number;
  pageModule: PageModule
}