import { TableOptions, TableQuery } from 'src/app/components/table/table.interfaces';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from '../../services/utils/utils.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { OperatingRegionsService } from 'src/app/services/operating-regions/operating-regions.service';
import { OperatingRegion } from 'src/common/entities/OperatingRegion';
import { OperatingRegionFactory } from 'src/common/factories/OperatingRegionFactory';
import { TableComponent } from 'src/app/components/table/table.component';
import { InputConfiguration, Inputs } from 'src/common/inputs/Inputs';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-operating-regions',
  templateUrl: './operating-regions.component.html',
  styleUrls: ['./operating-regions.component.scss'],
})
export class OperatingRegionsComponent implements OnInit {
  tab: Tab;
  tableOptions: TableOptions<OperatingRegion>;
  showSidebar: boolean = false;
  editMode: boolean = false;
  operatingRegion: OperatingRegion;
  languages;
  currentLanguage;
  hasDeleteRight: boolean = false;

  @ViewChild('operatingRegionsTable') operatingRegionsTable: TableComponent<any>;

  constructor(
    private authService: AuthService,
    private operatingRegionsService: OperatingRegionsService,
    public utilsService: UtilsService,
    private tabsService: TabsService,
    private activatedRoute: ActivatedRoute,
    private languagesService: LanguagesService,
    private collaborationService: CollaborationService
  ) {
    this.tableOptions = {
      size: 50,
      columns: [
        { header: 'GENERAL_INTERNAL_NAME', sort: 'internalName' },
        { header: 'PAGE_OPERATING_REGIONS_SORT_ORDER', sort: 'sortOrder' },
      ],
      filters: [{ header: 'GENERAL_INTERNAL_NAME', path: 'internalName' }],
    };
  }

  inputConfig: InputConfiguration;

  inputs: Inputs = {
    '$.internalName': {
      type: 'text',
    },
    '$.local': {
      factory: async () => ({}),
    },
    '$.local.$language': {
      factory: async () => {
        return new OperatingRegionFactory().createOperatingRegionLocal({});
      },
    },
    '$.local.$language.title': {
      type: 'text',
    },
    '$.sortOrder': {
      type: 'number',
    },
  };
  get jsonPathes(): string[] {
    return Object.keys(this.inputs);
  }

  async ngOnInit(): Promise<void> {
    this.hasDeleteRight = await this.authService.hasRight('configuration.delete');
    this.tab = this.tabsService.register({
      category: 'configuration',
      route: this.activatedRoute.snapshot,
    });
    this.languages = (await this.languagesService.getLanguages())?.items.filter((i) => i.selectable).map((i) => i.language);
    const language = this.activatedRoute.snapshot.queryParams.language || 'en';
    this.currentLanguage = this.languages.includes(language) ? language : this.languages[0];
    this.inputConfig = { languages: this.languages };
  }

  query(query: TableQuery<OperatingRegion>) {
    query.result = this.operatingRegionsService.getOperatingRegions(query.query);
  }

  async create(): Promise<void> {
    this.editMode = false;
    this.operatingRegion = null;
    this.operatingRegion = await new OperatingRegionFactory({ ensureLocals: [this.currentLanguage] }).createOperatingRegion();
    this.collaborationService.registerLocal(`operatingRegion:${this.operatingRegion._id}`, this.operatingRegion, true);
    this.showSidebar = true;
  }

  async edit(operatingRegion: OperatingRegion): Promise<void> {
    this.editMode = true;
    this.operatingRegion = await new OperatingRegionFactory({ ensureLocals: [this.currentLanguage] }).createOperatingRegion(operatingRegion);
    this.collaborationService.registerLocal(`operatingRegion:${this.operatingRegion._id}`, this.operatingRegion, true);
    this.showSidebar = true;
  }

  async save(): Promise<void> {
    if (!this.editMode) {
      await this.operatingRegionsService.createOperatingRegion(this.operatingRegion);
    } else {
      await this.operatingRegionsService.updateOperatingRegion(this.operatingRegion);
    }
    this.operatingRegionsTable.refresh(true);
  }

  async delete(): Promise<void> {
    await this.operatingRegionsService.deleteOperatingRegion(this.operatingRegion._id);
    this.operatingRegionsTable.refresh(true);
  }

  async ensureLanguages(languages: string[]) {
    for (const language of languages) {
      if (!this.operatingRegion.local[language]) {
        this.operatingRegion.local[language] = await new OperatingRegionFactory().createOperatingRegionLocal({});
      }
    }
  }
}
