import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmationService, MenuItem } from 'primeng/api';

import { Event, EventPhase } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page, PageType } from 'src/common/entities/Page';
import { EventsService } from 'src/app/services/events/events.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { AbstractFactory } from 'src/common/factories/AbstractFactory';
import { PageFactory } from 'src/common/factories/PageFactory';

@Component({
  selector: 'c-event-layout-pages',
  templateUrl: './event-layout-pages.component.html',
  styleUrls: ['./event-layout-pages.component.scss', '../layout-editor.scss'],
})
export class EventLayoutPagesComponent implements OnInit, OnDestroy {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  currentPage: Page;

  @Input()
  currentEventPhase: EventPhase;

  @Input()
  editPage: {
    page: Page;
    eventPhase: EventPhase;
  };

  @Output()
  currentChange: EventEmitter<{ eventPhase: EventPhase; page: Page }> = new EventEmitter<{
    eventPhase: EventPhase;
    page: Page;
  }>();

  // @Output()
  // currentEventPhaseChange: EventEmitter<EventPhase> = new EventEmitter<EventPhase>()

  @Output()
  onClose = new EventEmitter<boolean>();

  eventPhases: EventPhase[] = ['ANNOUNCED', 'RELEASED', 'LIVE', 'ARCHIVE'];
  currentLanguage: string = null;

  addPageMenu: { [eventPhase: string]: MenuItem[] } = {};

  editEventPhase: EventPhase = null;

  newPage: {
    eventPhase: EventPhase;
    mode?: 'clone' | 'new';
    type?: PageType;
  } = null;

  get phase() {
    return this.eventVersion.phase[this.currentEventPhase];
  }

  subscriptions: Subscription[] = [];

  constructor(
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private eventsService: EventsService,
    private utils: UtilsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((queryParams) => {
        this.currentLanguage = queryParams.language || 'en';
      })
    );
    this.currentLanguage = this.activatedRoute.snapshot.queryParams.language || 'en';
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  pageTitle(page: Page): string {
    return this.utils.pageTitle(page, this.currentLanguage);
  }

  setCurrentPage(eventPhase: EventPhase, page?: Page | string) {
    if (page) {
      const p =
        typeof page === 'undefined' ? this.eventVersion.phase[eventPhase].pages[0] : this.eventVersion.phase[eventPhase].pages.find((p) => p._id === (typeof page === 'string' ? page : page._id));
      if (p) {
        this.setCurrent(eventPhase, p);
      }
    } else {
      this.setCurrent(null, null);
    }
  }

  async addPage(pageType: PageType, eventPhase: EventPhase) {
    if (!this.eventVersion.phase[eventPhase].pages) this.eventVersion.phase[eventPhase].pages = [];
    let newPage = await AbstractFactory.get(PageFactory).page({ pageType });
    await this.eventsService.patch(this.eventVersion, {
      command: 'push',
      jsonpath: `$.phase.$eventPhase.pages`,
      jsonpathParams: {
        eventPhase: eventPhase,
      },
      value: newPage,
    });

    // Set new page as current page in layout editor
    this.setCurrent(eventPhase, newPage);
    // Open dialog to edit new pages properies
    this.editPage = { page: newPage, eventPhase: eventPhase };
  }

  async clonePage(targetEventPhase: EventPhase, page: Page) {
    let newPage = (await AbstractFactory.get(PageFactory, { newIds: true }).page(JSON.parse(JSON.stringify(page)))) as Page;
    await this.eventsService.patch(this.eventVersion, {
      command: 'push',
      jsonpath: `$.phase.$eventPhase.pages`,
      jsonpathParams: {
        eventPhase: targetEventPhase,
      },
      value: newPage,
    });
    newPage = this.eventVersion.phase[targetEventPhase].pages.find((i) => i._id === newPage._id);
    // Set new page as current page in layout editor
    this.setCurrent(targetEventPhase, newPage);
    // Open dialog to edit new pages properties
    this.editPage = { page: newPage, eventPhase: targetEventPhase };
  }

  async pageUp(eventPhase: EventPhase, page: Page) {
    await this.eventsService.patch(this.eventVersion, {
      command: 'up',
      jsonpath: `$.phase.$eventPhase.pages[?(@._id=="$pageId")]`,
      jsonpathParams: {
        eventPhase,
        pageId: page._id,
      },
    });
  }

  async pageDown(eventPhase: EventPhase, page: Page) {
    await this.eventsService.patch(this.eventVersion, {
      command: 'down',
      jsonpath: `$.phase.$eventPhase.pages[?(@._id=="$pageId")]`,
      jsonpathParams: {
        eventPhase,
        pageId: page._id,
      },
    });
  }

  async confirmDeletePage(event, eventPhase: EventPhase, page: Page) {
    this.confirmationService.confirm({
      key: 'eventLayoutConfirmPopup',
      target: event.target,
      message: this.translate.instant('GENERAL_CONFIRM_DELETE'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deletePage(eventPhase, page);
        // this.onDelete.emit(true);
      },
      reject: () => {
        // Nothing to do
      },
    });
  }

  async deletePage(eventPhase: EventPhase, page: Page) {
    await this.eventsService.patch(this.eventVersion, {
      command: 'delete',
      jsonpath: `$.phase.$eventPhase.pages[?(@._id=="$pageId")]`,
      jsonpathParams: {
        eventPhase,
        pageId: page._id,
      },
    });
  }

  trackByIndex(index: number, item: any) {
    return index;
  }

  setCurrent(eventPhase: EventPhase | null, page: Page | null) {
    this.currentEventPhase = eventPhase;
    this.currentPage = page;
    this.newPage = null;

    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: {
        page: page?._id || undefined,
      },
    });
    this.currentChange.emit({
      eventPhase: this.currentEventPhase,
      page: this.currentPage,
    });
  }
}
