import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetsComponent } from './assets.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { MultiSelectModule } from 'primeng/multiselect';
import { ComponentsModule } from 'src/app/components/components.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DividerModule } from 'primeng/divider';
import { PaginatorModule } from 'primeng/paginator';

const routes: Routes = [{ path: '', component: AssetsComponent }];

@NgModule({
  declarations: [AssetsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    NgxFileDropModule,
    DialogModule,
    InputTextModule,
    PipesModule,
    DirectivesModule,
    ProgressBarModule,
    PaginatorModule,
    DividerModule,
    TranslateModule,
    ToastModule,
    TableModule,
    ButtonModule,
    MultiSelectModule,
    ComponentsModule,
    InfiniteScrollModule,
    ProgressSpinnerModule,
  ],
  providers: [MessageService],
})
export class AssetsModule {}
