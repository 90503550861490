<div *ngFor="let eventSlot of ticketEventSlots">
  <div class="event-slot">
    <div>
      <p-checkbox [binary]="true" [disabled]="disabled" [(ngModel)]="selectedEventSlots[eventSlot._id]" (ngModelChange)="eventSlotChanged(eventSlot)"></p-checkbox>
    </div>
    <div>{{ eventSlot.startAt | timeZoneByUrl | async | date : 'short' }} - {{ eventSlot.endAt | timeZoneByUrl | async | date : 'short' }}</div>
  </div>
  <div class="event-time-slot" *ngFor="let eventTimeSlot of eventSlot.eventTimeSlots">
    <div>
      <p-checkbox [binary]="true" [disabled]="disabled" [(ngModel)]="selectedEventTimeSlots[eventTimeSlot._id]" (ngModelChange)="eventTimeSlotChanged(eventSlot, eventTimeSlot)"></p-checkbox>
    </div>
    <div>
      {{ eventTimeSlot.startAt | timeZoneByUrl | async | date : 'shortTime' }} - {{ eventTimeSlot.endAt | timeZoneByUrl | async | date : 'shortTime' }}
      <span class="event-time-slot-name">({{ (eventTimeSlot.local | localize)?.title }})</span>
    </div>
  </div>
</div>
<div class="no-event-slots" *ngIf="ticketEventSlots?.length === 0">No Event Slots selectable</div>
