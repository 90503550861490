<div *ngIf="selectedPage" class="page-item">
  <div class="page-preview">
    <c-asset-preview></c-asset-preview>
  </div>
  <div class="page-info">
    <span>{{ selectedPage.internalName }}</span>
    <div class="details">
      <div>Path: {{ selectedPage.path }}</div>
      <div>Type: {{ selectedPage.pageType }}</div>
    </div>
  </div>
</div>

<div class="actions as-layer" *ngIf="selectedPage && !this.disabled">
  <span>
    <a [routerLink]="[]" (click)="openSidebar()">{{ 'GENERAL_SEARCH' | translate }}</a>
    <span *ngIf="!hideRemoveLink">&nbsp;/&nbsp;<a [routerLink]="[]" (click)="removePage()">Remove</a>&nbsp;</span>
  </span>
</div>
<div class="actions" *ngIf="!selectedPage && !this.disabled">
  <span>
    <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openSidebar()">{{ 'GENERAL_SEARCH' | translate }}</a
    >&nbsp;
  </span>
</div>

<c-sidebar [header]="'COMPONENT_PAGE_SELECT_SEARCH_HEADER' | translate" [(visible)]="showSidebar">
  <div *ngIf="!domainCollectionId" class="input-group compact">
    <div class="input">
      <p-dropdown [options]="domainCollectionOptions" [(ngModel)]="selectedDomainCollection" (onChange)="selectDomainCollection($event)" [disabled]="disabled" [appendTo]="'body'"></p-dropdown>
    </div>
    <div class="title">{{ 'GENERAL_SHOW_DOMAIN_COLLECTION' | translate }}</div>
  </div>
  <div class="search-input input-group compact">
    <div class="input">
      <input #searchInput placeholder="Search pages ..." pInputText [(ngModel)]="searchQuery.text" (keyup)="searchTextKeyUp()" />
    </div>
  </div>
  <c-table mode="static" [singleTemplate]="true" [options]="tableOptions" [data]="$pageTreeStructure | async">
    <ng-template let-pageStructure let-level="level" #row>
      <tr [ngClass]="getChildContentClass(level)">
        <td [style.padding-left]="getRowPaddingLeft(pageStructure, level)">
          <i
            *ngIf="pageStructure.childPages.length > 0"
            [class]="expanded[pageStructure._id] ? 'pi pi-chevron-down small-chevron' : 'pi pi-chevron-right small-chevron'"
            (click)="expanded[pageStructure._id] = !expanded[pageStructure._id]"
          ></i>
          <a (click)="selectPage(pageStructure._id)">
            {{ pageStructure.internalName || 'GENERAL_UNNAMED' | translate }}
            <span *ngIf="pageStructure.path" class="pagelink-path">(/{{ pageStructure.path }})</span>
          </a>
        </td>
      </tr>

      <ng-container *ngIf="expanded[pageStructure._id]">
        <ng-container *ngFor="let childStructure of pageStructure.childPages">
          <ng-container *ngTemplateOutlet="row; context: { $implicit: childStructure, level: (level || 0) + 1 }"> </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  </c-table>
</c-sidebar>
