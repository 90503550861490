import { shareCtaTargets } from '../../entities/CTAButton';
import { isAssetPage, isExplorePage, isLinkedPage, isPage, PageTypes } from '../../entities/Page';
import { backgroundThemes } from '../../entities/PageModule';
import { Factory } from '../../factories/Factory';
import { Inputs } from '../Inputs';
import { eventStageInputs } from './EventStagesInputs';
import { pageModulesInputs } from './PageModulesInputs';

export const pageInputs: Inputs = {
  '$.internalName': {},
  '$.pageType': {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Blank Page', value: PageTypes.BlankPage },
      { label: 'Asset Page', value: PageTypes.AssetPage },
      // {label: 'Standard Page', value: 'StandardPage'},
      { label: 'Keytopic Page', value: PageTypes.KeytopicsPage },
      { label: 'Profile Page', value: PageTypes.ProfilePage }
    ]
  },

  '$.path': {},
  '$.linkedPage': {
    condition: parent => {
      return isLinkedPage(parent);
    },
    type: 'page',
    description: 'Select an original page to which this LinkedPage should point.'
  },
  '$.showBackButton': {
    type: 'switch',
    header: 'Show Back Button'
  },
  '$.showInMenu': {
    type: 'switch'
  },
  '$.stageBackgroundTheme': {
    type: 'dropdown',
    dropdownOptions: [...backgroundThemes].map(a => ({ label: a, value: a }))
  },
  '$.local.$language': {
    factory: async () => await Factory.page().pageLocal({})
  },
  '$.local.$language.title': {},
  '$.local.$language.navigationTitle': {},
  '$.local.$language.metaDescription': {
    type: 'textarea'
  },
  '$.local.$language.linkTitle': {},
  '$.local.$language.linkDescription': {
    type: 'textarea'
  },
  '$.onboarding': {
    type: 'onboarding'
  },
  '$.readAccess': {
    header: 'Who can read the page',
    type: 'accesspolicy'
  },
  '$.profileType': {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Speaker', value: 'speaker' },
      { label: 'User', value: 'user' }
    ]
  },
  '$.displayOption': {
    header: 'Where to display',
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Everywhere', value: 'everywhere' },
      { label: 'Only Web', value: 'onlyWeb' },
      { label: 'Only App', value: 'onlyApp' }
    ]
  },
  '$.contentChecklistActive': {
    header: 'Content Chcklist Active',
    type: 'switch',
    condition: (obj, jsonpath, jsonpathParams) => {
      return !isPage(obj);
    }
  },
  '$.showSubmenu': {
    header: 'Show Submenu',
    type: 'switch'
  },
  '$.showInSubmenu': {
    header: 'Show in Submenu',
    type: 'switch'
  },
  '$.showSummary': {},
  '$.stageImage': {
    type: 'imageasset'
  },
  '$.playerOverlayPicture': {
    type: 'imageasset'
  },
  '$.previewImage': {
    header: 'Preview Image',
    description: 'Set specific link preview properties for this page',
    type: 'imageasset'
  },
  '$.sitemap': {
    header: 'Add to Sitemap.xml',
    type: 'switch'
  },

  '$.showShareButton': {
    condition: parent => {
      return isAssetPage(parent);
    },
    type: 'switch'
  },
  '$.showLikeButton': {
    condition: parent => {
      return isAssetPage(parent);
    },
    type: 'switch'
  },
  '$.shareButtonTargets': {
    condition: parent => {
      return isAssetPage(parent);
    },
    type: 'multiselect',
    header: 'Share targets',
    description: 'If none is selected, the module will display all possible targets.',
    placeholder: 'All targets',
    showToggleAll: false,
    multiselectOptions: [...shareCtaTargets].map(a => ({ label: a, value: a }))
  },
  '$.featuredEvents': {
    condition: parent => {
      return isExplorePage(parent);
    },
    header: 'Featured Events',
    factory: async () => [],
    list: true,
    childFactory: async () => null
  },
  '$.featuredEvents[$index]': {
    type: 'event',
    header: 'Event'
  },
  '$.recommendationsSettings.local.$language': {
    factory: async () => await Factory.page().assetPageRecommendationsSettingsLocal({})
  },
  '$.recommendationsSettings.local.$language.loadMoreButtonLabel': {
    type: 'text',
    header: 'Load More Button Label'
  },
  '$.recommendationsSettings.teaserLayoutVariation': {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Layout 1 - Tags at top', value: 1 },
      { label: 'Layout 2 - Tags at bottom', value: 2 },
      { label: 'Layout 3 - Tags at top with title below teaser', value: 3 }
    ]
  },
  '$.recommendationsSettings.showVideoTitle': {
    type: 'switch',
    header: 'Show Video Title'
  },
  '$.recommendationsSettings.showLanguageTags': {
    type: 'switch',
    header: 'Show Language Tags'
  },
  '$.recommendationsSettings.showAssetTags': {
    type: 'switch',
    header: 'Show Asset Tags'
  },
  '$.recommendationsSettings.showEventtypeTags': {
    type: 'switch',
    header: 'Show Eventtype Tags '
  },
  '$.recommendationsSettings.showDate': {
    type: 'switch',
    header: 'Show Date'
  },
  '$.recommendationsSettings.showVideoStatusFlag': {
    type: 'switch',
    header: 'Show Video Status Flag'
  },
  '$.recommendationsSettings.showProgressBar': {
    type: 'switch',
    header: 'Show Watch Progress Bar'
  },
  '$.recommendationsSettings.showPlayButton': {
    type: 'switch',
    header: 'Show Play Button'
  },
  '$.recommendationsSettings.navigationType': {
    header: 'Navigation',
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Pagination', value: 'pagination' },
      { label: 'Load more', value: 'loadMore' }
    ]
  },
  '$.recommendationsSettings.pagination': {
    header: 'Pagination',
    type: 'number'
  },
  '$.recommendationsSettings.showSliderPagination': {
    type: 'switch',
    header: 'Show Slider Pagination',
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return parent?.displayAs === 'slider';
    }
  },
  '$.recommendationsSettings.loadMoreInitialAmount': {
    type: 'number',
    header: 'Initial Amount'
  },
  '$.recommendationsSettings.loadMoreAmount': {
    type: 'number',
    header: 'Load More Amount'
  },
  '$.recommendationsSettings.backgroundTheme': {
    header: 'Background Theme',
    type: 'dropdown',
    dropdownOptions: [...backgroundThemes].map(a => ({ label: a, value: a }))
  },
  '$.recommendationsSettings.accessPolicy': {
    header: 'Access Policy',
    type: 'accesspolicy'
  },
  '$.recommendationsSettings.paddingTop': {
    header: 'Padding Top',
    type: 'switch'
  },
  '$.recommendationsSettings.paddingBottom': {
    header: 'Padding Bottom',
    type: 'switch'
  },
  '$.recommendationsSettings.paddingLeft': {
    header: 'Padding Left',
    type: 'switch'
  },
  '$.recommendationsSettings.paddingRight': {
    header: 'Padding Right',
    type: 'switch'
  },
  ...eventStageInputs,
  ...pageModulesInputs
};
