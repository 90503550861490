import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/events/events.service';
import { Preview } from 'src/common/entities/Preview';
import { Event, eventPhase, EventPhase } from 'src/common/entities/Event';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { Region } from 'src/common/entities/Region';
import { RegionsService } from 'src/app/services/regions/regions.service';
import { EventVersion } from 'src/common/entities/EventVersion';
import { DomainService } from '../../../services/domain/domain.service';

@Component({
  selector: 'c-event-previews',
  templateUrl: './event-previews.component.html',
  styleUrls: ['./event-previews.component.scss'],
})
export class EventPreviewsComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  previews: Preview[] = [];
  newPreview: {
    startDate: Date;
    eventVersion?: string;
  };
  saving = false;

  regions: Region[];

  constructor(private eventsService: EventsService, private configurationService: ConfigurationService, private domainService: DomainService, private regionsService: RegionsService) {}

  async ngOnInit() {
    this.regions = (await this.regionsService.getRegions()).items;
    this.previews = (
      await this.eventsService.getPreviews(this.event._id, {
        filter: {
          eventVersion: {
            matchMode: 'equalsObjectId',
            value: this.eventVersion._id,
          },
        },
      })
    ).items;
  }

  currentDate(preview: Preview): Date {
    return new Date(this.configurationService.serverTime().getTime() + preview.timeDifference);
  }

  currentEventPhase(preview: Preview): EventPhase {
    return eventPhase(this.event, null, this.currentDate(preview));
  }

  url(preview: Preview): string {
    const url = this.domainService.findFirstFrontendDomain();
    return `${url}/events/${this.event.shortId}?preview=${preview.token}`;
  }

  addPreview() {
    this.newPreview = {
      startDate: new Date(),
      eventVersion: this.eventVersion._id,
    };
  }

  async createPreview() {
    this.saving = true;

    try {
      await this.eventsService.createPreview(this.event._id, {
        ...this.newPreview,
        startDate: this.newPreview.startDate.toISOString(),
      });
      this.previews = (await this.eventsService.getPreviews(this.event._id)).items;
      this.newPreview = null;
    } catch (err) {
      console.error(err);
    }

    this.saving = false;
  }
}
