import { TrendingTopicsPageModuleFactory } from './../../factories/pagemodules/TrendingTopicsPageModuleFactory';
import { TrendingTopicsPageModuleElement, TrendingTopicsStoryAssets, trendingTopicsType } from './../../entities/pagemodules/TrendingTopicsPageModule';
import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { isTrendingTopicsPageModule, titlePositions } from '../../entities/PageModule';
import { Inputs } from '../Inputs';
import { EventVersion } from '../../entities/EventVersion';
import { commonPageModuleInputs } from './common';
import { isPage, isPageWithPageModules, Page } from '../../entities/Page';
import { string } from 'yup';

export const trendingTopicsPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('TrendingTopics'),
  "$.pageModules[?(@.pageModuleType=='TrendingTopics' && @._id=='$pageModuleId')].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='TrendingTopics' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => await new TrendingTopicsPageModuleFactory().createTrendingTopicsPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='TrendingTopics' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },

  "$.pageModules[?(@.pageModuleType=='TrendingTopics' && @._id=='$pageModuleId')].trendingTopicsType": {
    header: 'trending topics type',
    type: 'dropdown',
    dropdownOptions: [
      { value: 'default', label: 'Default' },
      { value: 'story', label: 'Story' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='TrendingTopics' && @._id=='$pageModuleId')].local.$language.topics": {
    factory: async () => [],
    list: true,
    listKey: '_id',
    listValue: 'elementId',
    childFactory: async () => new TrendingTopicsPageModuleFactory().createTrendingTopicsPageModuleLocalTopic({}),
    childLabel: (item: TrendingTopicsPageModuleElement, index: number) => item?.title as string,
    childImage: (item: TrendingTopicsPageModuleElement, index: number) => (item.image ? item.image : null) as string,
  },
  "$.pageModules[?(@.pageModuleType=='TrendingTopics' && @._id=='$pageModuleId')].local.$language.topics[?(@._id=='$elementId')].image": {
    type: 'imageasset',
    description: 'Please use an image of ratio 1:1',
  },
  "$.pageModules[?(@.pageModuleType=='TrendingTopics' && @._id=='$pageModuleId')].local.$language.topics[?(@._id=='$elementId')].title": {},
  "$.pageModules[?(@.pageModuleType=='TrendingTopics' && @._id=='$pageModuleId')].local.$language.topics[?(@._id=='$elementId')].isHighlighted": {
    type: 'switch',
    header: 'Highlighted',
  },
  "$.pageModules[?(@.pageModuleType=='TrendingTopics' && @._id=='$pageModuleId')].local.$language.topics[?(@._id=='$elementId')].cta": {
    type: 'ctabutton',
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      let page: Page | null | undefined = null;

      if (isPage(obj)) {
        page = obj;
      } else {
        const eventVersion = obj as EventVersion;
        page = eventVersion.phase[jsonpathParams.eventPhase].pages.find((p) => p._id === jsonpathParams.pageId);
      }

      if (isPage(page)) {
        if (isPageWithPageModules(page)) {
          const pageModule = page.pageModules.find((p) => p._id === jsonpathParams.pageModuleId);

          if (isTrendingTopicsPageModule(pageModule)) {
            return pageModule.trendingTopicsType === 'default';
          }
        }
      }

      return false;
    },
  },
  "$.pageModules[?(@.pageModuleType=='TrendingTopics' && @._id=='$pageModuleId')].local.$language.topics[?(@._id=='$elementId')].storyAssets": {
    factory: async () => [],
    list: true,
    listKey: '_id',
    listValue: 'childElementId',
    childFactory: async () => new TrendingTopicsPageModuleFactory().createTrendingTopicsStoryAssets({}),
    childLabel: (item: TrendingTopicsStoryAssets, index: number) => item?.title as string,
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      let page: Page | null | undefined = null;

      if (isPage(obj)) {
        page = obj;
      } else {
        const eventVersion = obj as EventVersion;
        page = eventVersion.phase[jsonpathParams.eventPhase].pages.find((p) => p._id === jsonpathParams.pageId);
      }

      if (isPage(page)) {
        if (isPageWithPageModules(page)) {
          const pageModule = page.pageModules.find((p) => p._id === jsonpathParams.pageModuleId);

          if (isTrendingTopicsPageModule(pageModule)) {
            return pageModule.trendingTopicsType === 'story';
          }
        }
      }

      return false;
    },
  },

  "$.pageModules[?(@.pageModuleType=='TrendingTopics' && @._id=='$pageModuleId')].local.$language.topics[?(@._id=='$elementId')].storyAssets[?(@._id=='$childElementId')].asset": {
    header: 'asset',
    type: 'asset',
  },
  "$.pageModules[?(@.pageModuleType=='TrendingTopics' && @._id=='$pageModuleId')].local.$language.topics[?(@._id=='$elementId')].storyAssets[?(@._id=='$childElementId')].title": {
    header: 'title',
    type: 'text',
  },
  "$.pageModules[?(@.pageModuleType=='TrendingTopics' && @._id=='$pageModuleId')].local.$language.topics[?(@._id=='$elementId')].storyAssets[?(@._id=='$childElementId')].cta": {
    header: 'cta',
    type: 'ctabutton',
  },

  "$.pageModules[?(@.pageModuleType=='TrendingTopics' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [...titlePositions].map((item) => ({
      label: item,
      value: item,
    })),
  },
  "$.pageModules[?(@.pageModuleType=='TrendingTopics' && @._id=='$pageModuleId')].useFullWidth": {
    type: 'switch',
    header: 'Use fill width',
    description: 'Ignores right-sided padding/margin configuration if checked',
    factory: async () => true,
  },
};
