<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'PAGE_OPERATING_COUNTRIES' | translate }}</h1>
  </div>
  <div class="tab-menu">
    <div class="tab-menu">
      <c-language-button *hasRight="'configuration.edit'" [languages]="languageTags" [(currentLanguage)]="currentLanguage" (languagesChange)="ensureLanguages($event)"></c-language-button>
      <c-language-button
        *hasNoRight="'configuration.edit'"
        [languages]="languageTags"
        [(currentLanguage)]="currentLanguage"
        (languagesChange)="ensureLanguages($event)"
        [manageLanguages]="false"
      ></c-language-button>
    </div>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_OPERATING_COUNTRIES' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button *hasRight="'configuration.create'" [label]="'New Country'" icon="pi icon-ic-add-item" (onClick)="create()"></p-button>
      </div>
    </div>
    <c-table #operatingCountriesTable mode="query" [options]="tableOptions" [tableOptionsComponent]="toc" (query)="query($event)" (itemSelect)="edit($event)" [queryParamsHandling]="'merge'">
      <ng-template let-item let-index="index">
        <div *hasRight="['configuration.edit', 'configuration.read']">
          {{ item.internalName || ('GENERAL_EMPTY' | translate) }}
        </div>
        <span *hasNoRight="['configuration.edit', 'configuration.read']">
          {{ item.internalName || ('GENERAL_EMPTY' | translate) }}
        </span>
      </ng-template>
      <ng-template let-item>
        {{ (item.belongsToRegion | operatingRegion | async)?.internalName }}
      </ng-template>
      <ng-template let-item>
        <i *ngIf="item.isDefaultOperatingCountry" class="pi pi-check success"></i>
      </ng-template>
    </c-table>
  </div>
</div>
<c-sidebar [header]="(editMode ? ('GENERAL_EDIT' | translate) : ('GENERAL_ADD' | translate)) + ' ' + 'Operating Country'" [(visible)]="showSidebar" (onClose)="handleSidebarClose()">
  <div class="sidebar-tabs sidebar-body__no-left-padding">
    <button [class.active]="sidebarTab === 'general'" (click)="sidebarTab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
    <button [class.active]="sidebarTab === 'mappedIsoCodes'" (click)="sidebarTab = 'mappedIsoCodes'">{{ 'PAGE_OPERATING_COUNTRIES_MAPPED_ISO_CODES' | translate }}</button>
    <button [class.active]="sidebarTab === 'favoriteLanguages'" (click)="sidebarTab = 'favoriteLanguages'">{{ 'PAGE_OPERATING_COUNTRIES_FAVORITE_LANGUAGES' | translate }}</button>
  </div>
  <div *ngIf="operatingCountry && sidebarTab === 'general'" class="sidebar-body sidebar-body__no-left-padding">
    <c-collaboration-patch-input
      [object]="operatingCountry"
      [collaborationKey]="'operatingCountry:' + operatingCountry._id"
      [jsonpath]="'$.internalName'"
      [inputs]="inputs"
      [inputConfiguration]="inputConfig"
    >
    </c-collaboration-patch-input>
    <c-collaboration-patch-input
      [object]="operatingCountry"
      [collaborationKey]="'operatingCountry:' + operatingCountry._id"
      [jsonpath]="'$.isoCode'"
      [inputs]="inputs"
      [inputConfiguration]="inputConfig"
    ></c-collaboration-patch-input>
    <c-collaboration-patch-input
      [object]="operatingCountry"
      [collaborationKey]="'operatingCountry:' + operatingCountry._id"
      [jsonpath]="'$.local.$language.title'"
      [inputs]="inputs"
      [inputConfiguration]="inputConfig"
    >
    </c-collaboration-patch-input>
    <c-collaboration-patch-input [object]="operatingCountry" [collaborationKey]="'operatingCountry:' + operatingCountry._id" [jsonpath]="'$.flag'" [inputs]="inputs" [inputConfiguration]="inputConfig">
    </c-collaboration-patch-input>
    <c-collaboration-patch-input
      [object]="operatingCountry"
      [collaborationKey]="'operatingCountry:' + operatingCountry._id"
      [jsonpath]="'$.belongsToRegion'"
      [inputs]="inputs"
      [inputConfiguration]="inputConfig"
    >
    </c-collaboration-patch-input>
    <c-collaboration-patch-input [object]="operatingCountry" [collaborationKey]="'operatingCountry:' + operatingCountry._id" [jsonpath]="'$.tags'" [inputs]="inputs" [inputConfiguration]="inputConfig">
    </c-collaboration-patch-input>
    <c-collaboration-patch-input
      [object]="operatingCountry"
      [collaborationKey]="'operatingCountry:' + operatingCountry._id"
      [jsonpath]="'$.isDefaultOperatingCountry'"
      [inputs]="inputs"
      [inputConfiguration]="inputConfig"
    >
    </c-collaboration-patch-input>
  </div>
  <div *ngIf="operatingCountry && sidebarTab === 'mappedIsoCodes'" class="sidebar-body">
    <c-collaboration-patch-input
      [object]="operatingCountry"
      [collaborationKey]="'operatingCountry:' + operatingCountry._id"
      [jsonpath]="'$.mappedIsoCodes'"
      [inputs]="inputs"
      [inputConfiguration]="inputConfig"
    >
    </c-collaboration-patch-input>
  </div>

  <div *ngIf="operatingCountry && sidebarTab === 'favoriteLanguages'" class="sidebar-body">
    <c-collaboration-patch-input
      class="operating-countries__favorite-languages-input"
      [object]="operatingCountry"
      [collaborationKey]="'operatingCountry:' + operatingCountry._id"
      [jsonpath]="'$.favoriteLanguages'"
      [inputs]="inputs"
      [inputConfiguration]="inputConfig"
    >
    </c-collaboration-patch-input>
  </div>

  <c-sidebar-footer
    *hasRight="['configuration.edit']"
    [showSaveButton]="true"
    (onSave)="save(); showSidebar = false"
    [showDeleteButton]="editMode && hasDeleteRight"
    (onDelete)="delete(); handleSidebarClose()"
  >
  </c-sidebar-footer>
</c-sidebar>
