import { ImageTagTextLinkPageModuleAttributes } from './../../entities/pagemodules/ImageTagTextLinkPageModule';
import { HeadlineType } from './../../entities/Attributes';
import { ImageTagTextLinkPageModule } from '../../entities/pagemodules/ImageTagTextLinkPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class ImageTagTextLinkPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public createImageTagTextLinkPageModuleLocalItem(values: any) {
    return {
      image: values?.image || null,
      title: values?.title || '',
      tag: values?.tag || '',
      text: values?.text || '',
      linkType: values?.linkType || null,
      link: values?.link || '',
      width: values?.width || 'full',
    };
  }

  public imageTagTextLinkPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): ImageTagTextLinkPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public createImageTagTextLinkPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.imageTagTextLinkPageModuleAttributes(values?.title_attr),
      items: (values?.items || []).map((i: any) => this.createImageTagTextLinkPageModuleLocalItem(i)),
    };
  }

  public async imageTagTextLinkPageModule(values: any): Promise<ImageTagTextLinkPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'ImageTagTextLink',
      titlePosition: values?.titlePosition || 'left',
      local: await this.local(values, (val) => this.createImageTagTextLinkPageModuleLocal(val)),
      ctaButton: values?.ctaButton || null,
    };
  }
}
