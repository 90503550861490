import { Component, Input, OnInit } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { CURRENT_LANGUAGE_FILTER, EventsListPageModule } from 'src/common/entities/PageModule';

import { InputConfiguration } from 'src/common/inputs/Inputs';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { KeytopicsService } from 'src/app/services/keytopics/keytopics.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { EventsService } from 'src/app/services/events/events.service';

@Component({
  selector: 'c-eventslist-page-module-edit',
  templateUrl: './eventslist-page-module-edit.component.html',
  styleUrls: ['./eventslist-page-module-edit.component.scss'],
})
export class EventsListPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: EventsListPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathes: string[] = ['$.local.$language.title', '$.titlePosition', '$.displayAs', '$.ctaButton', '$.anchor'];

  inputJsonpathesButtons: string[] = [
    '$.showTicketInformation',
    '$.showTicketCancellationButton',
    '$.showEventShareButton',
    '$.showEventCalendarButton',
    '$.showEventBookmarkButton',
    '$.showEventRecommendationButton',
    '$.showSortButton',
  ];

  inputJsonpathesFilter: string[] = [
    '$.eventTypes',
    '$.keytopics',
    '$.dates',
    '$.languages',
    '$.countries',
    '$.sorting',
    '$.defaultSortingField',
    '$.defaultSortingDirection',
    '$.myBookmarkedEvents',
    '$.myBookedEvents',
    '$.showWithSessionVideosOnly',
    '$.eventSeries',
  ];

  inputConfigurationKeytopics: InputConfiguration = {};
  inputConfigurationLanguages: InputConfiguration = {};
  inputConfigurationCountries: InputConfiguration = {};
  inputConfigurationEventTypes: InputConfiguration = {};
  inputConfigurationEventSeries: InputConfiguration = {};

  initialized: boolean = false;
  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  constructor(private languagesService: LanguagesService, private keytopicsService: KeytopicsService, private configurationService: ConfigurationService, private eventsService: EventsService) {}

  // tslint:disable-next-line:typedef
  async ngOnInit() {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
    const [eventTypes, keytopics, activeLanguages, countries, eventSeries] = await Promise.all([
      this.eventsService.getEventTypes(),
      this.keytopicsService.getKeytopics(),
      this.languagesService.getActiveLanguagesAsPromise(),
      this.configurationService.getLookups('countries'),
      this.eventsService.getEventSeries(),
    ]);

    this.inputConfigurationKeytopics = {
      type: 'multiselect',
      multiselectOptions: keytopics.items.map((k) => ({ label: k.internalName, value: k._id })),
    };

    this.inputConfigurationLanguages = {
      type: 'multiselect',
      multiselectOptions: [{ label: 'Current User language selection', value: CURRENT_LANGUAGE_FILTER }].concat(activeLanguages.map((l) => ({ label: l.languageName, value: l.language }))),
    };

    this.inputConfigurationEventTypes = {
      type: 'multiselect',
      multiselectOptions: eventTypes.items.map((e) => ({ label: e.internalName, value: e._id })),
    };

    const _countries = countries['local']['en'];
    const mappedCountries = Object.keys(_countries).map((key) => [key, _countries[key]]);
    this.inputConfigurationCountries = {
      type: 'multiselect',
      multiselectOptions: mappedCountries.map((c) => ({ label: c[1], value: c[0] })),
    };

    this.inputConfigurationEventSeries = {
      type: 'multiselect',
      multiselectOptions: eventSeries.items.map((e) => ({ label: e.internalName, value: e._id })),
    };

    this.initialized = true;
  }
}
