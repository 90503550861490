<div class="select-button">
  <button class="input-button" (click)="showSearch = true">
    <span *ngIf="customData">{{ customData.internalName }}</span>
    <span *ngIf="!customData">None</span>
  </button>
  <a *ngIf="customData" [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="remove()"><i class="pi pi-times"></i></a>
</div>

<c-sidebar [header]="'COMPONENT_CUSTOMDATA_SELECT_SEARCH_HEADER' | translate" [(visible)]="showSearch">
  <div class="sidebar-search">
    <div class="search-input input-group compact">
      <div class="input">
        <input #searchInput placeholder="Search custom data ..." pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
      </div>
    </div>
    <div class="search-results">
      <div class="search-result text" *ngFor="let customData of searchResult" (click)="selectCustomData(customData)">
        {{ customData.internalName }}
      </div>
    </div>
  </div>

  <!-- <div class="loading">
  <c-loading [loading]="loading" [hideTitle]="true"></c-loading>
</div> -->
</c-sidebar>
