import { AppFunctionFactory } from "../../factories/AppFunctionFactory";
import { Inputs } from "../Inputs";

export const appFunctionInputs: Inputs = {
  '$.local.$language': {
    factory: async () => new AppFunctionFactory().createAppFunctionLocal({}),
  },
  '$.action': {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Open QR Scan', value: 'openQRScan' },
      { label: 'Open In-App Link', value: 'openInAppLink' },
      { label: 'Request Mobile Location Rights', value: 'requestLocationRights' },
    ],
  },
  "$.local.$language.actionData": {
    type: 'text',
  },
};
