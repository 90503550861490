import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { ApiService } from '../api/api.service';
import { ApiSocketService } from '../api-socket/api-socket.service';
// import { isNullOrUndefined } from 'util';
import { IBody, IFilterableListQuery, IQuery } from 'src/common/api/interfaces';
import { mergeMap } from 'rxjs/operators';

export interface CacheContainerConfig<T> {
  // type: string;
  maxAge?: number;
  get?: (_id: string) => Promise<T>;
  fill?: () => Promise<T[]>;
  id?: (value: T) => string;
  groups?: {
    name: string;
    filter: (value: T) => boolean;
    fill?: () => Promise<T[]>;
    query?: (query: IFilterableListQuery, currentValues: T[]) => Promise<T[]>;
    maxAge?: number;
    maxMembers?: number;
  }[];
  socketEvents?: string[];
  transform?: (value: T) => void;
}

export class CacheContainer<T> {
  private _initialized: Promise<void>;

  private _config: CacheContainerConfig<T>;
  private _api: ApiService;
  private _apiSocket: ApiSocketService;
  private _subjectAll: BehaviorSubject<T[]>;

  private _cache: {
    [_id: string]: {
      value: T;
      subjects: Subject<T>[];
      promise: Promise<T>;
    };
  } = {};

  private _groups: {
    [name: string]: {
      filled: boolean;
      _ids: string[];
      subjectAll: BehaviorSubject<T[]>;
      subjectLast: BehaviorSubject<T>;
    };
  } = {};

  constructor(config: CacheContainerConfig<T>, api: ApiService, apiSocket: ApiSocketService) {
    this._config = config;
    this._api = api;
    this._apiSocket = apiSocket;
    this._subjectAll = new BehaviorSubject<T[]>([]);

    for (const group of this._config.groups || []) {
      this._groups[group.name] = {
        filled: false,
        _ids: [],
        subjectAll: new BehaviorSubject<T[]>([]),
        subjectLast: new BehaviorSubject<T>(null),
      };
    }

    for (const event of this._config.socketEvents || []) {
      this._apiSocket.on<T>(event, (args) => {
        if (args instanceof Array) {
          this.fill(args, true);
        } else {
          this.add(args);
        }
      });
    }

    this._initialized = new Promise<void>((resolve: () => void, reject: (err: any) => void) => {
      if (this._config.fill) {
        this._config
          .fill()
          .then((values: T[]) => {
            this.fill(values);
            resolve();
          })
          .catch(reject);
      } else {
        resolve();
      }
    });
  }

  ready(): Promise<void> {
    return this._initialized;
  }

  asObservable(_id: string, refresh: boolean = false): Observable<T> {
    if (!this._cache[_id]) {
      this._cache[_id] = {
        value: null,
        subjects: [],
        promise: null,
      };
    }

    if (this._cache[_id].subjects.length === 0 || refresh) {
      let subject = new ReplaySubject<T>(1);
      this._cache[_id].subjects.push(subject);

      if ((refresh || this._cache[_id].value === null) && this._config.get) {
        this.ready().then(() => {
          this._config.get(_id).then((value) => {
            this.add(value, _id);
          });
        });
      } else {
        subject.next(this._cache[_id].value);
      }

      return subject.asObservable();
    } else {
      return this._cache[_id].subjects[this._cache[_id].subjects.length - 1].asObservable();
    }
  }

  async asPromise(_id: string, refresh: boolean = false): Promise<T> {
    if (!this._cache[_id]) {
      this._cache[_id] = {
        value: null,
        subjects: [],
        promise: null,
      };
    }

    if (!this._cache[_id].promise || refresh) {
      this._cache[_id].promise = new Promise<T>(async (resolve: (result: T) => void, reject: (err: any) => void) => {
        if (this._cache[_id].value && !refresh) {
          resolve(this._cache[_id].value);
          return;
        }

        if (this._config.get) {
          await this.ready();
          let value = null;
          await this._config.get(_id).then(
            (v) => {
              value = v;
              this.add(value, _id);
              resolve(value);
            },
            () => {
              reject(new Error(`Could not get value for ${_id}`));
            }
          );
        } else {
          reject(new Error(`Could not get value for ${_id}`));
        }
      });
    }

    return this._cache[_id].promise;
  }

  async refresh(_id: string): Promise<T> {
    return this.asPromise(_id, true);
  }

  values(group?: string): T[] {
    return ((group ? this._groups[group]._ids : Object.keys(this._cache)).map((_id) => this._cache[_id].value) || []).filter((v) => v !== null && typeof v !== 'undefined');
  }

  add(value: T, _id?: string, emitNext: boolean = true) {
    _id = _id || this.id(value);

    if (this._config.transform) {
      this._config.transform(value);
    }

    if (!this._cache[_id]) {
      this._cache[_id] = {
        value: value,
        subjects: [],
        promise: null,
      };
    } else {
      this._cache[_id].value = value;
      this._cache[_id].subjects.forEach((s) => s.next(value));
      this._cache[_id].promise = null;
    }

    for (const groupName of Object.keys(this._groups)) {
      const groupConfig = this._config.groups.find((g) => g.name === groupName);
      const group = this._groups[groupName];

      if (groupConfig.filter(value)) {
        group._ids = Array.from(new Set(group._ids.concat([_id])));

        if (groupConfig.maxMembers) {
          while (group._ids.length > groupConfig.maxMembers) {
            group._ids.shift();
          }
        }

        if (emitNext) {
          group.subjectAll.next(group._ids.map((_id) => this._cache[_id].value));
        }
      } else {
        const index = group._ids.indexOf(_id);

        if (index >= 0) {
          group._ids.splice(index, 1);

          if (emitNext) {
            group.subjectAll.next(group._ids.map((_id) => this._cache[_id].value));
          }
        }
      }

      if (emitNext) {
        let lastValue = group._ids.length > 0 ? this._cache[group._ids[group._ids.length - 1]].value : null;
        group.subjectLast.next(lastValue);
      }
    }

    if (emitNext) {
      this._subjectAll.next(
        Object.keys(this._cache)
          .map((c) => this._cache[c].value)
          .filter((c) => c !== null)
      );
    }
  }

  fill(values: T[], override: boolean = false) {
    for (let i = 0; i < values.length; i++) {
      const value = values[i];

      if (override || !this._cache[this.id(value)]) {
        this.add(value, this.id(value), true);
      }
    }
  }

  private async fillGroup(group: string) {
    this._groups[group].filled = true;
    const groupConfig = this._config.groups.find((g) => g.name === group);

    if (groupConfig.fill) {
      this.fill(await groupConfig.fill());
    }
  }

  refill(values: T[]) {
    this.clear();
    this.fill(values, true);
  }

  id(value: T): string {
    const _id = this._config.id ? this._config.id(value) : value['_id'];
    if (!_id) throw Error('Could not get id from value');
    return _id;
  }

  all(): Observable<T[]> {
    return this._subjectAll.asObservable();
  }

  async allAsPromise(): Promise<T[]> {
    return Object.values(this._cache).map((k) => k.value);
  }

  remove(_id: string) {
    if (this._cache[_id]) {
      this._cache[_id].subjects.forEach((s) => s.complete());
      delete this._cache[_id];
    }
  }

  group(group: string, query?: IFilterableListQuery): Observable<T[]> {
    if (!this._groups[group].filled) {
      this.fillGroup(group);
    }

    if (query) {
      const config = this._config.groups.find((g) => g.name === group);

      if (!config) {
        throw new Error('Invalid group name!');
      }

      if (config.query) {
        return this._groups[group].subjectAll.asObservable().pipe(
          mergeMap((values: T[]) => {
            return config.query(query, values);
          })
        );
      }
    }

    return this._groups[group].subjectAll.asObservable();
  }

  last(group: string): Observable<T> {
    return this._groups[group].subjectLast.asObservable();
  }

  clear() {
    for (const groupName of Object.keys(this._groups)) {
      this.clearGroup(groupName);
    }
    for (const _id of Object.keys(this._cache)) {
      this.remove(_id);
    }
    this._subjectAll.next([]);
  }

  length() {
    return Object.keys(this._cache).length;
  }

  clearGroup(group: string) {
    this._groups[group]._ids = [];
    this._groups[group].subjectAll.next([]);
    this._groups[group].subjectLast.next(null);
  }

  async get<Q extends IQuery, R extends T>(url: string, params?: any): Promise<T> {
    let result = await this._api.get<Q, R>(url, params).toPromise();
    this.add(result);
    return result;
  }

  async post<Q extends IQuery, B extends IBody, R extends T>(url: string, body: any): Promise<T> {
    let result = await this._api.post<Q, B, R>(url, body).toPromise();
    this.add(result);
    return result;
  }
}
