import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { Text3DImagePageModuleFactory } from './../../factories/pagemodules/Text3DImagePageModuleFactory';
import { titlePositions } from '../../entities/PageModule';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const text3DImagePageModulesInputs: Inputs = {
  ...commonPageModuleInputs('Text3DImage'),
  "$.pageModules[?(@.pageModuleType=='Text3DImage' && @._id=='$pageModuleId')].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='Text3DImage' && @._id=='$pageModuleId')].image": {},
  "$.pageModules[?(@.pageModuleType=='Text3DImage' && @._id=='$pageModuleId')].imagePosition": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Left', value: 'left' },
      { label: 'Right', value: 'right' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='Text3DImage' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new Text3DImagePageModuleFactory().createText3DImagePageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='Text3DImage' && @._id=='$pageModuleId')].local.$language.image": {},
  "$.pageModules[?(@.pageModuleType=='Text3DImage' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='Text3DImage' && @._id=='$pageModuleId')].local.$language.text": {},
  "$.pageModules[?(@.pageModuleType=='Text3DImage' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [...titlePositions].map((item) => ({
      label: item,
      value: item,
    })),
  },
};
