import { EventTicket } from './../../../common/entities/EventTicket';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EventSlot, EventTimeSlot } from 'src/common/entities/EventSlot';
import { EventVersion } from 'src/common/entities/EventVersion';

type EventSlots = {
  eventSlot: string | EventSlot;
  eventTimeSlots: string[] | EventTimeSlot[];
}[];

@Component({
  selector: 'c-event-slots-edit',
  templateUrl: './event-slots-edit.component.html',
  styleUrls: ['./event-slots-edit.component.scss'],
})
export class EventSlotsEditComponent implements OnInit, OnChanges {
  @Input()
  eventVersion: EventVersion;

  @Input()
  disabled: boolean = false;

  @Input()
  eventSlots: EventSlots;

  @Input()
  eventTicket: string | EventTicket;

  @Output()
  eventSlotsChange: EventEmitter<EventSlots> = new EventEmitter<EventSlots>();

  ticketEventSlots: EventSlot[] = [];

  selectedEventSlots: { [eventSlotId: string]: boolean } = {};
  selectedEventTimeSlots: { [eventTimeSlotId: string]: boolean } = {};

  constructor() {}

  ngOnInit(): void {
    this.defineTimeSlots();
  }

  private defineTimeSlots() {
    this.selectedEventSlots = {};
    this.selectedEventTimeSlots = {};
    this.eventSlots.forEach(({ eventSlot, eventTimeSlots }) => {
      const eventSlotId = typeof eventSlot === 'string' ? eventSlot : eventSlot._id;
      const hasTicketThisSlot = this.ticketEventSlots.some(({ _id }) => _id === eventSlotId);
      if (hasTicketThisSlot) {
        this.selectedEventSlots[eventSlotId] = true;
        eventTimeSlots.forEach((eventTimeSlot) => {
          const eventTimeSlotId = typeof eventTimeSlot === 'string' ? eventTimeSlot : eventTimeSlot._id;
          this.selectedEventTimeSlots[eventTimeSlotId] = true;
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eventTicket) {
      this.ticketEventSlots = this.eventVersion.eventSlots.filter((e) => e.eventTickets.includes(changes.eventTicket.currentValue));
    }
  }

  eventSlotChanged(eventSlot: EventSlot) {
    for (const eventTimeSlot of eventSlot.eventTimeSlots) {
      this.selectedEventTimeSlots[eventTimeSlot._id] = this.selectedEventSlots[eventSlot._id];
    }

    this.updateEventSlots();
  }

  eventTimeSlotChanged(eventSlot: EventSlot, eventTimeSlot: EventTimeSlot) {
    if (this.selectedEventTimeSlots[eventTimeSlot._id]) {
      this.selectedEventSlots[eventSlot._id] = true;
    } else {
      if (!eventSlot.eventTimeSlots.some((t) => this.selectedEventTimeSlots[t._id])) {
        this.selectedEventSlots[eventSlot._id] = false;
      }
    }

    this.updateEventSlots();
  }

  updateEventSlots() {
    const eventSlots: EventSlots = [];

    for (const selectedEventSlot of Object.keys(this.selectedEventSlots)) {
      const eventSlot = this.eventVersion.eventSlots.find((e) => e._id === selectedEventSlot);

      eventSlots.push({
        eventSlot: selectedEventSlot,
        eventTimeSlots: eventSlot.eventTimeSlots.filter((t) => this.selectedEventTimeSlots[t._id]).map((t) => t._id),
      });
    }

    this.eventSlots = eventSlots;
    this.eventSlotsChange.emit(this.eventSlots);
  }
}
