import { Pipe, PipeTransform } from '@angular/core';
import { AdminUsersService } from 'src/app/services/admin-users/admin-users.service';
import { AdminUser } from 'src/common/entities/AdminUser';

@Pipe({
  name: 'adminUser',
})
export class AdminUserPipe implements PipeTransform {
  constructor(private adminUserService: AdminUsersService) {}

  transform(user: string, ...args: unknown[]): Promise<AdminUser> {
    if (!user) return null;

    return this.adminUserService.getAdminUser(user);
  }
}
