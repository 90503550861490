import { Injectable } from '@angular/core';
import { ApiSocketService } from '../api-socket/api-socket.service';
import { ApiService } from '../api/api.service';
import { CacheContainer, CacheContainerConfig } from './cache-container';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  constructor(private api: ApiService, private apiSocket: ApiSocketService) {}

  create<T>(config: CacheContainerConfig<T>): CacheContainer<T> {
    return new CacheContainer<T>(config, this.api, this.apiSocket);
  }
}
