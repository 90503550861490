<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'PAGE_CSP_REPORTS_HEADER' | translate }}</h1>
  </div>
  <div class="tab-menu">
    <p-button (onClick)="refresh()" label="Refresh" icon="pi icon-ic-refresh" [disabled]="working"></p-button>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_CSP_REPORTS_HEADER' | translate }}
      <div class="actions">
        <p-button
          (onClick)="confirmDeleteMessage($event)"
          icon="pi pi-trash"
          [disabled]="working || !selection || selection?.length === 0"
          [label]="'Delete Selected (' + (selection?.length || '0') + ')'"
        ></p-button>
        <c-table-options #toc></c-table-options>
      </div>
    </div>
    <c-table mode="query" [options]="tableOptions" (query)="query($event)" [tableOptionsComponent]="toc" (selectionChange)="select($event)">
      <ng-template let-report>{{ report.date | date : 'short' }}</ng-template>
      <ng-template let-report>{{ report.domain }}</ng-template>
      <ng-template let-report>{{ report.userAgent }}</ng-template>
      <ng-template let-report>{{ report.cspReport.disposition }}</ng-template>
      <ng-template let-report>{{ report.cspReport.effectiveDirective }}</ng-template>
      <ng-template let-report>{{ report.cspReport.blockedURI }}</ng-template>
      <ng-template let-report>{{ report.cspReport.sourceFile }}</ng-template>
      <ng-template let-report>{{ report.cspReport.lineNumber }}</ng-template>
      <ng-template let-report>{{ report.cspReport.columnNumber }}</ng-template>
      <ng-template let-report>{{ report.cspReport.originalPolicy }}</ng-template>
      <ng-template let-report>{{ report.cspReport.documentURI }}</ng-template>
      <ng-template let-report>{{ report.cspReport.statusCode }}</ng-template>
    </c-table>
  </div>
  <p-confirmPopup [key]="'deleteConfirmPopup'"></p-confirmPopup>
</div>
