export const privacyPolicyModes = ['enforce', 'auto', 'disabled'] as const;
export type PrivacyPolicyMode = (typeof privacyPolicyModes)[number];
export const termsAndConditionsModes = ['enforce', 'auto', 'disabled'] as const;
export type TermsAndConditionsMode = (typeof termsAndConditionsModes)[number];
export const logoClickUrlTargetOptions = ['sameTab', 'newTab'] as const;
export type LogoClickUrlTargetOption = (typeof logoClickUrlTargetOptions)[number];
export const languageAndCountryModes = ['languageOnly', 'languageViaCountry'] as const;
export type LanguageAndCountryMode = (typeof languageAndCountryModes)[number];

export interface PlatformConfiguration {
  singleEvent?: string;
  /**
   * @deprecated the default setting for pages is now in global pages section
   */
  defaultPage?: string;
  logoClickUrl?: string;
  logoClickUrlTarget?: LogoClickUrlTargetOption;
  defaultEventRegistration?: string;
  navigationTargetPage?: string;
  privacyPolicyMode?: PrivacyPolicyMode;
  termsAndConditionsMode?: TermsAndConditionsMode;
  enableSearchFeature?: boolean;
  languageAndCountryMode?: LanguageAndCountryMode;
  loginProtected?: boolean;
  enableContentSecurityPolicy?: boolean;
  contentSecurityPolicy?: string;
  explorePage?: string;
  defaultOnboarding?: string;
}
