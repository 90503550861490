<div class="sidebar-search">
  <div class="search-input input-group compact">
    <div class="input">
      <input #searchInput placeholder="Search asset ..." pInputText (input)="handleInput($event)" />
    </div>
  </div>
  <div *ngIf="mimeTypes.length === 0 || !includeChip()" class="mimeFilters">
    <p-checkbox [(ngModel)]="imageFilterChecked" [binary]="true" (click)="handleFilterChange()" [disabled]="!mimeTypes.includes(image) && !(mimeTypes.length === 0)" label="Images"></p-checkbox>
    <p-checkbox [(ngModel)]="videoFilterChecked" [binary]="true" (click)="handleFilterChange()" [disabled]="!mimeTypes.includes(video) && !(mimeTypes.length === 0)" label="Videos"></p-checkbox>
    <p-checkbox
      [(ngModel)]="documentFilterChecked"
      [binary]="true"
      (click)="handleFilterChange()"
      [disabled]="!mimeTypes.includes(document) && !(mimeTypes.length === 0)"
      label="Documents"
    ></p-checkbox>
  </div>
  <div class="filter">
    <span *ngIf="mimeTypes.length > 0 && includeChip()">
      <p-chip *ngFor="let mimeType of mimeTypes" [label]="'COMPONENT_ASSET_SELECT_SEARCH_CHIP_MIME_TYPE' | translate : { mimeType: mimeType }"></p-chip>
    </span>
    <span *ngIf="width"><p-chip [label]="'COMPONENT_ASSET_SELECT_SEARCH_CHIP_WIDTH' | translate : { width: width }"></p-chip></span>
    <span *ngIf="minWidth"><p-chip [label]="'COMPONENT_ASSET_SELECT_SEARCH_CHIP_MIN_WIDTH' | translate : { minWidth: minWidth }"></p-chip></span>
    <span *ngIf="height"><p-chip [label]="'COMPONENT_ASSET_SELECT_SEARCH_CHIP_HEIGHT' | translate : { height: height }"></p-chip></span>
    <span *ngIf="minHeight"><p-chip [label]="'COMPONENT_ASSET_SELECT_SEARCH_CHIP_MIN_HEIGHT' | translate : { minHeight: minHeight }"></p-chip></span>
  </div>
  <c-table [$refresh]="$refresh" mode="query" [singleTemplate]="true" [options]="tableOptions" (query)="setQuery($event)">
    <ng-template let-asset #row>
      <tr (click)="selectAsset(asset)">
        <td>
          <c-asset-item [asset]="asset" [disableDetails]="false" [openEdit]="false"></c-asset-item>
        </td>
      </tr>
    </ng-template>
  </c-table>
</div>
