import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiSocketService {
  private _socket: Socket | null = null;
  private _listeners: { [event: string]: ((args: any) => void)[] } = {};
  private _bufferedEmits: { event: string; args: any }[] = [];

  public get socketId(): string {
    return this._socket?.id;
  }

  constructor(private environment: Environment) {}

  connect() {
    if (this._socket) {
      this.disconnect();
    }

    const token = localStorage.getItem('token');

    this._socket = io(this.environment.apiEndpoint, {
      path: '/ws/v1/websocket',
      upgrade: true,
      // autoConnect: true,
      auth: { token },

      // transports: ['websocket']
    });

    this._socket.on('connect', () => {
      (this._listeners['connect'] || []).forEach((c) => c({}));
    });

    let bufferedEmit: { event: string; args: any } | null | undefined = null;
    while ((bufferedEmit = this._bufferedEmits.shift())) {
      this.emit(bufferedEmit.event, bufferedEmit.args);
    }
  }

  disconnect() {
    if (this._socket) {
      this._socket.disconnect();
      this._socket = null;
    }
  }

  on<T>(event: string, callback: (args: T) => void) {
    if (this._socket) {
      this._socket.on(event, callback);
    }

    if (!this._listeners[event]) {
      this._listeners[event] = [];
    }

    this._listeners[event].push(callback);
  }

  removeListener(event: string, callback: (args: any) => void) {
    if (this._socket) {
      this._socket.off(event, callback);
    }
    this._listeners[event] = this._listeners[event].filter((c) => c !== callback);
  }

  removeAllListeners() {
    if (this._socket) {
      this._socket.offAny();
    }
    this._listeners = {};
  }

  removeEventListeners(event: string) {
    if (this._socket) {
      this._socket.off(event);
    }
    this._listeners[event] = [];
  }

  emit<T>(event: string, args: T) {
    if (this._socket) {
      this._socket.emit(event, args);
    } else {
      this._bufferedEmits.push({
        event: event,
        args: args,
      });
    }
  }
}
