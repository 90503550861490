<div class="sidebar-tabs">
  <button [class.active]="tab === 'general'" (click)="tab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
  <button [class.active]="tab === 'speakers'" (click)="tab = 'speakers'">{{ 'GENERAL_SPEAKERS' | translate }}</button>
  <button [class.active]="tab === 'sorting'" (click)="tab = 'sorting'">{{ 'GENERAL_SORTING' | translate }}</button>
  <button [class.active]="tab === 'design'" (click)="tab = 'design'">{{ 'GENERAL_DESIGN' | translate }}</button>
  <button [class.active]="tab === 'accesspolicy'" (click)="tab = 'accesspolicy'">{{ 'GENERAL_CONDITIONS' | translate }}</button>
</div>

<div *ngIf="tab == 'general'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.displayAs']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.personDetailPage']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.anchor']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.linkable']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>
<div *ngIf="tab === 'speakers'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.speakers']" [jsonpathParams]="jsonpathParams" [inputConfiguration]="inputConfigurationSpeaker" [object]="eventVersion || page"></c-page-patch-input>
</div>
<div *ngIf="tab == 'sorting'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.defaultSortingField']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.defaultSortingDirection']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <span class="speaker-border"></span>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.elements']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"> </c-page-patch-input>
</div>
<div *ngIf="tab == 'design'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.backgroundTheme']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"> </c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingTop']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingBottom']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingLeft']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingRight']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>
<div *ngIf="tab == 'accesspolicy'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.accessPolicy']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page" [inputConfiguration]="accessInputConfiguration"></c-page-patch-input>
</div>
