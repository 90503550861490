import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { StreamsService } from 'src/app/services/streams/streams.service';
import { StreamStatus, streamStatusChangeAllowed, StreamWithSessions } from 'src/common/entities/Stream';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'c-stream-details',
  templateUrl: './stream-details.component.html',
  styleUrls: ['./stream-details.component.scss'],
})
export class StreamDetailsComponent implements OnInit {
  loading = true;

  @Input()
  stream: StreamWithSessions | null = null;

  @Input()
  executeStreamOperation: null | ((streamPromise: Promise<StreamWithSessions>) => Promise<StreamWithSessions | void>) = null;

  constructor(private streamsService: StreamsService, private clipboard: Clipboard, private confirmationService: ConfirmationService) {}

  async ngOnInit() {
    this.loading = false;
  }

  private async _executeStreamOperation(streamPromise: Promise<StreamWithSessions>) {
    this.loading = true;
    if (this.executeStreamOperation) {
      await this.executeStreamOperation(streamPromise);
    } else {
      await streamPromise;
    }
    this.loading = false;
  }

  async activateStream() {
    this._executeStreamOperation(this.streamsService.activateStream(this.stream._id));
  }

  async deactivateStream() {
    this._executeStreamOperation(this.streamsService.deactivateStream(this.stream._id));
  }

  async startStream() {
    this._executeStreamOperation(this.streamsService.startStream(this.stream._id));
  }

  async stopStream(event) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to stop the stream?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this._executeStreamOperation(this.streamsService.stopStream(this.stream._id));
      },
    });
  }

  async standbyStream() {
    this._executeStreamOperation(this.streamsService.standbyStream(this.stream._id));
  }

  async deleteStream() {
    this._executeStreamOperation(this.streamsService.deleteStream(this.stream._id));
  }

  async refreshStream() {
    this._executeStreamOperation(this.streamsService.refreshStream(this.stream._id));
  }

  streamStatusChangeAllowed(oldStreamStatus: StreamStatus, newStreamStatus: StreamStatus): boolean {
    return streamStatusChangeAllowed(oldStreamStatus, newStreamStatus);
  }

  copyToClipboard(text: string) {
    this.clipboard.copy(text);
  }

  async internalNameBlur() {
    this._executeStreamOperation(this.streamsService.updateStream(this.stream._id, { internalName: this.stream.internalName }));
  }
}
