import { Pipe, PipeTransform } from '@angular/core';
import { TagsService } from 'src/app/services/tags/tags.service';
import { Tag, TaxonomyNode } from 'src/common/entities/Tag';

@Pipe({
  name: 'tag',
})
export class TagPipe implements PipeTransform {
  constructor(private tagsService: TagsService) {}

  transform(tagId: string, ...args: unknown[]): Promise<TaxonomyNode<Tag>> {
    return this.tagsService.getTag(tagId) as Promise<TaxonomyNode<Tag>>;
  }
}
