import { InputConfiguration } from 'src/common/inputs/Inputs';
import { FormsService } from 'src/app/services/forms/forms.service';
import { FormPageModule } from './../../../../common/entities/pagemodules/FormPageModule';
import { Page } from 'src/common/entities/Page';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-page-module-edit',
  templateUrl: './form-page-module-edit.component.html',
  styleUrls: ['./form-page-module-edit.component.scss'],
})
export class FormPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: FormPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  tab = 'general';

  initialized: boolean = false;

  inputConfigurationForm: InputConfiguration = {};

  constructor(private formsService: FormsService) {}

  inputJsonpathes: string[] = ['$.titlePosition', '$.buttonFullWidth', '$.anchor'];

  accessInputConfiguration: InputConfiguration;

  async ngOnInit(): Promise<void> {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
    this.inputConfigurationForm = {
      type: 'dropdown',
      dropdownOptions: [
        {
          label: 'None',
          value: null,
        },
      ].concat((await this.formsService.getForms()).items.map((e) => ({ label: e.internalName, value: e._id }))),
    };

    this.initialized = true;
  }
}
