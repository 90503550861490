<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_EVENT_SECTION_SLOTS_CONFIGURATION' | translate }}
  </div>
  <div class="panel-body">
    <c-event-patch-input jsonpath="$.eventSlotConfiguration.customTicketSlotHeader" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input
      jsonpath="$.eventSlotConfiguration.local.$language.customTitle"
      *ngIf="eventVersion.eventSlotConfiguration.customTicketSlotHeader"
      [eventVersion]="eventVersion"
    ></c-event-patch-input>
    <c-event-patch-input
      jsonpath="$.eventSlotConfiguration.local.$language.customDescription"
      *ngIf="eventVersion.eventSlotConfiguration.customTicketSlotHeader"
      [eventVersion]="eventVersion"
    ></c-event-patch-input>
  </div>
</div>
<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_EVENT_SECTION_SLOTS' | translate }}
    <c-tag-timezone></c-tag-timezone>
    <div class="actions">
      <button
        *hasRight="'events.editContent'"
        pButton
        type="button"
        [label]="' ' + ('PAGE_EVENT_SECTION_SLOTS_ADD' | translate)"
        icon="pi icon-ic-add-item"
        class="p-button"
        (click)="addEventSlot()"
      ></button>
    </div>
  </div>

  <c-table [options]="eventSlotsTableOptions" [data]="eventVersion.eventSlots">
    <ng-template let-eventSlot>
      <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="editableEventSlot = eventSlot">
        {{ eventSlot.startAt ? (eventSlot.startAt | timeZoneByUrl | async | date : 'short') : ('GENERAL_UNNAMED' | translate) }}
      </a>
    </ng-template>
    <ng-template let-eventSlot>{{ eventSlot.endAt | timeZoneByUrl | async | date : 'short' }}</ng-template>
    <ng-template let-eventSlot>{{ eventSlot.capacity }}</ng-template>
    <ng-template let-eventSlot>{{ eventSlot.warningCapacity }}</ng-template>
    <ng-template let-eventSlot>
      <div *ngFor="let ticket of eventSlot.eventTickets">
        {{ getTicketInfo(ticket) }}
      </div>
    </ng-template>
  </c-table>
</div>

<p-sidebar *ngIf="showEventSlotEdit && editableEventSlot" [(visible)]="showEventSlotEdit" styleClass="p-sidebar-st" position="right" [modal]="true" [appendTo]="'body'" [showCloseIcon]="false">
  <c-sidebar-header
    [headerTitle]="'PAGE_EVENT_SECTION_SLOTS_EDIT_SLOT' | translate"
    [headerSubTitle]="(editableEventSlot.startAt | timeZoneByUrl | async | date : 'short') || ('GENERAL_UNNAMED' | translate)"
    (onClose)="showEventSlotEdit = false"
  ></c-sidebar-header>

  <div class="sidebar-tabs">
    <button [class.active]="eventSlotEditTab == 'general'" (click)="eventSlotEditTab = 'general'">General</button>
    <button [class.active]="eventSlotEditTab == 'timeslots'" (click)="eventSlotEditTab = 'timeslots'">Time Slots</button>
  </div>

  <div class="sidebar-body" *ngIf="editableEventSlot && eventSlotEditTab === 'general'">
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventSlots[?(@._id=='$eventSlot')].startAt" [jsonpathParams]="{ eventSlot: editableEventSlot._id }"></c-event-patch-input>
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventSlots[?(@._id=='$eventSlot')].endAt" [jsonpathParams]="{ eventSlot: editableEventSlot._id }"></c-event-patch-input>
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventSlots[?(@._id=='$eventSlot')].capacity" [jsonpathParams]="{ eventSlot: editableEventSlot._id }"></c-event-patch-input>
    <c-event-patch-input [eventVersion]="eventVersion" jsonpath="$.eventSlots[?(@._id=='$eventSlot')].warningCapacity" [jsonpathParams]="{ eventSlot: editableEventSlot._id }"></c-event-patch-input>
    <c-event-patch-input
      [eventVersion]="eventVersion"
      jsonpath="$.eventSlots[?(@._id=='$eventSlot')].eventTickets"
      [jsonpathParams]="{ eventSlot: editableEventSlot._id }"
      [inputConfiguration]="inputConfigurationTickets"
    ></c-event-patch-input>
  </div>

  <div *ngIf="eventSlotEditTab === 'timeslots'" class="sidebar-outer-body">
    <p-accordion>
      <p-accordionTab
        *ngFor="let editableEventTimeSlot of editableEventSlot?.eventTimeSlots; let index = index"
        [header]="'Time Slot ' + (index + 1) + ' - ' + (editableEventTimeSlot?.startAt | timeZoneByUrl | async | date : 'short')"
      >
        <div class="sidebar-body">
          <c-event-patch-input
            [eventVersion]="eventVersion"
            jsonpath="$.eventSlots[?(@._id=='$eventSlot')].eventTimeSlots[?(@._id=='$timeSlot')].local.$language.title"
            [jsonpathParams]="{ eventSlot: editableEventSlot._id, timeSlot: editableEventTimeSlot._id }"
          ></c-event-patch-input>
          <c-event-patch-input
            [eventVersion]="eventVersion"
            jsonpath="$.eventSlots[?(@._id=='$eventSlot')].eventTimeSlots[?(@._id=='$timeSlot')].startAt"
            [jsonpathParams]="{ eventSlot: editableEventSlot._id, timeSlot: editableEventTimeSlot._id }"
          ></c-event-patch-input>
          <c-event-patch-input
            [eventVersion]="eventVersion"
            jsonpath="$.eventSlots[?(@._id=='$eventSlot')].eventTimeSlots[?(@._id=='$timeSlot')].endAt"
            [jsonpathParams]="{ eventSlot: editableEventSlot._id, timeSlot: editableEventTimeSlot._id }"
          ></c-event-patch-input>
        </div>
        <div class="accordion-footer p-d-flex">
          <div class="p-ml-auto"></div>
          <button
            *hasRight="'events.editContent'"
            pButton
            type="button"
            class="p-button-sm p-button-outlined"
            [label]="'GENERAL_DELETE' | translate"
            icon="pi pi-trash"
            (click)="deleteTimeSlot(editableEventTimeSlot)"
            [disabled]="eventVersion | isDisabled"
          ></button>
        </div>
      </p-accordionTab>
    </p-accordion>

    <div class="sidebar-footer" *hasRight="'events.editContent'">
      <button
        pButton
        type="button"
        class="p-button-sm p-button-outlined"
        [label]="'PAGE_EVENT_SECTION_SLOTS_ADD_TIME_SLOT' | translate"
        icon="pi icon-ic-add-item"
        (click)="addTimeSlot()"
        [disabled]="eventVersion | isDisabled"
      ></button>
    </div>
  </div>

  <c-sidebar-footer *hasRight="'events.editContent'" [autoSaveInfo]="true" [showDeleteButton]="true" (onDelete)="onDeleteSlot(editableEventSlot); showEventSlotEdit = false"> </c-sidebar-footer>
</p-sidebar>
