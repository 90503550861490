<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'PAGE_OPERATING_REGIONS' | translate }}</h1>
  </div>
  <div class="tab-menu">
    <div class="tab-menu">
      <c-language-button *hasRight="'configuration.edit'" [languages]="languages" [(currentLanguage)]="currentLanguage" (languagesChange)="ensureLanguages($event)"></c-language-button>
      <c-language-button
        *hasNoRight="'configuration.edit'"
        [languages]="languages"
        [(currentLanguage)]="currentLanguage"
        (languagesChange)="ensureLanguages($event)"
        [manageLanguages]="false"
      ></c-language-button>
    </div>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_OPERATING_REGIONS' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button *hasRight="'configuration.create'" [label]="'New Region'" icon="pi icon-ic-add-item" (onClick)="create()"></p-button>
      </div>
    </div>
    <c-table #operatingRegionsTable mode="query" [options]="tableOptions" [tableOptionsComponent]="toc" (query)="query($event)" (itemSelect)="edit($event)" [queryParamsHandling]="'merge'">
      <ng-template let-item let-index="index">
        <div *hasRight="['configuration.edit', 'configuration.read']">
          {{ item.internalName || ('GENERAL_EMPTY' | translate) }}
        </div>
        <span *hasNoRight="['configuration.edit', 'configuration.read']">
          {{ item.internalName || ('GENERAL_EMPTY' | translate) }}
        </span>
      </ng-template>
      <ng-template let-item>
        {{ item.sortOrder | translate }}
      </ng-template>
    </c-table>
  </div>
</div>
<c-sidebar [header]="(editMode ? ('GENERAL_EDIT' | translate) : ('GENERAL_ADD' | translate)) + ' ' + 'Operating Region'" [(visible)]="showSidebar" (onClose)="showSidebar = false; editMode = false">
  <div *ngIf="operatingRegion">
    <c-collaboration-patch-input
      [object]="operatingRegion"
      [collaborationKey]="'operatingRegion:' + operatingRegion._id"
      [jsonpath]="'$.internalName'"
      [inputs]="inputs"
      [inputConfiguration]="inputConfig"
    >
    </c-collaboration-patch-input>
    <c-collaboration-patch-input
      [object]="operatingRegion"
      [collaborationKey]="'operatingRegion:' + operatingRegion._id"
      [jsonpath]="'$.local.$language.title'"
      [inputs]="inputs"
      [inputConfiguration]="inputConfig"
    >
    </c-collaboration-patch-input>
    <c-collaboration-patch-input
      [object]="operatingRegion"
      [collaborationKey]="'operatingRegion:' + operatingRegion._id"
      [jsonpath]="'$.sortOrder'"
      [inputs]="inputs"
      [inputConfiguration]="inputConfig"
    >
    </c-collaboration-patch-input>
  </div>
  <c-sidebar-footer
    *hasRight="'configuration.edit'"
    [showSaveButton]="true"
    (onSave)="save(); showSidebar = false"
    [showDeleteButton]="editMode && hasDeleteRight"
    (onDelete)="delete(); showSidebar = false"
  >
  </c-sidebar-footer>
</c-sidebar>
