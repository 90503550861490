import { CTAButton, ShareCTATarget } from '../CTAButton';
import { EventStage } from './EventStage';

export const textEventStageLayout = ['Default', 'BackgroundFocused'] as const;
export type TextEventStageLayout = (typeof textEventStageLayout)[number];

export interface TextEventStage extends EventStage {
  eventStageType: 'Text';
  layout: TextEventStageLayout;
  showShareButton: boolean;
  shareButtonTargets?: ShareCTATarget[];
  showCalendarButton: boolean;
  local: { [language: string]: TextEventStageLocal };
  ctaButton?: CTAButton;
  ctalistInteraction?: [];
  ctalist?: [];
  showCountdown: boolean;
}

export interface CTAList {
  _id: string;
  ctaButton: CTAButton;
}

export interface TextEventStageLocal {
  smallHeadline: string;
  text: string;
}

export function isTextEventStage(object: any): object is TextEventStage {
  return object?.eventStageType === 'Text';
}

export function createEventStageLocal(values?: any) {
  return {
    smallHeadline: values?.smallHeadline || '',
    text: values?.text || '',
  } as TextEventStageLocal;
}

export async function createCTAList(values: any, newId: (previousId: string) => Promise<string>) {
  return {
    _id: await newId(values?._id),
    ctaButton: values?.ctaButton || null,
  } as CTAList;
}

export async function createTextEventStage(values: any, newId: (previousId: string) => Promise<string>) {
  return {
    _id: await newId(values?._id),
    eventStageType: 'Text',
    layout: values?.layout || 'Default',
    background: values?.background || null,
    local: Object.keys(values?.local || {}).reduce((a, b) => ({ ...a, [b]: createEventStageLocal(values.local[b]) }), {}),
    ctaButton: values?.ctaButton || null,
    ctalistInteraction: Object.keys(values?.ctalistInteraction || {}).reduce((a, b) => ({ ...a, [b]: createCTAList(values.ctalistInteraction[b], newId) }), {}),
    ctalist: Object.keys(values?.ctalistInteraction || {}).reduce((a, b) => ({ ...a, [b]: createCTAList(values.ctalistInteraction[b], newId) }), {}),
  } as TextEventStage;
}
