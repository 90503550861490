import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';

// declare var amp: any; // todo: use the amp d.ts

const ampLoaded = new Promise<boolean>((resolve: (successful: boolean) => void, reject: (err: any) => void) => {
  const linkTag = document.createElement('link');
  linkTag.rel = 'stylesheet';
  linkTag.href = '//amp.azure.net/libs/amp/2.3.11/skins/amp-default/azuremediaplayer.min.css';
  document.head.insertBefore(linkTag, document.head.firstChild);

  const scriptTag = document.createElement('script');
  scriptTag.src = '//amp.azure.net/libs/amp/2.3.11/azuremediaplayer.min.js';
  scriptTag.id = 'ng-amp';
  scriptTag.onload = () => {
    resolve(true);
  };
  document.body.appendChild(scriptTag);
});

@Component({
  selector: 'c-amp',
  template: `
    <video #video class="azuremediaplayer amp-default-skin vjs-big-play-centered" controls>
      <p class="amp-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video.</p>
    </video>
  `,
  styleUrls: ['./amp.component.scss'],
})
export class AmpComponent implements OnChanges, OnDestroy {
  @ViewChild('video') videoPlayer;

  @Input() src: string;
  @Input() autoplay: boolean;
  @Input() muted: boolean;
  @Input() width: number;
  @Input() height: number;

  @Output()
  position: EventEmitter<number> = new EventEmitter<number>();

  player: amp.Player;

  constructor() {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.src.currentValue !== changes.src.previousValue && changes.src.currentValue) {
      this.initSrc();
    }
  }

  ngOnDestroy() {
    this.dispose();
  }

  dispose() {
    if (this.player) {
      this.player.removeEventListener(amp.eventName.timeupdate);
      this.player.dispose();
      this.player = null;
    }
  }

  private async initSrc() {
    // await this.ready.promise;
    await ampLoaded;

    this.dispose();

    const config = {
      autoplay: this.autoplay,
      width: this.width,
      height: this.height,
      muted: this.muted,
      logo: { enabled: false },
    };

    this.player = amp(this.videoPlayer.nativeElement, config);

    this.player.src([
      {
        src: this.src,
        type: 'application/vnd.ms-sstr+xml',
      },
    ]);

    this.position.emit(0);

    this.player.addEventListener(amp.eventName.timeupdate, () => {
      this.position.emit(this.player.currentTime());
    });
  }
}
