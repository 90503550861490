import { HeadlineType } from '../../entities/Attributes';
import { actionTypes, ProductList2PageModule, ProductList2PageModuleAttributes, ProductList2PageModuleGroup, ProductList2PageModuleSeries } from '../../entities/pagemodules/ProductList2PageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class ProductList2PageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public productList2PageModuleLocal(values: any) {
    return {
      title: values?.title || '',
    };
  }

  public productList2PageModuleLocalWithAttributes(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.productList2PageModuleAttributes(values?.title_attr),
    };
  }

  public productList2PageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): ProductList2PageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async productList2PageModuleGroup(values: any): Promise<ProductList2PageModuleGroup> {
    return {
      _id: await this.id(values?._id),
      local: await this.local(values, (val) => this.productList2PageModuleLocal(val)),
      series: await Promise.all((values?.series || []).map((e: any) => this.productList2PageModuleSeries(e))),
    };
  }

  public async productList2PageModuleSeries(values: any): Promise<ProductList2PageModuleSeries> {
    return {
      _id: await this.id(values?._id),
      local: await this.local(values, (val) => this.productList2PageModuleLocal(val)),
      products: values?.products || [],
    } as ProductList2PageModuleSeries;
  }

  public async productList2PageModule(values?: any): Promise<ProductList2PageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'ProductList2',
      showHeadline: values?.showHeadline || false,
      showHeadlineGroup: typeof values?.showHeadlineGroup === 'boolean' ? values?.showHeadlineGroup : true,
      showHeadlineSeries: typeof values?.showHeadlineSeries === 'boolean' ? values?.showHeadlineSeries : true,
      local: await this.local(values, (val) => this.productList2PageModuleLocalWithAttributes(val)),
      action: values?.action || actionTypes[0],
      titlePosition: values?.titlePosition || 'left',
      displayAs: values?.displayAs || 'Slider',
      ctaButton: values?.ctaButton || null,
      groups: await Promise.all((values?.groups || []).map((e: any) => this.productList2PageModuleGroup(e))),
      productImageSize: values?.productImageSize || 'default',
      productScope: values?.productScope || 'manual',
      maxProductCount: values?.maxProductCount || 6,
    };
  }
}
