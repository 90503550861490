import { Event } from 'src/common/entities/Event';
import { isSessionWithSessionMedia, LiveSessionMedia, Session, SessionWithSessionMedia } from 'src/common/entities/Session';
import { Chat } from 'src/common/entities/Chat';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { EventsService } from 'src/app/services/events/events.service';
import { ChatsService } from 'src/app/services/chats/chats.service';
import { Inputs } from 'src/common/inputs/Inputs';
import { actualEndAt, actualStartAt } from 'src/common/inputs/event/EventVersionSessionsInputs';
import { Preview } from 'src/common/entities/Preview';
import { StreamWithSessions } from '../../../../common/entities/Stream';
import { StreamsService } from '../../../services/streams/streams.service';
import { EventVersion } from '../../../../common/entities/EventVersion';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-live-sessions',
  templateUrl: './live-sessions.component.html',
  styleUrls: ['./live-sessions.component.scss'],
  providers: [ConfirmationService],
})
export class LiveSessionsComponent implements OnInit, OnDestroy {
  @Input()
  event: Event = null;

  get eventVersion(): EventVersion {
    return this.event.currentEventVersion;
  }

  // eventVersion: EventVersion = null;
  preview: Preview = null;
  showVersions = false;
  showPreview = false;

  @Input()
  viewers: {
    [session: string]: {
      live: number;
      demand: number;
    };
  } = {};

  loading = true;
  sessionDates: string[] = [];
  agendaData: { [day: string]: Session[] } = {};
  liveSessions: string[] = [];
  liveInterval: any;

  session: Session = null;
  originalSession: Session = null;
  showSessionEdit = false;
  undoChanges = false;

  rightToReadStreams: boolean = false;

  chats: { [chatId: string]: Promise<Chat> };
  streams: { [streamId: string]: StreamWithSessions };

  sessionsInputs: Inputs = {
    '$.actualStartAt': actualStartAt,
    '$.actualEndAt': actualEndAt,
  };

  get isSessionChanged(): boolean {
    if (this.session) {
      return JSON.stringify(this.originalSession) !== JSON.stringify(this.session);
    }
    return true;
  }

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private eventsService: EventsService,
    private chatsService: ChatsService,
    private streamsService: StreamsService,
    private configurationService: ConfigurationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.rightToReadStreams = this.authService.hasGlobalRight(['streams.list', 'streams.create', 'streams.edit', 'streams.delete']);

    this.loading = false;
    this.setAgendaData();

    this.liveInterval = setInterval(() => {
      this.checkLiveSessions();
    }, 1000);
  }

  checkLiveSessions(): void {
    const serverTime = this.configurationService.serverTime();

    this.liveSessions = this.eventVersion.sessions
      .filter((s) => {
        return new Date(s.actualStartAt) <= serverTime && serverTime <= new Date(s.actualEndAt);
      })
      .map((s) => s._id);
  }

  ngOnDestroy(): void {
    if (this.liveInterval) {
      clearInterval(this.liveInterval);
    }
  }

  setAgendaData(): void {
    this.sessionDates = [
      ...new Set(
        (this.eventVersion?.sessions || [])
          .map((s) => new Date(s.startAt))
          .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
          .map((d) => d.toLocaleDateString())
      ),
    ];

    this.agendaData = {};
    this.chats = {};
    this.streams = {};

    for (const day of this.sessionDates) {
      this.agendaData[day] = this.eventVersion.sessions
        .filter((s) => new Date(s.startAt).toLocaleDateString() === day)
        .sort((a, b) => {
          return new Date(a.startAt).getTime() - new Date(b.startAt).getTime();
        });
      const daySessions = this.agendaData[day];
      for (const daySession of daySessions) {
        for (const local of Object.keys(daySession.local)) {
          if (daySession.local[local].chat) {
            const chtId = daySession.local[local].chat;
            if (!this.chats[chtId]) {
              this.chats[chtId] = this.chatsService.getChat(chtId);
            }
          }
        }
      }
    }

    const streamIds = this.eventVersion.sessions
      .filter((s) => isSessionWithSessionMedia(s))
      .map((s) => s as SessionWithSessionMedia)
      .map((s) => Object.values(s.sessionMedia) as LiveSessionMedia[])
      .flatMap((sms) => sms.map((sm) => sm.stream));

    if (this.rightToReadStreams) {
      this.streamsService
        .getStreams({
          limit: 1000,
          filter: {
            _id: {
              matchMode: 'inObjectId',
              value: streamIds,
            },
          },
        })
        .then((r) => {
          r.items.forEach((sm) => (this.streams[sm._id] = sm));
        });
    }
  }

  toStreamSession(session): SessionWithSessionMedia {
    return session;
  }

  toStreamId(lang): string {
    return (lang?.value as LiveSessionMedia)?.stream;
  }

  isLive(session: Session): boolean {
    return this.liveSessions.includes(session._id);
  }

  getChatName(chat: Chat): string {
    return chat?.internalName || this.translate.instant('GENERAL_UNNAMED');
  }

  getChatInfo(chat: Chat): string {
    if (chat) {
      return `${chat?.publishedMessagesCount} (${chat?.publishedMessagesCountCustomersOnly}) / ${chat?.requestedMessageCount} (${chat?.requestedMessageCountCustomersOnly})`;
    } else {
      return '0 (0) / 0 (0)';
    }
  }

  editSession(session: Session): void {
    // Init session to edit
    this.session = { ...session };
    // To be able to undo changes, deep object copy is neccessary here
    this.undoChanges = true;
    this.originalSession = JSON.parse(JSON.stringify(this.session));
    // Show dialog
    this.showSessionEdit = true;
  }

  closeSessionDialog(): void {
    if (!this.undoChanges) {
      // If data really changed ...
      if (JSON.stringify(this.originalSession) !== JSON.stringify(this.session)) {
        // ... update sessions data
        this.execute(this.eventsService.updateSession(this.event._id, this.session, this.preview?.token)).then((event) => {
          Object.assign(this.event, event);
          this.setAgendaData();
        });
      }
    }
    // Reset session dialog var's
    this.showSessionEdit = false;
    this.session = null;
    this.originalSession = null;
  }

  startSession(session: Session): void {
    this.execute(this.eventsService.startSession(this.event._id, session, this.preview?.token)).then((event) => {
      Object.assign(this.event, event);
      this.setAgendaData();
    });
  }

  stopSession(event, session: Session): void {
    this.confirmationService.confirm({
      target: event.target,
      message: this.translate.instant('PAGE_LIVE_OVERVIEW_CONFIRM_STOP_SESSION'),
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        this.execute(this.eventsService.stopSession(this.event._id, session, this.preview?.token)).then((event) => {
          Object.assign(this.event, event);
          this.setAgendaData();
        });
      },
    });
  }

  async execute<T>(promise: Promise<T>): Promise<T> {
    this.loading = true;
    let result: T;
    try {
      result = await promise;
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
    return result;
  }
}
