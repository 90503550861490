/**
 * GET /api/v1/configuration/pages
 */

import { IFilterableListQuery, IListResponse, IResponseError } from '../../../interfaces';
import { Page } from '../../../../entities/Page';

export enum PageViewType {
  TREE = 'tree',
  LIST = 'list',
}

export type PageWithChildPages = Page & { childPages?: PageWithChildPages[] };

export interface GetPagesQuery extends IFilterableListQuery {
  domainCollection?: string;
  path?: string;
  view?: PageViewType;
}

export interface GetPagesResponse extends IListResponse<PageWithChildPages> {}

export interface GetPagesResponseError extends IResponseError {}
