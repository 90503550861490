<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_EVENT_SECTION_SESSIONS' | translate }}
    <c-tag-timezone></c-tag-timezone>
    <div class="actions" *hasRight="'events.editContent'">
      <p-splitButton [label]="'PAGE_EVENT_SESSIONS_ADD' | translate" icon="pi icon-ic-add-session" (onClick)="addSession()" [model]="addSessionItems"></p-splitButton>
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <th class="selected">Start At</th>
        <th>End At</th>
        <th>Title</th>
        <th>Actual Start At</th>
        <th>Actual End At</th>
        <th>Language</th>
      </tr>
    </thead>
    <tbody *ngFor="let sessions of eventVersion.sessions | byDate : '$.startAt' : GroupingMode.DAY | keyvalue">
      <tr class="subheader">
        <td colspan="6">{{ 'GENERAL_DAY' | translate }} {{ (sessions.key | timeZoneByUrl | async | date : 'shortDate') || 'Unknown' }}</td>
      </tr>
      <tr *ngFor="let session of sessions.value">
        <td class="selected">
          <a (click)="openSidebar(session)" [routerLink]="[]" [queryParamsHandling]="'merge'">
            {{ session.startAt ? (session.startAt | timeZoneByUrl | async | date : 'shortTime') : ('GENERAL_UNKNOWN' | translate) }}
          </a>
        </td>
        <td>{{ session.endAt ? (session.endAt | timeZoneByUrl | async | date : 'shortTime') : '' }}</td>
        <td>
          <div *ngFor="let language of session.local | keyvalue">
            {{ session.local[language.key].title || ('GENERAL_UNNAMED' | translate) }}
          </div>
        </td>
        <td>{{ session.actualStartAt ? (session.actualStartAt | timeZoneByUrl | async | date : 'shortTime') : '' }}</td>
        <td>{{ session.actualEndAt ? (session.actualEndAt | timeZoneByUrl | async | date : 'shortTime') : '' }}</td>
        <td>
          <div *ngFor="let language of session.local | keyvalue">
            {{ (language.key | language | async)?.languageName }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<p-sidebar [(visible)]="showSessionSidebar" styleClass="p-sidebar-st" position="right" [modal]="true" [showCloseIcon]="false">
  <c-sidebar-header *ngIf="session" [headerTitle]="'PAGE_EVENT_SECTION_SESSIONS' | translate" (onClose)="showSessionSidebar = false; session = null"></c-sidebar-header>

  <div class="sidebar-tabs">
    <button [class.active]="sessionEditTab == 'general'" (click)="sessionEditTab = 'general'">General</button>
    <button *ngIf="session && session.sessionType === 'BreakoutSession'" [class.active]="sessionEditTab == 'breakouts'" (click)="sessionEditTab = 'breakouts'">Breakouts</button>
    <button [class.active]="sessionEditTab == 'speakers'" (click)="sessionEditTab = 'speakers'">Speakers</button>
    <button [class.active]="sessionEditTab == 'related_content'" (click)="sessionEditTab = 'related_content'">Related Content</button>
    <button [class.active]="sessionEditTab == 'keytopics'" (click)="sessionEditTab = 'keytopics'">Keytopics</button>
    <button [class.active]="sessionEditTab == 'stages'" (click)="sessionEditTab = 'stages'">Stages</button>
  </div>

  <div class="sidebar-body" *ngIf="session && sessionEditTab == 'general'">
    <c-event-patch-input jsonpath="$.sessions[?(@._id=='$sessionId')].startAt" [jsonpathParams]="{ sessionId: session._id }" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.sessions[?(@._id=='$sessionId')].endAt" [jsonpathParams]="{ sessionId: session._id }" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.sessions[?(@._id=='$sessionId')].local.$language.title" [jsonpathParams]="{ sessionId: session._id }" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.sessions[?(@._id=='$sessionId')].local.$language.description" [jsonpathParams]="{ sessionId: session._id }" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input
      jsonpath="$.sessions[?(@._id=='$sessionId')].local.$language.chat"
      [jsonpathParams]="{ sessionId: session._id }"
      [eventVersion]="eventVersion"
      [inputConfiguration]="{ type: 'dropdown', dropdownOptions: chatOptions }"
    ></c-event-patch-input>
    <c-event-patch-input jsonpath="$.sessions[?(@._id=='$sessionId')].maxChatMessages" [jsonpathParams]="{ sessionId: session._id }" [eventVersion]="eventVersion"></c-event-patch-input>

    <div *ngIf="isSessionWithMedia(session)">
      <c-event-patch-input
        jsonpath="$.sessions[?(@._id=='$sessionId')].sessionMedia.$language.sessionMediaType"
        [jsonpathParams]="{ sessionId: session._id }"
        [eventVersion]="eventVersion"
      ></c-event-patch-input>
      <c-event-patch-input
        jsonpath="$.sessions[?(@._id=='$sessionId')].sessionMedia.$language.stream"
        [jsonpathParams]="{ sessionId: session._id }"
        [eventVersion]="eventVersion"
        [inputConfiguration]="streamInputConfiguration"
      ></c-event-patch-input>
      <c-event-patch-input
        jsonpath="$.sessions[?(@._id=='$sessionId')].local.$language.chat"
        [jsonpathParams]="{ sessionId: session._id }"
        [eventVersion]="eventVersion"
        [inputConfiguration]="{ type: 'dropdown', dropdownOptions: chatOptions }"
      ></c-event-patch-input>
      <c-event-patch-input
        jsonpath="$.sessions[?(@._id=='$sessionId')].images.main"
        header="teaser image"
        [jsonpathParams]="{ sessionId: session._id }"
        [eventVersion]="eventVersion"
      ></c-event-patch-input>
      <c-event-patch-input
        jsonpath="$.sessions[?(@._id=='$sessionId')].local.$language.highlightTeaser"
        [jsonpathParams]="{ sessionId: session._id }"
        [eventVersion]="eventVersion"
      ></c-event-patch-input>
      <c-event-patch-input jsonpath="$.sessions[?(@._id=='$sessionId')].sessionMedia.$language.asset" [jsonpathParams]="{ sessionId: session._id }" [eventVersion]="eventVersion"></c-event-patch-input>
    </div>
    <c-event-patch-input jsonpath="$.sessions[?(@._id=='$sessionId')].highlight" [jsonpathParams]="{ sessionId: session._id }" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.sessions[?(@._id=='$sessionId')].hidden" [jsonpathParams]="{ sessionId: session._id }" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.sessions[?(@._id=='$sessionId')].skippable" [jsonpathParams]="{ sessionId: session._id }" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.sessions[?(@._id=='$sessionId')].actualStartAt" [jsonpathParams]="{ sessionId: session._id }" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.sessions[?(@._id=='$sessionId')].actualEndAt" [jsonpathParams]="{ sessionId: session._id }" [eventVersion]="eventVersion"></c-event-patch-input>
  </div>

  <div class="sidebar-body" *ngIf="session && sessionEditTab == 'breakouts'">
    <div *ngIf="session | fn : asBreakoutSession as breakoutSession">
      <div *ngFor="let breakout of breakoutSession.breakouts; trackBy: trackByForBreakout">
        <c-event-patch-input
          jsonpath="$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')].link"
          [jsonpathParams]="{ sessionId: session._id, breakoutId: breakout._id }"
          [eventVersion]="eventVersion"
        ></c-event-patch-input>
        <c-event-patch-input
          jsonpath="$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')].local.$language.title"
          [jsonpathParams]="{ sessionId: session._id, breakoutId: breakout._id }"
          [eventVersion]="eventVersion"
        ></c-event-patch-input>
        <c-event-patch-input
          jsonpath="$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')].local.$language.shortTitle"
          [jsonpathParams]="{ sessionId: session._id, breakoutId: breakout._id }"
          [eventVersion]="eventVersion"
        ></c-event-patch-input>
        <c-event-patch-input
          jsonpath="$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')].local.$language.description"
          [jsonpathParams]="{ sessionId: session._id, breakoutId: breakout._id }"
          [eventVersion]="eventVersion"
        ></c-event-patch-input>
        <c-event-patch-input
          jsonpath="$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')].asset"
          [jsonpathParams]="{ sessionId: session._id, breakoutId: breakout._id }"
          [eventVersion]="eventVersion"
        ></c-event-patch-input>

        <c-event-patch-input
          jsonpath="$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')].speakers"
          [jsonpathParams]="{ sessionId: session._id, breakoutId: breakout._id }"
          [eventVersion]="eventVersion"
          [inputConfiguration]="breakoutSpeakerInputConfiguration"
        ></c-event-patch-input>

        <c-event-patch-input
          jsonpath="$.sessions[?(@._id=='$sessionId')].breakouts[?(@._id=='$breakoutId')].keytopics"
          [jsonpathParams]="{ sessionId: session._id, breakoutId: breakout._id }"
          [eventVersion]="eventVersion"
          [inputConfiguration]="breakoutKeytopicInputConfiguration"
        ></c-event-patch-input>

        <div class="action-breakout">
          <div>
            <button pButton type="button" class="p-button-sm p-button-outlined p-mr-2" icon="pi pi-arrow-up" (click)="breakoutUp(breakoutSession, breakout)"></button>
            <button pButton type="button" class="p-button-sm p-button-outlined" icon="pi pi-arrow-down" (click)="breakoutDown(breakoutSession, breakout)"></button>
          </div>
          <button pButton type="button" class="p-button-sm p-button-outlined" [label]="'GENERAL_DELETE' | translate" icon="pi pi-trash" (click)="removeBreakout(breakoutSession, breakout)"></button>
        </div>
        <p-divider></p-divider>
      </div>

      <div class="input-group compact">
        <button pButton type="button" [label]="'PAGE_EVENT_SESSIONS_SESSION_SPEAKER_ADD' | translate" class="p-button" (click)="addBreakout(breakoutSession)"></button>
      </div>
    </div>
  </div>

  <div class="sidebar-body" *ngIf="session && sessionEditTab == 'speakers'">
    <div *ngIf="asSpeakerSession(session) || asBreakoutSession(session) as speakerSession">
      <div class="input-group compact">
        <div class="input">
          <p-autoComplete
            [disabled]="disabled"
            [(ngModel)]="currentSessionSpeaker"
            [suggestions]="filteredSessionSpeakers"
            [forceSelection]="true"
            (completeMethod)="filterSessionSpeakers($event)"
            [dropdown]="true"
            field="displayName"
          ></p-autoComplete>
        </div>
        <div class="title">{{ 'PAGE_EVENT_SESSIONS_SESSION_SPEAKER_NAME' | translate }}</div>
      </div>
      <div class="input-group compact">
        <div class="input">
          <p-dropdown
            [disabled]="disabled"
            [options]="this.speakerRoles | dropdownOptions : 'GENERAL_SPEAKER_ROLE' | async"
            [(ngModel)]="currentSessionSpeakerRole"
            optionLabel="name"
            optionValue="code"
          ></p-dropdown>
        </div>
        <div class="title">{{ 'PAGE_EVENT_SESSIONS_SESSION_SPEAKER_ROLE' | translate }}</div>
      </div>
      <div class="input-group compact">
        <button
          [disabled]="disabled"
          pButton
          type="button"
          [disabled]="!currentSessionSpeaker"
          [label]="' ' + ('PAGE_EVENT_SESSIONS_SESSION_SPEAKER_ADD' | translate)"
          class="p-button"
          (click)="addCurrentSessionSpeaker()"
        ></button>
      </div>
      <p-divider *ngIf="speakerSession?.speakers?.length > 0"></p-divider>
      <div *ngIf="speakerSession?.speakers?.length > 0">
        <div class="speaker-chip" *ngFor="let speakerrole of speakerSession.speakers">
          <p-chip
            [label]="(speakerrole.speaker | speaker | async)?.displayName + ' - ' + ('GENERAL_SPEAKER_ROLE_' + speakerrole.role.toUpperCase() | translate)"
            [removable]="!disabled"
            (onRemove)="removeSessionSpeaker(speakerrole.speaker)"
          ></p-chip>
        </div>
      </div>
    </div>
  </div>
  <div class="sidebar-body" *ngIf="session && sessionEditTab == 'related_content'">
    <div *ngIf="currentLanguage && session.local[currentLanguage]">
      <div>
        <div class="input-group compact">
          <div class="input">
            <p-dropdown
              [disabled]="disabled"
              [options]="relatedContentTypes | dropdownOptions : 'GENERAL_EVENT_SESSION_RELATED_CONTENT_TYPE' | async"
              [(ngModel)]="relatedContent.relatedContentType"
              optionLabel="name"
              optionValue="code"
              (onChange)="relatedContentTypeChanged($event)"
            ></p-dropdown>
          </div>
          <div class="title">{{ 'PAGE_EVENT_SESSIONS_SESSION_RELATED_CONTENT_TYPE' | translate }}</div>
        </div>
        <div class="input-group compact" *ngIf="isLinkRelatedContent(relatedContent)">
          <div class="input">
            <input [disabled]="disabled" type="text" pInputText [(ngModel)]="asLinkRelatedContent(relatedContent).title" />
          </div>
          <div class="title">{{ 'PAGE_EVENT_SESSIONS_SESSION_RELATED_CONTENT_LINK_TITLE' | translate }}</div>
        </div>
        <div class="input-group compact" *ngIf="isLinkRelatedContent(relatedContent)">
          <div class="input">
            <input [disabled]="disabled" type="text" pInputText [(ngModel)]="asLinkRelatedContent(relatedContent).link" />
          </div>
          <div class="title">{{ 'PAGE_EVENT_SESSIONS_SESSION_RELATED_CONTENT_LINK' | translate }}</div>
        </div>
        <div class="input-group compact" *ngIf="!isLinkRelatedContent(relatedContent)">
          <div class="input">
            <c-asset-select [(id)]="asAssetRelatedContent(relatedContent).asset" [mimeType]="['video/*', 'application/pdf']" inputWidth="100%"></c-asset-select>
          </div>
          <div class="title">{{ 'PAGE_EVENT_SESSIONS_SESSION_RELATED_CONTENT_ASSET' | translate }}</div>
        </div>
        <div class="input-group compact">
          <button [disabled]="disabled" pButton [label]="'PAGE_EVENT_SESSIONS_SESSION_ADD_RELATED_CONTENT' | translate" (click)="addRelatedContent()"></button>
        </div>
      </div>
      <p-divider></p-divider>
      <div *ngFor="let relatedContent of session.local[currentLanguage].relatedContent; let index = index">
        <div *ngIf="asLinkRelatedContent(relatedContent)">
          <a [href]="asLinkRelatedContent(relatedContent).link" target="_blank">{{ asLinkRelatedContent(relatedContent).title }}</a>
          <button
            pButton
            type="button"
            [label]="' ' + ('PAGE_EVENT_SESSIONS_SESSION_REMOVE_RELATED_CONTENT' | translate)"
            icon="pi pi-trash"
            class="p-button-text p-button-danger p-button-sm"
            (click)="removeRelatedContent(index)"
          ></button>
        </div>
        <div *ngIf="asAssetRelatedContent(relatedContent)">
          <c-asset-select [disabled]="disabled" mimeType="video/*" [(id)]="asAssetRelatedContent(relatedContent).asset" inputWidth="100%"></c-asset-select>
          <button
            [disabled]="disabled"
            pButton
            type="button"
            [label]="' ' + ('PAGE_EVENT_SESSIONS_SESSION_REMOVE_RELATED_CONTENT' | translate)"
            icon="pi pi-trash"
            class="p-button-text p-button-danger p-button-sm"
            (click)="removeRelatedContent(index)"
          ></button>
        </div>
      </div>
    </div>
  </div>

  <div class="sidebar-body" *ngIf="session && sessionEditTab == 'keytopics'">
    <div class="input-group compact">
      <div class="input">
        <p-autoComplete
          [disabled]="disabled"
          [(ngModel)]="currentSessionKeytopic"
          [suggestions]="filteredSessionKeytopics"
          [forceSelection]="true"
          (completeMethod)="filterSessionKeytopics($event)"
          field="internalName"
          [dropdown]="true"
        ></p-autoComplete>
      </div>
      <div class="title">{{ 'PAGE_EVENT_SESSIONS_SESSION_KEYTOPICS' | translate }}</div>
    </div>

    <div class="input-group compact">
      <button
        pButton
        type="button"
        [disabled]="!currentSessionKeytopic || disabled"
        [label]="'PAGE_EVENT_SESSIONS_SESSION_KEYTOPIC_ADD' | translate"
        class="p-button"
        (click)="addCurrentSessionKeytopic()"
      ></button>
    </div>

    <p-divider></p-divider>
    <div>
      <div class="speaker-chip" *ngFor="let keytopic of session.keytopics; let index = index">
        <p-chip [label]="(keytopic | keytopic | async)?.internalName" [removable]="!disabled" (onRemove)="removeSessionKeytopic(index)"></p-chip>
      </div>
    </div>
  </div>

  <div class="sidebar-body" *ngIf="session && sessionEditTab == 'stages'">
    <div class="input-group compact">
      <div class="input">
        <p-dropdown [disabled]="disabled" [options]="stageOptions" [(ngModel)]="currentSessionStage"></p-dropdown>
      </div>
      <div class="title">{{ 'PAGE_EVENT_SESSIONS_STAGES' | translate }}</div>
    </div>

    <div class="input-group compact">
      <button pButton type="button" [disabled]="!currentSessionStage || disabled" label="Add" class="p-button" (click)="addCurrentSessionStage()"></button>
    </div>

    <p-divider></p-divider>
    <div>
      <div class="speaker-chip" *ngFor="let stage of session.stages; let index = index">
        <p-chip [label]="stageName(stage)" [removable]="!disabled" (onRemove)="removeSessionStage(index)"></p-chip>
      </div>
    </div>
  </div>

  <c-sidebar-footer
    *hasRight="'events.editContent'"
    [showDeleteButton]="true"
    (onDelete)="deleteSession(session)"
    [showOptionalButton]="true"
    (onOptional)="deleteCurrentLanguage(session)"
    [optionalButtonLabel]="'Delete Current Language'"
  ></c-sidebar-footer>
</p-sidebar>
