import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { ComponentsModule } from 'src/app/components/components.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { LiveChatComponent } from './live-chat/live-chat.component';
import { LiveOverviewComponent } from './live-overview/live-overview.component';
import { LiveRegionComponent } from './live-region/live-region.component';
import { LiveSessionsComponent } from './live-sessions/live-sessions.component';
import { LiveTicketSlotsComponent } from './live-ticketslots/live-ticketslots.component';
import { LiveUsersComponent } from './live-users/live-users.component';
import { LiveComponent } from './live.component';
import { LiveReactionComponent } from './live-reaction/live-reaction.component';
import { LottieModule } from 'ngx-lottie';
import { playerFactory } from '../interaction/interaction.module';
import { LiveStreamPostProductionComponent } from './live-stream-post-production/live-stream-post-production.component';
import { LiveAutoAssignmentsComponent } from './live-ticketslots/live-auto-assignments/live-auto-assignments.component';
import { HasRightGuard } from 'src/app/guards/has-right.guard';
import { LiveNotificationsComponent } from './live-notifications/live-notifications.component';

const routes: Routes = [
  {
    path: '',
    component: LiveComponent,
    children: [
      { path: '', component: LiveOverviewComponent },
      // { path: ':region', component: LiveRegionComponent },
      {
        path: 'chats/:chatId',
        canActivate: [HasRightGuard],
        data: {
          hasRight: ['chats.manage'],
        },
        component: LiveChatComponent,
      },
      { path: 'streams/:streamId', component: LiveReactionComponent },
      // { path: 'registrations', component: LiveUsersComponent },
      // { path: '**', redirectTo: '', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  declarations: [
    LiveComponent,
    LiveOverviewComponent,
    LiveRegionComponent,
    LiveUsersComponent,
    LiveTicketSlotsComponent,
    LiveSessionsComponent,
    LiveChatComponent,
    LiveReactionComponent,
    LiveStreamPostProductionComponent,
    LiveAutoAssignmentsComponent,
    LiveNotificationsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ComponentsModule,
    DirectivesModule,
    CardModule,
    ButtonModule,
    PipesModule,
    DividerModule,
    TabViewModule,
    DropdownModule,
    TableModule,
    CalendarModule,
    CheckboxModule,
    EditorModule,
    DialogModule,
    FormsModule,
    TabViewModule,
    TranslateModule,
    InputTextModule,
    InputTextareaModule,
    InputSwitchModule,
    InputNumberModule,
    SplitButtonModule,
    OverlayPanelModule,
    AutoCompleteModule,
    ListboxModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    TooltipModule,
    SidebarModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  exports: [RouterModule],
  providers: [MessageService, DatePipe],
})
export class LiveModule {}
