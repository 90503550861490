import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { AssetsService } from 'src/app/services/assets/assets.service';
import { Asset, isImageAsset, isVideoAsset, VideoAsset } from 'src/common/entities/Asset';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'c-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss'],
})
export class AssetComponent implements OnChanges {
  @Input()
  id: string;

  @Input()
  size: 'cover' | 'contain' = 'contain';

  asset: Asset;
  assetSubscription: Subscription;

  get url(): string {
    if (this.asset && isImageAsset(this.asset) && this.asset.paths) {
      if (this.asset.mimeType === 'image/svg+xml') {
        return `url("${this.asset.paths.dataUri}")`;
      } else {
        return `url(${this.environment.cdn.images}/${this.asset.paths.thumbnail})`;
      }
    }

    return 'none';
  }

  get style(): any {
    return {
      'background-image': this.url,
      'background-size': this.size,
    };
  }

  get asVideoAsset(): VideoAsset {
    return isVideoAsset(this.asset) ? this.asset : null;
  }

  constructor(private environment: Environment, private assetsService: AssetsService) {}

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.id.currentValue !== simpleChanges.id.previousValue) {
      if (this.assetSubscription) {
        this.assetSubscription.unsubscribe();
        this.assetSubscription = null;
      }

      if (this.id) {
        this.assetSubscription = this.assetsService.getAsset(this.id).subscribe((asset: Asset) => {
          this.asset = asset;
        });
      }
    }
  }
}
