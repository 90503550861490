import { InputConfiguration } from 'src/common/inputs/Inputs';
import { Component, Input, OnInit } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { ExploreStagePageModule } from 'src/common/entities/pagemodules/ExploreStagePageModule';

@Component({
  selector: 'c-explore-stage-page-module-edit',
  templateUrl: './explore-stage-page-module-edit.component.html',
  styleUrls: ['./explore-stage-page-module-edit.component.scss'],
})
export class ExploreStagePageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: ExploreStagePageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  tab = 'general';

  backgroundImageJsonpathes: string[] = ['$.backgroundImage.mobile', '$.backgroundImage.tablet', '$.backgroundImage.desktop'];

  videoJsonpathes: string[] = ['$.video.mobile', '$.video.tablet', '$.video.desktop'];

  sliderBackgroundImageJsonpathes: string[] = ['$.sliderBackgroundImage.mobile', '$.sliderBackgroundImage.tablet', '$.sliderBackgroundImage.desktop'];

  accessInputConfiguration: InputConfiguration;

  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
  }
}
