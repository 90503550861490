<c-onboarding-item *ngIf="id" [onboarding]="onboarding"></c-onboarding-item>
<div class="actions as-layer" *ngIf="id && !disabled">
  <span>
    <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openSearch()">{{ 'GENERAL_SEARCH' | translate }}</a
    >&nbsp;/&nbsp; <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="remove()">Remove</a>&nbsp;
  </span>
</div>

<div class="actions" *ngIf="!id">
  <span *ngIf="!disabled">
    <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="openSearch()">{{ 'GENERAL_SEARCH' | translate }}</a
    >&nbsp;
  </span>
</div>

<c-sidebar [(visible)]="showSearch" styleClass="p-sidebar-st" header="Select Onboardings" position="left" showCloseButton="false" (onClose)="showSearch = false">
  <c-onboarding-select-search *ngIf="showSearch" (onboardingSelected)="select($event)"></c-onboarding-select-search>
</c-sidebar>
