import { Pipe, PipeTransform } from '@angular/core';

interface Result {
  name: string;
  code: string;
}

@Pipe({
  name: 'rbacDisplay',
})
export class RbacDisplayPipe implements PipeTransform {
  transform(value: string | string[], names: Result[]): string[] {
    let a: string[] = [];

    const array = Array.isArray(value) ? value : [value];
    array.forEach((item) => {
      names.forEach((i) => {
        if (item === i.code) {
          a.push(i.name);
        }
      });
    });

    return a;
  }
}
