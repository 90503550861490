import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { VideoAsset } from 'src/common/entities/Asset';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';

@Component({
  selector: 'c-vjs-player',
  templateUrl: './vjs-player.component.html',
  styleUrls: ['./vjs-player.component.scss']
})
export class VjsPlayerComponent implements OnInit {

  @ViewChild('target', {static: true}) target: ElementRef;

  @Input() videoAsset: VideoAsset;

  sources: {src: string, type: string | null}[] = []
  selectedSource: {src: string, type: string | null} | null = null

  invalidFormat = false

  player: Player;

  constructor() { }

  ngOnInit() {
    this.sources = this.videoAsset.streamingUrls.map(s => ({
      src: s,
      type: this.mimeType(s)
    }))

    this.selectedSource = this.sources.find(s => s.type) || null

    if (this.selectedSource) {
      this.player = videojs(this.target.nativeElement, {
        autoplay: false,
        sources: [{
          src: this.selectedSource.src,
          type: this.selectedSource.type,
        }]
      });
    } else {
      this.invalidFormat = true
    }
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }

  sourceChanged() {
    if (this.selectedSource) {
      if (this.selectedSource.type) {
        this.invalidFormat = false
        this.player.src(this.selectedSource)
      } else {
        this.player.reset()
        this.invalidFormat = true
      }
    } 
  }

  mimeType(streamingUrl: string): string | null {
    if (streamingUrl.endsWith('m3u8')) return 'application/x-mpegURL'
    if (streamingUrl.endsWith('mpd') || streamingUrl.includes('/manifest(format=mpd')) return 'application/dash+xml'
    if (streamingUrl.includes('/manifest(format=m3u8-aapl') || streamingUrl.includes('/manifest(format=m3u8-cmaf')) return 'application/vnd.apple.mpegurl'
    if (streamingUrl.endsWith('.mp4')) return 'video/mp4'

    return null
  }
  
}
