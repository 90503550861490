import { PagesService } from 'src/app/services/pages/pages.service';
import { DomainService } from './../../services/domain/domain.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TableComponent } from 'src/app/components/table/table.component';
import { TableOptions, TableQuery } from 'src/app/components/table/table.interfaces';
import { AssetsService } from 'src/app/services/assets/assets.service';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { AccessPolicyType, TagsAccessPolicy } from 'src/common/entities/AccessPolicy';
import { AssetSerie } from 'src/common/entities/AssetSerie';
import { Language } from 'src/common/entities/Language';
import { AssetSeriesFactory } from 'src/common/factories/AssetSeriesFactory';
import { InputConfiguration, Inputs } from 'src/common/inputs/Inputs';
import { QuickFilter } from '../../../common/entities/QuickFilter';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { AssetConfiguration } from 'src/common/entities/Configuration';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-asset-series',
  templateUrl: './asset-series.component.html',
  styleUrls: ['./asset-series.component.scss'],
})
export class AssetSeriesComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) table: TableComponent<AssetSerie>;

  executeAccessOptions = [
    { value: 'Public', title: 'Public' },
    { value: 'Platform', title: 'Platform' },
  ];
  readAccessOptions = [...this.executeAccessOptions, { value: 'Tags', title: 'Tags' }];
  domainOptions = new Array<{ option: string; value: string }>();

  searchTextSubject: Subject<string> = new Subject<string>();
  searchText: string;
  tab: Tab;
  editTab = 'general';
  tableOptions: TableOptions<any>;
  assetsIndex = -1;
  edit_mode: boolean = false;
  languages;
  currentLanguage;

  sourceQuickFilters: QuickFilter[] = [
    {
      name: 'Internal',
      active: true,
      value: 'Internal',
    },
    {
      name: 'External',
      active: true,
      value: 'External',
    },
  ];
  sourceQuickFiltersActive = true;

  loading: boolean = false;
  subscriptions: Subscription[] = [];
  assetSerie: AssetSerie;

  get showEditSidebar(): boolean {
    return this.assetsIndex >= 0;
  }
  set showEditSidebar(val: boolean) {
    if (!val) {
      this.assetsIndex = -1;
    }
  }

  inputConfig: InputConfiguration;

  inputConfigurationAssets: InputConfiguration = {};
  usedContentLanguages: Language[] = [];
  filteredContentLanguages: Language[] = [];
  allLanguages: Language[] = [];

  hasEditRights: boolean = false;

  inputs: Inputs = {
    '$.internalName': {
      type: 'text',
    },
    '$.thumbnail': {
      type: 'imageasset',
    },
    '$.local': {
      factory: async () => ({}),
    },
    '$.local.$language': {
      factory: async (obj: any, jsonpathParams: { [key: string]: any }) => {
        return new AssetSeriesFactory().createAssetSeriesLocal({});
      },
    },
    '$.assets': {
      factory: async () => [],
      list: true,
      childFactory: async () => '',
    },
    '$.assets[$index]': {
      header: 'Asset',
      type: 'asset',
    },
    '$.local.$language.title': {
      header: 'Title',
      type: 'text',
    },
    '$.local.$language.description': {
      header: 'Description',
      type: 'textarea',
    },
    '$.readAccess[0].negatePolicy': {
      header: 'NEGATE POLICY',
      type: 'switch',
    },
    '$.readAccess[0].tags': {
      header: 'TAGS',
      type: 'tags',
      multiselect: true,
    },
    '$.createdAt': {
      header: 'Created At',
      type: 'date',
      disabled: true,
    },
    '$.source.system': {
      header: 'System',
      type: 'text',
      disabled: true,
    },
    '$.source.externalId': {
      header: 'External Id',
      type: 'text',
      disabled: true,
    },
  };
  get jsonPathes(): string[] {
    return Object.keys(this.inputs);
  }

  constructor(
    private authService: AuthService,
    private assetsService: AssetsService,
    private tabsService: TabsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private languagesService: LanguagesService,
    private collaborationService: CollaborationService,
    private configurationService: ConfigurationService,
    private domainService: DomainService,
    private pageService: PagesService,
    private clipboard: Clipboard
  ) {
    this.tab = this.tabsService.register({
      category: 'data',
      loading: true,
      route: this.activatedRoute.snapshot,
      title: 'Asset Series',
    });
  }

  async ngOnInit(): Promise<void> {
    this.hasEditRights = await this.authService.hasRight('assets.edit');
    this.subscriptions.push(this.searchTextSubject.pipe(debounceTime(500)).subscribe(() => this.refresh(!this.searchText)));
    this.allLanguages = (await this.languagesService.getLanguages())?.items.filter((i) => i.selectable);
    this.languages = this.allLanguages.map((i) => i.language);
    this.filteredContentLanguages = this.allLanguages;

    const language = this.activatedRoute.snapshot.queryParams.language || 'en';
    this.currentLanguage = this.languages.includes(language) ? language : this.languages[0];
    this.inputConfig = { languages: this.languages };

    this.tableOptions = {
      size: 50,
      columns: [
        { header: 'PAGE_ASSET_SERIE_INTERNALNAME', sort: 'internalName' },
        { header: 'PAGE_ASSET_SERIE_TITLE', sort: 'local.en.title' },
        { header: 'PAGE_ASSET_SERIE_SYSTEM' },
        { header: 'PAGE_ASSET_SERIE_EXTERNAL_ID' },
      ],
      filters: [
        { header: 'PAGE_ASSET_SERIE_INTERNALNAME', path: 'internalName' },
        { header: 'PAGE_ASSET_SERIE_TITLE', path: 'local.en.title' },
        { header: 'PAGE_ASSET_SERIE_EXTERNAL_ID', path: 'source.externalId' },
      ],
    };

    const domainMap = this.domainService.getFrontendDomains();
    for (let key of domainMap.keys()) {
      const videoAssetPageId = ((await this.configurationService.getConfigurationByKeyAndDomain('asset', key)) as AssetConfiguration).videoAssetPage;
      if (videoAssetPageId) {
        const videoAssetPage = await this.pageService.getPage(videoAssetPageId);
        const path = videoAssetPage.fullPath ? videoAssetPage.fullPath : videoAssetPage.path;
        domainMap.get(key).forEach((item) => {
          this.domainOptions.push({ option: new URL(item).hostname, value: `${item}/${path}` });
        });
      }
    }

    this.tab.loading = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  query(query: TableQuery<any>) {
    this.updateQueryWithQuickFilters(query);
    if (this.searchText) {
      query.query.filter.internalName = {
        matchMode: 'contains',
        caseInsensitive: true,
        value: this.searchText,
      };
    }
    query.result = this.assetsService.getAssetSeries(query.query);
  }

  private updateQueryWithQuickFilters(query: TableQuery<AssetSerie>) {
    // TODO: This code mutates the parameter 'query'. Should be refactored.
    delete query.query.filter['source.system'];

    const quickFilterMap = new Map<string, boolean>(this.sourceQuickFilters.map(({ value, active }) => [value, active]));

    if (!quickFilterMap.get('External') && quickFilterMap.get('Internal')) {
      query.query.filter['source.system'] = {
        matchMode: 'contains',
        value: 'Internal',
      };
    }
    if (!quickFilterMap.get('Internal') && quickFilterMap.get('External')) {
      query.query.filter['source.system'] = {
        matchMode: 'notContains',
        value: 'Internal',
      };
    }
  }

  async setSourceQuickFilters(quickFilters: QuickFilter[]) {
    this.sourceQuickFilters = [...quickFilters];
    this.sourceQuickFiltersActive = this.sourceQuickFilters.find((q) => q.active == true) != null;
    await this.refresh();
  }

  searchTextKeyUp() {
    this.searchTextSubject.next();
  }
  async refresh(clear = false): Promise<void> {
    if (clear) {
      await this.table.clearFilter();
    } else {
      await this.table.refresh();
    }
  }
  async save(item: AssetSerie) {
    item.contentLanguages = (this.usedContentLanguages || []).map((langInUse) => langInUse.language);
    if (!this.edit_mode) {
      this.assetSerie = await this.assetsService.saveAssetSeries(item);
      this.collaborationService.registerLocal(`assetseries:${this.assetSerie._id}`, this.assetSerie, true);
      await this.refresh();
    } else {
      await this.assetsService.updateAssetSerie(item);
      await this.refresh();
    }
  }

  selectAssetSerie(item: AssetSerie) {
    this.assetSerie = item;

    this.usedContentLanguages = (this.assetSerie.contentLanguages || []).map((langInUse) => this.allLanguages.find((lang) => lang.language == langInUse));
    this.filterContentLanguages();
    this.edit_mode = true;

    this.ensureLanguages(this.languages);
    this.inputConfig = { languages: this.languages, disabled: this.assetSerie.source.system === 'Censhare' ? true : false };
    this.collaborationService.registerLocal(`assetseries:${this.assetSerie._id}`, this.assetSerie, true);
    this.assetsIndex = 2;
  }

  async newAssetSerie() {
    this.assetSerie = await new AssetSeriesFactory({ ensureLocals: [this.currentLanguage] }).createAssetSeries({ internalName: 'New Asset Serie' });
    this.edit_mode = false;
    this.assetsIndex = 2;
    this.collaborationService.registerLocal(`assetseries:${this.assetSerie._id}`, this.assetSerie, true);
    this.ensureLanguages(this.languages);
  }

  ensureLanguages(languages: string[]) {
    for (const language of languages) {
      if (!this.assetSerie.local[language]) {
        this.assetSerie.local[language] = new AssetSeriesFactory().createAssetSeriesLocal({});
      }
    }
  }

  get readAccess(): AccessPolicyType {
    // since empty access array is considered `Public` access in frontend, we should show it as default here too
    return this.assetSerie?.readAccess?.length > 0 ? this.assetSerie?.readAccess[0].accessPolicyType : 'Public';
  }
  set readAccess(type: AccessPolicyType) {
    this.assetSerie.readAccess = [
      {
        _id: '',
        negatePolicy: false,
        tags: [],
        accessPolicyType: type,
      } as TagsAccessPolicy,
    ];
  }

  createURL(url: string, path: string, query: string) {
    this.clipboard.copy(`${url}/${path}?series=${query}`);
  }

  filterContentLanguages(event?) {
    this.filteredContentLanguages = [];
    this.allLanguages.forEach((lang) => {
      if (!this.usedContentLanguages.find((langInUse) => langInUse._id == lang._id)) {
        this.filteredContentLanguages.push(lang);
      }
    });

    if (event && event.query) {
      this.filteredContentLanguages = this.filteredContentLanguages.filter((lang) => lang.language.toLowerCase().includes(event.query.toLowerCase()));
    }
  }
}
