import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { Stage2TextEventStagePageModuleFactory } from '../../factories/pagemodules/Stage2TextEventStagePageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const stage2TextEventStagePageModuleInputs: Inputs = {
  ...commonPageModuleInputs('TextEventStage2'),
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].ctalist": {
    factory: async () => [],
    childFactory: async () => new Stage2TextEventStagePageModuleFactory().textEventStage2PageModuleButton({}),
    list: true,
    listKey: '_id',
    header: 'CTA Button',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].ctalist[$index].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].ctalistInteraction": {
    factory: async () => [],
    childFactory: async () => new Stage2TextEventStagePageModuleFactory().textEventStage2PageModuleButton({}),
    list: true,
    listKey: '_id',
    header: 'CTA Button',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].ctalistInteraction[$index].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].local.$language.background": {},
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new Stage2TextEventStagePageModuleFactory().textEventStage2PageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].local.$language.smallHeadline": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].local.$language.text": {},
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].showCountdown": {
    header: 'Show Countdown',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].layout": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Default', value: 'Default' },
      { label: 'Background Focused', value: 'BackgroundFocused' },
    ],
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].local.$language.subtext": {},
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].discoverMore": {
    header: 'Show Discover More',
    type: 'switch',
  },

  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].topAreaHeight": {
    factory: async () => new Stage2TextEventStagePageModuleFactory().textEventStage2PageModuleAreaHeight({}),
  },

  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.topAreaHeight.desktopWidescreen": {
    header: 'Top Area Height Desktop Widescreen',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.topAreaHeight.desktop": {
    header: 'Top Area Height Desktop',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.topAreaHeight.desktopLarge": {
    header: 'Top Area Height Desktop large',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.topAreaHeight.mobile": {
    header: 'Top Area Height mobile',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.topAreaHeight.mobileLarge": {
    header: 'Top Area Height mobile large',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.topAreaHeight.tablet": {
    header: 'Top Area Height tablet ',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.topAreaHeight.tabletLarge": {
    header: 'Top Area Height tablet large',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].contentAreaHeight": {
    factory: async () => new Stage2TextEventStagePageModuleFactory().textEventStage2PageModuleAreaHeight({}),
  },

  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.contentAreaHeight.desktopWidescreen": {
    header: 'Top Area Height Desktop Widescreen',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.contentAreaHeight.desktop": {
    header: 'Top Area Height Desktop',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.contentAreaHeight.desktopLarge": {
    header: 'Top Area Height Desktop large',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.contentAreaHeight.mobile": {
    header: 'Top Area Height mobile',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.contentAreaHeight.mobileLarge": {
    header: 'Top Area Height mobile large',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.contentAreaHeight.tablet": {
    header: 'Top Area Height tablet ',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.contentAreaHeight.tabletLarge": {
    header: 'Top Area Height tablet large',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].ctaAreaHeight": {
    factory: async () => new Stage2TextEventStagePageModuleFactory().textEventStage2PageModuleAreaHeight({}),
  },

  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.ctaAreaHeight.desktopWidescreen": {
    header: 'Top Area Height Desktop Widescreen',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.ctaAreaHeight.desktop": {
    header: 'Top Area Height Desktop',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.ctaAreaHeight.desktopLarge": {
    header: 'Top Area Height Desktop large',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.ctaAreaHeight.mobile": {
    header: 'Top Area Height mobile',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.ctaAreaHeight.mobileLarge": {
    header: 'Top Area Height mobile large',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.ctaAreaHeight.tablet": {
    header: 'Top Area Height tablet ',
  },
  "$.pageModules[?(@.pageModuleType=='TextEventStage2' && @._id=='$pageModuleId')].$.ctaAreaHeight.tabletLarge": {
    header: 'Top Area Height tablet large',
  },
};
