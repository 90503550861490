<div class="main">
  <div class="user">
    <button (click)="logout()">AS</button>
  </div>
  <div class="categories" *ngIf="rightsCheckCompleted">
    <button (click)="openCategory('events')" *hasRight="rights['events']" [disabled]="category === 'events'">
      <i class="pi icon-ic-events"></i>
      <span>Events</span>
    </button>
    <button (click)="openCategory('pages')" *hasRight="rights['pages']" [disabled]="category === 'pages'">
      <i class="pi icon-ic-pages"></i>
      <span>Pages</span>
    </button>
    <button (click)="openCategory('users')" *hasRight="rights['users']" [disabled]="category === 'users'">
      <i class="pi icon-ic-users"></i>
      <span>Users</span>
    </button>
    <button (click)="openCategory('data')" *hasRight="rights['data']" [disabled]="category === 'data'">
      <i class="pi icon-ic-data"></i>
      <span>Data</span>
    </button>
    <button (click)="openCategory('configuration')" *hasRight="rights['configuration']" [disabled]="category === 'configuration'">
      <i class="pi icon-ic-settings"></i>
      <span>Settings</span>
    </button>
  </div>
</div>
<div class="tabs">
  <div class="brand">
    <img src="/assets/images/arvato-logo-svg-data.svg" />
  </div>
  <c-instance-alias *ngIf="instanceAlias" [alias]="instanceAlias" [color]="instanceAliasColor"></c-instance-alias>
  <div class="tabs-container">
    <div class="tab-container" *ngFor="let tab of tabs">
      <div class="tab" *hasRight="(!tab.configuration.loading && tab.configuration.hasRight) || []">
        <div class="main-tab" [class.active]="tab.isActive()">
          <a (click)="tabClick(tab)">
            <p-skeleton width="170px" *ngIf="tab.loading"></p-skeleton>
            <div class="tab-flex">
              <span class="title" *ngIf="!tab.loading">{{ tab.configuration.title | translate }}</span>
              <div class="badge warn" *ngIf="!tab.loading && tab.configuration.hasMoved">Moved</div>
            </div>
          </a>
          <button *ngIf="!tab.configuration.sticky" class="icon" (click)="closeTab(tab)">
            <i class="pi pi-times"></i>
          </button>
          <button *ngIf="tab.configuration.collapsible" class="icon" (click)="tab.collapsed = !tab.collapsed">
            <i class="pi pi-chevron-left" [class.rotate]="!tab.collapsed"></i>
          </button>
        </div>
        <div class="child-tabs" *ngIf="tab.children.length > 0 && !tab.collapsed">
          <div *ngFor="let child of tab.children">
            <div *hasRight="(!child.configuration.loading && child.configuration.hasRight) || []" class="child-tab" [class.active]="child.isActive()">
              <a (click)="tabClick(child)">
                <p-skeleton width="170px" *ngIf="child.loading"></p-skeleton>
                <div class="tab-flex">
                  <span *ngIf="!child.loading && child.titlePrefix" class="title-prefix">{{ child.titlePrefix | translate }}</span>
                  <span *ngIf="!child.loading" class="title">{{ child.title | translate }}</span>
                  <div class="badge warn" *ngIf="!child.loading && child.configuration.hasMoved">Moved</div>
                </div>
              </a>
              <button *ngIf="!child.configuration.sticky" class="icon" (click)="closeTab(child)">
                <i class="pi pi-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
