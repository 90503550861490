import { AfterViewInit, Component, ComponentFactoryResolver, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { EventStage } from 'src/common/entities/EventStage';
import { Event } from 'src/common/entities/Event';
import { Page } from 'src/common/entities/Page';
import { EventVersion } from 'src/common/entities/EventVersion';
import { StreamEventStageEditComponent } from './stream-event-stage-edit/stream-event-stage-edit.component';
import { TeaserEventStageEditComponent } from './teaser-event-stage-edit/teaser-event-stage-edit.component';
import { TextEventStageEditComponent } from './text-event-stage-edit/text-event-stage-edit.component';
import { TitleEventStageEditComponent } from './title-event-stage-edit/title-event-stage-edit.component';
import { UtilsService } from 'src/app/services/utils/utils.service';

const eventStageComponents: any = {
  Text: TextEventStageEditComponent,
  Title: TitleEventStageEditComponent,
  Stream: StreamEventStageEditComponent,
  Teaser: TeaserEventStageEditComponent,
};

@Component({
  selector: 'c-event-stage-edit',
  templateUrl: './event-stage-edit.component.html',
  styleUrls: ['./event-stage-edit.component.scss'],
})
export class EventStageEditComponent implements AfterViewInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  eventStage: EventStage;

  @Input()
  page: Page;

  @Input()
  jsonpath: string | string[];

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  @Output()
  onClose = new EventEmitter<boolean>();

  @ViewChild('child', { read: ViewContainerRef })
  child: ViewContainerRef;

  noEditComponent = false;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private utilsService: UtilsService) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const component = eventStageComponents[this.eventStage.eventStageType];

      if (component) {
        const editComponentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const ref = this.child.createComponent(editComponentFactory);
        const instance = ref.instance as any;

        instance.event = this.event;
        instance.eventVersion = this.eventVersion;
        instance.page = this.page;
        instance.eventStage = this.eventStage;
        instance.collaborationKey = this.collaborationKey;
        instance.jsonpath = this.utilsService.combineJsonpath(this.jsonpath);
        instance.jsonpathParams = this.jsonpathParams;

        this.noEditComponent = false;
      } else {
        this.noEditComponent = true;
      }
    }, 0);
  }
}
