import { Component, TemplateRef } from '@angular/core';

@Component({
  selector: 'c-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss'],
})
export class PagerComponent {
  stack: any[];

  constructor() {}

  clear() {}

  nextComponent(component: Component) {}

  nextTemplate(templateRef: TemplateRef<any>) {}
}
