import { AccordionPageModuleAttributes } from './../../entities/pagemodules/AccordionPageModule';
import { HeadlineType } from './../../entities/Attributes';
import { AccordionPageModule, AccordionPageModuleChildElement, AccordionPageModuleElement } from '../../entities/pagemodules/AccordionPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class AccordionPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public accordionPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.accordionPageModuleAttributes(values?.title_attr),
    };
  }

  public accordionPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): AccordionPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public accordionPageModuleElementLocal(values: any) {
    return {
      title: values?.title || '',
    };
  }

  public accordionPageModuleChildElementLocal(values: any) {
    return {
      title: values?.title || '',
      description: values?.description || '',
    };
  }

  public async accordionPageModuleElement(values: any): Promise<AccordionPageModuleElement> {
    return {
      _id: await this.id(values?._id),
      local: await this.local(values, (val) => this.accordionPageModuleElementLocal(val)),
      childElements: await Promise.all((values?.childElements || []).map((e: any) => this.accordionPageModuleChildElement(e))),
      isExpanded: values?.isExpanded || false,
    };
  }

  public async accordionPageModuleChildElement(values: any) {
    return {
      _id: await this.id(values?._id),
      local: await this.local(values, (val) => this.accordionPageModuleChildElementLocal(val)),
    } as AccordionPageModuleChildElement;
  }

  public async accordionPageModule(values?: any): Promise<AccordionPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'Accordion',
      titlePosition: values?.titlePosition || 'left',
      local: await this.local(values, (val) => this.accordionPageModuleLocal(val)),
      elements: await Promise.all((values?.elements || []).map((e: any) => this.accordionPageModuleElement(e))),
    };
  }
}
