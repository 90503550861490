import { HeadlineType } from './../../entities/Attributes';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';
import { TrendingTopicsPageModule, TrendingTopicsPageModuleAttributes } from './../../../common/entities/pagemodules/TrendingTopicsPageModule';

export class TrendingTopicsPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async createTrendingTopicsPageModuleLocalTopic(values: any) {
    return {
      _id: await this.id(values._id),
      title: values?.title || '',
      image: values?.image || null,
      storyAssets: values?.storyAssets ? await Promise.all(
            values.storyAssets.map(async (i: any) => {
              const t = await this.createTrendingTopicsStoryAssets(i);
              return t;
            })
          )
        : [],
      isHighlighted: values?.isHighlighted || false,
      cta: values?.cta || null
    };
  }

  public async createTrendingTopicsStoryAssets(values: any) {
    return {
      _id: await this.id(values._id),
      title: values?.title || '',
      asset: values?.asset || '',
      cta: values?.cta || null
    }
  }

  public TrendingTopicsPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): TrendingTopicsPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2'
    };
  }

  public async createTrendingTopicsPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.TrendingTopicsPageModuleAttributes(values?.title_attr),
      topics: values?.topics
        ? await Promise.all(
            values.topics.map(async (i: any) => {
              const t = await this.createTrendingTopicsPageModuleLocalTopic(i);
              return t;
            })
          )
        : []
    };
  }

  public async trendingTopicsPageModule(values: any): Promise<TrendingTopicsPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'TrendingTopics',
      trendingTopicsType: values?.trendingTopicsType || 'default',
      titlePosition: values?.titlePosition || 'centered',
      local: await this.local(values, async val => await this.createTrendingTopicsPageModuleLocal(val)),
      ctaButton: values?.ctaButton || null,
      useFullWidth: values?.useFullWidth || false
    };
  }
}
