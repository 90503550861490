<div *skeleton="{ show: tab.loading }">
  <div class="tab-header">
    <h1 *ngIf="speakerId === 'new'">{{ 'PAGE_SPEAKER_NEW_SPEAKER' | translate }}</h1>
    <h1 *ngIf="speakerId !== 'new'">{{ speaker?.firstName }}&nbsp;{{ speaker?.lastName }}</h1>
  </div>
  <div class="tab-menu">
    <p-button *ngIf="hasCreateRights || hasEditRights" icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" [disabled]="working || !isValid()" (onClick)="save()"></p-button>
    <c-language-button
      [(currentLanguage)]="currentLanguage"
      [languages]="languages"
      (languagesChange)="languagesChange($event)"
      [manageLanguages]="hasCreateRights || hasEditRights"
    ></c-language-button>
  </div>

  <div class="panel">
    <div class="panel-header">Person</div>
    <div class="panel-body">
      <c-collaboration-patch-input
        *ngFor="let jsonPath of mainJsonPathes"
        [object]="speaker"
        [collaborationKey]="'speaker:' + speakerId"
        [jsonpath]="jsonPath"
        [inputs]="mainInputs"
        [disabled]="!hasCreateRights && !hasEditRights"
      ></c-collaboration-patch-input>
    </div>
  </div>

  <div class="panel">
    <div class="panel-header">
      External Accounts
      <div class="actions">
        <c-table-options #to></c-table-options>
        <p-button *ngIf="hasEditRights" label="Add External Account" icon="pi icon-ic-add-item" (onClick)="addExternalAccount('Custom')"></p-button>
      </div>
    </div>
    <c-table #externalAccountsTable [data]="speaker.externalAccounts" [options]="externalAccountsTableOptions" [tableOptionsComponent]="to">
      <ng-template let-externalAccount>
        <a *ngIf="hasEditRights" [routerLink]="[]" queryParamsHandling="merge" (click)="editExternalAccount(externalAccount)">{{ externalAccount.externalAccountType }}</a>
        <span *ngIf="!hasEditRights" queryParamsHandling="merge">{{ externalAccount.externalAccountType }}</span>
      </ng-template>
      <ng-template let-externalAccount>{{ externalAccount.link }}</ng-template>
    </c-table>
  </div>
</div>

<p-sidebar [(visible)]="showExternalAccountEdit" styleClass="p-sidebar-st" position="right" [modal]="true" [appendTo]="'body'">
  <div class="sidebar-header">Edit External Account</div>
  <div class="sidebar-body" *ngIf="externalAccountEditIndex >= 0">
    <c-collaboration-patch-input
      [object]="speaker"
      [collaborationKey]="'speaker:' + speakerId"
      [jsonpath]="'$.externalAccounts[$index].externalAccountType'"
      [jsonpathParams]="{ index: externalAccountEditIndex }"
      [inputs]="externalAccountInputsCommon"
    ></c-collaboration-patch-input>

    <c-collaboration-patch-input
      *ngFor="let jsonPath of externalAccountsJsonPathes"
      [object]="speaker"
      [collaborationKey]="'speaker:' + speakerId"
      [jsonpath]="jsonPath"
      [jsonpathParams]="{ index: externalAccountEditIndex }"
      [inputs]="externalAccountInputs[speaker.externalAccounts[externalAccountEditIndex].externalAccountType]"
    ></c-collaboration-patch-input>
  </div>

  <c-sidebar-footer [showDeleteButton]="true" (onDelete)="deleteExternalAccount(externalAccountEditIndex); showExternalAccountEdit = false"></c-sidebar-footer>
</p-sidebar>

<!-- 
<div class="content">

  <h1>
    {{ "GENERAL_SPEAKER" | translate }}
    <span class="subheader" *ngIf="loading">{{ "GENERAL_LOADING" | translate }} ...</span>
    <span class="subheader" *ngIf="!loading && speakerId === 'new'">{{ "GENERAL_NEW" | translate }}</span>
    <span class="subheader" *ngIf="!loading && speakerId !== 'new'">{{ speaker?.firstName }}&nbsp;{{ speaker?.lastName }}</span>
    <button pButton type="button" [label]="' ' + ('GENERAL_SAVE' | translate)" icon="pi pi-save" class="p-button" [disabled]="loading || saving || !isValid()" (click)="save()"></button>
  </h1>

  <c-loading [saving]="saving">
    <p-card [header]="'GENERAL_GLOBAL_PROPERTIES' | translate">
      <c-skeleton *ngIf="!speaker" [fields]="['text', 'text', 'text', 'text']"></c-skeleton>
      <div *ngIf="speaker">

        <c-field header="PAGE_SPEAKER_GLOBAL_PROPERTIES_DISPLAYNAME" description="PAGE_SPEAKER_GLOBAL_PROPERTIES_DISPLAYNAME_DESCRIPTION">
          <input type="text" pInputText name="displayName" [(ngModel)]="speaker.displayName" disabled="disabled">
        </c-field>
        <c-field [error]="errorAt('firstName')" header="PAGE_SPEAKER_GLOBAL_PROPERTIES_FIRSTNAME" description="PAGE_SPEAKER_GLOBAL_PROPERTIES_FIRSTNAME_DESCRIPTION">
          <input type="text" pInputText name="firstName" [(ngModel)]="speaker.firstName">
        </c-field>
        <c-field [error]="errorAt('lastName')" header="PAGE_SPEAKER_GLOBAL_PROPERTIES_LASTNAME" description="PAGE_SPEAKER_GLOBAL_PROPERTIES_LASTNAME_DESCRIPTION">
          <input type="text" pInputText name="lastName" [(ngModel)]="speaker.lastName">
        </c-field>
        <c-field [error]="errorAt('company')" header="PAGE_SPEAKER_GLOBAL_PROPERTIES_COMPANY" description="PAGE_SPEAKER_GLOBAL_PROPERTIES_COMPANY_DESCRIPTION">
          <input type="text" pInputText name="company" [(ngModel)]="speaker.company">
        </c-field>
        <c-field [error]="errorAt('picture')" header="PAGE_SPEAKER_GLOBAL_PROPERTIES_PICTURE" description="PAGE_SPEAKER_GLOBAL_PROPERTIES_PICTURE_DESCRIPTION">
          <c-asset-select [(id)]="speaker.picture"></c-asset-select>
        </c-field>
        <c-field header="Mail">
          <input type="text" pInputText [(ngModel)]="speaker.email">
        </c-field>
        <c-field header="Skills">
          <p-chips [(ngModel)]="speaker.skills"></p-chips>
        </c-field>

        <div *ngFor="let externalAccount of speaker.externalAccounts; trackBy: trackByIndex">
          <c-field [header]="'External Account: ' + externalAccount.externalAccountType">
            <input type="text" pInputText [(ngModel)]="externalAccount.link">
            <button pButton type="button" [label]="' ' + ('GENERAL_DELETE' | translate)" icon="pi pi-trash" class="p-button-text p-button-outlined p-button-danger p-button-sm" (click)="deleteExternalAccount(externalAccount)"></button>
          </c-field>
          <div *ngIf="asCustomExternalAccount(externalAccount) as customExternalAccount">
            <c-field header="Custom External Account Title">
              <input type="text" pInputText [(ngModel)]="customExternalAccount.local[currentLanguage].title">
            </c-field>
            <c-field header="Custom External Account Icon">
              <c-asset-select [(id)]="customExternalAccount.icon"></c-asset-select>
            </c-field>
          </div>
        </div>
        <button pButton type="button" [label]="' ' + ('GENERAL_ADD' | translate) + ' Custom Account'" icon="pi pi-plus" class="p-button-outlined p-button-sm" (click)="addExternalAccount('Custom')"></button>
        <button pButton type="button" [label]="' ' + ('GENERAL_ADD' | translate) + ' Twitter Account'" icon="pi pi-plus" class="p-button-outlined p-button-sm" (click)="addExternalAccount('Twitter')"></button>
        <button pButton type="button" [label]="' ' + ('GENERAL_ADD' | translate) + ' Xing Account'" icon="pi pi-plus" class="p-button-outlined p-button-sm" (click)="addExternalAccount('Xing')"></button>
        <button pButton type="button" [label]="' ' + ('GENERAL_ADD' | translate) + ' LinkedIn Account'" icon="pi pi-plus" class="p-button-outlined p-button-sm" (click)="addExternalAccount('LinkedIn')"></button>
      </div>
    </p-card>
    <p-card [header]="'GENERAL_LOCAL_PROPERTIES' | translate" class="tiered-menu">
      <c-skeleton *ngIf="!speaker" [menu]="true" [fields]="['text', 'textarea', 'textarea']"></c-skeleton>

      <c-select-menu *ngIf="speaker" 
        [labelAddButton]="'GENERAL_LOCAL_PROPERTIES_ADD_LANGUAGE' | translate" 
        [(currentItem)]="currentLanguage" 
        [items]="getActiveLanguages() | async | selectMenuItems:'language':'languageName'"
        [invalidItems]="invalidLanguages()" 
        [(selectedItems)]="selectedLanguages"
      ></c-select-menu>

      <div *ngIf="speaker && speaker.local[currentLanguage]" class="tiered-menu-content">

        <h2>
          {{ (currentLanguage | language | async)?.languageName }}
          <button pButton type="button" [label]="' ' + ('GENERAL_DELETE' | translate)" icon="pi pi-trash" class="p-button-text p-button-danger p-button-sm" (click)="deleteLanguage(currentLanguage)" [disabled]="currentLanguage == 'en'"></button>
        </h2>

        <c-field [error]="localErrorAt(currentLanguage, 'title')" header="PAGE_SPEAKER_LOCAL_PROPERTIES_TITLE" description="PAGE_SPEAKER_LOCAL_PROPERTIES_TITLE_DESCRIPTION">
          <input name="title" type="text" pInputText [(ngModel)]="speaker.local[currentLanguage].title">
        </c-field>
        <c-field [error]="localErrorAt(currentLanguage, 'shortDescription')" header="PAGE_SPEAKER_LOCAL_PROPERTIES_SHORT_DESCIPTION" description="PAGE_SPEAKER_LOCAL_PROPERTIES_SHORT_DESCIPTION_DESCIPTION">
          <textarea name="shortDescription" pInputTextarea [(ngModel)]="speaker.local[currentLanguage].shortDescription"></textarea>
        </c-field>
        <c-field [error]="localErrorAt(currentLanguage, 'longDescription')" header="PAGE_SPEAKER_LOCAL_PROPERTIES_LONG_DESCIPTION" description="PAGE_SPEAKER_LOCAL_PROPERTIES_LONG_DESCIPTION_DESCIPTION">
          <textarea name="longDescription" pInputTextarea [(ngModel)]="speaker.local[currentLanguage].longDescription"></textarea>
        </c-field>

      </div>
    </p-card>
  </c-loading>
</div> -->
