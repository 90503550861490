export const customFieldTypes = ['select', 'checkbox', 'text', 'textarea', 'ticketSlotSelection', 'ticketAttendees', 'ticketSelfVisit'] as const;
export type CustomFieldType = (typeof customFieldTypes)[number];

export interface CustomField {
  _id: string;
  internalName: string;
  customFieldType: CustomFieldType;
  languages?: string[];
  local: {
    [language: string]: {
      title: string;
      description: string;
      checkboxDescription: string;
      default?: string | null;
    };
  };
  default?: boolean;
}

export interface CustomFieldSelectOption {
  key: string;
  local: { [language: string]: string };
}

export interface CustomFieldSelect extends CustomField {
  customFieldType: 'select';
  options: CustomFieldSelectOption[];
}

export interface CustomFieldCheckbox extends CustomField {
  customFieldType: 'checkbox';
}

export interface CustomFieldText extends CustomField {
  customFieldType: 'text';
}

export interface CustomFieldTextarea extends CustomField {
  customFieldType: 'textarea';
}

export interface CustomFieldTicketSlotSelection extends CustomField {
  customFieldType: 'ticketSlotSelection';
}

export interface CustomFieldTicketAttendeesOption {
  _id: string;
  internalName: string;
  local: { [language: string]: { title: string } };
  fieldType: string;
  maxCharacters: number;
  mandatory: boolean;
  deletedAt: string;
  deletedBy: string;
}

export interface CustomFieldTicketAttendees extends CustomField {
  customFieldType: 'ticketAttendees';
  fields: CustomFieldTicketAttendeesOption[];
}

export interface CustomFieldTicketSelfVisit extends CustomField {
  customFieldType: 'ticketSelfVisit';
}

export function isCustomFieldSelect(object: any): object is CustomFieldSelect {
  return object?.customFieldType === 'select';
}

export function isCustomFieldCheckbox(object: any): object is CustomFieldCheckbox {
  return object?.customFieldType === 'checkbox';
}

export function isCustomFieldText(object: any): object is CustomFieldText {
  return object?.customFieldType === 'text';
}

export function isCustomFieldTextarea(object: any): object is CustomFieldTextarea {
  return object?.customFieldType === 'textarea';
}

export function isCustomFieldTicketSlotSelection(object: any): object is CustomFieldTicketSlotSelection {
  return object?.customFieldType === 'ticketSlotSelection';
}

export function isCustomFieldTicketAttendees(object: any): object is CustomFieldTicketAttendees {
  return object?.customFieldType === 'ticketAttendees';
}

export function isCustomFieldTicketSelfVisit(object: any): object is CustomFieldTicketSelfVisit {
  return object?.customFieldType === 'ticketSelfVisit';
}
