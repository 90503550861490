import * as cloneDeep from 'clone-deep';
import { TableOptions } from './../../components/table/table.interfaces';
import { Tab } from './../../tabs/classes/tab';
import { TabsService } from './../../tabs/services/tabs.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { PagesService } from 'src/app/services/pages/pages.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { MenuConfiguration } from 'src/common/entities/Configuration';
import { MenuItem } from 'src/common/entities/MenuItem';
import { GlobalPageType, globalPageTypes, isLinkedPage, Page, PageTypes } from 'src/common/entities/Page';
import { Factory } from 'src/common/factories/Factory';
import { PageFactory } from 'src/common/factories/PageFactory';
import { delay, distinctUntilChanged, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';
import { IFilterList } from 'src/common/api/interfaces';
import { InputConfiguration, Inputs } from 'src/common/inputs/Inputs';
import { GetPagesQuery, PageViewType, PageWithChildPages } from '../../../common/api/v1/configuration/pages/GetPages';
import { fromPromise } from 'rxjs/internal-compatibility';
import { getSortFunction, sortByInternalName } from '../../utils';

@Component({
  selector: 'app-pages-configuration',
  templateUrl: './pages-configuration.component.html',
  styleUrls: ['./pages-configuration.component.scss'],
})
export class PagesConfigurationComponent implements OnInit, OnDestroy {
  @ViewChild('modalCreatePageSideBar') private modalCreatePageSideBar: SidebarComponent;

  $searchText = new BehaviorSubject<string | null>(null);
  $domainCollectionId = new BehaviorSubject<string | null>(null);

  tab: Tab;
  tableOptions: TableOptions<PageWithChildPages>;

  menuConfiguration: MenuConfiguration;
  showMenuConfiguration = false;
  languageOptions: { label: string; value: string }[] = [];
  language = 'en';
  selectedUserContextMenuItem: MenuItem;

  newPage: Page;
  newPageFromTemplateId: string;
  pageType: GlobalPageType = 'BlankPage';
  pageTypes = globalPageTypes.filter(() => true);
  pageTypesOptions = globalPageTypes.map((t) => ({ label: t, value: t }));
  newPageBaseType: 'none' | 'existingPage' = 'none';
  get showNewPageModal(): boolean {
    return !!this.newPage;
  }
  set showNewPageModal(val: boolean) {
    if (!val) {
      this.newPage = null;
    }
  }

  working = false;
  pageEdit: Page;
  get showEditStructureModal(): boolean {
    return !!this.pageEdit;
  }
  set showEditStructureModal(val: boolean) {
    if (!val) {
      this.pageEdit = null;
    }
  }
  expanded: { [pageId: string]: boolean } = {};

  // We need this subject to refresh the list of pages whenever any page is updated
  $refresh = new BehaviorSubject<unknown>(true);
  $filter = new BehaviorSubject<IFilterList>({});
  $pages: Observable<PageWithChildPages[]>;

  subscriptions: Subscription[] = [];

  constructor(
    private configurationService: ConfigurationService,
    private pagesService: PagesService,
    private languagesService: LanguagesService,
    private utilsService: UtilsService,
    private router: Router,
    private tabsService: TabsService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService
  ) {
    this.language = this.utilsService.defaultLanguage;
  }

  inputConfig: InputConfiguration;
  inputs: Inputs = {};

  async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      this.$searchText
        .pipe(
          delay(300),
          distinctUntilChanged(),
          withLatestFrom(this.$filter),
          tap(([value, filter]) => {
            if (value !== filter.internalName?.value) {
              filter.internalName = {
                matchMode: 'contains',
                caseInsensitive: true,
                value,
              };
              this.$filter.next(filter);
            }
          })
        )
        .subscribe()
    );

    this.$pages = combineLatest([
      this.$domainCollectionId.pipe(
        filter((data) => !!data),
        distinctUntilChanged()
      ),
      this.$filter.pipe(
        distinctUntilChanged((x, y) => Object.keys(x).length === 0 && Object.keys(y).length === 0),
        map((filter) => {
          if (!filter.internalName?.value) {
            delete filter.internalName;
          }
          return filter;
        }),
        map(this.getFilterForAllExceptEmbeddedPages)
      ),
      this.$refresh,
    ]).pipe(
      switchMap(([domainCollectionId, filter = {}]) => {
        const query: GetPagesQuery = { filter, view: PageViewType.TREE };
        return fromPromise(this.pagesService.getPages(domainCollectionId, query)).pipe(map(({ items }) => items.map((item) => ({ ...item }))));
      })
    );

    this.tab = this.tabsService.register({
      category: 'pages',
      route: this.activatedRoute.snapshot,
    });

    this.tableOptions = {
      size: 50,
      columns: [
        {
          header: 'GENERAL_TITLE',
          sort: sortByInternalName,
        },
        { header: 'GENERAL_PATH', sort: getSortFunction('path') },
        { header: 'GENERAL_PAGETYPE', sort: getSortFunction('pageType') },
        { header: 'GENERAL_DEFAULT', sort: 'default' },
        { header: '' },
      ],
      filters: [
        { header: 'GENERAL_TITLE', path: 'internalName' },
        { header: 'GENERAL_PATH', path: 'path' },
        { header: 'GENERAL_PAGETYPE', path: 'pageType' },
      ],
    };
    this.languageOptions = (await this.languagesService.getActiveLanguagesAsPromise()).map((l) => ({ label: l.languageName, value: l.language }));

    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        withLatestFrom(this.$domainCollectionId)
      )
      .subscribe(([navEnd, domainCollectionId]) => {
        if (!this.tab.loading && navEnd.url === '/configuration/pages') {
          this.loadDialog(domainCollectionId);
          this.$refresh.next(true);
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async loadDialog(domainCollectionId: string): Promise<void> {
    this.$domainCollectionId.next(domainCollectionId);

    this.inputs = {
      '$.parentPage': {
        header: 'Set Parent',
        type: 'page',
        domainCollectionId,
      },
      '$.linkedPage': {
        condition: (parent) => {
          return isLinkedPage(parent);
        },
        type: 'page',
        header: 'Set Linked Page',
        description: 'Select an original page to which this LinkedPage should point.',
      },
    };
  }

  async filterChange(filterChange: IFilterList): Promise<void> {
    this.$filter.next(filterChange);
    const value = filterChange.internalName?.value ? filterChange.internalName?.value.toString() : '';
    this.$searchText.next(value);
  }

  async editMenuConfiguration(): Promise<void> {
    this.showMenuConfiguration = true;
    this.menuConfiguration = await Factory.configuration().menuConfiguration(await this.configurationService.getConfigurationByKeyAndDomain('menu', this.$domainCollectionId.getValue()));
  }

  async saveMenuConfiguration(): Promise<void> {
    try {
      this.menuConfiguration = await this.configurationService.saveMenuConfiguration(this.menuConfiguration, null, this.$domainCollectionId.getValue());
    } catch (err) {
      console.error(err);
    }
    this.showMenuConfiguration = false;
  }

  cancelMenuConfiguration(): void {
    this.menuConfiguration = null;
    this.showMenuConfiguration = false;
  }

  selectUserContextMenuItems(item: MenuItem): void {
    this.selectedUserContextMenuItem = item;
  }

  async editStructure(page: Page): Promise<void> {
    this.pageEdit = cloneDeep(page);
  }

  onPageTypeChange(e): void {
    this.newPage.pageType = this.pageType;
    this.newPageFromTemplateId = null;
    this.newPageBaseType = 'none';
    this.newPage.parentPage = null;
  }

  async saveStructure(): Promise<void> {
    this.working = true;

    try {
      await this.pagesService.updatePage(this.pageEdit);
    } catch (error) {
      this.messageService.add({ severity: 'error', summary: error?.error?.errorTag || error.statusText || 'Unknown error' });

      this.working = false;
      return; // dont close side bar
    } finally {
      this.$refresh.next(true);
    }

    this.expanded = {};
    this.pageEdit = null;
    this.working = false;
  }

  async createPage(): Promise<void> {
    let newPage: any;
    if (this.newPageFromTemplateId && this.newPageBaseType === 'existingPage') {
      newPage = await this.pagesService.getPage(this.newPageFromTemplateId);
      if (newPage) {
        newPage._id = null;
        newPage.internalName = this.newPage.internalName;
        newPage.path = this.newPage.path;
        newPage.parentPage = this.newPage.parentPage;
        newPage.domainCollection = this.newPage.domainCollection;
        newPage.forkedAt = null;
        newPage.forkedBy = null;
        newPage.forkedAt = null;
        newPage.modifiedBy = null;
        newPage.modifiedAt = null;
        newPage.page = null;
        newPage = await new PageFactory({ newIds: true }).pageVersion(newPage);
      }
    } else {
      newPage = this.newPage;
    }
    if (newPage.internalName && newPage.path) {
      const pathExists = await this.pagesService.pathExists(newPage.path, newPage.domainCollection);
      if (pathExists) {
        let summary = `A page with path '${newPage.path}' already exist. The path needs to be unique.`;
        this.messageService.add({ severity: 'error', summary });
        return;
      }
      try {
        const page = await this.pagesService.createPage(newPage);
        this.newPage = null;
        this.working = false;
        this.newPageBaseType = 'none';
        this.showNewPageModal = false;
        setTimeout(() => {
          this.router.navigate(['/pages', page._id], {
            replaceUrl: true,
            queryParams: { domainCollectionId: this.$domainCollectionId.getValue() },
          });
        }, 500);
      } catch (error) {
        this.working = false;
        console.error(error);
        const summary = typeof error.error?.message === 'string' ? error.error.message : 'Something went wrong';
        this.messageService.add({ severity: 'error', summary });
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'Please fill empty forms.' });
    }
  }

  async openNewGlobalPageOrTemplateModal(): Promise<void> {
    this.pageType = 'BlankPage';
    let pageTemplate: any = { pageType: this.pageTypes[0] };
    if (this.newPageFromTemplateId) {
      pageTemplate = await this.pagesService.getPage(this.newPageFromTemplateId);
    }

    this.newPage = await new PageFactory({ newIds: true }).page({
      ...pageTemplate,
      domainCollection: this.$domainCollectionId.getValue(),
    });
  }

  getChildContentClass(level = 0): string {
    if (level === 0) {
      return '';
    }
    return `level-${(level - 1) % 2}`;
  }

  getRowPaddingLeft(pageStructure: PageWithChildPages, level = 0): string {
    const defaultPadding = 16;
    if (level === 0) {
      return `${defaultPadding}px`;
    }

    let padding = level * 8;
    if (pageStructure.childPages.length === 0) {
      padding += 26; // to fill empty arrow position
    }

    return `${padding + defaultPadding}px`;
  }

  hasPageReadAccessConditions = (p: Page): boolean => {
    // Check if page has access conditions other than public.
    if (p.readAccess) {
      const match = p.readAccess.find((e) => e.accessPolicyType !== 'Public');
      return match != undefined;
    }
    return false;
  };

  handleInput($event: Event) {
    const { value } = $event.target as HTMLInputElement;
    this.$searchText.next(value);
  }

  private getFilterForAllExceptEmbeddedPages(filter: IFilterList): IFilterList {
    return {
      ...filter,
      pageType: {
        matchMode: 'notEquals',
        value: PageTypes.EmbeddedPage,
      },
    };
  }

  linkClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
