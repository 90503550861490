import { QRCode } from '../entities/QRCode';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';

export class QRCodeFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createQRCode(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      code: values?.code || '',
      link: values?.link || '',
    } as QRCode;
  }
}
