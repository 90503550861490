import { Injectable } from '@angular/core';
import { InteractionConfiguration } from './../../../common/entities/Interaction';
import { ContactConfiguration } from './../../../common/entities/configuration/ContactConfiguration';

import { ApiSocketService } from '../api-socket/api-socket.service';
import { ApiService } from '../api/api.service';

import {
  AssetConfiguration,
  Configuration,
  ConfigurationEntry,
  ConfigurationType,
  LoginConfiguration,
  MenuConfiguration,
  PlatformConfiguration,
  PluginsConfiguration,
  SystemPluginsConfiguration,
  ThemeConfiguration,
} from 'src/common/entities/Configuration';
import { Group } from 'src/common/entities/Group';
import { Role } from 'src/common/entities/Role';

import { Ping } from 'src/common/api/v1/websocket/Ping';
import { Pong } from 'src/common/api/v1/websocket/Pong';

import { GetGroupQuery, GetGroupResponse } from 'src/common/api/v1/configuration/groups/GetGroup';
import { GetGroupsQuery, GetGroupsResponse } from 'src/common/api/v1/configuration/groups/GetGroups';
import { PostGroupBody, PostGroupQuery, PostGroupResponse } from 'src/common/api/v1/configuration/groups/PostGroup';
import { PostGroupsBody, PostGroupsQuery, PostGroupsResponse } from 'src/common/api/v1/configuration/groups/PostGroups';

import { GetRoleQuery, GetRoleResponse } from 'src/common/api/v1/configuration/roles/GetRole';
import { GetRolesQuery, GetRolesResponse } from 'src/common/api/v1/configuration/roles/GetRoles';
import { PostRoleBody, PostRoleQuery, PostRoleResponse } from 'src/common/api/v1/configuration/roles/PostRole';
import { PostRolesBody, PostRolesQuery, PostRolesResponse } from 'src/common/api/v1/configuration/roles/PostRoles';

import { GetVariableQuery, GetVariableResponse } from 'src/common/api/v1/configuration/variables/GetVariable';
import { GetVariablesQuery, GetVariablesResponse } from 'src/common/api/v1/configuration/variables/GetVariables';
import { PostVariableBody, PostVariableQuery, PostVariableResponse } from 'src/common/api/v1/configuration/variables/PostVariable';
import { PostVariablesBody, PostVariablesQuery, PostVariablesResponse } from 'src/common/api/v1/configuration/variables/PostVariables';
import { Variable } from 'src/common/entities/Variable';

import { GetRightsQuery, GetRightsResponse } from 'src/common/api/v1/configuration/rights/GetRights';

import { GetConfigurationQuery, GetConfigurationResponse } from 'src/common/api/v1/configuration/GetConfiguration';
import { GetObjectIdQuery, GetObjectIdResponse } from 'src/common/api/v1/configuration/GetObjectId';
import { PostConfigurationBody, PostConfigurationQuery, PostConfigurationResponse, PostConfigurationResponseError } from 'src/common/api/v1/configuration/PostConfiguration';

import { GetConfigurationsQuery, GetConfigurationsResponse } from 'src/common/api/v1/configuration/GetConfigurations';
import { GetCustomPageModuleTypesQuery, GetCustomPageModuleTypesResponse } from 'src/common/api/v1/configuration/customPageModuleType/GetCustomPageModuleTypes';
import { PostTagsImportBody, PostTagsImportQuery, PostTagsImportResponse } from 'src/common/api/v1/configuration/tags/PostTagsImport';
import { PostVariableDeleteBody, PostVariableDeleteQuery, PostVariableDeleteResponse, PostVariableDeleteResponseError } from 'src/common/api/v1/configuration/variables/PostVariableDelete';
import { CustomPageModuleType } from 'src/common/entities/pagemodules/CustomPageModule';
import { PostConfigurationsResponse } from '../../../common/api/v1/configuration/PostConfigurations';
import { DomainConfiguration } from '../../../common/entities/configuration/DomainConfiguration';
import { IPlugin } from 'src/common/plugin/IPlugin';
import { PostProductsImportBody, PostProductsImportQuery, PostProductsImportResponse } from 'src/common/api/v1/products/PostProductsImport';
import { GetConfigurationEntryQuery, GetConfigurationEntryResponse } from 'src/common/api/v1/configuration/GetConfigurationEntry';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private _serverTimeDifference: number = 0;
  private _configuration: Configuration;
  private _customPageModuleTypes: Promise<CustomPageModuleType[]> | null = null;

  constructor(private apiSocketService: ApiSocketService, private apiService: ApiService) {
    this.apiSocketService.on<Pong>('pong', (pong: Pong) => {
      const roundTripTime = new Date().getTime() - new Date(pong.clientDate).getTime();
      const latency = roundTripTime / 2;
      this._serverTimeDifference = new Date(pong.serverDate).getTime() - new Date(pong.clientDate).getTime() - latency;
    });

    this._updateServerTime();
    setInterval(this._updateServerTime, 1000 * 60 * 5);
  }

  private _updateServerTime = () => {
    this.apiSocketService.emit<Ping>('ping', { clientDate: new Date().toISOString() });
  };

  async ready() {
    this._configuration = await this.apiService.get<GetConfigurationQuery, GetConfigurationResponse>('/api/v1/configuration').toPromise();
  }

  serverTime(): Date {
    return new Date(new Date().getTime() + this._serverTimeDifference);
  }

  // ----------------------------------- Configuration -----------------------------------

  configuration(): Configuration {
    return this._configuration;
  }

  getConfigurationEntry(id: string): Promise<GetConfigurationEntryResponse> {
    return this.apiService.get<GetConfigurationEntryQuery, GetConfigurationEntryResponse>(`/api/v1/configuration/entry/${id}`).toPromise();
  }

  getConfigurations(domainCollection: string, key: string, query?: GetConfigurationQuery): Promise<GetConfigurationsResponse> {
    return this.apiService.get<GetConfigurationsQuery, GetConfigurationsResponse>(`/api/v1/configuration/${key}`, { ...query, domainCollection }).toPromise();
  }

  async getConfigurationByKeyAndDomain<T>(key: string, domainCollectionId: string, query?: GetConfigurationQuery): Promise<ConfigurationType> {
    return (await this.getConfigurations(domainCollectionId, key, query))?.items[0]?.value || null;
  }

  getLookups(key: string) {
    return this.apiService.get<GetConfigurationsQuery, GetConfigurationsResponse>(`/api/v1/configuration/lookups/${key}`).toPromise();
  }

  private async saveConfiguration(id: string, key: string, configuration: ConfigurationType, active: boolean = true, domainCollectionId: string): Promise<ConfigurationEntry> {
    const result = await this.apiService
      .post<PostConfigurationQuery, PostConfigurationBody, PostConfigurationResponse>(`/api/v1/configuration`, {
        ...(id ? { _id: id } : {}),
        key: key,
        active: active,
        domainCollection: domainCollectionId,
        value: configuration,
      })
      .toPromise();
    if (active) this._configuration[key] = result.value;
    return result;
  }

  async saveDomainConfiguration(configuration: DomainConfiguration, id: string = null): Promise<DomainConfiguration> {
    const result = await this.saveConfiguration(id, 'domain', configuration, true, null);
    return result.value as DomainConfiguration;
  }

  async savePlatformConfiguration(configuration: PlatformConfiguration, id: string = null, domainCollectionId: string) {
    const result = await this.saveConfiguration(id, 'platform', configuration, true, domainCollectionId);
    return result.value as PlatformConfiguration;
  }

  async saveAssetConfiguration(configuration: AssetConfiguration, id: string = null, domainCollectionId: string) {
    const result = await this.saveConfiguration(id, 'asset', configuration, true, domainCollectionId);
    return result.value as AssetConfiguration;
  }

  async saveLoginConfiguration(configuration: LoginConfiguration, id: string = null, domainCollectionId: string) {
    const result = await this.saveConfiguration(id, 'login', configuration, true, domainCollectionId);
    return result.value as LoginConfiguration;
  }

  async savePluginsConfiguration(configuration: PluginsConfiguration, id: string = null, domainCollectionId: string) {
    const result = await this.saveConfiguration(id, 'plugins', configuration, true, domainCollectionId);
    return result.value as PluginsConfiguration;
  }

  async saveMenuConfiguration(configuration: MenuConfiguration, id: string = null, domainCollectionId: string) {
    const result = await this.saveConfiguration(id, 'menu', configuration, true, domainCollectionId);
    return result.value as MenuConfiguration;
  }

  async saveSystemPluginsConfiguration(configuration: SystemPluginsConfiguration, id: string = null, domainCollectionId: string) {
    const result = await this.saveConfiguration(id, 'systemPlugins', configuration, true, domainCollectionId);
    return result.value as SystemPluginsConfiguration;
  }

  async saveThemeConfiguration(configuration: ThemeConfiguration, id: string = null, active: boolean = true, domainCollection: string): Promise<ConfigurationEntry> {
    return (await this.saveConfiguration(id, 'theme', configuration, active, domainCollection)) as ConfigurationEntry;
  }

  async saveContactConfiguration(configuration: ContactConfiguration, id: string = null, active: boolean = true, domainCollectionId: string): Promise<ConfigurationEntry> {
    return (await this.saveConfiguration(id, 'contact', configuration, active, domainCollectionId)) as ConfigurationEntry;
  }

  async importTags(): Promise<any> {
    return await this.apiService.post<PostTagsImportQuery, PostTagsImportBody, PostTagsImportResponse>('/api/v1/configuration/tags/import', {}).toPromise();
  }

  async importProducts(): Promise<any> {
    return await this.apiService.post<PostProductsImportQuery, PostProductsImportBody, PostProductsImportResponse>('/api/v1/configuration/products/import', {}).toPromise();
  }

  async saveInteractionConfiguration(configuration: InteractionConfiguration, id: string = null, active: boolean = true, domainCollectionId: string): Promise<ConfigurationEntry> {
    return (await this.saveConfiguration(id, 'interaction', configuration, active, domainCollectionId)) as ConfigurationEntry;
  }

  // ----------------------------------- Mongo DB OjectId -----------------------------------

  async getNewObjectId(): Promise<GetObjectIdResponse> {
    return await this.apiService.get<GetObjectIdQuery, GetObjectIdResponse>('/api/v1/configuration/objectid').toPromise();
  }

  async newObjectId(): Promise<string> {
    const result = await this.getNewObjectId();
    return result.objectId;
  }

  getGroups(query?: GetGroupsQuery): Promise<GetGroupsResponse> {
    return this.apiService.get<GetGroupsQuery, GetGroupsResponse>(`/api/v1/configuration/groups`, query).toPromise();
  }
  getGroup(groupId: string) {
    return this.apiService.get<GetGroupQuery, GetGroupResponse>(`/api/v1/configuration/groups/${groupId}`).toPromise();
  }
  createGroup(group: Group): Promise<Group> {
    return this.apiService.post<PostGroupsQuery, PostGroupsBody, PostGroupsResponse>('/api/v1/configuration/groups', group).toPromise();
  }
  updateGroup(group: Group): Promise<Group> {
    return this.apiService.post<PostGroupQuery, PostGroupBody, PostGroupResponse>(`/api/v1/configuration/groups/${group._id}`, group).toPromise();
  }

  // ----------------------------------- Entity Role -----------------------------------

  getRoles(query?: GetRolesQuery) {
    return this.apiService.get<GetRolesQuery, GetRolesResponse>(`/api/v1/configuration/roles`, query).toPromise();
  }
  getRole(roleId: string) {
    return this.apiService.get<GetRoleQuery, GetRoleResponse>(`/api/v1/configuration/roles/${roleId}`).toPromise();
  }
  createRole(role: Role): Promise<Role> {
    return this.apiService.post<PostRolesQuery, PostRolesBody, PostRolesResponse>('/api/v1/configuration/roles', role).toPromise();
  }
  updateRole(role: Role): Promise<Role> {
    return this.apiService.post<PostRoleQuery, PostRoleBody, PostRoleResponse>(`/api/v1/configuration/roles/${role._id}`, role).toPromise();
  }

  // ----------------------------------- Entity Right -----------------------------------

  getRights(): Promise<GetRightsResponse> {
    return this.apiService.get<GetRightsQuery, GetRightsResponse>(`/api/v1/configuration/rights`).toPromise();
  }

  // ----------------------------------- Variables -----------------------------------
  getVariables(domainCollectionId: string, query?: GetVariablesQuery) {
    return this.apiService.get<GetVariablesQuery, GetVariablesResponse>('/api/v1/configuration/variables', { ...query, domainCollection: domainCollectionId }).toPromise();
  }
  getVariable(variableId: string) {
    return this.apiService.get<GetVariableQuery, GetVariableResponse>(`/api/v1/configuration/variables/${variableId}`).toPromise();
  }
  createVariable(domainCollectionId: string, variable: Variable): Promise<Variable> {
    return this.apiService.post<PostVariablesQuery, PostVariablesBody, PostVariablesResponse>('/api/v1/configuration/variables', { ...variable, domainCollection: domainCollectionId }).toPromise();
  }
  updateVariable(variable: Variable): Promise<Variable> {
    return this.apiService.post<PostVariableQuery, PostVariableBody, PostVariableResponse>(`/api/v1/configuration/variables/${variable._id}`, variable).toPromise();
  }
  deleteVariable(variableId: string) {
    return this.apiService
      .post<PostVariableDeleteQuery, PostVariableDeleteBody, PostVariableDeleteResponse | PostVariableDeleteResponseError>(`/api/v1/configuration/variables/${variableId}/delete`, {})
      .toPromise();
  }

  async deleteConfig(configId: string): Promise<PostConfigurationResponse | PostConfigurationResponseError> {
    return await this.apiService
      .post<PostConfigurationQuery, PostConfigurationBody, PostConfigurationResponse | PostConfigurationResponseError>(`/api/v1/configuration/${configId}/delete`, null)
      .toPromise();
  }

  async splitConfig(key: string): Promise<ConfigurationEntry[]> {
    return (
      await this.apiService
        .post<PostConfigurationQuery, PostConfigurationBody, PostConfigurationsResponse>(`/api/v1/configuration/split-config/${key}`, {
          active: false,
          value: undefined,
          key: key,
        })
        .toPromise()
    ).items;
  }

  async unifyConfig(key: string, domainId: string): Promise<ConfigurationEntry[]> {
    return (
      await this.apiService
        .post<PostConfigurationQuery, PostConfigurationBody, PostConfigurationsResponse>(`/api/v1/configuration/unify-config/${key}`, {
          active: false,
          value: undefined,
          key: key,
          domainCollection: domainId,
        })
        .toPromise()
    ).items;
  }

  async rebuildSearchIndex() {
    return await this.apiService.post('/api/v1/configuration/search/build', {}).toPromise();
  }

  async forceRefresh() {
    return await this.apiService.post('/api/v1/configuration/version/refresh', {}).toPromise();
  }

  async customPageModuleTypes(): Promise<CustomPageModuleType[]> {
    if (!this._customPageModuleTypes) {
      this._customPageModuleTypes = this.apiService
        .get<GetCustomPageModuleTypesQuery, GetCustomPageModuleTypesResponse>(`/api/v1/configuration/custompagemoduletypes`)
        .toPromise()
        .then((c) => c.items);
    }

    return this._customPageModuleTypes;
  }

  async customPageModuleType(plugin: IPlugin, customPageModuleType: string): Promise<CustomPageModuleType | null> {
    const types = await this.customPageModuleTypes();
    return types.find((t) => t.plugin.name === plugin.name && t.plugin.version === plugin.version && t.customPageModuleType === customPageModuleType) || null;
  }
}
