import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';
import { PagesService } from 'src/app/services/pages/pages.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { AssetPage, Page, PageTypes, PageVersion, isLinkedPage, isNonVersionedPage } from 'src/common/entities/Page';
import { Inputs } from 'src/common/inputs/Inputs';
import { pageInputs } from 'src/common/inputs/page/PageInputs';
import { LayoutContext } from '../../layout-editor/layout-editor';
import { AssetScopeTypeLabels, AssetScopeTypes } from '../../../common/entities/Page';
import { backgroundThemes } from '../../../common/entities/pagemodules/PageModule';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { DomainService } from '../../services/domain/domain.service';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-page-configuration',
  templateUrl: './page-configuration.component.html',
  styleUrls: ['./page-configuration.component.scss'],
})
export class PageConfigurationComponent implements OnInit {
  page: Page;
  linkedPage?: Page = null;
  domainCollectionId: string = null;
  initialPath: string = null;
  hasEditRights: boolean = false;
  hasPublishRights: boolean = false;
  isNonVersionedPage: boolean = false;
  isLinkedPage: boolean = false;

  tab: Tab;
  embeddedeMode = false;
  working: boolean;
  pageId: string;
  pageVersion: PageVersion;
  linkedPageVersion?: PageVersion = null;
  linkedPageVersions?: PageVersion[] = null;
  pageVersions: PageVersion[] = [];
  domain = '';

  currentLanguage = 'en';

  showConfiguration = false;
  showVersions = false;
  showPreview = false;

  inputsConfiguration: Inputs = {
    ...pageInputs,
    '$.pageType': { disabled: true },
    '$.assetScope': {
      type: 'dropdown',
      dropdownOptions: AssetScopeTypes.map((type, i) => ({
        value: type,
        label: AssetScopeTypeLabels[i],
      })),
    },
    '$.local.$language.asset': { type: 'videoasset' },
    '$.backgroundTheme': {
      type: 'dropdown',
      dropdownOptions: [...backgroundThemes].map((a) => ({
        label: a,
        value: a,
      })),
    },
    '$.paddingTop': { type: 'switch' },
    '$.paddingBottom': { type: 'switch' },
    '$.paddingLeft': { type: 'switch' },
    '$.paddingRight': { type: 'switch' },
    '$.accessPolicy': { type: 'accesspolicy' },
    '$.playerOverlayPicture': { type: 'imageasset' },
  };
  inputJsonpathesConfiguration = ['$.pageType', '$.internalName', '$.path', '$.linkedPage', '$.showBackButton', '$.showSubmenu', '$.showInSubmenu'];
  inputJsonpathesAssetPageConfiguration = [
    '$.assetScope',
    '$.local.$language.asset',
    '$.showShareButton',
    '$.videoGridSettings.showAssetTags',
    '$.videoGridSettings.showLanguageTags',
    '$.videoGridSettings.showEventtypeTags',
    '$.videoGridSettings.showDate',
    '$.showLikeButton',
    '$.shareButtonTargets',
    '$.backgroundTheme',
    '$.paddingTop',
    '$.paddingBottom',
    '$.accessPolicy',
    '$.playerOverlayPicture',
  ];
  inputJsonpathesRecommendedVideosConfiguration = [
    '$.recommendationsSettings.teaserLayoutVariation',
    '$.recommendationsSettings.showVideoTitle',
    '$.recommendationsSettings.showAssetTags',
    '$.recommendationsSettings.showLanguageTags',
    '$.recommendationsSettings.showEventtypeTags',
    '$.recommendationsSettings.showDate',
    '$.recommendationsSettings.showVideoStatusFlag',
    '$.recommendationsSettings.showProgressBar',
    '$.recommendationsSettings.showPlayButton',
    '$.recommendationsSettings.navigationType',
  ];
  inputJsonPathesPagination: string[] = ['$.recommendationsSettings.pagination', '$.recommendationsSettings.showSliderPagination'];
  inputJsonPathesLoadMore: string[] = ['$.recommendationsSettings.loadMoreInitialAmount', '$.recommendationsSettings.loadMoreAmount', '$.recommendationsSettings.local.$language.loadMoreButtonLabel'];
  inputJsonpathesAssetPageRecommendedVideosConfiguration = [
    '$.recommendationsSettings.backgroundTheme',
    '$.recommendationsSettings.paddingTop',
    '$.recommendationsSettings.paddingBottom',
    '$.recommendationsSettings.accessPolicy',
  ];

  subscriptions: Subscription[] = [];

  constructor(
    private authServie: AuthService,
    private collaborationService: CollaborationService,
    private pagesService: PagesService,
    private tabsService: TabsService,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private configurationService: ConfigurationService,
    private domainService: DomainService,
    private router: Router,
    private messageService: MessageService
  ) {
    this.embeddedeMode = window.location.href.includes('/embedded');
    this.tab = this.tabsService.register({
      category: 'pages',
      loading: true,
      route: this.activatedRoute.snapshot,
      titlePrefix: 'Edit',
      parent: !this.embeddedeMode ? '/pages' : '/embedded-pages',
    });
  }

  async ngOnInit(): Promise<void> {
    this.pageId = this.activatedRoute.snapshot.params.pageId;
    this.hasEditRights = await this.authServie.hasRight('pages:' + this.pageId + '$' + 'pages.edit');
    this.hasPublishRights = await this.authServie.hasRight('pages:' + this.pageId + '$' + 'pages.publish');
    this.domainCollectionId = this.activatedRoute.snapshot.queryParams.domainCollectionId;

    this.subscriptions.push(
      this.pagesService.subscribePage(this.pageId).subscribe(async (page) => {
        this.page = page;

        this.isNonVersionedPage = isNonVersionedPage(page);
        this.isLinkedPage = isLinkedPage(page);

        if (this.isLinkedPage) {
          this.loadPage();
        }

        await this.loadPageVersions();

        if (this.pageVersions.length > 0) {
          await this.loadPageVersion(this.pageVersions[0]._id);
        }

        if (this.linkedPageVersions?.length > 0) {
          await this.loadLinkedPageVersion(this.linkedPageVersions[0]._id);
        }

        if (this.pageVersion?.languages?.length > 0) {
          if (this.isLinkedPage) {
            this.currentLanguage = this.linkedPageVersion.languages[0];
          } else {
            this.currentLanguage = this.pageVersion.languages[0];
          }
        }

        this.domain = this.domainService.findFirstFrontendDomain(this.page.domainCollection);
      })
    );
  }

  async loadPageVersions(): Promise<void> {
    this.pageVersions = (
      await this.pagesService.getPageVersions(this.pageId, {
        orderBy: 'version',
        orderDirection: -1,
      })
    ).items;
    if (isLinkedPage(this.page)) {
      this.linkedPageVersions = (
        await this.pagesService.getPageVersions(this.page?.linkedPage, {
          orderBy: 'version',
          orderDirection: -1,
        })
      ).items;
    }
    this.tab.title = this.page.internalName;
    this.tab.loading = false;
  }

  async loadPage(): Promise<void> {
    this.page = await this.pagesService.getPage(this.pageId);

    if (isLinkedPage(this.page)) {
      this.linkedPage = await this.pagesService.getPage(this.page.linkedPage);
    }
  }

  async work(pp: { page?: Promise<Page> | (() => Promise<Page>); pageVersion?: Promise<PageVersion>; loadPageVersions?: boolean; loadPage?: boolean }): Promise<void> {
    this.working = true;

    try {
      if (pp.pageVersion) {
        this.pageVersion = null;
      }

      if (pp.pageVersion) {
        this.pageVersion = await pp.pageVersion;
      }
      if (pp.page) {
        this.page = await (typeof pp.page == 'function' ? pp.page() : pp.page);
      }

      if (pp.loadPageVersions === true) {
        await this.loadPageVersions();
      }

      if (pp.loadPage === true) {
        await this.loadPage();
      }

      this.checkDisable();
    } catch (err) {
      console.error(err);
    }

    this.working = false;
  }

  async loadPageVersion(pageVersionId: string): Promise<void> {
    await this.work({
      pageVersion: this.pagesService.getPageVersion(this.pageId, pageVersionId),
    });
  }

  async loadLinkedPageVersion(linkedPageVersionId: string): Promise<void> {
    if (this.linkedPageVersions && isLinkedPage(this.page)) {
      this.linkedPageVersion = await this.pagesService.getPageVersion(this.page.linkedPage, linkedPageVersionId);
    }
  }

  async savePageConfiguration(): Promise<void> {
    if (this.page.internalName && this.page.path) {
      const initialPath: string = (await this.pagesService.getPage(this.pageId)).path;
      const pathExists = await this.pagesService.pathExists(this.page.path, this.page.domainCollection);
      if (pathExists && initialPath !== this.page.path) {
        const summary = `A page with path '${this.page.path}' already exist. The path needs to be unique.`;
        this.messageService.add({ severity: 'error', summary });
      } else {
        await this.work({
          page: this.pagesService.updatePage(this.page),
          loadPage: true,
        });
        this.showConfiguration = false;
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Please fill empty forms.',
      });
    }
    if (this.linkedPage) {
      this.loadPage();
      await this.loadPageVersions();
      if (this.linkedPageVersions?.length > 0) {
        await this.loadLinkedPageVersion(this.linkedPageVersions[0]._id);
      }
    }
  }

  async savePageVersion(pageVersionId: string): Promise<void> {
    if (isNonVersionedPage(this.page)) return;

    await this.work({
      pageVersion: this.pagesService.savePageVersion(this.pageId, pageVersionId),
      loadPageVersions: true,
    });
  }

  async publishPageVersion(pageVersionId: string): Promise<void> {
    await this.work({
      pageVersion: this.pagesService.publishPageVersion(this.pageId, pageVersionId),
      page: () => this.pagesService.getPage(this.pageId),
      loadPageVersions: true,
    });
  }

  async forkPageVersion(pageVersionId: string): Promise<void> {
    if (isNonVersionedPage(this.page)) return;

    await this.work({
      pageVersion: this.pagesService.forkPageVersion(this.pageId, pageVersionId),
      loadPageVersions: true,
    });
  }

  checkDisable(): void {
    if (this.hasEditRights && this.pageVersion.dirty) {
      this.collaborationService.enable(`pageversion:${this.pageVersion._id}`);
    } else {
      this.collaborationService.disable(`pageversion:${this.pageVersion._id}`);
    }
  }

  async onActiveLanguageChange(languages: string[]): Promise<void> {
    if (!languages || languages.length === 0) {
      // One language has to exist
      languages = ['en'];
      this.pageVersion.languages = languages;
    }
    const successful = await this.pagesService.patch(this.pageVersion, {
      command: 'set',
      jsonpath: `$.languages`,
      value: languages,
    });
  }

  pageAsPageVersion(): PageVersion {
    return this.page as PageVersion;
  }

  async contextChanged(context: LayoutContext): Promise<void> {
    if (!context.page) {
      const {
        pageVersion: { pageType },
      } = context;
      const route = pageType === PageTypes.EmbeddedPage ? '/configuration/embedded-pages' : '/configuration/pages';
      await this.router.navigate([route], {
        replaceUrl: true,
      });
    }
  }

  pageURL() {
    const path = this.page.fullPath ? this.page.fullPath : this.page.path;
    window.open(`${this.domain}/${this.currentLanguage}/${path}`);
  }

  asAssetPage(page: Page): AssetPage {
    return page as AssetPage;
  }
}
