import { InteractionConfiguration, QuickReaction, QuickReactionsLocal } from './../entities/Interaction';
import { ContactMethodType, EmailContactMethod, FormContactMethod, FormContactMethodLocal } from './../entities/configuration/ContactConfiguration';
import {
  AppAzureADLogin,
  AssetConfiguration,
  AzureADLogin,
  ConfigurationEntry,
  ContactConfiguration,
  DMGCoreNavigationConfiguration,
  ExternalConsent,
  FacebookPixelConfiguration,
  GoToWebinarSystemPlugin,
  GTMBasicConfiguration,
  GTMConfiguration,
  headerStyle,
  LocalLogin,
  Login,
  LoginConfiguration,
  LoginType,
  MenuConfiguration,
  O365SystemPlugin,
  OpenIdConnectLogin,
  PlatformConfiguration,
  PluginsConfiguration,
  ProductsImportPlugin,
  SourcePointConfiguration,
  SystemPluginsConfiguration,
  SystemPluginType,
  TagsImportPlugin,
  ThemeConfiguration,
  UsercentricsConfiguration,
  UsercentricsV2BasicConfiguration,
  UsercentricsV2Configuration
} from '../entities/Configuration';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { MenuItemFactory } from './MenuItemFactory';
import { DomainCollection, DomainCollectionLocal, DomainConfiguration } from '../entities/configuration/DomainConfiguration';

export class ConfigurationFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public localLogin(values: any) {
    return {
      loginType: 'Local',
      enabled: typeof values?.enabled === 'boolean' ? values.enabled : false
    } as LocalLogin;
  }

  public azureADLogin(values: any) {
    return {
      loginType: 'AzureAD',
      enabled: typeof values?.enabled === 'boolean' ? values.enabled : false,
      splitLogin: typeof values?.enabled === 'boolean' ? values.splitLogin : false,
      clientId: values?.clientId || '',
      redirectURI: values?.redirectURI || '',
      logoutRedirectURI: values?.logoutRedirectURI || '',
      authority: values?.authority || '',
      authorityRegister: values?.authorityRegister || '',
      knownAuthorities: values?.knownAuthorities || []
    } as AzureADLogin;
  }

  public appAzureADLogin(values: any) {
    return {
      loginType: 'AppAzureAD',
      enabled: typeof values?.enabled === 'boolean' ? values.enabled : false,
      splitLogin: typeof values?.enabled === 'boolean' ? values.splitLogin : false,
      clientId: values?.clientId || '',
      redirectURI: values?.redirectURI || '',
      logoutRedirectURI: values?.logoutRedirectURI || '',
      authority: values?.authority || '',
      authorityRegister: values?.authorityRegister || '',
      knownAuthorities: values?.knownAuthorities || []
    } as AppAzureADLogin;
  }

  public openIdConnectLogin(values: any) {
    return {
      loginType: 'OpenIdConnect',
      enabled: typeof values?.enabled === 'boolean' ? values.enabled : false,
      clientId: values?.clientId || '',
      redirectURI: values?.redirectURI || '',
      authority: values?.authority || '',
      authorityRegister: values?.authorityRegister || '',
      issuer: values?.issuer || ''
    } as OpenIdConnectLogin;
  }

  public login(value: { loginType: LoginType }) {
    switch (value.loginType) {
      case 'Local':
        return this.localLogin(value);
      case 'AzureAD':
        return this.azureADLogin(value);
      case 'AppAzureAD':
        return this.appAzureADLogin(value);
      case 'OpenIdConnect':
        return this.openIdConnectLogin(value);
    }
  }

  public contactMethod(value: { contactMethodType: ContactMethodType }) {
    switch (value.contactMethodType) {
      case 'Email':
        return this.emailContactMethod(value);
      case 'Form':
        return this.formContactMethod(value);
    }
  }

  public emailContactMethod(values: any) {
    return {
      contactMethodType: 'Email',
      contactEnabled: typeof values?.contactEnabled === 'boolean' ? values?.contactEnabled : true
    } as EmailContactMethod;
  }

  public formContactMethod(values: any) {
    return {
      contactMethodType: 'Form',
      form: values?.form || null,
      local: Object.keys(values?.local || {}).reduce((a, b) => ({ ...a, [b]: this.createFormContactLocal(values.local[b]) }), {}),
      contactEnabled: typeof values?.contactEnabled === 'boolean' ? values?.contactEnabled : true
    } as FormContactMethod;
  }

  public createFormContactLocal(value?: any) {
    return {
      label: value?.label || ''
    } as FormContactMethodLocal;
  }

  public loginConfiguration(values: any) {
    return {
      logins: (values?.logins || []).map((l: any) => this.login(l)),
      defaults: values?.defaults || (values?.logins ? Object.assign({}, ...values?.logins.map((l: Login) => ({ [l.loginType]: null }))) : [])
    } as LoginConfiguration;
  }

  public externalConsent(values?: Partial<ExternalConsent>): ExternalConsent {
    return {
      enabled: typeof values?.enabled === 'boolean' ? values?.enabled : false,
      consentParameter: values?.consentParameter || null,
      redirectUrl: values?.redirectUrl || null
    };
  }

  public googleTagManagerBasicConfiguration(values?: Partial<GTMBasicConfiguration>): GTMBasicConfiguration {
    return {
      id: values?.id || null,
      scriptTagType: values?.scriptTagType || null,
      additionalDatalayerEvents: typeof values?.additionalDatalayerEvents === 'boolean' ? values?.additionalDatalayerEvents : false
    };
  }

  public googleTagManager(values?: Partial<GTMConfiguration>): GTMConfiguration {
    return {
      ...this.googleTagManagerBasicConfiguration(values),
      enabled: typeof values?.enabled === 'boolean' ? values?.enabled : false,
      app: this.googleTagManagerBasicConfiguration(values?.app)
    };
  }

  public userCentrics(values?: Partial<UsercentricsConfiguration>): UsercentricsConfiguration {
    return {
      enabled: typeof values?.enabled === 'boolean' ? values?.enabled : false,
      id: values?.id || null,
      scriptUrl: values?.scriptUrl || null
    };
  }

  public userCentricsBasicConfigurationV2(values?: Partial<UsercentricsV2BasicConfiguration>): UsercentricsV2BasicConfiguration {
    return {
      id: values?.id || null,
      analyticsServiceId: values?.analyticsServiceId || null,
      analyticsServiceName: values?.analyticsServiceName || null,
      scriptUrl: values?.scriptUrl || null,
      onConsentEventName: values?.onConsentEventName || null,
      crossDomainConsent: values?.crossDomainConsent || null
    };
  }

  public userCentricsV2(values?: Partial<UsercentricsV2Configuration>): UsercentricsV2Configuration {
    return {
      ...this.userCentricsBasicConfigurationV2(values),
      enabled: typeof values?.enabled === 'boolean' ? values?.enabled : false,
      app: this.userCentricsBasicConfigurationV2(values?.app)
    };
  }

  public sourcePoint(values?: Partial<SourcePointConfiguration>): SourcePointConfiguration {
    return {
      enabled: typeof values?.enabled === 'boolean' ? values?.enabled : false,
      id: values?.id || null,
      baseEndpoint: values?.baseEndpoint || null,
      analyticsServiceId: values?.analyticsServiceId || null,
      targetingParams: values?.targetingParams || {}
    };
  }

  public facebookPixel(values?: Partial<FacebookPixelConfiguration>): FacebookPixelConfiguration {
    return {
      enabled: typeof values?.enabled === 'boolean' ? values?.enabled : false,
      id: values?.id || null
    };
  }
  public dmgCoreNavigation(values?: Partial<DMGCoreNavigationConfiguration>): DMGCoreNavigationConfiguration {
    return {
      enabled: typeof values?.enabled === 'boolean' ? values?.enabled : false,
      type: values?.type || 'event'
    };
  }

  public pluginsConfiguration(values: PluginsConfiguration): PluginsConfiguration {
    return {
      externalConsent: this.externalConsent(values?.externalConsent),
      gtm: this.googleTagManager(values?.gtm),
      gtm2: this.googleTagManager(values?.gtm2),
      usercentrics: this.userCentrics(values?.usercentrics),
      usercentricsV2: this.userCentricsV2(values?.usercentricsV2),
      sourcepoint: this.sourcePoint(values?.sourcepoint),
      facebookPixel: this.facebookPixel(values?.facebookPixel),
      dmgCoreNavigation: this.dmgCoreNavigation(values?.dmgCoreNavigation)
    };
  }

  public async domainConfiguration(values: any) {
    return {
      domainCollections: await Promise.all((values?.domainCollections || []).map((d: any) => this.domainCollection(d)))
    } as DomainConfiguration;
  }

  public domainCollectionLocal(values: any): DomainCollectionLocal {
    return {
      title: values?.title || '',
      shortDescription: values?.shortDescription || ''
    };
  }

  public async domainCollection(values: any): Promise<DomainCollection> {
    return {
      _id: values?._id || null,
      internalName: values?.internalName || '',
      reachableByEvents: values?.reachableByEvents || false,
      domains: values?.domains || [],
      copiedFrom: values?.copiedFrom || null,
      previewImage: values?.previewImage || null,
      local: await this.local(values, v => this.domainCollectionLocal(v))
    };
  }

  public async platformConfiguration(values: any) {
    return {
      singleEvent: values?.singleEvent || null,
      defaultPage: values?.defaultPage || null,
      logoClickUrl: values?.logoClickUrl || null,
      logoClickUrlTarget: values?.logoClickUrlTarget || 'sameTab',
      defaultEventRegistration: values?.defaultEventRegistration || null,
      navigationTargetPage: values?.navigationTargetPage || null,
      loginProtected: typeof values?.loginProtected === 'boolean' ? values?.loginProtected : false,
      privacyPolicyMode: values?.privacyPolicyMode || 'enforce',
      termsAndConditionsMode: values?.termsAndConditionsMode || 'enforce',
      languageAndCountryMode: values?.languageAndCountryMode || 'languageOnly',
      enableSearchFeature: !!values?.enableSearchFeature || false,
      enableContentSecurityPolicy: values?.enableContentSecurityPolicy || false,
      contentSecurityPolicy: values?.contentSecurityPolicy || '',
      explorePage: values?.explorePage || null,
      defaultOnboarding: values?.defaultOnboarding || null
    } as PlatformConfiguration;
  }

  public contactConfiguration(values: any): ContactConfiguration {
    return {
      _id: values?.id || null,
      contactMethods: (values?.contactMethods || []).map((l: any) => this.contactMethod(l)),
      contactPublic: typeof values?.contactPublic === 'boolean' ? values?.contactPublic : true
    };
  }

  public async interactionConfiguration(values: any) {
    return {
      _id: await this.id(values?._id),
      quickReactions: values?.quickReactions || []
    } as InteractionConfiguration;
  }

  public async quickReaction(values: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      enabled: values?.enabled || true,
      animationJSON: values?.animationJSON || null,
      local: Object.keys(values?.local || {}).reduce((a, b) => ({ ...a, [b]: this.quickReactionLocal(values.local[b]) }), {})
    } as QuickReaction;
  }

  quickReactionLocal(values?: any) {
    return {
      label: values?.label || ''
    } as QuickReactionsLocal;
  }

  public async assetConfiguration(values: any): Promise<AssetConfiguration> {
    return {
      statusNewDurationInDays: values?.statusNewDurationInDays || 14,
      videoPlayerType: values?.videoPlayerType || 'AMP',
      videoTarget: values?.videoTarget || 'overlay',
      videoAssetPage: values?.videoAssetPage || null,
      showVideoTitle: typeof values?.showVideoTitle === 'boolean' ? values?.showVideoTitle : true,
      showAssetTags: typeof values?.showAssetTags === 'boolean' ? values?.showAssetTags : true,
      showVideoDescription: typeof values?.showVideoDescription === 'boolean' ? values?.showVideoDescription : false,
      showLanguageTags: typeof values?.showLanguageTags === 'boolean' ? values?.showLanguageTags : true,
      showKeytopicTags: typeof values?.showKeytopicTags === 'boolean' ? values?.showKeytopicTags : true,
      showEventTypeTags: typeof values?.showEventTypeTags === 'boolean' ? values?.showEventTypeTags : true,
      showEventTitle: typeof values?.showEventTitle === 'boolean' ? values?.showEventTitle : true,
      showDate: typeof values?.showDate === 'boolean' ? values?.showDate : true
    };
  }

  public async menuConfiguration(values: any): Promise<MenuConfiguration> {
    return {
      headerMenuItems: await Promise.all((values?.headerMenuItems || []).map((i: any) => AbstractFactory.get(MenuItemFactory).menuItem(i))),
      footerMenuItems: await Promise.all((values?.footerMenuItems || []).map((i: any) => AbstractFactory.get(MenuItemFactory).menuItem(i))),
      userContextMenuItems: await Promise.all((values?.userContextMenuItems || []).map((i: any) => AbstractFactory.get(MenuItemFactory).menuItem(i)))
    };
  }

  public themeConfiguration(values: any) {
    return {
      internalName: values?.internalName || '',
      description: values?.description || '',
      icon: values?.icon || null,
      sharingImage: values?.sharingImage || null,
      logo: values?.logo || null,
      logoMobile: values?.logoMobile || null,
      appLogo: values?.appLogo || null,
      globalButtonStyle: values?.globalButtonStyle || 'default',
      iconFont: values?.iconFont || 'default',
      headerStyle: values?.headerStyle || headerStyle[0],
      mobileNavigationOnly: typeof values?.mobileNavigationOnly === 'boolean' ? values?.mobileNavigationOnly : false,
      stickyNavigation: typeof values?.stickyNavigation === 'boolean' ? values?.stickyNavigation : false,
      theme: values?.theme || '',
      loginDialogHeaderImage: values?.loginDialogHeaderImage || '',
      loginDialogBackgroundImage: values?.loginDialogBackgroundImage || '',
      loginDialogCheckIcon: values?.loginDialogCheckIcon || '',
      loginDialogArrowIcon: values?.loginDialogArrowIcon || '',
      loginDialogButtonAlignmentSection: values?.loginDialogButtonAlignmentSection || 'left',
      loginDialogModalType: values?.loginDialogModalType || 'closeIconOutside'
    } as ThemeConfiguration;
  }

  public async configurationEntry(values: any): Promise<ConfigurationEntry> {
    return {
      ...values,
      _id: await this.id(values?._id),
      active: typeof values?.active === 'boolean' ? values?.active : false,
      key: values?.key || '',
      domainCollection: values?.domainCollection || null,
      value: values?.value || null
    };
  }

  public systemPluginsConfiguration(values?: any): SystemPluginsConfiguration {
    return {
      plugins: (values?.plugins || []).map((plugin: any) => this.systemPlugin(plugin))
    };
  }

  public systemPlugin(value?: { pluginType?: SystemPluginType }) {
    switch (value?.pluginType) {
      case 'GoToWebinar':
        return this.goToWebinar(value);
      case 'O365':
        return this.o365(value);
      case 'TagsImport':
        return this.tagsImport(value);
      case 'ProductsImport':
        return this.productsImport(value);
    }

    throw new Error(`Invalid system plugin type '${value?.pluginType}'`);
  }

  public goToWebinar(values?: any): GoToWebinarSystemPlugin {
    return {
      pluginType: 'GoToWebinar',
      adminUser: values?.adminUser || null,
      eventTemplate: values?.eventTemplate || null,
      defaultRegistrationValue: values?.defaultRegistrationValue || null,
      eventType: values?.eventType || null
    };
  }

  public o365(values?: any): O365SystemPlugin {
    return {
      pluginType: 'O365'
    };
  }

  public tagsImport(values?: any): TagsImportPlugin {
    return {
      pluginType: 'TagsImport'
    };
  }

  public productsImport(values?: any): ProductsImportPlugin {
    return {
      pluginType: 'ProductsImport'
    };
  }
}
