import { EventVersion } from '../../entities/EventVersion';
import { Patch } from '../../patch/Patch';
import { Inputs } from '../Inputs';

export const eventVersionReactionsInputs: Inputs = {
  '$.reactions[?(@.reaction=="$reactionId")]': {
    beforePatching: async (obj: EventVersion, patch: Patch) => {
      if (patch.command === 'delete') {
        const patches: Patch[] = [];

        for (const session of obj.sessions) {
          for (const language of Object.keys(session.local)) {
            if (session.local[language].reaction === patch.jsonpathParams?.reactionId) {
              patches.push({
                command: 'set',
                value: null,
                jsonpath: "$.sessions[?(@._id=='$sessionId')].local.$language.reaction",
                jsonpathParams: {
                  sessionId: session._id,
                  language,
                },
              });
            }
          }
        }

        return patches;
      }
      return [];
    },
  },
  "$.reactions[?(@.reaction=='$reactionId')].internalName": {
    header: 'Internal Name',
  },
  "$.reactions[?(@.reaction=='$reactionId')].background": {
    header: 'Background',
  },
  "$.reactions[?(@.reaction=='$reactionId')].backgroundColor": {
    header: 'Background-Color',
  },
  "$.reactions[?(@.reaction=='$reactionId')].maxParallelConnections": {
    header: 'Max. Parallel Quick Reactions',
    type: 'number',
  },
};
