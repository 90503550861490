import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TableComponent } from 'src/app/components/table/table.component';
import { TableOptions, TableQuery } from 'src/app/components/table/table.interfaces';
import { CSPReportsService } from 'src/app/services/csp-reports/csp-reports.service';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { CSPReport } from 'src/common/entities/CSPReport';

@Component({
  selector: 'app-csp-reports',
  templateUrl: './csp-reports.component.html',
  styleUrls: ['./csp-reports.component.scss'],
})
export class CSPReportsComponent implements OnInit {
  constructor(
    private cspReportsService: CSPReportsService,
    private tabsService: TabsService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute
  ) {}
  @ViewChild(TableComponent) table: TableComponent<CSPReport>;
  tab: Tab;
  tableOptions: TableOptions<CSPReport>;
  working: boolean = false;
  selection: CSPReport[];

  ngOnInit() {
    this.tab = this.tabsService.register({
      category: 'data',
      route: this.activatedRoute.snapshot,
      title: 'CSP-Reports',
    });

    this.tableOptions = {
      size: 50,
      defaultSortDirection: 'desc',
      columns: [
        { header: 'Date', sort: 'date' },
        { header: 'Domain', sort: 'domain' },
        { header: 'User Agent', sort: 'user-agent', visible: false },
        { header: 'Disposition', sort: 'csp-report.disposition' },
        { header: 'Effective Directive', sort: 'csp-report.effective-directive' },
        { header: 'Blocked URI', sort: 'csp-report.blocked-uri' },
        { header: 'Source File', sort: 'csp-report.source-file' },
        { header: 'Line', sort: 'csp-report.line-number' },
        { header: 'Column', sort: 'csp-report.column-number' },
        { header: 'Original Policy', sort: 'csp-report.original-policy', visible: false },
        { header: 'Document URI', sort: 'csp-report.document-uri' },
        { header: 'Status Code', sort: 'csp-report.status-code' },
      ],
      filters: [
        { header: 'Date', path: 'date', type: 'date' },
        { header: 'Domain', path: 'domain' },
        { header: 'User Agent', path: 'user-agent' },
        { header: 'Disposition', path: 'csp-report.disposition' },
        { header: 'Effective Directive', path: 'csp-report.effective-directive' },
        { header: 'Blocked URI', path: 'csp-report.blocked-uri' },
        { header: 'Source File', path: 'csp-report.source-file' },
        { header: 'Original Policy', path: 'csp-report.original-policy' },
        { header: 'Document URI', path: 'csp-report.document-uri' },
      ],
      selectable: true,
    };
  }

  select(data) {
    this.selection = data;
  }

  query(query: TableQuery<CSPReport>) {
    query.result = this.cspReportsService.getReports(query.query);
  }

  async refresh(): Promise<void> {
    this.working = true;
    await this.table.refresh();
    this.working = false;
  }

  async confirmDeleteMessage(event: Event) {
    this.confirmationService.confirm({
      key: 'deleteConfirmPopup',
      target: event.target,
      message: `Are you sure that you want to delete ${this.selection.length > 1 ? 'all ' : ''}${this.selection.length} CSP ${this.selection.length > 1 ? 'Reports' : 'Report'}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteSelected();
      },
      reject: () => {
        // Nothing to do
      },
    });
  }

  async deleteSelected(): Promise<void> {
    this.working = true;
    const ids = this.selection.map((o) => o._id);
    let result = null;
    try {
      result = await this.cspReportsService.deleteSelectedReports(ids);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: `Deletion Error`, detail: `The deletion of CSP Reports failed` });
      return;
    }
    await this.table.refresh();
    this.working = false;
    this.messageService.add({ severity: 'success', summary: `Deletion Succeeded`, detail: `Deleted ${result.nDeleted} CSP ${result.nDeleted > 1 ? 'Reports' : 'Report'}` });
  }
}
