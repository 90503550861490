import { Component, Input } from '@angular/core';

import { TitleEventStage } from 'src/common/entities/EventStage';
import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';

@Component({
  selector: 'c-title-event-stage-edit',
  templateUrl: './title-event-stage-edit.component.html',
  styleUrls: ['./title-event-stage-edit.component.scss'],
})
export class TitleEventStageEditComponent {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  eventStage: TitleEventStage;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathes: string[] = ['$.local.$language.title', '$.local.$language.icon', '$.showLiveButton', '$.background'];

  tab = 'general';
}
