import { PageModuleFactory } from './pagemodules/PageModuleFactory';

import { AssetPage, AssetScopeTypes, BlankPage, EmbeddedPage, EmbeddedPageLocal, KeytopicsPage, PageLocal, PageTypes, ProfilePage, StandardPage, ExplorePage } from '../entities/Page';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { EventStageFactory } from './EventStageFactory';
import { backgroundThemes } from '../entities/pagemodules/PageModule';

export class PageFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async pageLocal(values: any): Promise<PageLocal> {
    return {
      title: values?.title || '',
      navigationTitle: values?.navigationTitle || '',
      metaDescription: values?.metaDescription || '',
      linkTitle: values?.linkTitle || '',
      linkDescription: values?.linkDescription || '',
      asset: values?.asset || '',
      displayOption: values?.displayOption || 'everywhere'
    } as PageLocal;
  }

  public assetPageRecommendationsSettingsLocal(values: any) {
    return {
      loadMoreButtonLabel: values?.loadMoreButtonLabel || ''
    };
  }

  public async blankPage(values: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      default: typeof values?.default === 'boolean' ? values.default : false,
      languages: values?.languages?.length > 0 ? values.languages : ['en'],
      pageType: PageTypes.BlankPage,
      readAccess: values?.readAccess || [],
      internalName: values?.internalName || null,
      path: values?.path || null,
      showBackButton: typeof values?.showBackButton === 'boolean' ? values.showBackButton : false,
      showInMenu: values?.showInMenu || null,
      sitemap: typeof values?.sitemap === 'boolean' ? values.sitemap : true,
      local: await this.local(values, val => this.pageLocal(val)),
      pageModules: (await Promise.all(values?.pageModules?.filter((p: any) => !!p).map((p: any) => new PageModuleFactory(this.options).createPageModule(p)) || [])).filter((p: any) => !!p),
      previewImage: values?.previewImage || null,
      parentPage: values?.parentPage || null,
      onboarding: values?.onboarding || null,
      contentChecklistActive: values?.contentChecklistActive || false,
      showSubmenu: typeof values?.showSubmenu === 'boolean' ? values.showSubmenu : false,
      showInSubmenu: typeof values?.showInSubmenu === 'boolean' ? values.showInSubmenu : false
    } as BlankPage;
  }

  public async explorePage(values: any) {
    return {
      ...values,
      default: typeof values?.default === 'boolean' ? values.default : false,
      languages: values?.languages?.length > 0 ? values.languages : ['en'],
      pageType: PageTypes.ExplorePage,
      readAccess: values?.readAccess || [],
      internalName: values?.internalName || null,
      path: values?.path || null,
      showBackButton: typeof values?.showBackButton === 'boolean' ? values.showBackButton : false,
      showInMenu: values?.showInMenu || null,
      sitemap: typeof values?.sitemap === 'boolean' ? values.sitemap : true,
      local: await this.local(values, val => this.pageLocal(val)),
      pageModules: (await Promise.all(values?.pageModules?.filter((p: any) => !!p).map((p: any) => new PageModuleFactory(this.options).createPageModule(p)) || [])).filter((p: any) => !!p),
      previewImage: values?.previewImage || null,
      parentPage: values?.parentPage || null,
      onboarding: values?.onboarding || null,
      contentChecklistActive: values?.contentChecklistActive || false,
      featuredEvents: values?.featuredEvents || [],
      showSubmenu: typeof values?.showSubmenu === 'boolean' ? values.showSubmenu : false,
      showInSubmenu: typeof values?.showInSubmenu === 'boolean' ? values.showInSubmenu : false
    };
  }

  public async linkedPage(values: any) {
    return {
      ...values,
      pageType: PageTypes.LinkedPage,
      default: typeof values?.default === 'boolean' ? values.default : false,
      path: values?.path || null,
      parentPage: values?.parentPage || null,
      linkedPage: values?.linkedPage || null,
      showSubmenu: typeof values?.showSubmenu === 'boolean' ? values.showSubmenu : false,
      showInSubmenu: typeof values?.showInSubmenu === 'boolean' ? values.showInSubmenu : false
    };
  }

  public async assetPage(values: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      default: typeof values?.default === 'boolean' ? values.default : false,
      languages: values?.languages?.length > 0 ? values.languages : ['en'],
      pageType: PageTypes.AssetPage,
      readAccess: values?.readAccess || [],
      internalName: values?.internalName || null,
      path: values?.path || null,
      showBackButton: typeof values?.showBackButton === 'boolean' ? values.showBackButton : false,
      showInMenu: values?.showInMenu || null,
      sitemap: typeof values?.sitemap === 'boolean' ? values.sitemap : true,
      local: await this.local(values, lang => this.pageLocal(lang)),
      pageModules: (await Promise.all(values?.pageModules?.filter((p: any) => !!p).map((p: any) => new PageModuleFactory(this.options).createPageModule(p)) || [])).filter((p: any) => !!p),
      previewImage: values?.previewImage || null,
      parentPage: values?.parentPage || null,
      assetScope: values?.assetScope || AssetScopeTypes[0],
      backgroundTheme: values?.backgroundTheme || backgroundThemes[0],
      showShareButton: values?.showShareButton || false,
      showLikeButton: values?.showLikeButton || true,
      shareButtonTargets: values?.shareButtonTargets || [],
      accessPolicy: values?.accessPolicy || [],
      paddingTop: typeof values?.paddingTop === 'boolean' ? values.paddingTop : true,
      paddingBottom: typeof values?.paddingBottom === 'boolean' ? values.paddingBottom : true,
      paddingLeft: typeof values?.paddingLeft === 'boolean' ? values.paddingLeft : true,
      paddingRight: typeof values?.paddingRight === 'boolean' ? values.paddingRight : true,
      videoGridSettings: await this.videoGridSettings(values?.videoGridSettings),
      recommendationsSettings: await this.assetPageRecommendationsSettings(values?.recommendationsSettings),
      playerOverlayPicture: values?.playerOverlayPicture || null,
      onboarding: values?.onboarding || null,
      showSubmenu: typeof values?.showSubmenu === 'boolean' ? values.showSubmenu : false,
      showInSubmenu: typeof values?.showInSubmenu === 'boolean' ? values.showInSubmenu : false
    } as AssetPage;
  }

  public async standardPage(values: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      default: typeof values?.default === 'boolean' ? values.default : false,
      languages: values?.languages?.length > 0 ? values.languages : ['en'],
      pageType: PageTypes.StandardPage,
      readAccess: values?.readAccess || [],
      internalName: values?.internalName || null,
      path: values?.path || null,
      showInMenu: values?.showInMenu || null,
      sitemap: typeof values?.sitemap === 'boolean' ? values.sitemap : true,
      local: await this.local(values, val => this.pageLocal(val)),
      eventStage: values?.eventStage ? await new EventStageFactory(this.options).eventStage(values?.eventStage) : null,
      pageModules: (await Promise.all(values?.pageModules?.filter((p: any) => !!p).map((p: any) => new PageModuleFactory(this.options).createPageModule(p)) || [])).filter((p: any) => !!p),
      previewImage: values?.previewImage || null,
      onboarding: values?.onboarding || null,
      showSubmenu: typeof values?.showSubmenu === 'boolean' ? values.showSubmenu : false,
      showInSubmenu: typeof values?.showInSubmenu === 'boolean' ? values.showInSubmenu : false
    } as StandardPage;
  }

  public async keytopicsPage(values: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      default: typeof values?.default === 'boolean' ? values.default : false,
      languages: values?.languages?.length > 0 ? values.languages : ['en'],
      pageType: PageTypes.KeytopicsPage,
      internalName: values?.internalName || null,
      path: 'keytopics',
      showInMenu: values?.showInMenu || null,
      sitemap: typeof values?.sitemap === 'boolean' ? values.sitemap : true,
      stageBackgroundTheme: values?.stageBackgroundTheme || 'Light',
      local: await this.local(values, val => this.pageLocal(val)),
      previewImage: values?.previewImage || null,
      onboarding: values?.onboarding || null,
      showSubmenu: typeof values?.showSubmenu === 'boolean' ? values.showSubmenu : false,
      showInSubmenu: typeof values?.showInSubmenu === 'boolean' ? values.showInSubmenu : false
    } as KeytopicsPage;
  }

  public async profilePage(values: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      default: typeof values?.default === 'boolean' ? values.default : false,
      languages: values?.languages?.length > 0 ? values.languages : ['en'],
      pageType: PageTypes.ProfilePage,
      internalName: values?.internalName || null,
      path: values?.path || null,
      profileType: values?.profileType || 'speaker',
      showInMenu: values?.showInMenu || null,
      sitemap: typeof values?.sitemap === 'boolean' ? values.sitemap : true,
      showSummary: values?.showSummary || false,
      stageImage: values?.stageImage || null,
      stageBackgroundTheme: values?.stageBackgroundTheme || 'Light',
      local: await this.local(values, val => this.pageLocal(val)),
      previewImage: values?.previewImage || null,
      onboarding: values?.onboarding || null,
      showSubmenu: typeof values?.showSubmenu === 'boolean' ? values.showSubmenu : false,
      showInSubmenu: typeof values?.showInSubmenu === 'boolean' ? values.showInSubmenu : false
    } as ProfilePage;
  }

  public async page(values: any) {
    switch (<PageTypes>values?.pageType) {
      case PageTypes.BlankPage:
        return { ...values, ...(await this.blankPage(values)) };
      case PageTypes.AssetPage:
        return { ...values, ...(await this.assetPage(values)) };
      case PageTypes.StandardPage:
        return { ...values, ...(await this.standardPage(values)) };
      /*case 'StreamPage':
        return { ...values, ...(await this.streamPage(values)) };*/
      case PageTypes.KeytopicsPage:
        return { ...values, ...(await this.keytopicsPage(values)) };
      case PageTypes.ProfilePage:
        return { ...values, ...(await this.profilePage(values)) };
      case PageTypes.EmbeddedPage:
        return { ...values, ...(await this.embeddedPage(values)) };
      case PageTypes.ExplorePage:
        return { ...values, ...(await this.explorePage(values)) };
      case PageTypes.LinkedPage:
        return { ...values, ...(await this.linkedPage(values)) };
    }
  }

  public async pageVersion(values: any) {
    return {
      ...values,
      ...(await this.page(values)),
      finalizedAt: values?.finalizedAt || null,
      finalizedBy: values?.finalizedBy || null,
      publishedAt: values?.publishedAt || null,
      publishedBy: values?.publishedBy || null,
      dirty: typeof values?.dirty === 'boolean' ? values.dirty : true,
      change: values?.change || 0
    };
  }

  public videoGridSettings(values: any) {
    return {
      showAssetTags: typeof values?.showAssetTags === 'boolean' ? values?.showAssetTags : true,
      showLanguageTags: typeof values?.showLanguageTags === 'boolean' ? values?.showLanguageTags : true,
      showEventtypeTags: typeof values?.showEventtypeTags === 'boolean' ? values?.showEventtypeTags : true,
      showDate: typeof values?.showDate === 'boolean' ? values?.showDate : true
    };
  }

  public async assetPageRecommendationsSettings(values: any) {
    return {
      teaserLayoutVariation: values?.teaserLayoutVariation || 1,
      showVideoTitle: typeof values?.showVideoTitle === 'boolean' ? values?.showVideoTitle : true,
      showAssetTags: typeof values?.showAssetTags === 'boolean' ? values?.showAssetTags : true,
      showLanguageTags: typeof values?.showLanguageTags === 'boolean' ? values?.showLanguageTags : true,
      showEventtypeTags: typeof values?.showEventtypeTags === 'boolean' ? values?.showEventtypeTags : true,
      showDate: typeof values?.showDate === 'boolean' ? values?.showDate : true,
      showVideoStatusFlag: typeof values?.showVideoStatusFlag === 'boolean' ? values?.showVideoStatusFlag : false,
      showProgressBar: typeof values?.showProgressBar === 'boolean' ? values?.showProgressBar : false,
      showPlayButton: typeof values?.showPlayButton === 'boolean' ? values?.showPlayButton : true,
      navigationType: values?.navigationType || 'pagination',
      pagination: values?.pagination || 10,
      showSliderPagination: typeof values?.showSliderPagination === 'boolean' ? values?.showSliderPagination : true,
      loadMoreInitialAmount: values?.loadMoreInitialAmount || 9,
      loadMoreAmount: values?.loadMoreAmount || 6,
      backgroundTheme: values?.backgroundTheme || backgroundThemes[0],
      accessPolicy: values?.accessPolicy || [],
      paddingTop: typeof values?.paddingTop === 'boolean' ? values.paddingTop : true,
      paddingBottom: typeof values?.paddingBottom === 'boolean' ? values.paddingBottom : true,
      paddingLeft: typeof values?.paddingLeft === 'boolean' ? values.paddingLeft : true,
      paddingRight: typeof values?.paddingRight === 'boolean' ? values.paddingRight : true,
      local: await this.local(values, val => this.assetPageRecommendationsSettingsLocal(val))
    };
  }

  public async embeddedPage(values: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      languages: values?.languages?.length > 0 ? values.languages : ['en'],
      pageType: PageTypes.EmbeddedPage,
      internalName: values?.internalName || null,
      pageModules: (await Promise.all(values?.pageModules?.filter((p: any) => !!p).map((p: any) => new PageModuleFactory(this.options).createPageModule(p)) || [])).filter((p: any) => !!p),
      local: await this.local(values, val => this.embeddedPageLocal(val))
    } as EmbeddedPage;
  }

  public async embeddedPageLocal(values: any): Promise<EmbeddedPageLocal> {
    return {} as EmbeddedPageLocal;
  }
}
