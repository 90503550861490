import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { EventVersion } from 'src/common/entities/EventVersion';
import { isPage, Page } from 'src/common/entities/Page';

import { InputConfiguration, Inputs } from 'src/common/inputs/Inputs';
import { pageInputs } from 'src/common/inputs/page/PageInputs';
import { eventVersionInputs } from 'src/common/inputs/event/EventVersionInputs';

@Component({
  selector: 'c-page-patch-input',
  templateUrl: './page-patch-input.component.html',
  styleUrls: ['./page-patch-input.component.scss'],
})
export class PagePatchInputComponent implements OnChanges {
  @Input()
  jsonpath: string | string[];

  @Input()
  jsonpathParams: { [key: string]: any } = {};

  @Input()
  object: Page | EventVersion;

  @Input()
  inputConfiguration: InputConfiguration;

  @Input()
  showHeader: boolean = true;

  @Input()
  showDescription: boolean = true;

  @Input()
  showDeleteButton?: boolean = true;

  @Input()
  header: string;

  @Input()
  description: string;

  @Output()
  onValueChanged = new EventEmitter<boolean>();

  @Input()
  inputs: Inputs | null = null;

  collaborationKey: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.object?.currentValue !== changes.object?.previousValue) {
      if (this.object) {
        if (isPage(this.object)) {
          this.collaborationKey = `pageversion:${this.object._id}`;
        } else {
          // is event version
          this.collaborationKey = `eventversion:${this.object._id}`;
        }
      }
      if (this.object && !this.inputs) {
        if (isPage(this.object)) {
          this.inputs = pageInputs;
        } else {
          // is event version
          this.inputs = eventVersionInputs;
        }
      }
    }
  }
}
