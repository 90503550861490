import { TeaserEventStagePageModuleFactory } from '../../factories/pagemodules/TeaserEventStagePageModuleFactory';
import { textAttributesInputs } from '../attributes/TextAttributesInputs';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const teaserEventStagePageModuleInputs: Inputs = {
  ...commonPageModuleInputs('TeaserEventStage'),
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].ctalist": {
    factory: async () => [],
    childFactory: async () => new TeaserEventStagePageModuleFactory().teaserEventStagePageModuleButton({}),
    list: true,
    listKey: '_id',
    header: 'CTA Button',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].ctalist[$index].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].ctalistInteraction": {
    factory: async () => [],
    childFactory: async () => new TeaserEventStagePageModuleFactory().teaserEventStagePageModuleButton({}),
    list: true,
    listKey: '_id',
    header: 'CTA Button',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].ctalistInteraction[$index].ctaButton": {},
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new TeaserEventStagePageModuleFactory().teaserEventStagePageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].local.$language.background": {},
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].local.$language.smallHeadline": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },

  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].local.$language.subtitle": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },

  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].showCountdown": {
    header: 'Show Countdown',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].local.$language.items": {
    factory: async () => [],
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].local.$language.items[?(@.teaserItemType=='Highlight' && ?(@._id=='$itemId')]": {
    factory: async () => new TeaserEventStagePageModuleFactory().highlightTeaserItem({}),
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].local.$language.items[?(@.teaserItemType=='Link' && ?(@._id=='$itemId')]": {
    factory: async () => new TeaserEventStagePageModuleFactory().linkTeaserItem({}),
  },

  // "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].local.$language.items[?(@.teaserItemType=='Link' && ?(@._id=='$itemId')].local.$language": {
  //   factory: async () => new TeaserEventStagePageModuleFactory().linkTeaserItemLocal({})
  // },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].local.$language.items[?(@.teaserItemType=='CTA' && ?(@._id=='$itemId')]": {
    factory: async () => new TeaserEventStagePageModuleFactory().ctaTeaserItem({}),
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].local.$language.subtext": {},
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].discoverMore": {
    header: 'Show Discover More',
    type: 'switch',
  },
  "$.pageModules[?(@.pageModuleType=='MultiEventStage' && @._id=='$pageModuleId')].stageHeight": {
    factory: async () => new TeaserEventStagePageModuleFactory().teaserEventStagePageModuleStageHeight({}),
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].stageHeight.desktop": {
    type: 'number',
    header: 'Stage height desktop',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].stageHeight.desktopLarge": {
    type: 'number',
    header: 'Stage height desktop large',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].stageHeight.desktopWidescreen": {
    type: 'number',
    header: 'Stage height desktop widescreen',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].stageHeight.tablet": {
    type: 'number',
    header: 'Stage height tablet',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].stageHeight.tabletLarge": {
    type: 'number',
    header: 'Stage height tablet large',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].stageHeight.mobile": {
    header: 'Stage height mobile',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].stageHeight.mobileLarge": {
    header: 'Stage height mobile large',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint": {
    factory: async () => new TeaserEventStagePageModuleFactory().teaserEventStagePageModuleButtonSpacingTopBreakpoint({}),
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.desktop": {
    header: 'Button spacing top desktop',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.desktopLarge": {
    header: 'Button spacing top desktop large',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.desktopWidescreen": {
    header: 'Button spacing top desktop widescreen',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.tablet": {
    header: 'Button spacing top tablet',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.tabletLarge": {
    header: 'Button spacing top tablet large',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.mobile": {
    header: 'Button spacing top mobile',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='TeaserEventStage' && @._id=='$pageModuleId')].buttonSpacingTopBreakpoint.mobileLarge": {
    header: 'Button spacing top mobile large',
    type: 'number',
  },
};
