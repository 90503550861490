<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_EVENT_SECTION_SLOTS' | translate }}
  </div>
  <c-table #table [data]="extendedEventSlots" [options]="eventTicketSlotsTableOptions">
    <ng-template let-eventTicketSlot>
      <button
        type="button"
        pButton
        pRipple
        class="p-button-text p-button-rounded p-button-plain"
        [icon]="expanded[eventTicketSlot._id] ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        (click)="expanded[eventTicketSlot._id] = !expanded[eventTicketSlot._id]"
      ></button>
    </ng-template>

    <ng-template let-eventTicketSlot>
      <div *ngIf="eventTicketSlot.eventTickets.length === 0">
        {{ 'PAGE_LIVE_VISITOR_MANAGEMENT_NO_RELATED_TICKETS' | translate }}
      </div>
      <div *ngIf="eventTicketSlot.eventTickets.length > 0">
        <div *ngFor="let ticket of eventTicketSlot.eventTickets">
          <div *hasNoRight="{ resource: 'events:' + event._id, rights: ['eventregistrations.list'] }">
            {{ getTicketInfo(ticket) || ('GENERAL_UNNAMED' | translate) }}
          </div>

          <a
            *hasRight="{ resource: 'events:' + event._id, rights: ['eventticketslots.read', 'eventticketslots.edit'] }"
            [routerLink]="[]"
            [queryParamsHandling]="'merge'"
            (click)="openEventSlotDialog(eventTicketSlot, null)"
          >
            {{ getTicketInfo(ticket) || ('GENERAL_UNNAMED' | translate) }}
          </a>
        </div>
      </div>

      <div *ngIf="expanded[eventTicketSlot._id]" class="expanded-cell">
        <div *ngFor="let ticketTimeSlot of eventTicketSlot.eventTimeSlots">
          <div *hasNoRight="{ resource: 'events:' + event._id, rights: ['eventregistrations.list', 'eventregistrations.read', 'eventregistrations.edit'] }">
            {{ ticketTimeSlot.local[utilsService.currentLanguage].title || ticketTimeSlot.local['en'].title || ('GENERAL_UNNAMED' | translate) }}
          </div>
          <a
            *hasRight="{ resource: 'events:' + event._id, rights: ['eventticketslots.read', 'eventticketslots.edit'] }"
            [routerLink]="[]"
            [queryParamsHandling]="'merge'"
            (click)="openEventSlotDialog(eventTicketSlot, ticketTimeSlot)"
          >
            {{ ticketTimeSlot.local[utilsService.currentLanguage].title || ticketTimeSlot.local['en'].title || ('GENERAL_UNNAMED' | translate) }}
          </a>
        </div>
      </div>
    </ng-template>

    <ng-template let-eventTicketSlot>
      {{ eventTicketSlot.startAt | date : 'short' }}
      <div *ngFor="let ticket of eventTicketSlot.eventTickets; let first = first">
        <br *ngIf="!first" />
        <!-- Generate empty rows for eventTickets 2 - n -->
      </div>

      <div *ngIf="expanded[eventTicketSlot._id]" class="expanded-cell">
        <div *ngFor="let ticketTimeSlot of eventTicketSlot.eventTimeSlots">
          {{ ticketTimeSlot.startAt | date : 'short' }}
        </div>
      </div>
    </ng-template>

    <ng-template let-eventTicketSlot>
      {{ eventTicketSlot.endAt | date : 'short' }}
      <div *ngFor="let ticket of eventTicketSlot.eventTickets; let first = first">
        <br *ngIf="!first" />
        <!-- Generate empty rows for eventTickets 2 - n -->
      </div>

      <div *ngIf="expanded[eventTicketSlot._id]" class="expanded-cell">
        <div *ngFor="let ticketTimeSlot of eventTicketSlot.eventTimeSlots">
          {{ ticketTimeSlot.endAt | date : 'short' }}
        </div>
      </div>
    </ng-template>

    <ng-template let-eventTicketSlot>
      {{ eventTicketSlot.capacity }}
      <div *ngFor="let ticket of eventTicketSlot.eventTickets; let first = first">
        <br *ngIf="!first" />
        <!-- Generate empty rows for eventTickets 2 - n -->
      </div>

      <div *ngIf="expanded[eventTicketSlot._id]" class="expanded-cell">
        <div *ngFor="let ticketTimeSlot of eventTicketSlot.eventTimeSlots">
          {{ ticketTimeSlot.capacity }}
        </div>
      </div>
    </ng-template>

    <ng-template let-eventTicketSlot>
      {{ eventTicketSlot.warningCapacity }}
      <div *ngFor="let ticket of eventTicketSlot.eventTickets; let first = first">
        <br *ngIf="!first" />
        <!-- Generate empty rows for eventTickets 2 - n -->
      </div>

      <div *ngIf="expanded[eventTicketSlot._id]" class="expanded-cell">
        <div *ngFor="let ticketTimeSlot of eventTicketSlot.eventTimeSlots">
          {{ ticketTimeSlot.warningCapacity }}
        </div>
      </div>
    </ng-template>

    <ng-template let-eventTicketSlot>
      <div class="capacity-bar">
        <div class="value" *ngIf="eventTicketSlot.capacity" [ngStyle]="{ width: (eventTicketSlot.usedCapacity / eventTicketSlot.capacity) * 100 + '%' }"></div>
      </div>
      {{ eventTicketSlot.usedCapacity }}
      <div *ngFor="let ticket of eventTicketSlot.eventTickets; let first = first">
        <br *ngIf="!first" />
        <!-- Generate empty rows for eventTickets 2 - n -->
      </div>

      <div *ngIf="expanded[eventTicketSlot._id]" class="expanded-cell">
        <div *ngFor="let ticketTimeSlot of eventTicketSlot.eventTimeSlots">
          <div class="capacity-bar">
            <div class="value" *ngIf="ticketTimeSlot.capacity" [ngStyle]="{ width: (ticketTimeSlot.usedCapacity / ticketTimeSlot.capacity) * 100 + '%' }"></div>
          </div>
          {{ ticketTimeSlot.usedCapacity }}
        </div>
      </div>
    </ng-template>

    <ng-template let-eventTicketSlot>
      <div class="text-right" *ngFor="let ticket of eventTicketSlot.eventTickets">
        <a
          *hasRight="{ resource: 'events:' + event._id, rights: ['eventregistrations.list'] }"
          [routerLink]="[]"
          [queryParams]="{ tab: 'eventregistrations', ticket: ticket, eventSlot: eventTicketSlot._id }"
        >
          {{ 'PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATIONS_OPEN' | translate }}
        </a>
      </div>

      <div *ngIf="expanded[eventTicketSlot._id]" class="expanded-cell">
        <div class="text-right" *ngFor="let ticketTimeSlot of eventTicketSlot.eventTimeSlots">
          <a
            *hasRight="{ resource: 'events:' + event._id, rights: ['eventregistrations.list', 'eventregistrations.read', 'eventregistrations.edit'] }"
            [routerLink]="[]"
            [queryParams]="{ tab: 'eventregistrations', ticket: eventTicketSlot.eventTickets[0], eventTimeSlot: ticketTimeSlot._id }"
          >
            {{ 'PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATIONS_OPEN' | translate }}
          </a>
        </div>
      </div>
    </ng-template>
  </c-table>
</div>

<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_LIVE_TICKET_SLOT_MANAGEMENT_VIPS' | translate }}
    <div class="actions">
      <c-table-options #toc></c-table-options>
      <c-vip-ticket-add-dialog
        *hasRight="'eventregistrations.edit'"
        styleClass="p-button-sm"
        (click)="showNewEventRegistrationDialog = true"
        icon="pi icon-ic-add-item"
        [disabled]="loading || saving"
        [event]="event"
        (onSave)="vipTable.refresh(true)"
      ></c-vip-ticket-add-dialog>
      <p-button
        *hasRight="['eventregistrations.list', 'eventregistrations.read', 'eventregistration.edit']"
        styleClass="p-button-sm"
        (click)="download()"
        icon="pi icon-ic-download"
        [disabled]="loading || saving"
        [label]="'Download'"
      ></p-button>
    </div>
  </div>

  <c-table #vipTable mode="query" [options]="eventTicketSlotsVipTableOptions" [singleTemplate]="true" (query)="retrieveVips($event)">
    <ng-template let-vipTicket>
      <tr [class.unregistered]="!vipTicket?.user">
        <td>
          <i
            *ngIf="getCountOfRegularBookedWithTicketId(vipTicket, true) > 0"
            [class]="expandedVipTicket[vipTicket._id] ? 'pi pi-chevron-down small-chevron' : 'pi pi-chevron-right small-chevron'"
            (click)="expandedVipTicket[vipTicket._id] = !expandedVipTicket[vipTicket._id]"
          ></i>
        </td>
        <td>{{ vipTicket?.emailLower ? vipTicket?.emailLower : '' }}<i [pTooltip]="'The user is not registered at the platform!'" class="pi pi-exclamation-triangle" *ngIf="!vipTicket?.user"></i></td>
        <td>{{ vipTicket?.user?.lastName ? vipTicket?.user?.lastName : '' }}</td>
        <td>{{ vipTicket?.user?.firstName ? vipTicket?.user?.firstName : '' }}</td>
        <td>
          {{ getTicketInfo(vipTicket.eventTicket) }}
          <span *ngIf="!expandedVipTicket[vipTicket._id] && getCountOfRegularBookedWithTicketId(vipTicket) > 1" class="registration-data-plus"
            >+&nbsp;{{ getCountOfRegularBookedWithTicketId(vipTicket) - 1 }}</span
          >
        </td>
        <td>
          {{ getRegularBookedTicketInfo(null, vipTicket, !expandedVipTicket[vipTicket._id]) }}
          <span
            *ngIf="!expandedVipTicket[vipTicket._id] && getCountOfRegularBookedWithTicketId(vipTicket, true) > 0"
            (click)="expandedVipTicket[vipTicket._id] = !expandedVipTicket[vipTicket._id]"
            class="registration-data-plus"
            >+&nbsp;{{ getCountOfRegularBookedWithTicketId(vipTicket, !expandedVipTicket[vipTicket._id]) - (!expandedVipTicket[vipTicket._id] ? 0 : 1) }}</span
          >
        </td>
        <td>
          <div class="text-right" *ngIf="vipTicket">
            <a
              *hasRight="{ resource: 'events:' + event._id, rights: ['eventregistrations.edit'] }"
              class="delete-link"
              (click)="deleteVipTicket(vipTicket)"
              [routerLink]="[]"
              [queryParamsHandling]="'merge'"
              >{{ 'GENERAL_DELETE' | translate }}</a
            >
          </div>
        </td>
      </tr>
      <ng-container *ngIf="expandedVipTicket[vipTicket._id]">
        <tr *ngFor="let eventTicket of filterBookingDistinctTicketId(vipTicket)">
          <td colspan="5"></td>
          <td>
            <div>
              {{ getRegularBookedTicketInfo(eventTicket, vipTicket) }}
            </div>
          </td>
          <td></td>
        </tr>
      </ng-container>
    </ng-template>
  </c-table>
</div>

<app-live-auto-assignments [event]="event"></app-live-auto-assignments>
<!-- Ticket (Time) Slot Event Registration Dialog -->
<p-sidebar *ngIf="showSlotRegistrationDialog" [visible]="showSlotRegistrationDialog" styleClass="p-sidebar-st" position="right" [modal]="true" [appendTo]="'body'" (onHide)="closeEventSlotDialog()">
  <c-sidebar-header
    [headerTitle]="editableEventTimeSlot ? ('PAGE_LIVE_TICKET_SLOT_MANAGEMENT_DIALOG_HEADER_TICKET_TIME_SLOT' | translate) : ('PAGE_LIVE_TICKET_SLOT_MANAGEMENT_DIALOG_HEADER_TICKET_SLOT' | translate)"
    [headerSubTitle]="
      editableEventTimeSlot ? editableEventTimeSlot.local[utilsService.currentLanguage].title : (editableEventSlot.startAt | date : 'short') + ' - ' + (editableEventSlot.endAt | date : 'shortTime')
    "
    [showCloseButton]="false"
  ></c-sidebar-header>

  <div class="sidebar-body">
    <div *ngIf="editableEventTimeSlot; else noEditableEventTimeSlot">
      <c-collaboration-patch-input
        [object]="editableEventTimeSlot"
        jsonpath="$.startAt"
        [inputConfiguration]="{
          type: 'datetime',
          header: 'PAGE_EVENT_SECTION_SLOTS_STARTAT'
        }"
        [disabled]="true"
      ></c-collaboration-patch-input>
      <c-collaboration-patch-input
        [object]="editableEventTimeSlot"
        jsonpath="$.endAt"
        [inputConfiguration]="{
          type: 'datetime',
          header: 'PAGE_EVENT_SECTION_SLOTS_ENDAT'
        }"
        [disabled]="true"
      ></c-collaboration-patch-input>
      <c-collaboration-patch-input
        [object]="extendedEventTimeSlot"
        jsonpath="$.usedCapacity"
        [inputConfiguration]="{
          type: 'number',
          header: 'PAGE_LIVE_TICKET_SLOT_MANAGEMENT_USEDCAPACITY'
        }"
        [disabled]="true"
      ></c-collaboration-patch-input>
      <div *hasRight="{ resource: 'events:' + event._id, rights: ['eventticketslots.edit'] }">
        <c-collaboration-patch-input
          [object]="editableEventTimeSlot"
          jsonpath="$.capacity"
          [inputConfiguration]="{
            type: 'number',
            header: 'PAGE_EVENT_SECTION_SLOTS_CAPACITY'
          }"
        ></c-collaboration-patch-input>
        <c-collaboration-patch-input
          [object]="editableEventTimeSlot"
          jsonpath="$.warningCapacity"
          [inputConfiguration]="{
            type: 'number',
            header: 'PAGE_EVENT_SECTION_SLOTS_WARNINGCAPACITY'
          }"
        ></c-collaboration-patch-input>
      </div>
      <div *hasNoRight="{ resource: 'events:' + event._id, rights: ['eventticketslots.edit'] }">
        <c-collaboration-patch-input
          [object]="editableEventTimeSlot"
          jsonpath="$.capacity"
          [inputConfiguration]="{
            type: 'number',
            header: 'PAGE_EVENT_SECTION_SLOTS_CAPACITY'
          }"
          [disabled]="true"
        ></c-collaboration-patch-input>
        <c-collaboration-patch-input
          [object]="editableEventTimeSlot"
          jsonpath="$.warningCapacity"
          [inputConfiguration]="{
            type: 'number',
            header: 'PAGE_EVENT_SECTION_SLOTS_WARNINGCAPACITY'
          }"
          [disabled]="true"
        ></c-collaboration-patch-input>
      </div>
    </div>

    <ng-template #noEditableEventTimeSlot>
      <c-collaboration-patch-input
        [object]="editableEventSlot"
        jsonpath="$.startAt"
        [inputConfiguration]="{
          type: 'datetime',
          header: 'PAGE_EVENT_SECTION_SLOTS_STARTAT'
        }"
        [disabled]="true"
      ></c-collaboration-patch-input>
      <c-collaboration-patch-input
        [object]="editableEventSlot"
        jsonpath="$.endAt"
        [inputConfiguration]="{
          type: 'datetime',
          header: 'PAGE_EVENT_SECTION_SLOTS_ENDAT'
        }"
        [disabled]="true"
      ></c-collaboration-patch-input>
      <c-collaboration-patch-input
        [object]="extendedEventSlot"
        jsonpath="$.usedCapacity"
        [inputConfiguration]="{
          type: 'number',
          header: 'PAGE_LIVE_TICKET_SLOT_MANAGEMENT_USEDCAPACITY'
        }"
        [disabled]="true"
      ></c-collaboration-patch-input>
      <div *hasRight="{ resource: 'events:' + event._id, rights: ['eventticketslots.edit'] }">
        <c-collaboration-patch-input
          [object]="editableEventSlot"
          jsonpath="$.capacity"
          [inputConfiguration]="{
            type: 'number',
            header: 'PAGE_EVENT_SECTION_SLOTS_CAPACITY'
          }"
        ></c-collaboration-patch-input>
        <c-collaboration-patch-input
          [object]="editableEventSlot"
          jsonpath="$.warningCapacity"
          [inputConfiguration]="{
            type: 'number',
            header: 'PAGE_EVENT_SECTION_SLOTS_WARNINGCAPACITY'
          }"
        ></c-collaboration-patch-input>
      </div>
      <div *hasNoRight="{ resource: 'events:' + event._id, rights: ['eventticketslots.edit'] }">
        <c-collaboration-patch-input
          [object]="editableEventSlot"
          jsonpath="$.capacity"
          [inputConfiguration]="{
            type: 'number',
            header: 'PAGE_EVENT_SECTION_SLOTS_CAPACITY'
          }"
        ></c-collaboration-patch-input>
        <c-collaboration-patch-input
          [object]="editableEventSlot"
          jsonpath="$.warningCapacity"
          [inputConfiguration]="{
            type: 'number',
            header: 'PAGE_EVENT_SECTION_SLOTS_WARNINGCAPACITY'
          }"
          error="error"
        ></c-collaboration-patch-input>
      </div>
    </ng-template>
  </div>

  <c-sidebar-footer
    [showCancelButton]="true"
    [showSaveButton]="true"
    [disableSaveButton]="!isValidEventSlot() || !isEditableChanged()"
    (onSave)="saveEventSlot()"
    (onCancel)="closeEventSlotDialog()"
  ></c-sidebar-footer>
</p-sidebar>
