import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { CustomExternalAccount, DefaultExternalAccount, ExternalAccountType, Speaker } from '../entities/Speaker';

export class SpeakerFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createSpeaker(values?: any) {
    return {
      _id: await this.id(values?._id),
      firstName: values?.firstName || '',
      lastName: values?.lastName || '',
      company: values?.company || '',
      picture: values?.picture || null,
      email: values?.email || values?.mail || '',
      externalProfileLink: values?.externalProfileLink || '',
      skills: values?.skills || null,
      externalAccounts: values?.externalAccounts || [],
      local: await this.local(values, async (v) => this.createSpeakerLocal(v)),
      displayName: values.displayName,
      type: values.type || 'Speaker',
    } as Speaker;
  }

  async createSpeakerLocal(values?: any) {
    return {
      title: values?.title || '',
      shortDescription: values?.shortDescription || '',
      longDescription: values?.longDescription || '',
    };
  }

  async createExternalAccount(values: { externalAccountType: ExternalAccountType }) {
    switch (values.externalAccountType) {
      case 'Custom':
        return this.createCustomExternalAccount(values);
    }
    return this.createDefaultExternalAccount(values);
  }

  async createCustomExternalAccount(values?: any) {
    return {
      externalAccountType: 'Custom',
      link: values?.link || '',
      icon: values?.icon || null,
      local: await this.local(values, async () => ({
        title: '',
      })),
    } as CustomExternalAccount;
  }

  async createDefaultExternalAccount(values: any & { externalAccountType: 'Twitter' | 'Xing' | 'LinkedIn' }) {
    return {
      externalAccountType: values.externalAccountType,
      link: values?.link || '',
    } as DefaultExternalAccount;
  }
}
