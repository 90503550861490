<div *skeleton="{ show: loading > 0 }">
  <div class="tab-header">
    <h1>
      <span class="pre-title">{{ 'GENERAL_LOOKUP' | translate }}</span>
      <span>{{ lookup?.internalName }} {{ lookup.description ? '(' + lookup.description + ')' : '' }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <p-button *hasRight="'configuration.edit'" icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" [disabled]="saving" (onClick)="save()"></p-button>
    <div *hasRight="'configuration.edit'">
      <c-language-button
        *ngIf="lookup && lookup.local"
        [(currentLanguage)]="languageEdit"
        [languages]="selectedLanguages"
        (languagesChange)="onLanguagesChange($event)"
        (currentLanguageChange)="onCurrentLanguageChange($event)"
        [disabled]="loading !== 0"
      ></c-language-button>
    </div>
    <div *hasNoRight="'configuration.edit'">
      <c-language-button
        *ngIf="lookup && lookup.local"
        [(currentLanguage)]="languageEdit"
        [languages]="selectedLanguages"
        (languagesChange)="onLanguagesChange($event)"
        (currentLanguageChange)="onCurrentLanguageChange($event)"
        [disabled]="loading !== 0"
        [manageLanguages]="false"
      ></c-language-button>
    </div>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_PROPERTIES' | translate }}
    </div>
    <div *ngIf="lookup" class="panel-body">
      <div class="input-group">
        <div class="input">
          <input type="text" class="p-inputtext-sm p-inputtext p-component" [(ngModel)]="lookup.shortId" [disabled]="loading != 0 || lookup.systemLookup" />
        </div>
        <div class="title">{{ 'PAGE_LOOKUP_SHORTID' | translate }}</div>
        <div class="description">{{ 'PAGE_LOOKUP_SHORTID_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <input type="text" class="p-inputtext-sm p-inputtext p-component" [(ngModel)]="lookup.internalName" [disabled]="loading != 0 || lookup.systemLookup" />
        </div>
        <div class="title">{{ 'PAGE_LOOKUP_INTERNAL' | translate }}</div>
        <div class="description">{{ 'PAGE_LOOKUP_INTERNAL_DESCRIPTION' | translate }}</div>
      </div>
      <div class="input-group">
        <div class="input">
          <input type="text" class="p-inputtext-sm p-inputtext p-component" [(ngModel)]="lookup.description" [disabled]="loading != 0" />
        </div>
        <div class="title">{{ 'PAGE_LOOKUP_DESCRIPTION' | translate }}</div>
        <div class="description">{{ 'PAGE_LOOKUP_DESCRIPTION_DESCRIPTION' | translate }}</div>
      </div>
      <div *ngIf="lookup.systemLookup" class="input-group">
        <div class="input no-border">
          <p-message severity="info" text="This lookup is used internally by the system (System Lookup). Changing the Short ID or Internal Name is not possible with this editor."></p-message>
        </div>
        <div class="title">{{ 'PAGE_LOOKUP_SYSTEMLOOKUP' | translate }}</div>
        <div class="description">{{ 'PAGE_LOOKUP_SYSTEMLOOKUP_DESCRIPTION' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ (lookup && lookup.local ? 'PAGE_LOOKUP_LOCAL_PROPERTIES' : 'PAGE_LOOKUP_DATA_PROPERTIES') | translate }}
    </div>
    <div *ngIf="lookup" class="panel-body">
      <div *ngIf="lookup && loading == 0" class="tiered-menu-content">
        <div class="p-grid gridinput">
          <div class="p-col">
            {{ 'PAGE_LOOKUP_KEY' | translate }}
          </div>
          <div class="p-col">
            {{ 'PAGE_LOOKUP_VALUE' | translate }}
          </div>
        </div>

        <div *ngIf="lookup.local">
          <div class="p-grid gridinput" *ngFor="let item of keysAndValuesByLanguage.language[languageEdit].concat(newKeysAndValues)">
            <div class="p-col">
              <div class="input-group">
                <div class="input">
                  <input type="text" pInputText [(ngModel)]="item.key" [class]="!item.key ? 'ng-invalid ng-dirty' : ''" (ngModelChange)="onKeyEdit($event)" />
                </div>
              </div>
            </div>
            <div class="p-col">
              <div class="input-group">
                <div class="input">
                  <input type="text" pInputText [(ngModel)]="item.value" [disabled]="!item.key" [class]="!item.value ? 'ng-invalid ng-dirty' : ''" />
                </div>
              </div>
            </div>
          </div>
          <button
            pButton
            type="button"
            [label]="' ' + ('GENERAL_NEW' | translate)"
            icon="pi icon-ic-add-item"
            class="p-button"
            [disabled]="loading > 0 || saving"
            (click)="addEntry(languageEdit)"
          ></button>
        </div>

        <div *ngIf="lookup.data">
          <div class="p-grid gridinput" *ngFor="let item of keysAndValues.data">
            <div class="p-col">
              <div class="input-group">
                <div class="input">
                  <input type="text" pInputText [(ngModel)]="item.key" [class]="!item.key ? 'ng-invalid ng-dirty' : ''" />
                </div>
              </div>
            </div>
            <div class="p-col">
              <div class="input-group">
                <div class="input">
                  <input type="text" pInputText [(ngModel)]="item.value" [disabled]="!item.key || item.key == ''" [class]="!item.value ? 'ng-invalid ng-dirty' : ''" />
                </div>
              </div>
            </div>
          </div>
          <button pButton type="button" [label]="' ' + ('GENERAL_NEW' | translate)" icon="pi icon-ic-add-item" class="p-button" [disabled]="loading > 0 || saving" (click)="addEntry()"></button>
        </div>
      </div>
    </div>
  </div>
</div>
