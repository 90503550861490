import { TextAttributes } from './../Attributes';
import { CTAButton } from './../CTAButton';
import { PageModule, TitlePositions } from './PageModule';

export type SortingTypeElement = {
  id: string;
  label: string;
  orderBy: string;
  orderDirection: number;
};
export const sortingTypes = [
  {
    id: 'abc-asc',
    label: 'abc-asc',
    orderBy: 'sort.{language}.title',
    orderDirection: 1
  },
  {
    id: 'abc-desc',
    label: 'abc-desc',
    orderBy: 'sort.{language}.title',
    orderDirection: -1
  },
  {
    id: 'date-asc',
    label: 'date-asc',
    orderBy: 'sort.{language}.startAt',
    orderDirection: 1
  },
  {
    id: 'date-desc',
    label: 'date-desc',
    orderBy: 'sort.{language}.startAt',
    orderDirection: -1
  }
] as SortingTypeElement[];
export type SortingType = (typeof sortingTypes)[number];

export const CURRENT_LANGUAGE_FILTER = 'CURRENT_LANGUAGE';

export const dateTypes = ['past', 'future', 'live'] as const;
export type DateType = (typeof dateTypes)[number];
export const displayTypes = ['list', 'card'];
export type DisplayType = (typeof displayTypes)[number];
export const paginationTypes = ['pagination', 'infinite'] as const;
export type PaginationType = (typeof paginationTypes)[number];
export interface EventsListPageModule extends PageModule {
  pageModuleType: 'EventsList';
  titlePosition?: TitlePositions;
  local: {
    [language: string]: {
      title: string;
      title_attr?: EventsListPageModuleAttributes;
    };
  };
  displayAs: DisplayType;
  pagination: number;
  paginationType: PaginationType;

  eventTypes: string[];
  keytopics: string[];
  eventSeries: string[];
  dates: DateType[];
  languages: string[];
  countries: string[];
  sorting: SortingType[];
  defaultSortingField: string;
  defaultSortingDirection: string;

  myBookmarkedEvents: boolean;
  myBookedEvents: boolean;
  showWithSessionVideosOnly: boolean;

  showTicketInformation: boolean;
  showTicketCancellationButton: boolean;
  showEventShareButton: boolean;
  showEventCalendarButton: boolean;
  showEventBookmarkButton: boolean;
  showEventRecommendationButton: boolean;
  showSortButton?: boolean;
  ctaButton?: CTAButton;
}

export type EventsListPageModuleAttributes = TextAttributes & {};

export function isEventsListPageModule(object: any): object is EventsListPageModule {
  return object?.pageModuleType === 'EventsList';
}
