import { Component, Input } from '@angular/core';
import { CTAButton } from 'src/common/entities/CTAButton';

@Component({
  selector: 'c-cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.scss'],
})
export class CtaButtonComponent {
  @Input()
  ctaButton: CTAButton;

  constructor() {}
}
