import { ITabConfiguration } from './classes/tab';

export const stickyTabs: ITabConfiguration[] = [
  {
    category: 'events',
    title: 'Events',
    url: '/events',
  },

  { category: 'events', title: 'Templates', url: '/templates' },

  { category: 'events', title: 'Event Series', url: '/event-series' },

  {
    category: 'pages',
    title: 'Pages',
    url: '/pages',
  },
  { category: 'pages', title: 'Embedded Pages', url: '/embedded-pages' },

  { category: 'users', title: 'Users', url: '/users' },
  { category: 'data', title: 'Appointments', url: '/appointments' },
  { category: 'data', title: 'Keytopics', url: '/keytopics' },
  { category: 'data', title: 'Taxonomy', url: '/taxonomy' },
  { category: 'data', title: 'People', url: '/speakers' },
  { category: 'data', title: 'Products', url: '/products' },
  { category: 'data', title: 'Global Tickets', url: '/global-tickets' },
  { category: 'data', title: 'Assets', url: '/assets' },
  { category: 'data', title: 'Asset Series', url: '/asset-series' },
  { category: 'data', title: 'Streams', url: '/streams' },
  { category: 'data', title: 'Forms', url: '/forms' },
  { category: 'data', title: 'Maps', url: '/maps' },
  { category: 'data', title: 'Onboardings', url: '/onboardings' },
  { category: 'data', title: 'QR Codes', url: '/qr-codes' },
  { category: 'data', title: 'CRM Status', url: '/crm-status' },
  { category: 'data', title: 'Exports', url: '/exports' },
  { category: 'data', title: 'Logs', url: '/logs' },
  { category: 'data', title: 'CSP-Reports', url: '/csp-reports' },

  {
    category: 'configuration',
    title: 'Basic Configuration',
    collapsible: true,
    hasRight: ['*$configuration.list', '*$configuration.read', '*$pages.list'],
    children: [
      { category: 'configuration', title: 'Domains', url: '/configuration/domains', hasRight: ['*$configuration.list'] },
      { category: 'configuration', title: 'Platform', url: '/configuration/platform', hasRight: ['*$configuration.read'] },
      { category: 'configuration', title: 'Login', url: '/configuration/login', hasRight: ['*$configuration.read'] },
      { category: 'configuration', title: 'Contact', url: '/configuration/contact-configuration', hasRight: ['*$configuration.read'] },
      { category: 'configuration', title: 'Global Pages', url: '/configuration/pages', hasRight: ['*$pages.list'], hasMoved: true },
      { category: 'configuration', title: 'Embedded Pages', url: '/configuration/embedded-pages', hasRight: ['*$pages.list'], hasMoved: true },
      { category: 'configuration', title: 'Themes', url: '/configuration/themes', hasRight: ['*$configuration.read'] },
      { category: 'configuration', title: 'Frontend Plugins', url: '/configuration/plugins', hasRight: ['*$configuration.read'] },
      { category: 'configuration', title: 'System Plugins', url: '/configuration/system-plugins', hasRight: ['*$configuration.read'] },
      { category: 'configuration', title: 'Global Variables', url: '/configuration/variables', hasRight: ['*$configuration.list'] },
      { category: 'configuration', title: 'Service Hooks', url: '/configuration/service-hooks', hasRight: ['*$configuration.list'] },
      { category: 'configuration', title: 'Asset', url: '/configuration/asset', hasRight: ['*$configuration.read'] },
      { category: 'configuration', title: 'Interaction', url: '/configuration/interaction', hasRight: ['*$configuration.read'] },
    ],
  },
  {
    category: 'configuration',
    title: 'Event Configuration',
    collapsible: true,
    hasRight: ['*$configuration.list'],
    children: [{ category: 'configuration', title: 'Types', url: '/configuration/types', hasRight: ['*$configuration.list'] }],
  },
  {
    category: 'configuration',
    title: 'Admin Users Management',
    collapsible: true,
    hasRight: ['*$admin'],
    children: [
      { category: 'configuration', title: 'Adminportal Users', url: '/configuration/admin-users', hasRight: ['*$admin'] },
      { category: 'configuration', title: 'Service Users', url: '/configuration/service-admin-users', hasRight: ['*$admin'] },
      { category: 'configuration', title: 'Groups', url: '/configuration/groups', hasRight: ['*$admin'] },
      { category: 'configuration', title: 'Roles', url: '/configuration/roles', hasRight: ['*$admin'] },
    ],
  },
  {
    category: 'configuration',
    title: 'Internationalization',
    collapsible: true,
    hasRight: ['*$configuration.list'],
    children: [
      { category: 'configuration', title: 'Languages', url: '/configuration/languages', hasRight: ['*$configuration.list'] },
      { category: 'configuration', title: 'Operating Countries', url: '/configuration/operating-countries', hasRight: ['*$configuration.list'] },
      { category: 'configuration', title: 'Operating Regions', url: '/configuration/operating-regions', hasRight: ['*$configuration.list'] },
      { category: 'configuration', title: 'Static Contents', url: '/configuration/static-contents', hasRight: ['*$configuration.list'] },
      { category: 'configuration', title: 'Email Templates', url: '/configuration/email-templates', hasRight: ['*$configuration.list'] },
    ],
  },
  {
    category: 'configuration',
    title: 'Miscellaneous',
    collapsible: true,
    hasRight: ['*$configuration.list'],
    children: [
      { category: 'configuration', title: 'Blacklist Entries', url: '/configuration/blacklistentries', hasRight: ['*$configuration.list'] },
      { category: 'configuration', title: 'Lookups', url: '/configuration/lookups', hasRight: ['*$configuration.list'] },
      { category: 'configuration', title: 'Custom Fields', url: '/configuration/custom-fields', hasRight: ['*$configuration.list'] },
      { category: 'configuration', title: 'Custom Data', url: '/configuration/custom-data', hasRight: ['*$configuration.list'] },
    ],
  },
];
