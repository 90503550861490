import { SpeakersService } from './../../../services/speakers/speakers.service';
import { SpeakerListPageModule } from './../../../../common/entities/pagemodules/SpeakerListPageModule';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { InputConfiguration } from 'src/common/inputs/Inputs';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-speaker-list-page-module-edit',
  templateUrl: './speaker-list-page-module-edit.component.html',
  styleUrls: ['./speaker-list-page-module-edit.component.scss']
})
export class SpeakerListPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: SpeakerListPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputConfigurationSpeaker: InputConfiguration = {};

  filterConfigurationSpeaker: InputConfiguration = {};

  initialized: boolean = false;
  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  constructor(private speakersService: SpeakersService) {}

  async ngOnInit(): Promise<void> {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion
      };
    }
    this.inputConfigurationSpeaker = {
      type: 'speaker',
    };

    this.initialized = true;
  }
}
