import { InputConfiguration } from 'src/common/inputs/Inputs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { UtilsService } from 'src/app/services/utils/utils.service';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { Stage2TeaserEventStagePageModule, Stage2TeaserItem, Stage2TeaserItemType, stage2TeaserItemTypes } from 'src/common/entities/PageModule';

import { TeaserEventStagePageModuleFactory } from 'src/common/factories/pagemodules/TeaserEventStagePageModuleFactory';

@Component({
  selector: 'c-stage2-teasereventstage-page-module-edit',
  templateUrl: './stage2-teasereventstage-page-module-edit.component.html',
  styleUrls: ['./stage2-teasereventstage-page-module-edit.component.scss'],
})
export class Stage2TeaserEventStagePageModuleEditComponent implements OnInit, OnDestroy {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: Stage2TeaserEventStagePageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  currentLanguage: string = null;

  inputJsonpathes: string[] = [
    '$.local.$language.title',
    '$.local.$language.subtitle',
    '$.local.$language.smallHeadline',
    '$.local.$language.background',
    '$.showCountdown',
    '$.local.$language.subtext',
    '$.discoverMore',
    '$.anchor',
  ];

  topAreaJsonpathes: string[] = [
    '$.topAreaHeight.mobile',
    '$.topAreaHeight.mobileLarge',
    '$.topAreaHeight.tablet',
    '$.topAreaHeight.tabletLarge',
    '$.topAreaHeight.desktop',
    '$.topAreaHeight.desktopLarge',
    '$.topAreaHeight.desktopWidescreen',
  ];

  contentAreaJsonpathes: string[] = [
    '$.contentAreaHeight.mobile',
    '$.contentAreaHeight.mobileLarge',
    '$.contentAreaHeight.tablet',
    '$.contentAreaHeight.tabletLarge',
    '$.contentAreaHeight.desktop',
    '$.contentAreaHeight.desktopLarge',
    '$.contentAreaHeight.desktopWidescreen',
  ];

  ctaAreaJsonpathes: string[] = [
    '$.ctaAreaHeight.mobile',
    '$.ctaAreaHeight.mobileLarge',
    '$.ctaAreaHeight.tablet',
    '$.ctaAreaHeight.tabletLarge',
    '$.ctaAreaHeight.desktop',
    '$.ctaAreaHeight.desktopLarge',
    '$.ctaAreaHeight.desktopWidescreen',
  ];

  itemJsonpath = `$.local.$language.items[?(@._id=='$itemId')]`;

  teaserItemTypes: { value: string; label: string }[] = [];
  newTeaserItemType: Stage2TeaserItemType = 'Highlight';

  sessions: { value: string; label: string }[] = [];

  subscriptions: Subscription[] = [];

  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  constructor(private collaborationService: CollaborationService, private utilsService: UtilsService, private activatedRoute: ActivatedRoute, private utils: UtilsService) {}

  async ngOnInit() {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.currentLanguage = params.language;
      })
    );

    this.teaserItemTypes = (
      await Promise.all(
        stage2TeaserItemTypes.map(async (p) => ({
          value: p,
          label: await this.utils.translate(`GENERAL_TEASERITEMTYPE_${p.toUpperCase()}`),
        }))
      )
    ).sort((a, b) => a.label.localeCompare(b.label));

    this.sessions = [{ label: '', value: null }].concat(
      this.eventVersion.sessions.map((s) => {
        const title = s.local['en']?.title || s.local['de']?.title || (Object.keys(s.local).length > 0 ? s.local[Object.keys(s.local)[0]] : '');
        return {
          label: `${new Date(s.startAt).toLocaleDateString()} ${new Date(s.startAt).toLocaleTimeString()} - ${new Date(s.endAt).toLocaleDateString()} ${new Date(
            s.endAt
          ).toLocaleTimeString()} | ${title}`,
          value: s._id,
        };
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  isDisabled(): boolean {
    return this.collaborationService.isDisabled(this.collaborationKey);
  }

  trackByIndex(index: number, item: any) {
    return index;
  }

  async addTeaserItem() {
    const item = await new TeaserEventStagePageModuleFactory().teaserItem({
      teaserItemType: this.newTeaserItemType,
    });

    if (item) {
      await this.collaborationService.patch(this.collaborationKey, this.eventVersion || this.page, {
        command: 'push',
        jsonpath: this.utilsService.combineJsonpath([this.jsonpath, '$.local.$language.items']),
        jsonpathParams: {
          ...this.jsonpathParams,
          language: this.currentLanguage,
        },
        value: item,
      });
    }
  }

  async deleteTeaserItem(item: Stage2TeaserItem) {
    await this.collaborationService.patch(this.collaborationKey, this.eventVersion || this.page, {
      command: 'delete',
      jsonpath: this.utilsService.combineJsonpath([this.jsonpath, `$.local.$language.items[?(@._id=='$itemId')]`]),
      jsonpathParams: {
        ...this.jsonpathParams,
        itemId: item._id,
        language: this.currentLanguage,
      },
    });
  }

  itemJsonpathParams(item: Stage2TeaserItem) {
    return {
      ...this.jsonpathParams,
      language: this.currentLanguage,
      itemId: item._id,
    };
  }
}
