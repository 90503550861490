import { HeadlineType } from './../../entities/Attributes';
import {
  documentCollectionDisplayTypeValues,
  DocumentCollectionPageModule,
  DocumentCollectionPageModuleAttributes,
  DocumentCollectionPageModuleLocal,
} from './../../entities/pagemodules/DocumentCollectionPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class DocumentCollectionPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async documentCollectionPageModuleLocal(values?: any): Promise<DocumentCollectionPageModuleLocal> {
    return {
      title: values?.title || '',
      title_attr: this.documentCollectionPageModuleAttributes(values?.title_attr),
      assets: values?.assets || [],
    };
  }

  public documentCollectionPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): DocumentCollectionPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async documentCollectionPageModule(values?: any): Promise<DocumentCollectionPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'DocumentCollection',
      titlePosition: values?.titlePosition || 'left',
      local: await this.local(values, (val) => this.documentCollectionPageModuleLocal(val)),
      pagination: values?.pagination || 10,
      displayAs: values?.displayAs || documentCollectionDisplayTypeValues[0],
    };
  }
}
