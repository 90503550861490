import * as yup from 'yup';
import { userTypes } from '../../../../entities/User';

export const userValidator = yup.object({
  userType: yup
    .string()
    .oneOf([...userTypes])
    .required(),
  blocked: yup.boolean().optional(),

  tags: yup.lazy((tag) => {
    return yup.object(Object.keys(tag).reduce((a, b) => ({ ...a, [b]: yup.array().of(yup.string()) }), {}));
  }),

  email: yup.string().email(),
  password: yup.string(),
  language: yup.string(),
  salesManager: yup.string(),

  salutation: yup.string(),
  firstName: yup.string(),
  lastName: yup.string(),
  phone: yup.string(),

  department: yup.string(),
  organization: yup.string(),
  street: yup.string(),
  zipCode: yup.string(),
  city: yup.string(),
  country: yup.string(),
  state: yup.string(),
});

export const artificialUserValidator = yup
  .object({
    expert: yup.boolean(),
    active: yup.boolean(),
  })
  .concat(userValidator);
