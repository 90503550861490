import { Component, Input, OnInit } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';

import { CURRENT_LANGUAGE_FILTER, VideoTeaserCollectionPageModule } from 'src/common/entities/PageModule';

import { InputConfiguration } from 'src/common/inputs/Inputs';

import { KeytopicsService } from '../../../services/keytopics/keytopics.service';
import { LanguagesService } from 'src/app/services/languages/languages.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'c-videoteasercollection-page-module-edit',
  templateUrl: './videoteasercollection-page-module-edit.component.html',
  styleUrls: ['./videoteasercollection-page-module-edit.component.scss'],
})
export class VideoTeaserCollectionPageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: VideoTeaserCollectionPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathesGeneral: string[] = ['$.local.$language.title', '$.titlePosition', '$.displayAs', '$.ctaButton', '$.anchor'];

  inputJsonpathesGeneralTEASERCONFIGURATION: string[] = [
    '$.teaserLayoutVariation',
    '$.showVideoTitle',
    '$.showAssetTags',
    '$.showLanguageTags',
    '$.showEventtypeTags',
    '$.showDate',
    '$.showVideoStatusFlag',
    '$.showProgressBar',
    '$.showPlayButton',
  ];

  inputJsonpathesGeneralDISCOVERMORE: string[] = ['$.showDiscoverMoreLast', '$.local.$language.discoverMoreTitle', '$.discoverMoreBackgroundImage', '$.discoverMoreIcon', '$.discoverMoreCta'];

  inputJsonpathesFilter: string[] = ['$.showInProgressVideos', '$.showViewedVideos', '$.showNewVideos', '$.languages'];

  inputJsonpathesVideos: string[] = ['$.VideoTeaserCollectionType', '$.maxCount', '$.keytopics', '$.event', '$.local.$language.videos', '$.query'];

  inputJsonPathesPagination: string[] = ['$.pagination', '$.showSliderPagination'];

  inputJsonPathesLoadMore: string[] = ['$.loadMoreInitialAmount', '$.loadMoreAmount', '$.local.$language.loadMoreButtonLabel'];

  inputConfigurationKeytopic: InputConfiguration = {};
  inputConfigurationLanguages: InputConfiguration = {};

  initialized = false;
  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  constructor(private keytopicsService: KeytopicsService, private languagesService: LanguagesService) {}

  async ngOnInit(): Promise<void> {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
    const [keytopics, activeLanguages] = await Promise.all([this.keytopicsService.getKeytopics(), this.languagesService.getActiveLanguagesAsPromise()]);

    this.inputConfigurationKeytopic = {
      type: 'multiselect',
      multiselectOptions: keytopics.items.map((e) => ({
        label: e.internalName,
        value: e._id,
      })),
    };
    this.inputConfigurationLanguages = {
      type: 'multiselect',
      multiselectOptions: [
        {
          label: 'Current User language selection',
          value: CURRENT_LANGUAGE_FILTER,
        },
      ].concat(
        activeLanguages.map((l) => ({
          label: l.languageName,
          value: l.language,
        }))
      ),
    };

    this.initialized = true;
  }
}
