import { EventSlotConfiguration, EventSlotLocalConfiguration } from './../entities/EventSlot';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';

export class EventSlotConfigurationFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  createEventSlotConfiguration(values?: any) {
    return {
      customTicketSlotHeader: values?.customTicketSlotHeader || false,
      local: this.localKeys(values?.local).reduce((a, b) => ({ ...a, [b]: this.createEventSlotConfigurationLocal(values.local[b]) }), {}),
    } as EventSlotConfiguration;
  }

  createEventSlotConfigurationLocal(values?: any) {
    return {
      customTitle: values?.customTitle || '',
      customDescription: values?.customDescription || '',
    } as EventSlotLocalConfiguration;
  }
}
