import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { OperatingRegionsService } from 'src/app/services/operating-regions/operating-regions.service';
import { IFilterList } from 'src/common/api/interfaces';
import { OperatingRegion } from 'src/common/entities/OperatingRegion';

@Component({
  selector: 'c-operating-region-select-search',
  templateUrl: './operating-region-select-search.component.html',
  styleUrls: ['./operating-region-select-search.component.scss'],
})
export class OperatingRegionSelectSearchComponent implements OnInit {
  @Output()
  operatingRegionSelected: EventEmitter<OperatingRegion> = new EventEmitter<OperatingRegion>();

  ngOnInit(): void {
    this.searchTextSubscription = this.searchTextSubject.pipe(debounceTime(500)).subscribe(() => this.executeSearch());
    this.executeSearch(false);
  }

  @ViewChild('searchInput')
  searchInput: ElementRef<HTMLInputElement>;

  searchText: string;
  searchTextSubject: Subject<string> = new Subject<string>();
  searchTextSubscription: Subscription;
  searchResult: OperatingRegion[] = [];
  loading = true;

  constructor(private operatingRegionsService: OperatingRegionsService) {}

  searchTextKeyUp() {
    this.searchTextSubject.next(this.searchText);
  }

  select(operatingRegion: OperatingRegion) {
    this.operatingRegionSelected.emit(operatingRegion);
  }

  async executeSearch(append: boolean = false) {
    const filter: IFilterList = {
      status: {
        matchMode: 'equals',
        value: 'FINISHED',
      },
    };

    if (this.searchText) {
      filter.internalName = {
        matchMode: 'contains',
        value: this.searchText,
        caseInsensitive: true,
      };
    }

    if (!append) {
      this.searchResult = [];
    }

    const result = await this.operatingRegionsService.getOperatingRegions({
      limit: 10,
      skip: 0,
      filter,
    });

    this.searchResult = this.searchResult.concat(result.items);
  }
}
