import { PageModule } from './PageModule';

export const userProfileTypes = ['CurrentUser', 'User'] as const;
export type UserProfileType = (typeof userProfileTypes)[number];

export interface UserProfileStagePageModule extends PageModule {
  pageModuleType: 'UserProfileStage';
  local: { [language: string]: UserProfileStagePageModuleLocal };
  userProfileType: UserProfileType;
}

export interface UserProfileStagePageModuleLocal {
  background: string;
}

export function isUserProfileStagePageModule(object: any): object is UserProfileStagePageModule {
  return object?.pageModuleType === 'UserProfileStage';
}
