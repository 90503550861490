import { IconGroupPageModuleAttributes } from './../../entities/pagemodules/IconGroupPageModule';
import { HeadlineType } from './../../entities/Attributes';
import { IconGroupPageModule } from '../../entities/pagemodules/IconGroupPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class IconGroupPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public createIconGroupPageModuleLocalIcon(values: any) {
    return {
      title: values?.title || '',
      text: values?.text || '',
      icon: values?.icon || null,
      link: values?.link || null,
    };
  }

  public iconGroupPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): IconGroupPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public createIconGroupPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.iconGroupPageModuleAttributes(values?.title_attr),
      icons: (values?.icons || []).map((i: any) => this.createIconGroupPageModuleLocalIcon(i)),
    };
  }

  public async iconGroupPageModule(values: any): Promise<IconGroupPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'IconGroup',
      titlePosition: values?.titlePosition || 'centered',
      local: await this.local(values, (val) => this.createIconGroupPageModuleLocal(val)),
      ctaButton: values?.ctaButton || null,
    };
  }
}
