import { TextAttributes } from './../Attributes';
import { CTAButton, ShareCTATarget } from '../CTAButton';
import { PageModule, TitlePositions } from './PageModule';

export const viewTypes = ['tabs', 'accordion'] as const;
export type ViewType = (typeof viewTypes)[number];
export const speakerDisplayTypes = ['line', 'list', 'cards'] as const;
export type SpeakerDisplayType = (typeof speakerDisplayTypes)[number];
export interface AgendaPageModule extends PageModule {
  pageModuleType: 'Agenda';
  titlePosition?: TitlePositions;
  event?: string;
  showTime: boolean;
  showThumbnail: boolean;
  showCalendarButton: boolean;
  showShareButton: boolean;
  shareButtonTargets?: ShareCTATarget[];
  indicateLiveSessions: boolean;
  indicatePastSessions: boolean;
  indicateCurrentSession?: boolean;
  maxSessionCount: number;
  viewType: ViewType;
  speakerDisplayType?: SpeakerDisplayType;
  showFilterOptions?: boolean;
  showAgendaCalendarButton?: boolean;
  showAgendaShareButton?: boolean;
  agendaShareButtonTargets?: ShareCTATarget[];
  showCurrentTime?: boolean;
  showBookmarkButton?: boolean;
  showDescription?: boolean;
  showMapButton?: boolean;

  highlightSessions?: string[];

  local: {
    [language: string]: {
      title: string;
      title_attr?: AgendaPageModuleAttributes;
      preCtaText?: string;
    };
  };

  ctaButton?: CTAButton;
}

export type AgendaPageModuleAttributes = TextAttributes & {};

export function isAgendaPageModule(object: any): object is AgendaPageModule {
  return object?.pageModuleType === 'Agenda';
}
