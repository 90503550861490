import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfirmationService, SelectItem } from 'primeng/api';

import { asPollMessage, Message, PollMessage, TextMessage } from 'src/common/entities/Message';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ChatContainer, ChatsService, ConfirmedMessage, PollMessages } from 'src/app/services/chats/chats.service';
import { Router } from '@angular/router';

@Component({
  selector: 'c-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [ConfirmationService],
})
export class ChatComponent implements OnInit {
  @ViewChild('scrollBottomChat') private scrollBottomChat: ElementRef;

  _chatId: string;
  @Input() set chatId(value: string) {
    if (value != this._chatId) {
      this._chatId = value;
      this.loadChat();
    }
  }

  @Output() onOpenArtificialUser = new EventEmitter<string>();
  @Output() onReply = new EventEmitter<Message>();

  chatContainer: Observable<ChatContainer>;

  messageFilters: SelectItem[] = [];
  selectedMessageFilter: string = ConfirmedMessage;

  language: string = 'en';

  constructor(private utilsService: UtilsService, private chatService: ChatsService, private router: Router, private confirmationService: ConfirmationService) {}

  async ngOnInit() {
    this.messageFilters = [
      { title: await this.utilsService.translate('PAGE_LIVE_CHAT_CONFIRMED_MESSAGES'), value: ConfirmedMessage },
      { title: await this.utilsService.translate('PAGE_LIVE_CHAT_POLLS_MESSAGES'), value: PollMessages },
    ];
    this.loadChat();
  }

  async loadChat() {
    if (this._chatId) {
      this.chatContainer = this.chatService.chatContainer(this._chatId);
      this.chatContainer.subscribe((chatContainer) => {
        this.scrollChatToBottom();
        chatContainer.asObservable('confirmed').subscribe(() => this.scrollChatToBottom());
      });
    }
  }

  asTextMessage = (message: Message): TextMessage => {
    return message as TextMessage;
  };

  async confirmDeleteMessage(event: Event, message: Message) {
    this.confirmationService.confirm({
      key: 'chatConfirmPopup',
      target: event.target,
      message: await this.utilsService.translate('PAGE_LIVE_CHAT_DELETE_CONFIRM_MESSAGE'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteMessage(message);
      },
      reject: () => {
        // Nothing to do
      },
    });
  }

  async deleteMessage(message: Message) {
    message = await this.chatService.deleteChatMessage(this._chatId, message._id);
  }

  asPollMessage = (message: Message): PollMessage | null => {
    return asPollMessage(message);
  };

  async confirmClosePoll(event: Event, poll: PollMessage) {
    this.confirmationService.confirm({
      key: 'chatConfirmPopup',
      target: event.target,
      message: await this.utilsService.translate('PAGE_LIVE_CHAT_CLOSE_POLL_CONFIRM_MESSAGE'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.chatService.pollClose(poll);
      },
      reject: () => {
        // Nothing to do
      },
    });
  }

  async confirmPublishPoll(event: Event, poll: PollMessage) {
    this.confirmationService.confirm({
      key: 'chatConfirmPopup',
      target: event.target,
      message: await this.utilsService.translate('PAGE_LIVE_CHAT_PUBLISH_RESULT_CONFIRM_MESSAGE'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.publishPoll(poll);
      },
      reject: () => {
        // Nothing to do
      },
    });
  }

  async publishPoll(poll: PollMessage) {
    await this.chatService.pollPublishResult(poll);
  }

  scrollChatToBottom(): void {
    setTimeout(() => {
      if (this.scrollBottomChat?.nativeElement) {
        this.scrollBottomChat.nativeElement.scrollTop = this.scrollBottomChat.nativeElement.scrollHeight;
      }
    }, 10);
  }

  openArtificialUser(user: string) {
    this.onOpenArtificialUser.emit(user);
  }

  showPollResult(pollMessage: PollMessage) {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/polls/${pollMessage.data.poll._id}`]));

    window.open(url, '_blank');
  }

  showSkippedTrackingId(trackingId: string): string {
    const ti = trackingId.substring(0, 15);
    return ti + (ti.length > 15 ? '...' : '');
  }

  reply(message: Message) {
    this.onReply.emit(message);
  }
}
