import { Injectable } from '@angular/core';
import { GetPollQuery, GetPollResponse } from 'src/common/api/v1/polls/GetPoll';
import { GetPollAnswersQuery, GetPollAnswersResponse } from 'src/common/api/v1/polls/GetPollAnswers';
import { GetPollsQuery, GetPollsResponse } from 'src/common/api/v1/polls/GetPolls';
import { PostPollBody, PostPollQuery, PostPollResponse } from 'src/common/api/v1/polls/PostPoll';
import { PostPollCloseBody, PostPollCloseQuery, PostPollCloseResponse } from 'src/common/api/v1/polls/PostPollClose';
import { PostPollPublishResultBody, PostPollPublishResultQuery, PostPollPublishResultResponse } from 'src/common/api/v1/polls/PostPollPublishResult';
import { PostPollsBody, PostPollsQuery, PostPollsResponse } from 'src/common/api/v1/polls/PostPolls';
import { Poll } from 'src/common/entities/Poll';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class PollsService {
  constructor(private apiService: ApiService) {}

  getPoll(pollId: string): Promise<Poll> {
    return this.apiService.get<GetPollQuery, GetPollResponse>(`/api/v1/polls/${pollId}`).toPromise();
  }

  getPolls(query?: GetPollsQuery): Promise<GetPollsResponse> {
    return this.apiService
      .get<GetPollsQuery, GetPollsResponse>('/api/v1/polls', {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();
  }

  createPoll(poll: Poll): Promise<Poll> {
    return this.apiService.post<PostPollsQuery, PostPollsBody, PostPollsResponse>('/api/v1/polls', poll).toPromise();
  }

  updatePoll(poll: Poll): Promise<Poll> {
    return this.apiService.post<PostPollQuery, PostPollBody, PostPollResponse>(`/api/v1/polls/${poll._id}`, poll).toPromise();
  }

  close(pollId: string): Promise<Poll> {
    return this.apiService.post<PostPollCloseQuery, PostPollCloseBody, PostPollCloseResponse>(`/api/v1/polls/${pollId}/close`, {}).toPromise();
  }

  publishResult(pollId: string): Promise<Poll> {
    return this.apiService.post<PostPollPublishResultQuery, PostPollPublishResultBody, PostPollPublishResultResponse>(`/api/v1/polls/${pollId}/publishresult`, {}).toPromise();
  }

  answers(pollId: string): Promise<GetPollAnswersResponse> {
    return this.apiService.get<GetPollAnswersQuery, GetPollAnswersResponse>(`/api/v1/polls/${pollId}/answers`).toPromise();
  }
}
