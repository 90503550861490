export const videoPlayerTypes = ['AMP', 'VideoJS'] as const;
export type VideoPlayerType = (typeof videoPlayerTypes)[number];

export const videoTargetTypes = ['overlay', 'assetPage'] as const;
export type VideoTargetType = (typeof videoTargetTypes)[number];

export interface AssetConfiguration {
  statusNewDurationInDays: number;
  videoPlayerType: VideoPlayerType;
  videoTarget: VideoTargetType;
  videoAssetPage?: string;

  showVideoTitle: boolean;
  showVideoDescription: boolean;
  showAssetTags: boolean;
  showLanguageTags: boolean;
  showKeytopicTags: boolean;
  showEventTypeTags: boolean;
  showEventTitle: boolean;
  showDate: boolean;
}
