import { ActivatedRoute, Router } from '@angular/router';
import { TabsService } from './../../tabs/services/tabs.service';
import { TableOptions, TableQuery } from './../../components/table/table.interfaces';
import { Tab } from './../../tabs/classes/tab';
import { Component, OnInit } from '@angular/core';

import { BlacklistEntriesService } from 'src/app/services/blacklistEntries/blacklist-entries.service';
import { BlacklistEntry, DomainBlacklistEntry, isDomainBlacklistEntry } from 'src/common/entities/BlacklistEntry';

@Component({
  selector: 'app-blacklist-entries',
  templateUrl: './blacklist-entries.component.html',
  styleUrls: ['./blacklist-entries.component.scss'],
})
export class BlacklistEntriesComponent implements OnInit {
  tab: Tab;
  tableOptions: TableOptions<BlacklistEntry>;

  constructor(private blacklistEntriesService: BlacklistEntriesService, private tabsService: TabsService, private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.tab = this.tabsService.register({
      category: 'data',
      route: this.activatedRoute.snapshot,
      title: 'Blacklist Entries',
    });

    this.tableOptions = {
      size: 50,
      columns: [{ header: 'PAGE_BLACKLIST_ENTRY_TYPE' }, { header: 'GENERAL_INFO', sort: 'domain' }],
      filters: [{ header: 'GENERAL_INFO', path: 'domain' }],
    };
  }

  query(query: TableQuery<BlacklistEntry>) {
    query.result = this.blacklistEntriesService.getBlacklistEntries(query.query);
  }

  newBlacklisstEntry() {
    this.router.navigate(['/configuration/blacklistentries/new']);
  }

  asDomainBlacklistEntry = (blacklistEntry: BlacklistEntry): DomainBlacklistEntry => {
    return isDomainBlacklistEntry(blacklistEntry) ? (blacklistEntry as DomainBlacklistEntry) : null;
  };

  prepareEntryTypeTranslation(blacklistEntry: BlacklistEntry) {
    return `BLACKLISTENTRYTYPE_${blacklistEntry.blacklistEntryType.toUpperCase()}`;
  }
}
