import { TextAttributes } from './../Attributes';
import { PageModule } from './PageModule';
import { CTAButton } from '../CTAButton';

export const contentPosition = ['centered', 'left'] as const;
export type ContentPosition = (typeof contentPosition)[number];

export interface ListPageModule extends PageModule {
  pageModuleType: 'List';
  local: { [language: string]: ListPageModuleLocal };
  ctaButton?: CTAButton;
}

export type ListPageModuleAttributes = TextAttributes & {};

export interface ListPageModuleLocal {
  title: string;
  title_attr?: ListPageModuleAttributes;
  items: string[];
  itemIcon?: string;
  contentPosition?: ContentPosition;
}

export function isListPageModule(object: any): object is ListPageModule {
  return object?.pageModuleType === 'List';
}
