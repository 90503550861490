import { HeadlineType } from './../../entities/Attributes';
import { Text3DImagePageModuleAttributes } from './../../entities/pagemodules/Text3DImagePageModule';
import { Text3DImagePageModule } from '../../entities/pagemodules/Text3DImagePageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class Text3DImagePageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public createText3DImagePageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.text3DImagePageModuleAttributes(values?.title_attr),
      text: values?.text || '',
      image: values?.image || null,
    };
  }

  public text3DImagePageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): Text3DImagePageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async text3DImagePageModule(values: any): Promise<Text3DImagePageModule> {
    if (values?.image) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.image = item.image || values.image;
      });
    }

    const pageModule = {
      ...(await this.baseValues(values)),
      pageModuleType: 'Text3DImage',
      titlePosition: values?.titlePosition || 'left',
      image: undefined,
      imagePosition: values?.imagePosition || 'left',
      local: await this.local(values, (val) => this.createText3DImagePageModuleLocal(val)),
      ctaButton: values?.ctaButton || null,
    };

    return { ...pageModule, pageModuleType: 'Text3DImage' };
  }
}
