import { MessageModule } from 'primeng/message';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DividerModule } from 'primeng/divider';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { ComponentsModule } from 'src/app/components/components.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { DirectivesModule } from 'src/app/directives/directives.module';

import { CustomFieldComponent } from './custom-field.component';

const routes: Routes = [{ path: '', component: CustomFieldComponent }];

@NgModule({
  declarations: [CustomFieldComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    TranslateModule,
    ButtonModule,
    SplitButtonModule,
    SelectButtonModule,
    CardModule,
    DividerModule,
    DropdownModule,
    EditorModule,
    InputTextModule,
    InputTextareaModule,
    ComponentsModule,
    PipesModule,
    DirectivesModule,
    MenuModule,
    DialogModule,
    CheckboxModule,
    MessageModule,
    ListboxModule,
  ],
  exports: [RouterModule],
})
export class CustomFieldModule {}
