import { AfterViewInit, Component, ContentChildren, ElementRef, EventEmitter, HostBinding, Inject, Input, OnChanges, OnDestroy, Output, QueryList, SimpleChanges, TemplateRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FilterValue, IFilterableListQuery, IFilterList } from 'src/common/api/interfaces';
import { TableOptionsComponent } from './table-options/table-options.component';
import { TableStatusComponent } from './table-status/table-status.component';
import { defaultMatchModes, TableColumnSettings, TableFilter, TableFilters, TableOptions, TableQuery } from './table.interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'c-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements OnChanges, OnDestroy, AfterViewInit {
  @ContentChildren(TemplateRef) templates: QueryList<TemplateRef<any>>;

  _defaultSize = 50;
  _templates: any;
  _data: T[] = [];
  _selection: T[] = [];
  _selectAll = false;
  _sort: {
    columnIndex: number;
    direction: 1 | -1;
  };
  _totalRecords = 0;
  _initRecords = 0;
  _totalAdditionalRecords = 0;
  _initAdditionalRecords = 0;
  _firstIndex = -1;
  _page = 0;
  _tableColumnSettings: TableColumnSettings;
  _filterList: IFilterList;
  _subscriptions: Subscription[] = [];
  _filterValues: { [columnIndex: number]: { label: string; value: any }[] } = {};

  private _loading = true;

  @Input()
  $refresh?: Observable<unknown>;

  @Input()
  mode: 'static' | 'query' = 'static';

  @Input()
  options: TableOptions<T> = {
    size: 0,
    columns: [],
    refreshTriggers: [],
    selectable: false,
  };

  @Input()
  tableOptionsComponent: TableOptionsComponent<T>;

  @Input()
  tableStatusComponent: TableStatusComponent;

  @Input()
  data: T[];

  @Input()
  baseRoute: string;

  @Input()
  routePathProperty: string;

  @Input()
  selectWithData: any;

  @Input()
  selectWithDataKey: string;

  @Input()
  selectWithDataUseIndex: boolean;

  @Input()
  queryParams: any;

  @Input()
  queryParamsHandling: 'merge' | 'preserve' = 'preserve';

  //@Input() selection: [] = [];
  @Output() selectionChange = new EventEmitter<T[]>();

  @Output()
  query: EventEmitter<TableQuery<T>> = new EventEmitter<TableQuery<T>>();

  @Output()
  clearFilterList: EventEmitter<IFilterList> = new EventEmitter<IFilterList>();

  @Output()
  itemSelect: EventEmitter<any> = new EventEmitter<any>();
  itemSelectedHasSubcriber: boolean = false;

  @Input()
  singleTemplate = false;

  public get currentFilter(): TableFilters {
    return (this.options?.filters || []).filter((f) => (this._filterList || {})[f.path]);
  }

  private _lastQuery: IFilterableListQuery;
  public get lastQuery(): IFilterableListQuery {
    return this._lastQuery;
  }

  @HostBinding('class.loading')
  public get loading(): boolean {
    return this._loading;
  }

  constructor(@Inject(ElementRef) private elementRef: ElementRef, private router: Router) {}

  async ngAfterViewInit(): Promise<void> {
    setTimeout(async () => {
      this._templates = this.templates.toArray();
      await this.init();
    }, 10);
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.mode === 'static' && changes.data && changes.data.currentValue !== changes.data.previousValue) {
      await this.refresh();
    }

    if (changes.options && changes.options.currentValue !== changes.options.previousValue) {
      await this.init();

      if (!changes.options.previousValue) {
        await this.refresh();
      }
    } else if (changes.tableOptionsComponent && changes.tableOptionsComponent.currentValue !== changes.tableOptionsComponent.previousValue) {
      await this.init();
    }
  }

  async init(): Promise<void> {
    this.itemSelectedHasSubcriber = this.itemSelect.observers.length > 0;
    if (!this.options) {
      return;
    }
    this.clearTableOptionsComponentSubscription();
    this._tableColumnSettings = this.options.columns.map((c) => ({ visible: c.visible, actions: c.actions }));
    this._filterList = {};

    for (let i = 0; i < (this.options.filters || []).length; i++) {
      const filter = this.options.filters[i];

      if (filter.value) {
        this._filterList[filter.path] = {
          value: filter.value,
          matchMode: (filter.matchModes || defaultMatchModes[filter.type || 'string'])[0],
        };
      }
      if (filter.values) {
        this._filterValues[i] = await Promise.resolve(filter.values);
      }
    }

    if (this.tableOptionsComponent) {
      this.tableOptionsComponent.init(this.options, this._tableColumnSettings, this._filterList);

      this._subscriptions.push(
        this.tableOptionsComponent.tableColumnSettingsChange.subscribe((tcs) => {
          this._tableColumnSettings = tcs;

          if (this.singleTemplate) {
            this.singleTemplateShowColumns();
          }
        })
      );

      this._subscriptions.push(
        this.tableOptionsComponent.filterListChange.subscribe((fl) => {
          this._filterList = fl;
          this._firstIndex = 0;
          this.refresh();
        })
      );
    }

    if (this.$refresh) {
      this._subscriptions.push(
        this.$refresh.subscribe(async () => {
          this._firstIndex = 0;
          await this.refresh();
        })
      );
    }
  }

  clearTableOptionsComponentSubscription(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
  }

  ngOnDestroy(): void {
    this.clearTableOptionsComponentSubscription();
  }

  checkSort(): void {
    if (!this._sort && this.options) {
      this._sort = {
        columnIndex: this.options.columns.findIndex((c) => !!c.sort),
        direction: this.options.defaultSortDirection === 'desc' ? -1 : 1,
      };
    }
  }

  onSelectAll(value: any): void {
    this._selection = [...this._data.map((elem) => value)];
    const selection = this._data.filter((elem, i) => this._selection[i]);
    this.selectionChange.emit(selection);
  }

  onSelect(value?: any): void {
    const selection = this._data.filter((elem, i) => this._selection[i]);
    this._selectAll = false;
    this.selectionChange.emit(selection);
  }

  async navigateTo(target: string[]): Promise<void> {
    if (!this.routePathProperty && !this.baseRoute) return;

    if (this.queryParams) {
      await this.router.navigate(target, {
        queryParams: this.queryParams,
        queryParamsHandling: this.queryParamsHandling,
      });
    } else {
      await this.router.navigate(target);
    }
  }

  async sort(columnIndex: number): Promise<void> {
    this._sort = {
      columnIndex,
      direction: ((this._sort.columnIndex === columnIndex ? this._sort.direction : -1) * -1) as 1 | -1,
    };

    await this.refresh();
  }

  async refresh(showLoading = true): Promise<void> {
    if (!this.options) {
      return;
    }

    this._selectAll = false;
    this._selection = [];
    this.selectionChange.emit([]);

    this._loading = showLoading;
    this.checkSort();

    if (this.mode === 'static') {
      await this.buildStatic(true, showLoading);
    } else {
      const sortColumn = this.options.columns[this._sort.columnIndex];
      const query: TableQuery<T> = {
        query: {
          ...(typeof sortColumn?.sort === 'string' ? { orderBy: sortColumn?.sort } : {}),
          ...(this._sort.direction === -1 ? { orderDirection: -1 } : { orderDirection: 1 }),
          ...(this.options.size > 0 ? { limit: this.options.size } : {}),
          ...(this._firstIndex > 0 ? { skip: this._firstIndex } : {}),
          filter: this._filterList || {},
        },
      };

      this._lastQuery = query.query;
      this.query.emit(query);

      if (typeof query.result === 'object') {
        const result = await Promise.resolve(query.result);
        this._totalRecords = result.totalCount;
        this._totalAdditionalRecords = result.additionalCount;
        if (this.tableStatusComponent) {
          if (Object.keys(this._filterList).length === 0) {
            this._initRecords = result.totalCount;
            this._initAdditionalRecords = result.additionalCount;
          }
          await this.tableStatusComponent.init(this._filterList, this._totalRecords, this._initRecords, this._totalAdditionalRecords, this._initAdditionalRecords);
        }
        this._data = result.items;

        if (this.options.size > 0) {
          this._page = Math.min(this._page, Math.floor(this._totalRecords / this.options.size));
          this._firstIndex = this._page * this.options.size;
        }

        setTimeout(() => {
          this.singleTemplateShowColumns();
        }, 0);
      }
    }

    setTimeout(() => {
      this._loading = false;
    }, 200);
  }

  async buildStatic(sort = true, showLoading = true): Promise<void> {
    this._loading = showLoading;

    let data = [...(this.data || [])];
    this._totalRecords = data.length;

    if (this.tableStatusComponent) {
      if (Object.keys(this._filterList).length === 0) {
        this._initRecords = data.length;
      }
      await this.tableStatusComponent.init(this._filterList, this._totalRecords, this._initRecords);
    }

    if (sort) {
      const sortColumn = this.options.columns[this._sort.columnIndex];

      if (sortColumn) {
        if (typeof sortColumn.sort === 'object') {
          const sort = sortColumn.sort;

          if (!sort.type || sort.type === 'string') {
            data = data.sort((a, b) => (a[sort.property]?.toString() || '').localeCompare(b[sort.property]?.toString() || '') * this._sort.direction);
          } else if (sort.type === 'number') {
            data = data.sort((a, b) => ((a[sort.property] || 0) - (b[sort.property] || 0)) * this._sort.direction);
          } else if (sort.type === 'date') {
            data = data.sort((a, b) => {
              if (!a[sort.property] && !b[sort.property]) {
                return 0;
              }
              if (a[sort.property] && !b[sort.property]) {
                return 1 * this._sort.direction;
              }
              if (!a[sort.property] && b[sort.property]) {
                return -1 * this._sort.direction;
              }
              return (new Date(a[sort.property]).getTime() - new Date(b[sort.property]).getTime()) * this._sort.direction;
            });
          }
        } else if (typeof sortColumn.sort === 'function') {
          data = data.sort((a, b) => (sortColumn.sort as (a, b) => any)(a, b) * this._sort.direction);
        }
      }
    }

    if (this.options.size > 0) {
      this._page = Math.min(this._page, Math.floor(this._totalRecords / this.options.size));
      this._firstIndex = this._page * this.options.size;
      data = data.slice(this._firstIndex, this._firstIndex + this.options.size);
    }

    this._data = data;

    setTimeout(() => {
      this._loading = false;
    }, 200);
  }

  async onPageChange(event): Promise<void> {
    if (event.first !== this._firstIndex) {
      this._firstIndex = event.first;
      this._page = event.page;
      this._selection = [];
      await this.refresh();
    }
  }

  async clearFilter(): Promise<void> {
    this._filterList = {};
    this.clearFilterList.emit(this._filterList);

    if (this.tableOptionsComponent) {
      this.tableOptionsComponent._filterList = this._filterList;
    }

    await this.refresh();
  }

  filterValue(filter: TableFilter): FilterValue {
    if (filter.type === 'date' && this._filterList[filter.path].value) {
      return new Date(this._filterList[filter.path].value as string).toLocaleDateString();
    }

    if (filter.values) {
      const index = this.options.filters.indexOf(filter);

      if (index >= 0) {
        if (this._filterValues[index]) {
          return this._filterValues[index].find((v) => v.value === this._filterList[filter.path].value)?.label;
        }
      }
    }

    return this._filterList[filter.path].value;
  }

  private singleTemplateShowColumns(): void {
    // dont like this solutions, but ok for now...

    const indexes = this._tableColumnSettings.map((c, i) => (typeof c.visible !== 'boolean' || c.visible === true ? i : -1)).filter((c) => c >= 0);
    const rows = this.elementRef.nativeElement?.querySelectorAll('tbody tr');
    for (let i = 0; i < rows.length; i++) {
      const cols = rows[i]?.querySelectorAll('td');

      for (let j = 0; j < cols.length; j++) {
        if (indexes.includes(j)) {
          cols[j].classList.remove('hide');
        } else {
          cols[j].classList.add('hide');
        }
      }
    }
  }

  itemIsSelected(data: any, index: any = undefined): void {
    if (this.selectWithDataUseIndex) {
      this.itemSelect.emit(index);
      return;
    }

    if (data && this.selectWithDataKey && data[this.selectWithDataKey]) {
      this.itemSelect.emit(data[this.selectWithDataKey]);
      return;
    }
    if (data) {
      this.itemSelect.emit(data);
      return;
    }
    if (this.selectWithData && this.selectWithDataKey && this.selectWithData[this.selectWithDataKey]) {
      this.itemSelect.emit(this.selectWithData[this.selectWithDataKey]);
      return;
    }
    if (this.selectWithData) {
      this.itemSelect.emit(this.selectWithData);
      return;
    }
    this.itemSelect.emit();
  }
}
