<div class="sidebar-tabs">
  <button [class.active]="tab === 'general'" (click)="tab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
  <button [class.active]="tab === 'products'" (click)="tab = 'products'">{{ 'GENERAL_PRODUCTS' | translate }}</button>
  <button [class.active]="tab === 'design'" (click)="tab = 'design'">{{ 'GENERAL_DESIGN' | translate }}</button>
  <button [class.active]="tab === 'conditions'" (click)="tab = 'conditions'">{{ 'GENERAL_CONDITIONS' | translate }}</button>
</div>

<div *ngIf="tab == 'general'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.local.$language.title']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.local.$language.buttonLabel']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.buttonPositionTop']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.buttonPositionLeft']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <p-accordion>
    <p-accordionTab header="Background Image">
      <c-page-patch-input
        *ngFor="let inputJsonpath of backgroundImageJsonpathes"
        [jsonpath]="[jsonpath, inputJsonpath]"
        [jsonpathParams]="jsonpathParams"
        [object]="eventVersion || page"
      ></c-page-patch-input>
    </p-accordionTab>
    <p-accordionTab header="Video">
      <c-page-patch-input *ngFor="let inputJsonpath of videoJsonpathes" [jsonpath]="[jsonpath, inputJsonpath]" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    </p-accordionTab>
    <p-accordionTab header="Slider Background Image">
      <c-page-patch-input
        *ngFor="let inputJsonpath of sliderBackgroundImageJsonpathes"
        [jsonpath]="[jsonpath, inputJsonpath]"
        [jsonpathParams]="jsonpathParams"
        [object]="eventVersion || page"
      ></c-page-patch-input>
    </p-accordionTab>
  </p-accordion>
</div>

<div *ngIf="tab == 'products'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.products']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'design'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.backgroundTheme']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.stageHeight.desktop']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.stageHeight.desktopLarge']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.stageHeight.desktopWidescreen']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.stageHeight.tablet']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.stageHeight.tabletLarge']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.stageHeight.mobile']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.stageHeight.mobileLarge']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>
<div *ngIf="tab == 'conditions'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.accessPolicy']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page" [inputConfiguration]="accessInputConfiguration"></c-page-patch-input>
</div>
