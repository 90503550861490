<button class="input-button" (click)="edit()">
  {{ _accessPoliciesLabel || 'Public' }}
</button>

<p-dialog [(visible)]="_editAccessPolicies" [modal]="true" header="Edit Policy" [appendTo]="'body'" (onHide)="onBlur.emit()">
  <div *ngIf="!allowedAccessPolicyTypes || allowedAccessPolicyTypes.includes('Public')">
    <div class="p-field-radiobutton">
      <p-radioButton [disabled]="disabled" name="mode" value="public" [(ngModel)]="mode" (ngModelChange)="modeChange()" inputId="public"></p-radioButton>
      <label for="public">Everybody</label>
    </div>
  </div>
  <div *ngIf="!allowedAccessPolicyTypes || allowedAccessPolicyTypes.includes('Platform')">
    <div class="p-field-radiobutton">
      <p-radioButton [disabled]="disabled" name="mode" value="platform" [(ngModel)]="mode" (ngModelChange)="modeChange()" inputId="platform"></p-radioButton>
      <label for="platform">Users who are logged in</label>
    </div>
  </div>
  <div *ngIf="!allowedAccessPolicyTypes || (allowedAccessPolicyTypes.includes('Platform') && inverted)">
    <div class="p-field-radiobutton">
      <p-radioButton [disabled]="disabled" name="mode" value="platform-inverted" [(ngModel)]="mode" (ngModelChange)="modeChange()" inputId="platform-inverted"></p-radioButton>
      <label for="platform-inverted">Users who are <strong>not</strong> logged in</label>
    </div>
  </div>
  <div *ngIf="!allowedAccessPolicyTypes || allowedAccessPolicyTypes.includes('NoTicket')">
    <div class="p-field-radiobutton">
      <p-radioButton [disabled]="disabled" name="mode" value="noticket-loggedin" [(ngModel)]="mode" (ngModelChange)="modeChange()" inputId="noticket-loggedin"></p-radioButton>
      <label for="noticket-loggedin">Users who do <strong>not</strong> have any event tickets and are logged in</label>
    </div>
  </div>
  <div *ngIf="!allowedAccessPolicyTypes || allowedAccessPolicyTypes.includes('NoTicket')">
    <div class="p-field-radiobutton">
      <p-radioButton [disabled]="disabled" name="mode" value="noticket-loggedout" [(ngModel)]="mode" (ngModelChange)="modeChange()" inputId="noticket-loggedout"></p-radioButton>
      <label for="noticket-loggedout">Users who do <strong>not</strong> have any event tickets or are not logged in</label>
    </div>
  </div>
  <div *ngIf="(!allowedAccessPolicyTypes || allowedAccessPolicyTypes.includes('EventTicket')) && eventVersion">
    <div class="p-field-radiobutton">
      <p-radioButton [disabled]="disabled" name="mode" value="eventticket" [(ngModel)]="mode" (ngModelChange)="modeChange()" inputId="eventticket"></p-radioButton>
      <label for="eventticket">Users with a specific event ticket</label>
    </div>
    <div class="radiobutton-subcontent" *ngIf="mode === 'eventticket'">
      <p-multiSelect [options]="tickets" [(ngModel)]="selectedEventTickets" defaultLabel="Select a Ticket" [appendTo]="'body'"></p-multiSelect>
    </div>
  </div>
  <div *ngIf="(!allowedAccessPolicyTypes || allowedAccessPolicyTypes.includes('VIPTicket')) && eventVersion">
    <div class="p-field-radiobutton">
      <p-radioButton [disabled]="disabled" name="mode" value="vipticket" [(ngModel)]="mode" (ngModelChange)="modeChange()" inputId="vipticket"></p-radioButton>
      <label for="vipticket">Users who are on the VIP list with a specific event ticket</label>
    </div>
    <div class="radiobutton-subcontent" *ngIf="mode === 'vipticket'">
      <p-multiSelect [options]="tickets" [(ngModel)]="selectedVipTickets" defaultLabel="Select a Ticket" [appendTo]="'body'"></p-multiSelect>
    </div>
  </div>
  <div *ngIf="!allowedAccessPolicyTypes || allowedAccessPolicyTypes.includes('GlobalTicket')">
    <div class="p-field-radiobutton">
      <p-radioButton [disabled]="disabled" name="mode" value="globalticket" [(ngModel)]="mode" (ngModelChange)="modeChange()" inputId="globalticket"></p-radioButton>
      <label for="globalticket">Users with a specific global ticket</label>
    </div>
    <div class="radiobutton-subcontent" *ngIf="mode === 'globalticket'">
      <p-multiSelect [options]="globalTickets" [(ngModel)]="selectedGlobalTickets" defaultLabel="Select a Global Ticket" [appendTo]="'body'"></p-multiSelect>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      label="Set Policy"
      (onClick)="setAccessPolicy()"
      [disabled]="disabled || (mode === 'eventticket' && selectedEventTickets.length === 0) || (mode === 'vipticket' && selectedVipTickets.length === 0)"
    ></p-button>
  </ng-template>
</p-dialog>
