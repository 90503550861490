<div *ngIf="value">
  <div class="input-button" (click)="showSearch = true">
    {{ form?.internalName || ('GENERAL_UNKNOWN' | translate) }}&nbsp;
    <a [routerLink]="[]" [queryParamsHandling]="'merge'" (click)="removeForm(); $event.stopPropagation()"> Remove </a>
  </div>
</div>
<div *ngIf="!value">
  <div class="input-button" (click)="showSearch = true">None</div>
</div>

<c-sidebar [(visible)]="showSearch" header="Select form" (onShow)="executeSearch()">
  <div class="sidebar-search">
    <div class="search-input input-group compact">
      <div class="input">
        <input #searchInput placeholder="Search form ..." pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
      </div>
    </div>
    <div class="search-results">
      <div class="search-result text" *ngFor="let form of searchResult" (click)="selectForm(form)">
        {{ form.internalName }}
      </div>
    </div>
  </div>
</c-sidebar>
