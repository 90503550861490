import { NgModule } from '@angular/core';
import { EventStageEditComponent } from './event-stage-edit/event-stage-edit.component';
import { EventStageEditModule } from './event-stage-edit/event-stage-edit.module';

@NgModule({
  declarations: [],
  imports: [EventStageEditModule],
  exports: [EventStageEditComponent],
})
export class EventStagesModule {}
