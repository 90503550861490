import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { TableComponent } from 'src/app/components/table/table.component';
import { TableOptions } from 'src/app/components/table/table.interfaces';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { EventsService } from 'src/app/services/events/events.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Event } from 'src/common/entities/Event';
import { EventVersion, EventVersionChat } from 'src/common/entities/EventVersion';
import { Session } from 'src/common/entities/Session';
import { Factory } from 'src/common/factories/Factory';

@Component({
  selector: 'c-event-chats',
  templateUrl: './event-chats.component.html',
  styleUrls: ['./event-chats.component.scss'],
})
export class EventChatsComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) table: TableComponent<EventVersionChat>;

  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  sessions: { [chat: string]: Session[] } = {};

  editableChat: EventVersionChat;

  get showChat(): boolean {
    return !!this.editableChat;
  }
  set showChat(val: boolean) {
    if (!val) {
      this.editableChat = null;
    }
  }

  subscriptions: Subscription[] = [];

  chatTableOptions: TableOptions<EventVersionChat>;

  constructor(private confirmationService: ConfirmationService, private utilsService: UtilsService, private configurationService: ConfigurationService, private eventsService: EventsService) {
    this.chatTableOptions = {
      columns: [
        {
          header: 'Internal Name',
          sort: { type: 'string', property: 'internalName' },
        },
        { header: 'Sessions' },
        { header: '' },
      ],
    };
  }

  async ngOnInit(): Promise<void> {
    for (const chat of this.eventVersion.chats) {
      this.sessions[chat.chat] = this.eventVersion.sessions.filter((s) => Object.keys(s.local).find((l) => s.local[l].chat === chat.chat));
    }

    this.subscriptions.push(
      this.eventsService.lastEventVersionPatch(this.eventVersion._id).subscribe((patch) => {
        if (this.utilsService.startsWithJsonpath(patch.patch.jsonpath, '$.chats', patch.patch.jsonpathParams)) {
          this.table.refresh();
        }

        if (this.editableChat) {
          const chat = this.eventVersion.chats.find((chat) => chat.chat == this.editableChat.chat);
          if (!chat) {
            this.editableChat = null;
          } else {
            this.editableChat = chat;
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  openChatDialog(chat: EventVersionChat): void {
    this.editableChat = chat;
  }

  async addChat(): Promise<void> {
    const chat = await Factory.eventVersion().createEventVersionChat({
      chat: await this.configurationService.newObjectId(),
      internalName: 'New Chat',
    });
    await this.eventsService.patch(this.eventVersion, {
      command: 'push',
      jsonpath: '$.chats',
      value: chat,
    });
    this.openChatDialog(chat);
  }

  async deleteChat(chat: EventVersionChat): Promise<void> {
    await this.eventsService.patch(this.eventVersion, {
      command: 'delete',
      jsonpath: `$.chats[?(@.chat=='$chatId')]`,
      jsonpathParams: {
        chatId: chat.chat,
      },
    });
  }
}
