import { ShowroomPageModule } from '../../entities/pagemodules/ShowroomPageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class ShowroomPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public createShowroomPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
    };
  }

  public async showroomPageModule(values: any): Promise<ShowroomPageModule> {
    return {
      ...(await this.baseValues(values)),
      pageModuleType: 'Showroom',
      showroomConfiguration: values?.showroomConfiguration || null,
      local: await this.local(values, (val) => this.createShowroomPageModuleLocal(val)),
      ctaButton: values?.ctaButton || null,
    };
  }
}
