import { DirectivesModule } from './../../directives/directives.module';
import { ButtonModule } from 'primeng/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';

import { ComponentsModule } from 'src/app/components/components.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

import { LanguagesComponent } from './languages.component';

const routes: Routes = [{ path: '', component: LanguagesComponent }];

@NgModule({
  declarations: [LanguagesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    TranslateModule,
    TableModule,
    CardModule,
    CheckboxModule,
    ComponentsModule,
    PipesModule,
    ButtonModule,
    ComponentsModule,
    DirectivesModule,
  ],
  exports: [RouterModule],
})
export class LanguagesModule {}
