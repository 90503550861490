import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { EventTicket, EventTicketCode, EventTicketConfiguration, EventTicketConfigurationLocal } from '../entities/EventTicket';

export class EventTicketFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  createEventTicketConfigurationLocal(values?: any): EventTicketConfigurationLocal {
    return {
      customConfirmationDialogHeadline: values?.customConfirmationDialogHeadline || '',
      customConfirmationDialogText: values?.customConfirmationDialogText || '',
      customChangedConfirmationDialogHeadline: values?.customChangedConfirmationDialogHeadline || '',
      customChangedConfirmationDialogText: values?.customChangedConfirmationDialogText || '',
      customEmailTemplateParts: values?.customEmailTemplateParts || {},
      customChangedEmailTemplateParts: values?.customChangedEmailTemplateParts || {}
    } as EventTicketConfigurationLocal;
  }

  createCustomTeplateParts(values?: any) {
    return {
      parts: {}
    };
  }

  createEventTicketConfiguration(values?: any): EventTicketConfiguration {
    return {
      maxEventTickets: values?.maxEventTickets || null,
      customConfirmationDialog: values?.customConfirmationDialog || false,
      customChangedConfirmationDialog: values?.customChangedConfirmationDialog || false,
      sendTicketConfirmationMail: values?.sendTicketConfirmationMail !== undefined ? values?.sendTicketConfirmationMail : true,
      sendChangedTicketConfirmationMail: values?.sendChangedTicketConfirmationMail !== undefined ? values?.sendChangedTicketConfirmationMail : true,
      sendCustomTicketConfirmationMail: values?.sendCustomTicketConfirmationMail || false,
      local: this.localKeys(values?.local).reduce((a, b) => ({ ...a, [b]: this.createEventTicketConfigurationLocal(values.local[b]) }), {}),
      emailTemplate: values?.emailTemplate || '',
      emailChangedTemplate: values?.emailChangedTemplate || '',
      warningCTA: values?.warningCTA || null,
      backgroundImage: values?.backgroundImage || '',
      showQRCode: values?.showQRCode || false
    } as EventTicketConfiguration;
  }

  async createEventTicket(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      validFrom: values?.validFrom || null,
      validUntil: values?.validUntil || null,
      digital: typeof values?.digital === 'boolean' ? values?.digital : true,
      onSite: typeof values?.onSite === 'boolean' ? values?.onSite : false,
      isUpgradeOf: values?.isUpgradeOf || null,
      maxEventSlots: typeof values?.maxEventSlots === 'number' ? values?.maxEventSlots : 1,
      sendRegistrationToExternalSystem: typeof values?.sendRegistrationToExternalSystem === 'boolean' ? values?.sendRegistrationToExternalSystem : false,
      showInEventRegistrationProcess: typeof values?.showInEventRegistrationProcess === 'boolean' ? values?.showInEventRegistrationProcess : true,
      showQRCode: typeof values?.showQRCode === 'boolean' ? values?.showQRCode : false,
      showDates: typeof values?.showDates === 'boolean' ? values?.showDates : true,
      showUserInfo: typeof values?.showUserInfo === 'boolean' ? values?.showUserInfo : true,
      isDigitalPass: typeof values?.isDigitalPass === 'boolean' ? values?.isDigitalPass : false,
      icon: values?.icon || null,
      deletedAt: values?.deletedAt || null,
      capacity: values?.capacity || null,
      warningCapacity: values?.warningCapacity || null,
      pricing: values?.pricing || {
        currency: null,
        segments: []
      },
      maxAttendees: values?.maxAttendees || 3,
      local: this.localKeys(values?.local).reduce((a, b) => ({ ...a, [b]: this.createEventTicketLocal(values.local[b]) }), {}),
      readAccess: values?.readAccess || [],
      codes: Array.isArray(values?.codes) ? await Promise.all(values.codes.map((code: any) => this.createEventTicketCode(code))) : [],
      warningCTA: values?.warningCTA || null,
      backgroundImage: values?.backgroundImage || '',
      backgroundColor: values?.backgroundColor || '',
      foregroundColor: values?.foregroundColor || '',
      logo: values?.logo || '',
      ticketNameBackgroundColor: values?.ticketNameBackgroundColor || '',
      ticketNameForegroundColor: values?.ticketNameForegroundColor || '',
      useWalletAdvantages: values?.useWalletAdvantages || ''
    } as EventTicket;
  }

  async createEventTicketCode(values?: any): Promise<EventTicketCode> {
    return {
      _id: await this.id(values?._id),
      code: values?.code || '',
      startAt: values?.startAt || '',
      endAt: values?.endAt || '',
      enabled: typeof values?.enabled === 'boolean' ? values?.enabled : true
    };
  }

  createEventTicketLocal(values?: any) {
    return {
      title: values?.title || '',
      walletTitle: values?.walletTitle || '',
      description: values?.description || '',
      advantages: values?.advantages || [],
      walletAdvantages: values?.walletAdvantages || [],
      digitalPassWarning: values?.digitalPassWarning || '',
      digitalPassTitle: values?.digitalPassTitle || '',
      digitalPassDescription: values?.digitalPassDescription || ''
    };
  }
}
