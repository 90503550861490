import { OperatingRegion, OperatingRegionLocal } from '../entities/OperatingRegion';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';

export class OperatingRegionFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createOperatingRegion(values?: any) {
    return {
      _id: await this.id(values?._id),
      internalName: values?.internalName || '',
      sortOrder: values?.sortOrder || null,
      local: await this.local(values, (val) => this.createOperatingRegionLocal(val)),
    } as OperatingRegion;
  }

  async createOperatingRegionLocal(values?: any) {
    return {
      title: values?.title || '',
    } as OperatingRegionLocal;
  }
}
