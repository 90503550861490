import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { EventsService } from 'src/app/services/events/events.service';
import { EventPhase } from 'src/common/entities/Event';
import { MenuItem, MenuItemType } from 'src/common/entities/MenuItem';
import { MenuItemFactory } from 'src/common/factories/MenuItemFactory';
import { InputConfiguration, Inputs } from 'src/common/inputs/Inputs';
import { LayoutContext, SiteStructureTab } from '../layout-editor';
import { appFunctionInputs } from 'src/common/inputs/menu-items/AppFunctionInputs';
import { MenuData, SITE_STRUCTURE_MENU_DATA } from './const/site-structure.const';

@Component({
  selector: 'c-site-structure',
  templateUrl: './site-structure.component.html',
  styleUrls: ['./site-structure.component.scss', '../layout-editor.scss'],
})
export class SiteStructureComponent implements OnInit, OnDestroy {
  @Input()
  context: LayoutContext;

  @Output()
  onClose = new EventEmitter<boolean>();

  @Input()
  tab: SiteStructureTab;

  eventPhases: EventPhase[] = ['ANNOUNCED', 'RELEASED', 'LIVE', 'ARCHIVE'];
  edit: {
    mode: 'selectType' | 'edit';
    eventPhase: EventPhase;
    menuItem?: MenuItem;
  } | null = null;
  currentLanguage: string = 'en';
  subscriptions: Subscription[] = [];

  inputs: Inputs = {};

  inputConfigurationReadAccess: InputConfiguration = {};
  inputConfigurationExecuteAccess: InputConfiguration = {};

  dialogDropdownItems = [
    { label: 'Login', value: 'login' },
    { label: 'Register Event', value: 'registerevent' },
    { label: 'Meet Your Expert', value: 'meetyourexpert' },
    { label: 'UserCentrics', value: 'usercentrics' },
  ];

  get jsonpathEdit(): string {
    return `$.phase.$eventPhase.${this.tab}[?(@._id=='$menuItemId')]`;
  }

  get jsonpathParamsEdit() {
    return { eventPhase: this.edit.eventPhase, menuItemId: this.edit.menuItem._id };
  }
  private siteStructureMenuData = new Map<SiteStructureTab, MenuData>(SITE_STRUCTURE_MENU_DATA);

  constructor(private eventsService: EventsService, private confirmationService: ConfirmationService, private translate: TranslateService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.inputConfigurationReadAccess = {
      type: 'accesspolicy',
      eventVersion: this.context.eventVersion,
    };
    this.inputConfigurationExecuteAccess = {
      type: 'accesspolicy',
      allowedAccessPolicyTypes: ['Public', 'Platform'],
      inverted: false,
    };
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.currentLanguage = params.language || 'en';
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  buildInputs(eventPhase: EventPhase, menuItem: MenuItem) {
    this.inputs = {
      [`$.phase.$eventPhase.${this.tab}[?(@._id=='$menuItemId')].page`]: {
        type: 'dropdown',
        dropdownOptions: [{ value: null, label: '-' }].concat(
          this.context.eventVersion.phase[eventPhase].pages.map((p) => ({
            label: `${p.local[this.currentLanguage]?.title || this.translate.instant('GENERAL_UNNAMED')} - /${p.path || ''}`,
            value: p._id,
          }))
        ),
      },
      [`$.phase.$eventPhase.${this.tab}[?(@._id=='$menuItemId')].dialog`]: {
        type: 'dropdown',
        dropdownOptions: [...this.dialogDropdownItems],
      },
    };

    if (menuItem.menuItemType === 'AppFunction') {
      this.inputs[`$.phase.$eventPhase.${this.tab}[?(@._id=='$menuItemId')].appFunction.local.$language`] = appFunctionInputs['$.local.$language'];
      this.inputs[`$.phase.$eventPhase.${this.tab}[?(@._id=='$menuItemId')].appFunction.action`] = appFunctionInputs['$.action'];
      this.inputs[`$.phase.$eventPhase.${this.tab}[?(@._id=='$menuItemId')].appFunction.local.$language.actionData`] = appFunctionInputs['$.local.$language.actionData'];
    }
  }

  trackByIndex(index: number, item: any) {
    return index;
  }

  async menuItemUp(eventPhase: EventPhase, menuItem: MenuItem) {
    await this.eventsService.patch(this.context.eventVersion, {
      command: 'up',
      jsonpath: `$.phase.$eventPhase.${this.tab}[?(@._id=='$menuItemId')]`,
      jsonpathParams: {
        eventPhase,
        menuItemId: menuItem._id,
      },
    });
  }

  async menuItemDown(eventPhase: EventPhase, menuItem: MenuItem) {
    await this.eventsService.patch(this.context.eventVersion, {
      command: 'down',
      jsonpath: `$.phase.$eventPhase.${this.tab}[?(@._id=='$menuItemId')]`,
      jsonpathParams: {
        eventPhase,
        menuItemId: menuItem._id,
      },
    });
  }

  async confirmDeleteMenuItem(event: any, eventPhase: EventPhase, menuItem: MenuItem) {
    this.confirmationService.confirm({
      target: event.target,
      message: this.translate.instant('GENERAL_CONFIRM_DELETE'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.deleteMenuItem(eventPhase, menuItem),
      reject: () => {},
    });
  }

  async deleteMenuItem(eventPhase: EventPhase, menuItem: MenuItem) {
    await this.eventsService.patch(this.context.eventVersion, {
      command: 'delete',
      jsonpath: `$.phase.$eventPhase.${this.tab}[?(@._id=='$menuItemId')]`,
      jsonpathParams: {
        eventPhase,
        menuItemId: menuItem._id,
      },
    });
  }

  async addMenuItem(eventPhase: EventPhase, menuItemType: MenuItemType) {
    let menuItem = this.siteStructureMenuData.get(this.tab)?.factory
      ? await this.siteStructureMenuData.get(this.tab)?.factory(menuItemType)
      : await new MenuItemFactory().menuItem({ menuItemType: menuItemType });

    await this.eventsService.patch(this.context.eventVersion, {
      command: 'push',
      jsonpath: `$.phase.$eventPhase.${this.tab}`,
      jsonpathParams: {
        eventPhase,
      },
      value: menuItem,
    });

    menuItem = this.context.eventVersion.phase[eventPhase][this.tab].find((m) => menuItem._id === m._id);

    if (menuItem) {
      this.editMenuItem(eventPhase, menuItem);
    }
  }

  editMenuItem(eventPhase: EventPhase, menuItem: MenuItem) {
    this.buildInputs(eventPhase, menuItem);
    this.edit = {
      eventPhase: eventPhase,
      mode: 'edit',
      menuItem: menuItem,
    };
  }

  menuItemTitle(menuItem: MenuItem) {
    return menuItem.local[this.currentLanguage]?.title || this.translate.instant('GENERAL_UNNAMED');
  }

  getEditTitle(): string {
    return this.siteStructureMenuData.get(this.tab)?.title ?? '';
  }
}
