import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetSpeakerQuery, GetSpeakerResponse } from 'src/common/api/v1/speakers/GetSpeaker';
import { GetSpeakersQuery, GetSpeakersResponse } from 'src/common/api/v1/speakers/GetSpeakers';
import { PostSpeakerBody, PostSpeakerQuery, PostSpeakerResponse } from 'src/common/api/v1/speakers/PostSpeaker';
import { PostSpeakersBody, PostSpeakersQuery, PostSpeakersResponse } from 'src/common/api/v1/speakers/PostSpeakers';
import { Speaker } from 'src/common/entities/Speaker';
import { ApiService } from '../api/api.service';
import { CacheContainer } from '../cache/cache-container';
import { CacheService } from '../cache/cache.service';

@Injectable({
  providedIn: 'root'
})
export class SpeakersService {
  private _cache: CacheContainer<Speaker>;

  constructor(private apiService: ApiService, private cacheService: CacheService) {
    this._cache = this.cacheService.create<Speaker>({
      fill: async () => (await this.apiService.get<GetSpeakersQuery, GetSpeakersResponse>('/api/v1/speakers').toPromise()).items,
      get: async _id => await this.apiService.get<GetSpeakerQuery, GetSpeakerResponse>(`/api/v1/speakers/${_id}`).toPromise(),
      socketEvents: ['speaker:update']
    });
  }

  asObservable(speaker: string): Observable<Speaker> {
    return this._cache.asObservable(speaker);
  }

  asPromise(speaker: string, refresh: boolean = false): Promise<Speaker> {
    return this._cache.asPromise(speaker, refresh);
  }

  getSpeaker(_id: string): Promise<Speaker> {
    return this.apiService.get<GetSpeakerQuery, GetSpeakerResponse>(`/api/v1/speakers/${_id}`).toPromise();
  }

  async getSpeakers(query?: GetSpeakersQuery): Promise<GetSpeakersResponse> {
    return this.apiService
      .get<GetSpeakersQuery, GetSpeakersResponse>('/api/v1/speakers', {
        limit: 999999,
        skip: 0,
        ...query
      })
      .toPromise();
    // .toPromise().then(res => {
    //   res.items.sort(((a, b) => {
    //     if (!a.displayName && !b.displayName) {
    //       return 0
    //     }

    //     if (!a.displayName) {
    //       return 1
    //     }

    //     if (!b.displayName) {
    //       return -1
    //     }

    //     return a.displayName.localeCompare(b.displayName)
    //   }))

    //   return res;
    // });
  }

  createSpeaker(speaker: Speaker): Promise<Speaker> {
    return this._cache.post<PostSpeakersQuery, PostSpeakersBody, PostSpeakersResponse>('/api/v1/speakers', speaker);
  }

  updateSpeaker(speaker: Speaker): Promise<Speaker> {
    return this._cache.post<PostSpeakerQuery, PostSpeakerBody, PostSpeakerResponse>(`/api/v1/speakers/${speaker._id}`, speaker);
  }

  deleteSpeaker(speaker: Speaker): Promise<Speaker> {
    return this._cache.post<PostSpeakerQuery, PostSpeakerBody, PostSpeakerResponse>(`/api/v1/speakers/${speaker._id}/delete`, speaker);
  }
}
