<div class="panel">
  <div class="panel-header">
    <span>{{ 'PAGE_LIVE_CHAT_LIVE_CHAT' | translate }}</span>
    <div class="actions">
      <p-dropdown [(ngModel)]="selectedMessageFilter" [options]="messageFilters" optionValue="value" optionLabel="title"></p-dropdown>
    </div>
  </div>
  <div class="chat-container" *ngIf="chatContainer | async as cc">
    <div #scrollBottomChat class="messages">
      <div class="message" *ngFor="let message of cc.getFilteredMessages(selectedMessageFilter); let index = index" [ngClass]="!message.expert ? 'left' : 'right'">
        <div class="bubble">
          <div class="bubble-head p-d-flex p-ai-center">
            <span *ngIf="message.messageType === 'PollMessage' && asPollMessage(message) as poll" class="user p-d-flex p-ai-center p-mr-1">
              <i class="pi pi-chart-bar p-mr-1"></i>
              {{ 'PAGE_LIVE_CHAT_POLLS_REQUEST' | translate }}
            </span>

            <span *ngIf="message.expert" class="p-d-flex p-ai-center p-mr-1">
              <i class="pi pi-star" [pTooltip]="'GENERAL_EXPERT_USER' | translate"></i>
            </span>

            <span *ngIf="message.messageType !== 'PollMessage' && message.user._id" class="user p-mr-1" (mouseenter)="userDetails.show($event)" (mouseleave)="userDetails.hide()">
              <a *ngIf="message.user?.userType != 'ArtificialUser' && message.user._id" [routerLink]="['/users/' + message.user._id]">
                {{ message.user?.displayName || 'Unknown User' }}
              </a>
              <a *ngIf="message.user?.userType == 'ArtificialUser'" [routerLink]="[]" (click)="openArtificialUser(message.user._id)">
                {{ message.user?.displayName || 'Unknown User' }}
              </a>
              <span *ngIf="message.user?.userType != 'ArtificialUser' && !message.user._id">
                {{ message.user?.displayName || 'Unknown User' }}
              </span>
              <p-overlayPanel [dismissable]="true" #userDetails appendTo="body" (click)="userDetails.toggle($event)">
                <ng-template pTemplate>
                  <c-user-details [userType]="'USER'" [user]="message.user?._id"></c-user-details>
                </ng-template>
              </p-overlayPanel>
            </span>
            <span *ngIf="message.messageType !== 'PollMessage' && !message.user._id" class="user p-mr-1">
              <a *ngIf="message.user?.userType != 'ArtificialUser' && message.user._id" [routerLink]="['/users/' + message.user._id]">
                {{ message.user?.displayName || 'Unknown User' }}
              </a>
              <a *ngIf="message.user?.userType == 'ArtificialUser'" [routerLink]="[]" (click)="openArtificialUser(message.user._id)">
                {{ message.user?.displayName || 'Unknown User' }}
              </a>
              <span *ngIf="message.user?.userType != 'ArtificialUser' && !message.user._id">
                {{ message.user?.displayName || 'Unknown User' }}
              </span>
              <p-overlayPanel [dismissable]="true" #userDetails appendTo="body" (click)="userDetails.toggle($event)">
                <ng-template pTemplate>
                  <c-user-details [userType]="'USER'" [user]="message.user?._id"></c-user-details>
                </ng-template>
              </p-overlayPanel>
            </span>
            <span
              *ngIf="
                ((message.messageType !== 'PollMessage' && !message.user) || (message.messageType !== 'PollMessage' && !message.user['userType'])) &&
                (message.data?.trackingId || message.user['trackingId']) as trackingId
              "
              class="user p-mr-1"
              (mouseenter)="userDetails.show($event)"
              (mouseleave)="userDetails.hide()"
            >
              <span>{{ showSkippedTrackingId(trackingId) }}</span>
              <p-overlayPanel [dismissable]="true" #userDetails appendTo="body" (click)="userDetails.toggle($event)">
                <ng-template pTemplate>
                  <div>
                    <div>{{ trackingId }}</div>
                  </div>
                  <div>
                    <div><small>Tracking-ID of not logged in User</small></div>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </span>

            <span class="date p-mr">{{ message.date | date : 'short' }}</span>
          </div>

          <div class="reply-container" *ngIf="message?.replyTo">
            <div class="message">
              <span *ngIf="message.originalMessage.messageType === 'TextMessage'" class="user">{{ message.originalMessage.user?.displayName || 'Unknown User' }}</span>
              <span *ngIf="message.originalMessage.messageType === 'PollMessage'" class="user">
                <i class="pi pi-chart-bar p-mr-1"></i>
                {{ 'PAGE_LIVE_CHAT_POLLS_REQUEST' | translate }}
              </span>
              <span class="date">{{ (message.originalMessage.date | date : 'short') || '-' }}</span>
              <div *ngIf="message.originalMessage.messageType === 'TextMessage'" class="text">{{ message.originalMessage.data.text || 'Message Deleted' }}</div>
            </div>
          </div>
          <div class="text" *ngIf="message.messageType === 'TextMessage'">
            {{ message.data.text }}
          </div>
          <div class="quotedMessage" *ngIf="(message | fn : asTextMessage).relatedMessage">
            {{ 'PAGE_LIVE_CHAT_RELATED_MESSAGE' | translate }}: {{ asTextMessage(message).relatedMessage.data.text }}
          </div>

          <div class="text" *ngIf="message.messageType === 'PollMessage' && asPollMessage(message) as pollMessage">
            <c-poll-viewer [data]="pollMessage.data" [bcp]="false"></c-poll-viewer>
          </div>

          <div class="messageActions p-d-flex p-jc-end">
            <div *ngIf="message | fn : asPollMessage as pollMessage">
              <button pButton pRipple type="button" label="Show Result" class="p-button-success p-button-text" (click)="showPollResult(pollMessage)"></button>
              <button
                *ngIf="!pollMessage.data.poll.publishedResultAt"
                pButton
                pRipple
                type="button"
                [label]="'PAGE_LIVE_CHAT_PUBLISH_RESULT' | translate"
                class="p-button-success p-button-text"
                (click)="confirmPublishPoll($event, pollMessage)"
              ></button>
              <button
                *ngIf="!pollMessage.data.poll.closedAt"
                pButton
                pRipple
                type="button"
                [label]="'PAGE_LIVE_CHAT_CLOSE_POLL' | translate"
                class="p-button-success p-button-text"
                (click)="confirmClosePoll($event, pollMessage)"
              ></button>
            </div>
            <button pButton pRipple type="button" [label]="'PAGE_LIVE_CHAT_REPLY' | translate" class="p-button-success p-button-text" (click)="reply(message)"></button>
            <button pButton pRipple type="button" [label]="'PAGE_LIVE_CHAT_DELETE' | translate" class="p-button-danger p-button-text" (click)="confirmDeleteMessage($event, message)"></button>
          </div>
        </div>
      </div>
      <p-confirmPopup [key]="'chatConfirmPopup'"></p-confirmPopup>
    </div>
  </div>
</div>
