import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ProductsService } from 'src/app/services/products/products.service';
import { IFilterList } from 'src/common/api/interfaces';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Product } from 'src/common/entities/Product';

@Component({
  selector: 'c-product-select-search',
  templateUrl: './product-select-search.component.html',
  styleUrls: ['./product-select-search.component.scss'],
})
export class ProductSelectSearchComponent implements OnInit, OnDestroy {
  @Output()
  productSelected: EventEmitter<Product> = new EventEmitter<Product>();

  @Input()
  eventVersion: EventVersion;
  subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.subscriptions.push(
      this.searchTextSubject
        .pipe(
          debounceTime(500),
          switchMap(async (searchText) => this.executeSearch(searchText, true))
        )
        .subscribe()
    );
    this.executeSearch('', true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  searchTextSubject: Subject<string> = new Subject<string>();
  currentPage = 0;
  productsPerPage = 10;
  searchText: string;
  searchResult: Product[] = [];

  constructor(private productsService: ProductsService) {}

  searchTextKeyUp(): void {
    this.searchTextSubject.next(this.searchText);
  }

  selectProduct(product: Product) {
    this.productSelected.emit(product);
  }

  executeSearch(searchText?: string, clear = false): void {
    if (clear) {
      this.searchResult = [];
      this.currentPage = 0;
    }
    const filter: IFilterList = {
      internalName: {
        matchMode: 'contains',
        value: searchText,
        caseInsensitive: true,
      },
      deletedBy: null,
    };
    this.productsService
      .getProducts({
        limit: this.productsPerPage,
        skip: this.productsPerPage * this.currentPage++,
        ...(filter ? { filter } : {}),
      })
      .then((r) => {
        r.items.forEach((e) => {
          this.searchResult.push(e);
        });
      });
  }
}
