import { SessionWithEvent } from './Session';

export const streamDistributorTypes = ['MediaServices', 'Bitmovin'] as const;
export type StreamDistributorType = (typeof streamDistributorTypes)[number];

export const streamStatus = ['CREATED', 'DELETED', 'ACTIVATED', 'DEACTIVATED', 'STARTED', 'STOPPED', 'STANDBY'] as const;
export type StreamStatus = (typeof streamStatus)[number];

export type StreamWithSessions = {
  sessions: SessionWithEvent[];
} & Stream;

export interface Endpoint {
  protocol: string;
  url: string;
  streamKey?: string;
}

export interface Stream {
  _id: string;
  liveEventId: string;
  internalName: string;
  ingestUrls: Endpoint[];
  streamingUrls: string[];
  previewUrls: Endpoint[];
  streamDistributor?: StreamDistributor;
  status: StreamStatus;
  history: {
    date: string;
    adminUser: string;
    action: StreamStatus;
    error: any;
  }[];
  deletedAt: string;
  deletedBy: string;
  VAInputStart?: string | Date;
  VAInputEnd?: string | Date;
  vodAsset?: string;

  // vod conversion by media service
  vodAssetConversion?: {
    startedAt: string | Date;
    finishedAt?: string | Date;
    startedBy: string;
    asset?: string;
  };
}

export interface StreamDistributor {
  _id: string;
  streamDistributorType: StreamDistributorType;
  plain: any;
}

export function streamStatusChangeAllowed(oldStreamStatus: StreamStatus, newStreamStatus: StreamStatus): boolean {
  switch (newStreamStatus) {
    case 'CREATED':
      return true;
    case 'ACTIVATED':
      return oldStreamStatus === 'CREATED' || oldStreamStatus === 'DEACTIVATED';
    case 'STANDBY':
      return oldStreamStatus === 'ACTIVATED' || oldStreamStatus === 'STOPPED';
    case 'STARTED':
      return oldStreamStatus === 'ACTIVATED' || oldStreamStatus === 'STANDBY' || oldStreamStatus === 'STOPPED';
    case 'STOPPED':
      return oldStreamStatus === 'STANDBY' || oldStreamStatus === 'STARTED';
    case 'DEACTIVATED':
      return oldStreamStatus === 'STOPPED' || oldStreamStatus === 'ACTIVATED';
    case 'DELETED':
      return oldStreamStatus === 'DEACTIVATED';
  }
}
