import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { StaticContent } from './../entities/StaticContent';
import { StaticContentConfiguration } from '../entities/StaticContentConfiguration';

export class StaticContentFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createStaticContent(values?: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      shortId: (await values?.shortId) || '',
      domainCollection: values?.domainCollection || null,
      local: await this.local(values, async v => this.createStaticContentLocal(v))
    } as StaticContent;
  }

  async createStaticContentLocal(values?: any) {
    return {
      parts: values?.parts || {}
    };
  }

  async createStaticContentLocalFromConfiguration(configuration: StaticContentConfiguration) {
    let parts: { [part: string]: any } = {};

    for (const key in configuration.parts) {
      parts[key] = '';
    }

    return {
      parts: parts || {}
    };
  }
}
