import { EventStage } from './EventStage';

export interface StreamEventStageLocal {
  smallHeadline: string;
  title: string;
}

export interface StreamEventStage extends EventStage {
  eventStageType: 'Stream';

  local: { [language: string]: StreamEventStageLocal };
}

export function isStreamEventStage(object: any): object is StreamEventStage {
  return object?.eventStageType === 'Stream';
}
