import { TitleEventStagePageModuleAttributes } from './../../entities/pagemodules/TitleEventStagePageModule';
import { HeadlineType } from './../../entities/Attributes';
import { ContentAreaHeightDefaultBreakpointValue, CTAAreaHeightDefaultBreakpointValue, TopAreaHeightDefaultBreakpointValue } from './DefaultBreakponitValue';
import { BreakpointValue } from './../../entities/Breakpoint';
import { TitleEventStagePageModule, TitleEventStagePageModuleLocal } from '../../entities/PageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class TitleEventStagePageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async titleEventStagePageModuleLocal(values?: any): Promise<TitleEventStagePageModuleLocal> {
    return {
      title: values?.title || '',
      title_attr: this.titleEventStagePageModuleAttributes(values?.title_attr),
      icon: values?.icon || '',
      background: values?.background || null,
    };
  }

  public titleEventStagePageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): TitleEventStagePageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async titleEventStagePageModuleButton(values?: any) {
    return {
      _id: await this.id(values?._id),
      ctaButton: values?.ctaButton || null,
    };
  }

  public titleEventStagePageModuleAreaHeight(defaultValues: BreakpointValue<number>, values?: any) {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge,
    };
  }

  public async titleEventStagePageModule(values: any): Promise<TitleEventStagePageModule> {
    if (values?.background) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.background = item.background || values.background;
      });
    }

    const pageModule = {
      ...(await this.baseValues(values)),
      pageModuleType: 'TitleEventStage',
      background: undefined,
      showLiveButton: values?.showLiveButton || false,
      ctalist: values?.ctalist || [],
      ctalistInteraction: values?.ctalistInteraction || [],
      local: await this.local(values, (val) => this.titleEventStagePageModuleLocal(val)),
      ctaAreaHeight: values?.ctaAreaHeight ? values?.ctaAreaHeight : this.titleEventStagePageModuleAreaHeight(CTAAreaHeightDefaultBreakpointValue, values?.ctaAreaHeight),
      contentAreaHeight: values?.ctaAreaHeight ? values?.ctaAreaHeight : this.titleEventStagePageModuleAreaHeight(ContentAreaHeightDefaultBreakpointValue, values?.ctaAreaHeight),
      topAreaHeight: values?.topAreaHeight ? values?.topAreaHeight : this.titleEventStagePageModuleAreaHeight(TopAreaHeightDefaultBreakpointValue, values?.topAreaHeight),
      iconStyle: values?.iconStyle || 'circle',
    };

    return { ...pageModule, pageModuleType: 'TitleEventStage' };
  }
}
