<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_EVENT_SECTION_MAPS' | translate }}
    <div class="actions">
      <button *hasRight="'events.editContent'" pButton type="button" class="p-button" [label]="'GENERAL_ADD' | translate" icon="pi icon-ic-add-item" (click)="showMapSelect = true"></button>
      <c-table-options #to></c-table-options>
    </div>
  </div>

  <c-table #table [data]="eventVersion.eventMaps" [options]="customFieldsTableOptions" [singleTemplate]="true" [tableOptionsComponent]="to">
    <ng-template let-map let-i="index">
      <tr class="table-row">
        <td>{{ (map | maps | async)?.internalName }}</td>
        <td class="actions table-row-item">
          <div class="buttons">
            <p-button *hasRight="'events.editContent'" [label]="'Remove'" (click)="deleteMap(i); $event.stopPropagation()"></p-button>
          </div>
        </td>
      </tr>
    </ng-template>
  </c-table>
</div>

<c-sidebar [noScroll]="true" [header]="'PAGE_EVENT_SECTION_MAPS_ADD' | translate" [(visible)]="showMapSelect">
  <div *ngIf="searchResult$ | async" class="sidebar-search">
    <div class="search-input input-group compact">
      <div class="input">
        <input [placeholder]="'Search ...'" pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
      </div>
    </div>
    <div infinite-scroll [scrollWindow]="false" (scrolled)="executeSearch()" class="search-results">
      <div *ngFor="let map of searchResult$ | async" (click)="addMap(map)">
        <div [title]="'PAGE_CUSTOM_FIELD_TYPE_' | translate" class="map-item-wrapper">
          <div class="map-item">
            <div class="map-info">
              <h1>{{ map.internalName }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</c-sidebar>
