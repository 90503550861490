export interface PluginsConfiguration {
  gtm: GTMConfiguration;
  gtm2: GTMConfiguration;
  usercentrics: UsercentricsConfiguration;
  usercentricsV2: UsercentricsV2Configuration;
  externalConsent: ExternalConsent;
  sourcepoint: SourcePointConfiguration;
  facebookPixel: FacebookPixelConfiguration;
  dmgCoreNavigation: DMGCoreNavigationConfiguration;
}

export interface PluginConfig {
  gtm: GTMConfiguration;
  gtm2: GTMConfiguration;
  usercentrics: UsercentricsConfiguration;
  usercentricsV2: UsercentricsV2Configuration;
  externalConsent: ExternalConsent;
  sourcepoint: SourcePointConfiguration;
  facebookPixel: FacebookPixelConfiguration;
  dmgCoreNavigation: DMGCoreNavigationConfiguration;
}

export interface Plugin {
  enabled: boolean;
}

export interface GTMBasicConfiguration {
  id?: string | null;
  scriptTagType?: string | null;
  additionalDatalayerEvents?: boolean;
}

export interface GTMConfiguration extends GTMBasicConfiguration, Plugin {
  app?: GTMBasicConfiguration;
}

export interface UsercentricsConfiguration extends Plugin {
  id?: string | null;
  scriptUrl?: string | null;
}

export interface UsercentricsV2BasicConfiguration {
  id?: string | null;
  analyticsServiceName?: string | null;
  analyticsServiceId?: string | null;
  scriptUrl?: string | null;
  onConsentEventName?: string | null;
  crossDomainConsent?: string | null;
}

export interface UsercentricsV2Configuration extends UsercentricsV2BasicConfiguration, Plugin {
  app?: UsercentricsV2BasicConfiguration;
}

export interface ExternalConsent extends Plugin {
  consentParameter?: string | null;
  redirectUrl?: string | null;
}

export interface SourcePointConfiguration extends Plugin {
  id?: string | null;
  baseEndpoint?: string | null;
  targetingParams: Record<string, string | number | boolean>;
  analyticsServiceId?: string | null;
}

export interface FacebookPixelConfiguration extends Plugin {
  id?: string | null;
}

export type DMGCoreNavigationType = 'event' | 'media';

export interface DMGCoreNavigationConfiguration extends Plugin {
  type: DMGCoreNavigationType;
}
