import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { ConfirmationService } from 'primeng/api';

import { Event } from 'src/common/entities/Event';
import { EventSlot, EventTimeSlot } from 'src/common/entities/EventSlot';

import { UtilsService } from 'src/app/services/utils/utils.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { EventsService } from 'src/app/services/events/events.service';

import { ExtendedEventSlot, ExtendedEventTimeSlot } from 'src/common/api/v1/events/GetEventSlots';

import { TableOptions } from 'src/app/components/table/table.interfaces';
import { VIPTicket } from 'src/common/entities/VIPTicket';
import { TableComponent } from '../../../components/table/table.component';
import { EventTicket } from '../../../../common/entities/EventTicket';

@Component({
  selector: 'app-live-ticketslots',
  templateUrl: './live-ticketslots.component.html',
  styleUrls: ['./live-ticketslots.component.scss'],
  providers: [ConfirmationService],
})
export class LiveTicketSlotsComponent implements OnInit {
  @Input()
  event: Event = null;

  extendedEventSlots: ExtendedEventSlot[] = null;

  editableEventSlot: EventSlot = null;
  extendedEventSlot: ExtendedEventSlot = null;
  editableEventSlotOriginal: string = null;

  editableEventTimeSlot: EventTimeSlot = null;
  extendedEventTimeSlot: ExtendedEventTimeSlot = null;
  editableEventTimeSlotOriginal: string = null;

  showSlotRegistrationDialog: boolean = false;
  showNewEventRegistrationDialog: boolean = false;

  @ViewChild('vipTable') vipTable: TableComponent<VIPTicket>;

  loading: boolean = true;
  saving: boolean = false;

  expanded: { [key: string]: boolean } = {};

  eventTicketSlotsTableOptions: TableOptions<ExtendedEventSlot> = {
    columns: [
      { header: '', visible: 'fixed' }, // for row expansion control
      { header: 'PAGE_EVENT_SECTION_SLOTS_TICKETS', visible: 'fixed' },
      { header: 'PAGE_EVENT_SECTION_SLOTS_STARTAT', visible: 'fixed', sort: { type: 'date', property: 'startAt' } },
      { header: 'PAGE_EVENT_SECTION_SLOTS_ENDAT', visible: 'fixed', sort: { type: 'date', property: 'endAt' } },
      { header: 'PAGE_EVENT_SECTION_SLOTS_CAPACITY', visible: 'fixed', sort: { type: 'number', property: 'capacity' } },
      { header: 'PAGE_EVENT_SECTION_SLOTS_WARNINGCAPACITY', visible: 'fixed', sort: { type: 'number', property: 'warningCapacity' } },
      { header: 'PAGE_LIVE_TICKET_SLOT_MANAGEMENT_USEDCAPACITY', visible: 'fixed', sort: { type: 'number', property: 'usedCapacity' } },
      { header: '', visible: 'fixed' }, // for row expansion control
    ],
  };

  expandedVipTicket: { [vipTicketId: string]: boolean } = {};
  eventTicketSlotsVipTableOptions: TableOptions<VIPTicket> = {
    columns: [
      { header: '' },
      { header: 'Email', visible: 'fixed' },
      { header: 'Last Name', visible: 'fixed' },
      { header: 'First Name', visible: 'fixed' },
      { header: 'PAGE_LIVE_TICKET_SLOT_MANAGEMENT_VIP_TICKET', visible: 'fixed' },
      { header: 'PAGE_LIVE_TICKET_SLOT_MANAGEMENT_BOOKED_TICKET', visible: 'fixed' },
      { header: '', visible: 'fixed' }, // for row delete control
    ],
  };

  constructor(public utilsService: UtilsService, private validatorService: ValidatorService, private eventsService: EventsService) {}

  ngOnInit(): void {
    this.retrieveEventSlots();

    // this.eventsService.getEventRegistrations(this.event._id).then(result => {
    //   result.items.forEach(er => {
    //     const userId: string = (er.user as User)?._id;
    //     if(userId) {
    //       this.regularBookingsOfUsers[userId] = (er.registrationData as EventRegistrationData[])
    //         .map(rd => rd.eventTicket as EventTicket)
    //         .filter(et => !et.deletedAt);
    //     }
    //   });
    // })
  }

  async retrieveEventSlots() {
    this.loading = true;
    try {
      // Get event ticket slot registrations
      const result = await this.eventsService.getEventSlots(this.event._id);
      this.extendedEventSlots = result.eventSlots;
    } catch (err) {
      throw err;
    }
    this.loading = false;
  }

  retrieveVips(query) {
    query.result = this.eventsService.getVipTickets(this.event._id, query.query);
  }

  async deleteVipTicket(vipTicket: VIPTicket) {
    this.loading = true;
    try {
      // Delete VIPTicket
      await this.eventsService.deleteVipTicket(this.event._id, vipTicket);
    } catch (err) {
      throw err;
    }
    this.vipTable.refresh(true);
    this.loading = false;
  }

  async saveEventSlot() {
    this.closeEventSlotDialog();
    this.saving = true;
    try {
      await this.eventsService.updateEventSlot(this.event._id, this.event.currentEventVersion.eventSlots);
      // Refresh table to show changes
      this.retrieveEventSlots();
    } catch (err) {
      throw err;
    }
    this.saving = false;
  }

  async openEventSlotDialog(extendedEventSlot: ExtendedEventSlot, extendedEventTimeSlot: ExtendedEventTimeSlot) {
    this.loading = true;
    try {
      // Get actual event setup
      this.event = await this.eventsService.getEvent(this.event._id);
    } catch (err) {
      throw err;
    }
    this.loading = false;

    // Get neccessary data for dialog
    if (!extendedEventTimeSlot) {
      this.extendedEventSlot = extendedEventSlot;
      this.editableEventSlot = this.event.currentEventVersion.eventSlots.find((realEventSlot) => realEventSlot._id === extendedEventSlot._id);
      this.editableEventSlotOriginal = JSON.stringify(this.editableEventSlot);
      this.extendedEventTimeSlot = null;
      this.editableEventTimeSlot = null;
      this.editableEventTimeSlotOriginal = null;
    } else {
      this.extendedEventTimeSlot = extendedEventTimeSlot;
      this.editableEventSlot = this.event.currentEventVersion.eventSlots.find((realEventSlot) => realEventSlot._id === extendedEventSlot._id);
      this.editableEventTimeSlot = this.editableEventSlot.eventTimeSlots.find((realEventTimeSlot) => realEventTimeSlot._id === extendedEventTimeSlot._id);
      this.editableEventTimeSlotOriginal = JSON.stringify(this.editableEventTimeSlot);
      this.extendedEventSlot = null;
      this.editableEventSlot = null;
      this.editableEventSlotOriginal = null;
    }
    // Open dialog
    this.showSlotRegistrationDialog = true;
  }

  closeEventSlotDialog() {
    // Hide dialog
    this.showSlotRegistrationDialog = false;
    // Clean up
    this.extendedEventSlot = null;
    this.editableEventSlot = null;
    this.editableEventSlotOriginal = null;

    this.extendedEventTimeSlot = null;
    this.editableEventTimeSlot = null;
    this.editableEventTimeSlotOriginal = null;
  }

  getTicketInfo(id: string) {
    const ticket = this.event.currentEventVersion.eventTickets.find((ticket) => ticket._id === id);
    if (ticket) {
      return ticket.internalName;
    }
    return null;
  }

  getRegularBookedTicketInfo(eventTicket: EventTicket, vipTicket: VIPTicket, showFirstAsDefault = false) {
    if (eventTicket) {
      return eventTicket.internalName;
    } else if (showFirstAsDefault && vipTicket.bookedTickets?.length) {
      return vipTicket.bookedTickets.find((t) => t._id == vipTicket.eventTicket)?.internalName || vipTicket.bookedTickets[0].internalName;
    }
    return null;
  }

  getCountOfRegularBookedWithTicketId(vipTicket: VIPTicket, differentTicketId = false) {
    return vipTicket?.bookedTickets?.filter((t) => (t._id == vipTicket.eventTicket) !== differentTicketId).length || 0;
  }

  isEditableChanged() {
    if (!this.extendedEventTimeSlot) {
      return this.editableEventSlotOriginal !== JSON.stringify(this.editableEventSlot);
    } else {
      return this.editableEventTimeSlotOriginal !== JSON.stringify(this.editableEventTimeSlot);
    }
  }

  isValidEventSlot(): boolean {
    return true;
    // return this.validatorService.isValid(eventSlotValidator, this.editableEventSlot);
  }

  async download() {
    if (this.vipTable.lastQuery) {
      await this.eventsService.downloadVipTickets(this.event._id, {
        ...this.vipTable.lastQuery,
        limit: 9999999,
        skip: 0,
      });
    }
  }

  filterBookingDistinctTicketId(vipTicket: VIPTicket) {
    const firstIdxOfVipTicket = vipTicket.bookedTickets.findIndex((t) => t._id == vipTicket.eventTicket);
    return vipTicket.bookedTickets?.filter((t, i) => i !== firstIdxOfVipTicket);
  }
}
