import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';

import { StaticContent } from 'src/common/entities/StaticContent';
import { GetStaticContentQuery, GetStaticContentResponse } from 'src/common/api/v1/configuration/staticContents/GetStaticContent';
import { PostStaticContentBody, PostStaticContentQuery, PostStaticContentResponse } from 'src/common/api/v1/configuration/staticContents/PostStaticContent';
import { PostStaticContentsBody, PostStaticContentsQuery, PostStaticContentsResponse } from 'src/common/api/v1/configuration/staticContents/PostStaticContents';
import { GetStaticContentsQuery, GetStaticContentsResponse } from 'src/common/api/v1/configuration/staticContents/GetStaticContents';

@Injectable({
  providedIn: 'root',
})
export class StaticContentsService {
  constructor(private apiService: ApiService) {}

  getStaticContent(shortId: string, domainCollectionId: string): Promise<StaticContent> {
    return this.apiService.get<GetStaticContentQuery, GetStaticContentResponse>(`/api/v1/configuration/static-contents/${shortId}`, { domainCollectionId: domainCollectionId }).toPromise();
  }

  getStaticContents(shortId?: string): Promise<GetStaticContentsResponse> {
    return this.apiService.get<GetStaticContentsQuery, GetStaticContentsResponse>(`/api/v1/configuration/static-contents`, { shortId: shortId }).toPromise();
  }

  createStaticContent(staticContent: StaticContent): Promise<StaticContent> {
    return this.apiService
      .post<PostStaticContentsQuery, PostStaticContentsBody, PostStaticContentsResponse>(`/api/v1/configuration/static-contents/${staticContent.shortId}`, staticContent)
      .toPromise();
  }

  updateStaticContent(staticContent: StaticContent): Promise<StaticContent> {
    return this.apiService.post<PostStaticContentQuery, PostStaticContentBody, PostStaticContentResponse>(`/api/v1/configuration/static-contents/${staticContent.shortId}`, staticContent).toPromise();
  }
}
