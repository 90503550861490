<div *ngIf="multi && !readonly">
  <p-autoComplete
    [(ngModel)]="users"
    (ngModelChange)="changed()"
    [suggestions]="filteredUserIds"
    (completeMethod)="filterUsers($event)"
    field="displayName"
    [dropdown]="true"
    [disabled]="disabled"
    [multiple]="true"
    styleClass="auto"
    [completeOnFocus]="true"
    (onSelect)="onSelectMulti($event)"
  >
    <!-- [forceSelection]="true" (onSelect)="onSelect($event)" -->
    -->
    <ng-template let-userId pTemplate="selectedItem">
      <div *ngIf="userType === 'USER'">
        <span *ngIf="userId | user | async as user">
          {{ user.displayName }}
          <span *ngIf="user.organization"
            ><small>({{ user.organization }})</small></span
          >
        </span>
      </div>
      <div *ngIf="userType === 'ADMIN_USER'">
        <div *ngIf="userId | adminUser | async as user">
          {{ user.displayName }}
          <span
            ><small>({{ user.email }})</small></span
          >
        </div>
      </div>
      <div *ngIf="userType === 'REMOTE_ADMIN_USER'">
        <div *ngIf="tmpRemoteUsers[userId] as user">
          {{ user.displayName }}
          <span
            ><small>({{ user.email }})</small></span
          >
        </div>
        <div *ngIf="!tmpRemoteUsers[userId] && (userId | adminUser | async) as user">
          {{ user.displayName }}
          <span
            ><small>({{ user.email }})</small></span
          >
        </div>
      </div>
    </ng-template>
    <ng-template let-userId pTemplate="item">
      <div *ngIf="filteredUsers[userId] as user">
        <div>{{ user.displayName }}</div>
        <div>
          <small>{{ user.email }}</small
          ><span *ngIf="user.organization"
            ><small> | {{ user.organization }}</small></span
          >
        </div>
      </div>
    </ng-template>
  </p-autoComplete>
  <!-- <button *ngIf="multi" pButton type="button" [disabled]="!currentUser" [label]="' ' + labelAddUser" icon="pi pi-user-plus" class="p-button p-ml-2" (click)="addCurrentUser()"></button> -->
</div>

<div *ngIf="!multi && !readonly" class="single-pick">
  <p-autoComplete
    [(ngModel)]="currentUser"
    [suggestions]="filteredUsersAsArray()"
    (completeMethod)="filterUsers($event)"
    field="displayName"
    (onSelect)="onSelect($event)"
    [dropdown]="true"
    class="auto"
    [disabled]="disabled"
  >
    <!-- [forceSelection]="true" (onSelect)="onSelect($event)" -->
    -->
    <ng-template let-user pTemplate="item">
      <div>{{ user.displayName }}</div>
      <div>
        <small>{{ user.email }}</small
        ><span *ngIf="user.organization"
          ><small> | {{ user.organization }}</small></span
        >
      </div>
    </ng-template>
  </p-autoComplete>
  <span *ngIf="showClear && !readonly && !disabled && user" class="clear auto">
    <i class="pi pi-times" (click)="clear()"></i>
  </span>
  <!-- <button *ngIf="multi" pButton type="button" [disabled]="!currentUser" [label]="' ' + labelAddUser" icon="pi pi-user-plus" class="p-button p-ml-2" (click)="addCurrentUser()"></button> -->
</div>

<!-- <p-divider *ngIf="!readonly && users?.length > 0"></p-divider> -->

<div *ngIf="multi && readonly">
  <div *ngIf="userType == 'USER'">
    <p-chip *ngFor="let user of users" [label]="(user | user | async)?.displayName" icon="pi pi-id-card" (click)="showUserDetails(user); userDetails.toggle($event)"></p-chip>
  </div>
  <div *ngIf="userType != 'USER'">
    <p-chip *ngFor="let user of users" [label]="(user | adminUser | async)?.displayName" icon="pi pi-id-card" (click)="showUserDetails(user); userDetails.toggle($event)"></p-chip>
  </div>
</div>
<div *ngIf="!multi && readonly">
  <div *ngIf="userType == 'USER'">{{ (user | user | async)?.displayName }}</div>
  <div *ngIf="userType != 'USER'">{{ (user | adminUser | async)?.displayName }}</div>
</div>
<p-overlayPanel #userDetails appendTo="body">
  <ng-template pTemplate>
    <div *ngIf="userType == 'USER'">
      <a *hasRight="'users.edit'" [routerLink]="['/users/' + currentUserForDetails._id]">{{ currentUserForDetails.displayName }}</a>
      <span *hasNoRight="'users.edit'">{{ currentUserForDetails.displayName }}</span>
    </div>
    <div *ngIf="userType != 'USER'">{{ currentUserForDetails.displayName }}</div>
    <div *ngIf="currentUserForDetails.email">
      <small>{{ 'PAGE_USER_EMAIL' | translate }}: {{ currentUserForDetails.email }}</small>
    </div>
    <div *ngIf="currentUserForDetails.organization">
      <small>{{ 'PAGE_USER_ORGANIZATION' | translate }}: {{ currentUserForDetails.organization }}</small>
    </div>
    <div *ngIf="userType == 'USER'">
      <small>{{ 'PAGE_USER_SALESMANAGER' | translate }}: {{ (currentUserForDetails.salesManager | adminUser | async)?.displayName }}</small>
    </div>
  </ng-template>
</p-overlayPanel>
