<c-sidebar-header [headerTitle]="'GENERAL_EDIT_PAGE'" [headerSubTitle]="page?.path || '[main]'" (onClose)="onClose.emit(true)"></c-sidebar-header>

<div class="sidebar-tabs">
  <button [class.active]="tab === 'general'" (click)="tab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
  <button [class.active]="tab === 'seo'" (click)="tab = 'seo'">{{ 'GENERAL_SEO' | translate }}</button>
  <button [class.active]="tab === 'design'" (click)="tab = 'design'">{{ 'GENERAL_DESIGN' | translate }}</button>
  <button [class.active]="tab === 'conditions'" (click)="tab = 'conditions'">{{ 'GENERAL_CONDITIONS' | translate }}</button>
  <button [class.active]="tab === 'linkpreview'" (click)="tab = 'linkpreview'">{{ 'GENERAL_LINK_PREVIEW' | translate }}</button>
  <button [class.active]="tab === 'onboarding'" (click)="tab = 'onboarding'">{{ 'GENERAL_ONBOARDING' | translate }}</button>
</div>

<c-loading *ngIf="loading"></c-loading>

<div *ngIf="!loading" class="sidebar-body">
  <div *ngIf="tab === 'general'">
    <div *ngIf="eventVersion">
      <c-page-patch-input (onValueChanged)="pageTypeChange()" [jsonpath]="[jsonpath, '$.pageType']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
      <c-page-patch-input [jsonpath]="[jsonpath, '$.path']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    </div>
    <c-page-patch-input [jsonpath]="[jsonpath, '$.showInMenu']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    <c-page-patch-input [jsonpath]="[jsonpath, '$.sitemap']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    <c-page-patch-input [jsonpath]="[jsonpath, '$.local.$language.navigationTitle']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    <c-page-patch-input [jsonpath]="[jsonpath, '$.displayOption']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    <c-page-patch-input [jsonpath]="[jsonpath, '$.contentChecklistActive']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    <c-page-patch-input [jsonpath]="[jsonpath, '$.featuredEvents']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    <div *ngIf="page?.pageType === 'ProfilePage'">
      <c-page-patch-input [jsonpath]="[jsonpath, '$.profileType']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
      <c-page-patch-input [jsonpath]="[jsonpath, '$.showTime']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
      <c-page-patch-input [jsonpath]="[jsonpath, '$.showSummary']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
      <c-page-patch-input [jsonpath]="[jsonpath, '$.stageImage']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    </div>
  </div>

  <div *ngIf="tab === 'seo'">
    <c-page-patch-input [jsonpath]="[jsonpath, '$.local.$language.title']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    <c-page-patch-input [jsonpath]="[jsonpath, '$.local.$language.metaDescription']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  </div>

  <div *ngIf="tab === 'design'">
    <div *ngIf="page?.pageType === 'ProfilePage' || page?.pageType === 'KeytopicsPage'">
      <c-page-patch-input [jsonpath]="[jsonpath, '$.stageBackgroundTheme']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    </div>
  </div>

  <div *ngIf="tab === 'conditions'">
    <div *ngIf="page.default" class="message"><p-message severity="warn" text='Page is currently marked as "Default". Conditions can not be changed and will stay on "Public"'></p-message></div>
    <c-page-patch-input
      [jsonpath]="[jsonpath, '$.readAccess']"
      [jsonpathParams]="jsonpathParams"
      [object]="eventVersion || page"
      [inputConfiguration]="readAccessInputConfiguration"
    ></c-page-patch-input>
  </div>

  <div *ngIf="tab === 'linkpreview'">
    <c-page-patch-input
      [jsonpath]="[jsonpath, '$.local.$language.linkTitle']"
      [jsonpathParams]="jsonpathParams"
      [object]="eventVersion || page"
      [inputConfiguration]="inputPropertyLinkTitle"
    ></c-page-patch-input>
    <c-page-patch-input [jsonpath]="[jsonpath, '$.previewImage']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    <c-page-patch-input
      [jsonpath]="[jsonpath, '$.local.$language.linkDescription']"
      [jsonpathParams]="jsonpathParams"
      [object]="eventVersion || page"
      [inputConfiguration]="inputPropertyLinkDescription"
    ></c-page-patch-input>
  </div>

  <div *ngIf="tab === 'onboarding'">
    <c-page-patch-input [jsonpath]="[jsonpath, '$.onboarding']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  </div>
</div>

<c-sidebar-footer [autoSaveInfo]="true" [showDeleteButton]="true" [onDeleteButton]="showDeleteConfirmation" [disableDeleteButton]="disableDelete"> </c-sidebar-footer>

<p-confirmDialog #cd [closable]="false" key="pageDeleteConfirm">
  <p-footer class="delete-confirm-footer">
    <div *ngIf="hasChildren()">
      <h4>This page has child pages!</h4>
      <p-checkbox [(ngModel)]="deleteChildren" [binary]="true" label="Delete all the child pages (recursively)?"></p-checkbox>
    </div>

    <div class="delete-confirm-buttons">
      <p-button styleClass="p-button-text p-button-md" (onClick)="cd.reject()" label="Cancel"></p-button>
      <p-button styleClass="p-button-danger p-button-md" (onClick)="cd.accept()" label="Delete"></p-button>
    </div>
  </p-footer>
</p-confirmDialog>
