<div class="container">
  <div class="error-message">
    <i class="pi pi-exclamation-circle"></i>
    <div class="title">{{ 'GENERAL_ERROR_NOT_FOUND_TITLE' | translate }}</div>
    <div class="content">
      <span>{{ 'GENERAL_ERROR_NOT_FOUND' | translate }}</span
      ><br />
      <span>{{ 'GENERAL_ERROR_CONTACT_ADMINISTRATOR' | translate }}</span>
    </div>
    <div class="actions">
      <a [href]="['/']" class="p-element p-button p-component">{{ 'GENERAL_GO_HOME' | translate }}</a>
    </div>
  </div>
</div>
