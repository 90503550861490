import { InputConfiguration } from '../../../../common/inputs/Inputs';
import { Page } from '../../../../common/entities/Page';
import { EventVersion } from '../../../../common/entities/EventVersion';
import { Component, Input, OnInit } from '@angular/core';
import { ProductList2PageModule } from 'src/common/entities/pagemodules/ProductList2PageModule';

@Component({
  selector: 'c-product-list2-page-module-edit',
  templateUrl: './product-list2-page-module-edit.component.html',
  styleUrls: ['./product-list2-page-module-edit.component.scss'],
})
export class ProductList2PageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: ProductList2PageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  tab = 'general';

  inputJsonpathes: string[] = ['$.local.$language.title', '$.titlePosition', '$.displayAs', '$.showHeadline', '$.showHeadlineGroup', '$.showHeadlineSeries', '$.ctaButton', '$.anchor', '$.action'];

  accessInputConfiguration: InputConfiguration;

  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
  }
}
