import { HeadlineType } from './../../entities/Attributes';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';
import { LoginPageModule, LoginPageModuleAttributes } from '../../entities/pagemodules/LoginPageModule';

export class LoginPageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public loginPageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): LoginPageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async createLoginPageModuleLocal(values: any) {
    return {
      title: values?.title || '',
      title_attr: this.loginPageModuleAttributes(values?.title_attr),
    };
  }

  public async loginPageModule(values?: any): Promise<LoginPageModule> {
    return {
      ...(await this.baseValues(values)),
      titlePosition: values?.titlePosition || 'centered',
      pageModuleType: 'Login',
      local: await this.local(values, (val) => this.createLoginPageModuleLocal(val)),
    };
  }
}
