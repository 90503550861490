<div class="asset-item" [ngClass]="disableDetails ? 'disable-details' : ''" *ngIf="internalAsset" (click)="openDetails()">
  <div class="asset-preview" *ngIf="asAssetWithThumbnail() as assetWithThumbnail">
    <c-asset-preview *ngIf="!assetWithThumbnail.thumbnail" [asset]="internalAsset"></c-asset-preview>
    <c-asset-preview *ngIf="assetWithThumbnail.thumbnail" [asset]="thumbnailAsset | async"></c-asset-preview>
  </div>
  <div class="asset-preview" *ngIf="!asAssetWithThumbnail()">
    <c-asset-preview [asset]="internalAsset"></c-asset-preview>
  </div>
  <div *ngIf="!disableDetails" class="asset-info">
    <h1>{{ internalAsset.internalName }}</h1>
    <div class="details">
      <div>Uploaded At: {{ internalAsset.uploadedAt | localedate }}</div>
      <div>Type: {{ internalAsset.mimeType }}</div>
      <div *ngIf="(asVideoAsset() || asDocumentAsset()) && contentLanguages()">Content languages: {{ contentLanguages() }}</div>
      <div *ngIf="internalAsset.status !== 'FINISHED'">
        Status: {{ internalAsset.status }}
        <span *ngIf="internalAsset.status === 'DOWNLOADING' && internalAsset.download"> - {{ internalAsset.download.progress | number : '0.2-2' }}% </span>
      </div>
      <div *ngIf="internalAsset.width && internalAsset.height">Dimensions: {{ internalAsset.width }}x{{ internalAsset.height }}</div>
      <div *ngIf="asVideoAsset() as videoAsset">
        <div *ngFor="let distributor of videoAsset.distributors">
          <div *ngIf="distributor.status !== 'FINISHED'">
            {{ distributor.videoAssetDistributorType }}: {{ distributor.status }}
            <span *ngIf="asMediaServiceVideoDistributor(distributor) as mediaServiceVideoDistributor"> - {{ mediaServiceVideoDistributor.progress | number : '0.2-2' }}% </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="asset-item" *ngIf="!internalAsset && assetUpload">
  <div class="asset-preview">
    <c-asset-preview></c-asset-preview>
  </div>
  <div class="asset-info">
    <h1>{{ assetUpload.internalName }}</h1>
    <div class="details">
      <div>Type: {{ assetUpload.file.type }}</div>
      <div *ngIf="assetUpload?.status?.progress">Progress: {{ assetUpload.status.progress }}%</div>
    </div>
  </div>
</div>

<div class="asset-item p-ai-center p-jc-center loading" *ngIf="!internalAsset && !assetUpload">
  <div class="asset-preview">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
