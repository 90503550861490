<ng-content></ng-content>
<div *ngIf="initialized" class="loading">
  <div>
    <!-- <p-progressSpinner></p-progressSpinner> -->
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="loading-text" *ngIf="!hideTitle">{{ title || text() | translate }} ...</div>
  </div>
</div>
