import { AccessPolicy, AccessPolicyType, EventTicketAccessPolicy, NoTicketAccessPolicy, PlatformAccessPolicy, PublicAccessPolicy, VIPTicketAccessPolicy } from '../entities/AccessPolicy';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';

export class AccessPolicyFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public async publicAccessPolicy(values: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      accessPolicyType: 'Public'
    } as PublicAccessPolicy;
  }

  public async platformAccessPolicy(values: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      accessPolicyType: 'Platform'
    } as PlatformAccessPolicy;
  }

  public async noTicketAccessPolicy(values: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      accessPolicyType: 'NoTicket',
      event: values?.event || null
    } as NoTicketAccessPolicy;
  }

  public async vipTicketAccessPolicy(values: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      accessPolicyType: 'VIPTicket',
      event: values?.event || null,
      eventTicket: values?.eventTicket || null
    } as VIPTicketAccessPolicy;
  }

  public async tagsAccessPolicy(values: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      accessPolicyType: 'Tags',
      negatePolicy: values?.negatePolicy || false,
      tags: values?.tags || []
    };
  }

  public async eventTicketAccessPolicy(values: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      accessPolicyType: 'EventTicket',
      event: values?.event || null,
      eventTicket: values?.eventTicket || null
    } as EventTicketAccessPolicy;
  }

  public async globalTicketAccessPolicy(values: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      accessPolicyType: 'GlobalTicket',
      globalTicket: values?.globalTicket || null
    } as EventTicketAccessPolicy;
  }

  public async accessPolicy(values: Omit<any, 'accessPolicyType'> & { accessPolicyType: AccessPolicyType }): Promise<AccessPolicy> {
    switch (values.accessPolicyType) {
      case 'Public':
        return { ...values, ...(await this.publicAccessPolicy(values)) };
      case 'Platform':
        return { ...values, ...(await this.platformAccessPolicy(values)) };
      case 'NoTicket':
        return { ...values, ...(await this.noTicketAccessPolicy(values)) };
      case 'EventTicket':
        return { ...values, ...(await this.eventTicketAccessPolicy(values)) };
      case 'GlobalTicket':
        return { ...values, ...(await this.globalTicketAccessPolicy(values)) };
      case 'Tags':
        return { ...values, ...(await this.tagsAccessPolicy(values)) };
      case 'VIPTicket':
        return { ...values, ...(await this.vipTicketAccessPolicy(values)) };
    }
  }
}
