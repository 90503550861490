import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { isPage } from 'src/common/entities/Page';
import { eventVersionInputs } from 'src/common/inputs/event/EventVersionInputs';
import { InputFactory, Inputs } from 'src/common/inputs/Inputs';
import { pageInputs } from 'src/common/inputs/page/PageInputs';
import * as jp from 'jsonpath';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'c-inputs-form',
  templateUrl: './inputs-form.component.html',
  styleUrls: ['./inputs-form.component.scss'],
})
export class InputsFormComponent implements OnInit, OnChanges {
  @Input()
  jsonpath: string | string[];

  @Input()
  jsonpathParams: { [key: string]: any } = {};

  @Input()
  excludedJsonpaths: string[] = [];

  @Input()
  mergeInputs: Inputs;

  @Input()
  object: any;

  @Input()
  disabled: boolean = false;

  get isDisabled(): boolean {
    return !this.collaborationKey ? true : this.collaborationService.isDisabled(this.collaborationKey);
  }

  type: 'eventversion' | 'pageversion';
  jsonpaths: string[];
  collaborationKey: string;
  inputs: Inputs;
  listInputParents: {
    [jsonpath: string]: {
      factory: InputFactory;
      children: string[];
      singleValue: boolean;
      childJsonpath: string;
      element: any;
    };
  } = {};
  isListInput: { [jsonpath: string]: boolean } = {};
  subscriptions: Subscription[] = [];
  combinedJsonpathParams: { [key: string]: any } = {};
  currentLanguage: string = null;

  constructor(private utilsService: UtilsService, private collaborationService: CollaborationService, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.currentLanguage = params.language;
        this.init();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.jsonpath?.currentValue !== changes.jsonpath?.previousValue ||
      changes.jsonpathParams?.currentValue !== changes.jsonpathParams?.previousValue ||
      changes.excludedJsonpaths?.currentValue !== changes.excludedJsonpaths?.previousValue ||
      changes.mergeInputs?.currentValue !== changes.mergeInputs?.previousValue ||
      changes.object?.currentValue !== changes.object?.previousValue ||
      changes.disabled?.currentValue !== changes.disabled?.previousValue
    ) {
      if (this.currentLanguage) this.init();
    }
  }

  async init() {
    this.type = isPage(this.object) ? 'pageversion' : 'eventversion';
    this.collaborationKey = `${this.type}:${this.object._id}`;
    this.isListInput = {};
    this.listInputParents = {};

    if (this.type === 'eventversion') {
      this.inputs = eventVersionInputs;
    } else {
      this.inputs = pageInputs;
    }

    if (this.mergeInputs) {
      for (const mergeInput of Object.keys(this.mergeInputs || {})) {
        this.inputs = {
          ...this.inputs,
          [mergeInput]: {
            ...(this.inputs[mergeInput] || {}),
            ...this.mergeInputs[mergeInput],
          },
        };
      }
    }

    const combinedJsonpath = this.utilsService.combineJsonpath(this.jsonpath);
    this.combinedJsonpathParams = {
      ...this.jsonpathParams,
      language: this.jsonpathParams.language || this.currentLanguage,
    };

    const affectedJsonpaths = Object.keys(this.inputs)
      .filter(
        (i) =>
          i === this.jsonpath ||
          `${i}.`.startsWith(`${combinedJsonpath}.`) ||
          `${i}.`.startsWith(`${combinedJsonpath}[`) ||
          `${i}[`.startsWith(`${combinedJsonpath}.`) ||
          `${i}[`.startsWith(`${combinedJsonpath}[`)
      )
      .filter((i) => !this.excludedJsonpaths.map((e) => this.utilsService.combineJsonpath([combinedJsonpath, e])).includes(i));

    for (const listInput of affectedJsonpaths.filter((j) => this.inputs[j].list)) {
      const children = affectedJsonpaths.filter((i) => `${i}[`.startsWith(`${listInput}[`)).filter((a) => !this.inputs[a].factory && a !== listInput);
      const childJsonpath = `${listInput}[$index]`;
      const factory = this.inputs[childJsonpath].factory;

      if (!factory) {
        throw new Error('Child factory not found');
      }

      await this.collaborationService.ensurePath(this.collaborationKey, this.object, listInput, this.combinedJsonpathParams, this.inputs);

      this.listInputParents[listInput] = {
        factory: factory,
        children: children,
        singleValue: children.length === 0,
        childJsonpath: childJsonpath,
        element: jp.value(this.object, this.utilsService.resolveJsonpath(listInput, this.combinedJsonpathParams)),
      };
      Object.assign(
        this.isListInput,
        children.reduce((a, b) => ({ ...a, [b]: true }), {})
      );
    }

    this.jsonpaths = affectedJsonpaths.filter((a) => !this.listInputParents[a] && !this.isListInput[a] && !this.inputs[a].factory);
  }

  trackByIndex(index: number) {
    return index;
  }

  trackByIndexForElement(index: number) {
    return index;
  }

  jsonpathParamsWithIndex(jsonpathParams: { [key: string]: any }, index: number) {
    return {
      ...jsonpathParams,
      index,
    };
  }

  async add(jsonpath: string) {
    await this.collaborationService.patch(this.collaborationKey, this.object, {
      command: 'push',
      jsonpath: jsonpath,
      jsonpathParams: this.combinedJsonpathParams,
      value: await this.listInputParents[jsonpath].factory(this.object, this.combinedJsonpathParams),
    });
  }

  async remove(jsonpath: string, index: number) {
    await this.collaborationService.patch(this.collaborationKey, this.object, {
      command: 'delete',
      jsonpath: jsonpath,
      jsonpathParams: {
        ...this.combinedJsonpathParams,
        index,
      },
    });
  }

  async up(jsonpath: string, index: number) {
    await this.collaborationService.patch(this.collaborationKey, this.object, {
      command: 'up',
      jsonpath: jsonpath,
      jsonpathParams: {
        ...this.combinedJsonpathParams,
        index,
      },
    });
  }

  async down(jsonpath: string, index: number) {
    await this.collaborationService.patch(this.collaborationKey, this.object, {
      command: 'down',
      jsonpath: jsonpath,
      jsonpathParams: {
        ...this.combinedJsonpathParams,
        index,
      },
    });
  }

  show(jsonpath: string): boolean {
    if (this.inputs[jsonpath]?.condition) {
      const parts = jp.parse(this.utilsService.resolveJsonpath(jsonpath, this.combinedJsonpathParams));
      const parentParts = parts.slice(0, parts.length - 1);
      const parentJsonpath = jp.stringify(parentParts);
      const parentValue = jp.value(this.object, parentJsonpath);

      return this.inputs[jsonpath].condition(this.object, jsonpath, this.combinedJsonpathParams, parentValue);
    } else {
      return true;
    }
  }
}
