<div class="panel">
  <div class="panel-header">
    {{ 'GENERAL_PRODUCTS' | translate }}
    <div class="actions">
      <button *hasRight="'events.editContent'" pButton type="button" class="p-button" [label]="'GENERAL_ADD' | translate" icon="pi icon-ic-add-item" (click)="showProductSelect = true"></button>
      <c-table-options #to></c-table-options>
    </div>
  </div>
  <div class="search">
    <input placeholder="Search products ..." pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
  </div>

  <c-table #table [data]="filteredEventProducts | async" [options]="productsTableOptions" [tableOptionsComponent]="to">
    <ng-template let-eventProduct>
      <a [routerLink]="[]" queryParamsHandling="merge" (click)="editProduct(eventProduct)">{{ (eventProduct.product | product | async)?.internalName }}</a>
    </ng-template>
    <ng-template let-eventProduct>{{ eventProduct.override ? eventProduct.override.local[language]?.title : (eventProduct.product | product | async)?.local[language]?.title }}</ng-template>
    <ng-template let-eventProduct>{{
      eventProduct.override ? eventProduct.override.local[language]?.description : (eventProduct.product | product | async)?.local[language]?.description
    }}</ng-template>
    <ng-template let-eventProduct>
      <i *ngIf="eventProduct.override" class="pi pi-check"></i>
      <i *ngIf="!eventProduct.override" class="pi pi-times"></i>
    </ng-template>
    <ng-template let-eventProduct let-first="first" let-last="last">
      <div *hasRight="'events.editContent'" style="cursor: pointer">
        <i *ngIf="!first" class="pi pi-sort-up p-mr-2" [pTooltip]="'GENERAL_MOVE_UP' | translate" tooltipPosition="left" (click)="productUp(eventProduct); $event.stopPropagation()"></i>
        <i
          *ngIf="!last"
          class="pi pi-sort-down"
          [ngClass]="{ 'down-padding-left': first }"
          [pTooltip]="'GENERAL_MOVE_DOWN' | translate"
          tooltipPosition="left"
          (click)="productDown(eventProduct); $event.stopPropagation()"
        ></i>
      </div>
    </ng-template>
  </c-table>
</div>

<!-- Product Edit Dialog -->
<p-sidebar *ngIf="showProductEdit" [(visible)]="showProductEdit" styleClass="p-sidebar-st" position="right" [modal]="true" [appendTo]="'body'" [showCloseIcon]="false">
  <c-sidebar-header
    [headerTitle]="'PAGE_EVENT_SECTION_PRODUCTS_EDIT' | translate"
    [headerSubTitle]="(editEventProduct.product | product | async)?.internalName"
    (onClose)="showProductEdit = false"
  ></c-sidebar-header>

  <div class="sidebar-tabs">
    <button [class.active]="productEditTab === 'general'" (click)="productEditTab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
    <button [class.active]="productEditTab === 'relatedAssets'" (click)="productEditTab = 'relatedAssets'">{{ 'PAGE_PRODUCT_RELATED_ASSETS' | translate }}</button>
    <button [class.active]="productEditTab === 'relatedProducts'" (click)="productEditTab = 'relatedProducts'">{{ 'PAGE_PRODUCT_RELATED_PRODUCTS' | translate }}</button>
    <button [class.active]="productEditTab === 'exhibitedWith'" (click)="productEditTab = 'exhibitedWith'">{{ 'PAGE_PRODUCT_EXHIBITED_WITH' | translate }}</button>
  </div>
  <div class="sidebar-body">
    <div *ngIf="productEditTab == 'general'">
      <c-collaboration-patch-input
        [object]="productEditHelper"
        jsonpath="$.override"
        [inputConfiguration]="{
          type: 'switch',
          header: 'PAGE_EVENT_SECTION_PRODUCTS_OVERRIDE',
          description: 'PAGE_EVENT_SECTION_PRODUCTS_OVERRIDE_DESCRIPTION'
        }"
        [disabled]="this.eventVersion | isDisabled"
        (onValueChanged)="overrideProduct($event, editEventProduct)"
      ></c-collaboration-patch-input>

      <div *ngIf="editEventProduct.override != null">
        <c-event-patch-input
          *ngFor="let jsonPath of generalJsonPathes"
          [eventVersion]="eventVersion"
          [jsonpath]="jsonPath"
          [inputs]="inputs"
          [jsonpathParams]="{ productId: editEventProduct.product }"
        ></c-event-patch-input>
      </div>
    </div>

    <div *ngIf="productEditTab == 'relatedAssets'">
      <c-event-patch-input
        [eventVersion]="eventVersion"
        jsonpath="$.eventProducts[?(@.product == '$productId')].override.relatedAssets"
        [jsonpathParams]="{ productId: editEventProduct.product }"
        [inputs]="inputs"
        [disabled]="editEventProduct.override == null"
      ></c-event-patch-input>
    </div>

    <div *ngIf="productEditTab == 'relatedProducts'">
      <c-event-patch-input
        [eventVersion]="eventVersion"
        jsonpath="$.eventProducts[?(@.product == '$productId')].override.relatedProducts"
        [jsonpathParams]="{ productId: editEventProduct.product }"
        [inputs]="inputs"
        [disabled]="editEventProduct.override == null"
      ></c-event-patch-input>
    </div>

    <div *ngIf="productEditTab == 'exhibitedWith'">
      <c-event-patch-input
        [eventVersion]="eventVersion"
        jsonpath="$.eventProducts[?(@.product == '$productId')].override.exhibitedWith"
        [jsonpathParams]="{ productId: editEventProduct.product }"
        [inputs]="inputs"
        [disabled]="editEventProduct.override == null"
      ></c-event-patch-input>
    </div>
  </div>

  <c-sidebar-footer
    *hasRight="'events.editContent'"
    [autoSaveInfo]="true"
    (onClose)="showProductEdit = false"
    [showDeleteButton]="true"
    (onDelete)="productDelete(editEventProduct); showProductEdit = false"
  ></c-sidebar-footer>
</p-sidebar>

<!-- Add Product Selection Dialog -->
<c-sidebar *ngIf="showProductSelect" [noScroll]="true" [(visible)]="showProductSelect" [header]="'PAGE_EVENT_SECTION_PRODUCTS_ADD' | translate" position="right">
  <div class="sidebar-search">
    <c-product-select-search [eventVersion]="eventVersion" (productSelected)="addProduct($event)"></c-product-select-search>
  </div>
</c-sidebar>
