import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AdminUsersService } from 'src/app/services/admin-users/admin-users.service';
import { UsersService } from 'src/app/services/users/users.service';
import { AdminUser } from 'src/common/entities/AdminUser';
import { ArtificialUser, asArtificialUser, asUser, isArtificialUser, isUser, User } from 'src/common/entities/User';

@Component({
  selector: 'c-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailComponent implements OnChanges {
  @Input() user: string;

  @Input() userType: 'USER' | 'ADMIN_USER';

  currentUser: User | ArtificialUser | AdminUser = null;

  constructor(private userService: UsersService, private adminUserService: AdminUsersService) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (this.user && (!this.currentUser || this.currentUser._id != this.user)) {
      this.getUserDetails(this.user);
    }
  }

  async getUserDetails(identifier: string) {
    if (this.userType == 'USER') {
      const baseUser = await this.userService.getUser(identifier);

      if (isArtificialUser(baseUser)) {
        this.currentUser = asArtificialUser(baseUser);
      } else {
        this.currentUser = asUser(baseUser);
      }
    } else {
      this.currentUser = await this.adminUserService.getAdminUser(identifier);
    }
  }

  asUser = (baseUser: any): User => {
    return isUser(baseUser) ? baseUser : null;
  };

  asArtificialUser = (baseUser: any): ArtificialUser => {
    return isArtificialUser(baseUser) ? baseUser : null;
  };
}
