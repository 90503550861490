import { PostFailedCRMEventRegistrationBody, PostFailedCRMEventRegistrationQuery, PostFailedCRMEventRegistrationResponse } from './../../../common/api/v1/events/PostFailedCRMEventRegistration';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, ReplaySubject, Subject, timer, using } from 'rxjs';
import * as jp from 'jsonpath';
import { GetEventQuery, GetEventResponse } from 'src/common/api/v1/events/GetEvent';
import { GetEventPreviewsQuery, GetEventPreviewsResponse } from 'src/common/api/v1/events/GetEventPreviews';
import { GetEventRegistrationQuery, GetEventRegistrationResponse } from 'src/common/api/v1/events/GetEventRegistration';
import { GetEventRegistrationsQuery, GetEventRegistrationsResponse } from 'src/common/api/v1/events/GetEventRegistrations';
import { GetEventsQuery, GetEventsResponse } from 'src/common/api/v1/events/GetEvents';
import { GetEventSlotsQuery, GetEventSlotsResponse } from 'src/common/api/v1/events/GetEventSlots';
import { GetFailedCRMEventRegistrationsQuery, GetFailedCRMEventRegistrationsResponse } from 'src/common/api/v1/events/GetFailedCRMEventRegistrations';
import { PostEventBody, PostEventQuery, PostEventResponse } from 'src/common/api/v1/events/PostEvent';
import { PostEventAnnounceBody, PostEventAnnounceQuery, PostEventAnnounceResponse } from 'src/common/api/v1/events/PostEventAnnounce';
import { PostEventCancelBody, PostEventCancelQuery, PostEventCancelResponse } from 'src/common/api/v1/events/PostEventCancel';
import { PostEventPreviewBody, PostEventPreviewQuery, PostEventPreviewResponse } from 'src/common/api/v1/events/PostEventPreview';
import { PostEventPreviewsBody, PostEventPreviewsQuery, PostEventPreviewsResponse } from 'src/common/api/v1/events/PostEventPreviews';
import { PostEventRegistrationBody, PostEventRegistrationQuery, PostEventRegistrationResponse } from 'src/common/api/v1/events/PostEventRegistration';
import {
  PostEventRegistrationCRMEventRegistrationRetryBody,
  PostEventRegistrationCRMEventRegistrationRetryQuery,
  PostEventRegistrationCRMEventRegistrationRetryResponse,
} from 'src/common/api/v1/events/PostEventRegistrationCRMEventRegistrationRetry';
import { PostEventRegistrationsBody, PostEventRegistrationsQuery, PostEventRegistrationsResponse } from 'src/common/api/v1/events/PostEventRegistrations';
import { PostEventRegistrationUnregisterBody, PostEventRegistrationUnregisterQuery, PostEventRegistrationUnregisterResponse } from 'src/common/api/v1/events/PostEventRegistrationUnregister';
import { PostEventReleaseBody, PostEventReleaseQuery, PostEventReleaseResponse } from 'src/common/api/v1/events/PostEventRelease';
import { PostEventsBody, PostEventsQuery, PostEventsResponse } from 'src/common/api/v1/events/PostEvents';
import { PostEventSendUpdateBody, PostEventSendUpdateQuery, PostEventSendUpdateResponse } from 'src/common/api/v1/events/PostEventSendUpdate';
import { PostEventSendUpdateSessionsBody, PostEventSendUpdateSessionsQuery, PostEventSendUpdateSessionsResponse } from 'src/common/api/v1/events/PostEventSendUpdateSessions';
import { PostEventSessionStartBody, PostEventSessionStartQuery, PostEventSessionStartResponse } from 'src/common/api/v1/events/PostEventSessionStart';
import { PostEventSessionStopBody, PostEventSessionStopQuery, PostEventSessionStopResponse } from 'src/common/api/v1/events/PostEventSessionStop';
import { PostEventSlotBody, PostEventSlotQuery, PostEventSlotResponse } from 'src/common/api/v1/events/PostEventSlot';
import { EventVersionPatch } from 'src/common/api/v1/websocket/EventVersionPatch';
import { EventVersion, eventVersionDateFields } from 'src/common/entities/EventVersion';
import { PatchExecutor } from 'src/common/patch/PatchExecutor';
import { Patch } from 'src/common/patch/Patch';
import { PostEventEventVersionPatchBody, PostEventEventVersionPatchQuery, PostEventEventVersionPatchResponse } from 'src/common/api/v1/events/PostEventEventVersionPatch';
import { GetEventEventVersionQuery, GetEventEventVersionResponse } from 'src/common/api/v1/events/GetEventEventVersion';
import { GetEventEventVersionsQuery, GetEventEventVersionsResponse } from 'src/common/api/v1/events/GetEventEventVersions';
import * as uuid from 'uuid';
import { PostEventEventVersionPublishBody, PostEventEventVersionPublishQuery, PostEventEventVersionPublishResponse } from 'src/common/api/v1/events/PostEventEventVersionPublish';
import { Event } from 'src/common/entities/Event';
import { EventRegistration } from 'src/common/entities/EventRegistration';
import { EventSlot } from 'src/common/entities/EventSlot';
import { Preview } from 'src/common/entities/Preview';
import { ApiSocketService } from '../api-socket/api-socket.service';
import { ApiService } from '../api/api.service';
import { PostEventSessionBody, PostEventSessionQuery, PostEventSessionResponse } from 'src/common/api/v1/events/PostEventSession';
import { Session } from 'src/common/entities/Session';
import { EventType } from '../../../common/entities/EventType';
import { GetEventTypesQuery, GetEventTypesResponse } from '../../../common/api/v1/events/GetEventTypes';
import { GetEventTypeQuery, GetEventTypeResponse } from '../../../common/api/v1/events/GetEventType';
import { PostEventTypeBody, PostEventTypeQuery, PostEventTypeResponse } from 'src/common/api/v1/events/PostEventType';
import { PostEventTypesBody, PostEventTypesQuery, PostEventTypesResponse } from 'src/common/api/v1/events/PostEventTypes';
import { PostEventsImportBody, PostEventsImportQuery, PostEventsImportResponse } from 'src/common/api/v1/events/PostEventsImport';
import { UtilsService } from '../utils/utils.service';
import { EventViewers } from 'src/common/entities/EventViewers';
import { mergeMap, publishReplay, refCount } from 'rxjs/operators';
import { GetEventViewersQuery, GetEventViewersResponse } from 'src/common/api/v1/events/GetEventViewers';
import { GetEventSeriesQuery, GetEventSeriesResponse } from 'src/common/api/v1/events/GetEventSeries';
import { PostEventSeriesBody, PostEventSeriesQuery, PostEventSeriesResponse } from 'src/common/api/v1/events/PostEventSeries';
import { EventSerie } from 'src/common/entities/EventSerie';
import { PostEventSerieBody, PostEventSerieQuery, PostEventSerieResponse } from 'src/common/api/v1/events/PostEventSerie';
import { GetEventSerieQuery, GetEventSerieResponse } from 'src/common/api/v1/events/GetEventSerie';
import { GetEventPreviewQuery, GetEventPreviewResponse } from 'src/common/api/v1/events/GetEventPreview';
import { HttpResponse } from '@angular/common/http';
import { CRMEvent } from 'src/common/entities/CRMEvent';
import { PostEventCRMEventBody, PostEventCRMEventQuery, PostEventCRMEventResponse } from 'src/common/api/v1/events/PostEventCRMEvent';
import { GetEventCRMEventQuery, GetEventCRMEventResponse } from 'src/common/api/v1/events/GetEventCRMEvent';
import { GetEventVipTicketsQuery, GetEventVipTicketsResponse } from 'src/common/api/v1/events/GetEventVipTickets';
import { PostEventVipTicketDeleteBody, PostEventVipTicketDeleteQuery, PostEventVipTicketDeleteResponse } from 'src/common/api/v1/events/PostEventVipTicketDelete';
import { VIPTicket } from 'src/common/entities/VIPTicket';
import { PostEventVipTicketBody, PostEventVipTicketQuery, PostEventVipTicketResponse } from 'src/common/api/v1/events/PostEventVipTicket';
import { PostEventVipTicketsBody, PostEventVipTicketsQuery, PostEventVipTicketsResponse } from 'src/common/api/v1/events/PostEventVipTickets';
import { PostEventConnectExternalEventBody, PostEventConnectExternalEventQuery, PostEventConnectExternalEventResponse } from 'src/common/api/v1/events/PostEventConnectExternalEvent';
import { PostEventSessionPublishVODBody, PostEventSessionPublishVODQuery, PostEventSessionPublishVODResponse } from 'src/common/api/v1/events/PostEventSessionPublishVOD';
import { GetEventTicketsQuery, GetEventTicketsResponse } from 'src/common/api/v1/events/GetEventTickets';
import { GetDiffQuery, GetDiffResponse } from 'src/common/api/v1/diff/GetDiff';

export enum ChangeType {
  Create,
  Update,
  Delete,
  Start,
  Stop,
}

export enum ChangeScope {
  Event,
  Preview,
  EventRegistration,
  EventSlot,
  Session,
  EventType,
  EventSerie,
}

export interface Change {
  scope: ChangeScope;
  type: ChangeType;
}

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private _events: {
    [eventId: string]: {
      subject: ReplaySubject<Event>;
      observable: Observable<Event>;
      viewersSubject: BehaviorSubject<EventViewers>;
    };
  } = {};

  private _previews: {
    [previewToken: string]: {
      subject: ReplaySubject<Preview>;
      observable: Observable<Preview>;
    };
  } = {};

  private _eventTypeCache: { [eventType: string]: Promise<EventType> } = {};
  private _eventSerieCache: { [eventSerie: string]: Promise<EventSerie> } = {};
  private _eventVersions: {
    [eventVersionId: string]: BehaviorSubject<EventVersion>;
  } = {};
  private _lastEventVersionPatch: {
    [eventVersionId: string]: ReplaySubject<EventVersionPatch>;
  } = {};

  private _change: Subject<Change> = new Subject();
  readonly change: Observable<Change> = this._change.asObservable();

  private _patches: {
    [id: string]: {
      resolve: (result: boolean) => void;
      reject: (err: any) => void;
    };
  } = {};

  private _getEventsObservable: Observable<GetEventsResponse>;

  constructor(private apiService: ApiService, private apiSocketService: ApiSocketService, private utilsService: UtilsService) {
    this.apiSocketService.on('connect', () => {
      [...new Set(Object.keys(this._events).concat(Object.values(this._eventVersions).map((v) => v.getValue().event)))].forEach((eventId) => {
        this.apiSocketService.emit('event:register', { event: eventId });
      });
    });

    this.apiSocketService.on<Event>('event:update', (event) => {
      if (this._events[event._id]) {
        this._events[event._id].subject.next(event);
      }
    });

    this.apiSocketService.on<Preview>('preview:update', (preview) => {
      if (this._previews[preview.token]) {
        this._previews[preview.token].subject.next(preview);
      }
    });

    this.apiSocketService.on<EventVersion>('eventversion:update', (eventVersion) => {
      if (this._eventVersions[eventVersion._id] && eventVersion.change >= this._eventVersions[eventVersion._id].getValue().change) {
        const previous = this._eventVersions[eventVersion._id].getValue();
        this.utilsService.replaceObject(previous, eventVersion);
        this.convertEventVersionDates(previous);
        this._eventVersions[eventVersion._id].next(previous);
      }
    });

    this.apiSocketService.on<EventVersionPatch>('eventversion:patch', async (eventVersionPatch) => {
      if (this._eventVersions[eventVersionPatch.eventVersion]) {
        const eventVersion = this._eventVersions[eventVersionPatch.eventVersion].getValue();

        try {
          if ((eventVersion.change || 0) === eventVersionPatch.change - 1) {
            if (eventVersionPatch.patch.command === 'set' && eventVersionDateFields.includes(eventVersionPatch.patch.jsonpath) && eventVersionPatch.patch.value) {
              eventVersionPatch.patch.value = new Date(eventVersionPatch.patch.value);
            }

            for (const patch of eventVersionPatch.previousPatches || []) {
              PatchExecutor.patch(eventVersion, patch);
            }

            PatchExecutor.patch(eventVersion, eventVersionPatch.patch);

            for (const patch of eventVersionPatch.subsequentPatches || []) {
              PatchExecutor.patch(eventVersion, patch);
            }

            this.convertEventVersionDates(eventVersion);
            eventVersion.change = eventVersionPatch.change;
            this._eventVersions[eventVersionPatch.eventVersion].next(eventVersion);

            if (!this._lastEventVersionPatch[eventVersionPatch.eventVersion]) {
              this._lastEventVersionPatch[eventVersionPatch.eventVersion] = new ReplaySubject(1);
            }
            this._lastEventVersionPatch[eventVersionPatch.eventVersion].next(eventVersionPatch);

            if (this._patches[eventVersionPatch.patch.id]) {
              this._patches[eventVersionPatch.patch.id].resolve(true);
              delete this._patches[eventVersionPatch.patch.id];
              return;
            }
          } else {
            await this.getEventVersion(eventVersionPatch.event, eventVersionPatch.eventVersion);
          }
        } catch (err) {
          console.error(err);
          await this.getEventVersion(eventVersionPatch.event, eventVersionPatch.eventVersion);
        }
      }

      if (this._patches[eventVersionPatch.patch.id]) {
        this._patches[eventVersionPatch.patch.id].resolve(false);
        delete this._patches[eventVersionPatch.patch.id];
      }
    });

    this.apiSocketService.on<EventViewers>('event:viewers', async (eventViewers) => {
      if (this._events[eventViewers.event]) {
        const currentValue = this._events[eventViewers.event].viewersSubject.getValue();

        if (typeof eventViewers.loggedInViewers === 'number') currentValue.loggedInViewers = eventViewers.loggedInViewers;
        if (typeof eventViewers.loggedOutViewers === 'number') currentValue.loggedOutViewers = eventViewers.loggedOutViewers;

        for (const session of eventViewers.sessions || []) {
          const index = currentValue.sessions.findIndex((s) => s.session === session.session);
          currentValue.sessions.splice(index >= 0 ? index : 0, index >= 0 ? 1 : 0, session);
        }

        this._events[eventViewers.event].viewersSubject.next(currentValue);
      }
    });
  }

  convertEventDates(event: Event) {
    this.convertEventVersionDates(event.currentEventVersion);
    return event;
  }

  convertEventVersionDates(eventVersion: EventVersion) {
    if (eventVersion) {
      eventVersionDateFields.forEach((d) => {
        const paths = jp.paths(eventVersion, d);

        for (const path of paths) {
          jp.apply(eventVersion, jp.stringify(path), (v) => (v ? new Date(v) : v));
        }
      });
    }
    return eventVersion;
  }

  async getEvent(eventId: string): Promise<Event> {
    return this.convertEventDates(await this.apiService.get<GetEventQuery, GetEventResponse>(`/api/v1/events/${eventId}`).toPromise());
  }

  private ensureEventVersion(eventVersion: EventVersion): EventVersion {
    this.convertEventVersionDates(eventVersion);

    if (!this._eventVersions[eventVersion._id]) {
      this._eventVersions[eventVersion._id] = new BehaviorSubject<EventVersion>(eventVersion);
    } else {
      this._eventVersions[eventVersion._id].next(eventVersion);
    }

    return eventVersion;
  }

  async getEventVersion(eventId: string, eventVersionId: string): Promise<EventVersion> {
    return this.ensureEventVersion(await this.apiService.get<GetEventEventVersionQuery, GetEventEventVersionResponse>(`/api/v1/events/${eventId}/eventversions/${eventVersionId}`).pipe().toPromise());
  }

  getEvents(query?: GetEventsQuery): Promise<GetEventsResponse> {
    return this.apiService.get<GetEventsQuery, GetEventsResponse>('/api/v1/events', query).toPromise();
  }

  // not meant to be precise up-to-date event subscription
  // just so that there is some catching and periodic refetch
  subscribeGetEvents(): Observable<GetEventsResponse> {
    if (!this._getEventsObservable) {
      this._getEventsObservable = timer(0, 10 * 1000).pipe(
        mergeMap(() => from(this.getEvents({ limit: 1000 }))),
        publishReplay(1),
        refCount()
      );
    }

    return this._getEventsObservable;
  }

  getDiff(firstVersion: string, secondVersion: string): Promise<GetDiffResponse> {
    return this.apiService.get<GetDiffQuery, GetDiffResponse>(`/api/v1/events/eventversions/${firstVersion}/diff`, { compareVersion: secondVersion }).toPromise();
  }

  getEventVersions(eventId: string, query?: GetEventEventVersionsQuery): Promise<GetEventEventVersionsResponse> {
    return this.apiService.get<GetEventEventVersionsQuery, GetEventEventVersionsResponse>(`/api/v1/events/${eventId}/eventversions`, query).toPromise();
  }

  importEvent(body: PostEventsImportBody): Promise<HttpResponse<PostEventsImportResponse>> {
    return this.apiService.postWithHttpResponse<PostEventsImportQuery, PostEventsImportBody, PostEventsImportResponse>('/api/v1/events/import', body).toPromise();
  }

  connectEventToExternalEvent(eventId: string, body: PostEventConnectExternalEventBody): Promise<HttpResponse<PostEventConnectExternalEventResponse>> {
    return this.apiService
      .postWithHttpResponse<PostEventConnectExternalEventQuery, PostEventConnectExternalEventBody, PostEventConnectExternalEventResponse>(`/api/v1/events/${eventId}/connect-external-event`, body)
      .toPromise();
  }

  async publishEventVersion(eventId: string, eventVersionId: string): Promise<EventVersion> {
    return this.ensureEventVersion(
      await this.apiService
        .post<PostEventEventVersionPublishQuery, PostEventEventVersionPublishBody, PostEventEventVersionPublishResponse>(`/api/v1/events/${eventId}/eventversions/${eventVersionId}/publish`, {})
        .toPromise()
    );
  }

  async saveEventVersion(eventId: string, eventVersionId: string): Promise<EventVersion> {
    return this.ensureEventVersion(
      await this.apiService
        .post<PostEventEventVersionPublishQuery, PostEventEventVersionPublishBody, PostEventEventVersionPublishResponse>(`/api/v1/events/${eventId}/eventversions/${eventVersionId}/save`, {})
        .toPromise()
    );
  }

  async forkEventVersion(eventId: string, eventVersionId: string): Promise<EventVersion> {
    return this.ensureEventVersion(
      await this.apiService
        .post<PostEventEventVersionPublishQuery, PostEventEventVersionPublishBody, PostEventEventVersionPublishResponse>(`/api/v1/events/${eventId}/eventversions/${eventVersionId}/fork`, {})
        .toPromise()
    );
  }

  async createEvent(event: PostEventsBody): Promise<Event> {
    const result = this.convertEventDates(await this.apiService.post<PostEventsQuery, PostEventsBody, PostEventsResponse>('/api/v1/events', event).toPromise());
    this._change.next({ scope: ChangeScope.Event, type: ChangeType.Create });
    return result;
  }

  async updateEvent(event: Event): Promise<Event> {
    const result = this.convertEventDates(await this.apiService.post<PostEventQuery, PostEventBody, PostEventResponse>(`/api/v1/events/${event._id}`, event).toPromise());
    this._change.next({ scope: ChangeScope.Event, type: ChangeType.Update });
    return result;
  }

  async deleteEvent(event: Event): Promise<Event> {
    const result = this.convertEventDates(await this.apiService.post<PostEventQuery, PostEventBody, PostEventResponse>(`/api/v1/events/${event._id}/delete`, event).toPromise());
    this._change.next({ scope: ChangeScope.Event, type: ChangeType.Delete });
    return result;
  }

  async getCRMEvent(eventId: string): Promise<CRMEvent> {
    return await this.apiService.get<GetEventCRMEventQuery, GetEventCRMEventResponse>(`/api/v1/events/${eventId}/crmevent`).toPromise();
  }

  async updateCRMEvent(event: CRMEvent): Promise<CRMEvent> {
    return await this.apiService.post<PostEventCRMEventQuery, PostEventCRMEventBody, PostEventCRMEventResponse>(`/api/v1/events/${event.event}/crmevent`, event).toPromise();
  }

  subscribeEvent(eventId: string): Observable<Event> {
    if (!this._events[eventId]) {
      const subject = new ReplaySubject<Event>(1);
      const viewersSubject = new BehaviorSubject<EventViewers>({
        event: eventId,
        sessions: [],
      });
      const observable = using(
        () => {
          return {
            unsubscribe: () => {
              if (subject.observers.length === 0) {
                delete this._events[eventId];
                subject.complete();
                this.apiSocketService.emit('event:unregister', {
                  event: eventId,
                });
              }
            },
          };
        },
        () => {
          return subject;
        }
      );

      this._events[eventId] = {
        subject: subject,
        observable: observable,
        viewersSubject: viewersSubject,
      };

      this.apiSocketService.emit('event:register', { event: eventId });

      Promise.all([this.getEvent(eventId), this.apiService.get<GetEventViewersQuery, GetEventViewersResponse>(`/api/v1/events/${eventId}/viewers`).toPromise()]).then(([event, viewers]) => {
        subject.next(event);
        viewersSubject.next(viewers);
      });
    }

    return this._events[eventId].observable;
  }

  subscribePreview(eventId: string, previewToken: string): Observable<Preview> {
    if (!this._previews[previewToken]) {
      const subject = new ReplaySubject<Preview>(1);

      const observable = using(
        () => {
          return {
            unsubscribe: () => {
              if (subject.observers.length === 0) {
                delete this._previews[previewToken];
                subject.complete();
                this.apiSocketService.emit('preview:unregister', {
                  preview: previewToken,
                });
              }
            },
          };
        },
        () => {
          return subject;
        }
      );

      this._previews[previewToken] = {
        subject: subject,
        observable: observable,
      };

      this.apiSocketService.emit('preview:register', { preview: previewToken });

      this.getPreview(eventId, previewToken).then((preview) => {
        subject.next(preview);
      });
    }

    return this._previews[previewToken].observable;
  }

  subscribeViewers(eventId: string): Observable<EventViewers> {
    return this.subscribeEvent(eventId).pipe(
      mergeMap((event) => {
        return this._events[event._id].viewersSubject.asObservable();
      })
    );
  }

  public lastEventVersionPatch(eventVersion: string): Observable<EventVersionPatch> {
    if (!this._lastEventVersionPatch[eventVersion]) {
      this._lastEventVersionPatch[eventVersion] = new ReplaySubject(1);
    }
    return this._lastEventVersionPatch[eventVersion].asObservable();
  }

  async patch(eventVersion: EventVersion, patch: Patch): Promise<boolean> {
    return new Promise<boolean>(async (resolve: (result: boolean) => void, reject: (err: any) => void) => {
      patch.id = uuid.v4();
      this._patches[patch.id] = { resolve, reject };

      const result = await this.apiService
        .post<PostEventEventVersionPatchQuery, PostEventEventVersionPatchBody, PostEventEventVersionPatchResponse>(
          `/api/v1/events/${eventVersion.event}/eventversions/${eventVersion._id}/patch`,
          patch
        )
        .toPromise();

      if (!result.successful) {
        delete this._patches[patch.id];
        resolve(false);
      } else {
        setTimeout(() => {
          if (this._patches[patch.id]) {
            delete this._patches[patch.id];
            reject(new Error('Timeout'));
          }
        }, 10000);
      }
    });
  }

  public async getPreviews(event: string, query?: GetEventPreviewsQuery): Promise<GetEventPreviewsResponse> {
    return this.apiService.get<GetEventPreviewsQuery, GetEventPreviewsResponse>(`/api/v1/events/${event}/previews`, query).toPromise();
  }

  public async getPreview(event: string, previewToken: string): Promise<GetEventPreviewResponse> {
    return this.apiService.get<GetEventPreviewQuery, GetEventPreviewResponse>(`/api/v1/events/${event}/previews/${previewToken}`).toPromise();
  }

  public async createPreview(event: string, preview: PostEventPreviewsBody): Promise<PostEventPreviewsResponse> {
    const result = await this.apiService.post<PostEventPreviewsQuery, PostEventPreviewsBody, PostEventPreviewsResponse>(`/api/v1/events/${event}/previews`, preview).toPromise();
    this._change.next({ scope: ChangeScope.Preview, type: ChangeType.Create });
    return result;
  }

  public async updatePreview(preview: Preview): Promise<PostEventPreviewResponse> {
    const result = await this.apiService.post<PostEventPreviewQuery, PostEventPreviewBody, PostEventPreviewResponse>(`/api/v1/events/${preview.event}/previews/${preview._id}`, preview).toPromise();
    this._change.next({ scope: ChangeScope.Preview, type: ChangeType.Update });
    return result;
  }

  public async cancel(event: string): Promise<PostEventCancelResponse> {
    return this.apiService.post<PostEventCancelQuery, PostEventCancelBody, PostEventCancelResponse>(`/api/v1/events/${event}/cancel`, {}).toPromise();
  }

  public async announce(event: string, announceParameter: { announceAt: string }): Promise<PostEventAnnounceResponse> {
    return this.apiService.post<PostEventAnnounceQuery, PostEventAnnounceBody, PostEventAnnounceResponse>(`/api/v1/events/${event}/announce`, announceParameter).toPromise();
  }

  public async release(event: string, releaseParameter: { releaseAt: string }): Promise<PostEventReleaseResponse> {
    return this.apiService.post<PostEventReleaseQuery, PostEventReleaseBody, PostEventReleaseResponse>(`/api/v1/events/${event}/release`, releaseParameter).toPromise();
  }

  //  ------------------ Event Registration ------------------ //

  convertRegistrations(response: GetEventRegistrationsResponse): GetEventRegistrationsResponse {
    response.items = response.items.map((i) => this.convertRegistration(i));
    // response.items.forEach(registration => {
    //   registration.registeredAt = new Date(registration.registeredAt);
    //   registration.unregisteredAt = new Date(registration.unregisteredAt);

    //   // Convert registrationData to an array if neccessary
    //   if (!Array.isArray(registration.registrationData)) {
    //     registration.registrationData = [registration.registrationData];
    //   }

    //   registration.registrationData.forEach(registrationData => {
    //     // Add logic if neccessary
    //     if (!registrationData.eventTicket) registrationData.eventTicket = null;
    //     if (!registrationData.customFields) registrationData.customFields = {};
    //     if (!registrationData.eventSlots) registrationData.eventSlots = [];

    //     // !!! the following code raises an error on startAt !!!
    //     // registrationData.eventSlots.forEach(eventSlot => {
    //     //   (eventSlot.eventSlot as EventSlot).startAt = new Date((eventSlot.eventSlot as EventSlot).startAt);
    //     //   (eventSlot.eventSlot as EventSlot).endAt = new Date((eventSlot.eventSlot as EventSlot).endAt);

    //     //   eventSlot.eventTimeSlots.forEach(eventTimeSlot => {
    //     //     (eventTimeSlot as EventTimeSlot).startAt = new Date((eventTimeSlot as EventTimeSlot).startAt);
    //     //     (eventTimeSlot as EventTimeSlot).endAt = new Date((eventTimeSlot as EventTimeSlot).endAt);
    //     //   });
    //     // });
    //   });
    // });
    return response;
  }

  async getEventRegistrations(event: string, query?: GetEventRegistrationsQuery): Promise<GetEventRegistrationsResponse> {
    const response = await this.apiService.get<GetEventRegistrationsQuery, GetEventRegistrationsResponse>(`/api/v1/events/${event}/registrations`, query).toPromise();
    return this.convertRegistrations(response);
  }

  async getFailedCRMEventRegistrations(query?: GetFailedCRMEventRegistrationsQuery): Promise<GetFailedCRMEventRegistrationsResponse> {
    return await this.apiService.get<GetFailedCRMEventRegistrationsQuery, GetFailedCRMEventRegistrationsResponse>(`/api/v1/events/failedcrmeventregistrations`, query).toPromise();
  }

  async postFailedCRMEventRegistration(id: string, showEntry: boolean, comment?: string): Promise<PostFailedCRMEventRegistrationResponse> {
    return await this.apiService
      .post<PostFailedCRMEventRegistrationQuery, PostFailedCRMEventRegistrationBody, PostFailedCRMEventRegistrationResponse>(`/api/v1/events/failedcrmeventregistration/${id}`, {
        showEntry: showEntry,
        comment: comment,
      })
      .toPromise();
  }

  async crmEventRegistrationRetry(eventId: string, eventRegistrationId): Promise<PostEventRegistrationCRMEventRegistrationRetryResponse> {
    return this.apiService
      .post<PostEventRegistrationCRMEventRegistrationRetryQuery, PostEventRegistrationCRMEventRegistrationRetryBody, PostEventRegistrationCRMEventRegistrationRetryResponse>(
        `/api/v1/events/${eventId}/registrations/${eventRegistrationId}/crmeventregistration/retry`,
        {}
      )
      .toPromise();
  }

  downloadEventRegistrations(event: string, query?: GetEventRegistrationsQuery) {
    this.apiService.download(`/api/v1/events/${event}/registrations`, 'event_registrations', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', {
      ...query,
      format: 'xlsx',
    });
  }

  convertRegistration(registration: EventRegistration): EventRegistration {
    registration.registeredAt = new Date(registration.registeredAt);
    registration.unregisteredAt = registration.unregisteredAt ? new Date(registration.unregisteredAt) : null;
    // Convert registrationData to an array if neccessary
    if (!Array.isArray(registration.registrationData)) {
      registration.registrationData = [registration.registrationData];
    }
    // Add logic if neccessary
    registration.registrationData.forEach((registrationData) => {
      if (!registrationData.eventTicket) registrationData.eventTicket = null;
      if (!registrationData.customFields) registrationData.customFields = {};
      if (!registrationData.eventSlots) registrationData.eventSlots = [];
    });

    return registration;
  }

  async getEventRegistration(eventId: string, eventRegistrationId: string): Promise<EventRegistration> {
    return this.convertRegistration(await this.apiService.get<GetEventRegistrationQuery, GetEventRegistrationResponse>(`/api/v1/events/${eventId}/registrations/${eventRegistrationId}`).toPromise());
  }

  async createEventRegistration(eventId: string, eventRegistration: PostEventRegistrationsBody): Promise<PostEventRegistrationsResponse> {
    const result = await this.apiService
      .post<PostEventRegistrationsQuery, PostEventRegistrationsBody, PostEventRegistrationsResponse>(`/api/v1/events/${eventId}/registrations`, eventRegistration)
      .toPromise();

    for (const eventRegistration of result) {
      if (eventRegistration.eventRegistration) {
        Object.assign(eventRegistration.eventRegistration, this.convertRegistration(eventRegistration.eventRegistration));
      }
    }

    this._change.next({
      scope: ChangeScope.EventRegistration,
      type: ChangeType.Create,
    });
    return result;
  }

  async updateEventRegistration(eventId: string, eventRegistration: EventRegistration, sendEmail?: boolean): Promise<EventRegistration> {
    const result = this.convertRegistration(
      await this.apiService
        .post<PostEventRegistrationQuery, PostEventRegistrationBody, PostEventRegistrationResponse>(`/api/v1/events/${eventId}/registrations/${eventRegistration._id}`, {
          ...eventRegistration,
          sendEmail: sendEmail,
        })
        .toPromise()
    );
    this._change.next({
      scope: ChangeScope.EventRegistration,
      type: ChangeType.Update,
    });
    return result;
  }

  async setEventRegistrationUnregistered(eventId: string, eventRegistration: EventRegistration): Promise<PostEventRegistrationUnregisterResponse> {
    return await this.apiService
      .post<PostEventRegistrationUnregisterQuery, PostEventRegistrationUnregisterBody, PostEventRegistrationUnregisterResponse>(
        `/api/v1/events/${eventId}/registrations/${eventRegistration._id}/unregister`,
        eventRegistration
      )
      .toPromise();
  }

  async getEventTickets(query?: GetEventTicketsQuery): Promise<GetEventTicketsResponse> {
    return await this.apiService
      .get<GetEventTicketsQuery, GetEventTicketsResponse>('/api/v1/events/tickets', {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();
  }

  //  ------------------ Event Slot (Registration) ------------------ //

  convertEventSlotsDates(response: GetEventSlotsResponse): GetEventSlotsResponse {
    response.eventSlots.forEach((eventSlot) => {
      eventSlot.startAt = new Date(eventSlot.startAt);
      eventSlot.endAt = new Date(eventSlot.endAt);
      eventSlot.eventTimeSlots.forEach((timeSlot) => {
        timeSlot.startAt = new Date(timeSlot.startAt);
        timeSlot.endAt = new Date(timeSlot.endAt);
      });
    });
    return response;
  }

  async getEventSlots(eventId: string): Promise<GetEventSlotsResponse> {
    return this.convertEventSlotsDates(await this.apiService.get<GetEventSlotsQuery, GetEventSlotsResponse>(`/api/v1/events/${eventId}/slots`).toPromise());
  }

  async updateEventSlot(eventId: string, eventSlots: EventSlot[]): Promise<PostEventSlotResponse> {
    const result = await this.apiService.post<PostEventSlotQuery, PostEventSlotBody, PostEventSlotResponse>(`/api/v1/events/${eventId}/eventslots`, { eventSlots: eventSlots }).toPromise();
    this._change.next({
      scope: ChangeScope.EventSlot,
      type: ChangeType.Update,
    });
    return result;
  }

  //  ------------------ VIP Tickets ------------------ //

  async createVipTickets(eventId: string, vipTicketsBody: PostEventVipTicketsBody): Promise<PostEventVipTicketsResponse> {
    const result = await this.apiService.post<PostEventVipTicketsQuery, PostEventVipTicketsBody, PostEventVipTicketsResponse>(`/api/v1/events/${eventId}/viptickets`, vipTicketsBody).toPromise();
    return result;
  }

  async getVipTickets(eventId: string, query?: GetEventVipTicketsQuery): Promise<GetEventVipTicketsResponse> {
    const result = await this.apiService.get<GetEventVipTicketsQuery, GetEventVipTicketsResponse>(`/api/v1/events/${eventId}/viptickets`, query).toPromise();
    return result;
  }

  downloadVipTickets(eventId: string, query?: GetEventRegistrationsQuery) {
    this.apiService.download(`/api/v1/events/${eventId}/viptickets`, 'vip_tickets', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', {
      ...query,
      format: 'xlsx',
    });
  }

  async updateVipTicket(eventId: string, vipTicket: VIPTicket): Promise<PostEventVipTicketResponse> {
    const result = await this.apiService
      .post<PostEventVipTicketQuery, PostEventVipTicketBody, PostEventVipTicketResponse>(`/api/v1/events/${eventId}/viptickets/${vipTicket._id}`, vipTicket)
      .toPromise();
    return result;
  }

  async deleteVipTicket(eventId: string, vipTicket: VIPTicket): Promise<PostEventVipTicketDeleteResponse> {
    const result = await this.apiService
      .post<PostEventVipTicketDeleteQuery, PostEventVipTicketDeleteBody, PostEventVipTicketDeleteResponse>(`/api/v1/events/${eventId}/viptickets/${vipTicket._id}/delete`, vipTicket)
      .toPromise();
    return result;
  }

  //  ------------------ Live sessions ------------------ //

  public async startSession(event: string, session: Session, previewToken?: string): Promise<PostEventSessionStartResponse> {
    const result = this.convertEventDates(
      await this.apiService
        .post<PostEventSessionStartQuery, PostEventSessionStartBody, PostEventSessionStartResponse>(`/api/v1/events/${event}/sessions/${session._id}/start`, {
          previewToken: previewToken,
        })
        .toPromise()
    );
    this._change.next({ scope: ChangeScope.Session, type: ChangeType.Start });
    return result;
  }

  public async stopSession(event: string, session: Session, previewToken?: string): Promise<PostEventSessionStopResponse> {
    const result = this.convertEventDates(
      await this.apiService
        .post<PostEventSessionStopQuery, PostEventSessionStopBody, PostEventSessionStopResponse>(`/api/v1/events/${event}/sessions/${session._id}/stop`, {
          previewToken: previewToken,
        })
        .toPromise()
    );
    this._change.next({ scope: ChangeScope.Session, type: ChangeType.Stop });
    return result;
  }

  public async updateSession(event: string, session: Session, previewToken?: string): Promise<PostEventSessionResponse> {
    const newEvent = await this.apiService
      .post<PostEventSessionQuery, PostEventSessionBody, PostEventSessionResponse>(`/api/v1/events/${event}/sessions/${session._id}`, {
        previewToken: previewToken,
        actualStartAt: session.actualStartAt ? new Date(session.actualStartAt).toISOString() : null,
        actualEndAt: session.actualEndAt ? new Date(session.actualEndAt).toISOString() : null,
      })
      .toPromise();
    const result = this.convertEventDates(newEvent);
    this._change.next({ scope: ChangeScope.Session, type: ChangeType.Update });
    return result;
  }

  public async publishSessionVOD(event: string, session: string, assetId: string, language: string): Promise<PostEventSessionPublishVODResponse> {
    return this.apiService
      .post<PostEventSessionPublishVODQuery, PostEventSessionPublishVODBody, PostEventSessionPublishVODResponse>(`/api/v1/events/${event}/sessions/${session}/publish-vod`, {
        assetId,
        language,
      })
      .toPromise();
  }

  public async sendUpdate(event: string): Promise<PostEventSendUpdateResponse> {
    return this.apiService.post<PostEventSendUpdateQuery, PostEventSendUpdateBody, PostEventSendUpdateResponse>(`/api/v1/events/${event}/sendupdate`, {}).toPromise();
  }

  public async sendUpdateSessions(event: string): Promise<PostEventSendUpdateSessionsResponse> {
    return this.apiService.post<PostEventSendUpdateSessionsQuery, PostEventSendUpdateSessionsBody, PostEventSendUpdateSessionsResponse>(`/api/v1/events/${event}/sendupdate/sessions`, {}).toPromise();
  }

  //  ------------------ EventTypes ------------------ //

  public async getEventTypes(query?: GetEventTypesQuery): Promise<GetEventTypesResponse> {
    return this.apiService.get<GetEventTypesQuery, GetEventTypesResponse>('/api/v1/events/eventtypes', query).toPromise();
  }

  public async getEventType(eventType: string): Promise<GetEventTypeResponse> {
    if (!this._eventTypeCache[eventType]) {
      this._eventTypeCache[eventType] = this.apiService.get<GetEventTypeQuery, GetEventTypeResponse>(`/api/v1/events/eventtypes/${eventType}`, {}).toPromise();
    }
    return this._eventTypeCache[eventType];
  }

  public async updateEventType(eventType: EventType): Promise<GetEventTypeResponse> {
    const result = await this.apiService
      .post<PostEventTypeQuery, PostEventTypeBody, PostEventTypeResponse>(`/api/v1/events/eventtypes/${eventType._id}`, {
        internalName: eventType.internalName,
        local: eventType.local,
      })
      .toPromise();
    this._change.next({
      scope: ChangeScope.EventType,
      type: ChangeType.Update,
    });
    return result;
  }

  public async createEventType(eventType: EventType): Promise<PostEventTypesResponse> {
    const result = await this.apiService
      .post<PostEventTypesQuery, PostEventTypesBody, PostEventTypesResponse>(`/api/v1/events/eventtypes`, {
        internalName: eventType.internalName,
        local: eventType.local,
      })
      .toPromise();
    this._change.next({
      scope: ChangeScope.EventType,
      type: ChangeType.Create,
    });
    return result;
  }

  //  ------------------ Event Series ------------------ //

  public async getEventSeries(query?: GetEventSeriesQuery): Promise<GetEventSeriesResponse> {
    return this.apiService.get<GetEventSeriesQuery, GetEventSeriesResponse>('/api/v1/events/eventseries', query).toPromise();
  }

  public async getEventSerie(eventSerie: string): Promise<GetEventSerieResponse> {
    if (!this._eventSerieCache[eventSerie]) {
      this._eventSerieCache[eventSerie] = this.apiService.get<GetEventSerieQuery, GetEventSerieResponse>(`/api/v1/events/eventseries/${eventSerie}`, {}).toPromise();
    }
    return this._eventSerieCache[eventSerie];
  }

  public async updateEventSerie(eventSerie: EventSerie): Promise<GetEventSerieResponse> {
    const result = await this.apiService
      .post<PostEventSerieQuery, PostEventSerieBody, PostEventSerieResponse>(`/api/v1/events/eventseries/${eventSerie._id}`, {
        internalName: eventSerie.internalName,
        local: eventSerie.local,
        type: eventSerie.type,
      })
      .toPromise();
    this._change.next({
      scope: ChangeScope.EventSerie,
      type: ChangeType.Update,
    });
    return result;
  }

  public async createEventSerie(eventSerie: EventSerie): Promise<PostEventSeriesResponse> {
    const result = await this.apiService
      .post<PostEventSeriesQuery, PostEventSeriesBody, PostEventSeriesResponse>(`/api/v1/events/eventseries`, {
        internalName: eventSerie.internalName,
        local: eventSerie.local,
        type: eventSerie.type,
      })
      .toPromise();
    this._change.next({
      scope: ChangeScope.EventSerie,
      type: ChangeType.Create,
    });
    return result;
  }
}
