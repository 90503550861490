import { StreamEventStagePageModuleAttributes } from './../../entities/pagemodules/StreamEventStagePageModule';
import { HeadlineType } from './../../entities/Attributes';
import { ButtonSpacingTopDefaultBreakpointValue, StageHeightDefaultBreakpointValue } from './DefaultBreakponitValue';
import { BreakpointValue } from './../../entities/Breakpoint';

import { StreamEventStagePageModule, StreamEventStagePageModuleLocal } from '../../entities/PageModule';
import { FactoryOptions } from '../AbstractFactory';
import { BasePageModuleFactory } from './BasePageModuleFactory';

export class StreamEventStagePageModuleFactory extends BasePageModuleFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  public streamEventStagePageModuleAttributes(values?: any, headlineTypeDefault?: HeadlineType): StreamEventStagePageModuleAttributes {
    return {
      headlineType: values?.headlineType || headlineTypeDefault || 'h2',
    };
  }

  public async streamEventStagePageModuleLocal(values?: any): Promise<StreamEventStagePageModuleLocal> {
    return {
      smallHeadline: values?.smallHeadline || '',
      smallHeadline_attr: this.streamEventStagePageModuleAttributes(values?.smallHeadline_attr, 'no headline'),
      title: values?.title || '',
      title_attr: this.streamEventStagePageModuleAttributes(values?.title_attr),
      subtext: values?.subtext || '',
      background: values?.background || null,
    };
  }

  public async streamEventStagePageModuleButton(values?: any) {
    return {
      _id: await this.id(values?._id),
      ctaButton: values?.ctaButton || null,
    };
  }

  public streamEventStagePageModuleStageHeight(defaultValues: BreakpointValue<number>, values?: any) {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge,
    };
  }

  public streamEventStagePageModuleButtonSpacingTopBreakpoint(defaultValues: BreakpointValue<number>, values?: any) {
    return {
      desktop: values?.desktop || defaultValues.desktop,
      desktopLarge: values?.desktopLarge || defaultValues.desktopLarge,
      desktopWidescreen: values?.desktopWidescreen || defaultValues.desktopWidescreen,
      tablet: values?.tablet || defaultValues.tablet,
      tabletLarge: values?.tabletLarge || defaultValues.tabletLarge,
      mobile: values?.mobile || defaultValues.mobile,
      mobileLarge: values?.mobileLarge || defaultValues.mobileLarge,
    };
  }

  public async streamEventStagePageModule(values?: any): Promise<StreamEventStagePageModule> {
    if (values?.background) {
      Object.values(values.local || {}).forEach((item: any) => {
        item.background = item.background || values.background;
      });
    }
    const pageModule = {
      ...(await this.baseValues(values)),
      pageModuleType: 'StreamEventStage',
      background: undefined,
      local: await this.local(values, (val) => this.streamEventStagePageModuleLocal(val)),
      ctalist: values?.ctalist || [],
      ctalistInteraction: values?.ctalistInteraction || [],
      discoverMore: values?.discoverMore || false,
      stageHeight: values?.stageHeight ? values?.stageHeight : this.streamEventStagePageModuleStageHeight(StageHeightDefaultBreakpointValue, values?.stageHeight),
      buttonSpacingTopBreakpoint: values?.buttonSpacingTopBreakpoint
        ? values?.buttonSpacingTopBreakpoint
        : this.streamEventStagePageModuleButtonSpacingTopBreakpoint(ButtonSpacingTopDefaultBreakpointValue, values?.buttonSpacingTopBreakpoint),
    };
    return { ...pageModule, pageModuleType: 'StreamEventStage' };
  }
}
