import { InputConfiguration } from 'src/common/inputs/Inputs';
import { Component, Input, OnInit } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { TextImagePageModule } from 'src/common/entities/PageModule';

@Component({
  selector: 'c-textimage-page-module-edit',
  templateUrl: './textimage-page-module-edit.component.html',
  styleUrls: ['./textimage-page-module-edit.component.scss'],
})
export class TextImagePageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: TextImagePageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathes: string[] = [
    '$.local.$language.title',
    '$.titlePosition',
    '$.local.$language.text',
    '$.local.$language.image',
    '$.imageSize',
    '$.imagePosition',
    '$.imagePositionWithinImageArea',
    '$.imagePositionMobile',
    '$.ctaButton',
    '$.anchor',
  ];

  tab = 'general';

  accessInputConfiguration: InputConfiguration;

  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
  }
}
