import { EventKeytopicFactory } from '../../factories/EventKeytopicFactory';
import { Inputs } from '../Inputs';

export const eventVersionKeytopicsInputs: Inputs = {
  "$.eventKeytopics[?(@.keytopic == '$keytopicId')].local.$language": {
    factory: async () => new EventKeytopicFactory().createEventKeytopicLocal({}),
  },
  "$.eventKeytopics[?(@.keytopic == '$keytopicId')].local.$language.assets": {
    factory: async () => [],
    list: true,
    childFactory: async () => null,
  },
  "$.eventKeytopics[?(@.keytopic == '$keytopicId')].local.$language.assets[$index]": {
    type: 'videoasset',
  },

  "$.eventKeytopics[?(@.keytopic == '$keytopicId')].override.icon": {},
  "$.eventKeytopics[?(@.keytopic == '$keytopicId')].override.header": {
    type: 'imageasset',
  },
  "$.eventKeytopics[?(@.keytopic == '$keytopicId')].override.local.$language": {
    factory: async () => new EventKeytopicFactory().createKeytopicContentLocal({}),
  },
  "$.eventKeytopics[?(@.keytopic == '$keytopicId')].override.local.$language.title": {},
  "$.eventKeytopics[?(@.keytopic == '$keytopicId')].override.local.$language.shortDescription": {},
  "$.eventKeytopics[?(@.keytopic == '$keytopicId')].override.local.$language.longDescription": {},
};
