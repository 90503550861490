import { IRBAC } from './../../../common/entities/RBAC';
import { PostRBACBody, PostRBACQuery, PostRBACResponse } from './../../../common/api/v1/adminusers/PostRBAC';
import { GetRBACsQuery, GetRBACsResponse } from './../../../common/api/v1/adminusers/GetRBACs';
import { ApiService } from 'src/app/services/api/api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RBACService {
  constructor(private apiService: ApiService) {}

  public async getRBACByResource(resource: string, query?: GetRBACsQuery): Promise<GetRBACsResponse> {
    return this.apiService.get<GetRBACsQuery, GetRBACsResponse>('/api/v1/adminusers/rbac/' + resource, query).toPromise();
  }

  public async postRBAC(rbac: IRBAC, resource: string): Promise<IRBAC> {
    return this.apiService.post<PostRBACQuery, PostRBACBody, PostRBACResponse>('api/v1/adminusers/rbacs/' + resource, rbac).toPromise();
  }

  public async deleteRBAC(rbac: IRBAC): Promise<IRBAC> {
    return this.apiService.post<PostRBACQuery, null, PostRBACResponse>('api/v1/adminusers/rbac/delete/' + rbac._id, null).toPromise();
  }
}
