import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MegaMenuModule } from 'primeng/megamenu';
import { ComponentsModule } from '../components/components.module';
import { EventStageEditModule } from '../event-stages/event-stage-edit/event-stage-edit.module';
import { PageModuleEditModule } from '../page-modules/page-module-edit/page-module-edit.module';
import { PageModule } from '../page/page.module';
import { PipesModule } from '../pipes/pipes.module';
import { EventLayoutPagesComponent } from './event-layout-pages/event-layout-pages.component';
import { LayoutEditorComponent } from './layout-editor.component';
import { PageLayoutEditorComponent } from './page-layout-editor/page-layout-editor.component';
import { MessageModule } from 'primeng/message';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { SiteStructureComponent } from './site-structure/site-structure.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MessagesModule } from 'primeng/messages';
import { WindowMessageService } from '../services/window-message/window-message.service';
import { ConfirmationService } from 'primeng/api';
import { DirectivesModule } from '../directives/directives.module';

const components = [LayoutEditorComponent, PageLayoutEditorComponent, EventLayoutPagesComponent];

@NgModule({
  declarations: [...components, SiteStructureComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    EventStageEditModule,
    RadioButtonModule,
    DialogModule,
    FormsModule,
    SidebarModule,
    MultiSelectModule,
    SplitButtonModule,
    MessageModule,
    MegaMenuModule,
    PipesModule,
    ButtonModule,
    TranslateModule,
    PageModuleEditModule,
    PageModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    MessagesModule,
    DirectivesModule,
  ],
  exports: [...components],
  providers: [ConfirmationService, WindowMessageService],
})
export class LayoutEditorModule {}
