import { TextAttributes } from './../Attributes';
import { CTAButton } from '../CTAButton';
import { PageModule, TitlePositions } from './PageModule';
import { ContentPosition } from './ListPageModule';

export interface TextPageModule extends PageModule {
  pageModuleType: 'Text';
  titlePosition?: TitlePositions;
  local: {
    [language: string]: {
      title: string;
      title_attr?: TextPageModuleAttributes;
      text: string;
    };
  };
  ctaButton?: CTAButton;
  contentPosition?: ContentPosition;
}

export type TextPageModuleAttributes = TextAttributes & {};

export function isTextPageModule(object: any): object is TextPageModule {
  return object?.pageModuleType === 'Text';
}
