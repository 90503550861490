<div class="sidebar-tabs">
  <button [class.active]="tab === 'general'" (click)="tab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
  <button [class.active]="tab === 'teasers'" (click)="tab = 'teasers'">{{ 'GENERAL_TEASERS' | translate }}</button>
  <button [class.active]="tab === 'ctalist'" (click)="tab = 'ctalist'">{{ 'GENERAL_CTA_LIST' | translate }}</button>
  <button [class.active]="tab === 'ctalistInteraction'" (click)="tab = 'ctalistInteraction'">{{ 'GENERAL_CTA_LIST_INTERACTION' | translate }}</button>
  <button [class.active]="tab === 'design'" (click)="tab = 'design'">{{ 'GENERAL_DESIGN' | translate }}</button>
  <button [class.active]="tab === 'accesspolicy'" (click)="tab = 'accesspolicy'">{{ 'GENERAL_CONDITIONS' | translate }}</button>
</div>

<div *ngIf="tab == 'general'" class="sidebar-body">
  <c-page-patch-input *ngFor="let inputJsonpath of inputJsonpathes" [jsonpath]="[jsonpath, inputJsonpath]" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'teasers'" class="sidebar-body">
  <div *ngIf="currentLanguage && pageModule.local[currentLanguage]" class="form-container-container">
    <div *ngFor="let item of pageModule.local[currentLanguage].items; let i = index; trackBy: trackByIndex" class="form-container-container-item">
      <div *ngIf="item.teaserItemType == 'Highlight'">
        <c-page-patch-input
          [jsonpath]="[jsonpath, itemJsonpath, '$.session']"
          [jsonpathParams]="itemJsonpathParams(item)"
          [object]="eventVersion || page"
          [inputConfiguration]="{ type: 'dropdown', dropdownOptions: sessions }"
        ></c-page-patch-input>
      </div>

      <div *ngIf="item.teaserItemType == 'Link'">
        <c-page-patch-input [jsonpath]="[jsonpath, itemJsonpath, '$.image']" [jsonpathParams]="itemJsonpathParams(item)" [object]="eventVersion || page"></c-page-patch-input>
        <c-page-patch-input [jsonpath]="[jsonpath, itemJsonpath, '$.title']" [jsonpathParams]="itemJsonpathParams(item)" [object]="eventVersion || page"></c-page-patch-input>
        <c-page-patch-input [jsonpath]="[jsonpath, itemJsonpath, '$.link']" [jsonpathParams]="itemJsonpathParams(item)" [object]="eventVersion || page"></c-page-patch-input>
      </div>

      <div *ngIf="item.teaserItemType == 'CTA'">
        <c-page-patch-input [jsonpath]="[jsonpath, itemJsonpath, '$.image']" [jsonpathParams]="itemJsonpathParams(item)" [object]="eventVersion || page"></c-page-patch-input>
        <c-page-patch-input [jsonpath]="[jsonpath, itemJsonpath, '$.title']" [jsonpathParams]="itemJsonpathParams(item)" [object]="eventVersion || page"></c-page-patch-input>
        <c-page-patch-input
          [jsonpath]="[jsonpath, itemJsonpath, '$.cta']"
          [jsonpathParams]="itemJsonpathParams(item)"
          [object]="eventVersion || page"
          [inputConfiguration]="{
            type: 'ctabutton',
            options: {
              types: ['meetyourexpert', 'registerplatform', 'registerevent', 'videoasset', 'externalevent']
            }
          }"
        ></c-page-patch-input>
      </div>

      <div class="fieldset-buttons p-d-flex">
        <!-- <button *ngIf="!first" pButton type="button" class="p-button-sm p-button-outlined p-mr-2" icon="pi pi-arrow-up"
          (click)="up(index)" 
          [disabled]="isDisabled()" 
        ></button>
        <button *ngIf="!last" pButton type="button" class="p-button-sm p-button-outlined" icon="pi pi-arrow-down" 
          (click)="down(index)" 
          [disabled]="isDisabled()" 
        ></button> -->
        <div class="p-ml-auto"></div>
        <button
          pButton
          type="button"
          class="p-button-sm p-button-outlined"
          [label]="'GENERAL_DELETE' | translate"
          icon="pi pi-trash"
          (click)="deleteTeaserItem(item)"
          [disabled]="isDisabled()"
        ></button>
      </div>
    </div>

    <div class="fieldset-last-button p-d-flex">
      <div class="input-flex input-group">
        <div class="input">
          <p-dropdown appendTo="body" [options]="teaserItemTypes" [(ngModel)]="newTeaserItemType" styleClass="p-mr-2 dropdown-type"></p-dropdown>
        </div>
        <div class="p-ml-auto"></div>
        <div class="input-button">
          <button
            pButton
            type="button"
            class="p-button-sm p-button-outlined input-teaser-add"
            [label]="('GENERAL_ADD' | translate) + ' Teaser Item'"
            icon="pi icon-ic-add-item"
            (click)="addTeaserItem()"
            [disabled]="isDisabled()"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="tab == 'ctalist'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.ctalist']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'ctalistInteraction'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.ctalistInteraction']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'design'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.backgroundTheme']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <p-accordion>
    <p-accordionTab header="Top Area">
      <c-page-patch-input *ngFor="let inputJsonpath of topAreaJsonpathes" [jsonpath]="[jsonpath, inputJsonpath]" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    </p-accordionTab>
    <p-accordionTab header="Content Area">
      <c-page-patch-input
        *ngFor="let inputJsonpath of contentAreaJsonpathes"
        [jsonpath]="[jsonpath, inputJsonpath]"
        [jsonpathParams]="jsonpathParams"
        [object]="eventVersion || page"
      ></c-page-patch-input>
    </p-accordionTab>
    <p-accordionTab header="CTA Area">
      <c-page-patch-input *ngFor="let inputJsonpath of ctaAreaJsonpathes" [jsonpath]="[jsonpath, inputJsonpath]" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
    </p-accordionTab>
  </p-accordion>
</div>

<div *ngIf="tab == 'accesspolicy'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.accessPolicy']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page" [inputConfiguration]="accessInputConfiguration"></c-page-patch-input>
</div>
