import { ConfirmationService } from 'primeng/api';
import { Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Self, SimpleChanges, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { EventFocus } from 'src/common/api/v1/websocket/EventFocus';
import { AdminUser } from 'src/common/entities/AdminUser';
import { InputConfiguration, Inputs, InputType, ListInputParentConfiguration } from 'src/common/inputs/Inputs';
import * as jp from 'jsonpath';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'c-collaboration-patch-input',
  templateUrl: './collaboration-patch-input.component.html',
  styleUrls: ['./collaboration-patch-input.component.scss'],
})
export class CollaborationPatchInputComponent implements OnInit, OnDestroy, OnChanges {
  @ContentChild('template', { static: false }) templateRef: TemplateRef<any>;

  @Input()
  jsonpath: string | string[];

  @Input()
  jsonpathParams: { [key: string]: any } = {};

  @Input()
  collaborationKey: string;

  @Input()
  object: any;

  @Input()
  inputConfiguration: InputConfiguration = {};

  @Input()
  inputs: Inputs = {};

  @Input()
  listInputIteration: number = 0;

  @Input()
  showHeader: boolean = true;

  @Input()
  showDescription: boolean = true;

  @Input()
  showLanguage: boolean = true;

  @Input()
  header: string;

  @Input()
  description: string;

  @Input()
  placeholder: string;

  @Input()
  placeholderDescription: string;

  @Input()
  showPlaceholderDescription = false;

  @Input()
  disabled: boolean;

  @Input()
  compact: boolean | 'auto' = 'auto';

  @Input()
  error: string;

  @Input()
  showDeleteButton: boolean = true;

  @Input()
  showAddButton: boolean = true;

  @Output()
  onValueChanged = new EventEmitter<any>();

  get isCompact(): boolean {
    if (this.compact === 'auto') {
      return this.width < 500;
    }
    return this.compact;
  }

  get width(): number {
    return this.element?.nativeElement?.offsetWidth || 0;
  }

  get hasLanguageParam() {
    return /\$language[.$]/.test(this.combinedJsonpath || '');
  }

  get languages() {
    return this.calculatedInputConfiguration.languages || this.object.languages || [this.jsonpathParams.language || 'en'];
  }

  parent: any;
  resolvedJsonpath: string;
  completeJsonpathParams: { [key: string]: any };
  calculatedInputConfiguration: InputConfiguration = {};
  calculatedChildInputConfiguration: InputConfiguration = {};

  isListInput: boolean = false;
  listInputParent: ListInputParentConfiguration = null;

  users: EventFocus[] = [];

  currentLanguage: string;

  focussedIndex: number;
  focussed: boolean = false;

  isSwitch: boolean = false;
  initialized: boolean = false;
  loading: boolean = false;

  successful: boolean | null = null;
  successfulTimeout: any = null;

  translationMode = false;
  attributeMode = false;
  sortMode = false;

  listChildElementSideBarOpen = [];

  subscriptions: Subscription[] = [];

  private _combinedJsonpath: string | null = null;
  get combinedJsonpath(): string {
    if (!this._combinedJsonpath) {
      this._combinedJsonpath = this.utilsService.combineJsonpath(this.jsonpath);
    }
    return this._combinedJsonpath;
  }

  constructor(
    @Self() private element: ElementRef,
    private collaborationService: CollaborationService,
    private utilsService: UtilsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService
  ) {}

  private focusCallback = (users: EventFocus[]) => {
    this.users = users;
  };

  ngOnInit(): void {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe(async (queryParams) => {
        if (queryParams.language && queryParams.language !== this.currentLanguage) {
          this.currentLanguage = queryParams.language;
          await this.init();
        }
      })
    );
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (
      changes.jsonpath?.currentValue !== changes.jsonpath?.previousValue ||
      (changes.jsonpathParams?.currentValue !== changes.jsonpathParams?.previousValue &&
        JSON.stringify(changes.jsonpathParams.currentValue) !== JSON.stringify(changes.jsonpathParams.previousValue)) ||
      changes.inputConfiguration?.currentValue !== changes.inputConfiguration?.previousValue ||
      changes.inputs?.currentValue !== changes.inputs?.previousValue
    ) {
      this._combinedJsonpath = null;
      await this.init();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());

    if (this.collaborationKey && this.resolvedJsonpath) {
      this.collaborationService.unregisterFocus(this.collaborationKey, this.resolvedJsonpath, this.focusCallback);
    }
  }

  public languageMode(): 'none' | 'default' | 'localized' | 'default-editable' | 'localized-editable' {
    //const v = Array.isArray(this.jsonpath) ? this.jsonpath.filter( t => t.includes('$language')).length === 0 : !this.jsonpath.includes('$language')
    if (!this.combinedJsonpath.includes('$language')) return 'none';

    if (this.calculatedInputConfiguration.languages) return 'localized-editable';

    const jsonpath = this.utilsService.resolveJsonpath(this.combinedJsonpath, {
      ...this.completeJsonpathParams,
      language: '$language',
    });

    const isGlobalProperty = (this.object.globalProperties || []).includes(jsonpath);

    if (/\$language\.[a-zA-Z0-9\\.\\[\]]+$/.test(jsonpath)) {
      // default-editable or localized-editable
      if (isGlobalProperty) {
        return 'default-editable';
      } else {
        return 'localized-editable';
      }
    } else {
      if (isGlobalProperty) {
        return 'default';
      } else {
        return 'localized';
      }
    }
  }

  attributJsonpath(key?: string): string {
    const lastPart = this.combinedJsonpath.substring(this.combinedJsonpath.lastIndexOf('.') + 1, this.combinedJsonpath.length);
    const parent = this.combinedJsonpath.substring(0, this.combinedJsonpath.lastIndexOf('.'));
    const attributPart = `$.${lastPart}_attr`;

    const attributJsonpath = this.utilsService.combineJsonpath([parent, attributPart]);

    if (key) {
      return this.utilsService.combineJsonpath([attributJsonpath, key]);
    } else {
      return attributJsonpath;
    }
  }

  order = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  };

  public attributInputConfiguration(): InputConfiguration {
    if (this.inputs[this.combinedJsonpath].type === 'text') {
      const input = this.inputs[this.combinedJsonpath] as {
        type: 'text';
        attributes?: { label: string | ((obj: any, parent: any) => string); inputs: Inputs };
      };
      if (input.attributes) {
        return input.attributes.inputs;
      }
      return null;
    }
    return null;
  }

  public attributeHeader(): string {
    if (this.inputs[this.combinedJsonpath]?.type === 'text') {
      const input = this.inputs[this.combinedJsonpath] as {
        type: 'text';
        attributes?: { label: string | ((obj: string) => string); inputs: Inputs };
      };
      if (input?.attributes) {
        const label = input.attributes.label;
        if (typeof label === 'string') {
          return label;
        } else {
          if (typeof label === 'function') {
            const a = jp.value(this.object, this.utilsService.resolveJsonpath(this.attributJsonpath('$.headlineType'), this.completeJsonpathParams));
            return label(a);
          }
          return null;
        }
      }
      return null;
    }
    return null;
  }

  show(): boolean {
    if (this.calculatedInputConfiguration.hide === true) return false;

    if (this.calculatedInputConfiguration?.condition) {
      const parts = jp.parse(this.utilsService.resolveJsonpath(this.combinedJsonpath, this.completeJsonpathParams));
      const parentParts = parts.slice(0, parts.length - 1);
      const parentJsonpath = jp.stringify(parentParts);
      const parentValue = jp.value(this.object, parentJsonpath);

      return this.calculatedInputConfiguration.condition(this.object, this.combinedJsonpath, this.completeJsonpathParams, parentValue);
    }

    return true;
  }

  isDisabled(): boolean {
    return this.loading || this.disabled || (this.collaborationKey && this.collaborationService.isDisabled(this.collaborationKey)) || (this.users && this.users.length > 0);
  }

  guessInputType(): InputType {
    const resolvedJsonpath = this.utilsService.resolveJsonpath(this.combinedJsonpath, this.completeJsonpathParams);
    return this.utilsService.guessInputType(resolvedJsonpath);
  }

  getListChildElementSideBarOpen(key: number | string) {
    return this.listChildElementSideBarOpen[key] || false;
  }

  setListChildElementSideBarOpen(key: number | string, value: boolean) {
    this.listChildElementSideBarOpen[key] = value || false;
  }

  getCalculatedListChildLabel(element: any, index: number, language?: string) {
    return (typeof this.calculatedInputConfiguration.childLabel === 'function' && this.calculatedInputConfiguration.childLabel(element, index, language)) || `Item #${index + 1}`;
  }

  getCalculatedListChildDescription(element: any, index: number, language?: string) {
    return typeof this.calculatedInputConfiguration.childDescription === 'function' ? this.calculatedInputConfiguration.childDescription(element, index, language) : '';
  }

  getCalculatedListChildImage(element: any, index: number) {
    return typeof this.calculatedInputConfiguration.childImage === 'function' ? this.calculatedInputConfiguration.childImage(element, index) : null;
  }

  isFunction(val: any): boolean {
    return typeof val === 'function';
  }

  calculateHeader(): string {
    const resolvedJsonpath = this.utilsService.resolveJsonpath(this.combinedJsonpath, this.completeJsonpathParams);
    const parts = jp.parse(resolvedJsonpath);
    const lastPart = parts[parts.length - 1]?.expression?.value;

    if (typeof lastPart === 'string') {
      return lastPart.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
      });
    }

    return '';
  }

  async init(): Promise<void> {
    this.isListInput = false;
    this.listInputParent = null;

    if (this.collaborationKey && this.resolvedJsonpath) {
      this.collaborationService.unregisterFocus(this.collaborationKey, this.resolvedJsonpath, this.focusCallback);
    }
    if (this.currentLanguage || !this.combinedJsonpath.includes('$language')) {
      this.initialized = false;

      this.completeJsonpathParams = {
        ...this.jsonpathParams,
        language: this.jsonpathParams.language || this.currentLanguage,
      };

      this.header = this.header || this.inputConfiguration?.header || (this.inputs && this.inputs[this.combinedJsonpath]?.header) || this.calculateHeader();
      this.description = this.inputConfiguration?.description || (this.inputs && this.inputs[this.combinedJsonpath]?.description) || this.description || '';
      this.calculatedInputConfiguration = {
        ...{ type: this.guessInputType() },
        ...{ header: this.header },
        ...{ description: this.description },
        ...(this.inputs[this.combinedJsonpath] || {}),
        ...(this.inputConfiguration || {}),
      } as InputConfiguration;

      if (this.calculatedInputConfiguration?.type == 'switch') {
        this.isSwitch = true;
      }

      if (!this.calculatedInputConfiguration.list) {
        this.resolvedJsonpath = this.utilsService.resolveJsonpath(this.combinedJsonpath, this.completeJsonpathParams);
        if (this.collaborationKey) {
          await this.collaborationService.ensurePath(this.collaborationKey, this.object, this.combinedJsonpath, this.completeJsonpathParams, this.inputs);
          this.collaborationService.registerFocus(this.collaborationKey, this.resolvedJsonpath, this.focusCallback);
        }
        this.isListInput = false;
      } else {
        const listInput = this.combinedJsonpath;
        const listFactory = this.inputs[listInput].factory;
        if (!listFactory) {
          throw new Error('List factory not found');
        }
        const childFactory = this.inputs[listInput].childFactory;
        if (!childFactory) {
          throw new Error('Listchild factory not found');
        }

        const childJsonpath = !this.calculatedInputConfiguration.listValue
          ? `${listInput}[$index]`
          : `${listInput}[?(@.${this.calculatedInputConfiguration.listKey}=='$${this.calculatedInputConfiguration.listValue}')]`;

        // Extract list input children (without list input itself, it's represented by it's children)
        let children = Object.keys(this.inputs)
          .filter((i) => `${i}[`.startsWith(`${listInput}[`))
          .filter((a) => a !== this.combinedJsonpath);

        // Remove '....$language' path from list input children ('...$language'-path is not a input)
        children = children.filter((c) => !c.endsWith(`$language`));

        // Remove list inputs from deeper list childs
        children = children.filter((c) => !c.substr(childJsonpath.length).includes('['));

        // ...(this.inputs[this.combinedJsonpath] || {}),
        if (this.calculatedInputConfiguration.type === 'dropdown') {
          this.calculatedChildInputConfiguration = {
            type: 'dropdown',
            dropdownOptions: this.calculatedInputConfiguration.dropdownOptions,
          };
        }

        // List of objects?
        let isObject = false;
        if (children.length > 1) {
          // If list isn't an simple array, it is an array of objects
          isObject = true;
          // Remove array input from child list, only represent object childs
          children = children.filter((c) => c !== childJsonpath);
        }

        // Ensure list property exists in object
        await this.collaborationService.ensurePath(this.collaborationKey, this.object, this.combinedJsonpath, this.completeJsonpathParams, this.inputs);

        // Prepare list input
        this.listInputParent = {
          isObject: isObject,
          indexName: this.inputs[listInput].indexName,
          children: children,
          childJsonpath: childJsonpath,
          childFactory: childFactory,
          element: jp.value(this.object, this.utilsService.resolveJsonpath(this.combinedJsonpath, this.completeJsonpathParams)),
        };
        this.isListInput = true;
      }

      this.initialized = true;
    }
  }

  focus() {
    this.focussed = true;
    if (this.collaborationKey) {
      this.collaborationService.focus(this.collaborationKey, this.resolvedJsonpath);
    }
  }

  blur() {
    this.focussed = false;
    if (this.collaborationKey) {
      this.collaborationService.blur(this.collaborationKey, this.resolvedJsonpath);
    }
  }

  async valueChanged(value: any) {
    if (this.collaborationKey) {
      this.loading = true;

      if (this.successfulTimeout) {
        clearTimeout(this.successfulTimeout);
      }

      try {
        this.successful = await this.collaborationService.patch(this.collaborationKey, this.object, {
          command: 'set',
          jsonpath: this.combinedJsonpath,
          jsonpathParams: this.completeJsonpathParams,
          value: value,
        });

        if (this.languageMode() === 'default-editable') {
          for (const language of this.languages.filter((l) => l !== this.completeJsonpathParams.language)) {
            const s = await this.collaborationService.patch(this.collaborationKey, this.object, {
              command: 'set',
              jsonpath: this.combinedJsonpath,
              jsonpathParams: {
                ...this.completeJsonpathParams,
                language: language,
              },
              value: value,
            });
            console.log(s);
          }
        }

        this.successfulTimeout = setTimeout(() => {
          this.successful = null;
        }, 1500);
      } catch (err) {
        console.error(err);
      }

      this.onValueChanged.emit(value);
      this.loading = false;
    } else {
      const parts = jp.parse(this.resolvedJsonpath);
      const parentPath = jp.stringify(parts.slice(0, parts.length - 1));
      const parent = jp.value(this.object, parentPath);

      if (parts?.length > 0 && parent) {
        const key = parts[parts.length - 1].expression.value;
        parent[key] = value;
        this.onValueChanged.emit(value);
      }
    }
  }

  userName(adminUser: AdminUser) {
    if (adminUser) {
      if (adminUser.lastName?.length >= 1 && adminUser.firstName?.length >= 1) {
        return adminUser.firstName[0] + adminUser.lastName[0];
      }
      if (adminUser.displayName?.length >= 2) {
        return adminUser.displayName.slice(0, 2);
      }
    }

    return '??';
  }

  color(adminUser: AdminUser) {
    const { red, green, blue } = this.utilsService.pastelColor(this.userName(adminUser));
    return `rgb(${red}, ${green}, ${blue})`;
  }

  trackByIndex(index: number) {
    return index;
  }

  trackByIndexForElement(index: number, element: any) {
    return element ? element.valueOf() : null;
  }

  jsonpathParamsWithIndexAndElement(jsonpathParams: { [key: string]: any }, index: number, element: any, indexName?: string) {
    let returnJsonpathParams = {
      ...jsonpathParams,
      [indexName || 'index']: index,
    };
    if (this.calculatedInputConfiguration.listKey) {
      returnJsonpathParams[this.calculatedInputConfiguration.listValue] = element[this.calculatedInputConfiguration.listKey];
    }
    return returnJsonpathParams;
  }

  jsonpathParamsForLanguage(language: string) {
    return {
      ...(this.jsonpathParams || {}),
      language: language,
      override: true,
    };
  }

  async add() {
    if (this.collaborationKey) {
      await this.collaborationService.patch(this.collaborationKey, this.object, {
        command: 'push',
        jsonpath: this.combinedJsonpath,
        jsonpathParams: this.completeJsonpathParams,
        value: await this.listInputParent.childFactory(this.object, this.completeJsonpathParams),
      });
    }
  }

  async remove(index: number, indexName?: string) {
    if (this.collaborationKey) {
      await this.collaborationService.patch(this.collaborationKey, this.object, {
        command: 'delete',
        jsonpath: this.listInputParent.childJsonpath,
        jsonpathParams: {
          ...this.completeJsonpathParams,
          [this.calculatedInputConfiguration.listValue]: this.listInputParent.element[index] ? this.listInputParent.element[index][this.calculatedInputConfiguration.listKey] : null,
          [indexName || 'index']: index,
        },
      });
    }
  }

  async up(index: number, indexName?: string) {
    if (this.collaborationKey) {
      await this.collaborationService.patch(this.collaborationKey, this.object, {
        command: 'up',
        jsonpath: this.listInputParent.childJsonpath,
        jsonpathParams: {
          ...this.completeJsonpathParams,
          [this.calculatedInputConfiguration.listValue]: this.listInputParent.element[index][this.calculatedInputConfiguration.listKey],
          [indexName || 'index']: index,
        },
      });
    }
  }

  async down(index: number, indexName?: string) {
    if (this.collaborationKey) {
      await this.collaborationService.patch(this.collaborationKey, this.object, {
        command: 'down',
        jsonpath: this.listInputParent.childJsonpath,
        jsonpathParams: {
          ...this.completeJsonpathParams,
          [this.calculatedInputConfiguration.listValue]: this.listInputParent.element[index][this.calculatedInputConfiguration.listKey],
          [indexName || 'index']: index,
        },
      });
    }
  }

  async confirmMessageDialogLocalization(event: any, message: string, type: 'localize' | 'globalize') {
    this.confirmationService.confirm({
      key: 'collaborationConfirmPopup',
      target: event.target,
      message: `${message}`,
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        if (type === 'localize') {
          await this.localizeProperty();
          this.translationMode = true;
        }
        if (type === 'globalize') {
          await this.globalizeProperty();
          this.translationMode = false;
        }
      },
      reject: () => {
        // Nothing to do
      },
    });
  }

  async localizeProperty() {
    const jsonpath = this.utilsService.resolveJsonpath(this.combinedJsonpath, {
      ...this.completeJsonpathParams,
      language: '$language',
    });

    const index = (this.object.globalProperties || []).indexOf(jsonpath);

    if (index >= 0) {
      await this.collaborationService.patch(this.collaborationKey, this.object, {
        command: 'delete',
        jsonpath: '$.globalProperties[$index]',
        jsonpathParams: {
          index: index,
        },
      });
    }
  }

  async globalizeProperty() {
    const jsonpath = this.utilsService.resolveJsonpath(this.combinedJsonpath, {
      ...this.completeJsonpathParams,
      language: '$language',
    });
    const index = (this.object.globalProperties || []).indexOf(jsonpath);

    if (index < 0) {
      await this.collaborationService.patch(this.collaborationKey, this.object, {
        command: 'push',
        jsonpath: '$.globalProperties',
        value: jsonpath,
      });
      this.valueChanged(null);
    }
  }
}
