import * as yup from 'yup';

export const i18nDataValidator = yup.string().required();

export const i18nValidator = yup.object({
  language: yup.string().required(),
  ns: yup.string().required(),
  module: yup.string().required(),
  data: yup.lazy((datas) => {
    return yup.object(Object.keys(datas).reduce((a, b) => ({ ...a, [b]: i18nDataValidator }), {}));
  }),
});
