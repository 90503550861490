import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { VideoSliderPageModuleFactory } from './../../factories/pagemodules/VideoSliderPageModuleFactory';
import { EventVersion } from '../../entities/EventVersion';
import { isPage, isPageWithPageModules, Page } from '../../entities/Page';
import { isVideoSliderPageModule, videoSliderTypes } from '../../entities/PageModule';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';

export const videoSliderPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('VideoSlider'),
  "$.pageModules[?(@.pageModuleType=='VideoSlider' && @._id=='$pageModuleId')].ctaButton": {},

  "$.pageModules[?(@.pageModuleType=='VideoSlider' && @._id=='$pageModuleId')].videoSliderType": {
    type: 'dropdown',
    dropdownOptions: videoSliderTypes.map((type) => {
      return { value: type, label: type };
    }),
  },
  "$.pageModules[?(@.pageModuleType=='VideoSlider' && @._id=='$pageModuleId')].keytopic": {
    type: 'dropdown',
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return parent?.videoSliderType === 'Keytopic' || parent?.videoSliderType === 'KeytopicAllEvents';
    },
  },
  "$.pageModules[?(@.pageModuleType=='VideoSlider' && @._id=='$pageModuleId')].event": {
    type: 'event',
    header: 'Event for keytopic sessions (optional)',
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return parent?.videoSliderType === 'Keytopic';
    },
  },
  "$.pageModules[?(@.pageModuleType=='VideoSlider' && @._id=='$pageModuleId')].maxCount": {
    type: 'number',
    header: 'Video max count',
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      return parent?.videoSliderType !== 'Default';
    },
  },
  "$.pageModules[?(@.pageModuleType=='VideoSlider' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new VideoSliderPageModuleFactory().createVideoSliderPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='VideoSlider' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },
  "$.pageModules[?(@.pageModuleType=='VideoSlider' && @._id=='$pageModuleId')].local.$language.videos": {
    factory: async () => [],
    list: true,
    childFactory: async () => null,
    condition: (obj, jsonpath, jsonpathParams, parent) => {
      let page: Page | null | undefined = null;

      if (isPage(obj)) {
        page = obj;
      } else {
        const eventVersion = obj as EventVersion;
        page = eventVersion.phase[jsonpathParams.eventPhase].pages.find((p) => p._id === jsonpathParams.pageId);
      }

      if (isPage(page)) {
        if (isPageWithPageModules(page)) {
          const pageModule = page.pageModules.find((p) => p._id === jsonpathParams.pageModuleId);

          if (isVideoSliderPageModule(pageModule)) {
            return pageModule.videoSliderType === 'Default';
          }
        }
      }

      return false;
    },
  },
  "$.pageModules[?(@.pageModuleType=='VideoSlider' && @._id=='$pageModuleId')].local.$language.videos[$index]": {
    type: 'videoasset',
    header: 'Video',
  },
};
