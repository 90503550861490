import { InputConfiguration } from 'src/common/inputs/Inputs';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UtilsService } from 'src/app/services/utils/utils.service';
import { CollaborationService } from 'src/app/services/collaboration/collaboration.service';

import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { ConversionStagePageModule, ConversionStageTeaserItem, ConversionStageTeaserItemType, conversionStageTeaserItemTypes } from 'src/common/entities/PageModule';
import { ConversionStagePageModuleFactory } from '../../../../common/factories/pagemodules/ConversionStagePageModuleFactory';
import { PagesService } from 'src/app/services/pages/pages.service';

@Component({
  selector: 'c-conversion-stage-page-module-edit',
  templateUrl: './conversion-stage-page-module-edit.component.html',
  styleUrls: ['./conversion-stage-page-module-edit.component.scss'],
})
export class ConversionStagePageModuleEditComponent implements OnInit {
  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: ConversionStagePageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  currentLanguage: string = null;

  inputJsonPathes: string[] = [
    '$.local.$language.title',
    '$.local.$language.subtext',
    '$.local.$language.devices.mobile.title',
    '$.local.$language.devices.mobile.subtext',
    '$.local.$language.devices.tablet.title',
    '$.local.$language.devices.tablet.subtext',
    '$.local.$language.devices.desktop.title',
    '$.local.$language.devices.desktop.subtext',
    '$.local.$language.background',
    '$.ctaButton',
    '$.anchor',
  ];

  designInputJsonPathes: string[] = [
    '$.backgroundTheme',
    '$.stageHeight.desktop',
    '$.stageHeight.desktopLarge',
    '$.stageHeight.desktopWidescreen',
    '$.stageHeight.tablet',
    '$.stageHeight.tabletLarge',
    '$.stageHeight.mobile',
    '$.stageHeight.mobileLarge',
  ];

  itemJsonpath = `$.local.$language.items[?(@._id=='$itemId')]`;

  teaserItemTypes: { value: string; label: string }[] = [];
  newTeaserItemType: ConversionStageTeaserItemType = 'CTA';

  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  pageOptions: { label: string; value: string | null }[];
  domainCollectionId: string;
  newSelectorPage: string = null;

  constructor(
    private collaborationService: CollaborationService,
    private utilsService: UtilsService,
    private activatedRoute: ActivatedRoute,
    private utils: UtilsService,
    private pagesService: PagesService
  ) {}

  async ngOnInit() {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }

    this.activatedRoute.queryParams.subscribe(async (params) => {
      this.currentLanguage = params.language;

      const newDomainCollectionId = params.domainCollectionId || null;

      if (newDomainCollectionId !== this.domainCollectionId || !this.pageOptions) {
        this.pageOptions = (await this.pagesService.getPages(newDomainCollectionId)).items.map((e) => ({
          label: `${e.local['en']?.title || (Object.keys(e.local).length > 0 ? e.local[Object.keys(e.local)[0]]?.title : 'Untitled')} ${e.path ? `(/${e.path})` : ''}`,
          value: e._id,
        }));
      }

      this.domainCollectionId = newDomainCollectionId;
    });

    this.teaserItemTypes = (
      await Promise.all(
        conversionStageTeaserItemTypes.map(async (p) => ({
          value: p,
          label: await this.utils.translate(`GENERAL_TEASERITEMTYPE_${p.toUpperCase()}`),
        }))
      )
    ).sort((a, b) => a.label.localeCompare(b.label));
  }

  isDisabled(): boolean {
    return this.collaborationService.isDisabled(this.collaborationKey);
  }

  trackByIndex(index: number, item: any) {
    return index;
  }

  async addTeaserItem() {
    const item = await new ConversionStagePageModuleFactory().teaserItem({
      teaserItemType: this.newTeaserItemType,
    });

    if (item) {
      await this.collaborationService.patch(this.collaborationKey, this.eventVersion || this.page, {
        command: 'push',
        jsonpath: this.utilsService.combineJsonpath([this.jsonpath, '$.local.$language.items']),
        jsonpathParams: {
          ...this.jsonpathParams,
          language: this.currentLanguage,
        },
        value: item,
      });
    }
  }

  async deleteTeaserItem(item: ConversionStageTeaserItem): Promise<void> {
    await this.collaborationService.patch(this.collaborationKey, this.eventVersion || this.page, {
      command: 'delete',
      jsonpath: this.utilsService.combineJsonpath([this.jsonpath, `$.local.$language.items[?(@._id=='$itemId')]`]),
      jsonpathParams: {
        ...this.jsonpathParams,
        itemId: item._id,
        language: this.currentLanguage,
      },
    });
  }

  async addSelectorPage() {
    await this.collaborationService.patch(this.collaborationKey, this.eventVersion || this.page, {
      command: 'push',
      jsonpath: this.utilsService.combineJsonpath([this.jsonpath, '$.selectorPages']),
      jsonpathParams: {
        ...this.jsonpathParams,
      },
      value: this.newSelectorPage,
    });
    this.newSelectorPage = null;
  }

  deleteSelectorPage(index: number) {
    this.pageModule.selectorPages.splice(index, 1);
    return this.patchSetSelecterPages(this.pageModule.selectorPages);
  }

  setSelectorPage(index: number, pageId: string) {
    const newSelectorPages = this.pageModule.selectorPages.slice();
    newSelectorPages[index] = pageId;

    return this.patchSetSelecterPages(newSelectorPages);
  }

  up(index: number) {
    const newSelectorPages = this.pageModule.selectorPages.slice();
    const item = newSelectorPages[index];
    newSelectorPages.splice(index, 1);
    newSelectorPages.splice(index - 1, 0, item);

    return this.patchSetSelecterPages(newSelectorPages);
  }

  async down(index: number) {
    const newSelectorPages = this.pageModule.selectorPages.slice();
    const item = newSelectorPages[index];
    newSelectorPages.splice(index, 1);
    newSelectorPages.splice(index + 1, 0, item);

    return this.patchSetSelecterPages(newSelectorPages);
  }

  patchSetSelecterPages(newSelectorPages: string[]) {
    return this.collaborationService.patch(this.collaborationKey, this.eventVersion || this.page, {
      command: 'set',
      jsonpath: this.utilsService.combineJsonpath([this.jsonpath, `$.selectorPages`]),
      jsonpathParams: {
        ...this.jsonpathParams,
      },
      value: newSelectorPages,
    });
  }

  itemJsonpathParams(item: ConversionStageTeaserItem) {
    return {
      ...this.jsonpathParams,
      language: this.currentLanguage,
      itemId: item._id,
    };
  }
}
