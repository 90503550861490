import { Component, Input } from '@angular/core';

import { TextEventStage } from 'src/common/entities/EventStage';
import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';

import { Inputs } from 'src/common/inputs/Inputs';
import { eventStageInputs } from 'src/common/inputs/page/EventStagesInputs';

@Component({
  selector: 'c-text-event-stage-edit',
  templateUrl: './text-event-stage-edit.component.html',
  styleUrls: ['./text-event-stage-edit.component.scss'],
})
export class TextEventStageEditComponent {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  eventStage: TextEventStage;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputs: Inputs = eventStageInputs;
  inputJsonpathes: string[] = [
    '$.local.$language.smallHeadline',
    '$.local.$language.text',
    '$.layout',
    '$.showShareButton',
    '$.shareButtonTargets',
    '$.showCalendarButton',
    '$.showCountdown',
    '$.background',
    '$.ctaButton',
  ];

  tab = 'general';
}
