import { PageModule, TitlePositions } from './PageModule';
import { TextAttributes } from './../Attributes';

export interface AccordionPageModule extends PageModule {
  pageModuleType: 'Accordion';
  titlePosition?: TitlePositions;
  elements?: AccordionPageModuleElement[];
  local: {
    [language: string]: {
      title?: string;
      title_attr?: AccordionPageModuleAttributes;
    };
  };
}

export type AccordionPageModuleAttributes = TextAttributes & {};

export interface AccordionPageModuleElement {
  _id: string;
  isExpanded: boolean;
  local: {
    [language: string]: {
      title?: string;
    };
  };
  childElements: AccordionPageModuleChildElement[];
}

export interface AccordionPageModuleChildElement {
  _id: string;
  local: {
    [language: string]: {
      title?: string;
      description: string;
    };
  };
}

export function isAccordionPageModule(object: any): object is AccordionPageModule {
  return object?.pageModuleType === 'Accordion';
}
