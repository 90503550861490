import { Component, Input } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { StreamEventStage } from 'src/common/entities/EventStage';

@Component({
  selector: 'c-stream-event-stage-edit',
  templateUrl: './stream-event-stage-edit.component.html',
  styleUrls: ['./stream-event-stage-edit.component.scss'],
})
export class StreamEventStageEditComponent {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  eventStage: StreamEventStage;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathes: string[] = ['$.local.$language.title', '$.local.$language.smallHeadline', '$.local.$language.background'];

  tab = 'general';
}
