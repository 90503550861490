import { EventVersion, EventVersionChat, EventVersionLanguage, EventVersionPhase, RegistrationCustomFieldAutoFill, RegistrationCustomFieldAutoFillCustomData } from '../entities/EventVersion';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { PageFactory } from './PageFactory';
import { RegistrationCustomFieldFactory } from './RegistrationCustomFieldFactory';
import { SlotFactory } from './SlotFactory';
import { EventTicketFactory } from './EventTicketFactory';
import { PollFactory } from './PollFactory';
import { StageFactory } from './StageFactory';
import { SessionFactory } from './sessions/SessionFactory';
import { EventKeytopicFactory } from './EventKeytopicFactory';
import { EventSlotConfigurationFactory } from './EventSlotConfigurationFactory';
import { MenuItemFactory } from './MenuItemFactory';
import { EventProductFactory } from './EventProductFactory';
import { ContentChecklistGroup, ContentChecklistGroupLocal, ContentChecklistItem, ContentChecklistItemLocal } from '../entities/EventVersion';

export class EventVersionFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createEventVersionChat(values: any) {
    return {
      chat: await this.id(values?.chat),
      internalName: values?.internalName || '',
      autoConfirm: typeof values?.autoConfirm === 'boolean' ? values.autoConfirm : false,
      chatReply: typeof values?.chatReply === 'boolean' ? values.chatReply : false
    } as EventVersionChat;
  }

  /**
   * Only used by EventVersionHelper for Migration of country from event to eventversion
   * @param values
   * @param country
   */
  async createEventVersionWithCountryMigration(values: any, country?: string) {
    return this.createEventVersion({ ...values, country: values?.country || country });
  }

  async createEventVersion(values: any) {
    return {
      ...values,
      _id: await this.id(values?._id),
      globalProperties: values?.globalProperties || [],
      eventTimeZone: values?.eventTimeZone || 'Europe/Berlin',
      publicStream: typeof values?.publicStream === 'boolean' ? values.publicStream : false,
      releaseValid: values?.releaseValid || false,
      announceValid: values?.announceValid || false,
      eventKeytopics: (values?.eventKeytopics || []).map((k: any) => new EventKeytopicFactory(this.options).createEventKeytopic(k)),
      eventProducts: await Promise.all((values?.eventProducts || []).map((p: any) => new EventProductFactory(this.options).createEventProduct(p))),
      regional: values?.regional || {},
      sessions: await Promise.all((values?.sessions || []).map((s: any) => new SessionFactory(this.options).createSession(s))),
      version: values?.version || 0,
      languages: (values?.languages?.length > 0 ? values?.languages : null) || (values?.local && Object.keys(values?.local).length > 0 ? Object.keys(values?.local) : null) || ['en'],
      modifiedAt: values?.modifiedAt || undefined,
      modifiedBy: values?.modifiedBy || undefined,
      chats: await Promise.all((values?.chats || []).map((c: any) => this.createEventVersionChat(c))),
      stages: await Promise.all((values?.stages || []).map((s: any) => new StageFactory(this.options).createStage(s))),
      polls: await Promise.all((values?.polls || []).map((p: any) => new PollFactory(this.options).createPoll(p))),
      event: values?.event || values?.eventId || '',
      dirty: typeof values?.dirty !== 'boolean' ? true : values.dirty,
      images: {
        placeholder: values?.images?.placeholder || undefined,
        teaser: values?.images?.teaser || undefined,
        share: values?.images?.share || undefined,
        stage: values?.images?.stage || undefined,
        explore: values?.images?.explore || undefined,
        stageCompact: values?.images?.stageCompact || undefined,
        stageShowroom: values?.images?.stageShowroom || undefined
      },
      change: values?.change || 0,
      eventSlots: await Promise.all((values?.eventSlots || []).map((s: any) => new SlotFactory(this.options).createSlot(s))),
      eventTicketConfiguration: new EventTicketFactory(this.options).createEventTicketConfiguration(values?.eventTicketConfiguration),
      eventSlotConfiguration: new EventSlotConfigurationFactory(this.options).createEventSlotConfiguration(values?.eventSlotConfiguration),
      eventTicketPools: values?.eventTicketPools || [],
      eventTickets: await Promise.all((values?.eventTickets || []).map((t: any) => new EventTicketFactory(this.options).createEventTicket(t))),
      local: this.localKeys(values?.local).reduce((a, b) => ({ ...a, [b]: this.createEventVersionLanguage(values.local[b]) }), {}),
      phase: {
        ANNOUNCED: await this.createEventVersionPhase(values?.phase?.ANNOUNCED),
        RELEASED: await this.createEventVersionPhase(values?.phase?.RELEASED),
        LIVE: await this.createEventVersionPhase(values?.phase?.LIVE),
        ARCHIVE: await this.createEventVersionPhase(values?.phase?.ARCHIVE)
      },
      country: values?.country || null,
      registrationCustomFields: values?.registrationCustomFields || [].map(c => new RegistrationCustomFieldFactory(this.options).createCustomField(c)),
      eventMaps: values?.eventMaps || [],
      countSessionVideoTotal: values?.countSessionVideoTotal || 0,
      countSessionVideo: values?.countSessionVideo || null,
      contentChecklistTitle: values?.contentChecklistTitle || '',
      contentChecklistGroups: await Promise.all((values?.contentChecklistGroups || []).map((group: any) => this.createContentChecklistGroup(group)))
    } as EventVersion;
  }

  public async createContentChecklistGroup(values: any): Promise<ContentChecklistGroup> {
    return {
      _id: await this.id(values?._id),
      local: await this.local(values, val => this.createContentChecklistGroupLocal(val)),
      items: await Promise.all((values?.items || []).map((item: any) => this.createContentChecklistItem(item)))
    };
  }

  public createContentChecklistGroupLocal(values: any): ContentChecklistGroupLocal {
    return {
      title: values?.title || '',
      description: values?.description || ''
    };
  }

  public async createContentChecklistItem(values: any): Promise<ContentChecklistItem> {
    return {
      _id: await this.id(values?._id),
      local: await this.local(values, val => this.createContentChecklistItemLocal(val)),
      link: values?.link || ''
    };
  }

  public createContentChecklistItemLocal(values: any): ContentChecklistItemLocal {
    return {
      title: values?.title || ''
    };
  }

  async createEventVersionPhase(values: any) {
    return {
      ...values,
      showMeetYourExpertInMenu: typeof values?.showMeetYourExpertInMenu === 'boolean' ? values?.showMeetYourExpertInMenu : false,
      pages: (await Promise.all((values?.pages || []).map((p: any) => new PageFactory(this.options).page(p)))).filter(v => !!v),
      menuItems: (await Promise.all((values?.menuItems || []).map((p: any) => new MenuItemFactory(this.options).menuItem(p)))).filter(v => !!v),
      mobileMenuItems: (await Promise.all((values?.mobileMenuItems || []).map((p: any) => new MenuItemFactory(this.options).menuItem(p)))).filter(v => !!v),
      appMenuItems: (await Promise.all((values?.appMenuItems || []).map((p: any) => new MenuItemFactory(this.options).menuItem(p)))).filter(v => !!v),
      webMenuItems: (await Promise.all((values?.webMenuItems || []).map((p: any) => new MenuItemFactory(this.options).menuItem(p)))).filter(v => !!v),
      appNavigationMenuItems: (await Promise.all((values?.appNavigationMenuItems || []).map((p: any) => new MenuItemFactory(this.options).menuItem(p)))).filter(v => !!v)
    } as EventVersionPhase;
  }

  createEventVersionLanguage(values?: any) {
    return {
      ...values,
      title: values?.title || '',
      longDescription: values?.longDescription || '',
      shortDescription: values?.shortDescription || '',
      linkTitle: values?.linkTitle || null, // intentionally null, instead of empty string, because we use fallback values
      linkDescription: values?.linkDescription || null, // intentionally null, instead of empty string, because we use fallback values
      endAt: values?.endAt || null,
      startAt: values?.startAt || null,
      onDemandOnly: values?.onDemandOnly || false,
      eventSpeakers: values?.eventSpeakers || [],
      actualEndAt: values?.actualEndAt || null,
      actualStartAt: values?.actualStartAt || null,
      city: values?.city || null,
      fullAddress: values?.fullAddress || null,
      mapsLink: values?.mapsLink || null,
      eventRegistration: values?.eventRegistration || {
        dataHeader: values?.dataHeader || '',
        ticketHeader: values?.ticketHeader || '',
        ticketSubheader: values?.ticketSubheader || ''
      }
    } as EventVersionLanguage;
  }

  createRegistrationCustomFieldAutoFill(values?: any): RegistrationCustomFieldAutoFill {
    return {
      ...values,
      ...(() => {
        switch (values.registrationCustomFieldAutoFillType) {
          case 'CustomData':
            return this.createRegistrationCustomFieldAutoFillCustomData(values);
        }

        return this.createRegistrationCustomFieldAutoFillCustomData(values);
      })()
    };
  }

  createRegistrationCustomFieldAutoFillCustomData(values?: any): RegistrationCustomFieldAutoFillCustomData {
    return {
      registrationCustomFieldAutoFillType: 'CustomData',
      customData: values?.customData || null,
      mode: values?.mode || 'EventRegistrationData',
      property: values?.property || null
    } as RegistrationCustomFieldAutoFillCustomData;
  }

  // createEventKeytopicLocal(values?: any) {
  //   return {
  //     ...values,
  //     assets: values?.assets || [],
  //   } as EventVersionLanguage
  // }

  // async createEventKeytopic(values?: any) {
  //   return {
  //     ...values,
  //     keytopic: values?.keytopic || null,
  //     override: values?.override || null,
  //     local: await this.local(values?.local, async (val) => this.createEventKeytopicLocal(val))
  //   } as EventKeytopic
  // }
}
