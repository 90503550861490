export const adminUserTypes = ['AzureADAdminUser', 'LocalAdminUser', 'ServiceAdminUser'] as const;
export type AdminUserType = (typeof adminUserTypes)[number];

export interface AdminUser {
  _id: string;
  oid?: string;
  adminUserType: AdminUserType;
  groups: string[];
  roles: string[];
  blocked?: boolean;
  admin: boolean;
  lastActivity: string;
  password?: string;
  email: string;
  emailLower: string;

  firstName?: string;
  lastName?: string;
  phone?: string;
  user?: string; // Link to Platform User
  organization?: string;
  salesManager?: string; // deprecated

  // calculated
  rights: string[];
  displayName: string;
}

export interface AzureADAdminUser extends AdminUser {
  adminUserType: 'AzureADAdminUser';
  aad: any;
  oid: string;
}

export function isAzureADAdminUser(object: any): object is AzureADAdminUser {
  return object?.adminUserType === 'AzureADAdminUser';
}

export interface LocalAdminUser extends AdminUser {
  adminUserType: 'LocalAdminUser';
  password: string;
}

export function isLocalAdminUser(object: any): object is LocalAdminUser {
  return object?.adminUserType === 'LocalAdminUser';
}

export interface ServiceAdminUserApiKey {
  _id: string;
  preview: string;
  key: string;
  md5: string;
  expiresAt: string;
  createdAt: string;
  createdBy: string;
}

export interface ServiceAdminUser extends AdminUser {
  adminUserType: 'ServiceAdminUser';
  internalName: string;
  description: string;
  apiKeys: ServiceAdminUserApiKey[];

  basicAuth?: {
    password?: string;
  };
}

export function isServiceAdminUser(object: any): object is ServiceAdminUser {
  return object?.adminUserType === 'ServiceAdminUser';
}
