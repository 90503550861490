import { Poll } from './Poll';
import { User } from './User';

export type MessageType = 'TextMessage' | 'PollMessage';
export type MessageState = 'OPEN' | 'CONFIRMED' | 'REJECTED' | 'DELETED';

export interface Message {
  _id: string;
  replyTo?: string;
  originalMessage?: Message | null;

  messageType: MessageType;
  messageState: MessageState;
  counter: number;

  chat: string;
  user?: User;
  adminUser?: string;
  trackingId?: string;
  date: string;
  createdAt: string;
  expert?: boolean;

  confirmed: boolean;
  confirmedBy?: string;
  confirmedAt?: string;
  rejectedBy?: string;
  rejectedAt?: string;
  rejectionReason?: string;

  data: any;

  deletedAt?: string;
}

export interface MessageUser {
  _id: string;
  firstName: string;
  lastName: string;
}

export interface TextMessageData {
  text: string;
}

export interface TextMessage extends Message {
  messageType: 'TextMessage';
  relatedMessage?: Message;
  likes: number;
  data: TextMessageData;
}

export interface PollMessageAnswer {
  option: string;
  count?: number;
}

export interface PollMessage extends Message {
  messageType: 'PollMessage';
  data: {
    poll: Poll;
    answers?: PollMessageAnswer[];
    users?: number;
  };
}

export function isTextMessage(object: any): object is TextMessage {
  return object.messageType === 'TextMessage';
}

export function isPollMessage(object: any): object is PollMessage {
  return object.messageType === 'PollMessage';
}

export function asTextMessage(message: Message): TextMessage | null {
  return isTextMessage(message) ? message : null;
}

export function asPollMessage(message: Message): PollMessage | null {
  return isPollMessage(message) ? message : null;
}
