import { TextAttributes } from './../Attributes';
import { BreakpointValue } from '../Breakpoint';
import { CTAButton } from '../CTAButton';
import { PageModule } from './PageModule';

export const teaserItemTypes = ['Highlight', 'Link', 'CTA'] as const;
export type TeaserItemType = (typeof teaserItemTypes)[number];

export interface TeaserItem {
  _id: string;
  teaserItemType: TeaserItemType;
}

export interface HighlightTeaserItem extends TeaserItem {
  teaserItemType: 'Highlight';
  session: string;
}

export interface LinkTeaserItem extends TeaserItem {
  teaserItemType: 'Link';
  // local: {[language: string] : LinkTeaserItemLocal};
  image: string;
  title: string;
  link: string;
}

export interface LinkTeaserItemLocal {
  image: string;
}

export interface CTATeaserItem extends TeaserItem {
  teaserItemType: 'CTA';
  image: string;
  title: string;
  cta: CTAButton;
}

export type TeaserEventStagePageModuleAttributes = TextAttributes & {};

export interface TeaserEventStagePageModuleLocal {
  smallHeadline: string;
  smallHeadline_attr?: TeaserEventStagePageModuleAttributes;
  title: string;
  title_attr?: TeaserEventStagePageModuleAttributes;
  subtitle: string;
  subtitle_attr?: TeaserEventStagePageModuleAttributes;
  items: TeaserItem[];
  subtext: string;
  background: string;
}

export interface TeaserEventStagePageModule extends PageModule {
  pageModuleType: 'TeaserEventStage';
  ctaButton?: CTAButton;
  local: { [language: string]: TeaserEventStagePageModuleLocal };
  ctalist?: TeaserEventStageButton[];
  ctalistInteraction?: TeaserEventStageButton[];
  showCountdown: boolean;
  discoverMore?: boolean;
  stageHeight: BreakpointValue<number>;
  buttonSpacingTopBreakpoint: BreakpointValue<number>;
}

export interface TeaserEventStageButton {
  _id: string;
  ctaButton: CTAButton;
}

export function isHighlightTeaserItem(object: any): object is HighlightTeaserItem {
  return object?.teaserItemType === 'Highlight';
}

export function isLinkTeaserItem(object: any): object is LinkTeaserItem {
  return object?.teaserItemType === 'Link';
}

export function isCTATeaserItem(object: any): object is CTATeaserItem {
  return object?.teaserItemType === 'CTA';
}

export function isTeaserEventStagePageModule(object: any): object is TeaserEventStagePageModule {
  return object?.pageModuleType === 'TeaserEventStage';
}
