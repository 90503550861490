import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { Reaction } from '../entities/Reaction';

export class ReactionFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createReaction(values?: any): Promise<Reaction> {
    return {
      _id: await this.id(values?._id),
      event: values?.event || null,
      stream: values?.stream || null,
      internalName: values?.internalName || '',
      background: values?.background || null,
      backgroundColor: values?.backgroundColor || null,
      maxParallelConnections: values?.maxParallelConnections || 100,
    } as Reaction;
  }
}
