import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Pipe({
  name: 'dropdownOptions',
})
export class DropdownOptionsPipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}

  transform(values: string[], ...args: string[]): Promise<{ name: string; code: string }[]> {
    return this.utilsService.createDropdownOptions(values, args[0]);
  }
}
