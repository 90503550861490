<div class="panel">
  <div class="panel-header">Main Properties</div>
  <div class="panel-body">
    <c-event-patch-input jsonpath="$.local.$language.title" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.local.$language.shortDescription" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.local.$language.longDescription" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.local.$language.startAt" [eventVersion]="eventVersion" [inputConfiguration]="inputConfigurationEventStartAt"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.local.$language.endAt" [eventVersion]="eventVersion" [inputConfiguration]="inputConfigurationEventEndAt"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.eventTimeZone" [eventVersion]="eventVersion" [inputConfiguration]="inputConfigurationEventTimeZone"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.publicStream" [eventVersion]="eventVersion"></c-event-patch-input>

    <c-event-patch-input jsonpath="$.images.placeholder" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.images.teaser" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.images.stage" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.images.explore" [eventVersion]="eventVersion"></c-event-patch-input>
  </div>
</div>

<div class="panel">
  <div class="panel-header">
    {{ 'EVENT_LOCATION_CONFIGURATIONS' | translate }}
  </div>
  <div class="panel-body">
    <c-event-patch-input jsonpath="$.country" [eventVersion]="eventVersion" [inputConfiguration]="inputConfigurationCountry" placeholder="None"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.local.$language.city" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.local.$language.fullAddress" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.local.$language.mapsLink" [eventVersion]="eventVersion"></c-event-patch-input>
  </div>
</div>

<div class="panel">
  <div class="panel-header">
    {{ 'LINK_PREVIEW_CONFIGURATIONS' | translate }}
  </div>
  <div class="panel-body">
    <c-event-patch-input
      jsonpath="$.local.$language.linkTitle"
      [eventVersion]="eventVersion"
      [inputConfiguration]="inputConfigurationLinkTitle"
      [placeholder]="linkTitlePlaceholder"
      placeholderDescription="PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_LINKTITLE_PLACEHOLDER_DESCRIPTION"
      [showPlaceholderDescription]="!eventVersion.local[currentLanguage]?.linkTitle"
    ></c-event-patch-input>
    <c-event-patch-input jsonpath="$.images.share" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input
      jsonpath="$.local.$language.linkDescription"
      [eventVersion]="eventVersion"
      [inputConfiguration]="inputConfigurationLinkDescription"
      [placeholder]="linkDescriptionPlaceholder"
      placeholderDescription="PAGE_EVENT_SECTION_BASIC_INFORMATION_LOCAL_LINKDESCRIPTION_PLACEHOLDER_DESCRIPTION"
      [showPlaceholderDescription]="!eventVersion.local[currentLanguage]?.linkDescription"
    ></c-event-patch-input>
  </div>
</div>

<div class="panel">
  <div class="panel-header">
    {{ 'PAGE_EVENT_CONTENT_CHECKLIST' | translate }}
  </div>
  <div class="panel-body">
    <c-event-patch-input jsonpath="$.local.$language.contentChecklistTitle" [eventVersion]="eventVersion"></c-event-patch-input>
    <c-event-patch-input jsonpath="$.contentChecklistGroups" [eventVersion]="eventVersion"></c-event-patch-input>
  </div>
</div>
