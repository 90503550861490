import { speakerDisplayTypes, viewTypes } from '../../entities/pagemodules/AgendaPageModule';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';
import { shareCtaTargets } from '../../entities/CTAButton';

export const gridPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('Grid'),
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].viewType": {
    type: 'dropdown',
    dropdownOptions: [...viewTypes].map((o) => ({ label: o, value: o })),
  },
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].gridType": {
    type: 'dropdown',
    dropdownOptions: [
      { value: '3grid', label: '3 Grid' },
      { value: '2grid', label: '2 Grid without chat' },
      { value: '2grid_without_agenda', label: '2 Grid without agenda' },
    ],
  },

  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].initGrid": {
    type: 'dropdown',
    dropdownOptions: [
      { value: 'agenda', label: 'Init Agenda' },
      { value: 'middle', label: 'Init Middle' },
      { value: 'chat', label: 'Init Chat' },
    ],
  },

  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].speakerDisplayType": {
    type: 'dropdown',
    dropdownOptions: [...speakerDisplayTypes].map((a) => ({ label: a, value: a })),
  },
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].showFilterOptions": {
    header: 'Show Filter Options',
  },
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].showAgendaCalendarButton": {
    header: 'Show Agenda Calendar Button',
  },
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].showAgendaShareButton": {
    header: 'Show Agenda Share Button',
  },
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].agendaShareButtonTargets": {
    type: 'multiselect',
    header: 'Agenda share targets',
    description: 'If none is selected, the module will display all possible targets.',
    placeholder: 'All targets',
    showToggleAll: true,
    multiselectOptions: [...shareCtaTargets].map((a) => ({ label: a, value: a })),
  },
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].showCurrentTime": {
    header: 'Show Current Time',
  },
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].showBookmarkButton": {
    header: 'Show Bookmark Button',
  },
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].showDescription": {
    header: 'Show Description',
    type: 'switch',
  },
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].showTime": {
    header: 'Show Time',
  },
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].showCalendarButton": {
    header: 'Show Calendar Button',
  },
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].showShareButton": {
    header: 'Show Share Button',
  },
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].shareButtonTargets": {
    type: 'multiselect',
    header: 'Share targets',
    description: 'If none is selected, the module will display all possible targets.',
    placeholder: 'All targets',
    showToggleAll: true,
    multiselectOptions: [...shareCtaTargets].map((a) => ({ label: a, value: a })),
  },
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].showRelatedDocuments": {
    header: 'Always show session related documents',
    type: 'switch',
  },

  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].showReactions": {
    header: 'Enable Quick Reactions',
    type: 'switch',
  },

  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].parallelReactions": {
    header: 'Max. parallel Quick Reactions',
    type: 'number',
  },
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].sticky": {
    header: 'Sticky',
    type: 'switch',
  },
  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].chatWithoutLogin": {
    header: 'Enable chat without login',
    description: 'If enabled, this allows an attendee to use the chat without being logged in. Otherwise a login is required to attend to the chat.',
    type: 'switch',
  },

  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].showPolls": {
    header: 'Show Polls',
    type: 'switch',
  },

  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].showCountdown": {
    header: 'Show Countdown',
    type: 'switch',
  },

  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].showStickyPlayerOnMobile": {
    header: 'Show Sticky Player Mobile',
    type: 'switch',
  },

  "$.pageModules[?(@.pageModuleType=='Grid' && @._id=='$pageModuleId')].showMapButton": {
    type: 'switch',
  },
};
