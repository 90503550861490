import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { ListPageModuleFactory } from '../../factories/pagemodules/ListPageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';
import { contentPosition } from './../../../common/entities/PageModule';

export const listPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('List'),
  "$.pageModules[?(@.pageModuleType=='List' && @._id=='$pageModuleId')].ctaButton": {},

  "$.pageModules[?(@.pageModuleType=='List' && @._id=='$pageModuleId')].contentPosition": {
    type: 'dropdown',
    dropdownOptions: [...contentPosition].map(a => ({ label: a, value: a }))
  },

  "$.pageModules[?(@.pageModuleType=='List' && @._id=='$pageModuleId')].local.$language.itemIcon": {
    type: 'imageasset'
  },

  "$.pageModules[?(@.pageModuleType=='List' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new ListPageModuleFactory().listPageModuleLocal({})
  },
  "$.pageModules[?(@.pageModuleType=='List' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: obj => {
        return obj;
      }
    }
  },
  "$.pageModules[?(@.pageModuleType=='List' && @._id=='$pageModuleId')].local.$language.items": {
    factory: async () => [],
    list: true,
    childFactory: async () => ''
  },
  "$.pageModules[?(@.pageModuleType=='List' && @._id=='$pageModuleId')].local.$language.items[$index]": {
    header: 'Item'
  }
};
