<div *ngIf="table">
  <p-table [value]="rows()">
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let columnTitle of this.table.columns">{{ columnTitle | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td *ngFor="let columnTitle of this.table.columns">
          <div class="table-cell"></div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div *ngIf="!table">
  <div *ngIf="menu" class="menu">
    <div class="menu-item menu-item-1"></div>
    <div class="menu-item menu-item-2"></div>
    <div class="menu-item menu-item-3"></div>
  </div>
  <div class="fields">
    <div *ngIf="menu">
      <h2>
        <div class="menu-header"></div>
      </h2>
    </div>
    <div *ngFor="let field of fields" class="field" [ngClass]="field">
      <div class="p-grid">
        <div class="p-xs-12 p-md-4 p-lg-3">
          <div>
            <div class="title"></div>
          </div>
          <div>
            <div class="description"></div>
          </div>
        </div>
        <div class="p-xs-12 p-md-8 p-lg-9">
          <div class="input"></div>
        </div>
      </div>
    </div>
  </div>
</div>
