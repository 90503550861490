import { BookmarksPageModule } from './../../../../common/entities/pagemodules/BookmarksPageModule';

import { Component, Input, OnInit } from '@angular/core';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Event } from 'src/common/entities/Event';
import { Page } from 'src/common/entities/Page';
import { InputConfiguration } from 'src/common/inputs/Inputs';

@Component({
  selector: 'app-bookmarks-page-module-edit',
  templateUrl: './bookmarks-page-module-edit.component.html',
  styleUrls: ['./bookmarks-page-module-edit.component.scss']
})
export class BookmarksPageModuleEditComponent implements OnInit {

  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: BookmarksPageModule;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  tab = 'general';

  accessInputConfiguration: InputConfiguration;

  inputJsonpathes: string[] = ['$.local.$language.title', '$.bookmarkType'];


  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
  }

}
