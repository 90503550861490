<div class="sidebar-search">
  <div class="search-input input-group compact">
    <div class="input">
      <input #searchInput placeholder="Search Operating Regions ..." pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
    </div>
  </div>
  <div class="search-results">
    <div class="search-result" *ngFor="let operatingRegion of searchResult" (click)="select(operatingRegion)">
      <c-operating-region-item [operatingRegion]="operatingRegion"></c-operating-region-item>
    </div>
  </div>
</div>
