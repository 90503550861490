import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { EventsService } from 'src/app/services/events/events.service';
import { PagesService } from 'src/app/services/pages/pages.service';
import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page, PageVersion } from 'src/common/entities/Page';
import { Preview } from 'src/common/entities/Preview';
import { Inputs } from 'src/common/inputs/Inputs';
import { DomainService } from '../../services/domain/domain.service';

@Component({
  selector: 'c-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit {
  @Input()
  event?: Event;

  @Input()
  page?: Page;

  @Input()
  eventVersion?: EventVersion;

  @Input()
  pageVersion?: PageVersion;

  @Input()
  mode: 'generate' | 'select' = 'generate';

  @Input()
  type: 'event' | 'page' = 'event';

  @Output()
  selected: EventEmitter<Preview> = new EventEmitter<Preview>();

  @ViewChild('generatedPreviewInput')
  private generatedPreviewInput: ElementRef;

  @ViewChildren('previewInputs')
  private previewInputs: QueryList<ElementRef>;

  previousPreviews: Preview[] = [];
  previewState: {
    [id: string]: {
      open: boolean;
      link: string;
    };
  } = {};
  working = true;

  newPreview: {
    startDate: Date;
    expiredIn: string;
  } = {
    startDate: new Date(),
    expiredIn: (60 * 60 * 24).toString(),
  };

  generatedPreview: Preview;

  inputs: Inputs = {
    '$.startDate': {
      type: 'datetime',
      description: 'Select a starting date for the preview',
      datetimeOptions: {
        showSeconds: true,
      },
    },
    '$.expiredIn': {
      header: 'Link expires in',
      description: 'How long should the preview link be valid',
      type: 'dropdown',
      dropdownOptions: [
        { label: '1 Hour', value: (60 * 60).toString() },
        { label: '1 Day', value: (60 * 60 * 24).toString() },
        { label: '3 Days', value: (60 * 60 * 24 * 3).toString() },
        { label: '1 Week', value: (60 * 60 * 24 * 7).toString() },
        { label: '1 Month', value: (60 * 60 * 24 * 31).toString() },
        { label: '3 Month', value: (60 * 60 * 24 * 31 * 3).toString() },
        { label: '6 Month', value: (60 * 60 * 24 * 31 * 6).toString() },
      ],
    },
  };

  constructor(private eventsService: EventsService, private pageService: PagesService, private domainService: DomainService) {}

  async ngOnInit() {
    await this.load();
    this.working = false;
  }

  async load() {
    let previousPreviews: Preview[] = [];
    if (this.type === 'event') {
      previousPreviews = (
        await this.eventsService.getPreviews(this.eventVersion.event, {
          limit: 20,
          filter: {
            eventVersion: {
              matchMode: 'equalsObjectId',
              value: this.eventVersion._id,
            },
          },
        })
      ).items;
    } else if (this.type === 'page') {
      previousPreviews = (
        await this.pageService.getPreviews(this.pageVersion.page, {
          limit: 20,
          filter: {
            pageVersion: {
              matchMode: 'equalsObjectId',
              value: this.pageVersion._id,
            },
          },
        })
      ).items;
    }

    for (const preview of previousPreviews) {
      this.previewState[preview._id] = this.previewState[preview._id] || {
        open: false,
        link: this.previewLink(preview),
      };
    }

    this.previousPreviews = previousPreviews;
  }

  async createPreview() {
    this.working = true;

    try {
      if (this.generatedPreview) {
        await this.load();
      }

      if (this.type === 'event') {
        this.generatedPreview = await this.eventsService.createPreview(this.eventVersion.event, {
          eventVersion: this.eventVersion._id,
          startDate: this.newPreview.startDate.toISOString(),
          persist: true,
          expiredIn: parseInt(this.newPreview.expiredIn),
        });
      } else if (this.type === 'page') {
        this.generatedPreview = await this.pageService.createPreview(this.pageVersion.page, {
          pageVersion: this.pageVersion._id,
          startDate: this.newPreview.startDate.toISOString(),
          persist: true,
          expiredIn: parseInt(this.newPreview.expiredIn),
        });
      }

      this.previewState[this.generatedPreview._id] = {
        open: false,
        link: this.previewLink(this.generatedPreview),
      };

      setTimeout(() => {
        this.generatedPreviewInput?.nativeElement?.focus();
        this.generatedPreviewInput?.nativeElement?.select();
      }, 50);
    } catch (err) {
      console.error(err);
    }

    this.working = false;
  }

  previewLink(preview: Preview) {
    const url = this.domainService.findFirstFrontendDomain(this.type === 'page' ? this.page.domainCollection : undefined);

    if (this.type === 'page') {
      return `${url}/${this.page.path || this.page._id}?preview=${preview.token}`;
    }

    return `${url}/events/${this.event.shortId || this.event._id}?preview=${preview.token}`;
  }

  copyLink(input?: number) {
    if (typeof input === 'number') {
      const previewInput = this.previewInputs.get(input);

      if (previewInput) {
        previewInput.nativeElement.focus();
        previewInput.nativeElement.select();
        document.execCommand('copy');
      }
    } else {
      this.generatedPreviewInput.nativeElement.focus();
      this.generatedPreviewInput.nativeElement.select();
      document.execCommand('copy');
    }
  }
}
