import { textAttributesInputs } from './../attributes/TextAttributesInputs';
import { EventVersion } from './../../entities/EventVersion';
import { isPage, isPageWithPageModules, Page } from './../../entities/Page';
import { ProductListPageModuleFactory } from './../../factories/pagemodules/ProductListPageModuleFactory';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';
import { isProductListPageModule, ProductListPageModuleGroup, ProductListPageModuleSeries, titlePositions } from './../../entities/PageModule';
import * as jp from 'jsonpath';

export const productListPageModulesInputs: Inputs = {
  ...commonPageModuleInputs('ProductList'),
  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].showHeadline": {
    type: 'switch',
  },

  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].ctaButton": {},

  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new ProductListPageModuleFactory().productListPageModuleLocal({}),
  },

  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: (obj) => {
        return obj;
      },
    },
  },

  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [...titlePositions].map((item) => ({
      label: item,
      value: item,
    })),
  },

  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].groups": {
    factory: async () => [],
    list: true,
    listKey: '_id',
    listValue: 'elementId',
    childFactory: async () => new ProductListPageModuleFactory().productListPageModuleGroup({}),
    childLabel: (item: ProductListPageModuleGroup, index: number, lang?: string) => item?.local[lang ? lang : 'en']?.title  as string,
  },

  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].local.$language": {
    factory: async () => new ProductListPageModuleFactory().productListPageModuleLocal({}),
  },

  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].local.$language.title": {},

  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].series": {
    factory: async () => [],
    list: true,
    listKey: '_id',
    listValue: 'childElementId',
    childFactory: async () => new ProductListPageModuleFactory().productListPageModuleSeries({}),
    childLabel: (item: ProductListPageModuleSeries, index: number, lang?: string) => item?.local[lang ? lang : 'en']?.title as string,
  },
  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].series[?(@._id=='$childElementId')].local.$language": {
    factory: async () => new ProductListPageModuleFactory().productListPageModuleLocal({}),
  },
  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].series[?(@._id=='$childElementId')].local.$language.title": {},

  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].series[?(@._id=='$childElementId')].products": {
    factory: async () => [],
    list: true,
    listKey: '_id',
    listValue: 'productId',
    childFactory: async () => new ProductListPageModuleFactory().productListPageModuleProducts({}),
  },

  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].series[?(@._id=='$childElementId')].products[?(@._id=='$productId')].local.$language": {
    factory: async () => new ProductListPageModuleFactory().productListPageModuleProductLocal({}),
  },

  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].series[?(@._id=='$childElementId')].products[?(@._id=='$productId')].local.$language.title":
    {
      type: 'html',
    },

  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].series[?(@._id=='$childElementId')].products[?(@._id=='$productId')].local.$language.image":
    {},

  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].series[?(@._id=='$childElementId')].products[?(@._id=='$productId')].local.$language.linkType":
    {
      type: 'dropdown',
      dropdownOptions: [
        { label: 'None', value: null },
        { label: 'External Link', value: 'externallink' },
      ],
    },

  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].series[?(@._id=='$childElementId')].products[?(@._id=='$productId')].local.$language.link":
    {
      condition: (obj, jsonpath, jsonpathParams) => {
        let page: Page | null | undefined = null;

        if (isPage(obj)) {
          page = obj;
        } else {
          const eventVersion = obj as EventVersion;
          page = eventVersion.phase[jsonpathParams.eventPhase].pages.find((p) => p._id === jsonpathParams.pageId);
        }

        if (isPage(page)) {
          if (isPageWithPageModules(page)) {
            const pageModule = page.pageModules.find((p) => p._id === jsonpathParams.pageModuleId);
            if (isProductListPageModule(pageModule)) {
              if (jsonpathParams.productId) {
                return (
                  jp.query(
                    pageModule,
                    `$.groups[?(@._id=='${jsonpathParams.elementId}')].series[?(@._id=='${jsonpathParams.childElementId}')].products[?(@._id=='${jsonpathParams.productId}')].local.${jsonpathParams.language}.linkType`
                  )[0] === 'externallink'
                );
              }
              return false;
            }
          }
        }

        return false;
      },
    },

  "$.pageModules[?(@.pageModuleType=='ProductList' && @._id=='$pageModuleId')].productImageSize": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Default', value: 'default' },
      { label: 'large (x1.5)', value: 'large' },
      { label: 'extra large (x2)', value: 'extra_large' },
    ],
  },
};
