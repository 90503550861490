import { Inputs } from '../Inputs';
import { eventVersionChatsInputs } from './EventVersionChatsInputs';
import { eventVersionLocalInputs } from './EventVersionLocalInputs';
import { eventVersionPhaseInputs } from './EventVersionPhaseInputs';
// import { eventVersionPollsInputs } from "./EventVersionPollsInputs";
import { eventVersionStagesInputs } from './EventVersionStagesInputs';
import { eventVersionSessionsInputs } from './EventVersionSessionsInputs';
import { eventVersionSlotsInputs } from './EventVersionSlotsInputs';
import { eventVersionTicketsInputs } from './EventVersionTicketsInputs';
import { eventVersionKeytopicsInputs } from './EventVersionKeytopicsInputs';
import { eventVersionReactionsInputs } from './EventVersionReactionsInputs';
import { eventVersionContentChecklistInputs } from './EventVersionContentChecklistInputs';

export const eventVersionInputs: Inputs = {
  ...eventVersionLocalInputs,
  ...eventVersionChatsInputs,
  ...eventVersionReactionsInputs,
  // ...eventVersionPollsInputs,
  ...eventVersionStagesInputs,
  ...eventVersionPhaseInputs,
  ...eventVersionTicketsInputs,
  ...eventVersionSlotsInputs,
  ...eventVersionSessionsInputs,
  ...eventVersionKeytopicsInputs,
  ...eventVersionContentChecklistInputs,
  '$.country': {
    header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_COUNTRY',
    description: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_COUNTRY_DESCRIPTION',
    type: 'dropdown'
  },
  '$.languages': {
    header: 'Languages',
    description: 'Event platform languages',
    type: 'dropdown'
  },
  '$.images.share': {
    header: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_PREVIEW_IMAGE',
    description: 'PAGE_EVENT_SECTION_BASIC_INFORMATION_PREVIEW_IMAGE_DESCRIPTION',
    type: 'imageasset'
  },
  '$.images.placeholder': {
    header: 'PAGE_EVENT_SECTION_MAINSTAGE_GLOBAL_IMAGES_PLACEHOLDER',
    description: 'PAGE_EVENT_SECTION_MAINSTAGE_GLOBAL_IMAGES_PLACEHOLDER_DESCRIPTION',
    type: 'imageasset'
  },
  '$.images.teaser': {
    header: 'PAGE_EVENT_SECTION_MAINSTAGE_GLOBAL_IMAGES_TEASER',
    description: 'PAGE_EVENT_SECTION_MAINSTAGE_GLOBAL_IMAGES_TEASER_DESCRIPTION',
    type: 'imageasset'
  },
  '$.images.stage': {
    header: 'PAGE_EVENT_SECTION_MAINSTAGE_GLOBAL_IMAGES_STAGE',
    description: 'PAGE_EVENT_SECTION_MAINSTAGE_REGIONAL_TEASERS_IMAGE_RATIO_DESCRIPTION',
    type: 'imageasset'
  },
  '$.images.explore': {
    header: 'PAGE_EVENT_SECTION_MAINSTAGE_GLOBAL_IMAGES_EXPLORE',
    description: 'PAGE_EVENT_SECTION_MAINSTAGE_GLOBAL_IMAGES_EXPLORE_DESCRIPTION',
    type: 'imageasset'
  },
  '$.publicStream': {
    header: 'Public Stream',
    description: 'Should the stream be public available (tmp solution, AccessPolicies will handle that in future)',
    type: 'switch'
  }
};
