<div class="sidebar-tabs">
  <button [class.active]="tab === 'general'" (click)="tab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
  <button [class.active]="tab === 'videos'" (click)="tab = 'videos'">{{ 'GENERAL_VIDEOS' | translate }}</button>
  <button [class.active]="tab === 'filter'" (click)="tab = 'filter'">{{ 'GENERAL_FILTER' | translate }}</button>
  <button [class.active]="tab === 'sorting'" (click)="tab = 'sorting'">{{ 'GENERAL_SORTING' | translate }}</button>
  <button [class.active]="tab === 'design'" (click)="tab = 'design'">{{ 'GENERAL_DESIGN' | translate }}</button>
  <button [class.active]="tab === 'accesspolicy'" (click)="tab = 'accesspolicy'">{{ 'GENERAL_CONDITIONS' | translate }}</button>
</div>

<div *ngIf="tab == 'general'" class="sidebar-body">
  <c-page-patch-input
    *ngFor="let inputJsonpath of inputJsonpathesGeneral"
    [jsonpath]="[jsonpath, inputJsonpath]"
    [jsonpathParams]="jsonpathParams"
    [object]="eventVersion || page"
  ></c-page-patch-input>
  <p-accordion>
    <p-accordionTab header="TEASER CONFIGURATION">
      <c-page-patch-input
        *ngFor="let inputJsonpath of inputJsonpathesGeneralTEASERCONFIGURATION"
        [jsonpath]="[jsonpath, inputJsonpath]"
        [jsonpathParams]="jsonpathParams"
        [object]="eventVersion || page"
      ></c-page-patch-input>
    </p-accordionTab>
    <p-accordionTab header="DISCOVER MORE">
      <c-page-patch-input
        *ngFor="let inputJsonpath of inputJsonpathesGeneralDISCOVERMORE"
        [jsonpath]="[jsonpath, inputJsonpath]"
        [jsonpathParams]="jsonpathParams"
        [object]="eventVersion || page"
      ></c-page-patch-input>
    </p-accordionTab>
    <p-accordionTab header="NAVIGATION">
      <c-page-patch-input [jsonpath]="[jsonpath, '$.navigationType']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
      <ng-container *ngIf="pageModule.navigationType === 'pagination'">
        <c-page-patch-input
          *ngFor="let inputJsonpath of inputJsonPathesPagination"
          [jsonpath]="[jsonpath, inputJsonpath]"
          [jsonpathParams]="jsonpathParams"
          [object]="eventVersion || page"
        ></c-page-patch-input>
      </ng-container>

      <ng-container *ngIf="pageModule.navigationType === 'loadMore'">
        <c-page-patch-input
          *ngFor="let inputJsonpath of inputJsonPathesLoadMore"
          [jsonpath]="[jsonpath, inputJsonpath]"
          [jsonpathParams]="jsonpathParams"
          [object]="eventVersion || page"
        ></c-page-patch-input>
      </ng-container>
    </p-accordionTab>
  </p-accordion>
</div>

<div *ngIf="tab == 'videos'" class="sidebar-body">
  <c-page-patch-input
    *ngFor="let inputJsonpath of inputJsonpathesVideos"
    [jsonpath]="[jsonpath, inputJsonpath]"
    [jsonpathParams]="jsonpathParams"
    [object]="eventVersion || page"
    [inputConfiguration]="inputJsonpath === '$.keytopics' ? inputConfigurationKeytopic : inputJsonpath === '$.event' ? { type: 'event' } : {}"
  ></c-page-patch-input>
</div>

<div *ngIf="tab == 'filter'" class="sidebar-body">
  <c-page-patch-input
    *ngFor="let inputJsonpath of inputJsonpathesFilter"
    [jsonpath]="[jsonpath, inputJsonpath]"
    [jsonpathParams]="jsonpathParams"
    [object]="eventVersion || page"
    [inputConfiguration]="inputJsonpath === '$.languages' ? inputConfigurationLanguages : {}"
  ></c-page-patch-input>
</div>

<div *ngIf="tab == 'sorting'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.defaultSortingField']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.defaultSortingDirection']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <span class="speaker-border"></span>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.sortingOptions']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'design'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.backgroundTheme']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.teaserAlignment']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.showResultLabel']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingTop']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingBottom']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingLeft']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
  <c-page-patch-input [jsonpath]="[jsonpath, '$.paddingRight']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page"></c-page-patch-input>
</div>

<div *ngIf="tab == 'accesspolicy'" class="sidebar-body">
  <c-page-patch-input [jsonpath]="[jsonpath, '$.accessPolicy']" [jsonpathParams]="jsonpathParams" [object]="eventVersion || page" [inputConfiguration]="accessInputConfiguration"></c-page-patch-input>
</div>
