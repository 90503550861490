import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { EventsService } from 'src/app/services/events/events.service';
import { debounceTime } from 'rxjs/operators';
import { Event } from '../../../common/entities/Event';
import { Asset } from '../../../common/entities/Asset';
import { AssetsService } from '../../services/assets/assets.service';
import { ActivatedRoute } from '@angular/router';
import { IFilterList } from '../../../common/api/interfaces';

@Component({
  selector: 'c-event-select',
  templateUrl: './event-select.component.html',
  styleUrls: ['./event-select.component.scss']
})
export class EventSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  onBlur?: Function;

  @Input()
  onFocus?: Function;

  @Input()
  disabled = false;

  @Input()
  value: string;

  @Input()
  hideRemoveLink: boolean;

  @Input()
  refreshManually: boolean;

  @Input()
  templateSearch: boolean;

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  showSearch: boolean = false;
  searchText: string;
  searchTextSubject: Subject<string> = new Subject<string>();

  selectedEvent: Event;
  allEventTypes: { [id: string]: string } = {};
  allTeaserAssets: { [id: string]: Asset } = {};
  searchResult: Event[] = [];
  currentPage: number = 0;
  assetsPerPage = 20;
  isLoading = false;
  private subscriptions: Subscription[] = [];

  constructor(private eventsService: EventsService, private assetsService: AssetsService, private activatedRoute: ActivatedRoute) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    this.eventsService.getEventTypes().then(r => r.items.forEach(et => (this.allEventTypes[et._id] = et.internalName)));

    this.subscriptions.push(this.searchTextSubject.pipe(debounceTime(500)).subscribe(() => this.executeSearch(true)));

    this.executeSearch();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.value && changes.value.currentValue !== changes.value.previousValue) {
      this.setCurrentEvent();
    } else {
      if ((!changes.value || !changes.value.currentValue) && this.hideRemoveLink && (!changes?.disabled || !changes?.disabled.previousValue)) {
        this.openSearch();
      }
    }
  }

  isCurrentEvent() {
    return this.activatedRoute.snapshot.params.eventId === this.value;
  }

  setCurrentEvent() {
    if (this.value) {
      this.eventsService.getEvent(this.value).then(se => {
        this.selectedEvent = se;

        if (se.currentEventVersion?.images?.teaser) {
          this.subscriptions.push(this.assetsService.getAsset(se.currentEventVersion.images.teaser).subscribe(ti => (this.allTeaserAssets[se._id] = ti)));
        }
      });
    } else {
      this.selectedEvent = null;
    }
  }

  searchTextKeyUp() {
    this.searchTextSubject.next(this.searchText);
  }

  executeSearch(clear = false) {
    if (this.isLoading) return;

    this.isLoading = true;

    setTimeout(() => {
      let filter: IFilterList | null = null;

      if (this.searchText) {
        if (clear) {
          this.searchResult = [];
          this.currentPage = 0;
        }
        filter = {
          internalName: {
            matchMode: 'contains',
            value: this.searchText,
            caseInsensitive: true
          },
          ...(this.templateSearch ? { template: { matchMode: 'equals', value: true } } : {})
        };
      } else if (this.templateSearch) {
        filter = {
          template: { matchMode: 'equals', value: true }
        };
      }

      this.eventsService
        .getEvents({
          limit: this.assetsPerPage,
          skip: this.assetsPerPage * this.currentPage++,
          ...(filter ? { filter } : {})
        })
        .then(r => {
          r.items.forEach(e => {
            this.searchResult.push(e);
            if (e._id && !this.allTeaserAssets[e._id] && e.currentEventVersion?.images?.teaser) {
              this.subscriptions.push(this.assetsService.getAsset(e.currentEventVersion.images.teaser).subscribe(ti => (this.allTeaserAssets[e._id] = ti)));
            }
          });
        })
        .finally(() => (this.isLoading = false));
    }, 150);
  }

  selectEvent(event: Event) {
    this.showSearch = false;
    this.value = event._id;
    this.valueChange.emit(this.value);

    if (this.refreshManually) {
      this.setCurrentEvent();
    }
  }

  removeEvent() {
    this.value = null;
    this.valueChange.emit(this.value);

    if (this.refreshManually) {
      this.setCurrentEvent();
    }
  }

  openSearch() {
    if (!this.disabled) {
      this.showSearch = true;
    }
  }
}
