import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Pipe({
  name: 'localize',
})
export class LocalizePipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}

  transform<T>(value: { [language: string]: T }, language?: string): T | null {
    return this.utilsService.localize<T>(value, language);
  }
}
