<div class="product-item" *ngIf="product">
  <div class="product-preview">
    <c-asset-preview [asset]="productThumbnail | async"></c-asset-preview>
  </div>
  <div class="product-info">
    <h1>{{ product.internalName }}</h1>
    <div class="details">
      <div>Source: {{ product.source || '-' }}</div>
    </div>
  </div>
</div>
