import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'c-tag-timezone',
  templateUrl: './tag-timezone.component.html',
  styleUrls: ['./tag-timezone.component.scss'],
  host: {
    '[style.display]': "'contents'",
  },
})
export class TagTimezoneComponent {
  @Input()
  displayTimeZone: string = null;

  isTimeZoneDifferentToLocal: boolean;

  constructor(activatedRout: ActivatedRoute) {
    this.isTimeZoneDifferentToLocal = moment.tz.guess() !== this.displayTimeZone;

    if (!this.displayTimeZone) {
      activatedRout.queryParams.subscribe((params) => {
        if (params.eTz) {
          this.displayTimeZone = params.eTz;
          this.isTimeZoneDifferentToLocal = moment.tz.guess() !== this.displayTimeZone;
        }
      });
    }
  }
}
