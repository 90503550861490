import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { EventsService } from 'src/app/services/events/events.service';
import { Tab } from 'src/app/tabs/classes/tab';
import { TabsService } from 'src/app/tabs/services/tabs.service';
import { Event, eventPhase, EventPhase } from 'src/common/entities/Event';
import { LiveSessionsComponent } from '../live-sessions/live-sessions.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LanguagesService } from 'src/app/services/languages/languages.service';

@Component({
  selector: 'app-live-overview',
  templateUrl: './live-overview.component.html',
  styleUrls: ['./live-overview.component.scss'],
})
export class LiveOverviewComponent implements OnInit, OnDestroy {
  @ViewChild(LiveSessionsComponent, { static: false })
  sessions: LiveSessionsComponent;

  eventId: string = null;
  event: Event = null;
  loggedInViewers: number = 0;
  loggedOutViewers: number = 0;
  viewers: {
    [session: string]: {
      live: number;
      demand: number;
    };
  } = {};

  tab: Tab;
  languages;
  currentLanguage;

  hasEventSessionRights: boolean = false;
  hasEventRegistrationRights: boolean = false;

  currentTab: 'overview' | 'eventregistrations' | 'eventticketslots' | 'notifications';

  working: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private eventsService: EventsService,
    private tabsService: TabsService,
    private languagesService: LanguagesService
  ) {
    this.tab = this.tabsService.register({
      category: 'events',
      loading: true,
      route: this.activatedRoute.snapshot,
      titlePrefix: 'Live',
      parent: '/events',
    });
  }

  async ngOnInit(): Promise<void> {
    this.hasEventSessionRights = await this.authService.hasRight(['eventsessions.list', 'eventsessions.create', 'eventsessions.read', 'eventsessions.edit']);
    this.hasEventRegistrationRights = await this.authService.hasRight(['eventregistrations.list', 'eventregistrations.create', 'eventregistrations.read', 'eventregistrations.edit']);

    this.currentTab = !this.hasEventSessionRights ? (this.hasEventRegistrationRights ? 'eventregistrations' : 'eventticketslots') : 'overview';

    this.eventId = this.activatedRoute.snapshot.params['eventId'];

    if (this.eventId) {
      this.activatedRoute.queryParams.subscribe((params) => {
        this.currentTab = params.tab || this.currentTab;
      });

      this.languages = (await this.languagesService.getLanguages())?.items.filter((i) => i.selectable).map((i) => i.language);
      const language = this.activatedRoute.snapshot.queryParams.language;
      this.currentLanguage = this.languages.includes(language) ? language : this.languages[0];

      this.subscriptions.push(
        this.eventsService.subscribeEvent(this.eventId).subscribe(
          (event) => {
            this.event = event;
            this.tab.title = event.internalName;
            this.tab.loading = false;
          },
          (error) => {
            this.event = null;
            throw error;
          }
        )
      );
      this.subscriptions.push(
        this.eventsService.subscribeViewers(this.eventId).subscribe((viewers) => {
          this.loggedInViewers = viewers.loggedInViewers;
          this.loggedOutViewers = viewers.loggedOutViewers;

          for (const session of viewers?.sessions || []) {
            this.viewers[session.session] = {
              live: session.liveViewers,
              demand: session.demandViewers,
            };
          }
        })
      );
    }

    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.currentTab = params.tab || this.currentTab;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  eventPhase(): EventPhase {
    return this.event ? eventPhase(this.event) : 'DRAFT';
  }

  async sendUpdateEvent() {
    const result = await this.execute(this.eventsService.sendUpdate(this.event._id));
    this.sessions?.setAgendaData();

    if (result.successful) {
      this.messageService.add({
        severity: 'success',
        summary: 'Send update',
        detail: `An event update has been sent successfully`,
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Send update', detail: `Could not send event update` });
    }
  }

  async sendUpdateSessions() {
    const result = await this.execute(this.eventsService.sendUpdateSessions(this.event._id));
    this.sessions?.setAgendaData();

    if (result.successful) {
      this.messageService.add({
        severity: 'success',
        summary: 'Send sessions update',
        detail: `An event sessions update has been sent successfully`,
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Send sessions update',
        detail: `Could not send event update`,
      });
    }
  }

  async execute<T>(promise: Promise<T>) {
    this.working = true;
    let result: T;
    try {
      result = await promise;
    } catch (err) {
      console.error(err);
    }
    this.working = false;
    return result;
  }
}
