import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AdminUsersService } from 'src/app/services/admin-users/admin-users.service';
import { UsersService } from 'src/app/services/users/users.service';
import { AdminUser, isAzureADAdminUser } from 'src/common/entities/AdminUser';
import { asUser, BaseUser, isUser, User } from 'src/common/entities/User';

@Component({
  selector: 'c-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss'],
})
export class UserPickerComponent implements OnChanges {
  @Input()
  users: string[] = [];

  @Output()
  usersChange = new EventEmitter<string[]>();

  @Input()
  user: string | null;

  @Output()
  userChange = new EventEmitter<string | null>();

  @Input()
  multi = true;

  @Input()
  readonly = false;

  @Input()
  disabled = false;

  @Input()
  showClear = true;

  @Input()
  userType: 'USER' | 'ADMIN_USER' | 'REMOTE_ADMIN_USER' = 'USER';

  @Input()
  group: string;

  // @Input()
  // labelAddUser: string;

  @Input()
  removeBlacklist: string[] = [];

  // @Output() onUserAdded = new EventEmitter<string>();
  // @Output() onUserRemoved = new EventEmitter<string>();

  currentUser: User | AdminUser;
  filteredUsers: { [key: string]: AdminUser | User } = {};
  filteredUserIds: string[];
  currentUserForDetails: User | AdminUser;

  tmpRemoteUsers: { [key: string]: AdminUser } = {};

  // convertCurrentUser: any = (id: string) : string => {
  //   return this.currentUser?.displayName // id === this.currentUser?._id ? this.currentUser.displayName : '';
  // };

  constructor(private userService: UsersService, private adminUserService: AdminUsersService) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.multi && ((!this.currentUser && this.user) || (this.user && this.userType === 'ADMIN_USER' && this.currentUser._id !== this.user))) {
      if (this.userType === 'USER') {
        this.currentUser = (await this.userService.getUser(this.user)) as User;
      } else {
        this.currentUser = (await this.adminUserService.getAdminUser(this.user)) as AdminUser;
      }
    }
  }

  async filterUsers(event) {
    let filteredUsers: { items: (BaseUser | AdminUser)[] };

    if (this.userType == 'USER') {
      filteredUsers = await this.userService.getCustomers({
        filter: {
          displayName: {
            matchMode: 'contains',
            value: event.query,
            caseInsensitive: true,
          },
          active: {
            matchMode: 'equals',
            value: true,
          },
        },
      });
    } else if (this.userType == 'REMOTE_ADMIN_USER') {
      filteredUsers = await this.adminUserService.getRemoteUsers({
        source: 'Remote',
        filter: {
          displayName: {
            matchMode: 'contains',
            value: event.query,
          },
        },
      });
    } else if (this.userType == 'ADMIN_USER' && this.group) {
      filteredUsers = await this.adminUserService.getAdminUsersByGroup(this.group, {
        source: 'Local',
        filter: {
          displayName: {
            matchMode: 'contains',
            value: event.query,
            caseInsensitive: true,
          },
        },
      });
    } else if (this.userType == 'ADMIN_USER') {
      filteredUsers = await this.adminUserService.getAdminUsers({
        source: 'Local',
        filter: {
          displayName: {
            matchMode: 'contains',
            value: event.query,
            caseInsensitive: true,
          },
        },
      });
    }

    this.filteredUsers = filteredUsers.items.reduce((a, b) => ({ ...a, [this.id(b)]: b }), {});
    this.filteredUserIds = Object.keys(this.filteredUsers);
  }

  filteredUsersAsArray(): (User | AdminUser)[] {
    return Object.values(this.filteredUsers);
  }

  changed() {
    if (this.multi) {
      this.usersChange.emit(this.users);
    }
  }

  onSelect(user: User | AdminUser) {
    this.userChange.emit(this.id(this.currentUser));
  }

  onSelectMulti(id: string) {
    this.tmpRemoteUsers[id] = this.filteredUsers[id] as AdminUser;
  }

  id(user: BaseUser | AdminUser): string {
    return user._id || (isUser(user) || isAzureADAdminUser(user) ? user.email?.toLowerCase() || user.emailLower : null) || (isAzureADAdminUser(user) ? user.oid : null);
  }

  // addCurrentUser() {
  //   if (!this.users) {
  //     this.users = [];
  //   }

  //   if (this.multi) {
  //     if (!isAzureADAdminUser(this.currentUser) && !this.users.find(u => u === this.currentUser._id)) {
  //       this.users.push(this.currentUser._id);
  //       this.usersChange.emit(this.users);
  //       this.onUserAdded.emit(this.currentUser._id)
  //     }

  //     if (isAzureADAdminUser(this.currentUser) && !this.users.find(u => isAzureADAdminUser(this.currentUser) && u === this.currentUser.oid)) {
  //       this.users.push(this.currentUser.oid);
  //       this.usersChange.emit(this.users);
  //       this.onUserAdded.emit(this.currentUser.oid)
  //     }
  //     this.currentUser = null;

  //   } else {
  //     this.user = this.currentUser._id || this.currentUser.emailLower || (isAzureADAdminUser(this.currentUser) ? this.currentUser.oid : null);
  //     this.userChange.emit(this.user);
  //   }
  // }

  // onSelect($event) {
  //   this.addCurrentUser()
  // }

  // test($event) {
  //   debugger
  // }

  // removeUser(userId: string) {
  //   if (this.users.find(u => u == userId)) {
  //     this.users = this.users.filter(u => u != userId);
  //     this.usersChange.emit(this.users);
  //     this.onUserRemoved.emit(userId)
  //   }
  // }

  async showUserDetails(identifier: string) {
    if (this.userType == 'USER') {
      const baseUser = await this.userService.getUser(identifier);
      this.currentUserForDetails = asUser(baseUser);
    } else {
      this.currentUserForDetails = await this.adminUserService.getAdminUser(identifier);
    }
  }

  // isRemovable(userId: string): boolean {
  //   return !this.readonly && !this.disabled && !this.removeBlacklist.some(blacklistUser => userId == blacklistUser);
  // }

  clear() {
    this.currentUser = null;
    this.user = null;
    this.userChange.emit(null);
  }
}
