import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventProduct, EventVersion } from 'src/common/entities/EventVersion';

import { EventsService } from 'src/app/services/events/events.service';
import { TableOptions } from 'src/app/components/table/table.interfaces';
import { concat, from, Observable, of, Subject, Subscription } from 'rxjs';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { TableComponent } from 'src/app/components/table/table.component';
import { Product } from 'src/common/entities/Product';
import { ProductsService } from 'src/app/services/products/products.service';
import { EventProductFactory } from 'src/common/factories/EventProductFactory';
import { Inputs } from 'src/common/inputs/Inputs';
import { debounceTime, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'c-event-products',
  templateUrl: './event-products.component.html',
  styleUrls: ['./event-products.component.scss'],
})
export class EventProductsComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) table: TableComponent<EventProduct>;

  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  language: string;

  searchText: string;
  searchTextSubject: Subject<string> = new Subject<string>();
  filteredEventProducts = new Observable<EventProduct[]>();

  productEditId: string = null;
  productEditHelper: any = {
    override: false,
  };

  get editEventProduct(): EventProduct {
    return this.eventVersion.eventProducts.find((k) => k.product === this.productEditId);
  }

  showProductSelect: boolean = false;

  get showProductEdit() {
    return this.productEditId !== null;
  }

  set showProductEdit(val: boolean) {
    if (!val) this.productEditId = null;
  }

  productsTableOptions: TableOptions<EventProduct> = {
    columns: [
      {
        header: 'GENERAL_INTERNAL_NAME',
        visible: 'fixed',
      },
      { header: 'GENERAL_TITLE' },
      { header: 'GENERAL_DESCRIPTION' },
      { header: 'GENERAL_OVERRIDE' },
      { header: 'GENERAL_MOVE' },
    ],
  };

  generalJsonPathes: string[] = [
    "$.eventProducts[?(@.product == '$productId')].override.local.$language.title",
    "$.eventProducts[?(@.product == '$productId')].override.local.$language.teaserText",
    "$.eventProducts[?(@.product == '$productId')].override.local.$language.description",
    "$.eventProducts[?(@.product == '$productId')].override.local.$language.productDetails",
    "$.eventProducts[?(@.product == '$productId')].override.local.$language.productHighlights",
    "$.eventProducts[?(@.product == '$productId')].override.image",
    "$.eventProducts[?(@.product == '$productId')].override.productLogo",
    "$.eventProducts[?(@.product == '$productId')].override.local.$language.link",
    "$.eventProducts[?(@.product == '$productId')].override.embeddedPage",
    "$.eventProducts[?(@.product == '$productId')].override.productTag",
    "$.eventProducts[?(@.product == '$productId')].override.technology",
    "$.eventProducts[?(@.product == '$productId')].override.tags",
  ];

  inputs: Inputs = {
    "$.eventProducts[?(@.product == '$productId')].override.local.$language.teaserText": {
      type: 'text',
    },
    "$.eventProducts[?(@.product == '$productId')].override.local.$language.productDetails": {
      type: 'textarea',
    },
    "$.eventProducts[?(@.product == '$productId')].override.local.$language.productHighlights": {
      type: 'textarea',
    },
    "$.eventProducts[?(@.product == '$productId')].override.productLogo": {
      type: 'imageasset',
    },
    "$.eventProducts[?(@.product == '$productId')].override.relatedAssets": {
      header: 'Related Asset',
      factory: async () => [],
      list: true,
      childFactory: async () => null,
    },
    "$.eventProducts[?(@.product == '$productId')].override.tags": {
      type: 'tags',
      multiselect: true,
    },
    "$.eventProducts[?(@.product == '$productId')].override.technology": {
      type: 'tags',
      multiselect: false,
    },
    "$.eventProducts[?(@.product == '$productId')].override.relatedAssets[$index]": {
      type: 'asset',
      header: 'Asset',
    },
    "$.eventProducts[?(@.product == '$productId')].override.relatedProducts": {
      header: 'Related Products',
      factory: async () => [],
      list: true,
      childFactory: async () => null,
    },
    "$.eventProducts[?(@.product == '$productId')].override.relatedProducts[$index]": {
      type: 'product',
      header: 'Product',
    },
    "$.eventProducts[?(@.product == '$productId')].override.embeddedPage": {
      header: 'Embedded Page',
      type: 'embeddedpage',
    },
    "$.eventProducts[?(@.product == '$productId')].override.exhibitedWith": {
      type: 'product',
      header: 'Exhibited With',
    },
  };

  productEditTab = 'general';
  subscriptions: Subscription[] = [];

  constructor(private productsService: ProductsService, private eventsService: EventsService, private utilsService: UtilsService) {}

  async ngOnInit() {
    this.filteredEventProducts = concat(
      of(this.eventVersion.eventProducts),
      this.searchTextSubject.pipe(
        debounceTime(500),
        mergeMap(() => {
          return from(this.getFilteredEventProducts());
        })
      )
    );

    this.subscriptions.push(
      this.eventsService.lastEventVersionPatch(this.eventVersion._id).subscribe((patch) => {
        if (this.utilsService.startsWithJsonpath(patch.patch.jsonpath, '$.eventProducts', patch.patch.jsonpathParams)) {
          this.table.refresh();
        }
      })
    );
  }

  async searchTextKeyUp() {
    this.searchTextSubject.next();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  editProduct(product: EventProduct) {
    this.productEditHelper.override = product.override !== null;
    this.productEditId = product.product;
  }

  async addProduct(product: Product) {
    // Prepare new event product for editing

    // Return if product already in array
    if (this.eventVersion.eventProducts.find((i: any) => i.product === product._id)) {
      this.showProductSelect = false;
      return;
    }
    const newProduct: EventProduct = await new EventProductFactory().createEventProduct({
      product: product._id,
    });
    await this.eventsService.patch(this.eventVersion, {
      command: 'push',
      jsonpath: '$.eventProducts',
      value: newProduct,
    });
    // Close add product dialog
    this.showProductSelect = false;
    // Open edit product dialog
    this.editProduct(newProduct);
  }

  productUp(eventProduct: EventProduct) {
    this.eventsService.patch(this.eventVersion, {
      command: 'up',
      jsonpath: `$.eventProducts[?(@.product == '$productId')]`,
      jsonpathParams: {
        productId: eventProduct.product,
      },
    });
  }

  productDown(eventProduct: EventProduct) {
    this.eventsService.patch(this.eventVersion, {
      command: 'down',
      jsonpath: `$.eventProducts[?(@.product == '$productId')]`,
      jsonpathParams: {
        productId: eventProduct.product,
      },
    });
  }

  productDelete(eventProduct: EventProduct) {
    this.eventsService.patch(this.eventVersion, {
      command: 'delete',
      jsonpath: `$.eventProducts[?(@.product == '$productId')]`,
      jsonpathParams: {
        productId: eventProduct.product,
      },
    });
  }

  async overrideProduct(override: boolean, eventProduct: EventProduct) {
    const product = await this.productsService.getProduct(eventProduct.product);
    this.eventsService.patch(this.eventVersion, {
      command: 'set',
      jsonpath: `$.eventProducts[?(@.product == '$productId')].override`,
      jsonpathParams: {
        productId: eventProduct.product,
      },
      value: override && product ? product : null,
    });
  }

  private async getFilteredEventProducts(): Promise<EventProduct[]> {
    if (!this.searchText) return this.eventVersion.eventProducts;

    const results: boolean[] = await Promise.all(
      this.eventVersion.eventProducts.map(async (p) => {
        const { product } = p;
        const { internalName } = await this.productsService.getProduct(product);
        return internalName.toLowerCase().includes(this.searchText.toLowerCase());
      })
    );

    return this.eventVersion.eventProducts.filter((_, index) => results[index]);
  }
}
