import { PostServiceHookExecutionRetryQuery, PostServiceHookExecutionRetryResponse } from './../../../common/api/v1/servicehooks/PostServiceHookExecution';
import { Injectable } from '@angular/core';
import { GetServiceHookQuery, GetServiceHookResponse } from 'src/common/api/v1/servicehooks/GetServiceHook';
import { GetServiceHookExecutionQuery, GetServiceHookExecutionResponse } from 'src/common/api/v1/servicehooks/GetServiceHookExecution';
import { GetServiceHookExecutionsQuery, GetServiceHookExecutionsResponse } from 'src/common/api/v1/servicehooks/GetServiceHookExecutions';
import { GetServiceHooksQuery, GetServiceHooksResponse } from 'src/common/api/v1/servicehooks/GetServiceHooks';
import { PostServiceHookBody, PostServiceHookQuery, PostServiceHookResponse } from 'src/common/api/v1/servicehooks/PostServiceHook';
import { PostServiceHooksBody, PostServiceHooksQuery, PostServiceHooksResponse } from 'src/common/api/v1/servicehooks/PostServiceHooks';
import { ServiceHook } from 'src/common/entities/ServiceHook';
import { ServiceHookExecution } from 'src/common/entities/ServiceHookExecution';
import { ApiService } from '../api/api.service';
import { PostServiceHookExecutionRetryBody } from 'src/common/api/v1/servicehooks/PostServiceHookExecution';

@Injectable({
  providedIn: 'root',
})
export class ServiceHooksService {
  constructor(private apiService: ApiService) {}

  getServiceHook(serviceHookId: string): Promise<ServiceHook> {
    return this.apiService.get<GetServiceHookQuery, GetServiceHookResponse>(`/api/v1/servicehooks/${serviceHookId}`).toPromise();
  }

  async getServiceHooks(domainCollectionId: string, query?: GetServiceHooksQuery): Promise<GetServiceHooksResponse> {
    return await this.apiService
      .get<GetServiceHooksQuery, GetServiceHooksResponse>('/api/v1/servicehooks', {
        limit: 50,
        skip: 0,
        ...query,
        domainCollection: domainCollectionId,
      })
      .toPromise();
  }

  async getServiceHookExecutions(serviceHookId: string, query?: GetServiceHookExecutionsQuery): Promise<GetServiceHookExecutionsResponse> {
    return await this.apiService
      .get<GetServiceHookExecutionsQuery, GetServiceHookExecutionsResponse>(`/api/v1/servicehooks/${serviceHookId}/executions`, {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();
  }

  async retryServiceHookExecution(serviceHookId: string, serviceHookExecutionId: string): Promise<PostServiceHookExecutionRetryResponse> {
    return await this.apiService.post<PostServiceHookExecutionRetryQuery, PostServiceHookExecutionRetryBody, PostServiceHookExecutionRetryResponse>(`/api/v1/servicehooks/${serviceHookId}/executions/${serviceHookExecutionId}/retry`,{}).toPromise();
  }

  getServiceHookExecution(serviceHookId: string, serviceHookExecutionId: string): Promise<ServiceHookExecution<any>> {
    return this.apiService.get<GetServiceHookExecutionQuery, GetServiceHookExecutionResponse>(`/api/v1/servicehooks/${serviceHookId}/executions/${serviceHookExecutionId}`).toPromise();
  }

  createServiceHook(serviceHook: ServiceHook): Promise<ServiceHook> {
    return this.apiService.post<PostServiceHooksQuery, PostServiceHooksBody, PostServiceHooksResponse>(`/api/v1/servicehooks`, serviceHook).toPromise();
  }

  updateServiceHook(serviceHook: ServiceHook): Promise<ServiceHook> {
    return this.apiService.post<PostServiceHookQuery, PostServiceHookBody, PostServiceHookResponse>(`/api/v1/servicehooks/${serviceHook._id}`, serviceHook).toPromise();
  }
}
