<div class="panel">
  <div class="panel-header">
    Event Registrations
    <div class="status">
      <c-table-status #tst></c-table-status>
    </div>
    <div class="actions">
      <c-table-options #toc></c-table-options>
      <p-button *hasRight="'eventregistrations.download'" styleClass="p-button-sm" (click)="download()" icon="pi icon-ic-download" [disabled]="loading || saving" [label]="'Download'"></p-button>
      <c-event-registration-dialog
        *hasRight="'eventregistrations.create'"
        styleClass="p-button-sm"
        (click)="showNewEventRegistrationDialog = true"
        icon="pi icon-ic-add-item"
        [disabled]="loading || saving"
        [event]="event"
      ></c-event-registration-dialog>
    </div>
  </div>
  <c-table mode="query" [singleTemplate]="true" [tableOptionsComponent]="toc" [tableStatusComponent]="tst" [options]="eventRegistrationsTableOptions" (query)="query($event)">
    <ng-template let-registration>
      <tr [class.unregistered]="!registration.user">
        <td>
          <!-- <i [class]="expanded[registration._id] ? 'pi pi-chevron-down small-chevron' : 'pi pi-chevron-right small-chevron'" (click)="expanded[registration._id] = !expanded[registration._id]"></i> -->
          <button
            *ngIf="registration.registrationData.length > 1"
            type="button"
            pButton
            pRipple
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="expanded[registration._id] ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            (click)="expanded[registration._id] = !expanded[registration._id]"
          ></button>
        </td>
        <td>
          {{ registration.user?.lastName }}
        </td>
        <td>{{ registration.user?.firstName }}</td>
        <td>
          {{ registration.user?.email || registration.user?.emailLower || registration.emailLower }}
          <i [pTooltip]="'The user is not registered at the platform!'" class="pi pi-exclamation-triangle" *ngIf="!registration.user"></i>
        </td>
        <td>{{ registration.user?.organization }}</td>
        <td>{{ registration.user?.country }}</td>
        <td class="no-wrap">{{ registration.registeredAt | date : 'short' }}</td>
        <td class="no-wrap">
          {{ registration.registrationData[0].eventTicket.internalName }}
          <span class="registration-data-plus" *ngIf="!expanded[registration._id] && registration.registrationData.length > 1">+&nbsp;{{ registration.registrationData.length - 1 }}</span>
        </td>
        <td class="no-wrap">
          <div *ngIf="registration.registrationData[0] as registrationData">
            <span *ngIf="!expanded[registration._id]">
              <span *ngIf="registrationData.eventSlots[0]?.eventTimeSlots.length === 0">
                {{ registrationData.eventSlots[0]?.eventSlot.startAt | date : 'short' }} - {{ registrationData.eventSlots[0]?.eventSlot.endAt | date : 'shortTime' }}
              </span>
              <span *ngIf="registrationData.eventSlots[0]?.eventTimeSlots.length > 0">
                {{ registrationData.eventSlots[0]?.eventTimeSlots[0]?.startAt | date : 'short' }} - {{ registrationData.eventSlots[0]?.eventTimeSlots[0]?.endAt | date : 'shortTime' }}
              </span>
              <span *ngIf="numberOfSlots(registration) as num">
                <span *ngIf="num > 1" class="registration-data-plus">+&nbsp;{{ num - 1 }}</span>
              </span>
            </span>

            <div *ngIf="expanded[registration._id]">
              <div *ngFor="let eventSlot of registrationData.eventSlots">
                <div *ngIf="eventSlot.eventTimeSlots.length === 0">{{ eventSlot.startAt | date : 'short' }} - {{ eventSlot.endAt | date : 'shortTime' }}</div>
                <div *ngIf="eventSlot.eventTimeSlots.length > 0">
                  <div *ngFor="let eventTimeSlot of eventSlot.eventTimeSlots">{{ eventTimeSlot.startAt | date : 'short' }} - {{ eventTimeSlot.endAt | date : 'shortTime' }}</div>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td *ngFor="let cfa of customFieldsAttendees()">
          <div *ngIf="registration.registrationData[0] as registrationData">
            <span *ngIf="!expanded[registration._id]">
              <span *ngIf="registrationData.attendees?.length > 0" class="registration-data-plus"> +&nbsp;{{ registrationData.attendees?.length }}</span>
            </span>
            <div *ngIf="expanded[registration._id]">
              <div *ngFor="let attendee of registrationData.attendees">
                {{ attendee[cfa._id] }}
              </div>
            </div>
          </div>
        </td>
        <td *ngFor="let customField of customFields">
          <span *ngIf="isBoolean(registration.registrationData[0]?.customFields[customField._id]) === false">{{ registration.registrationData[0]?.customFields[customField._id] }}</span>
          <p-checkbox
            *ngIf="isBoolean(registration.registrationData[0]?.customFields[customField._id])"
            [ngModel]="registration.registrationData[0]?.customFields[customField._id]"
            [binary]="true"
            [disabled]="true"
          ></p-checkbox>
        </td>
        <td><p-checkbox [ngModel]="registration.attended" [binary]="true" [disabled]="true"></p-checkbox></td>
        <td>
          <div *ngIf="registration.autoAssigned"><i class="pi pi-check success"></i></div>
        </td>
        <td>
          <p-button *hasRight="'eventregistrations.edit'" styleClass="p-button-sm" (click)="showDialog(registration)" label="Edit"></p-button>
        </td>
      </tr>
      <ng-container *ngIf="expanded[registration._id]">
        <tr *ngFor="let registrationData of registration.registrationData | slice : 1; let i = index">
          <td class="empty" colspan="7"></td>
          <td>{{ registration.registrationData[i + 1].eventTicket.internalName }}</td>
          <td>
            <div *ngIf="registration.registrationData[i + 1] as registrationData">
              <div *ngFor="let eventSlot of registrationData.eventSlots">
                <div *ngIf="eventSlot.eventTimeSlots.length === 0">{{ eventSlot.startAt | date : 'short' }} - {{ eventSlot.endAt | date : 'shortTime' }}</div>
                <div *ngIf="eventSlot.eventTimeSlots.length > 0">
                  <div *ngFor="let eventTimeSlot of eventSlot.eventTimeSlots">{{ eventTimeSlot.startAt | date : 'short' }} - {{ eventTimeSlot.endAt | date : 'shortTime' }}</div>
                </div>
              </div>
            </div>
          </td>
          <td *ngFor="let customField of customFields">{{ registration.registrationData[i + 1]?.customFields[customField._id] }}</td>
          <td class="empty"></td>
        </tr>
      </ng-container>
    </ng-template>
  </c-table>
</div>

<!-- ------------------ Event Registration Dialog  ------------------ -->

<p-dialog
  [(visible)]="showEventRegistrationDialog"
  [modal]="true"
  [header]="(newRegistration ? ('GENERAL_NEW' | translate) + ' ' : '') + ('PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION' | translate)"
  [showHeader]="true"
  (onHide)="closeDialog()"
  styleClass="mydialog"
  [contentStyle]="newRegistration && !user ? { overflow: 'visible', width: '50vw' } : {}"
>
  <!-- ------------------ Select User For New Event Registration ------------------ -->

  <c-field
    *ngIf="newRegistration && !user"
    class="new-registration"
    header="PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_USER_SELECTION"
    description="PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_USER_SELECTION_DESCRIPTION"
  >
    <c-user-picker [userType]="'USER'" [(user)]="userId" [multi]="false" style="width: 100%"></c-user-picker>
  </c-field>

  <!-- ------------------ Registrated User Already Exists Information ------------------ -->

  <div *ngIf="existingRegistration" class="p-text-center error-description">
    <strong>{{ 'PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_EXISTS_MESSAGE' | translate }}</strong>
  </div>

  <div *ngIf="editableRegistation && !loading" class="p-grid">
    <!-- ------------------ Registration Data Array Tabs ------------------ -->
    <div class="p-col-12">
      <p-tabView [(activeIndex)]="tabIndexRegistrationData" (activeIndexChange)="onTabIndexChangeRegistrationData($event)">
        <p-tabPanel
          *ngFor="let registrationData of asRegistrationDataArray(editableRegistation.registrationData); let i = index"
          header="{{ i + 1 }}.  {{ getTicketInfo(registrationData.eventTicket) }}"
        >
        </p-tabPanel>
        <p-tabPanel header="{{ 'PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATIONS_ADD' | translate }}" leftIcon="pi icon-ic-add-item"> </p-tabPanel>
      </p-tabView>
    </div>

    <!-- ------------------ Related Ticket Informations ------------------ -->

    <div class="p-col-3 p-d-flex p-flex-column content">
      <p-card [header]="'PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_TICKET_INFO' | translate">
        <p-divider *ngIf="asRegistrationDataArray(editableRegistation.registrationData).length > 1" align="right">
          <p-button
            (onClick)="confirmDeleteEventRegistrationData()"
            [label]="'PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATIONDATA_DELETE' | translate"
            icon="pi pi-trash"
            styleClass="p-button-sm p-button-danger p-button-outlined"
          >
          </p-button>
        </p-divider>
        <c-field header="PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_REGISTERED_AT" description="PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_REGISTERED_AT_DESCRIPTION">
          <strong>{{ editableRegistation.registeredAt | date : 'short' }}</strong>
        </c-field>
        <c-field
          *ngIf="editableRegistation.unregisteredAt"
          header="PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_UNREGISTERED_AT"
          description="PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_UNREGISTERED_AT_DESCRIPTION"
        >
          <strong>{{ editableRegistation.unregisteredAt | date : 'short' }}</strong>
        </c-field>
        <c-field
          [error]="errorAtEventRegistration('registrationData.eventTicket')"
          header="PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_TICKET"
          description="PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_TICKET_DESCRIPTION"
        >
          <p-dropdown
            [disabled]="!rightToEdit"
            [(ngModel)]="editableRegistation.registrationData[tabIndexRegistrationData].eventTicket"
            (ngModelChange)="prepareRegistrationForEdit($event)"
            [options]="eventTickets"
            optionValue="_id"
            optionLabel="internalName"
            styleClass="full-field-width"
          ></p-dropdown>
        </c-field>
        <p></p>
      </p-card>

      <!-- ------------------ Registration Custom Fiels ------------------ -->

      <p-card *ngIf="activatedCustomFields.length > 0" [header]="'PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_TICKET_CUSTOM_FIELDS_INFO' | translate">
        <c-field
          *ngFor="let customField of activatedCustomFields"
          [error]="errorAtEventRegistration('registrationData.customFields')"
          [header]="customField.internalName"
          [description]="(customField.local | localize)?.title"
        >
          <div *ngIf="isCustomFieldAttendees(customField)">
            <div *ngFor="let rd of editableRegistation.registrationData[tabIndexRegistrationData].attendees">
              <div *ngFor="let item of rd | keyvalue">
                <div *ngIf="asCustomFieldAttendees(customField)">{{ getAttendeesOptions(asCustomFieldAttendees(customField).fields, item.key) }}:&nbsp; &nbsp;{{ item.value }}</div>
              </div>
              <br />
            </div>
          </div>
          <p-inputSwitch
            [disabled]="true"
            *ngIf="isCustomFieldSelfVisit(customField)"
            [(ngModel)]="editableRegistation.registrationData[tabIndexRegistrationData].customFields[customField._id]"
          ></p-inputSwitch>
          <input
            [disabled]="!rightToEdit"
            *ngIf="asCustomFieldText(customField)"
            type="text"
            pInputText
            [(ngModel)]="editableRegistation.registrationData[tabIndexRegistrationData].customFields[customField._id]"
          />
          <textarea
            [disabled]="!rightToEdit"
            *ngIf="asCustomFieldTextarea(customField)"
            pInputTextarea
            [rows]="countRows(editableRegistation.registrationData[tabIndexRegistrationData].customFields[customField._id], 1)"
            [(ngModel)]="editableRegistation.registrationData[tabIndexRegistrationData].customFields[customField._id]"
          ></textarea>
          <p-inputSwitch
            [disabled]="!rightToEdit"
            *ngIf="asCustomFieldCheckbox(customField) as asCustomFieldCheckbox"
            [(ngModel)]="editableRegistation.registrationData[tabIndexRegistrationData].customFields[customField._id]"
          ></p-inputSwitch>
          <div class="p-d-flex p-ai-center">
            <p-dropdown
              [disabled]="!rightToEdit"
              *ngIf="asCustomFieldSelect(customField) as customFieldSelect"
              class="p-mr-2"
              [(ngModel)]="editableRegistation.registrationData[tabIndexRegistrationData].customFields[customField._id]"
              [options]="customFieldSelect.options"
              optionValue="key"
              optionLabel="key"
              styleClass="full-field-width"
            ></p-dropdown>
            <div *ngIf="asCustomFieldSelect(customField) as customFieldSelect">
              {{ getCustomFieldSelectOptionLabel(editableRegistation.registrationData[tabIndexRegistrationData].customFields[customField._id], customFieldSelect.options) }}
            </div>
          </div>
        </c-field>
      </p-card>

      <!-- ------------------ UTM Informations ------------------------- -->
      <p-card *ngIf="editableRegistation?.utm" [header]="'PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_UTM_INFO' | translate">
        <c-field *ngIf="editableRegistation?.utm?.utmCampaign" header="PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_UTM_CAMPAIGN">
          <strong>{{ editableRegistation.utm.utmCampaign }}</strong>
        </c-field>
        <c-field *ngIf="editableRegistation?.utm?.utmContent" header="PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_UTM_CONTENT">
          <strong>{{ editableRegistation.utm.utmContent }}</strong>
        </c-field>
        <c-field *ngIf="editableRegistation?.utm?.utmMedium" header="PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_UTM_MEDIUM">
          <strong>{{ editableRegistation.utm.utmMedium }}</strong>
        </c-field>
        <c-field *ngIf="editableRegistation?.utm?.utmSource" header="PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_UTM_SOURCE">
          <strong>{{ editableRegistation.utm.utmSource }}</strong>
        </c-field>
        <c-field *ngIf="editableRegistation?.utm?.utmTerm" header="PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_UTM_TERM">
          <strong>{{ editableRegistation.utm.utmTerm }}</strong>
        </c-field>
      </p-card>
    </div>

    <!-- ------------------ Registration Time Slots ------------------ -->

    <p-card *ngIf="visibleEventTicketSlots.length > 0" [header]="'PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_TICKET_TIME_SLOT_INFO' | translate" class="p-col content">
      <div class="p-text-center error-description" *ngIf="errorAtTicketSlots()">
        <strong>{{ 'PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_TICKET_TIME_SLOT_ERROR' | translate }}</strong>
      </div>
      <table class="table">
        <tbody *ngFor="let ticketSlot of visibleEventTicketSlots">
          <tr>
            <td><p-inputSwitch [disabled]="!rightToEdit" [(ngModel)]="selectedEventTicketSlots[ticketSlot._id]" (ngModelChange)="onChangedEventTicketSlot(ticketSlot._id, $event)"></p-inputSwitch></td>
            <td colspan="3">
              <strong>{{ ticketSlot.startAt | date : 'short' }} - {{ ticketSlot.endAt | date : 'short' }}</strong>
            </td>
          </tr>

          <tr *ngFor="let ticketTimeSlot of ticketSlot.eventTimeSlots">
            <td></td>
            <td>
              <p-inputSwitch
                [disabled]="!rightToEdit"
                [(ngModel)]="selectedEventTicketTimeSlots[ticketTimeSlot._id]"
                (ngModelChange)="onChangedEventTicketTimeSlot(ticketTimeSlot._id, $event)"
              ></p-inputSwitch>
            </td>
            <td>{{ (ticketTimeSlot.local | localize)?.title }}</td>
            <td>{{ ticketTimeSlot.startAt | date : 'shortTime' }} - {{ ticketTimeSlot.endAt | date : 'shortTime' }}</td>
          </tr>
        </tbody>
      </table>
    </p-card>

    <!-- ------------------ Related User Informations ------------------ -->

    <p-card *ngIf="user" [header]="'PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_USER_INFO' | translate" class="p-col-3 content">
      <c-field header="PAGE_USER_SALUTATION"
        ><strong>{{ luSalutation.local[this.utilsService.currentLanguage][user.salutation] }}</strong></c-field
      >
      <c-field header="PAGE_USER_FIRSTNAME"
        ><strong>{{ user.firstName }}</strong></c-field
      >
      <c-field header="PAGE_USER_LASTNAME"
        ><strong>{{ user.lastName }}</strong></c-field
      >
      <c-field header="PAGE_USER_EMAIL"
        ><strong>{{ user.email }}</strong></c-field
      >
      <c-field header="PAGE_USER_PHONENUMBER"
        ><strong>{{ user.phone }}</strong></c-field
      >
      <c-field header="PAGE_USER_LANGUAGE"
        ><strong>{{ (user.language | language | async)?.languageName }}</strong></c-field
      >
      <c-field header="PAGE_USER_SALESMANAGER"
        ><strong>{{ salesManager ? salesManager.displayName : '' }}</strong></c-field
      >
    </p-card>

    <!-- ------------------ Related Company Informations ------------------ -->

    <p-card *ngIf="user" [header]="'PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_USERS_COMPANY_INFO' | translate" class="p-col-3 content">
      <c-field header="PAGE_USER_ORGANIZATION"
        ><strong>{{ user.organization }}</strong></c-field
      >
      <c-field header="PAGE_USER_DEPARTMENT"
        ><strong>{{ luJobTitle.local[this.utilsService.currentLanguage][user.department] }}</strong></c-field
      >
      <c-field header="PAGE_USER_STREET"
        ><strong>{{ user.street }}</strong></c-field
      >
      <c-field header="PAGE_USER_ZIPCODE"
        ><strong>{{ user.zipCode }}</strong></c-field
      >
      <c-field header="PAGE_USER_CITY"
        ><strong>{{ user.city }}</strong></c-field
      >
      <c-field header="PAGE_USER_COUNTRY"
        ><strong>{{ user.country | country }}</strong></c-field
      >
      <c-field header="PAGE_USER_STATE"
        ><strong>{{ user.state }}</strong></c-field
      >
    </p-card>

    <!-- ------------------ External Event Information ------------------ -->
    <p-card *ngIf="editableRegistation.externalEventRegistration" [header]="'PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_EXTERNAL_EVENT_INFO' | translate" class="p-col-4 content">
      <c-field header="PAGE_LIVE_VISITOR_MANAGEMENT_EVENT_REGISTRATION_EXTERNAL_EVENT_INFO_JOIN_URL"
        ><strong>{{ editableRegistation.externalEventRegistration.joinUrl }}</strong></c-field
      >
    </p-card>
  </div>
  <!-- ------------------ Event Ticket Slot Dialog Footer With Buttons ------------------ -->

  <p-footer>
    <div *ngIf="newRegistration && !user">
      <p-button [label]="'OK' | translate" styleClass="p-button-sm" (onClick)="onNewRegistrationsUserSelected()" [disabled]="!userId"> </p-button>
    </div>
    <div *ngIf="(newRegistration && user && editableRegistation) || (!newRegistration && !loading)">
      <span>
        <p-checkbox styleClass="p-mr-2" [(ngModel)]="sendMail" [binary]="true"></p-checkbox>
        <span class="p-mr-2">Send confirmation mail to user</span>
      </span>
      <p-button
        *ngIf="rightToEdit"
        [label]="'GENERAL_SAVE' | translate"
        icon="pi pi-save"
        styleClass="p-button-sm"
        [disabled]="!isValidEventRegistration(editableRegistation) || errorAtTicketSlots() || (!newRegistration && isEditableChanged())"
        (onClick)="saveEventRegistration()"
      >
      </p-button>

      <span *ngIf="user && rightToOpenUser" class="divide"></span>
      <p-button
        *ngIf="user && rightToOpenUser"
        [routerLink]="['/users/' + user._id]"
        icon="pi pi-user"
        styleClass="p-button-sm"
        [label]="('GENERAL_USER' | translate) + ('GENERAL_DETAILS' | translate)"
      >
      </p-button>

      <span *ngIf="!newRegistration && rightToDelete" class="divide"></span>
      <p-button
        *ngIf="!newRegistration && rightToDelete"
        (onClick)="confirmDeleteEventRegistration()"
        [label]="'GENERAL_DELETE' | translate"
        icon="pi pi-trash"
        styleClass="p-button-sm p-button-danger p-button-outlined"
      >
      </p-button>
    </div>
    <p></p>
  </p-footer>

  <!-- Event Ticket Slot Dialog Confirmation Dialog (has to be part of p-dialog!) -->
  <p-confirmDialog></p-confirmDialog>
</p-dialog>
