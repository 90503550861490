<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'GENERAL_KEYTOPICS' | translate }}</h1>
  </div>
  <div class="tab-menu"></div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_KEYTOPICS' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button *hasRight="'keytopics.create'" [label]="'PAGE_KEYTOPIC_NEW' | translate" icon="pi icon-ic-add-item" [routerLink]="['./new']"></p-button>
      </div>
    </div>
    <div class="search">
      <input placeholder="Search keytopic ..." pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
    </div>
    <c-table mode="query" [options]="tableOptions" [tableOptionsComponent]="toc" (query)="query($event)" (clearFilterList)="searchText = ''" [baseRoute]="'/keytopics'" [routePathProperty]="'_id'">
      <ng-template let-keytopic>
        <div *hasRight="['keytopics.read', 'keytopics.edit']">
          {{ keytopic.internalName }}
        </div>
        <span *hasNoRight="['keytopics.read', 'keytopics.edit']">
          {{ keytopic.internalName }}
        </span>
      </ng-template>
      <ng-template let-keytopic>
        {{ (keytopic.local | keys).length }}
      </ng-template>
      <ng-template let-keytopic>{{ keytopic.local.en.title }}</ng-template>
      <ng-template let-keytopic>{{ keytopic.local.en.shortDescription }}</ng-template>
    </c-table>
  </div>
</div>
