import * as yup from 'yup';

export const regionValidator = yup.object({
  region: yup.string().required(),
  regionName: yup.string().required(),
  language: yup.string().required(),
  timeZone: yup.string().required(),
  active: yup.boolean().required(),
  sort: yup.number().positive().required(),
  icon: yup.string().required(),
});
