export const formFieldTypes = ['text', 'textarea', 'select', 'boolean', 'none'] as const;
export type FormFieldType = (typeof formFieldTypes)[number];

export type FormFieldLocal = {
  title: string;
  description: string;
};

export type FormField = {
  _id: string;
  internalName: string;
  formFieldType: FormFieldType;
  hidden: boolean;
  local: { [language: string]: FormFieldLocal };
};

export type TextFormField = FormField & {
  formFieldType: 'text';
  mandatory: boolean;
  default?: string;
};

export type TextareaFormField = FormField & {
  formFieldType: 'textarea';
  mandatory: boolean;
  default?: string;
};

export type SelectFormField = FormField & {
  formFieldType: 'select';
  mandatory: boolean;
  lookup: string; // lookup id or lookup shortid with localized key => values
  default?: string; // key of default option
};

export type BooleanFormField = FormField & {
  formFieldType: 'boolean';
  mandatory: boolean;
  default?: boolean;
};

export type NoneFormField = FormField & {
  formFieldType: 'none'; // only title and description are displayed without any inputs
};

export const formNotificationTypes = ['Email', 'WebHook'] as const;
export type FormNotificationType = (typeof formNotificationTypes)[number];

export interface FormNotification {
  formNotificationType: FormNotificationType;
}

export interface EmailFormNotification extends FormNotification {
  formNotificationType: 'Email';
  recipients: string[];
  subject: string;
  body: string;
  language: string;
}

export interface WebHookFormNotification extends FormNotification {
  formNotificationType: 'WebHook';
  method: 'POST';
  url: string;
  contentType: 'application/json' | 'application/x-www-form-urlencoded';
}

export interface FormLocal {
  title: string;
  ctaButtonText: string;
  confirmationTitle: string;
  confirmationText: string;
  confirmationOk?: string;
}

export interface Form {
  _id: string;
  internalName: string;
  fields: FormField[];
  local: { [language: string]: FormLocal };
  notifications: FormNotification[];
}

export function isTextFormField(obj: any): obj is TextFormField {
  return obj?.formFieldType === 'text';
}
export function isTextareaFormField(obj: any): obj is TextareaFormField {
  return obj?.formFieldType === 'textarea';
}
export function isSelectFormField(obj: any): obj is SelectFormField {
  return obj?.formFieldType === 'select';
}
export function isBooleanFormField(obj: any): obj is BooleanFormField {
  return obj?.formFieldType === 'boolean';
}
export function isNoneFormField(obj: any): obj is NoneFormField {
  return obj?.formFieldType === 'none';
}
export function isFormFieldWithDefault(obj: any): obj is { default: string | boolean } {
  return isTextFormField(obj) || isTextareaFormField(obj) || isSelectFormField(obj) || isBooleanFormField(obj);
}

export function isEmailFormNotification(obj: any): obj is EmailFormNotification {
  return obj?.formNotificationType === 'Email';
}
