import { textAttributesInputs } from '../attributes/TextAttributesInputs';
import { Inputs } from '../Inputs';
import { commonPageModuleInputs } from './common';
import { titlePositions } from '../../entities/PageModule';
import { ProductList2PageModuleFactory } from '../../factories/pagemodules/ProductList2PageModuleFactory';
import { ProductList2PageModuleGroup, ProductList2PageModuleSeries, displayAs } from './../../entities/pagemodules/ProductList2PageModule';

export const productList2PageModuleInputs: Inputs = {
  ...commonPageModuleInputs('ProductList2'),
  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].showHeadline": {
    type: 'switch'
  },
  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].showHeadlineGroup": {
    type: 'switch'
  },
  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].showHeadlineSeries": {
    type: 'switch'
  },

  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].ctaButton": {},

  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].local.$language": {
    factory: async () => new ProductList2PageModuleFactory().productList2PageModuleLocal({})
  },

  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].local.$language.title": {
    type: 'text',
    attributes: {
      inputs: textAttributesInputs,
      label: obj => {
        return obj;
      }
    }
  },

  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].titlePosition": {
    type: 'dropdown',
    dropdownOptions: [...titlePositions].map(item => ({
      label: item,
      value: item
    }))
  },

  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].displayAs": {
    type: 'dropdown',
    dropdownOptions: [...displayAs].map(item => ({
      label: item,
      value: item
    }))
  },

  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].action": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Open Link', value: 'open-link' },
      { label: 'Show Overlay', value: 'show-overlay' },
      { label: 'Fullscreen Dialog', value: 'fullscreeen-dialog' }
    ]
  },

  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].groups": {
    factory: async () => [],
    list: true,
    listKey: '_id',
    listValue: 'elementId',
    childFactory: async () => new ProductList2PageModuleFactory().productList2PageModuleGroup({}),
    childLabel: (item: ProductList2PageModuleGroup, index: number, lang?: string) => item?.local[lang ? lang : 'en']?.title as string
  },

  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].local.$language": {
    factory: async () => new ProductList2PageModuleFactory().productList2PageModuleLocal({})
  },

  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].local.$language.title": {},

  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].series": {
    factory: async () => [],
    list: true,
    listKey: '_id',
    listValue: 'childElementId',
    childFactory: async () => new ProductList2PageModuleFactory().productList2PageModuleSeries({}),
    childLabel: (item: ProductList2PageModuleSeries, index: number, lang?: string) => item?.local[lang ? lang : 'en']?.title as string
  },
  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].series[?(@._id=='$childElementId')].local.$language": {
    factory: async () => new ProductList2PageModuleFactory().productList2PageModuleLocal({})
  },
  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].series[?(@._id=='$childElementId')].local.$language.title": {},

  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].series[?(@._id=='$childElementId')].products": {
    factory: async () => [],
    list: true,
    childFactory: async () => null
  },

  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].groups[?(@._id=='$elementId')].series[?(@._id=='$childElementId')].products[$index]": {
    type: 'product',
    header: 'Product'
  },

  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].productImageSize": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Default', value: 'default' },
      { label: 'Large (x1.5)', value: 'large' },
      { label: 'Extra Large (x2)', value: 'extra_large' }
    ]
  },

  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].productScope": {
    type: 'dropdown',
    dropdownOptions: [
      { label: 'Manually', value: 'manual' },
      { label: 'From Asset Page', value: 'assetPage' },
      { label: 'From Asset Page - External Only', value: 'external' }
    ]
  },
  "$.pageModules[?(@.pageModuleType=='ProductList2' && @._id=='$pageModuleId')].maxProductCount": {
    type: 'number',
    header: 'Max Product Count'
  }
};
