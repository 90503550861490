import { Pipe, PipeTransform } from '@angular/core';
import { ProductsService } from 'src/app/services/products/products.service';
import { Product } from 'src/common/entities/Product';

@Pipe({
  name: 'product',
})
export class ProductPipe implements PipeTransform {
  constructor(private productsService: ProductsService) {}

  transform(product: string, ...args: unknown[]): Promise<Product> {
    return this.productsService.getProduct(product);
  }
}
