<p-button icon="pi icon-ic-filter" label="Filter" *ngIf="_tableOptions?.filters?.length > 0" (onClick)="showFilters()"></p-button>
<p-button icon="pi icon-ic-columns" label="Columns" *ngIf="_tableOptions?.columns?.length > 0" (onClick)="showColumns()"></p-button>

<p-sidebar [(visible)]="show" styleClass="p-sidebar-st" position="right" [modal]="true" [appendTo]="'body'">
  <div class="sidebar-header">
    <div class="title" *ngIf="_tableColumnSettingsEdit">
      {{ 'TABLE_OPTIONS_HEADER_COLUMNSETTINGS' | translate }}
    </div>
    <div class="title" *ngIf="_filterListEdit">
      {{ 'TABLE_OPTIONS_HEADER_FILTERLIST' | translate }}
    </div>
  </div>

  <div class="sidebar-body" *ngIf="_filterListEdit">
    <div class="input-group compact" *ngFor="let filter of _tableOptions.filters">
      <div class="input">
        <p-dropdown [options]="_matchModeOptions[filter.path]" [ngModel]="_filterListEdit[filter.path]?.matchMode" (ngModelChange)="filterChange(filter, { matchMode: $event })"></p-dropdown>

        <p-dropdown *ngIf="filter.values" [options]="_filterValues[filter.path]" [ngModel]="_filterListEdit[filter.path]?.value" (ngModelChange)="filterChange(filter, { value: $event })"></p-dropdown>
        <input
          *ngIf="!filter.values && (!filter.type || filter.type === 'string')"
          type="text"
          [ngModel]="_filterListEdit[filter.path]?.value"
          (ngModelChange)="filterChange(filter, { value: $event })"
        />
        <p-calendar
          *ngIf="!filter.values && filter.type === 'date'"
          [showTime]="false"
          [ngModel]="_filterListEdit[filter.path]?.value"
          (ngModelChange)="filterChange(filter, { value: $event })"
        ></p-calendar>
        <p-dropdown
          *ngIf="filter.type === 'boolean'"
          [options]="_booleanDropDownOptions"
          [ngModel]="_filterListEdit[filter.path]?.value"
          (ngModelChange)="filterChange(filter, { value: $event })"
        ></p-dropdown>
      </div>
      <div class="title">{{ filter.header | translate }}</div>
    </div>
  </div>

  <div class="sidebar-body" *ngIf="_tableColumnSettingsEdit">
    <div class="input-group switch compact" *ngFor="let col of _tableOptions?.columns; let index = index">
      <div class="input" *ngIf="col.header">
        <p-checkbox
          [ngModel]="col.visible !== false"
          (ngModelChange)="_tableColumnSettingsEdit[index].visible = $event"
          [binary]="true"
          [disabled]="_tableColumnSettingsEdit[index].visible === 'fixed'"
          [label]="col.header | translate"
        ></p-checkbox>
      </div>
    </div>
  </div>

  <div class="sidebar-footer">
    <p-button label="Apply Filter" *ngIf="_filterListEdit" (onClick)="applyFilter()"></p-button>
    <p-button label="Save" *ngIf="_tableColumnSettingsEdit" (onClick)="saveColumnSettings()"></p-button>
  </div>
</p-sidebar>
