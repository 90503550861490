import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { QRCode } from 'src/common/entities/QRCode';
import { CacheContainer } from '../cache/cache-container';
import { CacheService } from '../cache/cache.service';
import { GetQRCodesQuery, GetQRCodesResponse } from 'src/common/api/v1/qr-codes/GetQRCodes';
import { GetQRCodeQuery, GetQRCodeResponse } from 'src/common/api/v1/qr-codes/GetQRCode';
import { PostQRCodesBody, PostQRCodesQuery, PostQRCodesResponse } from 'src/common/api/v1/qr-codes/PostQRCodes';
import { PostQRCodeBody, PostQRCodeQuery, PostQRCodeResponse } from 'src/common/api/v1/qr-codes/PostQRCode';

@Injectable({
  providedIn: 'root',
})
export class QRCodesService {
  private _cache: CacheContainer<QRCode>;

  constructor(private apiService: ApiService, private cacheService: CacheService) {
    this._cache = this.cacheService.create<QRCode>({
      get: async (qrCodeId) => await this.apiService.get<GetQRCodeQuery, GetQRCodeResponse>(`/api/v1/configuration/qr-codes/${qrCodeId}`).toPromise(),
      socketEvents: ['qr-code:update'],
    });
  }

  async downloadQRCodes(query?: GetQRCodesQuery) {
    this.apiService.download(`/api/v1/configuration/qr-codes`, 'qrCodeArchiv', 'application/zip', {
      ...query,
      format: 'zip',
    });
  }

  async downloadQRCode(qrCodeId: string, fileName: string, type: 'svg' | 'png') {
    const mineType = type === 'svg' ? 'image/svg+xml' : 'image/png';
    this.apiService.download(`/api/v1/configuration/qr-codes/${qrCodeId}/image`, fileName, mineType, {
      type: type,
    });
  }

  getQRCodeImage(qrCodeId: string) {
    return this.apiService.blob(`/api/v1/configuration/qr-codes/${qrCodeId}/image?type=svg`);
  }

  async getQRCodes(query?: GetQRCodesQuery): Promise<GetQRCodesResponse> {
    const qrCodes = await this.apiService
      .get<GetQRCodesQuery, GetQRCodesResponse>('/api/v1/configuration/qr-codes', {
        limit: 50,
        skip: 0,
        ...query,
      })
      .toPromise();

    this._cache.fill(qrCodes.items);

    return qrCodes;
  }

  async getQRCode(qrCodeId: string): Promise<QRCode> {
    return this._cache.asPromise(qrCodeId);
  }

  async createQRCode(qrCode: QRCode): Promise<QRCode> {
    return this._cache.post<PostQRCodesQuery, PostQRCodesBody, PostQRCodesResponse>('/api/v1/configuration/qr-codes', qrCode);
  }

  async updateQRCode(qrCode: QRCode): Promise<QRCode> {
    return this._cache.post<PostQRCodeQuery, PostQRCodeBody, PostQRCodeResponse>(`/api/v1/configuration/qr-codes/${qrCode._id}`, qrCode);
  }

  deleteQRCode(qrCode: QRCode): Promise<QRCode> {
    return this._cache.post<PostQRCodeQuery, PostQRCodeBody, PostQRCodeResponse>(`/api/v1/configuration/qr-codes/${qrCode._id}/delete`, qrCode);
  }
}
