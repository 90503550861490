<div [ngClass]="layout === 'horizontal' ? 'p-grid' : ''">
  <div [ngClass]="layout === 'horizontal' ? 'p-xs-12 p-md-4 p-lg-3' : ''">
    <div class="header">{{ header | translate }}</div>
    <div class="description">{{ description | translate }}</div>
  </div>
  <div [ngClass]="layout === 'horizontal' ? 'p-xs-12 p-md-8 p-lg-9' : ''">
    <ng-content></ng-content>
    <div class="error-description" *ngIf="hasError">{{ error }}</div>
    <div class="warning-description" *ngIf="hasWarning">{{ warning }}</div>
  </div>
</div>
