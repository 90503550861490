import { ActivatedRoute, Router } from '@angular/router';
import { TabsService } from './../../tabs/services/tabs.service';
import { TableOptions, TableQuery } from './../../components/table/table.interfaces';
import { Tab } from './../../tabs/classes/tab';
import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';

import { Group } from 'src/common/entities/Group';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit {
  tab: Tab;
  tableOptions: TableOptions<Group>;

  constructor(private configurationService: ConfigurationService, private tabsService: TabsService, private activatedRoute: ActivatedRoute, private router: Router) {}

  async ngOnInit() {
    this.tab = this.tabsService.register({
      category: 'data',
      route: this.activatedRoute.snapshot,
      title: 'Groups',
    });

    this.tableOptions = {
      size: 50,
      columns: [{ header: 'PAGE_ADMINUSER_GROUP_GROUP_NAME', sort: 'name' }],
      filters: [{ header: 'PAGE_ADMINUSER_GROUP_GROUP_NAME', path: 'name' }],
    };
  }

  query(query: TableQuery<Group>) {
    query.result = this.configurationService.getGroups(query.query);
  }

  newGroup() {
    this.router.navigate(['/configuration/groups/new']);
  }
}
