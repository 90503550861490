import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'c-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent implements OnChanges {
  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  type: 'string' | 'boolean' | 'boolean-radio' | 'date' | 'country' | 'select' | 'tag';

  @Input()
  value: any = null;

  @Input()
  options: any[] = [];
  @Input()
  optionValue: string = null;
  @Input()
  optionLabel: string = null;
  @Input()
  nullOption: boolean = true;

  constructor() {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.type && changes.type.currentValue !== changes.type.previousValue) {
    }
  }

  valueChanged() {
    this.valueChange.emit(this.value);
  }

  clear() {
    this.value = null;
    this.valueChanged();
  }
}
