import { CTAButton } from '../CTAButton';
import { PageModule } from './PageModule';

export interface ShowroomPageModule extends PageModule {
  pageModuleType: 'Showroom';
  showroomConfiguration: string;
  local: {
    [language: string]: {
      title: string;
    };
  };
  ctaButton?: CTAButton;
}

export function isShowroomPageModule(object: any): object is ShowroomPageModule {
  return object?.pageModuleType === 'Showroom';
}
