import { BreakpointValue } from '../Breakpoint';
import { CTAButton } from '../CTAButton';
import { PageModule } from './PageModule';

export interface MultiEventStagePageModule extends PageModule {
  pageModuleType: 'MultiEventStage';

  /** @deprecated Use `local..events` */
  events?: string[] | null;
  local: {
    [language: string]: {
      preHeadline?: string;
      title: string;
      subtitle: string;
      subtext: string;
      events?: string[];
      background: string;
    };
  };
  ctaButton?: CTAButton;
  ctalist?: MultiEventStageButton[];
  ctalistInteraction?: MultiEventStageButton[];
  discoverMore?: boolean;
  stageHeight?: BreakpointValue<number>;
  buttonSpacingTopBreakpoint?: BreakpointValue<number>;
  hideEventName?: boolean;
  hideEventDate?: boolean;
  hideEventTags?: boolean;
}

export interface MultiEventStageButton {
  _id: string;
  ctaButton: CTAButton;
}

export function isMultiEventStagePageModule(object: any): object is MultiEventStagePageModule {
  return object?.pageModuleType === 'MultiEventStage';
}
