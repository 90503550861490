import { InputConfiguration } from 'src/common/inputs/Inputs';
import { Component, Input, OnInit } from '@angular/core';

import { Event } from 'src/common/entities/Event';
import { EventVersion } from 'src/common/entities/EventVersion';
import { Page } from 'src/common/entities/Page';
import { TextEventStage } from 'src/common/entities/EventStage';

@Component({
  selector: 'c-texteventstage-page-module-edit',
  templateUrl: './texteventstage-page-module-edit.component.html',
  styleUrls: ['./texteventstage-page-module-edit.component.scss'],
})
export class TextEventStagePageModuleEditComponent implements OnInit {
  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  page: Page;

  @Input()
  pageModule: TextEventStage;

  @Input()
  jsonpath: string;

  @Input()
  jsonpathParams: { [key: string]: any };

  @Input()
  collaborationKey: string;

  inputJsonpathes: string[] = [
    '$.local.$language.smallHeadline',
    '$.local.$language.text',
    '$.local.$language.background',
    '$.showParticipatingTag',
    '$.showShareButton',
    '$.shareButtonTargets',
    '$.showCalendarButton',
    '$.showCountdown',
    '$.layout',
    '$.ctaButton',
    '$.local.$language.subtext',
    '$.discoverMore',
    '$.anchor',
  ];

  designJsonpathes: string[] = [
    '$.backgroundTheme',
    '$.stageHeight.desktop',
    '$.stageHeight.desktopLarge',
    '$.stageHeight.desktopWidescreen',
    '$.stageHeight.tablet',
    '$.stageHeight.tabletLarge',
    '$.stageHeight.mobile',
    '$.stageHeight.mobileLarge',
    '$.buttonSpacingTopBreakpoint.desktop',
    '$.buttonSpacingTopBreakpoint.desktopLarge',
    '$.buttonSpacingTopBreakpoint.desktopWidescreen',
    '$.buttonSpacingTopBreakpoint.tablet',
    '$.buttonSpacingTopBreakpoint.tabletLarge',
    '$.buttonSpacingTopBreakpoint.mobile',
    '$.buttonSpacingTopBreakpoint.mobileLarge',
  ];

  tab = 'general';
  accessInputConfiguration: InputConfiguration;

  ngOnInit(): void {
    if (this.eventVersion) {
      this.accessInputConfiguration = {
        type: 'accesspolicy',
        eventVersion: this.eventVersion,
      };
    }
  }
}
