<div *skeleton="{ show: loading }">
  <div class="tab-header">
    <h1>
      <span class="pre-title">{{ 'PAGE_EVENT_TYPE_CONFIGURATION' | translate }}</span>
      <span>{{ eventTypeConfig?.internalName }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <p-button *hasRight="'configuration.edit'" icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" (onClick)="save()"></p-button>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_PROPERTIES' | translate }}
    </div>
    <div *ngIf="eventTypeConfig" class="panel-body">
      <!-- Internal Name -->
      <div class="input-group">
        <div class="input">
          <input type="text" pInputText [(ngModel)]="eventTypeConfig.internalName" />
        </div>
        <div class="title">{{ 'PAGE_EVENT_TYPE_CONFIGURATION_INTERNALNAME' | translate }}</div>
        <div class="description">{{ 'PAGE_EVENT_TYPE_CONFIGURATION_INTERNALNAME_DESCRIPTION' | translate }}</div>
      </div>
      <!-- Default Template -->
      <div class="input-group">
        <div class="input">
          <p-dropdown [disabled]="true"></p-dropdown>
        </div>
        <div class="title">{{ 'PAGE_EVENT_TYPE_CONFIGURATION_DEFAULT_TEMPLATE' | translate }}</div>
        <div class="description">{{ 'PAGE_EVENT_TYPE_CONFIGURATION_DEFAULT_TEMPLATE_DESCRIPTION' | translate }}</div>
      </div>

      <p></p>

      <!-- TODO: Refactor to new language selector -->
      <c-language-select *ngIf="eventTypeConfig && currentLanguage" [object]="eventTypeConfig" (languageAdded)="onLanguageAdded($event)" [(currentLanguage)]="currentLanguage">
        <!-- Title -->
        <div class="input-group">
          <div class="input">
            <input type="text" pInputText [(ngModel)]="eventTypeConfig.local[currentLanguage].title" />
          </div>
          <div class="title">{{ 'PAGE_EVENT_TYPE_CONFIGURATION_TITLE' | translate }}</div>
          <div class="description">{{ 'PAGE_EVENT_TYPE_CONFIGURATION_TITLE_DESCRIPTION' | translate }}</div>
        </div>
      </c-language-select>
    </div>
  </div>
</div>
