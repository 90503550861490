<div *skeleton="{ show: tab.loading }">
  <div class="tab-header">
    <h1>
      <span class="pre-title">{{ (keytopicId === 'new' ? 'PAGE_KEYTOPIC_NEW' : 'GENERAL_KEYTOPIC') | translate }}</span>
      <span>{{ keytopic?.internalName }}</span>
    </h1>
  </div>
  <div class="tab-menu">
    <p-button *ngIf="hasEditRights || hasCreateRights" icon="pi pi-save" [label]="'GENERAL_SAVE' | translate" [disabled]="!isValid()" (onClick)="save()"></p-button>
    <c-language-button
      [(currentLanguage)]="currentLanguage"
      [languages]="languages"
      [manageLanguages]="hasEditRights || hasCreateRights"
      (languagesChange)="languagesChange($event)"
      (currentLanguageChange)="changeLanguage($event)"
    ></c-language-button>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'GENERAL_GLOBAL_PROPERTIES' | translate }}
    </div>
    <div *ngIf="keytopic" class="panel-body">
      <div class="input-group">
        <div class="input">
          <input pInputText type="text" [(ngModel)]="keytopic.internalName" [disabled]="tab.loading || !hasEditRights" [readOnly]="keytopicId !== 'new'" />
        </div>
        <div class="title">{{ 'PAGE_KEYTOPIC_GLOBAL_PROPERTIES_INTERNAL_NAME' | translate }}</div>
      </div>

      <div class="input-group">
        <div class="input">
          <c-asset-select [(id)]="keytopic.icon" mimeType="image/svg+xml" [disabled]="!hasEditRights"></c-asset-select>
        </div>
        <div class="title">{{ 'PAGE_KEYTOPIC_GLOBAL_PROPERTIES_ICON' | translate }}</div>
      </div>

      <div class="input-group">
        <div class="input">
          <c-asset-select [(id)]="keytopic.header" mimeType="image/jpeg" [disabled]="!hasEditRights"></c-asset-select>
        </div>
        <div class="title">{{ 'PAGE_KEYTOPIC_GLOBAL_PROPERTIES_HEADER' | translate }}</div>
      </div>

      <div *ngIf="keytopic.local[currentLanguage]" class="tiered-menu-content">
        <c-collaboration-patch-input
          *ngFor="let jsonPath of mainJsonPathes"
          [disabled]="!hasEditRights"
          [object]="keytopic"
          [collaborationKey]="'keytopic:' + keytopicId"
          [jsonpath]="jsonPath"
          [jsonpathParams]="{ language: currentLanguage }"
          [inputs]="mainInputs"
        ></c-collaboration-patch-input>
      </div>
    </div>
  </div>
</div>
