import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomFieldsService } from 'src/app/services/customFields/custom-fields.service';
import {
  CustomField,
  CustomFieldCheckbox,
  CustomFieldSelect,
  CustomFieldText,
  CustomFieldTextarea,
  isCustomFieldCheckbox,
  isCustomFieldSelect,
  isCustomFieldText,
  isCustomFieldTextarea,
} from 'src/common/entities/CustomField';
import { EventTicketData } from 'src/common/entities/EventRegistration';
import { Event } from 'src/common/entities/Event';
import { isUser, User } from '../../../common/entities/User';

@Component({
  selector: 'c-app-event-ticket-selector',
  templateUrl: './event-ticket-selector.component.html',
  styleUrls: ['./event-ticket-selector.component.scss'],
})
export class EventTicketSelectorComponent<T extends EventTicketData> implements OnInit {
  @Input()
  event: Event;
  @Input() ngModel: T;
  @Output() ngModelChange = new EventEmitter<T>();

  isLoading: boolean = false;
  tickets: { label: string; value: string }[] = [];
  customFields: CustomField[] = [];
  customFieldSelectOptions: { [customFieldId: string]: { label: string; value: string }[] } = {};

  constructor(private customFieldsService: CustomFieldsService) {}

  ngOnInit(): void {
    this.tickets = [{ label: '-', value: '' }].concat(
      this.event.currentEventVersion.eventTickets.map((t) => ({
        label: t.internalName,
        value: t._id,
      }))
    );
  }

  asCustomFieldText(customField: any): CustomFieldText {
    return isCustomFieldText(customField) ? customField : null;
  }

  asCustomFieldTextarea(customField: any): CustomFieldTextarea {
    return isCustomFieldTextarea(customField) ? customField : null;
  }

  asCustomFieldCheckbox(customField: any): CustomFieldCheckbox {
    return isCustomFieldCheckbox(customField) ? customField : null;
  }

  asCustomFieldSelect(customField: any): CustomFieldSelect {
    return isCustomFieldSelect(customField) ? customField : null;
  }

  asUser(user: any): User {
    return isUser(user) ? user : null;
  }

  async ticketChange() {
    this.updateEventRegistrationData();
    this.isLoading = true;

    try {
      const customFieldIds = this.event.currentEventVersion.registrationCustomFields.filter((r) => r.eventTickets?.includes(this.ngModel.eventTicket as string)).map((r) => r.customField);
      const customFields = await Promise.all(customFieldIds.map((customFieldId) => this.customFieldsService.getCustomField(customFieldId)));

      for (const customField of customFields) {
        if (isCustomFieldSelect(customField)) {
          this.customFieldSelectOptions[customField._id] = [{ value: null, label: '-' }].concat(
            customField.options.map((o) => ({
              label: o.key,
              value: o.key,
            }))
          );
        }
      }

      this.customFields = customFields;
    } catch (err) {
      console.error(err);
    }

    this.isLoading = false;
  }

  countRows(stringContent: string, minRows): number {
    return Math.max((stringContent?.match(/\n/g) || []).length, minRows) + 1;
  }

  asString(customField: any): string {
    return customField;
  }
  updateEventRegistrationData() {
    this.ngModelChange.emit(this.ngModel);
  }
}
