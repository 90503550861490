<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'PAGE_QR_CODES' | translate }}</h1>
  </div>
  <div class="tab-menu"></div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_QR_CODES' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button [label]="'New QR Code'" icon="pi icon-ic-add-item" (onClick)="new()"></p-button>
        <p-button [label]="'Download'" icon="pi icon-ic-download" (onClick)="downloadQrCodeArchive()"></p-button>
      </div>
    </div>
    <c-table #qrCodesTable mode="query" [options]="tableOptions" [tableOptionsComponent]="toc" (query)="query($event)" (itemSelect)="edit($event)">
      <ng-template let-qrCode>
        {{ qrCode.internalName || ('GENERAL_EMPTY' | translate) }}
      </ng-template>
      <ng-template let-qrCode>
        {{ qrCode.code | translate }}
      </ng-template>
      <ng-template let-qrCode>
        {{ qrCode.link || ('GENERAL_EMPTY' | translate) }}
      </ng-template>
      <ng-template let-qrCode>
        <div class="action-buttons">
          <p-button (onClick)="linkClick($event); qrCodeSidebar(qrCode)" [disabled]="!qrCode.link || !qrCode.code || !qrCode.internalName">{{ 'PAGE_QR_CODES_DOWNLOAD' | translate }}</p-button>
        </div>
      </ng-template>
    </c-table>
  </div>
</div>
<c-sidebar [header]="(editMode ? ('GENERAL_EDIT' | translate) : ('GENERAL_ADD' | translate)) + ' ' + 'QR Code'" [(visible)]="showSidebar">
  <c-collaboration-patch-input [object]="qrCode" [jsonpath]="'$.internalName'" [inputs]="inputs"></c-collaboration-patch-input>
  <c-collaboration-patch-input [object]="qrCode" [jsonpath]="'$.code'" [inputs]="inputs"></c-collaboration-patch-input>
  <c-collaboration-patch-input [object]="qrCode" [jsonpath]="'$.link'" [inputs]="inputs"></c-collaboration-patch-input>

  <c-sidebar-footer [showSaveButton]="true" (onSave)="save(); showSidebar = false" [showDeleteButton]="true" (onDelete)="deleteQrCode(); showSidebar = false"> </c-sidebar-footer>
</c-sidebar>

<c-sidebar [header]="'PAGE_QR_CODE' | translate" [(visible)]="showQRCodeSidebar">
  <div class="wrapper-qr-code">
    <div class="wrapper">
      <img [src]="qrCodePreviewImage" *ngIf="!isImageLoading" width="150" />
    </div>
    <div class="input-group compact">
      <div class="input">
        <p-dropdown [(ngModel)]="qrCodeType" [options]="qrCodeTypeOptions" optionValue="value" optionLabel="title"></p-dropdown>
        <p-button icon="pi icon-ic-download" (onClick)="downloadQRCode()"></p-button>
      </div>
      <div class="title">Download</div>
    </div>
  </div>
</c-sidebar>
