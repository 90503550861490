<div *skeleton="{ show: tab.loading, type: 'table' }">
  <div class="tab-header">
    <h1>{{ 'PAGE_ASSET_SERIES' | translate }}</h1>
  </div>
  <div class="tab-menu">
    <p-button (onClick)="refresh()" label="Refresh" icon="pi icon-ic-refresh" [disabled]="loading"></p-button>
    <c-language-button [languages]="languages" [(currentLanguage)]="currentLanguage" (languagesChange)="ensureLanguages($event)" [manageLanguages]="hasEditRights"></c-language-button>
  </div>

  <div class="panel">
    <div class="panel-header">
      {{ 'PAGE_ASSET_SERIES' | translate }}
      <div class="actions">
        <c-table-options #toc></c-table-options>
        <p-button *hasRight="'assets.create'" [label]="'PAGE_ASSET_SERIES_ADD' | translate" icon="pi icon-ic-add-item" (onClick)="newAssetSerie()"></p-button>
      </div>
    </div>
    <div class="search">
      <input placeholder="Search asset series ..." pInputText [(ngModel)]="searchText" (keyup)="searchTextKeyUp()" />
      <c-quick-filters [title]="'GENERAL_SOURCE' | translate" [quickFilters]="sourceQuickFilters" [active]="sourceQuickFiltersActive" (valueChange)="setSourceQuickFilters($event)"></c-quick-filters>
    </div>
    <c-table
      #table
      mode="query"
      [options]="tableOptions"
      [tableOptionsComponent]="toc"
      (query)="query($event)"
      (clearFilterList)="searchText = ''"
      [queryParamsHandling]="'merge'"
      (itemSelect)="selectAssetSerie($event)"
    >
      <ng-template let-assetSerie>
        <div
          *hasRight="{
            resource: 'assetseries:' + assetSerie._id,
            rights: ['assets.read', 'assets.edit']
          }"
        >
          {{ assetSerie.internalName || ('GENERAL_EMPTY' | translate) }}
        </div>
        <span
          *hasNoRight="{
            resource: 'assetseries:' + assetSerie._id,
            rights: ['assets.read', 'assets.edit']
          }"
        >
          {{ assetSerie.internalName || ('GENERAL_EMPTY' | translate) }}</span
        >
      </ng-template>
      <ng-template let-assetSerie>{{ assetSerie.local?.en?.title }}</ng-template>
      <ng-template let-assetSerie>{{ assetSerie?.source?.system || '' }}</ng-template>
      <ng-template let-assetSerie>{{ assetSerie?.source?.externalId || '' }}</ng-template>
    </c-table>
  </div>
</div>

<p-sidebar [(visible)]="showEditSidebar" styleClass="p-sidebar-st" position="right" [modal]="false" [showCloseIcon]="false">
  <c-sidebar-header [headerTitle]="edit_mode ? 'Edit Asset Serie' : 'Add Asset Serie'" (onClose)="showEditSidebar = false"> </c-sidebar-header>

  <div class="sidebar-tabs">
    <button [class.active]="editTab === 'general'" (click)="editTab = 'general'">{{ 'GENERAL_GENERAL' | translate }}</button>
    <button [class.active]="editTab === 'assets'" (click)="editTab = 'assets'">{{ 'GENERAL_ASSETS' | translate }}</button>
    <button [class.active]="editTab === 'conditions'" (click)="editTab = 'conditions'">{{ 'GENERAL_CONDITIONS' | translate }}</button>
    <button [class.active]="editTab === 'system'" (click)="editTab = 'system'">{{ 'PAGE_ASSET_EDIT_SYSTEM' | translate }}</button>
  </div>

  <c-sidebar-body *ngIf="assetsIndex >= 0">
    <div class="sidebar-body" *ngIf="editTab === 'general'">
      <c-collaboration-patch-input [object]="assetSerie" [collaborationKey]="'assetseries:' + assetSerie._id" [jsonpath]="'$.internalName'" [inputConfiguration]="inputConfig" [inputs]="inputs">
      </c-collaboration-patch-input>
      <c-collaboration-patch-input
        [object]="assetSerie"
        [collaborationKey]="'assetseries:' + assetSerie._id"
        [jsonpath]="'$.local.$language.title'"
        [inputs]="inputs"
        [inputConfiguration]="inputConfig"
      >
      </c-collaboration-patch-input>
      <c-collaboration-patch-input
        [object]="assetSerie"
        [collaborationKey]="'assetseries:' + assetSerie._id"
        [jsonpath]="'$.local.$language.description'"
        [inputs]="inputs"
        [inputConfiguration]="inputConfig"
      >
      </c-collaboration-patch-input>
      <c-collaboration-patch-input [object]="assetSerie" [collaborationKey]="'assetseries:' + assetSerie._id" [jsonpath]="'$.thumbnail'" [inputConfiguration]="inputConfig" [inputs]="inputs">
      </c-collaboration-patch-input>

      <div class="input-group compact content-language">
        <div class="input">
          <p-autoComplete
            [(ngModel)]="usedContentLanguages"
            [suggestions]="filteredContentLanguages"
            (completeMethod)="filterContentLanguages($event)"
            field="languageName"
            [forceSelection]="true"
            [multiple]="true"
            [dropdown]="true"
            [disabled]="assetSerie.source.system === 'Censhare'"
          >
          </p-autoComplete>
        </div>
        <div class="title">Content Languages</div>
      </div>
    </div>
    <div class="sidebar-body" *ngIf="editTab === 'assets'">
      <c-collaboration-patch-input
        [object]="assetSerie"
        [collaborationKey]="'assetseries:' + assetSerie._id"
        [jsonpath]="'$.assets'"
        [inputs]="inputs"
        [inputConfiguration]="inputConfigurationAssets"
        [disabled]="assetSerie.source.system === 'Censhare'"
      >
        <ng-template let-params #template>
          <div class="url-wrapper">
            <div class="input-group compact text" *ngIf="assetSerie.assets[params.index]">
              <div class="input actions">
                <p-dropdown appendTo="'body'" class="actions" [options]="domainOptions" optionLabel="option" optionValue="value" ngModel #dropdown></p-dropdown>
                <p-button
                  icon="pi pi-copy"
                  (onClick)="createURL(dropdown.value, assetSerie.assets[params.index], assetSerie._id)"
                  [disabled]="!assetSerie.assets[params.index] && !assetSerie._id"
                  class="actions"
                ></p-button>
              </div>
              <div class="title actions">URL</div>
            </div>
          </div>
        </ng-template>
      </c-collaboration-patch-input>
    </div>

    <div class="sidebar-body" *ngIf="editTab === 'conditions'">
      <div class="input-group compact text">
        <div class="input">
          <p-dropdown [disabled]="assetSerie.source.system === 'Censhare'" appendTo="body" [(ngModel)]="readAccess" [options]="readAccessOptions" optionValue="value" optionLabel="title"></p-dropdown>
        </div>
        <div class="title">ACCESS POLICY</div>
      </div>

      <c-collaboration-patch-input
        *ngIf="readAccess === 'Tags'"
        [object]="assetSerie"
        [collaborationKey]="'assetconfiguration:' + assetSerie._id"
        [jsonpath]="'$.readAccess[0].negatePolicy'"
        [inputs]="inputs"
        [inputConfiguration]="inputConfig"
      ></c-collaboration-patch-input>

      <c-collaboration-patch-input
        *ngIf="readAccess === 'Tags'"
        [object]="assetSerie"
        [collaborationKey]="'assetseries:' + assetSerie._id"
        [jsonpath]="'$.readAccess[0].tags'"
        [inputs]="inputs"
        [inputConfiguration]="inputConfig"
      ></c-collaboration-patch-input>
    </div>
    <div class="sidebar-body" *ngIf="editTab === 'system'">
      <c-collaboration-patch-input [object]="assetSerie" [collaborationKey]="'assetseries:' + assetSerie._id" [jsonpath]="'$.createdAt'" [inputs]="inputs"> </c-collaboration-patch-input>
      <c-collaboration-patch-input [object]="assetSerie" [collaborationKey]="'assetseries:' + assetSerie._id" [jsonpath]="'$.source.system'" [inputs]="inputs"> </c-collaboration-patch-input>
      <c-collaboration-patch-input [object]="assetSerie" [collaborationKey]="'assetseries:' + assetSerie._id" [jsonpath]="'$.source.externalId'" [inputs]="inputs"> </c-collaboration-patch-input>
    </div>
  </c-sidebar-body>

  <c-sidebar-footer [showSaveButton]="hasEditRights || !edit_mode" (onSave)="save(assetSerie); showEditSidebar = false"> </c-sidebar-footer>
</p-sidebar>
