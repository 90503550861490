import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { CacheContainer } from '../cache/cache-container';
import { CacheService } from '../cache/cache.service';
import { GetNotificationQuery, GetNotificationResponse } from 'src/common/api/v1/notifications/GetNotification';
import { Notification } from 'src/common/entities/Notification';
import { GetNotificationsQuery, GetNotificationsResponse } from 'src/common/api/v1/notifications/GetNotifications';
import { PostNotificationsBody, PostNotificationsQuery, PostNotificationsResponse } from 'src/common/api/v1/notifications/PostNotifications';
import { PostNotificationBody, PostNotificationQuery, PostNotificationResponse } from 'src/common/api/v1/notifications/PostNotification';
import { PostNotificationsSendBody, PostNotificationsSendQuery, PostNotificationsSendResponse } from 'src/common/api/v1/notifications/PostNotificationsSend';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private _cache: CacheContainer<Notification>;

  constructor(private apiService: ApiService, private cacheService: CacheService) {
    this._cache = this.cacheService.create<Notification>({
      get: async (notification) => await this.apiService.get<GetNotificationQuery, GetNotificationResponse>(`/api/v1/notifications/${notification}`).toPromise(),
      socketEvents: ['notification:update'],
    });
  }

  async getNotifications(eventId: string, query?: GetNotificationsQuery): Promise<GetNotificationsResponse> {
    const notifications = await this.apiService
      .get<GetNotificationsQuery, GetNotificationsResponse>(`/api/v1/notifications`, {
        limit: 50,
        skip: 0,
        ...query,
        event: eventId,
      })
      .toPromise()
      .then((notifications) => {
        this._cache.fill(notifications.items);
        return notifications;
      })
      .catch((e) => {
        return { items: [], totalCount: 0 };
      });

    return notifications;
  }

  createNotification(notification: Notification): Promise<Notification> {
    return this._cache.post<PostNotificationsQuery, PostNotificationsBody, PostNotificationsResponse>(`/api/v1/notifications`, notification);
  }

  updateNotification(notification: Notification): Promise<Notification> {
    return this._cache.post<PostNotificationQuery, PostNotificationBody, PostNotificationResponse>(`/api/v1/notifications/${notification._id}`, notification);
  }

  async sendNotification(notification: Notification, users?: string[]): Promise<PostNotificationsSendResponse> {
    return this.apiService
      .post<PostNotificationsSendQuery, PostNotificationsSendBody, PostNotificationsSendResponse>(`/api/v1/notifications/${notification._id}/send`, users ? { users: users } : {})
      .toPromise();
  }
}
