import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { createCTAButtonLocal, CTAButton, CTAButtonType, shareCtaTargets } from 'src/common/entities/CTAButton';
import { CTAButtonInputOptions } from 'src/common/inputs/Inputs';
import { AppFunctionActionType } from '../../../common/entities/AppFunction';
import { CTAButtonFactory } from '../../../common/factories/CTAButtonFactory';
import { appFunctionInputs } from '../../../common/inputs/menu-items/AppFunctionInputs';

@Component({
  selector: 'c-cta-button-edit',
  templateUrl: './cta-button-edit.component.html',
  styleUrls: ['./cta-button-edit.component.scss'],
})
export class CtaButtonEditComponent implements OnInit, OnDestroy {
  _ctaButton: CTAButton;
  _ctaButtonLabel: string = '';
  _editCTAButton: CTAButton | null = null;
  _editMode: boolean = false;
  _shareCtaTargetOptions = shareCtaTargets.map((t) => ({ label: t, value: t }));

  @Input()
  set ctaButton(ctaButton: CTAButton) {
    this._ctaButton = ctaButton;
    this.ngOnInit();
  }

  get ctaButton(): CTAButton {
    return this._ctaButton;
  }

  @Output()
  ctaButtonChange: EventEmitter<CTAButton> = new EventEmitter<CTAButton>();

  @Output()
  onFocus: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onBlur: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  disabled: boolean = false;

  hasFocus: boolean = false;
  currentLanguage: string;
  subscriptions: Subscription[] = [];

  @Input()
  inputOptions?: CTAButtonInputOptions;

  ctaButtonTypes: { label: string; value: CTAButtonType }[] = [
    { label: 'None', value: null },
    { label: 'Register Platform', value: 'registerplatform' },
    { label: 'Register Event', value: 'registerevent' },
    { label: 'Meet your Expert', value: 'meetyourexpert' },
    { label: 'External Link', value: 'externallink' },
    { label: 'Reminder', value: 'reminder' },
    { label: 'Bookmark', value: 'bookmark' },
    { label: 'Calendar', value: 'calendar' },
    { label: 'Share', value: 'share' },
    { label: 'Video Asset', value: 'videoasset' },
    { label: 'External Event', value: 'externalevent' },
    { label: 'App Function', value: 'appfunction' },
    { label: 'Explore Event', value: 'exploreevent' },
    { label: 'Page', value: 'page' },
  ];
  currentCTAButtonType: CTAButtonType | null = null;

  appFunctionActionTypes: { label: string; value: AppFunctionActionType }[] = appFunctionInputs['$.action']['dropdownOptions'];

  constructor(private activatedRoute: ActivatedRoute) {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((queryParams) => {
        this.currentLanguage = queryParams.language || 'en';
      })
    );
    this.currentLanguage = this.activatedRoute.snapshot.queryParams.language || 'en';
  }

  ngOnInit(): void {
    if (this.inputOptions?.types && this.inputOptions.types.length > 0) {
      this.ctaButtonTypes = this.ctaButtonTypes.filter((t) => this.inputOptions.types.includes(t.value));
    }

    if (this.inputOptions?.additionalTypes && this.inputOptions.additionalTypes.length > 0) {
      this.ctaButtonTypes = this.ctaButtonTypes.concat(this.inputOptions?.additionalTypes);
    }

    const type = this.ctaButtonTypes.find((t) => t.value === this.ctaButton?.ctaButtonType || null);
    this._ctaButtonLabel = type?.label;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.emitBlur();
  }

  edit(): void {
    this.onFocus.emit();
    this._editMode = true;
    const button = this.ctaButton ? (JSON.parse(JSON.stringify(this.ctaButton)) as CTAButton) : null;

    if (button && !button.local[this.currentLanguage]) {
      button.local[this.currentLanguage] = {
        label: '',
      };
      if (button.ctaButtonType === 'appfunction' && !button?.appFunction?.local[this.currentLanguage]) {
        button.appFunction.local[this.currentLanguage] = {
          actionData: '',
        };
      }
    }

    this._editCTAButton = button;
    this.currentCTAButtonType = button ? button.ctaButtonType : null;
  }

  typeChange(): void {
    if (this.currentCTAButtonType) {
      this._editCTAButton = new CTAButtonFactory({ ensureLocals: [this.currentLanguage] }).createCTAButton({
        ctaButtonType: this.currentCTAButtonType,
        disabled: false,
        local: {},
      });

      if (!this._editCTAButton.local[this.currentLanguage]) {
        this._editCTAButton.local[this.currentLanguage] = createCTAButtonLocal({});
      }
    } else {
      this._editCTAButton = null;
    }
  }

  async setCtaButton() {
    this.ctaButtonChange.emit(JSON.parse(JSON.stringify(this._editCTAButton)));
    this._editMode = false;
  }

  setAssetId(id: string) {
    this._editCTAButton.local[this.currentLanguage].video = id;
  }

  emitFocus() {
    if (!this.hasFocus) {
      this.hasFocus = true;
      this.onFocus.emit();
    }
  }

  emitBlur() {
    if (!this.hasFocus) {
      this.hasFocus = false;
      this.onBlur.emit();
    }
  }
}
