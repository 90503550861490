import { EventStage } from './EventStage';

export interface TitleEventStageLocal {
  title: string;
  icon: string;
}

export interface TitleEventStage extends EventStage {
  eventStageType: 'Title';
  showLiveButton: boolean;
  local: { [language: string]: TitleEventStageLocal };
}

export function isTitleEventStage(object: any): object is TitleEventStage {
  return object?.eventStageType === 'Title';
}
