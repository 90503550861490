import { TextAttributes } from './../Attributes';
import { BreakpointValue } from '../Breakpoint';
import { CTAButton } from '../CTAButton';
import { PageModule } from './PageModule';

export interface StreamEventStagePageModuleLocal {
  smallHeadline: string;
  smallHeadline_attr?: StreamEventStagePageModuleAttributes;
  title: string;
  title_attr?: StreamEventStagePageModuleAttributes;
  subtext: string;
  background: string;
}

export type StreamEventStagePageModuleAttributes = TextAttributes & {};

export interface StreamEventStagePageModule extends PageModule {
  pageModuleType: 'StreamEventStage';
  local: { [language: string]: StreamEventStagePageModuleLocal };
  ctalist?: StreamEventStageButton[];
  ctalistInteraction?: StreamEventStageButton[];
  discoverMore?: boolean;
  buttonSpacingTopBreakpoint?: BreakpointValue<number>;
  stageHeight?: BreakpointValue<number>;
}
export interface StreamEventStageButton {
  _id: string;
  ctaButton: CTAButton;
}

export function isStreamEventStagePageModule(object: any): object is StreamEventStagePageModule {
  return object?.pageModuleType === 'StreamEventStage';
}
