import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { EventKeytopic } from '../entities/EventVersion';
import { KeytopicContent } from '../entities/Keytopic';

export class EventKeytopicFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  createEventKeytopic(values?: any) {
    return {
      keytopic: values?.keytopic || null,
      override: !values?.override ? null : this.createKeytopicContent(values.override),
      local: this.localKeys(values?.local).reduce((a, b) => ({ ...a, [b]: this.createEventKeytopicLocal(values.local[b]) }), {}),
    } as EventKeytopic;
  }

  createEventKeytopicLocal(values?: any) {
    return {
      assets: values?.assets || [],
    };
  }

  createKeytopicContent(values?: any) {
    return {
      header: values?.header || null,
      icon: values?.icon || null,
      local: this.localKeys(values?.local).reduce((a, b) => ({ ...a, [b]: this.createKeytopicContentLocal(values.local[b]) }), {}),
    } as KeytopicContent;
  }

  createKeytopicContentLocal(values?: any) {
    return {
      title: values?.title || '',
      shortDescription: values?.shortDescription || '',
      longDescription: values?.longDescription || '',
    };
  }
}
