import { Pipe, PipeTransform } from '@angular/core';
import { EventType } from '../../../common/entities/EventType';
import { EventsService } from '../../services/events/events.service';

@Pipe({
  name: 'eventType',
})
export class EventTypePipe implements PipeTransform {
  constructor(private eventsService: EventsService) {}

  transform(value: string, ...args: unknown[]): Promise<EventType> {
    return this.eventsService.getEventType(value);
  }
}
