import { TextAttributes } from '../Attributes';
import { CTAButton } from '../CTAButton';
import { PageModule, TitlePositions } from './PageModule';
import { ProductImageSize } from './ProductListPageModule';

export const actionTypes = ['open-link', 'show-overlay', 'fullscreeen-dialog'] as const;
export type ActionType = (typeof actionTypes)[number];

export const displayAs = ['Slider', 'Grid', 'Grid_V2'] as const;
export type ProductListDisplayType = (typeof displayAs)[number];

export const productScope = ['manual', 'assetPage', 'external'] as const;
export type ProductScope = (typeof productScope)[number];
export interface ProductList2PageModule extends PageModule {
  pageModuleType: 'ProductList2';
  showHeadline: boolean;
  showHeadlineGroup?: boolean;
  showHeadlineSeries?: boolean;
  local: {
    [language: string]: {
      title: string;
      title_attr?: ProductList2PageModuleAttributes;
    };
  };
  action: ActionType;
  titlePosition?: TitlePositions;
  displayAs?: ProductListDisplayType;
  ctaButton?: CTAButton;
  groups?: ProductList2PageModuleGroup[];
  productImageSize?: ProductImageSize;
  productScope?: ProductScope;
  maxProductCount?: number;
}

export type ProductList2PageModuleAttributes = TextAttributes & {};

export interface ProductList2PageModuleGroup {
  _id: string;
  local: {
    [language: string]: {
      title?: string;
    };
  };
  series: ProductList2PageModuleSeries[];
}

export interface ProductList2PageModuleSeries {
  _id: string;
  local: {
    [language: string]: {
      title?: string;
    };
  };
  products: string[];
}

export function isProductList2PageModule(object: any): object is ProductList2PageModule {
  return object?.pageModuleType === 'ProductList2';
}
