<div class="input-group">
  <div class="input">
    <p-dropdown [(ngModel)]="ngModel.eventTicket" [options]="tickets" [disabled]="isLoading" (ngModelChange)="ticketChange()"></p-dropdown>
  </div>
  <div class="label">Ticket</div>
</div>
<div
  class="input-group"
  *ngFor="let customField of customFields"
  [class.text]="asCustomFieldText(customField)"
  [class.textarea]="asCustomFieldTextarea(customField)"
  [class.switch]="asCustomFieldCheckbox(customField)"
  [class.select]="asCustomFieldSelect(customField)"
>
  <div class="input">
    <input [disabled]="isLoading" *ngIf="asCustomFieldText(customField)" type="text" pInputText [(ngModel)]="ngModel.customFields[customField._id]" (ngModelChange)="updateEventRegistrationData()" />
    <textarea
      [disabled]="isLoading"
      *ngIf="asCustomFieldTextarea(customField)"
      pInputTextarea
      [rows]="countRows(asString(ngModel.customFields[customField._id]), 1)"
      [(ngModel)]="ngModel.customFields[customField._id]"
      (ngModelChange)="updateEventRegistrationData()"
    ></textarea>
    <p-checkbox
      [disabled]="isLoading"
      *ngIf="asCustomFieldCheckbox(customField)"
      [(ngModel)]="ngModel.customFields[customField._id]"
      [binary]="true"
      (ngModelChange)="updateEventRegistrationData()"
    ></p-checkbox>
    <p-dropdown
      [disabled]="isLoading"
      *ngIf="asCustomFieldSelect(customField) as customFieldSelect"
      [(ngModel)]="ngModel.customFields[customField._id]"
      (ngModelChange)="updateEventRegistrationData()"
      [options]="customFieldSelectOptions[customField._id]"
    ></p-dropdown>
  </div>
  <div class="label">{{ customField.internalName }}</div>
</div>
<div class="input-group">
  <div class="input">
    <c-event-slots-edit [eventVersion]="event.currentEventVersion" [(eventSlots)]="ngModel.eventSlots" [eventTicket]="ngModel.eventTicket"></c-event-slots-edit>
  </div>
  <div class="label">Event Slots</div>
</div>
