import { Onboarding, OnboardingLocal, OnboardingStep, OnboardingStepLocal } from '../entities/Onboarding';
import { AbstractFactory, FactoryOptions } from './AbstractFactory';

export class OnboardingFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createOnboarding(values?: any): Promise<Onboarding> {
    return {
      _id: await this.id(values?._id),
      local: await this.local(values, val => this.createOnboardingLocal(val)),
      internalName: values?.internalName || '',
      showOnboarding: values?.showOnboarding || false,
      startingPageImage: values?.startingPageImage || null,
      startingPageImageMobile: values?.startingPageImageMobile || null,
      startingPageImageTablet: values?.startingPageImageTablet || null,
      steps: await Promise.all((values?.steps || []).map((step: any) => this.createOnboardingStep(step))),
      forceLogin: values?.forceLogin || false,
      loginPageImage: values?.loginPageImage || null,
      loginPageImageMobile: values?.loginPageImageMobile || null,
      loginPageImageTablet: values?.loginPageImageTablet || null
    } as Onboarding;
  }

  public createOnboardingLocal(values: any): OnboardingLocal {
    return {
      description: values?.description ?? '',
      startButtonLabel: values?.startButtonLabel ?? '',
      loginPageTitle: values?.loginPageTitle ?? '',
      loginPageText: values?.loginPageText ?? ''
    } as OnboardingLocal;
  }

  public async createOnboardingStep(values: any): Promise<OnboardingStep> {
    return {
      _id: await this.id(values?._id),
      local: await this.local(values, val => this.createOnboardingStepLocal(val)),
      imageMobile: values?.imageMobile || null,
      imageTablet: values?.imageTablet || null,
      imageDesktop: values?.imageDesktop || null,
      ctaButton: values?.ctaButton || null
    };
  }
  public createOnboardingStepLocal(values: any): OnboardingStepLocal {
    return {
      title: values?.title || '',
      description: values?.description || ''
    };
  }
}
