import { TableOptions } from '../components/table/table.interfaces';

export const areArraysEqualByTableOptions = (itemsA: unknown[], itemsB: unknown[], tableOptions: TableOptions<unknown>): boolean => {
  const hashesA = Array.from(new Set(itemsA.map((item) => getHashByFilter(item, tableOptions))));
  const hashesB = Array.from(new Set(itemsB.map((item) => getHashByFilter(item, tableOptions))));
  const set = Array.from(new Set([...hashesA, ...hashesB]));
  return set.length === hashesA.length && set.length === hashesB.length;
};

export const areArraysEqualByObjects = (itemsA: unknown[], itemsB: unknown[]): boolean => {
  const jsonsA = Array.from(new Set(itemsA.map((item) => JSON.stringify(item))));
  const jsonsB = Array.from(new Set(itemsB.map((item) => JSON.stringify(item))));
  const set = Array.from(new Set([...jsonsA, ...jsonsB]));
  return set.length === jsonsA.length && set.length === jsonsB.length;
};

export const areObjectEquals = (itemsA: unknown, itemsB: unknown): boolean => {
  return JSON.stringify(itemsA) === JSON.stringify(itemsB);
};

const getHashByFilter = (item: unknown, tableOptions: TableOptions<unknown>) => {
  const { filters } = tableOptions;
  const paths = filters.map(({ path }) => path);
  return paths.reduce((previousValue, currentValue) => {
    return `${previousValue}${item[currentValue]}`;
  }, '');
};
