import { AbstractFactory, FactoryOptions } from './AbstractFactory';
import { EventProduct } from '../entities/EventVersion';
import { ProductContent } from '../entities/Product';

export class EventProductFactory extends AbstractFactory {
  constructor(options?: FactoryOptions) {
    super(options);
  }

  async createEventProduct(values?: any) {
    return {
      product: values?.product || null,
      override: values?.override ? await this.createProductContent(values.override) : null
    } as EventProduct;
  }

  async createProductContent(values?: any) {
    return {
      local: await this.local(values, val => this.createProductContentLocal(val)),
      image: values?.image || null,
      productLogo: values?.productLogo || null,
      productTag: values?.productTag || '',
      technology: values?.technology || '',
      tags: values?.tags || [],
      relatedAssets: values?.relatedAssets || [],
      relatedProducts: values?.relatedProducts || [],
      exhibitedWith: values?.exhibitedWith || '',
      embeddedPage: values?.embeddedPage || ''
    } as ProductContent;
  }

  createProductContentLocal(values?: any) {
    return {
      title: values?.title || '',
      teaserText: values?.teaserText || '',
      description: values?.description || '',
      productDetails: values?.productDetails || '',
      productHighlights: values?.productHighlights || '',
      link: values?.link || ''
    };
  }
}
