import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { EventStageType, eventStageTypes } from 'src/common/entities/EventStage';

interface EventStageItem {
  type: EventStageType;
  label: string;
  image: string;
  description: string;
}

@Component({
  selector: 'c-event-stage-type-select',
  templateUrl: './event-stage-type-select.component.html',
  styleUrls: ['./event-stage-type-select.component.scss'],
})
export class EventStageTypeSelectComponent implements OnInit {
  @Output()
  selected: EventEmitter<EventStageType> = new EventEmitter<EventStageType>();

  @Output()
  onClose = new EventEmitter<boolean>();

  eventStages: EventStageItem[] = [];
  filteredEventStages: EventStageItem[] = [];
  filterText = '';

  constructor(private utilsService: UtilsService) {}

  async label(eventStageType: EventStageType) {
    const key = `GENERAL_EVENSTAGETYPE_${eventStageType.toUpperCase()}`;
    const translation = await this.utilsService.translate(key);
    return key === translation ? eventStageType : translation;
  }

  async description(eventStageType: EventStageType) {
    const key = `GENERAL_EVENSTAGETYPE_${eventStageType.toUpperCase()}_DESCRIPTION`;
    const translation = await this.utilsService.translate(key);
    return key === translation ? '' : translation;
  }

  async ngOnInit() {
    const labels = await Promise.all(eventStageTypes.map((p) => this.label(p)));
    const descriptions = await Promise.all(eventStageTypes.map((p) => this.description(p)));

    this.eventStages = eventStageTypes.map((p, i) => ({
      type: p,
      label: labels[i],
      description: descriptions[i],
      image: `/assets/images/eventstages/${p.toLowerCase()}_icon.svg`,
    }));

    this.filteredEventStages = this.eventStages.filter((p) => true);
  }

  filter() {
    this.filteredEventStages = this.eventStages.filter((p) => {
      return p.label.toLowerCase().includes(this.filterText.toLowerCase()) || p.description.toLowerCase().includes(this.filterText.toLowerCase());
    });
  }

  async select(eventStageType: EventStageType) {
    this.selected.emit(eventStageType);
  }
}
