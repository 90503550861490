import { TextAttributes } from './../Attributes';
import { CTAButton } from '../CTAButton';
import { PageModule, TitlePositions } from './PageModule';

export interface IconGroupPageModule extends PageModule {
  pageModuleType: 'IconGroup';
  titlePosition?: TitlePositions;
  local: {
    [language: string]: {
      title: string;
      title_attr?: IconGroupPageModuleAttributes;
      icons: IconGroupPageModuleChildElement;
    };
  };
  ctaButton?: CTAButton;
}

export interface IconGroupPageModuleChildElement {
  title: string;
  text: string;
  icon: string;
  link?: string;
}

export type IconGroupPageModuleAttributes = TextAttributes & {};

export function isIconGroupPageModule(object: any): object is IconGroupPageModule {
  return object?.pageModuleType === 'IconGroup';
}
