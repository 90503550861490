import { Pipe, PipeTransform } from '@angular/core';
import { CustomFieldsService } from 'src/app/services/customFields/custom-fields.service';
import { CustomField } from 'src/common/entities/CustomField';

@Pipe({
  name: 'customField',
})
export class CustomFieldsPipe implements PipeTransform {
  constructor(private customFieldsService: CustomFieldsService) {}

  transform(customField: string, ...args: unknown[]): Promise<CustomField> {
    if (!customField) return null;

    return this.customFieldsService.getCustomField(customField);
  }
}
