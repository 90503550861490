<div class="sidebar-body">
  <c-collaboration-patch-input [object]="newPreview" [disabled]="working" jsonpath="$.startDate" [inputs]="inputs"></c-collaboration-patch-input>

  <div class="event-phase-preview" *ngIf="type === 'event'">
    Event Phase: <span class="phase">{{ event | eventPhase : null : newPreview.startDate | lowercase }}</span>
  </div>

  <c-collaboration-patch-input [object]="newPreview" [disabled]="working" jsonpath="$.expiredIn" [inputs]="inputs"></c-collaboration-patch-input>

  <p-button class="create-preview" (onClick)="createPreview()" [label]="'Generate preview link'" [disabled]="working"></p-button>

  <h5 *ngIf="generatedPreview">Newly generated preview link</h5>
  <div *ngIf="generatedPreview" class="new preview">
    <div class="preview-link">
      <div class="input-group compact">
        <div class="input">
          <input #generatedPreviewInput [ngModel]="previewState[generatedPreview._id].link" readonly="readonly" />
          <p-button icon="pi pi-copy" [pTooltip]="'Click to copy'" [tooltipPosition]="'bottom'" [tooltipEvent]="'hover'" (onClick)="copyLink()"></p-button>
        </div>
        <div class="title">Link</div>
      </div>
      <div>Expires at {{ generatedPreview.expiredAt | date : 'shortDate' }} {{ generatedPreview.expiredAt | date : 'shortTime' }}</div>
      <div *ngIf="mode === 'select'">
        <p-button label="Select" [disabled]="working" (onClick)="selected.emit(generatedPreview)"></p-button>
      </div>
    </div>
  </div>

  <h5 *ngIf="previousPreviews.length > 0">Previously generated preview links for version {{ type === 'page' ? pageVersion.version : eventVersion.version }}</h5>
  <div *ngFor="let preview of previousPreviews; let index = index" class="previous preview">
    <div class="preview-header" (click)="previewState[preview._id].open = !previewState[preview._id].open" [class.open]="previewState[preview._id].open">
      <i class="pi" [class.pi-chevron-down]="previewState[preview._id].open" [class.pi-chevron-right]="!previewState[preview._id].open"></i>
      <div *ngIf="type === 'event'">
        For event phase <span class="phase">{{ event | eventPhase : null : (preview.timeDifference | serverTime | async) | lowercase }}</span> at
        <span class="date" *ngIf="preview.timeDifference | serverTime | async as st">{{ st | date : 'shortDate' }} {{ st | date : 'shortTime' }}</span>
      </div>
      <div *ngIf="type === 'page'">
        Start date at <span class="date">{{ preview.startDate | date : 'shortDate' }} {{ preview.startDate | date : 'shortTime' }}</span>
      </div>
    </div>
    <div class="preview-link" *ngIf="previewState[preview._id].open">
      <div class="input-group compact">
        <div class="input">
          <input #previewInputs [ngModel]="previewState[preview._id].link" readonly="readonly" />
          <p-button icon="pi pi-copy" [pTooltip]="'Click to copy'" [tooltipPosition]="'bottom'" [tooltipEvent]="'hover'" (onClick)="copyLink(index)"></p-button>
        </div>
        <div class="title">Link</div>
      </div>
      <div>Expires at {{ preview.expiredAt | date : 'shortDate' }} {{ preview.expiredAt | date : 'shortTime' }}</div>
      <div *ngIf="mode === 'select'">
        <p-button label="Select" [disabled]="working" (onClick)="selected.emit(preview)"></p-button>
      </div>
    </div>
  </div>
</div>
