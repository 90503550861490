import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { TableComponent } from 'src/app/components/table/table.component';
import { TableOptions } from 'src/app/components/table/table.interfaces';
import { CustomDataService } from 'src/app/services/custom-data/custom-data.service';
import { CustomFieldsService } from 'src/app/services/customFields/custom-fields.service';
import { EventsService } from 'src/app/services/events/events.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { CustomField, CustomFieldType } from 'src/common/entities/CustomField';
import { Event } from 'src/common/entities/Event';
import { EventVersion, isRegistrationCustomFieldAutoFillCustomData, RegistrationCustomField, RegistrationCustomFieldAutoFillType } from 'src/common/entities/EventVersion';
import { RegistrationCustomFieldFactory } from 'src/common/factories/RegistrationCustomFieldFactory';
import { EventVersionFactory } from 'src/common/factories/EventVersionFactory';
import { InputConfiguration } from 'src/common/inputs/Inputs';
import { AssetsService } from '../../../services/assets/assets.service';
import { IFilterList } from '../../../../common/api/interfaces';
import { debounceTime } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'c-event-custom-fields',
  templateUrl: './event-custom-fields.component.html',
  styleUrls: ['./event-custom-fields.component.scss'],
})
export class EventCustomFieldsComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) table: TableComponent<RegistrationCustomField>;

  @Input()
  event: Event;

  @Input()
  eventVersion: EventVersion;

  @Input()
  language: string;

  inputConfigurationRequired: InputConfiguration;

  checkDuplicateType: CustomFieldType[] = ['ticketAttendees', 'ticketSelfVisit', 'ticketSlotSelection'];

  searchText: string;
  searchTextSubject: Subject<string> = new Subject<string>();
  searchResult: CustomField[] = [];
  currentPage = 0;
  assetsPerPage = 30;
  isLoading = false;
  hasEditContentRight: boolean = false;

  showCustomFieldSelect = false;
  customFieldEditId: string = null;
  customFieldEditHelper: any = {
    visibleOnAllTickets: false,
  };

  customFieldIdTypeMapper: { [id: string]: CustomFieldType } = {};

  get editRegistrationCustomField(): RegistrationCustomField {
    return this.eventVersion.registrationCustomFields.find((rCF) => rCF.customField === this.customFieldEditId);
  }

  get showCustomFieldEdit(): boolean {
    return this.customFieldEditId !== null;
  }

  set showCustomFieldEdit(val: boolean) {
    if (!val) {
      this.customFieldEditId = null;
    }
  }

  customFieldsTableOptions: TableOptions<RegistrationCustomField> = {
    columns: [
      { header: 'GENERAL_INTERNAL_NAME', visible: 'fixed' },
      { header: 'PAGE_CUSTOM_FIELD_TYPE' },
      { header: 'GENERAL_REQUIRED' },
      { header: 'PAGE_EVENT_SECTION_CUSTOM_FIELDS_REGISTRATION_ALL_TICKETS' },
      { header: 'GENERAL_MOVE' },
    ],
  };

  customFieldEditTab = 'general';
  subscriptions: Subscription[] = [];

  inputConfigurationEventTickets: InputConfiguration = {};

  registrationCustomFieldAutoFillTypeOptions: { label: string; value: string }[] = [
    { value: null, label: '-' },
    { value: 'CustomData', label: 'From Custom Data' },
  ];

  inputConfigurationCustomDataAutoFillProperty: InputConfiguration = {
    type: 'dropdown',
    dropdownOptions: [],
  };

  inputConfigurationCustomData: InputConfiguration = {
    type: 'dropdown',
    dropdownOptions: [],
  };

  constructor(
    private customFieldsService: CustomFieldsService,
    private eventsService: EventsService,
    private utilsService: UtilsService,
    private customDataService: CustomDataService,
    private assetsService: AssetsService,
    private messageService: MessageService,
    private authService: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.hasEditContentRight = await this.authService.hasRight('events.editContent');
    this.inputConfigurationEventTickets = {
      type: 'multiselect',
      multiselectOptions: this.eventVersion.eventTickets
        .filter((item) => !item.deletedAt)
        .map((eT) => ({
          label: eT.internalName,
          value: eT._id,
        })),
    };

    this.inputConfigurationRequired = {
      type: 'switch',
      disabled: false,
    };

    this.inputConfigurationCustomData = {
      type: 'dropdown',
      dropdownOptions: await (
        await this.customDataService.getCustomData({ filter: {}, orderBy: 'internalName', orderDirection: 1 })
      ).items.map((s) => ({
        label: s.internalName,
        value: s._id,
      })),
    };

    this.subscriptions.push(
      this.eventsService.lastEventVersionPatch(this.eventVersion._id).subscribe((patch) => {
        if (this.utilsService.startsWithJsonpath(patch.patch.jsonpath, '$.registrationCustomFields', patch.patch.jsonpathParams)) {
          this.table.refresh();
        }
      })
    );

    this.subscriptions.push(this.searchTextSubject.pipe(debounceTime(500)).subscribe(() => this.executeSearch(true)));

    this.executeSearch();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async editCustomField(customField: RegistrationCustomField): Promise<void> {
    const field = await this.customFieldsService.getCustomField(customField.customField);
    if (this.checkDuplicateType.indexOf(field.customFieldType) > -1) {
      this.inputConfigurationRequired.disabled = true;
    } else {
      this.inputConfigurationRequired.disabled = false;
    }
    this.customFieldEditHelper.visibleOnAllTickets = customField.eventTickets === null;
    this.customFieldEditId = customField.customField;

    await this.updateAutoFillCustomDataProperty(customField);
  }

  async updateAutoFillCustomDataProperty(customField: RegistrationCustomField): Promise<void> {
    const none = [{ label: '-', value: null }];
    if (isRegistrationCustomFieldAutoFillCustomData(customField.autoFill) && customField.autoFill.customData) {
      const customData = await this.customDataService.getCustomData(customField.autoFill.customData);
      this.inputConfigurationCustomDataAutoFillProperty = {
        type: 'dropdown',
        dropdownOptions: none.concat(
          customData.properties.map((p) => ({
            label: p.internalName,
            value: p._id,
          }))
        ),
      };
    } else {
      this.inputConfigurationCustomDataAutoFillProperty = {
        type: 'dropdown',
        dropdownOptions: none,
      };
    }
  }

  async addRegistrationCustomField(customField: CustomField): Promise<void> {
    let customFieldRequired = true;
    if (this.checkDuplicateType.indexOf(customField.customFieldType) > -1) {
      customFieldRequired = false;
      const match = this.eventVersion.registrationCustomFields.find((cf) => this.customFieldIdTypeMapper[cf.customField] === customField.customFieldType);

      if (match) {
        this.messageService.add({
          severity: 'error',
          summary: 'Custom Field Creation failed',
          detail: `You can only add one Field of type ${customField.customFieldType} in an event.
          You need to remove that field first before adding a new field of that type.`,
        });

        return;
      }
    }

    const newRegistrationCustomField = await new RegistrationCustomFieldFactory().createCustomField({
      customField: customField._id,
      required: customFieldRequired,
    });

    await this.eventsService.patch(this.eventVersion, {
      command: 'push',
      jsonpath: `$.registrationCustomFields`,
      value: newRegistrationCustomField,
    });

    this.showCustomFieldSelect = false;

    await this.editCustomField(newRegistrationCustomField);
  }

  async registrationCFUp(customField: RegistrationCustomField): Promise<void> {
    await this.eventsService.patch(this.eventVersion, {
      command: 'up',
      jsonpath: `$.registrationCustomFields[?(@.customField=='$customFieldId')]`,
      jsonpathParams: { customFieldId: customField.customField },
    });
  }

  async registrationCFDown(customField: RegistrationCustomField): Promise<void> {
    await this.eventsService.patch(this.eventVersion, {
      command: 'down',
      jsonpath: `$.registrationCustomFields[?(@.customField=='$customFieldId')]`,
      jsonpathParams: { customFieldId: customField.customField },
    });
  }

  async registrationCFDelete(customField: RegistrationCustomField): Promise<void> {
    this.eventsService.patch(this.eventVersion, {
      command: 'delete',
      jsonpath: `$.registrationCustomFields[?(@.customField=='$customFieldId')]`,
      jsonpathParams: { customFieldId: customField.customField },
    });
  }

  async setVisibleOnAllTickets(allTickets, customFieldId: string): Promise<void> {
    // Set eventTickets = null to activate customfield for all tickets,
    // or activate customfield for single tickets by filling eventTickets[]
    await this.eventsService.patch(this.eventVersion, {
      command: 'set',
      jsonpath: `$.registrationCustomFields[?(@.customField=='$customFieldId')].eventTickets`,
      jsonpathParams: { customFieldId },
      value: allTickets ? null : [],
    });
  }

  registrationCustomFieldAutoFillType(customField: RegistrationCustomField): RegistrationCustomFieldAutoFillType {
    return customField.autoFill?.registrationCustomFieldAutoFillType || null;
  }

  async registrationCustomFieldAutoFillTypeChange(customField: RegistrationCustomField, type: RegistrationCustomFieldAutoFillType | null): Promise<void> {
    if (type) {
      await this.eventsService.patch(this.eventVersion, {
        command: 'set',
        jsonpath: `$.registrationCustomFields[?(@.customField=='$customFieldId')].autoFill`,
        jsonpathParams: { customFieldId: customField.customField },
        value: new EventVersionFactory().createRegistrationCustomFieldAutoFill({ registrationCustomFieldAutoFillType: type }),
      });
    } else {
      await this.eventsService.patch(this.eventVersion, {
        command: 'set',
        jsonpath: `$.registrationCustomFields[?(@.customField=='$customFieldId')].autoFill`,
        jsonpathParams: { customFieldId: customField.customField },
        value: null,
      });
    }
    await this.updateAutoFillCustomDataProperty(customField);
  }

  async autoFillCustomDataChanged(id: string): Promise<void> {
    if (isRegistrationCustomFieldAutoFillCustomData(this.editRegistrationCustomField.autoFill)) {
      if (this.editRegistrationCustomField.autoFill.customData !== id) {
        await this.eventsService.patch(this.eventVersion, {
          command: 'set',
          jsonpath: `$.registrationCustomFields[?(@.customField=='$customFieldId')].autoFill.property`,
          jsonpathParams: { customFieldId: this.editRegistrationCustomField.customField },
          value: null,
        });
      }
    }
    await this.updateAutoFillCustomDataProperty(this.editRegistrationCustomField);
  }

  htmlToText(html: string): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  searchTextKeyUp(): void {
    this.searchTextSubject.next(this.searchText);
  }

  executeSearch(clear = false): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    setTimeout(() => {
      let filter: IFilterList | null = null;

      if (clear) {
        this.searchResult = [];
        this.currentPage = 0;
        this.assetsPerPage = 0;
      }
      filter = {
        internalName: {
          matchMode: 'contains',
          value: this.searchText,
          caseInsensitive: true,
        },
        deletedBy: null,
      };

      this.customFieldsService
        .getCustomFields({
          limit: this.assetsPerPage,
          skip: this.assetsPerPage * this.currentPage++,
          ...(filter ? { filter } : {}),
        })
        .then((r) => {
          r.items.forEach((e) => {
            this.searchResult.push(e);
            this.customFieldIdTypeMapper[e._id] = e.customFieldType;
          });
        })
        .finally(() => (this.isLoading = false));
    }, 150);
  }
}
