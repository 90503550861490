import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Dialog } from 'primeng/dialog';
import { AppointmentsService } from 'src/app/services/appointments/appointments.service';
import { Appointment } from 'src/common/entities/Appointment';
import { Player } from 'furioos-sdk';
import { ShowroomsService } from 'src/app/services/showrooms/showrooms.service';
import { Showroom } from 'src/common/entities/Showroom';
import { LookupsService } from 'src/app/services/lookups/lookups.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.scss'],
})
export class AppointmentDetailComponent implements OnInit {
  loading = false;
  saving = false;

  showShowroom = false;
  showroomStarted = false;

  displayPosition: boolean;
  position: string;
  showroom: Showroom;

  topics: { [key: string]: string } = {};

  @Input() appointment: Appointment;
  @Input() dialog: Dialog;
  @Input() adhocAppointment: boolean = false;

  get currentAdminUserId(): string {
    return this.authService.currentAdminUser()._id;
  }

  eventKeytopics: { href: string; title: string }[] = [];
  helpfulLinks: { href: string; title: string }[] = [];

  constructor(
    private appointmentService: AppointmentsService,
    private router: Router,
    private environment: Environment,
    private lookupsService: LookupsService,
    private showroomsService: ShowroomsService,
    private authService: AuthService,
    private configurationService: ConfigurationService
  ) {}

  async ngOnInit() {
    this.topics = (await this.lookupsService.getLookup('topic'))?.local['en'] || {};

    this.eventKeytopics = this.configurationService.configuration().appointment?.eventKeytopics || [];
    this.helpfulLinks = this.configurationService.configuration().appointment?.helpfulLinks || [];
  }

  async startAppointment() {
    this.appointment = await this.appointmentService.startAppointment(this.appointment);
  }

  async joinExternalAppointment() {
    this.saving = true;

    if (!this.appointment.actualDateFrom) {
      await this.startAppointment();
    }

    if (this.appointment.externalAppointment) {
      window.open(this.appointment.externalAppointment.link, '_blank');
    }

    this.saving = false;
  }

  async joinDigitalShowroom() {
    this.saving = true;

    if (!this.adhocAppointment && !this.appointment.actualDateFrom) {
      await this.startAppointment();
    }

    this.showShowroom = true;

    if (!this.showroomStarted) {
      this.showroomStarted = true;

      try {
        this.showroom = await this.showroomsService.generateToken({
          tracking: !this.adhocAppointment,
          appointment: this.appointment?._id,
        });
      } catch (err) {
        console.error(err);
      }

      if (this.showroom) {
        setTimeout(() => {
          const options = {
            whiteLabel: true,
            hideToolbar: false,
            hideTitle: true,
            hidePlayButton: false,
          };

          const player = new Player(this.showroom?.instance, 'furioos_container', options);

          player.onSDKMessage((data) => {
            if (data === 'requestToken') {
              player.sendSDKMessage({
                token: this.showroom?.token,
                defaultRoom: this.showroom?.defaultRoom,
                url: this.environment.showroom.trackingUrl,
                defaultLanguage: 'en',
              });
            }
          });

          this.saving = false;
        }, 100);
      }

      if (!this.adhocAppointment && !this.appointment.joinedDigitalShowRoomAt) {
        try {
          this.appointment = await this.appointmentService.showDigitalShowroom(this.appointment);
        } catch (error) {
          console.error(error);
        }
      }
    }

    this.saving = false;
  }

  async stopAppointment() {
    this.saving = true;

    try {
      this.appointment = await this.appointmentService.stopAppointment(this.appointment);
    } catch (err) {
      console.error(err);
    }

    this.saving = false;
  }

  async closeDialog(event, saveBeforClose: boolean) {
    if (saveBeforClose) {
      await this.stopAppointment();
    }

    this.showShowroom = false;

    const temp = this.router.onSameUrlNavigation;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(this.router.url).then(() => {
      this.router.onSameUrlNavigation = temp;
    });

    this.dialog.close(event);
  }

  showPositionDialog(position: string) {
    this.position = position;
    this.displayPosition = true;
  }
}
