import { TextAttributes } from './../Attributes';
import { BreakpointKey, BreakpointValue } from './../Breakpoint';
import { CTAButton } from '../CTAButton';
import { PageModule } from './PageModule';

export const conversionStageTeaserItemTypes = ['Link', 'CTA'] as const;
export type ConversionStageTeaserItemType = (typeof conversionStageTeaserItemTypes)[number];

export interface ConversionStageTeaserItem {
  _id: string;
  teaserItemType: ConversionStageTeaserItemType;
}

export interface ConversionStageLinkTeaserItem extends ConversionStageTeaserItem {
  teaserItemType: 'Link';
  image: string;
  title: string;
  link: string;
}

export interface ConversionStageCTATeaserItem extends ConversionStageTeaserItem {
  teaserItemType: 'CTA';
  image: string;
  title: string;
  cta: CTAButton;
}

export interface ConversionStagePageModuleInfo {
  title: string;
  subtext: string;
}

export interface ConversionStagePageModuleLocal extends ConversionStagePageModuleInfo {
  title_attr?: TextAttributes;
  items: ConversionStageTeaserItem[];
  background?: string;
  pageSelectorPrefix?: string;
  devices: { [device in BreakpointKey]?: ConversionStagePageModuleInfo };
}

export interface ConversionStagePageModule extends PageModule {
  pageModuleType: 'ConversionStage';
  local: { [language: string]: ConversionStagePageModuleLocal };
  ctaButton?: CTAButton | null;
  stageHeight: BreakpointValue<number>;
  enablePageSelector: boolean;
  selectorPages: string[];
}

export function isLinkConversionStageLinkTeaserItem(object: any): object is ConversionStageLinkTeaserItem {
  return object?.teaserItemType === 'Link';
}

export function isCTAConversionStageCTATeaserItem(object: any): object is ConversionStageCTATeaserItem {
  return object?.teaserItemType === 'CTA';
}

export function isConversionStagePageModule(object: any): object is ConversionStagePageModule {
  return object?.pageModuleType === 'ConversionStage';
}
